<template>
  <div>
    <VDivider />
    <VList class="pt-2">
      <VListItem
        v-if="($isUserAdmin || $isUserMentor || !$viewProgramAsStudent) && !previewOnly"
        :to="{
          name: 'present.view',
          params: {
            team: $team.slug,
            type: item.type,
            id: item.id,
            isBackToDefault: isBackToDefault,
          },
        }"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          mdi-eye
        </VIcon>
        <VListItemTitle>View</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="fromProgram && !previewOnly"
        @click="copyTextToClipboard(getFullyQualifiedUrlForItem(`present.view`, {
          params: {
            team: `${$team.slug}`,
            type: item.type,
            id: item.id,
          },
        }))"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          link
        </VIcon>
        <VListItemTitle>Copy Link</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="hasAccess && !previewOnly"
        :to="{
          name: 'lesson.update',
          params: {
            team: `${$team.slug}`,
            id: item.id,
            isBackToDefault: isBackToDefault,
          },
        }"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          mdi-square-edit-outline
        </VIcon>
        <VListItemTitle>Edit</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="$isUserAdmin && $viewProgramAsAdmin && !previewOnly && fromProgram"
        @click="$bus.$emit('renameSectionItem', 'Lesson', item)"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          mdi-rename-box
        </VIcon>
        <VListItemTitle>Rename</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="($isUserAdmin || $isUserMentor || !$viewProgramAsStudent) && !previewOnly"
        @click="$emit('viewSubmitted', item)"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          list_alt
        </VIcon>
        <VListItemTitle>View Submitted</VListItemTitle>
      </VListItem>
      <VListItem
        v-if="$isUserAdmin && $viewProgramAsAdmin && !previewOnly"
        @click.prevent="$emit('duplicateItem', item)"
      >
        <VIcon
          size="20px"
          class="pr-2"
        >
          content_copy
        </VIcon>
        <VListItemTitle>Duplicate</VListItemTitle>
      </VListItem>
      <slot name="extra-menu" />
      <VListItem
        v-if="hasAccess && !previewOnly"
        @click="$emit('deleteItem', item)"
      >
        <VIcon
          size="20px"
          color="error"
          class="pr-2"
        >
          mdi-delete
        </VIcon>
        <VListItemTitle style="color: #ff5252;">
          Delete Forever
        </VListItemTitle>
      </VListItem>
    </VList>
  </div>
</template>
<script>
import SupportsModelTypes from "@/mixins/SupportsModelTypes";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
    isBackToDefault: {
      type: Boolean,
      default: false,
    },
    fromProgram: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SupportsModelTypes],
  data() {
    return {
      modelType: "lesson",
    };
  },
  computed: {
    hasAccess() {
      return (this.$isUserAdmin && this.$viewProgramAsAdmin) || (this.belongsToMe(this.item.user_id) && this.checkMentorPermission("lesson"));
    },
  },
};
</script>
