<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="save-evaluated-pipeline"
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow
      row
      wrap
      align-start
      class="fill-height"
    >
      <SaveHeader
        disable-default-actions
        :owner="this"
        :mode="mode"
      >
        <template #title>
          Submit {{ featureName("Application").singularize() }}
          {{ featureName("Evaluation") }}
        </template>
      </SaveHeader>
      <VCol
        cols="12"
        style="height: 64px"
      >
        <h1
          v-if="submitted"
          class="title mb-2"
        >
          Submitting evaluation for
          <UserModal
            :title="realSubmitted.title"
            :new-messages-count="realSubmitted.new_messages_for_user_count"
            :members="(realSubmitted.reviewers || []).map((u) => u.id)"
            :entity="realSubmitted"
            :thread="realSubmitted.thread_for_user"
            :user="submitted.user"
            :enable-edit="$isUserAdmin"
            :enable-chat="teamSetting('content.is_chat_enabled', true)"
          />
        </h1>
      </VCol>
      <VCol
        cols="12"
        style="height: calc(100% - 128px); overflow-y: auto"
      >
        <VCard class="mt-2 mb-2 mt-0 grey lighten-3">
          <VAlert
            v-if="
              !form.metadata || (form.metadata instanceof Array && !form.metadata.length)
            "
            type="warning"
            :value="true"
          >
            Looks like this form doesn't have fields. Please update the evaluation fields
            for this evaluation form.
          </VAlert>
          <VCardText v-else>
            <p
              v-if="form.description"
              v-html="form.description"
            />
            <DynamicInputsSubmit
              v-model="form.metadata"
              enable-default-values
              :card="false"
              :input-name="`${featureName('Pipeline')} ${featureName(
                'Stage',
                'singularize'
              )}`"
              :default-value-options="options.default_metadata_value_options"
              @new:input="onNewMetadata"
              @update:input="onUpdateMetadata"
              @update:inputs="onUpdateMultipleMetadata"
              @remove:input="onRemoveMetadata"
              @remove:inputs="onRemoveMultipleMetadata"
            />
          </VCardText>
        </VCard>
      </VCol>
      <VCol class="mx-3 sticky sticky--bottom">
        <VRow
          row
          wrap
          justify-end
        >
          <VBtn
            :class="{
              wethrive: !form.id,
              info: form.id > 0,
              'mr-3': true,
            }"
            :disabled="!isFormReady || isFormInputsDisabled"
            :loading="activity.isFormLoading"
            @click="onSave"
          >
            Save
          </VBtn>
          <VBtn
            class="wethrive-alt mr-3"
            :disabled="!isFormReady || isFormInputsDisabled"
            :loading="activity.isFormLoading"
            @click="onSubmit"
          >
            {{ form.is_submitted ? "Re-" : "" }} Submit
          </VBtn>
          <VBtn
            v-if="formHasId && deliverable.allow_multiple_submissions"
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSaveAndReset"
          >
            Submit New
          </VBtn>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import MetadataMixin from "@/mixins/Metadata";
import FormMixin from "@/mixins/Form";
import SavesSubmittedDataMixin from "@/mixins/SavesSubmittedData";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  between,
} from "vuelidate/lib/validators";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
import SaveHeader from "../../Elements/Navigation/SaveHeader";

export default {
  name: "PipelinesSubmitEvaluationForm",
  components: {
    DynamicInputsSubmit,
    SaveHeader,
  },
  mixins: [MetadataMixin, FormMixin, SavesSubmittedDataMixin, SupportsPipelines],
  props: {
    title: {
      type: String,
      default: null,
    },
    submitted: {
      type: Object,
      default: () => {},
    },
    content: {
      type: Object,
      default: () => {},
    },
    contentType: {
      type: String,
      default: "pipeline",
    },
    existing: {
      type: Object,
      default: () => {},
    },
    validator: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      resolvedApplication: null,
      resolvedPipelineKey: "content",
      modelType: "PipelineEvaluation",
      defaultForm: {
        user_id: null,
        is_submitted: false,
        metadata: [],
      },
      saveOnly: [
        "id",
        "metadata",
        "deliverable_id",
        "user_id",
        "is_submitted",
        "entity_type",
        "entity_id",
      ],
    };
  },
  validations() {
    return {
      form: {
        metadata: {
          $each: {
            value: {
              validUrl: (input) => {
                if (input instanceof Object && typeof input.type === "string") {
                  switch ((input.type || "").toLowerCase()) {
                    case "url":
                      const regexp = /^((https|http):\/\/)?([\w\d\-\_\:\@]+)\.(.*)/;
                      return !required(input.value) || regexp.test(input.value);
                      break;
                  }
                }
                return true;
              },
              required: requiredIf(this.metadataHasRequiredUrl),
              // max: (value, model) => {
              //   return parseInt((model.options || {}).maxLength) > 0 ? maxLength(model.options.maxLength)(value) : false;
              // }
            },
            url: {
              requiredIfUrl: requiredIf(this.metadataHasRequiredUrl),
            },
          },
        },
      },
    };
  },
  computed: {
    $v() {
      return this.validator instanceof Array ? this.validator : [];
    },
    application() {
      return this.submitted || this.resolvedApplication;
    },
    deliverable() {
      return this.realContent.evaluation_form || { metadata: [] };
    },
    realContent() {
      return this.content || this.stage || this.pipeline || {};
    },
    realSubmitted() {
      return this.contentType === "pipeline"
        ? this.submitted
        : this.submitted.pipeline_submitted;
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "deliverableSubmitted/getFormConfig",
      doSaveDeliverableSubmitted: "deliverableSubmitted/save",
      doGetDeliverable: "deliverable/getOne",
      doGetPipeline: "pipeline/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig({
        deliverable_id: this.submitted.evaluation.deliverable_id,
      });
    },
    onSave() {
      this.form.user_id = this.form.user_id || this.submitted.user_id;
      if (!this.form.deliverable_id) {
        this.form.deliverable_id = this.resolvedPipeline.evaluation_form.id;
      }
      this.form.entity_id = this.submitted.id;
      this.form.entity_type =
        this.contentType === "pipeline-stage"
          ? "App\\Models\\PipelineStageSubmitted"
          : "App\\Models\\PipelineSubmitted";
      return this.saveFormModel(this.doSaveDeliverableSubmitted).then((submitted) => {
        this.$emit("saved", submitted);
      });
    },
    onSubmit() {
      this.form.is_submitted = true;
      return this.onSave();
    },
    prepareNewSubmission() {
      const existing =
        this.existing instanceof Object && this.existing.id
          ? this.existing
          : this.submitted.evaluation || {};
      if (existing.id) {
        this.form = this.existing;
        // Commented below code to fix the metadata duplicate issue
        /* this.form.metadata = this.uniqueMetadata(
          this.form.metadata.concat(this.deliverable.metadata)
        ); */
      } else if (this.deliverable instanceof Object && this.deliverable.id) {
        this.form.deliverable_id = this.deliverable.id;
        // this.form.entity_id = result.id;
        this.form.user_id = this.submitted.user_id;
        this.form.metadata = this.getMetadataForSubmit(this.deliverable.metadata) || [];
      }
      this.emitLoadingDone();
      this.emitReady();
    },
  },
  mounted() {
    this.prepareNewSubmission();
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }
  .v-expansion-panel-header {
    padding: 0px 12px;

    .v-label {
      top: 0px;
    }
  }
}
</style>
