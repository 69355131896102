var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","type":"card-heading,card,actions","loading":_vm.activity.isFirstLoad}},[_c('VRow',[_c('SaveHeader',{attrs:{"disable-default-actions":"","owner":this,"mode":_vm.mode,"go-back":() => _vm.navigateTo({ name: 'deliverable.index' })},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Submit "+_vm._s(_vm.featureName("Deliverables"))),(_vm.realDeliverable)?[_vm._v(" for "+_vm._s(_vm.realDeliverable.title)+" ")]:_vm._e()]},proxy:true}])}),_c('VCol',{staticStyle:{"flex":"1 1 auto","max-height":"calc(100% - 70px)","overflow-y":"auto"}},[_c('VCard',[_c('VCardTitle',{ref:"cardTitle",staticClass:"px-2",staticStyle:{"height":"100% !important","border":"none !important"}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.previewMode ? "Preview: " : "")+" "+_vm._s(_vm.realDeliverable.title)+" ")]),_c('VSpacer'),(_vm.copyableLink)?_c('Linkable',{attrs:{"height":"40px","min-width":"auto","small":false,"text-button":false,"icon-button":false,"expand-on-hover":false,"button-style":{ 
              padding: '0 8px',
              backgroundColor: 'rgba(251, 152, 14, 0.2)',
              boxShadow: 'none',
              marginRight: '12px',
            },"icon-props":{ color: '#fb980e'},"url":_vm.copyableLink}}):_vm._e(),(_vm.mode !== 'standalone')?_c('VBtn',{staticClass:"px-2 no-shadow",attrs:{"height":"40px","min-width":"auto","color":"#ececf1"},on:{"click":function($event){return _vm.$emit('toggle:dialog')}}},[_c('VIcon',[_vm._v(" mdi-close-circle-outline ")])],1):_vm._e()],1),_c('VCardText',{ref:"cardText",staticStyle:{"overflow":"hidden auto"}},[_c('VRow',{attrs:{"row":"","wrap":""}},[(_vm.realDeliverable.content && _vm.realDeliverable.content !== '<p></p>')?_c('VCol',{attrs:{"cols":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.realDeliverable.content)}})]):_vm._e(),(_vm.hasMessagesOrErrors)?_c('VCol',{attrs:{"cols":"12"}},[_c('Alerts',{attrs:{"messages":_vm.messages,"errors":_vm.errors}})],1):_vm._e(),((_vm.$isUserAdmin || _vm.submitForUserMode) && !_vm.previewMode)?_c('VCol',{attrs:{"cols":"12"}},[_c('Autocomplete',{attrs:{"hide-details":"","hide-no-data-msg":"","item-text":"name","item-value":"id","append-inner-icon":"arrow-down","placeholder":"Find user","label":"Select User","loading":_vm.activity.isLoading,"items":_vm.options.users,"filter-options":{
                  type: 'user',
                },"getter":_vm.doGetUsers,"error":_vm.$v.form.user_id.$error},on:{"input":function($event){return _vm.$v.form.user_id.$touch()},"blur":function($event){return _vm.$v.$touch()}},model:{value:(_vm.form.user_id),callback:function ($$v) {_vm.$set(_vm.form, "user_id", $$v)},expression:"form.user_id"}})],1):_vm._e(),(_vm.form.files && _vm.form.files.length)?_c('VCol',{attrs:{"cols":"12"}},[_c('FilesList',{attrs:{"readonly":true,"files":_vm.form.files}})],1):_vm._e(),(_vm.form.metadata instanceof Array && _vm.form.metadata.length)?_c('VCol',{staticClass:"py-0"},[_c('DynamicInputsSubmit',{attrs:{"container-class":"dynamic-input-container px-2","item-title-class":"d-flex subtitle-2","required-text":"Required","card":false,"loading":_vm.activity.isLoading,"metadata-sections":_vm.form.metadata_sections,"disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"input-name":_vm.featureName('Deliverable'),"validator":_vm.$v.form.metadata},on:{"validated":function($event){return _vm.$v.form.$touch()},"update:input":_vm.onUpdateMetadata},model:{value:(_vm.form.metadata),callback:function ($$v) {_vm.$set(_vm.form, "metadata", $$v)},expression:"form.metadata"}})],1):_vm._e()],1)],1)],1)],1),(!_vm.previewMode)?_c('VCol',{staticClass:"mx-3 sticky sticky--bottom"},[_c('VRow',{attrs:{"row":"","wrap":"","justify-end":""}},[(
            _vm.formHasId &&
              (_vm.submitForUserMode ||
                _vm.realDeliverable.allow_multiple_submissions)
          )?_c('VBtn',{staticClass:"mr-3",staticStyle:{"box-shadow":"0 1.5px 3px 0 rgba(250, 36, 111, 0.25)"},attrs:{"color":"#60be39","disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"loading":_vm.activity.isFormLoading},on:{"click":_vm.onSaveAndReset}},[_c('span',{staticClass:"white--text"},[_vm._v("Submit New")])]):_vm._e(),_c('VBtn',{staticClass:"mr-3",staticStyle:{"box-shadow":"0 1.5px 3px 0 rgba(250, 36, 111, 0.25)"},attrs:{"color":"#60be39","disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"loading":_vm.activity.isFormLoading},on:{"click":_vm.onSubmit}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.form.is_submitted ? "Re-" : "")+" Submit")])]),_c('VBtn',{staticClass:"mr-3",staticStyle:{"box-shadow":"0 1.5px 3px 0 rgba(250, 36, 111, 0.25)"},attrs:{"color":"#60be39","disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"loading":_vm.activity.isFormLoading},on:{"click":_vm.onSave}},[_c('span',{staticClass:"white--text"},[_vm._v("Save")])])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }