<template>
  <Badge
    overlap
    top
    color="#ff2b7a"
    class="black--text"
    :value="hasNotifications"
    :content="notificationCount"
  >
    <VBtn
      text
      icon
      width="40px"
      height="40px"
      class="wethrive-notification"
      color="white"
      @click.stop="$emit('toggle', true)"
    >
      <VIcon
        medium
        v-if="hasNotifications"
      >
        notifications
      </VIcon>
      <VIcon v-else>
        notifications_none
      </VIcon>
    </VBtn>
    <span
      v-if="hasNotifications"
      slot="badge"
    >{{ notificationCount }}</span>
  </Badge>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    lastChecked: {
      type: String,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timestamp: null,
      eventName: "FeedNotificationWasReceived",
      subscribedChannels: [],
    };
  },
  computed: {
    /**
     * TODO: Figure out why stte is not being updated by getters
     */
    ...mapGetters({
      notifications: "user/notifications",
      allPrograms: "program/data",
      allLessons: "lesson/data",
      allDeliverables: "deliverable/data",
      allContent: "content/data",
      allFaqs: "faq/data",
    }),
    realPusher: function () {
      return this.$echo || this.$parent.pusher;
    },

    /**
     * Determine if the user has any notifications.
     */
    hasNotifications() {
      return this.$user.notification_announcement_count > 0;
    },
    notificationCount() {
      const count = this.$user.notification_announcement_count;
      return count > 9 ? "9+" : parseInt(count);
    },
  },
  created() {
    this.$log.debug("Notification: Mounted component...");
    this.initChannels();
    // this.getNewFeedNotifications();
    this.timestamp = this.lastChecked;
  },
  methods: {
    ...mapActions({
      addNotification: "user/addNotification",
    }),
    initChannelsPusher() {
      this.$log.debug("Notification: Initing channels: ");
      this.$log.debug(this.$parent.channels);
      this.$parent.channels.forEach((channel) => {
        this.$log.debug(
          "Notification: Pusher subscribing to [" + channel + "]"
        );
        let monitor = this.realPusher.subscribe(channel);
        //Bind a function to a Event (the full Laravel class)
        monitor.bind(this.eventName, (data) => {
          this.onAddFeedNotification(data.notification);
        });
      });
    },
    //This uses Laravel Echo
    initChannels() {
      this.$log.debug("Notification: Initing channels: ", this.channels);
      this.channels.forEach((channel) => {
        this.$log.debug(
          "Notification: Pusher subscribing to [private-" + channel + "]"
        );
        let monitor = this.realPusher
          .private(channel)
          .listen(this.eventName, (data) => {
            this.$log.debug("Notification: Received from pusher", data);
            this.onAddFeedNotification(data.message);
            this.updatePendingDuplication(data.message);
          });
        this.subscribedChannels.push(monitor);
      });
    },
    onAddFeedNotification(notification) {
      this.$log.debug("Notification: Adding new notification", notification);
      this.$user.notification_announcement_count++;
    },
    updatePendingDuplication(notification) {
      this.$log.debug("Notification: Updating item loading status", notification, this.allPrograms, this.allLessons, this.allContent, this.allDeliverables, this.allFaqs);
      const itemDetails = notification.detail;
      const itemModuleName = notification.event ? (notification.event.split(".")[0]).pluralize() : null;
      const moduleName = itemModuleName == "primarycontents" ? "content" : itemModuleName;
      if(moduleName) {
        if(notification.event == "template.import.completed") {
          // For program/hub template duplication
          const programIndex = this.allPrograms.data.findIndex(program => program.id === itemDetails.program_id);
          this.allPrograms.data[programIndex].is_duplication_complete = true;
        } else if(itemDetails.hasOwnProperty("program_id")) {
          // Content duplication within a program/hub
          this.$bus.$emit("notificationForDuplications", moduleName, itemDetails);
        } else {
          // Content duplication (GLOBAL)
          const itemIndex = this[`all${moduleName.capitalize()}`].data.findIndex(item => item.identifier === itemDetails.identifier);
          this[`all${moduleName.capitalize()}`].data[itemIndex].id = itemDetails.model_id;
          this[`all${moduleName.capitalize()}`].data[itemIndex].isLoading = false;
          this[`all${moduleName.capitalize()}`].data[itemIndex].processingDuplicate = false;
          this.removeGroupItemLocalStorage("pending-duplications", moduleName.singularize(), itemDetails.identifier);
        }
      }
    },
  },
};
</script>
<style>
.dialogs {
  max-height: 70vh;
  overflow: auto;
}

.dialogs a {
  font-size: 13px !important;
}

.dialogs .dialog_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_meta .dialog_date {
  flex: 0 1 128px;
  text-align: right;
}

:root .dialogs .dialog_author-name {
  flex: auto;
  transition: font-weight 50ms linear;
}

:root .dialogs .dialog_author-name:hover {
  font-weight: bolder;
}
</style>