<template>
  <section class="recover-form">
    <div id="recover-form-header">
      <h3 class="text-upper" />
    </div>
    <VCard class="content-bg">
      <VCardTitle id="form-header">
        <h3>Recover Password</h3>
      </VCardTitle>
      <VCardText>
        <Alerts
          message-color="info"
          error-color="info"
          :messages="messages"
          :errors="errors"
        />
        <form
          v-if="!recoverySent"
          class="form"
          name="recover"
          @submit.prevent="recover"
        >
          <TextField
            v-model="form.email"
            label="Email"
            name="email"
            type="text"
            :error="$v.form.email.$error"
            @keyup.enter="recover"
          />
          <VBtn
            class="wethrive"
            :disabled="!isFormReady"
            @click.prevent="recover"
          >
            {{ activity.isFormLoading ? 'Verifying...' : 'Recover' }}
          </VBtn>
        </form>
        <VAlert
          v-else
          color="info"
          :value="recoverySent"
        >
          <p v-html="message" />
        </VAlert>
      </VCardText>
    </VCard>
    <VRow
      align-center
      id="recover-form-footer"
      class="mx-3"
    >
      <VCol id="reset">
        <VRow
          align-center
          justify-start
        >
          <router-link to="/login">
            Have an account? Login Here!
          </router-link>
        </VRow>
      </VCol>
      <VSpacer />
      <VCol id="badge">
        <VRow
          align-center
          justify-end
        >
          <span>Powered by&nbsp;</span>
          <span>
            <img
              style="height: 26px;"
              :src="WeThriveLogo"
            >
          </span>
        </VRow>
      </VCol>
    </VRow>
  </section>
</template>

<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import { AUTH_RECOVER, AUTH_CSRF } from "@/store/actionTypes";
import { email, required, minLength, between } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";

export default {
  name: "RecoverForm",
  mixins: [FormMixin],
  props: {},
  data() {
    return {
      modelType: "password",
      WeThriveLogo,
      isLoading: false,
      error: false,
      message: "",
      recoverySent: false,
      csrfToken: "",
      csrfCookie: "",
      form: {
        password: "",
        email: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      doRecover: "auth/recover",
      getSCSRF: "auth/csrf",
    }),
    async recover() {
      if(!this.isFormReady) {
        return false;
      }
      const { email = this.form.email } = this;
      this.isLoading = true;
      this.isLoading = true;
      await this.saveFormModel(() => {
        return this.doRecover({
          email: email.toLowerCase(),
        }).then((response) => {
          /**
           * Get the user and log them in
           * TODO: Need to add custom logic here to determine if the user is properly setup
           * Possibly need a flag on the user account or a check on the org info
           */
          this.$log.debug("RecoverForm.recover: Sent");
          this.recoverySent = true;
          const result = {
            data: {
              data: {},
              message: `If you have an account then an email
                    will be sent to <strong>${this.form.email}</strong> to recover your login information`,
            },
          };
          // this.$bus.$emit('notificationMessage', result.data.message);
          window.localStorage.setItem("reset-email", this.form.email);
          this.message = result.data.message;
          return result;
        });
      }).catch((error) => {});
    },
    getCookie(name, _source) {
      const source = _source || document.cookie;
      function escape(s) {
        return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, "\\$1");
      }
      var match = source.match(
        RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
      );
      return match ? match[1] : null;
    },
    getCsrfToken() {
      this.getSCSRF().then((response) => {
        this.csrfToken = response.data;
        this.csrfCookie = this.getCookie("XSRF-TOKEN");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.recover-form {
  margin: auto;

  #recover-form-header h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  #circle {
    display: inline-block;
    background-color: #9bf2dc;
    border-radius: 50%;
    line-height: 5rem;
    height: 5rem;
    width: 5rem;
    text-align: center;
  }

  .form {
    background-color: $white-color;
    padding: 1rem;

    #form-header {
      border-bottom: 1px solid $border-color;
      margin: 0 -1rem 1rem -1rem;

      h3 {
        color: $content-title-color;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    label {
      font-size: 0.8rem;
    }

    span input {
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $border-color;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    button {
      border: 1px;
      box-shadow: none;
      width: 70%;
      margin-left: 15%;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      color: white;
    }

    #amnesia {
      text-align: right;
      font-size: 0.8rem;

      a {
        color: $font-light-color;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  #recover-form-footer {
    margin-top: 20px;
    font-size: 0.8rem;

    #amnesia a,
    #reset a {
      color: $font-light-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }

    #badge {
      text-align: right;

      span {
        display: inline-block;
      }
    }
  }
}
</style>