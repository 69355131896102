import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Assessment Results API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	saveCard(params) {
		let { assessment_result_id, assessment_card_id, id } = params;
		if (!assessment_result_id) {
			return Promise.reject("Missing result");
		}
		if (!assessment_card_id) {
			return Promise.reject("Missing card");
		}
		return super.save(params, {
			getter: "saveCard",
		});
	}

	complete(id) {
		this.log().info("[Services: Assessment Result]: Complete Assessment", id);
		return this.api()
			.put(this.getUrl("complete", "complete", id))
			.catch((error) => {
				this.log().error(
					"[Services: Assessment Result: Complete Assessment]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Assessment Results",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			return `assessments/${assessment_id}/result`;
		},
		getAll: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			return `assessments/${assessment_id}/result`;
		},
		saveCard: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			// If there's an id we're updating the result so ignore the assessment id
			const current_card_id = params.id ? null : params.current_card_id;
			return `assessments/${assessment_id}/result/${
				params.assessment_result_id
			}/card${current_card_id ? "/" + current_card_id : ""}`;
		},
		complete: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			return `assessments/${assessment_id}/result/${params.assessment_result_id}/complete`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			return `assessments/${assessment_id}/result`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id || params.entity_id;
			return `assessments/${assessment_id}/result`;
		},
		default: "assessments/result",
	},
});

export default api;
