<template>
  <VContainer
    fluid
    class="px-0 pb-0"
  >
    <VBanner
      text
      class="slightly-rounded"
      :value="hasSubmittedApplication"
      :color="`${applicationAlertType} accent-1`"
    >
      <template #icon>
        <VIcon x-large>
          {{ applicationAlertIcon }}
        </VIcon>
      </template>
      <VRow
        row
        space-between
        align-center
      >
        <VCol py-0>
          <h1 class="headline d-flex justify-space-between">
            <div class="grow">
              Hi {{ $user.first_name || 'there' }}!
            </div>
            <div class="shrink">
              <VBtn
                depressed
                small
                class="ml-2 slightly-rounded"
                @click="navigateTo({
                  name: 'account',
                  hash: '#applications'
                })"
              >
                View Your Applications
              </VBtn>
            </div>
          </h1>
        </VCol>
        <VCol
          v-if="teamSetting('pipeline.is_chat_enabled', true)"
          shrink
          py-0
        >
          <VBtn
            class="slightly-rounded"
            color="primary"
            :icon="$vuetify.breakpoint.smAndDown"
            @click="onToggleApplicantChat()"
          >
            <VIcon>chat</VIcon>
            <span v-show="$vuetify.breakpoint.mdAndUp">&nbsp;Chat Now</span>
          </VBtn>
        </VCol>
      </VRow>
      <div v-if="!isApplicationExpired">
        <p v-show="hasApplicationDeadline && !isApplicationSubmitted.is_submitted">
          Your application deadline is:
          <strong>{{ currentStageDeadline }}</strong>.
          <!-- <template v-if="!isApplicationExpired">
              That's
              <VChip>{{ $timezone.moment(currentStageDeadline).fromNow() }}</VChip>.
            </template>-->
        </p>
        <template v-if="currentApplicationStageForUser.is_submitted">
          Your information has been submitted.
          <template v-if="hasCurrentStageDeadline && teamSetting('pipeline.is_application_editable_until_deadline', true)">
            Feel free to edit up until:
            <strong large>{{ currentStageDeadline }}</strong>
          </template>
        </template>
        <template v-else>
          Your application hasn't been submitted.
          <template v-if="currentStageDeadline || applicationDeadline">
            You can
            <template v-if="teamSetting('pipeline.is_application_editable_until_deadline', true)">
              edit and
            </template> submit your information at any time before the deadline:
            <strong large>{{ currentStageDeadline }}</strong>
          </template>
        </template>
      </div>
      <div v-else>
        The deadline has passed.
      </div>
      <div
        align-center
        class="d-flex mt-4"
      >
        <VChip>{{ featureName('Stage', 'singularize') }}: {{ currentApplicationStage.title || "Onboarding" }}</VChip>
        <VSpacer />
        <VChip
          color="success"
          text-color="white"
          v-if="currentApplicationStageForUser.is_submitted"
        >
          <VIcon
            small
            class="mr-2"
          >
            check
          </VIcon>Submitted
        </VChip>
      </div>
      <template #actions>
        <template v-if="teamSetting('pipeline.is_application_editable_until_deadline', true) || !currentApplicationStageForUser.is_submitted">
          <Tooltip
            v-if="!isApplicationExpired && currentApplicationStageHasMissingMetadata"
            depressed
            small-icon
            color="error"
            text-color="white"
            button-text="Missing Information"
            button-class="normal-text px-1"
            icon="edit"
            :flat="false"
            :value="true"
            :title="`<strong>${currentApplicationStageForUser.missing_metadata_count}</strong> missing fields`"
            :action="() => onToggleApplicationForm()"
          />
          <VBtn
            v-else-if="!isApplicationExpired"
            depressed
            color="info"
            @click="onToggleApplicationForm"
          >
            <VIcon small>
              edit
            </VIcon>&nbsp;Update your application
          </VBtn>
        </template>
      </template>
    </VBanner>
    <StageSubmit
      v-if="hasSubmittedApplication && dialogs.applicationForm"
      mode="dialog"
      ref="submitPipeline"
      :is-dialog-open="dialogs.applicationForm"
      :id="{
        id: currentApplicationStageForUser.id,
        pipeline_id: currentApplicationStage.pipeline_id,
        pipeline_stage_id: submittedApplication.current_stage_id
      }"
      :pipeline="(submittedApplication || {}).pipeline"
      :application="submittedApplication"
      :existing="currentApplicationStageForUser"
      :stage="currentApplicationStage"
      :stage-index="currentApplicationStageIndex"
      :deadline="currentStageDeadline"
      is-inputs-required
      @toggle:dialog="() => dialogs.applicationForm = false"
      @input="submitted => $set(submittedApplication, 'current_stage_for_user', submitted)"
    />
    <MessagesDialog
      v-if="hasSubmittedApplication"
      max-width="640px"
      entity-type="pipeline-submitted"
      :is-dialog-open="dialogs.applicantChat"
      :title="submittedApplicationThreadTitle"
      :members="(submittedApplication.reviewers || []).map(u => u.id)"
      :entity="submittedApplication"
      :thread="submittedApplication.thread_for_user"
      :user="submittedApplication.user"
      dialog-hide-overlay
      @toggle:dialog="onToggleApplicantChat"
      @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
    />
  </VContainer>
</template>
<script>
import MessagesDialog from "@/components/Chat/MessagesDialog";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import StageSubmit from "@/components/Organization/Pipelines/StageSubmit";
import { mapActions } from "vuex";
export default {
  name: "PipelineApplication",
  components: {
    StageSubmit,
    MessagesDialog
  },
  mixins: [HasApplicationPipelinesMixin],
  methods: {
    ...mapActions({
      doGetApplication: "pipelineSubmitted/getOne"
    })
  },
  created() {
    this.doGetApplication({
      id: "current",
      pipeline_id: "application"
    })
      .then(application => {
        this.currentApplicationModel = application;
      })
      .catch(errror => {
        this.$log.debug("No applications");
      });
  }
};
</script>
