<template>
  <VRow
    row
    wrap
    justify-center
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol 
      justify-content-center
      cols="12"
    >
      <TextField
        v-model="form.name"
        placeholder="(Optional) Title"
        class="textarea-field"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <Editor
        placeholder="Enter your text here"
        class="lesson-editor"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
        v-model="form.value"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";

export default {
  name: "SaveCardText",
  mixins: [SaveCardMixin],
  data() {
    return {
      defaultForm: {
        name: "",
        value: ""
      }
    };
  }
};
</script>

<style lang="scss">
.lesson-editor {
  margin: 0px !important;

  .editor__content > div {
    border: solid thin #ddd;
    height: 200px;
    border-radius: 5px;
    background-color: #e6e6e6 !important;
    color: black;
  }
  .menubar {
    margin-left: -4px !important;
    margin-bottom: 0px !important;
  }
}
</style>
