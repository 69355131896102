<template>
  <VContainer
    fluid
    grid-list-lg
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader>
          <!-- <template v-slot:extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
              :to="getRouteForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
            />
          </template> -->
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <VCardTitle style="height: auto;">
                <h2 class="display-1 text-break">
                  {{ model.title }}
                </h2>
              </VCardTitle>
              <VCardText>
                <h3 v-if="model.city">
                  {{ model.city.name }}
                </h3>
                <p
                  class="formatted"
                  v-html="model.content"
                  v-linkified
                />
                <FilesList
                  :readonly="true"
                  :files="model.files"
                />
              </VCardText>
            </VCard>
            <DynamicInputsView
              :card="false"
              :readonly="true"
              :inputs="model.metadata"
            />
          </VCol>
          <VCol
            cols="12"
            class="ml-2"
          >
            <AssociatedContent :model="model" />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";

export default {
  name: "ContentView",
  components: { DynamicInputsView, AssociatedContent, ViewHeader },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Content",
    };
  },
  methods: {
    ...mapActions({
      doGetContent: "content/getOne",
    }),
    onGet(id) {
      return this.doGetContent(id);
    },
  },
};
</script>

