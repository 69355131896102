<script>
export default {
  data() {
    return {
      dueOnIsDate: true,
      hasDueOnDate: false,
    };
  },
  methods: {
    getDue(item) {
      if (!item.due_on && !item.due_in) {
        return "(not set)";
      }

      if (item.due_in) {
        return item.due_in + " " + item.due_in_unit + " after";
      }

      return item.due_on
        ? this.$timezone.formatDateTime(item.due_on, "DD/MM/YYYY")
        : "(not set)";
    },
    prepareDueOn() {
      this.setDueOn(this.form);
      this.form.due_on = this.hasDueOnDate ? this.form.due_on : null;
      this.form.due_in = this.hasDueOnDate ? parseInt(this.form.due_in) : null;
      this.form.due_in = isNaN(this.form.due_in) ? null : this.form.due_in;
      this.form.due_in_unit = this.hasDueOnDate ? this.form.due_in_unit : null;
      this.form.due_in_unit =
        this.form.due_in && !this.form.due_in_unit
          ? "Day"
          : this.form.due_in_unit;
    },
    setDueOn(model) {
      this.dueOnIsDate = model && model.due_on !== null;
      this.hasDueOnDate = this.dueOnIsDate || model.due_in !== null;
    },
    getDueDay(item) {
      if (!item.due_on && !item.due_in) {
        return "(not set)";
      }

      return item.due_on ? this.$timezone.formatDay(item.due_on) : "(not set)";
    },
  },
};
</script>
<style>
.due-on-color {
  color: #f5ba2b;
}
</style>