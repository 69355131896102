<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card-heading,table-heading,table-tbody"
    :loading="activity.isFirstLoad"
  >
    <VRow
      column
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <ViewHeader :route-params="realContent.id">
          <template #title>
            Completed {{ featureName('Content') }}
            <template v-if="realContent && realContent.id">
              for {{ realContent.title || '(not set)' }}
            </template>
          </template>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="visibility"
              :tooltip="`View ${featureName('Content').singularize()}`"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`content.view`, {content: realContent.id})"
              :to="getRouteForItem(`content.view`, {content: realContent.id})"
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <DataViewUI
          hide-item-actions
          :data="data"
          :headers="submittedHeaders"
          :is-loading="activity.isLoading"
          @paginate="onGetMore"
          @search="onSearch"
        >
          <template
            slot="items-cells"
            slot-scope="props"
          >
            <td><UserChip :user="props.item.causer" /></td>
            <td>
              <strong>{{ featureName('Content') }}</strong>:
              <i>{{ props.item.subject.title }}</i>
            </td>
            <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
            <td>{{ props.item.causer ? "Yes" : 'No' }}</td>
          </template>
        </DataViewUI>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  props: {
    contentId: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
  },
  components: {
    ViewHeader,
  },
  mixins: [SearchMixin, HasSubmittedDataMixin],
  data() {
    return {
      content: null,
      modelType: "contentCompleted",
    };
  },
  computed: {
    realContent() {
      return this.content;
    },
    submittedHeaders() {
      let value = [];
      value = [
        {
          text: "Read By",
          align: "left",
          sortable: true,
          value: "causer.name",
        },
        {
          text: this.featureName("Content"),
          align: "left",
          sortable: true,
          value: "subject.title",
        },
        {
          text: "Read On",
          align: "left",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Read",
          align: "left",
          sortable: true,
        },
      ];
      return value;
    },
  },
  created() {
    if(this.$isUserMentor && !this.checkMentorPermission("content")) {
      this.$router.push({ name: "program.index" });
    }
    let contentId =
      this.$route.params.content ||
      this.$route.params.id ||
      this.$route.query.content_id ||
      this.contentId;
    if(this.isFromExistingData) {
      contentId = this.contentId;
    }
    if (contentId) {
      this.filter.filter = { content: [contentId] };
    }
    this.doGetContent(contentId).then((content) => {
      this.content = content;
    });
  },
  methods: {
    ...mapActions({
      doGetAll: "contentCompleted/getAll",
      doGetContent: "content/getOne",
    }),
    onGetAll(_params) {
      const params = _params || {};
      const contentId =
        this.$route.params.content ||
        this.$route.params.id ||
        this.$route.query.content_id ||
        this.contentId;
      params.id = this.isFromExistingData ? this.contentId : contentId;
      return this.doGetAll(params);
    },
  },
};
</script>