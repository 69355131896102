var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasUser)?_c('VMenu',{staticClass:"pr-1",attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: slotOn }){return [_vm._t("default",function(){return [_c('VChip',_vm._g({class:`mr-${_vm.spacing} mb-${_vm.spacing}`,attrs:{"pill":"","outlined":_vm.outlined,"color":_vm.color,"text-color":_vm.textColor,"x-small":_vm.xSmall,"small":_vm.small,"large":_vm.large,"x-large":_vm.xLarge,"input-value":_vm.selected,"dark":_vm.dark,"light":_vm.light,"disabled":_vm.disabled}},!_vm.disabled ? Object.assign(slotOn, _vm.activatorVOn) : {}),[_c('VAvatar',{style:({
            width: `${_vm.avatarSize} !important`,
            height: `${_vm.avatarSize} !important`,
            minWidth: `${_vm.avatarSize} !important`
          }),attrs:{"left":"","size":_vm.avatarSize}},[_c('VImg',{attrs:{"src":_vm.user.photo_url}})],1),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.user.name))]}),_vm._t("content-right"),(_vm.clearable)?_c('VIcon',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('clear')}}},[_vm._v(" clear ")]):_vm._e()],2)]},{"on":slotOn})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('UserViewDetails',{attrs:{"user":_vm.user,"user-details":_vm.userDetails}})],1):_c('VChip',{class:`mr-${_vm.spacing} mb-${_vm.spacing}`,staticStyle:{"text-decoration":"line-through","color":"#666666"},attrs:{"pill":"","color":"#e0e0e0","outlined":_vm.outlined,"text-color":_vm.textColor,"x-small":_vm.xSmall,"small":_vm.small,"large":_vm.large,"x-large":_vm.xLarge,"input-value":_vm.selected,"dark":_vm.dark,"light":_vm.light,"disabled":_vm.disabled}},[_c('VAvatar',{style:({
      width: `${_vm.avatarSize} !important`,
      height: `${_vm.avatarSize} !important`,
      minWidth: `${_vm.avatarSize} !important`
    }),attrs:{"left":"","size":_vm.avatarSize}},[_c('VImg',{attrs:{"src":"https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"}})],1),_vm._v(" Deleted User ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }