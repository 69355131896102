<template>
  <VContainer id="user-information">
    <VRow>
      <VCol>
        <VList
          v-if="accounts.length"
          two-line
        >
          <VListItem
            v-for="(account, key) in accounts"
            :key="key"
            style="height: auto; max-height: 140px"
          >
            <VListItemContent style="height: auto; max-height: 140px">
              <VListItemTitle>{{ account instanceof Object ? account.label : '(not set)' }}</VListItemTitle>
              <VListItemSubtitle
                v-if="!getIsAccountProperlyConnected(account)"
                style="white-space: normal; line-height: 18px; overflow: visible"
              >
                <VAlert
                  outlined
                  type="error"
                  :value="true"
                >
                  This account isn't properly connected. Please go
                  <a
                    :href="getAccountDisconnectLink(account)"
                    target="_blank"
                  >
                    <strong>here to remove</strong>&nbsp;
                    <VIcon small>open_in_new</VIcon>
                  </a>
                  &nbsp;
                  <strong>{{ $root.$appName }}</strong> from your
                  <strong>{{ account instanceof Object ? account.label : '(not set)' }}</strong> account then come back here to disconnect and finally reconnect.
                </VAlert>
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction style="min-width: 96px">
              <GoogleAuth
                v-if="account.slug === 'google'"
                :account="account"
                @connected="addAccount"
                @error="showError"
                @remove:account="removeAccount(account)"
              />
            </VListItemAction>
          </VListItem>
        </VList>
        <VRow
          v-else
          row
          fluid
          justify-center
          class="mt-4"
        >
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import { mapActions } from "vuex";
import GoogleAuth from "./../Auth/Google";
export default {
  name: "ConnectedAccounts",
  components: {
    GoogleAuth
  },
  data() {
    return {
      accounts: [],
      types: [
        {
          label: "Google",
          slug: "google"
        }
      ]
    };
  },
  created() {
    this.getConnectedAccounts().then(accounts => {
      this.accounts = [...accounts, ...this.types]
        .filter(account => account instanceof Object)
        .unique(type => type.slug);
    });
  },
  methods: {
    ...mapActions({
      getConnectedAccounts: "user/getConnectedAccounts",
      deleteConnectedAccount: "user/deleteConnectedAccount"
    }),
    addAccount(account) {
      this.accounts = [...[account], ...this.types].unique(type =>
        type instanceof Object ? type.slug : 1
      );
      this.$bus.$emit("notificationMessage", "Successfully connected account");
    },
    showError(message) {
      this.$bus.$emit(
        "notificationError",
        "Unable to connect account: " + message
      );
    },
    removeAccount(account) {
      this.deleteConnectedAccount(account).then(result => {
        const type = this.types.find(type => type.slug === account.slug);
        const index = this.accounts.find(current => current.id === account.id);
        this.$log.debug("Removing", type, index);
        this.accounts.splice(index, 1, type);
        this.$bus.$emit("notificationMessage", "Successfully disconnected account");
      });
    },
    getIsAccountProperlyConnected(account) {
      switch (account.slug) {
        case "google":
          return account.token
            ? account.token.expires_in != null ||
                account.token.expires_in !== undefined
            : true;
          break;

        default:
          break;
      }
    },
    getAccountDisconnectLink(account) {
      switch (account.slug) {
        case "google":
          return "https://myaccount.google.com/permissions?utm_source=google-account&utm_medium=web";
          break;

        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss">
#user-information {
  .v-list__tile {
    height: auto !important;
    max-height: 120px;
  }
}
</style>