import Vue from "vue";
import BaseStore from "@nitm/js-api-client-base/dist/store";

/**
 * We're using the vuex store module for this application
 */
// Store types
const TYPES = {
	STORE_SORT_RELATION: "STORE_SORT_RELATION",
};

class BaseVuexStore extends BaseStore.VuexStore {
	/**
	 *Creates an instance of BaseApi.
	 * @param {Object} props
	 * @memberof BaseApi
	 */
	constructor(_props) {
		const props = _props || {};
		super(props);
		// Add extra mutation type
		this.coreTypes = { ...this.coreTypes, ...TYPES };
	}

	/**
	 * Generate the mutations for the store
	 *
	 * @param {Object} mutations
	 * @param {Object} state
	 * @returns
	 */
	mutations(mutations, types, state) {
		const utils = this.utils;
		const extend = mutations instanceof Object ? mutations : {};
		const defaultMutations = {
			...{
				[this.coreTypes.STORE_SAVE](state, data) {
					// Only update if this is a new item
					if (data.context) {
						utils.updateStats(data.context, data.params.id ? 0 : 1, data.type);
					}
					utils.addToStateData(state, data.result.data || data.result);
					return data;
				},
				[this.coreTypes.STORE_UPDATE_STATS](state, data) {
					if (data.context) {
						utils.updateStats(state, data.value, data.type);
					}
				},
				[this.coreTypes.STORE_DELETE](state, data) {
					utils.removeFromStateData(state, data.params);
					if (data.context) {
						utils.updateStats(data.context, -1, data.type);
					}
				},
				[this.coreTypes.STORE_SORT_RELATION](state, data) {},
			},
			...extend,
		};
		return super.mutations(defaultMutations, types, state);
	}

	/**
	 * Generate the actions for the store
	 *
	 * @param {Object} actions
	 * @param {Object} state
	 * @returns
	 */
	actions(actions, type, state) {
		let result = super.actions(actions, type, state);
		const utils = this.utils;
		const api = result.api() || this.api();
		const log = result.log() || this.log() || Vue.$log;
		const extend = actions instanceof Object ? actions : {};
		result = {
			...result,
			...extend,
			...{
				sortRelation(context, params) {
					log.info("[Store: " + type + "]: Sort Relation", params);
					return api
						.sortRelation(params)
						.then((response) => {
							log.info(`[Store: ${type}]: Relation Sorted`, response);
							const data = ((response || {}).data || {}).data || {};
							context.commit(TYPES.STORE_SORT_RELATION, data);
							return data;
						})
						.catch((error) => {
							log.info(`[Store: ${type}]: Error Sorting Relation`, error);
							throw error;
						});
				},
			},
		};
		return result;
	}
}
const store = new BaseVuexStore();
store.setLogger(Vue.$log || store.log());
store.setApp(new Vue());
// Add a custom update stats method to utils
store.utils = {
	...store.utils,
	...{
		/**
		 * Update global statistics
		 * @param {Object} context
		 * @param {int} value
		 * @param {string} type
		 */
		updateStats(context, value, type) {
			const keys = [
				type
					.pluralize()
					.underscore()
					.toLowerCase(),
				type
					.singularize()
					.underscore()
					.toLowerCase(),
			];

			if (
				context.state &&
				context.state.config &&
				context.state.config.index &&
				context.state.config.index.stats
			) {
				Vue.$log.info(
					"Updating stats",
					value,
					keys,
					context.state.config.index.stats
				);

				keys.forEach((key) => {
					if (context.state.config.index.stats.hasOwnProperty(key)) {
						context.state.config.index.stats[key] += value;
					}
				});
			}
			if (type !== "team") {
				context.commit("team/STORE_UPDATE_STATS", arguments, {
					root: true,
				});
			}
		},
	},
};
export default store;
