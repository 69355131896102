var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{attrs:{"id":"save-card"}},[(_vm.card.type)?_c('VCardTitle',{class:{
      'mx-2 pl-5 pr-5': true,
      'red lighten-4': _vm.modelIsDirty
    },attrs:{"id":"save-card-title"}},[_c('VIcon',{staticClass:"pa-1 rounded",staticStyle:{"background-color":"#f8f8f8"},attrs:{"size":"20px"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.card.type)+" ")]),_c('VSpacer'),_c('span',{class:{
        'mx-2': true
      }},[(_vm.isSavingCard && !_vm.isSavingCardDone)?_c('VProgressCircular',{attrs:{"color":_vm.savingCardMessageColor,"indeterminate":"","size":10,"width":2}}):(_vm.modelIsDirty)?_c('VIcon',{attrs:{"color":"error","size":12}},[_vm._v(" error ")]):_c('VIcon',{attrs:{"color":"success","size":12}},[_vm._v(" check ")]),_vm._v("  "),_c('small',{class:`${_vm.savingCardMessageColor}--text`},[_vm._v(_vm._s(_vm.savingCardMessage))]),_vm._v("   ")],1),(_vm.isSavingCardError)?[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.savingCardMessage))])]:_vm._e(),(_vm.$isUserAdmin || _vm.$isUserMentor)?_c('VBtn',{attrs:{"depressed":"","height":"30px","color":"info","id":"preview-lesson","disabled":!_vm.canAddSlide},on:{"click":function($event){return _vm.preview(_vm.index)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" visibility ")]),_c('span',{staticClass:"pl-1",staticStyle:{"color":"#fff","opacity":"0.75","font-size":"10px"}},[_vm._v(" PREVIEW ")])],1):_vm._e()],2):_vm._e(),_c('VCardText',{staticClass:"py-0",attrs:{"fill-height":"","id":"save-card-text"}},[(_vm.card.type != null)?[_c('VRow',{staticStyle:{"max-height":"calc(100% - 87px)"}},[(_vm.images.length > 0)?_c('VCol',{staticClass:"pa-7",staticStyle:{"border-right":"1px solid #e3e3e3"},attrs:{"cols":"6"}},[_c('ImagesList',{attrs:{"files":_vm.images},on:{"removeFile":_vm.onRemoveFile}})],1):_vm._e(),_c('VCol',{attrs:{"cols":_vm.images.length > 0 ? 6 : 12}},[_c(_vm.getComponent(),{tag:"component",attrs:{"card":_vm.card,"index":_vm.index,"card-options":_vm.options,"assessment":_vm.assessment,"has-additional-images":_vm.images.length > 0},on:{"blur":_vm.saveCard,"input":_vm.saveCard,"saveCardError":_vm.onToggleSavingCardError,"saveCardStart":_vm.onToggleSavingCard,"saveCardDirty":_vm.onToggleSavingCardDirty,"saveCardSuccess":_vm.onToggleSavingCard}})],1)],1),(_vm.hasCurrentCard)?_c('VRow',{style:({
          'border-top': '1px solid #e3e3e3'
        })},[_c('CardOptions',{ref:"cardOptions",staticClass:"pt-2 pb-0",attrs:{"block":false,"assessment":_vm.assessment,"card":_vm.card,"index":_vm.index},on:{"input":_vm.saveCard,"files":(files) => _vm.images = files,"saveCardDirty":function($event){return _vm.$emit('saveCardDirty')}}})],1):_vm._e()]:_c('VAlert',{staticClass:"mt-3",attrs:{"type":!_vm.canAddSlide ? 'warning' : 'info',"value":true}},[(!_vm.canAddSlide)?[_vm._v(" Enter a title to save your "+_vm._s(_vm.featureName('lesson').singularize())+"! ")]:[_vm._v(" No card selected ")]],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }