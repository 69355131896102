<template>
  <VContainer
    grid-list-lg
    fill-height
  >
    <VRow
      v-if="modelHasId"
      column
      fill-height
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>
            <VBtn
              icon
              fab
              @click="$router.go(-1)"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            Add {{ featureName('Lesson') }}
          </h1>
        </VRow>
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <h1>Lesson: {{ model.lesson.title }}</h1>
              <VCardText>
                <h3 v-if="model.lesson && model.lesson.city">
                  {{ model.lesson.city.name }}
                </h3>
                <p
                  class="formatted"
                  v-html="model.lesson.content"
                />
              </VCardText>
            </VCard>
            <DynamicInputsView
              @input="onUpdateMetadata"
              :card="false"
              :readonly="true"
              :inputs="model.metadata"
              :input-name="featureName('Metadata')"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import HasAssessmentsMixin from "@/mixins/HasAssessments";

export default {
  name: "AssessmentSubmittedView",
  components: { DynamicInputsView },
  mixins: [ViewMixin, HasCalendarEntries, HasAssessmentsMixin],
  data() {
    return {
      modelType: "Assessment Submitted"
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      doGetAssessmentResult: "assessmentResult/getOne"
    }),
    onGet(id) {
      return this.doGetAssessmentResult(id);
    }
  }
};
</script>

