<!--

Creates the following inputs that will be submited:

	- geo-id
	- geo-name
	- geo-long
	- geo-lat
	- geo-country
	- geo-country-code

Example Usage

	<form action="/submit/url" method="POST">
		<geo-select
			prefix = "my-prefix"
			api-root-url = "\xxx\yyy"
			:enable-breadcrumb = "true"
			:countries = "[390903,3175395]"
		></geo-select>
		<input type="submit">
	</form>

-->

<template>
  <div>
    <div v-if="location() !== null">
      <input
        type="hidden"
        :name="prefix+'-id'"
        :value="location().id"
      >
      <input
        type="hidden"
        :name="prefix+'-name'"
        :value="location().name"
      >
      <input
        type="hidden"
        :name="prefix+'-long'"
        :value="location().long"
      >
      <input
        type="hidden"
        :name="prefix+'-lat'"
        :value="location().lat"
      >
      <input
        type="hidden"
        :name="prefix+'-country'"
        :value="country().name"
      >
      <input
        type="hidden"
        :name="prefix+'-country-code'"
        :value="location().country"
      >
    </div>
    <div
      v-show="breadCrumb"
      class="geo-breadcrumb"
    >
      <div class="form-group">
        <label>Your Location:</label>
        <br>
        <span
          v-for="(item, key) in path"
          :key="key"
          class="geo-breadcrumb-item"
        >{{ item.name }}</span>
        <a
          class="btn btn-xs btn-default pull-right"
          href="#"
          @click="breadCrumb = false"
        >Change Location...</a>
        <div class="clearfix" />
      </div>
    </div>
    <div v-show="!breadCrumb">
      <div class="form-group">
        <p v-if="loadingIndex == 0">
          <i class="fa fa-cog fa-spin" />Loading Countries...
        </p>
        <Autocomplete
          v-else
          v-model="values[0]"
          item-text="name"
          item-value="id"
          :items="items[0]"
          class="form-control _select2"
          :placeholder="getPlaceholder(0)"
          :style="{
            display: hideCountry ? 'none' : 'block'
          }"
          @change="itemSelected(0)"
        />
      </div>
      <div
        v-for="(i, key) in count"
        :key="key"
        class="form-group"
      >
        <p v-if="loadingIndex == i">
          <i class="fa fa-cog fa-spin" />Loading...
        </p>
        <div v-else>
          <Autocomplete
            v-if="hasItems(i)"
            v-model="values[i]"
            item-text="name"
            item-value="id"
            :items="items[i]"
            class="form-control _select2"
            :placeholder="getPlaceholder(i)"
            @change="itemSelected(i)"
          />
          <div v-if="!hasItems(i) && enableBreadcrumb">
            <a
              class="btn btn-xs btn-default pull-right"
              href="#"
              @click="breadCrumb = true"
            >Confirm Location</a>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideCountry: {
      type: Boolean,
      default: false
    },
    apiRootUrl: {
      type: String,
      default: "/api"
    },
    prefix: {
      type: String,
      default: "geo"
    },
    countries: {
      type: Array,
      default: null
    },
    enableBreadcrumb: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      count: 0,
      items: [],
      values: [],
      path: [],
      loadingIndex: null,
      breadCrumb: false
    };
  },
  mounted: function() {
    this.getChildrenOf(null, 0);
  },
  methods: {
    itemSelected(index) {
      var that = this;
      if (this.values[index] > 0) {
        this.path[index] = this.items[index].find(function(item) {
          return item.id == that.values[index];
        });
        this.setIndex(index);
        this.getChildrenOf(this.values[index], index + 1);
        if (this.values.length === 3) {
          this.$emit("citySelected", this.values[2]);
        }
      }
    },
    setIndex(index) {
      this.count = index + 1;
      this.values.splice(index + 1, 10);
      this.path.splice(index + 1, 10);
    },
    hasItems(index) {
      return this.items[index] instanceof Array && this.items[index].length > 0;
    },
    location() {
      if (this.path.length == 0) return null;

      return this.path[this.path.length - 1];
    },
    country() {
      return this.path[0];
    },
    getChildrenOf: function(id, index) {
      this.loadingIndex = index;

      var url = this.apiRootUrl;
      if (id == null) {
        if (this.countries == null) url += "/geo/countries?fields=id,name";
        else url += "/geo/item/" + this.countries;
      } else url += "/geo/children/" + id;

      this.$http
        .get(url, {})
        .then(response => {
          this.items[index] = response.data.map(item => {
            item.name = item.name.replace("County", "");
            return item;
          });
          this.breadCrumb = this.enableBreadcrumb && !this.hasItems(index);
          this.loadingIndex = null;
          this.$forceUpdate();
          if (this.items[index].length == 1) {
            this.values[index] = this.items[index][0].id;
            this.itemSelected(index);
          }
        })
        .catch(error => {
          this.$log.debug(error.response.data);
        });
    },
    getPlaceholder(index) {
      const placeholders = ["Select Country", "Select State", "Select City"];
      return placeholders[index];
    }
  }
};
</script>

<style lang="scss">
.geo-breadcrumb {
  list-style: none;
  .geo-breadcrumb-item {
    display: inline;
    & + span:before {
      padding: 8px;
      color: black;
      font-family: FontAwesome;
      content: "\f0da";
    }
  }
}
</style>