<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card,actions"
    :loading="activity.isFirstLoad"
  >
    <VRow
      column
      fill-height
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>Submit {{ featureName('Deliverables') }}</h1>
          <div>
            <VBtn
              class="wethrive"
              large
              :disabled="!isFormReady || isFormInputsDisabled"
              @click="onSave"
            >
              Save
              Changes
            </VBtn>
          </div>
        </VRow>
      </VCol>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VRow v-if="form.files && form.files.length">
          <VCol cols="12">
            <FilesList
              :readonly="true"
              :files="form.files"
            />
          </VCol>
        </VRow>
        <VDataIterator
          row
          wrap
          :items="items"
          :page="currentIndex"
          :items-per-page.sync="itemsPerPage"
          @update:pagination="setCurrentIndex"
        >
          <template #default="props">
            <VRow
              v-for="item in props.items"
              :key="`submit-${item.id}`"
            >
              <VCol>
                <DynamicInputsSubmit
                  v-model="item.metadata"
                  :card="false"
                  :input-name="featureName('Deliverable')"
                />
              </VCol>
            </VRow>
          </template>
        </VDataIterator>
      </VCol>
      <VCol>
        <VRow
          row
          justify-center
        >
          <VBtn
            class="wethrive"
            large
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSave"
          >
            Save
          </VBtn>
          <VBtn
            large
            class="wethrive-alt ml-4"
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSubmit"
          >
            {{ form.is_submitted ? 'Re-' : '' }} Submit
          </VBtn>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "../../mixins/SavesSubmittedData.vue";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
export default {
  name: "DeliverablesSubmit",
  components: {
    DynamicInputsSubmit,
  },
  mixins: [FormMixin, MetadataMixin, SavesSubmittedData],
  data() {
    return {
      options: {
        supplements: [],
        primary_contents: [],
      },
      defaultForm: {
        program_id: null,
        supplement_id: null,
        primary_content_id: null,
      },
    };
  },
  validations: {
    form: {
      program_id: {
        required: requiredIf(function (form) {
          return !form.primary_content_id && !form.supplement_id;
        }),
      },
      primary_content_id: {
        required: requiredIf(function (form) {
          return !form.program_id && !form.supplement_id;
        }),
      },
      supplement_id: {
        required: requiredIf(function (form) {
          return !form.primary_content_id && !form.program_id;
        }),
      },
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "deliverableSubmitted/getFormConfig",
      doSaveDeliverableSubmitted: "deliverableSubmitted/save",
      doGetDeliverable: "deliverable/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig({
        deliverable_id: this.deliverable.id,
      });
    },
    onSave() {
      this.form.deliverable_id = this.current.id;
      return this.saveFormModel(this.doSaveDeliverableSubmitted);
    },
    onSubmit() {
      this.form.is_submitted = true;
      return this.onSave();
    },
    onGet(id) {
      return this.doGetDeliverable(id);
    },
  },
};
</script>
