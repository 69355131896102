<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="save-pipeline"
    type="card-avatar, article, actions"
    :loading="!modelHasId"
  >
    <VRow class="px-3 fill-height">
      <VCol class="fill-height evaluate-column">
        <component
          limited-details
          ref="viewItem"
          mode="dialog"
          :is="viewComponent"
          :existing="model"
        />
      </VCol>
      <VCol class="fill-height evaluate-column">
        <SubmitForm
          mode="dialog"
          ref="submitEvaluation"
          :submitted="model"
          :content="realContent"
          :content-type="contentType"
          :existing="model.evaluation || {}"
          @saved="onSaved"
        />
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import SubmitForm from "./SubmitEvaluationForm";
import ViewEvaluated from "./ViewEvaluated";
import ViewEvaluatedStage from "./ViewEvaluatedStage";
import ViewMixin from "@/mixins/View";
import { mapActions } from "vuex";
export default {
  name: "PipelinesEvaluate",
  components: {
    SubmitForm,
    ViewEvaluated,
    ViewEvaluatedStage,
    // MessagesDialog,
  },
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    contentType: {
      type: String,
      default: "pipeline",
    },
  },
  mixins: [ViewMixin],
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    submitEvaluationRef() {
      return this.$refs["submitEvaluation"];
    },
    viewComponent() {
      return this.contentType === "pipeline-stage" ? ViewEvaluatedStage : ViewEvaluated;
    },
    realContent() {
      if (
        this.content instanceof Object &&
        this.content.evaluation_form instanceof Object
      ) {
        return this.content;
      }
      return this.contentType === "pipeline-stage"
        ? this.model.stage
        : this.model.pipeline;
    },
  },
  methods: {
    ...mapActions({
      doGetPipelineStageSubmitted: "pipelineStageSubmitted/getOne",
      doGetPipelineSubmitted: "pipelineSubmitted/getOne",
    }),
    onGet(id) {
      let getter = this.doGetPipelineSubmitted;
      let relations = ["metadata", "user", "pipeline.evaluationForm"];
      if (this.contentType === "pipeline-stage") {
        getter = this.doGetPipelineStageSubmitted;
        relations = [
          "metadata",
          "user",
          "stage.evaluationForm",
          "stage.pipeline.evaluationForm",
        ];
      }
      this.$log.debug("onGet: ", relations, id);
      return getter({
        ...(id instanceof Object ? id : { id }),
        ...{
          _with: relations,
        },
      });
    },
    onSetModel(model) {
      this.model = model;
    },
    onToggleDrawer() {
      this.drawer = !this.drawer;
    },
    onSaved(model) {
      this.$emit("saved", this.model.id, model);
    },
  },
};
</script>
<style lang="scss">
.evaluate-column {
  max-height: calc(100vh - 80px);
  overflow: hidden auto;
}
</style>
