import BaseApi from "./_base-api";

/**
 * Product Coupons API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Product Coupon",
	path: {
		getAll: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id || params.id;
			if (productId) {
				return `products/${productId}/coupons`;
			} else {
				return `products/coupons`;
			}
		},
		getOne: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/coupons`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/coupons`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/coupons`;
		},
		default: "products/coupons",
	},
});

export default api;
