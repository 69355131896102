import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Submitted Deliverable API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Deliverables]: Get Submitted Deliverables",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params,
				}
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Deliverables: Get Submitted Deliverables]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getFormConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Deliverables]: Get Submitted Deliverables Form Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getFormConfig", "form-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params,
				}
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Deliverables: Get Submitted Deliverables Form Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getIndexConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Deliverables]: Get Submitted Deliverables Index Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getIndexConfig", "index-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params,
				}
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Deliverables: Get Submitted Deliverables Index Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getOne(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Deliverables]: Get Submitted Deliverable",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getOne", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params,
				}
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Deliverables: Get Submitted Deliverables]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Submitted Deliverable",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const deliverableId = params.deliverableId || params.deliverable_id;
			return `deliverables_submitted/${deliverableId}`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const deliverableId = params.deliverableId || params.deliverable_id;
			return `deliverables_submitted/${deliverableId}`;
		},
		default: "deliverables_submitted",
	},
});

export default api;
export { Api };
