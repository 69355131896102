<template>
  <VCard>
    <VCardTitle>Associations</VCardTitle>
    <VCardText class="pr-6">
      <VRow>
        <VCol cols="12">
          <span class="label">
            {{ featureName("FAQs") }}
          </span>
        </VCol>
        <VCol
          cols="12"
          class="py-0"
        >
          <Autocomplete
            v-model="associations.faqs"
            multiple
            hide-details
            :items="options.faqs"
            :placeholder="`Attach to ${featureName('FAQs')}`"
          />
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      associations: {
        faqs: this.model.faqs,
      },
    }
  },
  watch: {
    associations: {
      handler: function(value) {
        this.$emit("setAssociations", value);
      },
      deep: true,
    },
  },
}
</script>