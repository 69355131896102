import dotenv from "dotenv";
dotenv.config();
const isDev =
	process.env.NODE_ENV === "development" ||
	process.env.NODE_ENV === "dev" ||
	process.env.NODE_ENV === "local";
const env = {
	...process.env,
	...{
		isProduction: process.env.NODE_ENV === "production",
		isStaging: process.env.NODE_ENV === "staging",
		isDev,
		version: process.env.PACKAGE_VERSION + "-" + process.env.NODE_ENV
	}
};
isDev && console.log("Environment", env);
export default env;
