<template>
  <VRow
    row
    wrap
    fill-height
    align-center
    justify-center
    class="present-textarea"
  >
    <VCol
      px-12
      cols="12"
    >
      <CardTitle
        align="center"
        :has-images="hasImages"
      >
        {{ card.name }}
      </CardTitle>
      <br>
      <LongTextField
        v-model="form.value"
        filled
        :placeholder="cardPlaceholder"
        @input="saveResult"
        @blur="saveResult"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardTextArea",
  mixins: [PresentationCardMixin],
};
</script>
<style lang="scss">
.present-textarea {
  .v-text-field--box > .v-input__control > .v-input__slot {
    border-radius: 4px;
  }
  .v-text-field > .v-input__control > .v-input__slot::after {
    border: none !important;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .v-text-field > .v-input__control > .v-input__slot::after,
  .v-text-field > .v-input__control > .v-input__slot::before {
    display: none !important;
  }
}
</style>