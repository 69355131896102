import Vue from "vue";
import BaseApi from "./_base-api";

const helper = new BaseApi({
	$app: new Vue()
});

export default {
	login(data, rememberMe) {
		return new Promise((resolve, reject) => {
			this.$auth.login({
				data,
				rememberMe,
				success(resp) {
					resolve(resp);
				},
				error(error) {
					helper.log().error("[Services: Auth: Login]:", error);
					reject(helper.utils.resolveError(error));
				}
			});
		});
	},
	recover(params) {
		return helper
			.api()
			.post("api/password/email", {
				email: params.email
			})
			.catch(error => {
				helper.log().error("[Services: Auth: Recover]:", error);
				throw helper.utils.resolveError(error);
			});
	},
	reset(params) {
		return helper
			.api()
			.post("api/password/reset", params)
			.catch(error => {
				helper.log().error("[Services: Auth: Reset]:", error);
				throw helper.utils.resolveError(error);
			});
	},
	getTeam(params) {
		return new Promise((resolve, reject) => {
			const hostname = window.location.hostname;
			const regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
			const urlParts = regexParse.exec(hostname);
			const team =
				hostname.replace(urlParts[0], "").slice(0, -1) || params.team;
			helper.log().debug("Hostname is ", hostname, "Team is ", team);
			return helper
				.api()
				.get("/api/teams", {
					team: team
				})
				.catch(error => {
					helper.log().error("[Services: Auth: GetTeam]:", error);
					reject(helper.utils.resolveError(error));
				});
		});
	},
	csrf() {
		return helper
			.api()
			.get("/csrf-request")
			.catch(error => {
				helper.log().error("[Services: Auth: Csrf]:", error);
				throw helper.utils.resolveError(error);
			});
	}
};
