<template>
  <VCard>
    <VCardTitle v-if="!form.program_id">
      Send Invitation
    </VCardTitle>
    <VCardText>
      <!-- Success Message -->
      <VAlert
        v-if="form.successful"
        type="success"
        :value="true"
      >
        The invitation has been sent!
      </VAlert>
      <VAlert
        v-if="form.errors.has('email')"
        type="error"
        :value="true"
      >
        {{ form.errors.get('email') }}
      </VAlert>
      <form
        role="form"
        v-if="canInviteMoreTeamMembers"
      >
        <!-- E-Mail Address -->
        <VRow
          row
          wrap
        >
          <VCol>
            <TextField
              label="(Optional) Name"
              type="text"
              name="name"
              v-model="form.name"
              :class="{'is-invalid': form.errors.has('name')}"
            />
            <TextField
              label="E-Mail Address"
              type="email"
              name="email"
              v-model="form.email"
              :class="{'is-invalid': form.errors.has('email')}"
            />
            <span
              class="invalid-feedback"
              v-if="hasTeamMembersLimit"
            >You currently have {{ remainingTeamMembers }} remaining.</span>
            <span
              class="invalid-feedback"
              v-show="form.errors.has('email')"
            >{{ form.errors.get('email') }}</span>
          </VCol>
        </VRow>
        <!-- Role -->
        <VRow
          v-if="roles.length > 0"
          row
          wrap
          class="my-n6"
        >
          <VCol>
            <VSelect
              dense
              outlined
              label="Role"
              :items="validRoles($team.roles)"
              item-text="name"
              item-value="id"
              v-model="form.role"
              :class="{'is-invalid': form.errors.has('role')}"
            />
            <span
              class="invalid-feedback"
              v-show="form.errors.has('role')"
            >{{ form.errors.get('role') }}</span>
          </VCol>
        </VRow>
        <!-- Send Invitation Button -->
        <VRow
          row
          wrap
        >
          <VCol
            v-if="form.program_id"
            cols="12"
          >
            <Autocomplete
              v-model="form.program_id"
              :disabled="form.program_id !== null"
              :items="options.programs"
              :placeholder="`Attach to ${featureName('Programs')}`"
            />
          </VCol>
          <template v-else>
            <VCol cols="12">
              <Autocomplete
                v-model="form.pipeline_id"
                :items="options.pipelines"
                :placeholder="`(Optional) Invite to ${featureName('Pipeline', 'singularize')}...`"
              />
            </VCol>
            <VCol cols="12">
              <Autocomplete
                v-show="currentPipelineStages.length"
                v-model="form.pipeline_stage_id"
                :items="currentPipelineStages"
                :placeholder="`And ${featureName('Stage', 'singularize')} to...`"
              />
            </VCol>
          </template>
          <VCol cols="12">
            <VBtn
              type="submit"
              color="primary"
              block
              @click.prevent="send"
              :disabled="form.busy || !(form.email && form.role)"
              :loading="form.busy"
            >
              <span v-if="form.busy">Sending</span>
              <span v-else>Send Invitation</span>
            </VBtn>
          </VCol>
        </VRow>
      </form>
      <div v-else>
        <VAlert
          :value="true"
          type="error"
        >
          Your current plan doesn't allow you to invite more members please upgrade your subscription.
        </VAlert>
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    programId: {
      type: Number,
      default: null,
    },
  },
  /**
   * The component's data.
   */
  data() {
    const defaultForm = {
      email: "",
      name: "",
      role: null,
      pipeline_id: null,
      pipeline_stage_id: null,
      pipeline_application: {
        type: "application-pipeline",
      },
      program_id: this.programId || null,
    };
    return {
      roles: [],
      options: {
        pipelines: [],
      },
      form: {
        ...new SparkForm(defaultForm),
        ...defaultForm,
      },
    };
  },

  computed: {
    ...mapGetters({
      plans: "billing/plans",
    }),
    /**
     * Get the active subscription instance.
     */
    activeSubscription() {
      if (!this.billable) {
        return;
      }
      let subscription = "undefined";
      if (this.billable.subscription instanceof Array) {
        subscription = this.billable.subscriptions.find(
          (subscription) => subscription.name == "default"
        );
      }

      if (typeof subscription !== "undefined") {
        return subscription;
      }
    },

    /**
     * Get the active plan instance.
     */
    activePlan() {
      if (this.activeSubscription) {
        return this.plans.find((plan) => {
          return plan.id == this.activeSubscription.provider_plan;
        });
      }
    },

    /**
     * Check if there's a limit for the number of team members.
     */
    hasTeamMembersLimit() {
      if (!this.activePlan) {
        return false;
      }

      return !!this.activePlan.attributes.teamMembers;
    },

    /**
     *
     * Get the remaining team members in the active plan.
     */
    remainingTeamMembers() {
      return this.activePlan
        ? this.activePlan.attributes.teamMembers -
            this.$parent.team.users.length
        : 0;
    },

    /**
     * Check if the user can invite more team members.
     */
    canInviteMoreTeamMembers() {
      if (!this.hasTeamMembersLimit) {
        return true;
      }

      return this.remainingTeamMembers > 0;
    },
    currentPipelineStages() {
      const pipelineId = this.form.pipeline_id;
      if (pipelineId) {
        return (
          this.options.pipelines.find((pipeline) => pipeline.id === pipelineId)
            .stages || []
        );
      }
      return [];
    },
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    this.roles = this.$team.roles;
    this.onGetFormConfig().then((result) => (this.options = result));
  },

  methods: {
    ...mapActions({
      doSendInvite: "team/invite",
      doGetFormConfig: "users/getFormConfig",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    /**
     * Send a team invitation.
     */
    send() {
      //   Spark.post(
      //     `/settings/${Spark.teamsPrefix}/${this.team.id}/invitations`,
      //     this.form
      //   )
      this.form.startProcessing();
      this.doSendInvite(this.form)
        .then((invitation) => {
          this.form.email = this.form.role = this.form.name = this.form.pipeline_id = this.form.pipeline_stage_id = "";
          this.form.finishProcessing();

          this.$emit("addInvitation", invitation);
        })
        .catch((errors) => {
          this.form.setErrors(errors.message);
        });
    },
  },
};
</script>
