import BaseApi from "./_base-api";

/**
 * Content API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Content",
	path: "primary_contents"
});

export default api;
