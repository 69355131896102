<script>
import { mapActions } from "vuex";
export default {
  props: {
    pipeline: {
      type: Object,
      default: () => {},
    },
    pipelineId: {
      type: [String, Number],
      default: null,
    },
    stage: {
      type: Object,
      default: () => {},
    },
    pipelineStageId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      resolvedPipelineKey: "pipeline",
      resolvedPipelineStageKey: "stage",
      resolvedPipeline: null,
      resolvedPipelineId: null,
      resolvedPipelineStage: null,
      resolvedPipelineStageId: null,
    };
  },
  computed: {
    hasResolvedPipeline() {
      return this.resolvedPipeline instanceof Object && this.resolvedPipeline.id > 0;
    },
    hasResolvedPipelineStage() {
      return (
        this.resolvedPipelineStage instanceof Object && this.resolvedPipelineStage.id > 0
      );
    },
    realPipeline() {
      if (
        this.resolvedPipelineKey &&
        this[this.resolvedPipelineKey] instanceof Object &&
        ["pipeline", "application-pipeline"].indexOf(
          this[this.resolvedPipelineKey].type
        ) > -1 &&
        this[this.resolvedPipelineKey].id > 0
      ) {
        return this[this.resolvedPipelineKey];
      }
      return this.hasResolvedPipeline ? this.resolvedPipeline : this.pipeline;
    },
    realStage() {
      if (
        this.contentType === "pipeline-stage" &&
        this.resolvedPipelineStageKey &&
        this[this.resolvedPipelineStageKey] instanceof Object &&
        this[this.resolvedPipelineStageKey].pipeline_id > 0 &&
        this[this.resolvedPipelineStageKey].id > 0
      ) {
        return this[this.resolvedPipelineStageKey];
      }
      return this.hasResolvedPipelineStage ? this.resolvedPipelineStage : this.stage;
    },
  },
  created() {
    this.resolvePipeline();
    this.resolvePipelineStage();
  },
  methods: {
    ...mapActions({
      doGetPipeline: "pipeline/getOne",
      doGetPipelineStage: "pipelineStage/getOne",
    }),
    resolvePipelineId() {
      this.resolvedPipelineId = parseInt(
        this.resolvedPipelineId ||
          this.$route.params.pipeline ||
          this.$route.query.pipeline_id ||
          this.pipelineId ||
          (this.realPipeline || {}).id ||
          (this.realStage || {}).pipeline_id ||
          (this.id instanceof Object ? this.id.pipeline_id : -1)
      );
    },
    resolvePipelineStageId() {
      this.resolvedPipelineStageId = parseInt(
        this.$route.params.stage ||
          this.$route.query.stage_id ||
          this.pipelineStageId ||
          this.stageId ||
          (this.realStage || {}).id
      );
    },
    resolvePipeline() {
      this.resolvePipelineId();
      if (
        !this.realPipeline ||
        (this.realPipeline instanceof Object && !this.realPipeline.id)
      ) {
        if (this.resolvedPipelineId && this.resolvedPipelineId > 0) {
          if (this.filter instanceof Object) {
            this.filter.filter = { pipeline: [this.resolvedPipelineId] };
          }
          this.resolvedPipeline = { id: this.resolvedPipelineId };
          this.doGetPipeline(this.resolvedPipelineId).then((pipeline) => {
            this.resolvedPipeline = pipeline;
          });
        }
      } else if (this.realPipeline instanceof Object) {
        this.resolvedPipeline = this.realPipeline;
        if (this.filter instanceof Object) {
          this.filter.filter.pipeline = [this.resolvedPipelineId];
        }
      }
    },
    resolvePipelineStage() {
      this.resolvePipelineId();
      this.resolvePipelineStageId();
      if (!this.realStage || (this.realStage instanceof Object && !this.realStage.id)) {
        if (this.resolvedPipelineStageId) {
          if (this.filter instanceof Object) {
            this.filter.filter = { stage: [this.resolvedPipelineStageId] };
          }
          this.resolvedPipelineStage = { id: this.resolvedPipelineStageId };
          this.doGetPipelineStage({
            id: this.resolvedPipelineStageId,
            pipeline_id: this.resolvedPipelineId,
          }).then((stage) => {
            this.resolvedPipelineStage = stage;
          });
        }
      } else if (this.realStage) {
        this.resolvedPipelineStage = this.realStage;
        if (this.filter instanceof Object) {
          this.filter.filter.stage = [this.resolvedPipelineStageId];
        }
      }
    },
  },
};
</script>
