<template>
  <Dialog
    :dismissable="isError"
    :is-dialog-open="isDialogOpen"
    @toggle:dialog="$emit('toggle:dialog')"
  >
    <VRow
      fill-height
      align-center
      justify-center
      space-around
      class="pr-2"
    >
      <VCol
        cols="12"
        class="text-sm-center"
      >
        <VProgressLinear
          v-if="isLoading"
          intermediate
        />
        <VIcon
          v-else
          :style="{
            fontSize: '12rem',
            paddingTop: '16px'
          }"
          :color="isSuccessful ? 'success' : 'error'"
        >
          {{ isSuccessful ? 'check_circle' : 'error' }}
        </VIcon>
      </VCol>
      <VCol
        v-if="!isError"
        cols="12"
        class="text-sm-center py-12"
      >
        <p
          class="display-1"
          :style="{
            fontWeight: 700
          }"
        >
          {{ message }}
        </p>
      </VCol>
      <VCol class="text-sm-center">
        <p>
          <template v-if="isError">
            <template v-if="canContactSupport">
              Looks like you're having trouble regiserting. You can try again and if you continue having problems please contact support using the button below.
            </template>
            <template v-else>
              Looks like there was a problem registering you. Please try again.
            </template><br>
            <VBtn
              v-if="canContactSupport"
              color="info"
              class="mt-3"
              @click="$emit('toggle:dialog') && $bus.$emit('toggleIntercom')"
            >
              Contact Support
            </VBtn>
          </template>
          <template v-else>
            {{ statusText }}
          </template>
        </p>
      </VCol>
    </VRow>
  </Dialog>
</template>
<script>
export default {
  name: "StatusDialog",
  props: {
    canContactSupport: {
      type: Boolean,
      default: true,
    },
    isDialogOpen: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isSuccessful: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "We're processing your registration. Please wait...",
    },
    statusText: {
      type: String,
      default: "One second! We're taking you to your application ...",
    },
  },
};
</script>