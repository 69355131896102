<script>
export default {
  data() {
    return {
      hasFiles: false,
      fileProperties: ["files"]
    };
  },
  methods: {
    onAddFile(files, key) {
      const property = key || "files";
      this.form[property] = this.form[property] || [];
      this.form[property].push(files);
    },
    onRemoveFile(index, key) {
      const property = key || "files";
      if (this.form.hasOwnProperty(property)) {
        let file = [];
        if (this.form[property].length === 1) {
          file = this.form[property].splice(0, 1);
        } else {
          file = this.form[property].splice(index, 1);
        }
      }
    },
    prepareForFiles(data) {
      for (var i in this.fileProperties) {
        const property = this.fileProperties[i];
        if (
          data.hasOwnProperty(property) &&
          (data[property].length || data[property] instanceof File)
        ) {
          this.hasFiles = true;
          break;
        }
      }
      // if(this.hasFiles) {
      //     Object.keys(data).forEach(property => {
      //         if(this.fileProperties.indexOf(property) === -1) {
      //             formData.append(property, data[property] instanceof Object ? JSON.stringify(data[property]) : data[property]);
      //         } else {
      //             this.$log.debug("Checking files", data[property]);
      //             //now add all of the assigned files
      //             for (var i = 0; i < data[property]; i++) {
      //                 //add each file to the form data and iteratively name them
      //                 formData.append(property + i, data[property][i]);
      //             }
      //         }
      //     });
      // }
      return data;
    },
    resetForFiles(hadFiles) {
      this.hasFiles = hadFiles;
    }
  }
};
</script>