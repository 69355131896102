<template>
  <VRow
    row
    wrap
    fill-height
    align-center
    justify-center
    style="width: 100%"
  >
    <VCol
      px-12
      cols="12"
    >
      <CardTitle
        :has-images="hasImages"
        :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'"
      >
        {{ card.name }}
      </CardTitle>
      <TextField
        v-model="form.value"
        filled
        :placeholder="cardPlaceholder"
        @input="saveResult"
        @blur="saveResult"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardText",
  mixins: [PresentationCardMixin],
};
</script>

