import Vue from "vue";
import BaseApi from "./_base-api";

/**
 * Primary Content Completed API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(params) {
		this.log().info(
			"[Services: Primary Content Completed]: Get Primary Content Completed",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, "primary_content_completed")}/${
					params.id
				}`,
				params
			)
			.catch(error => {
				this.log().error(
					"[Services: Primary Content Completed: Get Primary Content Completed]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Primary Content Completed",
	path: "primary_content_completed"
});

export default api;
