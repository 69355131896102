<template>
  <VRow
    row
    wrap
  >
    <VCol cols="12">
      <h3>
        <VTooltip top>
          <template #activator="{ on }">
            <VCheckbox
              v-model="form.toOthers"
              class="mt-0"
              style="min-height: auto"
              :hint="toOthersMessage"
              persistent-hint
              v-on="on"
            >
              <template slot="label">
                <span>Sending to</span>
                <VChip
                  color="info"
                  text-color="white"
                >
                  {{ form.toOthers ? 'others in your team' : 'the user who initiated the automation' }}
                </VChip>
              </template>
            </VCheckbox>
          </template>
          <span>{{ toOthersTooltip }}</span>
        </VTooltip>
      </h3>
      <template v-if="!form.toOthers">
        <Autocomplete
          v-model="form.channels"
          :multiple="true"
          sm="4"
          label="Select destinations"
          :items="individualDestinations"
          item-text="title"
          item-value="value"
          append-inner-icon="arrow-down"
          placeholder="Select destinations ..."
          class="mt-4"
          @input="emitInput"
        />
      </template>
    </VCol>
    <VCol
      class="pb-0"
      v-if="form.toOthers"
    >
      <VExpansionPanels
        multiple
      >
        <VExpansionPanel
          v-for="(destination, i) in destinations"
          :key="i"
        >
          <VExpansionPanelHeader>
            <div>
              <VIcon
                @click="channel => toggleChannel(destination.value)"
                :color="hasChannel(destination.value) ? 'primary' : 'normal'"
              >
                {{ hasChannel(destination.value) ? 'check_box' : 'check_box_outline_blank' }}
              </VIcon>
              {{ destination.title }}
            </div>
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <VCard>
              <VCardText>
                <Threads
                  v-if="destination.value === 'chatThreads'"
                  mode="multiple"
                  @selectedThreads="value => setChannelValue(value, destination.value)"
                  :existing="existingValue(destination.value)"
                  thread-mode="select-thread"
                />
                <Threads
                  v-else-if="destination.value === 'groupChatThreads'"
                  mode="group"
                  @selectedThreads="value => setChannelValue(value, destination.value)"
                  :existing="existingValue(destination.value)"
                  thread-mode="select-thread"
                />
                <template v-else>
                  <Members
                    mode="multiple"
                    :is-group="true"
                    :autosave="true"
                    @saveMembers="value => setChannelValue(value, destination.value)"
                    :existing="existingValue(destination.value)"
                  />
                </template>
              </VCardText>
            </VCard>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
  </VRow>
</template>
<script>
import Threads from "@/components/Chat/Threads";
import Members from "@/components/Elements/Forms/MembersForm";
export default {
  props: {
    toOthers: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    destinations: {
      type: Array,
      default: () => [
        {
          title: "Email",
          value: "email"
        },
        {
          title: "Notification",
          value: "notification"
        },
        {
          title: "Chat Thread",
          value: "chatThreads"
        },
        {
          title: "Group Chat Thread",
          value: "groupChatThreads"
        }
      ]
    }
  },
  components: {
    Threads,
    Members
  },
  data() {
    return {
      channels: [true],
      form: {
        channels: ["email"],
        toOthers: false
      },
      defaultForm: { channels: ["email"], toOthers: false },
      individualDestinations: [
        {
          title: "Email",
          value: "email"
        },
        {
          title: "Notification",
          value: "notification"
        }
      ],
      previousUserChannelValue: [],
      previousOtherChannelValue: {}
    };
  },
  created() {
    const value = this.value || this.cloneObject(this.defaultForm);
    this.channels = Object.keys(value.channels) || [true];
    this.form = this.cloneObject({ ...this.defaultForm, ...value });
  },
  computed: {
    toOthersLabel() {
      if (this.form.toOthers) {
        return "Send to the user instead?";
      } else {
        return "Send to others instead?";
      }
    },
    toOthersTooltip() {
      if (this.form.toOthers) {
        return "You are sending this to other users, not the user who initiated this automation. To send it to the user please disable this switch.";
      } else {
        return "You are sending this to the user who initiated this automation. To send it to others please enable this switch.";
      }
    },
    toOthersMessage() {
      if (this.form.toOthers) {
        return "For each destination select the receipents. A copy of the message will be sent to each receipient in the destination.";
      } else {
        return "A copy of the message will be sent to the user for each destination below.";
      }
    }
  },
  watch: {
    channels: function(value) {
      if (value !== true && value !== false) {
        value.map(channel => {
          this.form.channels[channel] = this.form.channels[channel] || [];
        });
      }
    },
    "form.toOthers": function(value) {
      if (value) {
        this.previousUserChannelValue = this.form.channels;
        this.form.channels = this.previousOtherChannelValue;
      } else {
        this.previousOtherChannelValue = this.form.channels;
        this.form.channels = this.previousUserChannelValue;
      }
    }
  },
  methods: {
    emitInput() {
      this.$emit("input", this.form);
    },
    setChannelValue(value, channel) {
      this.form.channels[channel] = value;
      this.emitInput();
    },
    hasChannel(channel) {
      return this.form.channels.hasOwnProperty(channel);
    },
    existingValue(channel) {
      if (this.hasChannel(channel)) {
        return this.form.channels[channel];
      }
      return [];
    },
    isToUser(channel) {
      if (this.hasChannel(channel)) {
        return this.form.channels[channel] === true;
      }
      return false;
    },
    setToUser(value) {
      if (value) {
        this.form.channels[channel] = true;
      } else {
        delete this.form.channels[channel];
      }
      this.emitInput();
    },
    toggleChannel(channel) {
      if (!this.hasChannel(channel)) {
        this.form.channels[channel] = [];
      } else {
        delete this.form.channels[channel];
      }
      this.emitInput();
    }
  }
};
</script>