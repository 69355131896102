<template>
  <VCol
    fill-height
    style="overflow: hidden"
    class="px-0 py-0"
  >
    <VCol
      class="pa-0 mt-n1"
      style="border-bottom: 1px solid #e3e3e3; position: sticky; top: 0"
    >
      <Tooltip
        block
        icon-button
        button-text="Add Card"
        icon="mdi-plus"
        color="rgba(0, 0, 0, 0.65)"
        button-text-style="padding-left: 8px;"
        :action="() => (canAddSlide ? $emit('add') : false)"
        :disabled="!canAddSlide"
        :button-class="{
          'justify-start': true,
          'px-2 py-4': true,
        }"
        :button-style="{
          height: '49px',
          borderRadius: '0px',
        }"
        :style="{
          cursor: canAddSlide ? 'pointer' : 'error',
        }"
        :title="
          !canAddSlide
            ? 'Give your lesson a title before you add cards'
            : 'Add a new slide'
        "
      />
    </VCol>
    <Draggable
      v-if="allInputs && allInputs.length > 0"
      v-model="allInputs"
      id="cards-container"
      :options="draggableOptions"
      element="div"
      :no-transition-on-drag="true"
      :style="{
        height: 'auto',
        maxHeight: 'calc(100% - 54px)',
        overflow: 'auto',
        marginTop: '4px',
      }"
    >
      <transition-group
        name="dynamic-input-container"
        :css="true"
        tag="div"
      >
        <VHover
          v-for="(input, key) in allInputs"
          :key="`dynamic-${input.id}-${key}`"
        >
          <VCard
            tile
            slot-scope="{ hover }"
            style="border-bottom: 1px solid #e3e3e3"
            :id="`card${key}`"
            :class="`elevation-${hover || getIsCurrent(key) ? 8 : 1}`"
            @click="() => editCard(key, input)"
          >
            <div
              v-if="getIsCurrent(key)"
              class="active-card-indicator"
            />
            <VCardTitle
              primary-title
              text-center
              class="align-center dynamic-input-handle"
              style="border-bottom: none"
            >
              <p
                class="d-flex align-center text-truncate mb-0"
                style="width: 100%"
              >
                <img
                  src="../../../images/lessons/drag_2.png"
                  style="opacity: 0.85"
                >
                <span
                  style="padding: 6px 4px"
                  v-if="input.isBackgroundSaving"
                >
                  <VProgressCircular
                    :size="10"
                    :width="1"
                    indeterminate
                  />
                </span>
                <span
                  v-else
                  class="pl-2"
                >
                  <VIcon
                    size="16px"
                    class="pa-1 rounded"
                    style="background-color: #f8f8f8"
                  >
                    {{ getIcon(input.type) }}
                  </VIcon>
                </span>
                &nbsp;
                <span class="pl-3 text-truncate">
                  {{ getText(input.type) }}
                </span>
                <VSpacer />
                <VBtn
                  v-if="!input.deleted"
                  text
                  icon
                  :loading="input.isDuplicating"
                  @click.stop="$emit('duplicate', key, input)"
                >
                  <VIcon size="18px">
                    content_copy
                  </VIcon>
                </VBtn>
                <VBtn
                  v-if="!input.deleted"
                  text
                  icon
                  @click="() => editCard(key, input)"
                >
                  <VIcon size="18px">
                    mdi-pencil-outline
                  </VIcon>
                </VBtn>
              </p>
            </VCardTitle>
            <VCardText
              d-flex
              justify-center
              style="height: 60px; font-size: 12px"
              class="pl-4"
            >
              <p
                class="full-width text-no-wrap text-truncate"
                style="margin: 0px"
              >
                <span
                  :class="{
                    'font-weight-bold': input.type === 'Title',
                  }"
                  :style="{
                    color: input.type === 'Title' ? 'black' : 'inherit',
                    'font-weight': 450,
                  }"
                >
                  {{ input.name }}
                </span>
              </p>
              <small
                v-if="input.type === 'Question'"
                style="font-weight: 450"
              >
                <strong>{{ (input.choices && input.choices.length) || 0 }}</strong>
                Choices
                <strong>{{
                  (input.value && input.value.type && input.value.type.capitalize()) ||
                    "-"
                }}</strong>
                Choice
              </small>
              <template v-else-if="input.type === 'Image' && input.value && input.value.url">
                <VImg
                  class="mb-2"
                  max-height="50px"
                  contain
                  :src="input.value.url"
                >
                  <template #placeholder>
                    <VRow
                      fill-height
                      align-center
                      justify-center
                      ma-0
                    >
                      <VProgressCircular
                        indeterminate
                        color="grey"
                      />
                    </VRow>
                  </template>
                </VImg>
              </template>
              <template v-else-if="input.type === 'Video' && input.value">
                <p class="full-width text-no-wrap text-truncate">
                  {{ getDomain(input.value.url) || "(no video set)" }}
                </p>
              </template>
              <template v-else-if="input.type === 'Paragraph'">
                <small
                  class="d-block full-width text-truncate"
                  style="font-weight: 450"
                >
                  {{ input.raw_value }}
                </small>
              </template>
              <template v-else-if="['Question', 'Video'].indexOf(input.type) === -1">
                <div style="text-align: center">
                  <VIcon x-large>
                    {{ input.icon }}
                  </VIcon>
                </div>
              </template>
            </VCardText>
            <VCardActions class="pt-0 pl-6 pr-6">
              <VRow
                row
                justify-center
              >
                <VSpacer />
                <template v-if="!input.deleted">
                  <VTooltip top>
                    <template #activator="{ on }">
                      <VBtn
                        v-on="on"
                        text
                        icon
                        :loading="input.isNewCardLoading"
                      >
                        <VIcon
                          size="18px"
                          @click.stop="$emit('addNewBlankCard', key, input)"
                        >
                          mdi-card-plus-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    Insert a new blank {{ input.type }} Card
                  </VTooltip>
                  <VTooltip top>
                    <template #activator="{on}">
                      <VBtn
                        v-on="on"
                        text
                        icon
                      >
                        <VIcon
                          size="18px"
                          @click.stop="() => removeCard(key, input)"
                        >
                          mdi-delete-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    Delete Card
                  </VTooltip>
                </template>
                <template v-else>
                  <VTooltip top>
                    <template #activator="{on}">
                      <VBtn
                        v-on="on"
                        text
                        icon
                      >
                        <VIcon
                          size="18px"
                          @click="() => restoreCard(key, input)"
                        >
                          undo
                        </VIcon>
                      </VBtn>
                    </template>
                    Undo
                  </VTooltip>
                </template>
              </VRow>
            </VCardActions>
          </VCard>
        </VHover>
      </transition-group>
    </Draggable>
    <VAlert
      v-else
      class="mt-3 mx-3"
      :type="!canAddSlide ? 'warning' : 'info'"
      :value="true"
    >
      <template v-if="!canAddSlide">
        Enter a title to add cards!
      </template>
      <template v-else>
        No cards
      </template>
    </VAlert>
  </VCol>
</template>

<script>
import Draggable from "vuedraggable";
import Card from "./Card";

export default {
  name: "LessonCards",
  components: {
    Draggable,
  },
  props: {
    valuesFromUser: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputsHaveUploads: {
      type: Boolean,
      default: false,
    },
    metadataProp: {
      type: String,
      default: "metadata",
    },
    value: {
      type: Array,
      default: () => [],
    },
    inputName: {
      type: String,
      default: "",
    },
    choiceMode: {
      type: String,
      default: "",
    },
    inputChoices: {
      type: Array,
      default: () => [],
    },
    enableFiles: {
      type: Boolean,
      default: false,
    },
    canAddSlide: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    containerClass: {
      type: String,
      default: "content-bg dynamic-input-container",
    },
    containerCardClass: {
      type: String,
      default: "dynamic-input-container",
    },
    appendText: {
      type: String,
      default: "Pick a Field to Add",
    },
    activeCardIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      dialogs: {
        add: false,
      },
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      currentCard: null,
      currentCardIndex: 0,
      localChoices: [
        {
          text: "Image",
          type: "Image",
          icon: "mdi-image-outline",
        },
        {
          text: "Video",
          type: "Video",
          icon: "mdi-movie-filter",
        },
        {
          text: "Question",
          type: "Question",
          icon: "mdi-format-list-bulleted",
        },
        {
          text: "Text",
          type: "Text",
          icon: "text",
        },
        {
          text: "TextArea",
          type: "TextArea",
          icon: "text",
        },
        {
          text: "Title",
          type: "Title",
          icon: "title",
        },
        {
          text: "Result",
          type: "Result",
          icon: "check_box",
        },
      ],
    };
  },
  mounted () {
    const index = 0;
    this.$log.debug(this.allInputs.length);
    if (this.allInputs.length) {
      const card = this.allInputs[0];
      if (card instanceof Object) {
        this.setInitialCard(index, card);
      }
    }
  },
  computed: {
    choices () {
      let choices =
        this.choiceMode == "user"
          ? this.inputChoices
          : [].concat(this.localChoices, this.inputChoices);
      if (this.enableFiles) {
        choices.push(
          {
            text: "File",
            type: "File",
            icon: "attachment",
          },
          {
            text: "Google Drive",
            type: "Google Drive",
            icon: "google",
          }
        );
      }
      return choices;
    },
    placeholderText () {
      return this.valuesFromUser
        ? `${this.$team.user_role_name} will add value`
        : "Enter value";
    },
    titleText () {
      return this.title && this.title.length
        ? this.title
        : this.valuesFromUser
          ? this.placeholderText
          : "";
    },
    allInputs: {
      get () {
        return this.value instanceof Array ? this.value : [];
      },
      set (value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    allInputs: function (value) {
      if (value instanceof Array && value.length && !this.currentCard) {
        this.setInitialCard(0, value[0]);
      }
    },
    activeCardIndex: function (value) {
      this.currentCardIndex = value;
      this.currentCard = this.allInputs[value];
      this.scrollToCurrentCard();
      this.editCard(value, this.currentCard);
    },
  },
  methods: {
    setInitialCard (index, card, unwatch) {
      this.$log.debug("[LessonCards]: Setting initial card", card);
      this.editCard(index, card);
      if (unwatch instanceof Function) {
        unwatch();
      }
    },
    scrollToCurrentCard () {
      const cardContainer = document.querySelector("#cards-container");
      this.$log.debug("Card container", cardContainer);
      if (cardContainer) {
        this.waitFor(() => {
          const elem = cardContainer.querySelector(`#card${this.currentCardIndex}`);
          return elem instanceof HTMLElement;
        }, 2000).then(() => {
          const currentCard = cardContainer.querySelector(
            "#card" + this.currentCardIndex
          );
          const scrollTop = parseInt(currentCard.getBoundingClientRect().top);
          this.$log.debug(
            "Current Card",
            this.currentCardIndex,
            currentCard.offsetTop,
            scrollTop
          );
          cardContainer.scrollTo({
            top: currentCard.offsetTop - 200,
            behavior: "smooth",
          });
        });
      }
    },
    addInput (value) {
      this.$log.debug("DynamicInputs: Adding input", value);
      this.$emit(
        "newInput",
        {
          type: value,
          value: "",
        },
        this.metadataProp
      );
      this.$nextTick(() => {
        if (this.allInputs.length == 1) {
          this.editCard(0, this.allInputs[0]);
        }
      });
    },
    editCard (key, input) {
      if (!input.deleted) {
        /*
          Adding "options.caption" in the new card to avoid caption/description overwrite
          Jira: https://wethrive.atlassian.net/browse/PM-285
        */
        if(!input.hasOwnProperty("options")) {
          this.$set(input, "options", {
            caption: null,
          });
        }
        this.currentCard = input;
        this.currentCardIndex = key;
        this.$emit("edit", key, input);
      }
    },
    removeCard (key, input) {
      if (input.id) {
        this.$emit("delete", input, input, this.metadataProp);
      } else {
        this.$emit("remove", key, input, this.metadataProp);
      }
    },
    restoreCard (key, input) {
      this.$emit("restore", input.id ? input : key, input, this.metadataProp);
    },
    addRemoteFile (file, index) {
      let input = this.allInputs[index];
      input.value = file;
      input.name = file.name;
      this.allInputs.splice(index, 1, input);
    },
    addLocalFile (file, index) {
      this.allInputs.splice(
        index,
        1,
        Object.assign(this.allInputs[index], {
          value: file,
          name: file.name,
        })
      );
    },
    getIcon (type) {
      const choice = this.choices.find((choice) => choice.type === type) || {};
      return choice.icon || "warning";
    },
    getText (type) {
      const choice = this.choices.find((choice) => choice.type === type) || {};
      return choice.text || "Unknown " + type;
    },
    getIsCurrent (key) {
      return key == this.currentCardIndex;
    },
    getCardClass (key) {
      const card = this.allInputs[key];
      if (card instanceof Object) {
        if (card.deleted) {
          return "disabled white--text";
        } else {
          return this.getIsCurrent(key) ? "success white--text" : "default";
        }
      } else {
        return this.getIsCurrent(key) ? "success white--text" : "default";
      }
    },
    parseUrl (url) {
      try {
        return new URL(url);
      } catch (e) {
        return {};
      }
    },
    getDomain (s) {
      let url = this.parseUrl(s).hostname;
      if (url) {
        const firstTLDs = "ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|be|bf|bg|bh|bi|bj|bm|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|cl|cm|cn|co|cr|cu|cv|cw|cx|cz|de|dj|dk|dm|do|dz|ec|ee|eg|es|et|eu|fi|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|im|in|io|iq|ir|is|it|je|jo|jp|kg|ki|km|kn|kp|kr|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|na|nc|ne|nf|ng|nl|no|nr|nu|nz|om|pa|pe|pf|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|yt".split(
          "|"
        );
        const secondTLDs = "com|edu|gov|net|mil|org|nom|sch|caa|res|off|gob|int|tur|ip6|uri|urn|asn|act|nsw|qld|tas|vic|pro|biz|adm|adv|agr|arq|art|ato|bio|bmd|cim|cng|cnt|ecn|eco|emp|eng|esp|etc|eti|far|fnd|fot|fst|g12|ggf|imb|ind|inf|jor|jus|leg|lel|mat|med|mus|not|ntr|odo|ppg|psc|psi|qsl|rec|slg|srv|teo|tmp|trd|vet|zlg|web|ltd|sld|pol|fin|k12|lib|pri|aip|fie|eun|sci|prd|cci|pvt|mod|idv|rel|sex|gen|nic|abr|bas|cal|cam|emr|fvg|laz|lig|lom|mar|mol|pmn|pug|sar|sic|taa|tos|umb|vao|vda|ven|mie|北海道|和歌山|神奈川|鹿児島|ass|rep|tra|per|ngo|soc|grp|plc|its|air|and|bus|can|ddr|jfk|mad|nrw|nyc|ski|spy|tcm|ulm|usa|war|fhs|vgs|dep|eid|fet|fla|flå|gol|hof|hol|sel|vik|cri|iwi|ing|abo|fam|gok|gon|gop|gos|aid|atm|gsm|sos|elk|waw|est|aca|bar|cpa|jur|law|sec|plo|www|bir|cbg|jar|khv|msk|nov|nsk|ptz|rnd|spb|stv|tom|tsk|udm|vrn|cmw|kms|nkz|snz|pub|fhv|red|ens|nat|rns|rnu|bbs|tel|bel|kep|nhs|dni|fed|isa|nsn|gub|e12|tec|орг|обр|упр|alt|nis|jpn|mex|ath|iki|nid|gda|inc".split(
          "|"
        );

        const removeSubdomain = function (s) {
          s = s.replace(/^www\./, "");

          const parts = s.split(".");

          while (parts.length > 3) {
            parts.shift();
          }

          if (
            parts.length === 3 &&
            ((parts[1].length > 2 && parts[2].length > 2) ||
              (secondTLDs.indexOf(parts[1]) === -1 && firstTLDs.indexOf(parts[2]) === -1))
          ) {
            parts.shift();
          }

          return parts.join(".");
        };
        return removeSubdomain(url);
      } else {
        return "invalid url";
      }
    },
  },
};
</script>

<style lang="scss" scoped>.dynamic-input-add.layout.row {
  margin-top: 15px !important;
}

.dynamic-input-container-enter,
.dynamic-input-container-leave-active {
  opacity: 1;
}

.dynamic-input-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

#cards-container>div {
  overflow: auto;
}

.active-card-indicator {
  height: -webkit-fill-available;
  width: 5px;
  background-color: #89c160;
  position: absolute;
}</style>

<style lang="scss">@import "../../../styles/variables.scss";

.remove-dynamic-input .v-btn__content {
  text-transform: none;
  color: $font-light-color;
}

.remove-dynamic-input:hover .v-btn__content {
  color: #ec4a4a;
}

.dynamic-input-container .v-input__append-outer:before {
  content: "";
  height: 40%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 30%;
  background-color: #eaeaea;
}

#select-program {
  .v-input__slot:before {
    border: none !important;
  }

  input {
    margin-left: 20px;
  }

  input::placeholder {
    color: rgba(0, 0, 0, 0.65);
  }

  .v-input__icon {
    padding-right: 28px;
  }
}</style>
