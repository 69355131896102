import BaseApi from "./_base-api";

/**
 * Attendance API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Attendance",
	path: "attendances"
});

export default api;
