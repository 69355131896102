<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card-heading,table-heading,table-tbody"
    :loading="activity.isFirstLoad"
  >
    <VContainer id="user-notification-preferences">
      <VRow>
        <VCol
          v-if="hasMessagesOrErrors"
          cols="12"
        >
          <Alerts
            :messages="messages"
            :errors="errors"
          />
        </VCol>
        <VCol cols="12">
          <VForm>
            <VRow>
              <VCol
                sm="12"
                md="6"
                order-sm="2"
                order-md="1"
                class="d-inline-flex align-center"
              >
                <VSwitch
                  v-model="form.notifications_are_enabled"
                  inset
                  hide-details
                  depressed
                  width="35px"
                  height="35px"
                  class="no-shadow mt-0 no-border"
                  color="success"
                />
                Notifications are:&nbsp; <strong>{{ form.notifications_are_enabled ? 'Enabled' : 'Disabled' }}</strong>
              </VCol>
              <VCol
                sm="12"
                md="6"
                order-sm="1"
                order-md="2"
                class="d-inline-flex align-center justify-end"
              >
                <VBtn
                  large
                  class="wethrive"
                  style="text-transform: uppercase"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="!isFormReady"
                  @click="onSaveUser"
                >
                  Save Changes
                </VBtn>
              </VCol>
              <template v-if="form.notifications_are_enabled">
                <VCol
                  cols="6"
                  order-sm="3"
                  order-md="3"
                >
                  <VMenu
                    v-model="dialogs.notificationsStart"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    transition="scale-transition"
                    ref="notificationsStart"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.notifications_silent_from"
                  >
                    <template #activator="{ on, attrs }">
                      <TextField
                        v-model="form.notifications_silent_from"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        filled
                        clearable
                        label="Silence notifications from"
                        prepend-icon="mdi-clock-time-four-outline"
                        :error="$v.form.notifications_silent_from.$error"
                        @blur="$v.$touch()"
                      />
                    </template>
                    <VTimePicker
                      v-if="dialogs.notificationsStart"
                      v-model="time.start"
                      full-width
                      @click:minute="$refs.notificationsStart.save(time.start)"
                    />
                  </VMenu>
                </VCol>
                <VCol
                  cols="6"
                  order-sm="4"
                  order-md="4"
                >
                  <VMenu
                    v-model="dialogs.notificationsEnd"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    ref="notificationsEnd"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.notifications_silent_to"
                  >
                    <template #activator="{ on, attrs }">
                      <TextField
                        v-on="on"
                        v-bind="attrs"
                        v-model="form.notifications_silent_to"
                        readonly
                        filled
                        clearable
                        label="Silence notifications until"
                        prepend-icon="mdi-clock-time-four-outline"
                        :error="$v.form.notifications_silent_to.$error"
                        @blur="$v.$touch()"
                      />
                    </template>
                    <VTimePicker
                      v-if="dialogs.notificationsEnd"
                      v-model="time.end"
                      full-width
                      @click:minute="$refs.notificationsEnd.save(time.end)"
                    />
                  </VMenu>
                </VCol>
                <!-- <VCol
                  v-if="!datesAreValid"
                  cols="12"
                >
                  <VAlert type="warning">
                    Your settings are invalid. The start time should come after the end time
                  </VAlert>
                </VCol> -->
              </template>
            </VRow>
          </VForm>
        </VCol>
        <template v-if="form.notifications_are_enabled">
          <VCol cols="12">
            <VAlert
              type="info"
              :value="true"
            >
              Configure your individual notification preferences below.
            </VAlert>
          </VCol>
          <VCol cols="12">
            <VList two-line>
              <template v-for="(group, name) in groupedPreferences">
                <VSubheader :key="name">
                  {{ featureName(name) }}
                </VSubheader>
                <VListItem
                  v-for="(preference, key) in group"
                  :key="`${name}-${key}`"
                  style="height: auto; max-height: 140px"
                >
                  <VListItemContent style="height: auto; max-height: 140px">
                    <VListItemTitle>{{ preference instanceof Object ? preference.type.title : '(not set)' }}</VListItemTitle>
                    <VListItemSubtitle style="white-space: normal; line-height: 18px; overflow: visible">
                      {{ preference.type.description }}
                    </VListItemSubtitle>
                  </VListItemContent>
                  <VListItemAction style="min-width: 96px">
                    <VRow column>
                      <VCol>
                        <VBadge
                          overlap
                          :color="(preference && preference.isSaving ? 'info' : 'success')"
                          :icon="(preference && preference.isSaving ? 'pending' : 'check')"
                          :value="(preference.isSaved == true || preference.isSaving == true)"
                        >
                          <VTooltip
                            v-for="channel in channels"
                            top
                            :key="`${channel.channel}-${preference.id}`"
                          >
                            <template #activator="{on}">
                              <VBtn
                                icon
                                v-on="on"
                                :color="getChannelColor(preference[channel.channel])"
                                :disabled="preference.isSaving"
                                @click="onSetChannelStatus(preference.id, channel.channel)"
                              >
                                <VIcon>{{ channel.icon }}</VIcon>
                              </VBtn>
                            </template>
                            <span>{{ channel.tooltip }}. Currently: <strong>{{ preference[channel.channel] ? 'On' : 'Off' }}</strong>
                            </span>
                          </VTooltip>
                        </VBadge>
                      </VCol>
                    </VRow>
                  </VListItemAction>
                </VListItem>
              </template>
            </VList>
          </VCol>
        </template>
      </VRow>
    </VContainer>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import FormMixin from "@/mixins/Form";
import moment from "moment";
// Ensure the silent datesa re correct
function datesAreValid() {
  // if (
  //   this.form.notifications_silent_from &&
  //   this.form.notifications_silent_to
  // ) {
  //   return moment(this.form.notifications_silent_from, "HH:mm").isBefore(
  //     moment(this.form.notifications_silent_to, "HH:mm")
  //   );
  // }
  return true;
}
export default {
  name: "NotificationPreferences",
  components: {},
  mixins: [SearchMixin, FormMixin],
  data() {
    return {
      modelType: "notificationPreference",
      dialogs: {
        notificationStart: false,
        notificationEnd: false,
      },
      time: {
        start: null,
        end: null,
      },
      hasFiles: false,
      defaultForm: {
        notifications_silent_from: false,
        notifications_silent_to: false,
        notifications_are_enabled: false,
      },
      channels: [
        {
          icon: "laptop",
          channel: "via_web",
          tooltip: "Enable notifications via your browser",
        },
        {
          icon: "smartphone",
          channel: "via_mobile",
          tooltip: "Enable notifications via the WeThrive mobile app",
        },
        // {
        //   icon: "message",
        //   channel: "via_sms",
        //   tooltip: "Enable notifications via sms",
        // },
        {
          icon: "email",
          channel: "via_email",
          tooltip: "Enable notifications via email",
        },
      ],
    };
  },
  validations: {
    form: {
      notifications_silent_from: {
        datesAreValid,
      },
      notifications_silent_to: {
        datesAreValid,
      },
    },
  },
  computed: {
    groupedPreferences() {
      return this.data.data.groupBy("type.group");
    },
    datesAreValid() {
      if (
        this.form.notifications_silent_from &&
        this.form.notifications_silent_to
      ) {
        return moment(this.form.notifications_silent_from, "HH:mm").isBefore(
          moment(this.form.notifications_silent_to, "HH:mm")
        );
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      doGetAll: "notificationPreference/getAll",
      doDelete: "notificationPreference/delete",
      doSave: "notificationPreference/save",
      doSaveUser: "user/save",
    }),
    onGetAll(params) {
      return this.doGetAll(params);
    },
    onSaveUser() {
      this.form.id = this.$user.id;
      const team = this.$team;
      return this.saveFormModel(this.doSaveUser).then((user) => {
        this.$auth.user({ ...this.$user, ...user, team });
      });
    },
    onSave(preference) {
      const data = this.prepareFormModel(
        { ...preference },
        {
          dontSave: ["type"],
        }
      );
      return this.saveFormModel(this.doSave, null, data);
    },
    updatePreference(preference) {
      this.data = [...[preference], ...this.types].unique((type) =>
        type instanceof Object ? type.slug : 1
      );
      this.$bus.$emit(
        "notificationSuccess",
        "Successfully connected preference"
      );
    },
    showError(message) {
      this.$bus.$emit(
        "notificationError",
        "Unable to connect preference: " + message
      );
    },
    removePreference(preference) {
      this.delete(preference).then((result) => {
        const type = this.types.find((type) => type.slug === preference.slug);
        const index = this.data.find((current) => current.id === preference.id);
        this.$log.debug("Removing", type, index);
        this.data.splice(index, 1, type);
      });
    },
    getIsPreferenceProperlyConnected(preference) {
      switch (preference.slug) {
        case "google":
          return preference.token
            ? preference.token.expires_in != null ||
                preference.token.expires_in !== undefined
            : true;
          break;

        default:
          break;
      }
    },
    getPreferenceDisconnectLink(preference) {
      switch (preference.slug) {
        case "google":
          return "https://mypreference.google.com/permissions?utm_source=google-preference&utm_medium=web";
          break;

        default:
          break;
      }
    },
    getChannelColor(enabled) {
      return enabled ? "success" : "disabled";
    },
    onSetChannelStatus(id, channel) {
      const index = this.data.data.findIndex((d) => d.id == id);
      this.$set(
        this.data.data[index],
        channel,
        !this.data.data[index][channel]
      );
      this.$nextTick(() => {
        this.$set(this.data.data[index], "isSaving", true);
        this.$set(this.data.data[index], "hasFiles", false);
        this.onSave(this.data.data[index]).then(() => {
          this.$delete(this.data.data[index], "isSaving");
          this.$set(this.data.data[index], "isSaved", true);
          setTimeout(() => {
            this.$delete(this.data.data[index], "isSaved");
          }, 2000);
        });
      });
    },
  },
  mounted() {
    this.form.notifications_silent_from = this.$user.notifications_silent_from;
    this.form.notifications_silent_to = this.$user.notifications_silent_to;
    this.form.notifications_are_enabled = this.$user.notifications_are_enabled;
  },
};
</script>
<style lang="scss">
#user-information {
  .v-list__tile {
    height: auto !important;
    max-height: 120px;
  }
}
</style>