<template>
  <div>
    <template v-if="verticleView">
      <div
        :style="{
          cursor: enableInfoDialog ? 'pointer' : 'auto',
          display: 'inline-grid',
          'text-decoration': isUserDeleted ? 'line-through' : 'none',
        }"
        @click="enableInfoDialog ? isDialogOpen = !isDialogOpen : undefined"
      >
        <VAvatar
          color="primary"
          size="70px"
          class="ma-auto"
        >
          <VImg
            v-if="isUserDeleted"
            src="https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"
          />
          <img
            v-else
            :src="user.photo_url"
          >
        </VAvatar>
        <div
          class="d-flex justify-center"
          style="width: 70px;"
        >
          <span
            class="text-truncate pt-1"
            :style="{
              'font-size': '14px', 
              'font-weight': 500, 
              'color': isUserDeleted ? '#666666' : '#252526',
            }"
          >
            {{ isUserDeleted ? "Deleted User" : user.first_name }}
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <slot>
        <VChip
          pill
          :outlined="outline"
          :color="color"
          :text-color="isUserDeleted ? '#666666' : textColor"
          :x-small="xSmall"
          :small="small"
          :large="large"
          :x-large="xLarge"
          :dark="dark"
          :light="light"
          :disabled="disabled"
          :close="closable"
          :class="customClass"
          :style="{
            cursor: enableInfoDialog ? 'pointer' : 'auto',
            'text-decoration': isUserDeleted ? 'line-through' : 'none',
          }"
          @click="enableInfoDialog ? isDialogOpen = !isDialogOpen : undefined"
          @click:close="$emit('close')"
        >
          <VAvatar left>
            <VImg
              v-if="isUserDeleted"
              src="https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"
            />
            <VImg
              v-else
              :src="user.photo_url"
            />
          </VAvatar>
          <slot>{{ isUserDeleted ? "Deleted User" : user.name }}</slot>
          <slot name="content-right" />
        </VChip>
      </slot>
    </template>
    <Dialog 
      max-width="525px"
      card-text-class="px-0"
      default-content-class=""
      :dismissable="false"
      :is-dialog-open="isDialogOpen && !isUserDeleted"
    >
      <UserProfile
        v-if="!isUserDeleted"
        :id="user.id"
        :user="user"
        @close="isDialogOpen = false"
      >
        <template slot="actions">
          <ChatButton
            v-if="enableChat"
            :user="user"
            :title="title"
            :members="members"
            :entity="entity"
            :thread="thread"
            :new-messages-count="newMessagesCount"
            :enable-edit="enableEdit"
          />
        </template>
      </UserProfile>
    </Dialog>
  </div>
</template>

<script>
import UserProfile from "./UserProfile";
import ChatButton from "./ChatButton";

export default {
  name: "UserDialog",
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    userDetails: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "default",
    },
    textColor: {
      type: String,
      default: "inherit",
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    activatorVOn: {
      type: Object,
      default: () => {},
    },
    enableChat: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    newMessagesCount: {
      type: [Number, String],
      default: 0,
    },
    members: {
      type: Array,
      default: () => [],
    },
    entity: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: null,
    },
    thread: {
      type: Object,
      default: () => {},
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    verticleView: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    enableInfoDialog: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UserProfile,
  },
  data: () => ({
    isDialogOpen: false,
  }),
  computed: {
    isUserDeleted() {
      return !this.user;
      // return this.item && this.item.hasOwnProperty("is_user_deleted") && this.item.is_user_deleted === true;
    },
  },
};
</script>