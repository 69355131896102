<script>
import utils from "@/services/utils";
import { mapActions, mapState, mapGetters } from "vuex";
import activityMixin from "@/mixins/Activity";
import DeletesItems from "@/mixins/DeletesItems";
import DialogViewMixin from "@/mixins/DialogView";
import HasTabsMixin from "@/mixins/HasTabs";
import CanFilter from "./CanFilter";
import DuplicatesItems from "@/mixins/DuplicatesItems";
import SupportsModelTypes from "./SupportsModelTypes";
const moment = require("moment");

export default {
  mixins: [
    activityMixin,
    DeletesItems,
    DuplicatesItems,
    DialogViewMixin,
    HasTabsMixin,
    CanFilter,
    SupportsModelTypes,
  ],
  props: {
    existingData: {
      type: [Object, Array],
      default: () => null,
    },
    existingConfig: {
      type: Object,
      default: () => {},
    },
    isFromExistingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const dateIcons = {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-chevron-up",
      down: "fa fa-chevron-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-sun-o",
      clear: "fa fa-trash",
      close: "fa fa-remove",
    };
    return {
      config: {},
      currentPage: 1,
      allDataProp: "data",
      enableBackgroundSearch: false,
      dateIcons: dateIcons,
      dates: {
        dueOn: {
          inline: true,
          sideBySide: true,
          minDate: moment(),
          useCurrent: false,
          icons: dateIcons,
        },
      },
      statOptions: {},
      searchWaitTimeout: null,
      localSearchData: null,
      getAllOnLoad: true,
      isFirstLoad: true,
      dataHasBeenPaginated: false,
      defaultData: {
        current_page: 0,
        last_page: 0,
        data: [],
      },
      enableInfiniteLoader: false,
      infiniteLoadParams: {},
      infiniteLoad: {
        current_page: 0,
        last_page: 0,
        data: [],
      },
      isBusy: false,
      selectedItems: [],
      tempExistingData: null,
    };
  },
  computed: {
    data() {
      this.$log.debug("[Search Mixin]: Getting", this.modelType);
      if (this.localSearchData instanceof Object) {
        return this.localSearchData;
      }
      if (this.existingData instanceof Object) {
        return this.preparedExistingData;
      }
      if (this.$store.state.hasOwnProperty(this.modelType)) {
        return (
          this.$store.state[this.modelType].data || { ...this.defaultData }
        );
      }
      return { ...this.defaultData };
    },
    items() {
      return this.data.items || null;
    },
    totalPages() {
      return this.data.last_page;
    },
    canShowPagination() {
      return this.pagination.pages > 1;
    },
    canGetMoreData() {
      return (
        !this.activity.isLoading &&
        !this.isFirstLoad &&
        this.currentPage <= this.pagination.pages
      );
    },
    pagination() {
      return {
        page: this.data.page || this.data.current_page || 1,
        current: this.data.page || this.data.current_page || 1,
        pages: this.data.pages || this.data.last_page || 1,
        count: this.data.total,
        perPage: this.data.per_page,
      };
    },
    preparedExistingData() {
      return this.tempExistingData instanceof Array
        ? { data: this.tempExistingData }
        : this.tempExistingData;
    },
    hasExistingData() {
      return this.existingData && this.existingData.length > 0;
    },
  },
  watch: {
    existingData: {
      handler(value) {
        if(value) {
          this.tempExistingData = value;
        }
      },
      deep: true,
    },
  },
  /**
   * Getting index config during created operation is best to prepare data needed for search
   */
  created() {
    if (this.onGetIndexConfig instanceof Function && !this.disableFilter) {
      this.onGetIndexConfig().then((result) => {
        this.config = { ...this.config, ...result };
        this.$emit("indexConfig", this.config);
        if (result.filters instanceof Array) {
          this.prepareSearchFilters(result.filters);
        }
        return result;
      });
    }
  },
  /**
   * Doing search during mounted operation is best if we want the components to use custom filters
   */
  mounted() {
    this.tempExistingData = JSON.parse(JSON.stringify(this.existingData));
    if (this.existingData instanceof Object) {
      this.$emit("searchComplete", this.preparedExistingData);
    } else if (this.onGetAll instanceof Function && this.getAllOnLoad) {
      this.doSearch(this.onGetAll);
    } else {
      this.$emit("doneLoading");
    }
  },
  methods: {
    ...mapActions({
      getTypeOptions: "getTypeOptions",
    }),
    onReset() {
      this.onResetFilter();
    },
    onFilterData(force) {
      if (this.onGetAll instanceof Function) {
        this.doSearch(this.onGetAll);
      }
    },
    refreshIndexConfig() {
      this.onGetIndexConfig().then((result) => {
        this.config = { ...this.config, ...result };
        this.$emit("indexConfig", this.config);
        if (result.filters instanceof Array) {
          this.prepareSearchFilters(result.filters);
        }
      });
    },
    inputChange() {
      this.onSearch(new Event("submit"));
    },
    doImmediateSearch(callback, e, data) {
      e && e.preventDefault();
      this.errors = null;
      this.filterStatus.submitText = "Searching...";
      this.dataHasBeenPaginated = false;

      this.$emit("searchStart");

      /* data = Object.assign(
        {
          options: ["items"],
        },
        utils.flattenObject(this.getSanitizedSearchFilters()),
        data || {}
      ); */

      /* 
        Commented above code to fix duplicate filter key
        Related task: https://wethrive.atlassian.net/browse/PM-336
       */
      data = {
        options: ["items"],
        filter: this.getSanitizedSearchFilters(),
        ...(data || {})
      };

      this.$log.debug("Data", data);

      //Explicitly add the search string parameter
      if (this.filter.s && !data.s) {
        data.s = this.filter.s;
      }
      this.infiniteLoadParams = data;

      this.$log.debug("[Search Mixin]: Getting with params", data);
      return callback(data)
        .then((result) => {
          this.$log.debug("[Search Mixin]: Get Complete", result);
          this.$emit("searchComplete", result);
          this.filterStatus.submitText = "Done";
          this.filterStatus.submitVariant = "secondary";
          setTimeout(() => {
            this.filterStatus.submitText = "Search";
            this.filterStatus.submitVariant = "success";
          }, 2500);
          setTimeout(() => {
            if (this.isFirstLoad) {
              this.isFirstLoad = false;
            }
          }, 1000);
          if(this.enableInfiniteLoader) {
            this.infiniteLoad = result;
          }
          return result;
          // this.pagination = result.pagination || this.pagination;
        })
        .catch((error) => {
          this.$log.error(error);
          // Only hide information if response if not a 404 since the data doesn't exist anyway
          // if([404].indexOf(error.code) === -1) {
          //     this.activity.isLoading = false;
          // }
          this.errors = this.parseErrors(
            error.errors ? error.errors : error.message
          );
        })
        .then(() => {
          this.$emit("searchComplete", this.data);
          this.filterStatus.submitText = "Search";
        });
    },
    doBackgroundSearch(callback, e, data, userOptions, timeout) {
      return this.$nextTick().then(() => {
        clearTimeout(this.searchWaitTimeout);
        if (timeout !== true) {
          const realTimeout = parseInt(timeout) || 1000;
          this.searchWaitTimeout = setTimeout(
            () => this.doBackgroundSearch(callback, e, data, userOptions, true),
            realTimeout
          );
        }

        if (timeout === true) {
          return this.doImmediateSearch(callback, e, data);
        }
        return Promise.resolve(false);
      });
    },
    doSearch(callback, e, data) {
      if (this.enableBackgroundSearch) {
        return this.doBackgroundSearch(callback, e, data);
      } else {
        return this.doImmediateSearch(callback, e, data);
      }
    },
    doGetMore(callback, e, data) {
      e && e.preventDefault();
      this.errors = null;
      this.$emit("startLoading");
      this.dataHasBeenPaginated = true;

      /* data = Object.assign(
        {
          page: this.pagination.current + 1,
        },
        utils.flattenObject(this.getSanitizedSearchFilters()),
        data || {}
      ); */

      /* 
        Commented above code to fix duplicate filter key
        Related task: https://wethrive.atlassian.net/browse/PM-336
       */
      data = {
        page: this.pagination.current + 1,
        filter: this.getSanitizedSearchFilters(),
        ...(data || {})
      };

      //Explicitly add the search string parameter
      if (this.filter.s && !data.s) {
        data.s = this.filter.s;
      }
      this.infiniteLoadParams = data;

      this.$log.debug(
        "[Paginate Mixin]: Getting with params",
        data,
        this.filter
      );
      return callback(data)
        .then((result) => {
          this.$log.debug("[Paginate Mixin]: Got page", data.page, result);
          // this.data = result;
          setTimeout(() => {
            if (this.isFirstLoad) {
              this.isFirstLoad = false;
            }
          }, 1000);
          this.$emit("doneLoading");
          if(this.enableInfiniteLoader) {
            this.infiniteLoad = result;
          }
          return this.onAfterGetMore(result) || result;
        })
        .catch((error) => {
          // Only hide information if response if not a 404 since the data doesn't exist anyway
          if ([404].indexOf(error.code) === -1) {
            this.$emit("loadError");
          }
        });
    },
    getOptions(type) {
      return this.config[type];
    },
    searchComplete(result) {
      // this.data = result;
      this.$emit("doneLoading");
    },
    parseErrors(errors) {
      errors =
        typeof errors === "string"
          ? [errors]
          : Object.values(errors).map((error) => {
              if (typeof error === "array") {
                return error[1];
              } else if (typeof error === "object") {
                return Object.values(error).length
                  ? Object.values(error).join("\n")
                  : error.message;
              } else {
                return error;
              }
            });
      return errors;
    },
    onGetMore(params) {
      this.currentPage = params.page || 1;
      if (
        this.onGetAll instanceof Function &&
        (this.canGetMoreData || (params.sort || []).length > 0)
      ) {
        this.$log.debug("Need to get more", params);
        return this.doGetMore(this.onGetAll, null, params);
      }
    },
    onInfiniteLoad($infinite, pagination) {
      if(
        !this.busy && 
        this.infiniteLoad.data.length && 
        this.infiniteLoad.current_page < this.infiniteLoad.last_page
      ) {
        this.busy = true;
        this.onGetAll({
          ...this.infiniteLoadParams,
          paginated: true,
          page: pagination.page + 1,
        }).then((result) => {
          this.infiniteLoad.current_page =
            result.current_page || this.data.current_page;
          this.infiniteLoad.to = result.to || this.data.to;
          this.infiniteLoad.data.push(...(result.data || []));
          setTimeout(() => {
            if ($infinite) {
              this.busy = false;
              $infinite.loaded();
              if (result.current_page >= result.last_page) {
                $infinite.complete();
              }
            }
          }, 500);
        });
      } else {
        $infinite.complete();
      }
    },
    onAfterGetMore(result) {},
    onSearch(params) {
      if (this.onGetAll instanceof Function) {
        this.$log.debug("Need to search", params);
        this.doSearch(this.onGetAll, null, params);
      }
    },
    doLocalSearch(params) {
      if(params.s) {
        this.tempExistingData = (this.existingData.data || this.existingData).filter((item) => (item.name || item.title).includes(params.s) || (item.email || "").includes(params.s));
      } else {
        this.tempExistingData = this.existingData;
      }
    },
    isColumnVisible(name, value, hiddenKey = "hidden", hiddenValue = true) {
      const header = (this.headers || []).find((h) => h[name] === value) || {};
      if (Object.keys(header).length) {
        return header[hiddenKey] !== hiddenValue;
      } else {
        return false;
      }
    },
    onToggleItemLoading(item, value) {
      const index = this.data.data.findIndex((i) => {
        if (item instanceof Object) {
          return parseInt(item.id) == parseInt(i instanceof Object ? i.id : i);
        } else {
          return item == (i instanceof Object ? parseInt(i.id) : i);
        }
      });
      if (index > -1) {
        this.data.data.splice(index, {
          ...this.data.data[index],
          ...{
            isLodaing:
              value !== undefined ? value : !!this.data.data[index].isLoading,
          },
        });
      }
    },
    onSelectedItems(items) {
      this.selectedItems = items;
      this.$emit("selectedItems", this.selectedItems);
    },
    hasActionsAccess(item) {
      if(item) {
        return (this.$isUserAdmin && this.$viewProgramAsAdmin) || (this.belongsToMe(item.user_id) && this.checkMentorPermission(this.modelType));
      }
      return (this.$isUserAdmin && this.$viewProgramAsAdmin) || this.checkMentorPermission(this.modelType);
    },
  },
};
</script>