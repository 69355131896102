import Vue from 'vue';
import Pusher from 'pusher-js';
import VueEcho from 'vue-echo';
import dotenv from 'dotenv';
dotenv.config();

Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER || 'us2',
    authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT
});