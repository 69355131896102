<template>
  <VCard :width="width">
    <slot name="title">
      <VList>
        <VListItem>
          <VListItemAvatar>
            <VImg :src="user.photo_url" />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle>{{ user.name }}</VListItemTitle>
            <VListItemSubtitle>{{ user.email }}</VListItemSubtitle>
          </VListItemContent>
          <VListItemAction>
            <VBtn
              icon
              @click="isDialogOpen = false"
            >
              <VIcon>close</VIcon>
            </VBtn>
          </VListItemAction>
        </VListItem>
      </VList>
    </slot>
    <VList>
      <VListItem @click="() => {}">
        <VListItemContent>
          <VListItemSubtitle>{{ user.name }}</VListItemSubtitle>
        </VListItemContent>
        <VListItemAction>
          <VBtn
            icon
            small
            @click="copyTextToClipboard(user.name)"
          >
            <VIcon>file_copy</VIcon>
          </VBtn>
        </VListItemAction>
      </VListItem>
      <VListItem @click="() => {}">
        <VListItemAction>
          <VIcon>mail</VIcon>
        </VListItemAction>
        <VListItemContent>
          <VListItemSubtitle>{{ user.email }}</VListItemSubtitle>
        </VListItemContent>
        <VListItemAction>
          <VBtn
            @click="copyTextToClipboard(user.email)"
            icon
            small
          >
            <VIcon>file_copy</VIcon>
          </VBtn>
        </VListItemAction>
      </VListItem>
      <VListItem @click="() => {}">
        <VListItemAction>
          <VIcon>person</VIcon>
        </VListItemAction>
        <VListItemContent>
          <VListItemSubtitle><RoleChip :user="user" /></VListItemSubtitle>
        </VListItemContent>
      </VListItem>
      <VListItem
        v-for="(detail, key) in userDetails"
        :key="key"
        @click="() => {}"
      >
        <VListItemAction>
          <VIcon>{{ detail.icon }}</VIcon>
        </VListItemAction>
        <VListItemSubtitle>{{ detail.value }}</VListItemSubtitle>
      </VListItem>
    </VList>
    <VCardText v-if="user.profile instanceof Object && user.profile.bio">
      <h2>Bio</h2>
      <p
        class="formatted"
        v-html="user.profile.bio"
      />
    </VCardText>
    <VCardText>
      <AssociatedContent
        title="Details"
        :model="user"
        :only="['programs']"
      />
    </VCardText>
  </VCard>
</template>

<script>
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
export default {
  name: "UserViewDetails",
  components: { AssociatedContent },
  props: {
    user: {
      type: Object,
      default: () => null
    },
    userDetails: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: "300px"
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    hasUser() {
      return this.user instanceof Object;
    }
  }
};
</script>