<template>
  <VChip
    rounded
    text-color="#000"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :class="{
      'role--owner': isUserOwner,
      'role--student': isUserStudent,
      'role--mentor': isUserMentor,
      'role--organization-admin': isUserOrganizationAdmin,
      'role--missing': isUserUnassigned
    }"
  >
    <slot>
      {{ roleName }}
    </slot>
  </VChip>
</template>

<script>
export default {
  name: "RoleChip",
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    realUser() {
      return (this.user || {}).id ? this.user : this.$user;
    },
    teamUser() {
      return this.realUser.team_user || {};
    },
    teamUserRoleName() {
      return this.teamUser.role || "Unassigned";
    },
    isUserOwner() {
      return this.teamUserRoleName.toLowerCase() === "owner";
    },
    isUserStudent() {
      return this.teamUserRoleName.toLowerCase() === "student";
    },
    isUserMentor() {
      return this.teamUserRoleName.toLowerCase() === "mentor";
    },
    isUserOrganizationAdmin() {
      return this.teamUserRoleName.toLowerCase() === "organization-admin";
    },
    isUserUnassigned() {
      return !this.teamUser.is_approved || this.teamUserRoleName === "member";
    },
    roleName() {
      return (
        this.realUser.role_name ||
        this.featureName((this.realUser.team_user || {}).role || "student") ||
        "Unassigned"
      );
    },
  },
  methods: {
    clicked: function (item, event) {
      this.action(item);
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss">
.v-chip {
  &[class*="role--"] {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    font-weight: 500;
  }

  &.role--student {
    background-color: #f9f9fe !important;
  }

  &.role--mentor {
    background-color: #ffe79a !important;
  }

  &.role--organization-admin {
    background-color: #c6fc99 !important;
  }

  &.role--owner {
    background-color: #0095fc !important;
    color: #fff !important;
  }

  &.role--missing {
    background-color: #ff4e68 !important;
    color: #fff !important;
  }
}
</style>