import api from '../../services/billing';
export default function (helper) {
  // Team types
  const TYPES = {
    BILLING_CANCEL_SUBSCRIPTION: 'BILLING_CANCEL_SUBSCRIPTION',
    BILLING_GET_PLANS: 'BILLING_GET_PLANS',
    BILLING_GET_INVOICES: 'BILLING_GET_INVOICES',
    BILLING_GET_USER_DISCOUNTS: 'BILLING_GET_USER_DISCOUNTS',
    BILLING_GET_TEAM_DISCOUNTS: 'BILLING_GET_TEAM_DISCOUNTS',
    BILLING_REDEEM_COUPON: 'BILLING_REDEEM_COUPON',
    BILLING_UPDATE_PAYMENT_METHOD: 'BILLING_UPDATE_PAYMENT_METHOD',
    BILLING_CREATE_SUBSCRIPTION: 'BILLING_CREATE_SUBSCRIPTION',
    BILLING_UPDATE_SUBSCRIPTION: 'BILLING_UPDATE_SUBSCRIPTION',
    UPDATE_BILLABLE: 'UPDATE_BILLABLE'
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('billing');
  const state = helper.state({
    plans: [],
    invoices: [],
    coupons: [],
    paymentMethod: {},
    userDiscounts: [],
    teamDiscounts: [],
    billable: {}
  }, true);
  const getters = helper.getters({
    plans: state => state.plans,
    invoices: state => state.invoices,
    coupons: state => state.coupons,
    userDiscounts: state => state.userDiscounts,
    teamDiscounts: state => state.teamDiscounts,
    paymentMethod: state => state.paymentMethod
  }, true);

  const actions = helper.actions({
    getPlans(context, params) {
      helper.log().info(`[Store: Billing}]: Get Plans`, params);
      return new Promise((resolve, reject) => {
        if (state.plans.length) {
          resolve(state.plans);
        } else {
          return helper.api().getPlans(params).then((response) => {
            helper.log().info(`[Store: Billing]: Got all plans}`, response.data);
            context.commit(TYPES.BILLING_GET_PLANS, {
              params,
              result: response.data
            });
            resolve(context.getters.plans);
          }).catch((error) => {
            helper.log().info(`[Store: Billing]: Error getting plans`, error);
            reject(error);
          });
        }
      });
    },
    getInvoices(context, params) {
      helper.log().info(`[Store: Billing}]: Get Invoices`, params);
      return new Promise((resolve, reject) => {
        return helper.api().getInvoices(params).then((response) => {
          helper.log().info(`[Store: Billing]: Got all invoices}`, response.data);
          context.commit(TYPES.BILLING_GET_INVOICES, {
            params,
            result: response.data
          });
          resolve(context.getters.invoices);
        }).catch((error) => {
          helper.log().info(`[Store: Billing]: Error getting invoices`, error);
          reject(error);
        });
      });
    },
    redeemCoupon(context, params) {
      helper.log().info('[Store: Billing]: Redeem Coupon', params);
      return helper.api().redeemCoupon(params).then((response) => {
        helper.log().info(`[Store: Billing]: Redeem Coupon`, response);
        const data = response.data.data;
        context.commit(TYPES.BILLING_REDEEM_COUPON, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Billing]: Error Redeeming Coupon`, error);
        throw error;
      });
    },
    createSubscription(context, params) {
      helper.log().info('[Store: Billing]: Create Subscription', params);
      return helper.api().createSubscription(params).then((response) => {
        helper.log().info(`[Store: Billing]: Create Subscription`, response);
        const data = response.data.data;
        context.commit(TYPES.BILLING_CREATE_SUBSCRIPTION, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Billing]: Error Creating Subscription`, error);
        throw error;
      });
    },
    updatePlan(context, params) {
      helper.log().info('[Store: Billing]: Update Subscription', params);
      return helper.api().updatePlan(params).then((response) => {
        helper.log().info(`[Store: Billing]: Update Subscription`, response);
        const data = response.data.data;
        context.commit(TYPES.BILLING_UPDATE_SUBSCRIPTION, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Billing]: Error Updating Subscription`, error);
        throw error;
      });
    },
    getUserDiscounts(context, id) {
      const params = {
        id,
        billingUser: true
      };
      helper.log().info(`[Store: Billing}]: Get User Discounts`, params);
      return new Promise((resolve, reject) => {
        return helper.api().getUserDiscounts(params).then((response) => {
          helper.log().info(`[Store: Billing]: Got all User Discounts}`, response.data);
          context.commit(TYPES.BILLING_GET_USER_DISCOUNTS, {
            params,
            result: response.data
          });
          resolve(context.getters.invoices);
        }).catch((error) => {
          helper.log().info(`[Store: Billing]: Error getting user discounts`, error);
          reject(error);
        });
      });
    },
    getTeamDiscounts(context) {
      helper.log().info(`[Store: Billing}]: Get Team Discounts`);
      return new Promise((resolve, reject) => {
        return helper.api().getTeamDiscounts().then((response) => {
          helper.log().info(`[Store: Billing]: Got all Team Discounts}`, response.data);
          context.commit(TYPES.BILLING_GET_TEAM_DISCOUNTS, {
            params,
            result: response.data
          });
          resolve(context.getters.invoices);
        }).catch((error) => {
          helper.log().info(`[Store: Billing]: Error getting team discounts`, error);
          reject(error);
        })
      });
    },
    cancelSubscription(context, params) {
      helper.log().info('[Store: Billing]: Cancel Subscription', params);
      return helper.api().cancelSubscription(params).then((response) => {
        helper.log().info(`[Store: Billing]: Cancel Subscriptions`, response);
        const data = response.data.data;
        context.commit(TYPES.BILLING_CANCEL_SUBSCRIPTION, {
          params: params,
          data
        });
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Billing]: Error Cancelling Subscription`, error);
        throw error;
      });
    },
    updatePaymentMethod(context, params) {
      helper.log().info('[Store: Billing]: Update Payment Method', params);
      return helper.api().updatePaymentMethod(params).then((response) => {
        helper.log().info(`[Store: Billing]: Update Payment Method`, response);
        const data = response.data.data;
        context.commit(TYPES.BILLING_UPDATE_PAYMENT_METHOD, {
          params,
          data
        });
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Billing]: Error Updating Payment Method`, error);
        throw error;
      });
    },
    updateBillable(context, params) {
      if (params instanceof Object) {
        helper.log().info('[Store: BIlling]: Update Billable', params)
        context.commit(TYPES.UPDATE_BILLABLE, params);
      } else {
        return Promise.resolve({
          success: false
        });
      }
    }
  }, 'Team');

  const mutations = helper.mutations({
    [TYPES.BILLING_GET_PLANS](state, data) {
      state.plans = data.result;
    },
    [TYPES.BILLING_GET_INVOICES](state, data) {
      state.invoices = data.result;
    },
    [TYPES.BILLING_UPDATE_PAYMENT_METHOD](state, data) {},
    [TYPES.BILLING_REDEEM_COUPON](state, data) {},
    [TYPES.BILLING_CREATE_SUBSCRIPTION](state, data) {
      state.billable.subscriptions = data;
    },
    [TYPES.BILLING_UPDATE_SUBSCRIPTION](state, data) {
      state.billable.subscriptions = data;
    },
    [TYPES.BILLING_CANCEL_SUBSCRIPTION](state, data) {},
    [TYPES.UPDATE_BILLABLE]: (state, params) => {
      state.billable = {
        ...state.billable,
        ...params
      };
    }
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}