<template>
  <VRow
    row
    wrap
    fill-height
    fluid
    :class="{
      'is-loading': getModel ? activity.isFormLoading : activity.isLoading,
    }"
  >
    <VCol
      v-if="isStandalone"
      cols="12"
    >
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h1>Registration Settings</h1>
        </template>
        <template #create-action>
          <ExpandableButton
            large
            text="Save Changes"
            icon="save"
            button-class="wethrive-alt"
            :disabled="!isFormReady"
            :action="() => onSave()"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      cols="12"
      v-if="hasMessagesOrErrors"
    >
      <Alerts
        :messages="messages"
        :errors="errors"
      />
    </VCol>
    <VCol cols="12">
      <VTabs
        v-model="activeTab"
        lazy
        slider-color="primary"
        :grow="$vuetify.breakpoint.smAndDown"
        :vertical="$vuetify.breakpoint.mdAndUp"
        :class="{
          'hideTabs': !isStandalone
        }"
      >
        <VTab
          v-for="(tab, key) in tabs"
          :key="key"
          lazy
        >
          {{ tab.title }}
        </VTab>
        <VTabItem>
          <VResponsive
            height="325"
            id="registration-banner"
            :class="{
              'px-5': true,
              'd-flex': true,
              'align-center justify-center': bannerImage == null,
              'align-end justify-end': bannerImage != null,
            }"
          >
            <VImg
              id="banner-image"
              cover
              alt="Set Registration Banner"
              height="325"
              style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
              :src="bannerImage ? bannerImage : 'https://via.placeholder.com/700x350'"
            >
              <template #placeholder>
                <VRow
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <VSkeletonLoader
                    width="100%"
                    type="image"
                    class="mx-auto"
                  />
                </VRow>
              </template>
            </VImg>
            <div
              class="d-flex"
              :style="{
                opacity: '0.75',
                marginRight: bannerImage ? 0 : 'auto',
                marginLeft: 'auto',
                marginBottom: bannerImage ? '12px' : 'auto'
              }"
            >
              <VBtn
                v-if="bannerImage"
                height="44px"
                color="error"
                class="mr-2 px-4 dashed text-right white"
                @click="onResetBannerImage"
              >
                <VIcon>close</VIcon>
                Remove
              </VBtn>
              <FileUpload
                as-button
                button-class="px-4 dashed text-right info sligthly-rounded"
                :outlined="false"
                :multiple="false"
                :disabled="activity.isFormLoading"
                @formData="onSetBannerImage"
              >
                &nbsp;Change Header
              </FileUpload>
            </div>
          </VResponsive>
          <VCard v-if="isBetaFeature($root.env.VUE_APP_FEATURE_PIPELINES)">
            <VCardTitle>Configure registration and pipeline settings below</VCardTitle>
            <VCardText>
              <VRow
                row
                wrap
              >
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSwitch
                    v-model="form.settings.pipeline.is_application_editable_until_deadline.value"
                    inset
                    label="Enable application edit until deadline"
                  />
                  <p class="caption">
                    Enabling this will allow applicants to edit their applications up until the deadline for the application. If not enabled, applicants will not be able to edit their applications after submitting them and ensuring that once it’s submitted, it’s final
                  </p>
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSwitch
                    v-model="form.settings.pipeline.is_chat_enabled.value"
                    inset
                    label="Enable applicant chat"
                  />
                  <p class="caption">
                    Enable this to allow applicants to chat with the team.
                    <strong>NOTE</strong>: You will need to configure this properly by assigning reviewers to the pipeline or per application, otherwise applicants will not be able to chat with anyone. Assigned reviewers will receive notifications from applicants who wish to chat with someone within the pipeline
                  </p>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VTabItem>
        <VTabItem>
          <VCard>
            <VCardTitle>Configure Applicant Portal settings below</VCardTitle>
            <VCardText v-if="$team.is_enterprise">
              <VRow
                row
                wrap
              >
                <VCol
                  cols="12"
                  md="6"
                >
                  <DynamicInputs
                    v-model="form.settings.applicant_portal_learn_more_button"
                    append-text="Select ..."
                    title="Select what you want the learn more button to do"
                    choice-mode="user"
                    :enable-sections="false"
                    :card="false"
                    :enable-files="false"
                    :max="1"
                    :input-choices="applicantPortalButtonOptions"
                    @update:input="(index, value) => onSetApplicantPortal('learn_more_button', index, value)"
                    @remove:input="(index, value) => onRemoveApplicantPortal('learn_more_button', index)"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <DynamicInputs
                    v-model="form.settings.applicant_portal_faq_button"
                    append-text="Select ..."
                    title="Select what you want the faq button to do"
                    choice-mode="user"
                    :enable-sections="false"
                    :card="false"
                    :enable-files="false"
                    :max="1"
                    :input-choices="applicantPortalButtonOptions"
                    @update:input="(index, value) => onSetApplicantPortal('faq_button', index, value)"
                    @remove:input="(index, value) => onRemoveApplicantPortal('faq_button', index)"
                  />
                </VCol>
                <VCol
                  v-if="$team.can_customize"
                  cols="12"
                >
                  <VCard class="mb-2">
                    <VCardTitle style="line-height: 24px" />
                    <VCardText>
                      <DynamicInputs
                        v-model="form.settings.applicant_portal_extra_buttons"
                        append-text="Select ..."
                        title="Add extra buttons/links to the application portal"
                        choice-mode="user"
                        :enable-sections="false"
                        :card="false"
                        :enable-files="false"
                        :input-choices="applicantPortalButtonOptions"
                        @new:input="onNewExtraButton"
                        @remove:input="onRemoveExtraButton"
                      />
                    </VCardText>
                  </VCard>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VTabItem>
        <VTabItem>
          <VCard>
            <!-- <VCardTitle>
              <VCol
                grow
                py-0
              >
                {{ form.uses_multiple_register_pipelines === true ? 'Multiple' : 'Single' }} {{ featureName('Pipeline', form.uses_multiple_register_pipelines ? 'pluralize' : 'singularize') }}
              </VCol>
              <VCol
                shrink
                py-0
              >
                <VSwitch
                  class="inline"
                  style="height: 19.5px"
                  v-model="form.uses_multiple_register_pipelines"
                />
              </VCol>
            </VCardTitle> -->
            <VCheckbox
              v-model="form.open_registration"
              persistent-hint
              type="checkbox"
              label="Anyone can register"
              hint="If unchecked, an admin will have to manually add each user to the Workspace"
              class="mt-0 pt-0 pb-4 pl-2"
              :disabled="isCanRegisterDisabled"
            />
            <VCardText v-show="!form.uses_multiple_register_pipelines">
              <template>
                <Autocomplete
                  v-model="form.application_pipeline"
                  :items="form.pipelines"
                  item-value="id"
                  item-text="title"
                  append-inner-icon="arrow-down"
                  :placeholder="`Select the main ${featureName('Application').toLowerCase()} ${featureName('Pipeline').toLowerCase()}`"
                  :label="`${featureName('Application')} ${featureName('Pipeline').singularize()}`"
                />
              </template>
            </VCardText>
            <VCardText v-show="form.uses_multiple_register_pipelines">
              <VAlert
                :value="!(form.register_logic || []).length"
                type="warning"
              >
                You have not configured any logic. Potential users will be able to signup but you won't currently collect any extra information from them.
              </VAlert>
              <VExpansionPanels
                v-model="panel"
                multiple
                focusable
                class="elevation-0 mb-3"
              >
                <VExpansionPanel v-if="form.register_logic_message">
                  <VExpansionPanelHeader
                    hide-actions
                    style="pointer-events: none;"
                  >
                    <VCol sx12>
                      <strong>Welcome Content</strong>
                      <p>This section can be used to add additional text, such as instructions or welcome language to prospective applicants or Pipeline users. Click anywhere on this window to open this section.</p>
                    </VCol>
                  </VExpansionPanelHeader>
                  <template #actions>
                    <VIcon color="info">
                      settings
                    </VIcon>
                  </template>
                  <VExpansionPanelContent class="px-4">
                    <TextField
                      v-model="form.register_logic_message.name"
                      label="Title"
                    />
                    <Editor
                      v-model="form.register_logic_message.value"
                      label="Message"
                      class="mb-6"
                    />
                    <VCard
                      v-for="(option, index) in form.register_logic_message.default_value"
                      outlined
                      class="pa-4 mb-4"
                      :key="`message-row-${index}`"
                    >
                      <VRow>
                        <VCol>
                          <TextField
                            v-model="option.title"
                            persistent-hint
                            label="Title"
                            class="pb-2"
                          />
                          <Editor
                            v-model="option.description"
                            auto-grow
                            filled
                            outlined
                            placeholder="Type content here..."
                            class="content-editor mb-4"
                          />
                        </VCol>
                        <VBtn
                          icon
                          text
                          style="position: absolute; right: -12px; top: -12px;"
                          @click="onRemoveMessagePanel(index)"
                        >
                          <VIcon color="error">
                            mdi-close-circle
                          </VIcon>
                        </VBtn>
                      </VRow>
                    </VCard>
                    <VRow>
                      <VCol class="d-flex">
                        <VSpacer />
                        <VBtn @click="onNewMessagePanel">
                          <VTooltip top>
                            <template #activator="{on}">
                              <VIcon
                                v-on="on"
                                icon
                              >
                                mdi-information-outline
                              </VIcon>
                            </template>
                            Use this to add a toggle that encloses additional information.
                            <br>
                            <img
                              src="../../images/expansion-panel-info.gif"
                              height="250px"
                              width="100%"
                              class="py-2"
                            >
                          </VTooltip>
                          <span class="px-1">
                            Add Expansion Panel
                          </span>
                          <VIcon>add</VIcon>
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VExpansionPanelContent>
                </VExpansionPanel>
                <!-- <VExpansionPanel>
                  <VExpansionPanelHeader>
                    <span>
                      <VIcon color="warning">warning</VIcon>
                      &nbsp;Multiple Pipelines: Overrides the default {{ featureName('Application', 'singularize').toLowerCase() }} {{ featureName('Pipeline', 'singularize').toLowerCase() }}!
                    </span>
                  </VExpansionPanelHeader>
                  <template v-slot:actions>
                    <VIcon color="info">
                      info
                    </VIcon>
                  </template>
                  <VExpansionPanelContent>
                    <VAlert
                      :value="true"
                      type="info"
                    >
                      <p>Here you can configure the logic you would like to use</p>
                      <p>
                        For example if you want
                        <code>Teachers</code>
                        to go to {{ featureName('Pipeline') }}
                        <code>Teacher {{ featureName('Pipeline') }}</code> enter
                        <em>Teachers</em> in the title field and then select
                        <em>Teacher {{ featureName('Pipeline') }}</em> in the corresponding dropdown
                      </p>
                      <p>During signup the user will be directed to the appropriate pipeline based on the item they choose from below.</p>
                    </VAlert>
                  </VExpansionPanelContent>
                </VExpansionPanel> -->
              </VExpansionPanels>
              <Draggable
                v-model="form.register_logic"
                draggable=".dynamic-input-container"
                handle=".dynamic-input-handle"
                :no-transition-on-drag="true"
                :animation="200"
                @change="event => $set(event.moved.element, 'priority', event.moved.newIndex)"
              >
                <transition-group
                  name="dynamic-input-container"
                  type="transition"
                  :css="true"
                  tag="div"
                >
                  <VRow
                    row
                    v-for="(logic, index) in form.register_logic"
                    :key="`logic-row-${index}`"
                    :class="{
                      'dynamic-input-container': true,
                      'highlighter': highlightPipeline && logic.highlight,
                    }"
                  >
                    <VCol shrink>
                      <VIcon
                        style="transform: translateY(50%)"
                        class="dynamic-input-handle"
                      >
                        drag_indicator
                      </VIcon>
                    </VCol>
                    <VCol :key="`logic-title-pipeline-${index}`">
                      <TextField
                        v-model="logic.title"
                        persistent-hint
                        label="Title"
                        hint="This will be the text for the option that your user sees when they select this option."
                        class="pb-2"
                        :disabled="logic.deleted"
                        @blur="$v.$touch()"
                      />
                      <Autocomplete
                        v-model="logic.pipeline_id"
                        persistent-hint
                        item-text="title"
                        item-value="id"
                        hint="This is the Pipeline they'll be routed to when they proceed forward after selecting the appropriate Pipeline."
                        :label="`Select ${featureName('Pipeline')}`"
                        :disabled="logic.deleted"
                        :items="options.pipelines"
                        :error="$v.form.register_logic.$each[index].pipeline_id.$error"
                        @input="$v.form.register_logic.$each[index].pipeline_id.$touch()"
                        @blur="$v.$touch()"
                      />
                    </VCol>
                    <VCol
                      shrink
                      :key="`logic-delete-${index}`"
                      align-center
                      class="d-flex"
                    >
                      <VBtn
                        @click="onRemoveRegisterLogic(logic.id ? logic : index)"
                        icon
                        text
                      >
                        <VIcon :color="logic.deleted ? 'success' : 'error'">
                          {{ logic.deleted ? 'restore' : 'delete' }}
                        </VIcon>
                      </VBtn>
                    </VCol>
                  </VRow>
                </transition-group>
              </Draggable>
              <VRow
                row
                align-center
                justify-end
              >
                <div>
                  <VBtn
                    dashed
                    :block="$vuetify.breakpoint.smAndDown"
                    @click="onNewRegisterLogic"
                  >
                    Add {{ featureName('Pipeline').singularize() }}
                    <VIcon>add</VIcon>
                  </VBtn>
                </div>
              </VRow>
            </VCardText>
          </VCard>
        </VTabItem>
        <!-- <VTabItem>
          <Share />
        </VTabItem> -->
      </VTabs>
    </VCol>
  </VRow>
</template>

<script>
// import Croppa from "vue-croppa";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import Draggable from "vuedraggable";
import SearchMixin from "@/mixins/Search";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import DownloadsFilesMixin from "@/mixins/DownloadsFiles";
import { mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import Share from "./Share";

export default {
  name: "TeamRegistrationSettingsPanel",
  mixins: [FormMixin, MetadataMixin, SearchMixin, DownloadsFilesMixin],
  props: {
    isStandalone: {
      type: Boolean,
      default: true,
    },
    getModel: {
      type: Boolean,
      default: false,
    },
    highlightPipeline: {
      type: Boolean,
      default: false,
    },
    pipeline: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: [String, Number],
      default: 0,
    },
  },
  components: {
    IndexHeader,
    Draggable,
    DynamicInputs,
    // Share,
  },
  data() {
    return {
      modelType: "Settings",
      canGetModel: this.getModel || this.isStandalone,
      date: null,
      panel: [0],
      userFormUnwatcher: null,
      formHasFiles: true,
      autoloadViewModel: false,
      saveOnly: [
        "id",
        "open_registration",
        "application_pipeline",
        "register_logic_message",
        "register_logic",
        "register_banner",
        "settings",
        "uses_multiple_register_pipelines",
      ],
      defaultForm: {
        id: null,
        open_registration: false,
        application_pipeline: null,
        register_logic: [],
        register_banner: {},
        register_logic_message: {
          type: "Text",
          name: "Select the application",
          description: "",
        },
        uses_multiple_register_pipelines: true,
        settings: {
          applicant_portal_extra_buttons: [],
          applicant_portal_learn_more_button: -1,
          applicant_portal_faq_button: -1,
          pipeline: {
            is_chat_enabled: { value: false },
            is_application_editable_until_deadline: { value: false },
          },
        },
      },
      options: {
        countries: [],
      },
      tabs: [
        {
          title: "General",
        },
        {
          title: "Applicant Portal",
        },
        {
          title: "Landing Page",
        },
        /* {
          title: "Sharing",
        }, */
      ],
    };
  },
  validations: {
    form: {
      register_logic: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.hasOwnProperty("register_logic") && nestedModel.register_logic.length > 0;
        }),
        $each: {
          pipeline_id: { required },
        },
      },
    },
  },
  computed: {
    applicantPortalButtonOptions() {
      return [
        {
          text: `Load ${this.featureName("lesson")
            .singularize()
            .toLowerCase()}?`,
          value: "Relation",
          asyncKey: "load-async-lesson",
          loadFrom: this.doGetLessons,
          asyncType: "lesson",
          options: {
            label: `Select ${this.featureName("lesson")
              .singularize()
              .toLowerCase()}`,
            placeholder: `Select ${this.featureName("lesson")
              .singularize()
              .toLowerCase()}`,
            itemText: "title",
          },
        },
        {
          text: `Load ${this.featureName("content")
            .singularize()
            .toLowerCase()}?`,
          value: "Relation",
          asyncKey: "load-async-content",
          asyncType: "content",
          loadFrom: this.doGetContent,
          options: {
            label: `Select ${this.featureName("content")
              .singularize()
              .toLowerCase()}`,
            placeholder: `Select ${this.featureName("content")
              .singularize()
              .toLowerCase()}`,
            itemText: "title",
          },
        },
        {
          text: "Load a video",
          value: "Video_Link",
          options: {
            label: "Enter a url",
            placeholder: "Enter a url",
          },
        },
        {
          text: "Go to a Page",
          value: "Internal_Url",
          options: {
            label: "Select page",
            placeholder: "Select page",
          },
        },
        {
          text: "Link to a URL",
          value: "Url",
          options: {
            label: "Enter a url",
            placeholder: "Enter a url",
          },
        },
      ];
    },
    needsToComplete() {
      return (
        !this.form.user_role_name ||
        !this.form.time_format ||
        !this.form.date_format ||
        !this.form.timezone
      );
    },
    bannerImage: {
      get() {
        return this.form.register_banner instanceof Object
          ? this.form.register_banner.url
          : null;
      },
      set(file) {
        this.form.register_banner = file;
      },
    },
    isCanRegisterDisabled() {
      if(this.form.uses_multiple_register_pipelines) {
        if(this.form.register_logic.length) {
          let activePipeline = this.form.register_logic.find((pipeline) => pipeline.deleted === undefined || pipeline.deleted === false);
          return activePipeline === undefined;
        }
        return true;
      }
      return !this.form.application_pipeline;
    },
  },
  created() {
    if(this.userForm && !this.isStandalone) {
      this.form = this.userForm;
      this.userFormUnwatcher = this.$watch("userForm", (value) => {
        if("register_logic" in value) {
          this.form = value;
          this.userFormUnwatcher();
        }
      });
    } else {
      this.form = {
        id: (this.$team || this.form).id,
        owner_id: (this.$team || this.form).owner_id,
        register_logic:
          this.form.register_logic ||
          this.form.registerLogic ||
          this.defaultForm.register_logic,
        register_logic_message:
          this.form.register_logic_message ||
          this.form.registerLogicMessage ||
          this.defaultForm.register_logic_message,
        owner_id: this.form.owner_id || this.$user.id,
      };
    }
    // Used to support the Landing page settings in the Save Application page
    if(this.getModel) {
      this.doGetTeamFormConfig().then((result) => {
        this.options = { ...this.options, ...result };
      });
    }
  },
  watch: {
    form: {
      handler: function(value) {
        if(!this.isStandalone) {
          this.$emit("updated", value);
        }
        // Scroll to the new added pipeline and highlight the row
        if(this.getModel && this.highlightPipeline) {
          const pipelineIndex = this.form.register_logic.findIndex(logic => logic.scroll);
          if(pipelineIndex > -1) {
            this.$nextTick(() => {
              const stepper = document.querySelector(".highlighter");
              const container = document.querySelector(".custom-form-dialog .v-card__text");
              container.scroll({
                top: stepper.offsetTop - 200,
                behavior: "smooth",
              });
              setTimeout(() => {
                this.$set(this.form.register_logic, pipelineIndex, {
                  ...this.form.register_logic[pipelineIndex],
                  scroll: false,
                  highlight: true,
                });
              }, 2500);
            });
          }
        }
      },
      deep: true,
    },
    "userForm.register_logic": {
      handler: function(value) {
        if(!this.isStandalone && JSON.stringify(this.form.register_logic) != JSON.stringify(value)) {
          this.form.register_logic = value;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      doSaveTeam: "team/save",
      doGetTeam: "team/getOne",
      doGetLessons: "assessment/getAll",
      doGetContent: "content/getAll",
      doGetTeamFormConfig: "team/getFormConfig",
    }),
    onGet(id) {
      return this.doGetTeam({
        id: id,
        _fields: this.getModel ? "all" : ["id", "name", "owner_id", "uses_multiple_register_pipelines"],
        _relations: [
          "registerLogic",
          "registerLogicMessage",
          "pipelines",
          "registerBanner",
          "applicationPipeline",
          "settings",
          "legacySettings.applicantPortalLearnMoreButton",
          "legacySettings.applicantPortalFaqButton",
          "legacySettings.applicantPortalExtraButtons",
        ],
      }).then((result) => {
        if(this.getModel) {
          const pipelineIndex = result.register_logic.findIndex(logic => logic.pipeline_id == this.pipeline.id);
          if(pipelineIndex === -1) {
            result.register_logic.push({
              scroll: true,
              highlight: true,
              title: "New Landing Page Option",
              pipeline_id: this.pipeline.id,
              priority: result.register_logic.length ? result.register_logic[result.register_logic.length -1].priority + 1 : 0,
            });
          } else {
            result.register_logic[pipelineIndex].scroll = true;
            result.register_logic[pipelineIndex].highlight = true;
          }
        }
        return result;
      });
    },
    onSave() {
      this.formHasFiles = this.getModel ? false : this.formHasFiles;
      return this.saveFormModel(this.doSaveTeam).then((team) => {
        this.$team.register_logic = team.register_logic;
        this.$team.settings = team.settings;
      });
    },
    saveBanner(banner) {
      return this.saveFormModel(this.doSaveTeam, null, banner).then(
        (result) => {
          this.form.register_banner = result.register_banner || {};
        }
      );
    },
    onSetBannerImage(file) {
      this.saveBanner({
        id: this.form.id,
        register_banner: file,
      });
    },
    onResetBannerImage() {
      this.form.register_banner = {};
    },
    onSetApplicantPortal(key, input, value) {
      const fullKey = "applicant_portal_" + key;
      this.$set(this.form.settings, fullKey, input);
    },
    onRemoveApplicantPortal(key) {
      const fullKey = "applicant_portal_" + key;
      this.$set(this.form.settings, fullKey, -1);
    },
    onNewRegisterLogic() {
      this.onNewMetadata(
        {
          title: "",
          pipeline_id: null,
        },
        null,
        "register_logic"
      );
    },
    onRemoveRegisterLogic(key) {
      this.onRemoveMetadata(key, "register_logic");
    },
    onNewMessagePanel() {
      this.onNewMetadata(
        {
          title: "",
          description: "",
        },
        null,
        "register_logic_message.default_value",
      );
    },
    onRemoveMessagePanel(key) {
      this.onRemoveMetadata(key, "register_logic_message.default_value");
    },
    onSortRegisterLogic(value) {
      this.onSetMetadata(value, "register_logic");
    },
    onNewExtraButton() {
      this.onNewMetadata(
        {
          title: "",
          pipeline_id: null,
        },
        null,
        "settings.applicant_portal_extra_buttons"
      );
    },
    onRemoveExtraButton(key) {
      this.onRemoveMetadata(key, "settings.applicant_portal_extra_buttons");
    },
    onSortExtraButtons(value) {
      this.onSetMetadata(value, "settings.applicant_portal_extra_buttons");
    },
  },
};
</script>

<style lang="scss">
#registration-banner {
  .v-responsive__content {
    display: flex;
    justify-content: flex-end;
  }
}
.highlighter {
  background-color: lavender;
  border-radius: 12px;
}
.hideTabs {
  .v-tabs-bar {
    display: none;
  }
}
</style>
