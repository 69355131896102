var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{staticClass:"fill-height d-flex elevation-0 slightly-rounded",style:({
    borderRadius: '5px !important'
  }),attrs:{"light":""}},[_c('VCardTitle',{style:({
      height: '64px',
      position: 'absolute',
      top: 0,
      left: '8px',
      right: '8px',
      borderBottom: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
    })},[_c('VRow',{attrs:{"row":"","wrap":"","align-center":""}},[_c('VSpacer'),_c('VCol',{attrs:{"shrink":"","pr-0":""}},[_c('VBtn',{attrs:{"text":"","icon":"","color":"gray"},on:{"click":function($event){return _vm.navigateTo({
            name: 'lesson.index'
          })}}},[_c('VIcon',{attrs:{"color":"gray"}},[_vm._v(" close ")])],1)],1)],1)],1),_c('VCardText',{class:{
      'fill-height': true,
      'px-0': _vm.$vuetify.breakpoint.smAndDown,
      'pt-0': _vm.$vuetify.breakpoint.smAndDown,
      'flex xs12 align-self-center justify-self-center' : true
    }},[_c('VRow',{attrs:{"align-center":"","justify-center":"","row":"","wrap":""}},[_c('VCol',{staticClass:"px-2 pa-0"},[_c('VRow',{staticClass:"pr-2",attrs:{"fill-height":"","align-center":"","justify-center":"","space-around":""}},[_c('VCol',{staticClass:"text-sm-center py-12",attrs:{"cols":"12"}},[_c('p',{staticClass:"display-1",style:({
                textTransform: 'uppercase',
                fontWeight: 700
              })},[_vm._v(" No slides/ cards available! ")])]),_c('VCol',{staticClass:"text-sm-center"},[_c('VBtn',{staticClass:"px-12",attrs:{"color":"primary"},on:{"click":function($event){return _vm.navigateTo({
                name: 'lesson.index'
              })}}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }