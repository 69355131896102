var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticStyle:{"width":"100%"},attrs:{"row":"","wrap":"","fill-height":"","align-center":"","justify-center":""}},[_c('VCol',{attrs:{"px-12":"","cols":"12"}},[_c('CardTitle',{attrs:{"has-images":_vm.hasImages,"align":"left"}},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),_c('VList',{style:({
        overflowY: 'auto',
      })},_vm._l((_vm.choices),function(choice,key){return _c('VListItem',{key:key},[_c('VListItemAction',[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectChoice(choice)}}},[(_vm.isSelectedChoice(choice))?_c('VIcon',{style:({
                borderRadius: _vm.cardValue.type === 'multiple' ? '4px' : '50%',
                backgroundColor: '#89c160',
                width: '24px',
                height: '24px',
                lineHeight: '24px',

              }),attrs:{"color":"white","size":"20px"}},[_vm._v(" "+_vm._s(_vm.cardValue.type === 'multiple' ? 'check' : 'check')+" ")]):_c('VIcon',{style:({
                backgroundColor: '#eee',
                borderRadius: _vm.cardValue.type === 'multiple' ? '4px' : '50%',

              }),attrs:{"size":"24px","color":"#eee"}},[_vm._v(" "+_vm._s(_vm.cardValue.type === 'multiple' ? 'check_box_outline_blank' : 'radio_button_unchecked')+" ")])],1)],1),_c('VListItemContent',[_c('VListItemSubtitle',{staticClass:"text-wrap",staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(choice.text)+" ")])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }