<template>
  <VContainer
    fluid
    grid-list-lg
    fill-height
  >
    <VRow v-if="modelHasId">
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
      >
        <ViewHeader>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
              :to="getRouteForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <VCardText>
                <h1 class="pa-3 display-2 text-break">
                  {{ model.title }}
                </h1>
              </VCardText>
              <VList dense>
                <VListItem
                  v-for="(value,property) in details"
                  :key="property"
                >
                  <VListItemContent>
                    {{ property }}
                  </VListItemContent>
                  <VListItemContent class="align-end">
                    <strong>{{ value }}</strong>
                  </VListItemContent>
                </VListItem>
              </VList>
              <VCardText>
                <p
                  class="formatted"
                  v-html="model.content"
                  v-linkified
                />
                <FilesList
                  :readonly="true"
                  :files="model.files"
                />
              </VCardText>
            </VCard>
            <template v-if="stages.length > 0">
              <h2 class="display-1 mb-2 pa-3">
                {{ featureName("Stages", "pluralize") }}
              </h2>
              <template v-for="(stage,key) in stages">
                <div
                  :key="key"
                  class="elevation-2"
                >
                  <h3 class="pl-4 py-1 headline">
                    {{ stage.title }}
                  </h3>
                  <VDivider />
                  <p
                    class="formatted"
                    v-if="stage.description"
                    v-html="stage.description"
                  />
                  <DynamicInputsView
                    v-if="stage.metadata instanceof Array && stage.metadata.length"
                    :card="false"
                    :readonly="true"
                    :inputs="stage.metadata"
                    :input-name="featureName('Metadata')"
                    :metadata-sections="stage.metadata_sections"
                  />
                  <VAlert
                    v-else
                    type="info"
                    :value="true"
                  >
                    No entries set for this stage
                  </VAlert>
                </div>
              </template>
            </template>
          </VCol>
          <VCol
            cols="12"
            class="ml-0"
            v-if="hasAnyAssociations"
          >
            <AssociatedContent :model="model" />
            <br>
            <VCard>
              <h2 class="pa-3 display-1">
                {{ featureName('Reviewers').pluralize() }}
              </h2>
              <VCardText>
                <MembersList
                  v-if="model.reviewers && model.reviewers.length"
                  :readonly="true"
                  :users="model.reviewers"
                />
                <p v-else>
                  No {{ $team.user_role_name.toLowerCase().pluralize() }} have been added to this {{ featureName('pipeline').singularize() }}
                </p>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import MembersList from "@/components/Elements/Data/MembersList";

export default {
  name: "PipelineView",
  components: { DynamicInputsView, MembersList, AssociatedContent, ViewHeader },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Pipeline",
    };
  },
  computed: {
    stages() {
      return this.model.stages instanceof Array ? this.model.stages : [];
    },
    details() {
      return {
        Type: this.getPipelineType,
        "Due On": this.model.onboarding_stage.due_on
          ? this.$timezone.formatDateTime(this.model.onboarding_stage.due_on)
          : "No Due Date",
        "Public Due On":
          this.model.onboarding_stage.public_due_on || this.model.onboarding_stage.due_on
            ? this.$timezone.formatDateTime(
                this.model.onboarding_stage.public_due_on || this.model.onboarding_stage.due_on
              )
            : "No Date Set",
        "Approved User Role": this.featureName(
          this.model.approved_user_role
            ? this.model.approved_user_role.value
            : "No Role Set"
        ).titleize(),
      };
    },
    getPipelineType() {
      let type = null;
      switch (this.model.type) {
        case "pipeline":
          type = "registration"
          break;
        case "application-pipeline":
          type = "application"
          break;
      
        default:
          type = this.model.type;
          break;
      }
      return this.model.type ? type.titleize() : "(not set)";
    },
    hasAnyAssociations() {
      return (
        this.hasPrograms ||
        this.hasPrimaryContents ||
        this.hasReports ||
        this.hasSupplements ||
        this.hasLessons ||
        this.hasWorksheets ||
        this.hasDeliverables ||
        this.hasFAQs ||
        this.hasReviewers
      );
    },
    hasPrograms() {
      return (
        this.model &&
        this.model.programs instanceof Array &&
        this.model.programs.length > 0
      );
    },
    hasPrimaryContents() {
      return (
        this.model &&
        this.model.primary_contents instanceof Array &&
        this.model.primary_contents.length > 0
      );
    },
    hasReports() {
      return (
        this.model &&
        this.model.reports instanceof Array &&
        this.model.reports.length > 0
      );
    },
    hasSupplements() {
      return (
        this.model &&
        this.model.supplements instanceof Array &&
        this.model.supplements.length > 0
      );
    },
    hasLessons() {
      return (
        this.model &&
        this.model.lessons instanceof Array &&
        this.model.lessons.length > 0
      );
    },
    hasWorksheets() {
      return (
        this.model &&
        this.model.worksheets instanceof Array &&
        this.model.worksheets.length > 0
      );
    },
    hasDeliverables() {
      return (
        this.model &&
        this.model.deliverables instanceof Array &&
        this.model.deliverables.length > 0
      );
    },
    hasFAQs() {
      return (
        this.model &&
        this.model.faqs instanceof Array &&
        this.model.faqs.length > 0
      );
    },
    hasReviewers() {
      return (
        this.model &&
        this.model.reviewers instanceof Array &&
        this.model.reviewers.length > 0
      );
    },
  },
  methods: {
    ...mapActions({
      doGetPipeline: "pipeline/getOne",
    }),
    onGet(id) {
      return this.doGetPipeline(id).then((result) => {
        this.calendars.data = [result.calendars];
        return result;
      });
    },
  },
};
</script>

