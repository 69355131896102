import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Users API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	/**
	 * Delete user account (Soft delete)
	 *
	 * @param {*} user
	 * @returns
	 */
	deleteUser(user) {
		this.log().info("[Services: Users]: Delete user", user);
		return this
			.api()
			.delete("api/users")
			.catch(error => {
				this.log().error("[Services: Users: Delete user]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Delete user account parmanent (Hard delete)
	 *
	 * @param {*} user
	 * @returns
	 */
	deleteUserParmanently(user) {
		this.log().info("[Services: Users]: Delete user parmanently", user);
		return this
			.api()
			.delete("api/users/delete-account")
			.catch(error => {
				this.log().error("[Services: Users: Delete user parmanently]: Error", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Users",
	path: "users"
});

export default api;
