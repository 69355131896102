<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol cols="12">
      <VSelect
        v-model="form.value.type"
        dense
        outlined
        placeholder="Type..."
        class="textarea-field"
        :items="typeOptions"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
      />
      <TextField
        v-model="form.name"
        large
        filled
        clearable
        placeholder="Enter the question"
        class="textarea-field bg-textfield"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <VList>
        <Draggable
          v-if="choices && choices.length > 0"
          v-model="choices"
          element="div"
          :options="draggableOptions"
          :no-transition-on-drag="true"
          :disabled="form.isSaving"
          @input="backgroundSaveCard"
        >
          <transition-group
            name="dynamic-input-container"
            :css="true"
            tag="div"
          >
            <div
              class="dynamic-input-handle"
              v-for="(choice,key) in choices"
              :key="`dynamic-${choice.id}-${key}`"
            >
              <VListItem :key="key">
                <VListItemAction class="ques-options-list">
                  <VBtn
                    icon
                    @click="selectChoice(choice)"
                  >
                    <VIcon 
                      v-if="choice.is_correct_answer"
                      color="success"
                      style="border-radius: 6px;"
                    >
                      {{ form.value.type === 'multiple' ? 'check_box' : 'radio_button_checked' }}
                    </VIcon>
                    <VIcon 
                      v-else
                      :style="{
                        'background-color': form.value.type === 'multiple' ? '#eaeaea' : 'transparent',
                        'border-radius': '6px'
                      }"
                    >
                      {{ form.value.type === 'multiple' ? 'check_box_outline_blank' : 'radio_button_unchecked' }}
                    </VIcon>
                  </VBtn>
                </VListItemAction>
                <VListItemTitle class="d-flex align-center pl-3">
                  <TextField
                    v-model="choice.text"
                    large
                    filled
                    clearable
                    single-line
                    hide-details
                    type="text"
                    placeholder="Enter choice text"
                    class="textarea-field"
                    :key="key"
                    :disabled="form.isSaving"
                    @blur="backgroundSaveCard"
                  />
                </VListItemTitle>
                <VListItemAction>
                  <VBtn
                    icon
                    class="remove-dynamic-input"
                    text:disabled="form.isSaving"
                    @click.stop="removeInput(key)"
                  >
                    <VIcon>delete</VIcon>
                  </VBtn>
                </VListItemAction>
              </VListItem>
            </div>
          </transition-group>
        </Draggable>
        <VListItem style="padding-left: 70px;">
          <VBtn
            height="35px"
            color="primary"
            @click="addInput({
              is_correct_answer: false
            })"
            :disabled="form.isSaving"
          >
            <VIcon>add</VIcon>
            Add Choice
          </VBtn>
        </VListItem>
      </VList>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";
import Draggable from "vuedraggable";

export default {
  name: "SaveCardQuestions",
  mixins: [SaveCardMixin],
  components: { Draggable },
  data() {
    return {
      typeOptions: [
        {
          text: "Single",
          value: "single",
        },
        {
          text: "Multiple",
          value: "multiple",
        },
      ],
      defaultForm: {
        choices: [],
        name: "",
        value: {
          type: "single",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.ques-options-list {
  margin-right: 12px !important;
}
</style>
