import Vue from 'vue';
import decode from "jwt-decode";
import dotenv from 'dotenv';
import api from '../../services/auth';
import {
  exists
} from "fs";
dotenv.config();

try {
  const window = window || null;
  const localStorage = window instanceof Object ? window.localStorage : {
    store: {},
    setItem: function (key, value) {
      this.store[key] = value;
    },
    getItem: function (key) {
      return this.store[key];
    },
    removeItem: function (key) {
      delete this.store[key];
    },
  };
} catch (error) {

}

export default function (helper) {
  helper.setApi(api);

  const TYPES = {
    AUTH_CSRF: "AUTH_CSRF",
    AUTH_REQUEST: "AUTH_REQUEST",
    AUTH_RECOVER: "AUTH_RECOVER",
    AUTH_RESET: "AUTH_RESET",
    AUTH_ERROR: "AUTH_ERROR",
    AUTH_SUCCESS: "AUTH_SUCCESS",
    AUTH_LOGOUT: "AUTH_LOGOUT",
    AUTH_REMEMBER: "AUTH_REMEMBER",
    AUTH_VERIFY: "AUTH_VERIFY",
    AUTH_GET_TEAM: "AUTH_GET_TEAM",
    AUTH_TEAM: "AUTH_TEAM"
  };

  const state = {
    access_token: "",
    refresh_token: "",
    valid_token: false,
    status: "",
    email: "",
    rememberMe: false,
    client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID || 2,
    grant_type: "password",
    team: null
  };

  const getters = {
    rememberMe: state => !!state.rememberMe
  };

  const actions = {
    login(context, user) {
      // Logout the current credentials first to prevent overlapping sessions
      context.commit(TYPES.AUTH_LOGOUT);
      return new Promise((resolve, reject) => {
        context.commit(TYPES.AUTH_REQUEST);
        helper.log().info("Store.auth.login: Logging in", user, helper);
        helper
          .app()
          .$auth
          .login({
            data: {
              ...user,
              client_secret: state.client_secret,
              client_id: state.client_id,
              grant_type: state.grant_type
            },
            rememberMe: state.rememberMe || user.rememberMe,
            redirect: false,
            success(resp) {
              const {
                access_token,
                refresh_token
              } = resp.data;
              const tokens = {
                access_token,
                refresh_token
              };
              context.commit(TYPES.AUTH_SUCCESS, {
                ...user,
                tokens
              });
              // context.dispatch('user/me', null, {   root: true });
              resolve(resp);
            },
            error(err) {
              context.commit(TYPES.AUTH_ERROR, err);
              delete localStorage.token;
              reject(err);
            }
          });
      });
    },
    rememberMe(context, params) {
      helper.log().info('Store.auth.rememberMe: Remember Me', params)
      context.commit(TYPES.AUTH_REMEMBER, params);
    },
    recover(context, params) {
      helper.log().info('Store.auth.recover: Recover', params);
      return api
        .recover(params)
        .then(response => {
          context.commit(TYPES.AUTH_RECOVER);
          return response.data;
        });
    },
    reset(context, params) {
      return api
        .reset(params)
        .then(response => {
          return response.data;
        });
    },
    getTeam({
      commit,
      state
    }, params) {
      helper.log().info('Store.auth.getTeam: Get Team', params);
      return new Promise((resolve, reject) => {
        if (!state.team) {
          return api
            .getTeam(params)
            .catch(error => {
              helper.log().debug(error);
              reject(error);
            })
            .then(result => {
              context.commit(TYPES.AUTH_TEAM, result.data);
              resolve(result.data);
            });
        } else {
          resolve(state.team);
        }
      })
    },
    csrf({
      commit,
      state
    }, params) {
      return api
        .csrf(params)
        .catch(error => {
          helper.log().debug(error);
          return error;
        })
        .then(response => {
          return response;
        });
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        helper.log().info("Store.auth.logout: Logging out");
        context.commit(TYPES.AUTH_LOGOUT);
        // context.dispatch('user/logout', null, {
        //   root: true
        // });
        resolve();
      });
    },
    verify() {
      const token = decode(localStorage.getItem('access_token'));
      state.valid_token = !!token.exp;
    }
  };

  const mutations = {
    [TYPES.AUTH_REQUEST]: state => {
      state.status = "loading";
    },
    [TYPES.AUTH_SUCCESS]: (state, payload) => {
      state.status = "success";
      if (payload) {
        state.access_token = payload.tokens.access_token;
        localStorage.setItem('access_token', payload.tokens.access_token);
        state.refresh_token = payload.tokens.refresh_token;
        localStorage.setItem('refresh_token', payload.tokens.refresh_token);
        localStorage.setItem('pending-duplications', JSON.stringify({}));
      }
      if (state.rememberMe && payload.user) {
        localStorage.email = payload.user.username;
      }
    },
    [TYPES.AUTH_REMEMBER]: (state, value) => {
      if (value.value) {
        window
          .localStorage
          .setItem('remember', true);
      } else {
        window
          .localStorage
          .removeItem('remember');
      }
    },
    [TYPES.AUTH_ERROR]: state => {
      state.status = "error";
    },
    [TYPES.AUTH_LOGOUT]: state => {
      state.access_token = "";
      state.refresh_token = "";
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('default_auth_token');
      localStorage.removeItem('user');
    },
    [TYPES.AUTH_TEAM]: (state, payload) => {
      state.team = payload || state.team;
    }
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}
