<template>
  <div>
    <Badge
      overlap
      top
      color="#ff2b7a"
      class="black--text"
      :value="hasNotifications"
    >
      <VBtn
        text
        icon
        width="40px"
        height="40px"
        color="white"
        class="wethrive-notification"
        @click="navigateTo('chat.index')"
      >
        <img
          width="20"
          height="20"
          :src="chatIcon"
        >
      </VBtn>
      <span
        v-if="hasNotifications"
        slot="badge"
      >
        {{ $user.new_messages_count }}
      </span>
    </Badge>
  </div>
</template>
<script>
import chatIcon from "../../../images/message-icon@2x.png";
export default {
  props: {
    lastChecked: {
      type: String,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chatIcon,
      timestamp: null,
      notifications: {
        data: [],
      },
      eventName: "ChatMessageWasReceived",
      subscribedChannels: [],
    };
  },
  computed: {
    realPusher: function () {
      return this.$echo || this.$parent.pusher;
    },
    hasNotifications() {
      return this.$user.new_messages_count > 0;
    },
    notificationCount() {
      return this.notifications.data.length;
    },
  },
  created() {
    this.$log.debug("Chat Notification: Mounted component...");
    this.initChannels();
    // this.getNewFeedNotifications();
    this.timestamp = this.lastChecked;
  },
  methods: {
    getProfileUrl(user) {
      return user.url.replace("user", "profile");
    },
    getDisplayName(user) {
      let name = user.displayName;
      let parts = name.split(" ");
      return parts[0] + " " + parts[1].substr(0, 1) + ".";
    },
    getAvatarUrl(user) {
      if (user && user.profile_url) {
        return user.profile_url;
      }
      let image = user ? user.avatar || user.image : undefined;
      return image ? image.url : "https://avatars.io/gravatar/username";
    },
    getNewFeedNotifications() {
      return app.axios
        .get("/feed/new-feed-notifications", {
          timestamp: this.lastChecked,
        })
        .then((response) => {
          this.notifications = response.data;
          this.timestamp = Math.round(+new Date().getTime() / 1000);
          Promise.resolve(response.data);
        });
    },
    onAddFeedNotification(notification) {
      this.$log.debug(
        "Chat Notification: Adding new notification",
        notification
      );
      this.notifications.data.push(notification);
      this.notifications.total += 1;
    },
    initChannelsPusher() {
      this.$log.debug("Chat Notification: Initing channels: ");
      this.$log.debug(this.$parent.channels);
      this.$parent.channels.forEach((channel) => {
        this.$log.debug(
          "Chat Notification: Pusher subscribing to [" + channel + "]"
        );
        let monitor = this.realPusher.subscribe(channel);
        //Bind a function to a Event (the full Laravel class)
        monitor.bind(this.eventName, (data) => {
          this.onAddFeedNotification(data.notification);
        });
      });
    },
    //This uses Laravel Echo
    initChannels() {
      this.$log.debug("Chat Notification: Initing channels: ", this.channels);
      this.channels.forEach((channel) => {
        this.$log.debug(
          "Chat Notification: Pusher subscribing to [private-" + channel + "]"
        );
        let monitor = this.realPusher
          .private(channel)
          .listen(this.eventName, (data) => {
            // Only update the chat counter if we're not on the chat page
            if (!this.$route.name.startsWith("chat")) {
              this.$log.debug("Chat Notification: Received from pusher", data);
              this.$user.new_messages_count++;
            }
          });
        this.subscribedChannels.push(monitor);
      });
    },
  },
};
</script>
<style>
.dialogs {
  max-height: 70vh;
  overflow: auto;
}

.dialogs a {
  font-size: 13px !important;
}

.dialogs .dialog_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_meta .dialog_date {
  flex: 0 1 128px;
  text-align: right;
}

:root .dialogs .dialog_author-name {
  flex: auto;
  transition: font-weight 50ms linear;
}

:root .dialogs .dialog_author-name:hover {
  font-weight: bolder;
}
</style>