import Vue from "vue";
import BaseApi from "./_base-api";

const helper = new BaseApi({
	$app: new Vue()
});

export default {
	/**
	 * As a logged in user, get my user information
	 *
	 * @returns
	 */
	me() {
		// Need this here since app isn't available yet
		return helper
			.api()
			.get("/api/users/me")
			.catch(error => {
				helper.log().error("[Services: User: GetUser]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Get my notifications and announcements
	 *
	 * @returns
	 */
	getNotifications(params) {
		// Need this here since app isn't available yet
		return helper
			.api()
			.get("/api/users/notifications/recent", { params: params })
			.catch(error => {
				helper.log().error("[Services: User: Get Notifications]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Get only notifications
	 *
	 * @returns
	 */
	getOnlyNotifications(params) {
		// Need this here since app isn't available yet
		return helper
			.api()
			.get("/api/users/notifications/notifications", { params: params })
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Get Only Notifications]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Get only my announcements
	 *
	 * @returns
	 */
	getOnlyAnnouncements(params) {
		// Need this here since app isn't available yet
		return helper
			.api()
			.get("/api/users/notifications/announcements", { params: params })
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Get Only Announcements]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Mark my notifications as read
	 *
	 * @param {*} params
	 * @returns
	 */
	markNotificationsRead(params) {
		helper.log().info("[Services: User]: Mark Notifications Read");
		return helper
			.api()
			.put("api/users/notifications/read", params)
			.catch(error => {
				helper.log().error("[Services: User]: Mark Notifications Read", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Mark my announcements as read
	 *
	 * @returns
	 */
	updateLastReadAnnouncements() {
		helper.log().info("[Services: User]: Mark Notifications Read");
		return helper
			.api()
			.put("api/users/last-read-announcements-at/update")
			.catch(error => {
				helper.log().error("[Services: User]: Mark Notifications Read", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Save my user details
	 *
	 * @param {*} params
	 * @returns
	 */
	save(params) {
		const id = params.id;
		const data = helper.utils.createFormData(params);
		helper.log().info("[Services: Users]: SaveUser", params, data);
		const method = id ? "post" : "post";
		return helper
			.api()
			[method](id ? "api/users/" + id : "api/users", data)
			.catch(error => {
				helper.log().error("[Services: User]: SaveUser: Error", error);
				throw helper.utils.resolveError(error);
			});
	},
	/**
	 * Set my current team
	 *
	 * @param {*} params
	 * @returns
	 */
	setCurrentTeam(params) {
		const id = params.id || params;
		const data = helper.utils.createFormData(params);
		helper.log().info("[Services: Users]: SetCurrentTeam", params, data);
		return helper
			.api()
			.post(`api/teams/${id}/users/set-current-team`)
			.catch(error => {
				helper.log().error("[Services: User]: SetCurrentTeam: Error", error);
				throw helper.utils.resolveError(error);
			});
	},

	/**
	 * Set my auth token for the given connected account
	 *
	 * @param {*} params
	 * @returns
	 */
	setAuthToken(params) {
		helper.log().info("[Services: User]: Set Auth Token", params);
		return helper
			.api()
			.post(`api/users/connected-accounts/${params.type}`, params)
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Setting Auth Token]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},

	/**
	 * Delete the given connected account
	 *
	 * @param {*} params
	 * @returns
	 */
	deleteConnectedAccount(params) {
		helper.log().info("[Services: User]: Delete Connected Account", params);
		return helper
			.api()
			.delete(`api/users/connected-accounts/${params}`)
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Deleting Connected Account]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},

	/**
	 * Get all of my connected accounts
	 *
	 * @param {*} slug
	 * @returns
	 */
	getConnectedAccounts(slug) {
		helper.log().info("[Services: User]: Get Connected Accounts", slug);
		return helper
			.api()
			.get(`api/users/connected-accounts`)
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Getting Connected Accounts]: Error", error);
				throw helper.utils.resolveError(error);
			});
	},

	/**
	 * Delete the specified connected account
	 *
	 * @param {*} slug
	 * @returns
	 */
	getConnectedAccount(slug) {
		helper.log().info("[Services: User]: Get Connected Account", slug);
		return helper
			.api()
			.get(`api/users/connected-accounts/${slug}`)
			.catch(error => {
				helper
					.log()
					.error("[Services: User: Getting Connected Account]: Error", error);
				throw helper.utils.resolveError(error);
			});
	}
};
