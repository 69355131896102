<template>
  <div>
    <AssessmentPresentCards
      v-if="card.attached_assessment"
      :assessment="assessment"
      :cards="cards"
      :fullscreen="false"
      mode="edit"
      class="fill-height"
    />
    <VAlert
      v-else
      type="info"
      :value="true"
    >
      No worksheet attached
    </VAlert>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardWorksheet",
  mixins: [PresentationCardMixin],
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cards() {
      return this.card.attached_asessment instanceof Object &&
        this.card.attached_asessment.cards instanceof Array
        ? this.card.attached_asessment.cards
        : [];
    },
  },
};
</script>

