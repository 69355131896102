<template>
  <div id="calendar-event-list">
    <VList two-line>
      <template v-for="(event, key) in events">
        <VListItem
          :key="key"
          :style="event.style"
          :class="{
            mobile: $vuetify.breakpoint.smAndDown
          }"
        >
          <VListItemContent class="pr-4">
            <VListItemTitle
              :style="{
                cursor: 'pointer'
              }"
              @click.prevent="onShowDetails({originalEvent: event}, false)"
            >
              {{ event.title }}
              &nbsp;
              <VChip
                small
                label
                v-show="(event.rsvp_mode || event.rsvpMode) === 'shift'"
              >
                Shift
              </VChip>
            </VListItemTitle>
            <VListItemSubtitle style="color:#fff">
              From:
              <strong>{{ (event.calendar.calendarType || 'local').capitalize() }}</strong>
              &nbsp;
              <VChip>{{ (event.status || 'On').titleize() }}</VChip>

              <template v-if="(event.rsvp_mode || event.rsvpMode) === 'standard'">
                <VChip
                  v-if="(event.currentUserRsvp || event.current_user_rsvp || {}).id > 0"
                  color="info"
                  text-color="white"
                >
                  RSVP: {{ ((event.currentUserRsvp || event.current_user_rsvp || {}).status || 'Maybe').titleize() }}
                </VChip>
              </template>
              <template v-else>
                <VChip
                  color="success"
                  text-color="white"
                  v-if="(event.currentUserRsvp || event.current_user_rsvp || {}).is_accepted"
                >
                  Accepted: {{ $timezone.formatDateTime((event.currentUserRsvp || event.current_user_rsvp || {}).accepted_on) }}
                </VChip>
                <VChip
                  color="warning"
                  text-color="white"
                  v-if="(event.currentUserRsvp || event.current_user_rsvp || {}).is_rejected"
                >
                  Declined: {{ $timezone.formatDateTime((event.currentUserRsvp || event.current_user_rsvp || {}).rejected_on) }}
                </VChip>
              </template>
            </VListItemSubtitle>
          </VListItemContent>
          <VListItemAction
            :class="{
              'flex calendar-actions': true,
              'sm5 md4': $isUserAdmin && !readonly && $vuetify.breakpoint.mdAndUp,
              'sm2 md1': !$isUserAdmin && !readonly && $vuetify.breakpoint.mdAndUp
            }"
            style="flex-direction: row; align-items: center"
          >
            <template v-if="(event.calendar.calendarType || 'local') === 'local'">
              <VBtnToggle
                v-if="$isUserAdmin && !readonly"
                dark
                class="transparent event-actions elevation-0"
              >
                <VTooltip
                  v-if="isOn(event.status)"
                  top
                >
                  <VBtn
                    slot="activator"
                    icon
                    small
                    color="error"
                    :disabled="event.isLoading"
                    @click.prevent="onCancelEvent(event, key)"
                  >
                    <VIcon>clear</VIcon>
                  </VBtn>
                  <span>Cancel this event</span>
                </VTooltip>
                <VTooltip
                  v-else
                  top
                >
                  <VBtn
                    slot="activator"
                    icon
                    small
                    color="success"
                    :disabled="event.isLoading"
                    @click.prevent="onUnCancelEvent(event, key)"
                  >
                    <VIcon>check</VIcon>
                  </VBtn>
                  <span>Un-Cancel this event</span>
                </VTooltip>
                <VTooltip
                  v-if="event.canRsvp"
                  top
                >
                  <VBtn
                    slot="activator"
                    icon
                    color="secondary"
                    small
                    :disabled="event.isLoading"
                    @click.prevent="onToggleRsvpDialog(event)"
                  >
                    <VIcon>mail</VIcon>
                  </VBtn>
                  <span>RSVP</span>
                </VTooltip>
                <VTooltip top>
                  <VBtn
                    slot="activator"
                    icon
                    small
                    color="secondary"
                    :disabled="event.isLoading"
                    @click.prevent="onShowDetails({originalEvent: event}, false)"
                  >
                    <VIcon>visibility</VIcon>
                  </VBtn>
                  <span>View</span>
                </VTooltip>
                <VTooltip
                  v-if="isOn(event.status)"
                  top
                >
                  <VBtn
                    slot="activator"
                    icon
                    color="secondary"
                    small
                    :disabled="event.isLoading"
                    @click.prevent="onEditEvent(event)"
                  >
                    <VIcon>edit</VIcon>
                  </VBtn>
                  <span>Edit</span>
                </VTooltip>
                <VTooltip top>
                  <VBtn
                    v-if="$isUserAdmin"
                    slot="activator"
                    icon
                    color="secondary"
                    small
                    :loading="event.isDeleting"
                    @click="onDeleteEvent(event)"
                  >
                    <VIcon>delete</VIcon>
                  </VBtn>
                  <span>Delete</span>
                </VTooltip>
              </VBtnToggle>
              <template v-else>
                <VTooltip
                  v-if="event.canRsvp"
                  top
                >
                  <VBtn
                    slot="activator"
                    icon
                    color="secondary"
                    small
                    @click.prevent="onToggleRsvpDialog(event)"
                  >
                    <VIcon>mail</VIcon>
                  </VBtn>
                  <span>RSVP</span>
                </VTooltip>
                <VTooltip top>
                  <VBtn
                    slot="activator"
                    icon
                    color="secondary"
                    small
                    @click.prevent="onShowDetails({originalEvent: event}, false)"
                  >
                    <VIcon>visibility</VIcon>
                  </VBtn>
                  <span>View</span>
                </VTooltip>
              </template>
            </template>
            <template v-else>
              <VTooltip
                v-if="event.canRsvp"
                top
              >
                <VBtn
                  slot="activator"
                  icon
                  color="secondary"
                  small
                  @click.prevent="onToggleRsvpDialog(event)"
                >
                  <VIcon>mail</VIcon>
                </VBtn>
                <span>RSVP</span>
              </VTooltip>
              <VTooltip top>
                <VBtn
                  slot="activator"
                  icon
                  color="secondary"
                  small
                  @click.prevent="onShowDetails({originalEvent: event}, false)"
                >
                  <VIcon>visibility</VIcon>
                </VBtn>
                <span>View</span>
              </VTooltip>
            </template>
          </VListItemAction>
        </VListItem>
        <VDivider :key="`divider-${key}`" />
      </template>
    </VList>
    <Dialog
      :persistent="true"
      max-width="500px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.detail"
      :title="currentEvent.title"
      @toggle:dialog="onToggleEventDetailDialog"
    >
      <EventView :event="currentEvent" />
    </Dialog>
    <FormDialog
      button-text="RSVP"
      max-width="500px"
      model-type="RSVP"
      :readonly="(currentCalendarEntry || {}).rsvp_mode === 'shift'"
      :is-dialog-open="dialogs.rsvp"
      :title="(currentCalendarEntry || {}).title"
      @toggle:form:dialog="onToggleRsvpDialog"
    >
      <RSVPToEvent
        mode="dialog"
        :calendar-entry="currentCalendarEntry"
      />
    </FormDialog>
    <FormDialog
      v-if="currentEvent"
      max-width="640px"
      model-type="CalendarEntry"
      :current-model="currentEvent"
      :is-dialog-open="dialogs.event"
      :title="`Save Event ${currentEvent ? currentEvent.title || '' : ''}`"
      :button-text="`Save and Notify Users`"
      @toggle:form:dialog="onToggleEventFormDialog"
      @deleteItem="onDeleteEvent"
    >
      <EventForm
        :id="currentEvent && currentEvent.id ? {
          id: currentEvent.id,
          calendarId: currentEvent.calendar_id || currentEvent.calendarId
        } : null"
        mode="dialog"
        :entity-calendars="calendar ? [calendar] : []"
        :entity-calendar-id="currentEvent.calendar_id"
        :entity="entity"
        :entity-type="entityType"
        :user-form="currentEvent && !currentEvent.id ? currentEvent : {}"
      />
    </FormDialog>
    <Confirm ref="confirmDeleteEvent">
      <template v-if="currentEvent && currentEvent.id">
        <h2 class="subtitle subheading">
          Delete Mode
        </h2>
        <VRadioGroup
          v-model="deleteMode"
          column
          persistent-hint
          :hint="deleteModeOptions[this.deleteMode].hint"
        >
          <VRadio
            v-for="(mode, key) in deleteModeOptions"
            :value="mode.value"
            :key="`delete-mode-${key}`"
            :label="mode.text"
          />
        </VRadioGroup>
      </template>
    </Confirm>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import EventView from "./EventView";
import EventForm from "./SaveEvent";
import RSVPToEvent from "./RSVPToEvent";
import DeletesItems from "@/mixins/DeletesItems";

export default {
  name: "EventList",
  props: {
    events: {
      type: Array,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    calendar: {
      type: Object,
      default: () => {}
    },
    entity: {
      type: Object,
      default: () => {}
    },
    entityType: {
      type: String,
      default: ""
    }
  },
  components: { EventView, EventForm, RSVPToEvent },
  mixins: [DeletesItems],
  data() {
    return {
      deleteMode: "current",
      currentEvent: {},
      dialogs: {
        detail: false,
        event: false,
        rsvp: false
      },
      currentCalendarEntry: {},
      deleteModeOptions: {
        upcoming: {
          value: "upcoming",
          text: "Upcoming",
          hint: "Delete only upcoming events"
        },
        all: {
          value: "all",
          text: "All",
          hint: "Delete all events"
        },
        current: {
          value: "current",
          text: "Current",
          hint: "Delete only the current event"
        }
      }
    };
  },
  methods: {
    ...mapActions({
      doSaveCalendarEntry: "calendarEntry/save",
      doDeleteCalendarEntry: "calendarEntry/delete",
      doGetCalendarEntry: "calendarEntry/getOne"
    }),
    onToggleEventDetailDialog() {
      this.dialogs.detail = !this.dialogs.detail;
    },
    onToggleEventFormDialog() {
      this.dialogs.event = !this.dialogs.event;
    },
    onToggleRsvpDialog(entry) {
      this.dialogs.rsvp = !this.dialogs.rsvp;
      if (entry instanceof Object) {
        this.doGetCalendarEntry({
          id: entry.id,
          calendar_id: entry.calendar_id || entry.calendarId
        }).then(result => {
          this.events.splice(
            this.events.findIndex(e => e.id === result.id),
            1,
            { ...entry, ...result }
          );
          this.currentCalendarEntry =
            this.events.find(e => e.id === result.id) ||
            this.currentCalendarEntry;
        });
      } else {
        this.currentCalendarEntry = null;
      }
    },
    onShowDetails(event) {
      this.currentEvent = (event || {}).originalEvent || {};
      this.dialogs.detail = true;
    },
    onEditEvent(event) {
      if (!this.$isUserAdmin || this.readonly) {
        return false;
      }
      this.currentEvent = event;
      this.dialogs.event = true;
    },
    onCancelEvent(event) {
      return this.onConfirmAction(
        event,
        () => {
          ["canceled", "cancelled"].indexOf(
            this.events.find(e => e.id === event.id).status_
          ) !== -1;
          return this.doSaveCalendarEntry({
            id: event.id,
            data: {
              id: event.id,
              status: "canceled",
              calendar_id: event.calendarId
            }
          }).then(() => {
            event.status = "canceled";
            event.style = `color: #fff; background-color: ${this.getEventColor(
              event,
              event.calendar
            )}`;
          });
        },
        "Are you sure you want to cancel this event?"
      );
    },
    onUnCancelEvent(event) {
      return this.onConfirmAction(
        event,
        () => {
          ["on", "confirmed"].indexOf(
            this.events.find(e => e.id === event.id).status_
          ) !== -1;
          return this.doSaveCalendarEntry({
            id: event.id,
            data: {
              id: event.id,
              status: "on",
              calendar_id: event.calendarId
            }
          }).then(() => {
            event.status = "on";
            event.style = `color: #fff; background-color: ${this.getEventColor(
              event,
              event.calendar
            )}`;
          });
        },
        "Are you sure you want to un-cancel this event?"
      );
    },
    onDeleteEvent(event) {
      this.currentEvent = event;
      const message = event.hasEntries
        ? `DANGER: This event is linked to other events. Are you sure you want to delete it? Ideally you can adjust the start and end dates for this specific event.`
        : "Are you sure?";
      const params = [
        () => {
          return {
            id: event.id,
            calendar_id: event.calendar_id || event.calendarId,
            params: {
              mode: this.deleteMode
            }
          };
        },
        null,
        "confirmDeleteEvent"
      ];
      this.$set(event, "isLoading", true);
      this.$set(event, "isDeleting", true);
      return this.onDeleteItem(
        params,
        this.doDeleteCalendarEntry,
        message
      ).then(result => {
        if (result) {
          const index = this.events.findIndex(
            current => current.id == event.id
          );
          if (index > -1) {
            this.events.splice(index, 1);
          }
        }
        this.deleteMode = "current";
        this.currentEvent = {};
      });
    },
    getEventColor(entry, calendar) {
      return entry.status === "canceled" ? "#ccc" : calendar.color;
    },
    isOn(status) {
      return ["on", "confirmed"].indexOf(status) !== -1;
    },
    isCancelled(status) {
      return ["cancelled", "canceled"].indexOf(status) !== -1;
    }
  }
};
</script>
<style scoped lang="scss">
.event-actions {
  .v-btn {
    opacity: 1;
    margin: auto 4px;
    border-radius: 50%;
  }
}
@media (max-width: 960px) {
  .event-actions {
    .v-btn {
      width: 32px !important;
      height: 32px !important;
    }
  }
}
</style>

<style lang="scss">
#calendar-event-list {
  .mobile {
    > .v-list__tile {
      height: auto;
      flex-direction: column;
      padding: 12px;
      .v-list__tile__content,
      .v-list__tile__action {
        width: 100%;
      }
    }
  }
  .v-list__tile {
    height: auto;
    min-height: 86px;
    padding-top: 6px;
    padding-bottom: 6px;
    .v-list__tile__title {
      min-height: 36px;
    }
  }
}
</style>