import api from "../../services/calendarEntryRsvp";
export default function(helper) {
	// CalendarEntry types
	const TYPES = {
		CALENDAR_ENTRY_RSVP_GET: "STORE_GET",
		CALENDAR_ENTRY_RSVP_DELETE: "STORE_DELETE",
		CALENDAR_ENTRY_RSVP_SAVE: "STORE_SAVE",
		CALENDAR_ENTRY_RSVP_ACCEPT: "STORE_ACCEPT",
		CALENDAR_ENTRY_RSVP_REJECT: "STORE_REJECT"
	};

	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("calendar_entry_rsvp");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions(
		{
			/**
			 * Accept an RSVP
			 *
			 * @param {*} context
			 * @param {*} params
			 * @returns
			 */
			accept(context, params) {
				helper
					.log()
					.info("[Store: CalendarEntryRsvps]: SaveCalendarEntryRsvp", params);
				return helper
					.api()
					.accept(params)
					.then(response => {
						context.commit(
							TYPES.CALENDAR_ENTRY_RSVP_ACCEPT,
							{
                                type: 'calendar_entry_rsvp',
                                context,
                                params,
                                result: response.data.data
                            }
						);
						return response;
					})
					.catch(error => {
						helper
							.log()
							.error(
								"[Store: CalendarEntryRsvp: SaveCalendarEntryRsvp]: Error",
								error
							);
						throw helper.utils.resolveError(error);
					});
			},
			/**
			 * Reject an RSVP
			 *
			 * @param {*} context
			 * @param {*} params
			 * @returns
			 */
			reject(context, params) {
				helper
					.log()
					.info("[Store: CalendarEntryRsvps]: RejectCalendarEntryRsvp", params);
				return helper
					.api()
					.reject(params)
					.then(response => {
						context.commit(
							TYPES.CALENDAR_ENTRY_RSVP_REJECT,
							{
                                type: 'calendar_entry_rsvp',
                                context,
                                params,
                                result: response.data.data
                            }
						);
						return response;
					})
					.catch(error => {
						helper
							.log()
							.error(
								"[Store: CalendarEntryRsvp: RejectCalendarEntryRsvp]: Error",
								error
							);
						throw helper.utils.resolveError(error);
					});
			},
			/**
			 * Update the status of an RSVP
			 *
			 * @param {*} context
			 * @param {*} params
			 * @returns
			 */
			status(context, params) {
				helper
					.log()
					.info(
						"[Store: CalendarEntryRsvps]: UpdateCalendarEntryRsvpStatus",
						params
					);
				return helper
					.api()
					.status(params)
					.then(response => {
						context.commit(TYPES.CALENDAR_ENTRY_RSVP_SAVE, {
                                type: 'calendar_entry_rsvp',
                                context,
                                params,
                                result: response.data.data
                            });
						return response;
					})
					.catch(error => {
						helper
							.log()
							.error(
								"[Store: CalendarEntryRsvp: UpdateCalendarEntryRsvpStatus]: Error",
								error
							);
						throw helper.utils.resolveError(error);
					});
			}
		},
		"Calendar Entry RSVP",
		api
	);
	const mutations = helper.mutations(
		{
			[TYPES.CALENDAR_ENTRY_RSVP_GET](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_RSVP_SAVE", data, {
					root: true
				});
                helper.utils.addStateData(state, data.result || data);
				return data;
			},
			[TYPES.CALENDAR_ENTRY_RSVP_SAVE](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_RSVP_SAVE", data, {
					root: true
				});
                helper.utils.updateStateData(state, data.result || data);
				return data;
			},
			[TYPES.CALENDAR_ENTRY_RSVP_DELETE](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_RSVP_DELETE", data, {
					root: true
				});
                helper.utils.removeFromStateData(state, data.params || data);
				return data.result;
			},
			[TYPES.CALENDAR_ENTRY_RSVP_ACCEPT](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_RSVP_SAVE", data, {
					root: true
				});
				helper.utils.updateStateData(state, data.result || data);
				return data;
			},
			[TYPES.CALENDAR_ENTRY_RSVP_REJECT](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_RSVP_SAVE", data, {
					root: true
				});
				helper.utils.updateStateData(state, data.result || data);
				return data;
			}
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	};
}
