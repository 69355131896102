<template>
  <div class="chips-list">
    <template v-if="normalList">
      <VChip
        v-for="(item, key) in items"
        class="small-chip mb-3  mr-3"
        :key="key"
        :close="closable"
        @click:close="$emit('removeItem', item.id, modelType)"
      >
        <VAvatar
          v-if="showAvatar"
          color="#bfbfbf"
          height="18px !important"
          width="18px !important"
          min-width="18px !important"
          class="mr-2"
          style="border-radius: 4px;"
        >
          <img 
            src="@/images/doc-icon.png"
            style="height: 20px; width: 20px;"
          >
        </VAvatar>
        <span
          class="text-truncate"
          style="color: #252526; font-weight: 500;"
        >
          {{ item.title }}
        </span>
      </VChip>
      <VChip
        v-if="showMoreCount > 0"
        color="#fdbf14"
        class="mb-2 white--text"
        style="border-radius: 10px;"
      >
        <strong>+{{ showMoreCount }}</strong>
      </VChip>
      <VBtn
        v-if="enableAction"
        height="32px"
        class="small-chip add-chip mb-3"
        @click="$emit('addItems', modelType)"
      >
        <span style="color: #808080;">{{ addChipTitle }}</span>
        <VIcon
          right
          color="#757575"
          size="18px"
        >
          mdi-plus-circle
        </VIcon>
      </VBtn>
    </template>
    <VRow v-else>
      <VCol 
        v-for="(item, key) in items"
        class="pt-0"
        :key="key"
        :cols="cols"
      >
        <VChip
          large
          :class="{
            'big-chip': true,
            'chip-close-alt': chipCloseAlt,
          }"
          :close="closable"
          :close-icon="item.deleted ? 'mdi-restore' : 'mdi-close-circle'"
          @click:close="$emit('removeItem', chipCloseAlt ? item : item.id, modelType)"
        >
          <VAvatar
            v-if="item.hasOwnProperty('photo_url')"
            size="24px"
          >
            <img :src="item.photo_url">
          </VAvatar>
          <VAvatar
            v-else
            color="#737adf"
            height="40px !important"
            width="40px !important"
            min-width="40px !important"
            style="border-radius: 8px;"
          >
            <img 
              src="@/images/doc-icon.png"
              style="height: 20px; width: 20px;"
            >
          </VAvatar>
          <span
            :style="`color: ${item.deleted ? 'red' : '#252526'}; text-decoration: ${item.deleted ? 'line-through' : 'none'}; ${itemTitleStyle}`"
            :class="{
              'px-3 text-truncate': true,
              'mr-3': closable,
            }"
          >
            {{ $user.id == item.id && chipCloseAlt ? "You" : item.title }}
          </span>
        </VChip>
      </VCol>
      <VCol
        v-if="enableAction"
        class="py-0"
        :cols="cols"
      >
        <VBtn
          large
          height="54px"
          :class="{
            'big-chip add-chip': true,
            'chip-close-alt': chipCloseAlt,
          }"
          @click="$emit('addItems', modelType)"
        >
          <VIcon
            left
            color="#757575"
            size="22px"
          >
            mdi-plus-circle
          </VIcon>
          <span style="color: #808080; font-weight: 900; font-size: 18px;">{{ addChipTitle }}</span>
        </VBtn>
      </VCol>
    </VRow>
  </div>
</template>
<script>
export default {
  name: "ChipsList",
  props: {
    normalList: {
      type: Boolean,
      default: true,
    },
    modelType: {
      type: String,
      default: null,
    },
    items: {
      type: [Array, Object],
      default: null,
    },
    enableAction: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: [Number, String],
      default: 6,
    },
    showMoreCount: {
      type: [Number, String],
      default: 0,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    chipCloseAlt: {
      type: Boolean,
      default: false,
    },
    itemTitleStyle: {
      type: String,
      default: "font-weight: 900; font-size: 18px;",
    },
    addChipTitle: {
      type: String,
      default: "Add New",
    },
  },
}
</script>
<style lang="scss">
.chips-list {
  .small-chip {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.12) !important; 
    background-color: #ffffff !important; 
    border-radius: 10px !important;
  }
  .add-chip {
    border: dashed 1.5px #bfbfbf; 
    box-shadow: none !important; 
  }
  .big-chip {
    width: 100%;
    box-shadow: 0 3px 8px 0 rgba(216, 220, 240, 0.8);
    border-radius: 10px !important;
    background-color: #ffffff !important; 
  
    .v-chip__close {
      font-size: 22px !important;
      padding-bottom: 18px;
      position: absolute;
      right: 14px;
      margin: 0px !important;
    }
  }
  .chip-close-alt {
    .v-chip__close {
      padding-bottom: 0px !important;
    }
    .mdi-close-circle::before, .mdi-plus-circle::before {
      opacity: 0.5;
    }
  }
}
</style>