<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card-heading,table-heading,table-tbody"
    :loading="activity.isFirstLoad"
  >
    <VRow
      column
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <ViewHeader :route-params="realAssessment.id">
          <template #title>
            Submitted {{ featureName("Assessments") }}
            <template v-if="realAssessment && realAssessment.id">
              for {{ realAssessment.title || "(not set)" }}
            </template>
          </template>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="visibility"
              :tooltip="`View ${featureName('Assessment').singularize()}`"
              :copyable="false"
              :icon-props="{ small: true }"
              :url="
                getFullyQualifiedUrlForItem(`assessment.view`, {
                  assessment: realAssessment.id,
                })
              "
              :to="
                getRouteForItem(`assessment.view`, {
                  assessment: realAssessment.id,
                })
              "
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <DataViewUI
          :data="data"
          :headers="submittedHeaders"
          :is-loading="activity.isLoading"
          @paginate="onGetMore"
          @search="onSearch"
        >
          <template
            slot="items-cells"
            slot-scope="props"
          >
            <template v-if="$isUserAdmin || $isUserMentor">
              <td>
                <UserChip :user="props.item.user" />
              </td>
              <td v-if="resolvedAssessment.id == 'all'">
                {{ resolveAssessment(props.item).title }}
              </td>
              <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
              <td>
                {{
                  props.item.completed_on
                    ? $timezone.formatDateTime(props.item.completed_on)
                    : "(not completed)"
                }}
              </td>
              <td>{{ props.item.duration }}</td>
              <td>
                {{
                  resolveAssessment(props.item).is_gradable
                    ? props.item.score + "%"
                    : "N/A"
                }}
              </td>
              <td>
                {{
                  resolveAssessment(props.item).is_gradable
                    ? props.item.system_score + "%"
                    : "N/A"
                }}
              </td>
              <td
                v-for="(metadataHeader, metadataIndex) in metadataHeaders"
                :key="metadataIndex"
              >
                <DynamicInput
                  v-if="props.item.result_cards_for_user"
                  compact
                  :input="prepareInput(props.item, metadataHeader)"
                />
              </td>
            </template>
            <template v-else>
              <td v-if="resolvedAssessment.id == 'all'">
                {{ resolveAssessment(props.item).title }}
              </td>
              <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
              <td>
                {{
                  props.item.completed_on
                    ? $timezone.formatDateTime(props.item.completed_on)
                    : "(not completed)"
                }}
              </td>
              <td>{{ props.item.cduration }}</td>
              <td>
                {{
                  resolveAssessment(props.item).is_gradable
                    ? props.item.score + "%"
                    : "N/A"
                }}
              </td>
            </template>
            <td>
              <VBtn
                v-if="$isUserAdmin || $isUserMentor"
                color="primary"
                text
                @click.stop="onToggleSubmittedDialog(props.item, 'assessments')"
              >
                <VIcon>ballot</VIcon>&nbsp;Responses
              </VBtn>
              <VBtn
                v-if="$isUserAdmin || $isUserMentor"
                text
                color="error"
                :loading="props.item.isLoading"
                @click.stop="onDeleteItem(props.item)"
              >
                <VIcon
                  small
                  color="error"
                >
                  delete
                </VIcon>&nbsp;Delete
              </VBtn>
            </td>
          </template>
          <template
            slot="expand"
            slot-scope="props"
          >
            <VCard flat>
              <VCardText>
                <DynamicInputsSubmit
                  v-model="props.item.metadata"
                  :readonly="true"
                  :card="false"
                  :input-name="featureName('Report')"
                  @update:input="onUpdateMetadata"
                />
              </VCardText>
            </VCard>
          </template>
        </DataViewUI>
      </VCol>
    </VRow>
    <FormDialog
      max-width="75%"
      :current-model="submittedModel"
      :model-type="modelType"
      :is-dialog-open="submittedDialogs.submitted"
      :title="
        `Grade ${contentType.singularize()} for ${
          hasSubmittedModel ? submittedModel.user.name : '-'
        }: ${hasSubmittedModel ? resolvedAssessment.title : ''}`
      "
      @toggle:form:dialog="onToggleSubmittedDialog"
      :hide-overlay="true"
    >
      <SubmitForm
        v-if="submittedDialogs.submitted && hasSubmittedModel"
        ref="saveForm"
        mode="dialog"
        :id="submittedModel.id"
        :assessment="
          assessment ||
            submittedModel.entity || { id: submittedModel.entity_id }
        "
        :existing="submittedModel"
        :items="submitted.data"
      />
    </FormDialog>
    <Dialog
      max-width="640px"
      :is-dialog-open="dialogs.view"
      :title="`View ${featureName('Submitted ' + contentType)}`"
      @toggle:dialog="onToggleViewDialog"
    >
      <ViewComponent
        :id="
          currentModel
            ? {
              id: currentModel.id,
              assessment_id: currentModel.assessment_id,
            }
            : null
        "
        ref="viewItem"
        mode="dialog"
      />
    </Dialog>
    <Confirm ref="confirmDelete" />
  </VSkeletonLoader>
</template>
<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import DynamicInput from "@/components/Elements/Data/DynamicInput";
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./ViewSubmitted";

export default {
  name: "AssessmentsSubmitted",
  props: {
    assessment: {
      type: Object,
      default: () => {},
    },
    forSingleUser: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DynamicInputsSubmit,
    SubmitForm,
    ViewComponent,
    DynamicInput,
  },
  mixins: [SearchMixin, HasSubmittedDataMixin, HasAssessmentsMixin],
  data() {
    return {
      localAssessment: null,
      modelType: "assessmentResult",
    };
  },
  computed: {
    resolvedAssessment() {
      return (
        this.localAssessment || this.resolveAssessment({}) || { id: "all" }
      );
    },
    submittedHeaders() {
      let value = [];
      if (this.$isUserAdmin || this.$isUserMentor) {
        value = [
          {
            text: "User",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "entity.title",
            hidden: this.resolvedAssessment.id !== "all",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
          {
            text: "Completed On",
            align: "left",
            sortable: true,
            value: "completed_on",
          },
          {
            text: "Duration",
            align: "left",
            sortable: true,
            value: "duration",
          },
          {
            text: "Score",
            align: "left",
            sortable: true,
            value: "score",
          },
          {
            text: "System Score",
            align: "left",
            sortable: true,
            value: "system_score",
          },
        ].filter((i) => !i.hidden);
      } else {
        value = [
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "entity.title",
            hidden: this.resolvedAssessment.id !== "all",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
          {
            text: "Completed On",
            align: "left",
            sortable: true,
            value: "completed_on",
          },
          {
            text: "Duration",
            align: "left",
            sortable: true,
            value: "duration",
          },
          {
            text: "Score",
            align: "left",
            sortable: true,
            value: "score",
          },
        ].filter((i) => !i.hidden);
      }
      return !this.$isUserStudent
        ? value.concat(this.metadataHeaders)
        : value;
    },
  },
  methods: {
    ...mapActions({
      doGetAssessment: "assessment/getOne",
      doGetIndexConfig: "assessment/getIndexConfig",
      doGetSubmitted: "assessmentResult/getAll",
      doDelete: "assessmentResult/delete",
    }),
    onGetAll(_params) {
      const params = _params || {};
      if (!this.forSingleUser) {
        let id =
          this.$route.params.id || this.$route.query.assessment_result_id;
        let assessmentId;
        if (this.$route.name.indexOf("lesson") > -1) {
          assessmentId =
            this.$route.params.assessment ||
            this.$route.params.id ||
            this.$route.query.assessment_id ||
            this.resolvedAssessment.id ||
            this.deliverableId;
          params.id = id;
        } else {
          assessmentId =
            this.$route.params.assessment ||
            this.$route.query.assessment_id ||
            this.resolvedAssessment.id ||
            this.deliverableId;
        }
        params.assessment_id = assessmentId;
      } else {
        params.assessment_id = -1;
      }
      params._with = ["metadata"];
      params.filter = this.customFilter || {};
      // params.filter.assessment_id = params.assessment_id;
      console.log("params", params);
      return this.doGetSubmitted(params);
    },
    onDelete(params) {
      params.assessment_id = params.assessment_id || this.resolvedAssessment.id;
      return this.doDelete(params);
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    resolveAssessment(item) {
      return item.entity || item.assessment || this.assessment;
    },
    prepareInput(item, metadataHeader) {
      let input = item.result_cards_for_user.find(r => r.assessment_card_id == metadataHeader.cardId || r.card.name == metadataHeader.title);
      if(input) {
        input.type = metadataHeader.type;
        return input;
      }
    },
  },
  created() {
    const assessmentId =
      this.$route.params.assessment ||
      this.$route.query.assessment_id ||
      this.resolvedAssessment.id ||
      this.deliverableId;
    if (assessmentId) {
      this.filter.filter =
        assessmentId !== "all"
          ? { assessment_id: assessmentId }
          : this.filter.filter;
      this.onGetAssessment(assessmentId).then((assessment) => {
        this.localAssessment = assessment;
        this.metadataHeaders = (assessment.cards || [])
          .filter(card => ["Text", "TextArea", "Question"].includes(card.type) && card.name)
          .map((card) => this.prepareLessonSubmittedHeader(card));
        this.setEnableSubmittedHeaders(true);
      });
    }
  },
};
</script>
