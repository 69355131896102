<template>
  <Dialog
    v-if="isDialogOpen"
    enable-footer-slots
    max-width="500px"
    :close-immediately="closeImmediately"
    :action="onHandleReorderAction"
    :custom-dialog-data="[modelType]"
    :is-dialog-open="isDialogOpen"
    :title="title ? title :`Reorder ${featureName(modelType.capitalize())}`"
    @toggle:dialog="$emit('toggle:dialog')"
  >
    <ReorderData
      :value="items"
      :get-items="getItems"
      :model-type="modelType"
      :from-program="fromProgram"
      @input="onSetReorderData"
    />
  </Dialog>
</template>
<script>
import DialogViewMixin from "@/mixins/DialogView";
import ReorderData from "@/components/Elements/Data/ReorderData";
export default {
  name: "ReorderDialog",
  mixins: [DialogViewMixin],
  components: { ReorderData },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    reorderAction: {
      type: Function,
      default: null,
    },
    getItems: {
      type: Function,
      default: null,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    closeImmediately: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: null,
    },
    fromProgram: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      reorderData: [],
    };
  },
  methods: {
    onSetReorderData(data) {
      this.reorderData = data;
    },
    onHandleReorderAction() {
      this.isLoading = true;
      let promise = this.reorderAction.apply(null, [this.reorderData]);
      promise = promise instanceof Promise ? promise : Promise.resolve(promise);
      return promise.then((result) => {
        this.reorderData = result.data || result;
        this.isLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>