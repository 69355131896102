import api from '../../services/chat';
export default function (helper) {
    helper.setApi(api);
    const TYPES = {
        CHAT_THREAD_REQUEST: "CHAT_THREAD_REQUEST",
        CHAT_THREAD_ERROR: "CHAT_THREAD_ERROR",
        CHAT_THREAD_SUCCESS: "CHAT_THREAD_SUCCESS",
        CHAT_THREAD_UPDATE: 'CHAT_THREAD_UPDATE',
        CHAT_THREADS_REQUEST: "CHAT_THREADS_REQUEST",
        CHAT_THREADS_ERROR: "CHAT_THREADS_ERROR",
        CHAT_THREADS_GET_ALL: "CHAT_THREADS_GET_ALL",
        CHAT_THREADS_GROUP_GET_ALL: "CHAT_THREADS_GROUP_GET_ALL",
        CHAT_MESSAGES_REQUEST: "CHAT_MESSAGES_REQUEST",
        CHAT_MESSAGES_ERROR: "CHAT_MESSAGES_ERROR",
        CHAT_MESSAGES_SUCCESS: "CHAT_MESSAGES_SUCCESS",
        CHAT_MESSAGE_REQUEST: "CHAT_MESSAGE_REQUEST",
        CHAT_MESSAGE_ERROR: "CHAT_MESSAGE_ERROR",
        CHAT_MESSAGE_SUCCESS: "CHAT_MESSAGE_SUCCESS",
        CHAT_SAVE_MESSAGE_REQUEST: "CHAT_SAVE_MESSAGE_REQUEST",
        CHAT_SAVE_MESSAGE_ERROR: "CHAT_SAVE_MESSAGE_ERROR",
        CHAT_SAVE_MESSAGE_SUCCESS: "CHAT_SAVE_MESSAGE_SUCCESS",
        CHAT_SAVE_THREAD_REQUEST: "CHAT_SAVE_THREAD_REQUEST",
        CHAT_SAVE_THREAD_ERROR: "CHAT_SAVE_THREAD_ERROR",
        CHAT_SAVE_THREAD_SUCCESS: "CHAT_SAVE_THREAD_SUCCESS",
        CHAT_SAVE_GROUP_THREAD_REQUEST: "CHAT_SAVE_GROUP_THREAD_REQUEST",
        CHAT_SAVE_GROUP_THREAD_ERROR: "CHAT_SAVE_GROUP_THREAD_ERROR",
        CHAT_SAVE_GROUP_THREAD_SUCCESS: "CHAT_SAVE_GROUP_THREAD_SUCCESS",
        CHAT_SET_HUDDLE_MODE: "CHAT_SET_HUDDLE_MODE"
    };

    const state = {
        status: {
            thread: '',
            threads: '',
            messages: '',
            message: '',
            saveMessage: ''
        },
        threads: {
            group: {
                data: []
            },
            individual: {
                data: []
            }
        },
        thread: null,
        forHuddle: false,
        huddleThread: null,
        message: null,
        messages: {
            data: []
        }
    };

    const getters = {
        currentThread: state => state.forHuddle ? state.huddleThread : state.thread,
        threads: state => state.threads.individual,
        groupThreads: state => state.threads.group,
        messages: state => state.messages,
        isThreadsLoaded: state => state.threads.individual && !!state.threads.individual.data.length,
        isGroupThreadsLoaded: state => state.threads.group && !!state.threads.group.data.length,
        isMessagesLoaded: state => state.messsages && !!state.messages.data.length
    };

    const actions = {
        getThreads(context, params) {
            context.commit(TYPES.CHAT_THREADS_REQUEST);
            helper.log().info("[Store Chat:] Getting Threads", params);
            return new Promise((resolve, reject) => {
                return helper.api().getThreads(params)
                    .then(response => {
                        helper.log().info("[Store Chat:] Got Threads", response.data);
                        if (params.groups) {
                            context.commit(TYPES.CHAT_THREADS_GROUP_GET_ALL, response.data);
                            resolve(context.getters.groupThreads);
                        } else {
                            context.commit(TYPES.CHAT_THREADS_GET_ALL, response.data);
                            resolve(context.getters.threads);
                        }
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Getting Thread', error);
                    });
            });
        },
        setHuddleMode(context, status) {
            return Promise.resolve(context.commit(TYPES.CHAT_SET_HUDDLE_MODE, status));
        },
        setThread(context, thread) {
            return Promise.resolve(context.commit(TYPES.CHAT_THREAD_SUCCESS, thread));
        },
        updateThread(context, properties) {
            return Promise.resolve(context.commit(TYPES.CHAT_THREAD_UPDATE, properties));
        },
        getThread(context, id) {
            context.commit(TYPES.CHAT_THREAD_REQUEST);
            helper.log().info("[Store Chat:] Getting Thread", id);
            return helper.api().getThread(id)
                .then(response => {
                    context.commit(TYPES.CHAT_THREAD_SUCCESS, response.data);
                    return response.data;
                })
                .catch(error => {
                    helper.log().info('[Store: Chat]: Error Getting Thread', error);
                })
        },
        saveThread(context, data) {
            let {
                id
            } = data;
            const params = data.data || data;
            context.commit(TYPES.CHAT_SAVE_THREAD_REQUEST);
            helper.log().info("[Store Chat:] Saving Thread", params);
            return new Promise((resolve, reject) => {
                return helper.api().saveThread(data)
                    .then(response => {
                        context.commit(TYPES.CHAT_SAVE_THREAD_SUCCESS, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Saving Thread', error);
                    });
            });
        },
        deleteThread(context, data) {
            let {
                id
            } = data;
            const params = data.data || data;
            helper.log().info("[Store Chat:] Deleting Thread", params);
            return new Promise((resolve, reject) => {
                return helper.api().deleteThread(data)
                    .then(response => {
                        helper.log().info("[Store Chat:] Deleted Thread", response);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Deleting Thread', error);
                    });
            });
        },
        saveGroupThread(context, data) {
            let {
                id
            } = data;
            const params = data.data || data;
            context.commit(TYPES.CHAT_SAVE_THREAD_REQUEST);
            helper.log().info("[Store Chat:] Saving Group Thread", params);
            return new Promise((resolve, reject) => {
                return helper.api().saveGroupThread(data)
                    .then(response => {
                        context.commit(TYPES.CHAT_SAVE_GROUP_THREAD_SUCCESS, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Saving GroupThread', error);
                    });
            });
        },
        clearMessages(context) {
            helper.log().info("[Store Chat:] Clearing Messages");
            return Promise.resolve(context.commit(TYPES.CHAT_MESSAGES_SUCCESS, {
                data: []
            }));
        },
        getMessages(context, params) {
            helper.log().info("[Store Chat:] Getting Messages", params);
            context.commit(TYPES.CHAT_MESSAGES_REQUEST);
            return new Promise((resolve, reject) => {
                return helper.api().getMessages(params)
                    .then(response => {
                        context.commit(TYPES.CHAT_MESSAGES_SUCCESS, response.data);
                        resolve(context.getters.messages);
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Getting Messages', error);
                    });
            });
        },
        getMessage(context, id) {
            helper.log().info("[Store Chat:] Getting Message", id);
            context.commit(TYPES.CHAT_MESSAGE_REQUEST);
            return helper.api().getMessage(id)
                .then(response => {
                    context.commit(TYPES.CHAT_MESSAGE_SUCCESS, response.data);
                    return response.data;
                })
                .catch(error => {
                    helper.log().info('[Store: Chat]: Error Getting Message', error);
                })
        },
        saveMessage(context, data) {
            let {
                id
            } = data;
            const params = data.data || data;
            context.commit(TYPES.CHAT_SAVE_MESSAGE_REQUEST);
            helper.log().info("[Store Chat:] Saving Message", params);
            return new Promise((resolve, reject) => {
                return helper.api().saveMessage(data)
                    .then(response => {
                        context.commit(TYPES.CHAT_SAVE_MESSAGE_SUCCESS, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        helper.log().info('[Store: Chat]: Error Saving Message', error);
                    })
            });
        },
        joinPublicThread(context, thread) {
            helper.log().info("[Store Chat:] Join Public Group", thread);
            return helper.api().joinPublicThread(thread)
                .then(response => {
                    return response.data.data;
                })
                .catch(error => {
                    helper.log().info('[Store: Chat]: Error Join Public Group', error);
                })
        },
    };

    const mutations = {
        [TYPES.CHAT_SET_HUDDLE_MODE]: (state, status) => {
            state.forHuddle = status;
        },
        // Threads mutatioins
        [TYPES.CHAT_THREADS_REQUEST]: (state, payload) => {
            state.status.threads = "loading";
        },
        [TYPES.CHAT_THREADS_GROUP_GET_ALL]: (state, payload) => {
            state.status.threads = "success";
            state.threads.group = payload;
        },
        [TYPES.CHAT_THREADS_GET_ALL]: (state, payload) => {
            state.status.threads = "success";
            state.threads.individual = payload;
        },
        [TYPES.CHAT_THREADS_ERROR]: state => {
            state.status.threads = "error";
        },
        // Thread mutatioins
        [TYPES.CHAT_THREAD_REQUEST]: (state, payload) => {
            state.status.thread = "loading";
        },
        [TYPES.CHAT_THREAD_UPDATE]: (state, properties) => {
            if(state.forHuddle) {
                return state.huddleThread = {
                    ...(state.huddleThread || {}),
                    ...(properties || {})
                };
            } else {
                return state.thread = {
                    ...(state.thread || {}),
                    ...(properties || {})
                };
            }
        },
        [TYPES.CHAT_THREAD_SUCCESS]: (state, payload) => {
            state.status.thread = "success";
            if(state.forHuddle) {
                return state.huddleThread = payload.data || payload;
            } else {
                return state.thread = payload.data || payload;
            }
        },
        [TYPES.CHAT_THREAD_ERROR]: state => {
            state.status.thread = "error";
        },
        // Messages mutatioins
        [TYPES.CHAT_MESSAGES_REQUEST]: (state, payload) => {
            state.status.messages = "loading";
        },
        [TYPES.CHAT_MESSAGES_SUCCESS]: (state, payload) => {
            state.status.messages = "success";
            const thread = (state.messages.data[0] || {}).thread;
            const firstMessage = payload.data[0];
            if (thread && firstMessage && thread.thread_id == firstMessage.thread_id) {
                state.messages = {
                    ...payload,
                    data: state.messages.data.unshift(...payload.data)
                };
            } else {
                state.messages = payload;
            }
        },
        [TYPES.CHAT_MESSAGES_ERROR]: state => {
            state.status.messages = "error";
        },
        // Message mutatioins
        [TYPES.CHAT_SAVE_MESSAGE_REQUEST]: (state, payload) => {
            state.status.saveMessage = "loading";
        },
        [TYPES.CHAT_SAVE_MESSAGE_SUCCESS]: (state, payload) => {
            state.status.saveMessage = "success";
            // helper.utils.addToStateData(state.messages.data, payload, true, true);
        },
        [TYPES.CHAT_SAVE_MESSAGE_ERROR]: state => {
            state.status.saveMessage = "error";
        },
        // Thread mutatioins
        [TYPES.CHAT_SAVE_THREAD_REQUEST]: (state, payload) => {
            state.status.saveThread = "loading";
        },
        [TYPES.CHAT_SAVE_THREAD_SUCCESS]: (state, payload) => {
            state.status.saveThread = "success";
            helper.utils.addToStateData(state.threads.individual.data, payload, true);
        },
        [TYPES.CHAT_SAVE_GROUP_THREAD_SUCCESS]: (state, payload) => {
            state.status.saveThread = "success";
            helper.utils.addToStateData(state.threads.group.data, payload, true);
        },
        [TYPES.CHAT_SAVE_THREAD_ERROR]: state => {
            state.status.saveThread = "error";
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}