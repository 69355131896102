<template>
  <VRow
    fill-height
    align-start
    row
    wrap
    :justify-start="card.name || description || card.layout == 'Left'"
    :justify-end="!card.name && !description && card.layout == 'Right'"
  >
    <VCol
      v-if="hasUrl"
      :class="{
        'card-image layout row justify-center align-center mb-0': true,
        'order-first': card.images.length === 0 && card.layout == 'Left',
        'order-last': card.images.length === 0 && card.layout == 'Right',
      }"
      :style="{
        overflow: 'hidden',
        flex: $vuetify.breakpoint.mdAndUp ? '0 0 450px' : '1 1 100%',
        paddingTop: '2px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        margin: '0px',
        borderRadius: '6px',
        height: '450px'
      }"
    >
      <CardTitle
        v-if="card.name && $vuetify.breakpoint.smAndDown"
        class="card-title"
        :align="!description || $vuetify.breakpoint.smAndDown ? 'center' : 'left'"
        :style="{
          fontSize: `${'24px'}!important`,
        }"
      >
        {{ card.name }}
      </CardTitle>
      <VImg
        contain
        class="transparent"
        height="450px"
        width="450px"
        :src="url"
        :style="{
          borderRadius: '6px',
          color: '#000',
          cursor: 'zoom-in'
        }"
        @click="dialogs.preview = true"
      >
        <template #placeholder>
          <VRow
            fill-height
            align-center
            justify-center
            ma-0
          >
            <VProgressCircular
              indeterminate
              color="grey lighten-2"
            />
          </VRow>
        </template>
      </VImg>
      <Preview
        v-if="dialogs.preview"
        :files="[card.image || card.value]"
        @toggle="dialogs.preview = !dialogs.preview"
      />
    </VCol>
    <VCol
      v-if="card.name || description && $vuetify.breakpoint.mdAndUp"
      :class="{
        'd-flex align-center': true,
        'order-first': card.images.length === 0 && card.layout == 'Right',
        'order-last': card.images.length === 0 && card.layout == 'Left',
      }"
      :style="{
        flex: $vuetify.breakpoint.smAndDown ? '0 1 100%' : '1 1 calc(100% - 474px)',
        height: $vuetify.breakpoint.smAndDown ? 'auto' : 'calc(100% - 104px)',
        overflowY: 'auto',
        paddingLeft: '16px',
        marginTop: '50px',
        marginBottom: '50px',
        marginRight: '24px',
      }"
    >
      <VRow 
        space-around
        :style="{
          height: $vuetify.breakpoint.smAndDown ? 'auto' : '100%',
          overflow: 'hidden auto',
        }"
      >
        <VCol>
          <CardTitle
            class="card-title"
            :align="!description || $vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            :style="{
              fontSize: `${'24px'}!important`,
            }"
          >
            {{ card.name }}
          </CardTitle>
          <p
            v-if="$vuetify.breakpoint.mdAndUp"
            v-html="description"
            v-linkified
            :class="{
              formatted: true,
            }"
            :style="{
              lineHeight: 'normal',
              fontSize: '16px',
              opacity: 0.75,
              fontWeight: 300,
            }"
          />
        </VCol>
      </VRow>
    </VCol>
    <VCol v-if="description && $vuetify.breakpoint.smAndDown">
      <p
        :class="{
          formatted: true,
          'text-center': $vuetify.breakpoint.smAndDown
        }"
        v-html="description"
        v-linkified
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";
import Preview from "@/components/Elements/Dialogs/Preview";

export default {
  name: "PresentationCardImage",
  mixins: [PresentationCardMixin],
  components: {
    Preview,
  },
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogs: {
        preview: false,
      },
    };
  },
  computed: {
    url() {
      if (this.card.image && this.card.image instanceof Object) {
        return this.card.image.url || this.card.value instanceof Object
          ? this.card.value.url
          : "";
      } else {
        return this.card.value instanceof Object ? this.card.value.url : "";
      }
    },
    thumb() {
      return this.card.image instanceof Object && this.card.image.thumb
        ? this.card.image.thumb
        : "";
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: "24px" !important;
  font-weight: "normal" !important;
  line-height: 0.96 !important;
}
</style>

