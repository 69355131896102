import BaseApi from "./_base-api";

/**
 * Submitted Pipeline Stages API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipeline Stages]: Get Submitted Pipeline Stages",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				{ params }
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Pipeline Stages: Get Submitted Pipeline Stages]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getFormConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipeline Stages]: Get Submitted Pipeline Stages Form Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getFormConfig", "form-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{ params }
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Pipeline Stages: Get Submitted Pipeline Stages Form Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getIndexConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipeline Stages]: Get Submitted Pipeline Stages Index Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getIndexConfig", "index-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{ params }
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Pipeline Stages: Get Submitted Pipeline Stages Index Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getOne(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipeline Stages]: Get Submitted Pipeline Stage",
			{ params }
		);
		return this.api()
			.get(
				`${this.getUrl("getOne", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				params
			)
			.catch((error) => {
				this.log().error(
					"[Services: Submitted Pipeline Stages: Get Submitted Pipeline Stages]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	save(params) {
		let { pipelineStageId, id } = params;
		pipelineStageId = pipelineStageId || params.data.pipeline_stage_id;
		if (!pipelineStageId) {
			throw new Error("Missing pipeline stage");
		}
		params.data.pipeline_stage_id = pipelineStageId;
		return super.save(params);
	}
}

const api = new Api({
	type: "Submitted Pipeline Stages",
	path: {
		getAll: (app, _params) => {
			const params = _params || {};
			const pipelineStageId =
				params.pipelineStageId || params.pipeline_stage_id;
			const pipelineId = params.pipelineId || params.pipeline_id || params.id;
			if (pipelineId && pipelineStageId) {
				return `pipeline_stages_submitted/${pipelineId}/${pipelineStageId}`;
			} else if (pipelineId) {
				return `pipeline_stages_submitted/${pipelineId}`;
			}

			return "pipeline_stages_submitted";
		},
		getOne: (app, _params) => {
			const params = _params || {};
			const pipelineStageId =
				params.pipelineStageId || params.pipeline_stage_id;
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipeline_stages_submitted/${pipelineId}/${pipelineStageId}`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const pipelineStageId =
				params.pipelineStageId || params.pipeline_stage_id;
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipeline_stages_submitted/${pipelineId}/${pipelineStageId}`;
		},
		default: "pipeline_stages_submitted",
	},
});

export default api;
