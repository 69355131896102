<script>
import Vue from "vue";
import get from "sugar/object/get";

export default {
  props: {
    metadataSections: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      localMetadataSections: null,
    };
  },
  methods: {
    async onNewMetadata(value, index, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      this.$log.debug(
        `${this._name}: [MetadataMixin]: Adding `,
        value,
        " to ",
        property,
        this[formProperty],
        "at index",
        index
      );
      let destination = this.locateMetadataArray(formProperty, property);
      if (!(destination instanceof Array)) {
        destination = [];
        Vue.set(this[formProperty], property, destination);
      }
      const hasUserProvidedIndex = index !== undefined && parseInt(index) > -1;
      const insertedAt = index + 1;

      value.value = await this.getDefaultValueFor(
        value.linked_metadata || value
      );

      if (hasUserProvidedIndex) {
        destination.splice(insertedAt, 0, value);
      } else {
        destination.push(value);
      }
      if (value instanceof Object) {
        Vue.set(value, "priority", index || destination.length - 1);
      }
      this.setModelDirty();
      // Return default index if the lesson has no card or only one
      if (this[formProperty][property].length <= 1) {
        return index;
      }

      return hasUserProvidedIndex ? index + 1 : destination.length - 1;
    },
    async onRemoveMetadata(key, prop, formProp) {
      if (key instanceof Object && key.id) {
        return this.onDeleteMetadata(key, prop);
      }
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      const index = this.findMetadataIndex(key, property, formProperty);
      if (index > -1) {
        this.$log.debug(
          `${this._name}: [MetadataMixin]: Removing `,
          key,
          " from ",
          property,
          "at index",
          index
        );
        const destination = this.locateMetadataArray(formProperty, property);
        Vue.delete(destination, index);
      }
      this.setModelDirty();
      return index;
    },
    async onRemoveMultipleMetadata(group, prop, formProp) {
      const result = [];
      if (group && group instanceof Array && group.length) {
        const property = prop || "metadata";
        const formProperty = formProp || "form";
        const dontSave = group.filter((input) => input.id && input.id > 0);
        const toRemove = group.filter((input) => input.id === undefined);
        dontSave.map((input) => {
          if (input instanceof Object || input) {
            if (input instanceof Object && input.id) {
              return this.onDeleteMetadata(input, prop, formProp);
            }
          }
        });
        if (toRemove.length) {
          let destination = get(this, formProperty);
          if (Object.has(destination, property)) {
            const realIndexes = toRemove.map((input) => input.realIndex);
            Vue.set(
              destination,
              property,
              destination[property].filter(
                (input) => realIndexes.indexOf(input.realIndex) < 0
              )
            );
          }
        }
      }
      this.setModelDirty();
      return result;
    },
    async onDeleteMetadata(key, prop, formProp) {
      if (key instanceof Object && key.deleted) {
        return this.onRestoreMetadata(key, prop);
      }
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      const index = this.findMetadataIndex(key, property, formProperty);
      if (index > -1) {
        this.$log.debug(
          `${this._name}: [MetadataMixin]: Deleting `,
          key,
          " from ",
          property,
          "at index",
          index
        );
        const path = `${property}.${index}`;
        // Changed to support 2 level of property e.g. form.stages[0]
        const object = get(get(this, formProperty), path) || {};
        Vue.set(object, `deleted`, true);
      }
      return index;
    },
    async onRestoreMetadata(key, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      const index = this.findMetadataIndex(key, property, formProperty);
      if (index > -1) {
        this.$log.debug(
          `${this._name}: [MetadataMixin]: Restoring `,
          key,
          " from ",
          property,
          "at index",
          index
        );
        const path = `${property}.${index}`;
        const object = this.locateMetadataObject(formProperty, path);
        Vue.delete(object, `deleted`);
      }
      return index;
    },
    async onUpdateMultipleMetadata(group, prop, formProp) {
      const result = [];
      if (group && group instanceof Array && group.length) {
        group.map((input) => {
          if (input instanceof Object || input) {
            result.push(
              this.onUpdateMetadata(
                input instanceof Object
                  ? input.realIndex || input.priority
                  : null,
                input,
                prop,
                formProp
              )
            );
          }
        });
      }
      this.setModelDirty();
      return result;
    },
    async onUpdateMetadata(key, value, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      const index = this.findMetadataIndex(
        value instanceof Object && value.id ? value : key,
        property,
        formProperty
      );
      if (index > -1) {
        const path = `${property}.${index}`;
        const destination = this.locateMetadataArray(formProperty, property);
        const object = this.locateMetadataObject(formProperty, path);
        this.$log.debug(
          `${this._name}: [MetadataMixin]: Updating `,
          key,
          " in ",
          formProperty + "." + path,
          "with",
          value,
          object
        );
        Vue.set(destination, index, {
          ...object,
          ...value,
        });
      }
      this.setModelDirty();
      return index;
    },
    findMetadata(key, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      return this.locateMetadataObject(formProperty, property);
    },
    findMetadataIndex(key, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      const realKey = key instanceof Object ? key.id || key.realIndex : key;
      this.$log.debug(
        `${this._name}: [MetadataMixin]: Searching for`,
        key,
        realKey,
        property,
        formProperty
      );

      return this.locateMetadataArray(formProperty, property).findIndex(
        (v, k) => {
          return v instanceof Object &&
            key instanceof Object &&
            v.hasOwnProperty("id")
            ? v.id === realKey
            : k === realKey;
        }
      );
    },
    locateMetadataArray(formProperty, property, defaultValue) {
      const object = get(get(this, formProperty), property) || defaultValue || [];
      return object instanceof Array ? object : Object.values(object || {});
    },
    locateMetadataObject(formProperty, property, defaultValue) {
      return get(this[formProperty], property) || defaultValue || {};
    },
    onSetMetadataProperty(field, value, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      this.$log.debug(
        `${this._name}: [MetadataMixin]: Setting metadata property`,
        formProperty,
        `${property}.${field}`,
        value
      );
      const destination = this.locateMetadataObject(formProperty, property);
      Vue.set(destination, field, value);
    },
    onSetMetadata(value, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      this.$log.debug(
        `${this._name}: [MetadataMixin]: Setting metadata`,
        formProperty,
        property,
        value,
        this
      );
      let destination = get(this, formProperty);
      if (destination instanceof Object) {
        Vue.set(destination, property, value);
      }
    },
    onSetMetadataSections(value, prop, formProp) {
      this.onSetMetadata(value, prop || "metadata_sections", formProp);
      this.setModelDirty();
    },
    setModelDirty() {
      if(typeof(this.modelIsDirty) != undefined) {
        this.onSetModelDirty();
      }
    },
    onUpdateMetadataSections(key, value, prop, formProp) {
      this.onUpdateMetadata(key, value, prop || "metadata_sections", formProp);
    },
    allMetadata(value, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      return get(this[formProperty], property);
    },
    allUndeletedMetadata(value, prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";

      return this.locateMetadataArray(formProperty, property).filter(
        (v) => v.deleted !== true
      );
    },
    /**
     * We're using dot notation to access nested properties using SugarJS Object
     */
    getMetadataKey(prop, formProp) {
      const property = prop || "metadata";
      const formProperty = formProp || "form";
      const path = formProperty
        .split(".")
        .concat(property ? property.split(".") : []);
      return path.join(".");
    },
    getDeleteIcon(input) {
      return input.deleted ? "refresh" : "delete";
    },
    getDeleteText(input) {
      return input.deleted ? "Restore" : "Delete";
    },
    getMetadataForSubmit(metadata) {
      return metadata instanceof Array
        ? metadata.map((input, index) => {
            return this.prepareMetadata(input, index);
          })
        : [];
    },
    getDefaultValueFor(metadata) {
      let value,
        from = null;
      from = get(metadata, "default_value.from");
      value = get(metadata, "default_value.value");
      if (from) {
        const [provider, property] = from.split("::");
        this.$log.info(`Getting ${property} from ${provider}`);
        switch (provider.toLowerCase()) {
          case "user":
            const parts = property.split(".");
            switch (parts[0]) {
              case "location":
                fetch("https://geolocation-db.com/json")
                  .then((location) => {
                    value =
                      parts.length === 2
                        ? location[parts[1]]
                        : `${location.city}, ${location.state} ${location.country_name}`;
                  })
                  .catch((error) => {
                    this.$log.info("Failed to load locaton for default value");
                  });
                break;

              default:
                value = get(this.$user, property);
                break;
            }
            break;

          case "team":
            value = get(this.$team, property);
            break;
        }
      }
      return value;
    },
    prepareMetadata(metadata, index) {
      const value = this.getDefaultValueFor(metadata);
      return {
        type: metadata.type,
        name: metadata.name,
        realIndex: index || metadata.priority,
        priority: index || metadata.priority,
        options: metadata.options,
        linked_metadata_id: metadata.id,
        value,
        is_required: metadata.is_required,
        section: metadata.section,
        default_value: metadata.default_value || {
          from: "Custom",
          value: null,
        },
        linked_metadata: metadata,
      };
    },
    hasSectionDescription(section) {
      return this.findMetadataSection(section) instanceof Object;
    },
    sectionDescription(section) {
      return (this.findMetadataSection(section) || {}).description || "";
    },
    findMetadataSection(section) {
      const sections = this.localMetadataSections || this.sections;
      if (sections instanceof Array) {
        return sections.find((s) => s.title == section);
      } else if (sections instanceof Object) {
        return sections[section];
      }
    },
  },
};
</script>