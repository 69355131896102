<template>
  <VTooltip 
    top
    :disabled="tooltipText.length === 0"
  >
    <template #activator="{on}">
      <span
        :style="containerStyle"
        :class="containerClass"
      >
        <VHover v-slot="{ hover }">
          <BetaBadge
            v-bind="{...betaOptions}"
            :style="{
              zIndex: 1,
              width: isBlock ? '100%' : 'auto',
            }"
            :is-beta="isBetaFeature(betaFeature)"
          >
            <VBtn
              v-on="on"
              v-bind="{...$props, ...$attrs}"
              link
              :href="url"
              :style="buttonStyle"
              :text="textButton"
              :icon="iconButton"
              :block="isBlock"
              :class="getButtonClasses(hover)"
              @click.prevent.stop="doAction"
              @mouseover="buttonHover = true"
              @mouseout="buttonHover = false"
            >
              <VIcon v-bind="iconProps">
                {{ icon }}
              </VIcon>
              <span
                :class="{
                  'must-show': canShowText || hover
                }"
              >{{ text ? `&nbsp;${text}` : '' }}</span>
            </VBtn>
          </BetaBadge>
        </VHover>
      </span>
    </template>
    <span v-if="tooltipText.length">{{ tooltipText }}<small
      v-if="isBetaFeature(betaFeature)"
      style="max-width: 100px; width: 100%"
    ><br><strong>NOTE</strong>: This is a beta feature. If you experience issues please contact support using the help icon above</small></span>
  </VTooltip>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: null,
    },
    grow: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    iconButton: {
      type: Boolean,
      default: false,
    },
    forceExpandable: {
      type: Boolean,
      default: true,
    },
    expandOnHover: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: [Boolean, Function],
      default: true,
    },
    buttonClass: {
      type: [String, Object, Array],
      default: "wethrive",
    },
    hoverClass: {
      type: String,
      default: "expandable-button--hover",
    },
    buttonStyle: {
      type: [String, Object, Array],
      default: "",
    },
    containerClass: {
      type: String,
      default: "",
    },
    containerStyle: {
      type: [String, Object, Array],
      default: "",
    },
    tooltipText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "add",
    },
    url: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
      default: () => {
        {
          small: true;
        }
      },
    },
    betaFeature: {
      type: String,
      default: null,
    },
    betaOptions: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: null,
    },
    action: {
      type: [Function, Boolean],
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      buttonHover: false,
    };
  },
  computed: {
    canShowText() {
      if (!this.icon || (this.icon && !this.icon.length)) {
        return true;
      } else if (this.showText instanceof Function) {
        return this.showText();
      } else if (this.showText !== null) {
        return !!this.showText;
      } else if (this.forceExpandable) {
        return (
          (this.$vuetify.breakpoint.smAndUp && this.buttonHover) ||
          this.$vuetify.breakpoint.mdAndUp
        );
      }
    },
    isBlock() {
      return this.block !== null
        ? !!this.block
        : this.$vuetify.breakpoint.xsAndDown;
    },
  },
  methods: {
    doAction() {
      if (this.action instanceof Function) {
        console.log("Doing function");
        this.action();
      } else {
        this.$emit("click");
      }
    },
    getButtonClasses(hover) {
      if(this.expandOnHover) {
        let userClass = {
          flex: this.flex,
          grow: this.grow,
          [this.hoverClass]: hover,
          "ml-1": true,
          "expandable-button": true,
          "expandable-button-no-hover": !this.showText,
          "expandable-button-hover":
            (this.buttonHover || this.canShowText) && this.showText,
        };
        if (this.buttonClass instanceof Array) {
          const classes = this.buttonClass.join(" ");
          userClass[classes] = true;
        } else if (this.buttonClass instanceof Object) {
          userClass = { ...userClass, ...this.buttonClass };
        } else if (typeof this.buttonClass === "string") {
          userClass[this.buttonClass] = true;
        }
        return userClass;
      } else {
        return {};
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../node_modules/vuetify/src/styles/settings/_variables.scss";
:root {
  .beta-chip.v-badge {
    padding: 0px !important;
  }
}
.v-btn.expandable-button {
  transition: all 250ms ease-in-out;
  flex: 0 0 44px !important;
  span {
    display: inline-block;
    font-size: inherit;
    transition: all 250ms ease-in-out;
    &.must-show {
      font-size: inherit;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, 'md') - 1}) {
    flex: 1 1 auto !important;
  }
}

.v-btn.expandable-button.v-btn--block {
  flex: 1 1 100% !important;
}

.v-btn.expandable-button-hover,
.v-btn.expandable-button:hover {
  @media (min-width: #{map-get($grid-breakpoints, 'md') - 1}) {
    flex: 1 1 44px !important;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;

    span {
      opacity: 1;
      font-size: inherit;
    }
    &.expandable-button-no-hover {
      width: initial;
      padding-left: initial;
      padding-right: initial;
    }
  }

  @media (max-width: 960px) {
    flex: 1 1 auto !important;
  }
}
</style>