<template>
  <h1
    class="card-title mb-2"
    :class="classes"
    :style="{
      textAlign: `${align || 'auto'}!important`,
    }"
  >
    <slot>{{ title }}</slot>
  </h1>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 1
    },
    align: {
      type: String,
      default: null
    },
    customClass: {
      type: [String, Array, Object],
      default: ""
    },
    hasImages: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const customClass = this.getAllClassesOrStyles(this.customClass);
      const defaultClasses = this.getAllClassesOrStyles({
        [`display-${this.size}`]: true,
        "pt-4": true,
        "pr-4": this.$vuetify.breakpoint.mdAndUp,
        "text-sm-center": !this.align && this.$vuetify.breakpoint.smAndDown,
        "text-sm-right":
          !this.align && this.$vuetify.breakpoint.mdAndUp && !this.hasImages,
        "text-sm-left":
          !this.align && this.$vuetify.breakpoint.mdAndUp && this.hasImages
      });
      return this.combineClassesOrStyles(defaultClasses, customClass);
    }
  }
};
</script>

<style scoped>
.card-title {
  font-size: 24px !important;
  line-height: 0.96 !important;
  font-weight: 500;
  color: #000000;
}
</style>