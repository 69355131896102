<template>
  <VRow
    row
    wrap
    align-center
  >
    <VCol cols="12">
      <VResponsive
        height="310"
        id="team-banner"
        class="d-flex align-center justify-center"
      >
        <VImg
          id="banner-image"
          cover
          alt="Registration Banner"
          :src="bannerUrl"
          style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
        />
        <slot />
      </VResponsive>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    team: {
      type: Object,
      default: () => {}
    },
    imageSize: {
      type: Number,
      default: 96
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    bannerUrl() {
      return this.team.register_banner ? this.team.register_banner.url : "";
    }
  }
};
</script>