import BaseApi from "./_base-api";

class Api extends BaseApi {
	save(params) {
		let { calendarEntryId, id } = params;
		calendarEntryId = calendarEntryId || params.data.calendar_entry_id;
		if (!calendarEntryId) {
			throw new Error("Missing calendar entry");
		}
		params.data.calendar_entry_id = calendarEntryId;
		return super.save(params);
	}
	/**
	 * Accept an RSVP
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	accept(params) {
		this.log().info(
			"[Services: CalendarEntryRsvps]: AcceptCalendarEntryRsvp",
			params
		);
		const method = params.id ? 'put' : 'post';
		return this.api()[method](this.getUrl("accept", "accept", params))
			.catch(error => {
				this.log().error(
					"[Services: CalendarEntryRsvp]: AcceptCalendarEntryRsvp: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	/**
	 * Reject an RSVP
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	reject(params) {
		this.log().info(
			"[Services: CalendarEntryRsvps]: RejectCalendarEntryRsvp",
			params
		);
		const method = params.id ? 'put' : 'post';
		return this.api()[method](this.getUrl("reject", "reject", params))
			.catch(error => {
				this.log().error(
					"[Services: CalendarEntryRsvp]: RejectCalendarEntryRsvp: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	/**
	 * Update the status of an RSVP
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	status(params) {
		this.log().info(
			"[Services: CalendarEntryRsvps]: UpdateCalendarEntryRsvpStatus",
			params
		);
		return this.api()
			.put(this.getUrl("status", "status", params))
			.catch(error => {
				this.log().error(
					"[Services: CalendarEntryRsvp]: UpdateCalendarEntryRsvpStatus: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Calendar Entry RSVP",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp`;
		},
		accept: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp/accept${params.id ? '/'+params.id : ''}`;
		},
		reject: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp/reject${params.id ? '/'+params.id : ''}`;
		},
		status: (app, _params) => {
			const params = _params || {};
			const calendarEntryId =
				params.calendarEntryId || params.calendar_entry_id;
			return `calendar_entry/${calendarEntryId}/rsvp/status/${params.status}${params.id ? '/'+params.id : ''}`;
		},
		default: "calendar_entry_rsvp"
	}
});

export default api;
