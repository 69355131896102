<template>
  <VCard v-if="(option.options instanceof Object)">
    <VCardText>
      <Duration
        v-if="option.options.type === 'widget' && option.options.options === 'duration'"
        @input="value => emitInput(value)"
        :value="value"
      />
      <Autocomplete
        v-else
        :value="value"
        :multiple="option.options.multiple"
        :items="optionValues"
        :item-value="option.options.value || 'id'"
        :item-text="option.options.text || 'title'"
        :label="label"
        :placeholder="placeholder"
        @input="value => emitInput(value)"
      />
    </VCardText>
  </VCard>
</template>
<script>
import BaseOptions from "./BaseOptions";
import Duration from "./Widgets/Duration";
export default {
  extends: BaseOptions,
  components: {
    Duration
  }
};
</script>