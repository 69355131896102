<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol cols="12">
      <VSelect
        v-model="form.attached_assessment"
        dense
        outlined
        item-text="title"
        item-value="id"
        placeholder="Type..."
        class="textarea-field"
        :items="cardOptions.worksheets"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <AssessmentPresentCards
        v-if="form.attached_assessment && attachedAssessment.id"
        mode="embed"
        height="100%"
        :assessment="attachedAssessment"
        :cards="cards"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";
import HasAssessmentsMixin from "@/mixins/HasAssessments.vue";

export default {
  name: "SaveCardWorksheet",
  mixins: [SaveCardMixin, HasAssessmentsMixin],
  props: {
    card: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      attachedAssessment: {}
    };
  },
  created() {
    if (this.form && this.form.id) {
      this.form.attached_assessment =
        this.form.attached_assessment instanceof Object
          ? this.form.attached_assessment.id
          : this.form.attached_assessment;
    }
  },
  watch: {
    "form.attached_assessment": function(id) {
      if (id) {
        this.doGetWorksheet(id).then(result => {
          this.attachedAssessment = result;
        });
      }
    }
  },
  computed: {
    cards() {
      return this.card instanceof Object && this.card.cards instanceof Array
        ? this.card.cards
        : [];
    }
  }
};
</script>
