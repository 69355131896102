import api from "../../services/calendar";
export default function (helper) {
	function getAllEntries(entry) {
		let entries = [entry];
		if (entry.entries instanceof Array) {
			entries = entries.concat(entry.entries);
		}
		return entries.filter((e) => e instanceof Object);
	}
	function getAllRsvps(entry) {
		let rsvps = [];
		if (entry.rsvps instanceof Array) {
			rsvps = rsvps.concat(entry.rsvps);
		}
		return rsvps.filter((e) => e instanceof Object);
	}

	function ensureState(state, group, defaultValue) {
		state.data.data[group] =
			state.data.data[group] instanceof Array
				? state.data.data[group]
				: defaultValue || [];
	}

	// CalendarEntry types
	const TYPES = {
		CALENDARS_SAVE: "STORE_SAVE",
		CALENDARS_DELETE: "STORE_DELETE",
		CALENDARS_GET: "STORE_GET",
		CALENDAR_ENTRY_DELETE: "CALENDAR_ENTRY_DELETE",
		CALENDAR_ENTRY_SAVE: "CALENDAR_ENTRY_SAVE",
		CALENDAR_ENTRY_RSVP_DELETE: "CALENDAR_ENTRY_RSVP_DELETE",
		CALENDAR_ENTRY_RSVP_SAVE: "CALENDAR_ENTRY_RSVP_SAVE",
	};

	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("calendar");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions({}, "Calendar");
	const mutations = helper.mutations(
		{
			[TYPES.CALENDARS_GET](state, data) {
				const calendar = data.result;
				state.data.data[calendar.calendarFor] = state.data.data[
					calendar.calendarFor
				]
					? state.data.data[calendar.calendarFor]
					: [];
				helper.utils.addToStateData(
					state.data.data[calendar.calendarFor],
					calendar,
					true
				);
			},
			[TYPES.CALENDARS_SAVE](state, data) {
				const calendar = data.result;
				ensureState(state, calendar.calendarFor, []);
				helper.utils.addToStateData(
					state.data.data[calendar.calendarFor],
					calendar,
					true
				);
			},
			[TYPES.CALENDARS_DELETE](state, data) {
				const calendar = data.params;
				ensureState(state, calendar.calendarFor, []);
				helper.utils.removeFromStateData(
					state.data.data[calendar.calendarFor],
					calendar,
					true
				);
			},
			[TYPES.CALENDAR_ENTRY_DELETE](state, data) {
				const entry = data.params;
				const calendar =
					entry.calendar ||
					Object.values(state.data.data)
						.flat()
						.find((c) => c.id === entry.calendar_id);
				ensureState(state, calendar.calendarFor, [calendar]);
				const currentCalendar = state.data.data[calendar.calendarFor].find(
					(current) => current.id === calendar.id
				);
				if (currentCalendar instanceof Object) {
					const allEntries = getAllEntries(entry);
					const existingEntries = currentCalendar.entries.filter(
						(e) => e.id === entry.id || e.sequence_owner_id === entry.id
					);
					helper.utils.removeFromStateData(
						currentCalendar.entries,
						allEntries.concat(existingEntries),
						true
					);
				}
			},
			[TYPES.CALENDAR_ENTRY_SAVE](state, data) {
				const entry = data.result || data.data || data;
				const calendar = entry.calendar ||
					Object.values(state.data.data)
						.flat()
						.find((c) => c.id === entry.calendar_id) || {
						calendarFor: "Programs",
					};
				ensureState(state, calendar.calendarFor, [calendar]);
				const allEntries = getAllEntries(entry);
				const existingCalendar = (
					state.data.data[calendar.calendarFor] || []
				).find((c) => {
					return c.id === calendar.id;
				});
				if (existingCalendar instanceof Object) {
					existingCalendar.entries = existingCalendar.entries || [];
					const existingEntries = existingCalendar.entries.filter(
						(e) => e.id === entry.id || e.sequence_owner_id === entry.id
					);
					// Remove existing entries before adding new ones
					helper.utils.removeFromStateData(
						existingCalendar.entries,
						existingEntries,
						true
					);
					helper.utils.addToStateData(
						existingCalendar.entries,
						allEntries,
						true
					);
				}
			},
			/**
			 * Delete a calendar entry RSVP from the state
			 *
			 * @param {*} state
			 * @param {*} data
			 */
			[TYPES.CALENDAR_ENTRY_RSVP_DELETE](state, data) {
				const rsvp = data.params;
				const calendar =
					(rsvp.calendar_entry || {}).calendar ||
					Object.values(state.data.data)
						.flat()
						.find((c) => c.id === rsvp.calendar_entry.calendar_id);
				ensureState(state, calendar.calendarFor, [calendar]);
				const existingCalendar = state.data.data[calendar.calendarFor].find(
					(current) => current.id === calendar.id
				);

				if (existingCalendar instanceof Object) {
					const currentEntryIndex = (existingCalendar.entries || []).findIndex(
						(entry) => parseInt(rsvp.calendar_entry_id) === parseInt(entry.id)
					);
					if (currentEntryIndex > -1) {
						existingCalendar.entries[currentEntryIndex].rsvps =
							existingCalendar.entries[currentEntryIndex].rsvps || [];
						const allRsvps = getAllRsvps(
							existingCalendar.entries[currentEntryIndex]
						);
						helper.utils.removeFromStateData(
							existingCalendar.entries[currentEntryIndex].rsvps,
							allRsvps,
							true
						);
					}
				}
			},
			[TYPES.CALENDAR_ENTRY_RSVP_SAVE](state, data) {
				const rsvp = data.result || data;
				const calendar =
					rsvp.calendar_entry.calendar ||
					Object.values(state.data.data)
						.flat()
						.find((c) => c.id === rsvp.calendar_entry.calendar_id);
				ensureState(state, calendar.calendarFor, [calendar]);
				const existingCalendar = state.data.data[calendar.calendarFor].find(
					(current) => current.id === calendar.id
				);

				if (existingCalendar instanceof Object) {
					const currentEntryIndex = (existingCalendar.entries || []).findIndex(
						(entry) => parseInt(rsvp.calendar_entry_id) === parseInt(entry.id)
					);
					if (currentEntryIndex > -1) {
						existingCalendar.entries[currentEntryIndex].rsvps =
							existingCalendar.entries[currentEntryIndex].rsvps || [];
						helper.utils.addToStateData(
							existingCalendar.entries[currentEntryIndex].rsvps,
							[rsvp],
							true
						);
					}
				}
			},
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
