<template>
  <VRow
    row
    wrap
    :class="{
      'is-loading': activity.isLoading,
      'is-loading-full-width': mode === 'dialog'
    }"
  >
    <Confirm ref="confirmSave" />
    <VDialog
      v-model="dialogs.complete"
      persistent
      width="300"
      transition="zoom"
    >
      <VCard color="success py-4">
        <VCardText class="text-sm-center">
          <h1 class="display-1 white--text mb-3">
            Success!
          </h1>
          <VIcon
            style="font-size: 5rem"
            color="white"
          >
            check_circle
          </VIcon>
          <p class="subtitle white--text mt-3 px-4">
            Your information has been submitted.
            <template v-if="teamSetting('pipeline.is_application_editable_until_deadline', true)">
              Feel free to edit up until
              <br>
              <strong
                large
                v-if="deadline"
              >{{ deadline }}</strong>
            </template>
          </p>
        </VCardText>
        <VCardActions class="d-flex justify-center">
          <VBtn
            large
            color="white"
            @click="onCloseApplication"
          >
            Got it
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <template v-if="mode === 'standalone'">
      <VCol cols="12">
        <Alerts
          v-if="hasMessagesOrErrors"
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol cols="12">
        <DynamicInputsSubmitStepper
          v-model="form.metadata"
          item-title-class="subheading bordered-y py-4"
          container-class="px-0 elevation-0"
          :current-section="section"
          :readonly="readonly"
          :validator="stageValidator"
          :activity="activity"
          :metadata-sections="stage.metadata_sections || {}"
          @input="onUpdateStageMetadata"
          @errors="onUpdateErrors"
          @update:section="onUpdateSection"
          @validated="$v.form.$touch()"
        >
          <span
            slot="title"
            class="title"
          >{{ title || stageTitle }}</span>
        </DynamicInputsSubmitStepper>
      </VCol>
    </template>
    <Dialog
      v-else
      fullscreen
      persistent
      enable-footer-slots
      :is-dialog-open="isDialogOpen"
      :title="stageTitle"
      :max-width="maxWidth"
      @toggle:dialog="$emit('toggle:dialog')"
    >
      <VSkeletonLoader
        grid-list-lg
        fluid
        type="card-heading, card"
        :loading="isLoading"
      >
        <Alerts
          v-if="hasMessagesOrErrors && isFormReady"
          :messages="messages"
          :errors="errors"
        />
        <DynamicInputsSubmitStepper
          v-model="form.metadata"
          mode="dialog"
          item-title-class="subheading bordered-y py-4"
          :container-class="'px-0 elevation-0'"
          :current-section="section"
          :validator="stageValidator"
          :activity="activity"
          :readonly="readonly"
          :metadata-sections="stage.metadata_sections || {}"
          @input="onUpdateStageMetadata"
          @errors="onUpdateErrors"
          @update:section="onUpdateSection"
          @validated="$v.form.$touch()"
        >
          <span class="title">{{ title || this.stageTitle }}</span>
        </DynamicInputsSubmitStepper>
      </VSkeletonLoader>
      <template #footer-left>
        <VRow
          row
          align-end
          my-0
        >
          <VCol
            shrink
            pb-0
          >
            <VBtn
              depressed
              style="text-transform: none"
              :disabled="activity.isFormLoading || section <= offset"
              :loading="activity.isFormLoading"
              @click="prev"
            >
              Previous
            </VBtn>
          </VCol>
          <VCol
            v-if="$vuetify.breakpoint.mdAndUp"
            grow
            class="text-truncate text-no-wrap"
          >
            <span>{{ sectionName }}</span>
          </VCol>
        </VRow>
      </template>
      <template #footer-right>
        <div class="d-inline-flex">
          <VBtn
            v-show="section < inputSections(stageMetadata).length"
            class="wethrive"
            style="text-transform: none"
            :color="'primary'"
            :disabled="activity.isFormLoading"
            :loading="activity.isFormLoading"
            @click="next"
          >
            Next
          </VBtn>
          <VBtn
            v-if="!readonly"
            depressed
            color="info"
            class="ml-2"
            :disabled="activity.isFormLoading || readonly"
            :loading="activity.isFormLoading"
            @click="onSave"
          >
            Save
          </VBtn>
          <VBtn
            v-if="!readonly"
            v-show="section >= inputSections(stageMetadata).length"
            :class="{
              'ml-2 slight-radius': true,
              'wethrive-alt': isFormReady && !activity.isFormLoading
            }"
            :disabled="activity.isFormLoading || !isFormReady || readonly"
            :loading="activity.isFormLoading"
            @click="onSubmit"
          >
            {{ featureName('application_submit_button').titleize() }}
          </VBtn>
        </div>
      </template>
    </Dialog>
  </VRow>
</template>

<script>
import DynamicInputsSubmitStepper from "@/components/Elements/Forms/DynamicInputsSubmitStepper";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "@/mixins/SavesSubmittedData.vue";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  between,
} from "vuelidate/lib/validators";
import HasDynamicInputSectionsMixin from "@/mixins/HasDynamicInputSections";
export default {
  name: "PipelineStageSubmit",
  components: {
    DynamicInputsSubmitStepper,
  },
  mixins: [
    FormMixin,
    MetadataMixin,
    SavesSubmittedData,
    HasDynamicInputSectionsMixin,
  ],
  props: {
    title: {
      type: String,
      default: null,
    },
    deadline: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      default: "1024px",
    },
    stageIndex: {
      type: Number,
      default: 0,
    },
    stage: {
      type: Object,
      default: () => {},
    },
    pipeline: {
      type: Object,
      default: () => null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isInputsRequired: {
      type: Boolean,
      default: false,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogs: {
        form: false,
        complete: false,
      },
      hasFiles: true,
      canGetModel: false,
      options: {
        supplements: [],
        primary_contents: [],
      },
      modelType: "Stage",
      modelMergeStrategy: "replace",
      saveOnly: [
        "id",
        "completed_by",
        "completed_on",
        "internal_status",
        "is_complete",
        "is_submitted",
        "metadata",
        "pipeline_id",
        "pipeline_stage_id",
        "pipeline_submitted_id",
        "user_id",
        "status",
        "title",
      ],
      fullPipeline: null,
      defaultForm: {
        user_id: null,
        metadata: [],
        pipeline_stage_id: null,
      },
      validationMessages: {
        errors: {
          metadata: "Please respond to all required questions",
        },
      },
    };
  },
  mounted() {
    this.section = 0;
    this.form = this.existing;
    if (!this.form || (this.form && !this.form.id)) {
      this.canGetModel = true;
      this.loadFormModel(this.id).then(() => {
        this.$nextTick(() => {
          this.section = 1;
          this.$emit("loaded", this.id);
        });
      });
    } else {
      this.section = 1;
    }
    // this.doGetPipeline(this.existing.pipeline_id).then(result => {
    //   this.fullPipeline = result;
    //   if (!this.id) {
    //     this.form.stages = result.stages.map(stage => {
    //       return {
    //         pipeline_stage_id: stage.id,
    //         metadata: stage.metadata.map(metadata => {
    //           return this.prepareMetadata(metadata);
    //         })
    //       };
    //     });
    //   }
    // });
    // this.$nextTick(() => {
    //   this.validateModelAndSetErrors();
    // });
  },
  watch: {
    isDialogOpen: function (value) {
      if (!value) {
        this.section = 0;
      }
    },
  },
  computed: {
    sectionName() {
      const sectionName = this.inputSections(this.stageMetadata)[
        this.section > 0 ? this.section - 1 : 0
      ];
      return ["undefined", "null"].indexOf(sectionName) === -1
        ? sectionName
        : "";
    },
    stageTitle() {
      return `${this.stage.title || ""} ${this.sectionName ? ": " + this.sectionName : ""}`;
    },
    stageMetadata() {
      return this.form instanceof Object && this.form.metadata instanceof Array
        ? this.form.metadata.sortBy("priority") || []
        : [];
    },
    stageValidator() {
      return this.$v.form.metadata || {};
    },
    isStageExpired() {
      return (
        this.stage.is_expired == true ||
        this.stage.is_complete == true ||
        (this.pipeline || this.stage.pipeline).is_expired == true ||
        (this.pipeline || this.stage.pipeline).is_complete == true
      );
    },
  },
  validations() {
    const validations = {
      form: {
        required,
        user_id: {
          required,
        },
        pipeline_stage_id: {
          required,
        },
      },
    };

    /**
     * TODO: Fix issue with required always being true
     */

    if ((this.form.metadata || []).length && this.isInputsRequired) {
      validations.form.metadata = {
        $each: {
          value: {
            validUrl: (input) => {
              if (input instanceof Object && typeof input.type === "string") {
                switch ((input.type || "").toLowerCase()) {
                  case "url":
                    const regexp =
                      /^((https|http):\/\/)?([\w\d\-\_\:\@]+)\.(.*)/;
                    return !required(input.value) || regexp.test(input.value);
                    break;
                }
              }
              return true;
            },
            required: requiredIf((input) => {
              // Checked whether metadata item is required or not
              return input.is_required || this.metadataHasRequiredUrl(input);
            })
            // max: (value, model) => {
            //   return parseInt((model.options || {}).maxLength) > 0 ? maxLength(model.options.maxLength)(value) : false;
            // }
          },
          url: {
            requiredIfUrl: requiredIf(this.metadataHasRequiredUrl),
          },
        },
      };
    }
    return validations;
  },
  created() {
    this.$watch("existing", (value) => {
      this.form = value;
    });
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineSubmitted/getFormConfig",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      doGetPipelineSubmitted: "pipelineSubmitted/getOne",
      doSavePipelineStageSubmitted: "pipelineStageSubmitted/save",
      doGetPipelineStageSubmitted: "pipelineStageSubmitted/getOne",
      doGetPipeline: "pipeline/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig({
        id: this.stage.pipeline_id,
      });
    },
    onSave() {
      this.skipFormValidation = true;
      this.form.pipeline_id =
        (this.pipeline || {}).id ||
        (this.stage || {}).pipeline_id ||
        (this.form.stage || {}).pipeline_id;
      return this.saveFormModel(this.doSavePipelineStageSubmitted, null, null, {
        dontSave: ["pipeline", "user", "current_stage", "submitted_by"],
      })
        .then((result) => {
          this.$emit("input", this.form);
        })
        .finally(() => {
          this.skipFormValidation = false;
        });
    },
    onBackgroundSave() {
      if (!this.isStageExpired && !this.readonly) {
        this.form.pipeline_id =
          (this.pipeline || {}).id ||
          (this.stage || {}).pipeline_id ||
          (this.form.stage || {}).pipeline_id;
        return this.backgroundSaveFormModel(
          this.doSavePipelineStageSubmitted,
          null,
          null,
          {
            dontSave: ["pipeline", "user", "current_stage", "submitted_by"],
          }
        );
      }
    },
    onSubmit() {
      this.form.is_submitted = true;
      this.form.pipeline_id =
        (this.pipeline || {}).id ||
        (this.stage || {}).pipeline_id ||
        (this.form.stage || {}).pipeline_id;
      this.skipFormValidation = false;
      return this.saveFormModel(this.doSavePipelineStageSubmitted, null, null, {
        dontSave: ["pipeline", "user", "current_stage", "submitted_by"],
      }, false).then((result) => {
        if (result instanceof Object && result.id) {
          this.dialogs.complete = true;
          this.$emit("input", this.form);
        }
      });
    },
    onGet(id) {
      const params =
        id instanceof Object
          ? id
          : {
              id,
              pipeline_id: this.pipeline.id,
              pipeline_stage_id: this.stage.id,
              pipeline_id: this.stage.pipeline_id,
            };
      return this.doGetPipelineStageSubmitted(params);
    },
    onUpdateErrors(value) {
      console.log("Updating errors", value);
      this.errors = value;
    },
    onUpdateStageMetadata(data) {
      if (data) {
        this.form.metadata = data;
      }
      return true;
    },
    onUpdateSingleStageMetadata(key, value) {
      this.onUpdateMetadata(
        this.stageIndex,
        value,
        `stages[${this.stageIndex}].metadata`
      );
    },
    onCloseApplication() {
      this.dialogs.complete = false;
      this.$emit("toggle:dialog");
      this.$emit("submitted");
    },
    next() {
      if (this.section == 0) {
        this.section = 1;
      }
      this.section++;
      this.$log.debug("Advancing to next section", this.section);
      if (this.$v) {
        this.$v.$touch();
      }
      this.onBackgroundSave();
      this.scrollToElement();
    },
    prev() {
      if (this.section > this.offset) {
        this.section--;
        this.$log.debug("Advancing to previous section", this.section);
        if (this.$v) {
          this.$v.$touch();
        }
        this.onBackgroundSave();
        this.scrollToElement();
      }
    },
    onUpdateSection(value) {
      this.section = value;
      this.onBackgroundSave();
    },
    // Scroll top when stepper step changed i.e. Next, Previous
    scrollToElement() {
      const stepper = document.querySelector(".custom-stepper");
      const container = document.querySelector(".custom-dialog .v-card__text");
      container.scrollTop = stepper.offsetTop - 200;
    },
  },
};
</script>

