<template>
  <VRow
    column
    class="choice-card"
  >
    <VCol>
      <EditorBubble
        v-model="option.title"
        placeholder="Enter card title"
        :class="{
          'title-editor': true,
          'editor-error': $vError(input.realIndex, 'title'),
        }"
        style="margin-bottom: 1.6rem"
        @blur="$emit('validated')"
      />
      <Swatches
        v-model="option.background_color"
        show-fallback
        swatches="text-advanced"
        style="width: 100%"
        :trigger-style="{ width: '100%' }"
      >
        <TextField
          v-model="option.background_color"
          solo
          clearable
          slot="trigger"
          placeholder="Card background color"
        />
      </Swatches>
      <EmojiPicker
        v-if="input.type != 'Stack'"
        text-field
        slot="trigger"
        :value="option.emoji"
        @change="(emoji) => (option.emoji = emoji)"
      />
      <VSelect
        v-model="option.alignment"
        filled
        small
        dense
        outlined
        placeholder="Select content alignment"
        class="pb-0"
        :items="alignmentOptions"
      />
      <Editor
        outlined
        persistent-hint
        auto-grow
        class="mb-0"
        placeholder="Enter card description here..."
        :value="option.description"
        :type="input.type || 'text'"
        @input="(value) => (option.description = value)"
      >
        <VIcon slot="append">
          {{ input.icon }}
        </VIcon>
      </Editor>
      <FileUpload
        slot="append"
        color="primary"
        accept="image/*"
        background-color="primary"
        label="Choose background image..."
        :ref="`fileUpload${input.realIndex}`"
        :readonly="false"
        @formData="(file) => (option.image = file)"
      />
      <FilesList
        :files="[option.image]"
        @removeFile="onRemoveFile(`fileUpload${input.realIndex}`)"
      />
      <VTooltip
        v-if="enableDelete"
        left
      >
        <template #activator="{ on }">
          <VBtn
            v-on="on"
            icon
            slot="activator"
            style="position: absolute; right: -12px; top: -12px;"
            @click="$emit('removeCard')"
          >
            <VIcon color="error">
              mdi-close-circle
            </VIcon>
          </VBtn>
        </template>
        <span>Remove this card</span>
      </VTooltip>
    </VCol>
  </VRow>
</template>

<script>
import Swatches from "vue-swatches";

export default {
  name: "ChoiceList",
  props: {
    input: {
      type: Object,
      default: null,
    },
    option: {
      type: Object,
      default: null,
    },
    optionKey: {
      type: Number,
      default: null,
    },
    nestedOptionKey: {
      type: Number,
      default: null,
    },
    enableDelete: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: [Object, Array],
      default: () => {},
    },
  },
  components: {
    Swatches,
  },
  mounted() {
    if (this.mode === "standalone") {
      this.$emit("validated");
    }
  },
  computed: {
    $v() {
      if (this.validator instanceof Array) {
        return this.validator;
      } else if (
        this.validator instanceof Object &&
        this.validator.$each instanceof Object &&
        this.validator.$each.$iter instanceof Object
      ) {
        return Object.values(this.validator.$each.$iter);
      } else {
        return [];
      }
    },
    alignmentOptions() {
      return [
        {
          text: "Left Align",
          value: "left",
        },
        {
          text: "Center Align",
          value: "center",
        },
        {
          text: "Right Align",
          value: "right",
        },
      ];
    },
  },
  methods: {
    $vError(index, field) {
      this.$log.debug(
        "Card validation: ",
        this.optionKey,
        this.nestedOptionKey,
        this.$v[index],
        this.input
      );
      if (this.nestedOptionKey === null) {
        return this.$v.length && this.$v[index] instanceof Object
          ? this.$v[index].options.options.$each[this.optionKey][field].$error
          : false;
      } else {
        return this.$v.length && this.$v[index] instanceof Object
          ? this.$v[index].options.options.$each[this.optionKey].options.$each[
              this.nestedOptionKey
            ][field].$error
          : false;
      }
    },
    onRemoveFile(resetRef) {
      this.option.image = "";
      if (resetRef && this.$refs.hasOwnProperty(resetRef)) {
        let ref = this.$refs[resetRef];
        ref = ref instanceof Array ? ref[0] : ref;
        ref.onReset();
      }
    },
  },
};
</script>

<style lang="scss">
.choice-card {
  .title-editor .editor__content > div {
    height: 40px !important;
    border-radius: 10px;
  }
  .editor-error {
    .editor__content > div {
      border: 1px solid #ea4a4a !important;
    }
  }
}
</style>
