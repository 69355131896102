<template>
  <VRow
    :align-end="isFirst"
    :align-center="!isFirst"
    :justify-start="isFirst"
    :justify-center="!isFirst"
    row
    wrap
    fill-height
  >
    <VCol
      :class="{
        'pl-12 pb-12': true,
        'text-center': $vuetify.breakpoint.smAndDown,
      }"
    >
      <h1
        :class="{
          'assessment-title': true,
          'text-center': !isFirst
        }"
        :style="{
          'text-transform': isFirst ? 'uppercase' : 'none',
          'line-height': '30px'
        }"
      >
        {{ card.name }}
      </h1>
      <strong v-if="isFirst">{{ assessment.all_cards_count || assessment.cards_count }} Cards</strong>
      <br><br>
      <VBtn
        v-if="isFirst"
        icon
        class="ml-0"
        @click="$emit('next')"
      >
        <VImg :src="nextImg" />
      </VBtn>
    </VCol>
  </VRow>
</template>

<script>
import nextImg from "../../../images/lessons/next-icon@2x.png";
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardTitle",
  mixins: [PresentationCardMixin],
  data() {
    return {
      nextImg
    };
  }
};
</script>

