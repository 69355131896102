import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Billing API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getPlans (params) {
		this.log().info(`[Services: Billing]: Get All plans`, params);
		return this.api()
			.get(this.getUrl("plans", "plans", params), { params: params })
			.catch(error => {
				this.log().error(`[Services: Billing: Get All plans]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	getInvoices (params) {
		this.log().info(`[Services: Billing]: Get All invoices`, params);
		return this.api()
			.get(this.getUrl("invoices", "invoices", params), { params: params })
			.catch(error => {
				this.log().error(`[Services: Billing: Get All invoices]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	getUserDiscounts (params) {
		this.log().info(`[Services: Billing]: Get User Discounts`, params);
		return this.api()
			.get(this.getUrl("coupon", "coupon", params), { params: params })
			.catch(error => {
				this.log().error(`[Services: Billing: Get User Discounts]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	getTeamDiscounts () {
		this.log().info(`[Services: Billing]: Get Team Discounts`);
		return this.api()
			.get(this.getUrl("coupon", "coupon", {}))
			.catch(error => {
				this.log().error(`[Services: Billing: Get Team Discounts]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	redeemCoupon (params) {
		this.log().info(`[Services: Billing]: Redeem Coupon`);
		return this.api()
			.post(this.getUrl("coupon", "payment-method/coupon", {}), params)
			.catch(error => {
				this.log().error(`[Services: Billing: Redeem Coupon]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	createSubscription (params) {
		this.log().info(`[Services: Billing]: Create Subscription`);
		return this.api()
			.post(this.getUrl("subscribe", "subscription", {}), params)
			.catch(error => {
				this.log().error(`[Services: Billing: Create Subscription]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	updatePlan (params) {
		this.log().info(`[Services: Billing]: Update Subscription`);
		return this.api()
			.put(this.getUrl("subscribe", "subscription", {}), params)
			.catch(error => {
				this.log().error(`[Services: Billing: Update Subscription]:`, error);
				throw this.utils.resolveError(error);
			});
	}
	updatePaymentMethod (params) {
		this.log().info("[Services: Billing]: Update Payment Method", params);
		return this.api()
			.put(this.getUrl("payment-method", "payment-method"), params)
			.catch(error => {
				this.log().error(
					"[Services: Billing: Update Payment Method]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	cancelSubscription (params) {
		this.log().info("[Services: Billing]: Cancel Subscription", params);
		return this.api()
			.delete(this.getUrl("subscription", "subscription"), params)
			.catch(error => {
				this.log().error(
					"[Services: Billing: Cancel Subscription]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	getBasePath (action, app, params) {
		const realApp = app || this.app();
		const spark = realApp.$spark || {};
		const team = realApp.$team || {};
		let basePath = `api/${spark.teamsPrefix}`;
		switch (action) {
			case "delete":
			case "getOne":
				break;

			case "plans":
			case "geocode":
			case "tax-rate":
				basePath = `api/billing`;
				break;

			case "invoices":
			case "coupon":
			case "payment-method":
			case "subscribe":
			case "subscription":
				basePath =
					spark.billingUser || (params && params.billingUser)
						? `/api/billing/`
						: `/api/${spark.teamsPrefix}/${team.id}/billing`;
				break;

			default:
				basePath = `${basePath}/${team.id}`;
				break;
		}
		return basePath;
	}
}

const api = new Api({
	type: "Billing",
	basePath: "api",
	path: {
		default: null
	}
});

export default api;
