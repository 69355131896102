var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":""}},[_c('VCol',{attrs:{"cols":"12"}},[(!_vm.hidePreviewInfo)?_c('VAlert',{staticClass:"ma-6",attrs:{"type":"info","value":true}},[_vm._v(" This is a preview version of Lessons. ")]):_vm._e(),(_vm.lessons.overdue.data.length > 0)?_c('VCard',{class:{
        ..._vm.customCardClass,
        'no-bottom-border': _vm.lessons.overdue.data.length && (_vm.lessons.started_not_complete.data.length || _vm.lessons.all.data.length),
      },attrs:{"flat":""}},[_c('VCardText',[_c('VCol',{staticClass:"pl-0"},[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" Overdue ")])]),_c('VSheet',{staticClass:"group-slider",attrs:{"max-width":"1270px"}},[_c('VSlideGroup',{staticClass:"py-4",attrs:{"show-arrows":""}},_vm._l((_vm.lessons.overdue.data),function(item,key){return _c('VSlideItem',{key:item.id},[_c('LessonHomeCard',{style:({
                  'width': '275px',
                  'cursor': _vm.checkShowCount(key, _vm.lessons.overdue) ? 'pointer' : 'auto',
                }),attrs:{"overdue":"","preview-only":"","margin-right":"24px","item":item,"colors":_vm.colors,"due-on":_vm.getDue(item),"due-day":_vm.getDueDay(item),"is-back-to-default":_vm.isBackToDefault,"show-count":_vm.checkShowCount(key, _vm.lessons.overdue),"count":(_vm.lessons.overdue.total - _vm.limitForDashboard) + 1},on:{"showMore":function($event){return _vm.$emit('toggleTab')}}})],1)}),1)],1)],1)],1):_vm._e(),(_vm.lessons.overdue.data.length && (_vm.lessons.started_not_complete.data.length || _vm.lessons.all.data.length))?_c('VDivider'):_vm._e(),(_vm.lessons.started_not_complete.data.length > 0)?_c('VCard',{class:{
        ..._vm.customCardClass,
        'no-top-border': _vm.lessons.overdue.data.length && _vm.lessons.started_not_complete.data.length,
        'no-bottom-border': _vm.lessons.started_not_complete.data.length && _vm.lessons.all.data.length
      },attrs:{"flat":""}},[_c('VCardText',[_c('VCol',{staticClass:"pl-0"},[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" Started but not complete ")])]),_c('VSheet',{staticClass:"group-slider",attrs:{"max-width":"1270px"}},[_c('VSlideGroup',{staticClass:"py-4",attrs:{"show-arrows":""}},_vm._l((_vm.lessons.started_not_complete.data),function(item,key){return _c('VSlideItem',{key:item.id},[_c('LessonHomeCard',{style:({
                  'width': '275px',
                  'cursor': _vm.checkShowCount(key, _vm.lessons.started_not_complete) ? 'pointer' : 'auto',
                }),attrs:{"incompleted":"","preview-only":"","margin-right":"24px","item":item,"colors":_vm.colors,"due-on":_vm.getDue(item),"due-day":_vm.getDueDay(item),"is-back-to-default":_vm.isBackToDefault,"show-count":_vm.checkShowCount(key, _vm.lessons.started_not_complete),"count":(_vm.lessons.started_not_complete.total - _vm.limitForDashboard) + 1},on:{"showMore":function($event){return _vm.$emit('toggleTab')}}})],1)}),1)],1)],1)],1):_vm._e(),(_vm.lessons.started_not_complete.data.length && _vm.lessons.all.data.length)?_c('VDivider'):_vm._e(),(_vm.lessons.all.data.length > 0)?_c('VCard',{class:{
        ..._vm.customCardClass,
        'no-top-border': (_vm.lessons.overdue.data.length || _vm.lessons.started_not_complete.data.length) && _vm.lessons.all.data.length,
      },attrs:{"flat":""}},[_c('VCardText',[_c('VCol',{staticClass:"pl-0"},[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" All Lessons ")])]),_c('VSheet',{staticClass:"group-slider",attrs:{"max-width":"1270px"}},[_c('VSlideGroup',{staticClass:"py-4",attrs:{"show-arrows":""}},_vm._l((_vm.lessons.all.data),function(item,key){return _c('VSlideItem',{key:item.id},[_c('LessonHomeCard',{style:({
                  'width': '275px',
                  'cursor': _vm.checkShowCount(key, _vm.lessons.all) ? 'pointer' : 'auto',
                }),attrs:{"all":"","preview-only":"","margin-right":"24px","item":item,"colors":_vm.colors,"due-on":_vm.getDue(item),"due-day":_vm.getDueDay(item),"is-back-to-default":_vm.isBackToDefault,"show-count":_vm.checkShowCount(key, _vm.lessons.all),"count":(_vm.lessons.all.total - _vm.limitForDashboard) + 1},on:{"showMore":function($event){return _vm.$emit('toggleTab')}}})],1)}),1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }