<template>
  <VCard v-if="(option.options instanceof Object)">
    <VCardText>
      <Members
        v-if="option.options.type === 'widget' && option.options.options === 'members'"
        :mode="option.options.multiple ? 'multiple' : 'single'"
        :is-group="option.options.multiple === true"
        :autosave="true"
        @saveMembers="value => emitInput(value)"
        :existing="realValue"
      />
      <Threads
        v-else-if="option.options.type === 'widget' && option.options.options === 'chatThreads'"
        :mode="option.options.multiple ? 'multiple' : 'single'"
        @selectedThreads="value => emitInput(value)"
        :existing="realValue"
        thread-mode="select-thread"
      />
      <Threads
        v-else-if="option.options.type === 'widget' && option.options.options === 'groupThreads'"
        mode="group"
        @selectedThreads="value => emitInput(value)"
        :existing="realValue"
        thread-mode="select-thread"
      />
      <Autocomplete
        v-else-if="optionValues && optionValues.length"
        :value="realValue"
        :multiple="option.options.multiple"
        :items="optionValues"
        :item-value="option.options.value || 'id'"
        :item-text="option.options.text || 'title'"
        :label="label"
        :placeholder="placeholder"
        @input="value => emitInput(value)"
      />
      <template v-if="option.options.hasMessageDestinations">
        <MessageDestination
          @input="setDestination"
          :value="destination"
        />
      </template>
      <template v-if="option.options.hasMessage">
        <Message
          v-model="customMessage"
          :value="customMessage"
          :show-message-input="showMessageInput"
          :variables="variables"
          :placeholder="trigger.defaultMessage"
          @input="setMessage"
        />
      </template>
    </VCardText>
  </VCard>
</template>
<script>
/**
 * TODO: Addvariable information
 */
import BaseOptions from "./BaseOptions";
import Threads from "@/components/Chat/Threads";
import Members from "@/components/Elements/Forms/MembersForm";
import MessageDestination from "@/components/Organization/AutomationWorkflows/Widgets/MessageDestination";
import Message from "@/components/Organization/AutomationWorkflows/Widgets/Message";
export default {
  extends: BaseOptions,
  components: {
    Threads,
    Members,
    MessageDestination,
    Message
  }
};
</script>