import { Api as BaseApi } from "./deliverable";

/**
 * Pipeline API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Pipeline",
	path: "pipelines"
});

export default api;
