import api from "../../services/assessment";
import base from "./deliverable";
export default function (helper, moduleApi) {
	const realApi = moduleApi || api;
	const store = {
		...base(helper, realApi),
	};
	/**
	 * Generated store module
	 */
	helper.setApi(realApi);
	store.types = {
		...store.types,
		...helper.types("assessment"),
	};
	// Need to make sure we're using the right API for the actions
	store.actions = {
		...store.actions,
		...helper.actions({}, "Assessment"),
	};

	return store;
}
