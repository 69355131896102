<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      cols="12"
      justify-content-center
      align-items-center
    >
      <TextField
        v-model="form.name"
        filled
        large
        placeholder="Enter the question"
        class="textarea-field"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <TextField
        v-model="form.value"
        filled
        large
        placeholder="Answer will go here"
        class="textarea-field bg-textarea"
        :disabled="true"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";

export default {
  name: "SaveCardText",
  mixins: [SaveCardMixin]
};
</script>
