<template>
  <VCard
    class="chat-threads overflow-hidden layout column mx-0"
    :style="{
      backgroundColor,
      borderRadius: '0px !important'
    }"
  >
    <VCardTitle
      style="flex: 0 0 auto; border-bottom: none"
      class="pb-0"
    >
      <VRow column>
        <VCol
          :style="{
            paddingTop: $vuetify.breakpoint.mdAndUp ? '50px' : ''
          }"
        >
          <h2 class="headline">
            Welcome Back,
          </h2>
          <h1 class="display-1 font-weight-bold	">
            {{ $user.first_name }}!
          </h1>
        </VCol>
        <VCol grow>
          <TextField
            v-model="s"
            text
            solo-inverted
            height="22"
            class="search-individuals v-input-sm my-2 no-details"
            label="Your Messages"
            @keyup.enter="onSearch"
          >
            <img
              slot="append"
              style="cursor: pointer; z-index: 1"
              width="13"
              height="13"
              :src="searchIcon"
              @click="onSearch"
            >
            <VBtn
              icon
              rounded
              slot="append-outer"
              style="margin-top: -6px; background-color: #fff !important"
              @click="onHandleCreate"
            >
              <img
                width="20"
                height="20"
                :src="editIcon"
              >
            </VBtn>
          </TextField>
          <VBtn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            style="position: absolute; top: 16px; right: 16px"
            @click="$emit('toggleDrawer')"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </VCardTitle>
    <VSkeletonLoader
      type="list-item-avatar-three-line"
      class="chat-threads-list overflow-hidden"
      :style="{
        backgroundColor,
        borderRadius: '0px'
      }"
      :loading="isFirstLoad"
    >
      <VCardText
        class="px-0 py-0"
        :style="{
          overflowY: 'hidden',
          borderRadius: '0px'
        }"
      >
        <VList
          v-if="hasThreads"
          two-line
          id="infinite-threads"
          ref="threads"
          :style="{
            overflowY: 'auto',
            height: $vuetify.breakpoint.mdAndUp ? 'calc(100vh - 285px)' : 'calc(100vh - 138px)',
            borderRadius: '0px'
          }"
        >
          <Thread
            v-for="(item, index) in sortedData"
            :id="`thread-${item.thread_id}`"
            :key="index"
            :mode="threadMode"
            :thread="item"
            :current-thread="realSelectedThread"
            :is-selected="isThreadSelected(item)"
            @loadMessages="onLoadMessages"
            @selectThread="onHandleThreadSelection"
          />
          <infinite-loading
            v-if="canGetMore"
            force-use-infinite-wrapper="#infinite-threads"
            :distance="25"
            :identifier="new Date()"
            @infinite="onGetAll"
          />
        </VList>
        <VList
          v-else-if="!isLoading"
          two-line
        >
          <VListItem :key="thread.title">
            <VListItemContent>
              <VListItemTitle class="text-center">
                No threads
              </VListItemTitle>
              <VListItemSubtitle>
                <VBtn
                  v-if="$route.name !== 'chat.index'"
                  block
                  color="info"
                  @click="onHandleCreate"
                >
                  Start one here
                </VBtn>
                <p
                  v-else-if="!s"
                  class="text-center mt-6"
                >
                  Start one on the right
                </p>
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCardText>
    </VSkeletonLoader>
  </VCard>
  <!-- .b__messanger-dialogs -->
</template>

<script>
import { mapActions } from "vuex";
import Thread from "./Thread";
import editIcon from "../../images/edit-icon@2x.png";
import searchIcon from "../../images/search-icon@3x.png";

export default {
  components: {
    Thread,
  },
  props: {
    currentThread: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "single",
    },
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    threadMode: {
      type: String,
      default: "load-messages",
    },
    existing: {
      type: Array,
      default: () => [],
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editIcon,
      searchIcon,
      s: "",
      activeFilter: "active",
      filterOrder: "desc",
      thread: {},
      threads: {
        data: [],
      },
      selectedThreads: [],
      page: 1,
      isLoading: false,
      isFirstLoad: true,
      scrollTimeout: null,
      automaticallySelectedThread: null,
    };
  },
  computed: {
    realSelectedThread() {
      return this.currentThread || this.automaticallySelectedThread;
    },
    sortedData() {
      return this.threads.data.sortBy((d) => {
        return d.updated_at instanceof Date
          ? d.updated_at.getTime()
          : new Date(d.updated_at).getTime();
      }, true);
    },
    hasThreads() {
      return (
        this.threads instanceof Object &&
        this.threads.data instanceof Array &&
        this.threads.data.length > 0
      );
    },
    currentThreadId() {
      if (this.realSelectedThread instanceof Object) {
        return this.realSelectedThread.thread_id || false;
      }
      return false;
    },
    canGetMore() {
      return (
        !this.isFirstLoad &&
        this.hasThreads &&
        this.threads.current_page < this.threads.last_page
      );
    },
  },
  watch: {
    currentThreadId: {
      deep: true,
      immediate: true,
      handler: function (id) {
        this.$nextTick(() => {
          this.scrollToSelectedThread(id);
          this.markCurrentThreadRead(id);
        });
      },
    },
    automaticallySelectedThread: function (thread) {
      if (thread) {
        this.$nextTick(() => {
          this.onHandleThreadSelection(this.automaticallySelectedThread);
          this.onLoadMessages(this.automaticallySelectedThread);
        });
      }
    },
    currentThread: function (thread) {
      this.$set(this.currentThread, "new_messages_for_user_count", 0);
      if (thread) {
        this.automaticallySelectedThread = null;
      } else {
        this.automaticallySelectedThread = this.threads.data[0];
      }
    },
  },
  created() {
    this.$log.debug("Threads: Mounted threads component...");
    this.selectedThreads = this.existing.map((thread) =>
      parseInt(thread instanceof Object ? thread.id : thread)
    );
    this.refresh();
  },
  beforeDestroy() {
    this.clearScrollTmeout();
  },
  methods: {
    ...mapActions({
      doGetThreads: "chat/getThreads",
    }),
    refresh() {
      this.$log.debug("Threads: Refreshing threads...");
      this.onGetAll();
    },
    onSearch() {
      if (this.s.length >= 3 || !this.s) {
        this.isFirstLoad = true;
        this.onGetAll();
      }
    },
    clearScrollTimeout() {
      clearTimeout(this.scrollTimeout);
    },
    scrollToSelectedThread(id) {
      if (id) {
        const cb = () => {
          if (this.$refs.threads) {
            this.$log.debug(
              `Threads: Scrolling to #thread-${id}`,
              this.$refs.threads
            );
            this.$refs.threads.$el
              .querySelector(`#thread-${id}`)
              .scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            this.clearScrollTimeout();
            this.$log.debug(`Threads: Scrolled to #thread-${id}`);
          } else {
            this.$log.debug(`Threads: Refs aren't ready for #thread-${id}`);
            setTimeout(cb, 1000);
          }
        };
        this.scrollTimeout = setTimeout(cb, 1000);
      } else {
        this.$log.debug(`Threads: Thread not loaded`);
      }
    },
    markCurrentThreadRead(id) {
      //Set the new messages to 0 for the current thread
      if (id && this.threads.data.length > 0) {
        this.$log.debug(`Threads: Attempting to mark current thread ${id}`);
        const index = this.threads.data.findIndex((t) => t.thread_id == id);
        if (index > -1) {
          this.$log.debug(`Threads: Marking current thread ${id}`);
          const thread = this.threads.data[index];
          setTimeout(() => {
            this.threads.data.splice(index, {
              ...thread,
              ...{ new_messages_for_user_count: 0 },
            });
          }, 1000);
        }
      }
    },
    onGetAll($infinite) {
      console.log("HERE", this.isLoading, this.isFirstLoad);
      if (!this.isLoading || this.isFirstLoad) {
        let data = {
          page:
            this.threads && this.threads.current_page < this.threads.last_page
              ? this.threads.current_page + 1
              : 1,
        };
        if (
          !this.threads.total ||
          this.threads.last_page > data.current_page ||
          (this.s && this.s.length >= 3) ||
          !this.s
        ) {
          data.all = true;
          if (this.s.length >= 3) {
            data.s = this.s;
          }
          this.$log.debug(
            "Threads: Getting threads...{page: " + data.page + "}",
            "Mode",
            this.mode
          );
          this.isLoading = true;
          return this.doGetThreads(data).then((response) => {
            this.$log.debug("Threads", response);
            if ($infinite) {
              this.threads.current_page =
                response.current_page || this.threads.current_page;
              this.threads.to = response.to || this.threads.to;
              this.threads.data.push(...(response.data || []));
            } else {
              this.threads = response || this.threads;
              if (!this.currentThread) {
                this.automaticallySelectedThread = this.threads.data[0];
              }
            }
            this.isLoading = false;
            Promise.resolve(response);
            this.onHandleFirstLoad();
            setTimeout(() => {
              if ($infinite) {
                $infinite.loaded();
                if (response.current_page === response.last_page) {
                  $infinite.complete();
                }
              }
              this.scrollToSelectedThread(this.currentThreadId);
            }, 2500);
          });
        } else {
          this.$log.debug(
            "Threads: No more threads to get...{page: " + data.page + "}"
          );
        }
      } else {
        this.$log.warn(
          "Threads: Operation in progress...{page: " + data.page + "}"
        );
      }
    },
    onLoadMessages(thread) {
      this.$log.debug("Threads: Loading messages in ...[" + thread.title + "]");
      this.$emit("loadMessages", thread);
    },
    filterThreads(index) {
      if (this.activeFilter !== index) {
        this.filterOrder = "desc";
      }
      this.activeFilter = index;
      this.filterOrder = this.filterOrder === "asc" ? "desc" : "asc";
      this.doGetThreads({
        sort: this.activeFilter,
        order: this.filterOrder,
        all: true,
      }).then((result) => {
        this.threads = result || this.threads;
      });
    },
    loadThreadFromUrl() {
      const id = this.$route.params.id;
      if (id) {
        const thread = this.findLocalThread(id);
        this.$log.debug(
          "Threads: Loading thread from url",
          id,
          thread,
          `#thread-${id}`
        );
        if (thread instanceof Object) {
          this.$log.debug("Threads: Found thread from url", thread);
          this.onHandleThreadSelection(thread);
          this.onLoadMessages(thread);
        }
      }
    },
    findLocalThread(id) {
      return this.hasThreads
        ? this.threads.data.find((t) => t.id == id || t.thread_id == id)
        : null;
    },
    getLocalThread(search) {
      const threadId = `${this.$user.id}-${search.thread_id}`;
      const originalThreadId = search.thread_id;
      return this.threads && this.threads.data
        ? this.threads.data.find((thread) => {
            return (
              [thread.thread_id, thread.id].indexOf(threadId) !== -1 ||
              [thread.thread_id, thread.id].indexOf(originalThreadId) !== -1
            );
          })
        : null;
    },
    onHandleCreate() {
      this.navigateTo({
        name: "chat.index",
      });
      this.$emit("startChat");
    },
    onHandleFirstLoad() {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        this.$emit("threadsReady");
      }
    },
    onAddThread(thread) {
      this.$log.debug("Threads: Adding new thread", thread);
      // this.threads.data.unshift(thread);
    },
    onUpdateThread(id, properties) {
      const index = this.threads.data.findIndex((item) => item.id === id);
      Object.keys(properties).map((property) => {
        this.threads.data[index][property] = properties[property];
      });
    },
    onHandleThreadSelection(thread) {
      if (this.threadMode === "select-multiple") {
        if (this.selectedThreads.indexOf(thread.id) > -1) {
          this.selectedThreads = this.selectedThreads.filter(
            (id) => id !== thread.id
          );
        } else {
          this.selectedThreads.push(thread.id);
        }
      } else {
        this.selectedThreads = [thread.id];
      }
      this.$emit("selectedThreads", this.selectedThreads);
    },
    isThreadSelected(thread) {
      return this.selectedThreads.indexOf(thread.id) > -1;
    },
    //This uses Laravel Echo
    initChannels() {
      this.$log.debug("Chat Threads: Initing channels: ", this.channels);
      this.channels.forEach((channel) => {
        this.$log.debug(
          "Chat Threads: Pusher subscribing to [private-" + channel + "]"
        );
        let monitor = this.realPusher
          .private(channel)
          .listen(this.eventName, (data) => {
            // Only update the chat counter if we're not on the chat page
            this.$log.debug("Chat Threads: Received from pusher", data);
            const index = this.threads.data.findIndex(
              (t) =>
                t.thread_id === (data.thread || data.message.thread).thread_id
            );
            if (index > -1) {
              const thread = this.threads.data[index];
              if (this.realSelectedThread.id !== thread.id) {
                thread.new_messages_for_user_count =
                  (thread.new_messages_for_user_count || 0) + 1;
                this.$set(this.threads.data, index, thread);
              }
            }
          });
        this.subscribedChannels.push(monitor);
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";

.chat-threads.v-card {
  height: 100%;
  margin-top: 0px;
  margin-bottom: -8px;
}

.chat-items-nav.v-toolbar--card,
.chat-items-nav.v-toolbar--card .v-toolbar__content {
  height: 48px !important;
  padding: 0 0;
}

.chat-items-nav.v-toolbar--card .v-toolbar__content .v-toolbar__items {
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  width: 100%;
}

.chat-items-nav.v-toolbar--card .v-toolbar__content {
  width: 100%;
}

.chat-items-nav.v-toolbar--card .v-btn,
.chat-items-nav
  .v-toolbar__items
  .v-btn:not(.v-btn--floating):not(.v-btn--icon),
.chat-items-nav .v-toolbar__items .v-menu,
.v-toolbar__items .v-menu__activator {
  border-radius: 2px;
  height: 32px;
  text-transform: none;
  color: $font-light-color;
  flex: 1 1 auto;
}

.chat-items-nav.v-toolbar--card .v-btn.active {
  background-color: $white-color !important;
  box-shadow: 0 0 10px 0 $box-shadow-color !important;
  color: #000;
}

.chat-threads .chat-threads-list {
  flex: 1 1 100%;
  overflow-y: auto;
}

.thread {
  border-top: solid thin #f9f9fe;
  border-bottom: solid thin #f9f9fe;
  border-left: none;
  border-right: none;
  border-radius: 0px;

  &.active {
    background-color: #fff;
  }
}
</style>