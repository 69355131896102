<template>
  <VNavigationDrawer
    right
    absolute
    floating
    temporary
    width="300"
    height="auto"
    id="notification-panel"
    class="pa-1 bg-transparent"
    :style="{
      overflow: 'hidden',
      right: $vuetify.breakpoint.smAndDown ? '0px' : '64px',
      borderRadius: '10px',
      boxShadow: 'none !important'
    }"
    :value="active"
    :mobile-breakpoint="959"
    :disable-resize-watcher="false"
  >
    <VCard class="bg-transparent">
      <VCardTitle
        class="bg-transparent no-border-radius no-border"
        @click.stop="$emit('toggle')"
      >
        <FeedNotification
          mode="link"
          style="position: absolute; right: 1px"
          :channels="[`users.${$user.id}`]"
          @toggle="$emit('toggle')"
        />
        <!-- List Of Notifications -->
      </VCardTitle>
      <VCardText
        class="pl-3 pr-1 py-1 elevation-1 border-radius"
        style="background-color: #fff; border-bottom: none"
      >
        <VList v-if="hasNotifications">
          <VListItem>
            <VListItemContent>
              <VListItemTitle class="text-center info--text">
                <small>You have {{ $user.notification_announcement_count }} unread notifications!</small>
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
        <template
          v-if="activity.isLoading && isFirstLoad"
        >
          <VList
            two-line
            id="notifications-loading"
            class="infinite-loading"
          >
            <VListItem
              class="px-1"
            >
              <VListItemContent>
                <VSkeletonLoader
                  align-center
                  justify-center
                  type="list-item-avatar-three-line"
                  transition="scale-transition"
                />
              </VListItemContent>
            </VListItem>
          </VList>
        </template>
        <template v-else-if="hasNotifications">
          <VList
            two-line
            id="notifications"
          >
            <VListItem
              v-for="(notification, index) in notificationData"
              class="notification px-1 align-start"
              :key="index"
            >
              <Badge
                v-if="notification.creator || notification.icon"
                avatar
                overlap
                top
                right
                offset-x="25"
                color="transparent"
              >
                <!-- Notification Icon -->
                <VListItemAvatar
                  round
                  size="50"
                  class="ml-0"
                >
                  <img
                    v-if="notification.creator"
                    :src="notification.creator.photo_url"
                    class="spark-profile-photo"
                    style="width: 50px"
                  >

                  <span v-else>
                    <VIcon>{{ getIcon(notification) }}</VIcon>
                  </span>
                </VListItemAvatar>
                <template #badge>
                  <VIcon
                    v-if="!notification.read"
                    small
                    slot="badge"
                    color="primary"
                  >
                    new_releases
                  </VIcon>
                </template>
              </Badge>

              <!-- Notification -->
              <VListItemContent>
                <!-- <VListItemTitle class="meta layout row space-between">
              <VCol
                grow
                class="py-1 px-0"
              >
                <span class="text-truncate text-no-wrap">
                  <template v-if="notification.creator">
                    <small v-if="notification.creator.id !== $user.id">via</small>
                    {{ notification.creator.name }}
                  </template>
                  <template v-else>WeThrive</template>
                </span>
              </VCol>
              <VSpacer />
              <VCol
                shrink
                class="py-1 px-0"
              >
                <small class="date">{{ $timezone.fromNow(notification.created_at) }}</small>
              </VCol>
            </VListItemTitle> -->

                <VListItemSubtitle
                  class="notification-body"
                  v-html="notification.body"
                />
                <VListItemSubtitle>
                  <!-- Notification Action -->
                  <VBtn
                    block
                    text
                    outlined
                    @click="navigateToCustom({path: notification.action_url.replace('#/', '#')})"
                    color="primary"
                    v-if="notification.action_text"
                  >
                    {{ notification.action_text }}
                  </VBtn>
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
            <infinite-loading
              v-if="canGetMore"
              force-use-infinite-wrapper="#notifications"
              :distance="25"
              :identifier="new Date()"
              @infinite="getOnlyNotifications"
            />
          </VList>
          <VList v-if="hasNotifications">
            <VListItem class="px-1">
              <VListItemContent class="pa-0">
                <VListItemTitle class="text-center pa-3">
                  <VBtn
                    block
                    :loading="activity.isClearing"
                    @click="onMarkNotificationsRead"
                  >
                    Mark All as Read
                  </VBtn>
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VList>
        </template>
        <template v-else>
          <VList>
            <VListItem class="px-1">
              <VListItemContent>
                <VListItemTitle class="text-center">
                  You're all caught up!
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VList>
        </template>
      </VCardText>
    </VCard>
  </VNavigationDrawer>
</template>
<script>
import FeedNotification from "@/components/Elements/Notifications/FeedNotification";
import { mapGetters, mapActions } from "vuex";
import ActivityMixin from "@/mixins/Activity";
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    lastChecked: {
      type: String,
      default: null,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  components: { FeedNotification },
  mixins: [ActivityMixin],
  data() {
    return {
      busy: false,
      mode: 0,
      isFirstLoad: true,
      drawer: false,
      timestamp: null,
      eventName: "FeedNotificationWasReceived",
      subscribedChannels: [],
      loadingNotifications: false,
      showingNotifications: true,
      showingAnnouncements: false,
      activity: {
        isClearing: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      notifications: "user/notifications",
    }),
    actualNotifications() {
      return this.notifications.notifications;
    },
    actualAnnouncements() {
      return this.notifications.announcements;
    },
    notificationData() {
      return this.actualNotifications.data || this.actualNotifications;
    },
    announcementData() {
      return this.actualAnnouncements.data || this.actualAnnouncements;
    },
    realPusher: function () {
      return this.$echo || this.$parent.pusher;
    },
    notificationsCount() {
      return this.actualNotifications.total || 0;
    },
    announcementsCount() {
      return this.actualAnnouncements.total || 0;
    },
    /**
     * Get the active notifications or announcements.
     */
    activeNotifications() {
      if (!this.notifications) {
        return [];
      }

      if (this.showingNotifications) {
        return this.actualNotifications;
      } else {
        return this.actualAnnouncements;
      }
    },

    /**
     * Determine if the user has any notifications.
     */
    hasNotifications() {
      return this.actualNotifications.total > 0;
    },

    /**
     * Determine if the user has any announcements.
     */
    hasAnnouncements() {
      return this.actualAnnouncements.total > 0;
    },

    /**
     * The number of unread announcements.
     */
    unreadAnnouncementsCount() {
      if (this.notifications && this.$user) {
        if (
          this.actualAnnouncements.length &&
          !this.$user.last_read_announcements_at
        ) {
          return this.actualAnnouncements.length;
        }

        return this.actualAnnouncements.filter((announcement) => {
          return this.$timezone
            .moment(this.$user.last_read_announcements_at)
            .isBefore(announcement.created_at);
        }).length;
      }

      return 0;
    },

    /**
     * The number of unread notifications.
     */
    unreadNotificationsCount() {
      if (this.notifications) {
        return this.actualNotifications.filter((notification) => {
          return !notification.read;
        }).length;
      }

      return 0;
    },

    /**
     * Determine if the user has any unread notifications.
     */
    hasUnreadAnnouncements() {
      return this.unreadAnnouncementsCount > 0;
    },

    /**
     * Determine if the user has any unread notifications.
     */
    hasUnreadNotifications() {
      return this.unreadNotificationsCount > 0;
    },
    notificationIds() {
      return this.actualNotifications.map((notification) => notification.id);
    },
    canGetMore() {
      return (
        !this.isFirstLoad &&
        this.actualNotifications.current_page <
          this.actualNotifications.last_page
      );
    },
  },
  watch: {
    active: function (value) {
      if (value === true) {
        this.$emit("startLoading");
        this.getNotifications();
      }
      // else
      // if (this.hasNotifications) {
      //   this.markNotificationsAsRead({
      //     notifications: this.notificationIds
      //   });
      // }
    },
  },
  created() {
    this.$log.debug("Notification Panel: Created component...");
    this.timestamp = this.lastChecked;
    this.doSetAppendsNotificationData(true);
  },
  methods: {
    ...mapActions({
      updateLastReadAnnouncements: "user/updateLastReadAnnouncements",
      doGetNotifications: "user/getNotifications",
      doSetAppendsNotificationData: "user/setAppendsNotificationData",
      doGetOnlyNotifications: "user/getOnlyNotifications",
      doGgetOnlyAnnouncements: "user/getOnlyAnnouncements",
      markNotificationsAsRead: "user/markNotificationsRead",
    }),
    getIcon(notification) {
      let icon =
        notification.icon && notification.icon.startsWith("fa-")
          ? notification.icon.slice(3)
          : notification.icon;
      switch (icon) {
        case "users":
          icon = "group";
          break;
      }

      return icon;
    },
    getProfileUrl(user) {
      return user.url.replace("user", "profile");
    },
    getDisplayName(user) {
      let name = user.displayName;
      let parts = name.split(" ");
      return parts[0] + " " + parts[1].substr(0, 1) + ".";
    },
    getAvatarUrl(user) {
      if (user && user.profile_url) {
        return user.profile_url;
      }
      let image = user ? user.avatar || user.image : undefined;
      return image ? image.url : "https://avatars.io/gravatar/username";
    },
    /**
     * Show the user notifications.
     */
    showNotifications() {
      this.showingNotifications = true;
      this.showingAnnouncements = false;
    },

    /**
     * Show the product announcements.
     */
    showAnnouncements() {
      this.showingNotifications = false;
      this.showingAnnouncements = true;

      this.updateLastReadAnnouncementsTimestamp();
    },

    /**
     * Mark all notifications read
     */

    onMarkNotificationsRead() {
      this.activity.isClearing = true;
      this.markNotificationsAsRead({
        notifications: [],
      })
        .then(() => {
          this.$user.notification_announcement_count = 0;
        })
        .finally(() => {
          this.activity.isClearing = false;
        });
    },

    /**
     * Update the last read announcements timestamp.
     */
    updateLastReadAnnouncementsTimestamp() {
      this.updateLastReadAnnouncements().then(() => {
        this.$bus.$emit("updateUser");
      });
    },

    getNotifications(params) {
      return this.doGetNotifications({
        ...(params || {}),
        ...{
          paginated: true,
        },
      }).finally(() => {
        this.isFirstLoad = false;
        this.busy = false;
        this.$emit("doneLoading");
      });
    },

    getOnlyNotifications($infinite) {
      this.busy = true;
      this.$emit("startLoading");
      this.doGetOnlyNotifications({
        paginated: true,
        page: this.actualNotifications.current_page + 1,
      }).then((result) => {
        this.busy = false;
        this.$emit("doneLoading");
        // this.markNotificationsAsRead({
        //   notifications: (result || []).map(r => r.id)
        // });
        setTimeout(() => {
          if (this.isFirstLoad) {
            this.isFirstLoad = false;
          }
          if ($infinite) {
            $infinite.loaded();
            if (result.current_page >= result.last_page) {
              $infinite.complete();
            }
          }
        }, 2500);
      });
    },

    getOnlyAnnouncements($infinite) {
      this.busy = true;
      this.doGetOnlyAnnouncements({
        paginated: true,
        page: this.actualAnnouncements.current_page + 1,
      }).then((result) => {
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
        if ($infinite) {
          $infinite.loaded();
          if (result.current_page >= result.last_page) {
            $infinite.complete();
          }
        }
      });
    },
    navigateToCustom(to) {
      this.$emit("toggle");
      this.navigateTo(to);
    },
  },
  mounted() {
    // this.getNotifications();
  },
};
</script>
<style>
#notification-panel .v-list {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

#notification-panel .v-navigation-drawer__content {
  padding-right: 18px;
}

.dialogs {
  max-height: 70vh;
  overflow: auto;
}

.dialogs a {
  font-size: 13px !important;
}

.dialogs .dialog_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_meta .dialog_date {
  flex: 0 1 128px;
  text-align: right;
}

:root .dialogs .dialog_author-name {
  flex: auto;
  transition: font-weight 50ms linear;
}

:root .dialogs .dialog_author-name:hover {
  font-weight: bolder;
}

.notification .v-list__tile {
  height: auto !important;
  align-items: flex-start;
}

/* .notification:not(last-child)::after {
  width: 100%;
  height: 3px;
  border-top: solid thin #ddd;
  content: "";
  display: block;
  margin: 10px auto;
} */

.notification .v-list__tile__sub-title {
  overflow: visible;
  white-space: normal;
}

.notification .notification-body {
  white-space: pre-wrap;
}
</style>