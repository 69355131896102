<template>
  <VCard
    v-if="hasAnyAssociations"
    class="px-0"
  >
    <VCardTitle>
      <h2 class="display-1">
        {{ title }}
      </h2>
    </VCardTitle>
    <VCardText class="pb-0">
      <template v-for="(association, key) in allAssociations">
        <template v-if="model.hasOwnProperty(association.key)">
          <Chips
            v-if="association.type == 'url'"
            open-in-new-window
            :key="`${association.key}-${model.id}-${key}`"
            :title="featureName(association.title)"
            :items="model[association.key]"
            :url-resolver="item => getFullyQualifiedUrlForItem(association.viewRoute, {
              type: item.type,
              id: item.id
            })"
            :action="item => openUrl(getFullyQualifiedUrlForItem(association.viewRoute, {
              type: item.type,
              id: item.id
            }), '_blank')"
          />
          <ChipsDialog
            v-else
            :key="`${association.key}-${model.id}-${key}`"
            :title="featureName(association.title)"
            :models="model[association.key]"
            :component="association.view"
          />
        </template>
      </template>
    </VCardText>
  </VCard>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import MembersList from "@/components/Elements/Data/MembersList";

export default {
  name: "AssociatedContent",
  props: {
    title: {
      type: String,
      default: "Associations",
    },
    model: {
      type: Object,
      default: () => {},
    },
    associations: {
      type: Array,
      default: () => [],
    },
    only: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultAssociations: [
        {
          title: "Programs",
          key: "programs",
          view: "ProgramView",
        },
        {
          title: "Pipelines",
          key: "pipelines",
          view: "DeliverableView",
        },
      ],
    };
  },
  computed: {
    allAssociations() {
      const result = this.defaultAssociations
        .concat(this.associations)
        .unique((a) => a.key);
      return this.only.length > 0
        ? result.filter((v, k) => this.only.indexOf(v.key) > -1)
        : result;
    },
    hasAnyAssociations() {
      return (
        this.hasPrograms ||
        this.hasPrimaryContents ||
        this.hasReports ||
        this.hasSupplements ||
        this.hasFAQs ||
        this.hasLessons ||
        this.hasWorksheets ||
        this.hasDeliverables ||
        this.hasPipelines
      );
    },
    hasPrograms() {
      return (
        this.model &&
        this.model.programs instanceof Array &&
        this.model.programs.length > 0
      );
    },
    hasPrimaryContents() {
      return (
        this.model &&
        this.model.primary_contents instanceof Array &&
        this.model.primary_contents.length > 0
      );
    },
    hasReports() {
      return (
        this.model &&
        this.model.reports instanceof Array &&
        this.model.reports.length > 0
      );
    },
    hasSupplements() {
      return (
        this.model &&
        this.model.supplements instanceof Array &&
        this.model.supplements.length > 0
      );
    },
    hasFAQs() {
      return (
        this.model &&
        this.model.faqs instanceof Array &&
        this.model.faqs.length > 0
      );
    },
    hasPipelines() {
      return (
        this.model &&
        this.model.pipelines instanceof Array &&
        this.model.pipelines.length > 0
      );
    },
    hasLessons() {
      return (
        this.model &&
        this.model.lessons instanceof Array &&
        this.model.lessons.length > 0
      );
    },
    hasWorksheets() {
      return (
        this.model &&
        this.model.worksheets instanceof Array &&
        this.model.worksheets.length > 0
      );
    },
    hasDeliverables() {
      return (
        this.model &&
        this.model.deliverables instanceof Array &&
        this.model.deliverables.length > 0
      );
    },
  },
};
</script>

