var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"row":"","align-center":""}},[(_vm.team.photo_url)?_c('VCol',{staticClass:"d-flex",attrs:{"shrink":"","justify-center":"","mr-4":"","cols":_vm.$vuetify.breakpoint.mdAndUp ? 'auto' : 12}},[_c('img',{style:({
        backgroundColor: 'white',
        maxHeight: _vm.imageSize || '96px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }),attrs:{"src":_vm.team.photo_url}})]):_vm._e(),_c('VCol',{attrs:{"grow":"","mt-2":"","cols":_vm.$vuetify.breakpoint.mdAndUp ? 'auto' : 12}},[_c('h1',{class:{
        [`display-${_vm.size}`]: _vm.size > 0,
        'title': _vm.size === 0,
        'text-center': _vm.$vuetify.breakpoint.smAndDown,
        'text-left': _vm.$vuetify.breakpoint.mdAndUp,
        [`${_vm.textColor}--text`]: true
      }},[_vm._v(" "+_vm._s(_vm.team.name)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }