<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading"
    :loading="activity.isFirstLoad || activity.isOptionsLoading"
  >
    <VRow>
      <VCol cols="12">
        <Autocomplete
          v-model="form[exactModelType]"
          multiple
          hide-details
          return-object
          hide-label
          :items="customOptions[exactModelType]"
          :placeholder="`Attach to ${title}`"
          @input="$emit('input', form[exactModelType])"
        />
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin],
  props: {
    section: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    customOptions: {
      type: Object,
      default: null,
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      skipFormValidation: true,
      canGetModel: this.item === null,
    };
  },
  computed: {
    exactModelType() {
      return this.modelType == 'contents' ? 'primary_contents' : this.modelType;
    },
  },
  created() {
    this.modelType = this.section;
  },
  mounted() {
    if(this.item) {
      this.form = this.item;
    }
  },
  methods: {
    ...mapActions({
      doSaveProgram: "program/save",
      doGetProgram: "program/getOne",
    }),
    onGet(id) {
      return this.doGetProgram(id);
    },
    onSave() {
      let data = {
        id: this.form.id,
        [this.exactModelType]: this.exactModelType == "pipelines" ? this.prepareRelationIds(this.form[this.exactModelType]) : this.prepareRelationIdsWithVisibility(this.form[this.exactModelType])
      };
      return this.saveFormModel(this.doSaveProgram, null, data, null, false).then((result) => {
        this.$bus.$emit(
          "notificationMessage",
          `${this.featureName(this.modelType.capitalize())} attached successfully!`
        );
        this.$emit("attached", result);
      });
    },
  },
};
</script>

