import api from '../../services/automation';

export default function (helper) {
  /**
   * Automation mutation types
   */
  const TYPES = {
    AUTOMATION_GET_ACTIVITY: 'AUTOMATION_GET_ACTIVITY'
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('automation');
  const state = helper.state({
    activity: {
      data: []
    }
  });
  const getters = helper.getters({
    hasActivity: state => state.activity.data.length > 0
  });
  const actions = helper.actions({
    getActivity(context, params) {
      helper.log().info('[Store: Automations]: Get Automation Workflow Activity', params);
      return helper.api().getActivity(params).then((response) => {
        helper.log().info(`[Store: Automations]: Got Automation Workflow Activity`, response);
        const data = response.data.data;
        context.commit(TYPES.AUTOMATION_GET_ACTIVITY, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Automations]: Error Getting Automation Workflow Activity`, error);
        throw error;
      });
    },
  }, 'Automation');
  const mutations = helper.mutations({
    [TYPES.AUTOMATION_GET_ACTIVITY](state, data) {
      state.activity = data;
    },
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}