<template>
  <div v-if="event && event.id">
    <p>
      {{ $timezone.formatDate(event.date || event.group) }}: {{ $timezone.formatTime(event.startTime || event.start_time) }} to {{ $timezone.formatTime(event.endTime || event.end_time) }}
      <br>
    </p>
    <p>Calendar: {{ event.calendar ? event.calendar.title : '' }}</p>
    <p 
      v-html="event.description"
      class="formatted"
    />
    <h3>RSVPS</h3>
    <RSVPList 
      :rsvps="event.rsvps || []"
      readonly
    />
  </div>
</template>
<script>
import RSVPList from "@/components/Elements/Data/RSVPList";
export default {
  name: "EventView",
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  components: { RSVPList }
};
</script>