<script>
import { mapActions } from "vuex";
export default {
  props: {
    deliverableId: {
      type: [Number, String],
      default: null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
  },
  data() {
    return {
      hasRatingMetadata: false,
      enableMetadataHeaders: false,
      metadataHeaders: [],
      submitForUserMode: false,
      submittedFilter: {},
      submittedModel: {
        deliverable: {},
        assessment: {},
        user: {},
      },
      submittedDialogs: {
        submitted: false,
        singleSubmitted: false,
        reports: false,
        deliverables: false,
        view: false,
        submit: false,
        evaluation: false,
        completedEvaluations: false,
      },
      submitted: {
        data: [],
      },
    };
  },
  computed: {
    hasSubmittedModel() {
      return this.submittedModel instanceof Object;
    },
    submittedHeaders() {
      let value = [];
      if (this.$isUserAdmin) {
        value = [
          {
            text: "Submitted By",
            align: "left",
            sortable: true,
            value: "title",
          },
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "program.name",
          },
          {
            text: this.featureName("Deliverable"),
            align: "left",
            sortable: true,
            value: "deliverable.name",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Submitted",
            align: "left",
            sortable: true,
            value: "is_submitted",
          },
        ];
      } else {
        value = [
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "program.name",
          },
          {
            text: this.featureName("Deliverable"),
            align: "left",
            sortable: true,
            value: "deliverable.name",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
        ];
      }
      if (this.hasRatingMetadata) {
        value.push({
          text: "Overall Rating",
          align: "left",
          sortable: false,
          value: "deliverable.overall_rating",
        });
      }
      return this.enableMetadataHeaders && this.$isUserAdmin
        ? value.concat(this.metadataHeaders)
        : value;
    },
  },
  methods: {
    ...mapActions({
      doGetSubmittedPipeline: "pipelineSubmitted/getOne",
    }),
    onShowSubmitted(item) {
      this.submittedDialogs.submitted = true;
      this.submitted = {};
      this.submittedModel = item;
      if (this.doGetSubmitted instanceof Function) {
        /* this.doSearch(this.doGetSubmitted, null, {
          id: item.id,
        }).then((result) => (this.submitted = result)); */

        // Above commented code is returning undefined result. Below is the new implementation to achieve the goal.
        this.activity.isLoading = true;
        this.doGetSubmitted({
          id: item.id,
        }).then((result) => {
          this.submitted = result;
          this.activity.isLoading = false;
        });
      }
    },
    onToggleSubmittedDialog(model) {
      this.submittedDialogs.submitted = !this.submittedDialogs.submitted;
      this.submittedModel = model || this.submittedModel;
      // if (model) this.$set(model, "isLoading", true);
    },
    onToggleEvaluationDialog(model) {
      this.submittedDialogs.evaluation = !this.submittedDialogs.evaluation;
      this.submittedModel = model || this.submittedModel;
      // if (model) this.$set(model, "isLoading", true);
    },
    onToggleCompletedEvaluationsDialog(model) {
      this.submittedDialogs.completedEvaluations = !this.submittedDialogs
        .completedEvaluations;
      this.submittedModel = model || this.submittedModel;
      // if (model) this.$set(model, "isLoading", true);
    },
    onToggleSubmitDialog(model) {
      this.submittedDialogs.submit = !this.submittedDialogs.submit;
      this.submittedModel = model || this.submittedModel;
      this.$set(model, "isLoading", true);
    },
    onToggleSingleSubmittedDialog(model) {
      this.submittedDialogs.singleSubmitted = !this.submittedDialogs.singleSubmitted;
      this.submittedModel = model || this.submittedModel;
      this.$set(model, "isLoading", true);
    },
    onToggleSubmittedReportsDialog() {
      this.submittedDialogs.reports = !this.submittedDialogs.reports;
    },
    onToggleSubmittedDeliverablesDialog() {
      this.submittedDialogs.deliverables = !this.submittedDialogs.deliverables;
    },
    setSubmittedDialogData(model, item, submitForUserMode) {
      this.submitted.data = model[item];
      this.submittedModel = model;
      if (item) {
        this.submittedDialogs[item] = true;
      } else {
        this.submittedDialogs.submitted = true;
      }
      this.submitForUserMode = submitForUserMode;
    },
    onLoadModelForSubmittedData(model, item) {
      this.setSubmittedDialogData({}, item);
      this.onGet({
        id: model.id,
        _with: [`${item}.metadata`],
      }).then((result) => {
        model = result.data || model;
        this.setSubmittedDialogData(model, item);
      });
    },
    next() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
        this.form = this.items.slice(this.currentIndex, 1);
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.form = this.items.slice(this.currentIndex, 1);
      }
    },
    hasSubmitted(submission) {
      return submission instanceof Object && submission.is_submitted === true;
    },
    hasSaved(submission) {
      return submission instanceof Object;
    },
    setEnableSubmittedHeaders(value) {
      this.enableMetadataHeaders = !!value;
      this.hasRatingMetadata =
        this.metadataHeaders.findIndex((m) => m.type == "Rating") > -1;
      if (this.hasRatingMetadata) {
        this.metadataHeaders.push({
          text: "Overall Rating",
          align: "left",
          sortable: true,
          value: "metadata_rating",
          class: "sticky",
        });
      }
      this.$forceUpdate();
    },
    onToggleEnableSubmittedHeaders() {
      this.enableMetadataHeaders = !this.enableMetadataHeaders;
      this.$forceUpdate();
    },
    getSubmittedHeaders() {
      let value = [];
      if (this.$isUserAdmin) {
        value = [
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: "Submitted By",
            align: "left",
            sortable: true,
            value: "submittedby.name",
          },
          {
            text: this.featureName("Deliverable", "singularize"),
            align: "left",
            sortable: true,
            value: "deliverable.name",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
        ];
      } else {
        value = [
          {
            text: this.featureName("Deliverable", "singularize"),
            align: "left",
            sortable: true,
            value: "deliverable.name",
          },
          {
            text: "For",
            align: "left",
            sortable: true,
            value: "program.name",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
        ];
      }
      return this.enableMetadataHeaders && this.$isUserAdmin
        ? value.concat(this.metadataHeaders)
        : value;
    },
    prepareSubmittedHeader(m) {
      return {
        text: m.name,
        align: "left",
        title: m.name,
        sortable:
          ["Rating", "Dropdown", "Number", "Checkbox", "Radio", "Text"].indexOf(m.type) >
          -1,
        type: m.type,
        // sortable: m.type,
        value: "metadata.value:" + m.id,
        metadataId: m.id,
      };
    },
    prepareLessonSubmittedHeader(c) {
      return {
        text: c.name,
        align: "left",
        title: c.name,
        sortable: false,
        type: c.type,
        value: "",
        cardId: c.id,
      };
    },
  },
};
</script>
