import BaseApi from "./_base-api";

/**
 * Program API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	setUserPermissions(params) {
		return this.api()
			.post(this.getUrl("programs", `${params.program_id}/user/${params.user_id}/permissions`), params)
			.catch(error => {
				this.log().error("[Services: Programs: Set User Permissions]:", error);
				throw this.utils.resolveError(error);
			});
	}
	removeAttachments(params) {
		return this.api()
			.post(this.getUrl("programs", `${params.program_id}/remove-attachments`), params)
			.catch(error => {
				this.log().error("[Services: Programs: Remove associated items of a program]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Program",
	path: "programs"
});

export default api;
