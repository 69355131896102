<template>
  <VCard>
    <VCardTitle v-if="showTitle">
      Mailed Invitations
      <VSpacer />
      <VBtn
        icon
        @click="$emit('refreshInvitations')"
      >
        <VIcon>refresh</VIcon>
      </VBtn>
    </VCardTitle>
    <VCardText>
      <VSkeletonLoader
        type="date-picker-options"
        transition="expand-transition"
        :loading="isLoading"
      >
        <VList
          v-if="invitations.length > 0"
          two-line
        >
          <VListItem
            class="reveal"
            v-for="(invitation, key) in invitations"
            :key="key"
          >
            <!-- E-Mail Address -->
            <VListItemContent>
              <VListItemTitle>
                <span v-if="invitation.name">{{ invitation.name + ": &nbsp;" }}</span>
                {{ invitation.email }}
              </VListItemTitle>
              <VListItemSubtitle>
                <VChip
                  small
                  text-color="white"
                  color="info"
                >
                  {{ featureName(invitation.role, 'titleize') }}
                </VChip> Invited at
                <strong>{{ $timezone.formatDateTime(invitation.created_at) }}</strong>
              </VListItemSubtitle>
            </VListItemContent>
            <!-- Delete Button -->
            <VListItemAction>
              <VTooltip bottom>
                <template #activator="{on}">
                  <VBtn
                    v-on="on"
                    icon
                    outlined
                    color="error"
                    :disabled="invitation.isLoading"
                    :loading="invitation.isLoading"
                    @click="cancel(invitation, key)"
                  >
                    <VIcon>cancel</VIcon>
                  </VBtn>
                </template>
                <span>Cancel invitation to {{ invitation.email }}</span>
              </VTooltip>
            </VListItemAction>
          </VListItem>
        </VList>
        <VAlert
          v-else
          color="#ffe79a"
          class="mb-0 body-2"
        >
          No invitations found!
        </VAlert>
      </VSkeletonLoader>
      <Confirm ref="confirmDelete" />
    </VCardText>
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import DeletesItemsVue from "@/mixins/DeletesItems.vue";
export default {
  props: {
    invitations: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [DeletesItemsVue],
  methods: {
    ...mapActions({
      doDeleteInvite: "team/deleteInvite",
    }),
    /**
     * Cancel the sent invitation.
     */
    cancel(invitation, key) {
      //   axios.delete(`/settings/invitations/${invitation.id}`)
      this.$set(this.invitations[key], "isLoading", true);
      this.onDeleteItem([invitation], this.doDeleteInvite).then((result) => {
        if(result) {
          this.$emit("removeInvitation", invitation.id);
        }
        this.$set(this.invitations[key], "isLoading", false);
      });
    },
  },
};
</script>