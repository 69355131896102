<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="save-pipeline"
    type="table-heading,card"
    :loading="activity.isFirstLoad"
    :class="{
      'my-4': activity.isFirstLoad,
    }"
  >
    <VRow class="mr-n4">
      <VCol cols="auto py-0 left-panel">
        <SaveLeftBar>
          <AdditionalSave
            ref="additionalSave"
            v-model="form"
            :pages="pages"
            :validator="$v"
            :activity="activity"
            :options="options"
            :has-metadata="hasMetadata"
            :current-stage="currentStage"
            :active-tab-index="activeUrlTab"
            :active-stage-index="activeStageIndex"
            :default-page-title="defaultPageTitle"
            @addNewPage="onAddNewPage"
            @validated="$v.$touch()"
            @addStage="onAddStage"
            @stageMoved="onStageMoved"
            @deleteStage="onDeleteStage"
            @setModelDirty="onSetModelDirty"
            @updateActiveStageIndex="onUpdateActiveStageIndex"
            @updateDefaultPageTitle="title => defaultPageTitle = title"
            @pageMoved="event => $refs.dynamicInputs.onSectionMoved(event)"
          />
        </SaveLeftBar>
      </VCol>
      <VCol class="right-panel">
        <VRow>
          <SaveHeader
            alt-back-icon
            class="pl-0 pr-1"
            :owner="this"
            :mode="mode"
          >
            <template #title>
              Save {{ pipelineType }}
            </template>
            <template #actions-center>
              <VTabs
                grow
                background-color="transparent"
                class="form-tabs"
              >
                <VTab
                  v-for="(tab, index) in enabledTabs"
                  :to="{
                    hash: tab.url
                  }"
                  :key="index"
                >
                  {{ tab.title }}
                </VTab>
              </VTabs>
              <VTooltip
                top
                content-class="required-tooltip"
                :disabled="Boolean(form.title)"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <VBtn
                      height="40px"
                      color="#FFC100"
                      class="mr-3 ml-1 px-6"
                      style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25); border-radius: 10px;"
                      :disabled="!form.id || activity.isFormLoading"
                      @click="dialogs.export = !dialogs.export"
                    >
                      <span class="white--text">Export</span>
                    </VBtn>
                  </div>
                </template>
                <span v-if="form.id">
                  Export this {{ pipelineType }}
                </span>
                <span v-else>
                  {{ `Save ${pipelineType.toLowerCase()} to enable Preview` }}
                </span>
              </VTooltip>
              <VTooltip
                top
                content-class="required-tooltip"
                :disabled="Boolean(form.title)"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <VBtn
                      height="40px"
                      color="#60be39"
                      class="mr-3 px-6"
                      style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25); border-radius: 10px;"
                      :loading="activity.isLoading"
                      :disabled="isSaveDisabled"
                      @click="onSave"
                    >
                      <span class="white--text">Save</span>
                    </VBtn>
                  </div>
                </template>
                {{ `Enter a title to save ${pipelineType.toLowerCase()}` }}
              </VTooltip>
              <VTooltip
                top
                content-class="required-tooltip"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <VBtn
                      v-if="form.id"
                      color="error"
                      height="40px"
                      class="px-6 mr-3"
                      style="border-radius: 10px;"
                      :disabled="activity.isFormLoading"
                      :loading="form.isLoading"
                      @click="deleteItem"
                    >
                      <span class="white--text">Delete</span>
                    </VBtn>
                  </div>
                </template>
                Delete this {{ pipelineType.toLowerCase() }}
              </VTooltip>
              <VTooltip
                top
                content-class="required-tooltip"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <VBtn
                      height="40px"
                      min-width="auto"
                      color="rgba(60, 156, 255, 0.2)"
                      class="px-2 mr-3 no-shadow"
                      style="border-radius: 10px;"
                      :disabled="!form.id || isSaveDisabled"
                      @click="onToggleApplicationForm"
                    >
                      <VIcon color="#3c9cff">
                        mdi-eye
                      </VIcon>
                    </VBtn>
                  </div>
                </template>
                <span v-if="form.id">
                  Preview
                </span>
                <span v-else>
                  {{ `Save ${pipelineType.toLowerCase()} to enable Preview` }}
                </span>
              </VTooltip>
              <VTooltip
                top
                content-class="required-tooltip"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <VBtn
                      height="40px"
                      min-width="auto"
                      color="rgba(251, 152, 14, 0.2)"
                      class="px-2 mr-3 no-shadow"
                      style="border-radius: 10px;"
                      :disabled="!form.id || isSaveDisabled"
                      @click="dialogs.landingPage = true"
                    >
                      <VIcon color="#fb980e">
                        mdi-view-grid-plus-outline
                      </VIcon>
                    </VBtn>
                  </div>
                </template>
                <span v-if="form.id">
                  Add to Landing Page
                </span>
                <span v-else>
                  {{ `Save ${pipelineType.toLowerCase()} to enable Landing Page addition` }}
                </span>
              </VTooltip>
            </template>
          </SaveHeader>
          <VCol cols="12">
            <VTabsItems 
              touchless
              style="background-color: transparent;"
              :value="activeUrlTab"
            >
              <VTabItem>
                <VRow>
                  <VCol style="height: calc(100vh - 182px); overflow: hidden auto;">
                    <VCard
                      class="py-2"
                      style="box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);"
                    >
                      <VCardTitle
                        class="pa-4"
                        style="height: 100% !important; border: none !important;"
                      >
                        <TextField
                          v-if="hasCurrentStage"
                          v-model="form.stages[activeStageIndex].title"
                          hide-details
                          :label="`${featureName('Stage')} Title`"
                          :placeholder="`Enter ${featureName('Stage')} title...`"
                          :error="$v.form.stages.$each[activeStageIndex].title.$error"
                          @input="$v.form.stages.$each[activeStageIndex].title.$touch(); onSetModelDirty()"
                          @blur="$v.$touch()"
                        />
                      </VCardTitle>
                      <VCardText class="pa-0">
                        <VRow v-if="hasMetadata">
                          <VCol>
                            <DynamicInputs
                              v-model="form.stages[activeStageIndex].metadata"
                              fields-only
                              new-fields-view
                              enable-card-inputs
                              enable-default-values
                              enable-extra-options
                              ref="dynamicInputsMain"
                              container-class="dynamic-input-container px-4 white"
                              :normal-actions-view="false"
                              :can-add-more-inputs="false"
                              :show-delete-btn-text="false"
                              :card="false"
                              :default-value-options="options.default_metadata_value_options"
                              :input-name="featureName('Deliverable')"
                              :enable-files="true"
                              :readonly="true"
                              :values-from-user="true"
                              :sections="currentStage.metadata_sections"
                              :default-section-title="defaultPageTitle"
                              @new:input="onNewMetadata"
                              @update:input="(key, input) => onUpdateMetadata(key, input, null, `form.stages[${activeStageIndex}]`)"
                              @update:inputs="(group) => onUpdateMultipleMetadata(group, null, `form.stages[${activeStageIndex}]`)"
                              @remove:input="(key) => onRemoveMetadata(key, null, `form.stages[${activeStageIndex}]`)"
                              @remove:inputs="(group) => onRemoveMultipleMetadata(group, null, `form.stages[${activeStageIndex}]`)"
                              @input:sections="(sections) => onSetMetadataSections(sections, null, `form.stages[${activeStageIndex}]`)"
                              @updateDefaultSectionTitle="title => defaultPageTitle = title"
                              @removeSection="removePage"
                            />
                          </VCol>
                        </VRow>
                      </VCardText>
                      <VCardActions class="px-4">
                        <VBtn
                          v-if="isDeleteStageEnabled"
                          color="error"
                          height="40px"
                          class="px-6"
                          style="border-radius: 10px;"
                          :disabled="activity.isFormLoading"
                          :loading="currentStage.isDeleting"
                          @click="onDeleteStage(activeStageIndex)"
                        >
                          <span class="white--text">Delete {{ featureName("Stage") }}</span>
                        </VBtn>
                        <VSpacer />
                        <VBtn
                          color="#ececf1"
                          height="40px"
                          class="px-6"
                          style="box-shadow: none; border-radius: 10px;"
                          :disabled="activity.isFormLoading || form.isLoading"
                          @click="$router.push('/settings#pipelines')"
                        >
                          <span style="color: #808080;">Cancel</span>
                        </VBtn>
                        <VTooltip
                          top
                          content-class="required-tooltip"
                          :disabled="Boolean(form.title)"
                        >
                          <template #activator="{ on }">
                            <div v-on="on">
                              <VBtn
                                color="#60be39"
                                height="40px"
                                class="ml-4 px-6"
                                style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25); border-radius: 10px;"
                                :disabled="!isFormReady || isFormInputsDisabled || form.isLoading"
                                :loading="activity.isLoading"
                                @click="onSave"
                              >
                                <span class="white--text">Save</span>
                              </VBtn>
                            </div>
                          </template>
                          {{ `Enter a title to save ${pipelineType.toLowerCase()}` }}
                        </VTooltip>
                      </VCardActions>
                    </VCard>
                  </VCol>
                  <VCol cols="auto">
                    <DynamicInputs
                      v-if="hasCurrentStage"
                      v-model="form.stages[activeStageIndex].metadata"
                      hide-fields
                      list-view
                      enable-card-inputs
                      enable-extra-options
                      enable-default-values
                      ref="dynamicInputs"
                      questions-list-style="overflow: hidden auto; height: calc(100vh - 206px);"
                      :questions-list-class="$vuetify.breakpoint.md ? 'ml-n4' : 'ml-2'"
                      :readonly="true"
                      :values-from-user="true"
                      :default-section-title="defaultPageTitle"
                      :form-files="currentStage.files"
                      :sections="currentStage.metadata_sections"
                      :default-value-options="options.default_metadata_value_options"
                      @input:sections="(sections) => onSetMetadataSections(sections, null, `form.stages[${activeStageIndex}]`)"
                      @removeFile="onRemoveFile"
                      @addFile="onAddFile"
                    />
                  </VCol>
                </VRow>
              </VTabItem>
              <VTabItem>
                <Submitted
                  v-if="form.id"
                  ref="submitted"
                  mode="dialog"
                  class="table-th-default-color"
                  :pipeline="form"
                  :can-load-from-url="false"
                  :custom-filter="getCustomFilter"
                  @updateStats="onUpdateStats"
                />
                <VCard
                  v-else
                  class="pa-2 mt-4"
                >
                  <VCardText class="px-4">
                    <VAlert
                      color="rgba(73, 148, 236, 0.2)"
                      class="subtitle-2 font-weight-medium mt-4"
                      style="border-radius: 14px;"
                    >
                      <template #prepend>
                        <VIcon
                          color="#4994EC"
                          class="pr-3"
                        >
                          mdi-information
                        </VIcon>
                      </template>
                      {{ `Save ${pipelineType.toLowerCase()} to see Submitted data` }}
                    </VAlert>
                  </VCardText>
                </VCard>
              </VTabItem>
              <VTabItem>
                <ReviewersAndEvaluate
                  v-model="form"
                  :has-current-stage="hasCurrentStage"
                  :active-stage-index="activeStageIndex"
                />
              </VTabItem>
            </VTabsItems>
          </VCol>
          <StageSubmit
            v-if="dialogs.applicationForm"
            readonly
            is-inputs-required
            mode="dialog"
            :is-dialog-open="dialogs.applicationForm"
            :stage="currentOnBoardingStage"
            :existing="currentOnBoardingStage"
            @toggle:dialog="() => dialogs.applicationForm = false"
          />
          <FormDialog
            new-actions
            hide-delete-button
            max-width="75%"
            title="Landing Page Settings"
            :is-dialog-open="dialogs.landingPage"
            :do-save-action="() => $refs.registration.onSave()"
            @toggle:form:dialog="dialogs.landingPage = !dialogs.landingPage"
          >
            <Registration
              get-model
              highlight-pipeline
              ref="registration"
              class="ml-0"
              :id="$team.id"
              :active-tab="2"
              :is-standalone="false"
              :pipeline="form"
            />
          </FormDialog>
          <Dialog
            v-if="dialogs.export"
            is-dialog-open
            max-width="60%"
            :title="`Export ${featureName('Pipelines')}`"
            @toggle:dialog="dialogs.export = !dialogs.export"
          >
            <Export 
              v-if="form.id && hasCurrentStage"
              simple
              hide-submitted
              ref="exported"
              :pipeline-id="form.id"
              :current-stage-id="form.stages[activeStageIndex].id"
            />
          </Dialog>
        </VRow>
        <Confirm ref="confirmDelete" />
        <Confirm ref="confirmLeave" />
        <Confirm
          alt-view
          ref="confirmDeleteStage"
          :alt-title="`Delete ${featureName('Stage')}?`"
        />
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import HasTabsMixin from "@/mixins/HasTabs";
import HasDueMixin from "@/mixins/HasDueOn";
import MetadataMixin from "@/mixins/Metadata";
import DeletesItems from "@/mixins/DeletesItems";
import DefaultAssociations from "@/mixins/DefaultAssociations.vue";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import SaveLeftBar from "@/components/Elements/Navigation/SaveLeftBar";
import Submitted from "./Submitted";
import Export from "./Export";
import StageSubmit from "./StageSubmit";
import AdditionalSave from "./AdditionalSave";
import ReviewersAndEvaluate from "./ReviewersAndEvaluate";
import Registration from "../Registration";

export default {
  name: "PipelinesSave",
  components: {
    Export,
    SaveHeader,
    SaveLeftBar,
    DynamicInputs,
    AdditionalSave,
    Submitted,
    StageSubmit,
    Registration,
    ReviewersAndEvaluate,
  },
  mixins: [
    FormMixin, 
    HasDueMixin, 
    HasTabsMixin, 
    MetadataMixin, 
    DeletesItems,
    DefaultAssociations,
    HasApplicationPipelinesMixin
  ],
  data() {
    return {
      activeTab: 0,
      activeStageIndex: 0,
      modelType: "Pipeline",
      redirectOnSave: true,
      defaultPageTitle: "Page Title 1",
      hasFiles: true,
      tempReviewers: [],
      dialogs: {
        landingPage: false,
        export: false,
      },
      options: {
        types: [],
        supplements: [],
        primary_contents: [],
        due_in_units: [],
        default_metadata_value_options: {},
      },
      defaultForm: {
        title: null,
        due_on: null,
        public_due_on: null,
        due_in: 1,
        due_in_unit: "day",
        due_in_time: null,
        programs: [],
        supplements: [],
        metadata: [],
        metadata_sections: [],
        primary_contents: [],
        files: [],
        stages: [{
          title: "Stage Title",
          description: null,
          due_on: "",
          metadata: [],
          metadata_sections: [],
          is_required_during_onboarding: true,
          statuses: {
            name: "Pipeline Statuses",
            type: "Dropdown",
            options: {
              type: "Dropdown",
              options: [],
            },
          },
        }],
        timeline: [],
        statuses: {
          name: "Pipeline Statuses",
          type: "Dropdown",
          options: {
            type: "Dropdown",
            options: [],
          },
        },
        stage_statuses: {
          name: "Stage Statuses",
          type: "Dropdown",
          options: {
            type: "Dropdown",
            options: [],
          },
        },
        is_active: true,
        type: "application-pipeline",
        reviewers: [],
        approved_user_role: {
          type: "Text",
          value: "student",
        },
      },
      dueOn: {
        date: null,
        time: null,
      },
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      leaveMessage: "You have unsaved changes are you sure you want to leave?",
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
      stages: {
        $each: {
          title: {
            required,
            minLength: minLength(4),
          },
          metadata: {
            $each: {
              name: {
                required: requiredIf(function(input) {
                  return ["Stack", "List", "Grid"].indexOf(input.type) > -1;
                }),
              },
              options: {
                type: {
                  required: requiredIf(function(option) {
                    return (
                      option &&
                      option.hasOwnProperty("type") &&
                      option.type === null
                    );
                  }),
                },
                options: {
                  $each: {
                    title: {
                      required: requiredIf(function(option) {
                        return ["Stack", "Grid"].indexOf(option.type) > -1;
                      }),
                    },
                    options: {
                      $each: {
                        title: { required: false },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  computed: {
    tabs() {
      const env = this.$root.env;
      return [
        {
          title: "Build",
          url: "build",
          enabled: true,
        },
        {
          title: "Submitted",
          url: "submitted",
          enabled: true,
        },
        {
          title: "Evaluate",
          url: "evaluate",
          enabled: true,
        },
      ];
    },
    hasPrograms() {
      return this.form.programs && this.form.programs.length;
    },
    dueInUnits() {
      let units = [];
      for (const unit in this.options.due_in_units) {
        units.push({
          title: this.options.due_in_units[unit],
          id: unit,
        });
      }
      return units;
    },
    allStages: {
      get() {
        return this.form.stages instanceof Array ? this.form.stages : [];
      },
      set(value) {
        this.form.stages = value;
      },
    },
    hasRequiredStage() {
      var isValidStage = true;
      if (this.allStages.length > 0) {
        for (const key in this.form.stages) {
          isValidStage = this.form.stages[key].is_required_during_onboarding;
          if (isValidStage) {
            break;
          }
        }
      }
      return isValidStage;
    },
    pages() {
      if(this.hasMetadata) {
        let inputs =  this.currentStage.metadata.groupBy((input) => input.section);
        return Object.keys(inputs || {}).unique();
      }
      return [];
    },
    currentOnBoardingStage() {
      if(this.form.hasOwnProperty("stages") && this.form.stages.length > 0) {
        return this.form.stages.find((stage) => stage.is_required_during_onboarding === true);
      }
      return {};
    },
    currentStage() {
      return this.form.hasOwnProperty("stages") && this.form.stages.length > 0 ? this.form.stages[this.activeStageIndex] : {};
    },
    hasCurrentStage() {
      return this.currentStage && this.currentStage.hasOwnProperty("metadata");
    },
    hasMetadata() {
      return this.currentStage.hasOwnProperty("metadata") && this.currentStage.metadata.length > 0;
    },
    isSaveDisabled() {
      return !this.isFormReady || this.isFormInputsDisabled || this.form.isLoading || this.tabs[this.activeUrlTab].url == "submitted";
    },
    getCustomFilter() { 
      if(this.$route.hash == "#submitted" && this.$route.query.all === true) {
        return {};
      }
      return {
        filter: {
          strict: true,
          stage: [this.hasCurrentStage ? this.currentStage.id : null],
          stage_status: ["submitted"],
        },
      };
    },
    isDeleteStageEnabled() {
      if(this.form.hasOwnProperty("id")) {
        let savedStages = this.form.stages.filter((stage) => stage.hasOwnProperty("id"));
        if(savedStages.length > 1) {
          return true;
        }
        return this.currentStage.is_required_during_onboarding === false;
      }
      return this.form.hasOwnProperty("stages") && this.form.stages.length > 1;
    },
    pipelineType() {
      return this.form.type == "pipeline" ? "Registration" : "Application";
    },
    deleteStageMessage() {
      return `Do you want to delete this ${this.featureName("stage")}? Deleting the ${this.featureName("stage")} will also delete all pages and its questions within that ${this.featureName("stage")}.`;
    },
  },
  /**
   * Handler before route leave to alert user to unsaved information
   */
  beforeRouteLeave (to, from, next) {
    if (this.enableLeaveConfirmation) {
      this.onConfirmLeave(next);
    } else {
      next();
    }
  },
  created() {
    window.addEventListener(
      "beforeunload",
      (e) => {
        e.preventDefault();
        if (this.modelIsDirty && this.enableLeaveConfirmation) {
          e.returnValue = this.leaveMessage;
          return this.leaveMessage;
        }
        return;
      },
      false
    );
  },
  mounted() {
    if(!this.form.id) {
      // Add a new page to the default stage
      this.onAddNewPage();
      this.form.stages[0].title = `${this.featureName('Stage')} Title`;
      this.$nextTick(() => this.onSetModelClean());
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipeline/getFormConfig",
      doSavePipeline: "pipeline/save",
      doGetPipeline: "pipeline/getOne",
      doDeletePipeline: "pipeline/delete",
      doGetStages: "pipelineStage/getAll",
      doDeleteStage: "pipelineStage/delete",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onDelete(pipeline) {
      return this.doDeletePipeline(pipeline.id);
    },
    deleteItem() {
      this.onDeleteItem(this.form).then((result) => {
        if (result) {
          this.onSetModelClean();
          setTimeout(() => {
            this.$router.push('/settings#pipelines');
          }, 1000);
        }
      });
    },
    onSave() {
      if (!this.hasRequiredStage) {
        this.$bus.$emit(
          "notificationError",
          `Please select the ${this.featureName('Stage').toLowerCase()} used for onboarding.`
        );
        return false;
      }
      this.setOnboardingStage();
      // filter selected programs based on selected role
      this.form.programs = this.$refs.additionalSave.filteredPrograms.filter((program) => this.prepareRelationIds(this.form.programs).indexOf(program.id) > -1);
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.supplements = this.prepareRelationIds(this.form.supplements);
      this.form.primary_contents = this.prepareRelationIds(
        this.form.primary_contents
      );
      this.form.deliverables = this.prepareRelationIds(this.form.deliverables);
      this.form.faqs = this.prepareRelationIds(this.form.faqs);
      this.form.attendances = this.prepareRelationIds(this.form.attendances);
      this.form.calendars = this.prepareRelationIds(this.form.calendars);
      this.form.lessons = this.prepareRelationIds(this.form.lessons);
      this.form.worksheets = this.prepareRelationIds(this.form.worksheets);
      this.form.stage_statuses =
        this.form.stage_statuses || this.defaultForm.stage_statuses;
      this.form.statuses = this.form.statuses || this.defaultForm.statuses;
      const data = this.prepareFormModel(
        { ...this.form },
        {
          dontSave: ["team", "user"],
        }
      );
      return this.saveFormModel(this.doSavePipeline, null, data, null, false).then((result) => {
        if (result) {
          this.$bus.$emit("notificationMessage", `Saved ${this.pipelineType.toLowerCase()} successfully!`);
          this.$refs.dynamicInputsMain.prepareValue(true);
        }
        return result;
      }).finally(() => {
        this.onSetModelClean();
      });
    },
    onGet(id) {
      return this.doGetPipeline(id).then((result) => {
        this.setDueOn(result);
        // If no stage selected for onboarding then first stage will be default onboarding stage
        if(!result.onboarding_stage && result.stages_count) {
          this.$set(result.stages[0], "is_required_during_onboarding", true);
        }
        result.stages = this.sortStages(result.stages);
        return result;
      });
    },
    sortStages(stages) {
      if(stages.length) {
        let currentOnboardingIndex = stages.findIndex(stage => stage.is_required_during_onboarding === true);
        if(currentOnboardingIndex) {
          stages.unshift(stages.splice(currentOnboardingIndex, 1)[0]);
        }
      }
      return stages;
    },
    onUpdateDueOn(datetime) {
      this.form.due_on = datetime;
    },
    onUpdatePublicDueOn(datetime) {
      this.form.public_due_on = datetime;
    },
    async onAddStage() {
      await this.onNewMetadata(
        {
          pipeline_id: this.form.id,
        },
        null,
        "stages"
      );
      this.activeStageIndex = this.form.stages.length - 1;
      this.$set(this.form.stages, this.activeStageIndex, {
        title: `${this.featureName('Stage')} Title`,
        description: null,
        due_on: "",
        metadata: [],
        metadata_sections: [],
        is_required_during_onboarding: false,
        statuses: {
          name: "Pipeline Statuses",
          type: "Dropdown",
          options: {
            type: "Dropdown",
            options: [],
          },
        },
      });
      this.onAddNewPage();
    },
    onUpdateStage(data, index) {
      this.$log.info("Updating stage", index, data);
      let existing = this.form.stages[index] || {};
      this.$set(
        this.form.stages,
        index,
        { ...existing, ...data }.sort((s) => s.is_required_during_onboarding)
      );
    },
    onSetRequiredStage(stageKey) {
      if (this.form.stages.length > 1) {
        for (const key in this.form.stages) {
          this.$set(
            this.form.stages[key],
            `is_required_during_onboarding`,
            key == stageKey
          );
        }
      }
    },
    onRemoveStage(key) {
      this.onRemoveMetadata(key, "stages");
    },
    onAddNewPage() {
      this.defaultPageTitle = `Page Title ${this.pages.length + 1}`;
      this.$nextTick(() => {
        this.$refs.dynamicInputs.onAddInput({
          text: "Short Text",
          value: "Text",
          icon: "mdi-text",
        });
      });
    },
    removePage() {
      if(this.pages.length > 0) {
        this.defaultPageTitle = this.currentStage.metadata[0].section;
      }
      this.onSetModelDirty();
    },
    onStageMoved(event) {
      if (event && event.moved) {
        this.form.stages.splice(
          event.moved.newIndex,
          0,
          this.form.stages.splice(event.moved.oldIndex, 1)[0]
        );
        if(event.moved.newIndex === 0) {
          this.$nextTick(() => {
            this.$refs.dynamicInputsMain.prepareValue(true);
          });
        }
      }
    },
    setOnboardingStage() {
      let deletedOnboardingIndex = this.form.stages.findIndex((stage) => stage.is_required_during_onboarding === true && stage.deleted === true);
      if(deletedOnboardingIndex === 0) {
        this.$set(this.form.stages[1], "is_required_during_onboarding", true);
      } else {
        this.$set(this.form.stages[0], "is_required_during_onboarding", true);
      }
    },
    onPageMoved(event) {
      if (event && event.moved) {
        this.form.stages[this.activeStageIndex].metadata.splice(
          event.moved.newIndex,
          0,
          this.form.stages[this.activeStageIndex].metadata.splice(event.moved.oldIndex, 1)[0]
        );
      }
    },
    onUpdateActiveStageIndex(index) {
      this.activeStageIndex = index;
      this.$nextTick(() => {
        if(this.$refs.hasOwnProperty("submitted")) {
          this.$refs.submitted.refresh();
        }
        this.$refs.dynamicInputsMain.prepareValue(true);
      });
    },
    onConfirmLeave(callback) {
      if (this.modelIsDirty) {
        this.$refs["confirmLeave"]
          .open("Unsaved changes", this.leaveMessage, {
            color: "red",
          })
          .then((result) => {
            if (result === true) {
              callback();
              this.onSetModelClean();
              return true;
            }
          });
      } else {
        callback();
      }
    },
    onDeleteStage(stageIndex) {
      this.$refs["confirmDeleteStage"]
        .open("Delete", this.deleteStageMessage, {
          color: "red",
        })
        .then((result) => {
          if (result === true) {
            this.deleteStage(stageIndex);
          }
        });
    },
    deleteStage(stageIndex) {
      if(this.form.stages[stageIndex].hasOwnProperty("id")) {
        this.$emit("startFormLoading");
        this.$set(this.form.stages[stageIndex], "isDeleting", true);
        this.$set(this.form.stages[stageIndex], "deleted", true);
        this.setOnboardingStage();
        const params = {
          id: this.form.id,
          stages: this.form.stages,
        };
        this.doSavePipeline(params).then((response) => {
          this.form = response.data || response;
          this.onUpdateActiveStageIndex(0);
          this.onSetModelClean();
          this.$emit("doneFormLoading");
          this.$bus.$emit("notificationMessage", `Deleted ${this.featureName("stage")} successfully!`);
        });
      } else {
        this.removeLocalActiveStage(stageIndex);
      }
    },
    removeLocalActiveStage(stageIndex) {
      this.$delete(this.form.stages, stageIndex);
      let deletedOnboardingIndex = this.form.stages.findIndex((stage) => stage.is_required_during_onboarding === true && stage.deleted === true);
      this.onUpdateActiveStageIndex(deletedOnboardingIndex === 0 ? 1 : 0);
    },
    onUpdateStats(submitted) {
      if(submitted.data.length) {
        this.form.stages[this.activeStageIndex].applications_count = submitted.total > this.currentStage.applications_count ? submitted.total : this.currentStage.applications_count;
        this.form.stages[this.activeStageIndex].submitted_count = submitted.total > this.currentStage.submitted_count ? submitted.total : this.currentStage.submitted_count;
        this.form.stages[this.activeStageIndex].outstanding_count = this.currentStage.applications_count - submitted.total;
      }
    },
  },
};
</script>

<style lang="scss">
#save-pipeline {
  .left-panel {
    overflow: hidden auto; 
    height: calc(100vh - 56px);
  }
  .right-panel {
    width: calc(100vw - 354px); 
    height: calc(100vh - 56px);
    overflow: hidden auto; 
  }
}
</style>