<template>
  <div>
    <VList
      v-if="hasAnyFiles"
      two-line
      subheader
    >
      <VListItem
        v-for="(item, index) in files"
        :key="index"
      >
        <VListItemAvatar
          v-if="item.type && isImageType(item.type)"
          @click.prevent="doPreviewFiles(item)"
        >
          <img
            :id="`img-${index}-${uid}`"
            :src="getMediaFromFileObject(item, `#img-${index}-${uid}`)"
          >
        </VListItemAvatar>
        <VListItemAvatar v-else>
          <VBtn
            icon
            ripple
            target="_blank"
            :href="item.download_url || item.url"
          >
            <VIcon>
              {{ getIcon(item.type) }}
            </VIcon>
          </VBtn>
        </VListItemAvatar>
        <VListItemContent>
          <VListItemTitle>
            <a
              target="_blank"
              :title="item.name"
              :href="item.url"
            >
              {{ item.name }}
            </a>
          </VListItemTitle>
          <VListItemSubtitle>
            {{ getFileSize(item.size) }} From:
            <strong>{{ getFileOrigin(item) }}</strong>
            <VAlert
              v-if="!item.id && item.size > fileMaxSize"
              dense
              type="error"
              class="mt-1"
              :value="true"
            >
              {{
                `${fileMaxSizeHint} Your file is ${getFileSize(
                  item.size - fileMaxSize
                )} over the limit.`
              }}
            </VAlert>
          </VListItemSubtitle>
        </VListItemContent>
        <VListItemAction v-if="!readonly">
          <VBtn
            icon
            ripple
            :loading="item.isDeleting"
            @click="onRemoveFile(index, item.id)"
          >
            <VIcon color="grey">
              {{ item.id ? "trash" : "close" }}
            </VIcon>
          </VBtn>
          <VBtn
            v-if="item.id"
            icon
            ripple
            :loading="item.isDeleting"
            @click.prevent="onRemoveFile(index)"
          >
            <VIcon color="grey">
              delete
            </VIcon>
          </VBtn>
        </VListItemAction>
        <VListItemAction v-else>
          <VBtn
            icon
            ripple
            target="_blank"
            :href="item.download_url || item.url"
          >
            <VIcon color="grey">
              save_alt
            </VIcon>
          </VBtn>
        </VListItemAction>
      </VListItem>
    </VList>
    <Preview
      @toggle="onTogglePreview"
      :files="previewFiles"
      v-if="dialogs.preview"
    />
  </div>
</template>
<script>
import DownloadsFiles from "@/mixins/DownloadsFiles";
import Preview from "@/components/Elements/Dialogs/Preview";

export default {
  name: "FilesList",
  mixins: [DownloadsFiles],
  components: { Preview },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.filename = this.value;
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}
a {
  white-space: break-spaces;
}
</style>
