<script>
export default {
  props: {
    programs: {
      type: [Object, Array],
      default: () => {},
    },
    lessons: {
      type: [Object, Array],
      default: () => {},
    },
    deliverables: {
      type: [Object, Array],
      default: () => {},
    },
    contents: {
      type: [Object, Array],
      default: () => {},
    },
    resources: {
      type: [Object, Array],
      default: () => {},
    },
    faqs: {
      type: [Object, Array],
      default: () => {},
    },
    canGet: {
      type: Boolean,
      default: true,
    },
    hideTitleWithBack: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.canGetModel = this.canGet;
  },
  computed: {
    prepareCustomOptions() {
      // Filter the options according to Mentor permissions
      if(this.hasUserMentorInPrograms()) {
        this.$set(this.options, "programs", this.filterProgramsHavePermission(this.modelType.toLowerCase(), this.options.programs));
      }
      return this.options;
    },
  },
  mounted() {
    this.form["programs"] = this.prepareData("programs");
    this.form["lessons"] = this.prepareData("lessons");
    this.form["deliverables"] = this.prepareData("deliverables");
    this.form["contents"] = this.prepareData("contents");
    this.form["resources"] = this.prepareData("resources");
  },
  methods: {
    prepareData(featureName) {
      return this.$route.params[featureName] || this[featureName] || this.form[featureName];
    },
  },
};
</script>