import { Api as BaseApi } from "./assessment";
/**
 * Worksheet API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Worksheet",
	path: "worksheets"
});

export default api;
