import api from "../../services/notificationPreference";
export default function (helper, moduleApi) {
    // Assessment types
    const TYPES = {
        STORE_SAVE: `STORE_SAVE`,
    };
    const realApi = moduleApi || api;

    /**
     * Generated store module
     */
    helper.setApi(realApi);
    const types = helper.types("notificationPreference");
    const state = helper.state();
    const getters = helper.getters();
    const actions = helper.actions({}, 'Notification Preference');
    const mutations = helper.mutations({}, types, state);

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations,
    };
}
