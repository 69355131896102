var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.previewUsers.length > 0)?_c('VTooltip',{attrs:{"top":"","disabled":_vm.hideImage},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('VChip',_vm._g(_vm._b({staticClass:"pa-0",style:({
        ..._vm.customStyle
      }),attrs:{"color":"transparent","href":_vm.href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('showDetails')}}},'VChip',attrs,false),on),[_vm._l((_vm.users),function(item,index){return _c('VAvatar',{key:index,class:{
          'ml-n4': index > 0
        },style:({
          border: _vm.hideImage ? 'solid 1.5px rgba(47, 53, 63, 0.75)' : 'solid 1px #ffffff',
          'background-color': _vm.hideImage ? 'rgba(236, 236, 241)' : 'none',
        }),attrs:{"size":_vm.size}},[(!_vm.hideImage)?_c('VImg',{attrs:{"src":item.photo_url}}):_vm._e()],1)}),(!_vm.hideImage && (_vm.totalUsers > _vm.maxPreview))?_c('VChip',{staticClass:"ml-n4",style:({
          height: _vm.size, 
          color: _vm.textColor + '!important',
          border: 'solid 1px #ffffff !important',
          cursor: 'pointer'
        }),attrs:{"color":_vm.color}},[_c('strong',[_vm._v("+"+_vm._s(_vm.totalUsers - _vm.maxPreview))])]):_vm._e()],2)]}}],null,false,2724320141)},[_c('span',[_vm._v(_vm._s(_vm.totalUsers)+" "+_vm._s(_vm.tooltipText))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }