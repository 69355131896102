import Vue from 'vue';
import GoogleAuth from 'vue-google-oauth2';
import dotenv from 'dotenv';
dotenv.config();

// Vue.use(GoogleAuth, {
//     clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
//     scope: "https://www.googleapis.com/auth/drive.readonly"
// });

export default {}