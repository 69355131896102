<template>
  <VRow
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      cols="12"
      class="d-flex align-center"
    >
      <TextField
        v-model="form.name"
        filled
        placeholder="Enter title here"
        class="title-field textarea-field bg-textfield"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";

export default {
  name: "SaveCardText",
  mixins: [SaveCardMixin]
};
</script>

<style lang="scss">
.title-field fieldset {
  border: none !important;
}
</style>
