<template>
  <div>
    <VBtn
      x-small
      width="40px"
      height="40px"
      class="mr-2 px-0 bg-white"
      @click="isDialogOpen = !isDialogOpen"
    >
      <VIcon style="font-size: 28px;">
        mdi-magnify
      </VIcon>
    </VBtn>
    <VDialog
      v-model="isDialogOpen"
      max-width="530px"
      content-class="search-dialog"
    >
      <VCard
        flat
        class="rounded"
      >
        <VAutocomplete
          flat
          autofocus
          clearable
          hide-details
          return-object
          item-text="Description"
          item-value="API"
          prepend-inner-icon="mdi-magnify"
          placeholder="Start typing to search"
          append-icon=""
          :items="entries"
          :loading="isLoading"
          :search-input.sync="search"
        >
          <template #item="data">
            <VList width="490px">
              <VListItem
                two-line
                :href="data.item.Link"
              >
                <VListItemContent>
                  <VListItemTitle>{{ data.item.Category }}</VListItemTitle>
                  <VListItemSubtitle>{{ data.item.Description }}</VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </template>
        </VAutocomplete>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
export default {
  name: "GlobalSearch",
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      search: null,
    };
  },
  watch: {
    search(val) {
      if (val.length === 0) {
        this.entries = [];
      } else {
        this.isLoading = true;

        // Lazily load input items
        fetch("https://api.publicapis.org/entries")
          .then((res) => res.json())
          .then((res) => {
            const { count, entries } = res;
            this.count = count;
            this.entries = entries;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
  created() {
    if(this.isEnabledFeature('global_search')) {
      window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.code == "KeyP") {
          e.preventDefault();
          this.isDialogOpen = !this.isDialogOpen;
        }
      });
    }
  },
};
</script>

<style lang="scss">
.search-dialog {
  top: 65px;
  position: fixed;
  box-shadow: none !important;
  overflow: hidden;

  .v-input__prepend-inner {
    padding-left: 16px;
  }
  .v-input__append-inner {
    padding-right: 16px;
  }
  .v-select__slot {
    padding-bottom: 8px;
  }
}
</style>
