<script>
import SmallTeamBanner from "@/components/Elements/Navigation/SmallTeamBanner";
import LargeTeamBanner from "@/components/Elements/Navigation/LargeTeamBanner";
import weThriveLogo from "@/images/WeThrive-Logo.png";
import { mapActions } from "vuex";
export default {
  components: {
    SmallTeamBanner,
    LargeTeamBanner,
  },
  data() {
    return {
      /**
       * This teamSearchMode is used by the NeedsTeams mixin in order to determine how to handle errors
       */
      teamSearchMode: "searching",
      currentTeam: null,
      teamSlug: null,
      weThriveLogo,
      isRegistrationDisabled: false,
      isSearchingTeam: false,
      userWasCurrentTeamMember: false,
      selectedPipeline: null,
      requiredStageIndex: 0,
      hasPipelineFromLogic: false,
      findTeamScope: "login",
    };
  },
  computed: {
    currentTeamName() {
      return this.teamExists ? this.currentTeam.name || "" : "";
    },
    currentTeamSlug() {
      return this.teamExists ? this.currentTeam.slug || "" : "";
    },
    currentTeamProfile() {
      return this.teamExists ? this.currentTeam.profile || {} : {};
    },
    currentTeamPhoto() {
      return this.teamExists
        ? this.currentTeam.photo_url || this.weThriveLogo
        : this.weThriveLogo;
    },
    hasTeamFromDomainOrPath() {
      return (this.getTeamFromDomain() || this.getTeamFromPath()) != null;
    },
    teamExists() {
      return this.currentTeam instanceof Object && this.currentTeam.id != null;
    },
    hasOnboardingForm() {
      return (
        this.teamExists &&
        this.currentTeam.onboarding_form instanceof Array &&
        this.currentTeam.onboarding_form.length > 0
      );
    },
    hasPipeline() {
      return (
        this.teamExists &&
        this.currentTeam.application_pipeline instanceof Object &&
        this.currentTeam.application_pipeline.id > 0
      );
    },
    isPipelineExpired() {
      return this.hasSelectedPipeline && this.selectedPipeline.is_expired;
    },
    pipelineDeadline() {
      const dueOn = this.hasSelectedPipeline && this.selectedPipeline.due_on;
      return dueOn
        ? this.$timezone.formatTzDateTime(
            dueOn,
            this.currentTeam.date_format,
            this.currentTeam.time_format,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
        : null;
    },
    hasPipelineDeadline() {
      return (
        this.hasSelectedPipeline === true &&
        this.selectedPipeline.due_on !== null
      );
    },
    isPipelineStageExpired() {
      return (
        (this.selectedPipelineOnboardingStage || {}).is_expired ||
        this.isPipelineExpired
      );
    },
    pipelineStageDeadline() {
      const dueOn =
        this.hasPipelineStageDeadline &&
        this.selectedPipelineOnboardingStage.due_on;
      return dueOn
        ? this.$timezone.formatTzDateTime(
            dueOn,
            this.currentTeam.date_format,
            this.currentTeam.time_format,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
        : this.pipelineDeadline;
    },
    hasPipelineStageDeadline() {
      return (
        this.selectedPipelineHasRequiredStage === true &&
        this.selectedPipelineOnboardingStage.due_on != null
      );
    },
    pipelineAlertType() {
      if (this.hasSubmittedApplication) {
        if (this.submittedApplication.is_complete) {
          return "success";
        }
        if (this.selectedPipeline.is_expired) {
          return "warning";
        }
        return "info";
      }
    },
    hasSelectedPipeline() {
      return (
        this.selectedPipeline instanceof Object && this.selectedPipeline.id > 0
      );
    },
    selectedPipelineHasStages() {
      return (
        this.hasSelectedPipeline &&
        this.selectedPipeline.stages instanceof Array &&
        this.selectedPipeline.stages.length > 0
      );
    },
    selectedPipelineHasRequiredStage() {
      return (
        this.hasSelectedPipeline &&
        this.selectedPipeline.onboarding_stage instanceof Object
      );
    },
    selectedPipelineOnboardingStage() {
      if (
        this.selectedPipeline instanceof Object &&
        this.selectedPipeline.onboarding_stage instanceof Object
      ) {
        return this.selectedPipeline.onboarding_stage || {};
      }
      return this.selectedPipelineHasStages
        ? this.selectedPipeline[this.requiredStageIndex || 0]
        : {};
    },
    pipelineSections() {
      return Object.keys(this.pipelineSectionsData);
    },
    pipelineSectionsData() {
      let metadata = [];
      if (this.selectedPipelineHasRequiredStage) {
        metadata = this.selectedPipeline.onboarding_stage.metadata;
      } else if (this.selectedPipelineHasStages) {
        metadata = this.selectedPipeline.stages[this.requiredStageIndex]
          .metadata;
      }
      return (metadata || [])
        .filter((input) => input instanceof Object)
        .groupBy((input) => input.section);
    },
    hasRegisterLogic() {
      return (
        this.teamExists &&
        this.currentTeam.register_logic instanceof Array &&
        this.currentTeam.register_logic.length > 0
      );
    },
    registerLogicMessage() {
      const defaultMessage = {
        name: "Which application are you applying for?",
      };
      return this.hasRegisterLogic
        ? this.currentTeam.register_logic_message || defaultMessage
        : defaultMessage;
    },
    userIsCurrentTeamMember() {
      return (
        this.currentTeam &&
        this.$user.teams instanceof Array &&
        this.$user.teams.findIndex((team) => team.id == this.currentTeam.id) >
          -1
      );
    },
    canRegister() {
      return (
        this.teamExists &&
        !this.isRegistrationDisabled &&
        this.currentTeam.open_registration
      );
    },
  },
  created() {
    // Only enable this auto lookup for non users
    // if (!this.$auth.check()) {
    let slug = this.getTeamFromDomain() || this.getTeamFromPath();
    this.$log.debug("Team slug is ", slug);
    if (slug) {
      this.teamSlug = slug.slugify();
      this.findTeam();
    }
    // }
    this.userWasCurrentTeamMember = this.userIsCurrentTeamMember;
  },
  methods: {
    ...mapActions({
      doTeamExists: "team/teamExists",
    }),
    findTeam() {
      this.isRegistrationDisabled = false;
      this.error = this.errors = null;
      this.isSearchingTeam = true;
      if (this.teamSlug && this.teamSlug.length >= 3) {
        return this.doTeamExists({
          s: this.teamSlug,
          scope: this.findTeamScope,
        })
          .then((team) => {
            this.isSearchingTeam = false;
            if (!(team instanceof Object)) {
              if (this.teamSearchMode === "searching") {
                this.errors = ["Team not found"];
              }
            } else {
              this.$team = this.currentTeam = team;
              this.$nextTick(() => {
                this.selectedPipeline = this.hasRegisterLogic
                  ? this.selectedPipeline
                  : this.currentTeam.application_pipeline;
              });
            }
            return this.currentTeam;
          })
          .catch((error) => {
            this.isSearchingTeam = false;
            this.$log.debug("RegisterForm: ", error);
            if (this.teamSearchMode === "searching") {
              this.error = "Couldn't find this team";
            }
            return null;
          });
      } else {
        this.isSearchingTeam = false;
        return Promise.resolve(null);
      }
    },
    getTeamFromDomain() {
      const parts = window.location.hostname
        .split(".")
        .filter((part) => part.length);
      const team = parts.length >= 3 ? parts[0] : null;
      return [
        "register",
        "login",
        "www",
        "wethrive",
        "wethrive-staging",
        "wethrive-beta",
        "beta",
        "staging",
        "app",
        "account",
        "settings",
      ].indexOf(team) === -1
        ? team
        : null;
    },
    getTeamFromPath() {
      if (this.$route.params.team) {
        return this.$route.params.team;
      }
      const parts = window.location.pathname
        .split("/")
        .filter((part) => part.length);
      const team = parts[0];
      return ["register", "login", "app", "account", "settings"].indexOf(
        team
      ) === -1
        ? team
        : null;
    },
    setPipelineFromLogic(logic) {
      // this.$log.info("Setting pipeline from logic to", logic);
      this.selectedPipeline = logic.pipeline;
      /**
       * Updating this flag on next window update so that we give the app enough time to set the pipeline before calculating anything based on pipeline logic.
       * Needed for applications with many fields
       */
      this.$nextTick(() => {
        this.hasPipelineFromLogic = true;
      });
    },
  },
};
</script>

<style>
.success {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.warning {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
</style>