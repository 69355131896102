<script>
export default {
  props: {
    modelId: {
      type: Number,
      default: null,
    },
    existing: {
      type: Object,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    property: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: 0,
      itemsPerPage: 1,
      submittedPagination: {
        rowsPerPage: 1,
      },
    };
  },
  computed: {
    current() {
      return this.items[this.currentIndex] || {};
    },
  },
  created() {
    // this.doGetFormConfig(this.deliverable.id).then(result => this.options = result.data.data);
    this.setForm();
  },
  methods: {
    next() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
        this.setForm();
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.setForm();
      }
    },
    setCurrentIndex(page) {
      this.currentIndex = page.page - 1;
    },
    setForm() {
      if (this.existing instanceof Object) {
        this.form = this.existing;
      } else {
        this.form = this.cloneObject(this.defaultForm, true);
        this.form[this.property] = this.modelId;
        this.form.metadata = this.current.metadata;
        this.form.metadata = this.uniqueMetadata(this.form.metadata);
      }
    },
    onSaveAndReset() {
      this.onSave().then((result) => {
        this.resetForm();
        this.prepareNewSubmission();
        this.wasCreateMode = false;
      });
    },
    prepareNewSubmission() {},
    uniqueMetadata(metadata) {
      if (metadata instanceof Array) {
        return metadata
          .sortBy("id")
          .unique((m) => m.linked_metadata_id)
          .sortBy("priority");
      }
      return metadata;
    },
    metadataHasRequiredUrl(model) {
      switch (model.type) {
        case "File":
        case "Google Drive":
        case "Image":
          let isEmpty = !(model.value instanceof File);
          if (isEmpty) {
            isEmpty =
              model.value instanceof Object
                ? (model.value.url || "").length === 0
                : true;
          }
          return model.is_required === true && isEmpty;
          break;

        default:
          return false;
          break;
      }
    },
  },
};
</script>