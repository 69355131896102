import api from '../../services/users';
export default function (helper) {

  // User types
  const TYPES = {
    USERS_APPROVE_USER: 'USERS_APPROVE_USER',
    USERS_REMOVE_USER: 'STORE_DELETE',
    USERS_IMPORT: `USERS_IMPORT`,
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('users');
  const state = helper.state();
  const getters = helper.getters();
  const actions = helper.actions({
    deleteUser(context, user) {
      helper.log().info("[Store: Users]: Delete user");
      return helper
        .api()
        .deleteUser(user)
        .then((response) => {
          helper
            .log()
            .info(`[Store: Users]: Delete user`, response);
          const data = response.data.data;
          return data;
        })
        .catch((error) => {
          helper
            .log()
            .info(`[Store: Users]: Error Delete user`, error);
          throw error;
        });
    },
    deleteUserParmanently(context, user) {
      helper.log().info("[Store: Users]: Delete user parmanently");
      return helper
        .api()
        .deleteUserParmanently(user)
        .then((response) => {
          helper
            .log()
            .info(`[Store: Users]: Delete user parmanently`, response);
          const data = response.data.data;
          return data;
        })
        .catch((error) => {
          helper
            .log()
            .info(`[Store: Users]: Error Delete user parmanently`, error);
          throw error;
        });
    },
  }, 'User');
  const mutations = helper.mutations({
    [TYPES.USERS_APPROVE_USER](state, data) {
      helper.utils.updateStateData(state, data);
      return data;
    },
    [TYPES.USERS_IMPORT](state, data) {
      state.imported = data;
    }
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}