<template>
  <VCard class="current-rsvp-search-container">
    <VCardText style="padding: 0px">
      <TextField
        v-if="!readonly"
        text:label="`${label} ...`"
        solo-inverted
        class="search-rsvps"
        v-model="filter"
      >
        <img
          slot="append"
          style="cursor: pointer"
          src="@/images/search-icon.png"
        >
      </TextField>
      <slot name="info" />
      <VList
        v-if="hasRsvps"
        two-line
      >
        <template v-for="(rsvp, index) in localRsvps">
          <slot
            name="rsvp"
            :rsvp="rsvp"
          >
            <VListItem
              :key="index"
              @click="false"
            >
              <VListItemAvatar>
                <img :src="rsvp.user.photo_url">
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle
                  :class="{
                    'pl-3': mode === 'shift'
                  }"
                >
                  <template v-if="$user.id !== rsvp.user_id">
                    {{ rsvp.user.name }}
                  </template>
                  <template v-else>
                    <strong>You</strong>
                  </template>
                </VListItemTitle>
                <VListItemSubtitle v-if="mode === 'shift'">
                  <template v-if="!readonly && !disableActions">
                    <VBtn
                      v-show="canAccept(rsvp)"
                      depressed
                      outlined
                      @click="$emit('acceptRsvp', rsvp)"
                    >
                      <VIcon>check_circle</VIcon>&nbsp;Accept
                    </VBtn>
                    <VBtn
                      v-show="canReject(rsvp)"
                      depressed
                      outlined
                      @click="$emit('declineRsvp', rsvp)"
                    >
                      <VIcon>cancel</VIcon>&nbsp;Decline
                    </VBtn>
                  </template>
                  <VChip
                    color="success"
                    text-color="white"
                    v-if="rsvp.is_accepted"
                  >
                    Accepted: {{ $timezone.formatDateTime(rsvp.accepted_on) }}
                  </VChip>
                  <VChip
                    color="warning"
                    text-color="white"
                    v-if="rsvp.is_rejected"
                  >
                    Declined: {{ $timezone.formatDateTime(rsvp.rejected_on) }}
                  </VChip>
                  <VChip v-if="!rsvp.is_rejected && !rsvp.is_accepted">
                    Pending
                  </VChip>
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <template>
                  <VChip
                    small
                    v-if="rsvp.calendar_entry.rsvp_mode === 'standard'"
                    class="mr-0"
                  >
                    {{ (rsvp.status || 'Pending').titleize() }}
                  </VChip>
                  <VIcon
                    v-else
                    class="mr-0"
                    :color="(!rsvp.is_rejected && !rsvp.is_accepted) ? 'default' : (rsvp.is_accepted ? 'success' : 'error')"
                  >
                    <template v-if="!rsvp.is_rejected && !rsvp.is_accepted">
                      --
                    </template>
                    <template v-else>
                      {{ rsvp.is_accepted ? 'check_circle' : (rsvp.is_rejected ? 'block' : '') }}
                    </template>
                  </VIcon>
                </template>
                <VBtn
                  v-if="!readonly"
                  icon
                  text
                  small
                  pr-2
                  @click="$emit('removeMember', rsvp)"
                >
                  <VIcon>close</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
            <VDivider
              v-if="index + 1 < localRsvps.length"
              style="flex: 1 1 100%"
              :key="`divider-${index}`"
            />
          </slot>
        </template>
      </VList>
      <VAlert
        v-else
        :value="true"
        type="info"
      >
        No RSVPs
      </VAlert>
    </VCardText>
  </VCard>
</template>

<script>
/**
 * TODO: Fix accept/reject issues
 */
export default {
  name: "RSVPList",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Find an rsvp",
    },
    mode: {
      type: String,
      default: "standard",
    },
    rsvps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filter: "",
    };
  },
  computed: {
    hasRsvps() {
      return this.localRsvps.length > 0;
    },
    localRsvps() {
      return this.rsvps.filter((rsvp) => {
        if (this.filter && this.filter.length > 1) {
          const nameMatch = rsvp.user.name.match(new RegExp(this.filter, "ig"));
          const emailMatch = rsvp.user.email.match(
            new RegExp(this.filter, "ig")
          );
          const acceptedMatch =
            (rsvp.is_accepted || false) === (this.filter === "accepted");
          const rejectedMatch =
            (rsvp.is_rejected || false) === (this.filter === "rejected");
          return (
            (nameMatch && nameMatch.length > 0) ||
            (emailMatch && emailMatch.length > 0) ||
            (acceptedMatch && acceptedMatch.length > 0) ||
            (rejectedMatch && rejectedMatch.length > 0)
          );
        }
        return true;
      });
    },
  },
  methods: {
    canReject(rsvp) {
      if (!rsvp) return false;
      const canReject =
        (rsvp.is_rejected === false && rsvp.is_accepted === true) ||
        (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
        rsvp.is_accepted === undefined ||
        rsvp.is_accepted === null;
      return canReject;
    },
    canAccept(rsvp) {
      if (!rsvp) return false;
      const canAccept =
        (rsvp.is_accepted === false && rsvp.is_rejected === true) ||
        (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
        rsvp.is_rejected === undefined ||
        rsvp.is_rejected === null;
      return canAccept;
    },
  },
};
</script>
