<script>
import Vue from "vue";
import get from "sugar/object/get";

export default {
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sections: {
      type: [Object, Array],
      default: () => null,
    },
  },
  data() {
    return {
      inputWaitTimeout: {},
      allSections: {},
      isAllSectionsFirstInit: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.initAllSections(this.sections, true);
      this.prepareAllSections(this.inputSections, true);
    }, 1000);
  },
  watch: {
    inputSectionsLength: function (sectionLength, oldSectionLength) {
      this.$log.info("Section length changed", sections);
      this.prepareAllSections(this.inputSections);
    },
    sections: {
      handler: function (sections) {
        this.$log.info("Sections", sections);
        this.initAllSections(this.sections, true);
      },
      deep: true,
    },
    "sections.length": function (length, oldSections) {
      this.$log.info("Sections", this.sections);
      this.initAllSections(this.sections, true);
    },
  },
  methods: {
    initAllSections(sections, force = false) {
      if (
        (sections instanceof Object &&
          Object.keys(sections).length &&
          !Object.keys(this.allSections).length) ||
        this.isAllSectionsFirstInit ||
        force === true
      ) {
        this.$log.info("Initing sections - 1", sections, this.allSections);
        if(sections instanceof Array) {
          this.allSections = {};
          if(sections.length) {
            sections.forEach(section => {
              this.allSections[section.title] = section;
            });
          }
        } else {
          this.allSections = sections;
        }
        this.$log.info("Initing sections - 2", sections, this.allSections);
        this.isAllSectionsFirstInit = false;
        this.$forceUpdate();
      }
    },
    prepareAllSections(sections) {
      this.$log.info("Preparing sections", sections);
      if (sections instanceof Array) {
        for (const section of sections) {
          if (
            section != "null" &&
            this.allSections instanceof Object &&
            !this.allSections.hasOwnProperty(section)
          ) {
            const existing =
              this.sections instanceof Object
                ? Object.values(this.sections).find((s) => s.title == section)
                : null;
            this.allSections[section] = existing || {
              title: section,
              description: "",
            };
          }
        }
      }
    },
    updateValidation() {
      this.$emit("validated", false);
    },
    onUpdateSection(input, value) {
      if (
        (value || input.section) &&
        value != "null" &&
        value != "" &&
        input.section != "null" &&
        input.section != ""
      ) {
        this.$log.info("Updating Section", input, value, this.allSections);
        const realSection = value || input.section;
        if (realSection && !this.allSections.hasOwnProperty(realSection)) {
          this.allSections[realSection] = {
            title: realSection,
            description: "",
          };
        } else {
          this.allSections[realSection].title = realSection;
        }
        this.onUpdateSections();
      }
    },
    onUpdateSections(sections) {
      this.$emit("input:sections", Object.values(sections || this.allSections));
      this.onUpdateValue();
    },
    onUpdateValue(value) {
      this.$emit("input", value || this.value);
    },
    onUpdateSingleInput(index, input, timeout) {
      if (this.isSingleInputMode) {
        this.onUpdateValue(input);
      } else {
        const realInput = index instanceof Object ? index : input;
        const key = `${realInput.type}-${realInput.section}-${
          realInput.id || realInput.realIndex
        }`;
        this.$log.debug("Updating", key);
        this.debouncer(
          () => {
            this.$emit("update:input", index, input || index);
          },
          key,
          timeout
        );
      }
    },
    onAddRemoteFile(file, index) {
      this.onAddLocalFile(file, index);
    },
    onRemoveRemoteFile(file, index) {
      this.onRemoveLocalFile(file, index);
    },
    onAddLocalFile(file, index) {
      let input = this.isSingleInputMode ? this.value : this.value[index];
      input.value = file;
      this.onUpdateInput(index, input);
    },
    onRemoveLocalFile(file, index, resetRef) {
      let input = this.isSingleInputMode ? this.value : this.value[index];
      if (input) {
        input.value = { url: null };
        this.onUpdateInput(index, input);
      }
      if (resetRef && this.$refs.hasOwnProperty(resetRef)) {
        let ref = this.$refs[resetRef];
        ref = ref instanceof Array ? ref[0] : ref;
        this.$log.debug("[DynamicInputsFormMixin]: Removing ", resetRef, ref);
        ref.onReset();
      }
    },
    async onNewInput(value, index) {
      this.$log.debug(
        "[DynamicInputsFormMixin]: Adding ",
        value,
        " to ",
        this.value,
        "at index",
        index
      );
      if (this.isSingleInputMode) {
        Vue.set(value, "priority", 0);
        Vue.set(value, "realIndex", 0);
        this.$emit("input", value);
        return 0;
      } else {
        let destination = get(this.value, index);
        if (!(destination instanceof Array) && destination) {
          destination = [];
          this.value && Vue.set(this.value, index, destination);
        }
        const hasUserProvidedIndex =
          index !== undefined && parseInt(index) > -1;
        const insertedAt = index + 1;

        if (hasUserProvidedIndex) {
          this.value.splice(insertedAt, 0, value);
        } else {
          this.value.push(value);
        }
        if (value instanceof Object) {
          Vue.set(value, "priority", index || destination ? destination.length - 1 : value.priority);
        }
        if(this.newFieldsView || this.listView) {
          this.onUpdateSection(value, this.defaultSectionTitle);
        }

        return hasUserProvidedIndex ? index + 1 : destination ? destination.length - 1 : 0;
      }
    },
    async onRemoveInput(key) {
      if (key instanceof Object && key.id) {
        return this.onDeleteInput(key);
      }

      const index = this.findInputIndex(key);
      if (index > -1) {
        this.$log.debug(
          "[DynamicInputsFormMixin]: Removing ",
          key,
          "at index",
          index
        );
        if (!this.isSingleInputMode) {
          Vue.delete(this.value, index);
        } else {
          this.$emit("input", null);
        }
      }
      // Added this condition to avoid multiple input remove at same index
      if (this.isSingleInputMode) {
        this.$emit("remove:input", index);
      }
      return index;
    },
    async onRemoveMultipleInputs(group) {
      const result = [];
      if (group && group instanceof Array && group.length) {
        const toRemove = group.filter((input) => input.id === undefined);
        if (toRemove.length) {
          const realIndexes = toRemove.map((input) => input.realIndex);
          this.updateInput(
            this.value.filter(
              (input) => realIndexes.indexOf(input.realIndex) !== -1
            )
          );
        }
      }
      return result;
    },
    async onDeleteInput(key) {
      if (this.isSingleInputMode) {
        this.$emit("remove:input", key);
        return 0;
      }
      if (key instanceof Object && key.deleted) {
        return this.onRestoreInput(key);
      }

      const index = this.findInputIndex(key);
      if (index > -1) {
        this.$log.debug(
          "[DynamicInputsFormMixin]: Deleting ",
          key,
          "at index",
          index
        );
        if (!this.isSingleInputMode) {
          Vue.set(this.value[index], `deleted`, true);
          this.onUpdateSingleInput(index, this.value[index]);
        } else {
          this.$emit("input", null);
        }
      }
      return index;
    },
    async onRestoreInput(key) {
      const index = this.findInputIndex(key);
      if (index > -1) {
        this.$log.debug(
          "[DynamicInputsFormMixin]: Restoring ",
          key,
          "at index",
          index
        );
        if (!this.isSingleInputMode) {
          Vue.delete(this.value[index], `deleted`);
        } else {
          Vue.delete(this.value, `deleted`);
        }
        this.onUpdateSingleInput(index, this.value[index]);
      }
      return index;
    },
    async onUpdateMultipleInput(group) {
      const result = [];
      if (group && group instanceof Array && group.length) {
        group.map((input) => {
          if (input instanceof Object || input) {
            result.push(
              this.onUpdateInput(
                input instanceof Object
                  ? input.realIndex || input.priority
                  : null,
                input
              )
            );
          }
        });
      }
      return result;
    },
    async onUpdateInput(key, value) {
      console.log(key, value);
      const index = this.findInputIndex(
        value instanceof Object && value.id ? value : key
      );
      if (index > -1) {
        this.$log.debug(
          "[DynamicInputsFormMixin]: Updating ",
          index,
          "with",
          value,
          this.isSingleInputMode ? this.value : this.value[index]
        );
        if (!this.isSingleInputMode) {
          Vue.set(this.value, index, {
            ...this.value[index],
            ...value,
          });
        } else {
          this.$emit("input", value);
        }
        this.onUpdateSingleInput(index, value);
      }
      return index;
    },
    findInput(key) {
      return this.isSingleInputMode ? this.value : get(this.value, key);
    },
    findInputIndex(key) {
      const realKey = key instanceof Object ? key.id || key.realIndex : key;
      this.$log.debug("[DynamicInputsFormMixin]: Searching for", realKey);
      return this.value instanceof Array
        ? this.value.findIndex((v, k) => {
            return v instanceof Object &&
              key instanceof Object &&
              v.hasOwnProperty("id")
              ? v.id === realKey
              : k === realKey;
          })
        : 0;
    },
    onUpdateSingleAsyncInput(input, value) {
      const asyncOption = this.getAsyncOptions(input);
      const setValue = asyncOption.setValue;
      if(input.id) {
        input.id = typeof input.id === "string" ? parseInt(input.id) : input.id;
      }
      this.$log.debug("Updating async input", input, value, setValue);
      if (setValue instanceof Function) {
        setValue.call(this, [input, value, asyncOption]);
      } else {
        this.onSetAsyncInputValue(input, value, asyncOption);
      }
      this.$log.debug("Updated async input", input);
      this.onUpdateSingleInput(input);
    },
    hasAsyncOptions(input) {
      const realInput = input || {};
      const index = parseInt(realInput.realIndex || realInput.priority) || 0;
      return (
        this.asyncInputs.options instanceof Array &&
        this.asyncInputs.options[index] instanceof Array
      );
    },
    onSetAsyncInputValue(input, value, option) {
      const inputOptions = {
        asyncKey: (option || {}).asyncKey,
        asyncType: (option || {}).asyncType,
      };
      const inputValue = {
        id: value.id,
        type: inputOptions.asyncType,
        title:
          value instanceof Object
            ? value[((option || {}).options || {}).itemText || "text"]
            : value,
      };
      this.$log.debug(
        "Setting async input value",
        input,
        inputValue,
        inputOptions
      );
      if (this.isSingleInputMode) {
        this.$set(this.value, "value", inputValue);
        this.$set(this.value, "options", inputOptions);
      } else {
        this.$set(input, "value", inputValue);
        this.$set(input, "options", inputOptions);
      }
    },
    isAsyncInputLoading(index) {
      return this.asyncInputs.loading[parseInt(index) || 0] === true;
    },
    onSetInputProperty(input, value) {
      this.$log.debug(
        "[DynamicInputsFormMixin]: Setting metadata property",
        input,
        value
      );
      Vue.set(this.value, field, value);
    },
    onUpdateInputProperty(input, property, value, timeout) {
      if(this.newFieldsView) {
        Vue.set(input, property, value);
        this.$emit("update:input", input.realIndex, input);
      } else {
        const fn = () => {
          this.$log.debug(
            "[DynamicInputsFormMixin]: Updating metadata property",
            input,
            value
          );
          Vue.set(input, property, value);
          this.onUpdateSingleInput(input.id ? input : input.realIndex, input, 0);
        };
  
        // Make sure we can have multiple timeouts going to not prevent other debounced updates
        const key = `${input.type}-${input.section}-${
          input.id || input.realIndex
        }`;
        this.debouncer(fn, key);
      }
    },
    onSetInput(index, value) {
      this.$log.debug("[DynamicInputsFormMixin]: Setting metadata", value);
      if (this.isSingleInputMode) {
        this.$emit("input", value);
      } else {
        Vue.set(this.value, index, value);
      }
    },
    allUndeletedInput(value) {
      return this.isSingleInputMode
        ? this.value || []
        : this.value.filter((v) => v.deleted !== true);
    },
    /**
     * We're using dot notation to access nested properties using SugarJS Object
     */
    getInputKey(property) {
      const index = ["value"].concat(property ? property.split(".") : []);
      return index.join(".");
    },
    getDeleteIcon(input) {
      return input.deleted ? "refresh" : "delete";
    },
    getDeleteText(input) {
      return input.deleted ? "Restore" : "Delete";
    },
    getInputForSubmit(allInputs) {
      return allInputs instanceof Array
        ? allInputs.map((input, index) => {
            return {
              type: input.type,
              value: input.value,
              name: input.name,
              linked_metadata_id: input.linked_metadata_id,
              priority: input.prority || index,
              options: input.options || {},
              is_required: input.is_required,
            };
          })
        : [];
    },
    prepareInput(metadata, index) {
      return {
        type: metadata.type,
        name: metadata.name,
        realIndex: index || metadata.priority,
        priority: index || metadata.priority,
        options: metadata.options,
        linked_metadata_id: metadata.id,
        value: null,
        is_required: metadata.is_required,
        section: metadata.section,
      };
    },
  },
};
</script>