<template>
  <VSkeletonLoader
    type="list-item-three-line"
    :loading="isLoading"
  >
    <h2
      v-if="title"
      v-html="title"
      :class="titleClass"
    />
    <Draggable
      v-model="reorderData"
      draggable=".dynamic-input-container"
      handle=".dynamic-input-handle"
      :no-transition-on-drag="true"
      @change="event => $emit('input', reorderData)"
    >
      <transition-group
        name="dynamic-input-container"
        type="transition"
        tag="div"
        role="list"
        class="v-list v-sheet theme--light"
        :css="true"
      >
        <VListItem
          v-for="(item, index) in reorderData"
          dense
          class="my-2 reorder-item dynamic-input-container dynamic-input-handle"
          :key="item.id"
        >
          <VListItemAction
            class="mr-2"
            style="min-width: auto;"
          >
            <img src="@/images/lessons/drag_2.png">
          </VListItemAction>
          <VListItemAction
            class="mr-0"
            style="min-width: auto;"
          >
            <VChip>{{ index + 1 }}</VChip>
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle>
              <span
                class="px-2"
                style="font-size: 14px;"
              >
                {{ item.title }}
              </span>
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </transition-group>
    </Draggable>
  </VSkeletonLoader>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  name: "ReorderData",
  props: {
    value: {
      type: Array,
      default: null,
    },
    reorderAction: {
      type: Function,
      default: null,
    },
    getItems: {
      type: Function,
      default: null,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: "title",
    },
    fromProgram: {
      type: Boolean,
      default: false,
    },
  },
  components: { Draggable },
  data() {
    return {
      isLoading: true,
      reorderData: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.reorderData = value;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.getItems && this.getItems instanceof Function) {
      this.isLoading = true;
      this.getItems.apply().then((result) => {
        this.isLoading = false;
        this.reorderData = this.fromProgram ? result.data : result.data.sortBy('priority');
        this.$emit('input', this.reorderData);
      });
    } else {
      this.reorderData = (this.value || []).sortBy('priority');
      this.isLoading = false;
      this.$emit('input', this.reorderData);
    }
  },
};
</script>
<style lang="scss" scoped>
.reorder-item {
  border: 0.5px solid rgb(234, 234, 234);
  box-shadow: rgb(234 234 234) -1.7px 4.7px 6.1px 0.4px !important;
  border-radius: 8px;
}
</style>