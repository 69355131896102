<template>
  <VSkeletonLoader
    justify-center
    type="image"
    class="fluid d-flex flex-column"
    :loading="isCompleting"
  >
    <VRow
      row
      wrap
      align-center
      fill-height
      class="text-center"
    >
      <VCol cols="12">
        <VIcon 
          color="success"
          style="font-size: 12rem; padding-top: 16px;"
        >
          check_circle
        </VIcon>
        <h2>Congratulations! You have completed this {{ featureName("lessons", "singularize") }}.</h2>
        <VBtn
          color="primary"
          class="mt-4"
          @click="navigateTo({
            name: 'lesson.index'
          })"
        >
          Return to {{ featureName("Lessons") }}
        </VBtn>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardFinal",
  mixins: [PresentationCardMixin],
  props: {
    card: {
      type: Object,
      default: () => {}
    },
    isCompleting: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$emit("complete");
  },
};
</script>