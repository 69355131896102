<template>
  <VRow
    row
    wrap
  >
    <VCol
      v-if="mode === 'standalone'"
      cols="12"
      style="flex: 0 0 64px"
    >
      <ViewHeader :route-params="{id: resolvedPipelineStageId, pipeline: resolvedPipelineId}">
        <template #title>
          {{ featureName('Submitted Applications') }}
          <template v-if="resolvedPipelineStage && resolvedPipelineStageId">
            &nbsp;for
            {{ featureName('Pipeline').singularize() }}
            {{ featureName('Stage') }}&nbsp;{{ resolvedPipelineStage.title || 'ERROR: No Title Set' }}
          </template>
        </template>
        <template #extra-buttons>
          <Linkable
            v-if="!$isUserStudent"
            icon="visibility"
            :tooltip="`View ${featureName('Pipeline').singularize()} ${featureName('Stage').singularize()}`"
            :copyable="false"
            :icon-props="{small: true}"
            :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, {id: resolvedPipelineStageId, pipeline: resolvedPipelineId})"
            :to="getRouteForItem(`${modelTypeRoute}.view`, {id: resolvedPipelineStageId, pipeline: resolvedPipelineId})"
          />
        </template>
      </ViewHeader>
    </VCol>
    <VCol cols="12">
      <DataViewUI
        :show-expand="false"
        :expandable-rows="false"
        :data="data"
        :headers="submittedHeaders"
        :is-loading="activity.isLoading"
        :no-data-text="submittedFor === 'all' ? 'No applications found.' : 'You currently have no current or past applications to other workspaces. When you do, you can view and manage them here.'"
        :filters="allFilters"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <template v-if="($isUserAdmin || $isUserMentor) && submittedFor === 'all'">
            <td>
              <VRow row>
                <!-- <UserChip :user="props.item.user" /> -->
                <UserModal
                  :user="props.item.user"
                  :enable-chat="teamSetting('pipeline.is_chat_enabled', true)"
                  @toggleApplicantChat="onToggleApplicantChat(props.item)"
                />
                <Badge
                  :value="props.item.new_messages_count > 0"
                  overlap
                  color="info"
                >
                  <template #badge>
                    <VIcon
                      small
                      color="white"
                    >
                      priority_high
                    </VIcon>
                  </template>
                  <VBtn
                    v-if="teamSetting('pipeline.is_chat_enabled', true)"
                    :class="{
                      'slightly-rounded': true,
                      'wethrive': true,
                      'disabled': !(props.item.thread_for_user instanceof Object)
                    }"
                    :color="props.item.thread_for_user instanceof Object ? 'white' : 'grey'"
                    icon
                    small
                    @click="onToggleApplicantChat(props.item)"
                  >
                    <VIcon small>
                      chat
                    </VIcon>
                  </VBtn>
                </Badge>
                <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, {id: props.item.id, stage: resolvedPipelineStageId, pipeline: resolvedPipelineId})" />
              </VRow>
            </td>
            <td>
              <Tooltip
                :as-button="false"
                :icon="props.item.pipeline_submitted.pipeline.is_expired ? 'error' : null"
                :title="props.item.pipeline_submitted.pipeline.is_expired ? 'This stage is expired!' : 'This stage is active'"
                :color="props.item.pipeline_submitted.pipeline.is_expired ? 'warning' : ''"
                :text-color="props.item.pipeline_submitted.pipeline.is_expired ? 'white' : ''"
                :button-text="(props.item.pipeline_submitted.pipeline || {title: 'None'}).title"
              />
            </td>
          </template>
          <template v-else-if="submittedFor === 'me'">
            <td>
              <VChip>
                <VAvatar>
                  <VImg :src="props.item.pipeline_submitted.pipeline.team.photo_url" />
                </VAvatar>
                {{ props.item.pipeline_submitted.pipeline.team.name }}
              </VChip>
              <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, {id: props.item.id, stage: resolvedPipelineStageId, pipeline: resolvedPipelineId})" />
            </td>
            <td>
              <VRow row>
                <Tooltip
                  :as-button="false"
                  :icon="props.item.pipeline_submitted.pipeline.is_expired ? 'error' : null"
                  :title="props.item.pipeline_submitted.pipeline.is_expired ? 'This stage is expired!' : 'This stage is active'"
                  :color="props.item.pipeline_submitted.pipeline.is_expired ? 'warning' : ''"
                  :text-color="props.item.pipeline_submitted.pipeline.is_expired ? 'white' : ''"
                  :button-text="(props.item.pipeline_submitted.pipeline || {title: 'None'}).title"
                />
                <Badge
                  :value="props.item.new_messages_count > 0"
                  overlap
                  color="info"
                >
                  <template #badge>
                    <VIcon
                      small
                      color="white"
                    >
                      priority_high
                    </VIcon>
                  </template>
                  <VBtn
                    v-if="teamSetting('pipeline.is_chat_enabled', true)"
                    icon
                    small
                    :class="{
                      'slightly-rounded': true,
                      'wethrive': true,
                      'disabled': !(props.item.thread_for_user instanceof Object)
                    }"
                    :color="props.item.thread_for_user instanceof Object ? 'white' : 'grey'"
                    @click="onToggleApplicantChat(props.item)"
                  >
                    <VIcon small>
                      chat
                    </VIcon>
                  </VBtn>
                </Badge>
              </VRow>
            </td>
          </template>
          <td>
            <VMenu
              v-if="$isUserAdmin && !props.item.is_complete"
              offset-y
              :disabled="props.item.isSaving"
            >
              <template #activator="{on}">
                <VRow row>
                  <Tooltip
                    depressed
                    button-class="normal-text px-2"
                    right-icon="keyboard_arrow_down"
                    icon-right
                    :button-attributes="{
                      elevation: 3
                    }"
                    :activator-v-on="on"
                    :disabled="props.item.isSaving"
                    :loading="props.item.isSaving"
                    :text="false"
                    :icon="!props.item.stage ? 'error' : ''"
                    :icon-color="getCurrentStageStatusIconColor(props.item.stage || {})"
                    :title="props.item.status_message"
                    :button-text="(props.item.stage || {title: '(not set)'}).title || 'ERROR: No Title For Stage'"
                  />
                </VRow>
              </template>
              <VList :id="`set-stage-${props.item.id}`">
                <VListItem
                  v-for="(pipelineStage, pipelineStageKey) in otherStages"
                  :key="`pipeline-stage-${pipelineStageKey}`"
                  @click="onSetStage(props.index, pipelineStage.id)"
                >
                  {{ pipelineStage.title }}
                </VListItem>
              </VList>
            </VMenu>
            <Tooltip
              v-else
              :as-button="false"
              :icon="!props.item.stage ? 'error' : ''"
              :icon-color="getCurrentStageStatusIconColor(props.item.stage || {})"
              :title="(props.item.stage || {status_message: `No ${featureName('stage', 'singularize').toLowerCase()} set`}).status_message"
              :button-text="(props.item.stage || {title: 'None'}).title"
            />
          </td>
          <td>
            <VMenu
              v-if="$isUserAdmin && !props.item.is_complete"
              offset-y
              transition="scale-transition"
              :disabled="props.item.isSaving"
            >
              <template #activator="{on}">
                <VRow row>
                  <Tooltip
                    depressed
                    button-class="normal-text px-2"
                    right-icon="keyboard_arrow_down"
                    icon-right
                    icon-left
                    :button-attributes="{
                      elevation: 3
                    }"
                    :activator-v-on="on"
                    :disabled="props.item.isSaving"
                    :loading="props.item.isSaving"
                    :text="false"
                    :color="getCurrentStageStatusColor(props.item.stage || {})"
                    :text-color="getCurrentStageStatusTextColor(props.item.stage || {is_expired: false})"
                    :icon-color="getCurrentStageStatusIconColor(props.item.stage || {})"
                    :icon="getCurrentStageStatusIcon(props.item.stage || {})"
                    :title="getCurrentStageMessage(props.item.stage || {status_message: 'Not Started'})"
                    :button-text="getCurrentStageText(props.item.stage || {status: 'Not Started'})"
                  >
                    <VBtn
                      fab
                      x-small
                      rounded
                      slot="button-action"
                      :disabled="props.item.isSaving || props.item.is_complete"
                      @click.stop="onToggleEditSubmittedItemDialog(props.item)"
                    >
                      <VIcon small>
                        edit
                      </VIcon>
                    </VBtn>
                  </Tooltip>
                </VRow>
              </template>
              <VList :id="`set-status-${props.item.id}`">
                <VListItem @click="onSetStageStatus(props.index, 'in-progress')">
                  In Progress
                </VListItem>
                <VListItem @click="onSetStageStatus(props.index, 'submitted')">
                  Submitted
                </VListItem>
                <VListItem @click="onSetStageStatus(props.index, 'completed')">
                  Completed
                </VListItem>
              </VList>
            </VMenu>
            <Tooltip
              v-else
              depressed
              button-class="normal-text px-2"
              :text="false"
              :action="() => onToggleEditSubmittedItemDialog(props.item)"
              :color="getCurrentStageStatusColor(props.item || {})"
              :text-color="getCurrentStageStatusTextColor(props.item || {is_expired: false})"
              :icon-color="getCurrentStageStatusIconColor(props.item || {})"
              :icon="getCurrentStageStatusIcon(props.item || {})"
              :title="getCurrentStageMessage(props.item || {status_message: 'Not Started'})"
              :button-text="getCurrentStageText(props.item || {status: 'Not Started'})"
            />
          </td>
          <template v-if="$isUserAdmin && submittedFor === 'all'">
            <td>
              <Tooltip :title="getReviewersTitle(props.item)">
                <template #default="{ on }">
                  <VBtn
                    v-on="on"
                    depressed
                    class="normal-text px-1"
                    :disabled="props.item.is_complete"
                    :color="props.item.reviewers_count > 0 ? 'info' : 'grey lighten-2'"
                    :text-color="props.item.reviewers_count > 0 ? 'white' : 'grey darken-1'"
                    @click="onToggleReviewersDialog(props.index, props.item)"
                  >
                    <VAvatar
                      size="22"
                      :class="props.item.reviewers_count > 0 ? 'info' : 'grey lighten-2'"
                      :style="{
                        color: 'grey darken-2'
                      }"
                    >
                      {{ props.item.reviewers_count }}
                    </VAvatar>
                    &nbsp;{{ grammaticalFeatureName('Reviewers', props.item.reviewers_count) }} &nbsp;
                    <VIcon
                      class="mr-1"
                      color="primary"
                      text-color="white"
                    >
                      add_circle
                    </VIcon>
                  </VBtn>
                </template>
              </Tooltip>
            </td>
          </template>
          <!-- <td>
          <Tooltip
            :as-button="false"
            :color="(props.item.pipeline_submitted.pipeline || {is_expired: false}).is_expired && (!props.item.is_complete || !props.item.is_submitted) ? 'warning' : ''"
            :text-color="(props.item.pipeline_submitted.pipeline || {is_expired: false}).is_expired && (!props.item.is_complete || !props.item.is_submitted)? 'white' : ''"
            :icon-color="props.item.is_complete ? 'success' : 'info'"
            :icon="getApplicationStatusIcon(props.item)"
            :title="props.item.status_message"
            :button-text="props.item.status.titleize()"
          />
        </td> -->
          <td>{{ props.item.submitted_on ? $timezone.formatDateTime(props.item.submitted_on) : props.item.internal_status }}</td>
          <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
          <td class="actions">
            <Menu
              :disabled="props.item.isSaving || props.item.isLoading"
              :loading="props.item.isSaving || props.item.isLoading"
            >
              <VListItem
                v-if="canEditSubmitted(props.item)"
                :href="getFullyQualifiedUrlForItem(`${modelTypeRoute}.update`, {id: props.item.id, stage: resolvedPipelineStageId, pipeline: resolvedPipelineId})"
                @click="onToggleEditSubmittedItemDialog(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, {id: props.item.id, stage: resolvedPipelineStageId, pipeline: resolvedPipelineId})"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="canDeleteSubmitted(props.item)"
                @click="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <VCard flat>
            <VCardText>
              <div class="px-2">
                <DynamicInputsView
                  v-if="props.item.metadata instanceof Array && props.item.metadata.length"
                  :card="false"
                  :inputs="props.items.metadata"
                  :input-name="featureName('Metadata')"
                />
                <VAlert
                  v-else
                  type="info"
                  :value="true"
                >
                  No entries set for this stage
                </VAlert>
              </div>
            </VCardText>
          </VCard>
        </template>
      </DataViewUI>
      <Confirm ref="confirmDelete" />
      <FormDialog
        fullscreen
        persistent
        max-width="75%"
        model-type="PipelineSubmittedStage"
        :is-dialog-open="submittedDialogs.submitted"
        :title="`Update Submitted Stage: ${hasSubmittedModel && submittedModel.title}`"
        :action="onSubmitPipeline"
        :hide-overlay="true"
        :close-immediately="false"
        @toggle:form:dialog="onToggleSubmittedDialog"
      >
        <SubmitForm
          v-if="!$isUserStudent && submittedDialogs.submitted && hasSubmittedModel"
          mode="dialog"
          ref="submitPipelineStage"
          :id="submittedModel ? submittedModel.id : null"
          :stage="submittedModel.stage"
          :existing="submittedModel"
          :items="submitted.data"
          @isFormReady="onToggleItemLoading(submittedModel.id, false)"
        />
      </FormDialog>
      <Dialog
        fullscreen
        max-width="75%"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Submitted Pipeline')} Application`"
        :hide-overlay="true"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          ref="viewItem"
          mode="dialog"
          :id="currentModel ? {
            id: currentModel.id,
            pipeline_id: currentModel.stage.pipeline_id,
            pipeline_stage_id: currentModel.stage.id
          } : null"
        />
      </Dialog>
      <StageSubmit
        v-if="hasSubmittedApplication && dialogs.applicationForm"
        is-inputs-required
        mode="dialog"
        ref="submitPipelineStage"
        :is-dialog-open="dialogs.applicationForm"
        :id="{
          id: (currentApplicationModel.stage || {}).id,
          pipeline_id: (submittedApplication || {}).pipeline.id,
          pipeline_stage_id: currentApplicationModel.stage.id
        }"
        :pipeline="(submittedApplication || {}).pipeline"
        :application="submittedApplication"
        :stage="currentApplicationStage"
        :stage-index="currentApplicationStageIndex"
        :deadline="currentStageDeadline"
        @toggle:dialog="() => dialogs.applicationForm = false"
        @startFormLoading="onUpdateCurrentModelLoading(true)"
        @doneFormLoading="onUpdateCurrentModelLoading(false)"
      />
      <MessagesDialog
        v-if="hasSubmittedApplication"
        max-width="640px"
        entity-type="pipeline-submitted"
        :is-dialog-open="dialogs.applicantChat"
        :title="submittedApplicationThreadTitle"
        :members="(submittedApplication.reviewers || []).map(u => u.id)"
        :entity="submittedApplication"
        :thread="submittedApplication.thread_for_user"
        :user="submittedApplication.user"
        :enable-edit="submittedFor === 'all' && $isUserAdmin"
        dialog-hide-overlay
        @toggle:dialog="onToggleApplicantChat"
        @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
      />
      <Dialog
        :is-dialog-open="dialogs.reviewers.active"
        :title="`Add ${featureName('Reviewers')}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleReviewersDialog"
      >
        <MembersForm
          ref="addReviewers"
          type="mentors"
          mode="multiple"
          :existing="(dialogs.reviewers.model || {}).reviewers || []"
          :is-group="true"
          :parent-is-saving="dialogs.reviewers.isSaving"
          @addMember="user => addReviewerToApplicationStage(dialogs.reviewers.index, user)"
          @removeMember="user => removeReviewerFromApplicationStage(dialogs.reviewers.index, user)"
          @saveMembers="members => saveReviewersForApplicationStage(dialogs.reviewers.index, true)"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import MessagesDialog from "@/components/Chat/MessagesDialog";
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasDueMixin from "@/mixins/HasDueOn";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import MetadataMixin from "@/mixins/Metadata";
import SubmitForm from "./SubmitFormStage";
import ViewComponent from "./ViewSubmittedStage";
import HasMembersMixin from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import StageSubmit from "@/components/Organization/Pipelines/StageSubmit";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import SupportsPipelines from "@/mixins/SupportsPipelines";

export default {
  name: "PipelinesStagesSubmitted",
  components: {
    DynamicInputsView,
    SubmitForm,
    ViewComponent,
    MessagesDialog,
    MembersForm,
    StageSubmit,
    ViewHeader,
  },
  props: {
    pipeline: {
      type: Object,
      default: () => null,
    },
    stage: {
      type: Object,
      default: () => null,
    },
    stages: {
      type: Array,
      default: () => null,
    },
    submittedFor: {
      type: String,
      default: "all",
    },
    canLoadFromUrl: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    SearchMixin,
    MetadataMixin,
    HasSubmittedDataMixin,
    HasApplicationPipelinesMixin,
    HasDueMixin,
    HasMembersMixin,
    SupportsPipelines,
  ],
  data() {
    return {
      dialogs: {
        reviewers: {
          active: false,
          model: {},
        },
      },
      modelType: "pipelineStageSubmitted",
    };
  },
  created() {
    //We should load a specific application
    if (
      this.resolvedPipelineStageId &&
      this.resolvedPipelineId &&
      this.$route.params.id
    ) {
      this.doGetPipelineStageSubmitted({
        pipeline_id: this.resolvedPipelineId,
        pipeline_stage_id: this.resolvedPipelineStageId,
        id: this.$route.params.id,
      }).then((result) => {});
    }
  },
  mounted() {
    this.data.data = [];
  },
  computed: {
    otherStages() {
      return (
        this.stages ||
        (this.config.filters.find((f) => f.field == "stage") || {}).options ||
        []
      );
    },
    submittedHeaders() {
      if (this.$isUserAdmin && this.submittedFor === "all") {
        return [
          {
            text: "User",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "current_stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "stage.status",
          },
          {
            text: this.featureName("Reviewers", "pluralize"),
            align: "left",
            sortable: true,
            value: "reviewers.name",
          },
          /* {
            text: `${this.featureName("Application", "singularize")} Status`,
            align: "left",
            sortable: true,
            value: "status",
          }, */
          {
            text: `Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ];
      } else if (this.$isUserMentor && this.submittedFor == "all") {
        return [
          {
            text: "User",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "current_stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "stage.status",
          },
          {
            text: `${this.featureName("Stage")} Submitted On`,
            align: "left",
            sortable: true,
            value: "stage.submitted_on",
          },
          /* {
            text: `${this.featureName("Application", "singularize")} Status`,
            align: "left",
            sortable: true,
            value: "status",
          }, */
          {
            text: `${this.featureName(
              "Application",
              "singularize"
            )} Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ];
      } else {
        return [
          {
            text: this.featureName("Team", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.team.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "stage.stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "stage.status",
          },
          {
            text: `${this.featureName("Stage")} Submitted On`,
            align: "left",
            sortable: true,
            value: "stage.submitted_on",
          },
          /* {
            text: `${this.featureName("Application", "singularize")} Status`,
            align: "left",
            sortable: true,
            value: "status",
          }, */
          {
            text: `${this.featureName(
              "Application",
              "singularize"
            )} Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ];
      }
    },
  },
  methods: {
    ...mapActions({
      doGetSubmitted: "pipelineStageSubmitted/getAll",
      doDeletePipelineStageSubmitted: "pipelineStageSubmitted/delete",
      doSavePipelineStageSubmitted: "pipelineStageSubmitted/save",
      doSavePipelineStageSubmitted: "pipelineStageSubmitted/save",
      doGetIndexConfig: "pipelineSubmitted/getIndexConfig",
    }),
    onDelete(submitted) {
      return this.doDeletePipelineStageSubmitted({
        id: submitted.id,
        pipeline_id: this.resolvedPipelineId,
        pipeline_stage_id: this.resolvedPipelineStageId,
      });
    },
    onGetAll(_params) {
      const params = {
        ...(_params || {}),
        ...{
          pipeline_id: this.resolvedPipelineId,
          pipeline_stage_id: this.resolvedPipelineStageId,
          for: this.submittedFor,
          filter: {
            strict: true,
            ...(this.customFilter || _params.filter),
          },
        },
      };
      return this.doGetSubmitted(params);
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig({
        id: this.resolvedPipelineId,
      });
    },
    onSubmitPipeline() {
      return this.$refs["submitPipelineStage"].onSave();
    },
    addReviewerToApplicationStage(index, user) {
      const item = this.data.data[index];
      item.reviewers = item.reviewers || [];
      this.$set(
        this.data.data[index],
        "reviewers",
        this.onAddMember(item.reviewers, user)
      );
    },
    removeReviewerFromApplicationStage(index, user) {
      const item = this.data.data[index];
      item.reviewers = item.reviewers || [];
      this.$set(
        this.data.data[index],
        "reviewers",
        this.onRemoveMember(item.reviewers, user)
      );
    },
    saveReviewersForApplicationStage(index, leaveDialogOpen) {
      const item = this.data.data[index];
      let params = {
        id: item.id,
        pipeline_id: item.stage.pipeline_id,
        pipeline_stage_id: item.stage.id,
        reviewers: item.reviewers.map((member) =>
          member instanceof Object ? member.id : member
        ),
      };
      this.$set(this.dialogs.reviewers, "isSaving", true);
      this.doSavePipelineStageSubmitted(params).then((result) => {
        const existing = this.data.data[index];
        this.$set(this.data.data, index, { ...existing, ...result });
        this.$set(this.dialogs.reviewers, "isSaving", false);
        if (!leaveDialogOpen) {
          this.$set(this.dialogs.reviewers, "active", false);
        }
        this.$bus.$emit(
          "notificationMessage",
          result.message ||
            `Updated ${this.featureName("reviewers")
              .pluralize()
              .toLowerCase()} successfully!`
        );
      });
    },
    onToggleReviewersDialog(index, item) {
      this.dialogs.reviewers.active = !this.dialogs.reviewers.active;
      this.dialogs.reviewers.index = index;
      this.dialogs.reviewers.model = item;
    },
    onToggleEditSubmittedItemDialog(item) {
      if (this.$isUserStudent || this.submittedFor === "me") {
        this.onToggleApplicationForm(item);
      } else {
        this.onToggleSubmittedDialog(item, "pipelines");
      }
    },
    onUpdateCurrentModelLoading(isLoading) {
      const item = this.data.data.find(
        (item) => item.id == (this.currentApplicationModel || {}).id
      );
      if (item instanceof Object) {
        this.$set(item, "isLoading", !!isLoading);
      }
    },
    getReviewersTitle(item) {
      return item.reviewers_count
        ? `${item.reviewers.map((r) => r.name).join(", ")}`
        : `No ${this.featureName("Reviewers", "pluralize")}`;
    },
    canEditSubmitted(item) {
      return (
        (this.$isUserAdmin && this.submittedFor === "all") ||
        this.$isUserMentor ||
        (!item.pipeline.is_expired &&
          (this.teamSetting(
            "pipeline.is_application_editable_until_deadline",
            false
          ) ||
            !item.is_submitted))
      );
    },
    canDeleteSubmitted(item) {
      return this.$isUserAdmin && this.submittedFor === "all";
    },
    stageFor(item, stageId) {
      let id = stageId || item.stage.id;
      return item.stages.find((m) => m.pipeline_stage_id === id) || {};
    },
    currentStageFor(item) {
      return this.stageFor(item, item.stage.id);
    },
    stageDeadlineFor(item, stageId) {
      const stage = this.stageFor(item);
      const dueOn = stage.due_on || stage.pipeline.due_on;
      return dueOn ? this.$timezone.formatDateTime(dueOn) : "No deadline";
    },
    stageIndexFor(item, stageId) {
      let id = stageId || item.stage.id;
      return item.stages.findIndex((m) => m.pipeline_stage_id === id) || {};
    },
    onSetStageStatus(index, status) {
      let canSave = true;
      const stage = Object.select({ ...this.data.data[index].stage }, [
        "id",
        "user_id",
        "is_submitted",
        "is_completed",
        "pipeline_id",
        "pipeline_stage_id",
      ]);
      switch (status) {
        case "in-progress":
          stage.is_submitted = false;
          stage.is_complete = false;
          break;
        case "completed":
          stage.is_submitted = true;
          stage.is_complete = true;
          break;

        case "submitted":
          stage.is_submitted = true;
          stage.is_complete = false;
          break;

        default:
          canSave = false;
          break;
      }
      if (canSave) {
        this.$set(this.data.data[index], "isSaving", true);
        stage.pipeline_id = this.pipeline.id;
        this.doSavePipelineStageSubmitted({ data: stage }).then((result) => {
          this.data.data[index].stage = result;
          delete this.data.data[index].isSaving;
          this.$bus.$emit(
            "notificationMessage",
            `Updated ${this.featureName("stage").toLowerCase()} successfully!`
          );
        });
      }
    },
    onSetStage(index, stageId) {
      const submitted = Object.select(this.data.data[index], [
        "id",
        "user_id",
        "pipeline_id",
        "stage.id",
      ]);
      submitted.pipeline_stage_id = submitted.stage.id;
      submitted.stage.id = stageId;
      this.$set(this.data.data[index], "isSaving", true);
      this.doSavePipelineStageSubmitted({ data: submitted }).then((result) => {
        this.data.data[index].stage = result.stage;
        this.data.data[index].stage.id = result.stage.id;
        this.$delete(this.data.data[index], "isSaving");
        this.$bus.$emit(
          "notificationMessage",
          `Updated ${this.featureName("stage").toLowerCase()} successfully!`
        );
      });
    },
  },
};
</script>