import Vue from "vue";
import Vuex from "vuex";
/** Need to setup environment here for logger */
import env from "@/config/env";
Vue.use(Vuex);
// Setup logging
const options = {
	isEnabled: true,
	logLevel: env.isProduction ? "error" : "debug",
	stringifyArguments: false,
	showLogLevel: true,
	showMethodName: true,
	separator: "|",
	showConsoleColors: true,
};
import VueLogger from "vuejs-logger";
Vue.use(VueLogger, options);

import BaseStore from "./base";
import * as modules from "./modules";
// import LogRocket from 'logrocket';
// import createPlugin from 'logrocket-vuex';

// This method is needed to properly resolve the app instance
export default function(app) {
	BaseStore.setApp(app);
	BaseStore.setLogger(Vue.$log);
	// const logrocketPlugin = createPlugin(LogRocket);
	const generatedModules = BaseStore.generateModules(modules);
	const store = new Vuex.Store({
		modules: generatedModules,
		// plugins: [logrocketPlugin]
	});
	store.log = BaseStore.log;

	console.log("Store config", store);
	return store;
}
