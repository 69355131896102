var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{staticClass:"chat-threads overflow-hidden layout column mx-0",style:({
    backgroundColor: _vm.backgroundColor,
    borderRadius: '0px !important'
  })},[_c('VCardTitle',{staticClass:"pb-0",staticStyle:{"flex":"0 0 auto","border-bottom":"none"}},[_c('VRow',{attrs:{"column":""}},[_c('VCol',{style:({
          paddingTop: _vm.$vuetify.breakpoint.mdAndUp ? '50px' : ''
        })},[_c('h2',{staticClass:"headline"},[_vm._v(" Welcome Back, ")]),_c('h1',{staticClass:"display-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$user.first_name)+"! ")])]),_c('VCol',{attrs:{"grow":""}},[_c('TextField',{staticClass:"search-individuals v-input-sm my-2 no-details",attrs:{"text":"","solo-inverted":"","height":"22","label":"Your Messages"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.s),callback:function ($$v) {_vm.s=$$v},expression:"s"}},[_c('img',{staticStyle:{"cursor":"pointer","z-index":"1"},attrs:{"slot":"append","width":"13","height":"13","src":_vm.searchIcon},on:{"click":_vm.onSearch},slot:"append"}),_c('VBtn',{staticStyle:{"margin-top":"-6px","background-color":"#fff !important"},attrs:{"slot":"append-outer","icon":"","rounded":""},on:{"click":_vm.onHandleCreate},slot:"append-outer"},[_c('img',{attrs:{"width":"20","height":"20","src":_vm.editIcon}})])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('VBtn',{staticStyle:{"position":"absolute","top":"16px","right":"16px"},attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggleDrawer')}}},[_c('VIcon',[_vm._v("close")])],1):_vm._e()],1)],1)],1),_c('VSkeletonLoader',{staticClass:"chat-threads-list overflow-hidden",style:({
      backgroundColor: _vm.backgroundColor,
      borderRadius: '0px'
    }),attrs:{"type":"list-item-avatar-three-line","loading":_vm.isFirstLoad}},[_c('VCardText',{staticClass:"px-0 py-0",style:({
        overflowY: 'hidden',
        borderRadius: '0px'
      })},[(_vm.hasThreads)?_c('VList',{ref:"threads",style:({
          overflowY: 'auto',
          height: _vm.$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 285px)' : 'calc(100vh - 138px)',
          borderRadius: '0px'
        }),attrs:{"two-line":"","id":"infinite-threads"}},[_vm._l((_vm.sortedData),function(item,index){return _c('Thread',{key:index,attrs:{"id":`thread-${item.thread_id}`,"mode":_vm.threadMode,"thread":item,"current-thread":_vm.realSelectedThread,"is-selected":_vm.isThreadSelected(item)},on:{"loadMessages":_vm.onLoadMessages,"selectThread":_vm.onHandleThreadSelection}})}),(_vm.canGetMore)?_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"#infinite-threads","distance":25,"identifier":new Date()},on:{"infinite":_vm.onGetAll}}):_vm._e()],2):(!_vm.isLoading)?_c('VList',{attrs:{"two-line":""}},[_c('VListItem',{key:_vm.thread.title},[_c('VListItemContent',[_c('VListItemTitle',{staticClass:"text-center"},[_vm._v(" No threads ")]),_c('VListItemSubtitle',[(_vm.$route.name !== 'chat.index')?_c('VBtn',{attrs:{"block":"","color":"info"},on:{"click":_vm.onHandleCreate}},[_vm._v(" Start one here ")]):(!_vm.s)?_c('p',{staticClass:"text-center mt-6"},[_vm._v(" Start one on the right ")]):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }