var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"fill-height":"","align-start":"","row":"","wrap":"","justify-start":_vm.card.name || _vm.description || _vm.card.layout == 'Left',"justify-end":!_vm.card.name && !_vm.description && _vm.card.layout == 'Right'}},[(_vm.hasUrl)?_c('VCol',{class:{
      'card-image layout row justify-center align-center mb-0': true,
      'order-first': _vm.card.images.length === 0 && _vm.card.layout == 'Left',
      'order-last': _vm.card.images.length === 0 && _vm.card.layout == 'Right',
    },style:({
      overflow: 'hidden',
      flex: _vm.$vuetify.breakpoint.mdAndUp ? '0 0 450px' : '1 1 100%',
      paddingTop: '2px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      margin: '0px',
      borderRadius: '6px',
      height: '450px'
    })},[(_vm.card.name && _vm.$vuetify.breakpoint.smAndDown)?_c('CardTitle',{staticClass:"card-title",style:({
        fontSize: `${'24px'}!important`,
      }),attrs:{"align":!_vm.description || _vm.$vuetify.breakpoint.smAndDown ? 'center' : 'left'}},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]):_vm._e(),_c('VImg',{staticClass:"transparent",style:({
        borderRadius: '6px',
        color: '#000',
        cursor: 'zoom-in'
      }),attrs:{"contain":"","height":"450px","width":"450px","src":_vm.url},on:{"click":function($event){_vm.dialogs.preview = true}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('VRow',{attrs:{"fill-height":"","align-center":"","justify-center":"","ma-0":""}},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"grey lighten-2"}})],1)]},proxy:true}],null,false,1900538249)}),(_vm.dialogs.preview)?_c('Preview',{attrs:{"files":[_vm.card.image || _vm.card.value]},on:{"toggle":function($event){_vm.dialogs.preview = !_vm.dialogs.preview}}}):_vm._e()],1):_vm._e(),(_vm.card.name || _vm.description && _vm.$vuetify.breakpoint.mdAndUp)?_c('VCol',{class:{
      'd-flex align-center': true,
      'order-first': _vm.card.images.length === 0 && _vm.card.layout == 'Right',
      'order-last': _vm.card.images.length === 0 && _vm.card.layout == 'Left',
    },style:({
      flex: _vm.$vuetify.breakpoint.smAndDown ? '0 1 100%' : '1 1 calc(100% - 474px)',
      height: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : 'calc(100% - 104px)',
      overflowY: 'auto',
      paddingLeft: '16px',
      marginTop: '50px',
      marginBottom: '50px',
      marginRight: '24px',
    })},[_c('VRow',{style:({
        height: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : '100%',
        overflow: 'hidden auto',
      }),attrs:{"space-around":""}},[_c('VCol',[_c('CardTitle',{staticClass:"card-title",style:({
            fontSize: `${'24px'}!important`,
          }),attrs:{"align":!_vm.description || _vm.$vuetify.breakpoint.smAndDown ? 'center' : 'left'}},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],class:{
            formatted: true,
          },style:({
            lineHeight: 'normal',
            fontSize: '16px',
            opacity: 0.75,
            fontWeight: 300,
          }),domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.description && _vm.$vuetify.breakpoint.smAndDown)?_c('VCol',[_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],class:{
        formatted: true,
        'text-center': _vm.$vuetify.breakpoint.smAndDown
      },domProps:{"innerHTML":_vm._s(_vm.description)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }