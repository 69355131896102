<script>
import Vue from "vue";
import moment from "moment";
import momentTz from "moment-timezone";
import app from "../main";

class TimezoneHelper {
  constructor(app) {
    this.timezone = "America/New_York";
    // moment.tz.setDefault(this.timezone);
    this.$moment = moment;
    this.$momentTz = momentTz;
    return new Proxy(this, {
      get: function (helper, field) {
        if (field in helper) {
          return helper[field];
        }
        return helper.moment[field];
      },
    });
  }

  moment() {
    return moment(...arguments);
  }

  setDefaultTimezone(timezone) {
    this.$momentTz.tz.setDefault(timezone || "America/New_York");
    this.timezone = timezone;
  }

  formatDateTime(value, dateFormat, timeFormat) {
    return value
      ? this.formatDate(value, dateFormat) +
          " " +
          this.formatTime(value, timeFormat)
      : "";
  }

  formatTzDateTime(value, dateFormat, timeFormat, timezone) {
    return value
      ? this.formatTzDate(value, dateFormat, timezone) +
          " " +
          this.formatTzTime(value, timeFormat, timezone)
      : "";
  }

  formatDate(value, format) {
    const originalValue = value;
    if (!value) return "";
    const date = moment(value);
    const result = date.format(format || app.$user.date_format || app.$team.date_format || "DD/MM/YYYY");
    return result && result !== "Invalid date" ? result : value;
  }

  formatTzDate(value, format, timezone) {
    const originalValue = value;
    if (!value) return "";
    const date = this.$moment.tz(value, "UTC");
    const result = date
      .clone()
      .tz(timezone || this.timezone)
      .format(format || app.$user.date_format || app.$team.date_format || "DD/MM/YYYY");
    return result;
  }

  fromNow(value) {
    if (!value) return "";
    const result = moment(value);

    return result instanceof Object ? result.fromNow() : "just now";
  }

  fromTzNow(value) {
    if (!value) return "";
    const date = this.$momentTz.tz(value, "UTC");
    const result = date.clone();
    result.tz(this.timezone);

    return result instanceof Object ? result.fromNow() : "just now";
  }

  formatTime(value, format) {
    if (!value) return "";
    // console.log("Formatting", value, format);
    let parsedValue = moment(value);
    if (parsedValue.isValid()) {
      parsedValue = value;
    } else if (moment(value, "HH:mm:ss", true).isValid()) {
      parsedValue = "1970-01-01 " + value;
    } else {
      parsedValue = new Date("1970-01-01T" + value + "Z").toString();
    }
    const time = moment(parsedValue);
    return time.format(format || app.$user.time_format || app.$team.time_format || "HH:mm A");
  }

  formatTzTime(value, format, timezone) {
    if (!value) return "";
    let parsedValue = moment(value);
    if (parsedValue.isValid()) {
      parsedValue = value;
    } else if (moment(value, "HH:mm:ss", true).isValid()) {
      parsedValue = "1970-01-01 " + value;
    } else {
      parsedValue = new Date("1970-01-01T" + value + "Z").toString();
    }
    const time = this.$moment.tz(parsedValue, timezone || "UTC");
    return time
      .clone()
      .tz(this.timezone)
      .format(format || app.$user.time_format || app.$team.time_format || "HH:mm A");
  }

  formatDay(value) {
    const originalValue = value;
    if (!value) return "";
    const date = moment(value);
    const result = date.format("dddd");
    return result && result !== "Invalid date" ? result : value;
  }

  parseDateTime(value) {
    return value instanceof Object && value._isAMomentObject
      ? value
      : moment(value);
  }
}

const timezone = new TimezoneHelper(app);
Vue.prototype.$timezone = timezone;
Vue.prototype.$dateTimeOptions = {
  timezones: moment.tz
    .names()
    .filter((tz) => tz.startsWith("America"))
    .map((zone) => {
      return {
        text: zone,
        value: zone,
      };
    }),
  timeFormats: [
    {
      value: "HH:mm",
      text: "24HR 00:03",
    },
    {
      value: "LT",
      text: "12HR 12:03 AM",
    },
  ],
  dateFormats: [
    {
      value: "ll",
      text: "Aug 2, 1985",
    },
    {
      value: "LL",
      text: "August 2, 1985",
    },
    {
      value: "MMM-D-YYYY",
      text: "Aug-2-1985",
    },
    {
      value: "MMMM-D-YYYY",
      text: "August-2-1985",
    },
    {
      value: "MMM D YYYY",
      text: "Aug 2 1985",
    },
    {
      value: "MMMM D YYYY",
      text: "August 2 1985",
    },
    {
      value: "L",
      text: "08/02/1985",
    },
    {
      value: "l",
      text: "8/2/1985",
    },
  ],
};
export default timezone;
</script>