<template>
  <VRow
    row
    fluid
    fill-height
    justify-center
    class="mt-4"
    :column="column"
  >
    <slot name="title" />
    <slot name="loader">
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </slot>
  </VRow>
</template>
<script>
export default {
  props: {
    column: {
      type: Boolean,
      default: false
    }
  }
};
</script>