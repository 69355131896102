<template>
  <VContainer
    grid-list-lg
    fluid
  >
    <VRow v-if="modelHasId">
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader :route-params="{id: model.id, pipeline: model.pipeline_id}">
          <template #title>
            {{ featureName('Pipeline') }}
            {{ featureName('Stage') }}
          </template>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {stage: model.id, pipeline: model.pipeline_id})"
              :to="getRouteForItem(`${modelTypeRoute}.submitted`, {stage: model.id, pipeline: model.pipeline_id})"
            />
            <Linkable
              v-if="!$isUserStudent"
              icon="visibility"
              :tooltip="`View ${featureName('Pipeline').singularize()}`"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`pipeline.view`, {id: model.id, pipeline: model.pipeline_id})"
              :to="getRouteForItem(`pipeline.view`, {id: model.id, pipeline: model.pipeline_id})"
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <VCardText>
                <h1 class="display-2 text-break">
                  {{ model.title }}
                </h1>
                <VList dense>
                  <VListItem
                    v-for="(value,property) in details"
                    :key="property"
                  >
                    <VListItemContent>
                      {{ property }}
                    </VListItemContent>
                    <VListItemContent class="align-end">
                      <strong>{{ value }}</strong>
                    </VListItemContent>
                  </VListItem>
                </VList>
              </VCardText>
              <VCardText>
                <p
                  class="formatted"
                  v-html="model.content"
                  v-linkified
                />
                <FilesList
                  :readonly="true"
                  :files="model.files"
                />
              </VCardText>
            </VCard>
            <DynamicInputsView
              v-if="model.metadata instanceof Array && model.metadata.length"
              :card="false"
              :readonly="true"
              :inputs="model.metadata"
              :input-name="featureName('Metadata')"
            />
            <VAlert
              v-else
              type="info"
              :value="true"
            >
              No entries set for this stage
            </VAlert>
          </VCol>
          <VCol
            cols="12"
            class="ml-0"
            v-if="hasAnyAssociations"
          >
            <AssociatedContent :model="model" />
            <br>
            <VCard>
              <h2 class="display-1">
                {{ featureName('Reviewers').pluralize() }}
              </h2>
              <VCardText>
                <MembersList
                  v-if="model.reviewers && model.reviewers.length"
                  :readonly="true"
                  :users="model.reviewers"
                />
                <p v-else>
                  No {{ $team.user_role_name.toLowerCase().pluralize() }} have been added to this {{ featureName('pipeline').singularize() }}
                </p>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import MembersList from "@/components/Elements/Data/MembersList";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import SupportsPipelines from "@/mixins/SupportsPipelines";

export default {
  name: "PipelineStageView",
  components: { DynamicInputsView, MembersList, AssociatedContent, ViewHeader },
  mixins: [ViewMixin, HasCalendarEntries, SupportsPipelines],
  data() {
    return {
      modelType: "Pipeline Stage",
    };
  },
  computed: {
    stages() {
      return this.model.stages instanceof Array ? this.model.stages : [];
    },
    details() {
      return {
        Pipeline: this.model.pipeline.title,
        "Due On": this.model.due_on
          ? this.$timezone.formatDateTime(this.model.due_on)
          : "No Due Date",
        "Public Due On":
          this.model.public_due_on || this.model.due_on
            ? this.$timezone.formatDateTime(
                this.model.public_due_on || this.model.due_on
              )
            : "No Date Set",
        "Approved User Role": this.featureName(
          this.model.approved_user_role
            ? this.model.approved_user_role.value
            : "No Role Set"
        ).titleize(),
      };
    },
    hasAnyAssociations() {
      return (
        this.hasPrograms ||
        this.hasPrimaryContents ||
        this.hasReports ||
        this.hasSupplements ||
        this.hasLessons ||
        this.hasWorksheets ||
        this.hasDeliverables ||
        this.hasFAQs ||
        this.hasReviewers
      );
    },
    hasPrograms() {
      return (
        this.model &&
        this.model.programs instanceof Array &&
        this.model.programs.length > 0
      );
    },
    hasPrimaryContents() {
      return (
        this.model &&
        this.model.primary_contents instanceof Array &&
        this.model.primary_contents.length > 0
      );
    },
    hasReports() {
      return (
        this.model &&
        this.model.reports instanceof Array &&
        this.model.reports.length > 0
      );
    },
    hasSupplements() {
      return (
        this.model &&
        this.model.supplements instanceof Array &&
        this.model.supplements.length > 0
      );
    },
    hasLessons() {
      return (
        this.model &&
        this.model.lessons instanceof Array &&
        this.model.lessons.length > 0
      );
    },
    hasWorksheets() {
      return (
        this.model &&
        this.model.worksheets instanceof Array &&
        this.model.worksheets.length > 0
      );
    },
    hasDeliverables() {
      return (
        this.model &&
        this.model.deliverables instanceof Array &&
        this.model.deliverables.length > 0
      );
    },
    hasFAQs() {
      return (
        this.model &&
        this.model.faqs instanceof Array &&
        this.model.faqs.length > 0
      );
    },
    hasReviewers() {
      return (
        this.model &&
        this.model.reviewers instanceof Array &&
        this.model.reviewers.length > 0
      );
    },
  },
  methods: {
    ...mapActions({
      doGetPipelineStage: "pipelineStage/getOne",
    }),
    onGet(id) {
      const params = id instanceof Object ? id : { id };
      params.pipeline_id = id.pipeline_id || this.resolvedPipelineId;
      return this.doGetPipelineStage(params);
    },
  },
};
</script>

