<template>
  <VCard class="mb-2">
    <VCardTitle
      v-if="!hideTitle"
      style="line-height: 24px"
    >
      Share
    </VCardTitle>
    <VCardText>These are various registration workflow links you can share with others.</VCardText>
    <VCardText>
      <TextField
        v-for="(link, index) in links"
        readonly
        persistent-hint
        type="text"
        append-icon="file_copy"
        class="py-2"
        :key="index"
        :label="link.label"
        :hint="link.hint"
        :value="link.url"
        @click:append="() => copyTextToClipboard(link.url)"
      />
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: "Share",
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    links() {
      return [
        {
          label: "Register Url",
          hint: "Link for applicants to access your workspace's intakes and registrations.",
          url: `${this.$root.env.VUE_APP_APP_URL}/${this.$team.slug}/register`,
          enable: true,
        },
        {
          label: "Login Url",
          hint: "A direct login link for existing users or users you may have already created an account for.",
          url: `${this.$root.env.VUE_APP_APP_URL}/${this.$team.slug}/login`,
          enable: true,
        },
        {
          label: "Request To Join",
          hint: "This is a useful page to quickly send individuals who can self-register themselves without any process. They will simply enter their name and email address and request to join your workspace. Especially useful at events or tabling to replace the clunky excel sheet.",
          url: `${this.$root.env.VUE_APP_APP_URL}/${this.$team.slug}/join`,
          enable: false,
        },
        {
          label: "Profile Url",
          hint: "A public profile page for your organization. This is not accessible elsewhere and is for your sole use. If you choose not to use this, you don't need to worry about random site visitors coming across it.",
          url: `${this.$root.env.VUE_APP_APP_URL}/${this.$team.slug}/profile`,
          enable: false,
        },
      ].filter((item) => item.enable === true);
    },
  },
};
</script>