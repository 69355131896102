<template>
  <VCard class="current-members-search-container">
    <VCardText style="padding: 0px">
      <TextField
        v-if="!readonly"
        v-model="filter"
        solo-inverted
        class="search-individuals"
        :label="label"
      >
        <img
          slot="append"
          style="cursor: pointer"
          src="@/images/search-icon.png"
        >
      </TextField>
      <slot name="info" />
      <ChipsList
        v-if="altView"
        chip-close-alt
        add-chip-title="Add Reviewer"
        model-type="reviewers"
        item-title-style="font-weight: 400; font-size: 16px;"
        :normal-list="false"
        :items="localUsers"
        @removeItem="(member, type) => $emit('removeMember', member, type)"
        @addItems="(type) => $emit('toggleMemberDialog', type)"
      />
      <VList
        v-else-if="users.length"
        class="layout row wrap"
      >
        <template v-for="(member, index) in localUsers">
          <slot
            name="member"
            :member="member"
          >
            <VListItem
              class="flex xs12 lg6"
              :inactive="member.deleted"
              :key="index"
              @click="false"
            >
              <VListItemAvatar>
                <img :src="member.photo_url">
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle
                  :style="{
                    textDecoration: member.deleted ? 'line-through' : 'none',
                    color: member.deleted ? 'red' : 'inherit'
                  }"
                >
                  <template v-if="$user.id !== member.id">
                    {{ member.name }}
                  </template>
                  <template v-else>
                    <strong>You</strong>
                  </template>
                </VListItemTitle>
              </VListItemContent>
              <VListItemAction v-if="!readonly">
                <VBtn
                  icon
                  text
                  @click="$emit('removeMember', member)"
                >
                  <VIcon>{{ member.deleted ? 'refresh' : 'close' }}</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
          </slot>
        </template>
      </VList>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  name: "MembersList",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Find an individual ...",
    },
    users: {
      type: Array,
      default: () => [],
    },
    altView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: "",
    };
  },
  computed: {
    localUsers() {
      return this.users
        .filter((user) => {
          if (this.filter && this.filter.length) {
            const nameMatch = user.name.match(this.filter);
            const emailMatch = user.email.match(this.filter);
            return (
              (nameMatch && nameMatch.length > 0) ||
              (emailMatch && emailMatch.length > 0)
            );
          }
          return true;
        })
        .sortBy("name");
    },
  },
};
</script>
