<template>
  <VCard id="deliverable-export-card">
    <VCardText v-if="exportComplete">
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VAlert
        v-else-if="exportComplete"
        type="success"
        :value="true"
      >
        Your request is running in the background. You'll receive a notification
        once it's done. If you don't see the export please check your Spam
        folders. If you still don't receive the export in 15 mins, please
        contact support.
        <VBtn
          large
          color="primary"
          class="my-0"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset(true)"
        >
          Start Over
        </VBtn>
      </VAlert>
    </VCardText>
    <VCardText v-else>
      <VSheet
        rounded
        :color="!simple ? 'grey lighten-2' : ''"
        :class="{
          'px-4 py-4': !simple,
          'remove-fields-background': simple,
        }"
      >
        <VRow
          row
          wrap
          align-center
        >
          <VCol sm="12">
            <VAlert
              type="warning"
              :value="$route.query.pipeline_id > 0"
            >
              You have limited this export to a specific
              {{ featureName("pipeline").singularize() }}. To remove this
              <VBtn
                small
                depressed
                color="info"
                @click="onRemovePipelineFilter"
              >
                Click Here
              </VBtn>
            </VAlert>
            <h2 class="title mb-4">
              Configure export options here
            </h2>
            <Autocomplete
              v-model="filter.pipelines"
              filled
              multiple
              hide-details
              item-text="title"
              item-value="id"
              append-inner-icon="arrow-down"
              :dense="false"
              :disabled="activity.isLoading"
              :label="`Select ${featureName('pipelines')}`"
              :placeholder="`Select ${featureName('pipelines')}`"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.pipeline"
              :error="$v.form.pipelines.$error"
              @input="$v.form.pipelines.$touch()"
              @blur="onSyncFilterToForm"
            >
              <VBtn
                color="info"
                slot="append-outer"
                class="ml-2 mt-n2"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="activity.isFormLoading || activity.isOptionsLoading"
                @click="onSave"
              >
                Run Export
              </VBtn>
            </Autocomplete>
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.application_types"
              filled
              hide-details
              append-inner-icon="arrow-down"
              label="(Optional) Select application type"
              placeholder="Any application by default"
              :multiple="false"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.applicationType"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.status"
              filled
              multiple
              append-inner-icon="arrow-down"
              hide-details
              label="(Optional) Select applications with status"
              placeholder="Select applications with status"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.status"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol sm="12">
            <h3 class="subtitle">
              Stage Options
            </h3>
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.stage"
              filled
              multiple
              hide-details
              item-text="title"
              item-value="id"
              append-inner-icon="arrow-down"
              label="(Optional) Select stage"
              placeholder="Select stage"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptionStages"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <!-- <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.stage_status"
              filled
              multiple
              append-inner-icon="arrow-down"
              hide-details
              label="(Optional) Select application stage status"
              placeholder="Select stage status"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.status"
              @blur="onSyncFilterToForm"
            />
          </VCol> -->
          <VCol sm="12">
            <h3 class="subtitle">
              Applicant Options
            </h3>
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.applicant"
              filled
              multiple
              hide-details
              item-text="name"
              item-value="id"
              append-inner-icon="arrow-down"
              label="(Optional) Select applicants"
              placeholder="Select applicants"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.applicant"
              :getter="onGetApplicant"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <Autocomplete
              v-model="filter.submittedBy"
              filled
              multiple
              hide-details
              item-text="name"
              item-value="id"
              append-inner-icon="arrow-down"
              label="(Optional) Select submitted by"
              placeholder="Select submitted by"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="filterOptions.submittedBy"
              :getter="onGetSubmittedBy"
              @blur="onSyncFilterToForm"
            />
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <h3 class="subtitle mb-2">
              Select the dates
            </h3>
            <p class="caption">
              Select the date range for the export
            </p>
            <VDialog
              v-model="dialogs.submittedBetweenStart"
              width="290px"
              ref="submittedBetweenStartDialog"
              :disabled="activity.isLoading || activity.isFormLoading"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.submitted_between_start"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  clearable
                  outlined
                  hide-details
                  label="(Optional) Select Submitted Start date"
                  prepend-icon="event"
                  :disabled="activity.isLoading || activity.isFormLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.submitted_between_start"
                scrollable
                color="info"
                @input="dialogs.submittedBetweenStart = false"
              />
            </VDialog>
          </VCol>
          <VCol
            sm="12"
            md="6"
            :style="{
              marginTop: $vuetify.breakpoint.mdAndUp ? '64px' : 'auto',
            }"
          >
            <VDialog
              v-model="dialogs.submittedBetweenEnd"
              width="290px"
              ref="submittedBetweenEndDialog"
              :disabled="activity.isLoading || activity.isFormLoading"
              @update:return-value="onSyncFilterToForm"
            >
              <template #activator="{ on, attrs }">
                <VTextField
                  v-model="filter.submitted_between_end"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  clearable
                  readonly
                  hide-details
                  label="(Optional) Select Submitted End date"
                  prepend-icon="event"
                  :disabled="activity.isLoading || activity.isFormLoading"
                />
              </template>
              <VDatePicker
                v-model="filter.submitted_between_end"
                scrollable
                outlined
                color="info"
                @input="dialogs.submittedBetweenEnd = false"
              />
            </VDialog>
          </VCol>
          <VCol
            sm="12"
            md="6"
          >
            <h3 class="subtitle mb-2">
              Output Format
            </h3>
            <p class="caption">
              Select the output format for your export
            </p>
            <Autocomplete
              v-model="form.output_format"
              filled
              hide-details
              append-inner-icon="arrow-down"
              label="(Optional) Format for report"
              placeholder="Select format"
              item-text="text"
              item-value="value"
              :dense="false"
              :disabled="activity.isLoading || activity.isFormLoading"
              :loading="activity.isOptionsLoading"
              :items="outputFormats"
            />
          </VCol>
          <template v-if="form.output_format === 'PDF'">
            <VCol
              sm="12"
              md="6"
              :style="{
                marginTop: $vuetify.breakpoint.mdAndUp ? '64px' : 'auto',
              }"
            >
              <Autocomplete
                v-model="form.group_by"
                filled
                multiple
                hide-details
                append-inner-icon="arrow-down"
                label="(Optional) Group by"
                placeholder="Select group by"
                item-text="text"
                item-value="value"
                :dense="false"
                :disabled="activity.isLoading || activity.isFormLoading"
                :loading="activity.isOptionsLoading"
                :items="filterOptionMetadataFields"
              />
            </VCol>
            <VCol sm="12">
              <Draggable
                v-model="form.group_by"
                draggable=".group-by-input-container"
                handle=".group-by-input-handle"
                class="container"
                :no-transition-on-drag="true"
              >
                <transition-group
                  name="group-by-input-container"
                  type="transition"
                  tag="div"
                  class="v-list v-sheet theme--light v-list--subheader v-list--two-line"
                  role="list"
                  :css="true"
                >
                  <VListItem
                    v-for="(input, key) in form.group_by"
                    class="group-by-input-container"
                    :key="`group-by-input-${key}`"
                  >
                    <VListItemAction
                      v-if="key > 0"
                      class="group-by-input-handle dynamic-input-handle"
                    >
                      <VBtn
                        text
                        small
                        color="info"
                        style="cursor: move"
                      >
                        <VIcon>drag_indicator</VIcon>
                        Then by
                      </VBtn>
                    </VListItemAction>
                    <VListItemContent>
                      <VListItemTitle>
                        {{
                          (
                            filterOptionMetadataFields.find(
                              (m) => m.value === input
                            ) || {}
                          ).text || input
                        }}
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <VBtn
                        icon
                        @click="form.group_by.splice(key, 1)"
                      >
                        <VIcon>delete</VIcon>
                      </VBtn>
                    </VListItemAction>
                  </VListItem>
                </transition-group>
              </Draggable>
            </VCol>
          </template>
          <VCol sm="12">
            <h3 class="subtitle mb-2">
              Send Report To
            </h3>
            <VCombobox
              v-model="form.emails"
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              hide-details
              label="(Optional) Send the report to these emails"
              placeholder="Enter emails"
              :disabled="activity.isLoading || activity.isFormLoading"
            />
          </VCol>
        </VRow>
      </VSheet>
      <Submitted
        v-if="!hideSubmitted"
        mode="embedded"
        disable-filter
        :filter-on-filter-update="canFilterOnUpdate"
        :custom-filter="realFilter"
        @startLoading="emitLoading"
        @doneLoading="emitLoadingDone"
      />
    </VCardText>
    <Confirm ref="confirmReset" />
  </VCard>
</template>
<script>
import Draggable from "vuedraggable";
import { mapActions } from "vuex";
import { saveAs } from "file-saver";
import ActivityMixin from "@/mixins/Activity";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import HasPagination from "@/mixins/HasPagination";
import Submitted from "./Submitted";
import CanFilter from "@/mixins/CanFilter";

export default {
  name: "ExportSubmitted",
  mixins: [ActivityMixin, FormMixin, HasPagination, CanFilter],
  props: {
    pipelineId: {
      type: Number,
      default: null,
    },
    currentStageId: {
      type: Number,
      default: null,
    },
    hideSubmitted: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  components: { Submitted, Draggable },
  data() {
    return {
      enableBackgroundSearch: true,
      dialogs: {
        submittedBetweenStart: false,
        submittedBetweenEnd: false,
      },
      options: {
        pipelines: [],
        users: [],
      },
      error: "",
      message: "",
      outputFormats: ["PDF", "CSV"],
      filter: {
        output_format: "CSV",
        group_by: [],
        pipelines: [],
        application_types: [],
        stage: [],
        status: [],
        stage_status: [],
        applicant: [],
        submitted_by: [],
        submitted_between_start: null,
        submitted_between_end: null,
      },
      defaultForm: {
        s: "",
        emails: [],
        pipelines: [],
        application_types: [],
        stages: [],
        status: [],
        stage_status: [],
        applicants: [],
        submitted_by: [],
        group_by: [],
        output_format: "CSV",
        summarize: true,
        only_to_specified: false,
        application_type: "submitted",
        exclude_recently_notified: false,
        submitted_before: null,
        submitted_after: null,
        submitted_between_start: null,
        submitted_between_end: null,
      },
    };
  },
  validations: {
    form: {
      pipelines: {
        required,
        minLength: minLength(1),
      },
    },
  },
  computed: {
    realFilter() {
      return {
        strict: true,
        filter: {
          strict: true,
          pipelines: this.form.pipelines,
          pipeline: this.form.pipelines,
          applicant: this.form.applicant,
          application_types: this.form.application_types,
          stage: this.form.stage,
          group_by: this.form.group_by,
          status: this.form.status,
          stage_status: this.form.stage_status,
          output_format: this.form.output_format,
          submitted_between_start: this.form.submitted_between_start,
          submitted_between_end: this.form.submitted_between_end,
        },
      };
    },
    exportComplete() {
      return (this.saveResult || {}).isQueued;
    },
    hasErrorsOrMessages() {
      return this.error.length >= 0 || this.message.length > 0;
    },
    canBeAttached() {
      for (const i in this.entityTypes) {
        const entity = this.entityTypes[i];
        if (!entity instanceof Object) {
          return false;
        }
        const source = this.pipelineHasAssociations
          ? this.pipeline
          : this.options;
        if (
          source.hasOwnProperty(entity.slug) &&
          source[entity.slug] instanceof Array
        ) {
          return source[entity.slug].length > 0;
        }
      }
      return false;
    },
    selectedPipelineHasMetadata() {
      return this.form.pipelines
        ? (this.pipeline.metadata || []).length > 0
        : false;
    },
    filterOptionStages() {
      return this.filterOptions.stage instanceof Array
        ? this.filterOptions.stage.filter((s) =>
            this.filter.pipelines.length > 0
              ? this.filter.pipelines.indexOf(s.pipeline_id) > -1
              : true
          )
        : [];
    },
    filterOptionMetadataFields() {
      const fields = [
        {
          text: "Pipeline",
          value: "Pipeline",
        },
        {
          text: "Stage",
          value: "Stage",
        },
        {
          text: "Stage Status",
          value: "Stage Status",
        },
        {
          text: "Status",
          value: "Status",
        },
      ];
      for (const stage of this.filterOptionStages) {
        fields.push({
          header: stage.title,
        });
        fields.push(
          ...(stage.metadata || [])
            .map((m) => {
              return {
                text: m.name,
                value: m.id,
              };
            })
            .sortBy("text")
        );
      }
      return fields;
    },
  },
  watch: {
    "$route.query.pipeline_id": function(value) {
      if (value) {
        this.onReset(true, true);
        this.form.pipelines = this.filter.pipelines = [parseInt(value)];
      }
    },
  },
  created() {
    this.onGetExportConfig();
  },
  mounted() {
    this.form.emails = [this.$user.email];
    if (this.$route.query.pipeline_id) {
      this.form.pipelines = this.filter.pipelines = [
        parseInt(this.$route.query.pipeline_id),
      ];
    }
    if(this.pipelineId) {
      this.form.pipelines = this.filter.pipelines = [this.pipelineId];
      if(this.currentStageId) {
        this.form.stage = this.filter.stage = [this.currentStageId];
      }
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "pipelineSubmitted/getIndexConfig",
      doExportPipelinesSubmitted: "pipelineSubmitted/export",
      doGetUsers: "users/getAll",
      doGetPipelines: "pipeline/getAll",
    }),
    onSearch(s) {
      return this.saved.data.filter((user) => {
        if (this.filter && this.filter.length) {
          const nameMatch = user.name.match(this.filter);
          const emailMatch = user.email.match(this.filter);
          return (
            (nameMatch && nameMatch.length > 0) ||
            (emailMatch && emailMatch.length > 0)
          );
        }
        return true;
      });
    },
    onSave() {
      return this.saveFormModel(this.doExportPipelinesSubmitted);
    },
    onReset(force, resetSelected) {
      const resetFunc = (result, shouldResetEntities) => {
        if (result === true) {
          this.error = this.message = "";
          this.errors = this.messages = [];
          this.saveResult = null;
          this.form = { ...this.defaultForm };
          this.form.emails = [this.$user.email];
        }
      };

      if (force === true) {
        resetFunc(true, resetSelected);
      } else {
        this.$refs["confirmReset"]
          .open("Reset", "Are you sure?", {
            color: "orange",
          })
          .then((result) => {
            resetFunc(result, true);
          });
      }
    },
    onGetFormConfig() {
      return this.doGetIndexConfig({
        _filter_relations: {
          stage: ["metadata"],
        },
      })
        .then((result) => (this.config = result))
        .catch(() => {
          this.$emit("doneFormLoading");
        })
        .then(() => {
          this.$emit("doneOptionsLoading");
        });
    },
    onGetPipelines() {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      return this.doGetPipelines({
        _withCustom: true,
        filter: {
          id: this.$route.query.pipeline_id,
        },
      })
        .then((result) => {
          this.options.pipelines = result.data;
        })
        .catch(() => {
          this.$emit("doneOptionsLoading");
        })
        .then(() => {
          this.$emit("doneOptionsLoading");
        });
    },
    onGetSubmittedBy(params) {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      return this.doGetUsers(params).then((result) => {
        this.options.submittedBy = result.data;
        this.$emit("doneOptionsLoading");
        return result;
      });
    },
    onGetApplicant(params) {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      return this.doGetUsers(params).then((result) => {
        this.options.applicant = result.data;
        this.$emit("doneOptionsLoading");
        return result;
      });
    },
    getUniqueKeyValue(index) {
      let item = index instanceof Object ? index : this.prepared.data[index];
      if (item) {
        const metadata = item.metadata.find(
          (item) =>
            item.name ==
            (this.uniqueKey instanceof Object
              ? this.uniqueKey.id
              : this.uniqueKey)
        );
        if (metadata) {
          return metadata.value;
        }
      }
      return "None";
    },
    onPaginatePreparedLocal(pagination) {
      this.preparedPaginated = {
        ...this.preparedPaginated,
        ...this.getLocalDataPaginated(pagination, "prepared"),
      };
    },
    onPaginateSavedLocal(pagination) {
      this.savedPaginated = {
        ...this.savedPaginated,
        ...this.getLocalDataPaginated(pagination, "saved"),
      };
    },
    onUpdatePaginationPreparedLocal(pagination) {
      this.onPaginateLocal(pagination, "preparedPaginated");
      this.onPaginatePreparedLocal(pagination);
    },
    onUpdatePaginationSavedLocal(pagination) {
      this.onPaginateLocal(pagination, "savedPaginated");
      this.onPaginateSavedLocal(pagination);
    },
    onRemovePipelineFilter() {
      delete this.$route.query.pipeline_id;
      this.onGetExportConfig();
    },
  },
};
</script>
<style lang="scss">
.remove-fields-background {
  .v-text-field--filled > .v-input__control > .v-input__slot {
    background: none !important;
  }
}
</style>