import api from "../../services/calendarEntry";
export default function(helper) {
	// CalendarEntry types
	const TYPES = {
		CALENDAR_ENTRY_GET: "STORE_GET",
		CALENDAR_ENTRY_DELETE: "STORE_DELETE",
		CALENDAR_ENTRY_SAVE: "STORE_SAVE"
	};

	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("calendar_entry");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions({}, "Calendar Entry", api);
	const mutations = helper.mutations(
		{
			[TYPES.CALENDAR_ENTRY_GET](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_SAVE", data, {
					root: true
				});
			},
			[TYPES.CALENDAR_ENTRY_SAVE](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_SAVE", data, {
					root: true
				});
			},
			[TYPES.CALENDAR_ENTRY_DELETE](state, data) {
				this.commit("calendar/CALENDAR_ENTRY_DELETE", data, {
					root: true
				});
			}
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	};
}
