<template>
  <VRow
    row
    wrap
    fill-height
    align-center
    justify-center
    style="width: 100%"
  >
    <VCol
      px-12
      cols="12"
    >
      <CardTitle
        :has-images="hasImages"
        align="left"
      >
        {{ card.name }}
      </CardTitle>
      <VList
        :style="{
          overflowY: 'auto',
        }"
      >
        <VListItem
          v-for="(choice,key) in choices"
          :key="key"
        >
          <VListItemAction>
            <VBtn
              icon
              @click="selectChoice(choice)"
            >
              <VIcon
                v-if="isSelectedChoice(choice)"
                color="white"
                size="20px"
                :style="{
                  borderRadius: cardValue.type === 'multiple' ? '4px' : '50%',
                  backgroundColor: '#89c160',
                  width: '24px',
                  height: '24px',
                  lineHeight: '24px',

                }"
              >
                {{ cardValue.type === 'multiple' ? 'check' : 'check' }}
              </VIcon>
              <VIcon
                v-else
                size="24px"
                color="#eee"
                :style="{
                  backgroundColor: '#eee',
                  borderRadius: cardValue.type === 'multiple' ? '4px' : '50%',

                }"
              >
                {{ cardValue.type === 'multiple' ? 'check_box_outline_blank' : 'radio_button_unchecked' }}
              </VIcon>
            </VBtn>
          </VListItemAction>
          <VListItemContent>
            <VListItemSubtitle 
              class="text-wrap" 
              style="color: #000000;"
            >
              {{ choice.text }}
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </VList>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardQuestions",
  mixins: [PresentationCardMixin],
  data() {
    return {
      defaultForm: {
        value: [],
      },
    };
  },
  computed: {
    cardValue() {
      return (
        this.card.value || {
          type: "single",
        }
      );
    },
  },
};
</script>
