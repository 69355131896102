<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="view-evaluated-pipeline"
    type="card"
    :loading="!modelHasId"
  >
    <VRow
      column
      fill-height
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          fill-height
          justify-space-between
        >
          <h1>
            <VBtn
              icon
              fab
              @click="$router.go(-1)"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            View {{ featureName("Evaluated Applications")
            }}<template v-if="model">
              <!-- for {{ realPipeline.title }} -->
              <Linkable
                :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, model.id)"
              />
              <Linkable
                v-if="!$isUserStudent"
                icon="edit"
                tooltip="Click or tap to update"
                :copyable="false"
                :icon-props="{ small: true }"
                :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.update`, model.id)"
                :to="getRouteForItem(`${modelTypeRoute}.update`, model.id)"
              />
            </template>
          </h1>
        </VRow>
      </VCol>
      <VCol
        style="flex: 1 1 auto"
        class="pb-0"
      >
        <VRow
          row
          wrap
        >
          <VCol cols="12">
            <VCard>
              <h1
                v-if="mode !== 'embedded'"
                class="title mb-2"
              >
                {{ model.title }}
              </h1>
              <VRow
                row
                wrap
                class="pl-3 align-center"
              >
                <VCol
                  :class="{
                    'pb-0': $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <VList dense>
                    <VListItem>
                      <VListItemContent> User </VListItemContent>
                      <VListItemContent class="align-end">
                        <UserModal
                          :title="model.pipeline_submitted.title"
                          :new-messages-count="
                            model.pipeline_submitted.new_messages_for_user_count
                          "
                          :members="
                            (model.pipeline_submitted.reviewers || []).map((u) => u.id)
                          "
                          :entity="model.pipeline_submitted"
                          :thread="model.pipeline_submitted.thread_for_user"
                          :user="model.user"
                          :enable-edit="$isUserAdmin"
                          :enable-chat="
                            teamSetting('content.is_chat_enabled', true) || true
                          "
                        />
                      </VListItemContent>
                    </VListItem>
                    <VListItem>
                      <VListItemContent> {{ featureName("Stage") }} </VListItemContent>
                      <VListItemContent class="align-end">
                        <VChip
                          class="text-wrap"
                          style="height: auto; min-height: 32px; flex: none"
                        >
                          {{ model.stage.title }}
                        </VChip>
                      </VListItemContent>
                    </VListItem>
                    <VListItem v-if="limitedDetails">
                      <VListItemContent> Status </VListItemContent>
                      <VListItemContent class="align-end">
                        <VChip
                          style="flex: none"
                          :color="model.stage.is_expired ? 'light-grey' : 'success'"
                          :text-color="model.stage.is_expired ? 'light-grey' : 'white'"
                        >
                          {{ model.status.titleize() }}
                        </VChip>
                      </VListItemContent>
                    </VListItem>
                  </VList>
                </VCol>
                <VCol
                  v-if="!limitedDetails"
                  :class="{
                    'pt-0': $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <VList dense>
                    <VListItem>
                      <VListItemContent> Status </VListItemContent>
                      <VListItemContent class="align-end">
                        <VChip
                          :color="model.stage.is_expired ? 'light-grey' : 'success'"
                          :text-color="model.stage.is_expired ? 'light-grey' : 'white'"
                        >
                          {{ model.status.titleize() }}
                        </VChip>
                      </VListItemContent>
                    </VListItem>
                    <VListItem>
                      <VListItemContent> Submitted By </VListItemContent>
                      <VListItemContent class="align-end">
                        <UserModal
                          :title="`${model.submitted_by.name} (${model.pipeline_submitted.pipeline.title})`"
                          :new-messages-count="
                            model.pipeline_submitted.new_messages_for_user_count ||
                              model.pipeline_submitted.new_messages_count
                          "
                          :members="
                            (model.pipeline_submitted.reviewers || []).map((u) => u.id)
                          "
                          :entity="model.pipeline_submitted"
                          :thread="model.pipeline_submitted.thread_for_user || {}"
                          :user="model.submitted_by.user || model.user"
                          :enable-edit="$isUserAdmin"
                          :enable-chat="teamSetting('content.is_chat_enabled', true)"
                        />
                      </VListItemContent>
                    </VListItem>
                    <VListItem>
                      <VListItemContent> Submitted On </VListItemContent>
                      <VListItemContent class="align-end">
                        <Tooltip
                          :as-button="false"
                          :color="
                            (model.stage || { is_expired: false }).is_expired &&
                              (!model.is_complete || !model.is_submitted)
                              ? 'warning'
                              : ''
                          "
                          :text-color="
                            (model.stage || { is_expired: false }).is_expired &&
                              (!model.is_complete || !model.is_submitted)
                              ? 'white'
                              : ''
                          "
                          :icon-color="model.is_complete ? 'success' : 'info'"
                          :icon="getApplicationStatusIcon(model)"
                          :title="model.status_message"
                          :button-text="model.status.titleize()"
                        />
                      </VListItemContent>
                    </VListItem>
                  </VList>
                </VCol>
              </VRow>
              <VCardText class="px-0 pb-0">
                <DynamicInputsView
                  v-if="model.metadata instanceof Array && model.metadata.length"
                  readonly
                  class="px-2 py-2 grey lighten-3"
                  :card="false"
                  :inputs="model.metadata"
                  :input-name="featureName('Metadata')"
                />
                <VAlert
                  v-else
                  type="info"
                  :value="true"
                >
                  No entries set for this {{ featureName("stage") }}
                </VAlert>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";

export default {
  name: "PipelinesViewEvaluated",
  components: { DynamicInputsView },
  mixins: [ViewMixin, HasApplicationPipelinesMixin],
  props: {
    limitedDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelType: "Pipeline Stage Submitted",
    };
  },
  created() {
    if (this.existing && this.existing.id) {
      this.model = this.existing;
    }
  },
  methods: {
    ...mapActions({
      doGetPipelineStageSubmitted: "pipelineStageSubmitted/getOne",
    }),
    onGet(id) {
      return this.doGetPipelineStageSubmitted(id).then((result) =>
        this.$emit("model", result)
      );
    },
  },
};
</script>
<style lang="scss">
.view-submitted {
  .view-submitted-panels {
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
