<template>
  <VContainer
    fluid
    class="enterprise-applicant-portal px-0 py-0 fill-height"
    :style="containerStyle"
  >
    <VRow
      v-if="!activity.isLoading"
      fill-height
      :style="{
        maxHeight: $vuetify.breakpoint.mdAndUp ? '100vh' : 'auto'
      }"
    >
      <VCol
        xs="12"
        md="4"
        :class="{
          'bgImage px-8': true,
          'pr-8 pb-6': $vuetify.breakpoint.mdAndUp,
        }"
        :style="leftPanelStyle"
      >
        <VRow
          column
          fill-height
          justify-right
          justify-space-between
        >
          <router-link
            to="/"
            class="d-flex justify-end px-3"
          >
            <VImg
              max-width="200px"
              :src="$team.photo_url"
            />
          </router-link>
          <VCol class="d-flex align-center justify-end py-4">
            <VList class="menu-list">
              <VListItem
                :class="{
                  'mb-3': true,
                  'text-right': $vuetify.breakpoint.mdAndUp,
                  'text-center': $vuetify.breakpoint.smAndDown
                }"
                @click="onToggleApplicationForm"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    {{ canEditApplication ? 'Edit' : 'View' }} Application
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                :class="{
                  'mb-3': true,
                  'text-right': $vuetify.breakpoint.mdAndUp,
                  'text-center': $vuetify.breakpoint.smAndDown
                }"
                @click="navigateTo({
                  name: 'account',
                  params: {
                    state:'not-approved'
                  }
                })"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    Update Applicant Profile
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                :class="{
                  'mb-3': true,
                  'text-right': $vuetify.breakpoint.mdAndUp,
                  'text-center': $vuetify.breakpoint.smAndDown
                }"
                @click="learnMoreButton.action"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    {{ learnMoreButton.title }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                v-for="(item, index) in extraListItems"
                :key="`extra-list-item-${index}`"
                :class="{
                  'mb-3': true,
                  'text-right': $vuetify.breakpoint.mdAndUp,
                  'text-center': $vuetify.breakpoint.smAndDown
                }"
                @click.prevent="item.action()"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    {{ item.title }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </VCol>
          <VCol
            v-if="hasFaqButton"
            shrink
            class="text-right d-flex justify-end"
          >
            <VBtn
              x-large
              :class="{
                'faq-btn px-4 py-3': true,
                'text-center': $vuetify.breakpoint.smAndDown
              }"
              :block="$vuetify.breakpoint.smAndDown"
              :style="faqBtnStyle"
              @click="faqButton.action"
            >
              <span :style="{flex: $vuetify.breakpoint.smAndDown ? 1 : 'auto'}">{{ faqButton.title }}</span>
              <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
              <VIcon size="18">
                arrow_forward_ios
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        sm="12"
        md="8"
        :class="{
          'info-col bgImage px-8': true,
          'pl-10 pb-6': $vuetify.breakpoint.mdAndUp,
        }"
        :style="rightPanelStyle"
      >
        <VRow
          column
          fill-height
          justify-space-between
          align-left
          class="app_content_section"
        >
          <VCol shrink>
            <h1 :style="titleFontStyle">
              Welcome to {{ this.$team.name }}
            </h1>
            <Menu
              icon="keyboard_arrow_down"
              color="#000"
              :fab="false"
              :small="false"
              :button-attrs="{
                large: true,
                text: true,
                class: 'ml-0 pl-1'
              }"
            >
              <template slot="text">
                {{ (selectedPipeline || {}).title || "Select application" }}&nbsp;
              </template>
              <VListItem
                v-for="availablePipeline in availablePipelines"
                :key="`available-pipeline${availablePipeline.id}`"
                @click="onChangePipeline(availablePipeline)"
              >
                <VListItemContent>
                  <VListItemTitle>
                    {{ availablePipeline.title }} <VChip
                      v-if="!availablePipeline.current_user_submitted_count"
                      label
                      small
                      color="success"
                      style="cursor: pointer"
                    >
                      Apply Now!
                    </VChip>
                    <VChip
                      v-if="availablePipeline.id == pipelineDetails.id"
                      small
                      color="info"
                      style="cursor: pointer"
                    >
                      Current!
                    </VChip>
                  </VListItemTitle>
                </VListItemContent>
              </VListItem>
            </Menu>
            <VChip
              v-if="$user.submitted_application_count < availablePipelines.length"
              color="info"
              class="ml-2"
            >
              <VIcon class="shift--animation">
                {{ $vuetify.breakpoint.smAndDown ? 'expand_less' : 'chevron_left' }}
              </VIcon>
              &nbsp;New applications available!
            </VChip>
            <VDivider
              class="mt-3"
              style="padding: 1px"
              width="100px"
            />
          </VCol>
          <VCol grow>
            <h2
              :class="{
                'mt-6': $vuetify.breakpoint.mdAndUp,
                'mt-2': $vuetify.breakpoint.smAndDown
              }"
              :style="primaryFontStyle"
            >
              Application
            </h2>

            <VList
              color="transparent"
              style="min-height: 40px;"
            >
              <VListItem class="info-item mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    You created an <b>{{ pipelineDetails.title }}</b> application.
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem class="info-item mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    This application was last <span class="green--text">updated</span> on {{ $timezone.formatDateTime(pipelineDetails.updatedAt) }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem class="info-item mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    <template v-if="!currentApplicationStageForUser.is_submitted">
                      Your application has
                      <span class="red--text">NOT</span> been submitted yet. To submit your application click on <strong>Edit Application</strong>.
                    </template>
                    <template v-else>
                      You have submitted your application.
                    </template>
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                v-if="currentApplicationStageForUser.is_submitted"
                class="info-item mb-3"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    You <strong>{{ canEditApplication ? 'can' : 'can view but not' }}</strong> update your application.
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
            <h2
              class="applicant"
              :style="primaryFontStyle"
            >
              The Applicant
            </h2>
            <VRow align-center>
              <VCol
                shrink
                class="pr-0"
              >
                <VAvatar size="38">
                  <VImg
                    alt="img"
                    :src="userAvatar"
                  />
                </VAvatar>
              </VCol>
              <VCol grow>
                <VList color="transparent">
                  <VListItem class="info-item">
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $user.name }}, <a
                          style="font-weight: bold"
                          :href="`mailto:${$user.email}`"
                        >{{ $user.email }}</a><template v-if="$user.city">
                          — {{ userLocation(true, ', ') }}
                        </template>
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                </VList>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            v-if="steps.length"
            shrink
          >
            <div class="d-flex space-between align-center">
              <h2
                class="applicant flex mb-0"
                :style="primaryFontStyle"
              >
                Timeline
              </h2>
              <VChip
                label
                style="border-radius: 6px"
                class="flex shrink"
                :color="themeSetting('applicantPortal_secondary_color')"
                :text-color="themeSetting('applicantPortal_primary_font_color')"
              >
                Next Notification: {{ $timezone.formatDate(nextNotification.value, 'll') }}
              </VChip>
            </div>
            <VStepper class="timeline">
              <VStepperHeader style="height: 48px">
                <template v-for="(step, stepIndex) in steps">
                  <Tooltip
                    icon-button
                    small-icon
                    icon="stop_circle"
                    class="v-stepper_step"
                    button-style="cursor: pointer; margin-top: 6px"
                    :title="`${step.name}: ${$timezone.formatDate(step.value, 'll')}`"
                    :key="`step-${stepIndex}`"
                    :complete="$timezone.moment().isAfter(step.value)"
                    :color="$timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_secondary_color') : '#ccc'"
                  />
                  <VDivider
                    v-if="stepIndex < steps.length - 1"
                    :key="`step-divider-${stepIndex}`"
                    :style="{
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: $timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_secondary_color') : '',
                      backgroundColor: $timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_secondary_color') : ''
                    }"
                  />
                </template>
              </VStepperHeader>
            </VStepper>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      align-center
      fill-height
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
    <StageSubmit
      v-if="hasSubmittedApplication && dialogs.applicationForm"
      mode="dialog"
      ref="submitPipeline"
      :is-dialog-open="dialogs.applicationForm"
      :id="{
        id: currentApplicationStageForUser.id,
        pipeline_id: currentApplicationStage.pipeline_id,
        pipeline_stage_id: submittedApplication.current_stage_id
      }"
      :readonly="!canEditApplication"
      :pipeline="(submittedApplication || {}).pipeline"
      :application="submittedApplication"
      :existing="currentApplicationStageForUser"
      :stage="currentApplicationStage"
      :stage-index="currentApplicationStageIndex"
      :deadline="currentStageDeadline"
      is-inputs-required
      @toggle:dialog="() => dialogs.applicationForm = false"
      @input="submitted => $set(submittedApplication, 'current_stage_for_user', submitted)"
      @submitted="reloadPage"
    />
    <MessagesDialog
      v-if="hasSubmittedApplication"
      max-width="640px"
      entity-type="pipeline-submitted"
      :is-dialog-open="dialogs.applicantChat"
      :title="submittedApplicationThreadTitle"
      :members="(submittedApplication.reviewers || []).map(u => u.id)"
      :entity="submittedApplication"
      :thread="submittedApplication.thread_for_user"
      :user="submittedApplication.user"
      dialog-hide-overlay
      @toggle:dialog="onToggleApplicantChat"
      @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
    />
    <Dialog
      fullscreen
      card-text-class="px-0 py-0"
      :is-dialog-open="dialogs.video"
      @toggle:dialog="dialogs.video = !dialogs.video"
    >
      <div
        v-if="videoUrl"
        class="responsive-video"
        :style="{
          width: '100%',
          height: '100%'
        }"
      >
        <iframe
          :style="{
            width: '100vw',
            maxWidth: '100vw',
            minHeight: '315px',
            maxHeight: 'calc(100vh - 72px)',
          }"
          :src="videoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
        />
      </div>
    </Dialog>
  </VContainer>
</template>
<script>
import MessagesDialog from "@/components/Chat/MessagesDialog";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import SupportsCustomizationMixin from "@/mixins/SupportsCustomization";
import StageSubmit from "@/components/Organization/Pipelines/StageSubmit";
import moment from "moment";
import "moment-precise-range-plugin";

export default {
  name: "EnterprisePortal",
  components: {
    StageSubmit,
    MessagesDialog,
  },
  mixins: [HasApplicationPipelinesMixin, SupportsCustomizationMixin],
  props: {
    pipeline: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      dialogs: {
        video: false,
        value: null,
      },
    };
  },
  watch: {
    "$route.hash": function (value) {
      this.onShowApplicationFormOnHash();
    },
    fonts: function (fonts) {
      this.loadFonts(fonts);
    },
  },
  computed: {
    videoUrl() {
      return ((this.dialogs.value || {}).video || {}).url;
    },
    hasFaqButton() {
      return this.$teamSettings.applicant_portal_faq_button instanceof Object;
    },
    faqButton() {
      const button = this.$teamSettings.applicant_portal_faq_button;
      if (button instanceof Object && button.value) {
        return this.getCustomButton(button, "FAQ");
      } else {
        return {
          title: this.featureName("FAQs"),
          action: () => this.navigateTo({ name: "faq.index" }),
        };
      }
    },
    learnMoreButton() {
      const button = this.$teamSettings.applicant_portal_learn_more_button;
      if (button instanceof Object && button.value) {
        return this.getCustomButton(button, "Learn More");
      } else {
        return {
          title: "Learn More",
          action: () => this.navigateTo({ name: "lesson.index" }),
        };
      }
    },
    extraListItems() {
      return (this.$teamSettings.applicant_portal_extra_buttons || []).map(
        (button) => {
          return this.getCustomButton(button, button.name);
        }
      );
    },
    fonts() {
      return {
        primary: this.themeSetting("applicantPortal_primary_font"),
        secondary: this.themeSetting("applicantPortal_secondary_font"),
      };
    },
    containerStyle() {
      return {
        fontSize: this.themeSetting("applicantPortal_secondary_font_size"),
        color: this.themeSetting("applicantPortal_secondary_font_color"),
        fontFamily: this.getFontNameFrom(this.fonts.primary),
        maxHeight: this.$vuetify.breakpoint.mdAndUp ? "100vh" : "auto",
      };
    },
    titleFontStyle() {
      return {
        fontWeight: "normal",
        fontSize: `calc(${this.themeSetting(
          "applicantPortal_primary_font_size"
        )} + 33%)`,
        marginBottom: "10px",
      };
    },
    primaryFontStyle() {
      return {
        fontWeight: "normal",
        fontSize: this.themeSetting("applicantPortal_primary_font_size"),
        marginBottom: "10px",
      };
    },
    faqBtnStyle() {
      return {
        color: this.themeSetting("applicantPortal_primary_font_color"),
        backgroundColor: this.themeSetting("applicantPortal_primary_color"),
      };
    },
    leftPanelStyle() {
      return {
        paddingTop: "7.5vh",
        backgroundColor: this.themeSetting(
          "applicantPortal_left_panel_background_color"
        ),
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "cover",
        backgroundImage:
          this.themeSetting("applicantPortal_left_panel_background_url") !=
          "inherit"
            ? "url(" +
              this.themeSetting("applicantPortal_left_panel_background_url")
                .url +
              ")"
            : "none",
      };
    },
    rightPanelStyle() {
      return {
        paddingTop: this.$vuetify.breakpoint.mdAndUp ? "7.5vh" : "24px",
        backgroundColor: this.themeSetting(
          "applicantPortal_right_panel_background_color"
        ),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundImage:
          this.themeSetting("applicantPortal_right_panel_background_url") !=
          "inherit"
            ? "url(" +
              this.themeSetting("applicantPortal_right_panel_background_url")
                .url +
              ")"
            : "none",
      };
    },
  },
  methods: {
    loadFonts() {
      if (this.fonts instanceof Object) {
        if (this.fonts.primary) {
          this.fonts.primary && this.onLoadFont(this.fonts.primary);
        }
        if (this.fonts.secondary) {
          this.fonts.secondary && this.onLoadFont(this.fonts.secondary);
        }
      }
    },
    getCustomButton(button, defaultName) {
      let action;
      switch (button.type) {
        case "Video_Link":
          action = () => {
            this.dialogs.video = !this.dialogs.video;
            this.dialogs.value = {
              type: "Video",
              name: button.name,
              video: {
                url: this.getVideoUrl(button.value),
              },
            };
          };
          break;

        case "Internal_Url":
          action = () => this.navigateTo((button.value || {}).route || "#");
          break;

        case "Relation":
          action = () =>
            this.navigateTo({
              name: `${
                button.value.type == "lesson" ? "present" : button.value.type
              }.view`,
              params: {
                type: button.value.type,
                id: button.value.id,
              },
            });
          break;

        default:
          action = () => this.navigateTo(button.value, "_blank");
          break;
      }

      console.log("Returning", button.name || defaultName, action);
      return {
        title: button.name || defaultName,
        action,
      };
    },
    getVideoUrl(value) {
      try {
        const url = new URL(value);
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtu.be":
            const hostname =
              ["www.youtube.com", "youtube.com"].indexOf(url.hostname) === -1
                ? "www.youtube.com"
                : url.hostname;
            return `//${hostname}/embed/${
              url.searchParams.get("v") || url.pathname.substr(1)
            }?fs=1&autoplay=1`;
            break;

          case "vimeo.com":
          case "www.vimeo.com":
            return `//player.vimeo.com/video${url.pathname}?autoplay=1`;
            break;

          default:
            return value;
            break;
        }
      } catch (e) {
        return value;
      }
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {
    this.loadFonts();
    this.$emit("startLoading");
    const pipelineId =
      window.localStorage.getItem(
        `currentApplicationPipelineId${this.$team.id}`
      ) || "application";
    this.doGetApplication({
      id: "current",
      pipeline_id: pipelineId,
    })
      .then((application) => {
        this.currentApplicationModel = application;
        this.selectedPipeline = application.pipeline;
        this.onShowApplicationFormOnHash();
      })
      .catch((error) => {
        this.$log.debug("No applications");
      })
      .finally(() => {
        this.$emit("doneLoading");
      });
  },
};
</script>
<style lang="scss">
.enterprise-applicant-portal {
  a {
    color: currentColor !important;
  }
  .container {
    padding: 0px;
  }
  .bgImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .info-col {
    border-left: 1px solid #c7c4c4;
    padding: 15px 15px 0 15px;
  }
  .faq-btn {
    height: 52px;
    font-size: 1rem !important;
    @media (min-width: 961px) {
      min-width: 110px !important;
    }
    .v-btn__content {
      font-size: 1rem !important;
    }
  }
  .app_content_section h2 {
    padding: 0px;
  }
  .info-item {
    border: 1px solid #c7c4c4;
    border-radius: 10px !important;
    width: fit-content;
    white-space: break-spaces;
    background-color: transparent;
    min-height: 38px !important;
    .v-list-item__content {
      padding: 4px 0;
    }
  }
  .timeline {
    width: 100%;
    box-shadow: none;
    background: transparent !important;
  }
  .timeline .mdi-check::before {
    content: "" !important;
  }

  .v-stepper__step {
    padding: 16px;
    .v-stepper__step__step {
      width: 14px;
      height: 14px;
      max-width: 14px;
      min-width: 14px;
      margin-right: 0px;
    }
  }
  .theme--light.v-stepper
    .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    background: rgba(0, 0, 0, 0.15);
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    white-space: normal !important;
  }

  .green--text {
    color: #00c195 !important;
  }

  .red--text {
    color: #d96615 !important;
  }
}
</style>