var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.verticleView)?[_c('div',{style:({
        cursor: _vm.enableInfoDialog ? 'pointer' : 'auto',
        display: 'inline-grid',
        'text-decoration': _vm.isUserDeleted ? 'line-through' : 'none',
      }),on:{"click":function($event){_vm.enableInfoDialog ? _vm.isDialogOpen = !_vm.isDialogOpen : undefined}}},[_c('VAvatar',{staticClass:"ma-auto",attrs:{"color":"primary","size":"70px"}},[(_vm.isUserDeleted)?_c('VImg',{attrs:{"src":"https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"}}):_c('img',{attrs:{"src":_vm.user.photo_url}})],1),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"70px"}},[_c('span',{staticClass:"text-truncate pt-1",style:({
            'font-size': '14px', 
            'font-weight': 500, 
            'color': _vm.isUserDeleted ? '#666666' : '#252526',
          })},[_vm._v(" "+_vm._s(_vm.isUserDeleted ? "Deleted User" : _vm.user.first_name)+" ")])])],1)]:[_vm._t("default",function(){return [_c('VChip',{class:_vm.customClass,style:({
          cursor: _vm.enableInfoDialog ? 'pointer' : 'auto',
          'text-decoration': _vm.isUserDeleted ? 'line-through' : 'none',
        }),attrs:{"pill":"","outlined":_vm.outline,"color":_vm.color,"text-color":_vm.isUserDeleted ? '#666666' : _vm.textColor,"x-small":_vm.xSmall,"small":_vm.small,"large":_vm.large,"x-large":_vm.xLarge,"dark":_vm.dark,"light":_vm.light,"disabled":_vm.disabled,"close":_vm.closable},on:{"click":function($event){_vm.enableInfoDialog ? _vm.isDialogOpen = !_vm.isDialogOpen : undefined},"click:close":function($event){return _vm.$emit('close')}}},[_c('VAvatar',{attrs:{"left":""}},[(_vm.isUserDeleted)?_c('VImg',{attrs:{"src":"https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm"}}):_c('VImg',{attrs:{"src":_vm.user.photo_url}})],1),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.isUserDeleted ? "Deleted User" : _vm.user.name))]}),_vm._t("content-right")],2)]})],_c('Dialog',{attrs:{"max-width":"525px","card-text-class":"px-0","default-content-class":"","dismissable":false,"is-dialog-open":_vm.isDialogOpen && !_vm.isUserDeleted}},[(!_vm.isUserDeleted)?_c('UserProfile',{attrs:{"id":_vm.user.id,"user":_vm.user},on:{"close":function($event){_vm.isDialogOpen = false}}},[_c('template',{slot:"actions"},[(_vm.enableChat)?_c('ChatButton',{attrs:{"user":_vm.user,"title":_vm.title,"members":_vm.members,"entity":_vm.entity,"thread":_vm.thread,"new-messages-count":_vm.newMessagesCount,"enable-edit":_vm.enableEdit}}):_vm._e()],1)],2):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }