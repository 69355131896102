<template>
  <div>
    <VDialog
      v-model="isDialogOpen"
      persistent
      :max-width="maxWidth"
    >
      <VCard class="deleteOptions">
        <VToolbar
          dark
          dense
          text
          color="error"
        >
          <VToolbarTitle class="white--text">
            {{ title }}
          </VToolbarTitle>
        </VToolbar>
        <VCardText style="font-weight: 500;">
          <VRadioGroup 
            v-model="deleteType"
            mandatory
          >
            <VRadio 
              color="success"
              value="remove"
              :label="`Remove from ${modelType.capitalize()}`"
            />
            <VRadio 
              label="Delete Forever"
              color="success"
              value="delete"
            />
          </VRadioGroup>
        </VCardText>
        <VCardActions class="pt-0">
          <VCol class="pa-0 pr-1">
            <VBtn
              block
              :color="confirmColor"
              :disabled="isLoading || item.isLoading"
              :loading="isLoading || item.isLoading"
              @click="onSelect"
            >
              {{ confirmText }}
            </VBtn>
          </VCol>
          <VCol class="pa-0 pl-1">
            <VBtn
              block
              depressed
              :color="cancelColor"
              :disabled="isLoading || item.isLoading"
              @click="$emit('toggleDeleteDialog')"
            >
              {{ cancelText }}
            </VBtn>
          </VCol>
        </VCardActions>
      </VCard>
    </VDialog>
    <Confirm ref="confirmDelete" />
    <Confirm ref="confirmRemove" />
  </div>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import DeleteOptionsMixin from "@/mixins/DeleteOptions";
export default {
  props: {
    cancelText: {
      type: String,
      default: "No",
    },
    confirmText: {
      type: String,
      default: "Yes",
    },
    cancelColor: {
      type: String,
      default: "info",
    },
    confirmColor: {
      type: String,
      default: "error",
    },
    item: {
      type: Object,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: [String, Number],
      default: 290,
    },
    title: {
      type: String,
      default: "Delete Options"
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    parentModelType: {
      type: String,
      default: null,
    },
  },
  mixins: [SearchMixin, DeleteOptionsMixin],
  data() {
    return {
      deleteType: "remove",
    }
  },
  created() {
    this.deleteItem = this.item;
    this.deleteItemType = this.itemType;
  },
  watch: {
    item(value) {
      this.deleteItem = value;
    },
    itemType(value) {
      this.deleteItemType = value;
    },
  },
  methods: {
    onSelect() {
      if(this.deleteType == "remove") {
        this.onRemoveItem().then((status) => {
          if(status) {
            this.$emit("toggleDeleteDialog");
            this.$emit("toggleEditDialog");
          }
        });
      } else {
        this.doDeleteItem().then((status) => {
          if(status) {
            this.$emit("toggleDeleteDialog");
            this.$emit("toggleEditDialog");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.deleteOptions {
  .v-radio {
    border: none !important;
  }
}
</style>