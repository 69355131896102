var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VChip',{class:{
    'role--owner': _vm.isUserOwner,
    'role--student': _vm.isUserStudent,
    'role--mentor': _vm.isUserMentor,
    'role--organization-admin': _vm.isUserOrganizationAdmin,
    'role--missing': _vm.isUserUnassigned
  },attrs:{"rounded":"","text-color":"#000","small":_vm.small,"large":_vm.large,"x-large":_vm.xLarge}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.roleName)+" ")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }