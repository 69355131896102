<script>
export default {
  data() {
    return {
      dialogs: {
        form: false,
        view: false,
      },
      autoloadViewModel: true,
      currentMenuItem: null,
      currentModel: null,
    };
  },
  created() {
    if (
      this.$route.params.hasOwnProperty("id") &&
      parseInt(this.$route.params.id) > 0 &&
      this.onGet instanceof Function &&
      this.autoloadViewModel === true
    ) {
      this.onSetViewDialogModel(this.$route.params.id);
    }
  },
  methods: {
    onToggleMenuItem(item) {
      this.currentMenuItem = item.id;
    },
    onSetFormDialogModel(item, prop) {
      this.currentModel = item;
      this.$set(this.dialogs, prop || "form", true);
    },
    onSetViewDialogModel(item, prop, event) {
      if (event) {
        event.stopPropagation();
      }
      if (item instanceof Object) {
        this.currentModel = item;
        this.$set(this.dialogs, prop || "view", true);
      } else if (this.onGet instanceof Function) {
        this.onGet(item).then((model) => {
          this.currentModel = model;
          this.$set(this.dialogs, prop || "view", true);
        });
      }
      return false;
    },
    onToggleFormDialog(prop) {
      this.$set(this.dialogs, prop || "form", !this.dialogs[prop || "form"]);
      this.currentModel = null;
    },
    onToggleViewDialog(prop) {
      this.$set(this.dialogs, prop || "view", !this.dialogs[prop || "view"]);
      this.currentModel = null;
    },
  },
};
</script>