import BaseApiCore from "@nitm/js-api-client-base/dist/services";
/**
 * This class provides some core functionality that other modules can extend from
 *
 * @class BaseApi
 */
class BaseApi extends BaseApiCore {
	/**
	 *Creates an instance of BaseApi.
	 * @param {Object} props
	 * @memberof BaseApi
	 */
	constructor(_props) {
		const props = _props || {};
		super(props);
		this.$app = props.$app || {};
		this.setApi(this.$app.axios);
		this.setLogger(this.$app.$log);
	}

	getBasePath(action, app, params) {
		const realApp = app || this.$app;
		return (
			this.basePath ||
			`api/${(realApp.$spark || {}).teamsPrefix || "teams"}/${
				(realApp.$team || {}).id || -1
			}`
		);
	}

	sortRelation(params) {
		this.log().info("[Services: " + this.type + "]: Sort Relation", params);
		if (
			!(params instanceof Object) ||
			(params instanceof Object && (!params.relation || !params.id))
		) {
			const error = `
			Expected Object with the format:
			{
				id: number,
				relation: string,
				items: array | object
			}

			Received: ${JSON.stringify(params)}
			`;
			throw new Error(error);
		}
		let url = this.getUrl("save", null);
		if (params.id && params.relation) {
			url += `/${params.id}/sort/${params.relation || "ERROR"}`;
		} else if (params.relation) {
			url += `/sort/${params.relation}`;
		} else {
			url += `/sort`;
		}
		return this.api()
			.post(url, params)
			.catch((error) => {
				if (["testing", undefined].indexOf(process.env.ENV) == -1) {
					this.log().error(
						"[Services: " + this.type + "]: Sort Relation]: Error",
						error
					);
					throw this.utils.resolveError(error);
				}
			});
	}
}

export default BaseApi;
