<template>
  <AppLayout
    :enable-background="false"
    :enable-footer="false"
  >
    <MobileSignup />
  </AppLayout>
</template>
<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import MobileSignup from "@/components/Register/MobileSignup";

export default {
  name: "Launch",
  components: {
    AppLayout,
    MobileSignup,
  },
};
</script>