<template>
  <div class="calendar-form">
    <VContainer
      fluid
      grid-list-md
      :class="{
        'is-loading': activity.isLoading
      }"
    >
      <VRow
        row
        wrap
      >
        <VCol v-if="hasMessagesOrErrors">
          <Alerts
            :messages="messages"
            :errors="errors"
          />
        </VCol>
        <VCol cols="12">
          <Autocomplete
            v-model="form.entity_type"
            :items="entityTypes"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            placeholder="Attach to..."
            label="Calendar Type"
            @input="(value) => selectedEntityType = value"
          />
        </VCol>
        <VCol
          v-if="form.entity_type"
          cols="12"
        >
          <Autocomplete
            v-model="form.entity_id"
            :items="entityOptions"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            :placeholder="`Select ${selectedEntityTitle}`"
            :label="selectedEntityTitle"
          />
        </VCol>
        <VCol cols="12">
          <TextField
            v-model="form.title"
            name="form.title"
            label="Title"
            placeholder="Enter title..."
            :error="$v.form.title.$error"
            @input="$v.form.title.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol cols="12">
          <Swatches
            v-model="form.color"
            inline
          />
        </VCol>
      </VRow>
      <VRow
        v-if="false"
        row
        wrap
        class="grey lighten-3 px-2 pb-1 mx-1"
      >
        <VCol cols="12">
          <VAlert
            type="info"
            :value="true"
          >
            <h2>Add the first event</h2>
            <p>You may add more events directly to the calendar later.</p>
          </VAlert>
        </VCol>
        <EventCommon
          v-model="form"
          event-mode="calendar"
          :options="options"
          :validator="$v"
        />
      </VRow>
      <VRow
        row
        wrap
        justify-space-between
      >
        <VCol cols="12">
          <VRow
            row
            justify-space-between
            align-center
          >
            <h4>Members</h4>
            <VBtn
              color="primary"
              text
              @click="onToggleMembersDialog"
            >
              <VIcon class="wethrive">
                add
              </VIcon>Add Members
            </VBtn>
          </VRow>
        </VCol>
        <VCol cols="12">
          <MembersList
            v-if="form.members && form.members.length"
            :users="form.members"
            @removeMember="removeMember"
          />
          <p v-else>
            No members have been added to this calendar
          </p>
        </VCol>
      </VRow>
      <VRow
        v-if="form.id"
        row
        wrap
        style="height: 500px"
      >
        <VCol
          cols="12"
          v-if="wasCreateMode"
        >
          <VAlert
            type="info"
            :value="true"
          >
            <h2>Add the first event</h2>
            <p>
              Click the&nbsp;
              <VIcon>event</VIcon>&nbsp;icon below.
            </p>
            <p>You can also add more events to this calendar later.</p>
          </VAlert>
        </VCol>
        <VCol
          cols="12"
          fill-height
        >
          <h4>Manage Events</h4>
          <Calendar
            calendar-mode="single"
            month-name-format="short"
            class="theme-default holiday-us-traditional holiday-us-official"
            :fill-height="true"
            :can-create="false"
            :can-create-event="true"
            :events="events"
            :calendar="form"
            :entity="realEntity"
            :entity-type="form.entity_type"
            :show-event-times="false"
            :show-date="showDate"
            @click-date="$emit('day-changed')"
            @input="setShowDate"
          />
        </VCol>
      </VRow>
      <VCardActions
        v-if="mode !== 'dialog'"
        class="justify-center"
      >
        <VBtn
          large
          class="wethrive-alt"
          :loading="activity.isFormLoading"
          @click="onSave"
        >
          Save
        </VBtn>
      </VCardActions>
    </VContainer>
    <Dialog
      :hide-overlay="true"
      :is-dialog-open="dialogs.members"
      :title="`Add ${$team.user_role_name}`"
      @toggle:dialog="onToggleMembersDialog"
    >
      <MembersForm
        ref="addMembers"
        type="any"
        mode="multiple"
        :existing="existingMembers"
        :is-group="true"
        :autosave="false"
        @addMember="addMember"
        @removeMember="removeMember"
        @saveMembers="dialogs.members = false"
      />
    </Dialog>
  </div>
</template>
<script>
import Swatches from "vue-swatches";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/CalendarForm";
import { required, minLength, between } from "vuelidate/lib/validators";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import EventCommon from "./EventCommon";
import moment from "moment";
import Vue from "vue";

export default {
  name: "SaveLocalCalendarForm",
  components: {
    MembersForm,
    MembersList,
    EventCommon,
    Swatches
  },
  mixins: [FormMixin],
  data() {
    return {};
  }
};
</script>


<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
</style>
