import BaseApi from "./_base-api";

/**
 * NotificationPreference API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
    /**
     *Creates an instance of BaseApi.
     * @param {Object} props
     * @memberof BaseApi
     */
    constructor(_props) {
        const props = _props instanceof Object ? _props : {};
        super({ ...props, ...{ basePath: 'api/users' } });
    }
}

const api = new Api({
    type: "NotificationPreference",
    path: "notifications/preferences",
});
export default api;
export { Api };
