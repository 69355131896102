<template>
  <VRow column>
    <VCol 
      v-if="!hasExistingData" 
      style="flex: 0 0 64px"
    >
      <h1>{{ featureName(modelTypeTitle) }}</h1>
    </VCol>
    <VCol
      v-if="!hasExistingData"
      mb-2
      shrink
      class="pa-5"
    >
      <Stats
        :stats="stats"
        :options="statOptions"
        :full-width="false"
      >
        <template slot="append-actions">
          <VCol
            px-0
            class="mt-6 text-right"
          >
            <ExpandableButton
              v-if="isEnabledFeature(modelTypeAlt.pluralize())"
              icon=""
              button-class="mr-3 text-center"
              color="info"
              :text="featureName(modelTypeAltTitle)"
              :depressed="true"
              :large="true"
              :flex="true"
              :block="$vuetify.breakpoint.smAndDown"
              :to="{
                name: `${modelTypeAltRoute}.index`,
                params: {team: $team.slug}
              }"
            />
            <ExpandableButton
              v-if="hasActionsAccess()"
              icon="add"
              button-class="wethrive"
              :large="true"
              :flex="true"
              :block="$vuetify.breakpoint.smAndDown"
              :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
              @click="onToggleFormDialog"
            />
          </VCol>
        </template>
      </Stats>
    </VCol>
    <VCol style="flex: 1 1 auto">
      <CurrentFilter
        v-if="getQueryFilterValues.length > 0"
        class="rounded-lg pt-2 pb-6 mx-0 mb-n3 white"
        :filter="filter"
        :filter-values="getQueryFilterValues"
        :is-loading="activity.isLoading"
        @removeFilter="onRemoveFilter"
        @resetFilter="onResetFilter"
      />
      <DataViewUI
        hide-actions
        hide-records-info
        :data="getData"
        :headers="headers"
        :is-loading="activity.isLoading"
        :hide-main-title="hasExistingData"
        :disable-sort="hasExistingData"
        :enable-infinite-loader="enableInfiniteLoader"
        :class="{
          'pa-3 pt-0': hasExistingData
        }"
        @paginate="onGetMore"
        @search="onSearch"
        @infiniteLoad="onInfiniteLoad"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>
            {{ props.item.title }}
            <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, props.item.id)" />
          </td>
          <template v-if="$isUserAdmin && $viewProgramAsAdmin">
            <td>
              <UserModal :user="props.item.user" />
            </td>
          </template>
          <td>{{ props.item.deliverables_count || 0 }}</td>
          <td class="actions">
            <Menu
              :disabled="getIsDisabled(props.item)"
              :loading="props.item.isLoading"
            >
              <VListItem
                v-if="hasActionsAccess(props.item)"
                :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                @click.prevent="onSetFormDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="props.item.userNeedsToSubmitReports"
                :href="getHrefForItem(`${modelTypeRoute}.submit`, props.item.id)"
                @click.prevent="setSubmittedDialogData(props.item, 'reports')"
              >
                <VListItemTitle>
                  <VIcon small>
                    save
                  </VIcon>
                  &nbsp;
                  Submit {{ featureName('Reports') }}
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="props.item.userNeedsToSubmitDeliverables"
                :href="getHrefForItem(`${modelTypeRoute}.submit`, props.item.id)"
                @click.prevent="setSubmittedDialogData(props.item, 'deliverables')"
              >
                <VListItemTitle>
                  <VIcon small>
                    save
                  </VIcon>
                  &nbsp;
                  Submit {{ featureName('Deliverables') }}
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="hasActionsAccess(props.item)"
                :href="getHrefForItem(`${modelTypeRoute}.submitted`, props.item.id)"
                @click.prevent="onShowSubmitted(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    check
                  </VIcon>&nbsp;
                  View Read
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin && $viewProgramAsAdmin"
                @click.prevent="onDuplicateItem(props.item, false, false, true, true)"
              >
                <VListItemTitle>
                  <VIcon small>
                    content_copy
                  </VIcon>&nbsp;Duplicate
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="hasActionsAccess(props.item)"
                @click.prevent="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        new-actions
        max-width="570px"
        button-text="Save Changes"
        :can-delete="false"
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel ? currentModel.title : `Add ${featureName(modelType.capitalize()).singularize()}`"
        :model-type="modelType"
        @deleteItem="onDeleteItem"
        @toggle:form:dialog="onToggleFormDialog"
      >
        <Save
          ref="saveForm"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
          @saved="result => $emit('updated', result)"
          @duplicated="result => $emit('duplicated', result)"
          @updateModel="onToggleFormDialog('form')"
        />
      </FormDialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Content')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          :id="currentModel ? currentModel.id : null"
          ref="viewItem"
          mode="dialog"
        />
      </Dialog>
      <Confirm ref="confirmDelete" />
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Dialog
        max-width="75%"
        :is-dialog-open="submittedDialogs.submitted"
        :title="`Read ${featureName('Content')}: ${submittedModel.title ? submittedModel.title : ''}`"
        @toggle:dialog="onToggleSubmittedDialog"
      >
        <Submitted
          mode="dialog"
          :content-id="submittedModel.id"
          :is-from-existing-data="hasExistingData"
        />
      </Dialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.reports"
        :title="`Submit ${featureName('Reports')}`"
        @toggle:dialog="onToggleSubmittedReportsDialog"
      >
        <SubmitReports
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="primary_content_id"
        />
      </Dialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.deliverables"
        :title="`Submit ${featureName('Deliverables')}`"
        @toggle:dialog="onToggleSubmittedDeliverablesDialog"
      >
        <SubmitDeliverables
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="primary_content_id"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import HasSubmittedData from "../../mixins/HasSubmittedData";
import Save from "./Save";
import ViewComponent from "./View";
import Submitted from "./Submitted";
import { mapActions } from "vuex";
import SubmitDeliverables from "@/components/Deliverables/Submit";

export default {
  name: "ContentIndex",
  components: {
    Save,
    ViewComponent,
    SubmitDeliverables,
    Submitted,
  },
  mixins: [SearchMixin, HasStats, HasSubmittedData],
  data() {
    return {
      dialogs: {
        complete: false,
      },
      isCompleting: false,
      date: null,
      modelType: "content",
      modelTypeAlt: "supplement",
      hasDataTable: true,
      config: {
        stats: {
          "-": "-",
        },
      },
      statOptions: {
        inflect: {
          content: {
            singular: "Content",
            plural: "Content",
          },
          users_read: {
            singular: "Users Read",
            plural: "Users Read",
          },
          read_this_week: {
            singular: "Read This Week",
            plural: "Read This Week",
          },
        },
      },
      autoloadViewModel: false,
      enableInfiniteLoader: true,
    };
  },
  computed: {
    stats() {
      if (this.$isUserAdmin && this.$viewProgramAsAdmin) {
        delete this.config.stats.staff;
        return this.config.stats;
      } else {
        return {
          content: this.config.stats.content,
        };
      }
    },
    headers() {
      let headers;
      if (this.$isUserAdmin && this.$viewProgramAsAdmin) {
        headers = [
          {
            text: "Name",
            align: "left",
            sortable: true,
            value: "title",
          },
          {
            text: "Created By",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Deliverables"),
            align: "left",
            sortable: true,
            value: "deliverables_count",
          },
        ];
      } else {
        headers = [
          {
            text: "Name",
            align: "left",
            sortable: true,
            value: "title",
          },
          {
            text: this.featureName("Deliverables"),
            align: "left",
            sortable: true,
            value: "deliverables_count",
          },
        ];
      }
      return headers;
    },
    getData() {
      return this.infiniteLoad.data.length > 0 ? this.infiniteLoad : this.data;
    },
  },
  created() {
    if(this.hasExistingData) {
      this.enableInfiniteLoader = false;
    } else {
      this.doGetIndexConfig().then((result) => (this.config = result));
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "content/getIndexConfig",
      doGetContents: "content/getAll",
      doGetContent: "content/getOne",
      doDeleteContent: "content/delete",
      doGetSubmitted: "contentCompleted/getAll",
      doDuplicateContent: "content/duplicate",
    }),
    onDuplicate(params) {
      return this.doDuplicateContent(params);
    },
    onDelete(content) {
      return this.doDeleteContent(content.id);
    },
    onGetAll(params) {
      return this.doGetContents(params).then((result) => {
        // Handled duplication loading card
        if(!params.hasOwnProperty("page") || (params.hasOwnProperty("page") && params.page == 1)) {
          const pendingDuplications = this.getGroupItemLocalStorage("pending-duplications", this.modelType);
          if(pendingDuplications && Object.keys(pendingDuplications).length > 0) {
            Object.keys(pendingDuplications).map((itemKey) => {
              const itemIndex = result.data.findIndex((item) => item.duplicated_using_id == itemKey.split("-")[0]);
              if(itemIndex > -1) {
                this.$delete(pendingDuplications, itemKey);
                this.removeGroupItemLocalStorage("pending-duplications", this.modelType, itemKey);
              }
            });
          }
          this.$set(result, "data", [
            ...Object.values(pendingDuplications),
            ...result.data,
          ]);
        }
        return result;
      });
    },
    onGet(id) {
      return this.doGetContent(id);
    },
    deleteItem(params) {
      this.onDeleteItem(params).then((result) => {
        if(result) {
          this.onToggleFormDialog("form");
        }
        return result;
      })
    },
  },
};
</script>

