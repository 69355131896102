import api from "../../services/productCoupon";
export default function (helper) {
	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("product_coupon");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions({}, "Product Coupon");
	const mutations = helper.mutations({}, types, state);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
