<template>
  <EditorBase
    v-bind="$attrs"
    v-on="$listeners"
    editor-type="editor-menu-bubble"
    :editor="editor"
    :label="label"
    :error="error"
    :readonly="readonly"
    :menu-position="menuPosition"
    :placeholder="placeholder"
    @click="$emit('click')"
    @blur="$emit('blur')"
  >
    <template #buttons="{ commands, isActive }">
      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <VIcon>format_bold</VIcon>
          </VBtn>
        </template>
        Bold (Ctrl+B)
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <VIcon>format_italic</VIcon>
          </VBtn>
        </template>
        Italic (Ctrl+I)
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <VIcon>format_strikethrough</VIcon>
          </VBtn>
        </template>
        Strikethrough
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <VIcon>format_underline</VIcon>
          </VBtn>
        </template>
        Underline (Ctrl+U)
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
          >
            <VIcon>code</VIcon>
          </VBtn>
        </template>
        Code
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <VIcon>short_text</VIcon>
          </VBtn>
        </template>
        Paragraph
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </VBtn>
        </template>
        Heading 1
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </VBtn>
        </template>
        Heading 2
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </VBtn>
        </template>
        Heading 3
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <VIcon>format_list_bulleted</VIcon>
          </VBtn>
        </template>
        Bulleted List
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <VIcon>format_list_numbered</VIcon>
          </VBtn>
        </template>
        Numbered List
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <VIcon>format_quote</VIcon>
          </VBtn>
        </template>
        Block Quote
      </VTooltip>

      <!-- <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <VIcon>mdi-code-brackets</VIcon>
          </VBtn>
        </template>
        Code Block
      </VTooltip> -->

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            @click="commands.horizontal_rule"
          >
            <VIcon>minimize</VIcon>
          </VBtn>
        </template>
        Horizontal Line
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            @click="commands.undo"
          >
            <VIcon>undo</VIcon>
          </VBtn>
        </template>
        Undo (Ctrl+Z)
      </VTooltip>

      <VTooltip top>
        <template #activator="{ on, attrs }">
          <VBtn
            v-on="on"
            v-bind="attrs"
            icon
            text
            small
            class="menubar__button"
            @click="commands.redo"
          >
            <VIcon>redo</VIcon>
          </VBtn>
        </template>
        Redo (Ctrl+Y)
      </VTooltip>
    </template>
  </EditorBase>
</template>

<script>
import { Editor, Doc } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  History,
  Placeholder,
} from "tiptap-extensions";
import EditorBase from "./EditorBase";
import Link from "./EditorLink.js";
import EditorMixin from "./EditorMixin";

export default {
  props: {
    menuPosition: {
      type: String,
      default: "top",
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditorBase,
  },
  mixins: [EditorMixin],
  data() {
    return {
      innerHTML: "",
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          this.innerHTML = getHTML();
          // Adding this fix here to update whenever there is an input
          this.debouncer(
            () => this.$emit("input", this.innerHTML),
            `editor-${this.uid}`
          );
        },
        onBlur: ({ state }) => {
          /**
           * Commented below code to fix the editor content being replaced issue
           * JIRA - https://wethrive.atlassian.net/browse/PM-285
           * */ 
          // this.$emit("input", this.innerHTML);
        },
        extensions: [
          new Doc(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            showOnlyWhenEditable: false,
            emptyNodeText: this.placeholder,
          }),
        ],
        content: null,
      }),
    };
  },
};
</script>