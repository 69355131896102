<template>
  <VCard
    class="layout column bg-transparent fill-height chat-messages pa-0"
    :style="{
      borderRadius: '10px 0px 0px 10px !important'
    }"
  >
    <VCardTitle
      v-if="!editThreadMode"
      class="content-bg"
      :style="{
        padding: '0px 12px',
        minHeight: '52px',
        maxHeight: '52px',
        height: '52px',
        flex: '1 1 52px',
        borderRadius: '10px 0px 0px 0px !important'
      }"
    >
      <VBtn
        v-if="threadDrawerActive"
        text
        icon
        color="primary"
        style="max-width: 72px"
        @click="$emit('toggleDrawer')"
      >
        <VIcon>forum</VIcon>
      </VBtn>
      <VRow
        row
        wrap
        align-center
        class="mt-0"
      >
        <VCol
          v-if="threadEditable"
          shrink
          style="border-right: solid 1px rgba(0, 0, 0, 0.05); flex-basis: 90px; text-align: left"
        >
          <VTooltip bottom>
            <template #activator="{on}">
              <VBtn
                v-on="on"
                text
                small
                style="margin: 0px auto"
                @click="onToggleEditThread"
              >
                <VAvatar
                  v-if="!isGroup && thread && thread.to"
                  :size="20"
                >
                  <img :src="avatar">
                </VAvatar>
                <template v-else>
                  <img
                    width="20"
                    height="20"
                    :src="peopleIcon"
                  >
                  &nbsp;<strong class="subtitle-2 font-weight-bold ml-1">{{ thread.group.members.length || 0 }}</strong>
                </template>
              </VBtn>
            </template>
            <span>Edit this thread</span>
          </VTooltip>
        </VCol>
        <VCol
          cols="auto"
          class="mx-auto"
        >
          <BetaBadge 
            is-beta
            offset-x="-1"
            :color="thread.is_private ? 'secondary' : 'success'"
            :label="thread.is_private ? 'Private' : 'Public'"
            :value="forHuddle"
          >
            <span class="subtitle-2 font-weight-bold text-wrap">
              {{ title || thread.title }}
            </span>
          </BetaBadge>
          <Linkable
            v-if="threadEditable && !forHuddle"
            :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.index`, thread.thread_id)"
          />
        </VCol>
        <VCol
          v-if="dismissable"
          cols="auto"
        >
          <VBtn
            icon
            @click.native="$emit('toggle:dialog')"
          >
            <VIcon color="error">
              close
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText
      id="messages-container"
      :style="{
        height: editThreadMode ? '100vh' : 'calc(100vh - 224px)',
        backgroundColor: editThreadMode ? 'transparent' : '#fff'
      }"
      :class="{
        flex: true,
        messages: true,
        'pa-0': true,
        'is-loading': (isFirstLoad && isLoading) || activity.isFormLoading
      }"
    >
      <VList
        v-if="!editThreadMode"
        two-line
        ref="messages"
        id="infinite-messages"
        :class="{
          'messages-list': true,
          layout: true,
          column: true,
          'align-end': true,
          'justify-end': true,
          'px-3': true
        }"
      >
        <template v-if="hasMessages">
          <infinite-loading
            v-if="canGetMore"
            direction="top"
            force-use-infinite-wrapper="#infinite-messages"
            :distance="25"
            :identifier="new Date()"
            @infinite="getMessages"
          />
          <Message
            v-for="(message, key) in sortedData"
            :key="key"
            :message="message"
          />
        </template>
        <VListItem v-else>
          <h3>No messages</h3>
        </VListItem>
      </VList>
      <StartChat
        v-if="editThreadMode"
        force-group
        ref="startChat"
        class="messages-list fill-height"
        :tabs="false"
        :existing="thread"
        :for-huddle="forHuddle"
        :parent-is-saving="isLoading"
        :custom-filter="customFilter"
        :hide-filters="hideFilters"
        @startThread="onSaveThread"
        @closeEditor="editThreadMode = false"
        @startGroupThread="onSaveThread"
        :style="{
          width: '100%',
          overflowY: 'hidden',
        }"
      />
    </VCardText>
    <VCardActions
      v-if="!editThreadMode"
      class="flex shrink py-0"
    >
      <ChatForm
        class="chat-form-container"
        :thread="thread"
        :old-thread="oldThread"
        @addMessage="onAddMessage"
        @updateThreadTimestamp="onUpdateThreadTimestamp"
      />
    </VCardActions>
  </VCard>
  <!-- .b__messanger-chat -->
</template>


<script>
import { mapActions } from "vuex";
import ChatForm from "./Form";
import Message from "./Message";
// import Members from "./Members";
import StartChat from "./StartChat";
import HasChatThreads from "@/mixins/HasChatThreads";
import peopleIcon from "../../images/people-icon@3x.png";
import SupportsModelTypes from "../../mixins/SupportsModelTypes";

export default {
  components: {
    ChatForm,
    Message,
    // Members,
    StartChat,
  },
  mixins: [HasChatThreads, SupportsModelTypes],
  props: {
    title: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    oldThread: {
      type: Object,
      default: () => {},
    },
    thread: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => {},
    },
    pusher: {
      type: Object,
      default: () => {},
    },
    threadDrawerActive: {
      type: Boolean,
      default: false,
    },
    ensureThread: {
      type: Boolean,
      default: false,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    threadEditable: {
      type: Boolean,
      default: true,
    },
    forHuddle: {
      type: Boolean,
      default: false,
    },
    customFilter: {
      type: Object,
      default: function () {
        return null;
      },
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      peopleIcon,
      isFirstLoad: true,
      dialogs: {
        editChat: false,
      },
      channel: null,
      presenceChannel: null,
      data: {
        data: [],
        total: 0,
      },
      modelType: "chat",
      /**
       * WARN: CHange this back for ajax loading of threads
       */
      currentThread: null,
      isLoading: true,
      eventName: `ChatMessageWasReceived`,
      currentThreadId: null,
    };
  },
  computed: {
    sortedData() {
      return this.data.data.sortBy((d) => {
        return d.updated_at instanceof Date
          ? d.updated_at.getTime()
          : new Date(d.updated_at).getTime();
      });
    },
    canGetMore() {
      return (
        !this.isFirstLoad &&
        this.hasMessages &&
        this.data.current_page < this.data.last_page
      );
    },
    isGroup() {
      return this.thread instanceof Object && this.thread.group_id != null;
    },
    realPusher: function () {
      return this.$echo || this.$parent.pusher;
    },
    hasNotifications() {
      return this.data && this.data.total >= 1;
    },
    hasMembers() {
      return (
        this.thread instanceof Object &&
        this.thread.group instanceof Object &&
        this.thread.group.members instanceof Array &&
        this.thread.group.members.length > 0
      );
    },
    hasMessages() {
      return (
        this.data instanceof Object &&
        this.data.data instanceof Array &&
        this.data.data.length > 0
      );
    },
    threadMembers() {
      return this.hasMembers ? this.thread.group.members : [];
    },
    userStatus() {
      return this.thread && this.thread.user
        ? this.thread.user.status || ""
        : "";
    },
    avatar() {
      if (!(this.thread instanceof Object)) {
        return "";
      }
      const user =
        this.thread.user_id === this.$user.id
          ? this.thread.to
          : this.thread.user;
      return (user || {}).photo_url;
    },
  },
  watch: {
    thread: function (thread) {
      this.editThreadMode = false;
      this.isFirstLoad = true;
      if (
        thread instanceof Object &&
        thread.id != (this.currentThread || {}).id
      ) {
        this.loadThread(thread);
        // WHen the thread is switched set this to 0 so that the first page will automatically be set to 1 properly
        this.$set(this.data, "current_page", 0);
      }
    },
  },
  created() {
    this.loadThread();
  },
  beforeDestroy() {
    this.$log.debug("Messages: Unsubscribing from: " + this.getChannelName());
    this.realPusher.unsubscribe(this.getChannelName());
  },
  methods: {
    ...mapActions({
      doGetMessages: "chat/getMessages",
      doClearMessages: "chat/clearMessages",
      doSaveGroupThread: "chat/saveGroupThread",
    }),
    loadThread(_thread) {
      const thread = _thread || this.thread;
      if (
        thread instanceof Object &&
        thread.id &&
        thread.id != this.currentThreadId
      ) {
        this.currentThreadId = thread.id;
        this.$log.debug("Messages: Mounted. Thread changed to: ", thread.title);
        this.$set(this.data, "data", []);
        this.currentThread = thread;
        this.doClearMessages().then(() => {
          this.getMessages();
        });
        this.updateChannel();
      } else if (this.ensureThread && !this.thread.id) {
        this.newThread({
          ...this.thread,
          ...{
            ensure: this.ensureThread,
          },
          ...this.entity,
        });
      } else {
        this.isLoading = false;
        this.$log.debug("Messages: Mounted. Thread (not set)");
      }
    },
    getMessages($infinite) {
      if (!$infinite) this.isLoading = true;
      let id = this.thread.thread_id;
      this.$log.debug("Messages: Loading messages from thread", id);
      const thread = {
        ...{
          thread_id: id,
          ensure: this.ensureThread,
        },
        ...this.entity,
        ...{
          page:
            this.data.data && this.data.current_page < this.data.last_page
              ? this.data.current_page + 1
              : 1,
        },
      };
      return this.doGetMessages(thread)
        .then((response) => {
          console.log("Got messages", response);
          if (response.current_page === 1) {
            this.data = response;
            this.isFirstLoad = true;
          } else {
            this.data.current_page =
              response.current_page || this.data.current_page;
            this.data.to = response.to || this.data.to;
            this.data.data.unshift(...(response.data || []));
          }
          if (this.thread.new_message_count) {
            this.thread.new_message_count.count -= response.data.length;
          } else {
            this.thread.new_message_count = {
              count: this.data.data.length,
            };
          }
          const resultThread = (response.data[0] || {}).thread || {
            new_message_for_user_count: 0,
          };
          this.isLoading = false;

          setTimeout(() => {
            if ($infinite) {
              $infinite.loaded();
              if (response.current_page === response.last_page) {
                $infinite.complete();
              }
            }
          }, 2500);
          this.$emit("messagesLoaded", resultThread);
          return response;
        })
        .finally(() => {
          this.scrollMessages();
          this.isFirstLoad = false;
          this.isLoading = false;
        });
    },
    onAddMessage(message) {
      this.$log.debug("Messages: Adding new message", message);
      this.data.data.push(...message);
      this.data.total += message.length;
      this.thread.last_message = message.pop();
      this.scrollMessages();
    },
    onSaveThread(thread) {
      this.threadForm = thread;
      return this.saveThread().then((updatedThread) => {
        this.onUpdateThread(updatedThread);
      });
    },
    onUpdateThread(thread) {
      this.$emit("updateThread", thread);
    },
    onUpdateThreadTimestamp(id) {
      this.$emit("updateThreadTimestamp", id);
    },
    scrollMessages() {
      const callback = () => {
        try {
          const container =
            this.$refs.hasOwnProperty("messages") && this.$refs.messages
              ? this.$refs.messages.$el
              : null;
          if (container) {
            const lastChild = container.querySelector(".chat-item:last-child");
            if (lastChild) {
              const toPosition =
                container.scrollHeight + lastChild.clientHeight;
              this.$log.debug("Scrolling to", toPosition);
              container.scroll({
                top: toPosition,
                behavior: "smooth",
              });
            }
          }
        } catch (e) {
          this.$log.error(e);
        }
      };
      setTimeout(callback, 250);
    },
    getChannelName(_channel) {
      let channel = _channel;
      if (!_channel) {
        if (this.thread instanceof Object && this.thread.group_id) {
          channel = `group-chat.${this.thread.thread_id}.${this.$user.id}`;
        } else if (this.thread instanceof Object) {
          channel = `dm-chat.${this.thread.thread_id}.${this.$user.id}`;
        }
      }
      return channel;
    },
    updateChannel(_channel) {
      let channel = this.getChannelName(_channel);
      if (this.realPusher) {
        this.channel = this.channel || this.realPusher.private(channel);
        // this.presenceChannel = this.realPusher.connector.presenceChannel(channel);
        //Bind a function to a Event (the full Laravel class)
        let monitor = this.channel.listen(this.eventName, (data) => {
          this.$log.debug("Messages: Received from pusher", data);
          let messages =
            data.message instanceof Array ? data.message : [data.message];
          if (data.message.user_id != this.$user.id) {
            this.onAddMessage(messages);
          } else {
            this.thread.last_message = messages.pop();
          }
        });
        this.$log.debug(
          "Messages: Pusher subscribed to [" + channel + "]",
          this.eventName,
          monitor
        );
      }
    },
    threadName(thread) {
      if (thread) {
        if (thread.to_id == this.$user.id) {
          return thread.user instanceof Object
            ? thread.user.name
            : thread.title;
        }
        return thread.to instanceof Object ? thread.to.name : thread.title;
      }
      return "";
    },
    newThread(thread) {
      this.isLoading = true;
      this.$log.debug("Messages: Creating group thread", thread);
      return this.doSaveGroupThread(thread)
        .then((thread) => {
          if (thread.status != "error") {
            this.$log.debug("Messages: Created group thread", thread);
            this.$emit("newThread", thread);
            // Add the thread to the threads components
          } else {
            this.$log.debug("Messages: Create group thread error", thread);
            this.errors = thread;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";

.chat-messages {
  margin-top: 0px !important;
  align-items: stretch;
  overflow: hidden;
}

.chat-data.v-card {
  background: transparent;
}

.chat-messages .v-card__title {
  flex: 0 1 auto;
  max-height: 96px;
  background-color: $white-color;
  box-shadow: none;
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.chat-messages > .v-card__title span:not(.v-badge__badge) {
  color: $content-title-color;
  font-size: 14px;
}

.chat-messages .v-list__tile__avatar {
  margin-right: 20px;
}

.chat-messages .messages {
  flex: 1 1 100%;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  overflow-y: auto;
  z-index: 1;
}

.chat-messages .messages .messages-list {
  background-color: transparent;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 0;
}

.chat-messages .member-container {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chat-messages .member-container .v-chip__content {
  flex: 0 0 auto;
}
</style>