<template>
  <div>
    <template v-if="altView">
      <VRow class="files-chips-list">
        <VCol 
          v-for="(item, index) in files"
          lg="4"
          md="6"
          sm="12"
          :key="index"
        >
          <VChip
            close
            style="height: 40px;"
            @click:close="onRemoveFile(index, item)"
          >
            <VAvatar>
              <img
                v-if="item.type && isImageType(item.type)"
                :id="`img${index}`"
                :src="getMediaFromFileObject(item, `#img${index}`)"
              >
              <VIcon v-else>
                {{ getIcon(item.type) }}
              </VIcon>
            </VAvatar>
            <span
              class="ml-2 mr-6 text-truncate"
              style="color: #252526; font-weight: 900; font-size: 16px;"
            >
              <a
                target="_blank"
                class="text-truncate text-no-wrap"
                :href="item.url"
                :title="item.name"
                @click.prevent="doPreviewFiles(item)"
              >
                {{ getFileName(item) }}
              </a>
            </span>
          </VChip>
        </VCol>
        <VCol
          lg="4"
          md="6"
          sm="12"
        >
          <VBtn
            v-model="filename"
            single-line
            :label="label"
            :required="required"
            :disabled="disabled || readonly"
            :icon="asIconButton"
            :text="flat || textButton"
            :outlined="outline"
            :class="buttonClass"
            :rounded="round"
            :style="buttonStyle"
            :block="block"
            :loading="loading"
            @click.native="onFocus"
          >
            <VIcon
              v-if="icon"
              color="#bfbfbf"
            >
              {{ icon }}
            </VIcon>
            <img
              v-else
              class="mr-2"
              :height="iconHeight"
              :width="iconWidth"
              :src="attachIcon"
            >
            <slot>
              <template v-if="text">
                <span
                  class="px-2"
                  style="font-size: 14px; font-weight: 400;"
                >
                  {{ text }}
                </span>
              </template>
            </slot>
          </VBtn>
        </VCol>
      </VRow>
    </template>
    <template v-else>
      <VBtn
        v-if="asButton"
        v-model="filename"
        single-line
        :label="label"
        :required="required"
        :disabled="disabled || readonly"
        :icon="asIconButton"
        :text="flat || textButton"
        :outlined="outline"
        :class="buttonClass"
        :rounded="round"
        :style="buttonStyle"
        :block="block"
        :loading="loading"
        @click.native="onFocus"
      >
        <VIcon
          v-if="icon"
          small
        >
          {{ icon }}
        </VIcon>

        <img
          v-else
          class="mr-2"
          :height="iconHeight"
          :width="iconWidth"
          :src="attachIcon"
        >
        <slot>
          <template v-if="text">
            <span class="pl-2">{{ text }}</span>
          </template>
        </slot>
      </VBtn>
    </template>
    <VFileInput
      single-line
      persistent-hint
      show-size
      ref="fileInput"
      :multiple="false"
      :prepend-icon="icon"
      :label="label"
      :required="required"
      :disabled="disabled || readonly"
      :accept="accept"
      :hint="hint || fileMaxSizeHint"
      :hide-input="asButton"
      :style="{
        display: asButton || altView ? 'none' : 'block'
      }"
      @change="onFileChange"
    >
      <slot
        slot="append"
        name="append"
      />
      <slot
        slot="append-outer"
        name="append-outer"
      />
    </VFileInput>
    <VList
      v-if="multiple && hasAnyFiles && !altView"
      two-line
      subheader
      :class="listClass"
    >
      <template v-if="!uploadOnSave">
        <VListItem
          v-for="(item, index) in localFiles"
          :key="index"
          @click.prevent
        >
          <VListItemAvatar>
            <img
              v-if="item.type && isImageType(item.type)"
              :id="'img'+index"
              :src="getMediaFromFileObject(item, '#img'+index)"
            >
            <VIcon v-else>
              {{ getIcon(item.type) }}
            </VIcon>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle>
              <a
                :title="item.name"
                target="_blank"
                :href="item.url"
                class="text-truncate text-no-wrap"
                @click.prevent="doPreviewFiles(item)"
              >{{ getFileName(item) }}</a>
            </VListItemTitle>
            <VListItemSubtitle>
              {{ getFileSize(item.size) }} From:
              <strong>{{ getFileOrigin(item) }}</strong>
              <VAlert
                v-if="!item.id && item.size > fileMaxSize"
                dense
                type="error"
                class="mt-1"
                :value="true"
              >
                {{ `${fileMaxSizeHint} Your file is ${getFileSize(item.size-fileMaxSize)} over the limit.` }}
              </VAlert>
            </VListItemSubtitle>
          </VListItemContent>
          <VListItemAction>
            <VBtn
              icon
              ripple
              :loading="item.isDeleting"
              @click="onRemoveFile(index, item)"
            >
              <VIcon color="grey">
                {{ item.id ? 'delete' : 'close' }}
              </VIcon>
            </VBtn>
            <VBtn
              v-if="item.id"
              icon
              ripple
              :loading="item.isDeleting"
              @click="onRemoveFile(index, item)"
            >
              <VIcon color="grey">
                delete
              </VIcon>
            </VBtn>
          </VListItemAction>
        </VListItem>
      </template>
      <VListItem v-if="multiple && hasAnyFiles && !uploadOnSave && canUploadManually">
        <VBtn
          small
          block
          style="height: 56px"
          class="dashed"
          :disabled="disabled"
          :icon="asIconButton"
          :text="flat"
          :outlined="outline"
          :rounded="round"
          @click.native="uploadFiles"
        >
          <VIcon>play_arrow</VIcon>
          <template v-if="text">
            &nbsp;Upload Files
          </template>
        </VBtn>
      </VListItem>
      <VListItem
        v-for="(item, index) in files"
        class="mt-2"
        :key="index"
        @click.prevent
      >
        <VListItemAvatar>
          <img
            v-if="item.type && isImageType(item.type)"
            :id="'img'+index"
            :src="getMediaFromFileObject(item, '#img'+index)"
          >
          <VIcon v-else>
            {{ getIcon(item.type) }}
          </VIcon>
        </VListItemAvatar>
        <VListItemContent>
          <VListItemTitle>
            <a
              :title="item.name"
              target="_blank"
              :href="item.url"
              class="text-truncate text-no-wrap"
              @click.prevent="doPreviewFiles(item)"
            >{{ getFileName(item) }}</a>
          </VListItemTitle>
          <VListItemSubtitle>
            {{ getFileSize(item.size) }} From:
            <strong>{{ getFileOrigin(item) }}</strong>
            <VAlert
              v-if="!item.id && item.size > fileMaxSize"
              dense
              type="error"
              class="mt-1"
              :value="true"
            >
              {{ `${fileMaxSizeHint} Your file is ${getFileSize(item.size-fileMaxSize)} over the limit.` }}
            </VAlert>
          </VListItemSubtitle>
        </VListItemContent>
        <VListItemAction>
          <VBtn
            v-if="item.id"
            icon
            ripple
            :loading="item.isDeleting"
            @click="onRemoveFile(index, item)"
          >
            <VIcon color="grey">
              delete
            </VIcon>
          </VBtn>
          <VBtn
            v-else
            icon
            ripple
            :loading="item.isDeleting"
            @click="onRemoveFile(index)"
          >
            <VIcon color="grey">
              close
            </VIcon>
          </VBtn>
        </VListItemAction>
      </VListItem>
    </VList>
    <Preview
      @toggle="onTogglePreview"
      :files="previewFiles"
      v-if="dialogs.preview"
    />
    <Confirm ref="confirmDelete" />
  </div>
</template>
<script>
import DownloadsFiles from "@/mixins/DownloadsFiles";
import Preview from "@/components/Elements/Dialogs/Preview";
import DeletesItems from "@/mixins/DeletesItems";
import attachIcon from "../../../images/attach-icon@3x.png";
import { mapActions } from "vuex";

export default {
  mixins: [DownloadsFiles, DeletesItems],
  components: { Preview },
  props: {
    asButton: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: String,
      default: "height: 44px",
    },
    asIconButton: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    iconWidth: {
      type: String,
      default: "16px",
    },
    iconHeight: {
      type: String,
      default: "16px",
    },
    createFormData: {
      type: Boolean,
      default: false,
    },
    uploadOnSave: {
      type: Boolean,
      default: true,
    },
    canUploadManually: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    value: {
      type: [Array, String],
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
    label: {
      type: String,
      default: "Please choose...",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    }, // not yet possible because of data
    text: {
      type: String,
      default: null,
    },
    buttonClass: {
      type: [String, Object, Array],
      default: "",
    },
    files: {
      type: Array,
      default: () => [],
    },
    altView: {
      type: Boolean,
      default: false,
    },
    listClass: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      attachIcon,
      filename: "",
      localFiles: [],
    };
  },
  computed: {
    allFiles() {
      if (this.multiple) {
        return []
          .concat(this.files, this.localFiles)
          .filter((item) => item != null);
      } else {
        return [];
      }
    },
    existingFiles() {
      if (this.multiple) {
        return this.files.filter(
          (item) => item instanceof Object && item.id > 0
        );
      } else {
        return [];
      }
    },
    hasAnyFiles() {
      return this.multiple && this.allFiles.length > 0;
    },
  },
  watch: {
    value(v) {
      this.filename = v;
    },
  },
  created() {
    this.filename = this.value;
  },

  methods: {
    ...mapActions({
      deleteFile: "file/delete",
    }),
    getFormData(files) {
      const data = new FormData();
      [...files].forEach((file) => {
        data.append("data", file, file.name); // Currently only one file at a time
      });
      return data;
    },
    onFocus() {
      console.log("Input activated", this.$refs.fileInput);
      if (!this.disabled) {
        this.$refs.fileInput.$refs.input.click();
      }
    },
    onFileChange($event) {
      const files =
        $event instanceof File
          ? [$event]
          : $event instanceof Array
          ? $event
          : $event.target.files || $event.dataTransfer.files;
      this.$log.debug("FileUpload: File change", files);
      const form = this.createFormData ? this.getFormData(files) : files;
      if (files) {
        if (files.length > 0) {
          if (this.multiple) {
            Array.from(files).forEach((file) => {
              if (this.uploadOnSave === false) {
                this.$log.debug("FileUpload: Adding file", file);
                this.localFiles.push(file);
              }
            });
          } else {
            this.filename = [...files].map((file) => file.name).join(", ");
            this.$log.debug("FileUpload: Added file", this.filename);
          }
          // this.$emit("files", files);
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      this.$emit("files", files);
      this.$emit("input", this.filename);
      this.$emit("formData", form[0]);
    },
    onRemoveFile(index, file) {
      if (file instanceof Object && file.id) {
        this.$log.debug("FileUpload: Removing existing file", file.id);
        this.onDeleteItem(
          {
            id: file,
            entity_slug: file.entity_slug,
            entity_id: file.entity_id,
          },
          this.deleteFile
        ).then((result) => {
          this.$log.debug("FileUpload: Removing file result", result);
          if (result) {
            this.$log.debug("FileUpload: Removing file", index);
            this.localFiles.splice(index, 1);
            this.$emit("removeFile", index);
          }
        });
      } else {
        this.$log.debug("FileUpload: Removing file", index);
        this.localFiles.splice(index, 1);
        this.$emit("removeFile", index);
      }
      this.$refs.fileInput.reset();
    },
    uploadFiles() {
      this.$log.debug("FileUpload: Uploading files");
      this.$emit("uploadFiles", this.localFiles);
    },
    onReset() {
      this.filename = "";
      this.localFiles = [];
      this.$emit("input", "");
      this.$emit("files", []);
      this.$refs.fileInput.reset();
    },
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}
</style>
<style lang="scss">
.files-chips-list {
  .v-chip {
    width: 100%;
    box-shadow: 0 3px 8px 0 rgba(216, 220, 240, 0.8);
    border-radius: 12px !important;
    background-color: #ffffff !important; 
  
    .v-chip__close {
      color: #bfbfbf;
      font-size: 24px !important;
      right: 0;
      position: absolute;
      margin: 12px !important;
    }
  }
}
</style>