<template>
  <div>
    <Autocomplete
      v-if="viewType == 'dropdown'"
      item-text="title"
      item-value="id"
      append-inner-icon="arrow-down"
      placeholder="Select roles"
      :label="label"
      :value="modelValue"
      :items="options"
      :class="customClass"
      :multiple="true"
      @input="updateInput"
    />
    <VList
      v-else-if="viewType == 'list'"
      dense
      class="d-flex"
    >
      <VListItem
        v-for="(option, index) in options"
        :key="index"
      >
        <VListItemTitle
          class="px-2 pb-1"
          style="border: solid 0.5px #ececf1; border-radius: 8px;"
        >
          <VCheckbox
            v-model="visibility"
            dense
            multiple
            hide-details
            color="success"
            :class="{
              'mt-0 view-options': true,
              'gray-label': visibility.indexOf(option.id) === -1,
            }"
            :label="option.title"
            :value="option.id"
          />
        </VListItemTitle>
      </VListItem>
    </VList>
    <VAlert
      type="error"
      style="border-radius: 5px;"
      :class="alertClass"
      :value="!hasValue"
    >
      Please select who can view this. If you do not select any groups then this will not be visible to anyone
    </VAlert>
  </div>
</template>

<script>
export default {
  name: "VisibleTo",
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    options: {
      type: [Array],
      default: () => [],
    },
    label: {
      type: String,
      default: "Who can see this?",
    },
    customClass: {
      type: String,
      default: null,
    },
    alertClass: {
      type: String,
      default: null,
    },
    viewType: {
      type: String,
      default: "dropdown",
    },
  },
  computed: {
    hasValue() {
      if (this.options instanceof Array && this.options.length == 0) {
        return true;
      }
      return (
        this.modelValue instanceof Array &&
        this.modelValue.length > 0 &&
        this.options instanceof Array &&
        this.options.length > 0
      );
    },
    modelValue() {
      if (this.value instanceof Array) {
        return this.value;
      } else if (this.value instanceof Object) {
        return Object.keys(this.value);
      } else {
        return [this.value];
      }
    },
    visibility: {
      get: function () {
        return this.value instanceof Array ? this.value : Object.keys(this.value);
      },
      set: function (value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    updateInput(value) {
      console.log("Input value", value);
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/stats.scss";

.gray-label .v-label {
  color: #808080 !important;
}
</style>