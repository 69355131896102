<template>
  <VRow
    id="chat-form"
    column
    wrap
    fill-height
    :style="chatFormContainerStyle"
  >
    <VCol
      v-show="form.files && form.files.length"
      cols="12"
      class="py-0 mb-2"
    >
      <FilesList
        id="chat-files"
        :readonly="false"
        :files="form.files"
        :style="fileListStyle"
        @removeFile="onRemoveFile"
      />
      <VDivider />
    </VCol>
    <VCol
      ref="messageContainer"
      cols="12"
      class="py-0 mb-2"
      :style="style.chatMessageContainer"
    >
      <LongTextField
        v-model="formMessage"
        solo
        clearable
        autofocus
        auto-grow
        hide-details
        ref="message"
        :height="formMessage.length > 0 ? 'auto' : '44'"
        :disabled="!isFormReady"
        :placeholder="`Type a message (${sendMessageInstructions})`"
        @input="updateTextarea"
        @blur="onUpdateThreadTimestamp"
        @keydown.enter.exact.prevent="onSave"
        @keydown.enter.shift.exact.prevent="formMessage += '\n'"
      >
        <template slot="prepend-inner">
          <VDialog
            v-model="showEmojiPicker"
            width="355"
          >
            <Picker
              set="google"
              :data="emojiIndex"
              @select="addEmoji"
            />
          </VDialog>
          <VBtn
            text
            icon
            @click.prevent="showEmojiPicker = !showEmojiPicker"
            :disabled="!isFormReady"
          >
            <img
              height="20"
              :src="emoticonsIcon"
            >
          </VBtn>
          <FileUpload
            icon-height="20"
            button-style="height: 100%"
            :as-button="true"
            :outlined="false"
            :rounded="true"
            :as-icon-button="true"
            :disabled="!isFormReady || activity.isFormLoading"
            :text-button="true"
            :files="form.files"
            @formData="onAddFile"
          />
        </template>
        <template slot="append-outer">
          <VBtn
            icon
            depressed
            color="primary"
            :loading="activity.isFormLoading"
            :disabled="!isFormReady || !(formMessage.length > 0) && !(form.files.length > 0)"
            @click="onSave"
          >
            <img
              height="24"
              :src="sendIcon"
            >
          </VBtn>
        </template>
      </LongTextField>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import data from "emoji-mart-vue-fast/data/all.json";
import FileUpload from "../../components/Elements/Forms/FileUpload";
import FormMixin from "@/mixins/Form";
import sendIcon from "../../images/send-icon@3x.png";
import emoticonsIcon from "../../images/emoticons-icon@3x.png";
let emojiIndex = new EmojiIndex(data);

export default {
  components: {
    FileUpload,
    Picker,
  },
  mixins: [FormMixin],
  props: {
    oldThread: {
      type: Object,
      default: () => {},
    },
    thread: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sendIcon,
      emoticonsIcon,
      modelType: "Message",
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      formMessage: "",
      formMessages: {},
      hasFiles: true,
      defaultForm: {
        message: "",
        user_id: null,
        files: [],
      },
      style: {
        chatMessageContainer: {
          height: "auto",
          initialHeight: "90px",
        },
        chatFormInput: {
          height: "17px",
          initialHeight: "17px",
          overflow: "hidden",
        },
        textarea: {
          height: "auto",
          minHeight: "44px",
          initialHeight: "44px",
          maxHeight: "250px",
          overflowY: "auto",
        },
      },
    };
  },
  computed: {
    mutatorKeyForSend() {
      if (this.getOS() === "MacOS") {
        return "CMD";
      }
      return "CTRL";
    },
    sendMessageInstructions() {
      if (["Android", "iOS"].indexOf(this.getOS()) > -1) {
        return `Tap SEND to send`;
      } else {
        return `Press ENTER to send`;
      }
    },
    fileListStyle() {
      return {
        marginTop: this.form.files
          ? "-" + this.form.files.length * 72 + "px"
          : "0px",
        width: this.$refs.message ? this.$refs.message.width : "auto",
      };
    },
    chatFormContainerStyle() {
      const messageContainer = this.$refs.messageContainer;
      return {
        height: `${
          messageContainer ? messageContainer.clientHeight + "px" : "auto"
        }`,
        minHeight: "44px",
        maxHeight: "500px",
        transition: "all 0.25s ease-in-out",
        zIndex: 2,
      };
    },
  },
  watch: {
    thread: function (thread) {
      this.setThread(thread);
    },
    user: function (user) {
      this.user = this.form.user = user;
    },
  },
  created() {
    this.$log.debug("Chat-Form: Component mounted.");
    if (this.thread) {
      this.setThread(this.thread);
    }
    this.form.files = [];
  },
  methods: {
    ...mapActions({
      doSaveMessage: "chat/saveMessage",
    }),
    updateTextarea(value) {
      if (!value.length) {
        this.style.chatMessageContainer.height = this.style.chatMessageContainer.initialHeight;
        this.style.chatFormInput.height = this.style.chatFormInput.initialHeight;
        this.style.textarea.height = this.style.textarea.initialHeight;
        this.style.textarea.overflowY = "hidden";
      }
      // else {
      // this.style.chatMessageContainer.height = this.style.textarea.height = this.style.chatMessageContainer.height = this.$refs.message.$el.height;
      // this.style.textarea.overflowY = "auto";
      //46 is the top/bottom padding value for the textarea input. We subtract this from the overall height to make sure its the right height
      // this.style.chatFormInput.height = (this.$refs.message.$el.scrollHeight - 46) + "px";
      // this.$refs.message.value = this.$refs.message.value.substr(0, 140);
      // }
    },
    onUpdateThread() {
      const thread = this.thread;
      thread.updated_at = new Date();
      this.$emit("updateThread", thread);
    },
    onUpdateThreadTimestamp() {
      this.$emit("updateThreadTimestamp", this.thread.id);
    },
    onSave() {
      this.form.message = this.formMessage;
      if (!this.form.message.length && !this.form.files.length) {
        return false;
      }
      this.form.user_id = this.$user.id;
      this.form.thread_id = this.thread.thread_id;
      this.form.to_id =
        this.$user.id == this.thread.to_id
          ? this.thread.user_id
          : this.thread.to_id;
      this.$log.debug("Chat-Form: Adding new message: ");
      return this.saveFormModel(this.doSaveMessage, null)
        .then((response) => {
          let result = this.saveResult;
          this.$log.debug("Chat-Form: Added new message: ", result);
          if (result) {
            this.formMessage = this.formMessages[result[0].thread_id] = "";
            this.form = this.cloneObject({
              ...{},
              ...(this.defaultForm || {}),
            });
            this.form.files = [];
            //Switching this up as it doesn't make sense to use pusher notifications when adding your own messages
            this.$emit("addMessage", result);
            this.updateTextarea({
              target: {
                value: {
                  length: null,
                },
              },
            });
          }
        })
        .catch((error) => {});
    },
    beforeSetThread(thread) {
      this.$log.debug(
        "Chat-Form: Changing thread to: [",
        thread.title,
        "] from: ",
        this.oldThread ? this.oldThread.title : "none"
      );
      //Set the current thread message
      if (this.oldThread) {
        this.$log.debug(
          "Chat-Form: Old thread exists: [",
          this.oldThread.thread_id,
          "]"
        );
        this.formMessages[this.oldThread.thread_id] = this.formMessage;
      }
      this.formMessage = "";
    },
    setThread(thread) {
      if (!thread) {
        return;
      }
      this.beforeSetThread(thread);

      //Update the current thread
      this.thread = thread;
      this.form.thread_id = thread.thread_id;
      this.form.to_id =
        this.$user.id == thread.to_id ? thread.user_id : thread.to_id;
      this.emitDoneLoading();

      this.afterSetThread();
    },
    afterSetThread() {
      this.$log.debug(
        "Chat-Form: Thread changed to: [",
        this.thread.title,
        "]"
      );

      //Reload any temporarily saved messages
      if (this.formMessages.hasOwnProperty(this.thread.thread_id)) {
        this.formMessage = this.formMessages[this.thread.thread_id];
        this.$log.debug(
          "Chat-Form: Found existing message for: [",
          this.thread.thread_id,
          "] : [",
          this.formMessage,
          "]"
        );
      }

      this.$log.debug(
        "Chat-Form: Updating unsent message in: [",
        this.thread.thread_id,
        "] to: [",
        this.formMessage,
        "]"
      );
    },
    addEmoji(emoji) {
      const text = emoji.native;
      this.formMessage = this.formMessage || "";
      let elem = this.$refs.message.$el.querySelector("textarea");
      if (document.selection) {
        // IE
        elem.focus();
        const sel = document.selection.createRange();
        this.formMessage = text;
      } else if (elem.selectionStart || elem.selectionStart === 0) {
        // Others
        const startPos = elem.selectionStart;
        const endPos = elem.selectionEnd;
        this.formMessage =
          elem.value.substring(0, startPos) +
          text +
          this.formMessage.substring(endPos, elem.value.length);
        elem.selectionStart = startPos + text.length;
        elem.selectionEnd = startPos + text.length;
      } else {
        this.formMessage += text;
      }
      this.showEmojiPicker = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";

#chat-form {
  #chat-files {
    width: 100%;
    overflow-y: auto;
    z-index: 100;
  }

  .v-textarea textarea {
    height: auto;
    margin-top: 16px;
  }
  flex: 1 1 auto;
  height: auto;
  min-height: 44px;
  background-color: $white-color;
  overflow: visible;
}
</style>