var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"align-end":_vm.isFirst,"align-center":!_vm.isFirst,"justify-start":_vm.isFirst,"justify-center":!_vm.isFirst,"row":"","wrap":"","fill-height":""}},[_c('VCol',{class:{
      'pl-12 pb-12': true,
      'text-center': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('h1',{class:{
        'assessment-title': true,
        'text-center': !_vm.isFirst
      },style:({
        'text-transform': _vm.isFirst ? 'uppercase' : 'none',
        'line-height': '30px'
      })},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),(_vm.isFirst)?_c('strong',[_vm._v(_vm._s(_vm.assessment.all_cards_count || _vm.assessment.cards_count)+" Cards")]):_vm._e(),_c('br'),_c('br'),(_vm.isFirst)?_c('VBtn',{staticClass:"ml-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('next')}}},[_c('VImg',{attrs:{"src":_vm.nextImg}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }