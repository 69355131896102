<template>
  <router-view />
</template>

<script>
export default {
  name: "Deliverables",
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>