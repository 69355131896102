<script>
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogs: {
        preview: false,
      },
      previewFiles: [],
      fileMaxSizeHint: `There is a ${
        this.fileMaxSize || "2G"
      } limit for uploaded files.`,
    };
  },
  computed: {
    hasAnyFiles() {
      return (
        this.files &&
        this.files.filter(
          (item) =>
            (item instanceof Object && (item.url || item.size || "")) || ""
        ).length > 0
      );
    },
  },
  methods: {
    onTogglePreview() {
      this.dialogs.preview = !this.dialogs.preview;
      if (!this.dialogs.preview) {
        this.previewFiles = [];
      }
    },
    doPreviewFiles(files) {
      this.previewFiles = files instanceof Array ? files : [files];
      this.dialogs.preview = true;
    },
    isImageType(type) {
      return (
        type.startsWith("image/") ||
        type.startsWith("application/vnd.google-apps")
      );
    },
    /**
     * Uses Sugar library to provide bytes function
     */
    getFileSize(file) {
      function formatFileSize(bytes, decimalPoint) {
        if (bytes == 0) return "0 Bytes";
        var k = 1024,
          dm = decimalPoint || 2,
          sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
          i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
          parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
      }
      if (file instanceof Object) {
        if (file.readable_size) {
          return file.readable_size;
        } else if (parseInt(file.size) > 0) {
          return formatFileSize(file.size);
        } else {
          return "";
        }
      }
      return typeof file === "number" ? formatFileSize(file) : "";
    },
    getFileName(file) {
      this.$log.info("File name", file);
      return file instanceof Object ? file.name : file;
    },
    getFileOrigin(file) {
      const url = file.url ? new URL(file.url) : null;
      if (
        url &&
        ["docs.google.com", "drive.google.com"].indexOf(url.hostname) !== -1
      ) {
        return "Google Drive";
      }
      return "Local";
    },
    getIcon(mime) {
      if (!mime) {
        return "attach_file";
      }
      const part = mime.substr(0, mime.indexOf("/"));
      switch (part) {
        case "image":
          return "image";
          break;

        default:
          return "attach_file";
          break;
      }
    },
    /**
     * Video file implementation from: https://stackoverflow.com/a/61012791
     */
    getMediaFromFileObject(item, id, callback) {
      if (item instanceof File) {
        this.$log.info("Loading file from ", item);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$log.debug("Loaded file", item, e.target.result);
          let src = e.target.result;

          if (item.type.startsWith("video") || item.type.startsWith("audio")) {
            const blob = new Blob([new Uint8Array(e.target.result)], {
              type: item.type,
            });
            src = URL.createObjectURL(blob);
            this.$log.info("Created url from video file", src);
          }
          if (callback instanceof Function) {
            callback(src);
          }
          this.$nextTick(() => {
            const target = document.querySelector(id);
            this.$log.info("Found target", id, target);
            if (target) {
              target.src = src;
            }
          });
        };
        if (item.type.startsWith("video") || item.type.startsWith("audio")) {
          this.$log.info("Reading file as an array buffer");
          reader.readAsArrayBuffer(item);
        } else {
          reader.readAsDataURL(item);
        }
      } else {
        if (item.type && item.type.startsWith("application/vnd.google-apps")) {
          const type = item.type.split(".").pop();
          return (
            "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps." +
            type
          );
        }
        return item.url;
      }
    },
    onRemoveFile(index, id) {
      this.$emit("removeFile", index, id);
    },
    getQualifiedUrl(url = "") {
      if (!url.startsWith("http")) {
        return `https://${url}`;
      }
      return url;
    },
    getIsDownloadable(file) {
      return (
        (file instanceof Object &&
          (file instanceof Object || file.download_url != null)) ||
        typeof file == "string"
      );
    },
    getIsFromGoogle(file) {
      const value = file instanceof Object ? file.url || file.value : file;
      try {
        const url = new URL(value);
        return (
          url &&
          ["docs.google.com", "drive.google.com"].indexOf(url.hostname) !== -1
        );
      } catch (e) {
        console.warn(e);
      }
      return false;
    },
    getIsEmbeddableMedia(file) {
      return this.getIsEmbeddableVideo(file) || this.getIsEmbeddableAudio(file);
    },
    getIsEmbeddableVideo(file) {
      return (
        file instanceof Object && file.type && file.type.startsWith("video")
      );
    },
    getIsEmbeddableAudio(file) {
      return (
        file instanceof Object && file.type && file.type.startsWith("audio")
      );
    },
    getIsSelfHostedMedia(file) {
      const value =
        file instanceof Object &&
        typeof file.url == "string" &&
        file.url.length > 0
          ? file.url
          : null;
      if (value) {
        const url = new URL(value);
        // console.log(
        //   value,
        //   url,
        //   url.origin.startsWith(this.env.VUE_APP_API_URL),
        //   url.host.indexOf("amazonaws.com") > -1,
        //   url.protocol === "blob:",
        //   (url.origin.startsWith(this.env.VUE_APP_API_URL) ||
        //     url.host.indexOf("amazonaws.com") > -1 ||
        //     url.protocol === "blob:") === true
        // );
        return (
          (url.origin.startsWith(this.env.VUE_APP_API_URL) ||
            url.host.indexOf("amazonaws.com") > -1 ||
            url.protocol === "blob:") === true
        );
      }
      return false;
    },
    onCancelContextMenu(event) {
      if (!this.env.isDev) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    getIsEmbeddable(file) {
      if (file.type && file.type.indexOf("pdf") !== -1) {
        return true;
      }
      return this.getIsEmbeddableVideo(file);
    },
    download(index, force) {
      this.$log.info("Downloading", index);
      const item = this.files[index];
      if (!force && RegExp("^image").test(item.type)) {
        this.dialogs.preview = true;
        this.file = item;
      } else {
        try {
          // Universal browser attempt
          browser.downloads.download({
            url: item.download_url || item.url,
            filename: item.name,
            saveAs: true,
          });
        } catch (e) {
          this.$log.warn(e);

          try {
            // Chrome support
            chrome.downloads.download({
              url: item.download_url || item.url,
              filename: item.name,
              saveAs: true,
            });
          } catch (e) {
            this.$log.warn(e);
            // const headers = new Headers();
            fetch(item.download_url || item.url, {
              mode: "cors",
              headers: {
                Origin: window.location.origin,
              },
            })
              .then(function (response) {
                response.blob().then((blob) => {
                  saveAs(blob, item.name);
                });
              })
              .catch((e) => {
                this.$log.warn(e);
                const link = document.createElement("a");
                // link.href = `${item.download_url || item.url}?response-content-disposition=attachment;filename=${item.name}`;
                link.href = item.download_url || item.url;
                link.download = item.name;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              });
          }
        }
        this.dialogs.preview = false;
      }
    },
  },
};
</script>