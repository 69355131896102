<template>
  <div class="calendar-form">
    <VContainer
      fluid
      grid-list-md
      :class="{
        'is-loading': activity.isLoading
      }"
    >
      <VRow
        row
        wrap
      >
        <VCol v-if="hasMessagesOrErrors">
          <Alerts
            :messages="messages"
            :errors="errors"
          />
        </VCol>
        <VCol cols="12">
          <Autocomplete
            v-model="form.entity_type"
            :items="entityTypes"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            placeholder="Attach to..."
            label="Calendar Type"
            @input="(value) => selectedEntityType = value"
          />
        </VCol>
        <VCol
          v-if="form.entity_type"
          cols="12"
        >
          <Autocomplete
            v-model="form.entity_id"
            :items="entityOptions"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            :placeholder="`Select ${selectedEntityTitle}`"
            :label="selectedEntityTitle"
          />
        </VCol>
        <VCol cols="12">
          <TextField
            v-model="form.title"
            name="form.title"
            label="Title"
            placeholder="Enter title..."
            :error="$v.form.title.$error"
            @input="$v.form.title.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol cols="12">
          <Swatches
            v-model="form.color"
            :colors="colors"
            inline
          />
        </VCol>
        <VCol
          cols="12"
          v-if="form.remote_calendar"
        >
          <h2>
            Current Calendar:
            <strong>{{ form.remote_calendar.summary }}</strong>
          </h2>
        </VCol>
        <VCol cols="12">
          <h2>Google Calendars</h2>
          <template v-if="hasGoogleCalendars">
            <VList two-line>
              <VListItem
                v-for="(item, key) in calendars"
                :key="key"
                @click="setCalendar(item)"
              >
                <VListItemContent>
                  <VListItemTitle>{{ item.summary }}</VListItemTitle>
                  <VListItemSubtitle>{{ item.description }}</VListItemSubtitle>
                </VListItemContent>
                <VListItemAction>
                  <VBtn
                    icon
                    :style="{
                      color: item.backgroundColor
                    }"
                  >
                    <VIcon v-if="form.remote_calendar instanceof Object && form.remote_calendar.id == item.id">
                      radio_button_checked
                    </VIcon>
                    <VIcon v-else>
                      radio_button_unchecked
                    </VIcon>
                  </VBtn>
                </VListItemAction>
              </VListItem>
            </VList>
          </template>
          <VList v-else-if="activity.isLoading && hasCalendarAuth">
            <VListItem>
              <VListItemContent>
                <VRow
                  row
                  justify-center
                  py-6
                >
                  <VProgressCircular />
                </VRow>
              </VListItemContent>
            </VListItem>
          </VList>
          <VRow
            column
            v-else-if="!activity.isLoading && !hasCalendarAuth && canConnectOnNoToken"
          >
            <VCol>
              <Alerts
                :messages="calendarMessages"
                :errors="calendarErrors"
              />
            </VCol>
            <VCol>
              <GoogleAuth
                ref="googleAuth"
                @connected="setupConnectedAccount"
              >
                Connect Google
              </GoogleAuth>
            </VCol>
          </VRow>
          <VList v-else-if="!hasGoogleCalendars">
            <VListItem>
              <VListItemContent>
                <VListItemTitle>No Calendars</VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VList>
        </VCol>
        <VCol cols="12">
          <VInput
            type="hidden"
            slot="activator"
            v-model="form.day_of_week"
            label="End Date"
            prepend-icon="event"
            readonly
            v-show="false"
          />
          <VInput
            type="hidden"
            slot="activator"
            v-model="form.start_date"
            label="End Date"
            prepend-icon="event"
            readonly
            v-show="false"
          />
          <VInput
            type="hidden"
            slot="activator"
            v-model="form.end_date"
            label="End Date"
            prepend-icon="event"
            readonly
            v-show="false"
          />
          <VInput
            type="hidden"
            slot="activator"
            v-model="form.start_time"
            label="End Date"
            prepend-icon="event"
            readonly
            v-show="false"
          />
          <VInput
            type="hidden"
            slot="activator"
            v-model="form.end_time"
            label="End Date"
            prepend-icon="event"
            readonly
            v-show="false"
          />
        </VCol>
      </VRow>
      <VRow
        row
        wrap
        justify-space-between
      >
        <VCol cols="12">
          <VRow
            row
            justify-space-between
            align-center
          >
            <h4>Members</h4>
            <VBtn
              color="primary"
              text
              @click="onToggleMembersDialog"
            >
              <VIcon class="wethrive">
                add
              </VIcon>Add Members
            </VBtn>
          </VRow>
        </VCol>
        <VCol cols="12">
          <MembersList
            v-if="form.members && form.members.length"
            :users="form.members"
            @removeMember="removeMember"
          />
          <p v-else>
            No members have been added to this calendar
          </p>
        </VCol>
      </VRow>
      <VRow
        row
        wrap
        style="height: 500px"
      >
        <VCol cols="12">
          <h4>Mark Cancellations</h4>
        </VCol>
        <VCol
          cols="12"
          fill-height
        >
          <Calendar
            :fill-height="true"
            :can-create="false"
            :events="remoteEvents"
            :show-event-times="false"
            month-name-format="short"
            class="theme-default holiday-us-traditional holiday-us-official"
            :show-date="showDate"
            @click-date="$emit('day-changed')"
            @input="setShowDate"
          />
        </VCol>
      </VRow>
      <VCardActions
        v-if="mode !== 'dialog'"
        class="justify-center"
      >
        <VBtn
          :loading="activity.isFormLoading"
          :disabled="disabled"
          @click="onSave"
        >
          Save
        </VBtn>
      </VCardActions>
    </VContainer>
    <Dialog
      :hide-overlay="true"
      :is-dialog-open="dialogs.members"
      :title="`Add ${$team.user_role_name}`"
      @toggle:dialog="onToggleMembersDialog"
    >
      <MembersForm
        ref="addMembers"
        type="any"
        mode="multiple"
        :existing="existingMembers"
        :is-group="true"
        :autosave="false"
        @addMember="addMember"
        @removeMember="removeMember"
        @saveMembers="dialogs.members = false"
      />
    </Dialog>
  </div>
</template>
<script>
import Swatches from "vue-swatches";
import defaultSwatches from "vue-swatches/src/presets/basic.js";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/CalendarForm";
import { required, minLength, between } from "vuelidate/lib/validators";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import GoogleAuthMixin from "@/mixins/GoogleAuth";
import moment from "moment";
import GoogleAuth from "@/components/Auth/Google";
import FormatsCalendarEntriesMixin from "@/mixins/FormatsCalendarEntries";
import Vue from "vue";

export default {
  name: "SaveGoogleCalendarForm",
  components: {
    MembersForm,
    MembersList,
    Swatches,
    GoogleAuth,
  },
  mixins: [FormMixin, GoogleAuthMixin, FormatsCalendarEntriesMixin],
  props: {
    canConnectOnNoToken: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    //NOTE: This may cause issues with loading up the component properly
    defaultSwatches.colors.concat("#fff");
    return {
      colors: defaultSwatches,
      hasCalendarAuth: false,
      disabled: false,
      defaultForm: {
        title: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: null,
        start_time: "09:00",
        end_time: null,
        entries: [],
        calendar_type: "google",
        remote_calendar: {},
      },
      calendars: [],
      calendarErrors: [],
      calendarMessages: [],
    };
  },
  computed: {
    hasGoogleCalendars() {
      return (
        this.hasCalendarAuth &&
        this.calendars instanceof Array &&
        this.calendars.length > 0
      );
    },
  },
  methods: {
    setCalendar(calendar) {
      if (this.colors.swatches.indexOf(calendar.backgroundColor) === -1) {
        this.colors.swatches.push(calendar.backgroundColor);
      }
      this.form.calendar_type = "google";
      this.form.remote_calendar = calendar;
      this.form.color = calendar.backgroundColor;
      this.form.description = calendar.description;
      this.form.title = calendar.summary;
      this.remoteEvents = [];
      this.formatEvents(this.form);
    },
    setupConnectedAccount(account) {
      this.oauthToken = account.token.token;
      this.loadApi().then(() => {
        this.calendars.map((calendar) => {
          this.formatEntries(calendar);
        });
      });
    },
    async loadApi() {
      this.emitLoading();
      await this.loadGoogleApi();
      await this.loadGoogleCalendarApi();
      this.$log.debug(
        "Waiting for google calendar auth",
        window.gapi.client.getToken()
      );
      this.waitFor(() => window.gapi.client.getToken() instanceof Object, 30000)
        .then((result) => {
          this.waitFor(() => this.hasToken === true, 30000)
            .then(() => {
              this.$log.debug("We have google calendar auth");
              window.gapi.client.calendar.calendarList
                .list()
                .execute((response) => {
                  if (response.error) {
                    this.calendarErrors = [
                      `Error loading google calendars: ${response.message}`,
                    ];
                    this.emitLoadingDone();
                  } else {
                    this.hasCalendarAuth = true;
                    this.$log.debug("Received google calendars");
                    this.calendars = response.items;
                    /**
                     * In case we're creating a new calendar we don't need to wait for the remote model to load
                     * Or if the remote model has already loaded
                     *  */
                    if (!this.id || (this.id && this.form.id)) {
                      this.emitLoadingDone();
                    }
                  }
                });
            })
            .catch((error) => {
              this.calendarErrors = [
                `Google auth isn't loaded. Please make sure you've connected your Google account`,
              ];
              this.disabled = true;
              this.emitLoadingDone();
            });
        })
        .catch((error) => {
          this.calendarErrors = [
            `Google auth isn't loaded. Please make sure you've connected your Google account`,
          ];
          this.disabled = true;
          this.emitLoadingDone();
        });
    },
  },
  created() {
    this.loadApi();
  },
};
</script>


<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
</style>