<!--

Creates the following inputs that will be submited:

	- geo-id
	- geo-name
	- geo-long
	- geo-lat
	- geo-country
	- geo-country-code

Example Usage

	<form action="/submit/url" method="POST">
		<geo-select
			prefix = "my-prefix"
			api-root-url = "\xxx\yyy"
			:enable-breadcrumb = "true"
			:countries = "[390903,3175395]"
		></geo-select>
		<input type="submit">
	</form>

-->

<template>
  <div v-if="altView">
    <VAutocomplete
      v-model="values[1]"
      dense
      outlined
      hide-details
      item-text="name"
      item-value="id"
      :multiple="false"
      :items="items[1]"
      :loading="!hasItems(1)"
      :disabled="!hasItems(1)"
      :placeholder="getPlaceholder(1)"
      @change="onStateSelected"
    />
    <VAutocomplete
      v-model="values[2]"
      dense
      outlined
      hide-details
      item-text="name"
      item-value="id"
      class="mt-2"
      :multiple="false"
      :items="items[2]"
      :loading="loadingIndex == 0"
      :disabled="!hasItems(2)"
      :placeholder="getPlaceholder(2)"
      @change="onCitySelected"
    />
  </div>
  <div v-else>
    <div v-if="location() !== null">
      <input
        type="hidden"
        :name="prefix + '-id'"
        :value="location().id"
      >
      <input
        type="hidden"
        :name="prefix + '-name'"
        :value="location().name"
      >
      <input
        type="hidden"
        :name="prefix + '-long'"
        :value="location().long"
      >
      <input
        type="hidden"
        :name="prefix + '-lat'"
        :value="location().lat"
      >
      <input
        type="hidden"
        :name="prefix + '-country'"
        :value="country().name"
      >
      <input
        type="hidden"
        :name="prefix + '-country-code'"
        :value="location().country"
      >
    </div>
    <div
      v-show="breadCrumb"
      class="geo-breadcrumb"
    >
      <VRow
        row
        wrap
      >
        <VCol cols="12">
          <TextField
            readonly
            :value="currentPath"
            placeholder="Location"
            label="Location"
            append-icon="edit"
            @click:append="changeLocation"
          >
            <VBtn
              icon
              slot="append-outer"
              class="mt-n1"
              @click="$emit('removeCity')"
            >
              <VIcon color="error">
                delete
              </VIcon>
            </VBtn>
          </TextField>
        </VCol>
      </VRow>
    </div>
    <div v-show="!breadCrumb">
      <div class="form-group">
        <p v-if="loadingIndex == 0">
          <i class="fa fa-cog fa-spin" />Loading Countries...
        </p>
        <Autocomplete
          v-else
          v-model="values[0]"
          item-text="name"
          item-value="id"
          class="form-control _select2"
          :multiple="false"
          :items="items[0]"
          :placeholder="getPlaceholder(0)"
          :label="getPlaceholder(0)"
          :style="{
            display: hideCountry ? 'none' : 'block',
          }"
          @change="itemSelected(0)"
        />
      </div>
      <div
        v-for="(i, key) in count"
        :key="key"
        class="form-group"
      >
        <p v-if="loadingIndex == i">
          <i class="fa fa-cog fa-spin" />Loading...
        </p>
        <div v-else>
          <Autocomplete
            v-if="hasItems(i)"
            v-model="values[i]"
            item-text="name"
            item-value="id"
            class="form-control _select2"
            :multiple="false"
            :items="items[i]"
            :placeholder="getPlaceholder(i)"
            :label="getPlaceholder(i)"
            @change="itemSelected(i)"
          />
          <div v-if="!hasItems(i) && enableBreadcrumb">
            <VBtn
              text
              color="info"
              :style="confirmBtnStyle"
              @click="breadCrumb = true"
            >
              Confirm Location
            </VBtn>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideCountry: {
      type: Boolean,
      default: true,
    },
    apiRootUrl: {
      type: String,
      default: "/api",
    },
    prefix: {
      type: String,
      default: "geo",
    },
    countries: {
      type: Array,
      default: null,
    },
    enableBreadcrumb: {
      type: Boolean,
      default: true,
    },
    currentCity: {
      type: Object,
      default: () => {},
    },
    currentCityId: {
      type: Number,
      default: null,
    },
    confirmBtnStyle: {
      type: Object,
      default: null,
    },
    altView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: 0,
      items: [],
      values: [],
      path: [],
      loadingIndex: null,
      breadCrumb: true,
      mode: "update",
    };
  },
  computed: {
    currentPath() {
      return this.path.length
        ? this.path.map((l) => this.prepareName(l.name).trim()).join(" » ")
        : "";
    },
  },
  watch: {
    currentCityId: function(value) {
      if (!value) {
        this.getChildrenOf(null, 0);
      }
    },
    currentCity: function(city) {
      if (city instanceof Object && city.id) {
        this.setPath(city);
      }
    },
    countries: function(value) {
      if(value.length && this.altView) {
        this.getChildrenOf(null, 0);
      }
    },
  },
  created() {
    if (this.currentCity instanceof Object && this.currentCity.id) {
      this.setPath(this.currentCity);
    } else if(!this.altView && !this.currentCityId) {
      this.getChildrenOf(null, 0);
    }
  },
  methods: {
    setPath(from) {
      const parts = [from];
      let currentPart = from;
      let parent = from.parent;
      while (parent !== null) {
        if (parent) {
          parts.push(parent);
          currentPart = currentPart.parent;
          parent = currentPart.parent;
        }
      }
      parts.pop();
      this.path = parts.reverse();
    },
    changeLocation() {
      this.breadCrumb = false;
      this.getChildrenOf(null, 0);
    },
    itemSelected(index) {
      var that = this;
      if (this.values[index] > 0) {
        this.path[index] = this.items[index].find(function(item) {
          return item.id == that.values[index];
        });
        this.setIndex(index);
        this.getChildrenOf(this.values[index], index + 1);
        if (this.values.length === 3) {
          this.$emit("citySelected", this.values[2]);
        }
      }
    },
    setIndex(index) {
      this.count = index + 1;
      this.values.splice(index + 1, 10);
      this.path.splice(index + 1, 10);
    },
    hasItems(index) {
      return this.items[index] instanceof Array && this.items[index].length > 0;
    },
    location() {
      if (this.path.length == 0) return null;

      return this.path[this.path.length - 1];
    },
    country() {
      return this.path[0];
    },
    prepareName(name) {
      [
        " County",
        "Town of ",
        "City of ",
        "City and of ",
        "Township of ",
        "Village of ",
        "Borough of ",
        "Village of ",
      ].forEach((r) => (name = name.replace(r, "")));
      return name;
    },
    getChildrenOf: function(id, index) {
      // Limit to three levels
      if (index > 2) {
        return;
      }

      this.loadingIndex = index;

      var url = this.apiRootUrl;
      if (id == null) {
        if (this.countries == null) url += "/geo/countries?fields=id,name";
        else url += "/geo/items/" + this.countries;
      }
      // We need to get cities
      else if (index == 2) url += "/geo/cities/" + id;
      // We need to get states
      else url += "/geo/children/" + id;

      this.$http
        .get(url, {})
        .then((response) => {
          this.items[index] = response.data.map((item) => {
            item.name = this.prepareName(item.name);
            return item;
          });
          this.loadingIndex = null;
          if (this.items[index].length == 1) {
            this.values[index] = this.items[index][0].id;
          } else if(this.currentCity) {
            // Set these values to auto-select in Edit mode
            if(index == 1) {
              const state = this.items[index].find((item) => item.id === this.currentCity.parent.id || item.id === this.currentCity.parent.parent.id);
              this.values[index] = state.id;
            } else {
              this.values[index] = this.currentCity.id;
            }
          }
          this.itemSelected(index);
          this.$forceUpdate();
          this.$nextTick(() => {
            this.breadCrumb = this.enableBreadcrumb && !this.hasItems(index);
          });
        })
        .catch((error) => {
          this.$log.debug(error.response ? error.response.data : error);
        });
    },
    getPlaceholder(index) {
      const placeholders = ["Select Country", "Select State", "Select City"];
      return placeholders[index];
    },
    onStateSelected(stateId) {
      this.itemSelected(1);
      const selectedState = this.items[1].find((state) => state.id === stateId);
      this.$emit("stateDetails", selectedState);
    },
    onCitySelected(cityId) {
      this.itemSelected(2);
      const selectedCity = this.items[2].find((city) => city.id === cityId);
      this.$emit("cityDetails", selectedCity);
    },
  },
};
</script>

<style lang="scss">
.geo-breadcrumb {
  list-style: none;
  .geo-breadcrumb-item {
    display: inline;
    & + span:before {
      padding: 8px;
      color: black;
      font-family: FontAwesome;
      content: "\f0da";
    }
  }
}
</style>
