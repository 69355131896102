import api from "../../services/worksheet";
import base from "./assessment";
export default function (helper) {
	const store = {
		...base(helper, api),
	};
	/**
	 * Generated store module
	 */
	helper.setApi(api);
	store.types = {
		...store.types,
		...helper.types("worksheet"),
	};
	// Need to make sure we're using the right API for the actions
	store.actions = {
		...store.actions,
		...helper.actions({}, "Worksheet"),
	};

	return store;
}
