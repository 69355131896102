var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAnyAssociations)?_c('VCard',{staticClass:"px-0"},[_c('VCardTitle',[_c('h2',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('VCardText',{staticClass:"pb-0"},[_vm._l((_vm.allAssociations),function(association,key){return [(_vm.model.hasOwnProperty(association.key))?[(association.type == 'url')?_c('Chips',{key:`${association.key}-${_vm.model.id}-${key}`,attrs:{"open-in-new-window":"","title":_vm.featureName(association.title),"items":_vm.model[association.key],"url-resolver":item => _vm.getFullyQualifiedUrlForItem(association.viewRoute, {
            type: item.type,
            id: item.id
          }),"action":item => _vm.openUrl(_vm.getFullyQualifiedUrlForItem(association.viewRoute, {
            type: item.type,
            id: item.id
          }), '_blank')}}):_c('ChipsDialog',{key:`${association.key}-${_vm.model.id}-${key}`,attrs:{"title":_vm.featureName(association.title),"models":_vm.model[association.key],"component":association.view}})]:_vm._e()]})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }