<template>
  <VRow
    fill-height
    align-start
    space-around
    row
    wrap
    :justify-start="card.name || description || card.layout == 'Left'"
    :justify-end="!card.name && !description && card.layout == 'Right'"
  >
    <VSpacer v-if="$vuetify.breakpoint.smAndDown" />
    <VCol
      v-if="card.name || description"
      cols="12"
      order-sm="3"
      :class="{
        'd-flex align-center': true,
        'order-first': card.images.length === 0 && card.layout == 'Right',
        'order-last': card.images.length === 0 && card.layout == 'Left',
      }"
      :style="{
        flex: $vuetify.breakpoint.smAndDown ? '0 1 100%' : '1 1 calc(100% - 580px)',
        paddingLeft: '28px',
        marginTop: '50px',
        marginBottom: '50px',
      }"
    >
      <VRow 
        space-around
        :style="{
          maxHeight: $vuetify.breakpoint.smAndDown ? 'auto' : 'calc(443px - 104px)',
          overflow: 'hidden auto',
        }"
      >
        <VCol>
          <CardTitle
            class="card-title"
            :align="!description || $vuetify.breakpoint.smAndDown ? 'center' : 'left'"
            :style="{
              fontSize: `${'24px'}!important`,
            }"
          >
            {{ card.name }}
          </CardTitle>
          <p
            v-if="$vuetify.breakpoint.mdAndUp"
            v-html="description"
            v-linkified
            :class="{
              formatted: true,
            }"
            :style="{
              lineHeight: 'normal',
              fontSize: '16px',
              opacity: 0.75,
              fontWeight: 300,
              textAlign: 'left'
            }"
          />
        </VCol>
      </VRow>
    </VCol>
    <VCol
      order-sm="2"
      :fill-height="$vuetify.breakpoint.mdAndUp"
      :xs="description !== null && !$vuetify.breakpoint.smAndDown ? 10 : 12"
      :class="{
        'layout row justify-center align-center mb-0 mt-0': true,
        'order-first': card.images.length === 0 && card.layout == 'Left',
        'order-last': card.images.length === 0 && card.layout == 'Right',
      }"
      :style="{
        flex: $vuetify.breakpoint.smAndDown ? '0 1 100%' : '0 1 580px',
      }"
    >
      <VCol
        class="responsive-video"
        :style="{
          width: '100%',
          maxWidth: $vuetify.breakpoint.mdAndUp ? '1920px' : '100%',
          maxHeight: !!description && !card.name || $vuetify.breakpoint.mdAndUp ? '100%' : ($vuetify.breakpoint.smAndDown ? '500px' : '375px')
        }"
      >
        <template v-if="getIsSelfHostedMedia(card.video || card.value)">
          <video
            ref="video"
            style="position: relative; display: block; margin-left: auto; margin-right: auto; width: inherit; height: auto; min-height: 210px; border-radius: 4px;"
            :autoplay="false"
            :controls="true"
            @click.left="onCancelContextMenu($event)"
            @contextmenu="onCancelContextMenu($event)"
          >
            <source
              :id="mediaId"
              :type="mediaType"
              :src="url"
              @click.left="onCancelContextMenu($event)"
              @contextmenu="onCancelContextMenu($event)"
            >
          </video>
        </template>
        <iframe
          v-else
          ref="iframe"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          :style="style"
          :src="url"
        />
      </VCol>
    </VCol>
    <VCol
      v-if="description && $vuetify.breakpoint.smAndDown"
      order-sm="3"
      order-md="3"
    >
      <p
        :class="{
          formatted: true,
          'text-center': $vuetify.breakpoint.smAndDown
        }"
        v-html="description"
        v-linkified
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";
import DownloadsFilesMixin from "@/mixins/DownloadsFiles";

export default {
  name: "PresentationCardVideo",
  mixins: [PresentationCardMixin, DownloadsFilesMixin],
  props: {
    video: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isCurrent(value) {
      console.log("Video card switched", value);
      if (!value) {
        // Pause the playing content
        if (this.$refs.video) {
          this.$refs.video.pause();
        } else if (this.$refs.iframe) {
          const value = this.realUrl;
          const url = new URL(value);
          console.log("URL", url);
          try {
            switch (url.hostname) {
              case "www.youtube.com":
              case "youtu.be":
                this.$refs.iframe.contentWindow.postMessage(
                  '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
                  "*"
                );
                break;

              default:
                this.$refs.iframe.contentWindow.postMessage(
                  '{"method":"pause"}',
                  "*"
                );
                break;
            }
          } catch (error) {
            this.$log.error(error);
          }
        }
      }
    },
    index() {},
  },
  computed: {
    isSelfHostedMedia() {
      return this.getIsSelfHostedMedia(this.card.video || this.card.value);
    },
    style() {
      return {
        width: "100%",
        maxWidth: "calc(100vw - 32px)",
        minHeight: this.$vuetify.breakpoint.mdAndUp ? "315px" : "auto",
        maxHeight: this.$vuetify.breakpoint.mdAndUp ? "100%" : "315px",
      };
    },
    realUrl() {
      if (this.card.video && this.card.video instanceof Object) {
        return this.card.video.url ? this.card.video.url : "";
      } else {
        return this.card.value instanceof Object ? this.card.value.url : "";
      }
    },
    url() {
      const value = this.realUrl;
      try {
        const url = new URL(value);
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtu.be":
            const hostname =
              ["www.youtube.com", "youtube.com"].indexOf(url.hostname) === -1
                ? "www.youtube.com"
                : url.hostname;
            return `//${hostname}/embed/${
              url.searchParams.get("v") || url.pathname.substr(1)
            }?enablejsapi=1`;
            break;

          case "vimeo.com":
          case "www.vimeo.com":
            return `//player.vimeo.com/video${url.pathname}`;
            break;

          default:
            return value;
            break;
        }
      } catch (e) {
        return value;
      }
    },
    thumb() {
      return this.card.video instanceof Object && this.card.video.thumb
        ? this.card.video.thumb
        : "";
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: "24px" !important;
  font-weight: "normal" !important;
  line-height: 0.96 !important;
  padding-bottom: 16px;
}
</style>