<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card-heading,table-heading,table-thead,table-tbody,table-tfoot"
    :loading="activity.isFirstLoad"
  >
    <VRow column>
      <VCol
        v-if="!hasExistingData"
        mb-2
        shrink
        xs12
        :style="{
          height: $vuetify.breakpoint.mdAndUp ? '112px': 'auto'
        }"
      >
        <Stats
          :stats="config.stats"
          :options="statOptions"
        />
      </VCol>
      <VCol style="flex: 1 1 auto">
        <CurrentFilter
          v-if="getQueryFilterValues.length > 0"
          class="rounded-lg pt-2 pb-6 mx-0 mb-n3 white"
          :filter="filter"
          :filter-values="getQueryFilterValues"
          :is-loading="activity.isLoading"
          @removeFilter="onRemoveFilter"
          @resetFilter="onResetFilter"
        />
        <DataViewUI
          :data="data"
          :headers="headers"
          :is-loading="activity.isLoading"
          :hide-main-title="hasExistingData"
          :hide-records-info="hasExistingData"
          :hide-actions="hasExistingData"
          :disable-sort="hasExistingData"
          :class="{
            'pa-3 pt-0': hasExistingData
          }"
          @paginate="onGetMore"
          @search="onSearch"
        >
          <template
            slot="items-cells"
            slot-scope="props"
          >
            <td>
              <VRow>
                <VCol class="d-flex align-center">
                  <span 
                    class="d-block text-truncate" 
                    style="max-width: 360px;"
                  >
                    {{ props.item.title }}
                  </span>
                  <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, props.item.id)" />
                </VCol>
              </VRow>
            </td>
            <td style="max-width: calc(33.33vw)">
              <p class="text-truncate sm6">
                {{ props.item.summary }}
              </p>
            </td>
            <td v-if="$isUserAdmin && $viewProgramAsAdmin">
              <UserModal :user="props.item.user" />
            </td>
            <td class="actions">
              <Menu
                :disabled="getIsDisabled(props.item)"
                :loading="props.item.isLoading"
              >
                <VListItem
                  v-if="hasActionsAccess(props.item)"
                  :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                  @click.prevent="onSetFormDialogModel(props.item)"
                >
                  <VListItemTitle>
                    <VIcon small>
                      edit
                    </VIcon>&nbsp;Edit
                  </VListItemTitle>
                </VListItem>
                <VListItem
                  :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                  @click.prevent="onSetViewDialogModel(props.item)"
                >
                  <VListItemTitle>
                    <VIcon small>
                      visibility
                    </VIcon>&nbsp;View
                  </VListItemTitle>
                </VListItem>
                <VListItem
                  v-if="$isUserAdmin && $viewProgramAsAdmin"
                  @click.prevent="onDuplicateItem(props.item, false, duplicateAlertMessage, true, true)"
                >
                  <VListItemTitle>
                    <VIcon small>
                      content_copy
                    </VIcon>&nbsp;Duplicate
                  </VListItemTitle>
                </VListItem>
                <VListItem
                  v-if="hasActionsAccess(props.item)"
                  @click.prevent="onDeleteItem(props.item, false, false, false)"
                >
                  <VListItemTitle color="error">
                    <VIcon
                      small
                      color="error"
                    >
                      delete
                    </VIcon>&nbsp;Delete
                  </VListItemTitle>
                </VListItem>
              </Menu>
            </td>
          </template>
          <template #expand="props">
            <p
              class="formatted px-6 py-6"
              v-html="props.item.content"
            />
          </template>
        </DataViewUI>
        <FormDialog
          :read-only="!$isUserAdmin && !hasActionsAccess(currentModel)"
          :current-model="currentModel"
          :is-dialog-open="dialogs.form"
          :title="currentModel ? currentModel.title : ''"
          @toggle:form:dialog="onToggleFormDialog"
          @deleteItem="onDeleteItem"
          model-type="FAQ"
        >
          <Save
            ref="saveForm"
            mode="dialog"
            :id="currentModel ? currentModel.id : null"
            :read-only="!$isUserAdmin && !hasActionsAccess(currentModel)"
            @saved="result => $emit('updated', result)"
            @duplicated="result => $emit('duplicated', result)"
          />
        </FormDialog>
        <Confirm ref="confirmDelete" />
        <Confirm
          ref="confirmDuplicate"
          confirm-color="info"
          cancel-color="disabled"
        />
        <Dialog
          max-width="640px"
          :is-dialog-open="dialogs.view"
          :title="`View ${featureName('FAQs')}`"
          @toggle:dialog="onToggleViewDialog"
        >
          <ViewComponent
            :id="currentModel ? currentModel.id : null"
            ref="viewItem"
            mode="dialog"
          />
        </Dialog>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import Save from "./Save";
import ViewComponent from "./View";
import { mapActions } from "vuex";

export default {
  name: "FaqsTable",
  components: {
    Save,
    ViewComponent,
  },
  mixins: [SearchMixin, HasStats],
  data() {
    return {
      date: null,
      modelType: "faq",
      duplicateAlertMessage: `Are you sure you want to duplicate this ${this.featureName('FAQ')}?`,
      config: {
        stats: {
          faqs: 0,
        },
      },
    };
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "Question",
          align: "left",
          sortable: true,
          value: "title",
          class: "faq-title",
        },
        {
          text: this.featureName("Answer"),
          align: "left",
          sortable: false,
          value: "content",
          class: "faq-content",
        },
      ];
      if(this.$isUserAdmin && this.$viewProgramAsAdmin) {
        headers.push({
          text: "Created By",
          align: "left",
          sortable: true,
          value: "user.name",
        });
      }
      return headers;
    },
  },
  created() {
    if(!this.hasExistingData) {
      this.doGetIndexConfig().then((result) => (this.config = result));
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "faq/getIndexConfig",
      doGetFaqs: "faq/getAll",
      doDeleteFaq: "faq/delete",
      doDuplicateFaq: "faq/duplicate",
    }),
    onDuplicate(params) {
      return this.doDuplicateFaq(params);
    },
    onDelete(faq) {
      return this.doDeleteFaq(faq.id).then((result) => {
        if(result) {
          this.$bus.$emit("notificationMessage", `Deleted ${this.featureName(this.modelType, "singularize").toUpperCase()} successfully!`);
        }
        return result;
      });
      
    },
    onGetAll(params) {
      params._with = "user";
      return this.doGetFaqs(params).then((result) => {
        // Handled duplication loading card
        if(!params.hasOwnProperty("page") || (params.hasOwnProperty("page") && params.page == 1)) {
          const pendingDuplications = this.getGroupItemLocalStorage("pending-duplications", this.modelType);
          if(pendingDuplications && Object.keys(pendingDuplications).length > 0) {
            Object.keys(pendingDuplications).map((itemKey) => {
              const itemIndex = result.data.findIndex((item) => item.duplicated_using_id == itemKey.split("-")[0]);
              if(itemIndex > -1) {
                this.$delete(pendingDuplications, itemKey);
                this.removeGroupItemLocalStorage("pending-duplications", this.modelType, itemKey);
              }
            });
          }
          this.$set(result, "data", [
            ...Object.values(pendingDuplications),
            ...result.data,
          ]);
        }
        return result;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/stats.scss";

.data-table table.v-table thead th.column.faq-title {
  flex: 0 1 25%;
}

.data-table table.v-table thead th.column.faq-content {
  flex: 1 1 75%;
  max-width: 75%;
}
</style>