<template>
  <VContainer>
    <VRow
      column
      fill-height
      id="user-information"
      grid-list-lg
      style="height: 100%"
      :class="{
        'is-loading': activity.isLoading,
      }"
    >
      <!-- <VCol
        v-if="!$isUserApproved"
        cols="12"
      >
        <VAlert
          type="warning"
          :value="true"
        >
          You haven't been approved for this organization yet! Please wait for the organization admin to approve you. You can switch to a different team by tapping the
          <strong>Select Team</strong> button above.
        </VAlert>
      </VCol> -->
      <VCol
        v-if="!$hasTeams"
        cols="12"
      >
        <VAlert
          type="warn"
          :value="true"
        >
          You are not currently a member of any teams. Join a team by tapping the
          <strong>Select Team</strong> button above.
        </VAlert>
      </VCol>
      <VCol
        v-if="needsToComplete"
        cols="12"
      >
        <VAlert
          type="warning"
          :value="true"
        >
          Please complete the setup of your account! Scroll down to confirm your timezone, date and time formats
        </VAlert>
      </VCol>
      <VRow
        :row="$vuetify.breakpoint.mdAndUp"
        :column="$vuetify.breakpoint.smAndDown"
        wrap
        class="username-header"
        justify-space-between
        fill-width
      >
        <VCol
          cols="12"
          md="5"
          align-center
          justify-start
        >
          <h1
            :class="{
              'text-center': $vuetify.breakpoint.smAndDown
            }"
          >
            {{ user.name }}
          </h1>
        </VCol>
        <VCol
          cols="12"
          md="2"
          align-center
          d-flex
          justify-start
        >
          <VRow
            column
            align-center
            :justify-center="$vuetify.breakpoint.smAndDown"
          >
            <Badge
              right
              color="transparent"
            >
              <VAvatar
                v-if="form.photo_url"
                size="72"
              >
                <img :src="form.photo_url">
              </VAvatar>
              <VBtn
                id="pick-avatar"
                slot="badge"
                icon
                small
                color="info"
              >
                <VIcon>edit</VIcon>
              </VBtn>
            </Badge>
            <AvatarCropper
              ref="avatar"
              :upload-handler="saveAvatar"
              trigger="#pick-avatar"
              :labels="{submit: 'Save', cancel: 'Cancel'}"
            />
          </VRow>
        </VCol>
        <VSpacer />
        <VCol
          cols="12"
          md="3"
          d-flex
          justify-end
          align-center
        >
          <VBtn
            large
            class="wethrive"
            style="text-transform: uppercase"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="!isFormReady"
            @click="onSave"
          >
            Save Changes
          </VBtn>
        </VCol>
      </VRow>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol style="flex: 6 6 auto">
        <VRow
          align-space-between
          justify-space-between
          row
          wrap
        >
          <VCol
            align-center
            cols="12"
          >
            <VCard
              subheader
              class="white"
            >
              <VCardTitle>Details</VCardTitle>
              <VDivider />
              <VCardText>
                <TextField
                  v-model="form.name"
                  block
                  :placeholder="form.name"
                  type="text"
                  :error="$v.form.name.$error"
                  @input="$v.form.name.$touch()"
                  @blur="$v.$touch()"
                />
                <TextField
                  v-model="form.email"
                  label="Email"
                  :placeholder="form.email"
                  type="email"
                  :error="$v.form.email.$error"
                  @input="$v.form.email.$touch()"
                  @blur="$v.$touch()"
                />
                <LongTextField
                  v-if="form.profile"
                  v-model="form.profile.bio"
                  label="Bio"
                />
                <GeoSelectCity
                  v-if="isFormReady && options.countries.length"
                  :countries="options.countries"
                  :current-city="form.city"
                  :current-city-id="form.city_id"
                  @citySelected="(id) => form.city_id = id"
                  @removeCity="form.city = form.city_id = null"
                />
                <div>
                  <h4 class="read-only-label">
                    Role
                  </h4>
                  <p class="read-only-value">
                    {{ userRole }}
                  </p>
                </div>
                <div>
                  <h4 class="read-only-label">
                    Last Login
                  </h4>
                  <p class="read-only-value">
                    {{ getDate(user.last_login) }}
                  </p>
                </div>
                <div>
                  <h4 class="read-only-label">
                    Member Since
                  </h4>
                  <p class="read-only-value">
                    {{ getDate(user.created_at) }}
                  </p>
                </div>
              </VCardText>
            </VCard>
            <br>
            <VCard
              subheader
              class="white"
            >
              <VCardTitle class="py-0">
                <VCheckbox
                  style="min-height: 24px;"
                  label="Change Password"
                  v-model="changePassword"
                />
              </VCardTitle>
              <VDivider />
              <VCardText v-show="changePassword">
                <PasswordInput
                  v-model="form.password"
                  label="New Password"
                  :editable="false"
                  :disabled="!changePassword"
                  :error="$v.form.password.$error"
                  @input="$v.$touch()"
                />
                <PasswordInput
                  v-if="form.password"
                  v-model="form.password_confirmation"
                  label="Confirm Password"
                  :editable="false"
                  :disabled="!changePassword"
                  :error="$v.form.password_confirmation.$error"
                  @input="$v.$touch()"
                />
                <VBtn
                  class="wethrive"
                  block
                  large
                  :disabled="!isFormReady"
                  @click="onSavePassword"
                >
                  Save Password
                </VBtn>
              </VCardText>
            </VCard>
          </VCol>
          <VCol
            align-center
            cols="12"
          >
            <VCard class="mb-2">
              <VCardTitle>Time Settings</VCardTitle>
              <VCardText>
                <VSwitch
                  v-model="form.timezone_full_format"
                  inset
                  label="Show full timezone name"
                />
                <Autocomplete
                  v-model="form.timezone"
                  append-inner-icon="arrow-down"
                  placeholder="Select Timezone"
                  label="Timezone"
                  item-value="value"
                  :filter-callback="timezoneFilter"
                  :multiple="false"
                  :item-text="form.timezone_full_format ? 'text' : 'short'"
                  :items="timezones"
                />
                <Autocomplete
                  v-model="form.time_format"
                  append-inner-icon="arrow-down"
                  placeholder="Select time format"
                  label="Time format"
                  item-text="text"
                  item-value="value"
                  :multiple="false"
                  :items="options.time_options"
                />
                <Autocomplete
                  v-model="form.date_format"
                  append-inner-icon="arrow-down"
                  placeholder="Select date format"
                  label="Date format"
                  item-text="text"
                  item-value="value"
                  :multiple="false"
                  :items="options.date_options"
                />
              </VCardText>
            </VCard>
            <VCard
              v-if="history.length"
              subheader
              class="white"
            >
              <VCardTitle>Content History</VCardTitle>
              <VCardText>
                <div>
                  <DivTile
                    v-for="(item, i) in history"
                    :key="i"
                    class="py-4"
                  >
                    <DivTileContent>
                      <DivTileTitle>{{ item.title }}</DivTileTitle>
                    </DivTileContent>
                    <DivTileAction>{{ item.date }}</DivTileAction>
                  </DivTile>
                </div>
              </VCardText>
            </VCard>
            <VDivider v-if="isEnabledFeature('account_status')" />
            <VCard 
              v-if="isEnabledFeature('account_status')" 
              class="mb-2"
            >
              <VCardTitle class="text-h6">
                Account Status
              </VCardTitle>
              <VCardText>
                <VCard
                  v-for="(setting, index) in dangerSettings"
                  subheader
                  class="white"
                  :key="index"
                >
                  <VCardTitle :class="`${setting.title.color}--text text-subtitle-1`">
                    {{ setting.title.text }}
                  </VCardTitle>
                  <VCardText>
                    <template v-if="$user.role_slug == 'owner'">
                      Please contact support using the help icon.
                    </template>
                    <template v-else>
                      <p v-html="setting.description.title" />
                      <ul class="pb-2 mt-n3">
                        <li
                          v-for="(caption, key) in setting.description.captions"
                          :key="key"
                        >
                          {{ caption }}
                        </li>
                      </ul>
                      <VBtn
                        :color="setting.button.color"
                        :loading="setting.isLoading"
                        :disabled="setting.isLoading"
                        @click="performAccountAction(setting, index)"
                      >
                        {{ setting.button.text }}
                      </VBtn>
                    </template>
                  </VCardText>
                </VCard>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
        <Confirm 
          ref="confirmDeleteInfo" 
          confirm-text="Continue"
          cancel-text="Cancel"
        />
        <Confirm 
          ref="confirmDelete" 
          confirm-text="I Understand"
          cancel-text="Cancel"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import moment from "moment";
import FormMixin from "@/mixins/Form";
import SearchMixin from "@/mixins/Search";
import AvatarCropper from "vue-avatar-cropper";
import { mapActions } from "vuex";
import { required, minLength, between, sameAs } from "vuelidate/lib/validators";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "InformationPanel",
  components: {
    AvatarCropper,
    PasswordInput,
  },
  mixins: [FormMixin, SearchMixin],
  data() {
    return {
      modelType: "Account",
      date: null,
      history: [],
      changePassword: false,
      saveOnly: [
        "id",
        "avatar",
        "name",
        "timezone",
        "time_format",
        "date_format",
        "timezone_full_format",
        "password",
        "password_confirmation",
        "city_id",
        "profile",
        "email",
      ],
      defaultForm: {
        name: "",
        role_id: null,
        city_id: null,
        email: "",
        password: null,
        profile: {
          bio: "",
        },
      },
      options: {
        countries: [],
      },
      fileProperties: ["avatar"],
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      name: {
        required,
      },
      password: {},
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
      // profile: {
      //   bio: {
      //     required,
      //     minLength: 3
      //   }
      // }
    },
  },
  computed: {
    needsToComplete() {
      return (
        !this.form.timezone || !this.form.time_format || !this.form.date_format
      );
    },
    user() {
      return this.$auth.user();
    },
    userRole() {
      return this.$user.role_name || "No Role";
    },
    timezones() {
      return (this.options.timezones || [])
        .unique(this.form.timezone_full_format ? "text" : "short_offset")
        .sortBy(this.form.timezone_full_format ? "text" : "offset", true)
        .filter((tz) => {
          return this.timezone_full_format
            ? true
            : Number.isNaN(parseInt(tz.short));
        });
    },
    dangerSettings() { 
      return [
        {
          type: "leave",
          isLoading: false,
          isEnabled: this.$hasTeams,
          button: {
            color: "warning",
            text: `Leave ${this.$team.name}`,
          },
          title: {
            color: "default",
            text: "Leave Current Team",
          },
          description: {
            title: `You are currently viewing <b>${this.$team.name}</b>. Switch workspaces if you meant to leave a different team.<br><br>Leaving a team has the following effects:`,
            captions: [
              `This removes the user account access including ${this.featureName('Pipelines')}, ${this.featureName("Forms")}, ${this.featureName("Chat")}, ${this.featureName("Content")}, ${this.featureName("Faqs")}, ${this.featureName("Programs")}.`,
              "This does not delete your content.",
            ],
          },
        },
        {
          type: "deactivate",
          isLoading: false,
          isEnabled: false,
          button: {
            color: "warning",
            text: "Deactivate Account",
          },
          title: {
            color: "default",
            text: "Deactivate Account",
          },
          description: {
            title: "Need a break? Deactivate your account so you can catch up later.",
            captions: [],
          },
        },
        {
          type: "delete",
          isLoading: false,
          isEnabled: true,
          button: {
            color: "error",
            text: "Delete Account",
          },
          title: {
            color: "default",
            text: "Delete Account",
          },
          description: {
            title: `Warning: Deleting an account cannot be undone.<br><br>Deleting an account has the following effects:`,
            captions: [
              `This deletes the user account access including ${this.featureName('Pipelines')}, ${this.featureName("Forms")}, ${this.featureName("Chat")}, ${this.featureName("Content")}, ${this.featureName("Faqs")}, ${this.featureName("Programs")}.`,
            ],
          },
          
        },
      ].filter((item) => item.isEnabled === true);
    },
  },
  created() {
    this.form = {
      ...this.$user,
    };
    this.form.profile = this.form.profile || {};
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "users/getFormConfig",
      doSaveUser: "user/save",
      doDeleteUser: "users/deleteUser",
      doDeleteUserParmanently: "users/deleteUserParmanently",
      doLeaveTeam: "team/leave",
    }),
    getDate(date) {
      return date ? this.$timezone.formatDateTime(date) : "";
    },
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    performAccountAction(setting, index) {
      let alertMessage = null;
      let alertAction = null;
      switch (setting.type) {
        case "leave":
          alertAction = this.doLeaveTeam;
          alertMessage = "WARNING! Leaving a team is a permanent action. You will need to rejoin by re-applying or by invite from an organization admin to join the team again.";
          this.deleteAlertTitle = "Leave Current Team";
          this.deleteAlertOptions.color = setting.button.color;
          this.deleteAlertMessage = `Please confirm you understand that you are leaving ${this.$team.name}.`;
          break;
        case "deactivate":
          alertAction = this.doDeleteUser;
          alertMessage = "Deactivating your account is a non-permanent action. You need to contact support to recover your account after deactivation.";
          this.deleteAlertTitle = "Deactivate Account";
          this.deleteAlertOptions.color = setting.button.color;
          this.deleteAlertMessage = "Please confirm that you understand that your account will be deactivated.";
          break;
        case "delete":
          alertAction = this.doDeleteUserParmanently;
          alertMessage = "Deleting your account is a permanent action. There is no way to recover your account after deletion.";
          this.deleteAlertTitle = "Delete Account";
          this.deleteAlertOptions.color = setting.button.color;
          this.deleteAlertMessage = "Please confirm that you understand that your account will be deleted.";
          break;
      }
      this.$refs.confirmDeleteInfo
        .open(this.deleteAlertTitle, alertMessage, this.deleteAlertOptions)
        .then((status) => {
          if(status) {
            let params = setting.type == "leave" ? this.$team : this.$user;
            this.$set(this.dangerSettings[index], "isLoading", true);
            this.onDeleteItem(params, alertAction, null, false).then((result) => {
              if (result) {
                this.$set(this.dangerSettings[index], "isLoading", false);
                this.$bus.$emit("notificationMessage", `Successfully ${setting.type == "leave" ? "team" : "account"} ${setting.type}d!`);
                window.location.reload();
              }
            });
          }
        });
    },
    onSave() {
      const team = this.$team;
      return this.saveFormModel(this.doSaveUser).then((user) => {
        this.form.profile = this.form.profile || {};
        this.form.role_id = this.form.role ? this.form.role.id : "mentor";
        this.$auth.user({ ...this.$user, ...user, team });
      });
    },
    saveAvatar(cropper) {
      cropper
        .getCroppedCanvas(this.$refs.avatar.outputOptions)
        .toBlob((blob) => {
          return this.saveFormModel(this.doSaveUser, null, {
            id: this.form.id,
            avatar: new File([blob], this.$refs.avatar.filename),
          }).then((result) => (this.form.photo_url = result.photo_url));
        });
    },
    onSavePassword() {
      return this.saveFormModel(this.doSaveUser, null, {
        id: this.form.id,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      });
    },
    timezoneFilter(item, search, value) {
      if (search) {
        return (
          item.short.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          item.short_offset
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1 ||
          item.text.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          item.value
            .replace("_", " ")
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
        );
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.details-card {
  border-radius: 6px;
  color: $font-light-color;
  background-color: $white-color;
  box-shadow: 0 0 10px 0 $box-shadow-color;
  padding: 0 20px;

  .v-subheader {
    padding: 0;
  }

  hr {
    height: 1px;
    opacity: 0.5;
    background-color: $border-color;
  }

  .details-content .div {
    padding: 15px 0;

    .v-input {
      color: $content-title-color;
    }

    .v-label::after {
      color: $font-light-color;
    }
  }
}

.application .theme--light.v-btn,
.theme--light .v-btn,
.userprofile-save-button {
  border-radius: 24px;
  background-color: $font-light-color;
  color: $white-color;
  text-transform: none;
}

.username-header {
  padding: 0.5rem 1rem;
}

#user-profile .read-only-label {
  color: #a4a6a9;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
}

#user-profile .read-only-value {
  height: 14px;
  color: #404041;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
#user-profile .v-text-field input#name-input {
  color: #333636;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}
</style>