<template>
  <VRow
    v-if="modelHasId"
    column
    fill-height
  >
    <VCol
      v-if="mode === 'standalone'"
      style="flex: 0 0 64px"
    >
      <VRow
        row
        justify-space-between
      >
        <h1>
          <VBtn
            icon
            fab
            @click="$router.go(-1)"
          >
            <VIcon>arrow_back</VIcon>
          </VBtn>
          View {{ featureName("Pipeline") }}
          <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, model.id)" />
          <Linkable
            :tooltip="`Click or tap to ${$isUserStudent ? 'submit' : 'update'}`"
            :icon="$isUserStudent ? 'assignment_ind' : 'edit'"
            :copyable="false"
            :icon-props="{small: true}"
            :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.${$isUserStudent ? 'submit' : 'update'}`, model.id)"
            :to="getRouteForItem(`${modelTypeRoute}.update`, model.id)"
          />
        </h1>
      </VRow>
    </VCol>
    <VCol
      style="flex: 1 1 auto"
      class="pb-0"
    >
      <VRow
        row
        wrap
      >
        <VCol cols="12">
          <VCard>
            <h1
              v-if="mode !== 'embedded'"
              class="mb-3"
            >
              {{ model.pipeline.title }}
            </h1>
            <VRow
              row
              wrap
              class="pl-3 align-center"
            >
              <UserModal
                :title="model.title"
                :new-messages-count="model.new_messages_for_user_count"
                :members="(model.reviewers || []).map(u => u.id)"
                :entity="model"
                :thread="model.thread_for_user"
                :user="model.user"
                :enable-edit="$isUserAdmin"
                :enable-chat="teamSetting('pipeline.is_chat_enabled', true) || true"
              />
              <VChip
                class="mx-2"
                :color="model.pipeline.is_expired ? 'light-grey' : 'success'"
                :text-color="model.pipeline.is_expired ? 'light-grey' : 'white'"
              >
                {{ model.pipeline.status.titleize() }}
              </VChip>
              <Tooltip
                :as-button="false"
                :color="(model.pipeline || {is_expired: false}).is_expired && (!model.is_complete || !model.is_submitted) ? 'warning' : ''"
                :text-color="(model.pipeline || {is_expired: false}).is_expired && (!model.is_complete || !model.is_submitted)? 'white' : ''"
                :icon-color="model.is_complete ? 'success' : 'info'"
                :icon="getApplicationStatusIcon(model)"
                :title="model.status_message"
                :button-text="model.status.titleize()"
              />
            </VRow>
            <VCardText class="px-0 pb-0">
              <h3 v-if="model.pipeline && model.pipeline.city">
                {{ model.pipeline.city.name }}
              </h3>
              <p
                class="formatted"
                v-html="model.pipeline.content"
              />
              <VExpansionPanels
                focusable
                class="view-submitted-panels no-elevation"
              >
                <VExpansionPanel
                  v-for="(stage,key) in stages"
                  style="width: 100%"
                  :key="`stage-${key+1}`"
                  :class="{
                    'no-elevation': true,
                    'disabled': stage.is_expired,
                    'py-3 mb-3': true
                  }"
                  @click.stop="() => {}"
                >
                  <VExpansionPanelHeader>
                    <h2 class="title">
                      {{
                        stage.stage
                          ? stage.stage.title
                          : `(Default) ${featureName('Stage', 'singularize')} ${key + 1}`
                      }}
                      <Tooltip
                        depressed
                        button-class="normal-text px-2"
                        :text="false"
                        :color="getCurrentStageStatusColor(stage || {})"
                        :text-color="getCurrentStageStatusTextColor(stage.stage || {is_expired: false})"
                        :icon-color="getCurrentStageStatusIconColor(stage || {})"
                        :icon="getCurrentStageStatusIcon(stage || {})"
                        :title="getCurrentStageMessage(stage || {status_message: 'Not Started'})"
                        :button-text="getCurrentStageText(stage || {status: 'Not Started'})"
                      />
                    </h2>
                    <p
                      class="formatted"
                      v-if="stage.description"
                      v-html="stage.description"
                    />
                  </VExpansionPanelHeader>
                  <VExpansionPanelContent class="px-0 pt-0">
                    <DynamicInputsView
                      v-if="stage.metadata instanceof Array && stage.metadata.length"
                      :card="false"
                      :inputs="stage.metadata"
                      :input-name="featureName('Metadata')"
                      class="px-2 py-2 grey lighten-3"
                    />
                    <VAlert
                      v-else
                      type="info"
                      class="mx-2 my-2"
                      :value="true"
                    >
                      No entries set for this stage
                    </VAlert>
                  </VExpansionPanelContent>
                </VExpansionPanel>
              </VExpansionPanels>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
  <VRow
    v-else
    row
    fluid
    justify-center
    class="mt-4"
  >
    <VProgressCircular
      indeterminate
      color="primary"
    />
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";

export default {
  name: "SubmittedApplication",
  components: { DynamicInputsView },
  mixins: [ViewMixin, HasCalendarEntries, HasApplicationPipelinesMixin],
  data() {
    return {
      modelType: "Pipeline Submitted",
    };
  },
  created() {
    if (this.existing && this.existing.id) {
      this.model = this.existing;
    }
  },
  computed: {
    stages() {
      return this.model.stages instanceof Array
        ? this.model.stages
        : [this.model.current_stage_for_user];
    },
  },
  methods: {
    ...mapActions({
      doGetPipelineSubmitted: "pipelineSubmitted/getOne",
    }),
    onGet(id) {
      return this.doGetPipelineSubmitted(id);
    },
  },
};
</script>
<style lang="scss">
.view-submitted {
  .view-submitted-panels {
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
