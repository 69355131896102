<template>
  <VRow>
    <VCol>
      <VCard class="pa-2 my-4">
        <VCardText class="px-4">
          <VRow>
            <VCol>
              <span class="pipeline-label">Evaluate</span>
            </VCol>
          </VRow>
          <VAlert
            color="rgba(73, 148, 236, 0.2)"
            class="subtitle-2 font-weight-medium"
            style="border-radius: 14px"
          >
            <template #prepend>
              <VIcon
                color="#4994EC"
                class="pr-3"
              >
                mdi-information
              </VIcon>
            </template>
            People who review and assess applicants and registrants.
          </VAlert>
          <VRow>
            <VCol>
              <MembersList
                v-if="hasCurrentStage"
                alt-view
                label="Search..."
                :users="value.reviewers"
                :readonly="value.reviewers.length === 0"
                @removeMember="removeMember"
                @toggleMemberDialog="toggleAddMembersDialog"
              />
            </VCol>
          </VRow>
          <VRow>
            <VSpacer />
            <VCol 
              lg="3"
              md="5"
              sm="6"
            >
              <VMenu
                v-model="addEvaluateFormMenu"
                attach
                offset-y
                offset-overflow
                max-width="220px"
                min-width="220px"
                content-class="no-shadow"
              >
                <template #activator="{ on, attrs }">
                  <VTooltip 
                    top
                    :disabled="value.hasOwnProperty('id')"
                  >
                    <template #activator="{ on: onTooltip }">
                      <span v-on="onTooltip">
                        <VBtn
                          v-on="on"
                          v-bind="attrs"
                          height="40px"
                          width="100%"
                          class="px-2 mr-6 eval-form-btn"
                          style="border-radius: 10px; box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);"
                          :disabled="!value.hasOwnProperty('id')"
                          @click="addEvaluateFormMenu = !addEvaluateFormMenu"
                        >
                          <img
                            v-if="value.stages[activeStageIndex].evaluation_form_count"
                            height="24px"
                            class="mr-2"
                            src="../../../images/forms/long-text-black-icon@2x.png"
                          >
                          <img
                            v-else
                            height="24px"
                            class="mr-2"
                            src="../../../images/forms/add-black-icon@2x.png"
                            :style="{
                              opacity: !value.id ? 0.2 : 1,
                            }"
                          >
                          <span class="subtitle-2">
                            {{ value.stages[activeStageIndex].evaluation_form_count ? "Edit" : "Add" }}  Evaluation Form
                          </span>
                          <VIcon
                            right
                            size="18px"
                            color="#bfbfbf"
                            class="mx-2"
                          >
                            {{ addEvaluateFormMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}
                          </VIcon>
                        </VBtn>
                      </span>
                    </template>
                    {{ `Save ${featureName("Pipeline").singularize().toLowerCase()} to enable.` }}
                  </vtooltip>
                </template>
                <VList
                  v-if="value.stages[activeStageIndex].evaluation_form instanceof Object"
                  class="mt-5 mb-n2 add-new-round"
                >
                  <VListItem
                    link
                    class="px-3"
                    style="min-height: 40px;"
                    @click="onEditStageEvaluation"
                  >
                    <VListItemTitle class="text-truncate subtitle-2">
                      <VIcon
                        color="#252526"
                        class="mr-2"
                      >
                        mdi-pencil-circle
                      </VIcon>
                      {{ value.stages[activeStageIndex].evaluation_form.title }}
                    </VListItemTitle>
                  </VListItem>
                </VList>
                <VList class="my-4 add-new-round">
                  <VSubheader
                    v-if="value.stages[activeStageIndex].evaluation_form_count"
                    class="font-weight-medium mt-n1 mb-n2"
                  >
                    Change Evaluation Form
                  </VSubheader>
                  <VListItem
                    link
                    class="px-3"
                    style="min-height: 40px;"
                    @click="onCreateNew"
                  >
                    <VListItemTitle class="d-flex align-center caption font-weight-bold">
                      <img
                        height="24px"
                        class="mr-2"
                        src="../../../images/forms/paper-plus@2x.png"
                      >
                      Create New
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    link
                    class="px-3"
                    style="min-height: 40px;"
                    @click="onUseExisting"
                  >
                    <VListItemTitle class="d-flex align-center caption font-weight-bold">
                      <img
                        height="24px"
                        class="mr-2"
                        src="../../../images/forms/long-text-icon@2x.png"
                      >
                      Use Existing
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
            </VCol>
            <VCol 
              lg="3"
              md="4"
              sm="6"
            >
              <VTooltip 
                top
                :disabled="value.stages[activeStageIndex].evaluation_form_count > 0"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <VBtn
                      height="40px"
                      width="100%"
                      color="#FFC100"
                      class="white--text"
                      style="
                        box-shadow: 0px 3px 6px rgba(250, 36, 111, 0.25);
                        border-radius: 10px;
                      "
                      :disabled="
                        !value.stages[activeStageIndex].evaluation_form_count
                      "
                      @click="onToggleEvaluationDialog(value.stages[activeStageIndex])"
                    >
                      <span class="subtitle-2">Evaluate Now</span>
                    </VBtn>
                  </span>
                </template>
                Add Evaluation Form to enable.
              </VTooltip>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
      <VCard class="pa-2 mt-4">
        <VCardText class="px-4">
          <VRow>
            <VCol>
              <span class="pipeline-label">Completed Evaluations{{ value.title ? `: ${value.title}` : "" }}</span>
              <VTooltip top>
                <template #activator="{on}">
                  <VIcon
                    v-on="on"
                    color="#BFBFBF"
                    class="ml-2"
                  >
                    mdi-information
                  </VIcon>
                </template>
                Here you can view all Evaluations that have already been completed by {{ featureName("Reviewer", "pluralize") }}
              </VTooltip>
            </VCol>
          </VRow>
          <VAlert
            class="subtitle-2 font-weight-medium mt-2"
            style="border-radius: 14px"
            :color="value.stages[activeStageIndex].evaluation_form_count ? 'rgba(73, 148, 236, 0.2)' : 'rgba(255, 82, 82, 0.2)'"
          >
            <template #prepend>
              <VIcon
                class="pr-3"
                :color="value.stages[activeStageIndex].evaluation_form_count ? '#4994EC' : '#ff5252'"
              >
                {{ value.stages[activeStageIndex].evaluation_form_count ? "mdi-information" : "mdi-alert" }}
              </VIcon>
            </template>
            <p
              v-if="!value.stages[activeStageIndex].evaluation_form"
              class="mb-0"
              style="color: #ff5252;"
            >
              This {{ featureName("stage") }} is missing an
              evaluation form!
            </p>
            <template v-else-if="value.stages[activeStageIndex].evaluation_form instanceof Object">
              Using evaluation form: {{ value.stages[activeStageIndex].evaluation_form.title }}
              <VBtn
                icon
                width="24px"
                height="24px"
                class="ml-2"
                @click="() => onToggleEditStageEvaluationForm()"
              >
                <img
                  height="24px"
                  src="../../../images/forms/edit-icon@2x.png"
                >
              </VBtn>
            </template>
          </VAlert>
          <SubmittedEvaluations
            v-if="value.stages[activeStageIndex].evaluation_form instanceof Object"
            mode="dialog"
            ref="submittedEvaluations"
            class="table-th-default-color"
            :use-id-from-route="false"
            :deliverable-id="(value.stages[activeStageIndex].evaluation_form || {}).id"
            :custom-filter="submittedEvaluationFilter"
          />
        </VCardText>
      </VCard>
      <Dialog
        :is-dialog-open="dialogs.members"
        :title="`Add ${memberDialog.type.titleize()}`"
        :hide-overlay="true"
        @toggle:dialog="toggleMemberDialog"
      >
        <MembersForm
          ref="addMembers"
          mode="multiple"
          :type="memberDialog.type"
          :existing="memberDialog.existing"
          :is-group="true"
          :exclude-me="false"
          @addMember="(user) => addMember(user, 'reviewers')"
          @removeMember="removeMember"
          @saveMembers="onSaveMembers"
        />
      </Dialog>
      <FormDialog
        v-if="dialogs.addEvaluation"
        is-dialog-open
        new-actions
        persistent
        model-type="PipelineEvaluationForm"
        :fullscreen="!useExisting"
        :max-width="useExisting ? '50%' : '90%'"
        :custom-class="useExisting ? '' : 'save-eval-dialog'"
        :title="`Evaluation form for: ${submittedModel.title || ''}`"
        @toggle:form:dialog="onToggleEditStageEvaluationForm"
      >
        <SaveEvaluationForm
          ref="saveEvaluationForm"
          mode="dialog"
          content-type="pipeline-stage"
          class="px-4"
          :id="submittedModel.id"
          :content="submittedModel"
          :can-get-evaluation="canGetEvaluation"
          :active-use-existing="useExisting"
          @update:form="onUpdateEvaluationForm"
        />
      </FormDialog>
      <Dialog
        v-if="submittedDialogs.evaluation"
        is-dialog-open
        fullscreen
        :title="`Evaluate ${featureName('Stage').singularize()}${
          submittedModel ? ': ' + submittedModel.title : ''
        }`"
        @toggle:dialog="onToggleEvaluationDialog"
      >
        <template #title>
          <span class="text-truncate text-no-wrap">
            {{ `Evaluate ${featureName('Stage').singularize()}${submittedModel ? ': ' + submittedModel.title : ''}` }}</span>
          <VTooltip top>
            <template #activator="{on}">
              <VIcon
                v-on="on"
                color="#BFBFBF"
                class="ml-2"
              >
                mdi-information
              </VIcon>
            </template>
            Here you can view all Applicants to Evaluate
          </VTooltip>
        </template>
        <Evaluated
          content-type="pipeline-stage"
          mode="dialog"
          :content="submittedModel"
          :can-load-from-url="false"
          @toggle:evaluation-form="onToggleEditStageEvaluationForm"
          @update:evaluation-form="onUpdateEvaluationForm"
          @toggle:evaluations="onToggleCompletedEvaluationsDialog"
        />
      </Dialog>
      <Dialog
        v-if="submittedDialogs.completedEvaluations"
        is-dialog-open
        fullscreen
        :title="`Submit Evaluations${
          submittedModel ? ': ' + submittedModel.evaluation_form.title : ''
        }`"
        @toggle:dialog="onToggleCompletedEvaluationsDialog"
      >
        <template v-if="(submittedModel || {}).id">
          <VAlert
            class="subtitle-2 font-weight-medium mt-2"
            style="border-radius: 14px"
            :color="submittedModel.evaluation_form_count ? 'rgba(73, 148, 236, 0.2)' : 'rgba(255, 82, 82, 0.2)'"
          >
            <template #prepend>
              <VIcon
                class="pr-3"
                :color="submittedModel.evaluation_form_count ? '#4994EC' : '#ff5252'"
              >
                {{ submittedModel.evaluation_form_count ? "mdi-information" : "mdi-alert" }}
              </VIcon>
            </template>
            <p
              v-if="!submittedModel.evaluation_form"
              class="mb-0"
              style="color: #ff5252;"
            >
              This {{ featureName("stage") }} is missing an
              evaluation form!
            </p>
            <template v-else-if="submittedModel.evaluation_form instanceof Object">
              Using evaluation form: {{ submittedModel.evaluation_form.title }}
              <VBtn
                icon
                width="24px"
                height="24px"
                class="ml-2"
                @click="() => onToggleEditStageEvaluationForm()"
              >
                <img
                  height="24px"
                  src="../../../images/forms/edit-icon@2x.png"
                >
              </VBtn>
            </template>
          </VAlert>
          <SubmittedEvaluations
            v-if="submittedModel.evaluation_form instanceof Object"
            mode="dialog"
            class="table-th-default-color"
            :use-id-from-route="false"
            :deliverable-id="(submittedModel.evaluation_form || {}).id"
            :custom-filter="submittedEvaluationFilter"
          />
        </template>
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import HasMembersMixin from "@/mixins/HasMembers";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import { default as SubmittedEvaluations } from "@/components/Deliverables/Submitted";
import SaveEvaluationForm from "./SaveEvaluationForm";
import Evaluated from "./StagesEvaluated";
export default {
  name: "ReviewersAndEvaluate",
  mixins: [HasMembersMixin, HasSubmittedDataMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    hasCurrentStage: {
      type: Boolean,
      default: false,
    },
    activeStageIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Evaluated,
    MembersForm,
    MembersList,
    SaveEvaluationForm,
    SubmittedEvaluations,
  },
  data() {
    return {
      tempReviewers: [],
      canGetEvaluation: true,
      addEvaluateFormMenu: false,
      submittedEvaluationFilter: null,
      dialogs: {
        addEvaluation: false,
      },
    };
  },
  watch: {
    activeStageIndex() {
      this.getStage();
    },
  },
  mounted() {
    // Get complete stage details to load all the submitted evaluations
    this.$nextTick(() => {
      if(this.value.hasOwnProperty("id")) {
        this.getStage();
      }
    });
  },
  methods: {
    ...mapActions({
      doGetStage: "pipelineStage/getOne",
    }),
    getStage() {
      this.doGetStage({
        id: this.value.stages[this.activeStageIndex].id,
        pipeline_id: this.value.id,
        _with: ["evaluationForm"],
        _fields: ["id", "evaluation_form", "evaluation_form_count"],
      }).then((stage) => {
        this.$set(this.value.stages[this.activeStageIndex], "evaluation_form", stage.evaluation_form);
        this.$set(this.value.stages[this.activeStageIndex], "evaluation_form_count", stage.evaluation_form_count);
      });
    },
    toggleAddMembersDialog() {
      this.onToggleMemberDialog("reviewers");
      this.tempReviewers = this.value.reviewers.slice();
    },
    addMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.$set(this.value, type, this.onAddMember(this.value[type], member));
    },
    removeMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.$set(
        this.value,
        type,
        this.onRemoveMember(this.value[type], member)
      );
    },
    onSaveMembers(members) {
      this.tempReviewers = this.value.reviewers.slice();
      this.dialogs.members = false;
    },
    toggleMemberDialog() {
      this.$set(this.value, "reviewers", this.tempReviewers);
      this.onToggleMemberDialog();
    },
    onToggleEditStageEvaluationForm(model) {
      this.dialogs.addEvaluation = !this.dialogs.addEvaluation;
      this.submittedModel = model || this.value.stages[this.activeStageIndex];
    },
    onUpdateEvaluationForm(form, stageId) {
      this.useExisting = false;
      if(this.$refs.hasOwnProperty("submittedEvaluations")) {
        this.$refs.submittedEvaluations.refreshIndexConfig();
      }
      this.$set(this.value.stages[this.activeStageIndex], "evaluation_form", form);
      this.$set(this.value.stages[this.activeStageIndex], "evaluation_form_count", 1);
    },
    onToggleCompletedEvaluationsDialog(model, filter) {
      if (!this.submittedDialogs.completedEvaluations) {
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
        this.submittedModel = null;
        this.submittedEvaluationFilter = filter;
        this.doGetStage({
          id: model.id,
          pipeline_id: this.value.id,
          _with: ["evaluationForm"],
          _fields: ["id", "evaluation_form", "evaluation_form_count"],
        }).then((stage) => {
          this.submittedModel = stage || model || this.submittedModel;
        });
      } else {
        this.submittedEvaluationFilter = null;
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
      }
    },
    onEditStageEvaluation() {
      this.useExisting = false;
      this.canGetEvaluation = true;
      this.onToggleEditStageEvaluationForm(this.value.stages[this.activeStageIndex]);
    },
    onCreateNew() {
      this.useExisting = this.canGetEvaluation = false; 
      this.onToggleEditStageEvaluationForm(this.value.stages[this.activeStageIndex]);
    },
    onUseExisting() {
      this.useExisting = true;
      this.canGetEvaluation = false;
      this.onToggleEditStageEvaluationForm(this.value.stages[this.activeStageIndex]);
    },
  },
};
</script>
<style lang="scss">
.table-th-default-color {
  .data-table table thead th {
    background-color: #ECECF1 !important;
  }
  thead > tr:last-child > th {
    border: none !important;
  }
}
.eval-form-btn {
  .v-btn__content {
    opacity: 1 !important;
  }
}
.save-eval-dialog > .v-card > .v-card__text {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #f3f3f3;
}
</style>