import api from "../../services/lesson";
import base from "./assessment";
export default function (helper) {
	const store = {
		...base(helper, api),
	};
	/**
	 * Generated store module
	 */
	helper.setApi(api);
	store.types = {
		...store.types,
		...helper.types("lesson"),
	};
	// Need to make sure we're using the right API for the actions
	store.actions = {
		...store.actions,
		...helper.actions({
			duplicateCard(context, params) {
				helper
				  .log()
				  .info(`[Store: Lesson}]: Duplicate card`, params);
				return new Promise((resolve, reject) => {
				  return helper
					.api()
					.duplicateCard(params)
					.then((response) => {
					  helper
						.log()
						.info(`[Store: Lesson]: Duplicated card`, response.data);
					  resolve(response.data.data);
					})
					.catch((error) => {
					  helper
						.log()
						.info(`[Store: Lesson]: Error Duplicating card`, error);
					  reject(error);
					})
				});
			  },
		}, "Lesson"),
	};

	return store;
}
