import BaseApi from "./_base-api";

/**
 * Deliverable API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	/**
	 * Toggle an item
	 *
	 * @param {any} params
	 * @param {Object} options
	 * @returns {Promise}
	 * @memberof BaseApi
	 */
	toggleLock(params, options) {
		const id = params.id || params;
		this.log().info(`[Services: ${this.type}]: Toggle Lock ${this.type}`, id);
		const { getter, endPoint } = options || {};
		return this.api()
			.put(
				this.getUrl(
					getter || "toggle-lock",
					endPoint || "toggle-lock",
					params
				) +
					"/" +
					id
			)
			.catch((error) => {
				this.log().warn(
					`[Services: ${this.type}: Toggle Lock ${this.type}]: Error`,
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Deliverable",
	path: "deliverables",
});
export default api;
export { Api };
