<script>
import { mapActions, mapGetters } from "vuex";
import GoogleAuthMixin from "@/mixins/GoogleAuth";
import { Promise } from "q";
import FormatsCalendarEntriesMixin from "./FormatsCalendarEntries";
export default {
  mixins: [GoogleAuthMixin, FormatsCalendarEntriesMixin],
  data() {
    return {
      providers: {
        google: true,
      },
      calendarApiLoaded: {},
      remoteCalendars: {},
      showDate: new Date(),
      calendarsDrawer: false,
      activeFilter: "newest",
      page: 1,
      isLoading: true,
      allCalendars: false,
      localDrawer: false,
      dialogs: {
        entry: false,
        rsvp: false,
      },
      currentCalendar: null,
      currentCalendarEntry: null,
    };
  },
  computed: {
    hasCalendars() {
      return (
        this.calendars instanceof Object &&
        this.calendars.data instanceof Object &&
        Object.keys(this.calendars.data).length >= 1
      );
    },
  },
  methods: {
    ...mapActions({
      doGetCalendars: "calendar/getAll",
      doDeleteCalendar: "calendar/delete",
    }),
    setShowDate(d) {
      this.showDate = d;
    },
    filterCalendars(calendars, filter) {
      let result = {};

      if (calendars instanceof Object) {
        Object.keys(calendars).map((key) => {
          result[key] = calendars[key].filter((calendar) => {
            if (calendar instanceof Object) {
              if (filter instanceof Function) {
                return filter(calendar);
              }
              return calendar.calendar_type === filter;
            }
            return false;
          });
        });

        this.$log.debug(
          "[HasCalendarEntries]: Returning filtered calendars for filter",
          filter,
          result
        );
      }

      return result;
    },
    onAddCalendar(calendar) {
      this.$log.debug("Calendars: Adding new calendar", calendar);
      // this.calendars.data.push(calendar);
      // this.activeCalendars.push(calendar.id);
    },
    onCalendarSaved(calendar, _existingKey) {
      const prop = _existingKey || this.existingCalendarsKey || "existing";
      switch (calendar.calendar_type) {
        case "google":
          this.remoteEvents = [
            ...(this.remoteEvents || []),
            ...this.formatEvents(calendar),
          ];
          break;

        case "local":
          break;
      }
      if (this.$refs.hasOwnProperty("calendars")) {
        this.$refs["calendars"].activeCalendars.push(calendar.id);
      }
    },
    onCalendarDeleted(calendar, _existingKey) {
      const prop = _existingKey || this.existingCalendarsKey || "existing";
      /**
       * When using single calendarMode the current calendar is not loaded into the store so delete locally
       */
      this.$log.debug(
        "[HasCalendarEntries]: Calendar deleted",
        calendar,
        prop,
        this.calendarMode
      );
      if (this.calendarMode === "single") {
        const calendarPropKey =
          calendar instanceof Object && calendar.entity instanceof Object
            ? calendar.entity.title
            : -1;
        let keys = this[prop].hasOwnProperty(calendarPropKey)
          ? [calendarPropKey]
          : Object.keys(this[prop]);
        this.$log.debug(
          "[HasCalendarEntries]: Looking for key",
          keys,
          calendar
        );
        for (const key in keys) {
          const index = this[prop][keys[key]].findIndex(
            (c) => c.id === calendar.id
          );
          if (index > -1) {
            this[prop][keys[key]].splice(index, 1);
            break;
          }
        }
      }
      /**
       * Remove all events for this calendar
       */
      if (
        calendar instanceof Object &&
        calendar.calendar_type !== "local" &&
        this.remoteEvents instanceof Array
      ) {
        this.remoteEvents = this.remoteEvents.filter(
          (event) => event.calendarId !== calendar.id
        );
      }
    },
    onDelete(calendar) {
      return this.doDeleteCalendar(calendar).then(() => {
        this.onCalendarDeleted(calendar);
      });
    },
    getCalendars() {
      let data = {
        page: this.calendars ? this.calendars.current_page : 1,
      };
      if (
        !this.calendars.total ||
        this.calendars.last_page > data.current_page
      ) {
        data.page = this.calendars.current_page
          ? this.calendars.current_page + 1
          : 1;
        this.$log.debug(
          "Calendars: Getting calendars...{page: " + data.page + "}",
          "Mode",
          this.calendarMode
        );
        this.isLoading = true;
        return this.doGetCalendars(data).then((response) => {
          this.isLoading = false;
          this.$emit("calendars", this.calendars);
          Promise.resolve(response);
        });
      } else {
        this.$log.debug(
          "Calendars: No more calendars to get...{page: " + data.page + "}"
        );
      }
    },
    setCalendars(calendars) {
      this.calendars = calendars.hasOwnProperty("data")
        ? calendars
        : {
            data: calendars,
            total: 1,
          };
      let toResolve = [];
      Object.keys(this.providers).map((provider) => {
        if (this.providers[provider]) {
          const loadApiMethod = `load${provider.capitalize()}Api`;
          const loadCalendarApiMethod = `load${provider.capitalize()}CalendarApi`;
          if (
            this.hasOwnProperty(loadApiMethod) &&
            this.hasOwnProperty(loadCalendarApiMethod)
          ) {
            toResolve.push(
              new Promise((resolve, reject) => {
                this[loadApiMethod]().then((result) => {
                  return this[loadCalendarApiMethod]().then(() => {
                    this.remoteCalendars[provider] = this.filterCalendars(
                      calendars.data,
                      provider
                    );
                    resolve(true);
                  });
                });
              })
            );
          }
        }
      });
      this.$log.debug(
        "[HasCalendarEntries]: Current active calendars",
        this.activeCalendars
      );
      Promise.all(toResolve).then((result) => {
        const activeCalendars = [];
        Object.values(this.remoteCalendars)
          .flat()
          .map((group) => {
            Object.values(group)
              .flat()
              .map((calendar) => {
                activeCalendars.push(calendar.id);
                this.formatEvents(calendar);
              });
          });
        this.setActiveCalendars(
          activeCalendars.concat(
            this.allActiveCalendars.map((calendar) => calendar.id)
          )
        );
      });
    },
    onLoadCalendars(calendar) {
      this.$log.debug(
        "Calendars: Loading calendars in ...[" + calendar.title + "]"
      );
      this.$emit("loadCalendars", calendar);
    },
    filterCalendarsRemote(index) {
      this.activeFilter = index;
      this.doGetCalendars({
        sort: this.activeFilter,
      }).then((result) => {
        this.calendars = result || this.calendars;
      });
    },
    selectAllCalendars() {
      if (this.allCalendars) {
        this.setActiveCalendars(
          Object.values(this.calendars.data)
            .map(function (calendars) {
              return calendars.map((calendar) => calendar.id);
            })
            .flat()
        );
      } else {
        this.setActiveCalendars([]);
      }
      this.$emit("updateActiveCalendars", this.activeCalendars);
    },
    getLocalCalendar(search) {
      const calendarId = `${this.$user.id}-${search.calendar_id}`;
      const originalCalendarId = search.calendar_id;
      return this.calendars && this.calendars.data
        ? this.calendars.data.find((calendar) => {
            return (
              [calendar.calendar_id, calendar.id].indexOf(calendarId) !== -1 ||
              [calendar.calendar_id, calendar.id].indexOf(
                originalCalendarId
              ) !== -1
            );
          })
        : null;
    },
    loadGoogleCalendarApi() {
      return new Promise((resolve, reject) => {
        this.$log.debug("Google Calendar: Waiting for gapi client");
        this.waitFor(
          () =>
            window.gapi instanceof Object &&
            window.gapi.client instanceof Object,
          10000
        )
          .then(() => {
            this.$log.debug("Google Calendar: gapi client loaded");
            window.gapi.client.load("calendar", "v3", () => {
              this.calendarApiLoaded.google = true;

              this.$log.debug("Google Calendar: API loaded");
              resolve(true);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setCurrentCalendar(calendar) {
      this.currentCalendar = calendar;
    },
    setCurrentCalendarEntry(entry) {
      this.currentCalendarEntry = entry;
    },
    onToggleCalendarsDrawer(value) {
      this.calendarsDrawer = value;
    },
    onToggleCalendarEntryDialog() {
      this.dialogs.entry = !this.dialogs.entry;
    },
    onToggleRsvpDialog() {
      this.dialogs.rsvp = !this.dialogs.rsvp;
    },
  },
};
</script>