<template>
  <VCard
    height="44px"
    style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.12);"
  >
    <VRow>
      <VCol
        cols="2"
        class="py-0"
      >
        <VBtn
          color="#ececf1"
          height="44px"
          class="left"
          :disabled="disabled || counter === 0"
          @click="counter--"
        >
          <img src="../../../images/forms/left-arrow-icon.png">
        </VBtn>
      </VCol>
      <VCol
        cols="8"
        class="text-center"
        style="font-weight: 500; font-size: 16px;"
      >
        {{ counter }}
      </VCol>
      <VCol
        cols="2"
        class="py-0 d-flex justify-end"
      >
        <VBtn
          color="#ececf1"
          height="44px"
          class="right"
          :disabled="disabled"
          @click="counter++"
        >
          <img src="../../../images/forms/right-arrow-icon.png">
        </VBtn>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      counter: 0,
    }
  },
  watch: {
    counter(newValue) {
      this.$emit("input", newValue);
    },
  },
}
</script>

<style lang="scss" scoped>
.left {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>