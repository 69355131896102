<template>
  <VRow column>
    <VCol cols="12">
      <VAlert
        v-if="!hidePreviewInfo"
        type="info"
        class="ma-6"
        :value="true"
      >
        This is a preview version of Lessons.
      </VAlert>
      <VCard
        v-if="lessons.overdue.data.length > 0"
        flat
        :class="{
          ...customCardClass,
          'no-bottom-border': lessons.overdue.data.length && (lessons.started_not_complete.data.length || lessons.all.data.length),
        }"
      >
        <VCardText>
          <VCol class="pl-0">
            <h3 style="color: black;">
              Overdue
            </h3>
          </VCol>
          <VSheet
            max-width="1270px"
            class="group-slider"
          >
            <VSlideGroup
              class="py-4"
              show-arrows
            >
              <VSlideItem
                v-for="(item, key) in lessons.overdue.data"
                :key="item.id"
              >
                <LessonHomeCard
                  overdue
                  preview-only
                  margin-right="24px"
                  :item="item"
                  :colors="colors"
                  :due-on="getDue(item)"
                  :due-day="getDueDay(item)"
                  :is-back-to-default="isBackToDefault"
                  :show-count="checkShowCount(key, lessons.overdue)"
                  :count="(lessons.overdue.total - limitForDashboard) + 1"
                  :style="{
                    'width': '275px',
                    'cursor': checkShowCount(key, lessons.overdue) ? 'pointer' : 'auto',
                  }"
                  @showMore="$emit('toggleTab')"
                />
              </VSlideItem>
            </VSlideGroup>
          </VSheet>
        </VCardText>
      </VCard>
      <VDivider v-if="lessons.overdue.data.length && (lessons.started_not_complete.data.length || lessons.all.data.length)" />
      <VCard
        v-if="lessons.started_not_complete.data.length > 0"
        flat
        :class="{
          ...customCardClass,
          'no-top-border': lessons.overdue.data.length && lessons.started_not_complete.data.length,
          'no-bottom-border': lessons.started_not_complete.data.length && lessons.all.data.length
        }"
      >
        <VCardText>
          <VCol class="pl-0">
            <h3 style="color: black;">
              Started but not complete
            </h3>
          </VCol>
          <VSheet
            max-width="1270px"
            class="group-slider"
          >
            <VSlideGroup
              class="py-4"
              show-arrows
            >
              <VSlideItem
                v-for="(item, key) in lessons.started_not_complete.data"
                :key="item.id"
              >
                <LessonHomeCard
                  incompleted
                  preview-only
                  margin-right="24px"
                  :item="item"
                  :colors="colors"
                  :due-on="getDue(item)"
                  :due-day="getDueDay(item)"
                  :is-back-to-default="isBackToDefault"
                  :show-count="checkShowCount(key, lessons.started_not_complete)"
                  :count="(lessons.started_not_complete.total - limitForDashboard) + 1"
                  :style="{
                    'width': '275px',
                    'cursor': checkShowCount(key, lessons.started_not_complete) ? 'pointer' : 'auto',
                  }"
                  @showMore="$emit('toggleTab')"
                />
              </VSlideItem>
            </VSlideGroup>
          </VSheet>
        </VCardText>
      </VCard>
      <VDivider v-if="lessons.started_not_complete.data.length && lessons.all.data.length" />
      <VCard
        v-if="lessons.all.data.length > 0"
        flat
        :class="{
          ...customCardClass,
          'no-top-border': (lessons.overdue.data.length || lessons.started_not_complete.data.length) && lessons.all.data.length,
        }"
      >
        <VCardText>
          <VCol class="pl-0">
            <h3 style="color: black;">
              All Lessons
            </h3>
          </VCol>
          <VSheet
            max-width="1270px"
            class="group-slider"
          >
            <VSlideGroup
              class="py-4"
              show-arrows
            >
              <VSlideItem
                v-for="(item, key) in lessons.all.data"
                :key="item.id"
              >
                <LessonHomeCard
                  all
                  preview-only
                  margin-right="24px"
                  :item="item"
                  :colors="colors"
                  :due-on="getDue(item)"
                  :due-day="getDueDay(item)"
                  :is-back-to-default="isBackToDefault"
                  :show-count="checkShowCount(key, lessons.all)"
                  :count="(lessons.all.total - limitForDashboard) + 1"
                  :style="{
                    'width': '275px',
                    'cursor': checkShowCount(key, lessons.all) ? 'pointer' : 'auto',
                  }"
                  @showMore="$emit('toggleTab')"
                />
              </VSlideItem>
            </VSlideGroup>
          </VSheet>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>
<script>
import HasDueMixin from "@/mixins/HasDueOn";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import LessonHomeCard from "./Card";
export default {
  name: "PreviewLessons",
  props: {
    lessons: {
      type: Object,
      default: () => {},
    },
    hidePreviewInfo: {
      type: Boolean,
      default: false,
    },
    customCardClass: {
      type: Object,
      default: null,
    },
    isBackToDefault: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [HasDueMixin, HasAssessmentsMixin, ViewProgramSection],
  components: {
    LessonHomeCard,
  },
  methods: {
    checkShowCount(key, lessonsGroup) {
      if(this.isDashboard) {
        return lessonsGroup.total > this.limitForDashboard && key === lessonsGroup.data.length - 1;
      }
      return false;
    },
  },
}
</script>
<style lang="scss" scoped>
.no-bottom-border {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.no-top-border {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
</style>