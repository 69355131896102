import BaseApi from "./_base-api";

class Api extends BaseApi {
	save(params) {
		let { calendarId, id } = params;
		calendarId = calendarId || params.data.calendar_id;
		if (!calendarId) {
			throw new Error("Missing calendar");
		}
		params.data.calendar_id = calendarId;
		return super.save(params);
	}
}

const api = new Api({
	type: "Calendar Entry",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const calendarId = params.calendarId || params.calendar_id;
			return `calendars/${calendarId}/entries`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const calendarId = params.calendarId || params.calendar_id;
			return `calendars/${calendarId}/entries`;
		},
		default: "calendar_entry"
	}
});

export default api;
