var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode === 'standalone')?_c('VContainer',{class:{
    'save-header': true,
    'col': _vm.col
  },attrs:{"fluid":""}},[_c('VRow',{staticClass:"mr-2",attrs:{"row":"","align-center":"","wrap":_vm.$vuetify.breakpoint.smAndDown}},[_c('VCol',{class:{
        xs12: _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"shrink":_vm.$vuetify.breakpoint.mdAndUp && _vm.hasContent,"grow":_vm.$vuetify.breakpoint.mdAndUp && !_vm.hasContent,"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_c('VRow',{attrs:{"row":"","align-center":"","justify-start":"","nowrap":""}},[(!_vm.hideTitleWithBack && !_vm.hideBackButton)?_c('VCol',{staticStyle:{"flex":"0 0 48px"}},[_c('VBtn',{attrs:{"icon":"","large":""},on:{"click":_vm.onGoBack}},[(_vm.altBackIcon)?_c('img',{attrs:{"src":require("../../../images/forms/arrow-icon.png")}}):_c('VIcon',[_vm._v(" arrow_back ")])],1)],1):_vm._e(),(!_vm.hideTitleWithBack && !_vm.hideBackButton)?_c('VCol',[_c('h1',{class:{
              'pr-8': _vm.isBetaFeature(_vm.owner.modelType),
              'text-truncate text-no-wrap title': true,
              'text-center': _vm.$vuetify.breakpoint.smAndDown
            },style:({
              whiteSpace: _vm.$vuetify.breakpoint.smAndDown ? 'normal' : 'normal',
            })},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.owner.formAction)+" "+_vm._s(_vm.featureName(_vm.owner.modelType).capitalize())+" ")]}),_c('BetaBadge',{attrs:{"enable-tooltip":"","is-beta":_vm.isBetaFeature(_vm.owner.modelType)}})],2)]):_vm._e(),_c('VCol',{staticStyle:{"flex":"0 0 48px"}},[_vm._t("title-button")],2)],1)],1),(_vm.hasContent)?_c('VCol',{class:{
        xs12: _vm.$vuetify.breakpoint.smAndDown,
        'order-sm3': true,
        'order-md2': true,
        'mr-2': true
      },attrs:{"align-center":"","justify-start":"","grow":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_vm._t("content-center")],2):_vm._e(),_c('VCol',{class:{
        'order-sm2': true,
        'order-md3': true
      },attrs:{"pr-0":_vm.$vuetify.breakpoint.smAndDown,"shrink":_vm.$vuetify.breakpoint.mdAndUp && _vm.hasContent,"grow":_vm.$vuetify.breakpoint.mdAndUp && !_vm.hasContent,"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_c('VRow',{attrs:{"row":"","align-center":"","nowrap":"","justify-end":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VCol',{attrs:{"shrink":"","px-0":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_c('VRow',{attrs:{"row":"","align-center":""}},[_vm._t("actions-left")],2)],1),(!_vm.disableDefaultActions)?_c('VCol',{attrs:{"grow":"","pr-0":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_c('VRow',{class:{
              'px-6': _vm.$vuetify.breakpoint.smAndDown
            },attrs:{"row":"","nowrap":"","align-center":"","justify-end":_vm.$vuetify.breakpoint.mdAndUp,"justify-space-between":_vm.$vuetify.breakpoint.smAndDown}},[_vm._t("actions-center-left"),_vm._t("actions-center",function(){return [_c('VCol',{staticClass:"d-flex justify-end wrap"},[(!_vm.hideTitleWithBack)?_c('ExpandableButton',{attrs:{"icon-button":_vm.iconButton,"icon":_vm.saveBtnIcon,"text":_vm.saveBtnText,"block":_vm.$vuetify.breakpoint.smAndDown,"disabled":!_vm.owner.isFormReady || _vm.owner.isFormInputsDisabled,"loading":_vm.owner.activity.isFormLoading,"action":() => _vm.owner.onSave()}}):_vm._e(),(!_vm.disableAddNewText && _vm.owner.wasCreateMode && _vm.owner.form.id)?_c('ExpandableButton',{attrs:{"icon":"add","button-class":{
                    'wethrive-alt': true,
                    'ml-2': true
                  },"block":_vm.$vuetify.breakpoint.smAndDown,"disabled":!_vm.owner.isFormReady || _vm.owner.isFormInputsDisabled,"action":_vm.onSaveAndReset,"text":_vm.addNewText,"tooltip-text":_vm.addNewText,"show-text":_vm.$vuetify.breakpoint.smAndUp || _vm.$vuetify.breakpoint.smAndDown}}):_vm._e()],1)]}),_vm._t("actions-center-right")],2)],1):_vm._e(),_c('VCol',{attrs:{"shrink":"","px-0":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto'}},[_c('VRow',{attrs:{"row":"","align-center":""}},[_vm._t("actions-right")],2)],1)],1)],1)],1),_c('VRow',[(_vm.owner.hasMessagesOrErrors)?_c('VCol',{class:{
        'mb-2 mt-3': true,
        'mt-2': _vm.hideTitleWithBack
      }},[_c('Alerts',{attrs:{"messages":_vm.owner.messages,"errors":_vm.owner.errors,"align-icon":_vm.alignAlertIcon},scopedSlots:_vm._u([{key:"alert-message-action",fn:function(){return [_vm._t("response-action")]},proxy:true}],null,true)})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }