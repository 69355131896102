<template>
  <VRow column>
    <VCol cols="12">
      <VRow
        row
        space-between
      >
        <VCheckbox
          v-model="enableCustomMessage"
          label="Send a custom message?"
        />
        <VCheckbox
          v-model="resetCustomMessage"
          label="Use original message?"
        />
        <VCheckbox
          v-model="useDefaultMessage"
          label="Use default message?"
        />
      </VRow>
    </VCol>
    <VCol
      cols="12"
      v-if="enableCustomMessage"
    >
      <VAlert
        type="info"
        :value="canShowDefaultValueAlert"
      >
        The default message is shown below
      </VAlert>
      <LongTextField
        :placeholder="placeholder"
        v-model="message"
        @input="emitInput"
        ref="message"
      />
      <VExpansionPanels
        v-if="preparedVariables.length"
        lazy
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>
            <div>Want to use dynamic variables to customize this message even further?</div>
          </VExpansionPanelHeader>
          <VCard>
            <VCardText>
              <VAlert
                dismissible
                type="info"
                :value="true"
              >
                Use variables in your messages to automatically be replaced with data from the database. The table below explains which variables are available for use.
                <br>
                <br>For example: Hi
                <strong>{userName}</strong> becomes Hi
                <strong>Mark</strong>
              </VAlert>
              <VDataTable
                color="info"
                :headers="headers"
                :items="preparedVariables"
              >
                <template #items="props">
                  <td>
                    <strong>{{ props.item.value }}</strong>
                  </td>
                  <td>{{ props.item.description }}</td>
                  <td>
                    <VTooltip top>
                      <template #activator="{ on }">
                        <VBtn
                          v-on="on"
                          icon
                          @click="addVariable(props.item.value)"
                        >
                          <VIcon>add</VIcon>
                        </VBtn>
                      </template>
                      <span>Use {{ props.item.value }}</span>
                    </VTooltip>
                  </td>
                </template>
              </VDataTable>
            </VCardText>
          </VCard>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
    <VCol
      cols="12"
      v-else
    >
      <h3>Default message</h3>
      <p class="formatted">
        {{ message }}
      </p>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    showMessageInput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "(Optional) Enter a message",
    },
    variables: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      message: "",
      previousMessage: null,
      originalMessage: "",
      enableCustomMessage: false,
      resetCustomMessage: false,
      useDefaultMessage: false,
      headers: [
        {
          text: "Variable",
          value: "value",
        },
        {
          text: "Description",
          value: "description",
        },
      ],
    };
  },
  computed: {
    preparedVariables() {
      return this.variables instanceof Object
        ? Object.keys(this.variables).map((k, v) => {
            return {
              description: this.variables[k],
              value: k,
            };
          })
        : [];
    },
    canShowDefaultValueAlert() {
      return (
        (!this.value || (this.value && !this.value.length)) &&
        this.placeholder === this.message
      );
    },
  },
  watch: {
    message: function (value) {
      if (!value) {
        this.message = this.previousMessage || this.value || this.placeholder;
      }
    },
    enableCustomMessage: function (value) {
      if (!value) {
        this.previousMessage = this.message;
        this.message = this.placeholder;
        this.resetCustomMessage = false;
      } else {
        this.message = this.previousMessage || this.value || this.placeholder;
      }
    },
    resetCustomMessage: function (value) {
      if (value) {
        this.previousMessage = this.message;
        this.message = this.originalMessage;
      } else {
        this.message = this.previousMessage || this.value || this.placeholder;
      }
    },
    useDefaultMessage: function (value) {
      if (value) {
        this.previousMessage = this.message;
        this.message = this.placeholder;
      } else {
        this.message = this.previousMessage || this.value || this.placeholder;
      }
    },
  },
  created() {
    this.message = this.value || this.placeholder;
    this.enableCustomMessage =
      this.showMessageInput === true ||
      (this.value &&
        this.value.length > 0 &&
        this.message !== this.placeholder);
    this.emitInput(this.message);
    this.originalMessage = this.value;
  },
  methods: {
    addVariable(variable) {
      let elem = this.$refs.message.$el.querySelector("textarea");
      if (document.selection) {
        // IE
        elem.focus();
        const sel = document.selection.createRange();
        this.message = variable;
      } else if (elem.selectionStart || elem.selectionStart === 0) {
        // Others
        const startPos = elem.selectionStart;
        const endPos = elem.selectionEnd;
        this.message =
          elem.value.substring(0, startPos) +
          variable +
          this.message.substring(endPos, elem.value.length);
        elem.selectionStart = startPos + variable.length;
        elem.selectionEnd = startPos + variable.length;
      } else {
        this.message += variable;
      }
      this.showEmojiPicker = false;
    },
    emitInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>