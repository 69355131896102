<script>
import uuid from "uuid";

export default {
  props: {
    name: {
      type: String,
      default: uuid.v4(),
    },
    countWords: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedCounterValue() {
      if (typeof this.counterValue === "function") {
        return this.counterValue(this.internalValue);
      } else if (this.countWords === true || this.countWords === "true") {
        return (this.internalValue || "").toString().split(" ").length;
      }
      return (this.internalValue || "").toString().length;
    },
  },
};
</script>