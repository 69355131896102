import env from '@/config/env';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import dotenv from 'dotenv';
import * as Exceptions from './Exceptions';
dotenv.config();

// Only going to enable these tools for staging and production
if (env.isProduction) {
    // Setup logrocket
    LogRocket.init(process.env.VUE_APP_LOGROCKET);

    //Setup Sentry
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            const exception = hint.originalException;
            if (exception instanceof Exceptions.IgnorableException) {
                return null;
            }
            if (event.exception && env.isStaging) {
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    user: app.$user,
                    title: `Whoops! Looks like something went wrong`
                });
            }
            return event;
        }
    });
    // Integrate LogRocket and Sentry
    LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
            scope.setExtra("sessionURL", sessionURL);
        });
    });
}

export default {}