<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
export default {
  mixins: [SearchMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Find an rsvp",
    },
    mode: {
      type: String,
      default: "standard",
    },
    rsvps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelType: "calendarEntryRsvp",
      activity: {
        isSaving: {},
        isSaved: {},
        isDeleting: {},
      },
    };
  },
  computed: {
    hasRsvps() {
      return this.localRsvps.length > 0;
    },
    localRsvps() {
      const filter = (rsvps) => {
        return rsvps.filter((rsvp) => {
          if (this.filter && this.filter.length) {
            const nameMatch = rsvp.user.name.match(this.filter);
            const emailMatch = rsvp.user.email.match(this.filter);
            const acceptedMatch = rsvp.is_accepted.match(
              this.filter === "accepted"
            );
            const rejectedMatch = rsvp.is_rejected.match(
              this.filter === "rejected"
            );
            return (
              (nameMatch && nameMatch.length > 0) ||
              (emailMatch && emailMatch.length > 0) ||
              (acceptedMatch && acceptedMatch.length > 0) ||
              (rejectedMatch && rejectedMatch.length > 0)
            );
          }
          return true;
        });
      };
      if (
        this.localData instanceof Object &&
        this.localData.data instanceof Array
      ) {
        return filter(this.localData.data);
      } else if (
        this.data instanceof Object &&
        this.data.data instanceof Array
      ) {
        return filter(this.data.data);
      } else {
        return filter((this.calendarEntry || {}).rsvps || []);
      }
    },
  },
  methods: {
    ...mapActions({
      doGetCalendarEntryRSVPs: "calendarEntryRsvp/getAll",
      doSaveCalendarEntryRSVPStatus: "calendarEntryRsvp/status",
      doAcceptRsvp: "calendarEntryRsvp/accept",
      doRejectRsvp: "calendarEntryRsvp/reject",
      doDeleteRsvp: "calendarEntryRsvp/delete",
      doGetIndexConfig: "calendarEntryRsvp/getIndexConfig",
    }),
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    onToggleEventDetailDialog(currentEvent) {
      this.currentEvent = currentEvent || {};
      this.dialogs.detail = !this.dialogs.detail;
    },
    onUpdateRsvp(rsvp) {
      this.$set(this.activity.isSaving, rsvp.id, true);
      return this.doSaveCalendarEntryRSVPStatus({
        id: rsvp.id,
        calendar_entry_id: rsvp.calendar_entry_id,
        status: rsvp.status,
      })
        .then((result) => {
          this.onAfterUpdate(result.data.data);
          this.$bus.$emit(
            "notificationMessage",
            `${featureName("RSVP")} updated successfully!`
          );
        })
        .catch((error) => {
          this.$delete(rsvp, "isSaving");
          this.$bus.$emit(
            "notificationError",
            `Unable to accept ${featureName("RSVP")}`
          );
        });
    },
    onAfterUpdate(rsvp) {
      const existing = this.localRsvps.find((r) => r.id === rsvp.id);
      this.$log.debug("[CanRSVP]: onAfterUpdate", rsvp, existing, this.form);
      this.$delete(existing, "isSaving");
      this.localRsvps.splice(
        this.localRsvps.findIndex((r) => r.id === rsvp.id),
        1,
        { ...existing, ...rsvp }
      );
      if (this.form instanceof Object && this.form.id === rsvp.id) {
        this.form = { ...this.form, ...rsvp };
        this.localRsvps.splice(
          this.localRsvps.findIndex((r) => r.id === rsvp.id),
          1,
          this.form
        );
        this.$log.debug("[CanRSVP]: onAfterUpdate: Has Form", this.form);
      }
    },
    onAcceptRsvp(rsvp) {
      this.$set(rsvp, "isSaving", true);
      return this.doAcceptRsvp(rsvp)
        .then((result) => {
          this.onAfterUpdate(result.data.data);
          this.$bus.$emit(
            "notificationMessage",
            `${featureName("RSVP")} accepted successfully!`
          );
        })
        .catch((error) => {
          this.$delete(rsvp, "isSaving");
          this.$bus.$emit(
            "notificationError",
            `Unable to accept ${featureName("RSVP")}`
          );
        });
    },
    onRejectRsvp(rsvp) {
      this.$set(rsvp, "isSaving", true);
      return this.doRejectRsvp(rsvp)
        .then((result) => {
          this.onAfterUpdate(result.data.data);
          this.$bus.$emit(
            "notificationMessage",
            `${featureName("RSVP")} declined successfully!`
          );
        })
        .catch((error) => {
          this.$delete(rsvp, "isSaving");
          this.$bus.$emit(
            "notificationError",
            `Unable to decline ${featureName("RSVP")}`
          );
        });
    },
    getRsvpButtonColor(rsvp, status) {
      if (rsvp.status == status && status == "maybe") {
        return "warning";
      }
      if (rsvp.status == status && status == "yes") {
        return "success";
      }
    },
    canRsvpTo(entry) {
      return (entry.can_rsvp || entry.canRsvp) === true;
    },
    canReject(rsvp) {
      if (!rsvp) return false;
      const canReject =
        this.canRsvpTo(rsvp.calendar_entry || rsvp.calendarEntry) &&
        !rsvp.created_by_user &&
        ((rsvp.is_rejected === false && rsvp.is_accepted === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_accepted === undefined ||
          rsvp.is_accepted === null);
      return canReject;
    },
    canAccept(rsvp) {
      if (!rsvp) return false;
      const canAccept =
        this.canRsvpTo(rsvp.calendar_entry || rsvp.calendarEntry) &&
        !rsvp.created_by_user &&
        ((rsvp.is_accepted === false && rsvp.is_rejected === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_rejected === undefined ||
          rsvp.is_rejected === null);
      return canAccept;
    },
  },
};
</script>
