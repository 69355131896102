var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$user.team_user instanceof Object && _vm.$user.team_user.is_approved === true)?_c('VRow',{staticClass:"mb-3 mx-0",style:({
    height: 'auto'
  }),attrs:{"row":"","dense":"","wrap":_vm.$vuetify.breakpoint.smAndDown}},[_vm._l((_vm.stats),function(item,value){return _c('VCol',{key:value,class:_vm.dynamicMarginClass(value),attrs:{"xs":"6","md":"3"}},[_c('VCard',{staticClass:"stat-card"},[_c('VRow',{attrs:{"nowrap":""}},[_c('VCol',[_c('VCardText',{class:{
              'stat-card-count': true,
              'error': _vm.getHasIndicator('danger', value, _vm.stats[value]),
              'info': _vm.getHasIndicator('info', value, _vm.stats[value]),
              'warning': _vm.getHasIndicator('warning', value, _vm.stats[value]),
              'success': _vm.getHasIndicator('success', value, _vm.stats[value]),
            }},[_vm._v(" "+_vm._s(_vm.stats[value])+" ")])],1),_c('VCol',[_c('VCardText',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(value === '-' ? ' ' : _vm.formatTitle(value, _vm.stats[value]))+" ")])],1)],1)],1)],1)}),_vm._t("append-actions")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }