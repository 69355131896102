<template>
  <VContainer
    grid-list-sm
    fluid
    class="pr-0"
  >
    <VHover
      v-for="(item, index) in files"
      :key="`message-file-${item.id}`"
    >
      <VCard
        shrink
        class="message-files mx-auto mb-2"
        :key="index"
      >
        <VImg
          v-if="item.type && item.type.startsWith('image/')"
          max-width="300"
          max-height="200"
          style="border: 1px solid lightgrey; cursor: zoom-in;"
          :id="'img'+item.id"
          :src="item.url"
          :class="isSender ? `float-right` : `float-left`"
          @click="dialogs.preview = true"
        >
          <!-- <VFadeTransition>
            <div
              v-if="hover"
              class="d-flex align-center justify-center transition-in-out black lighten-2"
              style="height: 100%; opacity: 0.5"
            >
              <a
                target="_blank"
                class="display-1 v-btn v-btn--text white--text"
                :title="item.name"
                :href="item.url"
              >
                Download
              </a>
            </div>
          </VFadeTransition> -->
        </VImg>
        <VCardText
          class="pa-0"
          v-else
        >
          <VResponsive
            v-if="item.type && item.type.startsWith('video/')"
            min-height="150px"
            max-height="500px"
            min-width="150px"
            width="auto"
            :id="'video'+item.id"
            :src="item.url"
          >
            <video
              controls
              autoPictureInPicture="true"
            >
              <source
                :src="item.url"
                :type="item.type"
              >
              Sorry, your browser doesn't support embedded videos.
            </video>
          </VResponsive>
          <VList
            v-else
            two-line
            subheader
          >
            <VListItem>
              <VListItemContent>
                <VListItemTitle>
                  <a
                    :title="item.name"
                    target="_blank"
                    :href="item.url"
                  >
                    <VIcon>{{ getIcon(item.type) }}</VIcon>
                    {{ item.name.substr(0, 32) }}
                  </a>
                </VListItemTitle>
                <VListItemSubtitle>{{ item.readable_size || item.size }}</VListItemSubtitle>
              </VListItemContent>
            <!--<v-list-tile-action>
                            <VBtn icon ripple @click="onRemoveFile(index, item.id)">
                                <VIcon color="grey">{{item.id ? 'trash' : 'close'}}</VIcon>
                            </VBtn>
                            <VBtn icon ripple v-if="item.id" @click="onRemoveFile(index)">
                                <VIcon color="grey">delete</VIcon>
                            </VBtn>
            </v-list-tile-action>-->
            </VListItem>
          </VList>
        </VCardText>
        <VCardActions
          v-if="item.type && item.type.startsWith('video/')"
        >
          <VBtn
            text
            block
            target="_blank"
            :title="item.name"
            :href="item.url"
          >
            Download
          </VBtn>
        </VCardActions>
      </VCard>
    </VHover>
    <Preview
      v-if="dialogs.preview"
      :files="files"
      @toggle="dialogs.preview = !dialogs.preview"
    />
  </VContainer>
</template>
<script>
import Preview from "@/components/Elements/Dialogs/Preview";
export default {
  name: "MessageFile",
  components: {
    Preview,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
    isSender: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogs: {
        preview: false,
      },
    };
  },
  computed: {
    hasFiles() {
      this.$log.debug(this.files);
      return (
        this.files &&
        this.files.filter((item) => item.url || item.size).length > 0
      );
    },
  },
  created() {
    this.filename = this.value;
  },

  methods: {
    getIcon(mime) {
      if (!mime) {
        return "attach_file";
      }
      const part = mime.substr(0, mime.indexOf("/"));
      switch (mime) {
        case "image":
          return "image";
          break;

        default:
          return "attach_file";
          break;
      }
    },
    onRemoveFile(index, id) {
      this.$emit("removeFile", index);
    },
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}

.message-files img {
  width: 100%;
  max-height: 150px;
}

.message-files video {
  width: 100% !important;
  height: auto !important;
  position: relative !important;
}

.message-files img::after {
  position: absolute;
  content: "Open";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
</style>