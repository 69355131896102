var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{staticClass:"layout column bg-transparent fill-height chat-messages pa-0",style:({
    borderRadius: '10px 0px 0px 10px !important'
  })},[(!_vm.editThreadMode)?_c('VCardTitle',{staticClass:"content-bg",style:({
      padding: '0px 12px',
      minHeight: '52px',
      maxHeight: '52px',
      height: '52px',
      flex: '1 1 52px',
      borderRadius: '10px 0px 0px 0px !important'
    })},[(_vm.threadDrawerActive)?_c('VBtn',{staticStyle:{"max-width":"72px"},attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('toggleDrawer')}}},[_c('VIcon',[_vm._v("forum")])],1):_vm._e(),_c('VRow',{staticClass:"mt-0",attrs:{"row":"","wrap":"","align-center":""}},[(_vm.threadEditable)?_c('VCol',{staticStyle:{"border-right":"solid 1px rgba(0, 0, 0, 0.05)","flex-basis":"90px","text-align":"left"},attrs:{"shrink":""}},[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('VBtn',_vm._g({staticStyle:{"margin":"0px auto"},attrs:{"text":"","small":""},on:{"click":_vm.onToggleEditThread}},on),[(!_vm.isGroup && _vm.thread && _vm.thread.to)?_c('VAvatar',{attrs:{"size":20}},[_c('img',{attrs:{"src":_vm.avatar}})]):[_c('img',{attrs:{"width":"20","height":"20","src":_vm.peopleIcon}}),_vm._v("  "),_c('strong',{staticClass:"subtitle-2 font-weight-bold ml-1"},[_vm._v(_vm._s(_vm.thread.group.members.length || 0))])]],2)]}}],null,false,2997230843)},[_c('span',[_vm._v("Edit this thread")])])],1):_vm._e(),_c('VCol',{staticClass:"mx-auto",attrs:{"cols":"auto"}},[_c('BetaBadge',{attrs:{"is-beta":"","offset-x":"-1","color":_vm.thread.is_private ? 'secondary' : 'success',"label":_vm.thread.is_private ? 'Private' : 'Public',"value":_vm.forHuddle}},[_c('span',{staticClass:"subtitle-2 font-weight-bold text-wrap"},[_vm._v(" "+_vm._s(_vm.title || _vm.thread.title)+" ")])]),(_vm.threadEditable && !_vm.forHuddle)?_c('Linkable',{attrs:{"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.index`, _vm.thread.thread_id)}}):_vm._e()],1),(_vm.dismissable)?_c('VCol',{attrs:{"cols":"auto"}},[_c('VBtn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('toggle:dialog')}}},[_c('VIcon',{attrs:{"color":"error"}},[_vm._v(" close ")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('VCardText',{class:{
      flex: true,
      messages: true,
      'pa-0': true,
      'is-loading': (_vm.isFirstLoad && _vm.isLoading) || _vm.activity.isFormLoading
    },style:({
      height: _vm.editThreadMode ? '100vh' : 'calc(100vh - 224px)',
      backgroundColor: _vm.editThreadMode ? 'transparent' : '#fff'
    }),attrs:{"id":"messages-container"}},[(!_vm.editThreadMode)?_c('VList',{ref:"messages",class:{
        'messages-list': true,
        layout: true,
        column: true,
        'align-end': true,
        'justify-end': true,
        'px-3': true
      },attrs:{"two-line":"","id":"infinite-messages"}},[(_vm.hasMessages)?[(_vm.canGetMore)?_c('infinite-loading',{attrs:{"direction":"top","force-use-infinite-wrapper":"#infinite-messages","distance":25,"identifier":new Date()},on:{"infinite":_vm.getMessages}}):_vm._e(),_vm._l((_vm.sortedData),function(message,key){return _c('Message',{key:key,attrs:{"message":message}})})]:_c('VListItem',[_c('h3',[_vm._v("No messages")])])],2):_vm._e(),(_vm.editThreadMode)?_c('StartChat',{ref:"startChat",staticClass:"messages-list fill-height",style:({
        width: '100%',
        overflowY: 'hidden',
      }),attrs:{"force-group":"","tabs":false,"existing":_vm.thread,"for-huddle":_vm.forHuddle,"parent-is-saving":_vm.isLoading,"custom-filter":_vm.customFilter,"hide-filters":_vm.hideFilters},on:{"startThread":_vm.onSaveThread,"closeEditor":function($event){_vm.editThreadMode = false},"startGroupThread":_vm.onSaveThread}}):_vm._e()],1),(!_vm.editThreadMode)?_c('VCardActions',{staticClass:"flex shrink py-0"},[_c('ChatForm',{staticClass:"chat-form-container",attrs:{"thread":_vm.thread,"old-thread":_vm.oldThread},on:{"addMessage":_vm.onAddMessage,"updateThreadTimestamp":_vm.onUpdateThreadTimestamp}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }