var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{style:({
    width: _vm.$vuetify.breakpoint.mdAndUp ? '100%' : 'auto'
  }),attrs:{"fill-height":"","id":"lesson-cards"}},[_c('VCol',{staticStyle:{"height":"10px"},attrs:{"cols":"12"}},[_c('VProgressLinear',{staticClass:"mt-0",attrs:{"color":"success","height":"5","value":_vm.progress}})],1),_c('VCol',{class:{
      'px-4': _vm.$vuetify.breakpoint.mdAndUp,
      'px-1': _vm.$vuetify.breakpoint.smAndDown
    },style:({
      height: _vm.$vuetify.breakpoint.smAndDown && _vm.mode != 'standalone' ? 'auto' : 'calc(100% - 10px)'
    }),attrs:{"grow":"","cols":"12"}},[_c('VRow',{attrs:{"row":"","justify-center":"","fill-height":"","align-center":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VCol',{style:({
          flex: _vm.$vuetify.breakpoint.mdAndUp ? '0 1 1100px' : '1 1 100%'
        }),attrs:{"id":"lesson-cards-carousel-container","align-self-center":""}},[_c('VCarousel',{class:{
            'fill-height': true,

          },attrs:{"id":"lesson-cards-carousel","cycle":false,"value":_vm.currentIndex,"hide-delimiters":true,"show-arrows":false,"height":_vm.$vuetify.breakpoint.mdAndUp ? 476 : 'calc(100vh - 86px)'},on:{"change":_vm.setCurrentIndex}},[(_vm.hasCards)?_vm._l((_vm.cards),function(card,key){return _c('VCarouselItem',{key:key,staticClass:"fill-height",style:({
                height: '100%',
                maxWidth: _vm.$vuetify.breakpoint.mdAndUp ? '1100px' : 'calc(100vw - 24px)'
              }),attrs:{"lazy":"","value":key,"id":`lesson-card-${key}`}},[_c('Card',{attrs:{"hide-complete-button":"","card":card,"index":key,"is-current":_vm.currentIndex === key,"is-first":key === 0,"is-last":key === _vm.cards.length-1,"is-preview":_vm.isPreview,"assessment":_vm.assessment,"mode":_vm.mode},on:{"next":_vm.next,"previous":_vm.previous,"toggle:dialog":function($event){return _vm.$emit('toggle:dialog')}}})],1)}):_c('VCarouselItem',{attrs:{"height":"100%"}},[_c('VRow',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[(_vm.showNoCardsMsg)?_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary"}}):_c('VCol',[_c('BlankCard')],1)],1)],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }