import Vue from "vue";
import Alerts from "@/components/Elements/Alerts";
import Notifications from "@/components/Elements/Notifications/Notifications";
import Dialog from "@/components/Elements/Dialogs/Dialog";
import Confirm from "@/components/Elements/Dialogs/Confirm";
import FormDialog from "@/components/Elements/Dialogs/FormDialog";
import Tooltip from "@/components/Elements/Tooltip";
import Linkable from "@/components/Elements/Linkable";
import ExpandableButton from "@/components/Elements/ExpandableButton";
import DataViewUI from "@/components/Elements/Data/DataViewUI";
import Loading from "@/components/Elements/Loading";
import Stats from "@/components/Elements/Data/Stats";
import UserChip from "@/components/Elements/UserChip";
import Avatar from "@/components/Elements/Avatar";
import UserModal from "@/components/Elements/UserModal";
import ChatButton from "@/components/Elements/ChatButton";
import ChipMenu from "@/components/Elements/ChipMenu";
import Menu from "@/components/Elements/Menu";
import QuickActions from "@/components/Elements/QuickActions";
import RoleChip from "@/components/Elements/RoleChip";
import BetaChip from "@/components/Elements/BetaChip";
import BetaBadge from "@/components/Elements/BetaBadge";
import BetaLabel from "@/components/Elements/BetaLabel";
import Chips from "@/components/Elements/Chips";
import Badge from "@/components/Elements/Badge";
import VisibleToChips from "@/components/Elements/Data/VisibleToChips";
import StatChip from "@/components/Elements/StatChip";
import ChipsDialog from "@/components/Elements/Dialogs/ChipsDialog";
import Autocomplete from "@/components/Elements/Forms/Autocomplete";
import TextField from "@/components/Elements/Forms/TextField";
import TextArea from "@/components/Elements/Forms/TextArea";
import GeoSelect from "@/components/Elements/Forms/GeoSelect";
import GeoSelectCity from "@/components/Elements/Forms/GeoSelectCity";
import Calendar from "@/components/Calendar/Calendar";
import FileUpload from "@/components/Elements/Forms/FileUpload";
import FilesList from "@/components/Elements/Data/FilesList";
import DateTimePicker from "@/components/Elements/Forms/DateTimePicker";
import TimezoneMixin from "@/mixins/Timezones";
import AssessmentInputCards from "@/components/Lessons/Cards/index";
import AssessmentPresentCards from "@/components/Lessons/Present/Cards";
import Editor from "@/components/Elements/Forms/Editor";
import EditorBubble from "@/components/Elements/Forms/EditorBubble";
import EmojiPicker from "@/components/Elements/Forms/EmojiPicker";
import AttachAssociation from "@/components/Elements/Forms/AttachAssociation";
import ChipsList from "@/components/Elements/Data/ChipsList";
import CurrentFilter from "@/components/Elements/Data/CurrentFilter";
import VueVirtualScroller from "vue-virtual-scroller";
import DatetimePicker from 'vuetify-datetime-picker'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "vue-swatches/dist/vue-swatches.css";

Vue.use(VueVirtualScroller);
Vue.use(DatetimePicker);

Vue.component("Alerts", Alerts);
Vue.component("Notifications", Notifications);
Vue.component("Confirm", Confirm);
Vue.component("Dialog", Dialog);
Vue.component("FormDialog", FormDialog);
Vue.component("Tooltip", Tooltip);
Vue.component("Linkable", Linkable);
Vue.component("ExpandableButton", ExpandableButton);
Vue.component("DataViewUI", DataViewUI);
Vue.component("Stats", Stats);
Vue.component("UserChip", UserChip);
Vue.component("Avatar", Avatar);
Vue.component("UserModal", UserModal);
Vue.component("ChatButton", ChatButton);
Vue.component("ChipMenu", ChipMenu);
Vue.component("Menu", Menu);
Vue.component("QuickActions", QuickActions);
Vue.component("RoleChip", RoleChip);
Vue.component("BetaBadge", BetaBadge);
Vue.component("BetaChip", BetaChip);
Vue.component("BetaLabel", BetaLabel);
Vue.component("Chips", Chips);
Vue.component("VisibleToChips", VisibleToChips);
Vue.component("StatChip", StatChip);
Vue.component("ChipsDialog", ChipsDialog);
Vue.component("Autocomplete", Autocomplete);
Vue.component("TextField", TextField);
Vue.component("Badge", Badge);
// Using Textarea or any permutation therof causes errors in vue
Vue.component("LongTextField", TextArea);
Vue.component("GeoSelect", GeoSelect);
Vue.component("GeoSelectCity", GeoSelectCity);
Vue.component("Calendar", Calendar);
Vue.component("FileUpload", FileUpload);
Vue.component("Editor", Editor);
Vue.component("EditorBubble", EditorBubble);
Vue.component("FilesList", FilesList);
Vue.component("DateTimePicker", DateTimePicker);
Vue.component("AssessmentInputCards", AssessmentInputCards);
Vue.component("AssessmentPresentCards", AssessmentPresentCards);
Vue.component("EmojiPicker", EmojiPicker);
Vue.component("ChipsList", ChipsList);
Vue.component("Loading", Loading);
Vue.component("AttachAssociation", AttachAssociation);
Vue.component("CurrentFilter", CurrentFilter);

Vue.mixin({
	props: {
		/**
		 * Add a configurable uid indicator
		 * This can be used for unique bus events
		 */
		uid: {
			type: [String, Number],
			default: () => "element" + Date.now(),
		},
		parentUid: {
			type: [String, Number],
			default: () => "element-parent" + Date.now(),
		},
	},
});

export default {};
