import Vue from "vue";
import BaseApi from "./_base-api";

/**
 * Submitted Report API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(params) {
		this.log().info(
			"[Services: Submitted Reports]: Get Submitted Reports",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, "reports_submitted")}/${params.id}`,
				params
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Reports: Get Submitted Reports]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Submitted Report",
	path: "reports_submitted"
});

export default api;
