<template>
  <VContainer
    fluid
    grid-list-lg
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <ViewHeader>
          <!-- <template v-slot:extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
              :to="getRouteForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
            />
          </template> -->
        </ViewHeader>
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VCard>
          <VCardTitle>
            <h2 class="display-1">
              {{ model.title }}
            </h2>
          </VCardTitle>
          <VCardText>
            <h3 v-if="model.city">
              {{ model.city.name }}
            </h3>
            <p
              class="formatted"
              v-html="model.content"
              v-linkified
            />
            <FilesList
              :readonly="true"
              :files="model.files"
            />
          </VCardText>
        </VCard>
        <DynamicInputsView
          :card="false"
          :readonly="true"
          :inputs="model.metadata"
        />
      </VCol>
      <VCol
        cols="12"
        class="ml-2"
      >
        <AssociatedContent :model="model" />
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
    >
      <VCol cols="12">
        <VCard cols="12">
          <VCardText>
            <VRow
              justify-center
              slot="progress"
              class="mt-4"
            >
              <VProgressCircular
                indeterminate
                color="primary"
              />
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "SupplementView",
  components: {
    DynamicInputsView,
    AssociatedContent,
    ViewHeader,
  },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Supplement",
    };
  },
  methods: {
    ...mapActions({
      doGetSupplement: "supplement/getOne",
    }),
    onGet(id) {
      return this.doGetSupplement(id);
    },
  },
};
</script>

