<template>
  <VContainer
    fluid
    align-start
    px-0
    py-0
    id="register-for-team-form"
    class="fill-height align-start"
    style="align-items: start"
  >
    <VRow
      align-center
      style="background-color: #fff; border-bottom: solid thin #ddd;"
    >
      <VCol
        shrink
        class="d-flex align-center pl-6"
      >
        <VBtn
          text
          :to="{name: 'apply-to-team'}"
          :icon="$vuetify.breakpoint.mdAndDown"
          style="height: 56px;"
        >
          <VIcon>arrow_back</VIcon>
        </VBtn>
      </VCol>
      <VCol
        class="d-flex align-center px-4 justify-start"
        style="border-right: solid thin #ddd"
        :shrink="$vuetify.breakpoint.mdAndUp"
      >
        <img
          style="max-height: 48px"
          :src="currentTeamPhoto"
        >
      </VCol>
      <VCol
        v-if="$vuetify.breakpoint.mdAndUp"
        grow
        class="px-4"
        style="max-width: calc(100vw - 200px)"
      >
        <VRow
          row
          wrap
          align-center
        >
          <VCol
            cols="12"
            align-content-center
            v-show="$vuetify.breakpoint.mdAndUp"
          >
            <h2
              class="title black--text"
              style="text-transform: uppercase"
            >
              {{ currentTeamName }}
            </h2>
          </VCol>
          <VCol
            v-if="$vuetify.breakpoint.mdAndUp && currentTeamProfile.tagline && currentTeamProfile.tagline.length"
            class="text-truncate text-no-wrap black--text"
            :style="{
              maxWidth: $vuetify.breakpoint.smAndUp ? 'calc(100vw - 400px)' : '100%'
            }"
          >
            {{ currentTeamProfile.tagline }}
          </VCol>
        </VRow>
      </VCol>
      <template v-if="!$hasUser">
        <VCol
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center px-4 text-right justify-end"
        >
          Don't need to register?
        </VCol>
        <VCol
          shrink
          class="info px-8"
        >
          <VBtn
            text
            block
            style="height: 56px; color: #fff"
            class="my-0"
            :to="{
              name: 'team-login',
              params: {
                team: teamSlug
              }
            }"
          >
            Login
          </VBtn>
        </VCol>
      </template>
      <template v-if="$hasUser">
        <VCol
          shrink
          :class="activity.isFormLoading || (!pipelineForm.id && !$hasUser) ? 'grey lighten-2' : 'success'"
        >
          <Tooltip
            bottom
            :title="!pipelineForm.id && !$hasUser ? 'You haven\'t applied yet!' : 'Save your application and submit it later'"
          >
            <template #default="{on}">
              <VBtn
                v-on="on"
                text
                style="height: 56px; color: #fff"
                class="my-0 mr-2"
                @click="!pipelineForm.id && !$hasUser ? false : onApply(true)"
                :disabled="activity.isFormLoading"
                :loading="activity.isFormLoading"
              >
                Continue to Your Dashboard
              </VBtn>
            </template>
          </Tooltip>
        </VCol>
      </template>
    </VRow>
    <VRow
      row
      wrap
      justify-center
      id="registration-section"
      style="overflow-y: auto; height: calc(100vh - 150px)"
    >
      <template v-if="teamExists">
        <VCol
          :xs="12"
          :md="7"
          :lg="6"
          :order="$vuetify.breakpoint.mdAndUp ? 'first' : 'last'"
          :class="{
            'px-4': true,
            'pr-0': $vuetify.breakpoint.mdAndUp && canRegister
          }"
        >
          <VRow
            v-if="isSearchingTeam"
            justify-center
            slot="progress"
            class="mb-4"
          >
            <VProgressCircular
              indeterminate
              color="primary"
            />
          </VRow>
          <template v-if="canRegister">
            <Alerts
              v-if="hasMessagesOrErrors"
              :messages="
                messages"
              :errors="errors"
            />
            <form
              class="form"
              name="login"
              @submit.prevent="register"
            >
              <VStepper
                :cycle="false"
                hide-delimiters
                show-arrows
                class="no-elevation bg-transparent"
                v-model="section"
              >
                <VRow
                  :row="$vuetify.breakpoint.mdAndUp"
                  :column="$vuetify.breakpoint.smAndDown"
                  wrap
                  align-center
                  justify-end
                  mt-6
                >
                  <VCol
                    grow
                    :class="{
                      'title': true,
                      'text-right': $vuetify.breakpoint.mdAndUp,
                      'text-center' : $vuetify.breakpoint.smAndDown,
                      'pb-0' : $vuetify.breakpoint.smAndDown
                    }"
                    :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
                  >
                    {{ featureName('Registration') }}
                  </VCol>
                  <VCol
                    v-if="$route.hash === testHash"
                    sm="12"
                    md="auto"
                  >
                    <VRow
                      row
                      align-center
                      justify-center
                    >
                      <VCol class="d-flex align-center justify-end">
                        <VBtn
                          depressed
                          style="text-transform: none"
                          class="mr-2"
                          :disabled="section <= 1"
                          @click="prev"
                        >
                          Previous
                        </VBtn>
                        <VBtn
                          class="wethrive"
                          style="text-transform: none"
                          :disabled="section === pipelineSections.length + 1"
                          :color="'primary'"
                          @click="next"
                        >
                          Next
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol
                    v-if="enableApplicationForm"
                    grow
                    sm="12"
                    md="auto"
                    :class="{
                      'py-0' : $vuetify.breakpoint.smAndDown
                    }"
                  >
                    <VStepperHeader
                      :class="{
                        'elevation-0 align-center': true,
                        'justify-center': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <VStepperStep
                        :complete="section > 1"
                        :step="1"
                        class="pr-4"
                      />
                      <template v-for="(pipelineSection, sectionIndex) in pipelineSections">
                        <VDivider
                          :key="`step-divider-${sectionIndex}`"
                          :class="{
                            complete: section >= sectionIndex+offset
                          }"
                        />

                        <VStepperStep
                          class="px-1 mr-0 v-stepper_step_step__button"
                          error-icon="error"
                          :key="`step-${sectionIndex}`"
                          :complete="sectionIsComplete(sectionIndex) === true"
                          :step="sectionIndex"
                          :rules="[() => sectionIsComplete(sectionIndex)]"
                        >
                          <VBtn
                            icon
                            depressed
                            small
                            :disabled="!pipelineForm.id"
                            :class="stepClass(sectionIndex)"
                            :style="{
                              width: '32px',
                              height: '32px',
                              borderRadius: '50%',
                              backgroundColor: stepColor(sectionIndex)
                            }"
                            @click="switchToSection(sectionIndex)"
                          >
                            <template v-if="sectionIsComplete(sectionIndex) === true">
                              {{ sectionIndex+offset }}
                            </template>
                            <template v-else>
                              !
                            </template>
                          </VBtn>
                        </VStepperStep>
                      </template>
                    </VStepperHeader>
                  </VCol>
                </VRow>
                <VStepperItems>
                  <VStepperContent
                    :step="1"
                    style="padding: 0 0 24px 0"
                  >
                    <VCard light>
                      <VCardText>
                        <VRow
                          row
                          wrap
                          align-center
                          class="px-2"
                        >
                          <VCol
                            :grow="$vuetify.breakpoint.mdAndUp"
                            :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : 12"
                          >
                            <h2
                              :class="{
                                title: true,
                                'text-center': $vuetify.breakpoint.smAndDown
                              }"
                            >
                              {{ currentTeamName }}
                            </h2>
                          </VCol>
                          <VCol
                            :class="{
                              'd-flex align-center': true,
                              'justify-end': $vuetify.breakpoint.mdAndUp,
                              'justify-center': $vuetify.breakpoint.smAndDown
                            }"
                            style="flex: 1 0 56px;"
                            :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : 12"
                            :shrink="$vuetify.breakpoint.mdAndUp"
                          >
                            <div>
                              <img
                                height="56"
                                :src="currentTeamPhoto"
                              >
                            </div>
                          </VCol>
                          <VCol cols="12">
                            <div v-if="currentTeamProfile.tagline && currentTeamProfile.tagline.length">
                              {{ currentTeamProfile.tagline }}
                            </div>
                          </VCol>
                        </VRow>
                      </VCardText>
                      <VCardText>
                        <template v-if="hasRegisterLogic">
                          <h2 class="title mx-3">
                            {{ registerLogicMessage.name }}
                          </h2>
                          <p
                            v-show="registerLogicMessage.value"
                            v-linkified
                            v-html="registerLogicMessage.value"
                            class="formatted mx-3"
                            style="height: max-content; white-space: normal; overflow: visible"
                          />
                          <VRow 
                            v-if="registerLogicMessage.default_value.length"
                            class="mx-0"
                          >
                            <VCol>
                              <VExpansionPanels accordion>
                                <VExpansionPanel
                                  v-for="(option, key) in registerLogicMessage.default_value"
                                  :key="key"
                                >
                                  <VExpansionPanelHeader class="subtitle-1">
                                    {{ option.title }}
                                  </VExpansionPanelHeader>
                                  <VExpansionPanelContent>
                                    <p
                                      v-linkified
                                      v-show="option.description"
                                      v-html="option.description"
                                      class="formatted mx-3"
                                      style="height: max-content; white-space: normal; overflow: visible"
                                    />
                                  </VExpansionPanelContent>
                                </VExpansionPanel>
                              </VExpansionPanels>
                            </VCol>
                          </VRow>
                          <VRadioGroup
                            v-model="selectedLogic"
                            :disabled="activity.isFormLoading || !teamExists || (isRegistered && !$user.id)"
                            :error="!hasSelectedPipeline"
                            @change="setPipelineFromLogic(currentTeam.register_logic[selectedLogic])"
                          >
                            <VListItem
                              v-for="(logic, logicIndex) in currentTeam.register_logic"
                              :key="`register-logic-${logicIndex}`"
                              @click="onSetSelectedLogic(logicIndex)"
                            >
                              <VListItemAction>
                                <VRadio
                                  :value="logicIndex"
                                  :error="!hasSelectedPipeline"
                                />
                              </VListItemAction>
                              <VListItemTitle>
                                {{ logic.title }}
                              </VListItemTitle>
                            </VListItem>
                          </VRadioGroup>
                        </template>
                        <VList
                          v-else
                          light
                          class="relaxed"
                        >
                          <VListItem>
                            <VListItemTitle>Which role are you applying for?</VListItemTitle>
                          </VListItem>
                          <VRadioGroup
                            v-model="form.role"
                            :disabled="isRegistrationFieldsDisabled"
                          >
                            <VListItem
                              v-for="(role, roleIndex) in options.roles"
                              :key="`register-role-${roleIndex}`"
                            >
                              <VListItemAction>
                                <VRadio
                                  :value="role.id"
                                  :error="$v.form.role.$error"
                                />
                              </VListItemAction>
                              <VListItemTitle>{{ role.name }}</VListItemTitle>
                            </VListItem>
                          </VRadioGroup>
                        </VList>
                      </VCardText>
                      <VCardText
                        v-if="hasRegisterLogic"
                        class="pt-0"
                      >
                        <VRow>
                          <VCol
                            md="6"
                            class="pt-0"
                          >
                            <VBtn
                              block
                              depressed
                              :color="registerMode === 'register' ? 'info' : 'default'"
                              @click="registerMode='register'"
                            >
                              Do you need an account?
                            </VBtn>
                          </VCol>
                          <VCol
                            md="6"
                            class="pt-0"
                          >
                            <VBtn
                              block
                              depressed
                              :color="registerMode === 'login' ? 'info' : 'default'"
                              @click="registerMode='login'"
                            >
                              Already have an account?
                            </VBtn>
                          </VCol>
                        </VRow>
                      </VCardText>
                      <VCardText v-if="registerMode === 'login'">
                        <LoginWidget @loggedIn="onAfterLogin" />
                      </VCardText>
                      <VCardText v-if="registerMode === 'register' || !hasRegisterLogic">
                        <TextField
                          v-model="form.name"
                          label="Name"
                          type="text"
                          :disabled="isRegistrationFieldsDisabled"
                          :error="$v.form.name.$error"
                          @input="$v.$touch()"
                        />
                        <TextField
                          v-model="form.email"
                          label="Email"
                          type="email"
                          :disabled="isRegistrationFieldsDisabled"
                          :error="$v.form.email.$error"
                          @input="$v.$touch()"
                        />
                        <PasswordInput
                          v-model="form.password"
                          :editable="false"
                          :disabled="isRegistrationFieldsDisabled"
                          :error="$v.form.password.$error"
                          @input="$v.$touch()"
                        />
                        <PasswordInput
                          v-if="form.password"
                          v-model="form.password_confirmation"
                          label="Confirm Password"
                          :editable="false"
                          :disabled="isRegistrationFieldsDisabled"
                          :error="$v.form.password_confirmation.$error"
                          @input="$v.$touch()"
                        />
                        <template v-if="timezones && currentTeam.date_options && currentTeam.time_options">
                          <VCardTitle>Time Settings</VCardTitle>
                          <VCardText>
                            <VSwitch
                              v-model="form.timezone_full_format"
                              inset
                              label="Show full timezone name"
                            />
                            <Autocomplete
                              v-model="form.timezone"
                              append-inner-icon="arrow-down"
                              placeholder="Select Timezone"
                              label="Timezone"
                              item-value="value"
                              :filter-callback="timezoneFilter"
                              :multiple="false"
                              :item-text="form.timezone_full_format ? 'text' : 'short'"
                              :items="timezones"
                            />
                            <Autocomplete
                              v-model="form.time_format"
                              append-inner-icon="arrow-down"
                              placeholder="Select time format"
                              label="Time format"
                              item-text="text"
                              item-value="value"
                              :multiple="false"
                              :items="currentTeam.time_options"
                            />
                            <Autocomplete
                              v-model="form.date_format"
                              append-inner-icon="arrow-down"
                              placeholder="Select date format"
                              label="Date format"
                              item-text="text"
                              item-value="value"
                              :multiple="false"
                              :items="currentTeam.date_options"
                            />
                          </VCardText>
                        </template>
                      </VCardText>
                      <VCardText>
                        <VCheckbox
                          v-model="form.agreement"
                          type="checkbox"
                          label="Check here to agree to our"
                          :disabled="isRegistrationFieldsDisabled"
                          :error="$v.form.agreement.$error"
                          @input="$v.$touch()"
                        />
                        <VBtn
                          small
                          text
                          color="info"
                          @click="dialogs.terms = true"
                        >
                          Terms and Conditions
                        </VBtn>&nbsp; and &nbsp;
                        <VBtn
                          small
                          text
                          color="info"
                          @click="dialogs.privacy = true"
                        >
                          Privacy Policy
                        </VBtn>
                        <template v-if="hasTeamAgreements">
                          <VCheckbox
                            v-model="form.teamAgreement"
                            type="checkbox"
                            :disabled="isRegistrationFieldsDisabled"
                            :error="$v.form.teamAgreement.$error"
                            @input="$v.$touch()"
                          >
                            <span
                              slot="label"
                              style="margin-bottom: -16px"
                              v-html="agreementsLabel"
                            />
                          </VCheckbox>
                          <VBtn
                            v-for="(agreement,index) in currentTeam.agreements"
                            text
                            small
                            color="info"
                            class="mb-2"
                            :key="`agreement-activator-${index}`"
                            @click="onToggleTeamAgreement(index)"
                          >
                            {{ agreement.name }}
                          </VBtn>
                        </template>
                        <br>
                        <div class="d-flex justify-end fluid mt-2">
                          <VTooltip
                            :top="$vuetify.breakpoint.smAndDown"
                            :left="$vuetify.breakpoint.mdAndUp"
                            :value="isPipelineStageExpired"
                          >
                            <template #activator="{on}">
                              <VBtn
                                color="success"
                                :block="$vuetify.breakpoint.smAndDown"
                                :class="submitClass"
                                :loading="activity.isFormLoading"
                                :disabled="isSubmitDisabled"
                                @click.prevent="register"
                                v-on="on"
                              >
                                {{ submitText }}
                              </VBtn>
                            </template>
                            <span>
                              <VIcon class="mr-2">{{ registerTooltip.icon }}</VIcon>{{ registerTooltip.text }}
                            </span>
                          </VTooltip>
                        </div>
                      </VCardText>
                    </VCard>
                  </VStepperContent>
                  <!--Disable during registration fields-->
                  <template v-if="enableApplicationForm">
                    <VStepperContent
                      v-for="(sectionData, sectionName, sectionIndex) in onboardingMetadata"
                      :key="`pipeline-section-${sectionIndex}`"
                      :step="sectionIndex+2"
                      class="px-0"
                    >
                      <VCard light>
                        <VCardTitle class="py-2">
                          <VRow
                            row
                            align-center
                            style="height: 56px"
                            :wrap="$vuetify.breakpoint.mdAndDown"
                          >
                            <VCol
                              grow
                              class="text-uppercase"
                            >
                              {{ ['undefined', 'null'].indexOf(sectionName) === -1 ? sectionName : '' }}
                            </VCol>
                            <VCol
                              shrink
                              class="d-flex align-center"
                            >
                              <VBtn
                                v-show="section > 2"
                                depressed
                                style="text-transform: none"
                                class="mr-2"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                                @click="prev"
                              >
                                Previous
                              </VBtn>
                              <VBtn
                                v-show="section < pipelineSections.length + 1"
                                class="wethrive"
                                style="text-transform: none"
                                :color="'primary'"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                                @click="next"
                              >
                                Next
                              </VBtn>
                              <VBtn
                                v-show="section === pipelineSections.length + 1"
                                class="wethrive-alt slight-radius"
                                large
                                @click="onApply(true)"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                              >
                                Save
                              </VBtn>
                            </VCol>
                          </VRow>
                        </VCardTitle>
                        <VCardText>
                          <p
                            v-if="hasSectionDescription(sectionName)"
                            v-html="sectionDescription(sectionName)"
                          />
                          <DynamicInputsSubmit
                            v-model="onboardingMetadata[sectionName]"
                            item-title-class="subheading bordered-y py-4"
                            :section-start="sectionData[0].priority"
                            :section-end="sectionData.length + sectionData[0].priority"
                            :container-class="'px-0 elevation-0'"
                            :validator="$v.hasOwnProperty('pipelineForm') ? $v.pipelineForm.stages[requiredStageIndex].metadata : []"
                            @update:input="(key, value) => onUpdateMetadata(value.priority, value, `stages.${requiredStageIndex}.metadata`, 'pipelineForm')"
                            @validated="$v.hasOwnProperty('pipelineForm') ? $v.pipelineForm.$touch() : () => {}"
                          />
                        </VCardText>
                        <VCardActions
                          class="py-2"
                          style="height: 70px"
                        >
                          <VRow
                            row
                            align-center
                            :wrap="$vuetify.breakpoint.mdAndDown"
                          >
                            <VSpacer />
                            <VCol
                              pb-4
                              shrink
                              class="d-flex align-center"
                            >
                              <VBtn
                                v-show="section > 2"
                                depressed
                                style="text-transform: none"
                                class="mr-2"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                                @click="prev"
                              >
                                Previous
                              </VBtn>
                              <VBtn
                                v-show="section < pipelineSections.length + 1"
                                class="wethrive"
                                style="text-transform: none"
                                :color="'primary'"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                                @click="next"
                              >
                                Next
                              </VBtn>
                              <VBtn
                                class="wethrive-alt slight-radius"
                                large
                                v-show="section === pipelineSections.length + 1"
                                @click="onApply(true)"
                                :disabled="activity.isFormLoading"
                                :loading="activity.isFormLoading"
                              >
                                Save
                              </VBtn>
                            </VCol>
                          </VRow>
                        </VCardActions>
                      </VCard>
                    </VStepperContent>
                  </template>
                </VStepperItems>
              </VStepper>
            </form>
          </template>
          <VAlert
            type="info"
            v-else
            :value="true"
            :style="{
              marginTop: $vuetify.breakpoint.smAndDown ? 'auto' : '96px'
            }"
          >
            <h1>Registration is disabled</h1>
            <p v-html="registrationDisabledMessage" />
          </VAlert>
          <Dialog
            :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '640px'"
            :is-dialog-open="dialogs.privacy"
            title="Privacy Policy"
            @toggle:dialog="() => dialogs.privacy = false"
          >
            <Privacy mode="dialog" />
          </Dialog>
          <Dialog
            :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '640px'"
            :is-dialog-open="dialogs.terms"
            title="Terms and Conditions"
            @toggle:dialog="() => dialogs.terms = false"
          >
            <Terms mode="dialog" />
          </Dialog>
          <template v-if="hasTeamAgreements">
            <Dialog
              v-for="(agreement, index) in (currentTeam.agreements || [])"
              :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '640px'"
              :key="`agreement-info-${index}`"
              :is-dialog-open="dialogs.teamAgreements[index]"
              :title="agreement.name"
              @toggle:dialog="onToggleTeamAgreement(index)"
            >
              <VCard>
                <VCardText v-html="agreement.value" />
              </VCard>
            </Dialog>
          </template>
          <MessagesDialog
            v-if="isRegistered"
            max-width="640px"
            entity-type="pipeline-submitted"
            :is-dialog-open="dialogs.applicantChat"
            :title="`${((selectedPipeline || {}).thread_for_user || {}).title || 'Chat'}`"
            :members="((selectedPipeline || {}).reviewers || []).map(u => u.id)"
            :entity="submittedApplication"
            :thread="currentChatThread"
            @toggle:dialog="onToggleApplicantChat"
            @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
          />
        </VCol>
        <VCol
          class="mx-4"
          :xs="12"
          :md="3"
          :lg="2"
          :order="$vuetify.breakpoint.mdAndUp ? 'last' : 'first'"
          :style="{
            paddingTop: $vuetify.breakpoint.smAndDown ? 'auto' : '96px'
          }"
        >
          <div
            class="content-bg lighten-1 py-4 px-4 mb-2"
            :class="{
              error: isPipelineStageExpired
            }"
          >
            <span
              class="title"
              v-if="$hasUser"
            >{{ $user.first_name }}</span>
            <br>
            <span
              class="caption"
              v-show="hasSelectedPipeline"
            >You've selected</span>
            <h1
              class="subheading"
              v-show="hasSelectedPipeline"
            >
              {{ selectedPipeline.title }}
            </h1>
            <VAlert
              type="error"
              class="slightly-rounded"
              :value="isPipelineStageExpired"
            >
              Sorry but the deadline for this application has passed.
            </VAlert>
            <template v-if="selectedPipelineHasRequiredStage || hasSelectedPipeline">
              <template v-if="hasPipelineStageDeadline">
                Deadline is:
                <VChip color>
                  {{ isPipelineStageExpired ? ' Deadline Passed' : pipelineStageDeadline }}
                </VChip>
              </template>
              <VAlert
                type="info"
                class="slightly-rounded mt-1"
                :value="!isPipelineStageExpired"
              >
                <template v-if="isRegistered">
                  Strapped for time? You can save your progress now and continue later.
                </template>
                <template v-else-if="$hasUser">
                  Continue to apply
                </template>
                <template v-else>
                  Register to apply.
                </template>
              </VAlert>
            </template>
            <p v-else>
              Select an application to get more details
            </p>
          </div>
          <div
            v-if="teamSetting('pipeline.is_chat_enabled', true)"
            class="content-bg lighten-1 py-4 px-4 mb-2"
            style="background-color: #F1C830;"
          >
            <h1 class="title">
              Have a question?
            </h1>
            <p class="subheading">
              feel free to ask!
            </p>
            <VBtn
              v-if="$hasUser"
              depressed
              class="ml-0"
              large
              style="border: solid thin #E3B81A; color: black; background-color: #FFD63F;"
              text
              @click="onToggleApplicantChat"
            >
              <VIcon>chat</VIcon>&nbsp; Chat now
            </VBtn>
            <VAlert
              type="info"
              class="slightly-rounded pt-2"
              :value="!$hasUser"
            >
              Complete registration to chat with the team
            </VAlert>
          </div>

          <VAlert
            v-if="teamExists && (currentTeam.profile.support_email || currentTeam.profile.support_number || currentTeam.profile.support_name || currentTeam.profile.support_details)"
            color="white"
            class="text--black mt-2 slightly-rounded"
            :value="true"
          >
            <h1 class="headline black--text mb-2">
              Need further help?
            </h1>
            <h2
              v-if="currentTeam.profile.support_name"
              class="title black--text"
            >
              Contact: {{ currentTeam.profile.support_name }}
            </h2>
            <a
              v-if="currentTeam.profile.support_email"
              :href="`email:${currentTeam.profile.support_email}`"
              class="subheading subtitle"
            >{{ currentTeam.profile.support_email }}</a>
            <br>
            <a
              v-if="currentTeam.profile.support_number"
              :href="`tel:${currentTeam.profile.support_number}`"
              class="subheading subtitle"
            >{{ currentTeam.profile.support_number }}</a>
            <br>
            <p
              v-if="currentTeam.profile.support_details"
              class="formatted black--text mt-2"
              v-html="currentTeam.profile.support_details"
            />
          </VAlert>
        </VCol>
      </template>
      <VCol
        v-else
        px-4
        fill-height
        id="register-for-team-loading-error-messages"
      >
        <VRow
          fill-height
          align-center
          justify-center
        >
          <VCol
            cols="12"
            md="8"
            lg="6"
          >
            <Alerts
              v-if="hasMessagesOrErrors"
              :messages="messages"
              :errors="errors"
            />
            <Loading v-else />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <StatusDialog
      :can-contact-support="canContactSupport"
      :is-dialog-open="dialogs.statusDialog"
      :is-loading="isRegistering"
      :is-successful="isRegistered"
      :is-error="!isRegistered && !isRegistering"
      :message="isRegistering ? `${(form.name || '').split(' ')[0] || $user.first_name || 'Hey there,'} sit tight we're processing your registration ...` : `Success! Welcome to ${currentTeam.name} ${(form.name || '').split(' ')[0] || $user.first_name}.`"
      :status-text="isRegistering ? `Please wait ...` : `One second! We're taking you to your dashboard ...`"
    />
  </VContainer>
</template>

<script>
import globeIcon from "@/images/globe-icon@3x.png";
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import {
  helpers,
  sameAs,
  email,
  required,
  minLength,
  between,
  requiredIf,
  requiredUnless,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import FormMixin from "@/mixins/Form";
import Privacy from "@/components/App/Privacy";
import Terms from "@/components/App/Terms";
import MetadataMixin from "@/mixins/Metadata";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import MessagesDialog from "@/components/Chat/MessagesDialog";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import LoginWidget from "@/components/Login/LoginWidget";
import StatusDialog from "../StatusDialog";

export default {
  name: "RegisterForTeamForm",
  mixins: [
    NeedsTeamsMixin,
    FormMixin,
    MetadataMixin,
    HasApplicationPipelinesMixin,
  ],
  components: {
    Privacy,
    Terms,
    PasswordInput,
    DynamicInputsSubmit,
    MessagesDialog,
    LoginWidget,
    StatusDialog,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    invite: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (this.invite) {
      this.prepareInvite();
    }
    if (this.$route.params.team) {
      this.teamSlug = this.$route.params.team;
    }
    if (this.$route.hash === this.testHash) {
      this.form = this.mockData({
        name: "name.findName",
        email: "internet.domainName",
        password: "password",
        password_confirmation: "password",
        role: "student",
        team_id: null,
        terms: true,
        agreement: null,
        teamAgreement: false,
      });
      this.form.email = `${this.form.name.slugify()}@${this.form.email}`;
    }
  },
  mounted() {
    // if (this.$auth.check()) {
    //   this.navigateToDashboard();
    // }
    /**
     * Saw a situaton where there is an access token but it isn't valid
     * Logging out user here in that case
     */
    // this.doLogout();
    this.$v.$touch();
    if (this.$auth.check()) {
      this.setMe(this.$auth.user());
      this.registerMode = "login";
      this.onAfterLogin();
    }
    console.log("Auth Status", this.$auth.user(), this.$auth.check());
    this.$auth.load().then(() => {
      console.log("Auth ready");
    });
  },
  data() {
    return {
      enableApplicationForm: !!this.env.VUE_APP_ENABLE_APPLICATION_FORM,
      registerMode: null,
      globeIcon,
      failedRegistrationCount: 0,
      error: false,
      hasFiles: true,
      isRegistered: false,
      isRegistering: false,
      isPipelineSubmitted: false,
      section: 1,
      submitClass: "wethrive",
      options: {
        roles: [],
      },
      dialogs: {
        privacy: false,
        terms: false,
        statusDialog: false,
        teamAgreements: [],
      },
      pipelineForm: {
        pipeline_id: null,
        user_id: null,
        stages: [],
        current_stage_id: null,
        type: "application-pipeline",
      },
      defaultForm: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "member",
        team_id: null,
        terms: true,
        agreement: null,
        teamAgreement: false,
      },
      modelType: "Registration",
      offset: 2,
      selectedLogic: -1,
      findTeamScope: "register",
    };
  },
  validations() {
    const optionalSameAs = (value) => !isRegistered || sameAs(value);
    if (!this.isRegistered) {
      return {
        hasPipelineFromLogic: {
          required: requiredIf(function (nestedModel) {
            return this.hasRegisterLogic === true;
          }),
          sameAsHasPipelineFromLogic: sameAs((value) =>
            this.hasRegisterLogic ? true : false
          ),
        },
        teamSlug: {
          minLength: minLength(3),
        },
        form: {
          required: requiredUnless(function () {
            return this.isRegistered || this.$hasUser;
          }),
          name: {
            required: requiredUnless(function () {
              return this.isRegistered || this.$hasUser;
            }),
          },
          agreement: {
            required: requiredUnless(function () {
              return this.isRegistered || this.$hasUser;
            }),
            sameAs: sameAs((value) => true),
          },
          teamAgreement: {
            required: requiredIf(function (nestedModel) {
              return this.hasTeamAgreements;
            }),
            sameAs: sameAs((value) => this.hasTeamAgreements),
            // sameAs: sameAs((value) =>
            //   !this.$hasUser &&
            //   this.isRegistered === false &&
            //   this.teamExists &&
            //   (this.currentTeam.agreements || []).length > 0
            //     ? true
            //     : false
            // ),
          },
          password: {
            required: requiredUnless(function () {
              return this.isRegistered || this.$hasUser;
            }),
          },
          password_confirmation: {
            required: requiredUnless(function () {
              return this.isRegistered || this.$hasUser;
            }),
            sameAsPassword: sameAs((value) =>
              this.isRegistered
                ? this.form.password_confirmation
                : this.form.password
            ),
          },
          email: {
            email,
            required: requiredUnless(function () {
              return this.isRegistered || this.$hasUser;
            }),
          },
          role: {
            required: requiredIf(function (nestedModel) {
              return (
                !this.$hasUser && !this.isRegistered && !this.hasRegisterLogic
              );
            }),
          },
        },
        pipelineForm: {
          user_id: {},
          pipeline_id: {},
          stages: {
            [this.requiredStageIndex]: {
              required: {},
              pipeline_stage_id: {},
              metadata: {
                $each: {
                  value: {},
                },
              },
            },
          },
        },
      };
    } else {
      return {
        form: {
          name: {},
          agreement: {},
          teamAgreement: {},
          password: {},
          password_confirmation: {},
          email: {},
          role: {},
        },
        pipelineForm: {
          required: requiredIf(function (nestedModel) {
            return this.isRegistered === true;
          }),
          user_id: {
            required: requiredIf(function (nestedModel) {
              return this.isRegistered === true;
            }),
          },
          pipeline_id: {
            required: requiredIf(function (nestedModel) {
              return this.isRegistered === true;
            }),
          },
          stages: {
            [this.requiredStageIndex]: {
              required: requiredIf(function (nestedModel) {
                return (
                  nestedModel.is_required_during_onboarding === true &&
                  this.isRegistered === true
                );
              }),
              pipeline_stage_id: {
                required: requiredIf(function (nestedModel) {
                  return this.isRegistered === true;
                }),
              },
              metadata: {
                $each: {
                  value: (input) => {
                    switch (input.type.toLowerCase()) {
                      case "url":
                        const regexp =
                          /^((https|http):\/\/)?([\w\d\-\_\:\@]+)\.(.*)/;
                        return (
                          !required(input.value) || regexp.test(input.value)
                        );
                        break;
                    }
                    return true;
                  },
                },
              },
            },
          },
        },
      };
    }
  },
  computed: {
    canContactSupport() {
      return this.failedRegistrationCount > 0;
    },
    submitText() {
      if (this.invite) {
        return this.activity.isFormLoading
          ? "Accepting invite..."
          : "Accept Invite";
      } else if (this.$hasUser) {
        return "Continue";
      } else if (!this.isRegistered) {
        return this.activity.isFormLoading ? "Signing Up..." : "Sign Up";
      } else if (!this.$hasUser) {
        return "Logging you in...";
      } else if (this.hasOnboardingMetadata) {
        return "One sec...";
      } else {
        return "Loading...";
      }
    },
    registrationDisabledMessage() {
      return (
        this.teamExists &&
        `Registration is disabled for ${this.currentTeamName}. Please contact <a
          style="color: #000"
          href="mailto:${this.currentTeam.email}"
        >${this.currentTeam.email}</a> for help signing up`
      );
    },
    registerTooltip() {
      if (this.isPipelineStageExpired) {
        return {
          icon: "warning",
          text: "Registration is unavailable! Scroll up for more information.",
        };
      }
      return {
        icon: "check_circle",
        text: "Available for registration",
      };
    },
    isPipelineSaved() {
      return this.isRegistered && this.isPipelineSubmitted;
    },
    onboardingStage() {
      return this.pipelineForm.stages instanceof Array &&
        this.pipelineForm.stages.length > 0
        ? this.pipelineForm.stages[this.requiredStageIndex || 0] || {}
        : {};
    },
    onboardingMetadata() {
      return this.hasOnboardingMetadata
        ? this.onboardingStage.metadata
            .filter((input) => input instanceof Object)
            .map((input, k) => {
              input.priority = k;
              return input;
            })
            .groupBy((input) => input.section)
        : {};
    },
    hasOnboardingMetadata() {
      return (
        this.onboardingStage.metadata instanceof Array &&
        this.onboardingStage.metadata.length > 0
      );
    },
    isRegistrationFieldsDisabled() {
      return (
        this.activity.isFormLoading === true ||
        (this.isRegistered === true && !this.$hasUser) ||
        this.currentTeam.open_registration === false ||
        this.isPipelineStageExpired === true ||
        (this.hasRegisterLogic && !this.hasSelectedPipeline)
      );
    },
    isSubmitDisabled() {
      return (
        !this.isFormReady ||
        this.isRegistrationFieldsDisabled ||
        !this.form.agreement ||
        (this.hasTeamAgreements && !this.form.teamAgreement)
      );
    },
    currentChatThreadTitle() {
      if (!this.currentChatThread) {
        const entity = this.selectedPipeline;
        const user = this.$user;
        if (user && user.id && entity && entity.id) {
          return `${entity.title}: ${user.name}`;
        }
        return "Chat";
      }
      return (this.currentChatThread || {}).title || "";
    },
    currentChatThread() {
      return this.submittedApplication.thread_for_user;
    },
    hasTeamAgreements() {
      return this.teamExists && (this.currentTeam.agreements || []).length > 0;
    },
    agreementsLabel() {
      const customMessage = (this.currentTeam.register_agreement_message || {})
        .value;
      if (customMessage) {
        return customMessage;
      }
      if (this.currentTeam) {
        let teamName = this.currentTeamName;
        if (this.currentTeamName[this.currentTeamName.length - 1] === "s") {
          teamName = teamName + "'";
        } else {
          teamName = teamName + "'s";
        }
        return `Check here to agree to ${teamName} agreements`;
      }
      return "";
    },
    validationSections() {
      const $iter = Object.get(
        this.$v.pipelineForm.stages[this.requiredStageIndex || 0] || {},
        "metadata.$each.$iter"
      );
      if ($iter instanceof Object) {
        return Object.values(Object.values($iter).groupBy("$model.section"));
      }
      return null;
    },
    timezones() {
      return (this.currentTeam.timezones || [])
        .unique(this.form.timezone_full_format ? "text" : "short_offset")
        .sortBy(this.form.timezone_full_format ? "text" : "offset", true)
        .filter((tz) => {
          return this.timezone_full_format
            ? true
            : Number.isNaN(parseInt(tz.short));
        });
    },
  },
  watch: {
    currentApplicationStageIndex: function (index) {
      this.$nextTick(() => {
        if (index > -1) {
          this.localMetadataSections =
            this.currentApplicationStage.metadata_sections || {};
        }
      });
    },
    section: function (current) {
      if (this.isRegistered && this.pipelineForm.id) {
        this.onBackgroundSave();
      }
    },
    currentTeam: function (team) {
      if (team && !this.invite) {
        if (team.open_registration) {
          this.form.team_id = team.id;
          this.options.roles = team.signup_roles;
        } else {
          this.errors = [
            `Registration is disabled for ${team.name}. Please contact <a style="color: #000" href="mailto:${team.email}">${team.email}</a> for help signing up`,
          ];
        }
      }
    },
    invite: function (invite) {
      if (invite instanceof Object) {
        this.prepareInvite();
      }
    },
    "selectedPipeline.id": function (value) {
      // Need to do this here so that the pipeline reliably updates AFTER it has been set
      this.$nextTick(() => {
        if (value > 0) {
          // Set logged-in user id to mandate the validation
          if(this.isRegistered) {
            this.pipelineForm.user_id = this.$user.id;
          }
          this.pipelineForm.pipeline_id = this.selectedPipeline.id;
          this.pipelineForm.current_stage_id = (
            this.selectedPipeline.onboarding_stage || {}
          ).id;
          this.requiredStageIndex = this.selectedPipeline.stages.findIndex(
            (s) => s.is_required_during_onboarding
          );
          this.requiredStageIndex =
            this.requiredStageIndex < 0 ? 0 : this.requiredStageIndex;
          if (this.selectedPipeline.stages.length) {
            this.pipelineForm.stages = this.selectedPipeline.stages.map(
              (stage) => {
                return {
                  is_required_during_onboarding:
                    stage.is_required_during_onboarding,
                  pipeline_stage_id: stage.id,
                  metadata: (stage.metadata || []).map((metadata, index) => {
                    return this.prepareMetadata(metadata, index);
                  }),
                };
              }
            );
          } else if (
            this.selectedPipeline.onboarding_stage instanceof Object &&
            this.selectedPipeline.onboarding_stage.metadata instanceof Array
          ) {
            this.pipelineForm.stages = [
              {
                is_required_during_onboarding:
                  this.selectedPipeline.onboarding_stage
                    .is_required_during_onboarding,
                pipeline_stage_id: this.selectedPipeline.onboarding_stage.id,
                metadata: this.selectedPipeline.onboarding_stage.metadata.map(
                  (metadata, index) => {
                    return this.prepareMetadata(metadata, index);
                  }
                ),
              },
            ];
          }
          this.currentApplicationModel = this.pipelineForm;
          this.currentApplicationModel.pipeline = this.selectedPipeline;
          this.currentApplicationModel.current_stage_for_user =
            this.pipelineForm.stages[this.requiredStageIndex];
          this.currentApplicationModel.current_stage_for_user.stage =
            this.selectedPipeline.stages[this.requiredStageIndex];
        }
      });
    },
    isPipelineStageExpired: function (value) {
      if (value) {
        this.submitClass = "error";
      } else {
        this.submitClass = "wethrive";
      }
    },
  },
  methods: {
    ...mapActions({
      doRegister: "register/register",
      doLogin: "auth/login",
      doLogout: "auth/logout",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      setMe: "user/setMe",
      doSetCurrentTeam: "user/setCurrentTeam",
      doRequestAccess: "team/requestAccess",
    }),
    toSection(section) {
      this.section = section;
    },
    next() {
      this.section++;
      this.$log.debug("Advancing to next section", this.section);
    },
    prev() {
      this.section--;
      this.$log.debug("Advancing to previous section", this.section);
    },
    register() {
      this.$log.debug("register: ", this.$hasUser);
      this.isRegistering = true;
      if (!this.$hasUser) {
        this.form.team = this.currentTeam ? this.currentTeamName : null;
        this.form.terms = true;
        this.form.role = this.form.role || "member";
        this.form.pipeline_id = (this.selectedPipeline || {}).id || null;
        const password = this.form.password;
        return this.saveFormModel(this.doRegister)
          .then((resp) => {
            this.$log.debug("register: Registered ... logging in");
            if (resp.status != "error") {
              this.dialogs.statusDialog = true;
              this.submitClass = "wethrive-alt";
              this.isRegistered = true;
              const { username = this.form.email } = this.form;
              this.doLogin({
                username,
                password,
              }).then(() => {
                this.onAfterRegister(resp);
              });
            } else {
              this.errors = resp.data;
              this.failedRegistrationCount++;
              this.scrollToTop();
            }
          })
          .catch((error) => {
            this.failedRegistrationCount++;
            this.scrollToTop();
          });
      } else {
        this.onAfterRegister(this.$user);
      }
    },
    scrollToTop() {
      var container = this.$el.querySelector("#registration-section");
      container.scrollTop = 0;
    },
    onAfterRegister(user) {
      this.isRegistering = false;
      this.$log.debug("onAfterRegister: Logged in ... waiting for user");
      // this.$bus.$emit("notificationMessage", "Registration was successful!");
      this.$log.debug("Logged in ... onboarding or navigating to dashboard");
      if (this.$v && this.$v.form) {
        this.$v.form.$touch();
      }
      if (this.hasOnboardingMetadata) {
        this.setMe(user);
        this.pipelineForm.user_id = user.id;
        this.onApply().then(() => {
          // if (this.enableApplicationForm) {
          //   this.next();
          // } else {
          this.navigateToDashboard();
          // }
        });
      } else {
        this.$log.debug("Navigating to dashboard in 2s");
        this.navigateToDashboard();
      }
    },
    onAfterLogin(doApply) {
      this.isRegistered = true;
      this.$log.debug("After Login", doApply, {
        team_id: this.currentTeam ? this.currentTeam.id : this.$team.id,
        role:
          ((this.selectedPipeline || {}).approved_user_role || {}).value ||
          "student",
        id: this.$user.id,
      });
      this.doRequestAccess({
        team_id: this.currentTeam ? this.currentTeam.id : this.$team.id,
        role:
          ((this.selectedPipeline || {}).approved_user_role || {}).value ||
          "student",
        id: this.$user.id,
      }).then(() => {
        this.submitClass = "wethrive-alt";
        this.$log.debug("register: Logged in ... waiting for user");
        this.$bus.$emit(
          "notificationMessage",
          `Login was successful. Welcome back ${this.$user.first_name}!`
        );
        if (this.$v && this.$v.$form) {
          this.$v.$form.$touch();
        }
        this.onSelectTeam();
        if (this.hasOnboardingMetadata || doApply) {
          this.pipelineForm.user_id = this.$user.id;
          this.$user.current_team_id = this.currentTeam.id;
          this.dialogs.statusDialog = true;
          this.$team.id = this.currentTeam.id;
          this.activity.isFormLoading = true;
          this.$log.debug("onAfterLogin:", doApply, this.hasOnboardingMetadata);
          this.onApply().then(() => {
            this.$log.debug("onAfterApply:");
            this.activity.isFormLoading = false;
            if (this.enableApplicationForm) {
              this.next();
            } else {
              this.navigateToDashboard();
            }
          });
        } else {
          this.activity.isFormLoading = false;
        }
      });
    },
    onSubmit(navigateToDashboard) {
      return this.onApply(navigateToDashboard, true);
    },
    onApply(navigateToDashboard, submit) {
      this.$v.form.$touch();
      this.pipelineForm.pipeline_id = this.selectedPipeline.id;
      this.pipelineForm.current_stage_id = (
        this.selectedPipeline.onboarding_stage || {}
      ).id;
      this.pipelineForm.is_submitted = submit === true ? true : false;
      return this.saveFormModel(
        this.doSavePipelineSubmitted,
        null,
        this.pipelineForm,
        {
          dontSave: ["pipeline", "user", "current_stage", "submitted_by"],
        }
      )
        .then((result) => {
          this.$log.info(
            "Saved pipeline application",
            result,
            navigateToDashboard
          );
          if (result instanceof Object && result.id) {
            this.isPipelineSubmitted = true;
            this.$user.submitted_application = result;
            this.pipelineForm = result;
            if (this.$route.hash !== this.testHash && navigateToDashboard) {
              this.$log.info("Navigating to dashboard");
              this.navigateToDashboard();
            }
          }
        })
        .catch((error) => {
          this.$log.info("Error on applying", error);
          navigateToDashboard && this.navigateToDashboard();
        });
    },
    onBackgroundSave() {
      this.skipFormValidation = true;
      return this.backgroundSaveFormModel(
        this.doSavePipelineSubmitted,
        null,
        this.pipelineForm,
        {
          dontSave: ["pipeline", "user", "current_stage", "submitted_by"],
        }
      );
    },
    onSelectTeam() {
      this.$log.debug("Switching", this.$team.id);
      this.doSetCurrentTeam(this.$team.id);
    },
    navigateToDashboard() {
      const ready = function () {
        this.$log.debug("login: User is available");
        window.location.href = "/app/dashboard";
        // this.navigateTo({
        //   name: "dashboard",
        //   hash: "#edit-application",
        //   params: {
        //     team: this.currentTeamSlug,
        //   },
        // });
      };

      if (!this.$auth.ready()) {
        this.$auth.ready(ready);
      } else {
        ready.call(this);
      }
    },
    prepareInvite() {
      this.currentTeam = this.invite.team;
      this.form.email = this.invite.email;
      this.form.team_id = this.currentTeam.id;
      this.form.role = this.invite.role;
      this.form.invitation = this.invite.token;
      this.form.name = this.invite.name;
      this.options.roles = [
        {
          id: this.invite.role,
          name: this.invite.role.titleize(),
        },
      ];
      this.teamSlug = this.invite.team.slug;
    },
    onToggleTeamAgreement(index) {
      this.$set(
        this.dialogs.teamAgreements,
        index,
        !this.dialogs.teamAgreements[index]
      );
    },
    onSetSelectedLogic(index) {
      // this.$log.info("Setting logic to index", index);
      this.selectedLogic = index;
      this.setPipelineFromLogic(this.currentTeam.register_logic[index]);
    },
    inflectTeamName() {},
    stepClass(index) {
      if (!this.pipelineForm.id) {
        return "grey lighten-2 black--text";
      }
      return this.sectionIsComplete(index) ? "white--text" : "black--text";
    },
    stepColor(index) {
      if (!this.pipelineForm.id) {
        return "white";
      }
      const primaryColor = this.section > index + 1 ? "#2EB67D" : "#ddd";
      // const primaryColor = 'primary';
      // this.$log.debug("Step color", this.section, index + 1, primaryColor);
      return this.sectionIsComplete(index) === true ? primaryColor : "error";
    },
    switchToSection(sectionIndex) {
      if (this.isRegistered) {
        this.section = sectionIndex + 1;
      }
    },
    sectionIsComplete(index) {
      if (this.validationSections instanceof Array) {
        const section = this.validationSections[index];
        if (!section) {
          return true;
        }
        // console.log("Section details", index, section);
        const invalidFields = section.filter((s) => s.$error === true);
        // this.$log.debug(
        //   "Section Details",
        //   index,
        //   section,
        //   invalidFields,
        //   invalidFields.length == 0
        // );
        if (invalidFields.length) {
          this.$log.warn("invalidFields", invalidFields);
          return "Missing information";
        }
        return true;
      }
      // this.$log.debug("Section complete", index);
      return true;
    },
    timezoneFilter(item, search, value) {
      if (search) {
        return (
          item.short.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          item.short_offset
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1 ||
          item.text.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          item.value
            .replace("_", " ")
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
        );
      }
      return true;
    },
    sectionHasVisibility(sectionName) {
      // console.log(
      //   "[",
      //   sectionName,
      //   "][",
      //   this.pipelineSections[this.section],
      //   "]",
      //   this.pipelineSections.indexOf(sectionName),
      //   this.section - 2,
      //   this.pipelineSections.indexOf(sectionName) === this.section - 2
      // );
      return this.pipelineSections.indexOf(sectionName) === this.section - 2;
    },
  },
};
</script>
<style lang="scss">
@import "../../../styles/variables.scss";
#register-for-team-form {
  background-image: url("../../../images/freepik-vector@3x.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  a {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .team-link {
    text-decoration: none;
  }
  .team-about {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .v-stepper__step__step {
    margin-right: 0;
    height: 32px;
    width: 32px;
    color: #fff;
    padding: 12px;
  }

  .v-divider {
    border: solid 2px inherit;
    &.complete {
      // border: solid 2px $primary-color;
      border: solid 2px #2eb67d;
    }
  }

  .v-divider {
    border: solid 2px #dddddd;
    // margin: 0 -28px !important;
    &.complete {
      // border: solid 2px $primary-color !important;
      border: solid 2px #2eb67d !important;
    }

    .v-stepper__step.v-stepper__step--error .v_stepper__step__step {
      width: 36px;
      height: 36px;
    }
  }
}
</style>