<template>
  <VMenu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
  >
    <template #activator="{ on }">
      <component
        :is="componentName"
        class="action-title"
        pill
        text:text="textButton"
        :icon="iconButton"
        :outlined="outline"
        :color="color"
        :text-color="textColor"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        :selected="selected"
        :dark="dark"
        :light="light"
        :disabled="disabled"
        @click="() => {}"
        v-on="on"
        :style="{
          textTransform: asButton ? 'none' : 'inherit',
          marginLeft: asButton ? '-16px' : 'auto',
        }"
      >
        <VIcon v-if="icon && left">
          {{ icon }}
        </VIcon>
        <template v-if="title">
          <VTooltip top>
            <template #activator="{ on: tooltipOn }">
              <span
                v-on="tooltipOn"
                class="text-truncate no-wrap"
                :style="{
                  maxWidth: maxWidth
                }"
              >{{ title }}</span>
            </template>
            <span>{{ title }}</span>
          </VTooltip>
        </template> &nbsp;
        <VIcon v-if="icon && right">
          {{ icon }}
        </VIcon>
      </component>
    </template>
    <VCard width="300">
      <VList
        dark
        v-if="title"
      >
        <VListItem>
          <VListItemContent>
            <VListItemTitle>{{ title }}</VListItemTitle>
            <VListItemSubtitle v-if="description">
              {{ description }}
            </VListItemSubtitle>
          </VListItemContent>
          <VListItemAction>
            <VBtn
              icon
              @click="menu = false"
            >
              <VIcon>close</VIcon>
            </VBtn>
          </VListItemAction>
        </VListItem>
      </VList>
      <slot :actions="actions">
        <VList>
          <VListItem
            v-for="(action, key) in actions"
            :key="key"
            @click.stop="!slotActions ? action.action : () => {}"
          >
            <slot
              name="action"
              :action="action"
            >
              <VListItemAction v-if="actionLeft && (action.icon || action.type)">
                <template v-if="action.type && action.type.toLowerCase() === 'switch'">
                  <VCheckbox
                    style="min-height: inherit !important"
                    @click.stop="action.action"
                    v-model="action.value"
                  />
                </template>
                <VIcon v-else>
                  {{ action.icon }}
                </VIcon>
              </VListItemAction>
              <VListItemContent>
                <VListItemTitle>{{ action.description }}</VListItemTitle>
              </VListItemContent>
              <VListItemAction v-if="actionRight && (action.icon || action.type)">
                <template v-if="action.type && action.type.toLowerCase() === 'switch'">
                  <VCheckbox
                    style="min-height: inherit !important"
                    @click.stop="action.action"
                    v-model="action.value"
                  />
                </template>
                <VIcon v-else>
                  {{ action.icon }}
                </VIcon>
              </VListItemAction>
            </slot>
          </VListItem>
        </VList>
      </slot>
    </VCard>
  </VMenu>
</template>

<script>
import {VBtn, VChip} from 'vuetify/lib'
export default {
  name: "QuickActions",
  props: {
    asButton: {
      type: Boolean,
      default: true
    },
    iconButton: {
      type: Boolean,
      default: true
    },
    textButton: {
      type: Boolean,
      default: true
    },
    /**
     * Signature:
     * [
     *    {
     *    }
     * ]
     */
    actions: {
      type: Array,
      default: () => []
    },
    slotActions: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: "info"
    },
    color: {
      type: String,
      default: "default"
    },
    textColor: {
      type: String,
      default: "inherit"
    },
    maxWidth: {
      type: String,
      default: "auto"
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: true
    },
    actionLeft: {
      type: Boolean,
      default: false
    },
    actionRight: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VBtn,
    VChip,
  },
  data: () => ({
    menu: false
  }),
  computed: {
    componentName() {
      return this.asButton ? "VBtn" : "VChip";
    }
  }
};
</script>
<style lang="scss">
:root {
  .action-title {
    .v-chip__content {
      cursor: pointer;
    }
  }
}
</style>