<template>
  <div
    v-if="models instanceof Array && models.length"
    :class="{
      'fill-height' : this.fillHeight,
      'mb-3': mode === 'standalone',
    }"
  >
    <Dialog
      max-width="640px"
      :is-dialog-open="dialogs.view"
      :title="`View ${title}`"
      :hide-overlay="true"
      @toggle:dialog="onToggleViewDialog"
    >
      <component
        :is="component"
        :id="currentModel ? currentModel.id : null"
        ref="viewItem"
        mode="dialog"
      />
    </Dialog>
    <Chips
      :title="title"
      :items="models"
      :action="onSetViewDialogModel"
      :as-button="asButton"
      :outlined="outline"
      :rounded="round"
      :color="color"
      :text-color="textColor"
      :small="small"
      :selected="selected"
      :dark="dark"
      :light="light"
      :disabled="disabled"
      :text="flat || textButton"
      :block="block"
      :text-left="textLeft"
      :text-right="textRight"
      :icon="icon"
      :fill-height="fillHeight"
    />
  </div>
</template>

<script>
import ViewMixin from "@/mixins/View";
import SearchMixin from "@/mixins/Search";
import FaqView from "@/components/FAQ/View";
import DeliverableView from "@/components/Deliverables/View";
import ProgramView from "@/components/Programs/View";
import ContentView from "@/components/Content/View";
import SupplementView from "@/components/Supplements/View";
//import ReportView from "@/components/Reports/View";
import CalendarView from "@/components/Calendar/Calendar";
import AttendanceView from "@/components/Attendance/View";
import PipelineView from "@/components/Organization/Pipelines/View";

export default {
  name: "ChipsDialog",
  components: {
    FaqView,
    DeliverableView,
    ProgramView,
    ContentView,
    SupplementView,
    //ReportView,
    AttendanceView,
    PipelineView,
    CalendarView,
  },
  mixins: [ViewMixin, SearchMixin],
  props: {
    models: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    itemTitle: {
      type: String,
      default: "title",
    },
    component: {
      type: String,
      default: "",
    },
    outline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    textLeft: {
      type: Boolean,
      default: false,
    },
    textRight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    textColor: {
      type: String,
      default: "auto",
    },
    mode: {
      type: String,
      default: "standalone",
    },
  },
  data() {
    return {
      currentModel: null,
      dialogs: {
        view: false,
      },
    };
  },
  methods: {
    onSetViewDialogModel(item) {
      if (item instanceof Object) {
        this.currentModel = item;
        this.dialogs.view = true;
      } else if (this.onGet instanceof Function) {
        this.onGet(item).then((model) => {
          this.currentModel = model;
          this.dialogs.view = true;
        });
      }
    },
    onToggleViewDialog() {
      this.dialogs.view = !this.dialogs.view;
      this.currentModel = null;
    },
  },
};
</script>