import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faCcVisa,
    faCcDiscover,
    faCcAmex,
    faCcMastercard,
    faCcPaypal,
    faCcStripe,
    faCcApplePay,
    faCcAmazonPay,
    faCcJcb,
    faCcDinersClub,
    

} from "@fortawesome/free-brands-svg-icons";

library.add(
    faCcVisa,
    faCcDiscover,
    faCcAmex,
    faCcMastercard,
    faCcPaypal,
    faCcStripe,
    faCcApplePay,
    faCcAmazonPay,
    faCcJcb,
    faCcDinersClub
);

export default {};