<template>
  <VCard
    class="user-profile-content"
    color="#f2f2f2"
  >
    <VCardTitle
      style="border: none;"
      class="pb-7"
    >
      <VSpacer />
      <slot name="actions" />
      <VBtn
        icon
        @click="$emit('close')"
      >
        <VIcon>close</VIcon>
      </VBtn>
    </VCardTitle>
    <VCardText>
      <VCard
        class="mx-auto rounded"
        max-width="460"
      >
        <VCardTitle class="text-center">
          <VCol>
            <VAvatar
              size="125"
              style="top: -63px"
            >
              <VImg :src="user.photo_url" />
            </VAvatar>
          </VCol>
          <VListItem style="margin-top: -70px;">
            <VListItemContent>
              <VListItemTitle style="font-size: 18px;">
                {{ user.name }}
              </VListItemTitle>
              <VListItemSubtitle
                v-if="user.city_name"
                style="font-size: 12px; font-weight: 300;"
              >
                <VIcon size="13px">
                  mdi-earth
                </VIcon>
                {{ user.city_name }}, {{ user.city.parent.name }}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </VCardTitle>
        <VSkeletonLoader
          v-if="!modelHasId"
          type="article"
        />
        <VCardText
          v-else-if="userBio"
          class="user-bio"
        >
          <VCol>
            {{ userBio }}
          </VCol>
        </VCardText>
        <VCardText
          style="background-color: #f2f2f2;"
          class="pa-0 pt-2"
        >
          <VRow>
            <VCol cols="auto">
              <p class="mb-1">
                Email
              </p>
              <VChip
                label
                class="info-tag"
              >
                {{ user.email }}
              </VChip>
            </VCol>
            <VCol cols="auto">
              <p class="mb-1">
                Role
              </p>
              <VChip
                label
                class="info-tag"
              >
                {{ userRole }}
              </VChip>
            </VCol>
            <VCol cols="auto">
              <p class="mb-1">
                Current Workspace
              </p>
              <VChip
                label
                class="info-tag"
              >
                {{ user.current_team_name }}
              </VChip>
            </VCol>
          </VRow>
        </VCardText>
        <VCardText
          v-if="user.teams && user.teams.length > 0"
          class="pa-0"
          style="background-color: #f2f2f2;"
        >
          <VRow>
            <VCol cols="12">
              <p class="mb-1">
                Workspaces
              </p>
              <div
                v-for="team of user.teams"
                class="pr-4 pb-4"
                style="display: inline-block;"
                :key="team.id"
              >
                <VChip
                  label
                  class="info-tag"
                >
                  {{ team.name }}
                </VChip>
              </div>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCardText>
    <template v-if="!$isUserStudent">
      <VCardTitle class="title">
        Associations and Submitted Information
      </VCardTitle>
      <VCardText>
        <VChip
          v-if="user.engagement_level == 'not-approved'"
          color="disabled"
          label
        >
          Not Approved
        </VChip>
        <VChip
          v-else-if="user.engagement_level == 'engaged'"
          label
          color="success"
          text-color="white"
        >
          Engaged ({{ user.last_engaged }} Days)
        </VChip>
        <VChip
          v-else-if="user.engagement_level == 'at-risk'"
          label
          color="warning"
          text-color="white"
        >
          At Risk ({{ user.last_engaged }} Days)
        </VChip>
        <VChip
          v-else
          label
          color="error"
          text-color="white"
        >
          <template v-if="user.last_engaged > 0">
            Disengaged ({{ user.last_engaged }} Days)
          </template>
          <template v-else>
            Not Active
          </template>
        </VChip>
        <VChip class="ma-2">
          Last Logged in: {{ $timezone.formatDateTime(user.last_login) }}
        </VChip>
        <VChip>Last Active: {{ $timezone.formatDateTime(user.last_active) }}</VChip>
      </VCardText>
      <VCardText
        v-if="applications.length"
        class="pt-0 px-2"
      >
        <h3 class="title px-0">
          {{ featureName('Pipelines') }} <VChip>{{ applications.length || 0 }}</VChip>
        </h3>
        <VExpansionPanels class="view-applications-container">
          <VExpansionPanel
            v-for="(application, index) in applications"
            focusable
            class="bg-transparent"
            :key="`application-${index}`"
          >
            <VExpansionPanelHeader class="font-weight-medium">
              {{ application.pipeline.title }}
            </VExpansionPanelHeader>
            <VExpansionPanelContent class="light-grey pt-4 px-3">
              <SubmittedApplication
                mode="embedded"
                :existing="application"
              />
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCardText>
      <VCardText>
        <VExpansionPanels>
          <VExpansionPanel
            focusable
            class="bg-transparent"
          >
            <VExpansionPanelHeader>
              <h3 class="subtitle">
                {{ featureName('Deliverables') }} Submitted <VChip>{{ user.deliverables_submitted_count || 0 }}</VChip>
              </h3>
            </VExpansionPanelHeader>
            <VExpansionPanelContent class="light-grey pt-2 px-0">
              <DeliverablesSubmitted
                for-single-user
                mode="embedded"
                deliverable-id="all"
                :custom-filter="{
                  filter: {
                    submitted_by: user.id
                  }
                }"
              />
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCardText>
      <VCardText>
        <VExpansionPanels>
          <VExpansionPanel
            focusable
            class="bg-transparent"
          >
            <VExpansionPanelHeader>
              <h3 class="subtitle">
                {{ featureName('Lesson') }} Taken <VChip>{{ user.assessment_results_count || 0 }}</VChip>
              </h3>
            </VExpansionPanelHeader>
            <VExpansionPanelContent class="light-grey pt-2 px-0">
              <AssessmentsSubmitted
                for-single-user
                mode="embedded"
                deliverable-id="all"
                :custom-filter="{
                  filter: {
                    user_id: user.id
                  }
                }"
              />
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCardText>
    </template>
  </VCard>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import SubmittedApplication from "@/components/Organization/Pipelines/ViewSubmitted";
import DeliverablesSubmitted from "@/components/Deliverables/Submitted";
import AssessmentsSubmitted from "@/components/Lessons/Submitted";

export default {
  name: "UserProfile",
  mixins: [ViewMixin],
  components: {
    SubmittedApplication,
    DeliverablesSubmitted,
    AssessmentsSubmitted,
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    modelType: "User",
  }),
  computed: {
    userBio() {
      if (this.user.profile instanceof Object && this.user.profile.bio) {
        return this.user.profile.bio;
      }
      return null;
    },
    applications() {
      if (this.user.submitted_application) {
        return [this.user.submitted_application];
      }
      return this.user.submitted_applications || [];
    },
    userRole() {
      if(this.user.hasOwnProperty("attachment_meta")) {
        return this.featureName(this.user.attachment_meta.type, "singularize").capitalize();
      }
      return this.user.role_name;
    },
  },
  methods: {
    ...mapActions({
      doGetUser: "users/getOne",
    }),
    onGet(id) {
      return this.doGetUser({
        id,
        _fields: "all",
        _relations: [
          "submittedApplication.stages",
          "primaryContentCompleted",
          "deliverablesSubmitted",
        ],
      }).then((result) => {
        this.$set(this.user, "deliverables_submitted_count", result.deliverables_submitted_count);
        this.$set(this.user, "submitted_application", result.submitted_application);
        this.$set(this.user, "assessment_results_count", result.assessment_results_count);
        return result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-tag {
  //padding: 13px 21px 12px;
  border-radius: 6px !important;
  border: solid 1px #dfdfdf;
  background-color: #f2f2f2 !important;
  //margin: 0px 8px;
  color: rgba(0, 0, 0, 0.55) !important;
}
.user-bio {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.75;
  line-height: 1.68;
  color: rgba(0, 0, 0, 0.75) !important;
}
.user-profile-content {
  .v-expansion-panel,
  .v-expansion-panel.bg-transparent {
    color: rgba(0, 0, 0, 0.55) !important;
    border-radius: 6px !important;
    border: solid 1px #dfdfdf;
    background-color: #f2f2f2 !important;
    box-shadow: none;
  }
}
</style>