<template>
  <VContainer
    grid-list-lg
    fluid
    id="save-applicant"
    :class="{
      'py-0': true,
      'is-loading': activity.isLoading || activity.isOptionsLoading
    }"
  >
    <VRow
      column
      v-if="realPipeline"
    >
      <SaveHeader
        :owner="this"
        :mode="mode"
      >
        <template #title>
          Submit {{ featureName('Pipeline').singularize() }} {{ featureName('Application') }}
        </template>
      </SaveHeader>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol
        style="flex: 1 1 auto"
        :class="{
          'py-0': true,
          'px-0': $vuetify.breakpoint.mdAndUp,
          'px-4': $vuetify.breakpoint.smAndDown
        }"
      >
        <VTabs
          lazy
          slider-color="primary"
          class="pb-0"
          :grow="$vuetify.breakpoint.smAndDown"
          :vertical="$vuetify.breakpoint.mdAndUp"
        >
          <VTab
            v-for="(tab, key) in tabs"
            :key="key"
            lazy
          >
            {{ tab.title }}
          </VTab>
          <VTabItem>
            <VCard>
              <VCardTitle class="pl-4 title">
                Submitted: {{ form.is_submitted ? $timezone.formatDateTime(form.submitted_on) : 'Not Submitted' }}
              </VCardTitle>
              <VCardText>
                <template v-if="canBeAttached">
                  <VRow class="mb-5">
                    <VCol class="pt-0 pb-0">
                      <Autocomplete
                        v-model="form.pipeline_id"
                        single-line
                        item-text="title"
                        item-value="id"
                        append-inner-icon="arrow-down"
                        placeholder="Select item"
                        label="Select the pipeline for this application"
                        :items="options.pipeline"
                      />
                    </VCol>
                    <VCol class="pt-0 pb-0">
                      <Autocomplete
                        v-model="form.type"
                        append-inner-icon="arrow-down"
                        placeholder="Select application type"
                        label="Select the type for this application. Only applications pipelines will show up on the dashboard"
                        :items="options.type"
                      />
                    </VCol>
                  </VRow>
                  <VRow class="px-3 mb-5">
                    <VCol class="pt-0">
                      <VSwitch
                        v-model="form.is_complete"
                        inset
                        class="mt-0"
                        label="Is this application complete?"
                        hint="Marking an application as complete removes it from the user's dashboard. They can review it later but cannot make any further changes once it is complete."
                        persistent-hint
                      />
                    </VCol>
                    <VCol class="pt-0">
                      <VSwitch
                        v-model="form.is_submitted"
                        inset
                        class="mt-0"
                        label="Is this application submitted?"
                        hint="You may submit the application on behalf of the user. This means that you would be recorded as the one who submitted it"
                        persistent-hint
                      />
                    </VCol>
                  </VRow>
                  <VRow class="mb-5">
                    <VCol class="pt-0">
                      <Autocomplete
                        v-model="form.user_id"
                        hide-no-data-msg
                        append-selection-text
                        item-text="name"
                        item-value="id"
                        append-inner-icon="arrow-down"
                        placeholder="Find user"
                        label="User"
                        :items="users"
                        :filter-options="{
                          type: 'user'
                        }"
                        :getter="doGetUsers"
                      >
                        <template #selection="slotProps">
                          <span class="v-select__selection--comma">
                            {{ slotProps.item.name }} ({{ slotProps.item.email }})
                          </span>
                        </template>
                        <template #item="slotProps">
                          {{ slotProps.item.name }} ({{ slotProps.item.email }})
                        </template>
                      </Autocomplete>
                    </VCol>
                    <VCol class="pt-0">
                      <Autocomplete
                        v-model="form.current_stage_id"
                        persistent-hint
                        item-text="title"
                        item-value="id"
                        append-inner-icon="arrow-down"
                        placeholder="Select stage"
                        label="Current Stage"
                        hint="This is the stage/round that the user will have access to edit in the application process. Make sure to mark the previous stages as complete by toggling the switch for the appropriate stage."
                        :items="stages"
                      />
                    </VCol>
                  </VRow>
                  <VRow
                    class="mb-5"
                    v-if="hasPipelineStatuses"
                  >
                    <VCol class="pt-0">
                      <Autocomplete
                        v-model="form.status"
                        item-text="title"
                        item-value="id"
                        append-inner-icon="arrow-down"
                        placeholder="Select status"
                        label="Status"
                        :items="formatStatusOptions(realPipeline.statuses || {})"
                      />
                    </VCol>
                  </VRow>
                </template>
              </VCardText>
            </VCard>
          </VTabItem>
          <VTabItem class="px-3">
            <VCard>
              <VCardTitle>
                <VRow
                  row
                  justify-space-between
                  align-center
                >
                  <h4>{{ `Reviewers` }}</h4>
                  <VBtn
                    text
                    @click="onToggleMemberDialog('mentors')"
                  >
                    <VIcon class="wethrive">
                      add
                    </VIcon>
                    {{ `Add Reviewer` }}
                  </VBtn>
                </VRow>
              </VCardTitle>
              <VCardText>
                <MembersList
                  v-if="form.reviewers && form.reviewers.length"
                  :users="form.reviewers"
                  @removeMember="(member) => removeMember(member, 'reviewers')"
                />
                <p v-else>
                  No {{ $team.user_role_name.pluralize() }} have been added
                </p>
              </VCardText>
            </VCard>
          </VTabItem>
          <VTabItem class="px-3">
            <VRow row>
              <VSpacer />
              <Tooltip
                label
                small
                outlined
                color="default"
                class="mr-2"
                button-class="mr-2"
                :as-button="false"
                :icon="null"
                :title="`A normal ${featureName('Stage', 'singularize').toLowerCase()} is outlined with no border`"
                :button-text="`Normal ${featureName('Stage', 'singularize')}`"
              />
              <Tooltip
                label
                small
                outlined
                color="primary"
                class="mr-2"
                button-class="mr-2"
                :as-button="false"
                :icon="null"
                :title="`The current ${featureName('Stage', 'singularize').toLowerCase()} is outlined with a blue border`"
                :button-text="`Current ${featureName('Stage', 'singularize')}`"
              />
              <Tooltip
                label
                small
                color="info"
                class="mr-2"
                button-class="mr-2 white--text"
                :as-button="false"
                :icon="null"
                :title="`A submitted ${featureName('Stage', 'singularize').toLowerCase()} is with a blue background`"
                :button-text="`Submitted ${featureName('Stage', 'singularize')}`"
              />
              <Tooltip
                label
                small
                color="success"
                class="mr-2"
                button-class="mr-2 white--text"
                :as-button="false"
                :icon="null"
                :title="`A completed ${featureName('Stage', 'singularize').toLowerCase()} is outlined with a green border`"
                :button-text="`Completed ${featureName('Stage', 'singularize')}`"
              />
            </VRow>
            <VRow>
              <VCol v-if="formHasStages">
                <SubmitFormStages
                  v-if="formHasCurrentStage"
                  :title="`Current ${featureName('Stage', 'singularize')}`"
                  :stages="currentStages"
                  :all-stages="form.stages"
                  :form="form"
                  :status-options="formatStatusOptions(realPipeline.stage_statuses)"
                  :has-pipeline-stage-statuses="hasPipelineStageStatuses"
                  :validator="$v.form.stages.$each"
                  @input:metadata="onUpdateStageMetadata"
                  @input:stage-property="onSetStageProperty"
                />
                <SubmitFormStages
                  :title="`${(formHasCurrentStage ? 'Other' : '')} ${featureName('Stage', 'pluralize')}`"
                  :stages="otherStages"
                  :all-stages="form.stages"
                  :form="form"
                  :status-options="formatStatusOptions(realPipeline.stage_statuses)"
                  :has-pipeline-stage-statuses="hasPipelineStageStatuses"
                  :validator="$v.form.stages.$each"
                  @input:metadata="onUpdateStageMetadata"
                  @input:stage-property="onSetStageProperty"
                />
              </VCol>
            </VRow>
          </VTabItem>
        </VTabs>
      </VCol>
      <VCol v-if="mode === 'standalone'">
        <VRow
          row
          justify-center
        >
          <VBtn
            large
            class="wethrive"
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSave"
          >
            Save
            Changes
          </VBtn>
        </VRow>
      </VCol>
      <Dialog
        :is-dialog-open="dialogs.members"
        :title="`Add ${memberDialog.type.titleize()}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleMemberDialog"
      >
        <MembersForm
          ref="addMembers"
          mode="multiple"
          :type="memberDialog.type"
          :existing="memberDialog.existing"
          :is-group="true"
          :exclude-me="false"
          @addMember="user => addMember(user, 'reviewers')"
          @removeMember="removeMember"
          @saveMembers="dialogs.members = false"
        />
      </Dialog>
    </VRow>
  </VContainer>
</template>

<script>
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "@/mixins/SavesSubmittedData.vue";
import SubmitFormStages from "./SubmitFormStages";
import HasMembersMixin from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
export default {
  name: "PipelinesSubmitForm",
  components: {
    SubmitFormStages,
    MembersForm,
    MembersList,
    SaveHeader,
  },
  mixins: [
    FormMixin,
    MetadataMixin,
    SavesSubmittedData,
    HasMembersMixin,
    SupportsPipelines,
  ],
  props: {
    property: {
      type: String,
      default: "",
    },
    entity: {
      type: Object,
      default: () => {},
    },
    pipeline: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelType: "pipelineSubmitted",
      hasFiles: true,
      selectedEntityType: null,
      options: {
        pipeline: [],
        types: [],
      },
      defaultForm: {
        entity_type: "App\\Models\\Pipeline",
        current_stage_id: null,
        pipeline: {
          stages: [],
        },
        stages: [],
        reviewers: [],
      },
      combinedMetadata: [],
    };
  },
  validations: {
    form: {
      user_id: {
        required,
      },
      stages: {
        $each: {
          metadata: {
            $each: {
              value: {},
            },
          },
        },
      },
    },
  },
  computed: {
    tabs() {
      return [
        {
          title: "Details",
        },
        {
          title: "Associations",
        },
        {
          title: "Stages",
        },
      ];
    },
    users() {
      return this.id ? [this.form.user] : this.options.users || [];
    },
    stages() {
      return this.realPipeline && this.realPipeline.stages instanceof Array
        ? this.realPipeline.stages
        : [];
    },
    hasStages() {
      return this.stages && this.stages.length > 0;
    },
    formHasStages() {
      return (
        (this.form instanceof Object && this.form.stages instanceof Array
          ? this.form.stages
          : []
        ).length > 0
      );
    },
    formHasCurrentStage() {
      return (
        this.formHasStages &&
        this.form.stages.filter(
          (stage) => stage.pipeline_stage_id == this.form.current_stage_id
        ).length > 0
      );
    },
    currentStages() {
      return this.formHasCurrentStage
        ? this.form.stages.filter(
            (stage) => stage.pipeline_stage_id == this.form.current_stage_id
          )
        : [];
    },
    otherStages() {
      return this.formHasStages
        ? this.form.stages.filter(
            (stage) => stage.pipeline_stage_id != this.form.current_stage_id
          )
        : [];
    },
    existingMembers() {
      return this.form.members ? this.form.members.map((m) => m.id) : [];
    },
    selectedEntityTitle() {
      return this.canBeAttached
        ? (
            this.options.pipeline.find(
              (entity) => entity.id === this.form.pipeline_id
            ) || {}
          ).title
        : "";
    },
    canBeAttached() {
      return this.options.hasOwnProperty("pipeline")
        ? this.options.pipeline.length > 0
        : false;
    },
    hasPipelineStatuses() {
      return (
        this.realPipeline.statuses instanceof Object &&
        this.realPipeline.statuses.options instanceof Object &&
        this.realPipeline.statuses.options.options instanceof Array
      );
    },
    hasPipelineStageStatuses() {
      return (
        this.realPipeline.stage_statuses instanceof Object &&
        this.realPipeline.stage_statuses.options instanceof Object &&
        this.realPipeline.stage_statuses.options.options instanceof Array
      );
    },
  },
  created() {
    if (this.existing instanceof Object) {
      this.form = this.existing;
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineSubmitted/getFormConfig",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      doGetPipelineSubmitted: "pipelineSubmitted/getOne",
      doGetPipeline: "pipeline/getOne",
      doGetUsers: "users/getAll",
    }),
    onGet(id) {
      return this.doGetPipelineSubmitted({
        id,
        pipeline_id: this.resolvedPipelineId,
      });
    },
    onGetFormConfig() {
      return this.doGetFormConfig({
        id: this.resolvedPipelineId,
      });
    },
    onSave() {
      // this.form.id = this.form.id || this.realPipeline.id;
      this.form[this.property] = this.form.entity_id;
      this.form.pipeline_id = this.form.pipeline_id || this.resolvedPipelineId;
      return this.saveFormModel(this.doSavePipelineSubmitted, null, null, {
        dontSave: [
          "team",
          "pipeline",
          "user",
          "current_stage",
          "submitted_by",
          "current_stage_for_user",
          "thread_for_current_user",
          "deliverable",
          "calendar_entry",
          "thread_for_user",
          "stages.*.pipeline_submitted",
          "stages.*.user",
          "stages.*.stage",
        ],
      });
    },
    onUpdateStageMetadata(stageKey, key, value) {
      this.onUpdateMetadata(key, value, `stages[${stageKey}].metadata`);
    },
    onSetStageProperty(stageKey, field, value) {
      this.onSetMetadataProperty(field, value, `stages.${stageKey}`);
    },
    getStageMetadata(key) {
      const stage = (this.form.stages || [])[key];
      return stage instanceof Object ? stage.metadata || [] : [];
    },
    formatStatusOptions(owner) {
      const _options = Object.get(owner || {}, "options.options") || [];
      let options =
        typeof _options === "string" ? _options.split(",") : _options;
      return (options || []).map((option) => {
        return {
          id: option,
          title: option.titleize(),
        };
      });
    },
    addMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onAddMember(this.form[type], member);
      // this.saveMentors(true);
    },
    removeMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onRemoveMember(this.form[type], member);
      // this.saveMentors(true);
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  .v-expansion-panel-header {
    padding: 0px 12px;

    .v-label {
      top: 0px;
    }
  }
}

#save-applicant {
  .v-tabs-items {
    height: calc(100vh - 148px);
    overflow: hidden auto;
  }
}
</style>

