<script>
import activityMixin from "@/mixins/Activity";
import { mapActions } from "vuex";
import SupportsModelTypes from "./SupportsModelTypes";

const moment = require("moment");

export default {
  mixins: [activityMixin, SupportsModelTypes],
  props: {
    id: {
      type: [Number, String, Object],
      default: null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    canGet: {
      type: Boolean,
      default: true,
    },
    existing: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      options: {},
      currentMenuItem: null,
      model: {},
      modelMeta: {},
      modelType: "Model",
    };
  },
  computed: {
    modelHasId() {
      return this.model.id ? true : false;
    },
  },
  mounted() {
    if (this.existing && this.existing.id) {
      this.model = this.existing;
    } else {
      let id = this.id || this.$route.params.id;
      if (this.canGet && id && this.onGet) {
        this.$log.debug("[View Mixin]: There is a getter. Getting model", id);
        this.onGet(id)
          .then((result) => {
            const model = result.data ? result.data : result;
            this.modelMeta = result.meta || {};
            this.$log.debug("[View Mixin]: Got model", model);
            this.model = Object.assign({}, this.model, model);
          })
          .catch((error) => {
            if (error.code == 404) {
              if (this.on404 instanceof Function) {
                this.on404(error);
              } else {
                this.navigateTo({
                  name: `${this.modelType.toLowerCase()}.index`,
                });
              }
              this.$bus.$emit(
                "notificationError",
                `We can't find that ${this.modelType.toLowerCase()}. Are you sure that is on this team / workspace?`
              );
            }
          });
      } else {
        this.emitLoadingDone();
      }
    }
  },
  methods: {
    emitReady() {
      this.$bus.$emit("isViewReady", true);
      this.$emit("isViewReady", true);
    },
    emitNotReady() {
      this.$bus.$emit("isViewReady", false);
      this.$emit("isViewReady", false);
    },
    emitLoading() {
      this.$emit("startLoading");
      this.$bus.$emit("startLoading");
    },
    emitLoadingDone() {
      this.$emit("doneLoading");
      this.$bus.$emit("doneLoading");
    },
    emitError(error) {
      this.$emit("loadError", error);
    },
  },
};
</script>