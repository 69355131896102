<script>
export default {
  props: {
    value: {
      type: String,
      default: "<br>",
    },
    placeholder: {
      type: String,
      default: "Enter text here",
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * Fix for jumping cursor: https://teamwethrive.slack.com/archives/C01P5F067T6/p1619711500003400
   * Fix from: https://github.com/ueberdosis/tiptap/issues/126
   */
  watch: {
    value(value) {
      // assumes that value is the HTML value, keeps the cursor at the same position
      if (value == this.editor.getHTML()) {
        return;
      }
      // a change as happened, update the content, cursor is at the start of the editor,
      // however, that is no big deal, assume it's a different content anyways.
      this.editor.setContent(value);
    },
    readonly: {
      handler: function(value) {
        this.editor.setOptions({
          editable: !value,
        })
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.editor) {
      this.editor.setContent(this.value);
      this.innerHTML = this.value;
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
};
</script>