<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      deleteItem: null,
      deleteItemType: null,
    };
  },
  computed: {
    removeAlertMessage() {
      return `Are you sure you want to remove this from this ${this.featureName('Program').singularize()}? You can always add it back later.`;
    },
  },
  methods: {
    ...mapActions({
      doDeleteContent: "content/delete",
      doDeleteDeliverable: "deliverable/delete",
      doDeletePipeline: "pipeline/delete",
    }),
    onRemoveItem(_item) {
      if(_item) {
        this.deleteItem = _item;
      }
      return new Promise((resolve, reject) => {
        this.$log.info("[Mixins: DeleteOptions: onRemoveItem]", this.deleteItemType, this.deleteItem);
        this.$emit("enableItemLoading", true);
        this.$refs.confirmRemove
          .open(`Remove ${this.featureName(this.deleteItemType, "singularize")}`, this.removeAlertMessage, {
            color: "red",
          })
          .then((result) => {
            if(result) {
              this.$bus.$emit("removeSectionItem", this.deleteItemType.toLowerCase(), this.deleteItem);
            } else {
              this.$emit("enableItemLoading", false);
            }
            resolve(result);
          });
      });
    },
    doDeleteItem() {
      return this.onDeleteItem(this.deleteItem).then((result) => {
        if(result) {
          this.$bus.$emit("deleteSectionItem", this.deleteItemType.toLowerCase(), this.deleteItem);
          this.$bus.$emit("notificationMessage", `Deleted ${this.deleteItemType} successfully!`);
        }
        return result;
      });
    },
    onDelete() {
      return this[`doDelete${this.deleteItemType}`](this.deleteItem.id);
    },
  },
};
</script>