<script>
import Vue from "vue";
import GoogleDriveUI from "@/components/Elements/Forms/GoogleDriveUI";
export default {
  components: {
    GoogleDriveUI,
  },
  props: {
    mode: {
      type: String,
      default: "standalone",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    sectionStart: {
      type: Number,
      default: 0,
    },
    sectionEnd: {
      type: Number,
      default: null,
    },
    inputsHaveUploads: {
      type: Boolean,
      default: false,
    },
    enableFiles: {
      type: Boolean,
      default: false,
    },
    enableTitles: {
      type: Boolean,
      default: true,
    },
    metadataProp: {
      type: String,
      default: "metadata",
    },
    inputs: {
      type: [Array, Object],
      default: () => [],
    },
    inputName: {
      type: String,
      default: "",
    },
    choiceMode: {
      type: String,
      default: "",
    },
    inputChoices: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    infoText: {
      type: String,
      default: null,
    },
    card: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: "content-bg dynamic-input-container px-6 py-2 my-4",
    },
    itemTitleClass: {
      type: String,
      default: "subheading",
    },
    appendText: {
      type: String,
      default: "Add Field",
    },
    validator: {
      type: [Object, Array],
      default: () => {},
    },
    activity: {
      type: Object,
      default: () => {},
    },
    max: {
      type: Number,
      default: -1,
    },
    enableCardInputs: {
      type: Boolean,
      default: false,
    },
    requiredText: {
      type: String,
      default: "*",
    },
  },
  data() {
    return {
      asyncInputs: {
        search: [],
        loading: [],
        options: [],
      },
      oldInputSectionsLength: 0,
      sectionVisibility: {},
      localChoices: [
        {
          text: "Short Text",
          value: "Text",
          icon: "mdi-text",
          image: require("../images/forms/short-text-icon@2x.png"),
        },
        {
          text: "Long Text",
          value: "Textarea",
          icon: "mdi-text-box-outline",
          image: require("../images/forms/long-text-icon@2x.png"),
        },
        {
          text: "Date",
          value: "Date",
          icon: "mdi-calendar",
          image: require("../images/forms/calendar-icon@2x.png"),
        },
        {
          text: "Time",
          value: "Time",
          icon: "mdi-clock",
          image: require("../images/forms/time-icon@2x.png"),
        },
        {
          text: "Number",
          value: "Number",
          icon: "mdi-counter",
          image: require("../images/forms/number-icon@2x.png"),
        },
        {
          text: "Rating",
          value: "Rating",
          icon: "star",
          image: require("../images/forms/rating-icon@2x.png"),
        },
        {
          text: "URL Button/Link",
          value: "Url",
          icon: "link",
          image: require("../images/forms/button-icon@2x.png"),
        },
        {
          text: "Payment Button/Link",
          value: "ActionUrl",
          key: "payment_link",
          icon: "money",
          image: require("../images/forms/button-icon@2x.png"),
        },
        {
          text: "Dropdown",
          value: "Dropdown",
          icon: "arrow_drop_down",
          image: require("../images/forms/dropdown-icon@2x.png"),
        },
        {
          text: "Checkboxes",
          value: "Checkbox",
          icon: "check_box_outline_blank",
          image: require("../images/forms/checkbox-icon@2x.png"),
        },
        {
          text: "Single Select",
          value: "Radio",
          icon: "radio_button_unchecked",
          image: require("../images/forms/single-select-icon@2x.png"),
        },
        {
          text: "YouTube/Vimeo Video",
          value: "Video_Link",
          icon: "videocam",
          image: "",
        },
      ],
    };
  },
  watch: {
    // inputSectionsLength: function (value) {
    //   if (value > 0 && value != this.oldInputSectionsLength) {
    //     this.oldInputSectionsLength = value;
    //     const sections = this.inputSections || [];
    //     if (sections && sections.length) {
    //       sections.map((v, k) => {
    //         this.$set(this.sectionVisibility, v, false);
    //       });
    //       if (sections.length === 1) {
    //         this.$set(this.sectionVisibility, sections[0], true);
    //       }
    //     }
    //   }
    // },
  },
  computed: {
    canAddMoreInputs() {
      return (
        (this.mode !== "single" && this.max == -1) ||
        (this.mode !== "single" &&
          this.max > 0 &&
          this.allInputs.length < this.max)
      );
    },
    isSingleInputMode() {
      return (
        (this.mode == "multiple" && this.max == 1) || this.mode == "single"
      );
    },
    inputSectionsLength() {
      return (this.inputSections || []).length;
    },
    inputSections() {
      return Object.keys(this.allInputsGrouped || {}).unique().sort();
    },
    hasInputSections() {
      return this.inputSectionsLength > 0;
    },
    allInputs: {
      get() {
        if (this.value instanceof Array) {
          return this.value
            .filter((input) => input instanceof Object)
            .map((input, index) => {
              input.realIndex = index;
              // FOrce a json parse here to return the real value. i.e.: "33" returns an integer of 33
              input.value =
                input.value instanceof String
                  ? JSON.parse(input.value)
                  : input.value;
              return input;
            })
            .sortBy("realIndex");
        }
        if (this.value instanceof Object) {
          return [this.value];
        }
        return [];
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allInputsGrouped: {
      get() {
        return this.allInputs.groupBy((input) => input.section);
      },
      set(value) {
        this.allInputs = value.flat();
      },
    },
    allInputsGroupedValues: {
      get() {
        return Object.values(this.allInputsGrouped);
      },
      set(value) {
        this.allInputs = value.flat();
      },
    },
  },
  methods: {
    isFile(input) {
      return ["File"].indexOf(input.type) > -1;
    },
    isGoogleFile(input) {
      return ["Google Drive", "Google_Drive"].indexOf(input.type) > -1;
    },
    isOptionsInput(type) {
      return ["Dropdown", "Checkbox", "Radio"].indexOf(type) > -1;
    },
    isCardsInput(type) {
      return ["Stack", "List", "Grid"].indexOf(type) > -1;
    },
    isGridListCardsInput(type) {
      return ["List", "Grid"].indexOf(type) > -1;
    },
    isTextInput(type) {
      return ["Textarea", "Text", "TextArea"].indexOf(type) > -1;
    },
    isUrlInput(type) {
      return ["Url", "Button", "ActionUrl"].indexOf(type) > -1;
    },
    isPreviewableInput(type) {
      return ["Url", "ActionUrl", "Button"].indexOf(type) > -1;
    },
    deepEqualCustom(a, b) {
      console.log("Comparing", a, b, a == b, a === b);
      if (a === b) return true;

      if (a instanceof Date && b instanceof Date) {
        // If the values are Date, they were convert to timestamp with getTime and compare it
        console.log("Comparing 0", a, b, a == b, a === b);
        if (a.getTime() !== b.getTime()) return false;
      }

      if (a != Object(a) || b != Object(b)) {
        console.log("Comparing 1", a, b, a == b, a === b);
        // If the values aren't objects, they were already checked for equality
        return false;
      }

      const props = Object.keys(a);

      if (props.length !== Object.keys(b).length) {
        console.log("Comparing 2", a, b, a == b, a === b);
        // Different number of props, don't bother to check
        return false;
      }

      console.log("Comparing 3", a, b, a == b, a === b);
      return props.every((p) => this.deepEqualCustom(a[p], b[p]));
    },
    isOptionMultiple(_input) {
      const input = _input.linked_metadata || _input;
      return (
        input instanceof Object &&
        input.options instanceof Object &&
        input.options.multiple === "multiple" || input.type === "Checkbox"
      );
    },
    isOptionSelected(input, choiceKey, value) {
      // console.log("Checking selection", input, choiceKey);
      if (!input) {
        this.$log.warn(
          "[DynamicInputsMixin]: Input doesn't exist",
          value,
          choiceKey
        );
        return false;
      }

      const options = this.getInputOptionsOptions(input);
      // console.log("Options", input.value == value, input.value, value, options);
      return input.value instanceof Array && input.value.length > 1
        ? input.value.findIndex((v) => v == value) > -1
        : input.value == value;
    },
    getOptionIcon(input, choiceKey, value) {
      // console.log("Getting icon for", input, choiceKey, value);
      if (this.isOptionMultiple(input)) {
        return this.isOptionSelected(input, choiceKey, value)
          ? "check_box"
          : "check_box_outline_blank";
      } else {
        // console.log(
        //   "Geting option icon for radio",
        //   this.isOptionSelected(input, choiceKey, value),
        //   input,
        //   choiceKey,
        //   value,
        //   this.isOptionSelected(input, choiceKey, value)
        //     ? "radio_button_checked"
        //     : "radio_button_unchecked"
        // );
        return this.isOptionSelected(input, choiceKey, value)
          ? "radio_button_checked"
          : "radio_button_unchecked";
      }
    },
    getOptionsInputTypeIs(input, type) {
      return this.getOptionsInputType(input) === type;
    },
    getOptionsInputType(input) {
      return (input.options || {}).type || input.type;
    },
    getInputCaption(_input) {
      const input = _input.linked_metadata || _input;
      return (input.options || {}).caption;
    },
    getInputOptions(_input) {
      return (
        _input.options || (this.getAsyncOptions(_input) || {}).options || {}
      );
    },
    getInputOptionsOptions(_input) {
      const input = _input.linked_metadata || _input;
      let options =
        input instanceof Object &&
        input.options instanceof Object &&
        input.options.options instanceof Object
          ? input.options.options
          : [];
      if (options instanceof Array) {
        options = options.map((v) => {
          switch (typeof v) {
            case "string":
              v = isNaN(+v) ? v : +v;
              break;
          }
          return v;
        });
      }
      return Vue.observable(options);
    },
    getAsyncInputOptions(input) {
      const index = parseInt(input.realIndex || input.priority) || 0;
      return (
        (this.hasAsyncOptions && this.asyncInputs.options[index || 0]) || []
      );
    },
    getAsyncOptions(_input) {
      const input = _input || {};
      const options =
        (this.choices || []).find(
          (c) =>
            c.value == input.type &&
            (input.asyncKey == c.asyncKey ||
              (input.options instanceof Object &&
                (input.options || {}).asyncKey == c.asyncKey))
        ) || {};
      return options || {};
    },
    getAsyncOptionsOptions(input) {
      return this.getAsyncOptions(input).options || {};
    },
    onGetAsyncInputOptions(input, force = false) {
      const index = parseInt(input.realIndex) || 0;
      const s = this.asyncInputs.search[index];
      console.log("Getting async options", input, this.choices);
      if ((s && s.length > 2) || force === true) {
        const loadFrom = this.getAsyncOptions(input).loadFrom;
        console.log("Got async options", loadFrom, this.getAsyncOptions(input));
        if (loadFrom instanceof Function && !this.isAsyncInputLoading(index)) {
          this.$set(this.asyncInputs.loading, index, true);
          return loadFrom.call(this, { s: s || "" }).then((result) => {
            this.$set(this.asyncInputs.options, index, result.data);
            this.$set(this.asyncInputs.loading, index, false);
            return result;
          });
        }
      }
      return Promise.resolve();
    },
    getVideoUrl(value) {
      try {
        const url = new URL(value);
        let result;
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtu.be":
            const hostname =
              ["www.youtube.com", "youtube.com"].indexOf(url.hostname) === -1
                ? "www.youtube.com"
                : url.hostname;
            result = `//${hostname}/embed/${url.searchParams.get("v") ||
              url.pathname.substr(1)}`;
            break;

          case "vimeo.com":
          case "www.vimeo.com":
            result = `//player.vimeo.com/video${url.pathname}`;
            break;

          default:
            result = value;
            break;
        }
        return result;
      } catch (e) {
        return "//" + value;
      }
    },
    isDefaultSection(name) {
      return (
        name == "null" ||
        name === null ||
        name == "undefined" ||
        name === undefined ||
        !name
      );
    },
    allSectionsFor(input) {
      return this.inputSections.filter(
        (v) => v != input.section && ["null", "undefined"].indexOf(v) == -1
      );
    },
    moveInput(event, originalEvent, group) {},
    valuePlaceholder(input) {
      const realInput = (input || {}).linked_metadata || input;
      return this.readonly
        ? `${realInput.name || "User provides value"}`
        : `${realInput.name || "Enter value"}`;
    },
    onToggleSectionVisibility(index) {
      const value = this.sectionVisibility.hasOwnProperty(index)
        ? this.sectionVisibility[index]
        : false;
      this.$log.debug(
        "[DynamicInputsMixin]: Toggling visibility on",
        index,
        value,
        !value
      );
      // this.sectionVisibility[index] = !value;
      this.$set(this.sectionVisibility, index, !value);
    },
    sectionHasVisibility(index) {
      return this.sectionVisibility[index] === true;
    },
    checkSectionVisibilty(group) {
      let visibleInputs = this.allInputs.filter((input) => input.section === group && input.sectionVisibility === true);
      return visibleInputs.length > 0;
    },
    getInputTitle(type) {
      return this.choices.find((choice) => choice.value === type).text;
    },
    getInputIcon(type) {
      if(type === "Date") {
        return "mdi-calendar-month";
      }
    },
    hasGroupDescription(groupName) {
      if(this.metadataSections && this.metadataSections instanceof Array) {
        const section = this.metadataSections.find((section) => section.title == groupName);
        return section && section.description && section.description != "<p></p>";
      }
      return false;
    },
    groupDescription(groupName) {
      return this.metadataSections.find((section) => section.title == groupName).description;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";

.dynamic-input-add.layout.row {
  margin-top: 15px !important;
}

.remove-dynamic-input .v-btn__content {
  text-transform: none;
  color: $font-light-color;
}

.remove-dynamic-input:hover .v-btn__content {
  color: #ec4a4a;
}

.dynamic-input-container .v-input__append-outer::before {
  content: "";
  height: 40%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 30%;
  background-color: #eaeaea;
}
</style>
