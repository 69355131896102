import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import dotenv from "dotenv";
dotenv.config();

const API_URL = process.env.VUE_APP_API_URL;

let instance;
const token = localStorage.access_token || localStorage.default_auth_token;
if (token) {
	instance = axios.create({
		baseURL: API_URL,
		headers: {
			accept: "application/json",
			"Content-Type": "application/json",
			"X-CSRF-TOKEN": "",
			Authorization: "Bearer " + token,
			"X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
		},
	});
} else {
	instance = axios.create({
		baseURL: API_URL,
		headers: {
			accept: "application/json",
			"Content-Type": "application/json",
			"X-CSRF-TOKEN": "",
			"X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
		},
	});
}

instance.interceptors.request.use(
	(config) => {
		if (token) {
			config.headers.Authorization = `Bearer ${window.localStorage
				.access_token || window.localStorage.default_auth_token}`;
		}

		Vue.$log.info("Headers", config.headers, config);

		// Only activate upload progress on file uploads
		if (
			(config.headers.hasOwnProperty("Content-Type") &&
				config.headers["Content-Type"]
					.toLowerCase()
					.indexOf("multipart/form-data") > -1) ||
			config.data instanceof FormData
		) {
			Vue.$log.info("Progress Config", config);

			config.onUploadProgress = function(progressEvent) {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				Vue.$bus.$emit("upload:progress", percentCompleted);
				// Should we automatically close the upload UI after 100%?
				// if (percentCompleted == 100) {
				// 	setTimeout(() => Vue.$bus.$emit("upload:dialog", false), 1000);
				// }
				Vue.$log.info("Progress", percentCompleted);
			};
		}

		return config;
	},
	(error) => {
		Vue.$bus.$emit("upload:dialog");
		return Promise.reject(error);
	}
);

Vue.use(VueAxios, instance);

export default new VueAxios();
