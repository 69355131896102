<template>
  <DashPanel />
</template>

<script>
import DashPanel from "@/components/Dashboard";

export default {
  name: "DashboardIndex",
  components: {
    DashPanel
  },
  data() {
    return {};
  }
};
</script>

<style>
.dashboard-page {
  height: 100%;
}
</style>