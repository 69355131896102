<template>
  <VTooltip 
    v-if="previewUsers.length > 0"
    top
    :disabled="hideImage"
  >
    <template #activator="{ on, attrs }">
      <VChip
        v-bind="attrs"
        v-on="on"
        color="transparent"
        class="pa-0"
        :style="{
          ...customStyle
        }"
        :href="href"
        @click.prevent.stop="$emit('showDetails')"
      >
        <VAvatar
          v-for="(item, index) in users"
          :key="index"
          :size="size"
          :class="{
            'ml-n4': index > 0
          }"
          :style="{
            border: hideImage ? 'solid 1.5px rgba(47, 53, 63, 0.75)' : 'solid 1px #ffffff',
            'background-color': hideImage ? 'rgba(236, 236, 241)' : 'none',
          }"
        >
          <VImg
            v-if="!hideImage"
            :src="item.photo_url"
          />
        </VAvatar>
        <VChip
          v-if="!hideImage && (totalUsers > maxPreview)"
          class="ml-n4"
          :color="color"
          :style="{
            height: size, 
            color: textColor + '!important',
            border: 'solid 1px #ffffff !important',
            cursor: 'pointer'
          }"
        >
          <strong>+{{ totalUsers - maxPreview }}</strong>
        </VChip>
      </VChip>
    </template>
    <span>{{ totalUsers }} {{ tooltipText }}</span>
  </VTooltip>
</template>
<script>
export default {
  name: "AvatarGroup",
  props: {
    size: {
      type: String,
      default: "32px !important"
    },
    color: {
      type: String,
      default: "primary"
    },
    textColor: {
      type: String,
      default: "white"
    },
    tooltipText: {
      type: String,
      default: "Users"
    },
    customStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    previewUsers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    maxPreview: {
      type: Number,
      default: 3
    },
    totalUsers: {
      type: Number,
      default: 0
    },
    href: {
      type: String,
      default: null
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    users() {
      return this.previewUsers.slice(0, this.maxPreview);
    }
  }
}
</script>