<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <DataViewUI
        hide-item-actions
        :search-label="`Search ${featureName('mentor')}`"
        :data="data"
        :headers="headers"
        :is-loading="activity.isLoading"
        :filters="allFilters"
        :hide-actions="hasExistingData"
        :disable-sort="hasExistingData"
        :hide-records-info="hasExistingData"
        @search="doLocalSearch"
      >
        <template #[`header.mentor_can_invite_user`]="{ header }">
          {{ header.text }}
          <VTooltip top>
            <template #activator="{ on, attrs }">
              <VIcon
                v-bind="attrs"
                v-on="on"
                size="16px"
              >
                info
              </VIcon>
            </template>
            <span>{{ `${featureName("Mentor").singularize()} can invite ${featureName("User").singularize()} to a ${featureName("Program").singularize()}` }}</span>
          </VTooltip>
        </template>
        <template #items-cells="props">
          <td>
            <div style="width: max-content">
              <b
                :class="{
                  'success--text': props.item.id === $user.id,
                }"
              >
                {{ props.item.name }}
              </b>
            </div>
          </td>
          <td>{{ props.item.email || 0 }}</td>
          <td>
            <VSwitch 
              v-model="props.item.attachment_meta.permissions.mentor_can_invite_user"
              inset 
              hide-details
              color="success"
              class="mt-0 pt-0"
              :loading="props.item.isLoadingInvite"
              @change="() => updatePermissions(props.item, props.index, 'invite')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.attachment_meta.permissions.mentor_can_create_content"
              inset 
              hide-details
              color="success"
              class="mt-0 pt-0"
              :loading="props.item.isLoadingContent"
              @change="() => updatePermissions(props.item, props.index, 'content')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.attachment_meta.permissions.mentor_can_create_deliverable"
              inset 
              hide-details
              color="success"
              class="mt-0 pt-0"
              :loading="props.item.isLoadingDeliverable"
              @change="() => updatePermissions(props.item, props.index, 'deliverable')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.attachment_meta.permissions.mentor_can_create_faq"
              inset 
              hide-details
              color="success"
              class="mt-0 pt-0"
              :loading="props.item.isLoadingFaq"
              @change="() => updatePermissions(props.item, props.index, 'faq')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.attachment_meta.permissions.mentor_can_create_lesson"
              inset 
              hide-details
              color="success"
              class="mt-0 pt-0"
              :loading="props.item.isLoadingLesson"
              @change="() => updatePermissions(props.item, props.index, 'lesson')"
            />
          </td>
        </template>
      </DataViewUI>
      <Confirm ref="confirmPermission" />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";

export default {
  props: {
    programId: {
      type: Number,
      default: null,
    },
  },
  mixins: [SearchMixin],
  data() {
    return {
      modelType: "users",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email",
        },
        {
          text: `Can Invite ${this.featureName('User').singularize()}?`,
          align: "left",
          sortable: true,
          value: "mentor_can_invite_user",
        },
        {
          text: `Can Create ${this.featureName('Content').singularize()}?`,
          align: "left",
          sortable: true,
          value: "",
        },
        {
          text: `Can Create ${this.featureName('Deliverable').singularize()}?`,
          align: "left",
          sortable: true,
          value: "",
        },
        {
          text: `Can Create ${this.featureName('FAQ').singularize()}?`,
          align: "left",
          sortable: true,
          value: "",
        },
        {
          text: `Can Create ${this.featureName('Lesson').singularize()}?`,
          align: "left",
          sortable: true,
          value: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetUsers: "users/getAll",
      setUserPermissions: "program/setUserPermissions"
    }),
    onGetAll(params) {
      params.type = "mentors";
      params.filter = {
        program_mentors: [this.programId],
      };
      return this.doGetUsers(params).then((result) => {
        result.data = result.data.map((user) => {
          user.isLoadingContent = false;
          user.isLoadingUser = false;
          user.permissions = this.getPermissionForProgram(this.programId, user.programs);
          return user;
        });
        return result;
      });
    },
    updatePermissions(user, index, feature) {
      this.$set(this.data.data, index, user);
      this.$refs.confirmPermission
        .open("Update Permission", `Are you sure to update the permission for ${user.name}?`, {
          color: "info",
        })
        .then((result) => {
          if(result) {
            let params = {
              user_id: user.id,
              program_id: this.programId,
              permissions: this.getUpdatedPermissions(user, feature),
            };
            this.$set(this.data.data, index, { ...user, [`isLoading${feature.capitalize()}`]: true });
            this.setUserPermissions(params).then((result) => {
              user.attachment_meta.permissions = JSON.parse(result.permissions);
              this.$set(this.data.data, index, { ...user, [`isLoading${feature.capitalize()}`]: false });
              this.$bus.$emit(
                "notificationMessage",
                "Successfully updated permission"
              );
              this.$emit("updated", user.id, user.attachment_meta.permissions);
            });
          } else {
            if(feature == "invite") {
              this.$set(user.attachment_meta.permissions, `mentor_can_invite_user`, !user.attachment_meta.permissions.mentor_can_invite_user);
            } else {
              this.$set(user.attachment_meta.permissions, `mentor_can_create_${feature}`, !user.attachment_meta.permissions[`mentor_can_create_${feature}`]);
            }
            this.$set(this.data.data, index, user);
          }
        });
    },
    getUpdatedPermissions(user, feature) {
      const currentPermissions = user.attachment_meta.permissions;
      const updatedPermission = {
        [feature == "invite" ? `mentor_can_invite_user` :  `mentor_can_create_${feature}`]: feature == "invite" ? currentPermissions.mentor_can_invite_user : currentPermissions[`mentor_can_create_${feature}`]
      };
      return {
        ...currentPermissions,
        ...updatedPermission,
      };
    },
  },
};
</script>
