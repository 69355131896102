<template>
  <VRow
    fill-height
    row
    wrap
    :class="{
      'is-loading': activity.isLoading,
      'mt-n6': !isStandalone
    }"
  >
    <VCol
      v-if="isStandalone"
      cols="12"
    >
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h1>Rename Things</h1>
        </template>
        <template #create-action>
          <ExpandableButton
            button-class="wethrive-alt"
            large
            :disabled="!isFormReady"
            :action="() => onSave()"
            text="Save Changes"
            icon="save"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      cols="12"
      v-if="hasMessagesOrErrors"
    >
      <Alerts
        :messages="messages"
        :errors="errors"
      />
    </VCol>
    <VCol
      cols="12"
      style="overflow:auto"
    >
      <VRow
        justify-space-between
        row
        wrap
      >
        <VCol
          align-center
          cols="12"
          md="6"
        >
          <VCard>
            <VCardTitle>User Naming Conventions</VCardTitle>
            <VCardText class="details-content">
              <VAlert
                :value="true"
                type="info"
              >
                Please make sure to use singular values. For example use
                <code>Member</code> instead of
                <code>Members</code>
              </VAlert>
              <TextField
                v-model="form.user_role_name"
                name="user_role_name"
                label="Name of Moderator Role"
                :placeholder="form.user_role_name"
              />
              <TextField
                v-model="form.student_role_name"
                name="user_role_name"
                label="Name of Member Role"
                :placeholder="form.student_role_name"
              />
              <TextField
                v-model="form.feature_names.reviewer"
                name="user_role_name"
                label="Name of Reviewer Role"
                persistent-hint
                hint="Use this for users assigned to review applications and content"
                :placeholder="form.feature_names.reviewer"
              />
            </VCardText>
          </VCard>
          <VCard class="mt-4">
            <VCardTitle>Registration Naming Conventions</VCardTitle>
            <VCardText>
              <template v-for="(item, index) in registrationRenamable">
                <TextField
                  v-model="form.feature_names[item.id]"
                  persistent-hint
                  class="py-2"
                  :label="`Name of ${item.title}`"
                  :placeholder="form.feature_names[item.id]"
                  :key="index"
                  :hint="item.hint"
                />
              </template>
            </VCardText>
          </VCard>
        </VCol>
        <VCol
          align-center
          cols="12"
          md="6"
        >
          <VCard>
            <VCardTitle>Feature Naming Conventions</VCardTitle>
            <VCardText>
              <template v-for="(item, index) in renamable">
                <TextField
                  v-model="form.feature_names[item.id]"
                  :label="`Name of ${item.title}`"
                  :placeholder="form.feature_names[item.id]"
                  :key="index"
                  :hint="item.hint"
                  persistent-hint
                />
              </template>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
export default {
  name: "Workspace",
  mixins: [FormMixin],
  props: {
    isStandalone: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IndexHeader
  },
  data() {
    return {
      modelType: "Settings",
      date: null,
      defaultForm: {
        feature_names: {
          content: "Content Library",
          lessons: "Lessons",
          worksheets: "Worksheets",
          supplements: "Supplements",
          deliverables: "Forms",
          attendance: "Attendance",
          faq: "FAQs",
          users: "Users",
          registration: "Registration",
          programs: "Hubs",
          applications: "Applications",
          pipelines: "Applications/Registrations",
          reviewer: "Reviewer",
          application_submit_button: "Submit",
          application_apply_button: "Apply"
        },
        user_role_name: "Moderator",
        student_role_name: "Member"
      },
      fileProperties: ["avatar"],
      renamable: [
        {
          id: "programs",
          title: "Hubs"
        },
        {
          id: "lessons",
          title: "Lessons"
        },
        /* {
          id: "worksheets",
          title: "Worksheets"
        }, */
        {
          id: "content",
          title: "Content Library"
        },
        /* {
          id: "supplements",
          title: "Supplements"
        }, */
        {
          id: "deliverables",
          title: "Forms"
        },
        /* {
          id: "attendance",
          title: "Attendance"
        }, */
        {
          id: "faq",
          title: "FAQs"
        },
        {
          id: "users",
          title: "Users"
        }
      ],
      registrationRenamable: [
        {
          id: "registration",
          title: "Registration",
          hint: "This is what is seen by applicants"
        },
        {
          id: "pipelines",
          title: "Applications & Registrations",
          hint: "This is what is seen by admins/reviewers"
        },
        {
          id: "applications",
          title: "Applications",
          hint: "This is what is seen by users/applicants"
        },
        {
          id: "stage",
          title: "Round",
          hint: "This is what you would call a round. Ex: Stage or Group"
        },
        {
          id: "application_submit_button",
          title: "Submit",
          hint:
            "This is the text for the application submit button on the user's application"
        },
        {
          id: "application_apply_button",
          title: "Submit",
          hint:
            "This is the text for the application apply button on the team profile page"
        }
      ]
    };
  },
  validations: {
    form: {}
  },
  computed: {},
  created() {
    this.form = this.$team || this.form;
    this.form.owner = this.form.owner || this.$user.id;
    this.form.owner_id = this.form.owner_id || this.$user.id;
  },
  watch: {
    form: {
      handler: function(value) {
        if(!this.isStandalone) {
          this.$emit("updated", value);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      doSaveTeam: "team/save"
    }),
    onSave() {
      return this.saveFormModel(this.doSaveTeam).then(team => {
        this.$team = team;
      });
    }
  }
};
</script>