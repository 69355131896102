<template>
  <VRow
    row
    wrap
    mt-0
    pt-0
  >
    <VCol cols="12">
      <Autocomplete
        v-if="!form.id || !form.sequence_owner_id"
        v-model="form.days_of_week"
        multiple
        append-inner-icon="arrow-down"
        placeholder="Days of week"
        :items="Object.values(options.day_of_week || {})"
        @input="update"
      />
      <Autocomplete
        v-else
        v-model="form.day_of_week"
        append-inner-icon="arrow-down"
        placeholder="Day of week"
        :items="Object.values(options.day_of_week || {})"
        @input="update"
      />
    </VCol>
    <VCol cols="12">
      <VCheckbox
        v-model="form.is_all_day"
        label="Is this event all day"
        @input="update"
      />
    </VCol>
    <VCol xs="6">
      <VMenu
        v-model="menu.startDateMenu"
        lazy
        offset-y
        ref="startDateMenu"
        transition="scale-transition"
        min-width="290px"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.start_date"
      >
        <TextField
          slot="activator"
          v-model="form.start_date"
          label="Start Date"
          prepend-icon="event"
          readonly
          :error="$v.form.start_date.$error"
          @blur="$v.$touch()"
          @input="update"
        />
        <VDatePicker
          v-model="form.start_date"
          scrollable
          :error="$v.form.start_date.$error"
          @blur="$v.$touch()"
          @input="setStartDate"
        />
      </VMenu>
    </VCol>
    <VCol xs="6">
      <TextField
        v-model="form.start_time"
        slot="activator"
        type="time"
        label="Enter Time"
        prepend-icon="access_time"
        :disabled="form.is_all_day"
        @input="update"
      />
    </VCol>
    <VCol xs="6">
      <VMenu
        v-model="menu.endDateMenu"
        lazy
        offset-y
        transition="scale-transition"
        ref="endDateMenu"
        min-width="290px"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.end_date"
      >
        <TextField
          v-model="form.end_date"
          readonly
          slot="activator"
          label="End Date"
          prepend-icon="event"
          :disabled="form.is_recurring"
          @input="update"
        />
        <VDatePicker
          v-model="form.end_date"
          scrollable
          :disabled="form.is_all_day"
          @input="setEndDate"
        />
      </VMenu>
    </VCol>
    <VCol xs="6">
      <TextField
        v-model="form.end_time"
        slot="activator"
        type="time"
        label="Enter Time"
        prepend-icon="access_time"
        :disabled="form.is_all_day"
        @input="update"
      />
    </VCol>
    <VCol cols="12">
      <VCheckbox
        v-model="form.is_recurring"
        persistent-hint
        label="Do the events recur?"
        hint="Enable this to configure the recur options for this event"
        @input="update"
      />
    </VCol>
    <VCol v-show="form.is_recurring">
      <TextField
        v-model="form.recur_count"
        type="number"
        label="Repeat this many times"
        @input="update"
        @keypress="numericOnly"
      />
    </VCol>
    <VCol v-show="form.is_recurring">
      <Autocomplete
        v-model="form.recur_frequency"
        append-inner-icon="arrow-down"
        placeholder="Repeat"
        :items="Object.values(options.recur_frequency)"
        @input="update"
      />
    </VCol>
    <VCol
      cols="12"
      mt-2
    >
      <h2>RSVP Settings</h2>
      <VRadioGroup
        v-model="form.rsvp_mode"
        row
        @change="update"
      >
        <VRadio
          v-for="(mode, key) in options.rsvp_mode"
          :value="key"
          :key="`rsvp-mode-${key}`"
          :label="mode"
        />
      </VRadioGroup>
      <VAlert
        type="info"
        :value="true"
      >
        <template v-if="form.rsvp_mode === 'standard'">
          Users in your workspace will sign up for this calendar event without any requirement of being accepted, nor rejected
        </template>
        <template v-else>
          Users in your workspace will request to join this calendar event. You or another admin will accept or reject their sign up and each user will receive a notification and confirmation after they’ve been accepted or rejected.
        </template>
      </VAlert>
    </VCol>
    <VCol cols="12">
      <VCheckbox
        v-model="form.rsvp_is_open"
        persistent-hint
        hint="If you disable this then you will need to manually add users to this event"
        :label="`Can anyone in this ${featureName('program')} RSVP?`"
        @input="update"
      />
    </VCol>
    <VCol xs="6">
      <VCheckbox
        v-model="form.rsvp_is_limited_to_single"
        persistent-hint
        hint="Only the first RSVP will be automatically accepted"
        :label="`Limit to a single RSVP?`"
        @input="update"
      />
    </VCol>
    <VCol
      v-show="!form.rsvp_is_limited_to_single"
      xs="6"
    >
      <TextField
        v-model="form.rsvp_limit"
        persistent-hint
        type="number"
        label="Maximum number of rsvps"
        hint="Ex: 2 maximum RSVPs will only allow the first two users to confirm"
        :min="0"
        @input="update"
        @keypress="numericOnly"
      />
    </VCol>
    <VCol cols="12">
      <VRow
        row
        wrap
        justify-space-between
      >
        <VCol cols="12">
          <VRow
            row
            justify-space-between
            align-center
          >
            <h4>RSVPs</h4>
            <VTooltip top>
              <VBtn
                slot="activator"
                color="primary"
                text:disabled="!canRsvp"
                @click="onToggleMembersDialog"
              >
                <VIcon class="wethrive">
                  add
                </VIcon>Add Rsvps
              </VBtn>
              <span>{{ canRsvp ? 'Add an RSVP' : 'RSVPs are not available at this time. Did you limit RSVPs?' }}</span>
            </VTooltip>
          </VRow>
        </VCol>
        <VCol cols="12">
          <RSVPList
            :mode="form.rsvp_mode"
            :rsvps="rsvps"
            @removeMember="removeMember"
            @acceptRsvp="onAcceptRsvp"
            @declineRsvp="onDeclineRsvp"
          />
        </VCol>
      </VRow>
      <Dialog
        title="Add RSVP"
        :hide-overlay="true"
        :is-dialog-open="dialogs.rsvps"
        @toggle:dialog="onToggleMembersDialog"
      >
        <MembersForm
          ref="addRSVPs"
          type="any"
          mode="multiple"
          :existing="existingMembers"
          :is-group="true"
          @addMember="addMember"
          @removeMember="removeMember"
          @saveMembers="dialogs.rsvps = false"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import HasMembers from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import RSVPList from "@/components/Elements/Data/RSVPList";
import moment from "moment";

export default {
  name: "EventCommon",
  components: { MembersForm, RSVPList },
  mixins: [HasMembers],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    calendar: {
      type: Object,
      default: () => {}
    },
    validator: {
      type: Object,
      default: () => {}
    },
    eventMode: {
      type: String,
      default: "event"
    },
    options: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      enableModelPrepare: false,
      dialogs: {
        rsvps: false
      },
      form: {
        rsvp_mode: "standard",
        rsvp_is_open: true,
        rsvp_is_limited_to_single: false,
        rsvp_limit: 0,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: null,
        start_time: "09:00",
        end_time: null,
        entries: [],
        entity_id: null,
        entity_type: null,
        calendar_id: null,
        is_all_day: false,
        is_recurring: false,
        recur_count: 0,
        recur_frequency: "Weekly",
        rsvps: []
      },
      menu: {
        startDateMenu: false,
        entilDateMenu: false,
        endDateMenu: false,
        startTimeMenu: false,
        untilTimeMenu: false,
        endTimeMenu: false
      }
    };
  },
  created() {
    this.$log.debug("[EventCommon]: Created", this.calendar);
    const unwatchRsvps = this.$watch("calendar.members", function(members) {
      if (!this.form.id) {
        // Add the calendar members to this event's RSVP list by default
        this.form.rsvps = (members || [])
          // Don't include the current user in the default member list
          .filter(m => m.id !== this.$user.id)
          .map(member => {
            return {
              user: member,
              user_id: member.id,
              status: "maybe",
              calendar_entry: this.calendarEntry
            };
          });
        unwatchRsvps();
      }
    });
    this.form = { ...this.form, ...this.value };
    if (!this.form.id) {
      // Add the calendar members to this event's RSVP list by default
      this.form.rsvps = (this.calendar.members || [])
        // Don't include the current user in the default member list
        .filter(m => m.id !== this.$user.id)
        .map(member => {
          return {
            user: member,
            user_id: member.id,
            status: "maybe",
            calendar_entry: this.calendarEntry
          };
        });
    }
    this.update();
  },
  computed: {
    $v() {
      return this.validator;
    },
    existingMembers() {
      return this.form.rsvps instanceof Array
        ? this.form.rsvps.map(rsvp => rsvp.user_id)
        : [];
    },
    modeTitle() {
      return this.form.rsvp_mode === "standard" ? "RSVP" : "Shift";
    },
    rsvps() {
      return (this.form.rsvps || []).sortBy("user.name");
    },
    canRsvp() {
      let canRsvp = true;
      if (
        this.value.rsvp_is_limited_to_single === true &&
        this.value.rsvps_count > 0
      ) {
        canRsvp = false;
      } else if (
        this.value.rsvp_limit > 0 &&
        this.value.rsvp_limit <= this.rsvps.length
      ) {
        canRsvp = false;
      }
      return canRsvp;
    },
    calendarEntry() {
      return {
        id: this.form.id,
        rsvp_mode: this.form.rsvp_mode
      };
    }
  },
  // watch: {
  //   form: {
  //     handler: function(value) {
  //       this.update();
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    update() {
      this.$log.debug("[EventCommon]: Updating model");
      this.$emit("input", { ...this.value, ...this.form });
    },
    setShowDate(d) {
      this.showDate = d;
    },
    setStartDate() {
      this.$refs.startDateMenu.save(this.form.start_date);
      this.$v.form.start_date.$touch();
      this.update();
    },
    setEndDate() {
      this.$refs.endDateMenu.save(this.form.end_date);
      this.update();
    },
    onToggleMembersDialog() {
      this.dialogs.rsvps = !this.dialogs.rsvps;
    },
    addMember(member) {
      this.$set(this.form, "rsvps", this.form.rsvps || []);
      if (this.form.rsvps.map(m => m.user_id).indexOf(member.id) === -1) {
        this.form.rsvps.push({
          user: member,
          user_id: member.id,
          status: "yes"
        });
      }
      this.update();
    },
    removeMember(member) {
      this.form.rsvps = this.form.rsvps.filter(
        rsvp => rsvp.user_id !== member.id
      );
      this.update();
    },
    updateRsvp(rsvp) {
      // Depending on the mode of the RSVP reject other RSVPs if this once is accepted
      if (this.form.rsvp_is_limited_to_single && rsvp.is_accepted) {
        this.form.rsvps = this.form.rsvps.map(r => {
          if (r.user_id != rsvp.user_id) {
            r.is_accepted = false;
            r.is_rejected = true;
            r.accepted_on = null;
            r.rejected_on = Date.now();
          }
          return r;
        });
      }
      const index = this.form.rsvps.findIndex(r => r.user_id === rsvp.user_id);
      this.form.rsvps.splice(index, rsvp);
      this.update();
    },
    onDeclineRsvp(rsvp) {
      /** TODO: Conect to API */
      rsvp.is_accepted = false;
      rsvp.is_rejected = true;
      rsvp.accepted_on = null;
      rsvp.rejected_on = Date.now();
      this.updateRsvp(rsvp);
    },
    onAcceptRsvp(rsvp) {
      rsvp.is_accepted = true;
      rsvp.is_rejected = false;
      rsvp.accepted_on = Date.now();
      rsvp.rejected_on = null;
      this.updateRsvp(rsvp);
    }
  }
};
</script>