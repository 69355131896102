var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","id":"save-evaluated-pipeline","type":"table-heading,card","loading":_vm.activity.isFirstLoad}},[_c('VRow',{staticClass:"fill-height",attrs:{"row":"","wrap":"","align-start":""}},[_c('SaveHeader',{attrs:{"disable-default-actions":"","owner":this,"mode":_vm.mode},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Submit "+_vm._s(_vm.featureName("Application").singularize())+" "+_vm._s(_vm.featureName("Evaluation"))+" ")]},proxy:true}])}),_c('VCol',{staticStyle:{"height":"64px"},attrs:{"cols":"12"}},[(_vm.submitted)?_c('h1',{staticClass:"title mb-2"},[_vm._v(" Submitting evaluation for "),_c('UserModal',{attrs:{"title":_vm.realSubmitted.title,"new-messages-count":_vm.realSubmitted.new_messages_for_user_count,"members":(_vm.realSubmitted.reviewers || []).map((u) => u.id),"entity":_vm.realSubmitted,"thread":_vm.realSubmitted.thread_for_user,"user":_vm.submitted.user,"enable-edit":_vm.$isUserAdmin,"enable-chat":_vm.teamSetting('content.is_chat_enabled', true)}})],1):_vm._e()]),_c('VCol',{staticStyle:{"height":"calc(100% - 128px)","overflow-y":"auto"},attrs:{"cols":"12"}},[_c('VCard',{staticClass:"mt-2 mb-2 mt-0 grey lighten-3"},[(
            !_vm.form.metadata || (_vm.form.metadata instanceof Array && !_vm.form.metadata.length)
          )?_c('VAlert',{attrs:{"type":"warning","value":true}},[_vm._v(" Looks like this form doesn't have fields. Please update the evaluation fields for this evaluation form. ")]):_c('VCardText',[(_vm.form.description)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.form.description)}}):_vm._e(),_c('DynamicInputsSubmit',{attrs:{"enable-default-values":"","card":false,"input-name":`${_vm.featureName('Pipeline')} ${_vm.featureName(
              'Stage',
              'singularize'
            )}`,"default-value-options":_vm.options.default_metadata_value_options},on:{"new:input":_vm.onNewMetadata,"update:input":_vm.onUpdateMetadata,"update:inputs":_vm.onUpdateMultipleMetadata,"remove:input":_vm.onRemoveMetadata,"remove:inputs":_vm.onRemoveMultipleMetadata},model:{value:(_vm.form.metadata),callback:function ($$v) {_vm.$set(_vm.form, "metadata", $$v)},expression:"form.metadata"}})],1)],1)],1),_c('VCol',{staticClass:"mx-3 sticky sticky--bottom"},[_c('VRow',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('VBtn',{class:{
            wethrive: !_vm.form.id,
            info: _vm.form.id > 0,
            'mr-3': true,
          },attrs:{"disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"loading":_vm.activity.isFormLoading},on:{"click":_vm.onSave}},[_vm._v(" Save ")]),_c('VBtn',{staticClass:"wethrive-alt mr-3",attrs:{"disabled":!_vm.isFormReady || _vm.isFormInputsDisabled,"loading":_vm.activity.isFormLoading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.form.is_submitted ? "Re-" : "")+" Submit ")]),(_vm.formHasId && _vm.deliverable.allow_multiple_submissions)?_c('VBtn',{attrs:{"disabled":!_vm.isFormReady || _vm.isFormInputsDisabled},on:{"click":_vm.onSaveAndReset}},[_vm._v(" Submit New ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }