<template>
  <VRow
    row
    column
    wrap
  >
    <VCol
      v-if="mode === 'standalone'"
      style="flex: 0 0 64px"
    >
      <VRow
        row
        justify-space-between
      >
        <h1 class="display-1">
          <VBtn
            icon
            fab
            @click="$router.go(-1)"
          >
            <VIcon>arrow_back</VIcon>
          </VBtn>
          View {{ featureName("Pipeline Applications")
          }}<template v-if="realPipeline">
            for {{ realPipeline.title }}
            <Linkable
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, realPipeline.id)
              "
            />
          </template>
        </h1>
      </VRow>
    </VCol>
    <VCol>
      <VAlert
        outlined
        class="mt-2"
        :type="!realContent.evaluation_form_count ? 'error' : 'info'"
        :value="true"
      >
        <template v-if="!realContent.evaluation_form_count">
          <p>
            This {{ featureName(contentType).singularize() }} is missing an evaluation
            form!
          </p>
          <VBtn
            text
            @click="onToggleEditPipelineEvaluationForm"
          >
            Add Evaluation Form
          </VBtn>
        </template>
        <template v-else-if="realContent.evaluation_form instanceof Object">
          Using evaluation form: {{ realContent.evaluation_form.title }}.
          <span>
            <VBtn
              text
              @click="onToggleEditPipelineEvaluationForm"
            >
              <VIcon small>edit</VIcon>&nbsp;Edit
            </VBtn>
          </span>
        </template>
      </VAlert>
      <DataViewUI
        enable-filter-headers
        :show-expand="false"
        :expandable-rows="false"
        :data="data"
        :headers="submittedHeaders"
        :is-loading="activity.isLoading"
        :no-data-text="submittedFor === 'all'
          ? 'No applications found.'
          : 'You currently have no current or past applications to other workspaces. When you do, you can view and manage them here.'
        "
        :filters="allFilters"
        :custom-filter="filter"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template #items-cells="props">
          <td>
            <VRow row>
              <UserModal
                :title="`${props.item.user.name} (${getSubmittedFor(props.item).pipeline.title
                })`"
                :new-messages-count="getSubmittedFor(props.item).new_messages_for_user_count
                "
                :members="(getSubmittedFor(props.item).reviewers || []).map((u) => u.id)"
                :entity="getSubmittedFor(props.item)"
                :thread="getSubmittedFor(props.item).thread_for_user"
                :user="getSubmittedFor(props.item).user"
                :enable-edit="$isUserAdmin"
                :enable-chat="teamSetting('content.is_chat_enabled', true)"
              />
              <ChatButton
                v-if="teamSetting('content.is_chat_enabled', true)"
                :title="`${props.item.user.name} (${getSubmittedFor(props.item).pipeline.title
                })`"
                :new-messages-count="getSubmittedFor(props.item).new_messages_for_user_count
                "
                :members="(getSubmittedFor(props.item).reviewers || []).map((u) => u.id)"
                :entity="getSubmittedFor(props.item)"
                :thread="getSubmittedFor(props.item).thread_for_user"
                :user="getSubmittedFor(props.item).user"
                :enable-edit="$isUserAdmin"
                :disable-chat-btn="!(getSubmittedFor(props.item).thread_for_user instanceof Object)
                "
                :chat-button-color="getSubmittedFor(props.item).thread_for_user instanceof Object
                  ? 'white'
                  : 'grey'
                "
              />
            </VRow>
          </td>
          <td>
            <Tooltip
              :as-button="false"
              :icon="!getPipelineFor(props.item).is_active ? 'error' : null"
              :title="!getPipelineFor(props.item).is_active
                ? 'This pipeline is inactive!'
                : 'This pipeline is active'
              "
              :color="!getPipelineFor(props.item).is_active ? 'warning' : ''"
              :text-color="!getPipelineFor(props.item).is_active ? 'white' : ''"
              :button-text="getPipelineFor(props.item).title"
            />
          </td>
          <td>
            <Tooltip
              :as-button="false"
              :color="!props.item.is_active &&
                (!getSubmittedFor(props.item).is_complete ||
                  !getSubmittedFor(props.item).is_submitted)
                ? 'warning'
                : ''
              "
              :text-color="!props.item.is_active &&
                (!getSubmittedFor(props.item).is_complete ||
                  !getSubmittedFor(props.item).is_submitted)
                ? 'white'
                : ''
              "
              :icon-color="getSubmittedFor(props.item).is_complete ? 'success' : 'info'"
              :icon="getApplicationStatusIcon(props.item)"
              :title="getSubmittedFor(props.item).status_message"
              :button-text="(getSubmittedFor(props.item).status || '').titleize()"
            />
          </td>
          <td>{{ getApplicationSubmittedOn(props.item || {}) }}</td>
          <td>
            <small v-if="props.item.evaluation instanceof Object">
              {{ $timezone.formatDateTime(props.item.evaluation.submitted_on) }}
              <br>
              <VChip
                class="mt-2"
                :color="props.item.evaluations_count > 0 ? 'info' : 'default'"
                @click="onToggleCompletedEvaluationsDialog(props.item)"
              >
                <VAvatar
                  left
                  size="12"
                  :class="props.item.evaluations_count > 0 ? 'info darken-4' : 'grey'"
                >{{ props.item.evaluations_count || 0 }}</VAvatar>
                {{ "Evaluation".pluralize(props.item.evaluations_count) }}
              </VChip>
            </small>
            <small v-else>--</small>
          </td>
          <td class="actions">
            <template v-if="props.item instanceof Object">
              <VBtn
                small
                :color="props.item.evaluation instanceof Object ? 'info' : 'disabled'"
                @click="onSetViewDialogModel(props.item)"
              >
                {{
                  props.item.evaluation instanceof Object &&
                    props.item.evaluation.is_submitted
                    ? "Re-Evaluate"
                    : "Evaluate"
                }}
              </VBtn>
            </template>
            <template v-else>
              <Tooltip
                small-icon
                color="error"
                text-color="error"
                icon-color="error"
                icon="edit"
                title="Edit the current stage and application type for this Application"
                button-text="Fix Issues"
                :action="() => onToggleSubmittedDialog(props.item)"
              />
            </template>
          </td>
        </template>
      </DataViewUI>
      <Dialog
        fullscreen
        persistent
        max-width="75%"
        :is-dialog-open="submittedDialogs.submitted"
        :title="`Update Submitted ${featureName('Pipeline')}: ${realContent.title}`"
        :action="onSubmitPipeline"
        :hide-overlay="true"
        :close-immediately="false"
        @toggle:dialog="onToggleSubmittedDialog"
      >
        <SubmitForm
          v-if="!$isUserStudent && submittedDialogs.submitted && hasSubmittedModel"
          mode="dialog"
          ref="submitPipeline"
          :id="submittedModel.id"
          :pipeline="realPipeline"
          :existing="submittedModel"
          :items="submitted.data"
          @isFormReady="onToggleItemLoading(submittedModel.id, false)"
        />
      </Dialog>
      <Dialog
        fullscreen
        model-type="PipelineEvaluation"
        :scrollable="false"
        :is-dialog-open="dialogs.view"
        :title="`Evaluate ${featureName('Submitted Pipeline')}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleViewDialog"
      >
        <template #title>
          <VBtn
            v-if="$vuetify.breakpoint.mdAndDown"
            small
            raised
            class="mr-2"
            :color="($refs.viewItem || {}).drawer == true ? 'normal' : 'primary'"
            @click="onToggleEvaluatedDrawer"
          >
            View
            {{ ($refs.viewItem || {}).drawer == true ? "Form" : "Data" }}
          </VBtn>
          {{ `Evaluate ${featureName("Submitted Pipeline")}` }}
        </template>
        <EvaluateComponent
          ref="viewItem"
          mode="dialog"
          :id="currentModel
            ? {
              id: currentModel.id,
              pipeline_id: currentModel.pipeline_id,
            }
            : null
          "
          :content="realContent"
          @saved="onUpdateSavedEvaluation"
        />
      </Dialog>
      <MessagesDialog
        v-if="hasSubmittedApplication"
        max-width="640px"
        entity-type="pipeline-submitted"
        :is-dialog-open="dialogs.applicantChat"
        :title="submittedApplicationThreadTitle"
        :members="(submittedApplication.reviewers || []).map((u) => u.id)"
        :entity="submittedApplication"
        :thread="submittedApplication.thread_for_user"
        :user="submittedApplication.user"
        :enable-edit="submittedFor === 'all' && $isUserAdmin"
        dialog-hide-overlay
        @toggle:dialog="onToggleApplicantChat"
        @newThread="(thread) => $set(submittedApplication, 'thread_for_user', thread)"
      />
    </VCol>
  </VRow>
</template>
<script>
import MessagesDialog from "@/components/Chat/MessagesDialog";
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasDueMixin from "@/mixins/HasDueOn";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import MetadataMixin from "@/mixins/Metadata";
import EvaluateComponent from "./Evaluate";
import SubmitForm from "./SubmitForm";
import SupportsPipelines from "../../../mixins/SupportsPipelines";

export default {
  name: "PipelinesEvaluated",
  components: {
    MessagesDialog,
    EvaluateComponent,
    SubmitForm,
  },
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    submittedFor: {
      type: String,
      default: "all",
    },
    contentType: {
      type: String,
      default: "pipeline",
    },
    canLoadFromUrl: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    SearchMixin,
    MetadataMixin,
    HasSubmittedDataMixin,
    HasApplicationPipelinesMixin,
    HasDueMixin,
    SupportsPipelines,
  ],
  data () {
    return {
      resolvedPipelineKey: "content",
      filter: {
        filter: {
          status: "Submitted",
        },
      },
      dialogs: {
        addEvaluation: false,
        reviewers: {
          active: false,
          model: {},
        },
      },
      modelType: "pipelineSubmitted",
    };
  },
  watch: {
    content: {
      deep: true,
      handler (val) {
        this.initContent(val);
      },
    },
  },
  computed: {
    submittedHeaders () {
      return [
        {
          text: "User",
          align: "left",
          sortable: true,
          value: "applicant",
        },
        {
          text: this.featureName("Pipeline", "singularize"),
          align: "left",
          sortable: true,
          value: "pipeline",
        },
        {
          text: `${this.featureName("Application", "singularize")} Status`,
          align: "left",
          sortable: true,
          value: "status",
        },
        {
          text: `${this.featureName("Application")} Submitted On`,
          align: "left",
          sortable: true,
          value: "submitted_on",
        },
        {
          text: `Evaluated On`,
          align: "left",
          sortable: true,
          value: "evaluation.updated_at",
        },
      ];
    },
    hasSubmitEvaluationRef () {
      return (
        this.$refs["viewItem"] != null &&
        this.$refs["viewItem"].submitEvaluationRef != null
      );
    },
    realContent () {
      if (this.hasResolvedPipeline) {
        return this.resolvedPipeline;
      } else
        return ["pipeline", "application-pipeline"].indexOf(this.content.type) > -1
          ? this.content
          : this.content.pipeline || {};
    },
  },
  methods: {
    ...mapActions({
      doGetPipelineSubmitted: "pipelineSubmitted/getAll",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      doGetIndexConfig: "pipelineSubmitted/getIndexConfig",
      doGetPipeline: "pipeline/getOne",
    }),
    onGetAll (_params) {
      let params = {};
      let pipelineId =
        this.resolvedPipelineId ||
        this.$route.params.pipeline ||
        ((content.type || this.contentType).indexOf("pipeline") > -1
          ? content.id
          : content.pipeline_id);
      const content = this.realContent || {};
      params = {
        ...(_params || {}),
        ...{
          // Need to see if this is a pipeline or a pipeline stage
          id: pipelineId,
          for: this.submittedFor,
        },
      };
      if (!this.$route.query.pipeline_id && this.$route.query.id) {
        params = {
          ...params,
          ...{
            pipeline_id: this.$route.query.id,
          },
        };
      }
      params._with = ["evaluation"];
      this.$log.debug(`Getting applications for evaluation`, params);
      return this.doGetPipelineSubmitted(params);
    },
    onGetIndexConfig () {
      return this.doGetIndexConfig({
        id: this.realContent.id,
      });
    },
    onSubmitPipeline () {
      return this.$refs["submitPipeline"].onSave();
    },
    onToggleEditSubmittedItemDialog (item) {
      if (this.$isUserStudent || this.submittedFor === "me") {
        this.onToggleApplicationForm(item);
      } else {
        this.onToggleSubmittedDialog(item, "pipelines");
      }
    },
    onUpdateCurrentModelLoading (isLoading) {
      const item = this.data.data.find(
        (item) => item.id == (this.currentApplicationModel || {}).id
      );
      if (item instanceof Object) {
        this.$set(item, "isLoading", !!isLoading);
      }
    },
    onToggleEditPipelineEvaluationForm () {
      this.$emit("toggle:evaluation-form");
      // if (model) this.$set(model, "isLoading", true);
    },
    onUpdateEvaluationForm (form) {
      this.$emit("update:evaluation-form", form, this.content.id);
    },
    onUpdateSavedEvaluation (id, evaluation) {
      const index = this.data.data.findIndex((p) => p.id == id);
      console.log("Updating evaluation for ", id, index);
      this.$set(this.currentModel, "evaluation", evaluation);
      this.$set(this.currentModel, "evaluations_count", 1);
      this.data.data.splice(index, {
        ...this.data.data[index],
        ...{
          evaluations_count: 1,
          evaluation,
        },
      });
    },
    onToggleEvaluatedDrawer () {
      this.$refs.viewItem.onToggleDrawer();
    },
    onToggleCompletedEvaluationsDialog (model) {
      this.$emit("toggle:evaluations", this.content, {
        strict: true,
        entity_id: model.id,
        entity_type: "App\\Models\\PipelineSubmitted",
      });
    },
    getReviewersTitle (item) {
      return item.reviewers_count
        ? `${item.reviewers.map((r) => r.name).join(", ")}`
        : `No ${this.featureName("Reviewers", "pluralize")}`;
    },
    getSubmittedFor (item) {
      return item || {};
    },
    getPipelineFor (item) {
      return this.getSubmittedFor(item).pipeline || {};
    },
    initContent (val) {
      if (val instanceof Object && val.id) {
        this.$log.debug("Evaluated: Content changed", val.type, val.id);
        this.resolvedPipeline = val;
      }
    },
  },
  created () {
    this.$log.debug("Evaluated component created");
    this.filter.filter.status = "Submitted";
    this.initContent(this.content);
    this.$log.debug(
      `Evaluated: Fetching ${this.contentType}:${this.realContent.id} from API`
    );
    this.doGetPipeline({
      id: this.realContent.id,
      pipeline_id: this.realContent.pipeline_id,
      _with: ["evaluationForm"],
      _fields: ["id", "evaluation_form"],
    }).then((model) => {
      this.$log.debug("Evaluated: Received model", model);
      this.$set(this.resolvedPipeline, "evaluation_form", model.evaluation_form);
      this.content instanceof Object &&
        this.$set(this.content, "evaluation_form", model.evaluation_form);
    });
  },
};
</script>
