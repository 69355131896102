<template>
  <VCard
    class="chat-new-item-tabs content-bg column d-flex"
    style="overflow: hidden; flex-grow: 1; background-color: transparent; border-radius: 10px 0px 0px 10px !important"
  >
    <VCardTitle
      class="px-1 d-flex align-start bg-content"
      style="background-color: #fff"
    >
      <VCol class="px-4 d-flex">
        <VBtn
          v-if="$vuetify.breakpoint.smAndDown"
          style="max-width: 72px; margin-bottom: 0px;"
          text
          icon
          color="primary"
          @click="$emit('toggleDrawer')"
        >
          <VIcon>forum</VIcon>
        </VBtn>
        <template v-if="hasNoMembers">
          <h3>Select one or more users</h3>
        </template>
        <TextField
          v-else
          v-model="chatTitle"
          solo
          class="pt-0 mt-0"
          style="min-height: 48px"
          placeholder="Enter a name for this group"
          :hide-details="!isGroup"
        >
          <template slot="prepend">
            <img
              width="20"
              height="20"
              style="margin: 0 8px 16px"
              :src="editIcon"
            >
          </template>
        </TextField>
      </VCol>
      <VCol
        v-if="!hasNoMembers"
        class="d-flex justify-end align-center"
      >
        <VBtn
          style="max-width: 72px; margin-bottom: 0px; margin-top: -8px"
          text
          icon
          slot="append-outer"
          @click="$emit('closeEditor')"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VCol>
    </VCardTitle>
    <VCardText
      class="d-flex column px-0 py-0"
      style="min-height: 0; flex: 1; background-color: transparent"
    >
      <VList
        v-if="forHuddle"
        class="rounded-0"
      >
        <VListItem>
          <VListItemContent>
            <VListItemTitle class="d-flex align-center">
              {{ form.is_private ? "Private" : "Public" }}
              <VSpacer />
              <VSwitch
                inset
                hide-details
                color="success"
                class="mt-0"
                :value="!form.is_private"
                @change="form.is_private = !form.is_private"
              />
            </VListItemTitle>
            <VListItemSubtitle>
              <template v-if="form.is_private">
                Only invited members of this {{ featureName("Program", "singularize") }} can view and join a private Huddle group.
              </template>
              <template v-else>
                Any member of this {{ featureName("Program", "singularize") }} can view and join a public Huddle group.
              </template>
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </VList>
      <VDivider />
      <MembersForm
        ref="membersForm"
        is-group
        fill-height
        class="no-border-radius"
        mode="batch"
        background-color="transparent"
        results-background-color="transparent"
        :save-text="saveText"
        :existing="group.members"
        :exclude-existing="true"
        :parent-is-saving="isSaving"
        :do-initial-search="false"
        :custom-filter="customFilter"
        :hide-filters="hideFilters"
        :style="{
          height: forHuddle ? 'calc(100vh - 215px)' : '100%',
        }"
        @toggleMember="toggleMember"
        @addMember="addMember"
        @removeMember="removeMember"
        @saveMembers="saveMembers"
        @filterData="refreshMembersList"
      />
    </VCardText>
  </VCard>
</template>


<script>
import uuid from "uuid";
import HasMembers from "@/mixins/HasMembers";
import CanFilter from "@/mixins/CanFilter";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import editIcon from "../../images/edit-icon@2x.png";
export default {
  name: "StartChat",
  props: {
    existing: {
      type: Object,
      default: () => null,
    },
    parentIsSaving: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: true,
    },
    forceGroup: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    members: {
      type: Array,
      default: () => [],
    },
    forHuddle: {
      type: Boolean,
      default: false,
    },
  },
  components: { MembersForm },
  mixins: [HasMembers, CanFilter],
  data() {
    return {
      editIcon,
      isStartingChat: [],
      isSaving: false,
      form: {
        is_group: true,
        is_private: true,
        group: {
          title: "",
          members: [],
        },
      },
      defaultForm: {
        is_group: true,
        is_private: true,
        group: {
          title: "",
          members: [],
        },
      },
      active: 0,
      originalMemberIds: [],
    };
  },
  computed: {
    saveText() {
      return `${this.form.id > 0 ? "Save" : "Start"} ${
        this.isGroup > 0 ? "Group" : ""
      } Chat`;
    },
    hasNoMembers() {
      if (this.isGroup) {
        return (this.form.group.members || []).length == 0 && !this.form.to_id;
      } else {
        return false;
      }
    },
    group() {
      return this.form.group || {};
    },
    chatTitle: {
      get() {
        if (this.isGroup) {
          if (this.group.members.length > 1) {
            return this.group.title;
          }
          return (this.group.members[0] || this.form.to || {}).name;
        } else {
          return (this.form.to_user || {}).name;
        }
      },
      set(title) {
        this.form.group = this.form.group || this.defaultForm.group;
        /* if (this.isGroup) {
          this.form.group.title = title;
        } */
        /* 
         * Set thread title if it is set by the user regardless
         * Refer: https://teamwethrive.slack.com/archives/CFBMPJMN2/p1631185766034700
         */
        this.form.group.title = title;
      },
    },
  },
  mounted() {
    this.isSaving = false;
    this.isGroup =
      this.active === 1 ||
      this.forceGroup === true ||
      this.form.is_group === true;
    this.defaultForm.group.members = this.members;
    this.initForm(this.existing || this.defaultForm);
  },
  watch: {
    parentIsSaving: function (value) {
      this.isSaving = value;
    },
    active(value) {
      this.isGroup =
        value === 1 || this.forceGroup === true || this.form.is_group === true;
    },
    existing(value) {
      if (value instanceof Object && value.id) {
        this.initForm(value);
      }
    },
    "group.members.length": function (value) {
      this.form.is_group = value > 1;
    },
    "form.is_group": function (value) {
      this.isGroup = value;
    },
  },
  methods: {
    initForm(value) {
      this.form = value;
      // this.form.thread_id = value.thread_id || uuid.v4();
      this.$set(
        this.form,
        "group",
        this.form.group instanceof Object
          ? this.form.group
          : { members: [this.form.to, this.form.user] }
      );
      this.form.group.title = this.form.group.title || this.form.title;
      this.isGroup = true;
      this.originalMemberIds = (this.form.group.members || []).map((m) => m.id);
    },
    toggleMember(member) {
      if (this.isGroup) {
        this.form.group.members = this.onToggleMember(
          this.form.group.members,
          member
        );
      } else {
        this.isSaving = true;
        this.isStartingChat.push(member.id);
        this.$emit("startThread", {
          id: this.form.id,
          to_id: member.id,
        });
      }
    },
    addMember(member) {
      this.$log.debug("Adding member to members");
      this.form.group.members = this.onAddMember(
        this.form.group.members,
        member
      );
    },
    removeMember(member) {
      this.$log.debug("Removing member from members");
      this.form.group.members = this.onRemoveMember(
        this.form.group.members,
        member,
        null,
        true
      );
    },
    saveMembers(members) {
      this.isSaving = true;
      const ids = this.form.group.members
        .map((m) => m.id)
        .concat(this.originalMemberIds)
        .unique();
      this.form.group.title =
        this.form.group.title || this.$timezone.formatDate();
      const slug = this.form.group.title
        ? this.form.group.title.slugify()
        : ids.join("-");
      this.$emit("startThread", {
        id: this.form.id,
        title: this.form.group.title,
        user_id: this.$user.id,
        thread_id: this.form.thread_id,
        is_private: this.form.is_private,
        group: {
          title: this.form.group.title,
          members: ids.map((id) => {
            return {
              user_id: id,
              deleted: members.indexOf(id) === -1,
            };
          }),
        },
      });
    },
    isStartingChatWith(member) {
      return this.isStartingChat.indexOf(member.id) > -1;
    },
    onHandleCreate() {
      this.$emit("startChat");
    },
    refreshMembersList() {
      this.$refs.membersForm.refreshMembers();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";
.chat-new-item-tabs {
  height: 100%;
}

.chat-new-item-tabs .v-tabs__bar {
  padding: 15px;
  background: transparent !important;
}

.chat-new-item-tabs .v-tabs__wrapper {
  height: 46px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0 6px;
}

.chat-new-item-tabs .v-tabs__item {
  height: 34px;
  background-color: transparent;
  text-transform: none;
}

.chat-new-item-tabs .v-tabs__item.v-tabs__item--active {
  height: 34px;
  border-radius: 2px;
  background-color: $white-color;
  box-shadow: 0 0 10px 0 $box-shadow-color;
}

.chat-new-item-tabs .v-tabs__items,
.chat-new-item-tabs .v-window {
  height: calc(100% - 76px);
}

.chat-new-item-tabs .v-window .v-window__container .v-window-item {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-new-item-tabs .members-search-container .v-list {
  overflow-y: auto;
}

.search-individuals.v-text-field--solo .v-input__slot {
  height: 32px;
  border-radius: 100px !important;
  background-color: #fafafa !important;
}

.search-individuals.v-text-field--solo .v-input__slot input:focus {
  color: #000 !important;
}

.search-individuals .v-input--selection-controls.v-input--checkbox {
  height: auto;
}
</style>
