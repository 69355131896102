<template>
  <div
    v-if="(editor instanceof Object)"
    tabindex="0"
    class="editor"
    @click="$emit('click')"
    @blur="$emit('blur')"
  >
    <slot name="title">
      <h2
        class="caption mb-2"
        v-if="label"
      >
        {{ label }}
      </h2>
    </slot>
    <slot
      v-if="menuPosition === 'bottom'"
      name="editor"
    >
      <editor-content
        :editor="editor"
        :class="{
          'editor__content': true,
          'editor-top': !readonly,
          'editor-top-readonly': readonly,
          'editor-error': error,
        }"
      />
    </slot>
    <component
      v-if="!readonly"
      v-slot="defaultProps"
      :is="editorType"
      :editor="editor"
      :class="{
        menububble: hasMenuBubble
      }"
    >
      <div
        :class="{
          'is-active': defaultProps.menu.isActive,
          menububble: hasMenuBubble
        }"
        :style="`left: ${defaultProps.menu.left}px; bottom: ${defaultProps.menu.bottom}px;`"
      >
        <form
          class="menububble__form"
          v-if="linkMenuIsActive"
          @submit.prevent="setLinkUrl(defaultProps.commands.link, linkUrl)"
        >
          <TextField
            class="menububble__input"
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            ref="linkInput"
            @keydown.esc="hideLinkMenu"
          >
            <template slot="append">
              <VBtn
                icon
                small
                type="button"
                class="menububble__button"
                :dark="hasMenuBubble"
                @click="setLinkUrl(defaultProps.commands.link, linkUrl)"
              >
                <VIcon
                  small
                  color="primary"
                >
                  save
                </VIcon>
              </VBtn>
              <VBtn
                icon
                small
                :dark="hasMenuBubble"
                class="menububble__button"
                @click="setLinkUrl(defaultProps.commands.link, null)"
                type="button"
              >
                <VIcon
                  small
                  color="error"
                >
                  clear
                </VIcon>
              </VBtn>
            </template>
          </TextField>
        </form>
        <VRow
          row
          wrap
          v-else
          :class="{
            menubar: !hasMenuBubble
          }"
          :style="{
            'margin-bottom': menuPosition === 'bottom' ? '-16px !important' : '0px',
          }"
        >
          <slot
            name="buttons"
            v-bind="defaultProps"
          />
          <VTooltip top>
            <template #activator="{ on, attrs }">
              <VBtn
                v-if="enableLinks"
                v-on="on"
                v-bind="attrs"
                text
                icon
                small
                :class="{
                  menubar__button: !hasMenuBubble,
                  menububble_button: hasMenuBubble,
                  'is-active': defaultProps.isActive.link(),
                  'white--text': hasMenuBubble && !defaultProps.isActive.link(),
                  'info--text': defaultProps.isActive.link()
                }"
                :color="defaultProps.isActive.link() ? 'info' : (hasMenuBubble ? 'white' : 'default')"
                @click="showLinkMenu(defaultProps.commands.link, defaultProps.getMarkAttrs('link'))"
              >
                <VIcon>link</VIcon>
              </VBtn>
            </template>
            Link
          </VTooltip>
        </VRow>
        <br>
      </div>
    </component>
    <slot
      v-if="menuPosition === 'top'"
      name="editor"
    >
      <editor-content
        :editor="editor"
        :class="{
          'editor__content': true,
          'editor-error': error,
        }"
      />
    </slot>
  </div>
</template>
<script>
import { EditorContent, EditorMenuBar, EditorMenuBubble } from "tiptap";
export default {
  props: {
    editor: {
      type: Object,
      default: () => null
    },
    editorType: {
      type: String,
      default: "editor-menu-bar"
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    enableLinks: {
      type: Boolean,
      default: true
    },
    hasMenuBubble: {
      type: Boolean,
      default: false
    },
    menuPosition: {
      type: String,
      default: "top",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditorContent,
    EditorMenuBubble,
    EditorMenuBar
  },
  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false
    };
  },
  methods: {
    showLinkMenu(command, attrs) {
      /* this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      }); */
      
      // Commented above code to fix the https://wethrive.atlassian.net/browse/PM-211
      const url = window.prompt('Enter URL (e.g. https://xyz.abc)', attrs.href);
      if(url !== null) {
        this.setLinkUrl(command, url);
      }
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url, target: "_blank" });
      this.hideLinkMenu();
    },
  }
};
</script>
<style lang="scss">
.editor {
  a {
    text-decoration: underline;
  }
  p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #a9a9a9;
    padding-left: 4px;
    pointer-events: none;
    height: 0;
  }
}
.editor-top > div {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom: none !important;
}
.editor-top-readonly > div {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.editor-top > div:focus-visible {
  outline: none;
}
.editor-top + div {
  border: 1px solid #efefef;
  border-top: none;
  padding: 8px 12px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.editor-error div {
  border: solid 1px #ea4a4a !important;
}
</style>