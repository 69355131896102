<template>
  <VMenu
    v-bind="$attrs"
    bottom
    offset-y
    nudge-bottom="8"
    transition="scale-transition"
    :disabled="disabled"
    :loading="loading"
  >
    <template #activator="{ on }">
      <VBtn
        v-on="on"
        v-bind="buttonAttrs"
        class="menu-activator"
        slot="activator"
        :color="color"
        :fab="fab"
        :loading="loading"
      >
        <slot name="text" />
        <slot name="icon">
          <VIcon
            v-if="icon"
            small
            :style="{
              lineHeight: iconLineHeight
            }"
          >
            {{ icon }}
          </VIcon>
        </slot>
      </VBtn>
    </template>
    <VList>
      <slot />
    </VList>
  </VMenu>
</template>
<script>
export default {
  props: {
    buttonAttrs: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    icon: {
      type: String,
      default: "menu",
    },
  },
  computed: {
    iconLineHeight() {
      if (this.small) {
        return "40px";
      }
      return "64px";
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";

.menu-activator {
  border-radius: $border-radius-root;
}
</style>