<template>
  <div
    v-if="hasAnyFiles"
    style="height: 100%;"
  >
    <VCarousel
      hide-delimiters
      :show-arrows="files.length > 1"
      :style="{
        overflowX: 'hidden',
        height: '310px',
        borderRadius: '6px',
        marginTop: $vuetify.breakpoint.smAndDown ? '64px' : 'inherit'
      }"
      :class="{
        'elevation-0': true,
        'px-0': $vuetify.breakpoint.mdAndUp,
        'px-0': $vuetify.breakpoint.smAndDown
      }"
    >
      <VCarouselItem
        v-for="(item, index) in files"
        class="carousel-item"
        :key="index"
        :style="{
          borderRadius: '20px'
        }"
      >
        <VHover v-slot="{ hover }">
          <VImg
            v-if="item.id"
            contain
            height="100%"
            class="rounded"
            style="border: 1px solid lightgrey;"
            :src="getMediaFromFileObject(item, `#img-${index}-${uid}`)"
          >
            <template #placeholder>
              <VRow
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <VProgress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </VRow>
            </template>
            <VFadeTransition>
              <div
                v-if="hover"
                class="d-flex align-center justify-center transition-in-out black lighten-2"
                style="height: 100%; opacity: 0.5"
              >
                <VBtn
                  icon
                  ripple
                  class="mr-2"
                  :loading="item.isDeleting"
                  @click="$emit('removeFile', index, item)"
                >
                  <VIcon color="red">
                    mdi-delete
                  </VIcon>
                </VBtn>
                <VBtn
                  icon
                  ripple
                  color="white"
                  @click.prevent="doPreviewFiles(item)"
                >
                  <VIcon>mdi-magnify-plus-outline</VIcon>
                </VBtn>
              </div>
            </VFadeTransition>
          </VImg>
          <div
            v-else
            class="d-flex justify-center"
          >
            <img
              class="rounded"
              style="border: 1px solid lightgrey; height: 100%; width: 100%;"
              :id="`img-${index}-${uid}`"
              :src="getMediaFromFileObject(item, `#img-${index}-${uid}`)"
            >
            <VFadeTransition>
              <div
                v-if="hover"
                class="d-flex align-center justify-center transition-in-out black lighten-2 rounded"
                style="position: absolute; top: 0; height: 100%; width: 100%; opacity: 0.5;"
              >
                <VBtn
                  icon
                  ripple
                  class="mr-2"
                  :loading="item.isDeleting"
                  @click="$emit('removeFile', index)"
                >
                  <VIcon color="red">
                    close
                  </VIcon>
                </VBtn>
                <VBtn
                  icon
                  ripple
                  color="white"
                  @click.prevent="doPreviewFiles(item)"
                >
                  <VIcon>mdi-magnify-plus-outline</VIcon>
                </VBtn>
              </div>
            </VFadeTransition>
          </div>
        </VHover>
      </VCarouselItem>
    </VCarousel>
    <Preview
      @toggle="onTogglePreview"
      :files="previewFiles"
    />
  </div>
</template>
<script>
import DownloadsFiles from "@/mixins/DownloadsFiles";
import Preview from "@/components/Elements/Dialogs/Preview";

export default {
  name: "ImagesList",
  mixins: [DownloadsFiles],
  components: {
    Preview,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  created() {
    this.filename = this.value;
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}
</style>
<style lang="scss">
.carousel-item .v-carousel__item {
  height: 100% !important;
}
</style>