import Vue from "vue";
import VueAuth from "@websanova/vue-auth";
import get from "sugar/object/get";

/**
 * Support global data properties property
 */
Vue.mixin({
	computed: {
		$spark() {
			return this.$user.spark || {};
		},
		billable() {
			return this.$store.state.billing.billable;
		},
		$isTeamOwner() {
			return this.$user && this.$team && this.$user.id === this.$team.owner_id;
		},
		$adminRoles() {
			return ["super-admin", "organization-admin", "owner"];
		},
		$ownerRoles() {
			return this.$isTeamOwner ? ["owner"] : [];
		},
		$studentRoles() {
			return ["student"];
		},
		$mentorRoles() {
			return ["mentor"];
		},
		$isUserAdmin() {
			return this.$adminRoles.indexOf(this.$userRoleOnTeam) !== -1;
		},
		$isUserMentor() {
			return this.$mentorRoles.indexOf(this.$userRoleOnTeam) !== -1;
		},
		$isUserStudent() {
			return this.$studentRoles.indexOf(this.$userRoleOnTeam) !== -1;
		},
		$userRoleOnTeam() {
			return this.$user && this.$user.team_user && this.$user.team_user.role
				? this.$user.team_user.role
				: -1;
		},
		$user() {
			if (this.$auth && this.$store) {
				return this.$store.state.user.data;
			} else {
				return {};
			}
		},
		$hasUser() {
			return this.$user && this.$user.id !== undefined;
		},
		$hasTeam() {
			return this.$team && this.$team.id !== undefined;
		},
		$hasTeams() {
			return this.$user && this.$user.teams && this.$user.teams.length > 0;
		},
		$hasAccessToCurrentTeam() {
			const teamUser =
				(this.$user ? this.$user.team_user : {}) ||
				(this.$team ? this.$team.pivot : {}) ||
				{};
			return teamUser.is_approved === true;
		},
		$team: {
			set(team) {
				if (team instanceof Object && team.id) {
					this.$user.team = team;
				}
			},
			get() {
				return this.$user.team || {};
			},
		},
		$isUserApproved() {
			return (
				this.$user.team_user instanceof Object &&
				this.$user.team_user.is_approved === true
			);
		},
		$teamSettings() {
			return this.$team.settings || {};
		},
		$userSettings() {
			return this.$user.settings || {};
		},
		$theme() {
			return this.$teamSettings.theme || {};
		},
		$viewProgramAsAdmin() {
			return this.$store.state.program.viewAs === "organization-admin";
		},
		$viewProgramAsMentor() {
			return this.$store.state.program.viewAs === "mentor";
		},
		$viewProgramAsStudent() {
			return this.$store.state.program.viewAs === "student";
		},
	},
	methods: {
		slugify(str) {
			if (!str) {
				return "";
			}
			str = str.replace(/^\s+|\s+$/g, ""); // trim
			str = str.toLowerCase();

			// remove accents, swap ñ for n, etc
			var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
			var to = "aaaaaaeeeeiiiioooouuuunc------";

			for (var i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
			}

			str = str
				.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
				.replace(/\s+/g, "-") // collapse whitespace and replace by -
				.replace(/-+/g, "-") // collapse dashes
				.replace(/^-+/, "") // trim - from start of text
				.replace(/-+$/, ""); // trim - from end of text

			return str;
		},
		openUrl(path, target) {
			Vue.$log.debug("Navigating to raw link", path);
			if (["_blank"].indexOf(target) > -1) {
				window.open(path, target);
			} else {
				window.location.href = path;
			}
		},
		navigateTo(params, target) {
			let path = (params || {}).path || (target || {}).path || params;
			const regex = new RegExp(
				"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
					"(\\#[-a-z\\d_]*)?$",
				"i"
			); // fragment locator
			if (
				typeof path == "string" &&
				regex.test(path) &&
				!/^[\w\-\_]+.[\w\-\_]{4,}/.test(path)
			) {
				Vue.$log.debug("Navigating to raw link", path);
				if (["_blank"].indexOf(target) > -1) {
					window.open(path, target);
				} else {
					window.location.href = path;
				}
				return;
			}
			let route =
				params instanceof Object
					? params
					: {
							name: params,
					  };
			Vue.$log.debug("Team is ", this.$team);
			route.params = {
				...route.params,
				...{
					team: this.$team ? this.$team.slug : "-",
				},
			};
			Vue.$log.debug("Navigating to", route);
			this.$router.push(route);
		},
		teamSetting(key, equals) {
			const value = get(this.$teamSettings, key);
			return equals !== undefined ? equals == value : value;
		},
		themeSetting(key, equals) {
			const value = get(
				this.$theme,
				key.indexOf(".value") === -1 ? key + ".value" : key
			);
			return equals !== undefined ? equals == value : value || "inherit";
		},
		userSetting(key, equals) {
			const value = get(this.$userSettings, key);
			return equals !== undefined ? equals == value : value;
		},
		belongsToMe(ownerId) {
			return parseInt(ownerId) === parseInt(this.$user.id);
		},
		userLocation(asString, join = " » ", removeCountry = true) {
			let currentPart = this.$user.city || {};
			let parts = [currentPart];
			let parent = currentPart.parent || {};
			while (parent !== null) {
				if (parent) {
					parts.push(parent);
					currentPart = currentPart.parent;
					parent = currentPart.parent;
				}
			}

			removeCountry && parts.pop();

			const prepareName = function(name) {
				[
					" County",
					"Town of ",
					"City of ",
					"Township of ",
					"Village of ",
					"Borough of ",
					"Village of ",
				].forEach((r) => (name = name.replace(r, "")));
				return name;
			};

			const path = function(parts) {
				return parts.length
					? [
							parts[0].name,
							parts[1].short_name,
							!removeCountry && parts.length == 3 ? parts[2].name : null,
					  ]
							.filter((n) => n !== null)
							.join(join)
					: "";
			};

			this.$log.debug("User location", parts, path(parts));

			return asString === true ? path(parts) : parts;
		},
	},
});

Vue.use(VueAuth, {
	authType: "bearer",
	auth: {
		request: function(req, token) {
			this.options.http._setHeaders.call(this, req, {
				Authorization: "Bearer " + token,
			});
			this.options.http._setHeaders.call(this, req, {
				"Content-Type": "application/json",
			});
		},
		response: function(res) {
			// Get Token from response body
			const { access_token, refresh_token } = res.data;
			const tokens = {
				access_token,
				refresh_token,
			};

			return access_token;
		},
	},
	http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),
	router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js"),
	rolesVar: "role_slug",
	authRedirect: {
		path: "/",
	},
	notFoundRedirect: {
		name: "dashboard",
	},
	forbiddenRedirect: {
		name: "dashboard",
	},
	loginData: {
		url: "oauth/token",
		fetchUser: true,
		redirect: false,
	},
	logoutData: {
		url: "oauth/token",
		redirect: "/",
	},
	registerData: {
		url: "api/signup",
		method: "POST",
	},
	refreshData: {
		url: "api/users/me",
		method: "GET",
		enabled: false,
	},
	fetchData: {
		url: "api/users/me",
		method: "GET",
		enabled: true,
	},
	parseUserData: function(data) {
		let user = data.data || {};
		if (user.id) {
			return user;
		} else {
			try {
				this.logout();
			} catch (e) {}
			window.localStorage.removeItem("access_token");
			window.localStorage.removeItem("default_auth_token");
			window.location.href = "/login";
		}
	},
});

Vue.axios.interceptors.response.use(
	(response) => {
		return response;
	},
	function(error) {
		let originalRequest = error.config;
		Vue.$log.error(error, error.response);
		if ([401].indexOf((error.response || {}).status) > -1) {
			// Bypass the refresh 401 in axios level so it won't throw error that always breaks the app's execution.
			window.localStorage.removeItem("token_refresh_try");
			if (!window.location.href.match(/login$/)) {
				// Vue.auth.options.logoutProcess.call(Vue.auth, {}, {});
				Vue.$bus.$emit("needsToLogin");
			} else {
				Vue.auth.options.logoutProcess.call(Vue.auth, {}, {});
				Vue.$store.dispatch("auth/logout", null, {
					root: true,
				});
			}
		}
		return Promise.reject(error);
	}
);

export default {};
