<template>
  <VRow
    v-if="modelHasId"
    wrap
  >
    <VCol
      v-if="mode === 'standalone'"
      style="flex: 0 0 64px"
      cold="12"
    >
      <ViewHeader :route-params="{id: model.id, pipeline: model.stage.pipeline_id, stage: model.stage.pipeline_stage_id}">
        <template #title>
          Submitted Application for {{ featureName('Pipeline') }}
          {{ featureName('Stage') }}
        </template>
        <template #extra-buttons>
          <Linkable
            v-if="!$isUserStudent"
            icon="visibility"
            :tooltip="`View ${featureName('Pipeline').singularize()}`"
            :copyable="false"
            :icon-props="{small: true}"
            :url="getFullyQualifiedUrlForItem(`pipeline.view`, { pipeline: model.stage.pipeline_id})"
            :to="getRouteForItem(`pipeline.view`, {pipeline: model.stage.pipeline_id})"
          />
          <Linkable
            v-if="!$isUserStudent"
            icon="visibility"
            :tooltip="`View ${featureName('Stage').singularize()}`"
            :copyable="false"
            :icon-props="{small: true}"
            :url="getFullyQualifiedUrlForItem(`pipeline-stage.view`, {id: model.stage.id, pipeline: model.stage.pipeline_id})"
            :to="getRouteForItem(`pipeline.view`, {id: model.stage.id, pipeline: model.stage.pipeline_id})"
          />
        </template>
      </ViewHeader>
    </VCol>
    <VCol
      style="flex: 1 1 auto"
      class="pb-0"
      cols="12"
    >
      <VRow
        row
        wrap
      >
        <VCol cols="12">
          <VCard>
            <VCardText class="pb-0">
              <h1
                v-if="mode !== 'embedded'"
                class="mb-3"
              >
                {{ resolvedPipelineStage.title || 'ERROR: No title for this stage' }}
                <VChip
                  :color="resolvedPipelineStage.is_expired ? 'light-grey' : 'success'"
                  :text-color="resolvedPipelineStage.is_expired ? 'light-grey' : 'white'"
                >
                  {{ resolvedPipelineStage.is_expired ? 'Expired' : 'Active' }}
                </VChip>
              </h1>
              <VRow
                row
                wrap
                class="pl-3 align-center"
              >
                <UserChip
                  class="mt-3"
                  :user="model.user"
                />
                <Tooltip
                  :as-button="false"
                  :color="model.is_expired && (!model.is_complete || !model.is_submitted) ? 'warning' : ''"
                  :text-color="model.is_expired && (!model.is_complete || !model.is_submitted)? 'white' : ''"
                  :icon-color="model.is_complete ? 'success' : 'info'"
                  :icon="getApplicationStatusIcon(model)"
                  :title="model.status_message"
                  :button-text="model.status.titleize()"
                />
              </VRow>
            </VCardText>
            <VCardText class="px-0 pb-0">
              <p
                class="formatted"
                v-html="resolvedPipeline.content"
              />
              <DynamicInputsView
                v-if="model.metadata instanceof Array && model.metadata.length"
                :card="false"
                :inputs="model.metadata"
                :input-name="featureName('Metadata')"
                class="px-2 py-2 grey lighten-3"
              />
              <VAlert
                v-else
                type="info"
                :value="true"
              >
                No entries set for this stage
              </VAlert>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
  <VRow
    v-else
    row
    fluid
    justify-center
    class="mt-4"
  >
    <VProgressCircular
      indeterminate
      color="primary"
    />
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";
import SupportsPipelines from "@/mixins/SupportsPipelines";

export default {
  name: "ViewSubmittedStage",
  components: { DynamicInputsView, ViewHeader },
  mixins: [
    ViewMixin,
    HasCalendarEntries,
    HasApplicationPipelinesMixin,
    SupportsPipelines,
  ],
  data() {
    return {
      modelType: "Pipeline Stage Submitted",
    };
  },
  created() {
    if (this.existing && this.existing.id) {
      this.model = this.existing;
    }
  },
  computed: {
    application() {
      return this.model.pipeline_submitted || {};
    },
    applicationPipeline() {
      return this.application.pipeline || {};
    },
    applicationStage() {
      return this.model.stage || {};
    },
  },
  methods: {
    ...mapActions({
      doGetPipelineStageSubmitted: "pipelineStageSubmitted/getOne",
    }),
    onGet(id) {
      const params = id instanceof Object ? id : { id };
      params.pipeline_id =
        params.pipeline_id || params.pipelineId || this.resolvedPipelineId;
      params.pipeline_stage_id =
        params.pipeline_stage_id ||
        params.pipelineStageId ||
        this.resolvedPipelineStageId;
      return this.doGetPipelineStageSubmitted(params);
    },
  },
};
</script>
<style lang="scss">
.view-submitted {
  .view-submitted-panels {
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
