<template>
  <VContainer>
    <VRow
      id="user-account"
      row
      pt-4
      justify-center
    >
      <VCol cols="12">
        <VTabs
          grow
          slider-color="primary"
          :show-arrows="true"
        >
          <VTab
            v-for="(tab, index) in tabs"
            :to="{
              hash: tab.url
            }"
            :key="index"
          >
            {{ tab.title }}&nbsp;
            <BetaBadge
              enable-tooltip
              :is-beta="tab.beta"
            />
          </VTab>
        </VTabs>
        <VTabsItems
          touchless
          :value="activeUrlTab"
        >
          <VTabItem class="py-6">
            <Information />
          </VTabItem>
          <VTabItem class="py-6">
            <NotificationPreferences />
          </VTabItem>
          <VTabItem class="py-4">
            <Accounts />
          </VTabItem>
          <VTabItem class="py-4">
            <PipelineApplications />
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Information from "./Information";
import Accounts from "./Accounts";
import NotificationPreferences from "./NotificationPreferences";
import PipelineApplications from "./PipelineApplications";
import HasTabsMixin from "@/mixins/HasTabs";

export default {
  name: "UserAccount",
  mixins: [HasTabsMixin],
  components: {
    Information,
    Accounts,
    PipelineApplications,
    NotificationPreferences,
  },
  data() {
    return {
      tabs: [
        {
          title: "Information",
          url: "",
          enabled: true,
        },
        {
          title: "Notification Preferences",
          url: "#notification-preferences",
          enabled: true,
        },
        {
          title: "Connected Accounts",
          url: "#accounts",
          enabled: true,
        },
        {
          title: "Applications",
          url: "#applications",
          enabled: true,
        },
      ].filter(tab => tab.enabled),
    };
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.details-card {
  border-radius: 6px;
  color: $font-light-color;
  background-color: $white-color;
  box-shadow: 0 0 10px 0 $box-shadow-color;
  padding: 0 20px;

  .v-subheader {
    padding: 0;
  }

  hr {
    height: 1px;
    opacity: 0.5;
    background-color: $border-color;
  }

  .details-content .div {
    padding: 15px 0;

    .v-input {
      color: $content-title-color;
    }

    .v-label::after {
      color: $font-light-color;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.username-header {
  padding: 0.5rem 1rem;
}

#user-profile .read-only-label {
  color: #a4a6a9;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
}

#user-profile .read-only-value {
  height: 14px;
  color: #404041;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.application .theme--light.v-btn,
.theme--light .v-btn,
.userprofile-save-button {
  border-radius: 24px;
  background-color: $font-light-color;
  color: $white-color;
  text-transform: none;
}
#user-profile .v-text-field input#name-input {
  color: #333636;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}
</style>