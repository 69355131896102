<template>
  <router-view />
</template>

<script>
export default {
  name: "Programs",
  data() {
    return {
      msg: "Programs"
    };
  }
};
</script>

<style scoped>
</style>