<template>
  <router-view />
</template>

<script>
export default {
  name: "ChatPage",
  data() {
    return {};
  }
};
</script>

<style scoped>
.chat-index {
  overflow: hidden;
  height: calc(100vh - 100px);
}
</style>