import BaseApi from "./_base-api";

/**
 * Automation API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Automation",
	path: "automations"
});

export default api;
