<template>
  <div>
    <template v-if="inputs.length > 0">
      <VCard
        v-for="(group, groupName) in allInputsGrouped"
        :key="groupName"
        class="py-2 my-2 title-border-none"
      >
        <VCardTitle
          v-if="groupName && ['null', 'undefined', null, undefined].indexOf((groupName).toLowerCase()) === -1"
          class="title"
        >
          {{ groupName }}
        </VCardTitle>
        <VCardSubtitle 
          v-if="hasGroupDescription(groupName)" 
          class="px-4"
        >
          <p v-html="groupDescription(groupName)" />
        </VCardSubtitle>
        <VDivider v-if="groupName && ['null', 'undefined', null, undefined].indexOf((groupName).toLowerCase()) === -1" />
        <VCardText
          v-for="(input,key) in group"
          :key="key"
          class="px-4 py-2 my-2"
        >
          <h5 class="title">
            {{ input.name }}
            <span
              v-show="input.is_required"
              style="color: red"
              label
            >*</span>
          </h5>
          <span
            class="caption text-left d-block"
            v-html="getInputCaption(input)"
          />
          <DynamicInput
            readonly
            :input="input"
          />
        </VCardText>
      </VCard>
    </template>
  </div>
</template>

<script>
import DynamicInputsMixin from "@/mixins/DynamicInputs";
import MetadataMixin from "@/mixins/Metadata";
import DynamicInput from "./DynamicInput";
export default {
  name: "DynamicInputsView",
  mixins: [DynamicInputsMixin, MetadataMixin],
  components: { DynamicInput },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    inputsHaveUploads: {
      type: Boolean,
      default: false,
    },
    metadataProp: {
      type: String,
      default: "metadata",
    },
    inputs: {
      type: Array,
      default: () => [],
    },
    inputName: {
      type: String,
      default: "",
    },
    choiceMode: {
      type: String,
      default: "",
    },
    inputChoices: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    card: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: "content-bg dynamic-input-container",
    },
  },
  data() {
    return {};
  },
  computed: {
    choices() {
      return this.choiceMode == "user"
        ? this.inputChoices
        : Object.assign({}, this.localChoices, this.inputChoices);
    },
    allInputs() {
      if (!(this.inputs instanceof Array) && this.inputs instanceof Object) {
        return [this.inputs];
      }
      return this.inputs
        .filter((input) => input instanceof Object)
        .map((input, index) => {
          input.realIndex = index;
          return input;
        })
        .sortBy("realIndex");
    },
  },
  methods: {
    isFile(input) {
      return ["File"].indexOf(input.type) > -1;
    },
    isGoogleFile(input) {
      return ["Google Drive"].indexOf(input.type) > -1;
    },
    isVideoFile(input) {
      if (this.isFile(input)) {
        const value = input.value instanceof Object ? input.value : {};
        const type = value.type || input.simple_type || input.type || "";
        return type.toLowerCase() === "video" || /^video/.test(type);
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/variables.scss";

:root {
  .dynamic-input-add.layout.row {
    margin-top: 15px !important;
  }

  .remove-dynamic-input .v-btn__content {
    text-transform: none;
    color: $font-light-color;
  }

  .remove-dynamic-input:hover .v-btn__content {
    color: #ec4a4a;
  }

  .dynamic-input-container .v-input__append-outer::before {
    content: "";
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 30%;
    background-color: #eaeaea;
  }
  .caption {
    a {
      text-decoration: underline;
    }
  }

  .video-container {
    object-fit: fill;
    max-height: 300px;
    text-align: center;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  video {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>