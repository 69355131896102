export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_RECOVER = "AUTH_RECOVER";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_CSRF = "AUTH_CSRF";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REMEMBER = "AUTH_REMEMBER";
export const AUTH_VERIFY = "AUTH_VERIFY";
export const AUTH_TEAM = 'AUTH_TEAM';
export const AUTH_GET_TEAM = 'AUTH_GET_TEAM';
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const TEAM_CREATE = "TEAM_CREATE";
export const TEAM_UPDATE = "TEAM_CREATE";
export const TEAM_ERROR = "TEAM_ERROR";
export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_REQUEST = "TEAM_REQUEST";
export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_FIXTURE = "USERS_FIXTURE";
export const USER_GET_PROFILE = "USER_GET_PROFILE";
export const CHAT_THREADS_REQUEST = "CHAT_THREADS_REQUEST";
export const CHAT_THREADS_GET_ALL = "CHAT_THREADS_GET_ALL";
export const CHAT_THREADS_GROUP_GET_ALL = "CHAT_THREADS_GROUP_GET_ALL";
export const CHAT_THREADS_ERROR = "CHAT_THREADS_ERROR";
export const CHAT_THREAD_REQUEST = "CHAT_THREAD_REQUEST";
export const CHAT_THREAD_SUCCESS = "CHAT_THREAD_SUCCESS";
export const CHAT_THREAD_ERROR = "CHAT_THREAD_ERROR";
export const CHAT_MESSAGES_REQUEST = "CHAT_MESSAGES_REQUEST";
export const CHAT_MESSAGES_SUCCESS = "CHAT_MESSAGES_SUCCESS";
export const CHAT_MESSAGES_ERROR = "CHAT_MESSAGES_ERROR";
export const CHAT_MESSAGE_REQUEST = "CHAT_MESSAGE_REQUEST";
export const CHAT_MESSAGE_SUCCESS = "CHAT_MESSAGE_SUCCESS";
export const CHAT_MESSAGE_ERROR = "CHAT_MESSAGE_ERROR";
export const CHAT_SAVE_MESSAGE_REQUEST = "CHAT_SAVE_MESSAGE_REQUEST";
export const CHAT_SAVE_MESSAGE_SUCCESS = "CHAT_SAVE_MESSAGE_SUCCESS";
export const CHAT_SAVE_MESSAGE_ERROR = "CHAT_SAVE_MESSAGE_ERROR";
export const CHAT_SAVE_THREAD_REQUEST = "CHAT_SAVE_THREAD_REQUEST";
export const CHAT_SAVE_THREAD_SUCCESS = "CHAT_SAVE_THREAD_SUCCESS";
export const CHAT_SAVE_THREAD_ERROR = "CHAT_SAVE_THREAD_ERROR";