<script>
import ExtendFrom from "vuetify/lib/components/VTextField/VTextField";
import TextFieldInputMixin from "@/mixins/TextFieldInput.vue";

export default {
  extends: ExtendFrom,
  mixins: [TextFieldInputMixin],
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>