<template>
  <div>
    <template v-if="!enableColorPicker">
      <VChip
        v-if="emoji || value"
        outlined
        style="font-size: 30px; padding: 20px 0px; border-radius: 10px;"
        @click="showEmojiPicker = !showEmojiPicker"
      >
        {{ emoji || value }}
      </VChip>
      <VBtn
        v-else
        height="32px"
        style="border: dashed 1.5px #bfbfbf; border-radius: 10px; box-shadow: none;"
        @click="showEmojiPicker = !showEmojiPicker"
      >
        <span style="color: #808080; font-size: 12px;">Add Emoji</span>
        <VIcon
          right
          color="#757575"
          size="18px"
        >
          mdi-plus-circle
        </VIcon>
      </VBtn>
    </template>
    <Dialog
      max-width="375px"
      :dismissable="false"
      :persistent="false"
      :is-dialog-open="showEmojiPicker"
      @toggle:dialog="togglePicker"
    >
      <VRow v-if="enableColorPicker">
        <VBtn
          icon
          style="position: absolute; right: 0;"
          @click.native="togglePicker"
        >
          <VIcon color="error">
            close
          </VIcon>
        </VBtn>
        <VCol
          cols="12"
          class="pt-6 pb-0"
        >
          <span>Background Color:</span>
          <VSpacer />
          <Swatches
            v-model="backgroundColor"
            show-fallback
            swatches="text-advanced"
            class="mt-2 rounded"
            :trigger-style="{ 
              height: '36px', 
              width: '355px',
              border: '1px solid lightgrey',
            }"
            @input="$emit('change', emoji, backgroundColor)"
          />
        </VCol>
      </VRow>
      <Picker
        set="google"
        class="my-3"
        :data="emojiIndex"
        @select="addEmoji"
      />
    </Dialog>
  </div>
</template>
<script>
import Swatches from "vue-swatches";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import data from "emoji-mart-vue-fast/data/all.json";
let emojiIndex = new EmojiIndex(data);

export default {
  name: "EmojiPicker",
  props: {
    value: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    enableColorPicker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Picker,
    Swatches,
  },
  data() {
    return {
      emoji: null,
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      backgroundColor: this.color,
    }
  },
  methods: {
    addEmoji(emoji) {
      this.emoji = emoji.native;
      this.togglePicker();
      this.$emit("change", this.emoji, this.backgroundColor);
    },
    togglePicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
  },
};
</script>
