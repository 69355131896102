<template>
  <VTooltip
    center
    :disabled="!active"
    :bottom="bottom"
    :top="top"
  >
    <template #activator="{on}">
      <VBtn
        :block="block"
        :class="{
          'white--btn main-content-team-menu': true,
          [buttonClasses]: true
        }"
        :large="$vuetify.breakpoint.mdAndUp"
        :small="$vuetify.breakpoint.smAndDown"
        :style="{
          border: realInvitationsCount > 0
            ? 'solid 2px '+$vuetify.theme.themes.light.primary
            : 'none'
        }"
        @click.stop="$emit('click')"
        v-on="on"
      >
        <img
          v-if="$hasTeams && $team.photo_url"
          :style="{
            height: imageHeight
          }"
          :src="$team.photo_url"
        >
        <span
          class="px-2 layout column align-start"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <!-- <strong
              class="flex text-truncate"
              style="max-width: 150px"
            >{{ $team.name ? $team.name : 'Select Team' }}</strong> -->
          <!-- <small
              :class="{
                'flex text-truncate': true,
                'info--text': $team.id && $user.team_user.is_approved,
                'disabled--text': !$team.id || ($team.id && !$user.team_user.is_approved)
              }"
              style="font-size: 60%; max-width: 128px"
              v-if="$team.id"
            >{{ $user.team_user.is_approved ? $user.role_name : 'Pending' }}</small> -->
        </span>&nbsp;
        <VIcon>{{ isOpen ? 'expand_less' : 'expand_more' }}</VIcon>
        <span
          v-html="realInvitationsCount"
          v-if="realInvitationsCount > 0"
          class="d-inline-flex align-center justify-center"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.primary,
            position: 'absolute',
            color: '#fff',
            fontSize: large ? '12px' : '10px',
            fontWeight: '500',
            top: large ? '-24px' : '-8px',
            right: large ? '-16px' : '-10px',
            width: large ? '30px' : '20px',
            height: large ? '30px' : '20px',
            borderRadius: '5px',
            boxShadow: '0px 5px 14.3px 0.8px rgba(255, 43, 122, 0.3)'
          }"
        />
      </VBtn>
    </template>
    <span>You have new invitations!</span>
  </VTooltip>
</template>
<script>
export default {
  props: {
    imageHeight: {
      type: [String, Number],
      default: "32px"
    },
    large: {
      type: Boolean,
      default: false
    },
    buttonClasses: {
      type: String,
      default: ""
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    invitationsCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    active: {
      get() {
        return this.realInvitationsCount > 0;
      },
      set() {}
    },
    realInvitationsCount() {
      return this.invitationsCount || this.$user.invitations_count || 0;
    }
  }
};
</script>