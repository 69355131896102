<template>
  <VCard
    v-if="filters.length"
    class="filter-options fill-height stretch"
    :class="{
      'elevation-1': elevated,
      'no-border-radius': noBorder
    }"
  >
    <VCardTitle
      v-if="fixed"
      class="flex px-0 pt-4"
      style="height: 64px"
    >
      <VSpacer />
      <VBtn
        text
        icon
        @click="$emit('toggle')"
      >
        <VIcon>close</VIcon>
      </VBtn>
    </VCardTitle>
    <VCardText
      class="pb-2 px-0"
      :style="{
        overflow: 'hidden auto',
        height: !fixed ? 'calc(100% - 56px)' : 'calc(100% - 116px)'
      }"
    >
      <VRow
        row
        wrap
      >
        <VCol cols="12">
          <VSwitch
            v-model="form.filter.strict"
            inset
            label="Use strict matching?"
            class="mx-3"
            :color="value.filter.strict ? 'error' : 'info'"
          />
          <VAlert
            class="mx-2"
            :value="true"
            :type="value.filter.strict ? 'warning' : 'info'"
          >
            Results should match
            <strong>{{ form.filter.strict ? 'ALL' : 'ANY' }}</strong> of the following.
          </VAlert>
        </VCol>
        <template v-for="(filter,key) in filters">
          <VCol
            cols="12"
            :key="`filter-${key}`"
          >
            <VSubheader :style="headerStyle">
              {{ filter.label }}
            </VSubheader>
            <VList
              v-if="filter.type === 'array' || filter.type === 'list'"
              one-line
              class="px-0"
              style="max-height: 250px; overflow-y: auto; overflow-x: hidden; box-shadow: inset 1px 4px 6px #ddd; border-radius: 0px"
            >
              <VListItem @click="onToggleAll(filter.field, filter.options)">
                <VListItemContent>
                  <VListItemTitle>
                    Show All
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <VIcon
                    v-if="!form.filter[filter.field] || form.filter[filter.field] instanceof Array && form.filter[filter.field].length === 0"
                    small
                    color="primary"
                  >
                    fiber_manual_record
                  </VIcon>
                </VListItemAction>
              </VListItem>
              <VListItem
                v-for="(option,index) in filter.options"
                :key="`list-option-${index}`"
                @click="onToggleSelected(filter.field, index, option instanceof Object ? option.id : option)"
              >
                <VListItemContent>
                  <VListItemTitle>
                    {{ getOptionLabel(option) }}
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <VIcon
                    v-if="getIsOptionValueSelected(form.filter[filter.field], option instanceof Object ? option.id : option)"
                    small
                    color="primary"
                  >
                    fiber_manual_record
                  </VIcon>
                </VListItemAction>
              </VListItem>
            </VList>
            <!-- <Autocomplete
              v-else-if="filter.type === 'array'"
              v-model="form.filter[filter.field]"
              chips
              deletable-chips
              multiple
              clearable
              :item-value="filter.options[0] instanceof Object ? filter.itemValue || filter.item_value || 'id' : null"
              :item-text="filter.options[0] instanceof Object ? filter.itemText || filter.item_text || 'title' : null"
              :label="filter.label"
              :placeholder="filter.placeholder"
              :persistent-hint="filter.hint != null"
              :hint="filter.hint"
              :items="filter.options"
            /> -->
            <template v-else-if="filter.type === 'checkbox'">
              <h3
                class="subtitle v-subheader"
                :style="headerStyle"
              >
                {{ filter.label }}
              </h3>
              <VRow
                row
                wrap
                class="mx-2"
              >
                <VCol>
                  <VCheckbox
                    v-for="(option,index) in filter.options"
                    v-model="form.filter[filter.field]"
                    :key="`option-${filter.field}-${index}`"
                    :label="getOptionLabel(option)"
                    :value="option instanceof Object ? option.value || option.id : option"
                    :placeholder="filter.placeholder"
                    :persistent-hint="filter.hint != null"
                    :hint="filter.hint"
                  />
                </VCol>
              </VRow>
            </template>
            <template v-else-if="filter.type === 'boolean'">
              <h3
                class="subtitle v-subheader"
                :style="headerStyle"
              >
                {{ filter.label }}
              </h3>
              <VRow
                row
                wrap
                class="mx-2"
              >
                <VCol>
                  <VSwitch
                    v-model="form.filter[filter.field]"
                    inset
                    :key="`option-${filter.field}-${Date.now()}`"
                    :label="filter.label"
                    :placeholder="filter.placeholder"
                    :persistent-hint="filter.hint != null"
                    :hint="filter.hint"
                  >
                    <VChip
                      slot="append"
                      close
                      icon
                      small
                      style="margin-top: 0px"
                      @click="form.filter[filter.field] = null"
                    >
                      Clear
                    </VChip>
                  </VSwitch>
                </VCol>
              </VRow>
            </template>
          </VCol>
        </template>
      </VRow>
    </VCardText>
    <VCardActions class="px-2 d-flex justify-space-between">
      <VBtn
        v-if="!fixed"
        @click="$emit('toggle')"
      >
        Close
      </VBtn>
      <VBtn
        color="info"
        :block="fixed"
        @click="filterAndClose"
      >
        Filter
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    elevated: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        filter: {},
      },
    };
  },
  computed: {
    headerStyle() {
      return {
        borderBottom: `solid 2px #ddd`,
        borderTop: `solid 2px #eee`,
      };
    },
  },
  created() {
    this.form = { ...this.form, ...this.value };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.form.filter.strict = this.$set(
          this.form.filter,
          "strict",
          this.form.filter.strict
        );
        this.filters.map(
          (filter) =>
            (this.form.filter[filter.field] = (this.value || {})[filter.field])
        );
      },
    },
    form: {
      deep: true,
      handler() {
        this.$emit("input", this.form);
      },
    },
  },
  methods: {
    filterAndClose() {
      this.$emit("input", this.form);
      this.$emit("filter");
      this.$emit("toggle", true);
    },
    getOptionLabel(option) {
      return option instanceof Object
        ? option.title || option.text || option.name
        : option;
    },
    getIsOptionValueSelected(filterOptions, value) {
      return (
        (filterOptions instanceof Array && filterOptions.indexOf(value) > -1) ||
        filterOptions == value
      );
    },
    onToggleAll(filterName, options) {
      const filterOptions = this.form.filter[filterName] || [];
      this.$set(
        this.form.filter,
        filterName,
        filterOptions.length == 0
          ? options.map((o) => (o instanceof Object ? o.id : o))
          : []
      );
    },
    onToggleSelected(filterName, index, value) {
      this.$set(
        this.form.filter,
        filterName,
        this.form.filter[filterName] || []
      );
      if (value instanceof Boolean || value == undefined) {
        this.$set(
          this.form.filter[filterName],
          index,
          !this.form.filter[filterName][index]
        );
      } else {
        if (this.form.filter[filterName].indexOf(value) > -1) {
          this.form.filter[filterName].splice(index, 1);
        } else {
          this.$set(this.form.filter[filterName], index, value);
        }
      }
    },
  },
};
</script>