<template>
  <VListItem
    v-if="thread"
    :key="thread.thread_id"
    :class="{
      active: currentThread && currentThread.id === thread.id,
      thread: true,
      group: isGroupThread
    }"
    @click.prevent="onLoadMessages"
  >
    <Badge
      overlap
      top
      left
      offset-x="26"
      color="secondary"
      :value="thread.new_messages_for_user_count > 0"
      :content="thread.new_messages_for_user_count || 0"
    >
      <VListItemAvatar
        width="48"
        height="48"
        color="#fff"
        class="justify-center"
      >
        <img
          :src="avatar"
          :style="{
            width: !thread.is_group && thread.photo_url ? '100%' : '20px !important'
          }"
        >
        <span
          v-if="thread.new_messages_for_user_count"
          slot="badge"
          style="color: white"
        >{{ newCount }}</span>
      </VListItemAvatar>
    </Badge>
    <VListItemContent>
      <VListItemTitle class="d-inline-flex align-center">
        <VCol
          grow
          class="pa-0 text-truncate"
          :title="title"
        >
          {{ shortTitle }}
        </VCol>
        <VCol
          shrink
          class="pa-0"
          style="font-size: 10px; color: rgba(0,0,0, 0.65)"
        >
          {{ thread.date }}
        </VCol>
      </VListItemTitle>
      <VListItemSubtitle v-html="message" />
      <VListItemSubtitle class="date">
        <template v-if="message && message.length">
          <small>
            <b>Last message: {{ date }}</b>
          </small>
        </template>
      </VListItemSubtitle>
      <template v-if="isGroupThread && hasThreadMember">
        <Members :members="threadMembers" />
      </template>
      <VRow
        v-if="!hasThreadMember"
        class="mx-0"
      >
        <VCol class="text-right pa-0">
          <VTooltip top>
            <template #activator="{on}">
              <VBtn
                v-if="!hasThreadMember"
                v-on="on"
                icon
                :loading="thread.isLoading"
                @click.stop="joinThread"
              >
                <VIcon color="success">
                  mdi-chat-plus
                </VIcon>
              </VBtn>
            </template>
            Join this Chat
          </VTooltip>
        </VCol>
      </VRow>
    </VListItemContent>
    <VListItemAction v-if="mode !== 'load-messages'">
      <VCheckbox
        :multiple="mode === 'select-multiple'"
        color="primary"
        style="height: auto"
        v-model="selected"
        @change="$emit('selectThread', thread)"
      />
    </VListItemAction>
    <Confirm
      ref="confirmChatJoin"
      confirm-color="info"
      cancel-color="disabled"
    />
  </VListItem>
</template>

<script>
import { mapActions } from "vuex";
import globeIcon from "../../images/globe-icon@3x.png";
import Members from "./Members";

export default {
  components: {
    Members,
  },
  props: {
    currentThread: {
      type: Object,
      default: () => {},
    },
    thread: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "load-messages",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globeIcon,
      selected: false,
    };
  },
  created() {
    this.selected = this.isSelected;
  },
  watch: {
    isSelected: function (value) {
      this.selected = value;
    },
  },
  computed: {
    itemClass: function () {
      let itemClass = "dialog dialog--";
      if (this.currentThread) {
        let isCurrent = this.thread.id == this.currentThread.id;
        return itemClass + (isCurrent ? "not-read" : "read");
      } else {
        return itemClass + "read";
      }
    },
    displayName: function () {
      if (this.thread.to_user instanceof Object) {
        let name = this.thread.to_user.name;
        let lastName = this.thread.to_user.surname
          ? this.thread.to_user.surname.substr(0, 1) + "."
          : "";
        return name + " " + lastName;
      }
      return this.thread.title;
    },
    avatar: function () {
      if (this.thread.is_group) {
        return this.globeIcon;
      } else {
        return this.thread.photo_url;
      }
    },
    isGroupThread() {
      return this.thread.is_group === true;
    },
    title: function () {
      return this.isGroupThread ? this.thread.title : this.displayName;
    },
    shortTitle: function () {
      return this.isGroupThread ? this.thread.short_title : this.displayName;
    },
    date: function () {
      try {
        return this.thread.last_message.date;
      } catch (e) {
        return this.thread.date;
      }
    },
    message: function () {
      try {
        let message = this.thread.last_message;
        if (message && message.message) {
          const text =
            message.message.length > 140
              ? message.message
              : message.message.substr(0, 140) + "...";
          return text + " - " + message.user.name;
        }
      } catch (e) {
        this.$log.warn(e);
        return "";
      }
    },
    newCount: function () {
      let count =
        this.thread && this.thread.new_messages_for_user_count
          ? this.thread.new_messages_for_user_count
          : 0;
      return count > 9 ? "9+" : parseInt(count);
    },
    hasMembers() {
      return (
        this.thread.group instanceof Object &&
        this.thread.group.members_count > 1
      );
    },
    threadMembers() {
      return this.hasMembers ? this.thread.group.members : [];
    },
    hasThreadMember() {
      if(!this.$isUserAdmin && !this.thread.is_private) {
        let memberIndex = this.thread.group.members.findIndex((member) => member.id === this.$user.id);
        return memberIndex > -1;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      doSaveGroupThread: "chat/saveGroupThread",
    }),
    onLoadMessages() {
      if (this.mode === "load-messages" && this.hasThreadMember) {
        // If we're not on the chat route then navigate to the chat page
        if (this.$route.name == "chat.index") {
          this.$log.debug("Loading...[" + this.thread.title + "]");
          this.$emit("loadMessages", this.thread);
        } else {
          this.$log.debug(
            "Chat: Navigating to chat index because we're on",
            this.$route.name
          );
        }
        this.$router.push({
          name: "chat.index",
          params: {
            id: this.thread.thread_id,
          },
        });
      }
    },
    joinThread() {
      this.$refs
        .confirmChatJoin
        .open("Join Chat", `Are you sure you want to join ${this.thread.title}?`, {
          color: "info",
        })
        .then((status) => {
          if(status) {
            this.$set(this.thread, "isLoading", true);
            this.thread.group.members.push(this.$user);
            this.doSaveGroupThread(this.thread).then(() => {
              this.$delete(this.thread, "isLoading");
              this.$bus.$emit(
                "notificationMessage",
                "Joined chat successfully"
              );
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.counter.badge {
  left: 0;
  float: left;
}

.dialog_author-photo {
  position: relative;
}

.chat-threads-list .member-container {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>

<style lang="scss">
.thread .v-avatar img {
  width: 100% !important;
  height: auto;
}

.v-list .active .v-list__tile--link {
  background: rgba(0, 0, 0, 0.04);
}

.v-list.v-list--two-line .thread .v-list__tile {
  height: 96px;
}

.v-list.v-list--two-line .thread.group .v-list__tile {
  height: 112px;
}

.chat-threads-list .member-container .v-chip__content {
  flex: 0 0 auto;
}
</style>