var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"px-4",staticStyle:{"min-height":"315px"},attrs:{"column":""}},[_c('VCol',{attrs:{"justify-content-center":"","align-items-center":"","cols":"12"}},[_c('TextField',{staticClass:"textarea-field",attrs:{"filled":"","large":"","placeholder":"(Optional) Enter the result title","disabled":_vm.form.isSaving},on:{"blur":_vm.backgroundSaveCard,"input":_vm.backgroundSaveCard},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._l((_vm.resultTypes),function(type,index){return _c('VBtn',{key:index,staticClass:"mr-2 mb-2",attrs:{"small":"","color":"info","disabled":_vm.form.isSaving},on:{"click":function($event){return _vm.addResultItem(type.value)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" add ")]),_c('VSpacer'),_vm._v(" "+_vm._s(type.text)+" ")],1)}),(_vm.allInputs && _vm.allInputs.length > 0)?_c('Draggable',{attrs:{"element":"div","id":"cards-container","disabled":_vm.form.isSaving,"options":_vm.draggableOptions,"no-transition-on-drag":true},model:{value:(_vm.allInputs),callback:function ($$v) {_vm.allInputs=$$v},expression:"allInputs"}},[_c('transition-group',{staticClass:"layout row wrap justify-center",attrs:{"name":"dynamic-input-container","css":true,"tag":"div"}},_vm._l((_vm.allInputs),function(part,index){return _c('VCol',{key:`dynamic-${index}`,class:{
            round: true,
            [part.size ? part.size : 'auto']: true,
            'd-flex': true,
            'my-0 px-3 py-3': true,
            'align-items': 'stretch'
          },attrs:{"cols":_vm.allInputs.length % 2 === 0 || (_vm.allInputs.length - 1 > index) ? 6 : 12}},[_c('div',{staticClass:"pl-2 pr-2 fluid",style:({
              'background-color': part.type === 'Text' ? '#f8f8f8' : '#ffeca9',
              width: '100%',
              'border-radius': '4px'
            })},[_c('VRow',[_c('VCol',{staticClass:"d-flex"},[_c('VBtn',{staticClass:"rounded dynamic-input-handle mr-2",style:({
                    border: part.type === 'Text' ? 'solid 0.5px #eaeaea' : 'solid 0.5px #ecd893',
                    cursor: 'move'
                  }),attrs:{"small":"","icon":"","color":"transeparent"}},[_c('VIcon',[_vm._v("drag_handle")])],1),_c('VMenu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('VBtn',_vm._g({staticClass:"px-0",attrs:{"small":"","color":"info"}},on),[_vm._v(" "+_vm._s(_vm.getSizeTitle(part.size))+" ")])]}}],null,true),model:{value:(part.menu),callback:function ($$v) {_vm.$set(part, "menu", $$v)},expression:"part.menu"}},[_c('VCard',[_c('VList',_vm._l((_vm.sizes),function(size){return _c('VListItem',{key:size.size,on:{"click":function($event){part.size=size.size}}},[_vm._v(" "+_vm._s(size.title)+" ")])}),1)],1)],1),_c('VSpacer'),_c('VBtn',{staticStyle:{"opacity":"0.5"},attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('VIcon',[_vm._v("cancel")])],1)],1)],1),(part.type === 'Text')?[_c('TextField',{staticClass:"result-inputs",attrs:{"large":"","placeholder":"Enter your text","disabled":_vm.form.isSaving,"outlined":false},on:{"blur":_vm.backgroundSaveCard,"input":_vm.backgroundSaveCard},model:{value:(part.value),callback:function ($$v) {_vm.$set(part, "value", $$v)},expression:"part.value"}})]:[_c('Autocomplete',{staticClass:"result-autocomplete",attrs:{"large":"","placeholder":"Select variable","item-text":"text","item-value":"value","label":part.description,"items":_vm.allVariables,"disabled":_vm.form.isSaving,"outlined":false},on:{"input":value => _vm.setPartVariable(index, value)},model:{value:(part.value),callback:function ($$v) {_vm.$set(part, "value", $$v)},expression:"part.value"}})]],2)])}),1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }