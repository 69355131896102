/**
 * Custom exceptions
 */
class IgnorableException extends Error {
    constructor(message) {
        super(message);
        this.message = message;
        this.name = "IgnorableException";
    }
}

export {
    IgnorableException
};

export default {
    IgnorableException
}