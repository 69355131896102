<template>
  <VChip
    class="stat-chip"
    :label="label"
    :outlined="outline"
    :small="small"
    :selected="selected"
    :dark="dark"
    :light="light"
    :disabled="disabled"
    :color="realColor"
    :text-color="realTextColor"
  >
    <VAvatar :color="realAvatarColor">
      <template v-if="!icon">
        {{ value || 0 }}
      </template>
      <template v-else>
        <VIcon>{{ icon }}</VIcon>
      </template>
    </VAvatar>
    {{ featureName(title, 'pluralize', [this.value]) }}
  </VChip>
</template>

<script>
export default {
  name: "StatChip",
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: null
    },
    outline: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    emptyColor: {
      type: String,
      default: "grey"
    },
    color: {
      type: String,
      default: "info"
    },
    textColor: {
      type: String,
      default: "black"
    },
    hasValueTextColor: {
      type: String,
      default: "white"
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    realColor() {
      return this.value > 0 ? `${this.color}` : this.emptyColor;
    },
    realAvatarColor() {
      return this.value > 0 ? `${this.color} darken-1` : this.emptyColor;
    },
    realTextColor() {
      return this.value > 0 ? `${this.hasValueTextColor}` : `${this.textColor}`;
    }
  },
  methods: {
    clicked: function(item, event) {
      this.action(item);
      event.preventDefault();
    }
  }
};
</script>
<style lang="scss">
.v-chip.v-chip--label.stat-chip {
  .v-chip__content {
    .v-avatar {
      border-radius: 1px;
    }
  }
}
</style>