<template>
  <VRow class="current-filter justify-end py-0">
    <VCol class="text-right">
      <VChip
        v-for="(activeFilter,key) in filterValues"
        small
        class="mx-1"
        :close="!disableFilter"
        :key="`filter-${key}`"
        :disabled="isLoading"
        @update:active="$emit('removeFilter', activeFilter)"
      >
        <small>
          <strong>{{ activeFilter.label }}:&nbsp;</strong>
          {{ activeFilter.text || activeFilter.value }}
        </small>
      </VChip>
      <VChip
        v-if="filterValues.length > 0 && !disableFilter"
        close
        small
        color="info"
        text-color="white"
        :disabled="isLoading"
        @click:close="$emit('resetFilter')"
      >
        Clear
      </VChip>
    </VCol>
    <VCol
      cols="12"
      v-if="(filter.filter || {}).strict === true"
    >
      <VAlert
        dismissible
        type="warning"
        :value="true"
        @input="$emit('removeStrictMode')"
      >
        Strict mode is enabled. Only results that match
        <strong>ALL</strong> of your criteria will be returned. Click the close button to remove strict mode
      </VAlert>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
    },
    filterValues: {
      type: Array,
      default: () => [],
    },
    disableFilter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>