import BaseApi from "./_base-api";

/**
 * Report API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Report Dashboard Item",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			if (
				!params.report_dashboard_id &&
				["testing", undefined].indexOf(process.env.ENV) == -1
			) {
				throw new Error(
					"[Report Dashboard Item: Get One]: Missing params.report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/item`;
		},
		getAll: (app, _params) => {
			const params = _params || {};
			if (
				!params.report_dashboard_id &&
				["testing", undefined].indexOf(process.env.ENV) == -1
			) {
				throw new Error(
					"[Report Dashboard Item: Get All]: Missing params.report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/item`;
		},
		save: (app, _params) => {
			const params = _params || {};
			if (
				!params.report_dashboard_id &&
				["testing", undefined].indexOf(process.env.ENV) == -1
			) {
				throw new Error(
					"[Report Dashboard Item: Save]: Missing params.report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/item`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			if (
				!params.report_dashboard_id &&
				["testing", undefined].indexOf(process.env.ENV) == -1
			) {
				throw new Error(
					"[Report Dashboard Item: delete]: Missing params.report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/item`;
		},
		default: "reports/dashboards",
	},
});

export default api;
