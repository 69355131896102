<template>
  <AppLayout>
    <router-view
      v-if="$hasTeam"
      slot="main-content"
    />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import { AUTH_LOGOUT, USER_REQUEST, TEAM_CREATE } from "@/store/actionTypes";

export default {
  name: "App",
  components: {
    AppLayout
  },
  data() {
    return {};
  }
};
</script>