<template>
  <VRow
    row
    align-center
  >
    <VCol
      v-if="team.photo_url"
      shrink
      justify-center
      mr-4
      class="d-flex"
      :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : 12"
    >
      <img
        :src="team.photo_url"
        :style="{
          backgroundColor: 'white',
          maxHeight: imageSize || '96px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }"
      >
    </VCol>
    <VCol
      grow
      mt-2
      :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : 12"
    >
      <h1
        :class="{
          [`display-${size}`]: size > 0,
          'title': size === 0,
          'text-center': $vuetify.breakpoint.smAndDown,
          'text-left': $vuetify.breakpoint.mdAndUp,
          [`${textColor}--text`]: true
        }"
      >
        {{ team.name }}
      </h1>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    imageSize: {
      type: [Number, String],
      default: "96px",
    },
    textColor: {
      type: String,
      default: "black",
    },
    size: {
      type: Number,
      default: 0,
    },
  },
};
</script>