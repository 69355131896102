import BaseApi from "./_base-api";

/**
 * Report API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	pin(params) {
		this.log().info("[Services: Report Dashboard]: Pin Report", params);
		return this.api()
			.post(this.getUrl("pin", "pin", params))
			.catch((error) => {
				this.log().error(
					"[Services: Report Dashboard: Pin Report]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	unpin(params) {
		this.log().info("[Services: Report Dashboard]: Unpin Report", params);
		return this.api()
			.post(this.getUrl("unpin", "unpin", params))
			.catch((error) => {
				this.log().error(
					"[Services: Report Dashboard: Unpin Report]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Report Dashboard",
	path: {
		pin: (app, _params) => {
			const params = _params || {};
			if (!params.report_dashboard_id) {
				throw new Error(
					"[Report Dashboard: Pin Report]: Missing report_dashboard_id"
				);
			}
			if (!params.report_id) {
				throw new Error(
					"[Report Dashboard: Pin Report]: Missing report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/pin/${params.report_id}`;
		},
		unpin: (app, _params) => {
			const params = _params || {};
			if (!params.report_dashboard_id) {
				throw new Error(
					"[Report Dashboard: Unpin Report]: Missing report_dashboard_id"
				);
			}
			return `reports/dashboards/${params.report_dashboard_id}/unpin${
				params.report_id ? "/" + params.report_id : ""
			}`;
		},
		default: "reports/dashboards",
	},
});

export default api;
