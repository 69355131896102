<template>
  <VSkeletonLoader
    type="list-item-avatar-three-line"
    :class="{
      'fill-height': listView,
    }"
  >
    <VList
      v-if="listView"
      color="transparent"
      :class="`text-black questions-list ${questionsListClass}`"
      :style="questionsListStyle"
    >
      <VListItem>
        <VBtn
          color="white"
          height="40px"
          class="mb-2 px-2"
          style="border-radius: 10px"
          @click="addQuestionsMenu = !addQuestionsMenu"
        >
          <img
            height="24px"
            class="mr-2"
            src="../../../images/forms/add-black-icon@2x.png"
          >
          <span style="font-size: 16px; font-weight: 500; color: #252526">
            Add Question
          </span>
          <VIcon
            right
            size="18px"
            color="#bfbfbf"
            class="mx-2"
          >
            {{ addQuestionsMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </VIcon>
        </VBtn>
      </VListItem>
      <VListItem v-if="addQuestionsMenu">
        <VCard
          width="180.2px"
          class="mb-2"
          style="box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 12%)"
        >
          <VList>
            <VListItem
              v-for="(choice, key) in activeChoices"
              class="px-2"
              :key="`add-new-input-${key}`"
              @click.prevent="onAddInput(choice)"
            >
              <img
                v-if="choice.image"
                width="24px"
                class="mr-2"
                :src="choice.image"
              >
              <VIcon
                v-else
                size="20px"
                class="mr-2 pl-1"
              >
                {{ (choice || {}).icon }}
              </VIcon>
              <VListItemTitle style="font-size: 15px; color: #808080; font-weight: 500">
                {{ (choice || {}).value == "Url" ? "Button" : (choice || {}).text }}
              </VListItemTitle>
            </VListItem>
          </VList>
        </VCard>
      </VListItem>
      <VListItem
        v-for="(choice, key) in defaultChoice"
        class="py-1"
        :key="`add-input-${key}`"
      >
        <div
          class="d-flex"
          style="cursor: pointer"
          @click.prevent="onAddInput(choice)"
        >
          <VBtn
            height="40px"
            min-width="auto"
            class="px-2 mr-3"
            style="border-radius: 10px"
          >
            <img
              v-if="choice.image"
              :src="choice.image"
              :height="choice.value == 'Google Drive' ? 'auto' : '24px'"
            >
            <VIcon
              v-else
              color="#808080"
            >
              {{ choice.icon }}
            </VIcon>
          </VBtn>
          <VListItemTitle>
            <span style="color: #808080; font-weight: 500">
              {{ (choice || {}).text }}
            </span>
          </VListItemTitle>
        </div>
      </VListItem>
    </VList>
    <template v-else-if="!fieldsOnly">
      <slot name="title">
        <h3 class="mb-2">
          {{ titleText }}
        </h3>
      </slot>
      <VAlert
        type="info"
        :class="customClass"
        :value="valuesFromUser || (infoText && infoText.length > 0)"
      >
        {{
          infoText || `${$team.user_role_name} will provide values for the inputs below`
        }}
      </VAlert>
    </template>
    <Draggable
      v-if="allInputs.length > 0 && !listView"
      draggable=".dynamic-input-container"
      handle=".dynamic-input-handle"
      :value="allInputs"
      :no-transition-on-drag="true"
      :animation="200"
      @change="onSectionMoved"
    >
      <transition-group
        name="dynamic-input-container"
        type="transition"
        :css="true"
        tag="div"
      >
        <div
          v-for="(group, groupName, groupIndex) in getAllInputsGrouped"
          :key="`input-section-${groupIndex}`"
          :class="{
            'px-0 my-2': isDefaultSection(groupName) || mode === 'single',
            'dynamic-input-container': true,
            'remove-vertical-line': newFieldsView,
            'mt-0 mb-4 mx-0':
              !newFieldsView && hasInputSections && !isDefaultSection(groupName),
            'my-n6 mx-n2':
              !newFieldsView && !hasInputSections && isDefaultSection(groupName),
            'grey lighten-2 elevation-0 px-2 pb-1 mt-1':
              !isDefaultSection(groupName) &&
              mode !== 'single' &&
              !listView &&
              !newFieldsView,
          }"
        >
          <VCardTitle
            v-show="hasInputSections && !isDefaultSection(groupName) && !listView"
            :key="`section-header-${groupIndex}`"
            :class="{
              'py-0': !newFieldsView,
              'grey lighten-2 rounded-0': newFieldsView,
            }"
            :style="{
              height: newFieldsView ? '52px' : '64px',
            }"
          >
            <VIcon
              v-if="canAddMoreInputs && !newFieldsView"
              slot="prepend"
              class="dynamic-input-handle mt-1"
            >
              drag_indicator
            </VIcon>&nbsp;
            <TextField
              v-if="(groupName && !newFieldsView) || editSection"
              hide-details
              :autofocus="editSection"
              :key="`section-input-${groupIndex}`"
              :ref="`updateSection${groupIndex}`"
              :value="groupName || 'Default'"
              :disabled="deletedGroups[groupName]"
              :style="{
                width: newFieldsView ? '75px' : 'auto',
              }"
              @keydown.enter="newSection => onUpdateSectionsForGroup(newSection, group, groupName)"
              @blur="newSection => onUpdateSectionsForGroup(newSection, group, groupName)"
              @input="newSection => tempSectionTitle = newSection"
            >
              <VBtn
                icon
                text
                style="margin-top: -6px"
                :disabled="deletedGroups[groupName]"
                :slot="newFieldsView ? 'append' : 'append-outer'"
                @click="onSaveSectionsForGroup(groupIndex, group, groupName)"
              >
                <VIcon>save</VIcon>
              </VBtn>
            </TextField>
            <template v-else>
              <span
                class="px-1"
                style="font-size: 18px"
              >
                {{ groupName }}
              </span>
              <VBtn
                icon
                @click="editSection = true"
              >
                <img
                  height="24px"
                  src="../../../images/forms/edit-icon@2x.png"
                >
              </VBtn>
            </template>
            <VSpacer />
            <VChip
              v-if="newFieldsView"
              text-color="white"
              color="rgba(0, 0, 0, 0.87)"
            >
              {{ group.length }}
              {{ `${newFieldsView ? "Question" : "Field"}`.pluralize(group.length) }}
            </VChip>
            <VChip
              v-else
              text-color="white"
              :color="group.length > 0 && !deletedGroups[groupName] ? 'info' : 'default'"
            >
              {{ group.length }}
              {{ `${newFieldsView ? "Question" : "Field"}`.pluralize(group.length) }}
            </VChip>
            <VBtn
              text
              slot="append-outer"
              :icon="!newFieldsView"
              @click.stop="onRemoveInputs(groupIndex, group, groupName)"
            >
              <span
                v-if="newFieldsView"
                style="color: #ff2a7a; font-size: 14px"
              >
                {{ `${deletedGroups[groupName] === true ? "Restore" : "Delete"} Page` }}
              </span>
              <VIcon
                v-else
                color="red"
              >
                {{ deletedGroups[groupName] === true ? "refresh" : "delete" }}
              </VIcon>
            </VBtn>
            <VBtn
              v-if="!newFieldsView"
              icon
              @click="toggleSectionVisibility(groupName)"
            >
              <VIcon>
                {{
                  sectionHasVisibility(groupName) || checkSectionVisibilty(groupName)
                    ? "edit"
                    : "edit"
                }}
              </VIcon>
            </VBtn>
          </VCardTitle>
          <VCardText
            :class="{
              'px-0': newFieldsView,
            }"
          >
            <template
              v-if="enableSections &&
                [null, 'null', '', 'undefined', undefined, 'default'].indexOf(
                  groupName
                ) == -1 &&
                allSections.hasOwnProperty(groupName) &&
                !listView
              "
            >
              <EditorBubble
                v-model="allSections[groupName].description"
                style="margin-bottom: 1rem"
                :class="{
                  'px-4 pt-2 mb-0': newFieldsView,
                }"
                :placeholder="getSectionDescrPlaceholder(groupName)"
                @blur="onUpdateSections"
              />
            </template>
            <VSkeletonLoader
              v-if="newFieldsView && !allSections.hasOwnProperty(groupName) && groupName !== null"
              grid-list-lg
              fluid
              type="table-heading,card"
            />
            <Draggable
              v-else-if="(hasInputSections &&
                (sectionHasVisibility(groupName) ||
                  checkSectionVisibilty(groupName))) ||
                !hasInputSections ||
                isDefaultSection(groupName)
              "
              draggable=".dynamic-input-container"
              handle=".dynamic-input-handle"
              :no-transition-on-drag="true"
              :value="group"
              @input="(value) => onEnd(groupName, value)"
            >
              <transition-group
                name="dynamic-input-container"
                tag="div"
                :css="true"
                :key="`transition-group-section-${groupIndex}`"
              >
                <div
                  v-for="(input, key) in group"
                  :key="`dynamic-input-${groupIndex}-${key}`"
                  :class="{
                    [containerClass]: true,
                    'blue lighten-4':
                      !newFieldsView &&
                      !hideFields &&
                      enableSectionsBackground &&
                      !input.deleted,
                    'grey lighten-2 disabled': input.deleted,
                    'px-6 mb-6 pb-6':
                      !newFieldsView && !hideFields && enableSectionsBackground,
                    'my-6':
                      !newFieldsView && hasInputSections && !isDefaultSection(groupName),
                  }"
                >
                  <VRow
                    v-if="newFieldsView"
                    class="pt-2"
                  >
                    <VCol 
                      class="d-flex"
                      :cols="firstActionsColStyle"
                    >
                      <VTooltip
                        top
                        content-class="required-tooltip"
                      >
                        <template #activator="{ on, attrs }">
                          <VBtn
                            v-bind="attrs"
                            v-on="on"
                            height="40px"
                            min-width="auto"
                            class="px-2 mr-2"
                            :color="input.openAdvancedSettingsPanel.length ? '#fff' : '#ececf1'
                            "
                            :style="{
                              border: '1px solid #efefef',
                              'border-radius': '10px',
                              'box-shadow': input.openAdvancedSettingsPanel.length
                                ? '0 1.5px 3px 0 rgba(0, 0, 0, 0.12)'
                                : 'none',
                            }"
                            @click="
                              onUpdateInputProperty(
                                input,
                                'openAdvancedSettingsPanel',
                                input.openAdvancedSettingsPanel.length ? [] : [0]
                              )
                            "
                          >
                            <img
                              height="24px"
                              :src="require(`../../../images/forms/configure-${input.openAdvancedSettingsPanel.length ? 'dark-' : ''
                              }icon@2x.png`)
                              "
                            >
                          </VBtn>
                        </template>
                        <span>{{
                          input.openAdvancedSettingsPanel.length ? "Hide" : "Show"
                        }}
                          Advanced Settings</span>
                      </VTooltip>
                      <span
                        class="d-flex align-center pr-2"
                        style="font-weight: 900; font-size: 16px; width: inherit"
                      >
                        {{
                          isPreviewableInput(input.type)
                            ? "Button"
                            : getInputTitle(input.type)
                        }}
                      </span>
                      <VSelect
                        v-if="!normalActionsView && ($showLeftMainMenu || $vuetify.breakpoint.mdAndDown)"
                        solo
                        dense
                        hide-details
                        height="40px"
                        background-color="#ececf1"
                        :ref="`section-menu-${key}`"
                        :items="inputSections"
                        :value="input.section"
                        @change="(section) => updateInputSection(section, input, key)"
                      >
                        <template
                          v-if="$vuetify.breakpoint.lgAndUp"
                          #prepend-inner
                        >
                          Page:
                        </template>
                      </VSelect>
                    </VCol>
                    <VCol
                      class="d-flex"
                      :cols="secondActionsColStyle"
                    >
                      <VSelect
                        v-if="normalActionsView || (!$showLeftMainMenu && $vuetify.breakpoint.lgAndUp)"
                        solo
                        dense
                        hide-details
                        height="40px"
                        background-color="#ececf1"
                        :ref="`section-menu-${key}`"
                        :items="inputSections"
                        :value="input.section"
                        @change="(section) => updateInputSection(section, input, key)"
                      >
                        <template
                          v-if="$vuetify.breakpoint.lgAndUp"
                          #prepend-inner
                        >
                          Page:
                        </template>
                      </VSelect>
                      <VTooltip
                        top
                        content-class="required-tooltip"
                      >
                        <template #activator="{ on, attrs }">
                          <VBtn
                            v-if="!isUrlInput(input.type)"
                            v-bind="attrs"
                            v-on="on"
                            height="40px"
                            width="40px"
                            min-width="40px"
                            class="px-2 ml-2 no-shadow"
                            :color="input.is_required ? 'rgba(255, 42, 122, 0.2)' : '#ececf1'
                            "
                            :style="{
                              'border-radius': '10px',
                              border: input.is_required
                                ? '1px solid rgba(255, 42, 122, 0.2)'
                                : '1px solid #ececf1',
                            }"
                            @click="input.is_required = !input.is_required"
                          >
                            <img
                              v-if="input.is_required"
                              height="24px"
                              src="../../../images/forms/required-icon@2x.png"
                            >
                            <img
                              v-else
                              height="24px"
                              src="../../../images/forms/require-icon@2x.png"
                            >
                          </VBtn>
                        </template>
                        <span class="d-flex">
                          <img
                            v-if="input.is_required"
                            height="16px"
                            width="16px"
                            src="../../../images/forms/required-icon.png"
                            style="margin-top: 2px; margin-right: 2px"
                          >
                          <img
                            v-else
                            height="12px"
                            width="12px"
                            src="../../../images/forms/require-icon.png"
                            class="mt-1 mr-1"
                          >
                          Required
                        </span>
                      </VTooltip>
                      <VTooltip
                        top
                        content-class="required-tooltip"
                      >
                        <template #activator="{ on, attrs }">
                          <VBtn
                            v-bind="attrs"
                            v-on="on"
                            height="40px"
                            min-width="auto"
                            color="#ececf1"
                            class="px-2 mx-2 no-shadow"
                            style="border-radius: 10px"
                            @click="onDuplicate(input)"
                          >
                            <img
                              height="24px"
                              src="../../../images/forms/duplicate-icon@2x.png"
                            >
                          </VBtn>
                        </template>
                        <span>Duplicate</span>
                      </VTooltip>
                      <VTooltip
                        top
                        content-class="required-tooltip"
                      >
                        <template #activator="{ on, attrs }">
                          <VBtn
                            v-bind="attrs"
                            v-on="on"
                            height="40px"
                            min-width="auto"
                            color="#ececf1"
                            class="px-2 no-shadow"
                            style="border-radius: 10px"
                            @click="
                              onRemoveLocalInput(input.id ? input : input.realIndex)
                            "
                          >
                            <img
                              v-if="!input.deleted"
                              height="24px"
                              src="../../../images/forms/delete-icon@2x.png"
                            >
                            <VIcon
                              v-else
                              :color="input.deleted ? 'green' : 'red'"
                            >
                              {{ getDeleteIcon(input) }}
                            </VIcon>
                          </VBtn>
                        </template>
                        <span>{{ getDeleteText(input) }}</span>
                      </VTooltip>
                      <VBtn
                        v-if="canAddMoreInputs"
                        icon
                        height="40px"
                        width="40px"
                        class="dynamic-input-handle ml-2"
                      >
                        <img
                          height="24px"
                          src="../../../images/forms/drag-icon@2x.png"
                        >
                      </VBtn>
                    </VCol>
                  </VRow>
                  <VRow
                    v-if="!hideFields"
                    row
                    :align-start="enableSectionsValue"
                    :align-center="!enableSectionsValue"
                    :class="{
                      'pt-2': enableSectionsValue,
                      'mx-0': newFieldsView,
                    }"
                  >
                    <template v-if="!newFieldsView && canAddMoreInputs">
                      <VIcon
                        v-if="enableSectionsValue"
                        slot="prepend"
                        class="dynamic-input-handle mt-1"
                      >
                        drag_indicator
                      </VIcon>
                      <img
                        v-else
                        src="../../../images/lessons/drag_2.png"
                        class="dynamic-input-handle"
                      >
                    </template>
                    <VMenu
                      v-if="!newFieldsView && enableSections && !isCardsInput(input.type)"
                      v-model="input.sectionMenu"
                      class="mr-2"
                      :items="allSectionsFor(input)"
                      @blur="
                        onUpdateInputProperty(
                          input,
                          'sectionMenu',
                          $event.target.value,
                          $event
                        )
                      "
                      @input="onFocusInput(`input-section-${groupIndex}-${key}`)"
                    >
                      <template #activator="{ on }">
                        <VBtn
                          v-on="on"
                          text
                          class="px-1 ml-1 mr-2"
                          style="text-transform: none; text-align: left"
                          :disabled="input.deleted"
                          :title="input.section"
                        >
                          <VIcon small>
                            {{ input.section ? "edit" : "add" }}
                          </VIcon>&nbsp;
                          <span
                            class="text-truncate text-no-wrap"
                            style="max-width: 10vw"
                          >{{ input.section || "Page" }}</span>
                        </VBtn>
                      </template>
                      <VList>
                        <VListItem>
                          <TextField
                            persistent-hint
                            clearable
                            hide-details
                            :id="`input-section-${groupIndex}-${key}`"
                            :ref="`input-section-${groupIndex}-${key}`"
                            :value="input.section"
                            :hint="(input.options || {}).caption"
                            @click.native.stop
                            @keydown.enter="(event) =>
                              onSetSectionAndCloseMenu(
                                input,
                                event.target.value,
                                groupIndex,
                                key
                              )
                            "
                            @click:clear="onSetSection(input, null)"
                            @blur="(event) =>
                              onSetSectionAndCloseMenu(
                                input,
                                event.target.value,
                                groupIndex,
                                key
                              )
                            "
                          >
                            <VBtn
                              icon
                              text
                              style="margin-top: -6px"
                              slot="append-outer"
                              @click.prevent="
                                onSetSectionAndCloseMenu(
                                  input,
                                  input.section,
                                  groupIndex,
                                  key
                                )
                              "
                            >
                              <VIcon>save</VIcon>
                            </VBtn>
                          </TextField>
                        </VListItem>
                        <VListItem
                          v-for="(sectionChoice, sectionKey) in allSectionsFor(input)"
                          :key="`section-options-${groupIndex}-${sectionKey}-${key}`"
                          @click="onSetSection(input, sectionChoice)"
                        >
                          <VListItemTitle>{{ sectionChoice }}</VListItemTitle>
                        </VListItem>
                      </VList>
                    </VMenu>
                    <span
                      v-if="newFieldsView && input.showTitle"
                      class="pb-4"
                      style="
                        font-size: 16px;
                        color: #808080;
                        font-weight: 900;
                        cursor: pointer;
                      "
                      @click="onUpdateInputProperty(input, 'showTitle', false)"
                    >
                      {{ input.name }}
                    </span>
                    <TextField
                      v-else-if="enableTitles"
                      v-model="input.name"
                      autofocus
                      placeholder="Enter name"
                      class="dynamic-title"
                      :filled="!newFieldsView"
                      :hide-details="!enableSectionsValue"
                      :readonly="input.deleted"
                      :class="{
                        'ml-2': isSingleInputMode,
                      }"
                      :error="$vError(input.realIndex, 'name')"
                      @input="(value) => onUpdateInputProperty(input, 'name', value)"
                      @blur="
                        input.name.length
                          ? onUpdateInputProperty(input, 'showTitle', true)
                          : false
                      "
                    >
                      <VBtn
                        v-if="!newFieldsView"
                        text
                        slot="append-outer"
                        class="remove-dynamic-input error--text mt-n1"
                        :icon="$vuetify.breakpoint.mdAndDown"
                        :small="$vuetify.breakpoint.lgAndUp"
                        @click.stop="
                          onRemoveLocalInput(input.id ? input : input.realIndex)
                        "
                      >
                        <VIcon :color="input.deleted ? 'green' : 'red'">
                          {{ getDeleteIcon(input) }}
                        </VIcon>
                        <span
                          v-if="showDeleteBtnText && $vuetify.breakpoint.lgAndUp"
                          :style="{
                            color: input.deleted ? 'green' : 'red',
                          }"
                        >
                          {{ getDeleteText(input) }}
                        </span>
                      </VBtn>
                    </TextField>
                  </VRow>
                  <template v-if="enableSectionsValue">
                    <template v-if="!hideFields">
                      <template v-if="input.type === 'Textarea'">
                        <Editor
                          outlined
                          persistent-hint
                          auto-grow
                          class="content-editor"
                          :value="input.value"
                          :readonly="readonly"
                          :type="input.type || 'text'"
                          :placeholder="getPlaceholderText(input)"
                          :hint="(input.options || {}).caption"
                          :menu-position="newFieldsView ? 'bottom' : 'top'"
                          @input="(value) => onUpdateInputProperty(input, 'value', value)"
                        >
                          <VIcon slot="append">
                            {{ input.icon }}
                          </VIcon>
                        </Editor>
                      </template>
                      <template v-else-if="input.type == 'File'">
                        <FileUpload
                          slot="append"
                          background-color="primary"
                          color="primary"
                          :ref="`fileUpload${input.realIndex}`"
                          :readonly="readonly"
                          :hint="(input.options || {}).caption"
                          @formData="(file) => onAddLocalFile(file, input.realIndex)"
                        />
                        <FilesList
                          v-if="!readonly"
                          :files="[input.value instanceof Object ? input.value : {}]"
                          @removeFile="(file) =>
                            onRemoveLocalFile(
                              file,
                              input.realIndex,
                              `fileUpload${input.realIndex}`
                            )
                          "
                        />
                      </template>
                      <template v-else-if="input.type == 'Google Drive'">
                        <GoogleDriveUI
                          style="text-align: right"
                          mode="inline"
                          button-class="dashed text-right"
                          :rounded="true"
                          :outlined="true"
                          :can-connect-on-no-token="true"
                          :readonly="input.deleted"
                          :hint="(input.options || {}).caption"
                          @addFile="(file) => onAddRemoteFile(file, input.realIndex)"
                        />
                        <FilesList
                          v-if="!readonly"
                          :files="[input.value instanceof Object ? input.value : {}]"
                          @removeFile="(file) => onRemoveRemoteFile(file, input.realIndex)
                          "
                        />
                      </template>
                      <template v-else-if="input.type == 'Rating'">
                        <VCard
                          v-if="newFieldsView"
                          class="text-center py-2"
                          style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.12)"
                        >
                          <VRating
                            clearable
                            persistent-hint
                            background-color="primary"
                            color="primary"
                            length="5"
                            size="36"
                            :value="input.value"
                            :readonly="readonly"
                            :hint="(input.options || {}).caption"
                            @input="(value) => (input.value = value)"
                            @blur="onUpdateSingleInput(input)"
                          >
                            <template #item="{ isFilled }">
                              <img
                                v-if="isFilled"
                                class="mx-2"
                                src="../../../images/forms/star-selected-icon.png"
                              >
                              <img
                                v-else
                                class="mx-2"
                                src="../../../images/forms/star-deselected-icon.png"
                              >
                            </template>
                          </VRating>
                          <small
                            v-show="(input.options || {}).caption"
                            v-html="(input.options || {}).caption"
                          />
                        </VCard>
                        <VRow
                          v-else
                          row
                          justify-space-between
                          px-2
                          style="overflow: hidden"
                        >
                          <VCol>
                            <TextField
                              v-model="input.options.length"
                              filled
                              type="number"
                              min="3"
                              label="# Stars"
                              @input="(value) => onUpdateSingleInput(input)"
                              @blur="(value) => onUpdateSingleInput(input)"
                              @keypress="numericOnly"
                            />
                          </VCol>
                          <VCol
                            grow
                            align-content-center
                          >
                            <VRating
                              clearable
                              persistent-hint
                              background-color="primary"
                              color="primary"
                              size="36"
                              :value="input.value"
                              :readonly="readonly"
                              :hint="(input.options || {}).caption"
                              :length="input.options.length"
                              @input="(value) => (input.value = value)"
                              @blur="(value) => onUpdateSingleInput(input)"
                            />
                            <small
                              v-show="(input.options || {}).caption"
                              v-html="(input.options || {}).caption"
                            />
                          </VCol>
                        </VRow>
                      </template>
                      <template v-else-if="input.type == 'Relation'">
                        <VSkeletonLoader
                          type="text"
                          :loading="isAsyncInputLoading(input.realIndex)"
                        >
                          <Autocomplete
                            v-model="input.value"
                            filled
                            hide-details
                            return-object
                            append-inner-icon="arrow-down"
                            :dense="false"
                            :disabled="isAsyncInputLoading(input.realIndex)"
                            :loading="isAsyncInputLoading(input.realIndex)"
                            :item-text="getAsyncOptionsOptions(input).itemText || 'text'"
                            :item-value="getAsyncOptionsOptions(input).itemValue || 'id'"
                            :label="getAsyncOptionsOptions(input).label ||
                              'Press [enter] to search ...'
                            "
                            :placeholder="getAsyncOptionsOptions(input).placeholder ||
                              'Press [enter] to search ...'
                            "
                            :items="asyncInputs.options[parseInt(input.realIndex) || 0]"
                            :search-input.sync="asyncInputs.search[parseInt(input.realIndex) || 0]
                            "
                            :getter="() => onGetAsyncInputOptions(input)"
                            @input="(value) => onUpdateSingleAsyncInput(input, value)"
                          />
                        </VSkeletonLoader>
                      </template>
                      <template v-else-if="input.type == 'Internal_Url'">
                        <Autocomplete
                          v-model="input.value"
                          filled
                          hide-details
                          return-object
                          append-inner-icon="arrow-down"
                          item-text="value"
                          item-value="value"
                          label="Select a page"
                          placeholder="Press [enter] to search ..."
                          :dense="false"
                          :items="getInternalPageOptions()"
                          @input="(value) => onUpdateInputProperty(input, 'value', value)"
                        />
                      </template>
                      <template
                        v-else-if="newFieldsView &&
                          (input.type == 'Checkbox' || input.type == 'Radio')
                        "
                      >
                        <VList class="ma-n2">
                          <VListItem
                            v-for="(choice, choiceKey) in input.options.options"
                            class="px-0"
                            :key="`dynamic-${groupIndex}-${key}-${choiceKey}`"
                          >
                            <VListItemTitle class="text-wrap">
                              <VCheckbox
                                v-if="input.type == 'Checkbox'"
                                hide-details
                                color="success"
                                class="ma-0 px-2"
                                :readonly="readonly"
                              >
                                <template
                                  v-if="choice"
                                  #label
                                >
                                  <span
                                    style="
                                      font-size: 14px;
                                      color: rgb(128, 128, 128);
                                      font-weight: 500;
                                    "
                                  >
                                    {{ choice }}
                                  </span>
                                  <VIcon
                                    color="#bfbfbf"
                                    class="mx-2"
                                    @click.stop="onRemoveOptionsInput(key, choiceKey)"
                                  >
                                    mdi-close-circle
                                  </VIcon>
                                  <VIcon
                                    color="#bfbfbf"
                                    @click.stop="onEditOptionsInput(key, choiceKey)"
                                  >
                                    mdi-pencil-circle
                                  </VIcon>
                                </template>
                              </VCheckbox>
                              <VRadioGroup
                                v-else
                                v-model="input.options.selectedOption"
                                hide-details
                                class="ma-0 px-2 mt-2"
                                :readonly="readonly"
                                @change="(option) => onRadioOptionSelection(key, option)"
                              >
                                <VRadio
                                  color="success"
                                  class="custom-radio"
                                  :value="choice"
                                >
                                  <template
                                    v-if="choice"
                                    #label
                                  >
                                    <span
                                      style="
                                        font-size: 14px;
                                        color: rgb(128, 128, 128);
                                        font-weight: 500;
                                      "
                                    >
                                      {{ choice }}
                                    </span>
                                    <VIcon
                                      color="#bfbfbf"
                                      class="mx-2"
                                      @click.stop="onRemoveOptionsInput(key, choiceKey)"
                                    >
                                      mdi-close-circle
                                    </VIcon>
                                    <VIcon
                                      color="#bfbfbf"
                                      @click.stop="onEditOptionsInput(key, choiceKey)"
                                    >
                                      mdi-pencil-circle
                                    </VIcon>
                                  </template>
                                </VRadio>
                              </VRadioGroup>
                            </VListItemTitle>
                          </VListItem>
                          <template v-if="!input.options.showAddBtn">
                            <TextField
                              v-if="input.options.editOptionKey !== null"
                              v-model.lazy="input.options.options[input.options.editOptionKey]
                              "
                              autofocus
                              hide-details
                              label="Enter option and press [Enter]"
                              class="mx-2 mt-4"
                              :key="`option-${groupIndex}-${key}-${input.options.editOptionKey}`"
                              @keyup.enter="onUpdateOptionsInput(key)"
                              @blur="onUpdateOptionsInput(key)"
                            />
                            <TextField
                              v-else
                              autofocus
                              hide-details
                              placeholder="Enter option and press [Enter]"
                              class="mx-2 mt-4"
                              :disabled="input.deleted"
                              :key="`add-option-${groupIndex}-${key}`"
                              :ref="`add-option-${groupIndex}-${key}`"
                              @blur="$set(input.options, 'showAddBtn', true)"
                              @keyup.enter="
                                onAddOptionsInput(
                                  input.realIndex,
                                  $event.target.value,
                                  `add-option-${groupIndex}-${key}`
                                )
                              "
                            />
                          </template>
                          <VBtn
                            v-else
                            text
                            :class="{
                              'mt-2': input.options.options.length > 0,
                            }"
                            @click="
                              $set(input.options, 'showAddBtn', false);
                              onUpdateSingleInput(input);
                            "
                          >
                            <VIcon
                              color="#bfbfbf"
                              class="pr-2 ml-n2"
                            >
                              mdi-plus
                            </VIcon>
                            <span style="color: #808080; font-weight: normal; font-size: 14px">
                              Add New
                            </span>
                          </VBtn>
                        </VList>
                      </template>
                      <template v-else-if="input.type == 'Stack'">
                        <VRow
                          v-if="input.options.hasOwnProperty('background_color')"
                          column
                          class="mb-n6"
                        >
                          <VCol cols="12">
                            <EmojiPicker
                              text-field
                              slot="trigger"
                              placeholder="Review screen's emoji"
                              :value="input.options.emoji"
                              @change="(emoji) => (input.options.emoji = emoji)"
                            />
                            <Swatches
                              v-model="input.options.background_color"
                              show-fallback
                              swatches="text-advanced"
                              style="width: 100%"
                              :trigger-style="{ width: '100%' }"
                            >
                              <TextField
                                v-model="input.options.background_color"
                                solo
                                clearable
                                slot="trigger"
                                placeholder="Review screen's emoji background color"
                              />
                            </Swatches>
                          </VCol>
                        </VRow>
                        <Draggable
                          v-if="input.options.options instanceof Array &&
                            input.options.options.length > 0
                          "
                          v-model.lazy="input.options.options"
                          element="div"
                          class="pt-1"
                          :options="draggableOptions"
                          :no-transition-on-drag="true"
                        >
                          <transition-group
                            name="dynamic-input-container"
                            class="d-flex overflow-x-auto overflow-y-hidden"
                            :id="`stack-cards-${input.realIndex}`"
                            :css="true"
                            tag="div"
                          >
                            <div
                              v-for="(option, optionKey) in input.options.options"
                              :class="{
                                'dynamic-input-handle': optionKey > 0,
                                'px-6 py-4 mx-4 my-4 rounded grey lighten-4 elevation-4': true,
                              }"
                              :style="{
                                position: 'relative',
                                width: '95%',
                                'min-width': '95%',
                              }"
                              :key="`dynamic-card-${groupIndex}-${key}-${optionKey}`"
                            >
                              <ChoiceList
                                :enable-delete="optionKey > 0"
                                :input="input"
                                :option="option"
                                :option-key="optionKey"
                                :validator="validator"
                                @validated="$emit('validated')"
                                @removeCard="onRemoveCard(key, optionKey, null)"
                              />
                            </div>
                          </transition-group>
                        </Draggable>
                        <VRow class="d-flex justify-end mx-0 my-4">
                          <VBtn
                            :disabled="input.deleted"
                            @click="addNewStackCard(input.realIndex)"
                          >
                            Add New Card
                          </VBtn>
                        </VRow>
                      </template>
                      <template v-else-if="isGridListCardsInput(input.type)">
                        <VRow column>
                          <VCol cols="12">
                            <VSelect
                              v-model="input.options.type"
                              filled
                              small
                              dense
                              outlined
                              placeholder="Select selection type..."
                              class="pb-0"
                              :items="choiceSingleMultipleOptions"
                              :error="$vError(input.realIndex, 'type', true)"
                              @change="$emit('validated')"
                            />
                            <Swatches
                              v-if="input.options.hasOwnProperty('background_color')"
                              v-model="input.options.background_color"
                              show-fallback
                              swatches="text-advanced"
                              style="width: 100%"
                              :trigger-style="{ width: '100%' }"
                            >
                              <TextField
                                v-model="input.options.background_color"
                                solo
                                clearable
                                slot="trigger"
                                placeholder="Review screen's emoji background color"
                              />
                            </Swatches>
                            <EmojiPicker
                              v-if="input.options.hasOwnProperty('emoji')"
                              text-field
                              slot="trigger"
                              placeholder="Review screen's emoji"
                              :value="input.options.emoji"
                              @change="(emoji) => (input.options.emoji = emoji)"
                            />
                          </VCol>
                        </VRow>
                        <Draggable
                          v-if="input.options.options instanceof Array &&
                            input.options.options.length > 0
                          "
                          v-model.lazy="input.options.options"
                          element="div"
                          class="pt-1 px-2"
                          style="overflow: hidden"
                          :options="draggableOptions"
                          :no-transition-on-drag="true"
                          :id="`grid-list-cards-${input.realIndex}`"
                        >
                          <transition-group
                            name="dynamic-input-container"
                            :css="true"
                            tag="div"
                          >
                            <div
                              v-for="(option, optionKey) in input.options.options"
                              :class="{
                                'dynamic-input-handle': optionKey > 0,
                                'px-6 py-4 mb-4 rounded grey lighten-4 elevation-4': true,
                              }"
                              :style="{
                                position: 'relative',
                              }"
                              :key="`dynamic-card-${groupIndex}-${optionKey}`"
                            >
                              <ChoiceList
                                :enable-delete="optionKey > 0"
                                :input="input"
                                :option="option"
                                :option-key="optionKey"
                                :validator="validator"
                                @validated="$emit('validated')"
                                @removeCard="onRemoveCard(key, optionKey, null)"
                              />
                              <Draggable
                                v-if="input.options.options[optionKey].options.length > 0"
                                v-model.lazy="input.options.options[optionKey].options"
                                element="div"
                                class="pt-1"
                                style="overflow: auto hidden"
                                :options="draggableOptions"
                                :no-transition-on-drag="true"
                                :id="`grid-list--nested-cards-${input.realIndex}`"
                              >
                                <transition-group
                                  name="dynamic-input-container"
                                  :css="true"
                                  tag="div"
                                >
                                  <div
                                    v-for="(nestedOption, nestedOptionKey) in input
                                      .options.options[optionKey].options"
                                    class="dynamic-input-handle px-6 py-4 mb-4 mx-4 rounded grey lighten-4 elevation-4"
                                    :style="{
                                      position: 'relative',
                                    }"
                                    :key="`dynamic-card-${groupIndex}-${key}-${optionKey}-${nestedOptionKey}`"
                                  >
                                    <ChoiceList
                                      :input="input"
                                      :option="nestedOption"
                                      :option-key="optionKey"
                                      :nested-option-key="nestedOptionKey"
                                      :validator="validator"
                                      @validated="$emit('validated')"
                                      @removeCard="
                                        onRemoveCard(key, optionKey, nestedOptionKey)
                                      "
                                    />
                                  </div>
                                </transition-group>
                              </Draggable>
                              <VRow class="d-flex justify-end mx-0 mb-4">
                                <VBtn
                                  :disabled="input.deleted"
                                  @click="addNestedCard(input.realIndex, optionKey)"
                                >
                                  Add Child Card
                                </VBtn>
                              </VRow>
                            </div>
                            <VRow
                              class="d-flex justify-end mx-0 mb-4"
                              :key="`add-new-card-btn-${groupIndex}`"
                            >
                              <VBtn
                                :disabled="input.deleted"
                                @click="addNewCard(input.realIndex)"
                              >
                                Add New Card
                              </VBtn>
                            </VRow>
                          </transition-group>
                        </Draggable>
                      </template>
                      <template v-else-if="isOptionsInput(input.type)">
                        <VSelect
                          v-if="newFieldsView"
                          dense
                          outlined
                          clearable
                          hide-details
                          label="Dropdown"
                          :item-disabled="input.options.options instanceof Array
                            ? input.options.options
                            : []
                          "
                          :items="input.options.options instanceof Array
                            ? input.options.options
                            : []
                          "
                          @blur="$set(input.options, 'showAddBtn', true)"
                        >
                          <template #item="{ item }">
                            <VRow
                              class="mx-n4 px-4 py-2"
                              style="border-bottom: 1px solid #ececf1"
                            >
                              <span style="font-weight: 500">{{ item }}</span>
                              <VSpacer />
                              <VIcon
                                color="#bfbfbf"
                                @click.stop="
                                  onRemoveOptionsInput(
                                    key,
                                    input.options.options.indexOf(item)
                                  )
                                "
                              >
                                mdi-close-circle
                              </VIcon>
                              <VIcon
                                color="#bfbfbf"
                                class="ml-2"
                                @click.stop="
                                  onEditOptionsInput(
                                    key,
                                    input.options.options.indexOf(item)
                                  )
                                "
                              >
                                mdi-pencil-circle
                              </VIcon>
                            </VRow>
                          </template>
                          <template #append-item>
                            <VRow
                              v-if="!input.options.showAddBtn"
                              class="mx-0 px-4 py-2"
                            >
                              <TextField
                                v-if="input.options.editOptionKey !== null"
                                v-model.lazy="input.options.options[input.options.editOptionKey]
                                "
                                autofocus
                                hide-details
                                label="Enter option and press [Enter]"
                                :key="`option-${groupIndex}-${key}-${input.options.editOptionKey}`"
                                @keyup.enter="onUpdateOptionsInput(key)"
                                @blur="onUpdateOptionsInput(key)"
                              />
                              <TextField
                                v-else
                                clearable
                                hide-details
                                label="Enter option and press [Enter]"
                                :disabled="input.deleted"
                                :key="`add-option-${groupIndex}-${key}`"
                                :ref="`add-option-${groupIndex}-${key}`"
                                :autofocus="input.options.editOptionKey == null"
                                @keyup.enter="
                                  onAddOptionsInput(
                                    input.realIndex,
                                    $event.target.value,
                                    `add-option-${groupIndex}-${key}`
                                  )
                                "
                              />
                            </VRow>
                            <VRow
                              v-else
                              class="mx-0 px-4 py-2"
                              style="cursor: pointer"
                              @click="
                                $set(input.options, 'showAddBtn', false);
                                onUpdateSingleInput(input);
                              "
                            >
                              <span style="color: #fdbf14; font-weight: 500">Add New</span>
                              <VSpacer />
                              <VIcon color="#fdbf14">
                                mdi-plus-circle
                              </VIcon>
                            </VRow>
                          </template>
                        </VSelect>
                        <VRow
                          v-else
                          column
                        >
                          <VCol
                            v-if="mode !== 'single'"
                            cols="12"
                          >
                            <VSelect
                              filled
                              small
                              dense
                              hide-details
                              placeholder="Select type..."
                              class="pb-0"
                              :value="input.options.type"
                              :items="choiceTypeOptions"
                              @input="(value) => onSetOptionsInputType(value, input.realIndex)
                              "
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            align-items-start
                          >
                            <VSelect
                              v-if="input.options.type === 'Dropdown'"
                              outlined
                              small
                              persistent-hint
                              placeholder="Preview your dropdown options"
                              :items="input.options.options instanceof Array
                                ? input.options.options
                                : []
                              "
                              :hint="(input.options || {}).caption"
                            />
                            <VList style="background-color: transparent">
                              <Draggable
                                v-if="input.options.options instanceof Array &&
                                  input.options.options.length > 0
                                "
                                v-model.lazy="input.options.options"
                                element="div"
                                :options="draggableOptions"
                                :no-transition-on-drag="true"
                              >
                                <transition-group
                                  name="dynamic-input-container"
                                  :css="true"
                                  tag="div"
                                >
                                  <div
                                    class="dynamic-input-handle"
                                    v-for="(choice, choiceKey) in input.options.options"
                                    :key="`dynamic-${groupIndex}-${key}-${choiceKey}`"
                                  >
                                    <VListItem style="height: 72px">
                                      <VListItemAvatar>
                                        <VBtn
                                          fab
                                          small
                                          elevation="0"
                                          color="info"
                                          style="cursor: move"
                                        >
                                          {{ 1 + choiceKey }}
                                        </VBtn>
                                      </VListItemAvatar>
                                      <VListItemAction v-if="input.options.type !== 'Dropdown'">
                                        <VBtn icon>
                                          <VIcon>
                                            {{
                                              isOptionMultiple(input)
                                                ? "check_box_outline_blank"
                                                : "radio_button_unchecked"
                                            }}
                                          </VIcon>
                                        </VBtn>
                                      </VListItemAction>
                                      <VListItemTitle>
                                        <TextField
                                          v-model.lazy="input.options.options[choiceKey]"
                                          large
                                          filled
                                          hide-details
                                          type="text"
                                          placeholder="Enter a value"
                                          class="ml-3"
                                          :key="`option-${groupIndex}-${key}-${choiceKey}`"
                                          @blur="onUpdateSingleInput(input, input)"
                                        >
                                          <VBtn
                                            icon
                                            text
                                            class="remove-dynamic-input"
                                            slot="append-outer"
                                            @click.stop="
                                              onRemoveOptionsInput(key, choiceKey)
                                            "
                                          >
                                            <VIcon color="red">
                                              delete
                                            </VIcon>
                                          </VBtn>
                                        </TextField>
                                      </VListItemTitle>
                                    </VListItem>
                                  </div>
                                </transition-group>
                              </Draggable>
                              <TextField
                                large
                                type="text"
                                placeholder="Enter option and press [Enter]"
                                :disabled="input.deleted"
                                :key="`add-option-${groupIndex}-${key}`"
                                :ref="`add-option-${groupIndex}-${key}`"
                                @keyup.enter="
                                  onAddOptionsInput(
                                    input.realIndex,
                                    $event.target.value,
                                    `add-option-${groupIndex}-${key}`
                                  )
                                "
                              />
                            </VList>
                          </VCol>
                        </VRow>
                      </template>
                      <template v-else-if="input.type == 'Video_Link'">
                        <VRow
                          fill-height
                          :class="{
                            'py-4 mb-0': !newFieldsView,
                          }"
                        >
                          <VCol
                            cols="12"
                            :class="{
                              'py-0': newFieldsView,
                            }"
                          >
                            <TextField
                              filled
                              hide-details
                              persistent-hint
                              placeholder="Url"
                              label="Enter a url"
                              :readonly="readonly"
                              :value="input.value"
                              :hint="(input.options || {}).caption"
                              @input="(value) => onUpdateInputProperty(input, 'value', value)
                              "
                            />
                          </VCol>
                          <VCol
                            v-if="!valuesFromUser"
                            justify-content-center
                            align-items-center
                            cols="12"
                            :class="{
                              'responsive-video': input.value && input.value.length,
                            }"
                          >
                            <iframe
                              v-if="input.value"
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                              style="margin-left: auto; margin-right: auto"
                              height="420"
                              width="315"
                              :src="getVideoUrl(input.value)"
                            />
                            <VAlert
                              v-else
                              type="info"
                              class="mb-0"
                              :value="true"
                            >
                              Enter an example url above
                            </VAlert>
                          </VCol>
                        </VRow>
                      </template>
                      <template v-else-if="input.type == 'Number' && newFieldsView">
                        <NumberInput
                          :disabled="readonly"
                          @input="(value) => onUpdateInputProperty(input, 'value', value)"
                        />
                      </template>
                      <template v-else-if="isPreviewableInput(input.type) && newFieldsView">
                        <VMenu
                          v-model="input.options.showFields"
                          offset-y
                          :close-on-content-click="false"
                        >
                          <template #activator="{ on, attrs }">
                            <VBtn
                              v-bind="attrs"
                              v-on="on"
                              height="100%"
                              min-height="36px"
                              min-width="200px"
                              max-width="100%"
                              color="#60be39"
                              class="d-block text-wrap"
                              @click="
                                $set(
                                  input.options,
                                  'showFields',
                                  !input.options.showFields
                                );
                                onUpdateSingleInput(input);
                              "
                            >
                              <span
                                class="white--text"
                                style="font-size: 14px"
                              >
                                {{ input.options.buttonTitle }}
                              </span>
                              <VSpacer />
                              <VIcon
                                right
                                color="white"
                                size="18px"
                              >
                                {{
                                  input.options.showFields
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                }}
                              </VIcon>
                            </VBtn>
                          </template>
                          <VCard class="px-2">
                            <TextField
                              v-model="input.options.buttonTitle"
                              hide-details
                              placeholder="Enter Title"
                              class="my-3"
                              style="font-size: 14px"
                            />
                            <VTextarea
                              filled
                              hide-details
                              height="75px"
                              placeholder="Enter URL"
                              style="font-size: 12px"
                              :value="input.value"
                              @input="(value) => onUpdateInputProperty(input, 'value', value)
                              "
                            />
                            <VCardActions class="px-0">
                              <VSpacer />
                              <VBtn
                                text
                                color="#60be39"
                                @click="
                                  $set(input.options, 'showFields', false);
                                  onUpdateSingleInput(input);
                                "
                              >
                                <span style="font-size: 14px">Save</span>
                              </VBtn>
                            </VCardActions>
                          </VCard>
                        </VMenu>
                      </template>
                      <template v-else>
                        <TextField
                          persistent-hint
                          hide-details
                          :value="input.value"
                          :type="input.type || 'text'"
                          :placeholder="getPlaceholderText(input)"
                          :readonly="readonly && !getAcceptsAdminValues(input)"
                          :hint="(input.options || {}).caption"
                          @input="(value) => onUpdateInputProperty(input, 'value', value)"
                          @keypress="
                            (input.type || 'text').toLowerCase() == 'number'
                              ? numericOnly($event)
                              : ``
                          "
                        >
                          <template #append>
                            <img
                              v-if="input.type === 'Date'"
                              width="24px"
                              src="../../../images/forms/calendar-icon@2x.png"
                              style="z-index: 5;"
                            >
                            <VIcon
                              v-else
                              style="opacity: 0.6"
                            >
                              {{ input.icon || getInputIcon(input.type) }}
                            </VIcon>
                          </template>
                        </TextField>
                      </template>
                      <VRow v-if="!enableTitles">
                        <VSpacer />
                        <VBtn
                          text
                          class="remove-dynamic-input error--text mt-2"
                          :icon="$vuetify.breakpoint.mdAndDown"
                          :small="$vuetify.breakpoint.lgAndUp"
                          @click.stop="
                            onRemoveLocalInput(input.id ? input : input.realIndex)
                          "
                        >
                          <VIcon :color="input.deleted ? 'green' : 'red'">
                            {{ getDeleteIcon(input) }}
                          </VIcon>
                          <span
                            v-if="$vuetify.breakpoint.lgAndUp"
                            :style="{
                              color: input.deleted ? 'green' : 'red',
                            }"
                          >{{ getDeleteText(input) }}</span>
                        </VBtn>
                      </VRow>
                      <template v-if="isPreviewableInput(input.type) && !newFieldsView">
                        <VChip
                          label
                          small
                          class="mt-2 caption"
                          color="#fff"
                          style="
                            border-bottom-left-radius: 0px !importat;
                            border-bottom-right-radius: 0px !important;
                          "
                        >
                          Preview
                          <VIcon
                            small
                            class="ml-1"
                          >
                            visibility
                          </VIcon>
                        </VChip>
                        <VSheet
                          color="white"
                          class="pa-2"
                        >
                          <DynamicInput
                            preview-mode
                            :input="input"
                          />
                        </VSheet>
                      </template>
                    </template>
                    <template v-if="enableExtraOptions">
                      <template v-if="newFieldsView && input.openAdvancedSettingsPanel.length">
                        <h3
                          class="py-4"
                          style="font-size: 16px"
                        >
                          Advanced Options
                        </h3>
                        <template v-if="enableDefaultValues && getSupportsDefaultValues(input)">
                          <Autocomplete
                            v-model="input.default_value.from"
                            label="Pre-populate from"
                            placeholder="Pre-populate from"
                            item-text="text"
                            item-value="value"
                            :items="defaultValueOptions"
                            @input="(value) =>
                              onUpdateInpuDefaultValueOption(input, 'from', value)
                            "
                          />
                          <TextField
                            v-model="input.default_value.value"
                            v-show="input.default_value.from == 'Custom'"
                            label="Custom pre-populated value"
                            @input="(value) =>
                              onUpdateInpuDefaultValueOption(input, 'value', value)
                            "
                          />
                        </template>
                        <template v-if="isUrlInput(input.type)">
                          <VRow>
                            <VCol
                              cols="12"
                              class="pl-4"
                            >
                              <VSwitch
                                v-model="input.options.asButton"
                                inset
                                hide-details
                                single-line
                                label="(Optional) Show as a button?"
                                class="pt-0 mt-0"
                              />
                            </VCol>
                            <VCol cols="12">
                              <Swatches
                                v-model="input.options.backgroundColor"
                                show-fallback
                                swatches="text-advanced"
                                style="width: 100%"
                                :trigger-style="{ width: '100%' }"
                              >
                                <TextField
                                  v-model="input.options.backgroundColor"
                                  solo
                                  clearable
                                  hide-details
                                  slot="trigger"
                                  label="(Optional) Background color"
                                />
                              </Swatches>
                            </VCol>
                            <VCol
                              cols="12"
                              class="pb-0"
                            >
                              <Swatches
                                v-model="input.options.textColor"
                                show-fallback
                                swatches="text-advanced"
                                style="width: 100%"
                                :trigger-style="{ width: '100%' }"
                              >
                                <TextField
                                  v-model="input.options.textColor"
                                  solo
                                  clearable
                                  slot="trigger"
                                  label="(Optional) Text color"
                                />
                              </Swatches>
                            </VCol>
                          </VRow>
                        </template>
                        <VRow
                          v-if="isTextInput(input.type)"
                          class="my-n4"
                        >
                          <VCol cols="12">
                            <span
                              class="label pr-4"
                              style="font-weight: 900"
                            >
                              Type:
                            </span>
                            <VChip
                              label
                              color="#FFFFFF"
                              text-color="#808080"
                              class="rounded-lg px-2 mr-4"
                              style="border: solid 0.5px #ececf1"
                            >
                              <VRadioGroup v-model="input.options.useWords">
                                <VRadio
                                  color="success"
                                  label="Words"
                                  style="border: none"
                                  :value="true"
                                  :class="{
                                    'view-options': true,
                                    'gray-label': !input.options.useWords,
                                  }"
                                />
                              </VRadioGroup>
                            </VChip>
                            <VChip
                              label
                              color="#FFFFFF"
                              class="rounded-lg px-2 mr-4"
                              style="border: solid 0.5px #ececf1"
                            >
                              <VRadioGroup v-model="input.options.useWords">
                                <VRadio
                                  color="success"
                                  label="Characters"
                                  style="border: none"
                                  :value="false"
                                  :class="{
                                    'view-options': true,
                                    'gray-label':
                                      input.options.useWords === true ||
                                      input.options.useWords === null,
                                  }"
                                />
                              </VRadioGroup>
                            </VChip>
                          </VCol>
                          <VCol cols="12">
                            <TextField
                              label="Max length"
                              :value="input.options.maxLength"
                              @input="(value) => onUpdateInputOption(input, 'maxLength', value)
                              "
                            />
                          </VCol>
                        </VRow>
                        <EditorBubble
                          placeholder="Caption..."
                          class="content-bubble-editor"
                          style="width: 100%; margin-bottom: 0rem"
                          :value="input.options.caption"
                          @input="(value) => onUpdateInputOption(input, 'caption', value)"
                        />
                      </template>
                      <VExpansionPanels
                        v-else-if="!newFieldsView"
                        multiple
                        focusable
                        class="elevation-0 px-0 mt-2"
                        :disabled="input.deleted"
                      >
                        <VExpansionPanel>
                          <VExpansionPanelHeader expand-icon="edit">
                            <div>
                              Advanced Settings
                              <span
                                v-show="input.is_required"
                                style="color: red"
                                label
                              >*</span>
                            </div>
                            <template #actions>
                              <VRow
                                row
                                align-start
                              >
                                <VSwitch
                                  v-model="input.is_required"
                                  inset
                                  hide-details
                                  size="16px"
                                  label="Required?"
                                  class="mr-2 my-0"
                                  @click.stop="() => { }"
                                />&nbsp;
                                <VSpacer />
                                <VIcon
                                  class="mb-1 mr-2"
                                  :color="input.is_required ? 'primary' : 'default'"
                                >
                                  settings
                                </VIcon>
                              </VRow>
                            </template>
                          </VExpansionPanelHeader>
                          <VExpansionPanelContent class="px-2 py-2 pt-5">
                            <template
                              v-if="enableDefaultValues && getSupportsDefaultValues(input)
                              "
                            >
                              <Autocomplete
                                v-model="input.default_value.from"
                                label="Pre-populate from"
                                placeholder="Pre-populate from"
                                item-text="text"
                                item-value="value"
                                :items="defaultValueOptions"
                                @input="(value) =>
                                  onUpdateInpuDefaultValueOption(input, 'from', value)
                                "
                              />
                              <TextField
                                v-model="input.default_value.value"
                                v-show="input.default_value.from == 'Custom'"
                                label="Custom pre-populated value"
                                @input="(value) =>
                                  onUpdateInpuDefaultValueOption(input, 'value', value)
                                "
                              />
                            </template>
                            <template v-if="isUrlInput(input.type)">
                              <VRow>
                                <VCol
                                  cols="12"
                                  class="pl-4"
                                >
                                  <VSwitch
                                    v-model="input.options.asButton"
                                    inset
                                    single-line
                                    label="(Optional) Show as a button?"
                                  />
                                </VCol>
                                <VCol cols="12">
                                  <Swatches
                                    v-model="input.options.backgroundColor"
                                    show-fallback
                                    swatches="text-advanced"
                                    style="width: 100%"
                                    :trigger-style="{ width: '100%' }"
                                  >
                                    <TextField
                                      v-model="input.options.backgroundColor"
                                      solo
                                      clearable
                                      slot="trigger"
                                      label="(Optional) Background color"
                                    />
                                  </Swatches>
                                </VCol>
                                <VCol cols="12">
                                  <Swatches
                                    v-model="input.options.textColor"
                                    show-fallback
                                    swatches="text-advanced"
                                    style="width: 100%"
                                    :trigger-style="{ width: '100%' }"
                                  >
                                    <TextField
                                      v-model="input.options.textColor"
                                      solo
                                      clearable
                                      slot="trigger"
                                      label="(Optional) Text color"
                                    />
                                  </Swatches>
                                </VCol>
                              </VRow>
                            </template>
                            <template v-if="isTextInput(input.type)">
                              <TextField
                                label="Max length"
                                :value="input.options.maxLength"
                                @input="(value) =>
                                  onUpdateInputOption(input, 'maxLength', value)
                                "
                              >
                                <template #append>
                                  <VSwitch
                                    v-model="input.options.useWords"
                                    inset
                                    small
                                    hide-details
                                    label="Use Words?"
                                    class="mt-0 align-label metadata-switch"
                                    @click.stop="() => { }"
                                  />
                                </template>
                              </TextField>
                            </template>
                            <h3 class="caption pl-2">
                              (Optional) Enter a caption
                            </h3>
                            <EditorBubble
                              style="width: 100%; margin-bottom: 0rem"
                              :value="input.options.caption"
                              @input="(value) => onUpdateInputOption(input, 'caption', value)
                              "
                            />
                          </VExpansionPanelContent>
                        </VExpansionPanel>
                      </VExpansionPanels>
                    </template>
                  </template>
                  <div
                    v-if="(newFieldsView && !hideLastDivider) || (hideLastDivider && key < group.length - 1)"
                    class="grey lighten-2 rounded-0 mt-5"
                    style="height: 6px; margin: 0 -18px"
                  />
                </div>
              </transition-group>
            </Draggable>
          </VCardText>
        </div>
      </transition-group>
    </Draggable>
    <template v-if="!fieldsOnly && !hideFields">
      <VRow
        v-if="canAddMoreInputs"
        row
        justify-space-between
        class="dynamic-input-add"
      >
        <VSpacer />

        <VBtn
          v-if="choices.length == 1"
          slot="activator"
          class="dynamic-input mt-3 mr-3"
          large
          text-icon="add"
          style="box-shadow: none; border: solid 1px #e3e3e3"
          @click.prevent="onAddInput(choices[0])"
        >
          <VIcon
            left
            dark
            medium
          >
            add
          </VIcon>
          {{ appendText }}
        </VBtn>
        <VMenu
          v-else
          top
          auto
          offset-y
        >
          <template #activator="{ on }">
            <VBtn
              v-on="on"
              slot="activator"
              class="dynamic-input mr-3 mt-3"
              style="box-shadow: none; border: solid 1px #e3e3e3"
              text-icon="add"
            >
              <VIcon
                left
                dark
                medium
              >
                add
              </VIcon>
              {{ appendText }}
            </VBtn>
          </template>
          <VList>
            <VListItem
              v-for="(choice, key) in choices"
              :key="`add-input-${key}`"
              @click.prevent="onAddInput(choice)"
            >
              <VListItemTitle>{{ (choice || {}).text }}</VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
      </VRow>
    </template>
    <Confirm
      ref="confirmDelete"
      alt-title="Delete Page?"
      :alt-view="confirmAltView"
    />
  </VSkeletonLoader>
</template>

<script>
import Vue from "vue";
import Swatches from "vue-swatches";
import Draggable from "vuedraggable";
import DynamicInputsMixin from "@/mixins/DynamicInputs";
import DynamicInputsFormMixin from "@/mixins/DynamicInputsForm";
import DeletedItemsMixin from "@/mixins/DeletesItems";
import DynamicInput from "@/components/Elements/Data/DynamicInput";
import ChoiceList from "./ChoiceList";
import NumberInput from "@/components/Elements/Data/NumberInput";

export default {
  name: "DynamicInputs",
  components: {
    Draggable,
    Swatches,
    DynamicInput,
    ChoiceList,
    NumberInput,
  },
  mixins: [DynamicInputsMixin, DynamicInputsFormMixin, DeletedItemsMixin],
  props: {
    valuesFromUser: {
      type: Boolean,
      default: false,
    },
    restoreMode: {
      type: String,
      default: "auto",
    },
    mode: {
      type: String,
      default: "multiple",
    },
    max: {
      type: Number,
      default: -1,
    },
    enableSections: {
      type: Boolean,
      default: true,
    },
    enableDefaultValues: {
      type: Boolean,
      default: false,
    },
    enableExtraOptions: {
      type: Boolean,
      default: false,
    },
    defaultValueOptions: {
      type: [Object, Array],
      default: () => [],
    },
    customClass: {
      type: String,
      default: null,
    },
    enableSectionsValue: {
      type: Boolean,
      default: true,
    },
    enableSectionsBackground: {
      type: Boolean,
      default: true,
    },
    showDeleteBtnText: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },
    hideFields: {
      type: Boolean,
      default: false,
    },
    fieldsOnly: {
      type: Boolean,
      default: false,
    },
    newFieldsView: {
      type: Boolean,
      default: false,
    },
    hideLastDivider: {
      type: Boolean,
      default: false,
    },
    normalActionsView: {
      type: Boolean,
      default: true,
    },
    formFiles: {
      type: Array,
      default: null,
    },
    defaultSectionTitle: {
      type: String,
      default: null,
    },
    questionsListClass: {
      type: String,
      default: "ml-2",
    },
    questionsListStyle: {
      type: String,
      default: "width: fit-content;",
    },
  },
  data () {
    return {
      inited: false,
      editSection: false,
      addQuestionsMenu: false,
      confirmAltView: false,
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      currentChoice: null,
      tempSectionTitle: this.defaultSectionTitle,
      inputSectionMenus: {},
      deletedGroups: {},
      choiceTypeOptions: [
        {
          text: "Dropdown",
          value: "Dropdown",
        },
        {
          text: "Checkboxes",
          value: "Checkbox",
        },
        {
          text: "Single Select",
          value: "Radio",
        },
      ],
      choiceSingleMultipleOptions: [
        {
          text: "Single",
          value: "single",
        },
        {
          text: "Multiple",
          value: "multiple",
        },
      ],
      defaultChoice: [
        {
          text: "Request File",
          value: "File",
          icon: "mdi-image-outline",
          image: require("../../../images/forms/paper-plus@2x.png"),
        },
        {
          text: "Request Video",
          value: "Video_Link",
          icon: "mdi-play-circle-outline",
          image: require("../../../images/forms/play-icon@2x.png"),
        },
        {
          text: "Google Drive",
          value: "Google Drive",
          icon: "mdi-google-drive",
          image: false,
        },
      ],
      sectionRemoveMessage: this.newFieldsView ? "Do you want to delete this page? Deleting the page will also delete all questions within that page." : null,
    };
  },
  watch: {
    "value.id": function (id) {
      console.log("Value", id);
      if (!this.inited && value) {
        switch ((this.value || {}).type) {
          case "Relation":
            this.asyncInputs.options[this.value.realIndex] = [this.value.value];
            break;
        }
        this.inited = true;
      }
    },
    defaultSectionTitle: function (value) {
      this.tempSectionTitle = value;
    },
  },
  mounted () {
    if (this.mode === "standalone") {
      this.$emit("validated");
    }
    this.prepareValue();
    this.inited = true;
  },
  computed: {
    /**
     * Choices in the format
     * [
     * `  {
     *        text: The title of the choice,
     *        value: The type of the choice,
     *        icon: The icon to use for the choice
     *        options; The options for the input,
     *        asyncKey: The key to use to match options for the input when type in [Relation]
     *        loadFrom (Required): The callback to use to load options when type in [Relation]
     *        setValue(Optional): The function to use to set the value for the input
     *    },
     *    ...
     * ]
     */
    choices () {
      let choices =
        this.choiceMode == "user"
          ? this.inputChoices
          : [].concat(this.localChoices, this.inputChoices);
      if (this.enableCardInputs) {
        choices.push({
          text: "Stack Cards",
          value: "Stack",
          icon: "mdi-animation-outline",
        });
        choices.push({
          text: "Choice List",
          value: "List",
          icon: "mdi-format-align-justify",
        });
        choices.push({
          text: "Choice Grid",
          value: "Grid",
          icon: "mdi-grid",
        });
      }
      if (this.enableFiles) {
        choices.push(
          {
            text: "File Upload",
            value: "File",
            icon: "attachment",
          },
          {
            text: "Google Drive",
            value: "Google Drive",
            icon: "mdi-google-drive",
          }
        );
      }
      return choices;
    },
    activeChoices () {
      return this.choices.filter(
        (choice) => choice.value != "Video_Link" && choice.value != "ActionUrl"
      );
    },
    titleText () {
      return this.title && this.title.length ? this.title : this.getPlaceholderText({});
    },
    $v () {
      if (this.validator instanceof Array) {
        return this.validator;
      } else if (
        this.validator instanceof Object &&
        this.validator.$each instanceof Object &&
        this.validator.$each.$iter instanceof Object
      ) {
        return Object.values(this.validator.$each.$iter);
      } else {
        return [];
      }
    },
    getAllInputsGrouped () {
      if (this.newFieldsView) {
        return {
          [this.defaultSectionTitle]: this.allInputsGrouped[this.defaultSectionTitle],
        };
      }
      return this.allInputsGrouped;
    },
    firstActionsColStyle() {
      if(this.normalActionsView) {
        if(this.$showLeftMainMenu || this.$vuetify.breakpoint.mdAndDown) {
          return 3;
        }
        return 6;
      } else {
        if(this.$showLeftMainMenu || this.$vuetify.breakpoint.mdAndDown) {
          return 12;
        }
        return 4;
      }
    },
    secondActionsColStyle() {
      if(this.normalActionsView) {
        if(this.$showLeftMainMenu || this.$vuetify.breakpoint.mdAndDown) {
          return 9;
        }
        return 6;
      } else {
        if(this.$showLeftMainMenu || this.$vuetify.breakpoint.mdAndDown) {
          return 12;
        }
        return 8;
      }
    },
  },
  methods: {
    updateInput () { },
    prepareValue (reset = false) {
      if ((this.value || {}).type == "Relation") {
        const current = this.asyncInputs.options[this.value.realIndex || 0];
        if ((current instanceof Array && !current.length) || !current) {
          this.onGetAsyncInputOptions(this.value, true);
        }
      } else if (this.value instanceof Array && this.value.length) {
        this.value.forEach((value, index) => {
          if ((value || {}).type == "Relation") {
            const current = this.asyncInputs.options[value.realIndex || 0];
            if ((current instanceof Array && !current.length) || !current) {
              this.$log.info("Checking for async", value, index);
              this.onGetAsyncInputOptions(value, true);
            }
          }
          if (this.newFieldsView && this.isOptionsInput((value || {}).type)) {
            this.value[index].options.showAddBtn = true;
            this.value[index].options.editOptionKey = null;
            if (value.type == "Radio") {
              this.value[index].options.selectedOption = null;
            }
          }
          if (this.newFieldsView && this.isPreviewableInput((value || {}).type)) {
            if ((value || {}).options.buttonTitle === undefined) {
              this.value[index].options.buttonTitle = "Button Title";
            }
            this.value[index].options.showFields = false;
          }
          if (this.newFieldsView && (value || {}).section === null) {
            this.value[index].section = this.defaultSectionTitle;
          }
          if ((value || {}).openAdvancedSettingsPanel === undefined) {
            this.value[index].openAdvancedSettingsPanel = [];
          }
          if ((value || {}).sectionMenu === undefined) {
            this.value[index].sectionMenu = false;
          }
          if ((value || {}).sectionVisibility === undefined) {
            this.value[index].sectionVisibility = true;
          }
          if ((value || {}).showTitle === undefined) {
            this.value[index].showTitle =
              this.value[index].name && this.value[index].name.length > 0;
          }
        });
        if (this.newFieldsView) {
          this.$emit("updateDefaultSectionTitle", this.value[0].section);
        }
      }
      if (reset) {
        this.allInputsGrouped = this.value;
      }
    },
    getDeleteIcon (input) {
      return input.deleted ? "refresh" : "delete";
    },
    getDeleteText (input) {
      return input.deleted ? "Restore" : "Delete";
    },
    getValuePlaceholderFromType (type) {
      let result = "value";
      switch ((type || "").toLowerCase()) {
        case "url":
        case "actionurl":
          result = "url";
          break;
      }
      return result;
    },
    getPlaceholderText (input) {
      const valueTitle = this.getValuePlaceholderFromType(input.type);
      if (this.enableDefaultValues) {
        const defaultValue = (input || {}).default_value || {};
        if (defaultValue instanceof Object && defaultValue.hasOwnProperty("from") && defaultValue.from) {
          const text = (
            (this.defaultValueOptions || []).find(
              (o) => o instanceof Object && o.value === defaultValue.from
            ) || {}
          ).text;
          return text
            ? `${valueTitle.titleize()} will pre-populate${text ? " from: " + text : ""}`
            : `${this.$team.user_role_name} will add ${valueTitle}`;
        }
      }

      if (this.getAcceptsAdminValues(input)) {
        return `Enter ${valueTitle}`;
      }

      return this.valuesFromUser
        ? `${this.$team.user_role_name} will add ${valueTitle}`
        : `Enter ${valueTitle}`;
    },
    getDefaultCaptionFor (type) {
      switch (type) {
        case "ActionUrl":
          return "Please proceed to payment below. After payment is complete, please return to this page.";
          break;
      }
      return "";
    },
    getIsRequiredByDefault (choice) {
      return ["ActionUrl"].indexOf(choice.value) > -1;
    },
    getSupportsAdvancedOptions (choice) {
      return ["ActionUrl"].indexOf(choice.value) == -1;
    },
    getSupportsDefaultValues (input) {
      return ["Url", "ActionUrl", "Stack", "Grid", "List"].indexOf(input.type) == -1;
    },
    getAcceptsAdminValues (input) {
      return ["ActionUrl"].indexOf(input.type) > -1;
    },
    prepareNewInput (choice) {
      const type = choice.value;
      let realValue = {
        type,
        value: null,
        section: this.listView || this.newFieldsView ? this.defaultSectionTitle : null,
        sectionMenu: false,
        sectionVisibility: this.listView,
        showTitle: false,
        options: {
          type,
          caption: this.getDefaultCaptionFor(choice.key),
        },
        is_required: this.getIsRequiredByDefault(choice),
        openAdvancedSettingsPanel: [],
        default_value: {
          from: "",
          value: "",
        },
      };
      realValue.realIndex =
        (this.value || []).length > 0 ? (this.value || []).length + 1 : 0;
      realValue.priority = (this.value || []).length + 1;
      switch (type) {
        case "Dropdown":
          realValue.options.showAddBtn = true;
          realValue.options.editOptionKey = null;
          break;

        case "Checkbox":
          realValue.options.showAddBtn = true;
          realValue.options.editOptionKey = null;
          realValue.options.multiple = "multiple";
          realValue.options.options = [];
          break;

        case "Radio":
          realValue = {
            ...realValue,
            ...{
              options: {
                type: type,
                showAddBtn: true,
                editOptionKey: null,
                selectedOption: null,
                multiple: type === "Checkbox" ? "multiple" : "single",
                options: [],
              },
            },
          };
          break;

        case "Rating":
          realValue = {
            ...realValue,
            ...{
              options: {
                length: 5,
              },
            },
          };
          break;

        case "Relation":
          this.$set(this.asyncInputs.options, realValue.priority, []);
          this.$set(this.asyncInputs.loading, realValue.priority, false);
          this.$set(this.asyncInputs.search, realValue.priority, null);
          realValue.asyncKey = choice.asyncKey;
          realValue.options = {
            ...realValue.options,
            ...{
              asyncKey: choice.asyncKey,
              asyncType: choice.asyncType,
            },
          };
          this.onGetAsyncInputOptions(realValue, true);
          break;

        case "Stack":
          realValue = {
            ...realValue,
            options: {
              background_color: "",
              emoji: "",
              options: [
                {
                  title: "",
                  alignment: "left",
                  description: "",
                  background_color: "",
                  image: "",
                  options: [],
                  type: "Stack",
                },
              ],
            },
          };
          break;

        case "List":
          realValue = {
            ...realValue,
            options: {
              type: null,
              background_color: "",
              emoji: "",
              options: [
                {
                  title: "",
                  alignment: "left",
                  description: "",
                  background_color: "",
                  image: "",
                  options: [],
                  type: "List",
                },
              ],
            },
          };
          break;

        case "Grid":
          realValue = {
            ...realValue,
            options: {
              type: null,
              background_color: "",
              emoji: "",
              options: [
                {
                  title: "",
                  alignment: "left",
                  description: "",
                  background_color: "",
                  image: "",
                  options: [],
                  type: "Grid",
                },
              ],
            },
          };
          break;
      }

      switch (choice.value) {
        case "ActionUrl":
          realValue.options.showFields = false;
          realValue.options.buttonTitle = "Button Title";
          break;

        case "Url":
          realValue.options.buttonTitle = "Button Title";
          realValue.options.showFields = false;
          break;
      }
      return realValue;
    },
    $vError (index, field, _isOptionField) {
      return this.$v.length && this.$v[index] instanceof Object
        ? _isOptionField
          ? this.$v[index].options[field].$error
          : this.$v[index][field].$error
        : false;
    },
    onAddInput (value) {
      this.$log.debug("DynamicInputs: Adding input", value);
      this.onNewInput(this.prepareNewInput(value));
    },
    updateInputSection (section, input, key) {
      if (section != this.defaultSectionTitle) {
        this.$set(input, "section", section);
        this.onUpdateSingleInput(input);
        this.$nextTick(() => {
          this.$refs[`section-menu-${key}`][0].setValue(this.defaultSectionTitle);
        });
      }
    },
    onRemoveLocalInput (input) {
      if (input instanceof Object && this.restoreMode === "auto" && input.deleted) {
        this.onRestoreInput(input);
      } else if (input instanceof Object && input.id && !this.isSingleInputMode) {
        this.$set(input, "deleted", true);
        this.onUpdateSingleInput(input);
      } else {
        this.$log.debug("DynamicInputs: Removing input", input);
        this.onRemoveInput(input);
      }
      if (input.section) {
        // Let's mark the section as deleted if we delete all inputs in a section
        const groupIndex = this.inputSections.indexOf(input.section);
        const group = this.allInputsGroupedValues[groupIndex];
        if (group instanceof Array && group.length) {
          if (group.length === group.filter((i) => i.deleted === true).length) {
            this.deletedGroups[input.section] = true;
          } else {
            delete this.deletedGroups[input.section];
          }
        }
      }
      this.onSortInputs(this.value);
    },
    addNewStackCard (key) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      input.options.options.push({
        title: "",
        alignment: "left",
        description: "",
        background_color: "",
        image: "",
        options: [],
        type: "Stack",
      });
      this.$log.debug("Stack Card Added", key, input);
      this.$nextTick(() => {
        var container = this.$el.querySelector(`#stack-cards-${key}`);
        container.scrollLeft += container.scrollWidth;
      });
    },
    addNestedCard (key, optionKey) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      input.options.options[optionKey].options.push({
        title: "",
        alignment: "left",
        description: "",
        background_color: "",
        emoji: "",
        image: "",
        type: input.type,
      });
      this.$log.debug("Grid/List More Card Added", key, input);
    },
    addNewCard (key) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      input.options.options.push({
        title: "",
        alignment: "left",
        description: "",
        background_color: "",
        emoji: "",
        image: "",
        options: [],
        type: input.type,
      });
      this.$log.debug("Grid/List New Card Added", key, input);
    },
    onRemoveCard (key, optionKey, nestedOptionKey) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      if (nestedOptionKey === null) {
        this.$delete(input.options.options, optionKey);
      } else {
        this.$delete(input.options.options[optionKey].options, nestedOptionKey);
      }
    },
    onEditOptionsInput (key, choiceKey) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      this.$set(input.options, "editOptionKey", choiceKey);
      this.$set(input.options, "showAddBtn", false);
      this.onUpdateSingleInput(input);
    },
    onUpdateOptionsInput (key) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      this.$set(input.options, "showAddBtn", true);
      this.$set(input.options, "editOptionKey", null);
      input.options.options = input.options.options.filter((option) => option != "");
      this.onUpdateSingleInput(input);
    },
    onAddOptionsInput (key, value, refKey) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      input.options.options =
        input.options.options instanceof Array
          ? input.options.options
          : Vue.observable([]);
      input.options.options.push(value || "");
      if (refKey && this.$refs.hasOwnProperty(refKey)) {
        this.$refs[refKey][0].reset();
      }
      this.$set(input, "type", input.type || "Dropdown");
      this.$set(input.options, "type", input.options.type || "Dropdown");
      this.onUpdateSingleInput(input);
      this.$log.debug("Added", key, input);
    },
    onRemoveOptionsInput (key, choiceKey) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      if (
        input instanceof Object &&
        input.options instanceof Object &&
        input.options.options instanceof Array
      ) {
        this.$log.debug("Removing", choiceKey, "from", input, key, "in", this.value);
        this.$set(input, "type", input.type || "Dropdown");
        this.$set(input.options, "type", input.options.type || "Dropdown");
        this.$delete(input.options.options, choiceKey);
        this.onUpdateSingleInput(input);
      }
    },
    onRadioOptionSelection (key, option) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      this.$set(input.options, "selectedOption", option);
      this.onUpdateSingleInput(input);
    },
    onSetOptionsInputType (type, key) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      if (input instanceof Object && input.options instanceof Object) {
        input.options.type = type;
        this.$set(input.options, "multiple", type === "Checkbox" ? "multiple" : "single");
        // this.$set(this.allInputs, key, input);
        this.$log.debug(
          "Changing option type to",
          type,
          "for",
          key,
          "in",
          this.value,
          input
        );
        this.onUpdateSingleInput(input);
      }
    },
    onSetOptionsInputSingleMultiple (type, key) {
      const input = this.value instanceof Array ? this.value[key] : this.value;
      if (input instanceof Object && input.options instanceof Object) {
        input.options.multiple = type === "multiple" ? "multiple" : "single";
        // this.$set(this.allInputs, key, input);
        this.$log.debug(
          "Changing multiple single type to",
          type,
          "for",
          key,
          "in",
          this.value
        );
        this.onUpdateSingleInput(input);
      }
    },
    onUpdateInputOption (input, property, value) {
      this.debouncer(() => {
        Vue.set(input.options, property, value);
        this.onUpdateSingleInput(input, input);
      });
    },
    onUpdateInpuDefaultValueOption (input, property, value) {
      input.default_value =
        input.default_value instanceof Object &&
          input.default_value.constructor !== Object
          ? {}
          : input.default_value;
      this.debouncer(() => {
        Vue.set(input.default_value, property, value);
        this.onUpdateSingleInput(input, input);
      });
    },
    onEnd (groupName, group) {
      let groupIndex = this.allInputsGroupedValues.findIndex((group) => {
        let groupInput = group.find((input) => input.section === groupName);
        return groupInput;
      });
      this.$set(this.allInputsGroupedValues, groupIndex, group);
      this.allInputs = this.allInputsGroupedValues.flat();
    },
    onSetSection (input, value, groupIndex, key, event) {
      console.log("Updating section", ...arguments);
      if (event instanceof Event) {
        event.preventDefault();
      }
      this.onUpdateSection(input, value);
      console.log("Group Index", input, value, groupIndex, key, event);
      if (input.realIndex != undefined) {
        if (value) {
          console.log("Setting group section on ", input.realIndex, value, input);
          this.$set(this.allInputs[input.realIndex], "section", value);
          this.$set(this.allInputs[input.realIndex], "sectionMenu", false);
        } else {
          this.onClearSection(input, groupIndex, key, event);
        }
      } else {
        if (value) {
          console.log("Setting section on ", value, input);
          this.$set(input, "section", value);
          this.$set(input, "sectionMenu", false);
        } else {
          this.onClearSection(input, groupIndex, key, event);
        }
      }
      this.onUpdateSingleInput(input);
    },
    onClearSection (input, groupIndex, key, event) {
      console.log("Clearing section", ...arguments);
      if (event instanceof Event) {
        event.preventDefault();
      }
      this.onUpdateSection(input, null);
      console.log("Clearing Group Index", input, groupIndex, key, event);
      if (input.realIndex != undefined) {
        console.log("Deleting group section on ", input.realIndex, input);
        this.$delete(this.allInputs[input.realIndex], "section");
      } else {
        console.log("Deleting section on ", input);
        this.$delete(input, "section");
      }
      this.onUpdateSingleInput(input);
    },
    onSetSectionAndCloseMenu (input, value, groupIndex, key, event) {
      console.log(
        "Setting section and closing menu",
        input,
        value,
        groupIndex,
        key,
        event
      );
      if (value) {
        this.onSetSection(input, value, groupIndex, key);
      }
      this.onCloseSectionMenu(input, groupIndex, key);
    },
    onCloseSectionMenu (input, groupIndex, key) {
      this.$set(input, "sectionMenu", false);
      const ref = `input-section-${groupIndex}-${key}`;
      this.$log.debug(input, ref, this.$refs);
      this.$refs[ref][0].blur();
    },
    onSortInputs (inputs) {
      this.$emit(
        "sorted",
        inputs
          .map((input, key) => {
            input.realIndex = input.priority = key;
            return input;
          })
          .sortBy("realIndex")
      );
    },
    onSectionMoved (event) {
      if (event && event.moved) {
        const grouped = this.allInputsGroupedValues.slice();
        grouped.splice(
          event.moved.newIndex,
          0,
          grouped.splice(event.moved.oldIndex, 1)[0]
        );
        this.allInputs = grouped.flat();
      }
    },
    onRemoveInputs (groupIndex, group, groupName) {
      if (this.deletedGroups[groupName]) {
        delete this.deletedGroups[groupName];
        this.onRemoveMultipleInputs(group);
        this.$emit("remove:inputs", group);
      } else {
        this.confirmAltView = this.newFieldsView;
        this.onDeleteItem(
          null,
          () => {
            this.deletedGroups[groupName] = true;
            this.onRemoveMultipleInputs(group);
            this.$emit("remove:inputs", group);
            this.$emit("removeSection", groupName);
          },
          this.sectionRemoveMessage
        );
      }
    },
    onToggleInputRequired (input) {
      this.$set(input, "is_required", input.is_required ? false : true);
      this.onUpdateSingleInput(input, input);
    },
    onUpdateSectionsForGroup(event, group, groupName) {
      this.$log.info(
        "Updating section for group",
        event.target.value,
        this.allSections[event.target.value],
        groupName
      );
      const newSectionTitle =
        event.target.value ||
        (this.newFieldsView ? this.defaultSectionTitle : event.target.value);
      if (!this.allSections.hasOwnProperty(event.target.value)) {
        this.allSections[groupName] = {
          title: newSectionTitle,
          description: this.allSections[groupName].description || "",
        };
      }
      if (group instanceof Array && group.length) {
        if (event instanceof Object && event.target instanceof Object) {
          group.map((input) => {
            this.$set(input, "section", newSectionTitle);
          });
        }
        this.$emit("update:inputs", group);
        this.onUpdateSections();
      }
      this.editSection = false;
      this.$emit("updateDefaultSectionTitle", this.tempSectionTitle);
    },
    onFocusInput (ref) {
      this.$nextTick(() => {
        const $ref = this.$refs[ref];
        if ($ref instanceof Object) {
          if (
            $ref instanceof Array &&
            $ref[0].hasOwnProperty("$refs") &&
            $ref[0].$refs instanceof Object &&
            $ref[0].$refs.hasOwnProperty("input") &&
            $ref[0].$refs.input instanceof Object
          ) {
            this.$log.debug("Focusing", [
              ref,
              this.$refs,
              this.$refs[ref],
              this.$refs[ref][0].$refs.input,
            ]);
            setTimeout(() => {
              $ref[0].focus();
              $ref[0].$refs.input.focus();
            }, 0);
          }
        }
      });
    },
    getInternalPageOptions () {
      return [
        {
          value: "Account",
          route: { name: "account" },
        },
        {
          value: "Chat",
          route: { name: "chat.index" },
        },
        {
          value: "Content",
          route: { name: "content.index" },
        },
        {
          value: "Deliverables",
          route: { name: "deliverable.index" },
        },
        {
          value: "FAQ",
          route: { name: "faq.index" },
        },
        {
          value: "Lessons",
          route: { name: "lesson.index" },
        },
        {
          value: "Users",
          route: { name: "user.index" },
        },
        {
          value: "Programs",
          route: { name: "program.index" },
        },
      ];
    },
    onDuplicate (input) {
      let tempInput = JSON.parse(JSON.stringify(input));
      delete tempInput.id;
      tempInput.priority = -1;
      tempInput.realIndex = tempInput.realIndex + 1;
      this.value.splice(tempInput.realIndex, 0, tempInput);
    },
    toggleSectionVisibility (groupName) {
      if (this.newFieldsView) {
        let tempValue = this.value.map((tempInput) => {
          if (groupName === tempInput.section) {
            tempInput.sectionVisibility = !tempInput.sectionVisibility;
          } else {
            tempInput.sectionVisibility = false;
          }
          return tempInput;
        });
        this.onUpdateMultipleInput(tempValue);
      }
      this.onToggleSectionVisibility(groupName);
    },
    onSaveSectionsForGroup (groupIndex, group, groupName) {
      if (!this.editSection) {
        this.onUpdateSectionsForGroup(
          {
            target: this.$refs[`updateSection${groupIndex}`][0],
          },
          group,
          groupName,
        );
      } else {
        this.$nextTick(function () {
          this.$refs[`updateSection${groupIndex}`][0].blur();
          this.$emit("updateDefaultSectionTitle", this.tempSectionTitle);
        });
      }
    },
    getSectionDescrPlaceholder (sectionName) {
      if (this.newFieldsView) {
        return "(Optional) Enter a description for this page";
      }
      return `(Optional) Enter a description for the [${sectionName}] section`;
    },
  },
};
</script>

<style lang="scss">
:root {
  .caption {
    a {
      text-decoration: underline;
    }
  }

  .align-label {
    .v-label {
      top: 0px;
    }
  }

  .vue-swatches__trigger__wrapper {
    width: 100%;
  }

  .menubar {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.metadata-switch .v-input--selection-controls__input {
  border: none !important;
}

.stick-to-bottom {
  position: absolute;
  bottom: 0;
}

.remove-vertical-line {
  .dynamic-input-container .v-input__append-outer:before {
    background-color: transparent !important;
  }
}

.dynamic-input-border {
  border-radius: 10px !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.required-tooltip {
  color: #252526;
  font-weight: 500;
  background-color: #ffffff;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.12);
}

.required-tooltip.menuable__content__active {
  opacity: 1 !important;
}

.custom-radio {
  border: none !important;

  .v-input--selection-controls__input {
    border: solid thin #ddd !important;
    border-radius: 50% !important;
  }
}

.questions-list {
  .v-btn::before {
    display: none;
  }

  .v-btn:hover {
    transform: none !important;
  }
}
</style>
