<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <VRow
        row
        wrap
      >
        <VCol>
          <DataViewUI
            :data="data"
            :headers="headers"
            :is-loading="activity.isLoading"
            :hide-main-title="hasExistingData"
            :hide-records-info="hasExistingData"
            :hide-actions="hasExistingData"
            :disable-sort="hasExistingData"
            :class="{
              'pa-3 pt-0': hasExistingData,
            }"
            @paginate="onGetMore"
            @search="onSearch"
          >
            <template
              slot="items-cells"
              slot-scope="props"
            >
              <template>
                <td>
                  <template v-if="$isUserAdmin">
                    <QuickActions
                      icon=""
                      max-width="calc(100vw * 0.17)"
                      :icon-button="false"
                      :title="props.item.title"
                      :actions="[
                        {
                          type: 'switch',
                          value: props.item.is_active,
                          description: 'Enable/Disable this',
                          action: () => onToggleStageActive(props.item),
                        },
                      ]"
                    />
                    <Linkable
                      :url="
                        getFullyQualifiedUrlForItem(
                          `${modelTypeRoute}.view`,
                          props.item.id
                        )
                      "
                    />
                  </template>
                  <template v-else>
                    <VChip>{{ props.item.title }}</VChip>
                    <Linkable
                      :url="
                        getFullyQualifiedUrlForItem(
                          `${modelTypeRoute}.view`,
                          props.item.id
                        )
                      "
                    />
                  </template>
                </td>
                <td>{{ getContentType(props.item) }}</td>
                <td>
                  <VChip
                    v-if="props.item.is_active"
                    :text-color="props.item.is_expired ? 'white' : 'white'"
                    :color="props.item.is_expired ? 'warning' : 'success'"
                  >
                    {{ (props.item.status || "").replace("-", "").titleize() }}
                  </VChip>
                  <VChip v-else>
                    {{ (props.item.status || "").replace("-", "").titleize() }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    :text-color="props.item.applications_count ? 'white' : 'default'"
                    :color="props.item.applications_count ? 'black' : 'default'"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item)"
                  >
                    <VAvatar
                      left
                      class="grey lighten-3 black--text"
                    >
                      {{ props.item.applications_count || 0 }}
                    </VAvatar>
                    across all {{ featureName("Stages", "pluralize").toLowerCase() }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <template v-if="$isUserAdmin">
                    <Tooltip
                      v-if="!props.item.stages_count"
                      small-icon
                      icon="edit"
                      color="error"
                      text-color="white"
                      :button-text="`Add ${featureName('Stages')}`"
                      :as-button="false"
                      :title="`There are no ${featureName(
                        'stages'
                      ).pluralize()} for this ${getPipelineType(props.item).toLowerCase()}!`"
                      :action="() => $router.push({
                        name: `${modelType}.update`,
                        params: {
                          id: props.item.id,
                          team: $team.slug,
                        },
                      })"
                    />
                    <VBtn
                      v-else
                      :color="props.item.stages_count > 0 ? 'info' : 'warning'"
                      :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                      @click.prevent="$router.push({
                        name: `${modelType}.update`,
                        params: {
                          id: props.item.id,
                          team: $team.slug,
                        },
                      })"
                    >
                      {{ props.item.stages_count || 0 }}
                      <VIcon
                        small
                        class="ml-3"
                      >
                        visibility
                      </VIcon>
                    </VBtn>
                  </template>
                  <template v-else>
                    {{ props.item.stages_count || 0 }}
                  </template>
                </td>
                <!--
                <td class="text-sm-center">
                  <VChip
                    :text-color="props.item.current_stage_submitted_count ? 'white' : 'default'"
                    :color="props.item.current_stage_submitted_count ? 'blue lighten-1' : 'default'"
                  >
                    {{ props.item.current_stage_submitted_count || 0 }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    text-color="white"
                    :color="props.item.current_stage_completed_count ? 'success' : 'warning'"
                  >
                    {{ props.item.current_stage_completed_count || 0 }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    text-color="white"
                    :color="props.item.current_stage_outstanding_count ? 'error' : 'success'"
                  >
                    {{ props.item.current_stage_outstanding_count || 0 }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    :text-color="props.item.submitted_count ? 'white' : 'default'"
                    :color="props.item.submitted_count ? 'blue lighten-1' : 'default'"
                  >
                    {{ props.item.submitted_count || 0 }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    text-color="white"
                    :color="props.item.completed_count ? 'success' : 'warning'"
                  >
                    {{ props.item.completed_count || 0 }}
                  </VChip>
                </td> -->
                <td class="text-sm-center">
                  <VSelect
                    v-if="props.item.stages_count > 0 && $isUserAdmin"
                    v-model="props.item.current_item_id"
                    solo
                    hide-details
                    item-text="title"
                    item-value="id"
                    :placeholder="`Select ${featureName('Stage')}`"
                    :items="props.item.stages"
                    :loading="props.item.isSaving"
                    :disabled="props.item.isSaving"
                    @input="(value) => onSetCurrentStage(props.index, value)"
                  />
                  <VChip
                    v-else-if="!props.item.stages_count"
                    text-color="white"
                    color="error"
                  >
                    {{ `No ${featureName("Stage", "pluralize")}!` }}
                  </VChip>
                  <VChip v-else>
                    {{
                      (
                        (props.item.stages || []).find(
                          (s) => s.id == props.item.current_item_id
                        ) || {}
                      ).title || missingStageText
                    }}
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VSelect
                    v-if="props.item.stages_count > 0 && $isUserAdmin"
                    solo
                    hide-details
                    item-text="title"
                    item-value="id"
                    :placeholder="`Select Onboarding ${featureName('Stage')}`"
                    :items="
                      props.item.stages.map((s) => {
                        s.title = s.title || missingStageText;
                        return s;
                      })
                    "
                    :value="(props.item.onboarding_stage || {}).id"
                    :loading="props.item.isSaving"
                    :disabled="props.item.isSaving"
                    @input="(value) => onSetOnboardingStage(props.index, value)"
                  />
                  <VChip
                    v-else-if="!props.item.stages_count"
                    text-color="white"
                    color="error"
                  >
                    {{ `No ${featureName("Stage", "pluralize")}!` }}
                  </VChip>
                  <VChip v-else>
                    {{
                      (
                        (props.item.stages || []).find(
                          (s) => s.id == props.item.current_item_id
                        ) || {}
                      ).title || missingStageText
                    }}
                  </VChip>
                </td>
              </template>
              <td class="actions">
                <Menu :disabled="getIsDisabled(props.item)">
                  <VListItem
                    v-if="$isUserAdmin"
                    :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                    @click.prevent="
                      $router.push({
                        name: `${modelType}.update`,
                        params: {
                          id: props.item.id,
                          team: $team.slug,
                        },
                      })
                    "
                  >
                    <VListItemTitle>
                      <VIcon small>
                        edit
                      </VIcon>&nbsp;Edit
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                    @click.prevent="onSetViewDialogModel(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        visibility
                      </VIcon>&nbsp;View
                    </VListItemTitle>
                  </VListItem>
                  <VListItem 
                    v-if="!$isUserStudent"
                    @click="openPreviewDialog(props.item.id)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        mdi-file-eye-outline
                      </VIcon>&nbsp;Preview
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-if="$isUserAdmin"
                    @click.prevent="onDeleteItem(props.item, null, null, false)"
                  >
                    <VListItemTitle color="error">
                      <VIcon
                        small
                        color="error"
                      >
                        delete
                      </VIcon>&nbsp;Delete
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-if="$isUserAdmin || $isUserMentor"
                    :to="{
                      hash: '#pipelines-export',
                      query: {
                        pipeline_id: props.item.id,
                      },
                    }"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        north
                      </VIcon>&nbsp; Export
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-if="$isUserAdmin"
                    :href="getHrefForItem(`${modelTypeRoute}.update`, { 
                      params: { 
                        id: props.item.id 
                      }, 
                      query: {
                        all: true,
                      },
                      hash: '#submitted' 
                    })"
                    @click.prevent="$router.push({
                      name: `${modelType}.update`,
                      params: {
                        id: props.item.id,
                        team: $team.slug,
                      },
                      query: {
                        all: true,
                      },
                      hash: '#submitted',
                    })"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        list_alt
                      </VIcon>&nbsp;View Applicants
                    </VListItemTitle>
                  </VListItem>
                </Menu>
              </td>
            </template>
          </DataViewUI>
          <FormDialog
            v-if="currentModel && dialogs.form"
            fullscreen
            is-dialog-open
            :current-model="currentModel"
            :title="currentModel ? currentModel.title : ''"
            :model-type="modelType"
            @deleteItem="onDeleteItem"
            @toggle:form:dialog="onToggleFormDialog"
          >
            <Save
              ref="saveForm"
              mode="dialog"
              v-if="$isUserAdmin"
              :id="currentModel ? currentModel.id : null"
            />
            <Submit
              v-else
              ref="saveForm"
              mode="dialog"
              :pipeline="currentModel"
            />
          </FormDialog>
          <FormDialog
            v-if="currentModel && dialogs.stageForm"
            fullscreen
            is-dialog-open
            mode="dialog"
            model-type="PipelineStage"
            :title="`Stage: ${currentModel ? currentModel.title : ''}`"
            @deleteItem="onDeleteItem"
            @toggle:form:dialog="() => onToggleFormDialog('stageForm')"
          >
            <SaveStage
              v-if="$isUserAdmin"
              ref="saveForm"
              mode="dialog"
              :pipeline="currentModel"
              @saved="onGetAll"
            />
          </FormDialog>
          <Confirm ref="confirmDelete" />
          <Dialog
            v-if="dialogs.view"
            is-dialog-open
            max-width="75%"
            :title="`View ${currentModel ? getPipelineType(currentModel) : ''}`"
            @toggle:dialog="onToggleViewDialog"
          >
            <ViewComponent
              ref="viewItem"
              mode="dialog"
              :id="currentModel ? currentModel.id : null"
            />
          </Dialog>
          <Dialog
            v-if="dialogs.stages"
            is-dialog-open
            max-width="100%"
            :title="`View ${featureName('Stages').pluralize()} for ${currentModel.title}`"
            @toggle:dialog="onToggleStagesDialog"
          >
            <Stages
              ref="viewStages"
              mode="dialog"
              :pipeline="currentModel"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.submitted"
            fullscreen
            is-dialog-open
            :title="`Submitted: ${
              (submittedModel.evaluation_form || {}).title || 'Applications'
            }`"
            @toggle:dialog="onToggleSubmittedDialog"
          >
            <Submitted
              mode="dialog"
              :pipeline="submittedModel"
              :can-load-from-url="false"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.singleSubmitted"
            fullscreen
            is-dialog-open
            :title="`Submitted: ${(submittedModel.evaluation_form || {}).title}`"
            @toggle:dialog="onToggleSingleSubmittedDialog"
          >
            <ViewSubmittedComponent
              mode="dialog"
              :existing="submittedModel"
              :can-get="false"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.evaluation"
            is-dialog-open
            fullscreen
            :title="`Evaluate: ${submittedModel.title || '(not set)'}`"
            @toggle:dialog="onToggleEvaluationDialog"
          >
            <Evaluated
              mode="dialog"
              :content="submittedModel"
              :can-load-from-url="false"
              @toggle:evaluation-form="onToggleEditPipelineEvaluationForm"
              @update:evaluation-form="onUpdateEvaluationForm"
              @toggle:evaluations="onToggleCompletedEvaluationsDialog"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.completedEvaluations"
            is-dialog-open
            fullscreen
            :title="`Submitted Evaluations: ${submittedModel.title || '(not set)'}`"
            @toggle:dialog="onToggleCompletedEvaluationsDialog"
          >
            <template v-if="(submittedModel || {}).id">
              <VAlert
                outlined
                class="mt-2"
                :type="!submittedModel.evaluation_form_count ? 'error' : 'info'"
                :value="true"
              >
                <template v-if="!submittedModel.evaluation_form">
                  <p>
                    This {{ featureName("pipeline").singularize() }} is missing an
                    evaluation form!
                  </p>
                  <VBtn
                    text
                    @click.prevent="() => onToggleEditPipelineEvaluationForm()"
                  >
                    Add Evaluation Form
                  </VBtn>
                </template>
                <template v-else-if="submittedModel.evaluation_form instanceof Object">
                  Using evaluation form: {{ submittedModel.evaluation_form.title }}.
                  <span>
                    <VBtn
                      text
                      @click.prevent="() => onToggleEditPipelineEvaluationForm()"
                    >
                      <VIcon small>edit</VIcon>&nbsp;Edit
                    </VBtn>
                  </span>
                </template>
              </VAlert>
              <SubmittedEvaluations
                v-if="submittedModel.evaluation_form instanceof Object"
                mode="dialog"
                :deliverable-id="(submittedModel.evaluation_form || {}).id"
                :custom-filter="submittedEvaluationFilter"
              />
              <VAlert
                v-else
                type="error"
              >
                There are no submitted evaluations available. Please configure an
                evaluation form above.
              </VAlert>
            </template>
          </Dialog>
          <Dialog
            v-if="submittedDialogs.submit"
            fullscreen
            is-dialog-open
            :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
            :title="`Submit Pipelines${
              submittedModel ? ': ' + submittedModel.title : '(not set)'
            }`"
            :action="onSubmitPipeline"
            :close-immediately="false"
            @toggle:dialog="onToggleSubmitDialog"
          >
            <SubmitForm
              v-if="submitted"
              :pipeline="submittedModel"
              mode="dialog"
              :items="submitted.data"
              property="program_id"
              entity-type="App\Models\Program"
              ref="submitPipeline"
            />
          </Dialog>
          <FormDialog
            v-if="dialogs.addEvaluation"
            is-dialog-open
            persistent
            max-width="75%"
            title="Save Evaluation Form"
            model-type="PipelineEvaluationForm"
            style="z-index: 10000"
            @toggle:form:dialog="onToggleEditPipelineEvaluationForm"
          >
            <SaveEvaluationForm
              ref="saveEvaluationForm"
              mode="dialog"
              :id="submittedModel.id"
              :content="submittedModel"
              @update:form="onUpdateEvaluationForm"
            />
          </FormDialog>
          <StageSubmit
            v-if="dialogs.preview.status"
            readonly
            is-inputs-required
            mode="dialog"
            :is-loading="!dialogs.preview.item"
            :is-dialog-open="dialogs.preview.status"
            :stage="currentOnBoardingStage"
            :existing="currentOnBoardingStage"
            @toggle:dialog="closePreviewDialog"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStatsMixin from "@/mixins/HasStats";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasDueMixin from "@/mixins/HasDueOn";
import Save from "./Save";
import SaveStage from "./SaveStage";
import Stages from "./Stages";
import Submit from "./Submit";
import Submitted from "./Submitted";
import Evaluated from "./Evaluated";
import StageSubmit from "./StageSubmit";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./View";
import ViewSubmittedComponent from "./ViewSubmitted";
import { default as SubmittedEvaluations } from "@/components/Deliverables/Submitted";
import { mapActions } from "vuex";
import SaveEvaluationForm from "./SaveEvaluationForm";

export default {
  name: "PipelinesData",
  components: {
    Save,
    Stages,
    SaveStage,
    Submit,
    Submitted,
    Evaluated,
    SubmitForm,
    StageSubmit,
    ViewComponent,
    SaveEvaluationForm,
    SubmittedEvaluations,
    ViewSubmittedComponent,
  },
  mixins: [SearchMixin, HasStatsMixin, HasSubmittedDataMixin, HasDueMixin],
  data() {
    return {
      date: null,
      dialogs: {
        addEvaluation: false,
        stages: false,
        stageForm: false,
        preview: {
          status: false,
          item: null,
        },
      },
      autoloadViewModel: false,
      submittedEvaluationFilter: null,
      modelType: "pipeline",
      missingStageText: "ERROR: Missing Title!",
      statOptions: {
        inflect: {
          submitted: {
            plural: "Submitted",
          },
        },
      },
      config: {
        stats: {
          "-": "-",
        },
      },
    };
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Type",
          align: "left",
          sortable: true,
          value: "type",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          value: "status",
        },
        {
          text: "Total Applicants",
          align: "left",
          sortable: true,
          value: "applications_count",
        },
        {
          text: `# ${this.featureName("Stages", "pluralize")}`,
          align: "center",
          sortable: false,
          value: "stages_count",
        },
        {
          text: `Active ${this.featureName("Stage")}`,
          align: "center",
          sortable: true,
          value: "current_item_id",
        },
        {
          text: `Onboarding ${this.featureName("Stage")}`,
          align: "left",
          sortable: true,
          value: "onboarding_stage",
        },
      ].filter((i) => i.hidden !== true);
      return headers;
    },
    currentOnBoardingStage() {
      if(this.dialogs.preview.item && this.dialogs.preview.item.stages.length > 0) {
        return this.dialogs.preview.item.stages.find((stage) => stage.is_required_during_onboarding === true);
      }
      return {};
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "pipeline/getIndexConfig",
      doGetPipelines: "pipeline/getAll",
      doGetPipeline: "pipeline/getOne",
      doDeletePipeline: "pipeline/delete",
      doSavePipeline: "pipeline/save",
      doSaveStage: "pipelineStage/save",
      doGetSubmitted: "pipelineSubmitted/getAll",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
    }),
    onDelete(pipeline) {
      return this.doDeletePipeline(pipeline.id).then((result) => {
        if(result) {
          this.$bus.$emit("notificationMessage", `Deleted ${this.getPipelineType(pipeline).toLowerCase()} successfully!`);
        }
        return result;
      });
    },
    onGet(id) {
      return this.doGetPipeline(id);
    },
    onGetAll(params) {
      return this.doGetPipelines(params);
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    onToggleStageRequiredDuringOnboarding(pipeline) {
      return this.doSavePipeline({
        id: pipeline.id,
        is_active: !pipeline.is_active,
      });
    },
    getContentType(pipeline) {
      let type = null;
      switch (pipeline.type) {
        case "pipeline":
          type = "registration"
          break;
        case "application-pipeline":
          type = "application"
          break;
      
        default:
          type = pipeline.type;
          break;
      }
      return pipeline.type ? type.titleize() : "(not set)";
    },
    onSubmitPipeline() {
      return this.$refs["submitPipeline"].onSave();
    },
    onUpdateEvaluationForm(form, pipelineId) {
      const index = this.data.data.findIndex((p) => p.id == pipelineId);
      this.data.data.splice(index, {
        ...this.data.data[index],
        ...{
          evaluation_form: form,
          evaluation_form_count: 1,
        },
      });
      this.$set(this.submittedModel, `evaluation_form`, form);
      this.$set(this.submittedModel, `evaluation_form_count`, 1);
      this.$log.info("Updated evaluation form for ", pipelineId, index);
    },
    onToggleEditPipelineEvaluationForm(model) {
      this.dialogs.addEvaluation = !this.dialogs.addEvaluation;
      this.submittedModel = model || this.submittedModel;
    },
    onToggleCompletedEvaluationsDialog(model, filter) {
      if (!this.submittedDialogs.completedEvaluations) {
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
        this.submittedModel = null;
        this.submittedEvaluationFilter = filter;
        this.doGetPipeline({
          id: model.id,
          _with: ["evaluationForm"],
          _fields: ["id", "title", "evaluation_form", "evaluation_form_count"],
        }).then((pipeline) => {
          this.submittedModel = pipeline || model || this.submittedModel;
        });
      } else {
        this.submittedEvaluationFilter = null;
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
      }
    },
    onToggleStagesDialog() {
      this.dialogs.stages = !this.dialogs.stages;
      this.currentModel = null;
    },
    onSetStagesDialogModel(item) {
      if (item instanceof Object) {
        this.currentModel = item;
        this.dialogs.stages = true;
      }
    },
    onSetCurrentStage(index, stageId) {
      const id = this.data.data[index].id;
      const data = {
        id,
        current_item_id: stageId,
      };
      this.$set(this.data.data[index], "isSaving", true);
      this.doSavePipeline({ id, data }).then((result) => {
        this.$delete(this.data.data[index], "isSaving");
        this.$bus.$emit(
          "notificationMessage",
          `Updated ${this.featureName("stage").toLowerCase()} successfully!`
        );
      });
    },
    onSetOnboardingStage(index, stageId) {
      const id = this.data.data[index].id;
      const data = {
        id: stageId,
        is_onboarding_stage: true,
        pipeline_id: id,
      };
      this.$set(this.data.data[index], "isSaving", true);
      this.doSaveStage(data).then((result) => {
        this.$delete(this.data.data[index], "isSaving");
        this.$bus.$emit(
          "notificationMessage",
          `Updated onboarding ${this.featureName("stage").toLowerCase()} successfully!`
        );
      });
    },
    getPipelineType(item) {
      return item.type == "pipeline" ? "Registration" : "Application";
    },
    openPreviewDialog(id) {
      this.dialogs.preview.status = !this.dialogs.preview.status;
      this.onGet(id).then((result) => {
        this.dialogs.preview.item = result;
      });
    },
    closePreviewDialog() {
      this.dialogs.preview.item = null;
      this.dialogs.preview.status = !this.dialogs.preview.status;
    },
  },
};
</script>
