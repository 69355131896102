<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="save-pipeline"
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
      >
        <template #title>
          Save {{ featureName("Pipeline").singularize() }} Stage<template
            v-if="realPipeline"
          >
            for {{ realPipeline.title }}
          </template>
        </template>
      </SaveHeader>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol cols="12">
            <VCard>
              <VCardText>
                <VCol
                  cols="12"
                  class="pt-0 pb-0"
                >
                  <TextField
                    v-model="form.title"
                    label="Title"
                    placeholder="Enter title..."
                    :error="$v.form.title.$error"
                    @input="$v.form.title.$touch()"
                    @blur="$v.$touch()"
                  />
                </VCol>
                <VCol cols="12">
                  <VCheckbox
                    v-model="form.is_required_during_onboarding"
                    :label="
                      `Is this ${featureName(
                        'stage'
                      ).toLowerCase()} required during onboarding?`
                    "
                  />
                </VCol>
                <VCol cols="12">
                  <label>Due On</label>
                  <br>
                  <DateTimePicker
                    v-model="form.due_on"
                    clearable
                    label="Due On"
                    :reactive="true"
                    @input="onUpdateDueOn"
                  />
                  <br>
                  <label>Public Due On</label>
                  <br>
                  <DateTimePicker
                    v-model="form.public_due_on"
                    clearable
                    label="Public Due On"
                    :reactive="true"
                    @input="onUpdatePublicDueOn"
                  />
                  <small>Use this date if you want to use a different due on date.
                    For example you may want your actual application date to be
                    later than what users expect in order to offer
                    flexibility.</small>
                  <br>
                </VCol>
                <VCol>
                  <h3 class="title">
                    Fields
                  </h3>
                  <DynamicInputs
                    v-model="form.metadata"
                    enable-extra-options
                    enable-default-values
                    append-text="Add Question"
                    :card="false"
                    :input-name="featureName('PipelineStage')"
                    :enable-files="true"
                    :readonly="true"
                    :values-from-user="true"
                    :enable-card-inputs="true"
                    :default-value-options="defaultValueOptions"
                    :sections="form.metadata_sections"
                    :validator="$v.form.metadata"
                    @validated="$v.form.$touch()"
                    @new:input="onNewMetadata"
                    @update:input="onUpdateMetadata"
                    @update:inputs="onUpdateMultipleMetadata"
                    @remove:input="onRemoveMetadata"
                    @remove:inputs="onRemoveMultipleMetadata"
                    @input="onSetMetadata"
                    @input:sections="onSetMetadataSections"
                    @update:sections="onUpdateMetadataSections"
                  />
                </VCol>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FormMixin from "@/mixins/Form";
import HasDueMixin from "@/mixins/HasDueOn";
import MetadataMixin from "@/mixins/Metadata";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
export default {
  name: "SavePipelineStage",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    pipeline: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    defaultValueOptions: {
      type: [Object, Array],
      default: () => [],
    },
  },
  components: {
    DynamicInputs,
    SaveHeader,
  },
  mixins: [FormMixin, MetadataMixin, HasDueMixin, SupportsPipelines],
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
      metadata: {
        $each: {
          name: {
            required: requiredIf(function(input) {
              return ["Stack", "List", "Grid"].indexOf(input.type) > -1;
            }),
          },
          options: {
            type: {
              required: requiredIf(function(option) {
                return (
                  option &&
                  option.hasOwnProperty("type") &&
                  option.type === null
                );
              }),
            },
            options: {
              $each: {
                title: {
                  required: requiredIf(function(option) {
                    return ["Stack", "Grid"].indexOf(option.type) > -1;
                  }),
                },
                options: {
                  $each: {
                    title: { required: false },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  data() {
    return {
      modelType: "PipelineStage",
      hasFiles: true,
      defaultForm: {
        title: null,
        description: null,
        metadata: [],
        metadata_sections: [],
        is_required_during_onboarding: false,
        statuses: {
          name: "Pipeline Statuses",
          type: "Dropdown",
          options: {
            type: "Dropdown",
            options: [],
          },
        },
      },
    };
  },
  created() {
    this.$set(this.form, "metadata", this.form.metadata || []);
  },
  // watch: {
  //   value: function(value) {
  //     this.value = this.cloneObject({ ...this.defaultForm, ...this.value });
  //   }
  // },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineStage/getFormConfig",
      doSavePipelineStage: "pipelineStage/save",
      doGetPipelineStage: "pipelineStage/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      this.form.pipeline_id = this.form.pipeline_id || this.pipeline.id;
      this.form.statuses = this.form.statuses || this.defaultForm.statuses;
      const data = this.prepareFormModel(
        { ...this.form },
        {
          dontSave: ["team", "user", "pipeline"],
        }
      );
      // this.form.due_on.replace('undefined', '00:00:00');
      // this.form.public_due_on.replace('undefined', '00:00:00');
      return this.saveFormModel(this.doSavePipelineStage, null, data);
    },
    onGet(id) {
      const params = id instanceof Object ? id : { id };
      params.pipeline_id =
        params.pipeline_id ||
        this.pipelineId ||
        (this.pipeline || {}).id ||
        this.$route.params.pipeline;
      return this.doGetPipelineStage(params).then((result) => {
        this.setDueOn(result);
        return result;
      });
    },
    onAddFile(file) {
      this.onAddFile(file);
    },
    onRemoveFile(file) {
      this.onRemoveFile(file);
    },
    onUpdateDueOn(datetime) {
      this.form.due_on = datetime;
    },
    onUpdatePublicDueOn(datetime) {
      this.form.public_due_on = datetime;
    },
  },
};
</script>
