var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VListItem',{class:_vm.messageClass,style:({
    maxWidth: '100%'
  }),attrs:{"id":`message${_vm.message.id}`}},[_c('VListItemAvatar',{class:{
      'chat-item__author-photo my-0': true,
      'mr-0 ml-3': _vm.$user.id == _vm.message.user.id,
      'mr-3 ml-0': _vm.$user.id != _vm.message.user.id
    },staticStyle:{"align-self":"flex-start"},attrs:{"width":"50","height":"50","align-start":"","justify-start":""}},[_c('Avatar',{attrs:{"size":50,"user":_vm.message.user}})],1),_c('VListItemContent',{staticClass:"chat-item__body fill-height pa-0"},[_c('VListItemSubtitle',{staticClass:"chat-item__actions mb-2"},[(_vm.message.user.id != _vm.$user.id)?[_c('strong',[_vm._v(_vm._s(_vm.message.user.name ? _vm.message.user.name : ''))])]:_vm._e(),_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.65)","font-size":"10px","margin-left":"8px"}},[_vm._v(_vm._s(_vm.message.date || 'just now'))])],2),(_vm.message.message)?_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"formatted",domProps:{"innerHTML":_vm._s(_vm.message.message || '...' )}}):_vm._e(),(_vm.hasFiles)?_c('MessageFiles',{attrs:{"files":_vm.message.files,"is-sender":_vm.isSender}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }