<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      :cols="hasAdditionalImages ? 12 : 6"
      :class="{
        'py-3': true,
        'order-last': form.layout == 'Right',
      }"
    >
      <TextField
        v-if="!form.value.id && (!form.image || form.image && !form.image.url && !isDataImage)"
        v-model="form.value.url"
        filled
        clearable
        persistent-hint
        placeholder="(Optional) Url"
        label="Import image from url"
        class="textarea-field"
        :error-messages="url && !url.startsWith('https') ? 'Due to privacy concerns, we do not accept http:// links. Please use the https:// version or upload the image directly.' : ''"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <VCol
        cols="12"
        class="pa-0"
      >
        <template v-if="url">
          <VImg
            contain
            max-height="210px"
            class="grey lighten-2 rounded"
            style="border: 1px solid lightgrey;"
            :src="url"
            :lazy-src="thumbUrl"
            :id="imageId"
          >
            <template #placeholder>
              <VRow
                fill-height
                align-center
                justify-center
                ma-0
              >
                <VProgressCircular
                  indeterminate
                  color="grey lighten-5"
                />
              </VRow>
            </template>
          </VImg>
          <VBtn
            block
            text
            color="error"
            align-self-end
            @click="clearImage"
          >
            Remove
            <VIcon>delete</VIcon>
          </VBtn>
        </template>
        <FileUpload
          v-if="!url"
          text="Or Upload An Image"
          button-class="px-4 dashed"
          icon="image"
          accept="image/*"
          :as-button="true"
          :outlined="true"
          :rounded="true"
          :block="true"
          :multiple="false"
          :files="[form.image]"
          @removeFile="onRemoveFile"
          @formData="onAddFile"
        />
        <VAlert
          dismissible
          color="primary"
          class="mt-6"
          style="border-radius: 5px; padding-bottom: 12px; padding-top: 12px; color: white;"
          id="img-card-alert"
          :value="!(form.value || {}).url"
        >
          <h2 class="subtitle">
            Pro Tips
          </h2>For the optimal results please upload an image in one of the following formats:
          <ul>
            <li>
              4:3
              <ul>
                <li>640×480</li>
                <li>800×600</li>
                <li>1024×768</li>
                <li>1280×960</li>
              </ul>
            </li>
            <li>
              16:10
              <ul>
                <li>1280×800</li>
                <li>1440×900</li>
                <li>1680×1050</li>
                <li>1920×1200</li>
              </ul>
            </li>
          </ul>
        </VAlert>
      </VCol>
    </VCol>
    <VCol :cols="hasAdditionalImages ? 12 : 6">
      <VCol
        justify-content-center
        align-items-center
        cols="12"
        class="pb-0"
      >
        <VSelect 
          v-model="form.layout"
          dense
          outlined
          label="Select Layout"
          class="layout-options"
          :items="layoutOptions"
          :disabled="form.isSaving"
          @blur="backgroundSaveCard"
          @input="backgroundSaveCard"
        />
        <TextField
          v-model="form.name"
          placeholder="(Optional) Title"
          class="textarea-field"
          :disabled="form.isSaving"
          @blur="backgroundSaveCard"
          @input="backgroundSaveCard"
        />
        <Editor
          v-model="form.options.caption"
          placeholder="(Optional) Description"
          class="lesson-editor textarea-field bg-textarea"
          :disabled="form.isSaving"
          @blur="backgroundSaveCard"
          @input="backgroundSaveCard"
        />
      </VCol>
    </VCol>
  </VRow>
</template>

<script>
import SaveCardMixin from "@/mixins/SaveAssessmentCard";
import HasFilesMixin from "@/mixins/HasFiles";
import DownloadsFilesMixin from "@/mixins/DownloadsFiles";

export default {
  name: "SaveCardImage",
  mixins: [SaveCardMixin, HasFilesMixin, DownloadsFilesMixin],
  data() {
    return {
      layoutOptions: ["Left", "Right"],
      defaultForm: {
        layout: "Left",
        name: "",
        value: {},
        image: {},
      },
    };
  },
  computed: {
    imageId() {
      return "card-image" + this._uid;
    },
    thumbUrl() {
      return this.form.value instanceof Object ? this.form.value.thumb : "";
    },
    isDataImage() {
      return (
        this.form.value instanceof Object &&
        typeof this.form.value.url === "string" &&
        this.form.value.url.startsWith("data:image")
      );
    },
  },
  mounted() {
    if(!this.form.layout) {
      this.form.layout = "Left";
    }
  },
  methods: {
    onAddFile(file) {
      this.form.image = file;
      this.getMediaFromFileObject(file, "#" + this.imageId, (src) => {
        this.form.value = {
          ...(this.form.value || {}),
          ...{
            url: src,
          },
        };
        this.backgroundSaveCard();
      });
    },
    clearImage() {
      this.form.value.url = null;
      this.form.image = null;
      this.backgroundSaveCard();
    },
  },
};
</script>

<style lang="scss">
#img-card-alert .v-alert__dismissible {
  right: 0px;
  top: 0px;
  position: absolute;
  background: transparent;
  margin: 12px;
  box-shadow: none;
}
.bg-textarea .v-input__slot {
  min-height: 210px;
}
.layout-options fieldset {
  border-radius: 4px !important;
}
</style>
