<template>
  <VCard class="current-rsvp-search-container">
    <VCardText style="padding: 0px">
      <TextField
        v-if="!readonly"
        v-model="filter.s"
        text
        box
        class="search-rsvps"
        :label="`${label} ...`"
        @keyup.enter="onGetMore"
      >
        <template slot="append">
          <img
            style="cursor: pointer; width: 32px; height: 32px"
            src="@/images/search-icon.png"
            @click="onGetMore"
          >
          <VBtn
            v-if="hasFilterOptions"
            text
            small
            class="mt-0"
            :disabled="activity.isLoading"
            :color="hasFilter ? 'info' : 'default'"
            @click="filterDrawer = !filterDrawer"
          >
            <VIcon small>
              filter_list
            </VIcon>
          </VBtn>
        </template>
      </TextField>
      <div
        v-if="hasFilter"
        class="layout row justify-end py-1"
      >
        <CurrentFilter
          :filter="filter"
          :filter-values="filterValues"
          :is-loading="isSearching || activity.isLoading"
          @removeFilter="onRemoveFilter"
          @removeStrictMode="onRemoveStrictMode"
          @resetFilter="onResetFilter"
        />
      </div>
      <slot name="info" />

      <VRow
        v-if="activity.isLoading"
        row
        fluid
        justify-center
        class="mt-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
      <VList
        v-else-if="hasRsvps"
        three-line
      >
        <template v-for="(rsvp, index) in localRsvps">
          <slot
            name="rsvp"
            :rsvp="rsvp"
          >
            <VListItem
              class="mb-1"
              :key="index"
              @click="false"
            >
              <VListItemAvatar>
                <img :src="rsvp.calendar_entry.calendar.team.photo_url">
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle
                  :class="{
                    'pl-3': mode === 'shift'
                  }"
                >
                  {{ rsvp.calendar_entry.title }}
                </VListItemTitle>
                <VListItemSubtitle>
                  <p>
                    {{ $timezone.formatDate(rsvp.calendar_entry.date) }}: {{ $timezone.formatTime(rsvp.calendar_entry.start_time) }} to {{ $timezone.formatTime(rsvp.calendar_entry.end_time) }}
                    <br>
                  </p>
                  <p>Calendar: {{ rsvp.calendar_entry.calendar ? rsvp.calendar_entry.calendar.title : 'Unknown' }}</p>
                </VListItemSubtitle>
                <VListItemSubtitle
                  v-if="rsvp.calendar_entry.rsvp_mode === 'shift'"
                  style="min-height: 44px"
                >
                  <VBtn
                    v-show="canAccept(rsvp)"
                    depressed
                    outlined
                    color="success"
                    :loading="rsvp.isSaving"
                    :disabled="rsvp.isDeleting"
                    @click="onAcceptRsvp(rsvp)"
                  >
                    <VIcon>check_circle</VIcon>&nbsp;Accept
                  </VBtn>
                  <VBtn
                    v-show="canReject(rsvp)"
                    depressed
                    outlined
                    color="error"
                    :loading="rsvp.isSaving"
                    :disabled="rsvp.isDeleting"
                    @click="onRejectRsvp(rsvp)"
                  >
                    <VIcon>cancel</VIcon>&nbsp;Decline
                  </VBtn>
                  <VChip
                    color="success"
                    text-color="white"
                    v-if="rsvp.is_accepted"
                  >
                    Accepted: {{ $timezone.formatDateTime(rsvp.accepted_on) }}
                  </VChip>
                  <VChip
                    color="warning"
                    text-color="white"
                    v-if="rsvp.is_rejected"
                  >
                    Declined: {{ $timezone.formatDateTime(rsvp.rejected_on) }}
                  </VChip>
                </VListItemSubtitle>
                <VListItemSubtitle v-else>
                  <VSpacer />
                  <VChip
                    v-if="rsvp.calendar_entry.is_expired"
                    depressed
                    outlined
                  >
                    {{ rsvp.status.titleize() }}
                  </VChip>
                  <template v-else>
                    <VIcon
                      v-show="activity.isSaved[rsvp.id]"
                      small
                      color="success"
                    >
                      check
                    </VIcon>&nbsp;
                    <VBtnToggle
                      v-model="rsvp.status"
                      :disabled="activity.isSaving[rsvp.id]"
                      @change="onUpdateRsvp(rsvp)"
                    >
                      <VBtn
                        v-for="(status,key) in options.statuses"
                        depressed
                        :color="getRsvpButtonColor(rsvp, status)"
                        :value="key"
                        :key="`status-${key}`"
                        :loading="activity.isSaving[rsvp.id]"
                      >
                        {{ status }}
                      </VBtn>
                    </VBtnToggle>
                  </template>
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <VBtn
                  icon
                  text
                  small
                  @click="onToggleEventDetailDialog(rsvp.calendar_entry)"
                >
                  <VIcon>visibility</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
            <VDivider
              v-if="index + 1 < localRsvps.length"
              style="flex: 1 1 100%"
              :key="`divider-${index}`"
            />
          </slot>
        </template>
      </VList>
      <VAlert
        v-else
        :value="true"
        type="info"
      >
        No {{ featureName('RSVP') }}s
      </VAlert>
    </VCardText>
    <Dialog
      :persistent="true"
      max-width="500px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.detail"
      :title="currentEvent.title"
      @toggle:dialog="onToggleEventDetailDialog"
    >
      <div v-if="currentEvent && currentEvent.id">
        <p>
          {{ $timezone.formatDate(currentEvent.date) }}: {{ $timezone.formatTime(currentEvent.start_time) }} to {{ $timezone.formatTime(currentEvent.end_time) }}
          <br>
        </p>
        <p>Calendar: {{ currentEvent.calendar ? currentEvent.calendar.title : '' }}</p>
        <p
          v-html="currentEvent.description"
          class="formatted"
        />
        <h3>{{ featureName('RSVP') }}s</h3>
        <RSVPList
          :rsvps="currentEvent.rsvps || []"
          :mode="currentEvent.rsvp_mode || 'standard'"
          readonly
        />
      </div>
    </Dialog>
    <VNavigationDrawer
      v-model="filterDrawer"
      temporary
      clipped
      right
      fixed
      hide-overlay
      width="640px"
    >
      <slot name="filters">
        <FilterOptions
          v-model="filter"
          :filters="allFilters"
          @filter="onFilterData(true)"
          @toggle="filterDrawer = !filterDrawer"
        />
      </slot>
    </VNavigationDrawer>
  </VCard>
</template>

<script>
import CanRSVPMixin from "@/mixins/CanRSVP";
import RSVPList from "@/components/Elements/Data/RSVPList";
import FilterOptions from "./FilterOptions";
import CurrentFilter from "./CurrentFilter";
export default {
  name: "UserRSVPList",
  components: { RSVPList, FilterOptions, CurrentFilter },
  mixins: [CanRSVPMixin],
  data() {
    return {
      dialogs: {
        detail: false
      },
      currentEvent: {},
      options: {
        statuses: {
          yes: "Yes",
          maybe: "Maybe",
          no: "No"
        }
      }
    };
  },
  methods: {
    onGetAll(params) {
      return this.doGetCalendarEntryRSVPs(params).then(result => {
        this.localSearchData = result;
        return result;
      });
    },
    onUpdateRsvp(rsvp) {
      this.$set(this.activity.isSaving, rsvp.id, true);
      return this.doSaveCalendarEntryRSVPStatus({
        id: rsvp.id,
        calendar_entry_id: rsvp.calendar_entry_id,
        status: rsvp.status
      }).then(result => {
        this.$set(this.activity.isSaving, rsvp.id, false);
        this.$set(this.activity.isSaved, rsvp.id, true);
        setTimeout(() => {
          this.$set(this.activity.isSaved, rsvp.id, false);
        }, 1500);
      });
    },
    canReject(rsvp) {
      if (!rsvp) return false;
      const canReject =
        rsvp.calendar_entry.can_rsvp &&
        !rsvp.created_by_user &&
        ((rsvp.is_rejected === false && rsvp.is_accepted === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_accepted === undefined ||
          rsvp.is_accepted === null);
      return canReject;
    },
    canAccept(rsvp) {
      if (!rsvp) return false;
      const canAccept =
        rsvp.calendar_entry.can_rsvp &&
        !rsvp.created_by_user &&
        ((rsvp.is_accepted === false && rsvp.is_rejected === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_rejected === undefined ||
          rsvp.is_rejected === null);
      return canAccept;
    }
  }
};
</script>

<style lang="scss">
.current-rsvp-search-container {
  .v-list--three-line .v-list__tile {
    min-height: 96px;
    height: auto;
  }
}
</style>
