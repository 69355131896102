<template>
  <VContainer
    fluid
    :style="{
      padding: 0,
      height: !$auth.ready() ? '100vh' : '100%',
    }"
  >
    <router-view v-if="$auth.ready()" />
    <VRow
      v-else
      row
      fluid
      justify-center
      align-center
      class="mt-4"
      :style="{
        height: '100vh',
      }"
    >
      <VCol style="display: flex; justify-content: center; align-items: center">
        <VProgressCircular
          indeterminate
          color="#F5BA2B"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { AUTH_SUCCESS, AUTH_VERIFY } from "@/store/actionTypes";
import LogRocket from "logrocket";

export default {
  name: "MainApp",
  watch: {
    $hasUser: function(value) {
      if (value === true && this.env.isProduction) {
        LogRocket.identify(this.$user.id, {
          name: this.$user.name,
          email: this.$user.email,
          teamId: this.$team.id,
          teamName: this.$team.name,
        });
      }
    },
  },
  methods: {
    checkForToken() {
      if (typeof localStorage.access_token == undefined) {
        if (this.$route.path !== "/" && this.$route.path !== "/register") {
          this.$router.push("/?redirect=" + this.$route.path);
        }
      } else if (localStorage.access_token && this.$route.path == "/") {
        this.$store.dispatch(AUTH_VERIFY);
        if (this.$store.state.auth.valid_token == true) {
          this.$store.commit(AUTH_SUCCESS);
          this.$router.push("/app/dashboard");
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "./styles/layout.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons");
</style>
