<template>
  <VStepper
    class="elevation-0 bg-transparent custom-stepper"
    v-model="section"
  >
    <VRow
      row
      align-center
      justify-end
    >
      <VCol shrink>
        <slot name="title" />
      </VCol>
      <VCol grow>
        <VStepperHeader class="elevation-0">
          <slot name="initial-steps-header" />
          <template v-for="(sectionName, sectionIndex) in inputSections">
            <VDivider
              v-show="sectionIndex > 0"
              :key="`step-divider-${sectionIndex}`"
              :class="{
                complete: section > sectionIndex
              }"
            />
            <VStepperStep
              editable
              class="px-1 mr-0 v-stepper_step_step__button"
              error-icon="error"
              :key="`step-${sectionIndex}`"
              :complete="sectionIsComplete[sectionIndex] === true"
              :step="sectionIndex+offset"
              :rules="[() => sectionIsComplete[sectionIndex]]"
              @click="section = sectionIndex"
            >
              <VChip
                v-if="sectionIsComplete[sectionIndex] === true"
                icon
                small
                :flat="false"
                :text="false"
                :class="stepClass(sectionIndex)"
                :color="stepColor(sectionIndex)"
                :style="{
                  width: '32px',
                  height: '32px',
                  borderRadius: '50%',
                  cursor: 'pointer'
                }"
              >
                {{ sectionIndex+offset }}
              </VChip>
              <VIcon
                v-else
                :class="stepClass(sectionIndex)"
                :color="stepColor(sectionIndex)"
                :style="{
                  fontSize: '32px',
                  borderRadius: '50%',
                  cursor: 'pointer'
                }"
              >
                info
              </VIcon>
            </VStepperStep>
          </template>
        </VStepperHeader>
      </VCol>
    </VRow>
    <VStepperItems>
      <slot name="initial-steps" />
      <VStepperContent
        v-for="(sectionData, sectionName, sectionIndex) in allInputsGrouped"
        class="px-0"
        :key="`pipeline-section-${sectionIndex}`"
        :step="sectionIndex+offset"
      >
        <VCard light>
          <VCardTitle
            class="py-2"
            v-show="section > offset && mode == 'standalone'"
          >
            <VRow
              row
              align-center
              style="height: 64px"
              :wrap="$vuetify.breakpoint.mdAndDown"
            >
              <VCol grow>
                {{ ['undefined', 'null'].indexOf(sectionName) === -1 ? sectionName : '' }}
              </VCol>
              <VCol
                shrink
                class="d-inline-flex"
              >
                <VBtn
                  v-show="section > offset"
                  depressed
                  style="text-transform: none"
                  :disabled="activity.isFormLoading"
                  @click="prev"
                >
                  Previous
                </VBtn>
                <VBtn
                  v-show="!isEnd"
                  class="wethrive"
                  style="text-transform: none"
                  :color="'primary'"
                  :disabled="activity.isFormLoading"
                  @click="next"
                >
                  Next
                </VBtn>
                <VBtn
                  v-show="isEnd && canSubmit"
                  large
                  class="wethrive-alt slight-radius"
                  :disabled="activity.isFormLoading || readonly"
                  @click="onSave"
                >
                  Save
                </VBtn>
              </VCol>
            </VRow>
          </VCardTitle>
          <VCardText>
            <p
              v-if="hasSectionDescription(sectionName)"
              v-html="sectionDescription(sectionName)"
            />
            <DynamicInputsSubmit
              v-model="allInputsGrouped[sectionName]"
              mode="embedded"
              container-class="px-0"
              item-title-class="subheading py-4"
              :section-start="sectionData[0].realIndex"
              :section-end="sectionData.length"
              :validator="validator"
              :readonly="activity.isFormLoading || readonly"
              @update:input="(inputKey, inputValue) => onUpdateInput(inputKey, inputValue)"
              @validated="$emit('validated')"
            />
          </VCardText>
          <VCardActions
            v-if="mode == 'standalone'"
            class="py-2"
            style="height: 70px"
          >
            <VRow
              row
              align-center
              :wrap="$vuetify.breakpoint.mdAndDown"
            >
              <VSpacer />
              <VCol
                shrink
                pb-4
                class="d-inline-flex"
              >
                <VBtn
                  v-show="section > offset && mode == 'standalone'"
                  depressed
                  style="text-transform: none"
                  :disabled="activity.isFormLoading"
                  @click="prev"
                >
                  Previous
                </VBtn>
                <VBtn
                  v-show="!isEnd"
                  class="wethrive"
                  style="text-transform: none"
                  :color="'primary'"
                  :disabled="activity.isFormLoading"
                  @click="next"
                >
                  Next
                </VBtn>
                <VBtn
                  v-show="isEnd && canSubmit"
                  large
                  class="wethrive-alt slight-radius"
                  :disabled="activity.isFormLoading || readonly"
                  @click="onSave"
                >
                  Save
                </VBtn>
              </VCol>
            </VRow>
          </VCardActions>
        </VCard>
      </VStepperContent>
    </VStepperItems>
  </VStepper>
</template>

<script>
import DynamicInputsSubmitStepper from "@/mixins/Metadata";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import DynamicInputsMixin from "@/mixins/DynamicInputs";
import DynamicInputsFormMixin from "@/mixins/DynamicInputsForm";

export default {
  name: "DynamicInputsSubmitStepper",
  mixins: [
    DynamicInputsSubmitStepper,
    DynamicInputsMixin,
    DynamicInputsFormMixin,
  ],
  props: {
    offset: {
      type: Number,
      default: 1,
    },
    currentSection: {
      type: Number,
      default: 1,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    canSubmit: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DynamicInputsSubmit,
  },
  data() {
    return {
      section: 1,
      submitClass: "wethrive",
    };
  },
  computed: {
    isEnd() {
      return (
        this.section === this.inputSectionsLength + 1 ||
        (this.sectionEnd && this.section == this.sectionEnd)
      );
    },
    isStart() {
      return this.section <= 1;
    },
    validationSections() {
      const $iter = Object.get(this.validator, "$each.$iter");
      if ($iter instanceof Object) {
        return Object.values(Object.values($iter).groupBy("$model.section"));
      }
      return null;
    },
    sectionIsComplete() {
      return this.validationSections instanceof Array
        ? this.validationSections.map((section) =>
            section.filter((s) => s.$error === true).length > 0
              ? "Missing information"
              : true
          )
        : [true];
    },
  },
  watch: {
    currentSection: function (value) {
      this.section = value;
    },
    section: function (value) {
      this.$nextTick(() => {
        this.notifyOfInvalidSection(value - 1);
      });
      this.$emit("update:section", value);
    },
  },
  methods: {
    updateInput() {},
    toSection(section) {
      this.section = section;
      this.$emit("validated");
    },
    next() {
      if (this.section == 0) {
        this.section = 1;
      }
      this.section++;
      this.$log.debug("Advancing to next section", this.section);
      this.$emit("validated");
    },
    prev() {
      this.section--;
      this.$log.debug("Advancing to previous section", this.section);
      this.$emit("validated");
    },
    onSave() {
      this.$emit("input", this.allInputs);
    },
    getSectionIsComplete(index) {
      if (this.validationSections) {
        const section = this.validationSections[index];
        const invalidFields = section.filter((s) => s.$error === true);
        if (invalidFields.length) {
          // this.$log.warn("invalidFields", invalidFields);
          return "Missing information";
        }
        return true;
      }
      return true;
    },
    notifyOfInvalidSection(index) {
      if (this.validationSections) {
        const section = this.validationSections[index];
        const invalidFields =
          section instanceof Array
            ? section.filter((s) => s.$error === true)
            : [];
        if (invalidFields.length) {
          // this.$log.warn("invalidFields", invalidFields);
          this.$emit("errors", [
            "<h3>We've identified the following issues</h3>",
            "",
            ...invalidFields.map((i) => {
              let errorType = "Is Required";
              if (i.value.max) {
                errorType = "Exceeds max length";
              } else if (i.value.required) {
                errorType = "Is required";
              }
              return `${i.$model.name}: <strong>${errorType}</strong>`;
            }),
          ]);
        } else {
          this.$emit("errors", []);
        }
      }
    },
    async onUpdateInput(key, value) {
      const index = this.findInputIndex(
        value instanceof Object && value.id ? value : key
      );
      if (index > -1) {
        this.$log.debug(
          "[DynamicInputsSubmitStepperMixin]: Updating input at index",
          index
        );
        Object.keys(value).map((k) => {
          this.$set(this.value[index], k, value[k]);
        });
        this.notifyOfInvalidSection(this.section - 1);
        this.$emit("input", this.value);
      }
    },
    findInputIndex(key) {
      const realKey = key instanceof Object ? key.id : key;
      this.$log.debug("[DynamicInputsSubmitStepper]: Searching for", realKey);
      return this.value.findIndex((v, k) => {
        return v instanceof Object &&
          key instanceof Object &&
          v.hasOwnProperty("id")
          ? v.id === realKey
          : k === realKey;
      });
    },
    stepClass(index) {
      return this.sectionIsComplete[index] === true
        ? "white--text"
        : "black--text";
    },
    stepColor(index) {
      return this.sectionIsComplete[index] === true ? "primary" : "error";
    },
  },
  mounted() {
    this.notifyOfInvalidSection(this.section - 1);
  },
};
</script>
<style lang="scss">
@import "../../../styles/variables.scss";
.custom-stepper {
  .v-stepper__step__step {
    margin-right: 0;
    height: 32px;
    width: 32px;
    background-color: #ddd;
    color: #000;
  }

  .v-divider {
    border: solid 2px #dddddd;
    // margin: 0 -28px !important;
    &.complete {
      border: solid 2px $primary-color !important;
    }

    .v-stepper__step.v-stepper__step--error .v_stepper__step__step {
      width: 36px;
      height: 36px;
    }
  }

  .v-stepper__header {
    height: 60px;
    .v-stepper__step {
      padding: 12px;
      border-radius: 50%;
      /* padding: 0px 24px; */
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      .v-stepper__step__step {
        display: none;
      }
      .v-stepper__label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
      }
    }
  }
}
</style>