<template>
  <VContainer
    fluid
    fill-height
    px-0
    py-0
    id="apply-to-team"
    class="fill-height align-start"
    style="align-items: start; overflow-y auto"
  >
    <VRow
      align-center
      full-width
      style="background-color: #fff; border-bottom: solid thin #ddd; position: fixed; top: 0; right: 0; left: 0; z-index: 1000"
    >
      <VCol
        shrink
        class="d-flex align-center"
      >
        <VBtn
          :to="{ name: 'login' }"
          text
          style="height: 56px;"
        >
          <VIcon>arrow_back</VIcon>
        </VBtn>
      </VCol>
      <VCol
        class="d-flex align-center pr-4 justify-start"
        style="border-right: solid thin #ddd"
        :shrink="$vuetify.breakpoint.mdAndUp"
      >
        <img
          style="max-height: 48px"
          :src="logo"
        >
      </VCol>
      <VCol
        v-if="$vuetify.breakpoint.mdAndUp"
        grow
        class="px-4"
        style="max-width: calc(100vw - 200px)"
      >
        <VRow
          row
          wrap
          align-center
        >
          <VCol
            cols="12"
            align-content-center
            v-show="$vuetify.breakpoint.mdAndUp"
          >
            <h2
              class="title"
              style="text-transform: uppercase"
            >
              {{ currentTeamName }}
            </h2>
          </VCol>
          <VCol
            v-if="
              $vuetify.breakpoint.mdAndUp &&
                currentTeamProfile.tagline &&
                currentTeamProfile.tagline.length
            "
            class="text-truncate text-no-wrap"
            :style="{
              maxWidth: $vuetify.breakpoint.smAndUp
                ? 'calc(100vw - 400px)'
                : '100%',
            }"
          >
            {{ currentTeamProfile.tagline }}
          </VCol>
        </VRow>
      </VCol>
      <VCol
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex align-center px-4 text-right justify-end"
      >
        Already have an account?
      </VCol>
      <VCol
        shrink
        class="info px-8"
      >
        <VBtn
          text
          block
          style="height: 56px; color: #fff"
          class="my-0"
          :to="{
            name: 'team-login',
            params: {
              team: teamSlug,
            },
          }"
        >
          Login
        </VBtn>
      </VCol>
    </VRow>

    <VRow
      v-if="teamExists"
      row
      wrap
      justify-center
      style="margin-top: 68px; overflow-y: auto; height: calc(100vh - 150px)"
    >
      <VCol xs="12">
        <LargeTeamBanner
          style="height: 310px"
          :team="currentTeam"
        >
          <VRow
            row
            align-center
            justify-center
            fill-height
          >
            <VCard
              class="d-flex justify-space-between column"
              style="height: 200px; max-width: 250px; border-radius: 10px"
            >
              <VCardText>
                <VImg
                  max-height="112"
                  max-width="224"
                  :src="currentTeam.photo_url"
                  style="border-radius: 10px; overflow: hidden"
                />
              </VCardText>
              <VCardActions>
                <VBtn
                  block
                  class="wethrive"
                  :href="`/${currentTeam.slug}/register`"
                >
                  {{ featureName("application_apply_button").capitalize() }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VRow>
        </LargeTeamBanner>
        <VRow
          align-center
          justify-center
        >
          <VCol
            cols="12"
            md="6"
            lg="4"
            class="my-12"
          >
            <VRow
              row
              justify-center
              v-if="currentTeam.city instanceof Object"
            >
              <VCol
                shrink
                pr-2
              >
                <VImg
                  width="22"
                  :src="globeIcon"
                />
              </VCol>
              <VCol
                shrink
                class="text-no-wrap"
              >
                {{ currentTeam.city.name }},
                {{ currentTeam.city.parent.name }} &nbsp;
              </VCol>
              <VCol
                v-if="
                  currentTeam.application_pipeline &&
                    currentTeam.application_pipeline.due_on
                "
                style="white-space: nowrap"
              >
                &bull;
                {{
                  $timezone.formatDateTime(
                    currentTeam.application_pipeline.due_on,
                    currentTeam.date_format,
                    currentTeam.time_format
                  )
                }}
              </VCol>
            </VRow>
            <VDivider
              class="my-2"
              style="max-width: 400px; margin: 0 auto"
            />
            <p
              class="text-center team-link"
              v-linkified
            >
              {{ currentTeam.website }}
            </p>
            <p
              class="team-about formatted"
              v-html="currentTeamProfile.bio"
            />
          </VCol>
        </VRow>
        <VRow
          v-if="teamExists"
          row
          align-center
          justify-center
          fill-height
          class="bottom-section"
        >
          <VCard style="height: 220px; min-width: 400px; border-radius: 10px">
            <VCardTitle>Contact Us</VCardTitle>
            <VCardText class="d-flex">
              <VImg
                height="112"
                width="112"
                :src="currentTeam.photo_url"
                style="border-radius: 10px; overflow: hidden; flex: 0 0 112"
              />
              <div class="p-3">
                <VList dense>
                  <VListItem style="height:24px">
                    <VListItemTitle>
                      <span
                        class="title"
                        v-linkified
                      >{{
                        currentTeam.owner.name
                      }}</span>
                    </VListItemTitle>
                  </VListItem>
                  <VListItem style="height:24px">
                    <VListItemTitle>
                      <span v-linkified>{{ currentTeam.email }}</span>
                    </VListItemTitle>
                  </VListItem>
                  <VListItem style="height:24px">
                    <VListItemTitle>
                      <span v-linkified>{{ currentTeam.website }}</span>
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </div>
            </VCardText>
          </VCard>
        </VRow>
        <Dialog
          :is-dialog-open="dialogs.privacy"
          title="Privacy Policy"
          @toggle:dialog="() => (dialogs.privacy = false)"
        >
          <Privacy mode="dialog" />
        </Dialog>
        <Dialog
          :is-dialog-open="dialogs.terms"
          title="Terms and Conditions"
          @toggle:dialog="() => (dialogs.terms = false)"
        >
          <Terms mode="dialog" />
        </Dialog>
      </VCol>
    </VRow>
    <VRow
      v-else
      align-center
      justify-center
      fill-height
    >
      <VProgressCircular
        indeterminate
        color="primary"
        v-if="isSearchingTeam"
      />
      <h1 v-else>
        Team not found
      </h1>
    </VRow>
  </VContainer>
</template>

<script>
import globeIcon from "@/images/globe-icon@3x.png";
import logo from "@/images/WeThrive-Logo.png";
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import {
  sameAs,
  email,
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import FormMixin from "@/mixins/Form";
import Privacy from "@/components/App/Privacy";
import Terms from "@/components/App/Terms";
import MetadataMixin from "@/mixins/Metadata.vue";

export default {
  name: "ApplyToTeam",
  mixins: [NeedsTeamsMixin, FormMixin, MetadataMixin],
  components: {
    Privacy,
    Terms,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      globeIcon,
      logo,
      error: false,
      submitClass: "wethrive",
      options: {
        roles: [],
      },
      dialogs: {
        privacy: false,
        terms: false,
      },
      form: {},
      isLoading: false,
      modelType: "Registration",
    };
  },
  validations: {
    teamSlug: {
      minLength: minLength(3),
    },
    form: {
      name: {
        required,
      },
      agreement: {
        sameAs: sameAs(() => true),
      },
      password: {
        required,
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
      },
      team_id: {
        required,
      },
      role: {
        required,
      },
      current_team_onboarding_form: {
        required: requiredIf(function(nestedModel) {
          return this.hasOnboardingForm === true;
        }),
        metadata: {
          required: requiredIf(function(nestedModel) {
            return this.hasOnboardingForm === true;
          }),
          $each: {
            name: { required },
            value: { required },
          },
        },
      },
    },
  },
  computed: {
    submitText() {
      if (this.invite) {
        return this.isLoading ? "Accepting invite..." : "Accept Invite";
      } else {
        return this.isLoading ? "Requesting access..." : "Request Acccess";
      }
    },
  },
  watch: {
    currentTeam: function(team) {
      if (team && !this.invite) {
        if (team.open_registration) {
          this.form.team_id = team.id;
          this.options.roles = team.signup_roles;
          this.isRegistrationDisabled = false;
          this.form.current_team_onboarding_form = {
            team_id: team.id,
            metadata: this.getMetadataForSubmit(team.onboarding_form),
          };
        } else {
          this.isRegistrationDisabled = true;
          this.errors = [
            `Registration is disabled for ${
              team.name
            }. Please contact <a style="color: #000" href="mailto:${
              team.email
            }">${team.email}</a> for help signing up`,
          ];
        }
      }
    },
    invite: function(invite) {
      if (invite instanceof Object) {
        this.prepareInvite();
      }
    },
  },
  methods: {
    ...mapActions({
      doRegister: "register/register",
      doLogin: "auth/login",
    }),
    register() {
      this.form.team = this.currentTeam ? this.currentTeam.name : null;
      const password = this.form.password;
      return this.saveFormModel(this.doRegister)
        .then((resp) => {
          if (resp.status != "error") {
            this.isLoading = false;
            this.submitClass = "wethrive-alt";
            const { username = this.form.email } = this.form;
            this.doLogin({
              username,
              password,
            }).then(() => {
              this.$log.debug(
                "RegisterMemberForm.register: Registered ... waiting for user"
              );
              this.$bus.$emit(
                "notificationMessage",
                "Registration was successful!"
              );

              const ready = function() {
                // window.location.href = `${team ? '/'+team : ''}/app/dashboard`;
                this.$log.debug("RegisterMemberForm.login: User is available");
                this.$router.push({
                  name: "dashboard",
                  params: {
                    team: this.$team.slug,
                  },
                });
              };

              setTimeout(() => {
                if (!this.$auth.ready()) {
                  this.$auth.ready(ready);
                } else {
                  ready.call(this);
                }
              }, 500);
            });
          } else {
            this.errors = resp.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    prepareInvite() {
      this.currentTeam = this.invite.team;
      this.form.email = this.invite.email;
      this.form.team_id = this.currentTeam.id;
      this.form.role = this.invite.role;
      this.form.invitation = this.invite.token;
      this.form.name = this.invite.name;
      this.options.roles = [
        {
          id: this.invite.role,
          name: this.invite.role.titleize(),
        },
      ];
      this.teamSlug = this.invite.team.slug;
    },
  },
  created() {
    if (this.invite) {
      this.prepareInvite();
    }
    if (this.$route.params.team) {
      this.teamSlug = this.$route.params.team;
      this.findTeam();
    }
  },
};
</script>
<style lang="scss">
@import "../../../styles/variables.scss";
:root {
  a {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
  }
  .team-link {
    text-decoration: none;
  }
  .team-about {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .bottom-section {
    height: 500px;
    background: $primary-color url("../../../images/freepik-vector@3x.png");
    background-size: cover;
  }
}
</style>
