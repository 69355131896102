<template>
  <VCard
    grid-list-md
    fluid
  >
    <VRow
      column
      wrap
      class="px-5"
    >
      <VCol
        v-for="(group, key) in choices"
        class="pb-0"
        :key="key"
      >
        <h4 style="color: rgba(0, 0, 0, 0.55);">
          {{ group.title }}
        </h4>
        <VRow
          row
          wrap
          class="py-2"
        >
          <VCol
            v-for="(choice,choiceKey) in group.items"
            sm="6"
            md="4"
            :key="key+choiceKey"
          >
            <VCardText>
              <VBtn
                block
                outlined
                fill-height
                :disabled="isAdding"
                :style="{
                  height: $vuetify.breakpoint.smAndDown ? '100px' : '150px',
                  border: isSelected(key+choiceKey) ? 'solid 0.5px currentColor' : 'solid 0.5px #eaeaea',
                  'box-shadow': '-1.7px 4.7px 6.1px 0.4px rgba(0, 0, 0, 0.03)'
                }"
                :color="isSelected(key+choiceKey) ? 'success': 'disabled'"
                @click="select(choice, key+choiceKey)"
              >
                <VRow
                  column
                  align-center
                  justify-center
                >
                  <VIcon 
                    class="card-icons"
                  >
                    {{ choice.icon }}
                  </VIcon>
                  <span class="pt-4">
                    <h4>{{ choice.text }}</h4>
                  </span>
                </VRow>
              </VBtn>
            </VCardText>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
export default {
  name: "AddLessonCard",
  props: {
    choices: {
      type: Object,
      default: () => []
    },
    isAdding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: null,
      currentChoice: null
    };
  },
  methods: {
    select(choice, key) {
      this.currentChoice = choice;
      this.selected = key;
      this.$bus.$emit("saveDialogData", {
        uid: this.uid,
        data: [this.currentChoice]
      });
      this.addCard();
    },
    isSelected(key) {
      return this.selected === key;
    },
    addCard() {
      this.$bus.$emit("input", {
        uid: this.$parent._uid,
        data: [this.currentChoice]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-icons {
  background-color: #f4f4f4;
  height: 60px;
  width: 60px;
  border-radius: 5px;
  opacity: 0.5;
}
</style>