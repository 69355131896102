<template>
  <ExpandableButton
    link
    :disabled="disabled"
    :height="height"
    :min-width="minWidth"
    :small="small"
    :expand-on-hover="expandOnHover"
    :text-button="textButton"
    :icon-button="iconButton"
    :url="url"
    :icon="icon"
    :icon-props="iconProps"
    :hover-class="hoverClass"
    :button-style="buttonStyle"
    :button-class="buttonClass"
    :show-text="false"
    :force-expandable="forceExpandable"
    :tooltip-text="temporaryText || tooltip"
    :action="onCopyLink"
  />
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "#",
    },
    to: {
      type: [String, Object],
      default: "#",
    },
    copyable: {
      type: Boolean,
      default: true,
    },
    preventExpandable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "Click or tap to copy this shareable link",
    },
    text: {
      type: String,
      default: "Copy link",
    },
    icon: {
      type: String,
      default: "link",
    },
    iconProps: {
      type: Object,
      default: () => {},
    },
    hoverClass: {
      type: [String, Array, Object],
      default: "white",
    },
    buttonStyle: {
      type: [String, Array, Object],
      default: "",
    },
    buttonClass: {
      type: [String, Array, Object],
      default: () => {
        return { "default ml-0": true };
      },
    },
    expandOnHover: {
      type: Boolean,
      default: true,
    },
    textButton: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
    iconButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "",
    },
    minWidth: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      forceExpandable: false,
      temporaryText: null,
    };
  },
  methods: {
    onCopyLink() {
      if (this.copyable) {
        this.forceExpandable = !this.preventExpandable;
        this.temporaryText = "Copied!";
        // this.buttonClass["blue lighten-4 white--text"] = false;
        this.copyTextToClipboard(this.url);
        setTimeout(() => {
          this.temporaryText = null;
          this.forceExpandable = this.preventExpandable;
          // this.buttonClass["blue lighten-4 white--text"] = true;
        }, 5000);
      } else {
        this.navigateTo(this.to);
      }
    },
  },
};
</script>