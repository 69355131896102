import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
// import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: "mdi",
	},
	theme: {
		themes: {
			dark: {
				primary: "#F5BA2B",
				success: "#4EBD30",
				secondary: "#ff2b7a",
			},
			light: {
				primary: "#F5BA2B",
				success: "#4EBD30",
				secondary: "#ff2b7a",
			},
		},
	},
});
