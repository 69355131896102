<script>
import { mapActions, mapGetters } from "vuex";
import GoogleAuthMixin from "@/mixins/GoogleAuth";
import { Promise } from "q";
export default {
  mixins: [GoogleAuthMixin],
  data() {
    return {
      isFirstCalendarEntriesLoad: true,
      hasCalendarAuth: {},
      remoteEvents: null,
      activeCalendars: [],
      calendars: [],
      calendar: null,
      existingCalendarsKey: "existing",
      calendarMode: "multiple",
      loadedRemoteCalendars: {},
    };
  },
  computed: {
    allEvents() {
      if (this.activeLocalEvents.length || this.activeRemoteEvents.length) {
        // Only do this for local calendars since we should have filtered remote calendars already
        return this.activeLocalEvents.concat(this.activeRemoteEvents);
      }
      return this.activeLocalEvents;
    },
    activeLocalEvents() {
      if (
        this.allActiveCalendars &&
        this.calendars instanceof Object &&
        this.calendars.data instanceof Object
      ) {
        let entries = this.allActiveCalendars.map((filteredCalendar) =>
          this.formatEvents(filteredCalendar)
        );
        return entries instanceof Array && entries.length ? entries.flat() : [];
      }
      return [];
    },
    allActiveCalendars() {
      const calendars = Object.values(
        this.filterCalendars(this.calendars.data, (calendar) => {
          let isActive = true;
          if (this.activeCalendars.length) {
            isActive = this.activeCalendars.indexOf(calendar.id) > -1;
          } else if (!this.isFirstCalendarEntriesLoad) {
            isActive = true;
          }
          return calendar.calendar_type === "local" && isActive === true;
        })
      );
      return calendars instanceof Array && calendars.length
        ? calendars.flat()
        : [];
    },
    activeRemoteEvents: {
      set: function (events) {
        this.remoteEvents = this.remoteEvents || [];
        this.remoteEvents = this.remoteEvents.concat(events).unique();
        this.$forceUpdate();
      },
      get: function () {
        return this.remoteEvents instanceof Array
          ? this.remoteEvents.filter(
              (entry) => this.activeCalendars.indexOf(entry.calendarId) !== -1
            )
          : [];
      },
    },
  },
  watch: {
    "calendars.total": function (value) {
      this.updateActiveCalendars();
    },
  },
  methods: {
    setActiveCalendars(calendars) {
      this.$log.debug(
        "[FormatsCalendarEntries]: Setting active calendars",
        calendars
      );
      this.activeCalendars = calendars;
    },
    updateActiveCalendars() {
      const activeCalendars = [...this.activeCalendars];
      this.setActiveCalendars(
        Object.values(this.calendars.data)
          .map((group) => {
            return group.map((calendar) => {
              if (
                activeCalendars instanceof Array &&
                activeCalendars.indexOf(calendar.id) === -1
              ) {
                this.formatEvents(calendar);
              }
              return calendar.id;
            });
          })
          .flat()
      );
      this.allCalendars = true;
    },
    getEventColor(entry, calendar) {
      return entry.status === "canceled" ? "#ccc" : calendar.color;
    },
    /**
     * Determine the type of entry to format
     * @param {Object} entry
     * @param {Object} calendar
     * @return {Object}
     */
    formatEvent(entry, calendar) {
      switch (calendar.calendar_type) {
        case "google":
          return this.formatGoogleEvent(entry, calendar);
          break;

        default:
          return this.formatLocalEvent(entry, calendar);
          break;
      }
    },
    /**
     * Normalize a calendar entry according to our needs
     * @param {Object} entry
     * @param {Object} calendar
     * @return {Object}
     */
    normalizeEvent(entry, calendar) {
      const result = {
        id: entry.id,
        sequenceOwnerId: entry.sequence_owner_id,
        title: entry.title
          ? entry.title
          : this.$timezone.formatTime(entry.start_time) +
            " " +
            calendar.title +
            (entry.entity ? ": " + entry.entity.title : ""),
        calendarId: calendar.id,
        calendar: {
          id: calendar.id,
          title: calendar.title,
          color: calendar.color,
          calendarFor: calendar.calendarFor,
          calendarType: calendar.calendar_type,
        },
        hasEntries: entry.entries_count > 0,
        originalStartDate: entry.start_date,
        startDate: entry.start_date,
        startTime: entry.start_time,
        originalEndDate: entry.end_date,
        endDate: entry.end_date,
        endTime: entry.end_time,
        rsvps: entry.rsvps || [],
        rsvpsCount: entry.rsvps_count || 0,
        rsvpMode: entry.rsvp_mode,
        status: entry.status,
        canRsvp: entry.can_rsvp,
        currentUserRsvp: entry.current_user_rsvp,
        date: this.$timezone.moment(entry.date).format("ddd, MMM Do YYYY"),
        originalDate: entry.date,
        dom: this.$timezone.moment(entry.date).format("D"),
        group: this.$timezone.moment(entry.date).format("YYYY-MMM-DD"),
        year_month_week_day: entry.year_month_week_day,
        style: `color: #fff; background-color: ${this.getEventColor(
          entry,
          calendar
        )}`,
      };
      this.$log.info("[normalizeEvent]: Normalizing", entry, result);
      return result;
    },
    /**
     * Format a google calendar entry
     * @param {Object} entry
     * @param {Object} calendar
     * @return {Object}
     */
    formatGoogleEvent(entry, calendar) {
      let preparedEvent = {
        id: entry.id,
        title: entry.summary,
        status: entry.status,
      };
      if (entry.start) {
        preparedEvent = {
          ...preparedEvent,
          ...{
            start_date: entry.start.date || entry.start.dateTime,
            start_time: this.$timezone.formatTime(
              entry.start.date || entry.start.dateTime
            ),
          },
        };
      }

      if (entry.end) {
        preparedEvent = {
          ...preparedEvent,
          ...{
            end_date: entry.end.date || entry.end.dateTime,
            end_time: this.$timezone.formatTime(
              entry.end.date || entry.end.dateTime
            ),
          },
        };
      }

      const start = this.$timezone.moment(preparedEvent.start_date);

      preparedEvent.year_month_week_day = start.format("YYYY-MMM-W-ddd");
      preparedEvent.date = preparedEvent.start_date;
      preparedEvent.title =
        (preparedEvent.start_time || "00:00") + " " + preparedEvent.title;
      return this.normalizeEvent(preparedEvent, calendar);
    },
    /**
     * Format a single local entry
     * @param {Object} entry
     * @param {Object} calendar
     * @return {Object}
     */
    formatLocalEvent(entry, calendar) {
      const result = this.normalizeEvent(entry, calendar);
      return result;
    },
    /**
     * Format entries helper. Deterines the type of entries to format based on calendar entry type
     * @param {Object} calendar
     * @return {Array}
     */
    formatEvents(calendar) {
      /*this.$log.debug(
        "[FormatsCalendarEntries]: Formatting events for",
        calendar
      );*/
      this.isFirstCalendarEntriesLoad = false;
      switch (calendar.calendar_type) {
        /**
         * Do not hold up UI waiting for data when using remote calendars
         */
        case "google":
          this.formatGoogleEvents(calendar);
          return [];
          break;

        default:
          return this.formatLocalEvents(calendar);
          break;
      }
    },
    /**
     * Format Google calendar entries for the local calendar
     * @param {Object} calendar
     */
    formatGoogleEvents(calendar) {
      if (this.loadedRemoteCalendars.hasOwnProperty(calendar.id)) {
        this.$log.debug(
          "[formatGoogleEvents]: Already loaded google calendar entries for",
          calendar.remote_calendar.id
        );
        return;
      }
      this.$log.debug(
        "[formatGoogleEvents]: Loading google calendar entries for",
        calendar.remote_calendar.id
      );
      this.waitFor(
        () =>
          window.gapi instanceof Object &&
          window.gapi.client instanceof Object &&
          window.gapi.client.calendar instanceof Object &&
          window.gapi.client.getToken() instanceof Object,
        10000
      ).then(() => {
        this.$log.debug("We're good with authentication for calendar");
        window.gapi.client.calendar.events
          .list({
            calendarId: calendar.remote_calendar.id,
            maxResults: 2500,
            timeZone: this.$team.timezone,
            timeMin: this.$timezone.moment().subtract(1, "month").toISOString(),
          })
          .execute((response) => {
            if (response.code && response.code === 401) {
              this.hasCalendarAuth.google = false;
            } else if (response.items instanceof Array) {
              this.$log.debug("Got google calendar entries");
              this.activeRemoteEvents = response.items.map((entry) => {
                return this.formatGoogleEvent(entry, calendar);
              });
              this.loadedRemoteCalendars[calendar.id] = true;
              // this.setActiveCalendars(
              //   this.activeCalendars
              //     .filter(id => id !== calendar.id)
              //     .concat([calendar.id])
              // );
            }
          });
      });
    },
    /**
     * Format local calendar entries
     * @param {Object} calendar
     * @return {Array} entries
     */
    formatLocalEvents(calendar) {
      this.$log.debug(
        "[formatLocalEvents]: Loading local calendar",
        calendar.title
      );
      return calendar.entries instanceof Array
        ? calendar.entries.map((entry) =>
            this.formatLocalEvent(entry, calendar)
          )
        : [];
    },
  },
};
</script>
<style>