<template>
  <VTooltip
    :bottom="bottom"
    :top="resolvedTop"
    :right="right"
    :left="left"
    :small="small"
    :value="value"
  >
    <template #activator="{ on: tooltip }">
      <slot :on="tooltip">
        <component
          v-on="!disabled ? Object.assign(tooltip, activatorVOn) : {}"
          v-bind="buttonAttributes"
          :is="componentType"
          :icon="iconButton"
          :color="color"
          :text-color="textColor"
          :text="flat || textButton"
          :dark="dark"
          :small="small"
          :large="large"
          :rounded="rounded"
          :label="label"
          :block="block"
          :outlined="outline"
          :depressed="depressed"
          :disabled="disabled"
          :loading="loading"
          :to="to"
          :class="buttonClass"
          :style="buttonStyle"
          @click="action"
        >
          <component
            v-if="(icon && iconLeft) || (icon && !iconRight) || (icon && !iconLeft && !iconRight)"
            v-bind="avatarAttributes"
            class="mr-0"
            :is="avatarType"
          >
            <slot name="button-left">
              <VIcon
                :color="iconColor"
                :small="smallIcon"
                :large="largeIcon"
              >
                {{ icon }}
              </VIcon>
            </slot>
          </component>
          <template v-if="buttonText">
            <span
              v-if="buttonTextIsVisible"
              class="mr-2"
              :style="buttonTextStyle"
            >&nbsp;{{ buttonText }}&nbsp;</span>
          </template>
          <component
            v-if="(icon || rightIcon) && iconRight"
            :is="avatarType"
            class="ml-0"
          >
            <slot name="button-right">
              <VIcon
                :color="iconColor"
                :small="smallIcon"
                :large="largeIcon"
              >
                {{ rightIcon || icon }}
              </VIcon>
            </slot>
            <slot name="button-action" />
          </component>
        </component>
      </slot>
    </template>
    <span
      v-if="title && title.length"
      :style="{
        cursor: action ? 'pointer' : 'default'
      }"
      v-html="title"
    />
  </VTooltip>
</template>
<script>
import VBtn from "vuetify/lib/components/VBtn";
import VChip from "vuetify/lib/components/VChip";
import VAvatar from "vuetify/lib/components/VAvatar";
export default {
  props: {
    title: {
      type: [String, Number],
      default: "",
    },
    value: {
      type: Boolean,
      default: null,
    },
    buttonAttributes: {
      type: Object,
      default: () => {},
    },
    avatarAttributes: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: null,
    },
    buttonClass: {
      type: [Object, String, Array],
      default: null,
    },
    buttonStyle: {
      type: [Object, String, Array],
      default: null,
    },
    asButton: {
      type: Boolean,
      default: true,
    },
    asText: {
      type: Boolean,
      default: false,
    },
    buttonTextIsVisible: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    iconButton: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: null,
    },
    rightIcon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: "",
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    smallIcon: {
      type: Boolean,
      default: false,
    },
    largeIcon: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "default",
    },
    textColor: {
      type: String,
      default: "black",
    },
    action: {
      type: Function,
      default: () => $emit("click"),
    },
    activatorVOn: {
      type: Object,
      default: () => {},
    },
    label: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    buttonTextStyle: {
      type: [Object, String, Array],
      default: null,
    },
  },
  components: {
    VBtn,
    VChip,
    VAvatar,
  },
  data() {
    return {
      resolvedTop: false,
    };
  },
  computed: {
    componentType() {
      if (this.asText) {
        return "span";
      }
      return this.asButton ? "VBtn" : "VChip";
    },
    avatarType() {
      return this.asButton || this.asText ? "span" : "VAvatar";
    },
  },
  watch: {
    top: function (value) {
      this.resolvedTop = value;
    },
  },
  created() {
    if (!this.top && !this.bottom && !this.right && !this.left) {
      this.resolvedTop = true;
    } else {
      this.resolvedTop = this.top;
    }
  },
};
</script>