import BaseApi from "./_base-api";

/**
 * Report API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	pinStats(params) {
		this.log().info("[Services: Report]: Pin Stats", params);
		return this.api()
			.post(this.getUrl("pinStats", "pinStats", params))
			.catch((error) => {
				this.log().error(
					"[Services: Report: Pin Stats]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}}

const api = new Api({
	type: "Report",
	path: "reports",
	pinStats: (app, _params) => {
		return `reports/pin-stats`;
	},
});

export default api;
