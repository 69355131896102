<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      justify-content-center
      align-items-center
      cols="12"
    >
      <TextField
        v-model="form.name"
        filled
        large
        placeholder="(Optional) Enter the result title"
        class="textarea-field"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <VBtn
        v-for="(type, index) in resultTypes"
        small
        color="info"
        class="mr-2 mb-2"
        :key="index"
        :disabled="form.isSaving"
        @click="addResultItem(type.value)"
      >
        <VIcon small>
          add
        </VIcon>
        <VSpacer />
        {{ type.text }}
      </VBtn>
      <Draggable
        v-if="allInputs && allInputs.length > 0"
        v-model="allInputs"
        element="div"
        id="cards-container"
        :disabled="form.isSaving"
        :options="draggableOptions"
        :no-transition-on-drag="true"
      >
        <transition-group
          name="dynamic-input-container"
          :css="true"
          tag="div"
          class="layout row wrap justify-center"
        >
          <VCol
            v-for="(part, index) in allInputs"
            :key="`dynamic-${index}`"
            :cols="allInputs.length % 2 === 0 || (allInputs.length - 1 > index) ? 6 : 12"
            :class="{
              round: true,
              [part.size ? part.size : 'auto']: true,
              'd-flex': true,
              'my-0 px-3 py-3': true,
              'align-items': 'stretch'
            }"
          >
            <div
              class="pl-2 pr-2 fluid"
              :style="{
                'background-color': part.type === 'Text' ? '#f8f8f8' : '#ffeca9',
                width: '100%',
                'border-radius': '4px'
              }"
            >
              <VRow>
                <VCol class="d-flex">
                  <VBtn
                    small
                    icon
                    color="transeparent"
                    class="rounded dynamic-input-handle mr-2"
                    :style="{
                      border: part.type === 'Text' ? 'solid 0.5px #eaeaea' : 'solid 0.5px #ecd893',
                      cursor: 'move'
                    }"
                  >
                    <VIcon>drag_handle</VIcon>
                  </VBtn>
                  <VMenu
                    v-model="part.menu"
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template #activator="{on}">
                      <VBtn
                        v-on="on"
                        small
                        class="px-0"
                        color="info"
                      >
                        {{ getSizeTitle(part.size) }}
                      </VBtn>
                    </template>
                    <VCard>
                      <VList>
                        <VListItem
                          v-for="size in sizes"
                          :key="size.size"
                          @click="part.size=size.size"
                        >
                          {{ size.title }}
                        </VListItem>
                      </VList>
                    </VCard>
                  </VMenu>
                  <VSpacer />
                  <VBtn
                    x-small
                    icon
                    style="opacity: 0.5;"
                    @click="removeItem(index)"
                  >
                    <VIcon>cancel</VIcon>
                  </VBtn>
                </VCol>
              </VRow>
              <template v-if="part.type === 'Text'">
                <TextField
                  v-model="part.value"
                  large
                  placeholder="Enter your text"
                  class="result-inputs"
                  :disabled="form.isSaving"
                  :outlined="false"
                  @blur="backgroundSaveCard"
                  @input="backgroundSaveCard"
                />
              </template>
              <template v-else>
                <Autocomplete
                  v-model="part.value"
                  large
                  placeholder="Select variable"
                  item-text="text"
                  item-value="value"
                  class="result-autocomplete"
                  :label="part.description"
                  :items="allVariables"
                  :disabled="form.isSaving"
                  :outlined="false"
                  @input="value => setPartVariable(index, value)"
                />
              </template>
            </div>
          </VCol>
        </transition-group>
      </Draggable>
    </VCol>
  </VRow>
</template>

<script>
import Draggable from "vuedraggable";
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";

export default {
  name: "SaveCardResult",
  mixins: [SaveCardMixin],
  components: {
    Draggable,
  },
  data() {
    return {
      defaultForm: {
        value: {
          parts: [],
        },
      },
      resultTypes: [
        {
          text: "Text",
          value: "Text",
        },
        {
          text: "Result Item",
          value: "ResultItem",
        },
      ],
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      sizes: {
        auto: {
          title: "Auto",
          size: "auto",
        },
        "col-xs-6 col-md-3": {
          title: "25%",
          size: "col-xs-6 col-md-3",
        },
        "col-xs-6": {
          title: "50%",
          size: "col-xs-6",
        },
        "col-xs-12 col-md-9": {
          title: "75%",
          size: "col-xs-12 col-md-9",
        },
        "col-12": {
          title: "100%",
          size: "col-12",
        },
      },
    };
  },
  computed: {
    inputCards() {
      let cards = this.assessment.all_cards || this.assessment.cards;
      return cards.filter(
        (card) =>
          ["Textarea", "TextArea", "Text", "Question"].indexOf(card.type) > -1
      );
    },
    allInputs: {
      get() {
        return (this.form.value.parts || []).map((part) => {
          if (part instanceof Object) {
            part.menu = false;
          }
          return part;
        });
      },
      set(value) {
        this.form.value.parts = value;
        this.backgroundSaveCard();
      },
    },
    allVariables() {
      let variables = [];
      this.inputCards.map((input) => {
        input.variable && variables.push(input.variable);
      });
      return variables;
    },
  },
  methods: {
    setPartVariable(index, value) {
      const current = this.form.value.parts[index];
      this.form.value.parts.splice(index, 1, {
        ...current,
        ...{
          value,
          description: this.form.variables[value],
          size: "auto",
        },
      });
      this.backgroundSaveCard();
    },
    addResultItem(type) {
      this.form.value.parts.push({
        type: type,
        value: "",
        priority: this.form.value.parts.length + 1,
        description: this.allVariables[type],
      });
      this.backgroundSaveCard();
    },
    removeItem(index) {
      this.form.value.parts.splice(index, 1);
    },
    getSizeTitle(value) {
      return this.sizes.hasOwnProperty(value)
        ? this.sizes[value].title
        : this.sizes.auto.title;
    },
  },
};
</script>

<style lang="scss">
.result-inputs input {
  padding: 8px 12px !important;
}
.result-autocomplete .v-select__selection--comma {
  padding-left: 12px !important;
}
</style>
