<template>
  <VContainer
    fluid
    class="launch-page"
    :style="style"
  >
    <VRow
      align-center
      :class="{
        'container': true,
        'pa-8': $vuetify.breakpoint.mdAndUp,
        'pa-0': $vuetify.breakpoint.smAndDown
      }"
      :style="{
        height: $vuetify.breakpoint.smAndDown ? '72px' : '150px'
      }"
    >
      <VCol
        cols="12"
        class="d-flex align-center justify-start"
        :shrink="$vuetify.breakpoint.mdAndUp"
      >
        <img
          :style="{
            maxHeight: $vuetify.breakpoint.smAndDown ? '36px' : '72px',
            minHeight: $vuetify.breakpoint.smAndDown ? '36px' : '54px'
          }"
          :src="logo"
        >
      </VCol>
    </VRow>
    <VRow
      :style="{
        height: $vuetify.breakpoint.smAndDown ? '300px' : 'calc(100vh - 150px)',
        marginBottom: $vuetify.breakpoint.smAndDown ? '0px' : '200px'
      }"
      :class="{
        'container': true,
        'pa-8': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <VCol
        class="d-flex flex-column flex-wrap"
        :grow="$vuetify.breakpoint.smAndDown"
        :class="{
          'pa-1': $vuetify.breakpoint.smAndDown
        }"
        :cols="$vuetify.breakpoint.mdAndUp ? 6 : 'auto'"
      >
        <h1
          :style="{
            marginTop: $vuetify.breakpoint.smAndDown ? '55px' : '15vh',
            marginBottom: '40px'
          }"
        >
          Join your team
          <br>on <b>WeThrive</b> <br>Mobile
        </h1>
        <template v-if="$vuetify.breakpoint.smAndDown">
          <p
            class="my-3 paragraph"
            :style="{
              'font-weight': 500,
            }"
          >
            Already have the WeThrive Mobile app?
          </p>
          <VBtn
            depressed
            color="#000000"
            class="white--text text-none launch-btn"
            style="border-radius: 16px"
            :small="$vuetify.breakpoint.smAndDown"
            :x-large="$vuetify.breakpoint.mdAndUp"
            :href="linkForLaunchMobileApp"
          >
            Launch Mobile App
          </VBtn>
        </template>
        <template v-else>
          <div>
            <VBtn
              outlined
              depressed
              color="#000000"
              class="text-none download-btn"
              :href="downloadLinkForIOS"
              :x-large="$vuetify.breakpoint.mdAndUp"
            >
              <VIcon :large="$vuetify.breakpoint.mdAndUp">
                mdi-apple
              </VIcon>
              <span>
                <h4>Download on the</h4>
                <h2 class="d-flex justify-start">App Store</h2>
              </span>
            </VBtn>
            <VBtn
              outlined
              depressed
              color="#000000"
              class="text-none ml-4 download-btn"
              :href="downloadLinkForAndroid"
              :x-large="$vuetify.breakpoint.mdAndUp"
            >
              <VIcon :large="$vuetify.breakpoint.mdAndUp">
                mdi-google-play
              </VIcon>
              <span>
                <h6 class="d-flex justify-start">ANDROID APP ON</h6>
                <h2>Google Play</h2>
              </span>
            </VBtn>
          </div>
        </template>
      </VCol>
      <VCol
        :shrink="$vuetify.breakpoint.smAndDown"
        :cols="$vuetify.breakpoint.mdAndUp ? 6 : 'auto'"
        :class="{
          'd-flex flex-wrap': true,
          'pa-0': $vuetify.breakpoint.smAndDown,
          'justify-center': $vuetify.breakpoint.mdAndUp,
          'justify-end': $vuetify.breakpoint.smAndDown
        }"
      >
        <div style="text-align: center">
          <img
            id="mobile-image"
            :src="backgroundMobileImage"
            :style="mobileImageStyle"
          >
          <div>
            <VBtn
              v-if="$vuetify.breakpoint.mdAndUp"
              depressed
              large
              color="#000000"
              class="white--text text-none py-6 mt-6 launch-btn"
              style="border-radius: 16px; max-width: 275px !important; padding: 10px 30px !important"
              :small="$vuetify.breakpoint.smAndDown"
              :x-large="$vuetify.breakpoint.mdAndUp"
              :href="linkForLaunchMobileApp"
            >
              Launch Mobile App
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>
    <VRow
      v-if="$vuetify.breakpoint.smAndDown"
      class="py-8"
    >
      <VCol
        cols="12"
        class="d-flex justify-center text-center py-6"
      >
        <span
          :style="{
            'font-size': $vuetify.breakpoint.xs ? '22px' : '36px',
            'font-weight': 500,
          }"
        >
          Need to download the app?
        </span>
      </VCol>
      <VCol
        cols="12"
        class="d-flex justify-center py-6"
      >
        <VBtn
          outlined
          depressed
          color="#000000"
          class="text-none download-btn"
          :href="downloadLinkForIOS"
          :x-large="$vuetify.breakpoint.mdAndUp"
        >
          <VIcon :large="$vuetify.breakpoint.mdAndUp">
            mdi-apple
          </VIcon>
          <span>
            <h4>Download on the</h4>
            <h2 class="d-flex justify-start">App Store</h2>
          </span>
        </VBtn>
        <VBtn
          outlined
          depressed
          color="#000000"
          class="text-none ml-4 download-btn"
          :href="downloadLinkForAndroid"
          :x-large="$vuetify.breakpoint.mdAndUp"
        >
          <VIcon :large="$vuetify.breakpoint.mdAndUp">
            mdi-google-play
          </VIcon>
          <span>
            <h6 class="d-flex justify-start">ANDROID APP ON</h6>
            <h2>Google Play</h2>
          </span>
        </VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import hubLogo from "@/images/logo_wethrivehub.png";
import normalLogo from "@/images/WeThrive-logo-black.png";
import backgroundImage from "@/images/mobile-signup/background.png";
import backgroundMobileImage from "@/images/mobile-signup/mobile-phone.png";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
export default {
  name: "MobileSignup",
  mixins: [NeedsTeamsMixin],
  data() {
    return {
      hubLogo,
      normalLogo,
      pageLinkSlug: "wethrive",
      backgroundMobileImage,
    };
  },
  computed: {
    style() {
      return {
        backgroundImage: `url('${backgroundImage}')`,
        backgroundPosition: this.$vuetify.breakpoint.mdAndUp
          ? "top -30vh right 0px"
          : "top right 0px",
        backgroundSize: this.$vuetify.breakpoint.mdAndUp
          ? "50vw 140vh"
          : "55vw 375px",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        position: "absolute",
        top: "0px",
      };
    },
    mobileImageStyle() {
      return {
        // To make up for incorrectly framed image
        marginLeft: this.$vuetify.breakpoint.smAndDown ? "0px" : "20px",
      };
    },
    downloadLinkForAndroid() {
      if(this.$route.params.team == "wethrive-education") {
        return "https://wethrive-yarn.onelink.me/PESd/fdvpol90?action=registration&team=wethrive-education";
      } else if(this.$route.params.team == "dycd") {
        return "https://wethrive-dycd.onelink.me/ENRE/fr5mc8qo?action=registration&team=dycd";
      } else {
        return `https://${this.pageLinkSlug}.onelink.me/7Zg8/7sckelno?action=registration&id=${this.teamSlug}`;
      }
    },
    linkForLaunchMobileApp() {
      if(this.$route.params.team == "wethrive-education") {
        return "https://wethrive-yarn.onelink.me/PESd/fdvpol90?action=registration&team=wethrive-education";
      } else if(this.$route.params.team == "dycd") {
        return "https://wethrive-dycd.onelink.me/ENRE/fr5mc8qo?action=registration&team=dycd";
      } else {
        return `https://${this.pageLinkSlug}.onelink.me/7Zg8/7sckelno?action=registration&id=${this.teamSlug}`;
      }
    },
    downloadLinkForIOS() {
      if(this.$route.params.team == "wethrive-education") {
        return "https://wethrive-yarn.onelink.me/PESd/fdvpol90?action=registration&team=wethrive-education";
      } else if(this.$route.params.team == "dycd") {
        return "https://wethrive-dycd.onelink.me/ENRE/fr5mc8qo?action=registration&team=dycd";
      } else {
        return `https://${this.pageLinkSlug}.onelink.me/7Zg8/7sckelno?action=registration&id=${this.teamSlug}`;
      }
    },
    logo() {
      if(this.$route.params.team == "wethrive-education") {
        return this.normalLogo;
      }
      return this.hubLogo;
    }
  },
  watch: {
    currentTeamSlug(value) {
      if (value) {
        this.pageLinkSlug = this.$team.has_whitelabel
          ? "wt" +
            value.replace(
              // eslint-disable-line
              /[^a-zA-Z0-9]/g,
              ""
            )
          : "wethrive";
      }
    },
  },
  created() {
    if (this.$route.params.team) {
      this.teamSlug = this.$route.params.team;
      this.findTeam();
    }
  },
};
</script>

<style lang="scss">
.launch-page {
  color: #000000;
  background-color: #ffffff;
  line-height: normal;

  h1 {
    font-size: 48px;
    font-weight: 100;
  }

  h2 {
    font-size: 32px;
  }
  h4 {
    font-size: 12px;
  }

  #mobile-image {
    height: 66vh;
  }
  @media (min-width: 1904px) {
    #mobile-image {
      height: 65vh;
    }
    .v-btn.download-btn,
    .v-btn.launch-btn {
      height: 84px;
      max-width: 275px;
      .v-btn__content,
      h2 {
        font-size: 22px !important;
      }
    }

    h1 {
      font-size: 96px;
    }
  }
  @media (max-width: 1903px) {
    #mobile-image {
      height: 60vh;
    }
    .v-btn.launch-btn {
      height: 50px;
    }
    .download-btn,
    .launch-btn {
      width: auto;
      height: 72px;
      max-width: 275px;
      padding: 10px 20px;
      .v-btn__content,
      h2 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 960px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    #mobile-image {
      height: auto;
      max-height: 250px;
      width: auto;
      margin-right: -10px;
    }
    .v-btn.launch-btn {
      margin-top: 20px;
      height: 32px;
      min-width: 36px;
      max-width: min-content;
      padding: 10px 12px;
      border-radius: 10px !important;
    }
    .v-btn.download-btn,
    .launch-btn {
      width: auto;
      height: 60px;
      max-width: 275px;
      padding: 10px 20px;
      .v-btn__content,
      h2 {
        font-size: smaller !important;
      }
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 10px !important;
    }

    .paragraph {
      font-size: 12px;
      margin-bottom: 10px;
      max-width: 40vw;
    }
  }
  > .row {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .v-window__prev,
  .v-window__next {
    top: unset !important;
    background-color: transparent !important;
    bottom: 8px;
    z-index: 9999;

    .v-icon {
      color: #000000;
      font-size: 85px !important;
    }
  }
  .v-carousel__controls {
    width: auto;
    position: sticky;
  }
  .v-carousel__controls__item .v-icon {
    border: 4px solid black;
    border-radius: 12px;
    opacity: 1;
  }
  .v-item--active .v-icon {
    color: #efa609 !important;
    caret-color: #efa609 !important;
    opacity: 1 !important;
    border: none !important;
    font-size: 26px !important;
  }

  .v-image {
    .v-responsive-content,
    .v-sheet {
      height: 100%;
      text-align: center;
    }
  }
}
</style>