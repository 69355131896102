<script>
export default {
  data() {
    return {
      section: 1,
      offset: 1,
    };
  },
  watch: {
    section: function (value) {
      this.errors = [];
    },
  },
  methods: {
    toSection(section) {
      this.section = section;
    },
    next() {
      if (this.section == 0) {
        this.section = 1;
      }
      this.section++;
      this.$log.debug("Advancing to next section", this.section);
      if (this.$v) {
        this.$v.$touch();
      }
    },
    prev() {
      if (this.section > this.offset) {
        this.section--;
        this.$log.debug("Advancing to previous section", this.section);
        if (this.$v) {
          this.$v.$touch();
        }
      }
    },
    inputSections(inputs) {
      return inputs
        .filter((input) => input instanceof Object)
        .map((input) => input.section)
        .unique();
    },
  },
};
</script>