<template>
  <router-view />
</template>

<script>
export default {
  name: "Pipelines",
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>