import api from '../../services/assessmentCard';
export default function (helper) {
    /**
     * Generated store module
     */
    helper.setApi(api);
    const types = helper.types('assessment_card');
    const state = helper.state();
    const getters = helper.getters();
    const actions = helper.actions({}, 'Assessment Card');
    const mutations = helper.mutations({}, types, state);

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}