<script>
import CardContent from "@/components/Lessons/Present/CardContent";
import CardTitle from "@/components/Lessons/Present/CardTitle";

export default {
  props: {
    assessment: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    existing: {
      type: Object,
      default: () => {},
    },
    result: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [String, Number],
      default: null,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Lesson",
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CardContent,
    CardTitle,
  },
  data() {
    return {
      form: {},
      defaultForm: {
        value: null,
      },
    };
  },
  computed: {
    choices() {
      return this.card.choices;
    },
    hasImages() {
      return this.images instanceof Array && this.images.length > 0;
    },
    images() {
      return this.card.images;
    },
    url() {
      return this.card.value instanceof Object ? this.card.value.url : "";
    },
    hasUrl() {
      return this.url && this.url.length > 0;
    },
    urlExists() {
      try {
        if (this.hasUrl) {
          var http = new XMLHttpRequest();

          http.open("HEAD", this.url, false);
          http.send();

          return http.status != 404;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    hasDescriptionOrTitle() {
      return (this.description || this.card.name || "").length > 0;
    },
    description() {
      let description = (this.card.value || {}).description;
      return description
        ? description
        : (this.card.options || {}).caption || this.card.description;
    },
    cardValue() {
      return this.card.value || "";
    },
    cardPlaceholder() {
      return this.card.value instanceof Object
        ? this.card.value.placeholder || "Enter response here"
        : "Enter response here";
    },
  },
  created() {
    this.prepareFormModel();
  },
  watch: {
    "card.result_for_user.value": function (value) {
      this.prepareFormModel();
    },
  },
  methods: {
    prepareFormModel() {
      const cardResult = this.result || this.card.result_for_user || {};
      this.form = this.cloneObject({
        ...this.defaultForm,
        ...this.existing,
        ...{
          is_correct: false,
        },
        ...cardResult,
      });
      this.form.value = this.form.value || this.defaultForm.value;
    },
    saveResult() {
      this.debouncer(() => this.$emit("input", this.form), `card-${this.uid}`);
    },
    selectChoice(choice) {
      const index = this.form.value.indexOf(choice.id);
      if (index === -1) {
        if (this.cardValue.type === "single") {
          this.form.value = [choice.id];
        } else {
          this.form.value.push(choice.id);
        }
      } else {
        this.form.value.splice(index, 1);
      }
      this.saveResult();
    },
    isSelectedChoice(choice) {
      return this.form.value.indexOf(choice.id) > -1;
    },
  },
};
</script>