var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{class:{
    'fill-height': true,
    'd-flex elevation-0 slightly-rounded': true
  },style:({
    borderRadius: '5px !important',
    marginTop: 0,
    maxWidth: _vm.$vuetify.breakpoint.mdAndUp ? '1100px' : '100%'
  }),attrs:{"id":"presentation-card","light":""}},[(_vm.mode === 'standalone' && _vm.card.type != 'Final')?_c('VCardTitle',{style:({
      height: '64px',
      position: 'absolute',
      top: 0,
      left: '8px',
      right: '8px',
      borderBottom: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
    })},[_c('VRow',{attrs:{"row":"","wrap":"","align-center":""}},[_c('VSpacer'),(_vm.env.isDev)?_c('VCol',{attrs:{"shrink":"","cols":"2"}},[_vm._v(" "+_vm._s(_vm.card.type)+" ")]):_vm._e(),(!_vm.dialogs.completeMessage)?_c('VCol',{attrs:{"shrink":""}},[(!_vm.isFirst)?_c('VBtn',{staticStyle:{"text-transform":"none"},attrs:{"text":""},on:{"click":_vm.saveForLater}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"mr-2"},[_vm._v(" Save for later ")]),_c('VIcon',{attrs:{"size":"19","color":_vm.isSaved ? '#efc013': 'rgba(0, 0, 0, 0.55);'}},[_vm._v(" "+_vm._s(_vm.isSaved ? 'star' : 'star_outline')+" ")])],1):_vm._e()],1):_vm._e(),(!_vm.isLast)?_c('VCol',{attrs:{"shrink":"","pr-0":""}},[_c('VBtn',{attrs:{"text":"","icon":"","color":"gray"},on:{"click":_vm.close}},[_c('VIcon',{attrs:{"color":"gray"}},[_vm._v(" close ")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('VCardText',{class:{
      'fill-height': true,
      'px-0': _vm.$vuetify.breakpoint.smAndDown,
      'pt-0': _vm.$vuetify.breakpoint.smAndDown,
      'flex xs12 align-self-center justify-self-center' : true
    },style:({
      overflowY: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden',
      overflowX: 'hidden',
      paddingLeft: '14px',
      paddingRight: '14px',
      paddingTop: '12px',
      paddingBottom: '14px',
      marginTop: _vm.$vuetify.breakpoint.smAndDown ? '0px' : 'auto'
    })},[_c('VRow',{style:({
        width: '100%',
        margin: 'auto 0',
        height: ['Imgae', 'Video', 'Final'].includes(_vm.card.type) ? '100%' : 'calc(100% - 50px)'
      }),attrs:{"align-center":"","justify-center":"","row":"","wrap":""}},[(_vm.hasImages && !_vm.dialogs.completeMessage)?_c('VCol',{class:{
          'pa-0': _vm.$vuetify.breakpoint.mdAndUp
        },style:({
          width: _vm.carousel.width,
          flex: _vm.$vuetify.breakpoint.smAndDown ? '1 1 100%' : ('0 0 ' + _vm.carousel.width)
        })},[_c('VCarousel',{staticClass:"presentation-card-images",class:{
            'elevation-0': true,
            'px-0': _vm.$vuetify.breakpoint.mdAndUp,
            'px-0': _vm.$vuetify.breakpoint.smAndDown
          },style:({
            maxHeight: _vm.carousel.height,
            overflowX: 'hidden',
            borderRadius: '6px',
            marginTop: _vm.$vuetify.breakpoint.smAndDown ? '64px' : 'inherit'
          }),attrs:{"hide-delimiters":"","show-arrows":_vm.images.length > 1,"id":`presentation-card-images-${_vm.card.id}`,"width":_vm.carousel.width,"height":_vm.carousel.height}},_vm._l((_vm.images),function(image,imageIndex){return _c('VCarouselItem',{key:imageIndex,style:({
              borderRadius: '20px',
              cursor: 'zoom-in'
            }),attrs:{"src":image.url},on:{"click":function($event){_vm.dialogs.preview = true}}})}),1),(_vm.dialogs.preview)?_c('Preview',{attrs:{"files":_vm.images},on:{"toggle":function($event){_vm.dialogs.preview = !_vm.dialogs.preview}}}):_vm._e()],1):_vm._e(),_c('VCol',{staticClass:"presentation-card-content",class:{
          'px-2': _vm.$vuetify.breakpoint.smAndDown,
          'pa-0': _vm.$vuetify.breakpoint.mdAndUp,
        },style:({
          overflowX: 'hidden',
          height: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : '100%',
          flex: `1 0 calc(100% - ${_vm.carousel.width})`,
          overflowY: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden',
          paddingBottom: _vm.$vuetify.breakpoint.smAndDown ? '100px' : '64px',
          marginTop: !['Imgae', 'Video', 'Final'].includes(_vm.card.type) && _vm.mode === 'standalone' && _vm.$vuetify.breakpoint.mdAndUp ? '50px' : '0'

        })},[_c(_vm.getComponent(_vm.card.type),{tag:"component",staticClass:"mx-0",style:({
            width: '100%',
            overflowY: 'auto',
            maxHeight: ['Imgae', 'Video', 'Final'].includes(_vm.card.type) ? '100%' : 'calc(100% - 50px)',
          }),attrs:{"card":_vm.card,"mode":_vm.mode,"index":_vm.index,"is-current":_vm.isCurrent,"is-first":_vm.isFirst,"is-last":_vm.isLast,"assessment":_vm.assessment,"result":_vm.card.result_for_user,"is-completing":_vm.isCompleting},on:{"next":function($event){return _vm.navigate('next')},"input":data => _vm.stashResultCard(data, true),"complete":_vm.onComplete}})],1)],1)],1),(_vm.enableActions)?_c('VCardActions',{staticClass:"flex xs12 justify-space-between align-items-center align-self-end px-2",style:({
      height: '68px',
      right: '0',
      position: 'absolute',
      left: '0',
      bottom: '0',
      zIndex: 0
    }),attrs:{"id":"presentation-card-actions"}},[(!_vm.dialogs.completeMessage)?_c('VRow',{attrs:{"row":"","wrap":"","align-center":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('VSpacer'):_vm._e(),_c('VCol',{attrs:{"sm":"2","md":"auto","order-sm":"2","order":"1"}},[_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canProgress),expression:"!canProgress"}],staticClass:"red--text"},[_vm._v("This card is required!")])]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('VSpacer'):_vm._e(),_c('VCol',{attrs:{"shrink":"","sm":"10","md":"auto","order-sm":"1","order":"2"}},[(!_vm.isFirst)?_c('VBtn',{staticStyle:{"background-color":"rgba(255,255,255, 0.75)"},attrs:{"text":"","color":"rgba(152, 152, 152, 0.95)"},on:{"click":function($event){return _vm.navigate('previous')}}},[_vm._v(" Previous ")]):_vm._e()],1),_c('VCol',{attrs:{"shrink":"","sm":"10","md":"auto","order-sm":"3","order":"3"}},[(!_vm.isLast)?_c('VBtn',{staticClass:"mx-2",attrs:{"depressed":"","color":"success","disabled":!_vm.canProgress},on:{"click":function($event){return _vm.navigate('next')}}},[_vm._v(" Next ")]):_vm._e(),(_vm.isLast && !_vm.dialogs.completeMessage && !_vm.isPreview)?_c('VBtn',{staticClass:"px-4",attrs:{"color":"success","loading":_vm.isCompleting,"disabled":_vm.isCompleting || !_vm.canProgress},on:{"click":_vm.onComplete}},[_c('VIcon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" check ")]),_c('span',[_vm._v("Complete Lesson")])],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }