import BaseApi from "./_base-api";

/**
 * Template API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	copyContent(params) {
		return this.api()
			.post(this.getUrl("templates", `${params.data.type}/${params.data.template_id}/duplicate-content`), params.data)
			.catch(error => {
				this.log().error("[Services: Templates: Error copying template content into program]:", error);
				throw this.utils.resolveError(error);
			});
	}
	create(params) {
		return this.api()
			.post(this.getUrl("templates", `${params.type}/hub-type`), params)
			.catch(error => {
				this.log().error("[Services: Templates: Error creating a new template]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Template",
	basePath: "api",
	path: {
		getAll: (app, _params) => {
			return `templates/${_params.type}`;
		},
		getOne: (app, _params) => {
			return `templates/${_params.type}`;
		},
		default: "templates",
	}
});

export default api;
