var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.thread)?_c('VListItem',{key:_vm.thread.thread_id,class:{
    active: _vm.currentThread && _vm.currentThread.id === _vm.thread.id,
    thread: true,
    group: _vm.isGroupThread
  },on:{"click":function($event){$event.preventDefault();return _vm.onLoadMessages.apply(null, arguments)}}},[_c('Badge',{attrs:{"overlap":"","top":"","left":"","offset-x":"26","color":"secondary","value":_vm.thread.new_messages_for_user_count > 0,"content":_vm.thread.new_messages_for_user_count || 0}},[_c('VListItemAvatar',{staticClass:"justify-center",attrs:{"width":"48","height":"48","color":"#fff"}},[_c('img',{style:({
          width: !_vm.thread.is_group && _vm.thread.photo_url ? '100%' : '20px !important'
        }),attrs:{"src":_vm.avatar}}),(_vm.thread.new_messages_for_user_count)?_c('span',{staticStyle:{"color":"white"},attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.newCount))]):_vm._e()])],1),_c('VListItemContent',[_c('VListItemTitle',{staticClass:"d-inline-flex align-center"},[_c('VCol',{staticClass:"pa-0 text-truncate",attrs:{"grow":"","title":_vm.title}},[_vm._v(" "+_vm._s(_vm.shortTitle)+" ")]),_c('VCol',{staticClass:"pa-0",staticStyle:{"font-size":"10px","color":"rgba(0,0,0, 0.65)"},attrs:{"shrink":""}},[_vm._v(" "+_vm._s(_vm.thread.date)+" ")])],1),_c('VListItemSubtitle',{domProps:{"innerHTML":_vm._s(_vm.message)}}),_c('VListItemSubtitle',{staticClass:"date"},[(_vm.message && _vm.message.length)?[_c('small',[_c('b',[_vm._v("Last message: "+_vm._s(_vm.date))])])]:_vm._e()],2),(_vm.isGroupThread && _vm.hasThreadMember)?[_c('Members',{attrs:{"members":_vm.threadMembers}})]:_vm._e(),(!_vm.hasThreadMember)?_c('VRow',{staticClass:"mx-0"},[_c('VCol',{staticClass:"text-right pa-0"},[_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [(!_vm.hasThreadMember)?_c('VBtn',_vm._g({attrs:{"icon":"","loading":_vm.thread.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.joinThread.apply(null, arguments)}}},on),[_c('VIcon',{attrs:{"color":"success"}},[_vm._v(" mdi-chat-plus ")])],1):_vm._e()]}}],null,false,1173365322)},[_vm._v(" Join this Chat ")])],1)],1):_vm._e()],2),(_vm.mode !== 'load-messages')?_c('VListItemAction',[_c('VCheckbox',{staticStyle:{"height":"auto"},attrs:{"multiple":_vm.mode === 'select-multiple',"color":"primary"},on:{"change":function($event){return _vm.$emit('selectThread', _vm.thread)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_c('Confirm',{ref:"confirmChatJoin",attrs:{"confirm-color":"info","cancel-color":"disabled"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }