<template>
  <VRow
    row
    wrap
    align-center
    justify-center
    class="request-invitation-form"
  >
    <VCol cols="12">
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VRow
        v-if="isSearchingTeam"
        justify-center
        slot="progress"
        class="mb-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
      <template v-else-if="canRegister && !inviteRequestIsSuccessful">
        <VRow
          row
          wrap
          align-center
        >
          <VCol
            cols="12"
            class="d-flex align-center justify-center"
          >
            <img
              :src="currentTeam.photo_url"
              style="backgrounded-color: white; max-height: 100px"
            >
          </VCol>
          <VCol
            cols="12"
            mt-2
          >
            <h1 class="text-center white--text display-1">
              {{ currentTeam.name }}
            </h1>
          </VCol>
        </VRow>
        <form
          class="form"
          name="login"
          @submit.prevent="register"
        >
          <VCarousel
            v-model="stage"
            hide-delimiters
            class="elevation-0 bg-transparent"
            :cycle="false"
            :show-arrows="false"
          >
            <VCarouselItem>
              <VRow>
                <VCol cols="12">
                  <TextField
                    v-model="form.name"
                    validate-on-blur
                    label="What is your name?"
                    type="text"
                    :error="$v.form.name.$error"
                    @input="$v.form.name.$touch()"
                    @blur="$v.form.name.$touch()"
                  />
                </VCol>
                <VCol
                  mt-6
                  class="d-flex align-end justify-end"
                >
                  <VSpacer />
                  <Tooltip
                    small-icon
                    align-self-end
                    icon="arrow_forward"
                    color="primary"
                    button-text="Next"
                    title="Enter your email"
                    :flat="false"
                    :block="true"
                    :icon-right="true"
                    :button-text-is-visible="$vuetify.breakpoint.mdAndUp"
                    :action="() => toStage(1)"
                    :disabled="!hasName"
                  />
                </VCol>
              </VRow>
            </VCarouselItem>
            <VCarouselItem>
              <VRow column>
                <VCol cols="12">
                  <TextField
                    v-model="form.email"
                    validate-on-blur
                    type="email"
                    :error="$v.form.email.$error"
                    :label="`What's your email, ${firstName}?`"
                    @input="$v.form.email.$touch()"
                    @blur="$v.form.email.$touch()"
                  />
                </VCol>
                <VCol
                  mt-6
                  class="d-flex align-end justify-end"
                >
                  <Tooltip
                    small-icon
                    align-self-end
                    icon="arrow_back"
                    color="primary"
                    button-text="Back"
                    :title="`Join ${currentTeam.name}`"
                    :block="true"
                    :flat="false"
                    :icon-left="true"
                    :button-text-is-visible="$vuetify.breakpoint.mdAndUp"
                    :action="() => toStage(0)"
                  />
                  <VSpacer />
                  <Tooltip
                    small-icon
                    align-self-end
                    icon="arrow_forward"
                    color="primary"
                    button-text="Next"
                    :title="`Select Your Role`"
                    :block="true"
                    :flat="false"
                    :icon-right="true"
                    :button-text-is-visible="$vuetify.breakpoint.mdAndUp"
                    :action="() => toStage(2)"
                    :disabled="!hasEmail"
                  />
                </VCol>
              </VRow>
            </VCarouselItem>
            <VCarouselItem>
              <VRow column>
                <VCol cols="12">
                  <h3 class="display-1 mt-6 mb-4 white--text">
                    Which role are you interested in?
                  </h3>
                  <VBtnToggle
                    mt-6
                    dark
                    large
                    v-model="form.role"
                    style="width: 100%"
                  >
                    <VBtn
                      v-for="(role, key) in options.roles"
                      :key="key"
                      :label="role.name"
                      block
                      color="primary"
                      large
                    >
                      {{ role.name }}
                    </VBtn>
                  </VBtnToggle>
                </VCol>
                <VCol
                  mt-6
                  class="d-flex align-end justify-end"
                >
                  <Tooltip
                    small-icon
                    icon="arrow_back"
                    color="primary"
                    button-text="Back"
                    :title="`Join ${currentTeam.name}`"
                    :block="true"
                    :flat="false"
                    :icon-left="true"
                    :button-text-is-visible="$vuetify.breakpoint.mdAndUp"
                    :action="() => toStage(1)"
                  />
                  <VSpacer />
                  <Tooltip
                    small-icon
                    icon="check_circle"
                    color="success"
                    :button-text="submitText"
                    :title="`Join ${currentTeam.name}`"
                    :block="true"
                    :flat="false"
                    :icon-right="true"
                    :button-text-is-visible="$vuetify.breakpoint.mdAndUp"
                    :action="requestInvite"
                    :disabled="!isFormReady || activity.isFormLoading"
                  />
                </VCol>
              </VRow>
            </VCarouselItem>
            <!-- <template
          v-if="hasOnboardingForm && form.current_team_onboarding_form && form.current_team_onboarding_form.metadata"
        >
          <DynamicInputsSubmit
            @input="(key, value) => onUpdateMetadata(key, value, 'metadata', 'form.current_team_onboarding_form')"
            title="Please answer the following questions"
            "form.current_team_onboarding_form.metadata"
            input-name="Onboarding Question"
          />
            </template>-->
          </VCarousel>
        </form>
      </template>
      <template v-else-if="inviteRequestIsSuccessful">
        <VAlert
          :value="true"
          type="success"
        >
          <h1 class="display-1">
            Your invite is on the way!
          </h1>
          <p>Please check your inbox. If you do not see the invite please double check your spam messages. If you still do not receive an invite please let us know.</p>
        </VAlert>
      </template>
    </VCol>
  </VRow>
</template>

<script>
import { AUTH_REQUEST, REGISTER_REQUEST } from "@/store/actionTypes";
import {
  sameAs,
  email,
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import HasMetadataMixin from "@/mixins/Metadata";

export default {
  name: "RequestInvitation",
  mixins: [NeedsTeamsMixin, FormMixin, HasMetadataMixin],
  components: {},
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stage: 0,
      error: false,
      submitClass: "wethrive-alt",
      options: {
        roles: [],
      },
      defaultForm: {
        name: "",
        email: "",
        role: "",
        team_id: null,
        terms: true,
      },
      isLoading: false,
      modelType: "RequestInvite",
      inviteRequestIsSuccessful: false,
      findTeamScope: "register",
    };
  },
  validations: {
    teamSlug: {
      minLength: minLength(3),
    },
    form: {
      name: {
        required,
      },
      email: {
        email,
        required,
      },
      team_id: {
        required,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    submitText() {
      if (this.invite) {
        return this.isLoading ? "Accepting invite..." : "Accept Invite";
      } else {
        return this.isLoading ? "Requesting access..." : "Request Acccess";
      }
    },
    hasName() {
      return this.$v.form.name.$invalid === false;
    },
    hasEmail() {
      return this.$v.form.email.$invalid === false;
    },
    canRegister() {
      return (
        this.teamExists &&
        !this.isRegistrationDisabled &&
        !this.userIsCurrentTeamMember
      );
    },
    firstName() {
      return this.form.name ? this.form.name.split(" ")[0] : "";
    },
  },
  watch: {
    currentTeam: function (team) {
      this.form.team_id = team.id;
      this.options.roles = team.signup_roles;
      this.isRegistrationDisabled = false;
      this.form.current_team_onboarding_form = {
        team_id: team.id,
        metadata: this.getMetadataForSubmit(team.onboarding_form),
      };
    },
  },
  methods: {
    ...mapActions({
      doRequestInvite: "team/requestInvite",
    }),
    requestInvite() {
      this.form.team = this.currentTeam ? this.currentTeam.name : null;
      this.form.team_id = this.currentTeam.id;
      this.form.role = this.options.roles[this.form.role].id;
      return this.saveFormModel(this.doRequestInvite)
        .then((resp) => {
          if (resp.status != "error") {
            this.isLoading = false;
            this.submitClass = "wethrive-alt";
            this.inviteRequestIsSuccessful = true;
            this.$bus.$emit(
              "notificationMessage",
              `Your invite was sent to: ${this.form.email}! Please check your email for the next steps.`
            );

            const ready = function () {
              this.form = this.cloneObject(this.defaultForm);
              this.stage = 0;
              this.messages = [];
              this.inviteRequestIsSuccessful = false;
            };

            setTimeout(() => {
              ready.call(this);
            }, 10000);
          } else {
            this.errors = resp.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    toStage(stage) {
      this.stage = stage;
    },
    next() {
      this.stage++;
    },
    prev() {
      this.stage--;
    },
  },
  created() {
    if (this.$route.params.team) {
      this.teamSlug = this.$route.params.team;
      this.findTeam().then(() => {
        if (this.teamExists) {
          this.defaultForm.team_id = this.form.team_id = this.currentTeam.id;
        }
      });
    }
  },
};
</script>
<style lang="scss">
$input-height: 120px;
$input-font-size: 2rem;

.request-invitation-form {
  .v-carousel {
    .v-responsive__content {
      display: flex;
      align-items: center;
    }
  }

  .v-input:not(.v-input--checkbox):not(.v-input--radio):not(.v-input--switch)
    fieldset {
    background-color: rgba(255, 255, 255, 0.15) !important;
  }

  .v-input.v-text-field {
    margin-top: 20px;
  }

  .v-input,
  .v-input .v-input__control,
  .v-input .v-input__control .v-input__slot .v-text-field__slot {
    height: 120px;
  }

  .v-input .v-input__control .v-input__slot .v-text-field__slot {
    input[type="text"],
    input[type="email"] {
      height: $input-height / 1.5;
      max-height: $input-height / 1.5;
      font-size: $input-font-size;
      margin-top: $input-height / 3;
    }

    label {
      color: white;
      height: $input-height / 2;
      line-height: $input-height / 2;
      max-height: $input-height / 2;
      font-size: $input-font-size;
    }
  }
}
</style>