var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"index-header",attrs:{"row":"","align-center":"","column":_vm.$vuetify.breakpoint.smAndDown,"wrap":_vm.$vuetify.breakpoint.smAndDown}},[_c('VCol',{class:{
      xs12: _vm.$vuetify.breakpoint.smAndDown,
      'order-sm1': true,
      'order-md1': true
    },attrs:{"grow":""}},[_c('VRow',{class:{
        'flex-nowrap': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"row":"","align-center":"","justify-start":""}},[_c('VCol',{attrs:{"pl-3":""}},[_c('h1',{class:{
            'text-truncate text-no-wrap title' : true,
            'text-center': _vm.$vuetify.breakpoint.smAndDown
          },style:({
            textTransform: _vm.uppercaseTitle ? 'uppercase' : 'none',
            whiteSpace: _vm.$vuetify.breakpoint.smAndDown ? 'normal' : 'normal',
          })},[_c('VBtn',{attrs:{"icon":"","fab":""},on:{"click":_vm.onGoBack}},[_c('VIcon',[_vm._v("arrow_back")])],1),_vm._t("title",function(){return [_vm._v(" View "+_vm._s(_vm.featureName(_vm.title || _vm.modelType).capitalize())+"   ")]}),_c('BetaBadge',{attrs:{"enable-tooltip":"","is-beta":_vm.isBetaFeature((_vm.owner || _vm.$parent).modelType)}}),_c('Linkable',{attrs:{"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.view`, _vm.routeParams || ((_vm.owner || _vm.$parent).model || {}).id)}}),(!_vm.$isUserStudent)?_c('Linkable',{attrs:{"icon":"edit","tooltip":"Click or tap to update","copyable":false,"icon-props":{small: true},"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.update`, _vm.routeParams || ((_vm.owner || _vm.$parent).model || {}).id),"to":_vm.getRouteForItem(`${_vm.modelTypeRoute}.update`, _vm.routeParams || ((_vm.owner || _vm.$parent).model || {}).id)}}):_vm._e(),_vm._t("extra-buttons")],2)]),_c('VCol',{staticStyle:{"flex":"0 0 48px"}},[_vm._t("title-button")],2)],1)],1),(_vm.hasContent)?_c('VCol',{class:{
      xs12: _vm.$vuetify.breakpoint.smAndDown,
      md4: _vm.$vuetify.breakpoint.mdAndUp || _vm.hasContent,
      'order-sm3': true,
      'order-md2': true,
      'd-inline-flex': true
    },attrs:{"align-center":"","justify-start":""}},[_vm._t("content-center")],2):_vm._e(),_c('VCol',{class:{
      xs12: _vm.$vuetify.breakpoint.smAndDown,
      'order-sm2': true,
      'order-md3': true
    }},[_c('VRow',{class:{
        'flex-nowrap': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"row":"","align-center":"","justify-end":_vm.$vuetify.breakpoint.smAndUp}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('VSpacer'):_vm._e(),_c('VCol',{staticClass:"d-inline-flex",attrs:{"px-1":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto',"shrink":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._t("actions-left")],2),_c('VCol',{staticClass:"d-inline-flex",attrs:{"px-1":"","cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 'auto',"shrink":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._t("actions-right")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }