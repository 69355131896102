import api from '../../services/program';
export default function (helper) {
  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('program');
  const state = helper.state({
    viewAs: "organization-admin",
  });
  const getters = helper.getters();
  const actions = helper.actions({
    setUserPermissions(context, params, programId, userId) {
      helper
        .log()
        .info(`[Store: Program}]: Set user permissions for a program`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .setUserPermissions(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Set user permissions for a program`, response.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error Setting user permissions for a program`, error);
            reject(error);
          })
      });
    },
    removeAssociatedItems(context, params) {
      helper
        .log()
        .info(`[Store: Program}]: Remove associated items of a program`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .removeAttachments(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Removed associated items of a program`, response.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error remove associated items of a program`, error);
            reject(error);
          })
      });
    },
  }, 'Program');
  const mutations = helper.mutations({
    updateViewAs(state, data) {
      return state.viewAs = data;
    },
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}