import api from "../../services/reportDashboard";
export default function (helper) {
	const TYPES = {
		PIN_REPORT: "PIN_REPORT",
		UNPIN_REPORT: "UNPIN_REPORT",
	};
	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("report_dashboard");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions(
		{
			pin(context, id) {
				helper.log().info("[Store: Report Dashboard]: Pin Report", id);
				return helper
					.api()
					.pin(id)
					.then((response) => {
						helper
							.log()
							.info(`[Store: Report Dashboard]: Pin Report`, response);
						const data = response.data.data;
						context.commit(TYPES.PIN_REPORT, data);
						return data;
					})
					.catch((error) => {
						helper
							.log()
							.info(`[Store: Report Dashboard]: Error Pinning Reports`, error);
						throw error;
					});
			},
			unpin(context, id) {
				helper.log().info("[Store: Report Dashboard]: Unpin Report", id);
				return helper
					.api()
					.unpin(id)
					.then((response) => {
						helper
							.log()
							.info(`[Store: Report Dashboard]: Unpin Report`, response);
						const data = response.data.data;
						context.commit(TYPES.UNPIN_REPORT, data);
						return data;
					})
					.catch((error) => {
						helper
							.log()
							.info(
								`[Store: Report Dashboard]: Error Unpinning Reports`,
								error
							);
						throw error;
					});
			},
		},
		"Report Dashboard"
	);
	const mutations = helper.mutations(
		{
			[TYPES.PIN_REPORT](state, data) {
				return data;
			},
			[TYPES.UNPIN_REPORT](state, data) {
				return data;
			},
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
