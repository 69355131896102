<template>
  <!-- For Enterprise/applicant -->
  <VContainer
    v-if="!$isUserApproved && hasSubmittedApplication"
    fluid
    :class="{
      'py-0 fill-height': true,
      'px-0': $vuetify.breakpoint.mdAndUp,
      'px-2': $vuetify.breakpoint.smAndDown,
    }"
  >
    <EnterprisePortal v-if="$team.is_enterprise" />
    <StandardPortal v-else />
  </VContainer>
  <!-- End -->
  <VContainer
    v-else
    grid-list-lg
    max-width=""
    :style="{
      maxWidth: '834px',
      height: 'auto'
    }"
    :class="{
      'dashboard-panel': true,
      'px-0': $vuetify.breakpoint.mdAndUp,
      'px-2': $vuetify.breakpoint.smAndDown,
    }"
  >
    <VRow>
      <VCol>
        <h2 class="headline">
          Welcome Back,
        </h2>
        <h1 class="display-3 font-weight-bold	">
          {{ $user.first_name }}!
        </h1>
      </VCol>
    </VRow>
    <VRow
      align-center
      justify-center
    >
      <VCol
        cols="12"
        :class="{
          'pl-0': $vuetify.breakpoint.mdAndUp,
          'pb-4 pt-0': true
        }"
      >
        <PipelineApplication style="width: 100%" />
      </VCol>
      <!-- <VCol
        v-if="!($user.team_user || {}).is_approved"
        cols="12"
        class="pl-0 pb-1"
      >
        <VAlert
          class="mb-0"
          type="warning"
        >
          You haven't been approved for this team yet. Once you are approved you will have full access to this teams workspace.
        </VAlert>
      </VCol> -->
      <VCol
        sm="6"
        md="4"
        :class="{
          'pl-0': $vuetify.breakpoint.mdAndUp,
          'pb-1 pt-0': true
        }"
        :style="{
          flex: '0 1 417px',
          maxWidth: '417px'
        }"
      >
        <TeamMenuActivator
          block
          top
          large
          button-classes="dashboard-column"
          image-height="60px"
          @click="$bus.$emit('toggleTeamsDialog')"
        />
      </VCol>
      <VCol
        sm="6"
        md="4"
        :class="{
          'pl-0': $vuetify.breakpoint.mdAndUp,
          'mt-3': $vuetify.breakpoint.smAndDown,
          'pb-1 pt-0': true
        }"
        :style="{
          flex: '0 1 417px',
          maxWidth: '417px',
        }"
      >
        <div
          class="dashboard-column px-3"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.primary,
            borderRadius: '10px'
          }"
        >
          <VRow
            align-center
            justify-space-between
            class="fill-height px-3"
          >
            <VCol v-if="activity.isLoading">
              <VSkeletonLoader type="avatar" />
            </VCol>
            <template v-else-if="config.threads.length > 0">
              <VCol
                v-for="(thread, threadIndex) in config.threads"
                shrink
                :key="threadIndex"
                class="px-1"
              >
                <VBadge
                  top
                  left
                  offset-x="20"
                  offset-y="20"
                  color="#ff2b7a"
                  :content="thread.new_messages_for_user_count || 0"
                  :value="thread.new_messages_for_user_count > 0"
                >
                  <VAvatar
                    width="65px"
                    height="65px"
                    class="v-btn v-btn--rounded"
                    :key="threadIndex"
                    :style="{
                      objectFit: 'contain',
                      cursor: 'pointer'
                    }"
                    @click="navigateTo({
                      name: 'chat.index',
                      params: {
                        id: thread.thread_id
                      }
                    })"
                  >
                    <img
                      v-if="(thread.to_user instanceof Object)"
                      :src="thread.to_user.photo_url"
                    >
                    <span v-else>{{ thread.title.substring(0, 2).toUpperCase() }}</span>
                  </VAvatar>
                </VBadge>
              </VCol>
            </template>
            <VCol
              shrink
              v-else
            >
              <h1
                class="title white--text pa-2 text-no-wrap"
                color="info"
              >
                No messages
              </h1>
            </VCol>
            <VSpacer />
            <VCol
              shrink
              justify-self-end
              class="px-1"
            >
              <VBtn
                icon
                large
                class="wethrive"
                style="height: 60px; width: 60px"
                @click="navigateTo({
                  name: 'chat.index'
                })"
              >
                <img
                  width="29"
                  height="26"
                  :src="chatIcon"
                >
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </VCol>
    </VRow>
    <MainMenu dashboard />
    <DueItems
      v-if="dialogs.attendanceDue"
      title="Atttendance Due"
      :items="dueData"
      type="Attendance"
      @toggle:dialog="onToggleDue('Attendance', [])"
    >
      <template #item="{item}">
        <VListItemContent>
          <VListItemTitle>{{ item.entity ? item.entity.title : '(not set)' }}: Held on: {{ $timezone.formatDateTime(item.date) }}</VListItemTitle>
          <VListItemSubtitle>{{ item.entity ? item.entity.content : '' }}</VListItemSubtitle>
        </VListItemContent>
      </template>
      <template #submit="{item}">
        <AttendanceSubmit
          mode="dialog"
          :user-form="{
            program_id: item.program_id
          }"
          @submitted="removeSavedAttendance"
        />
      </template>
    </DueItems>
    <DueItems
      v-if="dialogs.deliverablesDue"
      title="Deliverables Due"
      :items="dueData"
      @toggle:dialog="onToggleDue('Deliverables', [])"
    >
      <template #item="{item}">
        <VListItemTitle>{{ item.title }}</VListItemTitle>
      </template>
      <template #submit="deliverableParent">
        <DeliverablesSubmit
          mode="dialog"
          property="program_id"
          entity-type="App\Models\Program"
          :parent-uid="deliverableParent.uid"
          :deliverable="deliverableParent.item"
          @submitted="removeSavedDeliverable"
        />
      </template>
    </DueItems>
  </VContainer>
</template>

<script>
import HasActivityMixin from "@/mixins/Activity";
// import HasStats from "@/mixins/HasStats";
// import HasCalendarEntries from "@/mixins/HasCalendarEntries";
// import GoogleAuthMixin from "@/mixins/GoogleAuth";
import DueItems from "./DueItems";
import DeliverablesSubmit from "@/components/Deliverables/SubmitForm";
// import AttendanceSubmit from "@/components/Attendance/Save";
import PipelineApplication from "./PipelineApplication";

// For applicant/enterpise
import EnterprisePortal from "./EnterprisePortal";
import StandardPortal from "./StandardPortal";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
// End

import MainMenu from "@/components/Elements/Navigation/MainMenu";
import chatIcon from "../../images/message-icon@2x.png";
import TeamMenuActivator from "@/components/Elements/Notifications/TeamMenuActivator";
import { mapActions } from "vuex";
export default {
  name: "Dashboard",
  components: {
    DueItems,
    DeliverablesSubmit,
    // AttendanceSubmit,
    PipelineApplication,
    EnterprisePortal,
    StandardPortal,
    MainMenu,
    TeamMenuActivator,
  },
  mixins: [
    HasApplicationPipelinesMixin,
    HasActivityMixin,
    // HasStats,
    // HasCalendarEntries,
    // GoogleAuthMixin
  ],
  data() {
    return {
      chatIcon,
      date: null,
      dialogs: {
        attendanceDue: false,
        deliverablesDue: false,
      },
      dueData: [],
      config: {
        stats: {
          "-": "-",
        },
        content: [],
        events: [],
        threads: [],
        due: [
          {
            title: this.featureName("Deliverables"),
            count: 0,
          },
          {
            title: this.featureName("Reports"),
            count: 0,
          },
          {
            title: this.featureName("Attendance"),
            count: 0,
          },
          {
            title: "",
            count: "",
          },
        ],
      },
    };
  },
  computed: {
    invitationsCount() {
      return this.config.invitations_count || this.$user.invitations_count || 0;
    },
    allEvents() {
      const localEvents =
        this.config.events instanceof Array
          ? this.config.events.map((event) =>
              this.formatEvent(event, event.calendar)
            )
          : [];
      const remoteEvents =
        this.config.remote_calendars instanceof Array ? this.remoteEvents : [];
      return localEvents.concat(remoteEvents);
    },
    couponClaimed() {
      return window.localStorage.getItem("upwork-coupon") === "true";
    },
    canShowPromotion() {
      return (
        this.$isUserAdmin &&
        !this.couponClaimed &&
        this.$timezone.moment().isBefore("2019-01-01 00:00:00")
      );
    },
    statOptions() {
      return this.$team && this.$team.feature_names instanceof Object
        ? {
            inflect: {
              deliverables_due: {
                singular: `${this.featureName("Deliverables")} Due`.dedupe(),
                plural: `${this.featureName("Deliverables")} Due`.dedupe(),
              },
              reports_submitted: {
                singular: this.featureName("Reports") + " Submitted",
                plural: this.featureName("Reports") + " Submitted",
              },
            },
            indicators: {
              /** If it ends in _due we should indicate it */
              _due$: function (value, type) {
                return value > 0 && type === "danger" ? true : false;
              },
            },
          }
        : { "-": "-" };
    },
    hasSubmittedApplication() {
      return this.$user.has_submitted_application;
    },
  },
  created() {
    this.$emit("startLoading");
    if (
      this.$isUserApproved ||
      (!this.$isUserApproved && !this.$team.can_customize)
    ) {
      this.doGetIndexConfig()
        .then((result) => {
          this.config = result;
          // const remoteCalendars = result.remote_calendars;
          // this.loadRemoteCalendarApisFor(remoteCalendars);
          this.$user.invitations_count = result.invitations_count || 0;
        })
        .finally(() => {
          this.$emit("doneLoading");
        });
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "team/getIndexConfig",
    }),
    claimCoupon() {
      window.localStorage.setItem("upwork-coupon", true);
    },
    onToggleDue(title, data) {
      const key = title.toLowerCase();
      const index = `${key}Due`;
      this.dialogs[index] = !this.dialogs[index];
      this.dueData = data;
    },
    async loadRemoteCalendarApisFor(calendars) {
      if (calendars instanceof Array) {
        this.loadGoogleApi().then(() => {
          this.loadGoogleCalendarApi().then(() => {
            calendars.map((calendar) => this.formatEvents(calendar));
          });
        });
      }
    },
    removeDueItem(id, type) {
      if (this.config instanceof Object && this.config.due instanceof Array) {
        const contentIndex = this.config.due.findIndex((d) => d.type == type);
        if (
          contentIndex > -1 &&
          this.config.due[contentIndex].data instanceof Array &&
          this.config.due[contentIndex].data.length > 0
        ) {
          const index = this.config.due[contentIndex].data.findIndex(
            (d) => d.id == id
          );
          if (index > -1) {
            this.config.due[contentIndex].count =
              this.config.due[contentIndex].count - 1;
            this.$delete(this.config.due[contentIndex].data, index);
          }
        }
      }
    },
    removeSavedDeliverable(id) {
      this.removeDueItem(id, "Deliverables");
    },
    removeSavedAttendance(id) {
      this.removeDueItem(id, "Attendance");
    },
  },
};
</script>

<style lang="scss">
.v-picker--landscape .v-picker__actions,
.v-picker--landscape .v-picker__body {
  margin-left: 0px;
}

.dashboard-column {
  height: 94px !important;
  border-radius: 10px !important;
}
</style>
