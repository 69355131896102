<script>
import ActivityMixin from "./Activity.vue";
import { mapActions } from "vuex";

export default {
  mixins: [ActivityMixin],
  data() {
    return {
      chatThread: {},
      selectedPipeline: {},
      dialogs: {
        applicationForm: false,
        applicantChat: false,
        applicantChats: [],
      },
      currentApplicationModel: {},
    };
  },
  computed: {
    pipelineDetails() {
      const applicationModel = this.currentApplicationModel || {};
      const applicationPipeline = applicationModel.pipeline || {};
      return {
        id: applicationPipeline.id,
        title: applicationPipeline.title,
        current_user_submitted_count: 1,
        updatedAt: applicationModel.updated_at
          ? this.$timezone.formatDate(applicationModel.updated_at, "MMM DD,YYYY")
          : null,
      };
    },
    availablePipelines() {
      return [this.pipelineDetails]
        .concat(this.$user.available_pipelines || [])
        .filter((p) => p.id != null)
        .unique((p) => p.id);
    },
    canEditApplication() {
      return (
        !this.isApplicationExpired &&
        ((this.$teamSettings.pipeline.is_application_editable_until_deadline &&
          !this.isApplicationSubmitted) ||
          !this.isApplicationSubmitted)
      );
    },
    currentApplicationStageForUser() {
      return this.submittedApplication.current_stage_for_user || { is_expired: true };
    },
    currentApplicationStage() {
      return (
        (this.currentApplicationStageForUser || {}).stage || {
          is_expired: true,
        }
      );
    },
    currentApplicationStageId() {
      return this.currentApplicationStage.id;
    },
    currentApplicationStageIndex() {
      return this.submittedApplicationStages.findIndex(
        (s) => s.pipeline_stage_id === this.currentApplicationStageId
      );
    },
    hasCurrentStage() {
      return (
        this.currentApplicationStageId > 0 || this.currentApplicationStage.pipeline_id > 0
      );
    },
    submittedApplicationStages() {
      return this.hasSubmittedApplication ? this.submittedApplication.stages || [] : [];
    },
    submittedApplication() {
      return this.hasCurrentApplicationModel
        ? this.currentApplicationModel || {}
        : this.$user.submitted_application || {};
    },
    submittedApplicationThreadTitle() {
      const thread =
        this.submittedApplication.thread_for_current_user ||
        this.submittedApplication.thread_for_user;
      if (!thread) {
        const baseTitle = "Application Chat";
        const entity = this.submittedApplication.pipeline;
        const user = this.submittedApplication.user || {};
        if (entity && entity.id) {
          const entityTitle = entity.title
            ? entity.title.indexOf(baseTitle) === -1
              ? `${baseTitle}: ${entity.title}`
              : entity.title
            : baseTitle;
          if (entityTitle.indexOf(user.name) === -1) {
            return `${entityTitle}${user ? ": " + user.name : ""}`;
          } else {
            return entityTitle;
          }
        }
        return `${baseTitle}${user ? ": " + user.name : ""}`;
      }
      return thread.title;
    },
    hasCurrentApplicationModel() {
      return (
        this.currentApplicationModel instanceof Object &&
        this.currentApplicationModel.pipeline_id > 0
      );
    },
    hasSubmittedApplication() {
      return (
        this.hasCurrentApplicationModel ||
        (this.$user.submitted_application instanceof Object &&
          this.$user.submitted_application.id > 0)
      );
    },
    isApplicationExpired() {
      const isApplicationExpired =
        (this.hasSubmittedApplication && !this.submittedApplication.pipeline.is_active) ||
        this.submittedApplication.is_complete === true;
      if (isApplicationExpired) {
        return true;
      } else {
        return (
          this.currentApplicationStage.is_expired ||
          this.currentApplicationStage.is_completed
        );
      }
    },
    isApplicationSubmitted() {
      return (
        this.currentApplicationStageForUser.is_submitted ||
        this.submittedApplication.is_submitted === true
      );
    },
    hasApplicationDeadline() {
      return this.applicationDeadline != null;
    },
    applicationDeadline() {
      if (!this.hasSubmittedApplication) {
        return null;
      }
      const dueOn =
        this.submittedApplication.pipeline.public_due_on ||
        this.submittedApplication.pipeline.due_on;
      return dueOn ? this.$timezone.formatDateTime(dueOn) : null;
    },
    hasCurrentStageDeadline() {
      return this.currentStageDeadline != null;
    },
    currentStageDeadline() {
      if (!this.hasCurrentStage) {
        return this.applicationDeadline;
      }
      const dueOn =
        this.currentApplicationStage.public_due_on || this.currentApplicationStage.due_on;
      return dueOn ? this.$timezone.formatDateTime(dueOn) : this.applicationDeadline;
    },
    applicationAlertIcon() {
      if (this.hasSubmittedApplication) {
        if (
          this.currentApplicationStageForUser.is_complete ||
          this.currentApplicationStageForUser.is_submitted ||
          this.submittedApplication.is_complete
        ) {
          return "check_circle";
        }
        if (this.currentApplicationStageHasMissingMetadata) {
          return "warning";
        }
        if (this.isApplicationExpired) {
          return "cancel";
        }
        return "info";
      }
      return "info";
    },
    applicationAlertType() {
      if (this.hasSubmittedApplication) {
        if (
          this.currentApplicationStageForUser.is_complete ||
          this.currentApplicationStageForUser.is_submitted ||
          this.submittedApplication.is_complete
        ) {
          return "green";
        }
        if (this.currentApplicationStageHasMissingMetadata) {
          return "orange";
        }
        if (this.isApplicationExpired) {
          return "red";
        }
        return "light-blue";
      }
      return "info";
    },
    hasApplicationPipeline() {
      return (
        this.$team.application_pipeline instanceof Object &&
        this.$team.application_pipeline.id > 0
      );
    },
    currentApplicationStageHasMissingMetadata() {
      return (
        !this.currentApplicationStageForUser.is_submitted &&
        !this.currentApplicationStageForUser.is_complete &&
        this.currentApplicationStageForUser.missing_metadata_count > 0
      );
    },
    steps() {
      return (
        ((this.currentApplicationModel || {}).pipeline || {}).timeline || []
      ).sortBy("value");
    },
    userAvatar() {
      return this.$user ? this.$user.photo_url : "";
    },
    lastProfileUpdated() {
      if (this.$user.updated_at) {
        const updated = this.$timezone.moment(
          this.$user.updated_at,
          "YYYY-MM-DD HH:mm:ss"
        );
        const currentDT = this.$timezone.moment().utc().format("YYYY-MM-DD HH:mm:ss");
        return this.$timezone.moment().diff(updated, currentDT);
      }
      return "";
    },
    nextNotification() {
      return (
        this.steps.find((step) => this.$timezone.moment().isBefore(step.value)) ||
        this.steps[0]
      );
    },
  },
  methods: {
    ...mapActions({
      doGetApplication: "pipelineSubmitted/getOne",
      doSaveApplication: "pipelineSubmitted/save",
    }),
    onShowApplicationFormOnHash() {
      if (this.$route.hash === "#edit-application") {
        this.onToggleApplicationForm();
      }
    },
    onChangePipeline(pipeline) {
      this.selectedPipeline = pipeline.id;
      window.localStorage.setItem(
        `currentApplicationPipelineId${pipeline.team_id}`,
        pipeline.id
      );
      this.$emit("startLoading");
      if (pipeline.current_user_submitted_count) {
        this.doGetApplication({
          id: "current",
          pipeline_id: pipeline.id,
        })
          .then((application) => {
            this.currentApplicationModel = application;
            this.selectedPipeline = application.pipeline;
          })
          .finally(() => {
            this.$emit("doneLoading");
          });
      } else {
        this.doSaveApplication({
          user_id: this.$user.id,
          stages: [],
          pipeline_id: pipeline.id,
        })
          .then((application) => {
            this.currentApplicationModel = application;
            this.selectedPipeline = application.pipeline;
          })
          .finally(() => {
            this.$emit("doneLoading");
          });
      }
    },
    getApplicationChatTitle(_application) {
      const application = _application || {};
      const thread = application.thread_for_current_user || {
        user: { name: "" },
      };
      return (
        thread.title ||
        `Application Chat: ${application.user.name} : ${application.title}`
      );
    },
    getApplicationReviewers(pipeline) {
      const reviewers = (pipeline || {}).reviewers;
      return (reviewers || []).filter((u) => u instanceof Object).map((u) => u.id);
    },
    onToggleApplicationForm(application) {
      if (application instanceof Object && application.id) {
        this.currentApplicationModel = application;
      }
      this.dialogs.applicationForm = !this.dialogs.applicationForm;
      // this.$set(application, "isLoading", true);
    },
    onToggleApplicantChat(application) {
      if (application) {
        this.currentApplicationModel = application;
      }
      this.dialogs.applicantChat = !this.dialogs.applicantChat;
    },
    onToggleApplicantChats(index, application) {
      if (application) {
        this.currentApplicationModel = application;
      }
      this.dialogs.applicantChats[index] = !this.dialogs.applicantChats[index];
    },
    hasMissingMetadata(stage) {
      return (
        !stage.is_submitted && !stage.is_complete && stage.missing_metadata_count > 0
      );
    },
    getApplicationStatusIcon(application) {
      if (
        !(application instanceof Object) ||
        (!application.is_complete && !application.is_submitted)
      ) {
        return "error";
      }
      if (
        (application instanceof Object && application.is_complete) ||
        application.is_submitted
      ) {
        return "check_circle";
      }
      return "";
    },
    getApplicationStatusIconColor(application) {
      if (!application.is_complete && !application.is_submitted) {
        return "warning";
      }
      return stage.is_complete ? "success" : "info";
    },
    getApplicationStatusColor(application) {
      if (!application.is_active) {
        return "warning";
      }
      return "";
    },
    getApplicationSubmittedOn(application) {
      return application.submitted_on
        ? this.$timezone.formatDateTime(application.submitted_on)
        : application.internal_status || "Not Started";
    },
    getCurrentStageStatusIcon(_stage) {
      const stage = _stage || {};
      if (
        (stage.is_expired && !stage.is_complete && !stage.is_submitted) ||
        this.hasMissingMetadata(stage)
      ) {
        return "error";
      }
      if (stage.is_complete || stage.is_submitted) {
        return "check_circle";
      }
      return "";
    },
    getCurrentStageStatusIconColor(_stage) {
      const stage = _stage || {};
      if (
        (stage.is_expired && !stage.is_complete && !stage.is_submitted) ||
        this.hasMissingMetadata(stage)
      ) {
        return "inherit";
      }
      return stage.is_complete ? "success" : "info";
    },
    getCurrentStageStatusTextColor(_stage) {
      const stage = _stage || {};
      if (
        (stage.is_expired && !stage.is_complete && !stage.is_submitted) ||
        this.hasMissingMetadata(stage)
      ) {
        return "white";
      }
      return "black";
    },
    getCurrentStageStatusColor(_stage) {
      const stage = _stage || {};
      if (
        (stage.is_expired && !stage.is_complete && !stage.is_submitted) ||
        this.hasMissingMetadata(stage)
      ) {
        return "warning";
      }
      return "";
    },
    getCurrentStageSubmittedOn(stage) {
      return stage.submitted_on
        ? this.$timezone.formatDateTime(stage.submitted_on)
        : stage.status || "Not Started";
    },
    getCurrentStageText(stage) {
      return !stage.is_submitted && !stage.is_complete
        ? stage.internal_status || "Not Started"
        : stage.status || "Not Started";
    },
    getCurrentStageMessage(stage) {
      return stage.status_message;
    },
  },
};
</script>
