var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","id":"view-evaluated-pipeline","type":"card","loading":!_vm.modelHasId}},[_c('VRow',{attrs:{"column":"","fill-height":""}},[(_vm.mode === 'standalone')?_c('VCol',{staticStyle:{"flex":"0 0 64px"}},[_c('VRow',{attrs:{"row":"","fill-height":"","justify-space-between":""}},[_c('h1',[_c('VBtn',{attrs:{"icon":"","fab":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("arrow_back")])],1),_vm._v(" View "+_vm._s(_vm.featureName("Evaluated Applications"))),(_vm.model)?[_c('Linkable',{attrs:{"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.view`, _vm.model.id)}}),(!_vm.$isUserStudent)?_c('Linkable',{attrs:{"icon":"edit","tooltip":"Click or tap to update","copyable":false,"icon-props":{ small: true },"url":_vm.getFullyQualifiedUrlForItem(`${_vm.modelTypeRoute}.update`, _vm.model.id),"to":_vm.getRouteForItem(`${_vm.modelTypeRoute}.update`, _vm.model.id)}}):_vm._e()]:_vm._e()],2)])],1):_vm._e(),_c('VCol',{staticClass:"pb-0",staticStyle:{"flex":"1 1 auto"}},[_c('VRow',{attrs:{"row":"","wrap":""}},[_c('VCol',{attrs:{"cols":"12"}},[_c('VCard',[(_vm.mode !== 'embedded')?_c('h1',{staticClass:"title mb-2"},[_vm._v(" "+_vm._s(_vm.model.title)+" ")]):_vm._e(),_c('VRow',{staticClass:"pl-3 align-center",attrs:{"row":"","wrap":""}},[_c('VCol',{class:{
                  'pb-0': _vm.$vuetify.breakpoint.smAndDown,
                }},[_c('VList',{attrs:{"dense":""}},[_c('VListItem',[_c('VListItemContent',[_vm._v(" User ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('UserModal',{attrs:{"title":_vm.model.pipeline_submitted.title,"new-messages-count":_vm.model.pipeline_submitted.new_messages_for_user_count,"members":(_vm.model.pipeline_submitted.reviewers || []).map((u) => u.id),"entity":_vm.model.pipeline_submitted,"thread":_vm.model.pipeline_submitted.thread_for_user,"user":_vm.model.user,"enable-edit":_vm.$isUserAdmin,"enable-chat":_vm.teamSetting('content.is_chat_enabled', true) || true}})],1)],1),_c('VListItem',[_c('VListItemContent',[_vm._v(" "+_vm._s(_vm.featureName("Stage"))+" ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('VChip',{staticClass:"text-wrap",staticStyle:{"height":"auto","min-height":"32px","flex":"none"}},[_vm._v(" "+_vm._s(_vm.model.stage.title)+" ")])],1)],1),(_vm.limitedDetails)?_c('VListItem',[_c('VListItemContent',[_vm._v(" Status ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('VChip',{staticStyle:{"flex":"none"},attrs:{"color":_vm.model.stage.is_expired ? 'light-grey' : 'success',"text-color":_vm.model.stage.is_expired ? 'light-grey' : 'white'}},[_vm._v(" "+_vm._s(_vm.model.status.titleize())+" ")])],1)],1):_vm._e()],1)],1),(!_vm.limitedDetails)?_c('VCol',{class:{
                  'pt-0': _vm.$vuetify.breakpoint.smAndDown,
                }},[_c('VList',{attrs:{"dense":""}},[_c('VListItem',[_c('VListItemContent',[_vm._v(" Status ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('VChip',{attrs:{"color":_vm.model.stage.is_expired ? 'light-grey' : 'success',"text-color":_vm.model.stage.is_expired ? 'light-grey' : 'white'}},[_vm._v(" "+_vm._s(_vm.model.status.titleize())+" ")])],1)],1),_c('VListItem',[_c('VListItemContent',[_vm._v(" Submitted By ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('UserModal',{attrs:{"title":`${_vm.model.submitted_by.name} (${_vm.model.pipeline_submitted.pipeline.title})`,"new-messages-count":_vm.model.pipeline_submitted.new_messages_for_user_count ||
                            _vm.model.pipeline_submitted.new_messages_count,"members":(_vm.model.pipeline_submitted.reviewers || []).map((u) => u.id),"entity":_vm.model.pipeline_submitted,"thread":_vm.model.pipeline_submitted.thread_for_user || {},"user":_vm.model.submitted_by.user || _vm.model.user,"enable-edit":_vm.$isUserAdmin,"enable-chat":_vm.teamSetting('content.is_chat_enabled', true)}})],1)],1),_c('VListItem',[_c('VListItemContent',[_vm._v(" Submitted On ")]),_c('VListItemContent',{staticClass:"align-end"},[_c('Tooltip',{attrs:{"as-button":false,"color":(_vm.model.stage || { is_expired: false }).is_expired &&
                            (!_vm.model.is_complete || !_vm.model.is_submitted)
                            ? 'warning'
                            : '',"text-color":(_vm.model.stage || { is_expired: false }).is_expired &&
                            (!_vm.model.is_complete || !_vm.model.is_submitted)
                            ? 'white'
                            : '',"icon-color":_vm.model.is_complete ? 'success' : 'info',"icon":_vm.getApplicationStatusIcon(_vm.model),"title":_vm.model.status_message,"button-text":_vm.model.status.titleize()}})],1)],1)],1)],1):_vm._e()],1),_c('VCardText',{staticClass:"px-0 pb-0"},[(_vm.model.metadata instanceof Array && _vm.model.metadata.length)?_c('DynamicInputsView',{staticClass:"px-2 py-2 grey lighten-3",attrs:{"readonly":"","card":false,"inputs":_vm.model.metadata,"input-name":_vm.featureName('Metadata')}}):_c('VAlert',{attrs:{"type":"info","value":true}},[_vm._v(" No entries set for this "+_vm._s(_vm.featureName("stage"))+" ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }