import api from '../../services/attendanceSubmitted';
export default function (helper) {
  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('attendance_subumitted');
  const state = helper.state();
  const getters = helper.getters();
  const actions = helper.actions({}, 'Submitted Attendances');
  const mutations = helper.mutations({}, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}