<template>
  <router-view />
</template>

<script>
export default {
  name: "FaqPage",
  data() {
    return {};
  }
};
</script>

<style scoped>
.faq-index.container {
  height: 100%;
}
</style>