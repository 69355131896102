var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    chips: true,
    'fill-height': _vm.fillHeight
  }},[(_vm.items instanceof Array && _vm.items.length)?[(_vm.title.length)?_c('h3',{staticClass:"headline mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.asButton)?_vm._l((_vm.items),function(item,key){return _c('VBtn',{key:key,class:{
          'text-truncate': true,
          'text-no-wrap': true,
          'text--left': _vm.textLeft,
          'text--right': _vm.textRight,
          'fill-height': _vm.fillHeight,
          'mt-0': true,
          'mb-1': true,
          'ml-0': true,
          'text--no-transform': true
        },attrs:{"outlined":_vm.outlined,"color":_vm.color,"text-color":_vm.textColor,"small":_vm.small,"selected":_vm.selected,"dark":_vm.dark,"light":_vm.light,"disabled":_vm.disabled,"rounded":_vm.round,"block":_vm.block,"text":_vm.flat || _vm.textButton,"href":_vm.urlResolver(item)},on:{"click":function($event){$event.preventDefault();return (e => _vm.clicked(item, e)).apply(null, arguments)}}},[(_vm.icon && _vm.iconLeft)?_c('VIcon',{attrs:{"small":_vm.small,"left":true}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item[_vm.itemTitle] || item)+"  "),(_vm.icon && _vm.iconRight)?_c('VIcon',{attrs:{"small":_vm.small,"right":true}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()],1)}):_vm._l((_vm.items),function(item,key){return _c('VChip',{key:key,class:{
          'text-truncate': true,
          'text-no-wrap': true,
          'text--left': _vm.textLeft,
          'text--right': _vm.textRight,
          'fill-height': _vm.fillHeight,
          'mt-0': true,
          'mb-1': true,
          'ml-0': true,
          'mr-1': true
        },attrs:{"outlined":_vm.outlined,"color":_vm.color,"text-color":_vm.textColor,"small":_vm.small,"input-value":_vm.selected,"dark":_vm.dark,"light":_vm.light,"disabled":_vm.disabled,"label":_vm.label,"href":_vm.urlResolver(item)},on:{"click":function($event){$event.preventDefault();return (e => _vm.clicked(item, e)).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item instanceof Object ? item[_vm.itemTitle] : item)+" "),(_vm.openInNewWindow)?_c('VIcon',{staticClass:"ml-1"},[_vm._v(" launch ")]):_vm._e()],1)})]:[_vm._t("empty",function(){return [_vm._v(" "+_vm._s(_vm.emptyText)+" ")]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }