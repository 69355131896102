<template>
  <VRow
    row
    wrap
  >
    <VCol
      cols="12"
      v-if="title"
    >
      <h2 class="title mb-2">
        {{ title }}
      </h2>
    </VCol>
    <VCol>
      <VExpansionPanels focusable>
        <VExpansionPanel
          v-for="(stage,key) in stages"
          lazy
          style="width: 100%"
          :key="key+1"
          :class="{
            'px-0 pt-1 mb-3': true,
            'elevation-0': true,
            'primary': getIsCurrentStage(stage),
            'success': stage.is_complete,
            'info': !stage.is_complete && stage.is_submitted,
            'white--text': stage.is_complete || stage.is_submitted || getIsCurrentStage(stage),
            'v-expansion-panel__container--outline': true
          }"
          @click.stop="() => {}"
        >
          <VExpansionPanelHeader
            class="py-1"
            :class="{
              'white--text': stage.is_submitted || stage.is_complete
            }"
            @click.prevent=""
          >
            <VRow
              row
              wrap
              align-center
              :key="key"
            >
              <VCol grow>
                <h2
                  class="truncate-text text-no-wrap"
                  style="max-width: 75vw"
                >
                  {{ (stage.stage || {}).title || `${featureName('Stage')} ${realStageKey(stage)+1}` }}
                </h2>
                <VSwitch
                  v-model="stage.is_complete"
                  inset
                  persistent-hint
                  :dark="stage.is_submitted || stage.is_complete || getIsCurrentStage(stage)"
                  :label="`Is this ${featureName('Stage', 'singularize').toLowerCase()} complete?`"
                  :hint="`Marking this ${featureName('Stage', 'singularize').toLowerCase()} complete allows the user to only view, not edit the ${featureName('Stage', 'singularize').toLowerCase()}`"
                  @click.stop="() => {}"
                />
                <VSwitch
                  v-model="stage.is_submitted"
                  inset
                  persistent-hint
                  :dark="stage.is_submitted || stage.is_complete || getIsCurrentStage(stage)"
                  :label="`Is this ${featureName('Stage', 'singularize').toLowerCase()} submitted?`"
                  :hint="`Marking this ${featureName('Stage', 'singularize').toLowerCase()} as submitted`"
                  @click.stop="() => {}"
                />
              </VCol>
              <VCol
                align-items-end
                v-if="hasPipelineStageStatuses"
              >
                <Autocomplete
                  v-model="stage.status"
                  filled
                  item-text="title"
                  item-value="id"
                  append-inner-icon="arrow-down"
                  :dark="stage.is_submitted || stage.is_complete || getIsCurrentStage(stage)"
                  :class="{
                    'mt-6 mr-2': true
                  }"
                  :items="statusOptions"
                  :placeholder="`Select ${featureName('Stage', 'singularize').toLowerCase()} status`"
                  :hint="`Use a custom ${featureName('Stage', 'singularize').toLowerCase()} status for your internal needs`"
                  :label="`${featureName('Stage')} Status`"
                  @click.stop="() => {}"
                  @input="value => $emit('input:stage-proeprty', `${realStageKey(stage)}.status`, value)"
                />
              </VCol>
            </VRow>
          </VExpansionPanelHeader>
          <VExpansionPanelContent class="px-0 pt-0">
            <VCard class="mb-2 mt-0 grey lighten-3">
              <VCardText>
                <p
                  v-if="stage.description"
                  v-html="stage.description"
                />
                <DynamicInputsSubmit
                  v-model="stage.metadata"
                  enable-default-values
                  :card="false"
                  :input-name="`${featureName('Pipeline')} ${featureName('Stage', 'singularize')}`"
                  @update:input="(k, v) => $emit('input:metadata', realStageKey(stage), k, v)"
                  :validator="$v[realStageKey(stage)]"
                />
              </VCardText>
            </VCard>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
  </VRow>
</template>

<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import MetadataMixin from "@/mixins/Metadata";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
export default {
  name: "PipelinesSubmitFormStages",
  components: {
    DynamicInputsSubmit,
  },
  mixins: [MetadataMixin, SupportsPipelines],
  props: {
    title: {
      type: String,
      default: null,
    },
    stages: {
      type: Array,
      default: () => [],
    },
    allStages: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    hasPipelineStageStatuses: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: [Object, Array],
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modelType: "stage",
    };
  },
  computed: {
    $v() {
      return this.validator instanceof Array ? this.validator : [];
    },
  },
  methods: {
    getIsCurrentStage(stage) {
      return stage.id == this.form.current_stage_id;
    },
    getStageMetadata(key) {
      const stage = (this.stages || [])[key];
      return stage instanceof Object ? stage.metadata || [] : [];
    },
    realStageKey(stage) {
      return this.allStages.findIndex((s) => s.id === stage.id);
    },
    toggleStageProperty(key, property) {
      const stage = this.stages[key];
      const value = !stage[property];
      // this.$set(this.stages, `${key}.${property}`, value);
      this.$emit(
        "input:stage-property",
        this.realStageKey(stage),
        property,
        value
      );
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }
  .v-expansion-panel-header {
    padding: 0px 12px;

    .v-label {
      top: 0px;
    }
  }
}
</style>