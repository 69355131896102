<template>
  <VRow
    wrap
    fill-height
    align-center
    justify-start
    style="width: 100%"
  >
    <VCol
      v-if="card.name"
      shrink
      px-4
      cols="12"
    >
      <CardTitle align="center">
        {{ card.name }}
      </CardTitle>
    </VCol>
    <VCol
      grow
      :style="{
        maxHeight: card.name ? 'calc(100% - 74px)' : 'calc(100% - 52px)',
        overflowY: 'auto'
      }"
    >
      <VRow
        row
        wrap
        justify-center
        align-center
      >
        <VCol
          v-for="(part, index) in allInputs"
          :key="index"
          :class="{
            round: true,
            'blue lighten-5': part.type !== 'Text',
            'my-2 py-2': true,
            'mx-1 px-1': true,
            'title': true,
            'text-center': true,
            [part.size || 'auto']: true
          }"
        >
          <template v-if="part.type === 'Text'">
            <span
              v-linkified
              v-html="part.value"
            />
          </template>
          <template v-else>
            {{ getVariableValue(part.value) }}
          </template>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentCardResult",
  mixins: [PresentationCardMixin],
  data() {
    return {
      defaultForm: {
        value: {
          parts: [],
        },
      },
    };
  },
  computed: {
    inputCards() {
      return this.assessment.all_cards.filter(
        (card) => ["TextArea", "Text", "Question"].indexOf(card.type) > -1
      );
    },
    allInputs: {
      get() {
        return this.card.value instanceof Object ? this.card.value.parts : [];
      },
      set(value) {
        this.card.value.parts = value;
        this.saveCard();
      },
    },
  },
  methods: {
    getVariableValue(key) {
      const card = this.inputCards.find(
        (card) => card.variable instanceof Object && card.variable.value === key
      );
      if (card instanceof Object && card.result_for_user instanceof Object) {
        const result = card.result_for_user;
        console.log("Found result", result, card);
        if (
          card.type === "Question" &&
          card.choices instanceof Array &&
          card.choices.length &&
          result.value instanceof Array
        ) {
          return card.choices
            .filter((choice) => {
              return result.value.indexOf(choice.id) > -1;
            })
            .map((choice) => choice.text)
            .join(", ");
        } else {
          console.log("Returning", result.value);
          return result.value;
        }
      }
      return "(no answer)";
    },
  },
};
</script>

