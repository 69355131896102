<template>
  <VRow
    id="main-menu"
    class="menu-items pt-0 main-menu-items align-center justify-center fill-height"
    :style="{
      height: !dashboard ? 'calc(100% - 56px)': 'auto'
    }"
  >
    <VCol
      sm="12"
      :class="{
        'pt-0': true,
        'pl-3': $vuetify.breakpoint.mdAndUp
      }"
      :md="dashboard ? 12 : 10"
      :lg="dashboard ? 12 : 8"
      :xl="dashboard ? 12 : 6"
    >
      <VRow
        v-if="!dashboard && false"
        justify-center
        align-center
      >
        <VCol shrink>
          <VBtn
            rounded
            large
            fab
            @click="navigateTo({name: 'dashboard'})"
          >
            <VIcon color="primary">
              home
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
      <VRow
        align-center
        justify-center
        class="mt-4"
        :style="{
          maxHeight: dashboard ? 'auto' : 'calc(100vh - 140px)',
          overflow: 'hidden auto'
        }"
      >
        <template v-for="item in menuItems">
          <VCol
            sm="6"
            :key="item.title"
            :class="{
              active: routeIsPartOfGroup(item.routeGroup),
              'menu-items-item': true,
              'pl-0': $vuetify.breakpoint.mdAndUp,
              'mb-4': true
            }"
            :style="{
              flex: '0 1 417px',
              width: '417px',
              maxWidth: '417px'
            }"
            @click="onSelectMenuItem(item)"
            @mouseover="onMouseoverMenuItem(item)"
            @mouseleave="onMouseleaveMenuItem(item)"
          >
            <VBtn
              block
              class="main-menu-button"
              :style="{
                overflow: 'visible',
                border: item.style.border
              }"
              :to="item.route || {name: item.routeName}"
            >
              <VRow
                align-center
                justify-start
                :class="{
                  'px-4': $vuetify.breakpoint.smAndDown
                }"
              >
                <VCol shrink>
                  <div
                    class="d-flex align-center justify-center"
                    :style="{
                      width: '71px',
                      height: '71px',
                      backgroundColor: item.style.icon.backgroundColor,
                      borderRadius: '10px',
                      boxShadow: `0px 5px 14.3px 0.8px ${item.style.icon.shadowColor}`
                    }"
                  >
                    <img
                      :style="{
                        width: item.style.icon.width,
                        height: item.style.icon.height,
                        objectFit: 'contain'
                      }"
                      :src="item.icon"
                    >
                  </div>
                </VCol>
                <VCol grow>
                  <h3 class="text-left">
                    <BetaBadge
                      enable-tooltip
                      :is-beta="item.beta"
                    >
                      <strong>{{ item.title }}</strong>
                    </BetaBadge>
                  </h3>
                  <p
                    class="text-left"
                    v-html="item.description || 'Here you can access high-level information for all of the programs you are in. Including key dates and deadlines.'"
                  />
                  <span
                    v-html="(item.badge || {}).value"
                    v-if="(item.badge || {}).active === true"
                    class="d-inline-flex align-center justify-center"
                    :style="{
                      backgroundColor: item.badge.backgroundColor || '#000',
                      position: 'absolute',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: '500',
                      top: '-20px',
                      right: '-28px',
                      width: '40px',
                      height: '40px',
                      borderRadius: '10px',
                      boxShadow: item.badge.boxShadow
                    }"
                  />
                </VCol>
              </VRow>
            </VBtn>
          </VCol>
        </template>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    dashboard: {
      type: Boolean,
      default: false,
    },
    due: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    menuItems() {
      const env = this.$root.env;
      return [
        {
          priority: 4,
          title: this.featureName("Deliverables"),
          description: `Here you can access all forms due for submission.`,
          icon: require("../../../images/forms-white-icon.png"),
          routeName: "deliverable.index",
          routeGroup: "deliverable",
          visible: true,
          style: {
            icon: {
              backgroundColor: "#2f353f",
              shadowColor: "rgba(0, 0, 0, 0.25)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
            border:
              this.$user.deliverables_due_count > 0
                ? "solid 2px #ff2b7a"
                : "none",
          },
          badge: {
            value: this.$user.deliverables_due_count || 0,
            active: this.$user.deliverables_due_count > 0,
            backgroundColor: "#ff2b7a",
            boxShadow: "0px 5px 14.3px 0.8px rgba(255, 43, 122, 0.3)",
          },
        },
        {
          priority: 5,
          title: this.featureName("Lessons"),
          description: "All of your online learning modules.",
          icon: require("../../../images/lessons-white-icon.png"),
          routeName: "lesson.index",
          routeGroup: ["lesson", "worksheet"],
          visible: this.isEnabledFeature("LESSONS"),
          beta: this.isBetaFeature("LESSONS"),
          style: {
            icon: {
              backgroundColor: "#2f353f",
              shadowColor: "rgba(0, 0, 0, 0.25)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 8,
          title: this.featureName("User").singularize() + " Management",
          description:
            "Manage all users within the workspace. Approve, remove or invite users.",
          icon: require("../../../images/user-white-icon.png"),
          routeName: "user.index",
          routeGroup: "user",
          visible: this.$isUserAdmin,
          style: {
            icon: {
              backgroundColor: "#2f353f",
              shadowColor: "rgba(0, 0, 0, 0.25)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 2,
          title: this.featureName("Programs"),
          description:
            "Here you can access high-level information for all of the programs you’re involved with.",
          icon: require("../../../images/programs-black-icon.png"),
          routeName: "program.index",
          routeGroup: "program",
          visible: true,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 6,
          title: this.featureName("FAQs"),
          description:
            "Have a question? Check here first as we may have already answered it.",
          icon: require("../../../images/faq-black-icon.png"),
          routeName: "faq.index",
          routeGroup: "faq",
          visible: true,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 7,
          title: this.featureName("Workspace Settings"),
          description:
            "Customize workspace settings & preferences to align with your brand.",
          icon: require("../../../images/workspace-settings.png"),
          routeName: "settings",
          routeGroup: "settings",
          visible: this.$isUserAdmin,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 1,
          title: this.featureName("Pipelines"),
          description:
            "Manage all applications, registration and intake forms your organization is using.",
          icon: require("../../../images/pipelines-white-icon.png"),
          route: {
            name: "settings",
            hash: "#pipelines",
          },
          routeGroup: "settings",
          // Hide this menu item from students
          visible: this.$hasAccessToCurrentTeam && !this.$isUserStudent,
          style: {
            icon: {
              backgroundColor: "#2f353f",
              shadowColor: "rgba(0, 0, 0, 0.25)",
              fillColor: "#fff",
              width: "29px",
              height: "29px",
            },
          },
        },
        {
          priority: 3,
          title: this.featureName("Content"),
          description: "Documents, videos, images and more.",
          icon: require("../../../images/content-black-icon.png"),
          routeName: "content.index",
          routeGroup: "content",
          visible: true,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "34px",
              height: "34px",
            },
          },
        },
        {
          priority: 9,
          title: this.featureName("Reports"),
          icon: require("../../../images/reports-icon@2x.png"),
          routeName: "report.index",
          routeGroup: "report",
          visible: this.$isUserAdmin && this.isEnabledFeature(env.VUE_APP_FEATURE_REPORTS),
          beta: this.isBetaFeature(env.VUE_APP_FEATURE_REPORTS),
          style: {
            icon: {
              backgroundColor: "#2f353f",
              shadowColor: "rgba(0, 0, 0, 0.25)",
              fillColor: "#fff",
              width: "29px",
              height: "29px",
            },
          },
        },
        {
          priority: 12,
          title: this.featureName("Attendance"),
          icon: require("../../../images/attendance-icon@2x.png"),
          routeName: "attendance.index",
          routeGroup: "attendance",
          visible: false,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "29px",
              height: "29px",
            },
          },
        },
        {
          priority: 11,
          title: this.featureName("Calendar"),
          icon: require("../../../images/calendar@2x.png"),
          description: "Calendar preview",
          routeName: "calendar.index",
          routeGroup: "calendar",
          visible: this.isEnabledFeature(env.VUE_APP_FEATURE_CALENDAR),
          beta: this.isBetaFeature(env.VUE_APP_FEATURE_CALENDAR),
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "29px",
              height: "29px",
            },
          },
        },
        {
          priority: 10,
          title: this.featureName("Supplements"),
          icon: require("../../../images/media-icon@2x.png"),
          routeName: "supplement.index",
          routeGroup: "supplement",
          visible: false,
          style: {
            icon: {
              backgroundColor: "#ffffff",
              shadowColor: "rgba(232, 232, 244, 0.7)",
              fillColor: "#fff",
              width: "29px",
              height: "29px",
            },
          },
        },
      ]
        .filter((item) => item.visible === true)
        .map((item) => {
          this.$set(item, "active", this.routeIsPartOfGroup(item.routeGroup));
          return item;
        })
        .sortBy("priority");
    },
  },
  methods: {
    routeIsPartOfGroup(group) {
      const routeParent = this.$route.name.split(".")[0];
      return group instanceof Array
        ? group.indexOf(routeParent) > -1
        : routeParent.startsWith(group);
    },
    onSelectMenuItem(item) {
      const existing = this.menuItems.find((i) => i.active === true);
      if (existing) {
        existing.active = false;
        existing.currentIcon = existing.icon;
      }
      this.$set(item, "currentIcon", item.iconActive || item.icon);
      this.$set(item, "active", true);
      this.navigateTo(item.route || item.routeName);
    },
    onMouseoverMenuItem(item) {
      this.$set(item, "currentIcon", item.iconActive || item.icon);
    },
    onMouseleaveMenuItem(item) {
      this.$set(
        item,
        "currentIcon",
        item.active ? item.iconActive || item.icon : item.icon
      );
    },
  },
};
</script>
<style lang="scss">
#main-menu {
  .beta-chip.v-badge {
    padding: 0px !important;
  }

  @media (max-width: 767px) {
    .main-menu-button {
      .row {
        max-width: 356px !important;
      }
    }
  }
}
</style>