import utils from "./utils";
import Vue from "vue";
import { Api as BaseApi } from "./deliverableSubmitted";

/**
 * Submitted Pipelines API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipelines]: Get Submitted Pipelines",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params
				}
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Pipelines: Get Submitted Pipelines]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getFormConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipelines]: Get Submitted Pipelines Form Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getFormConfig", "form-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params
				}
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Pipelines: Get Submitted Pipelines Form Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getIndexConfig(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipelines]: Get Submitted Pipelines Index Config",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getIndexConfig", "index-config", params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params
				}
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Pipelines: Get Submitted Pipelines Index Config]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
	getOne(_params) {
		const params = _params || {};
		this.log().info(
			"[Services: Submitted Pipelines]: Get Submitted Pipeline",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getOne", null, params)}${
					params.id ? "/" + params.id : ""
				}`,
				{
					params
				}
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Pipelines: Get Submitted Pipelines]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Submitted Pipelines",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipelines_submitted/${pipelineId}`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipelines_submitted/${pipelineId}`;
		},
		default: "pipelines_submitted"
	}
});

export default api;
