<template>
  <VRow
    fill-height
    row
    wrap
    id="customization"
    :class="{
      'is-loading': activity.isLoading,
      'mt-n2': !isStandalone
    }"
  >
    <VCol
      v-if="isStandalone"
      cols="12"
    >
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h1>Customize Your Workspace</h1>
        </template>
        <template #create-action>
          <ExpandableButton
            large
            button-class="wethrive-alt"
            text="Save Changes"
            icon="save"
            :disabled="!isFormReady"
            :action="() => onSave()"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      cols="12"
      v-if="hasMessagesOrErrors"
    >
      <Alerts
        :messages="messages"
        :errors="errors"
      />
    </VCol>
    <VCol
      cols="12"
      style="overflow:auto"
    >
      <VRow
        justify-space-between
        row
        wrap
      >
        <VCol align-center>
          <VCard
            v-for="(group, groupIndex) in groupedSettings"
            :key="`group-${groupIndex}`"
          >
            <VCardTitle>{{ groupIndex.titleize() }}</VCardTitle>
            <VCardText>
              <VRow
                v-for="(setting, index) in group"
                :key="`setting-${groupIndex}-${index}`"
              >
                <VCol
                  sm="12"
                  md="5"
                  lg="3"
                  :class="{
                    'pt-6': $vuetify.breakpoint.mdAndUp,
                    'text-right': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ setting.title }}
                </VCol>
                <VCol>
                  <Swatches
                    v-if="setting.type === 'color'"
                    v-model="form.settings.theme[setting.relative_key].value"
                    show-fallback
                    swatches="text-advanced"
                  />
                  <template v-else-if="setting.type === 'font'">
                    <Autocomplete
                      v-model="form.settings.theme[setting.relative_key].value"
                      return-object
                      item-text="family"
                      item-value="family"
                      prepend-icon="font_download"
                      label="Select Font"
                      :search-input.sync="fontFilter[setting.relative_key]"
                      :items="fonts"
                      :loading="activity.isLoading"
                      @change="onLoadFont"
                    />
                    <p
                      :style="{
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '10px',
                        borderRadius: '6px',
                        fontFamily: getFontNameFrom(setting.value)
                      }"
                    >
                      This is example text
                    </p>
                  </template>
                  <template v-else-if="setting.type === 'image'">
                    <FileUpload @files="files => form.settings.theme[setting.relative_key].value = files[0]" />
                    <template v-if="(form.settings.theme[setting.relative_key].value instanceof Object && form.settings.theme[setting.relative_key].value.url)">
                      <h3>Current Image</h3>
                      <img
                        style="max-width: 250px; cursor: zoom-in"
                        class="my-3"
                        :id="`image-${setting.relative_key}`"
                        :src="getImageUrl(form.settings.theme[setting.relative_key].value, `#image-${setting.relative_key}`)"
                        @click.prevent="doPreviewFiles(form.settings.theme[setting.relative_key].value)"
                      >
                      <br>
                      <VBtn
                        text
                        color="error"
                        @click="form.settings.theme[setting.relative_key].value = {url: null}"
                      >
                        Remove
                      </VBtn>
                    </template>
                  </template>
                  <TextField
                    v-else
                    v-model="form.settings.theme[setting.relative_key].value"
                    placeholder="Enter value ..."
                    :label="`Value of ${setting.relative_key.titleize()}`"
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
      <Preview
        @toggle="onTogglePreview"
        :files="previewFiles"
        v-if="dialogs.preview"
      />
    </VCol>
  </VRow>
</template>

<script>
import Swatches from "vue-swatches";
import FormMixin from "@/mixins/Form";
import DownloadsFiles from "@/mixins/DownloadsFiles";
import { mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import Preview from "@/components/Elements/Dialogs/Preview";
import SupportsCustomization from "@/mixins/SupportsCustomization";

export default {
  name: "Customization",
  mixins: [FormMixin, DownloadsFiles, SupportsCustomization],
  props: {
    isStandalone: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IndexHeader,
    Swatches,
    Preview,
  },
  data() {
    return {
      modelType: "Settings",
      hasFiles: true,
      date: null,
      defaultForm: {
        settings: {
          theme: {},
        },
      },
      fileProperties: ["avatar"],
      fontFilter: {},
      allFonts: [],
      /**
       * Custom font objects with the following properties:
       * kind: The kind of object, a webfont object
       * family: The name of the family
       * subsets: A list of scripts supported by the family
       * variants: The different styles available for the family
       * version: The font family version.
       * lastModified: The date (format "yyyy-MM-dd") the font family was modified for the last time.
       * files: The font family files (with all supported scripts) for each one of the available variants.
       */
      customFonts: [
        {
          type: "custom",
          family: "FuturaStd",
          format: "woff",
        },
      ],
    };
  },
  validations: {
    form: {},
  },
  computed: {
    fonts() {
      return this.customFonts
        .concat(this.allFonts)
        .filter((font) => {
          if (this.fontFilter && this.fontFilter.length) {
            const familyMatch = font.family.match(this.fontFilter);
            return familyMatch && familyMatch.length > 0;
          }
          return true;
        })
        .sortBy("family");
    },
    groupedSettings() {
      return Object.values(this.$teamSettings.theme)
        .sortBy("section")
        .groupBy("section");
    },
    // groupedLegacySettings() {
    //   const grouped = {};
    //   const getType = (key) => {
    //     let type = "text";
    //     if (key.indexOf("color") > -1) {
    //       type = "color";
    //     } else if (this.getIsFontProperty(key)) {
    //       type = "font";
    //     } else if (key.indexOf("url") > -1) {
    //       type = "image";
    //     }
    //     return type;
    //   };
    //   Object.keys(this.$team.legacySettings.theme).map((key) => {
    //     const section = key.substring(0, key.indexOf("_"));
    //     const property = key.substring(key.indexOf("_"));
    //     grouped[section] = grouped[section] || {};
    //     grouped[section][property] = {
    //       key: section + property,
    //       title: key.titleize(),
    //       type: getType(key),
    //       value: this.form.settings.theme[key],
    //     };
    //   });

    //   return grouped;
    // },
  },
  created() {
    this.form = this.$team || this.form;
    this.form.owner = this.form.owner || this.$user.id;
    this.form.owner_id = this.form.owner_id || this.$user.id;
    this.getFonts();
    this.$once("isFormReady", () => {
      this.loadFontsFrom(
        Object.entries(this.form.settings.theme)
          .filter((value) => this.getIsFontProperty(value[0]))
          .map((value) => value[1])
      );
    });
  },
  watch: {
    form: {
      handler: function (value) {
        if (!this.isStandalone) {
          this.$emit("updated", value);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      doSaveTeam: "team/save",
    }),
    onSave() {
      return this.saveFormModel(this.doSaveTeam, null, {
        id: this.form.id,
        settings: this.form.settings,
      }).then((team) => {
        this.$team = { ...this.$team, ...team };
      });
    },
    getFonts() {
      let promise = Promise.resolve(
        window.localStorage.getItem("google-fonts")
      );
      promise.then((fonts) => {
        if (!fonts || fonts === "undefined" || fonts === undefined) {
          const apiKey = this.env.VUE_APP_GOOGLE_DEVELOPER_KEY;
          fetch(
            `https://www.googleapis.com/webfonts/v1/webfonts?sort=ALPHA&key=${apiKey}`
          ).then((response) => {
            response.json().then((fonts) => {
              this.allFonts = fonts.items;
              window.localStorage.setItem(
                "google-fonts",
                JSON.stringify(this.allFonts)
              );
            });
          });
        } else {
          this.allFonts = JSON.parse(fonts);
        }
      });
    },
    getImageUrl(item, unique) {
      if (item instanceof File) {
        return this.getMediaFromFileObject(item, unique);
      } else {
        return (item || {}).url;
      }
    },
  },
};
</script>

<style lang="scss">
#customization {
  .vue-swatches__trigger {
    box-shadow: 1px 1px 6px #aaa;
  }
}
</style>