<template>
  <VRow class="px-4">
    <VCol class="d-inline-flex">
      <VBtn
        single-line
        outlined
        height="36px"
        class="px-4 dashed file-upload rounded"
        @click.native="$refs.fileInput.click()"
      >
        <img 
          src="../../../images/lessons/image-icon.png"
          style="opacity: 0.85;"
        >
        <span
          class="pl-2"
          style="opacity: 0.75; font-size: 12px; color: rgba(0, 0, 0, 0.75);"
        >
          Add Image
        </span>
      </VBtn>
      <VSwitch
        v-if="isQuestionCard"
        v-model="form.is_required"
        inset
        dense
        label="Is this card required?"
        class="pl-6 switch-option"
        style="margin: 0px;"
        @change="backgroundSaveCard"
      />
    </VCol>
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      :multiple="false"
      @change="onFileChange"
    >
    <Confirm ref="confirmDelete" />
  </VRow>
</template>
<script>
import SaveCardMixin from "@/mixins/SaveAssessmentCard";
import HasFilesMixin from "@/mixins/HasFiles";
import DeletesItems from "@/mixins/DeletesItems";
import { mapActions } from "vuex";

export default {
  name: "CardOptions",
  mixins: [SaveCardMixin, HasFilesMixin, DeletesItems],
  props: {
    block: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultForm: {
        is_required: false,
        images: [],
      },
      fileProperties: ["images"],
      localFiles: [],
    };
  },
  watch: {
    card: function () {
      this.allFiles();
    }
  },
  computed: {
    isQuestionCard() {
      return (
        ["textarea", "text", "question", "file"].indexOf(
          this.form.type.toLowerCase()
        ) > -1
      );
    },
  },
  created() {
    this.allFiles();
  },
  methods: {
    ...mapActions({
      deleteFile: "file/delete",
    }),
    addLocalImage(file) {
      this.onAddFile(file, "images");
      this.backgroundSaveCard();
    },
    removeLocalImage(index) {
      this.onRemoveFile(index, "images");
      this.backgroundSaveCard();
      this.allFiles();
    },
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      this.$log.debug("FileUpload: File change", files);
      const form = this.createFormData ? this.getFormData(files) : files;
      if (files) {
        if (files.length > 0) {
          if (this.multiple) {
            Array.from(files).forEach((file) => {
              if (this.uploadOnSave === false) {
                this.$log.debug("FileUpload: Adding file", file);
                this.localFiles.push(file);
              }
            });
          } else {
            this.filename = [...files].map((file) => file.name).join(", ");
            this.$log.debug("FileUpload: Added file", this.filename);
          }
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      this.addLocalImage(form[0]);
      this.$emit("files", this.form.images);
      this.$emit("input", this.filename);
      // Reset input file to attach same image for multiple card
      $event.target.value = '';
    },
    onDeleteFile(index, file) {
      if (file instanceof Object && file.id) {
        this.$log.debug("FileUpload: Removing existing file", file.id);
        this.onDeleteItem(
          {
            id: file,
            entity_slug: file.entity_slug,
            entity_id: file.entity_id,
          },
          this.deleteFile
        ).then((result) => {
          this.$log.debug("FileUpload: Removing file result", result);
          if (result) {
            this.$log.debug("FileUpload: Removing file", index);
            this.localFiles.splice(index, 1);
            this.removeLocalImage(index);
          }
        });
      } else {
        this.$log.debug("FileUpload: Removing file", index);
        this.localFiles.splice(index, 1);
        this.removeLocalImage(index);
      }
    },
    getFormData(files) {
      const data = new FormData();
      [...files].forEach((file) => {
        data.append("data", file, file.name);
      });
      return data;
    },
    allFiles() {
      const allFiles = []
        .concat(this.form.images, this.localFiles)
        .filter((item) => item != null);
      this.$emit("files", allFiles);
    }
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
</style>
<style lang="scss">
.switch-option .v-label {
  font-size: 14px;
}
</style>
