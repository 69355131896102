<template>
  <EditorBase
    v-bind="$attrs"
    v-on="$listeners"
    has-menu-bubble
    editor-type="editor-menu-bubble"
    :editor="editor"
    :label="label"
    :placeholder="placeholder"
  >
    <template #buttons="{ commands, isActive }">
      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.bold(), 'white--text': true }"
        @click="commands.bold"
      >
        <VIcon>format_bold</VIcon>
      </VBtn>

      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.italic(), 'white--text': true }"
        @click="commands.italic"
      >
        <VIcon>format_italic</VIcon>
      </VBtn>

      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.strike(), 'white--text': true }"
        @click="commands.strike"
      >
        <VIcon>format_strikethrough</VIcon>
      </VBtn>

      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.underline(), 'white--text': true }"
        @click="commands.underline"
      >
        <VIcon>format_underline</VIcon>
      </VBtn>

      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.bullet_list(), 'white--text': true }"
        @click="commands.bullet_list"
      >
        <VIcon>format_list_bulleted</VIcon>
      </VBtn>

      <VBtn
        icon
        text
        small
        class="menubar__button"
        :class="{ 'is-active': isActive.ordered_list(), 'white--text': true }"
        @click="commands.ordered_list"
      >
        <VIcon>format_list_numbered</VIcon>
      </VBtn>
    </template>
  </EditorBase>
</template>

<script>
import { Doc, Editor } from "tiptap";
import {
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  History,
  Placeholder
} from "tiptap-extensions";
import EditorBase from "./EditorBase";
import Link from "./EditorLink.js";
import EditorMixin from "./EditorMixin";

export default {
  components: {
    EditorBase
  },
  mixins: [EditorMixin],
  data() {
    return {
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          this.$emit("input", getHTML());
        },
        onBlur: ({ state }) => {
          this.$emit("blur", state.doc.innerHTML);
        },
        extensions: [
          new Doc(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            showOnlyWhenEditable: true,
            emptyNodeText: this.placeholder
          })
        ],
        content: null
      })
    };
  }
};
</script>