<template>
  <VMenu
    v-if="hasUser"
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    class="pr-1"
  >
    <template #activator="{ on: slotOn }">
      <slot :on="slotOn">
        <VChip
          v-on="!disabled ? Object.assign(slotOn, activatorVOn) : {}"
          pill
          :outlined="outlined"
          :color="color"
          :text-color="textColor"
          :x-small="xSmall"
          :small="small"
          :large="large"
          :x-large="xLarge"
          :input-value="selected"
          :dark="dark"
          :light="light"
          :disabled="disabled"
          :class="`mr-${spacing} mb-${spacing}`"
        >
          <VAvatar
            left
            :size="avatarSize"
            :style="{
              width: `${avatarSize} !important`,
              height: `${avatarSize} !important`,
              minWidth: `${avatarSize} !important`
            }"
          >
            <VImg :src="user.photo_url" />
          </VAvatar>
          <slot>{{ user.name }}</slot>
          <slot name="content-right" />
          <VIcon
            v-if="clearable"
            small
            @click.prevent="$emit('clear')"
          >
            clear
          </VIcon>
        </VChip>
      </slot>
    </template>
    <UserViewDetails
      :user="user"
      :user-details="userDetails"
    />
  </VMenu>
  <VChip
    v-else
    pill
    color="#e0e0e0"
    style="text-decoration: line-through; color: #666666;"
    :outlined="outlined"
    :text-color="textColor"
    :x-small="xSmall"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :input-value="selected"
    :dark="dark"
    :light="light"
    :disabled="disabled"
    :class="`mr-${spacing} mb-${spacing}`"
  >
    <VAvatar
      left
      :size="avatarSize"
      :style="{
        width: `${avatarSize} !important`,
        height: `${avatarSize} !important`,
        minWidth: `${avatarSize} !important`
      }"
    >
      <VImg src="https://www.gravatar.com/avatar/475760632cec1a865abf22b79269f099.jpg?s=200&d=mm" />
    </VAvatar>
    Deleted User
  </VChip>
</template>

<script>
import UserViewDetails from "./UserViewDetails";
export default {
  name: "UserChip",
  components: { UserViewDetails },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    userDetails: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "default",
    },
    textColor: {
      type: String,
      default: "inherit",
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: [Number, String],
      default: 1,
    },
    activatorVOn: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    avatarSize() {
      if (this.large) {
        return "54px";
      } else if (this.xLarge) {
        return "62px";
      } else {
        return "32px";
      }
    },
    hasUser() {
      return this.user instanceof Object;
    },
  },
};
</script>
<style lang="scss">
:root {
  .v-badge {
    .v-chip {
      height: 16px !important;
      .v-chip__content {
        height: 16px;
        padding: 0 6px;
        text-transform: lowercase;
      }
    }
  }
}
</style>