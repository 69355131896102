<template>
  <div class="calendar-form">
    <VContainer
      fluid
      grid-list-md
      :class="{
        'is-loading': activity.isLoading
      }"
    >
      <VRow
        row
        wrap
      >
        <VCol v-if="hasMessagesOrErrors">
          <Alerts
            :messages="messages"
            :errors="errors"
          />
        </VCol>
        <VCol v-show="form.sequence_owner_id && (form.sequence_owner instanceof Object)">
          <VAlert
            :value="true"
            type="info"
          >
            This event is part of a series of events
          </VAlert>
        </VCol>
      </VRow>
      <VRow
        v-show="!entityCalendarId && !formHasId"
        row
        wrap
      >
        <VCol cols="12">
          <Autocomplete
            v-model="form.entity_type"
            :items="entityTypes"
            :readonly="entity instanceof Object && entity.id !== null"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            placeholder="Attach to..."
            label="Select type"
            :error="$v.form.entity_type.$error"
            @input="$v.form.entity_type.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol
          v-if="form.entity_type"
          xs12
        >
          <Autocomplete
            v-model="form.entity_id"
            :items="entityOptions"
            :readonly="entity instanceof Object && entity.id !== null"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            :placeholder="`Select ${selectedEntityTypeTitle}`"
            :label="selectedEntityTypeTitle"
            :error="$v.form.entity_id.$error"
            @input="$v.form.entity_id.$touch()"
            @blur="$v.$touch()"
          />
        </VCol>
        <VCol
          v-if="form.entity_id"
          cols="12"
        >
          <Autocomplete
            v-if="calendarOptions && calendarOptions.length"
            v-model="form.calendar_id"
            :readonly="entityCalendarId !== null"
            :items="calendarOptions"
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            placeholder="Select calendar"
            label="Select calendar"
            :error="$v.form.calendar_id.$error"
            @input="$v.form.calendar_id.$touch()"
            @blur="$v.$touch()"
          />
          <template v-else>
            <VAlert
              color="warning"
              :value="true"
            >
              <h3 class="title mb-4">
                No calendars available. Save one below
              </h3>
              <TextField
                v-model="newCalendar.title"
                solo
                name="form.calendar.title"
                label="Enter new calendar title"
                placeholder="Enter new calendar title..."
              />
              <VBtn
                block
                class="wethrive-alt"
                :disabled="!canSaveNewCalendar || newCalendarIsSaving"
                :loading="newCalendarIsSaving"
                @click="onSaveCalendar"
              >
                <VIcon>save</VIcon>&nbsp;Save New Calendar
              </VBtn>
            </VAlert>
          </template>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <TextField
            v-model="form.title"
            name="form.title"
            label="Title"
            placeholder="Enter title..."
          />
        </VCol>
      </VRow>
      <VRow
        row
        wrap
        mt-0
        pt-0
      >
        <VCol cols="12">
          <VCheckbox
            v-model="form.is_all_day"
            label="Is this event all day"
          />
        </VCol>
        <VCol cols="6">
          <VMenu
            v-model="menu.startDateMenu"
            lazy
            offset-y
            ref="startDateMenu"
            transition="scale-transition"
            min-width="290px"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-form.sync="form.start_date"
          >
            <template #activator="{on}">
              <TextField
                v-on="on"
                v-model="form.start_date"
                label="Start Date"
                prepend-icon="event"
                readonly
                :error="$v.form.start_date.$error"
                @blur="$v.$touch()"
              />
            </template>
            <VDatePicker
              v-model="form.start_date"
              scrollable
              :error="$v.form.start_date.$error"
              @blur="$v.$touch()"
              @input="setStartDate"
            />
          </VMenu>
        </VCol>
        <VCol cols="6">
          <TextField
            v-model="form.start_time"
            slot="activator"
            type="time"
            label="Enter Time"
            prepend-icon="access_time"
            :disabled="form.is_all_day"
          />
        </VCol>
        <VCol cols="6">
          <VMenu
            v-model="menu.endDateMenu"
            lazy
            offset-y
            ref="endDateMenu"
            transition="scale-transition"
            min-width="290px"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-form.sync="form.end_date"
          >
            <template #activator="{on}">
              <TextField
                v-model="form.end_date"
                v-on="on"
                readonly
                label="End Date"
                prepend-icon="event"
                :disabled="form.is_recurring"
              />
            </template>
            <VDatePicker
              v-model="form.end_date"
              scrollable
              @input="setEndDate"
            />
          </VMenu>
        </VCol>
        <VCol cols="6">
          <TextField
            v-model="form.end_time"
            slot="activator"
            type="time"
            label="Enter Time"
            prepend-icon="access_time"
            :disabled="form.is_all_day"
          />
        </VCol>
        <VCol xs12>
          <VCheckbox
            v-model="form.is_recurring"
            persistent-hint
            label="Do the events recur?"
            hint="Enable this to configure the recur options for this event"
          />
        </VCol>
        <VCol v-show="form.is_recurring">
          <h3 class="subtitle">
            Frequency
          </h3>
          <VRow row>
            <VCol>
              <TextField
                v-model="form.recur_count"
                type="number"
                :min="1"
                label="Repeat every"
                @keypress="numericOnly"
              />
            </VCol>
            <VCol>
              <Autocomplete
                slot="append-outer"
                v-model="form.recur_frequency"
                append-inner-icon="arrow-down"
                placeholder="Frequency ..."
                :items="recurFrequencies"
              />
            </VCol>
          </VRow>
          <h3 class="subtitle">
            Repeat on
          </h3>
          <VBtnToggle
            v-model="form.days_of_week"
            append-inner-icon="arrow-down"
            label="Repeat on"
            class="elevation-0 my-2"
            :multiple="!form.id || !form.sequence_owner_id"
          >
            <VBtn
              v-for="(day, dayKey) in daysOfWeek"
              large
              text:value="day"
              :key="`repeat-on-${dayKey}`"
            >
              {{ day }}
            </VBtn>
          </VBtnToggle>
          <h3 class="subtitle">
            Ends
          </h3>
          <VRadioGroup v-model="form.recur_ends">
            <VRadio
              value="never"
              label="Never"
              @blur="$v.$touch()"
            />
            <VRadio value="on">
              <template #label>
                <VMenu
                  v-model="menu.eventEndsValueMenu"
                  lazy
                  offset-y
                  ref="eventEndsValueMenu"
                  transition="scale-transition"
                  min-width="290px"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-form.sync="form.recur_ends_on"
                >
                  <template #activator="{on}">
                    <TextField
                      v-model="form.recur_ends_on"
                      v-on="on"
                      filled
                      readonly
                      class="has-prepend-append"
                      :error="$v.form.recur_ends_on.$error"
                      @blur="$v.$touch()"
                    >
                      <template #prepend>
                        On
                      </template>
                    </TextField>
                  </template>
                  <VDatePicker
                    v-model="form.recur_ends_on"
                    scrollable
                    label="On"
                    :disabled="form.recur_ends !== 'on'"
                    @input="setEventEndsValueDate"
                  />
                </VMenu>
              </template>
            </VRadio>
            <VRadio value="after">
              <template #label>
                <TextField
                  v-model="form.recur_ends_count"
                  filled
                  type="number"
                  class="has-prepend-append"
                  :min="1"
                  :disabled="form.recur_ends !== 'after'"
                  :error="$v.form.recur_ends_count.$error"
                  @blur="$v.$touch()"
                  @keypress="numericOnly"
                >
                  <template #prepend>
                    After
                  </template>
                  <template #append-outer>
                    Occurrences
                  </template>
                </TextField>
              </template>
            </VRadio>
          </VRadioGroup>
        </VCol>
        <VCol
          mt-2
          cols="12"
        >
          <h2>RSVP Settings</h2>
          <VRadioGroup
            v-model="form.rsvp_mode"
            row
          >
            <VRadio
              v-for="(mode, key) in options.rsvp_mode"
              :value="key"
              :key="`rsvp-mode-${key}`"
              :label="mode"
            />
          </VRadioGroup>
          <VAlert
            :value="true"
            type="info"
          >
            <template v-if="form.rsvp_mode === 'standard'">
              Users in your workspace will sign up for this calendar event without any requirement of being accepted, nor rejected
            </template>
            <template v-else>
              Users in your workspace will request to join this calendar event. You or another admin will accept or reject their sign up and each user will receive a notification and confirmation after they’ve been accepted or rejected.
            </template>
          </VAlert>
        </VCol>
        <VCol cols="12">
          <VCheckbox
            v-model="form.rsvp_is_open"
            persistent-hint
            hint="If you disable this then you will need to manually add users to this event"
            :label="`Can anyone in ${selectedEntityTitle} RSVP?`"
          />
        </VCol>
        <!-- <VCol xs6>
          <VCheckbox
            v-show="form.rsvp_mode === 'shift'"
            v-model="form.rsvp_is_limited_to_single"
            persistent-hint
            hint="Only one RSVP will be allowed. The first user to RSVP will be automatically accepted"
            :label="`First come first serve`"
          />
        </VCol>-->
        <VCol
          v-show="!form.rsvp_is_limited_to_single"
          :xs6="form.rsvp_mode === 'shift'"
        >
          <TextField
            v-model="form.rsvp_limit"
            persistent-hint
            type="number"
            label="Maximum number of rsvps"
            hint="Ex: 2 maximum RSVPs will only allow the first two users to confirm"
            :min="0"
            @keypress="numericOnly"
          />
        </VCol>
        <VCol cols="12">
          <VRow
            row
            wrap
            justify-space-between
          >
            <VCol xs12>
              <VRow
                row
                justify-space-between
                align-center
              >
                <h4>RSVPs</h4>
                <VTooltip top>
                  <template #activator="{on}">
                    <VBtn
                      v-on="on"
                      color="primary"
                      text:disabled="!canRsvp"
                      @click="onToggleMembersDialog"
                    >
                      <VIcon class="wethrive">
                        add
                      </VIcon>Add Rsvps
                    </VBtn>
                  </template>
                  <span>{{ canRsvp ? 'Add an RSVP' : 'RSVPs are not available at this time. Did you limit RSVPs?' }}</span>
                </VTooltip>
              </VRow>
            </VCol>
            <VCol cols="12">
              <RSVPList
                :mode="form.rsvp_mode"
                :rsvps="rsvps"
                :disable-actions="!form.id"
                @removeMember="removeMember"
                @acceptRsvp="onAcceptRsvp"
                @declineRsvp="onDeclineRsvp"
              />
            </VCol>
          </VRow>
          <Dialog
            title="Add RSVP"
            :hide-overlay="true"
            :is-dialog-open="dialogs.rsvps"
            @toggle:dialog="onToggleMembersDialog"
          >
            <MembersForm
              ref="addRSVPs"
              type="any"
              mode="multiple"
              :existing="existingMembers"
              :is-group="true"
              @addMember="addMember"
              @removeMember="removeMember"
              @saveMembers="dialogs.rsvps = false"
            />
          </Dialog>
        </VCol>
      </VRow>
      <VCardActions
        v-if="mode !== 'dialog'"
        class="justify-center"
      >
        <VBtn
          :loading="activity.isFormLoading"
          @click="onSave"
        >
          Save
        </VBtn>
      </VCardActions>
    </VContainer>
    <Dialog
      :hide-overlay="true"
      :is-dialog-open="dialogs.members"
      :title="`Add ${$team.user_role_name}`"
      @toggle:dialog="onToggleMembersDialog"
    >
      <MembersForm
        ref="addMembers"
        type="any"
        mode="multiple"
        :existing="existingMembers"
        :is-group="true"
        @addMember="addMember"
        @removeMember="removeMember"
        @saveMembers="dialogs.members = false"
      />
    </Dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import {
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import HasMembers from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import RSVPList from "@/components/Elements/Data/RSVPList";
import moment from "moment";
export default {
  name: "SaveEvent",
  components: {
    MembersForm,
    RSVPList,
  },
  mixins: [FormMixin, HasMembers, HasCalendarEntries],
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    entityCalendarId: {
      type: Number,
      default: null,
    },
    entityCalendars: {
      type: Array,
      default: () => [],
    },
    entityType: {
      type: String,
      default: "",
    },
    existing: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelType: "CalendarEntry",
      showDate: new Date(),
      dialogs: {
        rsvps: false,
      },
      menu: {
        eventEndsValueMenu: false,
        startDateMenu: false,
        entilDateMenu: false,
        endDateMenu: false,
        startTimeMenu: false,
        untilTimeMenu: false,
        endTimeMenu: false,
      },
      entityTypes: [
        {
          id: "App\\Models\\Program",
          title: this.featureName("Program", "singularize"),
        },
        {
          id: "App\\Models\\Deliverable",
          title: this.featureName("Deliverable", "singularize"),
        },
      ],
      options: {
        primary_contents: [],
        supplemental_contents: [],
        programs: [],
        day_of_week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        rsvp_mode: {
          shift: "Shift",
          standard: "Standard",
        },
      },
      defaultForm: {
        start_date: moment().format("YYYY-MM-DD"),
        end_date: null,
        start_time: "09:00",
        end_time: null,
        entries: [],
        entity_id: null,
        entity_type: null,
        calendar_id: null,
        is_all_day: false,
        is_recurring: false,
        recur_count: 1,
        recur_frequency: "weekly",
        rsvps: [],
        rsvp_is_open: true,
        rsvp_mode: "standard",
        recur_ends: null,
        recur_ends_on: null,
        recur_ends_count: 0,
        days_of_week: [],
      },
      newCalendarIsSaving: false,
      newCalendar: {
        title: "",
      },
    };
  },
  validations: {
    form: {
      start_date: {
        required,
      },
      entity_type: {
        required,
      },
      entity_id: {
        required,
      },
      calendar_id: {
        required,
      },
      recur_ends: {
        required: requiredIf(function (form) {
          return form.is_recurring === true;
        }),
      },
      recur_ends_on: {
        required: requiredIf(function (form) {
          return form.is_recurring === true && form.recur_ends === "on";
        }),
      },
      recur_ends_count: {
        required: requiredIf(function (form) {
          return form.is_recurring === true && form.recur_ends === "after";
        }),
      },
    },
  },
  computed: {
    recurFrequencies() {
      return Object.entries(this.options.recur_frequency || {}).map(
        ([value, text]) => {
          return {
            value,
            text: this.form.recur_count > 1 ? text.pluralize() : text,
          };
        }
      );
    },
    daysOfWeek() {
      return !this.form.id || !this.form.sequence_owner_id
        ? this.options.day_of_week || []
        : Object.values(this.form.days_of_week || {});
    },
    selectedEntityTitle() {
      return this.form.entity_id
        ? this.entityOptions.find((entity) => entity.id === this.form.entity_id)
            .title
        : "";
    },
    selectedEntityTypeTitle() {
      return this.form.entity_type
        ? this.entityTypes.find((entity) => entity.id === this.form.entity_type)
            .title
        : "";
    },
    events() {
      const entries = this.form.entries || [];
      return entries.map((entry) => {
        return this.formatEvent(entry, this.form);
      });
    },
    entityOptions() {
      return this.options[this.getEntity()]
        ? this.options[this.getEntity()]
        : [];
    },
    calendarOptions() {
      let item = {};
      if (this.entityCalendars.length) {
        return this.entityCalendars;
      }
      if (this.entityOptions instanceof Array && this.entityOptions.length) {
        item = this.entityOptions.find(
          (entity) => entity.id === this.form.entity_id
        );
      }
      return item instanceof Object && item.calendars instanceof Array
        ? item.calendars.filter((c) => c.calendar_type === "local")
        : [];
    },
    selectedCalendar() {
      return (
        this.calendarOptions.find((c) => c.id == this.form.calendar_id) || {}
      );
    },
    canSaveNewCalendar() {
      return this.newCalendar.title && !this.newCalendarIsSaving;
    },
    calendarMembers() {
      return this.selectedCalendar instanceof Object
        ? this.selectedCalendar.members || []
        : [];
    },
    existingMembers() {
      return this.form.rsvps instanceof Array
        ? this.form.rsvps.map((u) => u.user_id)
        : [];
    },
    modeTitle() {
      return this.form.rsvp_mode === "standard" ? "RSVP" : "Shift";
    },
    rsvps() {
      return (this.form.rsvps || []).sortBy("user.name");
    },
    canRsvp() {
      let canRsvp = true;
      if (
        this.form.rsvp_is_limited_to_single === true &&
        this.form.rsvps_count > 0
      ) {
        canRsvp = false;
      } else if (
        this.form.rsvp_limit > 0 &&
        this.form.rsvp_limit <= this.rsvps.length
      ) {
        canRsvp = false;
      }
      return canRsvp;
    },
    calendarEntry() {
      return {
        id: this.form.id,
        rsvp_mode: this.form.rsvp_mode,
      };
    },
  },
  watch: {
    calendarOptions: function (value) {
      if (value instanceof Array && value.length == 1) {
        this.form.calendar_id = value[0].id;
        this.form.title = this.form.title || value[0].title;
        this.newCalendar.title = this.form.title;
      }
    },
    "form.entity_id": function (value) {
      if (value >= 1) {
        this.newCalendar.title = this.entityOptions.find(e => e.id == value).title;
      }
    },
    //   "form.calendar_id": function(value) {
    //     if (value && !this.title) {
    //       setTimeout(() => {
    //         const entity =
    //           this.entityOptions instanceof Array
    //             ? this.entityOptions.find(item => item.id === this.form.entity_id)
    //             : null;
    //         const calendar =
    //           this.calendarOptions instanceof Array
    //             ? this.calendarOptions.find(item => item.id === value)
    //             : null;
    //         if (entity && calendar) {
    //           this.form.title = entity.title + ": " + calendar.title;
    //         }
    //       }, 100);
    //     }
    //   }
  },
  created() {
    this.$log.debug("[SaveEvent]: Created");
    if (
      this.entityType &&
      this.entity instanceof Object &&
      this.entity.id !== null
    ) {
      this.$log.debug("[SaveEvent]: Loading existing form");
      this.form.entity_id = this.entity.id || this.form.entity_id;
      this.form.entity_type = this.entityType || this.form.entity_type;
      this.form.calendar_id = this.entityCalendarId || this.form.calendar_id;
      this.options[this.getEntity()] = [this.entity];
      this.form.title = this.entity.title;
      this.entityTypes = this.entityTypes.filter(
        (type) => type.id == this.entityType
      );
    }

    // const unwatchRsvps = this.$watch("calendarMembers.length", function(
    // length
    // ) {
    if (!this.formHasId) {
      this.$log.debug("[SaveEvent]: New form", this.formHasId);
      // Add the calendar members to this event's RSVP list by default
      this.$set(
        this.form,
        "rsvps",
        (this.calendarMembers || [])
          // Don't include the current user in the default member list
          .filter((m) => m.id !== this.$user.id)
          .map((member) => {
            return {
              user: member,
              user_id: member.id,
              status: "",
              calendar_entry: this.calendarEntry,
            };
          })
      );
    }
    // });

    this.addValidationMessages({
      errors: {
        entity_type: `You must attach this event to something`,
        entity_id: `You must attach this event to a specific ${this.selectedEntityTypeTitle}`,
        calendar_id: `You must select a calendar`,
      },
    });
  },
  methods: {
    ...mapActions({
      doGetCalendarEntry: "calendarEntry/getOne",
      doSaveCalendar: "calendar/save",
      doSaveCalendarEntry: "calendarEntry/save",
      doGetFormConfig: "calendar/getFormConfig",
    }),
    getEntity() {
      const entityMap = {
        "App\\Models\\Program": "programs",
        "App\\Models\\Deliverble": "deliverables",
        "App\\Models\\Report": "reports",
      };
      return entityMap[this.form.entity_type];
    },
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    addMember(member) {
      this.form.rsvps = this.onAddMember(
        this.form.rsvps,
        {
          user: member,
          user_id: member.id,
          calendar_entry: Object.select(this.form, ["rsvp_mode"]),
        },
        (m) => {
          return m.user_id === member.user_id;
        }
      );
      // this.saveMembers(true);
    },
    removeMember(member) {
      this.form.rsvps = this.onRemoveMember(
        this.form.rsvps,
        member,
        (m) => {
          return m.user_id === member.user_id;
        },
        true
      );
      // this.saveMembers(true);
    },
    onSaveCalendar() {
      this.newCalendar.entity_type = this.form.entity_type;
      this.newCalendar.entity_id = this.form.entity_id;
      this.newCalendar.start_date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.newCalendarIsSaving = true;
      this.doSaveCalendar(this.newCalendar)
        .then((response) => {
          console.log("Response", response);
          const calendar = response.data;
          this.form.calendar_id = calendar.id;
          if (this.entity instanceof Object) {
            this.entity.calendars = this.entity.calendars || [];
            this.entity.calendars.push(calendar);
          }
          const entity =
            this.entityOptions instanceof Array
              ? this.entityOptions.find(
                  (e) => (e.id === parseInt(this.form.entity_id))
                )
              : {};
          entity.calendars = entity.calendars || [];
          entity.calendars.push(calendar);
          this.newCalendar = {};
          this.newCalendarIsSaving = false;
        })
        .catch((e) => {
          this.newCalendarIsSaving = false;
        });
    },
    onSaveCalendarEntry() {
      this.$emit("saveCalendar");
    },
    onGet(id) {
      const params = id.id ? id : null;
      return this.doGetCalendarEntry(params).then((result) => {
        result.days_of_week = Object.values(result.days_of_week || {});
        return result;
      });
    },
    onSave() {
      // this.form.start_date = /\d\d\d\d-\d\d-\d\d \d\d:\d\d/.test(
      //   this.form.start_date
      // )
      //   ? this.form.start_date
      //   : `${this.form.start_date} ${this.form.start_time || "00:00:00"}`;
      // if (this.form.end_date) {
      //   this.form.end_date =
      //     this.form.end_date &&
      //     /\d\d\d\d-\d\d-\d\d \d\d:\d\d/.test(this.form.end_date)
      //       ? this.form.end_date
      //       : `${this.form.end_date} ${this.form.end_time || "00:00:00"}`;
      // }
      const data = this.prepareFormModel(
        { ...this.form },
        {
          dontSave: ["calendar", "entries"],
        }
      );
      return this.saveFormModel(this.doSaveCalendarEntry, null, data).then(
        (result) => {
          if (result instanceof Object) {
            this.form.days_of_week =
              result.days_of_week instanceof Object
                ? Object.values(result.days_of_week || {})
                : [result.days_of_week];
          }
        }
      );
    },
    setShowDate(d) {
      this.showDate = d;
    },
    setStartDate() {
      this.$refs.startDateMenu.save(this.form.start_date);
      this.$v.form.start_date.$touch();
      this.update();
    },
    setEndDate() {
      this.$refs.endDateMenu.save(this.form.end_date);
      this.update();
    },
    setEventEndsValueDate() {
      this.$refs.eventEndsValueMenu.save(this.form.recur_ends_on);
      this.update();
    },
    onToggleMembersDialog() {
      this.dialogs.rsvps = !this.dialogs.rsvps;
    },
    updateRsvp(rsvp) {
      // Depending on the mode of the RSVP reject other RSVPs if this once is accepted
      if (this.form.rsvp_is_limited_to_single && rsvp.is_accepted) {
        this.form.rsvps = this.form.rsvps.map((r) => {
          if (r.user_id != rsvp.user_id) {
            r.is_accepted = false;
            r.is_rejected = true;
            r.accepted_on = null;
            r.rejected_on = Date.now();
          }
          return r;
        });
      }
      const index = this.form.rsvps.findIndex(
        (r) => r.user_id === rsvp.user_id
      );
      this.form.rsvps.splice(index, rsvp);
      this.update();
    },
    onDeclineRsvp(rsvp) {
      /** TODO: Conect to API */
      rsvp.is_accepted = false;
      rsvp.is_rejected = true;
      rsvp.accepted_on = null;
      rsvp.rejected_on = Date.now();
      this.updateRsvp(rsvp);
    },
    onAcceptRsvp(rsvp) {
      rsvp.is_accepted = true;
      rsvp.is_rejected = false;
      rsvp.accepted_on = Date.now();
      rsvp.rejected_on = null;
      this.updateRsvp(rsvp);
    },
  },
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
.has-prepend-append.v-text-field .v-input__prepend-outer,
.has-prepend-append.v-text-field .v-input__append-outer {
  margin-top: 34px;
}
</style>