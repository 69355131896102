const Vue = require("vue");

/**
 * SparkForm helper class. Used to set common properties on all forms.
 */
window.SparkForm = function(data) {
	let form = this;

	form = {
		...this,
		...data,
	};

	/**
	 * Create the form error helper instance.
	 */
	this.errors = new SparkFormErrors();

	this.busy = false;
	this.successful = false;

	/**
	 * Start processing the this.
	 */
	this.startProcessing = function() {
		this.errors.forget();
		this.busy = true;
		this.successful = false;
	};

	/**
	 * Finish processing the this.
	 */
	this.finishProcessing = function() {
		this.busy = false;
		this.successful = true;
	};

	/**
	 * Reset the errors and other state for the this.
	 */
	this.resetStatus = function() {
		this.errors.forget();
		this.busy = false;
		this.successful = false;
	};

	/**
	 * Set the errors on the this.
	 */
	this.setErrors = function(errors) {
		this.busy = false;
		this.errors.set(errors);
	};
};

/**
 * Spark form error collection class.
 */
window.SparkFormErrors = function() {
	this.errors = {};

	/**
	 * Determine if the collection has any errors.
	 */
	this.hasErrors = function() {
		return !_.isEmpty(this.errors);
	};

	/**
	 * Determine if the collection has errors for a given field.
	 */
	this.has = function(field) {
		return _.indexOf(_.keys(this.errors), field) > -1;
	};

	/**
	 * Get all of the raw errors for the collection.
	 */
	this.all = function() {
		return this.errors;
	};

	/**
	 * Get all of the errors for the collection in a flat array.
	 */
	this.flatten = function() {
		return _.flatten(_.toArray(this.errors));
	};

	/**
	 * Get the first error message for a given field.
	 */
	this.get = function(field) {
		if (this.has(field)) {
			return this.errors[field][0];
		}
	};

	/**
	 * Set the raw errors for the collection.
	 */
	this.set = function(errors) {
		if (typeof errors === "object") {
			this.errors = errors;
		} else {
			this.errors = {
				form: [
					"Something went wrong. Please try again or contact customer support.",
				],
			};
		}
	};

	/**
	 * Remove errors from the collection.
	 */
	this.forget = function(field) {
		if (typeof field === "undefined") {
			this.errors = {};
		} else {
			Vue.delete(this.errors, field);
		}
	};
};

export default SparkForm;
