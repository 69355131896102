<template>
  <VContainer
    fluid
    grid-list-lg
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>
            <VBtn
              icon
              fab
              @click="$router.go(-1)"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            View {{ featureName('Submitted Deliverable') }}
            <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, model.id)" />
            <Linkable
              :tooltip="`Click or tap to ${$isUserStudent ? 'submit' : 'update'}`"
              :icon="$isUserStudent ? 'assignment_ind' : 'edit'"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.${$isUserStudent ? 'submit' : 'update'}`, model.id)"
              :to="getRouteForItem(`${modelTypeRoute}.update`, model.id)"
            />
          </h1>
        </VRow>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VRow
          row
          wrap
        >
          <VCol cols="12">
            <VCard>
              <VCardText class="px-4">
                <h1 class="display-1">
                  {{ featureName('Deliverable') }}: {{ (model.deliverable || {}).title }}
                </h1>
                <VList dense>
                  <VListItem>
                    <VListItemContent>
                      <VListItemTitle>
                        For
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <UserModal :user="model.user" />
                    </VListItemAction>
                  </VListItem>
                  <VListItem>
                    <VListItemContent>
                      <VListItemTitle>
                        Submitted By
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <UserModal :user="model.submitted_by" />
                    </VListItemAction>
                  </VListItem>
                  <VListItem v-if="model.submitted_on">
                    <VListItemContent>
                      <VListItemTitle>
                        Submitted On
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      {{ $timezone.formatDateTime(model.submitted_on) }}
                    </VListItemAction>
                  </VListItem>
                  <VListItem v-if="model.deliverable && model.deliverable.city">
                    <VListItemContent>
                      <VListItemTitle>
                        City
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      {{ model.deliverable.city.name }}
                    </VListItemAction>
                  </VListItem>
                  <VListItem v-if="model.deliverable && model.deliverable.content">
                    <VListItemContent>
                      <VListItemTitle>
                        <p v-html="model.deliverable.content" />
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem v-if="((model.entity||model.program||model.supplement||model.primaryContent) instanceof Object)">
                    <VListItemContent>
                      <VListItemTitle>
                        Submitted for:
                      </VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <strong>{{ (model.entity||model.program||model.supplement||model.primaryContent || {}).title }}</strong>
                    </VListItemAction>
                  </VListItem>
                </VList>
              </VCardText>
            </VCard>
            <FilesList
              :readonly="true"
              :files="model.deliverable.files"
            />
            <DynamicInputsView
              :inputs="model.metadata"
              :card="false"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="my-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";

export default {
  name: "ViewDeliverableSubmitted",
  components: { DynamicInputsView },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Deliverable Submitted",
    };
  },
  computed: {
    hasAnyAssociations() {
      return (
        this.hasProgram ||
        this.hasPrimaryContent ||
        this.hasReport ||
        this.hasSupplement ||
        this.hasFAQ
      );
    },
    hasProgram() {
      return this.model && this.model.program instanceof Object;
    },
    hasPrimaryContent() {
      return this.model && this.model.primary_content instanceof Object;
    },
    hasReport() {
      return this.model && this.model.report instanceof Object;
    },
    hasSupplement() {
      return this.model && this.model.supplement instanceof Object;
    },
    hasFAQ() {
      return this.model && this.model.faqs instanceof Object;
    },
  },
  methods: {
    ...mapActions({
      doGetDeliverableSubmitted: "deliverableSubmitted/getOne",
    }),
    onGet(id) {
      let params = id;
      if (this.$route.params.deliverable) {
        params = {
          deliverable_id: this.$route.params.deliverable,
          id: id,
        };
      }
      return this.doGetDeliverableSubmitted(params);
    },
  },
};
</script>

