<script>
export default {
  computed: {
    visibilityToOptions() {
      let options = [];
      for (const visible in this.options.visible_to) {
        options.push({ title: this.options.visible_to[visible], id: visible });
      }
      return options;
    },
    hasVisibilityToOptions() {
      return (
        this.form.visible_to instanceof Array && this.form.visible_to.length > 0
      );
    }
  },
  created() {
    if (this.form instanceof Object) {
      this.form.visible_to =
        this.form.visible_to instanceof Object &&
        !(this.form.visible_to instanceof Array)
          ? Object.keys(this.form.visible_to)
          : this.form.visible_to;
    }
  },
  methods: {
    getVisibleTo(item) {
      if (item instanceof Array && !item.length) {
        return [];
      } else if (item instanceof Array) {
        return item;
      }
      return item instanceof Object && item.visible_to instanceof Object
        ? Object.values(item.visible_to)
        : [];
    },
    getVisibleToForProgram(item, programId) {
      let model = item;
      if(!model.hasOwnProperty("pivot")) {
        model = item.programs.find((program) => program.id === programId);
      }
      const visibility = JSON.parse(model.pivot.visible_to);
      return visibility instanceof Object ? Object.keys(visibility) : [];
    },
  }
};
</script>