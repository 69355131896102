<template>
  <VContainer
    fluid
    grid-list-lg
    fill-height
  >
    <VRow
      column
      fill-height
    >
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <h1>Details</h1>
              <VCardText>
                <h2 v-if="model.calendar && model.calendar.entity">
                  {{ model.calendar.calendarFor }}: {{ model.calendar.entity.title }}
                </h2>
                <h2 v-if="model.calendar_entry">
                  Held On: {{ $timezone.formatDateTime(model.calendar_entry.date) }}
                </h2>
                <h2 v-if="model.attendance_submitted">
                  Submitted By: {{ model.attendance_submitted.submitted_by.name }}
                </h2>
              </VCardText>
            </VCard>
          </VCol>
          <VCol
            cols="12"
            class="ml-2"
          >
            <VCard>
              <h1>{{ featureName('Students', 'pluralize') }}</h1>
              <VCardText>
                <VList>
                  <VListItem
                    v-for="(student, key) in model.students"
                    :key="key"
                    @click.prevent
                  >
                    <VListItemAvatar>
                      <img :src="student.photo_url">
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle v-text="student.name" />
                    </VListItemContent>
                    <VListItemAction>
                      <VIcon color="primary">
                        {{ student.attendance.is_present ? 'check' : 'minus' }}
                      </VIcon>
                    </VListItemAction>
                  </VListItem>
                </VList>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";

export default {
  name: "AttendanceView",
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Attendance",
    };
  },
  methods: {
    ...mapActions({
      doGetAttendance: "attendance/getOne",
    }),
    onGet(id) {
      return this.doGetAttendance(id).then((result) => {
        this.calendars.data = [result.calendars];
        return result;
      });
    },
  },
};
</script>