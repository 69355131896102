<template>
  <VMenu
    v-model="menu"
    offset-y
    style="border-top-left-radius: 0px"
    :close-on-content-click="false"
    :class="{
      'elevation-1': elevated,
      'no-border-radius': noBorder
    }"
    @update:return-value="filterAndClose"
  >
    <template #activator="{ on, attrs }">
      <VBtn
        v-bind="attrs"
        v-on="on"
        text
        class="pl-1"
        style="display: inline-block"
        :color="hasValue ? 'info' : ''"
      >
        {{ text }}
        <VIcon small>
          expand_more
        </VIcon>
      </VBtn>
    </template>
    <VCard>
      <VCardText
        class="pb-2 px-0"
        :style="{
          overflow: 'hidden auto',
          maxHeight: !fixed ? 'calc(100% - 56px)' : 'calc(100% - 116px)'
        }"
      >
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="pb-0"
          >
            <VList
              v-if="filter.type === 'array' || filter.type === 'list'"
              one-line
              class="px-0"
              style="max-height: 250px; overflow-y: auto; overflow-x: hidden"
            >
              <VSubheader :style="headerStyle">
                {{ filter.label }}
              </VSubheader>
              <VListItem @click="onToggleAll(filter.field, filter.options)">
                <VListItemContent>
                  <VListItemTitle>
                    Show All
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <VIcon
                    v-if="!form || form instanceof Array && form.length === 0"
                    small
                    color="primary"
                  >
                    fiber_manual_record
                  </VIcon>
                </VListItemAction>
              </VListItem>
              <VListItem
                v-for="(option,index) in filter.options"
                :key="`list-option-${index}`"
                @click="onToggleSelected(filter.field, index, option instanceof Object ? option.id : option)"
              >
                <VListItemContent>
                  <VListItemTitle>
                    {{ getOptionLabel(option) }}
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <VIcon
                    v-if="getIsOptionValueSelected(form, option instanceof Object ? option.id : option)"
                    small
                    color="primary"
                  >
                    fiber_manual_record
                  </VIcon>
                </VListItemAction>
              </VListItem>
            </VList>
            <Autocomplete
              v-else-if="filter.type === 'dropdown'"
              v-model="form"
              chips
              deletable-chips
              multiple
              clearable
              :item-value="filter.options[0] instanceof Object ? filter.itemValue || filter.item_value || 'id' : null"
              :item-text="filter.options[0] instanceof Object ? filter.itemText || filter.item_text || 'title' : null"
              :label="filter.label"
              :placeholder="filter.placeholder"
              :persistent-hint="filter.hint != null"
              :hint="filter.hint"
              :items="filter.options"
            />
            <template v-else-if="filter.type === 'checkbox'">
              <h3
                class="subtitle v-subheader"
                :style="headerStyle"
              >
                {{ filter.label }}
              </h3>
              <VRow
                row
                wrap
                class="mx-2"
              >
                <VCol>
                  <VCheckbox
                    v-for="(option,index) in filter.options"
                    v-model="form"
                    :key="`option-${filter.field}-${index}`"
                    :label="getOptionLabel(option)"
                    :value="option instanceof Object ? option.value || option.id : option"
                    :placeholder="filter.placeholder"
                    :persistent-hint="filter.hint != null"
                    :hint="filter.hint"
                  />
                </VCol>
              </VRow>
            </template>
            <template v-else-if="filter.type === 'boolean'">
              <h3
                class="subtitle v-subheader"
                :style="headerStyle"
              >
                {{ filter.label }}
              </h3>
              <VRow
                row
                wrap
                class="mx-2"
              >
                <VCol>
                  <VSwitch
                    v-model="form"
                    inset
                    :key="`option-${filter.field}-${Date.now()}`"
                    :label="filter.label"
                    :placeholder="filter.placeholder"
                    :persistent-hint="filter.hint != null"
                    :hint="filter.hint"
                  >
                    <VChip
                      slot="append"
                      close
                      icon
                      small
                      style="margin-top: 0px"
                      @click="form = null"
                    >
                      Clear
                    </VChip>
                  </VSwitch>
                </VCol>
              </VRow>
            </template>
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions class="px-2 d-flex justify-space-between">
        <VBtn
          v-if="!fixed"
          @click="$emit('toggle')"
        >
          Close
        </VBtn>
        <VBtn
          color="info"
          :block="fixed"
          @click="filterAndClose"
        >
          Filter
        </VBtn>
      </VCardActions>
    </VCard>
  </VMenu>
</template>
<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    elevated: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "(not set)",
    },
    value: {
      type: null,
      default: null,
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menu: false,
      form: null,
    };
  },
  computed: {
    hasValue() {
      return !(
        this.value == null ||
        (this.value instanceof Array && !this.value.length)
      );
    },
    headerStyle() {
      return {
        borderBottom: `solid 2px ${this.$vuetify.theme.themes.light.primary}`,
      };
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.form = this.value || this.form;
      },
    },
    form: {
      deep: true,
      handler() {
        this.$emit("input", this.form);
      },
    },
    value: {
      deep: true,
      handler(value) {
        this.form = value;
      },
    },
  },
  mounted() {
    this.form = this.value || this.form;
  },
  methods: {
    filterAndClose() {
      this.menu = !this.menu;
      this.$emit("input", this.form);
      this.$emit("filter");
      this.$emit("toggle");
    },
    getOptionLabel(option) {
      return option instanceof Object
        ? option.title || option.text || option.name
        : option;
    },
    getIsOptionValueSelected(filterOptions, value) {
      return (
        (filterOptions instanceof Array && filterOptions.indexOf(value) > -1) ||
        filterOptions == value
      );
    },
    onToggleAll(options) {
      const filterOptions = this.form || [];
      this.form =
        filterOptions.length == 0
          ? options.map((o) => (o instanceof Object ? o.id : o))
          : [];
    },
    onToggleSelected(filterName, index, value) {
      this.form = this.form || [];
      if (value instanceof Boolean || value == undefined) {
        this.$set(this.form, index, !this.form[index]);
      } else {
        if (this.form.indexOf(value) > -1) {
          this.form.splice(index, 1);
        } else {
          this.$set(this.form, index, value);
        }
      }
    },
  },
};
</script>
<style scoped>
.menuable__content__active {
  border-top-left-radius: 0px !important;
}
</style>