<template>
  <VSkeletonLoader
    justify-center
    type="date-picker-options,card-heading"
    :loading="activity.isFirstLoad"
    :class="{
      fluid: true,
      'd-flex flex-column': true,
    }"
  >
    <SaveHeader
      style="flex: 1 1 auto"
      save-btn-icon=""
      save-btn-text="Save"
      disable-add-new-text
      :owner="this"
      :mode="mode"
      :has-content="true"
      :icon-button="false"
      :enable-add-new="false"
      :hide-title-with-back="hideTitleWithBack"
    >
      <template slot="title">
        Save {{ contentType }}
      </template>
      <template slot="content-center">
        <VRow
          align-center
          justify-start
        >
          <VCol class="text-right">
            <VTooltip top>
              <template #activator="{ on }">
                <VBtn
                  v-on="on"
                  icon
                  :disabled="!form.id"
                  :loading="activity.isToggling"
                  @click="onToggle"
                >
                  <VIcon
                    v-if="form.is_locked"
                    size="16px"
                  >
                    {{ getLockIcon(form.is_locked) }}
                  </VIcon>
                  <img
                    v-else
                    src="../../images/lessons/lock@2x.png"
                    style="width: 12px"
                  >
                </VBtn>
              </template>
              <span>Click to {{ form.is_locked ? "Unlock" : "Lock" }}</span>
            </VTooltip>
          </VCol>
          <VCol>
            <TextField
              v-model="form.title"
              small
              filled
              height="35"
              single-line
              class="lesson-title-field"
              style="
                    height: 35px;
                    padding-top: 0px;
                    margin-top: 0px;
                    min-height: 35px !important;
                  "
              :disabled="activity.isSaving"
              :placeholder="`Enter ${contentType.toLowerCase()} title here`"
              @input="onSetLessonDirty"
            />
          </VCol>
        </VRow>
      </template>
      <template slot="actions-center-left">
        <VBtn
          large
          icon
          @click="drawers.left = !drawers.left"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <VIcon>view_agenda</VIcon>
        </VBtn>
        <VCol class="pr-2">
          <VBtn
            depressed
            style="box-shadow: none; color: rgba(0, 0, 0, 0.65)"
            @click="onToggleSettingsDialog"
          >
            <img
              src="../../images/lessons/details@2x.png"
              style="width: 14px"
            >
            <span class="pl-2">Details</span>
          </VBtn>
        </VCol>
        <VCol>
          <VBtn
            depressed
            style="box-shadow: none; color: rgba(0, 0, 0, 0.65)"
            @click="onToggleAssociationsDialog"
          >
            <img
              src="../../images/lessons/associations-icon@2x.png"
              style="width: 14px"
            >
            <span class="pl-2">Associations</span>
          </VBtn>
        </VCol>
      </template>
    </SaveHeader>
    <VRow
      id="lesson-holder"
      class="fluid px-0 ma-0"
      :style="{
        height: $vuetify.breakpoint.mdAndUp
          ? hasMessagesOrErrors
            ? 'calc(90vh - 126px)'
            : 'calc(100vh - 126px)'
          : 'max-content',
        overflow: 'hidden',
        'border-top': hideTitleWithBack ? '1px solid #e3e3e3' : 'none',
      }"
    >
      <VCol
        fill-height
        cols="12"
        class="pt-0 pb-1"
      >
        <VCard
          fill-height
          style="border-radius: 4px"
        >
          <VRow fill-height>
            <VNavigationDrawer
              :style="{
                height: '100%',
              }"
              v-model="drawers.left"
              class="wethrive-shadow col sm11 md2 fill-height pl-0 pr-0 pt-0 pb-0"
              style="overflow: hidden auto; border-radius: 4px"
              :fixed="$vuetify.breakpoint.smAndDown"
              :permanent="$vuetify.breakpoint.mdAndUp"
              :temporary="$vuetify.breakpoint.smAndDown"
              :floating="true"
            >
              <VCol
                fill-height
                class="px-0 py-0"
              >
                <Cards
                  v-model="form.cards"
                  ref="cards"
                  choice-mode="user"
                  :active-card-index="currentCardIndex"
                  :input-choices="
                    Object.values(cardChoices)
                      .map((group) => group.items)
                      .flatten()
                  "
                  :can-add-slide="(form.title || '').length > 0"
                  @input="onBackgroundSaveAssessment"
                  @edit="onStartEdit"
                  @remove="onRemoveCard"
                  @delete="onDeleteCard"
                  @restore="onRestoreCard"
                  @add="onToggleAddCardDialog"
                  @duplicate="onDuplicateCard"
                  @addNewBlankCard="onAddNewBlankCard"
                />
              </VCol>
            </VNavigationDrawer>
            <VCol
              class="pt-0 pb-0 pl-0"
              style="overflow: hidden; border-left: 1px solid #e3e3e3"
              :style="{
                height: '100%',
              }"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
            >
              <Card
                :assessment="form"
                :card="currentCard"
                :index="currentCardIndex"
                :options="options"
                :icon="getCardIcon(hasCurrentCard ? currentCard.type : '')"
                :can-add-slide="(form.title || '').length > 0"
                :model-is-dirty="modelIsDirty"
                :has-current-card="hasCurrentCard"
                @input="onUpdateCard"
                @saveCardDirty="onSetLessonDirty"
                @setViewDialogModel="onSetViewDialogModel(form)"
                @setPreviewItem="(priority) => (activeCardPriority = priority)"
              />
            </VCol>
          </VRow>
        </VCard>
      </VCol>
    </VRow>

    <Dialog
      max-width="640px"
      button-text="Add Card"
      :is-dialog-open="dialogs.addCard"
      :title="`Add Card`"
      :uid="_uid"
      :action="onAddCard"
      :close-immediately="true"
      @toggle:dialog="onToggleAddCardDialog"
    >
      <AddCard
        ref="addCard"
        mode="dialog"
        :uid="_uid"
        :choices="cardChoices"
        :is-adding="isAddingCard"
        @input="onToggleAddCardDialog"
        @toggle:dialog="onToggleAddCardDialog"
      />
    </Dialog>
    <Dialog
      max-width="640px"
      :title="`Details`"
      :is-dialog-open="dialogs.settings"
      :action="onUpdateFormSettings"
      @toggle:dialog="onToggleSettingsDialog"
    >
      <Settings
        ref="updateSettings"
        mode="dialog"
        :assessment-type="modelType"
        :custom-options="options"
        :existing="form"
        @input="onUpdateFormSettings"
        @input:update="onUpdateForm"
      />
    </Dialog>
    <Dialog
      max-width="640px"
      :is-dialog-open="dialogs.associations"
      :title="`Associations`"
      @toggle:dialog="onToggleAssociationsDialog"
      :action="onUpdateFormAssociations"
    >
      <Associations
        ref="updateAssociations"
        mode="dialog"
        :assessment-type="modelType"
        :options="prepareCustomOptions"
        :existing="form"
        @input="onUpdateFormAssociations"
        @input:update="onUpdateForm"
      />
    </Dialog>
    <Dialog
      persistent
      :is-dialog-open="dialogs.view"
      :fullscreen="true"
      :fill-height="true"
      :hide-overlay="true"
      :title="form.title"
      @toggle:dialog="onToggleViewDialog"
    >
      <ViewComponent
        v-if="currentModel && currentModel.cards.length > 0"
        is-preview
        :id="currentModel ? currentModel.id : null"
        ref="viewItem"
        mode="dialog"
        :return-to="$route"
        :active-card="activeCardPriority"
        @toggle:dialog="onToggleViewDialog"
      />
    </Dialog>
    <Confirm ref="confirmLeave" />
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import HasDueMixin from "@/mixins/HasDueOn";
import DialogViewMixin from "@/mixins/DialogView";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import DefaultAssociations from "@/mixins/DefaultAssociations.vue";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import { required, minLength, between } from "vuelidate/lib/validators";
import Card from "./Cards/Card";
import Cards from "./Cards/index";
import Settings from "./Settings";
import AddCard from "./Cards/Add";
import ViewComponent from "./View";
import Associations from "./Associations";

export default {
  name: "LessonsSave",
  components: {
    SaveHeader,
    Settings,
    Associations,
    Card,
    Cards,
    AddCard,
    ViewComponent,
  },
  mixins: [
    FormMixin,
    HasDueMixin,
    HasAssessmentsMixin,
    DialogViewMixin,
    DefaultAssociations,
  ],
  data () {
    return {
      activity: {
        isToggling: false,
      },
      isAddingCard: false,
      redirectOnSave: true,
      autoloadViewModel: false,
      modelType: "Lesson",
      assessmentType: "lesson",
      editCardTitleMode: false,
      drawers: {
        left: false,
        right: false,
      },
      hasFiles: true,
      options: {
        workshests: [],
        prerequisites: [],
      },
      defaultForm: {
        due_on: null,
        due_in: 1,
        due_in_unit: "day",
        due_in_time: null,
        prerequisites: [],
        programs: [],
        supplements: [],
        metadata: [],
        do_now: [],
        key_points: [],
        primary_contents: [],
        files: [],
        cards: [],
        is_active: null,
        is_locked: null,
        is_gradable: true,
        visible_to: [],
      },
      dueOn: {
        date: null,
        time: null,
      },
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      containerClass: "content-bg dynamic-input-container",
      containerCardClass: "dynamic-input-container",
      dialogs: {
        addCard: false,
        settings: false,
        associations: false,
        view: false,
      },
      currentCard: {},
      currentCardIndex: 0,
      modelTypeAlt: 0,
      leaveMessage: "You have unsaved changes are you sure you want to leave?",
      activeCardPriority: null,
      dontSave: ["thread"],
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    hasPrograms () {
      return this.form.programs && this.form.programs.length;
    },
    dueInUnits () {
      let units = [];
      for (const unit in this.options.due_in_units) {
        units.push({
          title: this.options.due_in_units[unit],
          id: unit,
        });
      }
      return units;
    },
    cards () {
      return this.form.cards;
    },
  },
  /**
   * Handler before route leave to alert user to unsaved information
   */
  beforeRouteLeave (to, from, next) {
    // Whenever a lesson is created it attempts to navigate to the update route which results in a prompt that you're leaving the route
    // Only prompt is this wasn't just created
    if (!this.wasCreateMode) {
      this.onConfirmLeave(next);
    } else {
      next();
    }
  },
  created () {
    if (this.$isUserMentor && !this.checkMentorPermission(this.modelType)) {
      this.$router.push({ name: "program.index" });
    }
    // this.doGetFormConfig().then((result) => (this.options = result)); // Commented this to fix the lesson loading issue for Daquan
    this.form.cards = [];
    this.$bus.$on("set:card", (key, card) => {
      this.onSetCard(key, card);
    });
    this.$bus.$on("input:card", (key, card) => {
      this.onUpdateCard(key, card);
    });
    window.addEventListener(
      "beforeunload",
      (e) => {
        e.preventDefault();
        if (this.modelIsDirty) {
          e.returnValue = this.leaveMessage;
          return this.leaveMessage;
        }
        return;
      },
      false
    );
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "assessment/getFormConfig",
      doDuplicateCard: "lesson/duplicateCard",
    }),
    onGetFormConfig () {
      return this.doGetFormConfig();
    },
    onSave () {
      return this.onSaveAssessment().then((result) => {
        // Update the current card. Solves the issue of reflecting properly saved data in card options
        this.currentCard = (this.form.all_cards || []).find(
          (c) => c instanceof Object && c.id === this.currentCard.id
        );
        return result;
      });
    },
    onGet (id) {
      return this.onGetAssessment(id);
    },
    onDuplicateCard (key, card) {
      const params = {
        card_id: card.id,
        lesson_id: this.form.id,
      };
      this.$set(this.form.cards[key], "isDuplicating", true);
      this.doDuplicateCard(params).then((result) => {
        result.priority++;
        // Update cards priority after new blank card added
        this.form.cards = this.form.cards.map((c) => {
          if (c.priority >= result[0] && c.id != result[0].id) {
            c.priority++;
          }
          return c;
        });
        this.onBackgroundSaveAssessment(true).then(() => {
          this.form.cards.splice(key + 1, 0, result);
          this.onStartEdit(key + 1, result);
          this.$bus.$emit("notificationMessage", `Duplicated card successfully!`);
          this.$set(this.form.cards[key], "isDuplicating", false);
        });
      });
    },
    onStartEdit (key, card) {
      /* this.currentCard = {
        type: "div"
      }; */
      this.$nextTick(() => {
        this.currentCard = card;
        this.currentCardIndex = key;
      });
    },
    onAddNewBlankCard (key, card) {
      this.$set(this.form.cards[key], "isNewCardLoading", true);
      const choices = Object.values(this.cardChoices)
        .map((group) => group.items)
        .flatten();
      const choice = choices.find((choice) => choice.type === card.type) || {};
      this.onSaveCard(choice, this.form.id, key, true).then((result) => {
        // Update cards priority after new blank card added
        this.form.cards = this.form.cards.map((c) => {
          if (c.priority >= result[0] && c.id != result[0].id) {
            c.priority++;
          }
          return c;
        });
        this.onBackgroundSaveAssessment(true).then((response) => {
          this.onStartEdit(result[0], response.cards[result[0]]);
          this.$bus.$emit(
            "notificationMessage",
            `New blank ${card.type} card added successfully!`
          );
          this.$set(this.form.cards[key], "isNewCardLoading", false);
        });
      });
    },
    async onAddCard (card) {
      if (card) {
        let realCard = card;
        let currentCardIndex = this.currentCardIndex;
        this.isAddingCard = true;
        if (this.formHasId) {
          const [resultCardIndex, result] = await this.onSaveCard(
            realCard,
            this.form.id,
            currentCardIndex
          );
          currentCardIndex = resultCardIndex;
          realCard = result;
        } else {
          currentCardIndex = await this.onNewMetadata(
            realCard,
            this.currentCardIndex,
            "cards"
          );
          let savedAssessment = await this.onSaveAssessment(true);
          realCard = savedAssessment.cards[0];
        }
        realCard.assessment_id = this.form.id;
        this.isAddingCard = false;
        this.onStartEdit(this.cards.length === 1 ? 0 : currentCardIndex, realCard);
      }
      return true;
    },
    onRemoveCard (key, card) {
      if (this.currentCard === card) {
        this.currentCard = {};
      }
      this.onRemoveMetadata(key, "cards").then((index) => {
        if (index >= 1) {
          this.waitFor(() => {
            const elem = document.querySelector(`#cards-container #card${index}`);
            return elem instanceof HTMLElement;
          }, 2000).then(() => {
            this.currentCardIndex = index - 1;
          });
        } else {
          this.currentCardIndex = 0;
        }
      });
      this.onSaveAssessment(true);
    },
    onDeleteCard (key, card) {
      if (this.currentCard.id === card.id) {
        this.currentCard = {};
      }
      this.onDeleteMetadata(key, "cards");
    },
    onRestoreCard (key, card) {
      this.onRestoreMetadata(key, "cards");
    },
    onUpdateCard (key, card) {
      if (this.currentCard.id === card.id) {
        this.currentCard = { ...this.currentCard, ...card };
      }
      this.onUpdateMetadata(key, card, "cards");
    },
    onSetCard (key, card) {
      const index = this.findMetadataIndex(key, "cards");
      this.onSetMetadataProperty(index, card, "cards");
    },
    onUpdateForm (form) {
      this.form = { ...this.form, ...form };
    },
    onUpdateFormSettings (form) {
      this.onUpdateForm(form);
      this.onToggleSettingsDialog();
      this.onSaveAssessment();
    },
    onUpdateFormAssociations (form) {
      this.onUpdateForm(form);
      this.onToggleAssociationsDialog();
      this.onSaveAssessment();
    },
    onToggle () {
      if (this.form.id) {
        this.activity.isToggling = true;
        this.onToggleLocal(this.form.id).then((result) => {
          this.activity.isToggling = false;
          this.form = { ...this.form, ...result };
        });
      } else {
        this.form.is_locked = !this.form.is_locked;
      }
    },
    onConfirmLeave (callback) {
      if (this.modelIsDirty) {
        this.$refs["confirmLeave"]
          .open("Unsaved changes", this.leaveMessage, {
            color: "red",
          })
          .then((result) => {
            if (result === true) {
              callback();
              return true;
            }
          });
      } else {
        callback();
      }
    },
    updateDueOn (datetime) {
      this.form.due_on = datetime;
    },
    onToggleAddCardDialog () {
      this.dialogs.addCard = !this.dialogs.addCard;
    },
    onToggleSettingsDialog () {
      this.dialogs.settings = !this.dialogs.settings;
    },
    onToggleAssociationsDialog () {
      this.dialogs.associations = !this.dialogs.associations;
    },
    onToggleEditCardTitle () {
      this.editCardTitleMode = !this.editCardTitleMode;
    },
    onSorted (sorted) {
      this.form.cards =
        this.form.cards instanceof Array ? JSON.parse(JSON.stringify(sorted)) : [];
    },
    onSetLessonDirty () {
      this.onSetModelDirty();
    },
    getCardIcon (type) {
      if (type) {
        const choices = Object.values(this.cardChoices)
          .map((group) => group.items)
          .flatten();
        const choice = choices.find((choice) => choice.type === type) || {};
        return choice.icon || "warning";
      }
      return "warning";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.dynamic-input-add.layout.row {
  margin-top: 15px !important;
}

.dynamic-input-container-enter,
.dynamic-input-container-leave-active {
  opacity: 1;
}

.dynamic-input-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.remove-dynamic-input .v-btn__content {
  text-transform: none;
  color: $font-light-color;
}

.remove-dynamic-input:hover .v-btn__content {
  color: #ec4a4a;
}

.dynamic-input-container .v-input__append-outer:before {
  content: "";
  height: 40%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 30%;
  background-color: #eaeaea;
}

#lesson-holder {
  overflow: hidden;
}

.lesson-title-field {
  input {
    font-size: 14px;
  }

  .v-input__slot {
    min-height: 35px !important;
  }

  fieldset {
    border-radius: 6px !important;
    border: none !important;
    background-color: #eaeaea !important;
  }
}
</style>
