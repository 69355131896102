import BaseApi from "./_base-api";

class Api extends BaseApi {
  // getBasePath(action, app, params) {
  //   const realApp = app || this.$app;
  //   return (
  //     this.basePath ||
  //     `api`
  //   );
  // }

  listChartTypes() {
    this.log().info("[Services: Reporting]: List Charts");
    return this.api()
      .get(this.getUrl("reporting", 'charts'))
      .catch((error) => {
        this.log().error(
          "[Services: Report: List Charts]: Error",
          error
        );
        throw this.utils.resolveError(error);
      });
  }

  getFilterOptions(params) {
    this.log().info("[Services: Reporting]: List Options Values");
    var _this = this;
    var id = params instanceof Object ? params.id : params;
    return this.api()
      .get(this.getUrl('reporting', 'getFilterOptions', params) + "/" + id, {
        params: params
      })
      .catch(function (error) {
        _this.log().warn("[Services: ".concat(_this.type, ": Get ").concat(_this.type, "]: Error"), error);
        throw this.utils.resolveError(error);
      });
    // return this.api()
    //   .get(this.getUrl("reporting", 'charts'))
    //   .catch((error) => {
    //     this.log().error(
    //       "[Services: Report: List Charts]: Error",
    //       error
    //     );
    //     throw this.utils.resolveError(error);
    //   });
  }
}

const api = new Api({
  type: "Reporting",
  path: "reporting",
});

export default api;
