<template>
  <VRow
    fill-height
    align-center
    justify-center
    :row="$vuetify.breakpoint.mdAndUp"
    :column="$vuetify.breakpoint.smAndDown"
    wrap
  >
    <div>
      <VProgressLinear
        color="success"
        height="5"
        value="20"
      />
    </div>
    <VCol
      v-if="hasImages"
      shrink
      :style="{
        width: '100%',
        flex: '0 1 ' + ($vuetify.breakpoint.smAndDown ? '250px' : '500px')
      }"
    >
      <VCarousel
        hide-delimiters
        show-arrows
        height="250px"
        :style="{
          maxHeight: $vuetify.breakpoint.smAndDown ? '250px' : '500px'
        }"
        class="elevation-0"
      >
        <VCarouselItem
          v-for="(image, index) in images"
          :src="image.url"
          :key="index"
        />
      </VCarousel>
    </VCol>
    <VCol
      :md="$vuetify.breakpoint.mdAndUp ? 6 : false"
      :lg="$vuetify.breakpoint.mdAndUp ? 4 : false"
      :grow="$vuetify.breakpoint.smAndDown"
    >
      <VRow
        :row="!hasImages"
        :column="hasImages"
        wrap
      >
        <slot />
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
export default {
  name: "PresentContent",

  props: {
    hasImages: {
      type: Boolean,
      default: false
    },

    images: {
      type: Array,
      default: () => null
    }
  }
};
</script>

