<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
    :class="{
      'white pa-2': activity.isFirstLoad,
    }"
  >
    <VRow class="deliverable-save">
      <SaveHeader
        alt-back-icon
        :owner="this"
        :mode="mode"
      >
        <template #actions-center>
          <VTabs
            grow
            background-color="transparent"
            class="form-tabs"
          >
            <VTab
              v-for="(tab, index) in enabledTabs"
              :to="{
                hash: tab.url
              }"
              :key="index"
            >
              {{ tab.title }}
            </VTab>
          </VTabs>
          <VTooltip
            top
            content-class="required-tooltip"
            :disabled="Boolean(form.title)"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <VBtn
                  height="40px"
                  color="#60be39"
                  class="mr-3 ml-1 px-6"
                  style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25); border-radius: 10px;"
                  :disabled="isSaveDisabled"
                  :loading="activity.isFormLoading"
                  @click="onSave"
                >
                  <span class="white--text">{{ `Save ${featureName(modelType).singularize()}` }}</span>
                </VBtn>
              </div>
            </template>
            {{ `Enter a Title to Save ${featureName(modelType).singularize()}` }}
          </VTooltip>
          <VTooltip
            top
            content-class="required-tooltip"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <VBtn
                  height="40px"
                  min-width="auto"
                  color="rgba(60, 156, 255, 0.2)"
                  class="px-2 mr-3 no-shadow"
                  style="border-radius: 10px;"
                  :disabled="!form.id"
                  @click="dialogs.preview = !dialogs.preview"
                >
                  <VIcon color="#3c9cff">
                    mdi-eye
                  </VIcon>
                </VBtn>
              </div>
            </template>
            <span v-if="form.id">
              Preview
            </span>
            <span v-else>
              {{ `Save ${featureName(modelType).singularize().toLowerCase()} to enable Preview` }}
            </span>
          </VTooltip>
          <VTooltip
            top
            content-class="required-tooltip"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <Linkable
                  height="40px"
                  min-width="auto"
                  tooltip=""
                  :small="false"
                  :text-button="false"
                  :icon-button="false"
                  :expand-on-hover="false"
                  :disabled="!form.id"
                  :button-style="{ 
                    padding: '0 8px',
                    backgroundColor: 'rgba(251, 152, 14, 0.2)',
                    boxShadow: 'none',
                    marginRight: '12px',
                    borderRadius: '10px',
                  }"
                  :icon-props="{ color: '#fb980e'}"
                  :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submit`, { deliverable: form.id })"
                />
              </div>
            </template>
            <span v-if="form.id">
              Click or tap to copy this shareable link
            </span>
            <span v-else>
              {{ `Save ${featureName(modelType).singularize().toLowerCase()} to enable Sharing` }}
            </span>
          </VTooltip>
        </template>
      </SaveHeader>
      <VCol style="flex: 1 1 auto">
        <VTabsItems 
          touchless
          style="background-color: transparent;"
          :value="activeUrlTab"
        >
          <VTabItem>
            <VRow style="width: 100%;">
              <VCol
                lg="2"
                md="3"
                class="pl-0"
              >
                <VList
                  color="transparent"
                  class="questions-list"
                >
                  <VListItem>
                    <div
                      class="d-flex"
                      style="cursor: pointer;"
                      @click="addNewPage"
                    >
                      <VBtn
                        color="#252526"
                        height="40px"
                        min-width="auto"
                        class="px-2 mr-3"
                        style="border-radius: 10px;"
                      >
                        <img
                          height="24px"
                          src="../../images/forms/add-icon@2x.png"
                        >
                      </VBtn>
                      <VListItemTitle>
                        <span style="color: #252526; font-weight: 500;">
                          Add Page
                        </span>
                      </VListItemTitle>
                    </div>
                  </VListItem>
                  <Draggable
                    v-if="pages.length > 0"
                    handle=".dynamic-page-handle"
                    :no-transition-on-drag="true"
                    :animation="200"
                    :value="pages"
                    @change="event => $refs.dynamicInputs.onSectionMoved(event)"
                  >
                    <transition-group
                      name="dynamic-pages"
                      type="transition"
                    >
                      <VListItem
                        v-for="(page, key) in pages"
                        class="pt-2"
                        :key="`dynamic-page-${key}`"
                      >
                        <VListItemTitle 
                          class="d-flex align-center"
                          :style="{
                            cursor: page !== defaultPageTitle ? 'pointer' : 'default',
                          }"
                          @click="defaultPageTitle = page"
                        >
                          <VBtn
                            height="40px"
                            width="40px"
                            min-width="40px"
                            class="px-2 mr-3 no-shadow"
                            :color="page === defaultPageTitle ? '#ffffff' : '#f4f4f9'"
                            :style="{
                              border: '1px solid #252526',
                              'border-radius': '10px',
                              cursor: page !== defaultPageTitle ? 'pointer' : 'default',
                            }"
                          >
                            <span style="font-size: 16px; color: #252526">{{ key + 1 }}</span>
                          </VBtn>
                          <span
                            class="text-truncate"
                            :style="{
                              color: '#252526',
                              'font-weight': 500,
                              'max-width': $vuetify.breakpoint.lgAndUp ? '250px' : '130px',
                            }"
                          >
                            {{ page }}
                          </span>
                          <VSpacer />
                          <img
                            v-if="pages.length > 1"
                            height="24px"
                            class="dynamic-page-handle pl-2"
                            style="cursor: grabbing;"
                            src="../../images/forms/drag-icon@2x.png"
                          >
                        </VListItemTitle>
                      </VListItem>
                    </transition-group>
                  </Draggable>
                </VList>
              </VCol>
              <VCol
                md="6"
                :lg="$showLeftMainMenu && $isMenuExpanded ? 7 : 8"
              >
                <VCard
                  class="py-2"
                  style="box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);"
                >
                  <VCardTitle
                    class="pa-4"
                    style="height: 100% !important; border: none !important;"
                  >
                    <TextField
                      v-model="form.title"
                      label="Title"
                      hide-details
                      placeholder="Enter title..."
                      :error="$v.form.title.$error"
                      @input="$v.form.title.$touch()"
                      @blur="$v.$touch()"
                    />
                  </VCardTitle>
                  <VCardText class="pa-0">
                    <VRow class="px-4">
                      <VCol cols="12">
                        <Editor
                          v-model="form.content"
                          auto-grow
                          filled
                          outlined
                          menu-position="bottom"
                          placeholder="Description of form..."
                          class="content-editor"
                        />
                      </VCol>
                      <VCol
                        cols="12"
                        class="py-0"
                      >
                        <FileUpload
                          alt-view
                          text="Attach Files"
                          button-class="px-2 dashed text-right no-shadow"
                          button-style="height: 40px; border-radius: 14px;"
                          icon="mdi-plus-circle"
                          :as-button="true"
                          :outlined="true"
                          :rounded="true"
                          :multiple="true"
                          :files="form.files"
                          @removeFile="onRemoveFile"
                          @formData="onAddFile"
                        />
                      </VCol>
                    </VRow>
                    <VRow v-if="hasMetadata">
                      <VCol>
                        <DynamicInputs
                          v-model="form.metadata"
                          fields-only
                          new-fields-view
                          enable-default-values
                          enable-extra-options
                          ref="dynamicInputsMain"
                          container-class="dynamic-input-container px-4 white"
                          :can-add-more-inputs="false"
                          :show-delete-btn-text="false"
                          :card="false"
                          :default-value-options="
                            options.default_metadata_value_options
                          "
                          :input-name="featureName('Deliverable')"
                          :enable-files="true"
                          :readonly="true"
                          :values-from-user="true"
                          :sections="form.metadata_sections"
                          :default-section-title="defaultPageTitle"
                          @new:input="onNewMetadata"
                          @update:input="onUpdateMetadata"
                          @update:inputs="onUpdateMultipleMetadata"
                          @remove:input="onRemoveMetadata"
                          @remove:inputs="onRemoveMultipleMetadata"
                          @input:sections="onSetMetadataSections"
                          @update:sections="onUpdateMetadataSections"
                          @updateDefaultSectionTitle="title => defaultPageTitle = title"
                          @removeSection="removePage"
                        />
                      </VCol>
                    </VRow>
                  </VCardText>
                  <VCardActions class="px-4">
                    <VBtn
                      v-if="form.id"
                      color="error"
                      height="40px"
                      class="px-6"
                      style="border-radius: 10px;"
                      :disabled="activity.isFormLoading"
                      :loading="form.isLoading"
                      @click="deleteItem"
                    >
                      <span class="white--text">Delete</span>
                    </VBtn>
                    <VSpacer />
                    <VBtn
                      color="#ececf1"
                      height="40px"
                      class="px-6"
                      style="box-shadow: none; border-radius: 10px;"
                      :disabled="activity.isFormLoading || form.isLoading"
                      @click="$router.push({ name: 'deliverable.index'})"
                    >
                      <span style="color: #808080;">Cancel</span>
                    </VBtn>
                    <VTooltip
                      top
                      content-class="required-tooltip"
                      :disabled="Boolean(form.title)"
                    >
                      <template #activator="{ on }">
                        <div v-on="on">
                          <VBtn
                            color="#60be39"
                            height="40px"
                            class="ml-4 px-6"
                            style="box-shadow: 0 1.5px 3px 0 rgba(250, 36, 111, 0.25); border-radius: 10px;"
                            :disabled="!isFormReady || isFormInputsDisabled || form.isLoading"
                            :loading="activity.isFormLoading"
                            @click="onSave"
                          >
                            <span class="white--text">{{ `Save ${featureName(modelType).singularize()}` }}</span>
                          </VBtn>
                        </div>
                      </template>
                      {{ `Enter a Title to Save ${featureName(modelType).singularize()}` }}
                    </VTooltip>
                  </VCardActions>
                </VCard>
              </VCol>
              <VCol
                md="3"
                class="pl-0"
                :lg="$showLeftMainMenu && $isMenuExpanded ? 3 : 2"
              >
                <DynamicInputs
                  v-model="form.metadata"
                  hide-fields
                  list-view
                  enable-extra-options
                  enable-default-values
                  ref="dynamicInputs"
                  container-class="ml-2"
                  :readonly="true"
                  :values-from-user="true"
                  :default-section-title="defaultPageTitle"
                  :form-files="form.files"
                  :sections="form.metadata_sections"
                  :default-value-options="options.default_metadata_value_options"
                  @input:sections="onSetMetadataSections"
                  @removeFile="onRemoveFile"
                  @addFile="onAddFile"
                />
                <Confirm ref="confirmDelete" />
                <Dialog
                  max-width="520px"
                  :dismissable="false"
                  :persistent="true"
                  :is-dialog-open="dialogs.preview"
                >
                  <SubmitForm
                    preview-mode
                    mode="dialog"
                    :use-id-from-route="false"
                    :deliverable="form"
                    :existing="form"
                    :copyable-link="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submit`, { deliverable: form.id })"
                    @toggle:dialog="dialogs.preview = !dialogs.preview"
                  />
                </Dialog>
              </VCol>
            </VRow>
          </VTabItem>
          <VTabItem>
            <Settings
              v-model="form"
              :custom-options="prepareCustomOptions"
            />
          </VTabItem>
          <VTabItem>
            <Submitted
              mode="dialog"
              :deliverable-id="form.id"
            />
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Draggable from "vuedraggable";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import HasDueMixin from "@/mixins/HasDueOn";
import DeletesItems from "@/mixins/DeletesItems";
import HasTabsMixin from "@/mixins/HasTabs";
import DefaultAssociations from "@/mixins/DefaultAssociations.vue";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import Settings from "./Settings";
import Submitted from "./Submitted";
import SubmitForm from "./SubmitForm";

export default {
  name: "DeliverableSave",
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
  },
  mixins: [
    FormMixin,
    HasTabsMixin,
    MetadataMixin,
    HasDueMixin,
    DeletesItems,
    DefaultAssociations,
  ],
  components: {
    DynamicInputs,
    Settings,
    SubmitForm,
    Draggable,
    SaveHeader,
    Submitted,
  },
  data() {
    return {
      modelType: "Deliverable",
      hasFiles: true,
      redirectOnSave: true,
      defaultPageTitle: "Page Title 1",
      dialogs: {
        preview: false,
        settings: false,
      },
      options: {
        supplements: [],
        primary_contents: [],
        due_in_units: [],
        default_metadata_value_options: {},
      },
      defaultForm: {
        title: "",
        content: "",
        supplements: [],
        programs: [],
        metadata: [],
        metadata_sections: [],
        files: [],
        visible_to: [],
        background_color: null,
        emoji: null,
        due_on: null,
        due_in: 1,
        due_in_unit: "day",
        due_in_time: null,
        is_active: true,
        is_locked: false,
        allow_multiple_submissions: false,
      },
      dueOn: {
        date: null,
        time: null,
      },
      dontSave: ["team", "user", "submitted_by", "submitted_by_me"],
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2),
      },
    },
  },
  watch: {
    value: function (updatedValue) {
      this.form = updatedValue;
    },
    "options.default_metadata_value_options": function (updatedOptions) {
      this.$emit("defaultOptions", updatedOptions);
    },
    form: {
      handler: function (updatedForm) {
        this.$emit("input", updatedForm);
      },
      deep: true,
    },
  },
  computed: {
    tabs() {
      const env = this.$root.env;
      return [
        {
          title: "Build",
          url: "build",
          enabled: true,
        },
        {
          title: "Settings",
          url: "settings",
          enabled: true,
        },
        {
          title: "Submitted",
          url: "submitted",
          enabled: true,
        },
      ];
    },
    hasPrograms() {
      return this.form.programs && this.form.programs.length;
    },
    hasMetadata() {
      return this.form.hasOwnProperty("metadata") && this.form.metadata.length > 0;
    },
    pages() {
      if(this.hasMetadata) {
        let inputs =  this.form.metadata.groupBy((input) => input.section);
        return Object.keys(inputs || {}).unique();
      }
      return [];
    },
    isSaveDisabled() {
      return !this.isFormReady || this.isFormInputsDisabled || this.form.isLoading || this.tabs[this.activeUrlTab].url == "submitted";
    },
  },
  created() {
    if(this.$isUserMentor && !this.checkMentorPermission(this.modelType)) {
      this.$router.push({ name: "program.index" });
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "deliverable/getFormConfig",
      doSaveDeliverable: "deliverable/save",
      doGetDeliverable: "deliverable/getOne",
      doDeleteDeliverable: "deliverable/delete",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onDelete(deliverable) {
      return this.doDeleteDeliverable(deliverable.id);
    },
    onSave() {
      this.prepareDueOn();
      const item = { ...this.form };
      item.supplements = this.prepareRelationIds(item.supplements);
      item.primary_contents = this.prepareRelationIds(item.primary_contents);
      item.programs = this.prepareRelationIds(item.programs);
      item.faqs = this.prepareRelationIds(item.faqs);
      return this.saveFormModel(this.doSaveDeliverable, null, item).then(
        (result) => {
          if (result) {
            this.$emit("updateModel", result);
            this.$refs.dynamicInputsMain.prepareValue(true);
          }
        }
      );
    },
    onGet(id) {
      return this.doGetDeliverable(id).then((result) => {
        // Create metadata sections for old deliverables that don't have metadata_sections
        if(result.metadata_sections.length === 0 && result.metadata.length > 0) {
          result.metadata.forEach(metadata => {
            let sectionIndex = result.metadata_sections.findIndex((section) => section.title === (metadata.section || this.defaultPageTitle));
            if(sectionIndex === -1) {
              result.metadata_sections.push({
                title: metadata.section || this.defaultPageTitle,
                description: "",
              });
            }
          });
        }
        this.setDueOn(result);
        return result;
      });
    },
    deleteItem() {
      this.onDeleteItem(this.form, null, null, true).then((result) => {
        if (result) {
          this.$router.push({ name: "deliverable.index" });
        }
      });
    },
    addNewPage() {
      this.defaultPageTitle = `Page Title ${this.pages.length + 1}`;
      this.$nextTick(() => {
        this.$refs.dynamicInputs.onAddInput({
          text: "Short Text",
          value: "Text",
          icon: "mdi-text",
        });
      });
    },
    removePage() {
      if(this.pages.length > 0) {
        this.defaultPageTitle = this.form.metadata[0].section;
      }
    },
  },
};
</script>
<style lang="scss">
.deliverable-save {
  .save-header {
    padding: 20px 0px;
    margin-right: 12px;
  }
  .checkbox-label .v-label {
    font-size: 14px !important;
    color: #808080 !important;
    font-weight: 500;
  }
}
.form-tabs {
  flex: 0;
  .v-tabs-bar__content {
    height: 40px !important;
    margin-top: 4px !important;
  }
}
</style>
