<template>
  <VCard
    width="100%"
    :id="uid"
    :color="backgroundColor"
  >
    <VCardTitle
      v-if="!hideMainTitle"
      class="data-view-title"
    >
      <slot name="title">
        {{ title }}
      </slot>
      <slot name="search-prepend" />
      <VSpacer />
      <TextField
        v-model="searchString"
        clearable
        single-line
        hide-details
        prepend-icon="search"
        append-icon="refresh"
        class="search-field"
        :label="searchLabel"
        :disabled="isLoading"
        @keyup.enter="loadData"
        @click:append="() => loadData(true)"
      >
        <template slot="append-outer">
          <template v-if="enableGrid">
            <VBtnToggle
              v-model="viewMode"
              group
              class="no-elevation"
              style="margin-top: -6px"
              color="primary"
            >
              <VBtn
                small
                value="table"
                :text:raised="isViewMode('table')"
              >
                <VIcon icon="list">
                  view_list
                </VIcon>
              </VBtn>
              <VBtn
                small
                value="grid"
                :text:raised="isViewMode('grid')"
              >
                <VIcon icon="grid">
                  dashboard
                </VIcon>
              </VBtn>
            </VBtnToggle>
          </template>
          <slot
            name="filter-button"
            :toggleFilterDrawer="toggleFilterDrawer"
          >
            <VBtn
              v-if="hasFilterOptions && !disableFilter"
              text
              small
              class="mt-0"
              :disabled="isLoading"
              :color="hasFilter ? 'info' : 'default'"
              @click="filterDrawer = !filterDrawer"
            >
              <VIcon small>
                filter_list
              </VIcon>
            </VBtn>
          </slot>
        </template>
      </TextField>
      <slot
        name="action-right"
        :setViewMode="setViewMode"
        :loadData="loadData"
      />
    </VCardTitle>
    <VCardText
      v-if="hasFilter"
      class="layout row wrap justify-end py-1"
    >
      <CurrentFilter
        :filter="filter"
        :filter-values="filterValues"
        :is-loading="isLoading"
        :disable-filter="disableFilter"
        @removeFilter="onRemoveFilter"
        @removeStrictMode="onRemoveStrictMode"
        @resetFilter="onResetFilter"
      />
    </VCardText>
    <VCardText
      class="layout row justify-end pt-1 pb-0 pr-1"
      v-if="!hideRecordsInfo"
    >
      <VCol>
        <slot name="legend" />
      </VCol>
      <VSpacer />
      <VCol
        shrink
        class="text-no-wrap"
      >
        <VSkeletonLoader
          type="text"
          :loading="isLoading"
        >
          <template>
            <strong>{{ totalItems || 0 }}</strong>&nbsp;Results |
            Page
            <strong>{{ currentPage || 1 }}</strong> of
            <strong>{{ totalPages || 1 }}</strong>
          </template>
        </VSkeletonLoader>
      </VCol>
    </VCardText>
    <VDataTable
      v-if="isViewMode('table')"
      v-model="selectedItems"
      must-sort
      ref="dataViewTable"
      class="data-table no-elevation"
      :page.sync="internalCurrentPage"
      :search="filter.s"
      :expanded.sync="expanded"
      :loading="isLoading"
      :loading-text="''"
      :headers="fullHeaders"
      :items="items"
      :options.sync="options"
      :show-select="showSelect"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :disable-sort="disableSort"
      :server-items-length="itemsAreLocal ? -1 : totalItems"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems
      }"
      :hide-default-footer="hideActions"
      :hide-default-header="hideHeaders"
      :no-data-text="noDataText"
      :style="{
        width: 'calc(100% - 2px)',
        maxWidth: 'calc(100% - 2px)'
      }"
      @page-count="internalTotalPages = $event"
    >
      <VSkeletonLoader
        indeterminate
        slot="progress"
        class="mb-4 mt-1 ml-2 pr-4"
        type="table-tbody,table-tfoot"
        color="primary"
      />
      <!-- Dynamic slots to customize certain columns(header) -->
      <template
        v-for="(header) in fullHeaders"
        :slot="header.slotName"
      >
        <slot 
          :name="header.slotName" 
          :header="header"
        />
      </template>
      <template
        v-for="(filterHeader,filterHeaderIndex) in filterHeaders"
        :slot="`${filterHeader.slotName}`"
      >
        <FilterOption
          v-if="hasFilterOptions && filters.findIndex(f => f.field == filterHeader.value) > -1"
          v-model="filter.filter[filterHeader.value]"
          :key="filterHeaderIndex"
          :filter="filters.find(f => f.field == filterHeader.value)"
          :text="filterHeader.text"
          @filter="loadData(true)"
        />
        <template v-else>
          {{ filterHeader.text }}
        </template>
      </template>
      <template #item="props">
        <tr
          v-if="!isLoading"
          class="data-row"
          :style="allRowStyles(props.item, false, [1, 5])"
          :class="allRowClasses(props.item, false, [1, 5])"
        >
          <td 
            v-if="props.item.processingDuplicate"
            colspan="12"
            class="px-0"
          >
            <VOverlay
              absolute
              light
              class="dataViewUI-processing"
            >
              <VRow>
                <VCol 
                  cols="9" 
                  class="d-flex align-center"
                >
                  <div class="mx-4 my-2 text-body-1 font-weight-bold"> 
                    Your content is being duplicated.  Please check back soon.
                  </div>
                </VCol>
                <VCol 
                  cols="3"
                  class="d-flex align-center"
                >
                  <div
                    class="mx-4 my-2"
                    :style="{
                      background: `url(${ProgressImage})`,
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      height: '36px',
                      width: '100%',
                    }"
                  >
                    <VRow class="fill-height">
                      <VCol class="d-flex align-center px-8 py-0">
                        <span style="font-size: 14px; color: white; font-weight: 700;">
                          Working...
                        </span>
                        <VSpacer />
                        <VTooltip
                          top
                          content-class="progress-tooltip"
                        >
                          <template #activator="{ on }">
                            <VIcon 
                              v-on="on"
                              color="#FFFFFF"
                            >
                              mdi-information-outline
                            </VIcon>
                          </template>
                          <div style="font-size: 14px;">
                            <span style="font-weight: 700;">Just a sec..</span>
                            <br>
                            <span style="font-weight: 400;">
                              Duplication can take a few minutes.
                              <br>
                              Please check back soon.
                            </span>
                          </div>
                        </VTooltip>
                      </VCol>
                    </VRow>
                  </div>
                </VCol>
              </VRow>
            </VOverlay>
          </td>
          <template v-else>
            <td
              width="1px"
              class="expand-action"
              v-if="showExpand"
            >
              <VBtn
                icon
                text
                :loading="props.item.isLoading"
                @click="onExpand(props)"
              >
                <VIcon>{{ props.isExpanded ? 'expand_less' : 'expand_more' }}</VIcon>
              </VBtn>
            </td>
            <td v-if="showSelect">
              <VCheckbox
                v-if="!props.item.isLoading"
                v-model="selectedItems"
                :value="props.item"
              />
            </td>
            <slot
              name="items-cells"
              :item="props.item"
              :index="props.index"
              :selected="props.selected"
              :expanded="props.expanded"
              :is-expanded="props.isExpanded"
              :is-selected="props.isSelected"
            />
          </template>
        </tr>
      </template>
      <template
        v-if="expandableRows"
        #expanded-item="{headers: expandedHeaders, item: props }"
      >
        <tr>
          <td :colspan="expandedHeaders.length">
            <div
              v-if="(expandedHeaders[0] || {}).isShowExpand || props.isExpanded || props.expanded"
              class="expanded-dataview-section"
            >
              <slot
                name="expand"
                :item="props.item || props"
                :index="props.index || 0"
                :selected="props.selected || false"
                :expanded="props.expanded"
                :is-expanded="props.isExpanded"
              />
            </div>
          </td>
        </tr>
      </template>
      <template slot="footer">
        <slot name="footer" />
        <VRow
          v-show="isLoading"
          justify-center
          slot="progress"
          class="mb-4"
        >
          <VProgressLinear
            indeterminate
            color="primary"
          />
        </VRow>
      </template>
      <template slot="actions-append">
        <slot name="actions-append" />
      </template>
      <template slot="actions-prepend">
        <slot name="actions-prepend" />
      </template>
      <template slot="header">
        <slot name="header" />
      </template>
      <template slot="top">
        <slot name="top" />
      </template>
      <template
        v-if="enableInfiniteLoader && (!pagination.hasOwnProperty('pages') || pagination.page < pagination.pages)"
        slot="body.append"
      >
        <tr>
          <td :colspan="fullHeaders.length">
            <infinite-loading
              :identifier="new Date()"
              @infinite="($state) => $emit('infiniteLoad', $state, pagination)"
            />
          </td>
        </tr>
      </template>
    </VDataTable>
    <VSkeletonLoader
      v-else-if="isViewMode('grid')"
      indeterminate
      type="card"
      transition="scale-transition"
      color="primary"
      max-width="250px"
      :class="{
        'mb-4': !hideActions,
        'px-4': gridPadding
      }"
      :loading="isLoading"
    >
      <VRow
        row
        wrap
      >
        <VCol
          v-for="(item, key) in items"
          sm="6"
          :md="colMd"
          :lg="colLg"
          :key="item.id"
        >
          <VHover
            v-slot="{hover}"
            :key="`dynamic-${item.id}-${key}`"
            @click.stop
          >
            <slot
              name="card"
              :item="item"
              :index="key"
            >
              <VCard
                style="flex-direction: column; display: flex; justify-content: space-between"
                :height="cardHeight"
                :raised="hover"
                :color="allCardColors(item)"
                :class="allCardClasses(item, false)"
              >
                <VImg
                  v-if="item.image"
                  :src="item.image.url"
                />
                <VCardTitle
                  primary-title
                  style="flex: 0 1 64px !important;"
                  class="layout row pt-4 pb-2 mx-2 justify-space-between"
                >
                  <slot :item="item">
                    {{ item.title }}
                  </slot>
                </VCardTitle>
                <VCardText justify-between>
                  <slot
                    name="card-content"
                    :item="item"
                  />
                </VCardText>

                <VCardActions
                  v-if="!hideItemActions"
                  style="flex-direction: row; flex-wrap: wrap"
                >
                  <slot
                    name="card-actions"
                    :item="item"
                  />
                </VCardActions>
              </VCard>
            </slot>
          </VHover>
        </VCol>
        <slot name="extra-card" />
        <VCol 
          v-if="enableInfiniteLoader && (!pagination.hasOwnProperty('pages') || pagination.page < pagination.pages)"
          cols="12"
        >
          <infinite-loading
            :identifier="new Date()"
            @infinite="($state) => $emit('infiniteLoad', $state, pagination)"
          />
        </VCol>
        <VCol 
          v-if="!hideActions"
          cols="12"
        >
          <div class="text-right pt-2">
            <template slot="actions-prepend">
              <slot name="actions-prepend" />
            </template>
            <VPagination
              v-if="pagination.pages > 1"
              total-visible="7"
              :value="pagination.page"
              :length="pagination.pages"
              @input="updatePage"
            />
            <template slot="actions-append">
              <slot name="actions-append" />
            </template>
          </div>
        </VCol>
      </VRow>
    </VSkeletonLoader>

    <VSkeletonLoader
      v-else-if="isViewMode('slider')"
      indeterminate
      type="card"
      transition="scale-transition"
      color="primary"
      class="mb-4 px-4"
      max-width="250px"
      :loading="isLoading"
    >
      <VRow
        row
        wrap
      >
        <VCol cols="12">
          <slot
            name="content"
            :items="[data]"
          />
        </VCol>
        <VCol cols="12">
          <div class="text-right pt-2">
            <template slot="actions-prepend">
              <slot name="actions-prepend" />
            </template>
            <VPagination
              v-if="pagination.pages > 1"
              v-model="pagination.page"
              :length="pagination.pages"
              @input="updatePage"
            />
            <template slot="actions-append">
              <slot name="actions-append" />
            </template>
          </div>
        </VCol>
      </VRow>
    </VSkeletonLoader>

    <VNavigationDrawer
      v-model="filterDrawer"
      temporary
      clipped
      right
      fixed
      hide-overlay
      width="640px"
    >
      <slot name="filters">
        <FilterOptions
          v-model="filter"
          :filters="allFilters"
          @filter="loadData(true)"
          @toggle="onToggleFilterDrawer"
        />
      </slot>
    </VNavigationDrawer>
  </VCard>
</template>

<script>
import uuid from "uuid";
import ProgressImage from "@/images/progress-bar.gif";
import FilterOption from "./FilterOption";
import FilterOptions from "./FilterOptions";
import CanFilter from "@/mixins/CanFilter";
import HasPagination from "@/mixins/HasPagination";
import CurrentFilter from "./CurrentFilter";
import get from "sugar/object/get";
export default {
  name: "DataViewUI",
  props: {
    title: {
      type: String,
      default: "",
    },
    sortBy: {
      type: String,
      default: null,
    },
    defaultViewMode: {
      type: String,
      default: "table",
    },
    cardHeight: {
      type: String,
      default: "250px",
    },
    noDataText: {
      type: String,
      default: "No data available",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    sorter: {
      type: Function,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    enableGrid: {
      type: Boolean,
      default: false,
    },
    expandableRows: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideHeaders: {
      type: Boolean,
      default: false,
    },
    hideItemActions: {
      type: Boolean,
      default: false,
    },
    enableFilterHeaders: {
      type: Boolean,
      default: false,
    },
    rowStyle: {
      type: [Array, String, Object, Function],
      default: () => {},
    },
    rowClass: {
      type: [String, Array, Object, Function],
      default: () => "",
    },
    cardClass: {
      type: [String, Array, Object, Function],
      default: () => {},
    },
    cardColor: {
      type: [String, Function],
      default: () => {},
    },
    hideMainTitle: {
      type: Boolean,
      default: false,
    },
    hideRecordsInfo: {
      type: Boolean,
      default: false,
    },
    rowClasses: {
      type: Boolean,
      default: true,
    },
    gridPadding: {
      type: Boolean,
      default: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    searchLabel: {
      type: String,
      default: "Search",
    },
    enableInfiniteLoader: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: [String, Number],
      default: null,
    },
    md: {
      type: [String, Number],
      default: null,
    },
    backgroundColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
  mixins: [CanFilter, HasPagination],
  components: { FilterOptions, CurrentFilter, FilterOption },
  data() {
    return {
      ProgressImage,
      options: {},
      expanded: [],
      selectedItems: [],
      uuid: uuid,
      viewMode: "table",
      loading: true,
      searchString: null,
    };
  },
  watch: {
    options: {
      handler(value) {
        this.updatePagination(value);
      },
      deep: true,
    },
    isLoading: function (value) {
      if (!value) {
        this.$nextTick(this.setStickyPositioning);
      }
    },
    searchString: function (value) {
      if(!value) {
        this.loadData(true);
      }
    },
    selectedItems: function (value) {
      this.$emit("selected", value);
    },
    filterDrawer: function (value) {
      if(!this.filtersPersist && !value) {
        this.$set(this.filter, "filter", {});
      }
      if(this.filtersPersist && !value) {
        this.$set(this.filter, "filter", JSON.parse(JSON.stringify(this.tempFilter.filter)));
      }
    },
  },
  computed: {
    filterHeaders() {
      return this.enableFilterHeaders
        ? this.fullHeaders.map((h) => {
            return h;
          })
        : [];
    },
    fullHeaders() {
      let headers = this.headers;
      if (
        headers.findIndex((h) => h.text === " - ") === -1 &&
        !this.hideItemActions
      ) {
        headers.push({
          text: " - ",
          sortable: false,
          class: "white--text table-actions",
          width: "64px",
        });
      }
      if (this.showExpand && !headers[0].isShowExpand) {
        headers.unshift({
          text: "",
          sortable: false,
          width: "1px",
          class: "expand-action",
          isShowExpand: true,
        });
      }
      return headers.map((h) => {
        h.slotName = h.slotName || `header.${h.value || h.name || h.text}`;
        return h;
      });
    },
    items() {
      const items = this.data instanceof Object ? this.data.data || [] : [];
      const sorter =
        this.sorter instanceof Function
          ? this.sorter
          : this.sortBy
          ? (i) => get(i, this.sortBy)
          : null;
      return sorter instanceof Function
        ? items.sortBy(sorter, this.sortDesc)
        : items;
    },
    colLg() {
      if(this.lg) {
        return this.lg;
      }
      return this.$showLeftMainMenu && this.$isMenuExpanded ? 4 : 3;
    },
    colMd() {
      if(this.md) {
        return this.md;
      }
      return this.$showLeftMainMenu && this.$isMenuExpanded ? 6 : 4
    },
  },
  created() {
    this.loading = false;
    this.viewMode = this.defaultViewMode;
  },
  mounted() {
    this.$nextTick(this.setStickyPositioning);
  },
  methods: {
    onFilterData(force) {
      this.loadData(force);
    },
    loadData(force) {
      this.filter.s = this.searchString;
      if (
        (this.filter.s && this.filter.s.length >= 2) ||
        (force === true && !this.isLoading)
      ) {
        this.$emit("search", this.filter);
        this.tempFilter = JSON.parse(JSON.stringify(this.filter));
      }
    },
    setViewMode(mode) {
      this.viewMode = mode;
    },
    isViewMode(mode) {
      return this.viewMode === mode;
    },
    toggleFilterDrawer() {
      this.filterDrawer = !this.filterDrawer;
    },
    _addElevation(classes, hover, levels) {
      const realLevels = levels || [1, 12];
      if (classes instanceof Array) {
        return [
          ...classes,
          ...[`elevation-${hover ? realLevels[1] : realLevels[0]}`],
        ].unique();
      }
      if (classes instanceof Object) {
        return {
          ...classes,
          ...{
            [`elevation-${realLevels[1]}`]: hover,
            [`elevation-${realLevels[0]}`]: !hover,
          },
        };
      }
      return `${classes} elevation-${hover ? realLevels[1] : realLevels[0]}`;
    },
    allCardClasses(card, hover, levels) {
      return this._addElevation(
        this.getAllClassesOrStyles(this.cardClass, card),
        hover,
        levels
      );
    },
    allRowClasses(item, hover, levels) {
      if (this.rowClasses) {
        return this._addElevation(
          this.getAllClassesOrStyles(this.rowClass, item, "elevation-0"),
          hover,
          levels
        );
      }
      return [];
    },
    allRowStyles(item, hover, levels) {
      return this.getAllClassesOrStyles(
        this.rowStyle,
        item,
        "elevation-0",
        true
      );
    },
    allCardColors(card) {
      let colors = this.getAllClassesOrStyles(
        this.cardColor,
        card,
        "elevation-0"
      );
      if (colors instanceof Array) {
        return colors.join(" ");
      }
      if (colors instanceof Object) {
        return Object.keys(colors)
          .filter((c) => colors[c] === true)
          .join(" ");
      }
      return colors;
    },
    onExpand(props) {
      this.$set(props, "isExpanded", !props.isExpanded);
      props.expand(props.isExpanded);
      this.$emit("expand", props);
    },
    setStickyPositioning() {
      const items = document.querySelectorAll(`#${this.uid} .sticky`);
      items.forEach((item) => {
        let sibling = item;
        let width = 0;
        while (sibling.nextSibling instanceof HTMLElement) {
          width += sibling.nextSibling.clientWidth;
          sibling = sibling.nextSibling;
        }
        item.style.right = `${width}px`;
      });
    },
    onToggleFilterDrawer(_filterPersist) {
      if(!_filterPersist) {
        this.filtersPersist = false;
      } else {
        this.filtersPersist = true;
      }
      this.filterDrawer = !this.filterDrawer;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/variables.scss";

.v-datatable thead th.column.sortable {
  color: red;
}
// Show select header checkbox css
.v-data-table__checkbox.v-simple-checkbox > .v-input--selection-controls__input {
  border: solid thin #ddd !important;
  border-radius: 6px !important;
  .v-icon.mdi.mdi-checkbox-marked.theme--light, .v-icon.mdi.mdi-minus-box {
    background-color: #ffc015;
    color: #FFFFFF !important;
    caret-color: #FFFFFF !important;
    font-size: 120% !important;
  }
  .v-icon.mdi.mdi-checkbox-marked.theme--light::before {
    content: "\f012c";
  }
  .v-icon.mdi.mdi-minus-box::before {
    content: "\F0374";
  }
}

.data-table {
  margin: 0;
  width: 100%;
  object-fit: fill;

  .v-select__selection--comma {
    overflow: initial;
  }

  table {
    // border-collapse: collapse;
    thead {
      tr:is(:first-child) {
        border-bottom: solid 1px $border-color;
        height: 48px;
      }

      th.column {
        height: 14px;
        color: $content-title-color;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
        margin: 10px 0;
        position: relative;
      }

      th.column:not(:last-child):after {
        content: "";
        height: 40%; //You can change this if you want smaller/bigger borders
        width: 1px;

        position: absolute;
        right: 0;
        top: 30%; // If you want to set a smaller height and center it, change this value

        background-color: #eaeaea; // The color of your border
      }
    }

    tbody {
      // border-right: 20px solid transparent;
      // border-left: 20px solid transparent;
      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.02);
      }

      tr:not(:last-child) {
        border-bottom: none;
      }

      tr:not(.v-datatable__expand-row) {
        height: 64px;
      }

      tr {
        background-color: transparent;
        border-radius: 0;
        margin-bottom: 10px;

        &.data-row {
          td {
            white-space: nowrap;
          }
        }

        .actions {
          .view-detail {
            height: 18px;
            min-width: 16px;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            text-align: right;
            text-transform: uppercase;
            text-decoration: none;
            color: #fbba11;
            margin: 3px 3px 3px 0;
            &.inherit-color {
              color: inherit;
            }
          }
        }
      }

      tr.v-datatable__expand-row
        .v-datatable__expand-col
        .v-datatable__expand-content {
        box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75),
          inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75) !important;
        padding: 6px;
      }

      tr.v-datatable__expand-row {
        max-width: calc(100vw - 190px);
      }

      tr:hover:not(.v-datatable__expand-row),
      tr:hover {
        background-color: $white-color;
        .actions {
          .view-detail {
            visibility: visible;
          }
        }

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      }

      // tr:first-child td:first-child {
      //   border-top-left-radius: 10px;
      // }

      // tr:first-child td:last-child {
      //   border-top-right-radius: 10px;
      // }

      // tr:last-child td:first-child {
      //   border-bottom-left-radius: 10px;
      // }

      // tr:last-child td:last-child {
      //   border-bottom-right-radius: 10px;
      // }
    }
  }
}

.v-dialog__container {
  .v-table.v-datatable {
    tr.v-datatable__expand-row {
      .v-datatable__expand-col {
        .v-datatable__expand-content {
          max-width: calc(100vw - 48px);
          position: sticky;
          left: 0;
        }
      }
    }
  }
}

.dataViewUI-processing {
  .v-overlay__content {
    width: 100%;
  }
}

:root {
  .v-navigation-drawer.v-navigation-drawer--close {
    display: none;
  }
}
</style>