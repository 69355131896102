<template>
  <VContainer
    grid-list-lg
    fill-height
  >
    <VRow
      v-if="form"
      fill-height
    >
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>Result {{ featureName(contentType) }}</h1>
          <div>
            <VBtn
              class="wethrive"
              large
              :disabled="!isFormReady || isFormInputsDisabled"
              @click="onSave"
            >
              Save
              Changes
            </VBtn>
          </div>
        </VRow>
      </VCol>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            md="6"
          >
            <h3>System Score</h3>
            <span class="display-3">{{ parseInt(form.system_score) || 0 }} / 100</span>
          </VCol>
          <VCol
            cols="12"
            md="6"
          >
            <h3>Grade This {{ contentType.capitalize() }}</h3>
            <TextField
              v-model="form.score"
              sm="4"
              large
              filled
              :label="`Grade this ${contentType.toLowerCase()}`"
              type="number"
              append-inner-icon="arrow-down"
              :placeholder="`Final score`"
              height="72px"
              class="v-input-lg"
              @keypress="numericOnly"
            >
              <template slot="append">
                <div style="line-height: 56px; font-size: 36px">
                  /100
                </div>
              </template>
            </TextField>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="12">
        <VAlert
          type="info"
          :value="true"
        >
          <strong>This is an automatically calculated score</strong>
          <p>We can only automatically grade multiple and single choice questions</p>
        </VAlert>
      </VCol>
      <VCol
        v-if="hasCards"
        cols="12"
      >
        <h2 class="display-2">
          Result
        </h2>
        <VHover
          v-for="(resultCard, index) in cards"
          :key="`card-${index}`"
        >
          <VCard
            slot-scope="{ hover }"
            class="my-2"
            :class="`elevation-${hover ? 2 : 0}`"
            :style="{
              backgroundColor: !hasChoices(resultCard) ? getItemColor(resultCard.value ? resultCard.is_correct : null) : 'inherit'
            }"
          >
            <VCardTitle>
              {{ resultCard.card.name }}
              <template v-if="!hasChoices(resultCard)">
                <VSpacer />
                <VTooltip top>
                  <template #activator="{ on }">
                    <VBtn
                      v-on="on"
                      rounded
                      class="mt-2"
                      style="width: 48px; height: 48px; min-width: 48px"
                      :icon="$vuetify.breakpoint.smAndDown"
                      :color="resultCard.is_correct ? 'success' : 'disabled'"
                      @click="toggleCorrect(resultCard)"
                    >
                      <VIcon>check</VIcon>
                    </VBtn>
                  </template>
                  <span>Mark this answer as correct</span>
                </VTooltip>
              </template>
            </VCardTitle>
            <VCardText style="min-height: 64px">
              <template v-if="hasChoices(resultCard)">
                <VList>
                  <VListItem
                    v-for="(choice, choiceIndex) in resultCard.card.choices"
                    :key="`choice-${choiceIndex}`"
                    :style="{
                      backgroundColor: getItemChoiceColor(resultCard.value, choice)
                    }"
                    @click="false"
                  >
                    <VListItemAction>
                      <VIcon color="success">
                        {{ choice.is_correct_answer ? 'check' : '' }}
                      </VIcon>
                    </VListItemAction>
                    <VListItemContent>
                      <VListItemTitle>{{ choice.text }}</VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <VBtn
                        fab
                        text
                        small
                        rounded
                        :color="getChoiceColor(resultCard.value, choice)"
                      >
                        <VIcon>{{ getChoiceIcon(resultCard.value, choice) }}</VIcon>
                      </VBtn>
                    </VListItemAction>
                  </VListItem>
                </VList>
              </template>
              <p v-else>
                {{ resultCard.value }}
              </p>
            </VCardText>
          </VCard>
        </VHover>
      </VCol>
      <VCol v-if="mode === 'standalone'">
        <VRow
          row
          justify-center
        >
          <VBtn
            class="wethrive"
            large
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSave"
          >
            Save
            Changes
          </VBtn>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import FormMixin from "@/mixins/Form";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
export default {
  name: "AssessmentResultSave",
  components: {},
  mixins: [FormMixin, HasAssessmentsMixin],
  props: {
    property: {
      type: String,
      default: "",
    },
    entity: {
      type: Object,
      default: () => {},
    },
    assessment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modelType: "assessmentResult",
      options: {},
      defaultForm: {
        user: {},
        result_cards_for_user: [],
      },
    };
  },
  computed: {
    hasCards() {
      return this.cards.length > 0;
    },
    cards() {
      return this.form.result_cards_for_user instanceof Array
        ? this.form.result_cards_for_user
            .filter(
              (result) =>
                [
                  "Question",
                  "Text",
                  "TextArea",
                  "Textarea",
                  "Worksheet",
                ].indexOf(result.card && (result.card || {}).type) > -1
            )
            .sortBy("card.priority")
        : [];
    },
  },
  validations: {
    form: {
      // program_id: {
      //     required: requiredIf(function (form) {
      //         return !form.primary_content_id && !form.supplement_id;
      //     })
      // },
      // primary_content_id: {
      //     required: requiredIf(function (form) {
      //         return !form.program_id && !form.supplement_id;
      //     })
      // },
      // supplement_id: {
      //     required: requiredIf(function (form) {
      //         return !form.primary_content_id && !form.program_id;
      //     })
      // },
      // entity_id: {
      //   required
      // }
    },
  },
  methods: {
    ...mapActions({
      doSaveAssessmentResult: "assessmentResult/save",
      doGetAssessmentResult: "assessmentResult/getOne",
    }),
    onGet(id) {
      const params = {
        assessment_id: this.assessment.id,
        id,
      };
      return this.doGetAssessmentResult(params);
    },
    onSave() {
      this.form.id = this.form.id;
      this.form.assessment_id = this.form.entity_id;
      delete this.form.current_card;
      delete this.form.entity;
      delete this.form.user;
      return this.saveFormModel(this.doSaveAssessmentResult).then(() => {
        this.form.entity_type = this.entityType || this.form.entity_type;
      });
    },
    toggleCorrect(result) {
      const index = this.form.result_cards_for_user.findIndex(
        (card) => card.id == result.id
      );
      if (index > -1) {
        this.$set(result, "is_correct", !result.is_correct);
        this.$log.debug("[SubmitForm]: Set result is correct", result);
      } else {
        this.$log.debug("[SubmitForm]: Cant find result", result);
      }
    },
    hasChoices(resultCard) {
      return (
        resultCard.card.type === "Question" &&
        resultCard.card.choices instanceof Array
      );
    },
    isCorrectChoice(value, choice) {
      return choice.is_correct_answer && this.hasChoice(value, choice);
    },
    hasChoice(value, choice) {
      return value instanceof Array
        ? value.indexOf(choice.id) > -1
        : value === choice.id;
    },
    getChoiceColor(value, choice) {
      if (this.hasChoice(value, choice)) {
        return this.isCorrectChoice(value, choice) ? "success" : "error";
      } else {
        return "default";
      }
    },
    getItemChoiceColor(value, choice) {
      if (this.hasChoice(value, choice)) {
        return this.isCorrectChoice(value, choice)
          ? "rgba(0, 255, 0, 0.05)"
          : "rgba(255, 0, 0, 0.05)";
      } else {
        return "default";
      }
    },
    getItemColor(value) {
      if (value !== null) {
        return value === true
          ? "rgba(0, 255, 0, 0.05)"
          : "rgba(255, 0, 0, 0.05)";
      } else {
        return "default";
      }
    },
    getChoiceIcon(value, choice) {
      if (this.hasChoice(value, choice)) {
        return this.isCorrectChoice(value, choice) ? "check_circle" : "cancel";
      } else {
        return "remove";
      }
    },
  },
};
</script>

