import BaseApi from "./_base-api";

/**
 * Activity API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Activity",
	path: "activity",
});

export default api;
