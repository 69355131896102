<template>
  <VChip
    v-if="isBeta"
    label
    small
    class="ml-1 my-0 justify-start beta-label"
    :color="color"
    :text-color="textColor"
  >
    <slot>{{ label }}</slot>
  </VChip>
</template>

<script>
export default {
  name: "BetaLabel",
  props: {
    label: {
      type: String,
      default: "new",
    },
    color: {
      type: String,
      default: "secondary",
    },
    textColor: {
      type: String,
      default: "white",
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.v-chip.beta-label {
  height: 14px;
  .v-chip__content {
    height: 12px;
    padding: 0 4px;
    font-size: 10px;
    text-transform: lowercase;
  }
}
</style>