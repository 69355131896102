var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chips-list"},[(_vm.normalList)?[_vm._l((_vm.items),function(item,key){return _c('VChip',{key:key,staticClass:"small-chip mb-3 mr-3",attrs:{"close":_vm.closable},on:{"click:close":function($event){return _vm.$emit('removeItem', item.id, _vm.modelType)}}},[(_vm.showAvatar)?_c('VAvatar',{staticClass:"mr-2",staticStyle:{"border-radius":"4px"},attrs:{"color":"#bfbfbf","height":"18px !important","width":"18px !important","min-width":"18px !important"}},[_c('img',{staticStyle:{"height":"20px","width":"20px"},attrs:{"src":require("@/images/doc-icon.png")}})]):_vm._e(),_c('span',{staticClass:"text-truncate",staticStyle:{"color":"#252526","font-weight":"500"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),(_vm.showMoreCount > 0)?_c('VChip',{staticClass:"mb-2 white--text",staticStyle:{"border-radius":"10px"},attrs:{"color":"#fdbf14"}},[_c('strong',[_vm._v("+"+_vm._s(_vm.showMoreCount))])]):_vm._e(),(_vm.enableAction)?_c('VBtn',{staticClass:"small-chip add-chip mb-3",attrs:{"height":"32px"},on:{"click":function($event){return _vm.$emit('addItems', _vm.modelType)}}},[_c('span',{staticStyle:{"color":"#808080"}},[_vm._v(_vm._s(_vm.addChipTitle))]),_c('VIcon',{attrs:{"right":"","color":"#757575","size":"18px"}},[_vm._v(" mdi-plus-circle ")])],1):_vm._e()]:_c('VRow',[_vm._l((_vm.items),function(item,key){return _c('VCol',{key:key,staticClass:"pt-0",attrs:{"cols":_vm.cols}},[_c('VChip',{class:{
          'big-chip': true,
          'chip-close-alt': _vm.chipCloseAlt,
        },attrs:{"large":"","close":_vm.closable,"close-icon":item.deleted ? 'mdi-restore' : 'mdi-close-circle'},on:{"click:close":function($event){return _vm.$emit('removeItem', _vm.chipCloseAlt ? item : item.id, _vm.modelType)}}},[(item.hasOwnProperty('photo_url'))?_c('VAvatar',{attrs:{"size":"24px"}},[_c('img',{attrs:{"src":item.photo_url}})]):_c('VAvatar',{staticStyle:{"border-radius":"8px"},attrs:{"color":"#737adf","height":"40px !important","width":"40px !important","min-width":"40px !important"}},[_c('img',{staticStyle:{"height":"20px","width":"20px"},attrs:{"src":require("@/images/doc-icon.png")}})]),_c('span',{class:{
            'px-3 text-truncate': true,
            'mr-3': _vm.closable,
          },style:(`color: ${item.deleted ? 'red' : '#252526'}; text-decoration: ${item.deleted ? 'line-through' : 'none'}; ${_vm.itemTitleStyle}`)},[_vm._v(" "+_vm._s(_vm.$user.id == item.id && _vm.chipCloseAlt ? "You" : item.title)+" ")])],1)],1)}),(_vm.enableAction)?_c('VCol',{staticClass:"py-0",attrs:{"cols":_vm.cols}},[_c('VBtn',{class:{
          'big-chip add-chip': true,
          'chip-close-alt': _vm.chipCloseAlt,
        },attrs:{"large":"","height":"54px"},on:{"click":function($event){return _vm.$emit('addItems', _vm.modelType)}}},[_c('VIcon',{attrs:{"left":"","color":"#757575","size":"22px"}},[_vm._v(" mdi-plus-circle ")]),_c('span',{staticStyle:{"color":"#808080","font-weight":"900","font-size":"18px"}},[_vm._v(_vm._s(_vm.addChipTitle))])],1)],1):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }