<template>
  <VCard id="user-import-card">
    <VCardTitle>
      <VRow
        row
        wrap
        align-center
      >
        <VCol cols="12">
          <VAlert
            type="warning"
            :value="$route.query.pipeline_id > 0"
          >
            You have limited this import to a specific {{ featureName('pipeline').singularize() }}. To remove this <VBtn
              small
              depressed
              color="info"
              @click="onRemovePipelineFilter"
            >
              Click Here
            </VBtn>
          </VAlert>
          <Autocomplete
            v-model="selectedPipelineId"
            filled
            item-text="title"
            item-value="id"
            append-inner-icon="arrow-down"
            placeholder="Submit for..."
            label="Select Pipeline"
            :loading="activity.isOptionsLoading"
            :items="options.pipelines"
            :multiple="false"
          >
            <template #append-outer>
              <Tooltip
                icon="save_alt"
                button-text="Template"
                :text="false"
                :rounded="false"
                :disabled="!selectedPipelineId"
                :color="!selectedPipelineHasMetadata ? 'warning' : 'info lighten-1 elevation-0 block'"
                :title="!selectedPipelineHasMetadata ? 'No metadata available' : 'Download the CSV template'"
                :action="downloadSampleForPipeline"
              />
            </template>
          </Autocomplete>
        </VCol>
        <!-- <template v-if="canBeAttached">
          <VCol cols="12">
            <Autocomplete
              v-model="selectedEntityType" filled clearable
              item-text="title"
              item-value="id"
              append-inner-icon="arrow-down"
              placeholder="Attach to..."
              label="Select type"
              :items="entityTypes"
              :multiple="false"
            />
          </VCol>
          <VCol
            v-if="selectedEntityType"
            cols="12"
          >
            <Autocomplete
              v-model="selectedEntityId" filled clearable
              item-text="title"
              item-value="id"
              append-inner-icon="arrow-down"
              placeholder="Select item"
              :items="entityOptions"
              :label="selectedEntityTitle"
              :multiple="false"
              :filter-options="{
                type: 'user'
              }"
              :getter="currentEntity.getter"
            />
          </VCol>
          <VCol
            v-if="selectedEntityId"
            cols="12"
          >
            <Autocomplete
              v-model="selectedCalendarEntryId"
              :items="instanceOptions"
              item-text="date"
              item-value="id"
              append-inner-icon="arrow-down"
              placeholder="(Optional) Select instance"
              label="(Optional) Select instance"
            />
          </VCol>
        </template> -->
        <template v-if="!selectedPipelineId">
          <VCol cols="12">
            <VAlert
              type="warning"
              :value="true"
            >
              Please select a(an) {{ featureName('Pipeline') }} above.
            </VAlert>
          </VCol>
        </template>
        <VCol
          cols="12"
          md="4"
        >
          Import
        </VCol>
        <VCol justify-self-end>
          <FileUpload
            ref="fileUpload"
            label="Select csv file"
            accept="text/csv"
            :disabled="!selectedPipelineId"
            @files="handleFile"
          />
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText>
      <VAlert
        :value="true"
        color="warning"
        v-if="hasPrepared && !hasPreparedData && !hasSavedData"
      >
        Whoops! You have uploaded a file but there is no data to submit!
      </VAlert>
      <VAlert
        :value="true"
        color="info"
        v-else-if="!hasFile && !hasPrepared"
      >
        <div class="title">
          Upload a CSV file
        </div>
        <VAlert
          :value="true"
          type="warning"
        >
          <h3>Format</h3>
          <p>Please make sure your file is a plain text CSV file and is not endcoded in UTF8 or any other encoding.</p>

          <p>To make sure your CSV has the right encoding. Open it in Notepad (Windows) or TextEdit (Mac) and save it again as a plain csv file.</p>

          <h3>How we determine users</h3>
          <p>If you need to attach submitted data to a {{ featureName('user').singularize().toLowerCase() }} on the platform you will want to make sure the first column has the unique {{ featureName('user').singularize().toLowerCase() }} value. This can be a <strong>name, email or user id</strong>.</p>

          <h3>How we parse and format headers</h3>
          <p>Each header represents a field for the selected {{ featureName('pipeline').singularize().toLowerCase() }}. Be sure to copy and paste the field name properly otherwise a new field will be added.</p>
          <!-- <h4>Headers Optional Configuration</h4>
          <p>
            You may specify the type for a field by prefixing the header with one of the following:<br>
            <ul>
              <li>(Text)</li>
              <li>(Textarea)</li>
              <li>(File)</li>
              <li>(Number)</li>
              <li>(Dropdown)</li>
              <li>(Checkbox)</li>
              <li>(Radio)</li>
              <li>(Video_Link)</li>
            </ul>
          </p>
          <p>
            <strong>You can also add a section for the metadata field like so: (TYPE|SECTION).</strong><br>

            Example:
            <section style="padding:10px; background-color: #eee; color: darkred; font-family: 'Courier New',serif; font-size: 12px; margin: 6px">
              (Text) What is your name?, (Number) How old are you?, (Textarea|Section 1) Describe your personality<br>
              John Doe, 22, I'm cool<br>
              Jane Doe, 25, I'm also cool
            </section>
          </p> -->
          <!-- <h3>How we parse values for each entry</h3>
          <p>
            We will automatically determine the basic type of the field based ont eh actual value if you do not set a type. Automatic transformation is done for:<br>
            <ul>
              <li>Short Text: Value shorter than 140 characters</li>
              <li>Long Text: Value longer than 140 characters</li>
              <li>Url: Becomes a File and we will attempt to download the file</li>
              <li>Number: Any number value</li>
              <li>Dropdown: Values separated by a bar: One|Two|Three </li>
              <li>Time: Any time value. i.e.: 2:00, 3AM, ...etc</li>
              <li>Date: Any date value. i.e.: 2002/05/27 12AM, ...etc</li>
            </ul>
          </p> -->
        </VAlert>
        <VBtn
          class="ml-0"
          color="info lighten-1 elevation-0 block"
          @click="downloadSample"
        >
          <VIcon>save_alt</VIcon>
          <span>&nbsp;Click here to download a sample file&nbsp;</span>
        </VBtn>
      </VAlert>
    </VCardText>
    <VCardText>
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VAlert
        :value="hasSavedData && !hasErrors"
        color="success"
      >
        Successfully imported data!
        <VBtn
          large
          color="primary"
          class="my-0"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset"
        >
          Start Over
        </VBtn>
      </VAlert>
      <VRow
        v-if="activity.isLoading"
        row
        fluid
        justify-center
        class="mt-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
      <template v-else-if="!hasSavedData && !isSavedDataQueued && hasPreparedData">
        <DataViewUI
          items-are-local
          hide-item-actions
          expandable-rows
          show-expand
          id="prepared-submitted-data"
          :headers="allHeaders"
          :data="prepared"
          :row-style="{
            height: '112px'
          }"
          @search="onSearch"
        >
          <template #search-prepend>
            <VSelect
              v-model="uniqueKey"
              dense
              item-text="text"
              item-value="id"
              append-inner-icon="arrow-down"
              label="Unique Key"
              placeholder="Select Unique Key..."
              :items="metadataHeaders"
            >
              <template
                slot="prepend"
                style="margin-top: 0px"
              >
                <VBtn
                  depressed
                  color="warning"
                  class="my-0"
                  :block="$vuetify.breakpoint.smAndDown"
                  :disabled="$v.invalid || activity.isFormLoading"
                  @click="onReset"
                >
                  Start Over
                </VBtn>
              </template>
            </VSelect>
          </template>
          <template #items-cells="props">
            <td>{{ getUniqueKeyValue(props.item) }}</td>
            <td v-if="!allHeaders[1].hidden">
              {{ selectedEntityTitle }}
            </td>
            <td v-if="!allHeaders[2].hidden">
              {{ selectedEntityOptionTitle }}
            </td>
            <td v-if="!allHeaders[3].hidden">
              {{ selectedCalendarEntryTitle }}
            </td>
          </template>
          <template #expand="props">
            <VCard flat>
              <VCardText>
                <VAlert
                  v-if="!props.item.metadata || props.item.metadata instanceof Array && props.item.metadata.length == 0"
                  type="info"
                  :value="true"
                >
                  No metadata found
                </VAlert>
                <DynamicInputsView
                  v-else
                  :inputs="props.item.metadata"
                  :readonly="true"
                  :card="false"
                  :input-name="featureName('Report')"
                />
              </VCardText>
            </VCard>
          </template>
          <template #top>
            <VRow
              row
              py-2
              style="width: 100%"
              class="my-2"
              :justify-space-between="$vuetify.breakpoint.mdAndUp"
              :justify-center="$vuetify.breakpoint.smAndDown"
            >
              <VBtn
                class="mr-2"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onReset"
              >
                Reset
              </VBtn>
              <VBtn
                class="wethrive"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onSaveSubmitted"
              >
                Import
              </VBtn>
            </VRow>
          </template>
          <template #footer>
            <VRow
              row
              py-2
              style="width: 100%"
              class="my-2"
              :justify-space-between="$vuetify.breakpoint.mdAndUp"
              :justify-center="$vuetify.breakpoint.smAndDown"
            >
              <VBtn
                class="mr-2"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onReset"
              >
                Reset
              </VBtn>
              <VBtn
                class="wethrive"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onSaveSubmitted"
              >
                Import
              </VBtn>
            </VRow>
          </template>
        </DataViewUI>
      </template>
      <VAlert
        v-if="isSavedDataQueued"
        prominent
        type="info"
      >
        <h3>
          Your request is running in the background. You'll receive a notification once it's done. If you don't see the export please check your Spam folders. If you still don't receive the export in 15 mins, please contact support.
        </h3>
        <p>{{ saved.data.message }}</p>
        <hr>
        <VSpacer />
        <VBtn
          color="success"
          class="mt-3"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset"
        >
          Start New Import
        </VBtn>
      </VAlert>
      <DataViewUI
        v-else-if="hasSavedData"
        expandable-rows
        show-expand
        items-are-local
        hide-item-actions
        id="saved-submitted-data"
        :headers="allHeaders"
        :data="saved"
        :row-style="{
          height: '112px'
        }"
        @search="onSearch"
      >
        <template #items-cells="props">
          <td>{{ getUniqueKeyValue(props.item) }}</td>
          <td v-if="allHeaders[1].hidden !== true">
            {{ selectedEntityTitle }}
          </td>
          <td v-if="allHeaders[2].hidden !== true">
            {{ (props.item.entity || {}).title || 'None' }}
          </td>
          <td v-if="allHeaders[3].hidden !== true">
            {{ (props.item.calendarEntry || {}).title || 'None' }}
          </td>
          <td>
            <VTooltip top>
              <template #activator="{on}">
                <VBtn
                  text
                  icon
                  v-on="on"
                >
                  <VIcon :color="props.item.import_result ? 'success': 'error'">
                    {{ props.item.import_result ? 'done' : 'error' }}
                  </VIcon>
                </VBtn>
              </template>
              <span v-html="props.item.import_message" />
            </VTooltip>
          </td>
        </template>
        <template #expand="props">
          <VCard flat>
            <VCardText>
              <VAlert
                v-if="!props.item.metadata || props.item.metadata instanceof Array && props.item.metadata.length == 0"
                type="info"
                :value="true"
              >
                No metadata found
              </VAlert>
              <DynamicInputsView
                v-else
                :inputs="props.item.metadata"
                :readonly="true"
                :card="false"
                :input-name="featureName('Report')"
              />
            </VCardText>
          </VCard>
        </template>
      </DataViewUI>
      <Confirm ref="confirmReset" />
    </VCardText>
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ImportsData from "@/mixins/ImportsData";

export default {
  name: "ImportSubmitted",
  mixins: [ImportsData],
  components: { DynamicInputsView },
  data() {
    return {
      selectedPipelineId: null,
      selectedCalendarEntryId: null,
      selectedEntityType: null,
      selectedEntityId: null,
      options: {
        pipelines: [],
        programs: [],
        supplements: [],
        primary_contents: [],
        pipelines: [],
        users: [],
      },
      headers: [
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "selectedEntityTitle",
          hidden: true,
        },
        {
          text: "For",
          align: "left",
          sortable: false,
          value: "selectedInstanceOption",
          hidden: true,
        },
        {
          text: "Instance",
          align: "left",
          sortable: false,
          value: "selectedCalendarEntryTItle",
          hidden: true,
        },
      ],
      types: [
        {
          id: "App\\Models\\Program",
          title: this.featureName("Program"),
          slug: "programs",
          getter: (params) => this.doGetPrograms(params),
        },
        // {
        //   id: "App\\Models\\Report",
        //   title: this.featureName("Report"),
        //   slug: "reports"
        // },
        {
          id: "App\\Models\\Supplement",
          title: this.featureName("Supplement"),
          slug: "supplements",
          getter: (params) => this.doGetSupplements(params),
        },
        {
          id: "App\\Models\\PrimaryContent",
          title: this.featureName("Content"),
          slug: "primary_contents",
          getter: (params) => this.doGetContent(params),
        },
        {
          id: "App\\Models\\User",
          title: this.featureName("User"),
          slug: "users",
          getter: (params) => this.doGetUsers(params),
        },
        {
          id: "App\\Models\\Pipeline",
          title: this.featureName("Pipeline"),
          slug: "pipelines",
          getter: (params) => this.doGetPipelines(params),
        },
        {
          id: "App\\Models\\PipelineSubmitted",
          title: `${this.featureName("Pipeline")} ${this.featureName(
            "Application"
          )}`,
          slug: "pipelines_submitted",
          getter: (params) => this.doGetPipelinesSubmitted(params),
        },
      ],
    };
  },
  computed: {
    pipeline() {
      return (
        this.options.pipelines.find((d) => d.id == this.selectedPipelineId) ||
        {}
      );
    },
    pipelineHasAssociations() {
      return (
        this.pipeline.is_locked === true && this.pipelineEntityTypes.length > 0
      );
    },
    entityTypes() {
      return this.pipelineHasAssociations
        ? this.pipelineEntityTypes
        : this.types;
    },
    pipelineEntityTypes() {
      return this.types.filter(
        (type) =>
          this.pipeline.hasOwnProperty(type.slug) &&
          this.pipeline[type.slug].length > 0
      );
    },
    entityOptions() {
      if (this.pipelineHasAssociations) {
        return this.selectedEntityType !== null &&
          this.pipeline[this.currentEntitySlug]
          ? this.pipeline[this.currentEntitySlug]
          : [];
      } else {
        return this.selectedEntityType !== null &&
          this.options[this.currentEntitySlug]
          ? this.options[this.currentEntitySlug]
          : [];
      }
    },
    currentEntity() {
      const entityTypes = this.entityTypes || [];
      const entity = this.selectedEntityType
        ? entityTypes.find((entity) => entity.id === this.selectedEntityType)
        : {};
      return entity || {};
    },
    currentEntitySlug() {
      return this.currentEntity.slug;
    },
    instanceOptions() {
      const item =
        this.entityOptions instanceof Array
          ? this.entityOptions.find(
              (entity) => entity.id === this.selectedEntityId
            )
          : {};
      return item ? item.instances : [];
    },
    existingMembers() {
      return this.form.members
        ? this.form.members.map((mentor) => mentor.id)
        : [];
    },
    selectedEntity() {
      return this.selectedEntityType !== null &&
        this.entityTypes instanceof Array
        ? this.entityTypes.find(
            (entity) => entity.id === this.selectedEntityType
          ) || {}
        : {};
    },
    selectedEntityOption() {
      return this.selectedEntityId !== null &&
        this.entityOptions instanceof Array
        ? this.entityOptions.find(
            (entity) => entity.id === this.selectedEntityId
          ) || {}
        : {};
    },
    selectedCalendarEntry() {
      return this.selectedCalendarEntryId !== null &&
        this.instanceOptions instanceof Array
        ? this.instanceOptions.find(
            (entity) => entity.id === this.selectedCalendarEntryId
          ) || {}
        : {};
    },
    selectedEntityTitle() {
      return this.selectedEntity.title || "None";
    },
    selectedEntityOptionTitle() {
      return this.selectedEntityOption.title || "None";
    },
    selectedCalendarEntryTitle() {
      return this.selectedCalendarEntry.title || "None";
    },
    canBeAttached() {
      for (const i in this.entityTypes) {
        const entity = this.entityTypes[i];
        if (!entity instanceof Object) {
          return false;
        }
        const source = this.pipelineHasAssociations
          ? this.pipeline
          : this.options;
        if (
          source.hasOwnProperty(entity.slug) &&
          source[entity.slug] instanceof Array
        ) {
          return source[entity.slug].length > 0;
        }
      }
      return false;
    },
    selectedPipelineHasMetadata() {
      return this.selectedPipelineId
        ? (this.pipeline.metadata || []).length > 0
        : false;
    },
  },
  watch: {
    "$route.query.pipeline_id": function (value) {
      if (value) {
        this.onReset(true, true);
        this.selectedPipelineId = value;
      }
    },
  },
  validations: {
    prepared: {
      data: {
        $each: {
          pipeline_id: {
            required,
          },
          metadata: {
            $each: {
              name: required,
            },
          },
        },
      },
    },
  },
  mounted() {
    this.onGetPipelines();
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineSubmitted/getFormConfig",
      doSavePipelinesSubmitted: "pipelineSubmitted/import",
      doGetUsers: "users/getAll",
      doGetPipelines: "pipeline/getAll",
      doGetPipelines: "pipelines/getAll",
      doGetPipelinesSubmitted: "pipelineSubmitted/getAll",
      doGetPrograms: "program/getAll",
      doGetSupplements: "supplement/getAll",
      doGetContents: "content/getAll",
    }),
    onSaveSubmitted() {
      return this.onImportData(() =>
        this.saveFormModel(
          this.doSavePipelinesSubmitted,
          null,
          this.prepared.data
        )
      );
    },
    handleFile(files) {
      const file = this.onHandleFileStart(files);
      const self = this;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        // step: function(result) {
        //   self.$log.debug("[Import Submitted]: Import progress", result);
        //   if (result.errors && result.errors.length) {
        //     self.error = result.errors
        //       .map(error => error.message)
        //       .join("<br/>");
        //   } else {
        //     self.message = `Parsed ${result.data.count} items`;
        //   }
        // },
        complete(result) {
          self.onHandleFileComplete(result, () => {
            self.prepared.data = result.data.map((item) => {
              let normalized = {
                id: "submited-" + window.performance.now(),
                entity_id:
                  item.entity_id ||
                  self.selectedEntityId ||
                  item[self.uniqueKey],
                entity_type:
                  item.entity_type || self.selectedEntityType || "AppUser",
                pipeline_id: item.pipeline_id || self.selectedPipelineId,
                calendar_entry_id:
                  item.calendar_entry_id || self.selectedCalendarEntryId,
                submitted_by: item.submitted_by || self.$user.id,
                metadata: [],
              };
              Object.keys(item).map((v, k) => {
                normalized.metadata.push({
                  id: "metadata-" + window.performance.now(),
                  name: v,
                  value: item[v],
                });
              });
              return normalized;
            });
          });
        },
      });
    },
    downloadSample() {
      var blob = new Blob(
        [
          Papa.unparse({
            fields: [
              "Stage",
              "Status",
              "(Text|Section 2)What is the name of the user?",
              "(Text)How old is the user?",
              "What is the name of their company?",
            ],
            data: [
              [
                "First Stage",
                "In Progress",
                "Jane Doe",
                "22",
                "Awesome Company 1",
              ],
              [
                "First Stage",
                "Complete",
                "John Doe",
                "17",
                "Awesome Company 2",
              ],
              [
                "First Stage",
                "In Progress",
                "Landra Doe",
                "25",
                "Awesome Company 3",
              ],
            ],
          }),
        ],
        {
          type: "text/csv;charset=utf-8",
        }
      );
      saveAs(blob, "import-submitted-pipelines.csv");
    },
    downloadSampleForPipeline() {
      if (
        !this.selectedPipelineId ||
        (this.pipeline &&
          (!this.pipeline.metadata ||
            (this.pipeline.metadata instanceof Array &&
              !this.pipeline.metadata.length)))
      ) {
        this.$bus.$emit(
          "notificationError",
          "No metadata for the selected " +
            this.featureName("pipeline").toLowerCase().singularize() +
            ". Please add metadata fields."
        );
        return;
      }
      var blob = new Blob(
        [
          Papa.unparse({
            fields: this.pipeline.metadata.map((m) => m.name),
            data: [[]],
          }),
        ],
        {
          type: "text/csv;charset=utf-8",
        }
      );
      saveAs(
        blob,
        "import-submitted-" + this.pipeline.title.slugify() + ".csv"
      );
    },
    onGetPipelines() {
      this.$emit("doneLoading");
      this.$emit("startOptionsLoading");
      this.doGetPipelines({
        _withCustom: true,
        filter: {
          id: this.$route.query.pipeline_id,
        },
      }).then((result) => {
        this.options.pipelines = result.data;
        this.$emit("doneOptionsLoading");
        if (this.$route.query.pipeline_id) {
          this.selectedPipelineId = this.$route.query.pipeline_id;
        }
      });
    },
    onRemovePipelineFilter() {
      delete this.$route.query.pipeline_id;
      this.onGetPipelines();
    },
  },
};
</script>