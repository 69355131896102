<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <VRow
        row
        wrap
      >
        <VCol>
          <DataViewUI
            :data="localData"
            :headers="headers"
            :is-loading="activity.isLoading"
            @paginate="onGetMore"
            @search="onSearch"
          >
            <template
              slot="items-cells"
              slot-scope="props"
            >
              <td>
                <UserModal :user="getUser(props.item.user_id)" />
              </td>
              <td>${{ props.item.amount }}</td>
              <td>
                <VChip
                  text-color="white"
                  :color="getStatusChipColor(props.item.status)"
                >
                  {{ (props.item.status || '').titleize() }}
                </VChip>
              </td>
              <td>{{ props.item.stripe_transaction_id }}</td>
              <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
            </template>
          </DataViewUI>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
export default {
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
  },
  mixins: [SearchMixin],
  data() {
    return {
      localData: null,
      users: null,
    }
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "Purchased By",
          align: "left",
          sortable: true,
          value: "user.name",
        },
        {
          text: "Amount",
          align: "left",
          sortable: true,
          value: "amount",
        },
        {
          text: "Payment Status",
          align: "left",
          sortable: true,
          value: "status",
        },
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Purchased On",
          align: "left",
          sortable: true,
          value: "created_at",
        }
      ].filter((i) => i.hidden !== true);
      return headers;
    },
  },
  mounted() {
    // Set per page larger to get all users 
    let params = {
      perPage: 1000000,
    };
    this.doGetUsers(params).then((result) => {
      this.users = result.data;
    })
  },
  methods: {
    ...mapActions({
      doGetTransactions: "productTransaction/getAll",
      doGetUsers: "users/getAll",
    }),
    onGetAll(params) {
      params.productId = this.productId;
      return this.doGetTransactions(params).then((result) => {
        this.localData = result;
      });
    },
    getStatusChipColor(status) {
      switch (status) {
        case "success":
          return "success";
        case "failed":
          return "warning";
        default:
          return "info";
      }
    },
    getUser(userId) {
      return this.users.find((user) => user.id == userId);
    },
  },
}
</script>