import { Api as BaseApi } from "./deliverable";

/**
 * Assessment API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Assessment",
	path: "assessments",
});

export default api;
export { Api };
