var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VDivider'),_c('VList',{staticClass:"pt-2"},[((_vm.$isUserAdmin || _vm.$isUserMentor || !_vm.$viewProgramAsStudent) && !_vm.previewOnly)?_c('VListItem',{attrs:{"to":{
        name: 'present.view',
        params: {
          team: _vm.$team.slug,
          type: _vm.item.type,
          id: _vm.item.id,
          isBackToDefault: _vm.isBackToDefault,
        },
      }}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" mdi-eye ")]),_c('VListItemTitle',[_vm._v("View")])],1):_vm._e(),(_vm.fromProgram && !_vm.previewOnly)?_c('VListItem',{on:{"click":function($event){_vm.copyTextToClipboard(_vm.getFullyQualifiedUrlForItem(`present.view`, {
        params: {
          team: `${_vm.$team.slug}`,
          type: _vm.item.type,
          id: _vm.item.id,
        },
      }))}}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" link ")]),_c('VListItemTitle',[_vm._v("Copy Link")])],1):_vm._e(),(_vm.hasAccess && !_vm.previewOnly)?_c('VListItem',{attrs:{"to":{
        name: 'lesson.update',
        params: {
          team: `${_vm.$team.slug}`,
          id: _vm.item.id,
          isBackToDefault: _vm.isBackToDefault,
        },
      }}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" mdi-square-edit-outline ")]),_c('VListItemTitle',[_vm._v("Edit")])],1):_vm._e(),(_vm.$isUserAdmin && _vm.$viewProgramAsAdmin && !_vm.previewOnly && _vm.fromProgram)?_c('VListItem',{on:{"click":function($event){return _vm.$bus.$emit('renameSectionItem', 'Lesson', _vm.item)}}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" mdi-rename-box ")]),_c('VListItemTitle',[_vm._v("Rename")])],1):_vm._e(),((_vm.$isUserAdmin || _vm.$isUserMentor || !_vm.$viewProgramAsStudent) && !_vm.previewOnly)?_c('VListItem',{on:{"click":function($event){return _vm.$emit('viewSubmitted', _vm.item)}}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" list_alt ")]),_c('VListItemTitle',[_vm._v("View Submitted")])],1):_vm._e(),(_vm.$isUserAdmin && _vm.$viewProgramAsAdmin && !_vm.previewOnly)?_c('VListItem',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('duplicateItem', _vm.item)}}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px"}},[_vm._v(" content_copy ")]),_c('VListItemTitle',[_vm._v("Duplicate")])],1):_vm._e(),_vm._t("extra-menu"),(_vm.hasAccess && !_vm.previewOnly)?_c('VListItem',{on:{"click":function($event){return _vm.$emit('deleteItem', _vm.item)}}},[_c('VIcon',{staticClass:"pr-2",attrs:{"size":"20px","color":"error"}},[_vm._v(" mdi-delete ")]),_c('VListItemTitle',{staticStyle:{"color":"#ff5252"}},[_vm._v(" Delete Forever ")])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }