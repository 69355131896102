<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import { required, minLength, between } from "vuelidate/lib/validators";
import HasMembers from "@/mixins/HasMembers";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import moment from "moment";
import Vue from "vue";

export default {
  mixins: [FormMixin, HasMembers, HasCalendarEntries],
  props: {
    entity: {
      type: Object,
      default: () => {}
    },
    entityType: {
      type: String,
      default: ""
    },
    existing: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedEntityType: null,
      showDate: new Date(),
      modelType: "Calendar",
      menu: {
        startDateMenu: false,
        entilDateMenu: false,
        endDateMenu: false,
        startTimeMenu: false,
        untilTimeMenu: false,
        endTimeMenu: false
      },
      entityTypes: [
        {
          id: "App\\Models\\Program",
          title: this.featureName("Program")
        },
        {
          id: "App\\Models\\Deliverable",
          title: this.featureName("Deliverable")
        }
      ],
      options: {
        primary_contents: [],
        supplemental_contents: [],
        programs: [],
        day_of_week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      },
      defaultForm: {
        title: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: null,
        start_time: "09:00",
        end_time: null,
        entries: [],
        calendar_type: "local",
        remote_calendar: {},
        members: [],
        until_date: null,
        is_all_day: false,
        is_repeating: false,
        recur_count: 0,
        recur_frequency: "Weekly"
      }
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3)
      },
      start_date: {
        required
      }
    }
  },
  computed: {
    selectedEntityTitle() {
      return this.selectedEntityType
        ? this.entityTypes.find(entity => entity.id === this.form.entity_type)
            .title
        : "";
    },
    selectedEntity() {
      if (this.entity) {
        return this.entity;
      }
      return this.form.entity_id
        ? this.entityOptions.find(e => e.id === this.form.entity_id)
        : {};
    },
    events() {
      const entries = this.form.entries || [];
      return entries.map(entry => {
        return this.formatEvent(entry, this.form);
      });
    },
    entityOptions() {
      return this.selectedEntityType ? this.options[this.getEntity()] : [];
    },
    existingMembers() {
      return this.form.members
        ? this.form.members.map(mentor => mentor.id)
        : [];
    },
    realEntity() {
      return this.selectedEntity instanceof Object
        ? this.selectedEntity.entity || this.selectedEntity
        : this.form.entity;
    }
  },
  watch: {
    "form.entity_id": function(value) {
      if (value && !this.title) {
        Vue.nextTick(() => {
          const entity = this.entityOptions.find(
            entity => entity && entity.id === value
          );
          if (entity instanceof Object) {
            this.form.title = this.form.title || entity.title;
          }
        });
      }
      if (value && !this.form.id) {
        console.log("Setting users from ", this.realEntity);
        let users = []
          .concat(this.realEntity.users || [])
          .concat(this.realEntity.members || [])
          .concat(this.realEntity.students || [])
          .concat(this.realEntity.mentors || []);
        this.form.members = users;
      }
    }
  },
  created() {
    if (this.entityType && this.entity) {
      this.form.entity_id = this.entity.id || this.form.entity_id;
      this.form.entity_type = this.entityType || this.form.entity_type;
      this.selectedEntityType = this.form.entity_type;
      this.options[this.getEntity()] = [this.entity.entity || this.entity];
      this.entityTypes = this.entityTypes.filter(
        type => type.id == this.entityType
      );
      this.form.title = this.entity.title;
    } else {
      this.doGetFormConfig().then(result => (this.options = result));
    }
  },
  methods: {
    ...mapActions({
      doGetCalendar: "calendar/getOne",
      doSaveCalendar: "calendar/save",
      doGetFormConfig: "calendar/getFormConfig"
    }),
    getEntity() {
      const entityMap = {
        "App\\Models\\Program": "programs",
        "App\\Models\\Deliverble": "deliverables"
      };
      return entityMap[this.form.entity_type];
    },
    onToggleMembersDialog() {
      this.dialogs.members = !this.dialogs.members;
    },
    addMember(member) {
      this.form.members = this.onAddMember(this.form.members, member);
      // this.saveMembers(true);
    },
    removeMember(member) {
      this.form.members = this.onRemoveMember(this.form.members, member);
      // this.saveMembers(true);
    },
    onSaveCalendar() {
      this.$emit("saveCalendar");
    },
    onGet(id) {
      return this.doGetCalendar(id).then(result => {
        result.day_of_week =
          result.day_of_week instanceof Object
            ? Object.values(result.day_of_week)
            : [];
        return result;
      });
    },
    setShowDate(d) {
      this.showDate = d;
    },
    setStartDate() {
      this.$refs.startDateMenu.save(this.form.start_date);
      this.$v.form.start_date.$touch();
    },
    onSave() {
      // this.form.start_date = /\d\d\d\d-\d\d-\d\d \d\d:\d\d/.test(
      //   this.form.start_date
      // )
      //   ? this.form.start_date
      //   : `${this.form.start_date} ${this.form.start_time || "00:00:00"}`;
      // if (this.form.end_date) {
      //   this.form.end_date =
      //     this.form.end_date &&
      //     /\d\d\d\d-\d\d-\d\d \d\d:\d\d/.test(this.form.end_date)
      //       ? this.form.end_date
      //       : `${this.form.end_date} ${this.form.end_time || "00:00:00"}`;
      // }
      this.saveFormModel(this.doSaveCalendar).then(result => {
        if (result instanceof Object && result.id) {
          this.form.day_of_week =
            this.form.day_of_week instanceof Object
              ? Object.values(this.form.day_of_week)
              : [];
          // Make sure the calendar type is set properly
          result.calendar_type =
            result.calendar_type || this.defaultForm.calendar_type;
          this.$emit("savedCalendar", result);
        }
      });
    }
  }
};
</script>