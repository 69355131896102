<template>
  <VContainer
    grid-list-lg
    fluid
    :class="{
      'is-loading': activity.isLoading
    }"
  >
    <VRow
      v-if="(options instanceof Object)"
    >
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol cols="12">
            <VCard v-if="(form.trigger instanceof Object)">
              <VCardText>
                <TextField
                  v-model="form.title"
                  label="Title"
                  placeholder="Enter title..."
                  :error="$v.form.title.$error"
                  @input="$v.form.title.$touch()"
                  @blur="$v.$touch()"
                />
                <VRow>
                  <VCol>
                    <VCheckbox
                      v-model="form.is_active"
                      label="Is this automation active?"
                    />
                  </VCol>
                  <VCol>
                    <VCheckbox
                      v-model="hasAdvancedOptions"
                      label="Advanced"
                    />
                  </VCol>
                </VRow>
                <VCard v-show="hasAdvancedOptions">
                  <VCardText>
                    <LongTextField
                      v-model="form.description"
                      placeholder="(Optional) Description..."
                    />
                  </VCardText>
                </VCard>
                <VAlert
                  color="light-blue accent-3"
                  :value="true"
                  style="border-radius: 20px"
                >
                  <Autocomplete
                    v-model="form.trigger.event_name"
                    item-text="title"
                    item-value="event"
                    placeholder="When ..."
                    label="When ..."
                    :multiple="false"
                    :items="preparedTriggerOptions"
                  />
                  <TriggerOptions
                    v-if="(hasCurrentTrigger)"
                    ref="trigger"
                    :option="currentTrigger"
                    :label="currentTriggerLabel"
                    :placeholder="currentTriggerPlaceholder"
                    :value="form.trigger.event_data"
                    :action="currentAction"
                    :notification="currentNotification"
                    @input="setTriggerData"
                  />
                </VAlert>
                <VAlert
                  color="light-blue accent-2"
                  :value="form.trigger.event_name !== undefined"
                  style="border-radius: 20px"
                  transition="scale-transition"
                >
                  <Autocomplete
                    v-model="form.trigger.action.event_name"
                    item-text="title"
                    item-value="event"
                    placeholder="WeThrive should ..."
                    label="WeThrive should ..."
                    :items="preparedActionOptions"
                  />
                  <ActionOptions
                    v-if="(currentAction instanceof Object)"
                    :option="currentAction"
                    :label="currentActionLabel"
                    :placeholder="currentTriggerPlaceholder"
                    :value="form.trigger.action.event_data"
                    :trigger="currentTrigger"
                    :notification="currentNotification"
                    @input="setActionData"
                    ref="action"
                  />
                </VAlert>
                <VAlert
                  color="light-blue accent-1"
                  :value="form.trigger.action.event_name !== undefined"
                  style="border-radius: 20px"
                  transition="scale-transition"
                >
                  <Autocomplete
                    v-model="form.trigger.notification.event_name"
                    :multiple="false"
                    :items="preparedNotificationOptions"
                    item-text="title"
                    item-value="event"
                    placeholder="(Optional) And then ..."
                    label="(Optional) And then ..."
                  />
                  <NotificationOptions
                    v-if="(currentNotification instanceof Object)"
                    :option="currentNotification"
                    :label="currentNotificationLabel"
                    :placeholder="currentTriggerPlaceholder"
                    :value="form.trigger.notification.event_data"
                    :trigger="currentTrigger"
                    :action="currentAction"
                    @input="setNotificationData"
                    ref="notification"
                  />
                </VAlert>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import { mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
import TriggerOptions from "./TriggerOptions";
import ActionOptions from "./ActionOptions";
import NotificationOptions from "./NotificationOptions";
export default {
  name: "AutomationsSave",
  components: {
    SaveHeader,
    TriggerOptions,
    ActionOptions,
    NotificationOptions,
  },
  mixins: [FormMixin, MetadataMixin],
  data() {
    return {
      modelType: "Automation",
      hasFiles: true,
      hasAdvancedOptions: false,
      options: null,
      defaultForm: {
        title: null,
        description: null,
        trigger: {
          action: {},
          notification: {
            event_name: "",
          },
        },
        is_active: true,
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
      trigger: {
        event_name: required,
        action: {
          event_name: required,
          event_data: required,
        },
      },
    },
  },
  computed: {
    /** Trigger properties */
    preparedTriggerOptions() {
      return this._groupOptions("triggers");
    },
    hasCurrentTrigger() {
      return this.currentTrigger instanceof Object;
    },
    currentTrigger() {
      const triggerOption =
        this.form.trigger &&
        this.preparedTriggerOptions.find(
          (option) => option.event === this.form.trigger.event_name
        );
      return triggerOption instanceof Object ? triggerOption : null;
    },
    currentTriggerLabel() {
      const currentTriggerOptions =
        this.hasCurrentTrigger && this.currentTrigger.options;
      return (
        (currentTriggerOptions &&
          (currentTriggerOptions.placeholder || currentTriggerOptions.label)) ||
        (this.hasCurrentTrigger && this.currentTrigger.title)
      );
    },
    currentTriggerPlaceholder() {
      const currentTriggerOptions =
        this.hasCurrentTrigger && this.currentTrigger.options;
      return (
        (currentTriggerOptions && currentTriggerOptions.label) ||
        (this.hasCurrentTrigger &&
          (this.currentTrigger.description ||
            this.currentTrigger.title ||
            this.currentTrigger.defaultMessage))
      );
    },
    /** Action properties */
    preparedActionOptions() {
      return this._groupOptions("actions");
    },
    currentAction() {
      const actionOption =
        this.form.trigger &&
        this.form.trigger.action &&
        this.preparedActionOptions.find(
          (option) => option.event === this.form.trigger.action.event_name
        );
      return actionOption instanceof Object ? actionOption : null;
    },
    currentActionLabel() {
      const currentActionOptions =
        this.currentAction instanceof Object && this.currentAction.options;
      return (
        (currentActionOptions &&
          (currentActionOptions.placeholder || currentActionOptions.label)) ||
        (this.hasCurrentTrigger && this.currentAction.title)
      );
    },
    currentActionPlaceholder() {
      const currentActionOptions =
        this.currentAction instanceof Object && this.currentAction.options;
      return (
        (currentActionOptions && currentActionOptions.label) ||
        (this.hasCurrentTrigger &&
          (this.currentAction.defaultMessage ||
            this.currentAction.description ||
            this.currentAction.title))
      );
    },
    /** Notification properties */
    preparedNotificationOptions() {
      return this._groupOptions("notifications");
    },
    currentNotification() {
      const notificationOption =
        this.form.trigger &&
        this.form.trigger.notification &&
        this.preparedNotificationOptions.find(
          (option) => option.event === this.form.trigger.notification.event_name
        );
      return notificationOption instanceof Object ? notificationOption : null;
    },
    currentNotificationLabel() {
      const currentNotificationOptions =
        this.currentNotification instanceof Object &&
        this.currentNotification.options;
      return (
        (currentNotificationOptions &&
          (currentNotificationOptions.placeholder ||
            currentNotificationOptions.label)) ||
        this.currentNotification.title
      );
    },
    currentNotificationPlaceholder() {
      const currentNotificationOptions =
        this.currentNotification instanceof Object &&
        this.currentNotification.options;
      return (
        (currentNotificationOptions && currentNotificationOptions.label) ||
        this.currentNotification.defaultMessage ||
        this.currentNotification.description ||
        this.currentNotification.title
      );
    },
  },
  watch: {
    "form.trigger.event_name": function (value) {
      if (value && !this.form.title) {
        this.form.title = this.currentTriggerLabel;
      }
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "automation/getFormConfig",
      doSaveAutomation: "automation/save",
      doGetAutomation: "automation/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      //Sync all options
      ["trigger", "action", "notification"].forEach((ref, index) => {
        if (
          this.$refs.hasOwnProperty(ref) &&
          this.$refs[ref] instanceof Object
        ) {
          this.$refs[ref].syncInput();
        }
      });
      return this.saveFormModel(this.doSaveAutomation);
    },
    onGet(id) {
      return this.doGetAutomation(id).then((result) => {
        result.trigger = result.trigger || {};
        result.trigger.action = result.trigger.action || {};
        result.trigger.notification = result.trigger.notification || {};
        return result;
      });
    },
    setTriggerData(value) {
      this.form.trigger.event_data = value;
    },
    setActionData(value) {
      this.form.trigger.action.event_data = value;
    },
    setNotificationData(value) {
      this.form.trigger.notification.event_data = value;
    },
    _groupOptions(key) {
      return this.options[key] instanceof Object
        ? Object.keys(this.options[key])
            .map((group, index) => {
              return [
                { header: group },
                this.options[key][group],
                { divider: true },
              ].flat();
            })
            .flat()
        : [];
    },
  },
};
</script>

