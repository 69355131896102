<template>
  <VRow
    row
    align-center
    class="index-header"
    :column="$vuetify.breakpoint.smAndDown"
    :wrap="$vuetify.breakpoint.smAndDown"
  >
    <VCol
      grow
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        'order-sm1': true,
        'order-md1': true
      }"
    >
      <VRow
        row
        align-center
        justify-start
        :class="{
          'flex-nowrap': $vuetify.breakpoint.mdAndUp,
          'flex-wrap': $vuetify.breakpoint.smAndDown,
        }"
      >
        <VCol pl-3>
          <h1
            :class="{
              'text-no-wrap' : true,
              'text-center': $vuetify.breakpoint.smAndDown
            }"
            :style="{
              textTransform: uppercaseTitle ? 'uppercase' : 'none',
              whiteSpace: $vuetify.breakpoint.smAndDown ? 'normal' : 'normal',
            }"
          >
            <BetaBadge
              enable-tooltip
              offset-y="30%"
              :is-beta="isBetaFeature((owner || $parent).modelType)"
            >
              <slot name="title">
                {{ featureName((owner || $parent).modelType).capitalize() }}
                &nbsp;
              </slot>
            </BetaBadge>
          </h1>
        </VCol>
        <VCol style="flex: 0 0 48px">
          <slot name="title-button" />
        </VCol>
      </VRow>
    </VCol>
    <VCol
      v-if="hasContent"
      align-center
      justify-start
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        md4: $vuetify.breakpoint.mdAndUp || hasContent,
        'order-sm3': true,
        'order-md2': true,
        'd-inline-flex': true
      }"
    >
      <slot name="content-center" />
    </VCol>
    <VCol
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        'order-sm2': true,
        'order-md3': true
      }"
    >
      <VRow
        row
        align-center
        :justify-end="$vuetify.breakpoint.smAndUp"
        :class="{
          'flex-nowrap': $vuetify.breakpoint.mdAndUp,
          'flex-wrap': $vuetify.breakpoint.smAndDown,
        }"
      >
        <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
        <VCol
          px-1
          class="d-inline-flex"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          :shrink="$vuetify.breakpoint.mdAndUp"
          :class="{
            'flex-nowrap': $vuetify.breakpoint.mdAndUp,
            'flex-wrap': $vuetify.breakpoint.smAndDown,
          }"
        >
          <slot name="actions-left" />
        </VCol>
        <VCol
          px-0
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          :shrink="$vuetify.breakpoint.mdAndUp"
          :class="{
            'd-inline-flex': true,
            'flex-nowrap': $vuetify.breakpoint.mdAndUp,
            'flex-wrap': $vuetify.breakpoint.smAndDown,
          }"
        >
          <slot name="create-action">
            <ExpandableButton
              v-if="canCreate"
              icon="add"
              text="Add New"
              tooltip-text="Add New"
              button-class="wethrive px-2 pr-4"
              :large="true"
              :flex="true"
              :block="$vuetify.breakpoint.smAndDown"
              :to="realCreateRoute"
            />
          </slot>
        </VCol>
        <VCol
          px-1
          class="d-inline-flex"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          :shrink="$vuetify.breakpoint.mdAndUp"
          :class="{
            'flex-nowrap': $vuetify.breakpoint.mdAndUp,
            'flex-wrap': $vuetify.breakpoint.smAndDown,
          }"
        >
          <slot name="actions-right" />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
export default {
  props: {
    owner: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    routeName: {
      type: String,
      default: null,
    },
    contentClass: {
      type: [String, Array, Object],
      default: "xs12 md6",
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
    uppercaseTitle: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      buttonHover: false,
    };
  },
  computed: {
    realCreateRoute() {
      return (
        this.createRoute || {
          name: `${this.realRouteName}.create`,
          params: {
            team: `${this.$team.slug}`,
          },
        }
      );
    },
    realRouteName() {
      return (
        this.routeName ||
        ((this.owner || this.$parent).modelType || "model")
          .singularize()
          .parameterize()
      );
    },
  },
};
</script>
<style scoped lang="scss">
.index-header {
  @media (max-width: 768px) {
    .v-btn--block {
      margin-bottom: 6px;
    }
  }
}
</style>