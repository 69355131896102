import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Saved Assessments API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	save(_params) {
		const params = _params || {};
		let { assessment_id, id } = params;
		assessment_id = assessment_id || params.assessment_id;
		if (!assessment_id) {
			throw new Error("Missing assessment");
		}
		params.assessment_id = assessment_id;
		return super.save(params);
	}
}

const api = new Api({
	type: "Saved Assessments",
	path: {
		save: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id;
			return `assessments_saved/${assessment_id}`;
		},
		default: "assessments_saved"
	}
});

export default api;
