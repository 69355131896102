<script>
import { mapActions } from "vuex";
import FormMixin from "./Form";

export default {
  mixins: [FormMixin],
  props: {
    assessment: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    existing: {
      type: Object,
      default: () => {},
    },
    result: {
      type: Object,
      default: () => {},
    },
    cardOptions: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [String, Number],
      default: null,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Lesson",
    },
    hasAdditionalImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        choices: [],
        name: "",
        value: "",
      },
      defaultForm: {
        choices: [],
        name: "",
        value: "",
      },
      draggableOptions: {
        handle: ".dynamic-input-handle",
        animation: 150,
        Draggable: ".dynamic-input-container",
      },
      isSaving: false,
      backgroundSaveWaitTimeout: null,
    };
  },
  computed: {
    choices: {
      get() {
        return this.form.choices.filter((c) => !c.deleted);
      },
      set(choices) {
        this.form.choices = choices;
      },
    },
    url() {
      return this.form.value instanceof Object ? this.form.value.url : "";
    },
  },
  created() {
    this.prepareCardForm(this.card);
  },
  watch: {
    card: function (card) {
      this.prepareCardForm(card);
    },
  },
  methods: {
    ...mapActions({
      saveAssessmentCard: "assessmentCard/save",
    }),
    /**
     * This method is needed in order to properly switch between cards of the same type
     * Otherwise the computed URL values will not update
     */
    prepareCardForm(card) {
      if (card instanceof Object) {
        const defaultForm = { ...this.defaultForm };
        const existing = { ...this.existing };
        this.form = {
          ...this.form,
          ...this.cloneObject(defaultForm),
          ...(existing || {}),
          ...card,
        };
        this.form.value =
          this.form.value === null || 
          this.form.value === undefined || 
          (this.form.value instanceof Array && this.form.value.length === 0)
            ? this.defaultForm.value
            : this.form.value;

        /** Setup expected properties */
        const props = {
          images: [],
        };
        Object.keys(props).map((prop) => {
          if (!this.card.hasOwnProperty(prop)) {
            this.card[prop] = props[prop];
          }
        });
        this.form.options = this.form.options || {};
        this.form.options.caption =
          this.form.options.caption || (this.form.value || {}).description;
      }
    },
    saveCard(timeout) {
      this.$bus.$emit("input:card", { ...this.form }, { ...this.form });
      // Make sure to clear any background save operation
      clearTimeout(this.saveWaitTimeout);
      this.form.assessment_id = this.form.entity_id || this.assessment.id;
      // We'll toggle form events here so that we can save silently using our own events
      this.toggleEmitFormEvents();
      this.$emit("saveCardStart");
      this.saveFormModel(this.saveAssessmentCard, null, this.form)
        .then((result) => {
          this.form = { ...result, ...this.form };
          this.$log.debug("[SaveAssessmentCardMixin]: Sending input event");
          this.$emit("saveCardSuccess");
          this.$bus.$emit("set:card", { ...this.form }, { ...this.form });
        })
        .catch((error) => {
          this.$emit("saveCardError");
        })
        .finally(() => {
          this.isSaving = false;
          this.toggleEmitFormEvents();
        });
    },
    backgroundSaveCard(timeout) {
      // Flag for dirty data and prevent navigate awaay before saivng if there is dirty data
      this.$emit("saveCardDirty");
      const saver = () => {
        // this.$emit("saveCardStart");
        // this.form.assessment_id = this.form.entity_id || this.assessment.id;
        // const result = this.backgroundSaveFormModel(
        //   this.saveAssessmentCard,
        //   null,
        //   this.form
        // );
        // if (result) {
        //   result
        //     .then(result => {
        //       this.$log.debug(
        //         "[SaveAssessmentCardMixin]: Sending background input event"
        //       );
        //       this.$emit("saveCardSuccess");
        //       this.$bus.$emit("set:card", { ...this.form }, { ...this.form });
        //     })
        //     .catch(error => {
        //       this.$emit("saveCardError");
        //     });
        // }
        this.$bus.$emit("input:card", { ...this.form }, { ...this.form });
      };

      clearTimeout(this.backgroundSaveWaitTimeout);
      if (timeout !== true) {
        const realTimeout = parseInt(timeout) || 25;
        this.backgroundSaveWaitTimeout = setTimeout(
          () => this.backgroundSaveCard(true),
          realTimeout
        );
      }

      if (timeout === true) {
        console.warn("Background timeout exceeded...saving for real");
        return saver();
      }
    },
    addInput(value, prop) {
      const property = prop || "choices";
      this.form[property].push(value || {});
      this.backgroundSaveCard();
    },
    removeInput(key, prop) {
      const property = prop || "choices";
      // this.form[property] = this.form[property].filter((v, k) => {
      //   return key instanceof Object ? v.id !== key.id : k !== key;
      // });
      const index = this.form[property].findIndex((v, k) => {
        return key instanceof Object ? v.id === key.id : k === key;
      });
      const object = key instanceof Object ? key : this.form[property][index];
      console.log(key, object, this.form[property], index);
      if (object.id) {
        this.$set(object, "deleted", true);
      } else {
        this.form[property].splice(index, 1);
      }
      this.backgroundSaveCard();
    },
    selectChoice(choice) {
      if (
        this.form.choices &&
        this.form.choices instanceof Array &&
        this.form.value.type === "single"
      ) {
        this.form.choices = this.form.choices.map((c) => {
          c.is_correct_answer = false;
          return c;
        });
      }
      choice.is_correct_answer = !choice.is_correct_answer;
      this.backgroundSaveCard();
    },
  },
};
</script>
<style lang="scss">
.v-textarea.fill-height {
  min-height: calc(100% - 1px);
  .v-input__control {
    height: calc(100% - 1px);
  }
  .textarea {
    height: 100%;
  }
}
</style>