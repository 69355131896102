import BaseApi from "./_base-api";

/**
 * Content API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getBasePath (action, app, _params) {
		const params = _params || {};
		let basePath = super.getBasePath(action, app, params);
		const parts = [params.entity_slug, params.entity_id];
		return basePath + "/" + parts.join("/");
	}

	delete (file) {
		return super.delete(file.id);
	}
}

const api = new Api({
	type: "File",
	path: "files"
});

export default api;
