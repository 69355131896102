<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    id="save-monetize"
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol
        style="flex: 1 1 auto"
        :class="{
          'py-0': true,
          'px-0': $vuetify.breakpoint.mdAndUp,
          'px-4': $vuetify.breakpoint.smAndDown
        }"
      >
        <VTabs
          v-model="activeTab"
          lazy
          slider-color="success"
          :grow="$vuetify.breakpoint.smAndDown"
          :vertical="$vuetify.breakpoint.mdAndUp"
        >
          <VTab
            v-for="(tab, key) in tabs"
            :key="key"
            lazy
          >
            {{ tab.title }}
          </VTab>
          <VTabItem>
            <VCard>
              <VCardTitle>Details</VCardTitle>
              <VCardText class="ml-n2">
                <!-- <VCol>
                  <VSelect
                    v-model="form.payment_type"
                    dense
                    outlined
                    hide-details
                    height="25px"
                    label="Payment Type"
                    placeholder="Select type..."
                    :items="paymentTypeOptions"
                    :error="$v.form.payment_type.$error"
                    @input="$v.form.payment_type.$touch()"
                    @blur="$v.$touch()"
                  />
                </VCol> -->
                <template v-if="form.payment_type == 'fixed_amount_options'">
                  <div
                    v-for="(item, index) in form.items"
                    style="position: relative;"
                    :class="{
                      'pa-1 mx-3 mt-2 mb-5 elevation-2 rounded-lg': isItemRemoveable(index),
                    }"
                    :key="`fixed-option-${index}`"                  
                  >
                    <template v-if="item.active">
                      <VCol>
                        <TextField
                          v-model="item.title"
                          hide-details
                          label="Title"
                          placeholder="Enter title..."
                          :error="$v.form.items.$each[index].title.$error"
                          @input="$v.form.items.$each[index].title.$touch()"
                          @blur="$v.$touch()"
                        />
                      </VCol>
                      <VCol>
                        <TextField
                          v-model="item.price"
                          hide-details
                          type="number"
                          prefix="$"
                          label="Price"
                          placeholder="Enter price..."
                          class="price-field"
                          :error="$v.form.items.$each[index].price.$error"
                          @input="$v.form.items.$each[index].price.$touch()"
                          @blur="$v.$touch()"
                        />
                      </VCol>
                      <VCol>
                        <Editor
                          v-model="item.description"
                          auto-grow
                          filled
                          outlined
                          placeholder="Enter description..."
                          class="content-editor"
                        />
                      </VCol>
                      <VTooltip 
                        v-if="isItemRemoveable(index)"
                        left
                      >
                        <template #activator="{ on }">
                          <VIcon 
                            v-on="on"
                            color="error"
                            slot="activator"
                            style="position: absolute; right: -12px; top: -12px;"
                            @click="removeOption(index)"
                          >
                            mdi-close-circle
                          </VIcon>
                        </template>
                        <span>Remove this option</span>
                      </VTooltip>
                    </template>
                  </div>
                  <VCol class="d-flex">
                    <VSpacer />
                    <ExpandableButton
                      icon="add"
                      text="Add Payment Option"
                      button-class="ml-1"
                      color="info"
                      :depressed="true"
                      :large="true"
                      :flex="true"
                      @click="addOption"
                    />
                  </VCol>
                </template>
                <template v-else-if="form.payment_type == 'slider_scale'">
                  <VCol>
                    <TextField
                      v-model="form.slider.title"
                      hide-details
                      label="Title"
                      placeholder="Enter title..."
                      :error="$v.form.slider.title.$error"
                      @input="$v.form.slider.title.$touch()"
                      @blur="$v.$touch()"
                    />
                  </VCol>
                  <VCol>
                    <VSlider
                      v-model="form.slider.value"
                      hide-details
                      color="primary"
                      class="align-center pt-2"
                      :max="form.slider.max"
                      :min="form.slider.min"
                    />
                  </VCol>
                  <VCol>
                    <VRow>
                      <VCol cols="6">
                        <TextField
                          v-model="form.slider.min"
                          hide-details
                          type="number"
                          label="Min"
                          prefix="$"
                          placeholder="Enter minimum"
                          :error="$v.form.slider.min.$error"
                          @input="$v.form.slider.min.$touch()"
                          @blur="$v.$touch()"
                        />
                      </VCol>
                      <VCol cols="6">
                        <TextField
                          v-model="form.slider.max"
                          hide-details
                          type="number"
                          label="Max"
                          prefix="$"
                          placeholder="Enter maximum"
                          :error="$v.form.slider.max.$error"
                          @input="$v.form.slider.max.$touch()"
                          @blur="$v.$touch()"
                        />
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol class="py-0">
                    <VRow class="align-center">
                      <VCol cols="auto">
                        <VSubheader 
                          class="pl-2"
                          style="font-size: 14px;font-weight: 500;"
                        >
                          User must pay minimum amount:
                        </VSubheader>
                      </VCol>
                      <VSwitch 
                        v-model="form.slider.must_pay_min"
                        inset
                        dense
                        :success="form.slider.must_pay_min"
                      />
                    </VRow>
                  </VCol>
                  <VCol>
                    <TextField
                      v-model="form.slider.caption_text"
                      hide-details
                      label="(Optional) Caption text"
                      placeholder="Enter caption text..."
                    />
                  </VCol>
                </template>
              </VCardText>
            </VCard>
          </VTabItem>
          <VTabItem>
            <Associations
              :model="form"
              :options="options"
              @setAssociations="onSetAssociations"
            />
          </VTabItem>
          <VTabItem>
            <Transactions :product-id="id" />
          </VTabItem>
        </VTabs>
      </VCol>
      <Confirm ref="confirmDelete" />
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import DeletesItems from "@/mixins/DeletesItems";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import Transactions from "./Transactions";
import Associations from "./Associations";

import { 
  required,
  requiredIf, 
  minLength,
  minValue,
} from "vuelidate/lib/validators";

export default {
  props: {
    currentModel: {
      type: Object,
      default: null,
    },
  },
  components: {
    SaveHeader,
    Transactions,
    Associations,
  },
  mixins: [FormMixin, DeletesItems],
  data() {
    return {
      activeTab: 0,
      modelType: "Monetize",
      tabs: [
        { title: "Details" },
        { title: "Associations" },
        { title: "Transactions" },
      ],
      paymentTypeOptions: [
        { text: "Fixed Amount Options", value: "fixed_amount_options" },
        // { text: "Sliding Scale", value: "slider_scale" },
      ],
      localItems: [],
      options: {
        faqs: [],
      },
      defaultForm: {
        title: "",
        description: "",
        faqs: null,
        payment_type: "fixed_amount_options",
        type: "product",
        entity_type: "program",
        entity_id: null,
        items: [{
          title: "",
          description: "", 
          price: "",
          method: "create",
          active: true,
        }],
        slider: {
          title: "",
          value: "",
          min: 0,
          max: 0,
          must_pay_min: false,
          caption_text: "",
        },
      },
    };
  },
  validations: {
    form: {
      payment_type: {
        required,
      },
      items: {
        $each: {
          title: {
            required: requiredIf(function() {
              return this.form.payment_type == "fixed_amount_options";
            }),
            minLength: minLength(4),
            unique(value) {
              if(this.form.items.length > 1 && value.length > 3) {
                let result = this.form.items.filter((item) => item.title == value);
                return !(result.length > 1);
              } 
              return true;
            },
          },
          price: {
            required: requiredIf(function() {
              return this.form.payment_type == "fixed_amount_options";
            }),
            minValue: minValue(1),
          },
        },
      },
      /* slider: {
        title: {
          required: requiredIf(function() {
            return this.form.payment_type == "slider_scale";
          }),
          minLength: minLength(4),
        },
        min: {
          required: requiredIf(function() {
            return this.form.payment_type == "slider_scale";
          }),
          minValue: minValue(1),
        },
        max: {
          required: requiredIf(function() {
            return this.form.payment_type == "slider_scale";
          }),
          minValue: minValue(1),
        },
      }, */
    },
  },
  watch: {
    "form.items": {
      handler: function(value) {
        value.forEach((item, index) => {
          if(
            this.localItems[index].id == item.id && 
            Number(this.localItems[index].price) != Number(item.price)
          ) {
            this.$set(this.form.items[index], "method", "create");
            this.$set(this.form.items[index], "active", true);
          }
        })
      },
      deep: true,
    },
    activeTab(value) {
      // Hide dialog actions when Transactions tab is active
      this.$emit("toggleActions", value === 2);
    },
  },
  mounted() {
    this.form = {
      ...this.form,
      title: this.currentModel.title,
      entity_id: this.currentModel.id,
    };
    // Show dailog actions by default
    this.$emit("toggleActions", false);
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "program/getFormConfig",
      doGetProduct: "product/getOne",
      doSaveProduct: "product/save",
      doDeleteProduct: "product/delete",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    addOption() {
      this.form.items.push({
        title: "",
        description: "", 
        price: "",
        method: "create",
        active: true,
      });
    },
    removeOption(index) {
      if(this.form.items[index].id) {
        this.$set(this.form.items[index], "method", "update");
        this.$set(this.form.items[index], "active", false);
      } else {
        this.form.items.splice(index, 1);
      }
    },
    onGet(id) {
      return this.doGetProduct(id).then((result) => {
        if(result) {
          result.items = result.items.map((item) => {
            this.localItems.push(item);
            return {
              ...item,
              method: "update",
              active: true,
            };
          });
        }
        return result;
      });
    },
    onSave() {
      return this.saveFormModel(this.doSaveProduct).then((result) => {
        if(result) {
          this.$emit("toggleDialog");
          if(this.id === null) {
            this.$emit("productAdded", this.currentModel.id, result);
          }
        }
      });
    },
    onDelete(product) {
      return this.doDeleteProduct(product.id).then((result) => {
        if(result) {
          this.$emit("toggleDialog");
          this.$emit("productDeleted", this.currentModel.id);
        }
      });
    },
    deleteItem(params) {
      this.onDeleteItem(params);
    },
    isItemRemoveable(index) {
      let activeItems = this.form.items.filter((item) => item.active === true);
      return activeItems.length > 1 && this.form.items[index].active && (index > 0 || this.form.id);
    },
    onSetAssociations(associations) {
      for (let association in associations ) {
        if(associations[association].length > 0) {
          this.form[association] = associations[association];
        }
      }
    },
  },
};
</script>

<style lang="scss">
#save-monetize {
  .v-tabs-items {
    height: calc(100vh - 173px);
    overflow: hidden auto;
  }
  .v-slider__thumb {
    height: 26px;
    border-radius: 0px;
  }
  .price-field {
    .v-input__prepend-inner {
      margin-top: 6px !important;
    }
  }
}
</style>