<template>
  <VListItem
    :id="`message${message.id}`"
    :class="messageClass"
    :style="{
      maxWidth: '100%'
    }"
  >
    <VListItemAvatar
      width="50"
      height="50"
      align-start
      justify-start
      style="align-self: flex-start"
      :class="{
        'chat-item__author-photo my-0': true,
        'mr-0 ml-3': $user.id == message.user.id,
        'mr-3 ml-0': $user.id != message.user.id
      }"
    >
      <Avatar
        :size="50"
        :user="message.user"
      />
    </VListItemAvatar>
    <VListItemContent class="chat-item__body fill-height pa-0">
      <VListItemSubtitle class="chat-item__actions mb-2">
        <template v-if="message.user.id != $user.id">
          <strong>{{ message.user.name ? message.user.name : '' }}</strong>
        </template>
        <span style="color: rgba(0, 0, 0, 0.65); font-size: 10px; margin-left: 8px">{{ message.date || 'just now' }}</span>
      </VListItemSubtitle>
      <span
        v-if="message.message"
        class="formatted"
        v-html="message.message || '...' "
        v-linkified
      />
      <!-- <VListItemSubtitle class="chat-item__actions">
        <Linkable
          :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.index`, {
            params: {
              id: message.thread_id
            },
            hash: '#message'+message.id
          })"
        />
      </VListItemSubtitle> -->
      <MessageFiles
        v-if="hasFiles"
        :files="message.files"
        :is-sender="isSender"
      />
    </VListItemContent>
  </VListItem>
</template>
<script>
import SupportsModelTypes from "../../mixins/SupportsModelTypes";
import MessageFiles from "./MessageFiles";
export default {
  components: { MessageFiles },
  mixins: [SupportsModelTypes],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    messageClass: function () {
      return {
        "chat-item pa-3": true,
        "chat-item__self": this.$user.id == this.message.user.id,
        "bg-transparent": this.hasFiles,
      };
    },
    avatar: function () {
      return this.message.user && this.message.user.photo_url
        ? this.message.user.photo_url
        : "";
    },
    hasFiles() {
      return (
        this.message.files &&
        this.message.files.filter((item) => item.url || item.size).length > 0
      );
    },
    isSender() {
      return this.$user.id == this.message.user.id;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
.chat-item {
  align-self: flex-start;
  flex: 0 0 auto;
  margin-top: 20px;
  margin-right: auto;
  margin-left: 0;
  max-width: 75% !important;
  width: fit-content;
  border-radius: 38px;
  background-color: $disabled-color;
}

.chat-item .v-list__tile {
  height: auto !important;
  flex-wrap: wrap;
  align-items: flex-start;
}

.chat-item .v-list__tile .v-list__tile__avatar.chat-item__author-photo {
  align-self: flex-start;
  margin-right: 0px;
  order: 1;
}

.chat-item .v-list__tile .v-list__tile__avatar .v-avatar img {
  border: 1.5px solid #fcbb11;
}

.chat-item .v-list__tile .v-list__tile__content.chat-item__actions {
  align-self: flex-end;
  color: $font-light-color;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
}

.chat-item .v-list__tile .v-list__tile__content.chat-item__body {
  border-radius: 6px;
  background-color: $white-color;
  box-shadow: 0 0 10px 0 $box-shadow-color;
  padding: 20px 30px;
  order: 2;
  flex: 0 1 auto;
  max-width: calc(100% - 112px);
}

.chat-item.chat-item__self {
  align-self: flex-end;
  justify-content: flex-end;
  background-color: $primary-color;
  margin-left: auto;
  margin-right: 0;
  flex-direction: row-reverse;
  text-align: right;
}

.chat-item.chat-item__self .v-list__tile {
  align-items: flex-end;
  justify-content: flex-end;
}

.chat-item.chat-item__self
  .v-list__tile
  .v-list__tile__avatar.chat-item__author-photo {
  order: 2;
  margin-right: 0px;
  justify-content: flex-end;
}

.chat-item.chat-item__self
  .v-list__tile
  .v-list__tile__content.chat-item__body {
  order: 1;
  max-width: calc(100% - 112px);
}

.chat-item .v-list__tile .v-list__tile__content.chat-item__actions {
  align-items: flex-start;
  flex: 0 0 100%;
  order: 3;
}

.chat-item.chat-item__self
  .v-list__tile
  .v-list__tile__content.chat-item__actions {
  align-items: flex-end;
}

.bg-transparent {
  background-color: transparent;
}
</style>