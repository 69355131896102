<template>
  <VRow class="deliverable-settings justify-center">
    <VCol cols="6">
      <VCard
        class="px-2 pb-2"
        style="box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);"
      >
        <VCardText>
          <!-- <VRow>
            <VCol>
              <span class="label">
                Due On:
              </span>
              <br>
              <DateTimePicker
                v-model="form.due_on"
                label="Due On"
                :reactive="true"
                @input="updateDueOn"
              />
            </VCol>
          </VRow> -->
          <VRow>
            <VCol cols="6">
              <VCheckbox
                v-model="form.is_active"
                hide-details
                color="success"
                label="Accept Responses"
                class="checkbox-label mt-0"
              />
            </VCol>
            <VCol
              cols="6"
              class="d-flex justify-end"
            >
              <VCheckbox
                v-model="form.allow_multiple_submissions"
                hide-details
                color="success"
                label="Allow multiple submissions?"
                class="checkbox-label mt-0"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <span class="label">
                {{ featureName("Programs") }}
              </span>
            </VCol>
            <VCol
              cols="12"
              class="py-0"
            >
              <ChipsList
                model-type="programs"
                :normal-list="false"
                :items="form.programs"
                @addItems="onAddItems"
                @removeItem="onRemoveItem"
              />
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
      <FormDialog
        new-actions
        max-width="500px"
        button-text="Add"
        :can-delete="false"
        :is-dialog-open="dialogs.form"
        :model-type="dialogs.modelType"
        :do-save-action="saveAttachedElement"
        :title="`Attach ${getTitle}`"
        @toggle:form:dialog="dialogs.form = !dialogs.form"
      >
        <AttachAssociation
          v-model="defaultForm[dialogs.modelType]"
          class="px-3"
          :title="getTitle"
          :item="JSON.parse(JSON.stringify(form))"
          :custom-options="customOptions"
          :section="dialogs.modelType"
        />
      </FormDialog>
    </VCol>
  </VRow>  
</template>
<script>
import FormMixin from "@/mixins/Form";
export default {
  name: "DeliverableSave",
  props: {
    value: {
      type: Object,
      default: null,
    },
    customOptions: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      defaultForm: {
        supplements: [],
        programs: [],
        primary_contents: [],
      },
      dialogs: {
        form: false,
        modelType: "primary_contents",
      },
    };
  },
  computed: {
    getTitle() {
      let title =
        this.dialogs.modelType == "primary_contents"
          ? "Content"
          : this.dialogs.modelType;
      return this.featureName(title.capitalize());
    },
  },
  mounted() {
    this.form = this.value;
    this.options = this.customOptions;
  },
  methods: {
    onAddItems(section) {
      this.$bus.$emit("isFormReady", true);
      this.$bus.$emit("doneFormLoading");
      this.defaultForm[section] = this.form[section];
      this.dialogs.modelType = section;
      this.dialogs.form = !this.dialogs.form;
    },
    onRemoveItem(id, section) {
      this.form[section] = this.form[section].filter((item) => item.id != id);
    },
    saveAttachedElement() {
      this.form[this.dialogs.modelType] = this.defaultForm[this.dialogs.modelType];
      this.dialogs.form = !this.dialogs.form;
    },
    updateDueOn(datetime) {
      this.form.due_on = datetime;
    },
  },
};
</script>

<style lang="scss">
.deliverable-settings {
  .checkbox-label .v-label {
    font-size: 14px !important;
    color: #808080 !important;
    font-weight: 500;
  }
}
</style>