<template>
  <Chips
    :items="items"
    :as-button="asButton"
    :label="label"
    :outlined="outlined"
    :color="color"
    :empty-text="emptyText"
    :small="small"
  >
    <template #empty>
      <VTooltip top>
        <template #activator="{ on }">
          <VBtn
            small
            depressed
            color="error"
            v-on="on"
          >
            <VIcon>{{ icon }}</VIcon>
            &nbsp;{{ emptyText }}
          </VBtn>
        </template>
        <span>No one will be able to see this content!</span>
      </VTooltip>
    </template>
  </Chips>
</template>

<script>
export default {
  name: "VisibleToChips",
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    emptyText: {
      type: String,
      default: "(no one)"
    },
    outlined: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    label: {
      type: Boolean,
      default: false
    },
    asButton: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "info"

    },
    color: {
      type: String,
      default: "default"
    },
    textColor: {
      type: String,
      default: "auto"
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    clicked: function(item, event) {
      this.action(item);
      event.preventDefault();
    }
  }
};
</script>