<template>
  <AppLayout>
    <UserPanel
      slot="main-content"
      class="user-profile-page px-0"
    />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import UserPanel from "@/components/Account";

export default {
  name: "UserProfile",
  components: {
    UserPanel,
    AppLayout,
  },
  data() {
    return {};
  },
};
</script>

<style>
.user-profile {
  height: 100%;
}
</style>