<template>
  <div
    v-if="allInputs.length > 0"
    class="mt-4"
  >
    <div
      v-for="(inputs, groupName) in allInputsGrouped"
      :key="groupName"
    >
      <h1
        v-if="groupName && groupName != 'null'"
        class="title text-break"
      >
        {{ groupName }}
      </h1>
      <p
        v-if="hasGroupDescription(groupName)"
        v-html="groupDescription(groupName)"
        class="mt-2"
      />
      <VDivider
        v-if="(groupName && groupName != 'null') || hasGroupDescription(groupName) "
        class="my-4"
      />
      <div
        v-for="(input,key) in inputs"
        :key="key"
        :class="containerClass"
      >
        <span
          v-if="input.name"
          :class="itemTitleClass"
        >
          {{ input.name }}
          <VSpacer v-if="requiredText !== '*'" />
          <span
            v-show="input.is_required"
            style="color: red"
            label
          >
            {{ requiredText }}
          </span>
        </span>
        <p
          class="caption text-left d-block mb-2 pa-1"
          v-html="getInputCaption(input)"
        />
        <VInput
          v-show="false"
          v-model="input.name"
          type="hidden"
          :readonly="true"
          :placeholder="input.name || input.type.titleize()"
        />
        <template
          v-if="input.type === 'Textarea'"
          :class="containerClass"
        >
          <LongTextField
            v-model="input.value"
            outlined
            :type="input.type || 'text'"
            :placeholder="valuePlaceholder(input)"
            :disabled="readonly"
            :counter="getInputOptions(input).maxLength || 750"
            :max-length="getInputOptions(input).maxLength || 750"
            :use-words="getInputOptions(input).useWords || false"
            :error="$vError(getInputKey(input))"
            @blur="updateInput(getInputKey(input), input)"
          >
            <VIcon slot="append">
              {{ input.icon }}
            </VIcon>
          </LongTextField>
        </template>
        <template v-else-if="isFile(input)">
          <FileUpload
            v-if="!readonly"
            v-model="input.filename"
            slot="append"
            background-color="primary"
            color="primary"
            :disabled="readonly"
            :error="$vError(getInputKey(input))"
            :ref="`fileUpload${input.realIndex}`"
            @blur="updateInput(getInputKey(input), input)"
            @formData="(file) => onAddFile(getInputKey(input), file, input)"
          />
          <FilesList
            :files="[input.value instanceof Object ? input.value : {}]"
            @removeFile="file => onRemoveFile(getInputKey(input), file, input, `fileUpload${input.realIndex}`)"
          />
        </template>
        <template v-else-if="isGoogleFile(input)">
          <GoogleDriveUI
            v-if="!readonly"
            v-model="input.filename"
            style="text-align: right"
            mode="inline"
            button-class="px-4 dashed text-right"
            :rounded="true"
            :outlined="true"
            :can-connect-on-no-token="true"
            :disabled="readonly"
            :error="$vError(getInputKey(input))"
            @addFile="file => onAddFile(getInputKey(input), file, input)"
            @blur="updateInput(getInputKey(input), input)"
          />
          <FilesList
            :files="[input.value instanceof Object ? input.value : {}]"
            @removeFile="file => onRemoveFile(getInputKey(input), file, input, `fileUpload${input.realIndex}`)"
          />
        </template>
        <template v-else-if="input.type == 'Rating'">
          <VRating
            v-model.number="input.value"
            large
            clearable
            slot="append"
            background-color="primary"
            color="primary"
            class="mb-4"
            :disabled="readonly"
            :error="$vError(getInputKey(input))"
            :length="input.options.length || 5"
            @blur="updateInput(getInputKey(input), input)"
          />
        </template>
        <template v-else-if="isOptionsInput(input.type)">
          <template v-if="(input.options instanceof Object)">
            <VSelect
              v-if="input.options.type === 'Dropdown'"
              v-model="input.value"
              outlined
              small
              dense
              :multiple="isOptionMultiple(input)"
              :placeholder="valuePlaceholder(input)"
              :items="getInputOptionsOptions(input)"
              :error="$vError(getInputKey(input))"
              @blur="updateInput(getInputKey(input), input)"
            >
              <template #item="option">
                <VTooltip
                  top
                  right
                >
                  <template #activator="{ on }">
                    <span
                      v-on="on"
                      class="text-no-wrap; max-width: inherit"
                    >{{ option.item }}</span>
                  </template>
                  <span>{{ option.item }}</span>
                </VTooltip>
              </template>
            </VSelect>
            <VList v-else>
              <VListItem
                v-for="(choice, choiceKey) in getInputOptionsOptions(input)"
                :key="`choice-${key}-${choiceKey}`"
                @click="selectChoice(key, input, choice)"
              >
                <VListItemAvatar>
                  <VBtn
                    fab
                    small
                    elevation="0"
                    color="info"
                    style="cursor: pointer"
                  >
                    {{ 1+choiceKey }}
                  </VBtn>
                </VListItemAvatar>
                <VListItemAction v-if="(input.options || {}).type !== 'Dropdown'">
                  <VBtn
                    icon
                    slot="append-outer"
                  >
                    <!-- <template v-if="isOptionMultiple(input) ">
                      <VCheckbox
                        value
                        :input-value="isOptionSelected(input, choiceKey, choice)"
                        @change.stop="selectChoice(input, choice)"
                      />
                    </template>
                    <VSimpleCheckbox
                      v-else
                      class="v-radio"
                      :color="$vError(getInputKey(input)) ? 'error' : 'default'"
                      :value="isOptionSelected(input, choiceKey, choice)"
                    /> -->
                    <VIcon :color="getInputOptionColor(input, choiceKey, choice)">
                      {{ getOptionIcon(input, choiceKey, choice) }}
                    </VIcon>
                  </VBtn>
                </VListItemAction>
                <VListItemTitle class="pl-2 text-wrap">
                  {{ choice }}
                </VListItemTitle>
              </VListItem>
            </VList>
          </template>
        </template>
        <template v-else-if="input.type == 'Video_Link'">
          <VRow
            column
            fill-height
            class="pt-0 pb-6"
          >
            <VCol>
              <TextField
                v-model="input.value"
                filled
                placeholder="Url"
                label="Enter a url"
                @blur="updateInput(getInputKey(input), input)"
                :error="$vError(getInputKey(input))"
                :error-messages="$vError(getInputKey(input)) ? 'Enter valid URL' : ''"
              />
            </VCol>
            <VCol
              v-if="!$vError(getInputKey(input))"
              justify-content-center
              align-items-center
              sm="6"
              :class="{
                'responsive-video': input.value && input.value.length
              }"
              :style="{
                width: '100%',
                maxWidth: $vuetify.breakpoint.mdAndUp ? '1920px' : '100%',
                maxHeight: $vuetify.breakpoint.mdAndUp ? '100%' : ($vuetify.breakpoint.smAndDown ? '500px' : '375px')
              }"
            >
              <iframe
                v-if="input.value"
                allowfullscreen
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                style="margin-left: auto; margin-right: auto;"
                :src="getVideoUrl(input.value)"
              />
              <VAlert
                v-else
                type="info"
                :value="true"
              >
                Enter a url above
              </VAlert>
            </VCol>
          </VRow>
        </template>
        <template v-else-if="isUrlInput(input.type)">
          <template v-if="input.type != 'ActionUrl'">
            <p
              v-if="(getInputCaption(input) + '').length > 0"
              v-html="getInputCaption(input)"
              v-linkified
              class="caption pl-2"
            />
            <VAlert
              v-else
              type="info"
              :value="true"
            >
              Please return to this window/tab once you have completed the action below.
            </VAlert>
          </template>
          <VBtn
            small
            tile
            target="_blank"
            :href="(input.linked_metadata || {}).value || '#'"
            :text="!getInputOptions(input).asButton"
            :color="getInputOptions(input).backgroundColor"
            :class="{
              'py-0 px-2': !getInputOptions(input).asButton
            }"
            :style="{
              color: getInputOptions(input).textColor
            }"
            @click.prevent="onHandleMetadataAction(input)"
          >
            {{ input.name }}
            <VIcon
              small
              class="ml-1"
            >
              launch
            </VIcon>
          </VBtn>
        </template>
        <template v-else>
          <TextField
            v-model="input.value"
            validate-on-blur
            :prepend-icon="(input.type || '').toLowerCase() == 'url' ? 'link' : ''"
            :key="key"
            :counter="!input.type || (input.type || 'text').toLowerCase() === 'text' ? getInputOptions(input).maxLength || 255 : null"
            :max-length="!input.type || (input.type || 'text').toLowerCase() === 'text' ? getInputOptions(input).maxLength || 255 : null"
            :use-words="getInputOptions(input).useWords || false"
            :type="(input.type || 'text').toLowerCase()"
            :pattern="(input.type || 'text').toLowerCase() === 'number' ? '[0-9]+' : ''"
            :placeholder="valuePlaceholder(input)"
            :error-messages="$vError(getInputKey(input)) ? valuePlaceholder(input) : ''"
            :disabled="readonly"
            :error="$vError(getInputKey(input))"
            @blur="updateInput(getInputKey(input), input)"
            @keypress="(input.type || 'text').toLowerCase() == 'number' ? numericOnly($event) : ``"
          >
            <VIcon slot="append">
              {{ input.icon }}
            </VIcon>
          </TextField>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DynamicInputsMixin from "@/mixins/DynamicInputs";
import DynamicInputsFormMixin from "@/mixins/DynamicInputsForm";
import MetadataMixin from "@/mixins/Metadata";
import { mapActions } from "vuex";
export default {
  name: "DynamicInputsSubmit",
  mixins: [DynamicInputsMixin, DynamicInputsFormMixin, MetadataMixin],
  data() {
    return {};
  },
  created() {
    if (this.mode === "standalone") {
      this.updateValidation();
    }
  },
  computed: {
    choices() {
      return this.choiceMode == "user"
        ? this.inputChoices
        : Object.assign({}, this.localChoices, this.inputChoices);
    },
    $v() {
      if (this.validator instanceof Array) {
        return this.validator;
      } else if (
        this.validator instanceof Object &&
        this.validator.$each instanceof Object &&
        this.validator.$each.$iter instanceof Object
      ) {
        return Object.values(this.validator.$each.$iter);
      } else {
        return [];
      }
    },
  },
  watch: {
    "$v.invalid": function (value) {
      if (value) {
        // this.updateValidation();
      }
    },
  },
  methods: {
    ...mapActions({
      doSaveActivity: "activity/save",
    }),
    onAddFile(key, file, input) {
      this.$set(input, "value", file);
      this.updateInput(key, input);
    },
    onRemoveFile(key, file, input, resetRef) {
      this.$set(input, "value", {});
      this.updateInput(key, input);
      if (resetRef && this.$refs.hasOwnProperty(resetRef)) {
        let ref = this.$refs[resetRef];
        ref = ref instanceof Array ? ref[0] : ref;
        this.$log.debug("[DynamicInputsSubmit]: Removing ", resetRef, ref);
        ref.onReset();
      }
    },
    onHandleMetadataAction(input) {
      this.doSaveActivity({
        subject_type: "metadata",
        subject_id: [input.id, input.linked_metadata_id],
        action: "click",
        log_name: "interactions",
      }).finally(() => {
        window.open(
          (input.linked_metadata || {}).value || input.value || "#",
          "_blank"
        );
      });
    },
    updateInput(key, value) {
      if (!this.readonly) {
        this.$emit("update:input", key, value);
        this.updateValidation();
      }
    },
    update(value) {
      if (!this.readonly) {
        this.$emit("input", this.value);
        this.updateValidation();
      }
    },
    getLabel(key, input) {
      return this.readonly ? `${this.inputName} ${key + 1}` : null;
    },
    selectChoice(inputIndex, input, value) {
      this.$nextTick(() => {
        if (input instanceof Object) {
          if (this.isOptionMultiple(input)) {
            if (!(input.value instanceof Array)) {
              this.$set(input, "value", []);
            }
            const index = input.value.indexOf(value);
            // If the value doesn't exist add it otherwise remove it
            if (index === -1) {
              input.value.push(value);
            } else {
              input.value.splice(index, 1);
            }
            const filtered = input.value.unique();
            this.$set(input, "value", !filtered.length ? [] : filtered);
          } else {
            this.$set(input, "value", value);
          }
          this.$set(
            input.options,
            "options",
            Vue.observable([...input.options.options])
          );
          if (!this.readonly) {
            this.updateInput(this.getInputKey(input), input);
          } else {
            this.allInputs.splice(this.getInputKey(input), 1, input);
          }

          this.value.splice(inputIndex, 1, input);
        }
      });
    },
    $vError(index) {
      return this.$v.length && this.$v[index] instanceof Object
        ? this.$v[index].$error
        : false;
    },
    $vKey(index) {
      let realIndex = index;
      if (this.sectionStart > 0) {
        realIndex = this.sectionStart + index;
      }
      return realIndex;
    },
    getInputKey(input) {
      if (input.id) {
        return input;
      }
      return this.mode === "standalone" ? input.realIndex : input.priority - 1;
    },
    getInputOptionColor(input, choiceKey, choice) {
      if (this.$vError(this.getInputKey(input))) {
        return "error";
      } else {
        return this.isOptionSelected(input, choiceKey, choice)
          ? "primary"
          : "default";
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  .caption {
    a {
      text-decoration: underline;
    }
  }
}
</style>