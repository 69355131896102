<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="table-heading,card"
  >
    <VCard
      class="fill-height"
      id="calendar-add-type-select"
    >
      <VCardText
        :style="{
          height: $vuetify.breakpoint.mdAndUp ? 'auto' : 'calc(100vh - 162px)'
        }"
      >
        <VContainer
          grid-list-lg
          fill-height
        >
          <VRow
            :wrap="$vuetify.breakpoint.mdAndUp"
            justify-center
            align-center
            fill-height
          >
            <VCol
              cols="12"
              md="6"
              class="text-sm-center"
              :style="{
                flex: 1,
                width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto'
              }"
            >
              <VBtn
                depressed
                block
                @click="onToggleGoogleDialog"
              >
                Google Calendar
              </VBtn>
            </VCol>
            <VCol
              cols="12"
              md="6"
              class="text-sm-center"
              :style="{
                flex: 1,
                width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto'
              }"
            >
              <VBtn
                depressed
                block
                @click="onToggleFormDialog"
              >
                Local Calendar
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
    </VCard>
  </VSkeletonLoader>
</template>
<script>
import Vue from "vue";
import ActivityMixin from "@/mixins/Activity.vue";

export default {
  name: "CalendarForm",
  mixins: [ActivityMixin],
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: "",
    },
    existing: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    onToggleFormDialog() {
      this.$emit("toggleLocalDialog");
    },
    onToggleGoogleDialog() {
      this.$emit("toggleGoogleDialog");
    },
  },
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";
.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}

@media (max-width: 768px) {
  #calendar-add-type-select .v-btn {
    height: 100% !important;
  }
}
#calendar-add-type-select .v-btn {
  padding: 25px;
  height: 200px;
}
</style>