<template>
  <VContainer
    fluid
    grid-list-lg
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone' && $isUserAdmin"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader />
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VCard>
          <VCardTitle style="height: auto;">
            <h2 class="display-1 text-break">
              {{ model.title }}
            </h2>
          </VCardTitle>
          <VCardText>
            <h3 v-if="model.city">
              {{ model.city.name }}
            </h3>
            <p
              class="formatted"
              v-html="model.content"
              v-linkified
            />
            <FilesList
              :readonly="true"
              :files="model.files"
            />
          </VCardText>
        </VCard>
      </VCol>
      <VCol
        cols="12"
        class="ml-2"
      >
        <AssociatedContent :model="model" />
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "FaqView",
  components: { AssociatedContent, ViewHeader },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "FAQ",
    };
  },
  methods: {
    ...mapActions({
      doGetFAQ: "faq/getOne",
    }),
    onGet(id) {
      return this.doGetFAQ(id);
    },
  },
};
</script>

