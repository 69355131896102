<template>
  <VContainer id="user-applications">
    <VRow>
      <VCol>
        <Submitted
          submitted-for="me"
          mode="embedded"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import Submitted from "@/components/Organization/Pipelines/Submitted";

export default {
  name: "PipelineApplications",
  components: {
    Submitted
  },
  data() {
    return {};
  }
};
</script>