<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      oauthToken: null,
      isLoading: true,
    };
  },
  computed: {
    hasToken() {
      return this.oauthToken !== null;
    },
  },
  created() {
    const promise = this.loadGoogleApi();
    this.$log.debug("Google Auth: Loading account information");
    promise.then((gapi) => {
      // if (!window.gapi) return console.warn("Google API not loaded");
      this.getConnectedAccount("google").then((account) => {
        if (account) {
          this.oauthToken =
            account && account.token ? account.token.token : this.oauthToken;
          this.isLoading = false;
          gapi.client.setToken({ access_token: this.oauthToken });
          /**
           * Make sure to update the token in a timely manner
           */
          const seconds =
            this.$timezone
              .moment(account.token.expires_in)
              .diff(this.$timezone.moment(), "seconds") - 1000;
          setTimeout(
            () =>
              this.getConnectedAccount("google").then((account) => {
                this.oauthToken = account
                  ? account.token.token
                  : this.oauthToken;
                gapi.client.setToken({
                  access_token: this.oauthToken,
                });
              }),
            seconds
          );
        }
      });

      this.config = {
        // The Google App ID
        appId: this.env.VUE_APP_GOOGLE_APP_ID,
        // The Browser API key obtained from the Google API Console.
        developerKey: this.env.VUE_APP_GOOGLE_DEVELOPER_KEY,
        // The Client ID obtained from the Google API Console. Replace with your own Client ID.
        clientId: this.env.VUE_APP_GOOGLE_CLIENT_ID,
        // Scope to use to access user's drive.
        scope: [
          "https://www.googleapis.com/auth/drive.file",
          "https://www.googleapis.com/auth/calendar",
          "openid",
          "profile",
          "emial",
        ],
      };
      this.$log.debug(this.config);
    });
  },
  methods: {
    ...mapActions({
      getConnectedAccount: "user/getConnectedAccount",
    }),
    async loadGoogleApi() {
      if (
        window.gapi instanceof Object &&
        window.gapi.client instanceof Object
      ) {
        this.$log.debug("Google Auth: Library is already loaded");
        return window.gapi;
      }
      await this.loadUrl().js("https://apis.google.com/js/api.js");
      await this.waitFor(() => window.gapi instanceof Object, 10000);
      window.gapi.load("client:auth2", () => {});
      await this.waitFor(() => window.gapi.client instanceof Object, 10000);
      this.$log.debug("Google Auth: Library loaded");
      return window.gapi;
    },
  },
};
</script>
<style>
.picker {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
}
</style>