<template>
  <Confirm
    ref="confirmDuplicate"
    confirm-color="info"
    cancel-color="disabled"
  />
</template>
<script>
export default {
  methods: {
    onDuplicateItem(
      params,
      userFunction = undefined,
      message = undefined,
      notify = true,
      addTempItem = false,
    ) {
      return new Promise((resolve, reject) => {
        const [item, callback, refId] =
          params instanceof Array ? params : [params];
        if (
          (item instanceof Object &&
            (item.id || item instanceof Function) &&
            (this.onDuplicate instanceof Function ||
              userFunction instanceof Function)) ||
          userFunction instanceof Function
        ) {
          item instanceof Object && this.$set(item, "isDuplicating", true);
          item instanceof Object &&
            item.id instanceof Object &&
            this.$set(item.id, "isDuplicating", true);
          params instanceof Object &&
            this.$set(
              params instanceof Array ? params[0] : params,
              "isDuplicating",
              true
            );
          const handler = userFunction || this.onDuplicate;
          const ref =
            this.$refs[refId] ||
            this.$refs.confirmAction ||
            this.$refs.confirmDuplicate;
          const realItem =
            item instanceof Function
              ? item.call()
              : item instanceof Object && item.id instanceof Object
              ? item.id
              : item;
          realItem instanceof Object &&
            this.$set(realItem, "isLoading", true);
          params instanceof Object &&
            this.$set(
              params instanceof Array ? params[0] : params,
              "isLoading",
              true
            );
          const handlerPayload = item instanceof Function
              ? item.call()
              : item instanceof Object && item.items
              ? item
              : {
                  items:
                    item instanceof Array
                      ? item
                      : [item instanceof Object ? item.id : item],
                };
          handlerPayload.identifier = item.id + "-" + Date.now();
          handler(handlerPayload, params)
            .then((result) => {
              if (result) {
                // this.data.data = this.data.data.filter(current =>
                // current.id != item.id);
                if (callback instanceof Function) {
                  callback(realItem);
                }
                if(addTempItem && result.data instanceof Array && result.data.length === 0) {
                  const newTempItem = {
                    ...params,
                    isDuplicating: false,
                    processingDuplicate: true,
                    title: "Copy of " + params.title,
                    identifier: handlerPayload.identifier,
                  };
                  this.$delete(newTempItem, "id");
                  this.data.data.unshift(newTempItem);
                  this.setGroupItemLocalStorage("pending-duplications", this.modelType.singularize(), newTempItem.identifier, newTempItem);
                }
                if (notify !== false) {
                  this.$bus.$emit(
                    "notificationMessage",
                    `${this.featureName(this.modelType || "", "singularize").capitalize()} duplication is in-progress`
                  );
                }
                this.$emit("duplicated", result.data[0]);
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.log("Duplication error: ", error);
              this.$bus.$emit(
                "notificationError",
                `Problem duplicating ${this.featureName(this.modelType || "", "singularize").toLowerCase()}`
              );
            })
            .finally(() => {
              realItem instanceof Object &&
                this.$delete(realItem, "isDuplicating");
              params instanceof Object &&
                this.$delete(
                  params instanceof Array ? params[0] : params,
                  "isDuplicating"
                );
              realItem instanceof Object &&
                this.$delete(realItem, "isLoading");
              params instanceof Object &&
                this.$delete(
                  params instanceof Array ? params[0] : params,
                  "isLoading"
                );
            });
        } else {
          resolve(false);
        }
      });
    },
    onConfirmAction(params, handler, message, notify) {
      return new Promise((resolve, reject) => {
        const [item, callback] = params instanceof Array ? params : [params];
        if (
          (item && item.id && handler instanceof Function) ||
          handler instanceof Function
        ) {
          this.$set(item, "isLoading", true);
          this.$set(
            params instanceof Array ? params[0] : params,
            "isLoading",
            true
          );
          return this.$refs.confirmAction
            .open("Confirm", message || "Are you sure?", {
              color: "info",
            })
            .then((result) => {
              if (result === true) {
                handler(item)
                  .then((result) => {
                    if (result) {
                      if (callback instanceof Function) {
                        callback(item);
                      }
                      if (notify !== false && result.message) {
                        this.$bus.$emit("notificationMessage", result.message);
                      }
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  })
                  .finally(() => {
                    this.$delete(item, "isLoading");
                    this.$delete(
                      params instanceof Array ? params[0] : params,
                      "isLoading"
                    );
                  });
              } else {
                this.$delete(item, "isLoading");
                this.$delete(
                  params instanceof Array ? params[0] : params,
                  "isLoading"
                );
                resolve(false);
              }
            });
        } else {
          resolve(false);
        }
      });
    },
  },
};
</script>