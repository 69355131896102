<template>
  <VCard class="current-rsvp-search-container">
    <VCardText style="padding: 0px">
      <TextField
        v-if="!readonly"
        v-model="filter.s"
        text
        filled
        class="search-rsvps"
        :label="`${label} ...`"
        @keyup.enter="onGetMore"
      >
        <template slot="append">
          <img
            style="cursor: pointer; width: 32px; height: 32px"
            src="@/images/search-icon.png"
            @click="onGetMore"
          >
          <VBtn
            v-if="hasFilterOptions"
            text
            small
            class="mt-0"
            :disabled="activity.isLoading"
            :color="hasFilter ? 'info' : 'default'"
            @click="filterDrawer = !filterDrawer"
          >
            <VIcon small>
              filter_list
            </VIcon>
          </VBtn>
        </template>
      </TextField>
      <div
        v-if="hasFilter"
        class="layout row justify-end py-1"
      >
        <CurrentFilter
          :filter="filter"
          :filter-values="filterValues"
          :is-loading="isSearching || activity.isLoading"
          @removeFilter="onRemoveFilter"
          @removeStrictMode="onRemoveStrictMode"
          @resetFilter="onResetFilter"
        />
      </div>
      <slot name="info" />

      <VRow
        v-if="activity.isLoading"
        row
        fluid
        justify-center
        class="mt-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
      <VList
        v-else-if="hasRsvps"
        three-line
      >
        <template v-for="(rsvp, index) in localRsvps">
          <slot
            name="rsvp"
            :rsvp="rsvp"
          >
            <VListItem
              :key="index"
              @click="false"
            >
              <VListItemAvatar>
                <img :src="(rsvp.user || {}).photo_url">
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle
                  :class="{
                    'pl-3': rsvp.calendar_entry.rsvp_mode === 'shift'
                  }"
                >
                  <template v-if="$user.id !== rsvp.user_id">
                    {{ (rsvp.user || {}).name }}
                  </template>
                  <template v-else>
                    <strong>You</strong>
                  </template>
                </VListItemTitle>
                <VListItemTitle
                  :class="{
                    'pl-3': mode === 'shift'
                  }"
                >
                  {{ rsvp.calendar_entry.title }}
                </VListItemTitle>
                <VListItemSubtitle>
                  <small>
                    {{ $timezone.formatDate(rsvp.calendar_entry.date) }}: {{ $timezone.formatTime(rsvp.calendar_entry.start_time) }}
                    <template v-if="rsvp.calendar_entry.end_time">to {{ $timezone.formatTime(rsvp.calendar_entry.end_time) }}</template>
                    &nbsp;
                    Calendar: {{ rsvp.calendar_entry.calendar ? rsvp.calendar_entry.calendar.title : 'Unknown' }}
                  </small>
                </VListItemSubtitle>
                <VListItemSubtitle v-if="!readonly && rsvp.calendar_entry.rsvp_mode === 'shift'">
                  <VBtn
                    v-show="canAccept(rsvp)"
                    depressed
                    outlined
                    color="success"
                    :loading="rsvp.isSaving"
                    :disabled="rsvp.isDeleting"
                    @click="onAcceptRsvp(rsvp)"
                  >
                    <VIcon>check_circle</VIcon>&nbsp;Accept
                  </VBtn>
                  <VBtn
                    v-show="canReject(rsvp)"
                    depressed
                    outlined
                    color="error"
                    :loading="rsvp.isSaving"
                    :disabled="rsvp.isDeleting"
                    @click="onRejectRsvp(rsvp)"
                  >
                    <VIcon>cancel</VIcon>&nbsp;Decline
                  </VBtn>
                  <VChip
                    color="success"
                    text-color="white"
                    v-if="rsvp.is_accepted"
                  >
                    Accepted: {{ $timezone.formatDateTime(rsvp.accepted_on) }}
                  </VChip>
                  <VChip
                    color="warning"
                    text-color="white"
                    v-if="rsvp.is_rejected"
                  >
                    Declined: {{ $timezone.formatDateTime(rsvp.rejected_on) }}
                  </VChip>
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <template>
                  <VChip
                    v-if="rsvp.calendar_entry.rsvp_mode === 'standard'"
                    small
                    class="mr-0"
                  >
                    {{ (rsvp.status || 'maybe').titleize() }}
                  </VChip>
                  <VIcon
                    v-else
                    small
                    class="mr-0"
                    :color="rsvp.is_accepted ? 'success' : 'error'"
                  >
                    {{ rsvp.is_accepted ? 'check_circle' : (rsvp.is_rejected ? 'block' : '') }}
                  </VIcon>
                </template>
                <VBtn
                  v-if="!readonly"
                  icon
                  text
                  small
                  pr-2
                  :loading="rsvp.isDeleting || rsvp.isSaving"
                  @click="onDeleteItem(rsvp)"
                >
                  <VIcon>close</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
            <VDivider
              v-if="index + 1 < localRsvps.length"
              style="flex: 1 1 100%"
              :key="`divider-${index}`"
            />
          </slot>
        </template>
      </VList>
      <VAlert
        v-else
        :value="true"
        type="info"
      >
        No {{ featureName('RSVP').pluralize() }}
      </VAlert>
      <Confirm ref="confirmDelete" />
    </VCardText>
    <VNavigationDrawer
      v-model="filterDrawer"
      temporary
      clipped
      right
      fixed
      hide-overlay
      width="640px"
    >
      <slot name="filters">
        <FilterOptions
          v-model="filter"
          :filters="allFilters"
          @filter="onFilterData(true)"
          @toggle="filterDrawer = !filterDrawer"
        />
      </slot>
    </VNavigationDrawer>
  </VCard>
</template>

<script>
import FilterOptions from "./FilterOptions";
import CanRSVPMixin from "@/mixins/CanRSVP";
import DeletesItemsMixin from "@/mixins/DeletesItems.vue";
import CurrentFilter from "./CurrentFilter";
export default {
  name: "AdminRSVPList",
  mixins: [CanRSVPMixin, DeletesItemsMixin],
  components: { FilterOptions, CurrentFilter },
  data() {
    return {
      dialogs: {
        detail: false,
      },
      currentEvent: {},
      options: {
        statuses: {
          yes: "Yes",
          maybe: "Maybe",
          no: "No",
        },
      },
    };
  },
  methods: {
    onDelete(rsvp) {
      return this.doDeleteRsvp(rsvp);
    },
    onGetAll(params) {
      params.for = "admin";
      return this.doGetCalendarEntryRSVPs(params).then((result) => {
        this.localSearchData = result;
        return result;
      });
    },
    onUpdateRsvp(rsvp) {
      this.$set(this.activity.isSaving, rsvp.id, true);
      return this.doSaveCalendarEntryRSVPStatus({
        id: rsvp.id,
        calendar_entry_id: rsvp.calendar_entry_id,
        status: rsvp.status,
      }).then((result) => {
        this.onAfterUpdate(result.data.data);
        this.$bus.$emit(
          "notificationMessage",
          `${featureName("RSVP")} updated successfully!`
        );
      });
    },
    onAfterUpdate(rsvp) {
      // Depending on the mode of the RSVP reject other RSVPs if this once is accepted
      if (rsvp.calendar_entry.rsvp_is_limited_to_single && rsvp.is_accepted) {
        this.localRsvps.map((r) => {
          if (r.user_id != rsvp.user_id) {
            this.$set(r, "is_accepted", false);
            this.$set(r, "is_rejected", true);
            this.$set(r, "accepted_on", null);
            this.$set(r, "rejected_on", Date.now());
          }
          return r;
        });
      }
      const existing = this.localRsvps.find((r) => r.id === rsvp.id);
      this.$delete(existing, "isSaving");
      // const index = this.localRsvps.findIndex(r => r.user_id === rsvp.user_id);
      // this.rsvps.splice(index, rsvp);
    },
    canReject(rsvp) {
      if (!rsvp) return false;
      const canReject =
        rsvp.calendar_entry.can_rsvp &&
        ((rsvp.is_rejected === false && rsvp.is_accepted === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_accepted === undefined ||
          rsvp.is_accepted === null);
      return canReject;
    },
    canAccept(rsvp) {
      if (!rsvp) return false;
      const canAccept =
        rsvp.calendar_entry.can_rsvp &&
        ((rsvp.is_accepted === false && rsvp.is_rejected === true) ||
          (rsvp.is_rejected === false && rsvp.is_accepted === false) ||
          rsvp.is_rejected === undefined ||
          rsvp.is_rejected === null);
      return canAccept;
    },
  },
};
</script>

<style lang="scss">
.current-rsvp-search-container {
  .v-list--three-line .v-list__tile {
    min-height: 96px;
    height: auto;
  }
}
</style>
