<template>
  <div class="alerts">
    <VSnackbar
      v-if="allMessages && allMessages.length && snackbar"
      top
      multi-line
      :color="messageColor"
      :elevation="6"
      :value="snackbar"
      :timeout="timeout"
    >
      <span v-html="allMessages instanceof Array ? allMessages.join('<br/>') : allMessages" />
      <template
        #action
        icon
        text
        color=""
      >
        <VIcon
          @click="snackbar = false"
        >
          highlight_off
        </VIcon>
      </template>
    </VSnackbar>
    <VAlert
      v-if="allErrors && allErrors.length"
      :class="{
        'response-alert': true,
        'adjust-alert-icon': alignIcon,
      }"
      :value="true"
      :type="errorColor"
    >
      <p
        style="margin-bottom: 0px; word-break: break-word"
        v-html="allErrors instanceof Array ? allErrors.join('<br/>') : allErrors"
      />
      <slot name="alert-message-action" />
    </VAlert>
  </div>
</template>

<script>
export default {
  name: "Alerts",
  props: {
    timeout: {
      type: Number,
      default: 5000
    },
    errors: {
      type: [Array, String],
      default: () => []
    },
    messages: {
      type: [Array, String],
      default: () => []
    },
    errorColor: {
      type: String,
      default: "warning"
    },
    messageColor: {
      type: String,
      default: "info"
    },
    alignIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false
    };
  },
  computed: {
    allMessages() {
      return (this.messages instanceof Array
        ? this.messages
        : [this.messages]
      ).filter(m => typeof m === "string" && m.length > 0);
    },
    allErrors() {
      return (this.errors instanceof Array
        ? this.errors
        : [this.errors]
      ).filter(m => typeof m === "string" && m.length > 0);
    }
  },
  watch: {
    messages: function(value) {
      if (value instanceof Array && value.length) {
        this.snackbar = true;
      }
    }
  },
  mounted() {
    this.snackbar = (this.messages || []).length > 0;
  }
};
</script>

<style lang="scss">
.response-alert {
  .v-alert__content {
    display: flex;
    align-items: center;
  }
}
.adjust-alert-icon {
  .v-alert__icon.v-icon {
    padding-top: 8px;
  }
}
</style>