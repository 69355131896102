<template>
  <VNavigationDrawer
    right
    absolute
    floating
    temporary
    width="290"
    height="auto"
    id="profile-menu-panel"
    class="pa-1 bg-transparent"
    :style="{
      overflow: 'hidden',
      right: $vuetify.breakpoint.smAndDown ? '0px' : '32px',
      borderRadius: '10px'
    }"
    :value="active"
    :mobile-breakpoint="959"
    :disable-resize-watcher="false"
  >
    <VCard class="bg-transparent">
      <VCardTitle
        class="bg-transparent no-border-radius no-border"
        @click.stop="$emit('toggle')"
      >
        <VBtn
          text
          icon
          width="40px"
          height="40px"
          color="white"
          style="position: absolute; right: 0px"
          @click.stop="$emit('toggle')"
        >
          <VAvatar
            style="border-radius: 10px; cursor: pointer"
            size="40"
          >
            <img :src="userAvatar">
          </VAvatar>
        </VBtn>
      </VCardTitle>
      <VCardText
        class="pa-1 elevation-1 border-radius"
        style="background-color: #fff; border-bottom: none"
      >
        <VList v-if="$vuetify.breakpoint.smAndDown">
          <VListItem
            justify-space-between
            class="d-flex"
          >
            <VBtn
              x-small
              width="40px"
              height="40px"
              :class="{
                'mr-2': true,
              }"
              :style="{
                backgroundColor: $intercom.visible ? '#ddd' : '#fff'
              }"
              @click="$bus.$emit('toggleIntercom')"
            >
              <VIcon>
                mdi-help
              </VIcon>
            </VBtn>
            <ChatNotification
              mode="link"
              :channels="[`chat.${$user.id}`]"
            />
            <VBtn
              v-if="isEnabledFeature('calendar')"
              color="primary"
              text
              icon
              @click="navigateTo({
                name: 'calendar.index',
                hash: $user.new_rsvps_count ? '#rsvps' : ''
              })"
            >
              <!-- <img
                  style="margin-left: 6px; width: 14px"
                  src="./../images/calendar.png"
                      >-->
              <Badge
                top
                right
                overlap
                offset-y="50%"
                color="primary"
                :value="$user.new_rsvps_count > 0"
              >
                <template #badge>
                  !
                </template>
                <VIcon
                  large
                  small
                  color="primary"
                  style="font-size: 24px"
                  :size="24"
                >
                  calendar_today
                </VIcon>
              </Badge>
            </VBtn>
            <FeedNotification
              mode="link"
              :channels="[`users.${$user.id}`]"
              @toggle="onToggleNotifications"
            />
          </VListItem>
        </VList>
        <VList two-line>
          <VListItem>
            <VListItemContent>
              <VListItemTitle>
                {{ $user.name }}
                <RoleChip
                  v-if="$hasTeams"
                  small
                  class="ml-2"
                />
              </VListItemTitle>
              <VListItemSubtitle class="info--text">
                {{ $user.email }}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
          <VListItem v-if="$hasTeams && $team.is_enterprise && !$isUserApproved">
            <VListItemContent>
              <TeamMenuActivator
                block
                top
                image-height="60px"
                @click="$bus.$emit('toggleTeamsDialog')"
              />
            </VListItemContent>
          </VListItem>
        </VList>
        <div style="max-height: calc(100vh - 264px); overflow-y: auto">
          <template v-for="(list, type) in items">
            <template v-if="list.length">
              <VList 
                dense 
                :key="`menu-${type}`"
              >
                <template v-for="(item, i) in list">
                  <VListGroup 
                    v-if="(item.children || []).length > 0"
                    :value="true"
                    :key="`menu-item-group-${i}`"
                  >
                    <template #activator>
                      <VListItemAvatar
                        v-if="item.icon"
                        tile
                      >
                        <img
                          style="width: 20px; height: auto"
                          :src="item.icon"
                        >
                      </VListItemAvatar>
                      <VListItemTitle 
                        class="profile-menu"
                        @click.stop="navigateTo(item.routeName || item.route || item.href, item.target)"
                      >
                        <BetaBadge
                          enable-tooltip
                          style="margin-top: 0px"
                          :is-beta="item.beta"
                        >
                          {{ item.title }}
                        </BetaBadge>
                      </VListItemTitle>
                    </template>
                    <VListItem
                      v-for="(child, childIndex) in item.children || []"
                      class="sub-menu"
                      :key="`sub-menu-${childIndex}`"
                      :value="true"
                      @click.stop="navigateTo(child.routeName || child.route || child.href, child.target)"
                    >
                      <VListItemAvatar
                        v-if="child.icon"
                        tile
                      >
                        <img
                          style="width: 20px; height: auto"
                          :src="child.icon"
                        >
                      </VListItemAvatar>
                      <VListItemTitle class="profile-menu">
                        <BetaBadge
                          enable-tooltip
                          style="margin-top: 0px"
                          :is-beta="child.beta"
                        >
                          {{ child.title }}
                        </BetaBadge>
                      </VListItemTitle>
                    </VListItem>
                  </VListGroup>
                  <VListItem 
                    v-else-if="item.header" 
                    :key="i"
                  >
                    <VListItemContent>
                      <VListItemTitle class="profile-menu">
                        {{ item.header.toUpperCase() }}
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem
                    v-else
                    link
                    :value="true"
                    :key="`menu-item-${i}`"
                    @click.stop="navigateTo(item.routeName || item.route || item.href, item.target)" 
                  >
                    <VListItemAvatar
                      v-if="item.icon"
                      tile
                    >
                      <img
                        style="width: 20px; height: auto"
                        :src="item.icon"
                      >
                    </VListItemAvatar>
                    <VListItemTitle class="profile-menu">
                      <BetaBadge
                        enable-tooltip
                        style="margin-top: 0px"
                        :is-beta="item.beta"
                      >
                        {{ item.title }}
                      </BetaBadge>
                    </VListItemTitle>
                  </VListItem>
                </template>
              </VList>
              <VDivider
                color="primary"
                :key="type"
              />
            </template>
          </template>
        </div>
        <VList>
          <VListItem @click="logout">
            <VListItemContent class="profile-menu">
              <VBtn block>
                Logout
              </VBtn>
            </VListItemContent>
          </VListItem>
          <VListItem
            class="small pl-2"
            style="min-height: 24px; height: 24px"
          >
            <VListItemTitle class="text-right">
              <small style="font-size: 0.65rem">
                Version:
                <strong>{{ env.version }}</strong>
              </small>
            </VListItemTitle>
          </VListItem>
        </VList>
      </VCardText>
    </VCard>
  </VNavigationDrawer>
</template>
<script>
import { mapActions } from "vuex";
import RoleChip from "@/components/Elements/RoleChip";
import ChatNotification from "@/components/Elements/Notifications/ChatNotification";
import FeedNotification from "@/components/Elements/Notifications/FeedNotification";
import TeamMenuActivator from "@/components/Elements/Notifications/TeamMenuActivator";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Object,
      default: () => [],
    },
  },
  components: {
    RoleChip,
    ChatNotification,
    FeedNotification,
    TeamMenuActivator,
  },
  computed: {
    userAvatar() {
      return this.$user ? this.$user.photo_url : "";
    },
    userName() {
      return this.$user ? this.$user.name : "Loading";
    },
  },
  methods: {
    ...mapActions({
      doLogout: "auth/logout",
    }),
    logout() {
      const params = {
        team: this.$team.slug,
      };
      this.doLogout().then(() => {
        this.$log.debug("App: Logout");
        this.$auth.token(null, "");
        this.$auth.logout({
          redirect: false,
        });
        window.location.href = "/login";
      });
    },
    onToggleNotifications() {
      this.$emit("toggle");
      this.$emit("toggleNotifications");
    },
  },
};
</script>
<style lang="scss">
#profile-menu-panel {
  .v-list {
    max-height: calc(100vh - 105px);
    overflow-y: auto;
  }
  .profile-menu {
    overflow: visible;
  }
}

.sub-menu {
  padding-left: 40px;
}

.dialogs {
  max-height: 70vh;
  overflow: auto;
}

.dialogs a {
  font-size: 13px !important;
}

.dialogs .dialog_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_meta .dialog_date {
  flex: 0 1 128px;
  text-align: right;
}

:root .dialogs .dialog_author-name {
  flex: auto;
  transition: font-weight 50ms linear;
}

:root .dialogs .dialog_author-name:hover {
  font-weight: bolder;
}

.notification .v-list__tile {
  height: auto !important;
  align-items: flex-start;
}

/* .notification:not(last-child)::after {
  width: 100%;
  height: 3px;
  border-top: solid thin #ddd;
  content: "";
  display: block;
  margin: 10px auto;
} */

.notification .v-list__tile__sub-title {
  overflow: visible;
  white-space: normal;
}

.notification .notification-body {
  white-space: pre-wrap;
}
</style>