<script>
import { mapActions } from "vuex";
import MetadataMixin from "@/mixins/Metadata";

export default {
  mixins: [MetadataMixin],
  created() {
    // this.modelType = this.contentTypeCamelized;
  },
  data() {
    return {
      colors: {
        font: {
          white: "#f6f6f6",
          black: "#0000008a",
        },
        border: {
          info: "#1976D2",
          infoButton: "#0b8ee1",
          infoButtonDefault: "#dcd9d98a",
          disabled: "#dcd9d98a",
          warning: "#FFB74D",
          success: "#43A047",
          error: "#E53935",
          card: "#eaeaea",
          default: "#f5f5f5",
        },
        background: {
          info: "#17a1fa",
          blue: "#009cf3",
          disabled: "#ddd",
          warning: "#f3cd42",
          success: "#66BB6A",
          default: "#fff",
          overdue: "#f7246e",
          incomplete: "#313f47",
          error: "#ff5252",
        },
      },
    };
  },
  computed: {
    contentType() {
      const parts = this.$route.name.split(".");
      let type;
      if (parts[0] === "present") {
        type = this.$route.params.type;
      } else {
        type = this.assessmentType || parts[0];
      }
      console.log("Content Type: ", type);
      switch (type.toLowerCase()) {
        /** So dumb but SugarJS singularizes Worksheet to Workshoot */
        case "worksheets":
        case "worksheet":
          type = "worksheet";
          break;
        case "program":
          type = "lesson";
          break;

        default:
          type = type.singularize();
          break;
      }
      return type.titleize();
    },
    contentTypeCamelized() {
      return this.contentType.camelize();
    },
    currentCardHasOptions() {
      return (
        this.currentCard instanceof Object &&
        this.currentCard.hasOptions === true
      );
    },
    hasCurrentCard() {
      return this.currentCard instanceof Object && this.currentCard.type;
    },
    cardChoices() {
      return {
        inputs: {
          title: "Request Information",
          items: [
            {
              text: "Choices",
              type: "Question",
              icon: "mdi-format-list-bulleted",
            },
            {
              text: "Question: Text",
              type: "Text",
              icon: "text_fields",
            },
            {
              text: "Question: Paragraph",
              type: "TextArea",
              icon: "input",
            },
          ],
        },
        outputs: {
          title: "Display Information",
          items: [
            {
              text: "Image",
              type: "Image",
              icon: "mdi-image-outline",
            },
            {
              text: "Video",
              type: "Video",
              icon: "mdi-movie-filter",
            },
            {
              text: "Title",
              type: "Title",
              icon: "title",
            },
            {
              text: "Paragraph",
              type: "Paragraph",
              icon: "notes",
            },
            {
              text: "Result",
              type: "Result",
              icon: "check_box",
            },
          ],
        },
      };
    },
    statusOptions() {
      return [
        {
          title: "Published",
          value: "published",
          visible: this.$isUserAdmin || this.$isUserMentor,
        },
        {
          title: "Draft",
          value: "draft",
          visible: this.$isUserAdmin || this.$isUserMentor,
        },
        {
          title: "Archived",
          value: "archived",
          visible: this.$isUserAdmin,
        },
      ].filter((option) => option.visible);
    },
  },
  methods: {
    ...mapActions({
      doSaveLesson: "lesson/save",
      doSaveWorksheet: "worksheet/save",
      doGetLesson: "lesson/getOne",
      doGetWorksheet: "worksheet/getOne",
      doToggleLesson: "lesson/toggle",
      doToggleLockLesson: "lesson/toggleLock",
      doToggleWorksheet: "worksheet/toggle",
      doToggleLockWorksheet: "worksheet/toggleLock",
      doSaveAssessmentCard: "assessmentCard/save",
    }),
    onGetAssessment(id) {
      const getter = `doGet${this.contentTypeCamelized}`;
      this.$log.debug("[HasAssessments]: Getter is ", getter);
      return this[getter](id).then((result) => {
        result.cards = result.cards || [];
        return result;
      });
    },
    onSaveAssessment() {
      this.form.supplements = this.prepareRelationIds(this.form.supplements);
      this.form.primary_contents = this.prepareRelationIds(
        this.form.primary_contents
      );
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.faqs = this.prepareRelationIds(this.form.faqs);
      delete this.form.user;
      this.prepareDueOn();
      this.form.type = this.contentType.parameterize();
      const saver = `doSave${this.contentTypeCamelized}`;
      const data = this.prepareFormModel(
        { ...this.form },
        {
          dontSave: [
            "all_cards",
            "team",
            "result_for_user",
            "variables",
            "prerequisites_for_user",
          ],
        }
      );

      if (data.prerequisites instanceof Array) {
        data.prerequisites = data.prerequisites.map((item, i) => {
          return {
            id: item.id,
            priority: i + 1,
          };
        });
      }
      return this.saveFormModel(this[saver], null, data);
    },
    onBackgroundSaveAssessment(immediately) {
      const form = { ...this.form };
      form.supplements = this.prepareRelationIds(this.form.supplements);
      form.primary_contents = this.prepareRelationIds(
        this.form.primary_contents
      );
      form.programs = this.prepareRelationIds(this.form.programs);
      form.faqs = this.prepareRelationIds(this.form.faqs);
      delete form.user;
      this.prepareDueOn();
      form.type = this.contentType.parameterize();
      const saver = `doSave${this.contentTypeCamelized}`;
      const data = this.prepareFormModel(
        { ...form },
        {
          dontSave: ["all_cards", "team", "result_for_user", , "variables"],
        }
      );
      return this.backgroundSaveFormModel(
        this[saver],
        null,
        data,
        null,
        immediately
      );
    },
    async onSaveCard(card, assessmentId, cardIndex, _isBlankCard = false) {
      card.assessment_id = assessmentId;
      this.$set(card, "isSaving", true);
      if(_isBlankCard) {
        card.priority = cardIndex + 1;
      } else {
        card.priority = card.priority || (cardIndex || this.form.cards.length - 1) + 1;
      }
      const cardResult = await this.doSaveAssessmentCard(card);
      const result = cardResult.data || cardResult;
      let currentIndex = cardIndex;
      result.isSaving = false;
      if (!card.id) {
        currentIndex = await this.onNewMetadata(
          { ...card, ...result },
          cardIndex,
          "cards"
        );
      } else {
        currentIndex = await this.onUpdateMetadata(result, result, "cards");
      }
      if (this.cards[currentIndex] instanceof Object) {
        this.$set(this.cards[currentIndex], "isSaving", false);
      }
      return [currentIndex, result];
    },
    onUpdateTitle(title) {
      this.title = title;
      this.$emit("title", title);
    },
    getLockIcon(isLocked) {
      return isLocked ? "lock" : "lock_open";
    },
    onToggleLocal(id) {
      const toggler = `doToggle${this.contentTypeCamelized}`;
      this.$log.debug("[HasAssessmntsMixin]: Toggling using", toggler);
      if (id instanceof Object) {
        this.$set(id, "isLoading", true);
      }
      return this[toggler](id)
        .then((result) => {
          return result;
        })
        .finally((result) => {
          if (id instanceof Object) {
            this.$delete(id, "isLoading");
          }
        })
        .catch((result) => {
          if (id instanceof Object) {
            this.$delete(id, "isLoading");
          }
        });
    },
    onToggleLockLocal(id) {
      const toggler = `doToggleLock${this.contentTypeCamelized}`;
      this.$log.debug("[HasAssessmntsMixin]: Toggling Lock using", toggler);
      if (id instanceof Object) {
        this.$set(id, "isLoading", true);
      }
      return this[toggler](id)
        .then((result) => {
          return result;
        })
        .finally((result) => {
          if (id instanceof Object) {
            this.$delete(id, "isLoading");
          }
        })
        .catch((result) => {
          if (id instanceof Object) {
            this.$delete(id, "isLoading");
          }
        });
    },
    getLessonStatus(item) {
      if (item.is_active && !item.is_locked) {
        return "published";
      } else if (item.is_locked) {
        return "draft";
      } else if (!item.is_active) {
        return "archived";
      }
    },
    onStatusChange(item, status) {
      if (status == "published") {
        item.is_active = true;
        item.is_locked = false;
      } else if (status == "draft") {
        item.is_locked = true;
        item.is_active = true;
      } else {
        item.is_active = false;
        // Setting is_locked to false to fix the issue of selecting "Archived" status of lesson card menu
        item.is_locked = false;
      }
      let params = {
        id: item.id,
        is_active: item.is_active,
        is_locked: item.is_locked,
      };
      this.doSaveLesson(params).then(() => {
        this.$bus.$emit("notificationMessage", `Successfully record updated`);
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>