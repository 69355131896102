import api from '../../services/content';
export default function (helper) {
  const _TYPES = {
    STORE_DELETE: `STORE_DELETE`
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('content');
  const state = helper.state();
  const getters = helper.getters();
  const actions = helper.actions({}, 'Content');
  const mutations = helper.mutations({
    [_TYPES.STORE_DELETE](state, data) {
      helper.utils.removeFromStateData(state, data.params);
      if (data.context) {
        helper.utils.updateStats(data.context, -1, 'content');
      }
    }
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}