<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
export default {
  mixins: [FormMixin],
  data() {
    return {
      editThreadMode: false,
      threadForm: {
        title: ""
      }
    };
  },
  methods: {
    ...mapActions({
      doSaveThread: "chat/saveThread"
    }),
    onToggleEditThread() {
      this.editThreadMode = !this.editThreadMode;
      this.threadForm = this.thread;
    },
    saveThread() {
      return this.saveFormModel(
        this.doSaveThread,
        null,
        this.threadForm
      ).finally(result => {
        this.editThreadMode = false;
        this.$bus.$emit("notificationMessage", "Succesfully updated thread!");
        this.$emit("updateThread", {
          title: this.threadForm.title
        });
        return result;
      });
    }
  }
};
</script>