<template>
  <Confirm ref="confirmAction" />
</template>
<script>
export default {
  data() {
    return {
      deleteAlertTitle: "Delete",
      deleteAlertMessage: "Are you sure you want to delete this forever? This action can not be undone.",
      deleteAlertOptions: {
        color: "red",
      },
    }
  },
  methods: {
    onDeleteItem(
      params,
      userFunction = undefined,
      message = undefined,
      notify = true,
    ) {
      return new Promise((resolve, reject) => {
        const [item, callback, refId] =
          params instanceof Array ? params : [params];
        if (
          (item instanceof Object &&
            (item.id || item instanceof Function) &&
            (this.onDelete instanceof Function ||
              userFunction instanceof Function)) ||
          userFunction instanceof Function
        ) {
          item instanceof Object && this.$set(item, "isDeleting", true);
          item instanceof Object &&
            item.id instanceof Object &&
            this.$set(item.id, "isDeleting", true);
          params instanceof Object &&
            this.$set(
              params instanceof Array ? params[0] : params,
              "isDeleting",
              true
            );
          const handler = userFunction || this.onDelete;
          const ref =
            this.$refs[refId] ||
            this.$refs.confirmAction ||
            this.$refs.confirmDelete;
          return ref
            .open(this.deleteAlertTitle, message || this.deleteAlertMessage, this.deleteAlertOptions)
            .then((result) => {
              const realItem =
                item instanceof Function
                  ? item.call()
                  : item instanceof Object && item.id instanceof Object
                  ? item.id
                  : item;
              if (result) {
                realItem instanceof Object &&
                  this.$set(realItem, "isLoading", true);
                params instanceof Object &&
                  this.$set(
                    params instanceof Array ? params[0] : params,
                    "isLoading",
                    true
                  );
                handler(item instanceof Function ? item.call() : item)
                  .then((result) => {
                    if (result) {
                      // this.data.data = this.data.data.filter(current =>
                      // current.id != item.id);
                      if (callback instanceof Function) {
                        callback(realItem);
                      }
                      if (notify !== false) {
                        this.$bus.$emit(
                          "notificationMessage",
                          result.message ||
                            `Deleted ${this.modelType ? this.featureName(this.modelType, "singularize") : ""} successfully!`
                        );
                      }
                      this.$emit("deleted", result.id);
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  })
                  .finally(() => {
                    realItem instanceof Object &&
                      this.$delete(realItem, "isDeleting");
                    params instanceof Object &&
                      this.$delete(
                        params instanceof Array ? params[0] : params,
                        "isDeleting"
                      );
                    realItem instanceof Object &&
                      this.$delete(realItem, "isLoading");
                    params instanceof Object &&
                      this.$delete(
                        params instanceof Array ? params[0] : params,
                        "isLoading"
                      );
                  });
              } else {
                realItem instanceof Object &&
                  this.$delete(realItem, "isDeleting");
                params instanceof Object &&
                  this.$delete(
                    params instanceof Array ? params[0] : params,
                    "isDeleting"
                  );
                resolve(false);
              }
            });
        } else {
          resolve(false);
        }
      });
    },
    onConfirmAction(params, handler, message, notify) {
      return new Promise((resolve, reject) => {
        const [item, callback] = params instanceof Array ? params : [params];
        if (
          (item && item.id && handler instanceof Function) ||
          handler instanceof Function
        ) {
          this.$set(item, "isLoading", true);
          this.$set(
            params instanceof Array ? params[0] : params,
            "isLoading",
            true
          );
          return this.$refs.confirmAction
            .open("Confirm", message || "Are you sure?", {
              color: "info",
            })
            .then((result) => {
              if (result === true) {
                handler(item)
                  .then((result) => {
                    if (result) {
                      if (callback instanceof Function) {
                        callback(item);
                      }
                      if (notify !== false && result.message) {
                        this.$bus.$emit("notificationMessage", result.message);
                      }
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  })
                  .finally(() => {
                    this.$delete(item, "isLoading");
                    this.$delete(
                      params instanceof Array ? params[0] : params,
                      "isLoading"
                    );
                  });
              } else {
                this.$delete(item, "isLoading");
                this.$delete(
                  params instanceof Array ? params[0] : params,
                  "isLoading"
                );
                resolve(false);
              }
            });
        } else {
          resolve(false);
        }
      });
    },
  },
};
</script>