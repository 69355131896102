<template>
  <VMenu
    v-if="hasUser"
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    class="pr-1"
  >
    <template #activator="{ on: slotOn }">
      <slot :on="slotOn">
        <VAvatar
          v-on="!disabled ? Object.assign(slotOn, activatorVOn) : {}"
          :x-small="xSmall"
          :small="small"
          :large="large"
          :x-large="xLarge"
          :selected="selected"
          :dark="dark"
          :light="light"
          :size="size"
          :disabled="disabled"
          :class="`mr-${spacing} mb-${spacing}`"
        >
          <VImg :src="user.photo_url">
            <template #placeholder>
              <img
                src="https://www.gravatar.com/avatar?s=75&d=mm"
                :style="{
                  width: `${size}px`,
                  height: `${size}px`
                }"
              >
            </template>
          </VImg>
        </VAvatar>
      </slot>
    </template>
    <UserViewDetails
      :user="user"
      :user-details="userDetails"
    />
  </VMenu>
</template>

<script>
import UserViewDetails from "./UserViewDetails";
export default {
  name: "UserAvatar",
  components: { UserViewDetails },
  props: {
    user: {
      type: Object,
      default: () => null
    },
    userDetails: {
      type: Array,
      default: () => []
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    size: {
      type: [Number, String],
      default: 32
    },
    spacing: {
      type: [Number, String],
      default: 1
    },
    activatorVOn: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    hasUser() {
      return this.user instanceof Object;
    }
  }
};
</script>
<style lang="scss">
:root {
  .v-badge {
    .v-chip {
      height: 16px !important;
      .v-chip__content {
        height: 16px;
        padding: 0 6px;
        text-transform: lowercase;
      }
    }
  }
}
</style>