<script>
import ExtendFrom from "vuetify/lib/components/VTextarea/VTextarea";
import TextFieldInputMixin from "@/mixins/TextFieldInput.vue";

export default {
  extends: ExtendFrom,
  mixins: [TextFieldInputMixin],
  props: {
    autoGrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>