<script>
export default {
  props: {
    filterMethod: {
      type: Function,
      default: null,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    customFilter: {
      type: Object,
      default: function () {
        return null;
      },
    },
    disableFilter: {
      type: Boolean,
      default: false,
    },
    filterOnFilterUpdate: {
      type: Boolean,
      default: false,
    },
    filterIsLoading: {
      type: Boolean,
      default: false,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {},
      searchString: "",
      canFilterOnUpdate: false,
      filterDrawer: false,
      filtersPersist: false,
      filterStatus: {
        submitText: "Filter",
        submitVariant: "info",
      },
      preparesQueryFiltersOnFirstLoad: true,
      firstFilter: true,
      filter: {
        filter: {},
        s: null,
      },
      tempFilter: {
        filter: {},
        s: null,
      },
      supportedQueryFilters: [
        "program",
        "programs",
        "lesson",
        "lessons",
        "deliverable",
        "deliverables",
        "faq",
        "faqs",
      ],
    };
  },
  watch: {
    customFilter: {
      deep: true,
      handler: function (value) {
        this.prepareCustomSearchFilters(value);
      },
    },
    filters: {
      deep: true,
      handler: function (value) {
        if (value instanceof Array) {
          this.prepareSearchFilters(value);
        }
      },
    },
    "config.filters": {
      deep: true,
      handler: function (value) {
        if (value instanceof Array) {
          this.prepareSearchFilters(value);
          this.prepareCustomSearchFilters(this.customFilter);
        }
      },
    },
  },
  computed: {
    hasFilter() {
      return (
        this.filterValues.length > 0 ||
        (this.filter.filter || {}).strict === true
      );
    },
    hasFilterOptions() {
      return (this.allFilters || []).length > 0;
    },
    allFilters() {
      return (this.config.filters || []).concat(this.filters).unique();
    },
    filterHasId() {
      return this.filter.id ? true : false;
    },
    filterInputsDisabled() {
      return this.filterIsLoading || (this.activity || {}).isLoading
        ? true
        : false;
    },
    filterOptions() {
      const options = {};
      Object.forEach(this.allFilters, (f, k) => {
        options[f.field] = f.options;
      });
      return options;
    },
    filterValues() {
      const values = [];
      if (this.filter.filter instanceof Object) {
        for (const field in this.filter.filter) {
          if (field === "undefined") {
            continue;
          }
          const filter = this.allFilters.find((f) => f.field == field);
          if (filter instanceof Object) {
            const value = this.filter.filter[field];
            if (value instanceof Array) {
              value.map((v) => {
                values.push({
                  label: filter.label,
                  field: field,
                  value: v,
                  text:
                    filter.options[0] instanceof Object
                      ? (filter.options.find((o) => o.id == v) || {}).title ||
                        (filter.options.find((o) => o.id == v) || {}).text ||
                        (filter.options.find((o) => o.id == v) || {}).name
                      : filter.options[v],
                });
              });
            } else {
              values.push({
                label: filter.label,
                field: field,
                value: value,
                text:
                  filter.options instanceof Object
                    ? filter.options[0] instanceof Object
                      ? (filter.options.find((o) => o.id == value) || {})
                          .title ||
                        (filter.options.find((o) => o.id == value) || {}).text
                      : filter.options[value] || value
                    : value,
              });
            }
          }
        }
      }
      return values.filter((v) => v.value != null);
    },
    getQueryFilterValues() {
      return this.prepareQueryFiltersValue();
    },
  },
  created() {
    if (this.preparesQueryFiltersOnFirstLoad) {
      this.prepareQueryFilters();
    }
    this.prepareCustomSearchFilters(this.customFilter);
    this.filterStatus.submitVariant = this.filter.id ? "success" : "primary";
  },
  methods: {
    onSyncFilterToForm(event) {
      this.canFilterOnUpdate = !this.hasQueryFlag("disable-filter-on-update");
      this.$nextTick(() => {
        this.form = { ...this.form, ...this.filter };
      });
    },
    hasQueryFlag(flag) {
      return this.$route.query.hasOwnProperty(flag);
    },
    setFilterValue(filter, target) {
      if (!filter.field) {
        this.$log.warn("[CanFilterMixin]: filter is undefined in", filter);
        return;
      }
      switch (filter.type) {
        case "array":
        case "checkbox":
        case "radio":
          this.$set(
            target,
            filter.field,
            this.filter.filter[filter.field] || []
          );
          break;

        default:
          this.$set(
            target,
            filter.field,
            this.filter.filter[filter.field] || undefined
          );
          break;
      }
      this.firstFilter = false;
    },
    getSanitizedSearchFilters() {
      if (this.filter.filter instanceof Object) {
        const newFilter = {};
        for (const filter in this.filter.filter) {
          if (["undefined"].indexOf(filter) === -1) {
            if (
              this.filter.filter[filter] !== undefined &&
              this.filter.filter[filter] !== null
            ) {
              newFilter[filter] = this.filter.filter[filter];
            }
          }
        }
        return newFilter;
      }
      return this.filter.filter || {};
    },
    prepareQueryFilters() {
      // To filter the items by the given query param
      let useStrict = false;
      this.$log.debug("Supported Query Filtes", this.supportedQueryFilters);
      for (const param of this.supportedQueryFilters) {
        this.$log.debug("Param", param);
        if (this.$route.query[param] != null) {
          // useStrict = true;
          const value = this.$route.query[param];
          this.filter.filter[param] = value instanceof Array ? value : [value];
        }
      }
      if (useStrict) {
        this.filter.filter.strict = true;
      }
    },
    prepareQueryFiltersValue() {
      let filterValues = [];
      if(Object.keys(this.$route.query).length) {
        for (const param of this.supportedQueryFilters) {
          if (this.filter.filter[param] != null) {
            filterValues.push({
              field: param,
              label: param.capitalize(),
              text: (this.$route.query || {}).name.capitalize(),
              value: this.$route.query[param],
            });
          }
        }
      }
      // Remove the query strings from url
      if(Object.keys(this.$route.query).length && Object.keys(this.filter.filter).length === 0) {
        this.$router.replace({'query': null});
      }
      return filterValues;
    },
    prepareCustomSearchFilters(value) {
      this.$nextTick(() => {
        if (value instanceof Object) {
          if (value.s) {
            this.$set(this.filter, "s", value.s);
          }
          if (value.filter instanceof Object) {
            const customFilter = {};
            Object.keys(value.filter).map((filter) => {
              customFilter[filter] = value.filter[filter];
            });
            this.$set(this.filter, "filter", {
              ...(this.filter.filter || {}),
              ...customFilter,
            });
          }
          if (this.filterOnFilterUpdate) {
            this.onFilterData(true);
          }
        }
      });
    },
    prepareSearchFilters(value) {
      this.$nextTick(() => {
        const newFilter = {};
        value.map((filter) => {
          this.setFilterValue(filter, newFilter);
        });
        this.$set(this.filter, "filter", {
          ...newFilter,
          ...((this.customFilter || {}).filter || {}),
        });
        if (this.filterOnFilterUpdate) {
          this.onFilterData(true);
        }
      });
    },
    onFilterData(force) {
      this.$emit("filterData", force);
    },
    onResetFilter() {
      if ((this.activity || {}).isLoading || this.isLoading) return;
      this.searchString = undefined;
      this.$set(this.filter, "s", undefined);
      const queryFilters = this.prepareQueryFiltersValue();
      if (this.filter.filter instanceof Object) {
        for (const filter in this.filter.filter) {
          if (filter !== "strict") {
            this.filter.filter[filter] = undefined;
            if(queryFilters.length) {
              this.$delete(this.filter.filter, filter);
            }
            // this.setFilterValue(
            //   this.filters.find(f => f.field == filter),
            //   this.filter.filter
            // );
          }
        }
      }
      this.onFilterData(true);
    },
    onRemoveStrictMode() {
      this.$delete(this.filter.filter, "strict");
      this.onFilterData(true);
    },
    onRemoveFilter(filter) {
      if ((this.activity || {}).isLoading || this.isLoading) return;
      if (this.filter.filter.hasOwnProperty(filter.field)) {
        if (filter.field === "s") {
          this.filter.s = null;
        } else {
          const existing = this.filter.filter[filter.field];
          if (existing instanceof Array) {
            this.filter.filter[filter.field] = existing.filter(
              (f) => (f instanceof Object ? f.value || f.id : f) != filter.value
            );
          } else {
            this.$delete(this.filter.filter, filter.field);
          }
        }
        if (
          (this.filter.filter[filter.field] instanceof Array &&
            !this.filter.filter[filter.field].length) ||
          !this.filter.filter[filter.field]
        ) {
          this.$delete(this.filter.filter, filter.field);
        }
        this.onFilterData(true);
      }
    },
  },
};
</script>