var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.editor instanceof Object))?_c('div',{staticClass:"editor",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.$emit('click')},"blur":function($event){return _vm.$emit('blur')}}},[_vm._t("title",function(){return [(_vm.label)?_c('h2',{staticClass:"caption mb-2"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),(_vm.menuPosition === 'bottom')?_vm._t("editor",function(){return [_c('editor-content',{class:{
        'editor__content': true,
        'editor-top': !_vm.readonly,
        'editor-top-readonly': _vm.readonly,
        'editor-error': _vm.error,
      },attrs:{"editor":_vm.editor}})]}):_vm._e(),(!_vm.readonly)?_c(_vm.editorType,{tag:"component",class:{
      menububble: _vm.hasMenuBubble
    },attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(defaultProps){return [_c('div',{class:{
        'is-active': defaultProps.menu.isActive,
        menububble: _vm.hasMenuBubble
      },style:(`left: ${defaultProps.menu.left}px; bottom: ${defaultProps.menu.bottom}px;`)},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(defaultProps.commands.link, _vm.linkUrl)}}},[_c('TextField',{ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.hideLinkMenu.apply(null, arguments)}},model:{value:(_vm.linkUrl),callback:function ($$v) {_vm.linkUrl=$$v},expression:"linkUrl"}},[_c('template',{slot:"append"},[_c('VBtn',{staticClass:"menububble__button",attrs:{"icon":"","small":"","type":"button","dark":_vm.hasMenuBubble},on:{"click":function($event){return _vm.setLinkUrl(defaultProps.commands.link, _vm.linkUrl)}}},[_c('VIcon',{attrs:{"small":"","color":"primary"}},[_vm._v(" save ")])],1),_c('VBtn',{staticClass:"menububble__button",attrs:{"icon":"","small":"","dark":_vm.hasMenuBubble,"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(defaultProps.commands.link, null)}}},[_c('VIcon',{attrs:{"small":"","color":"error"}},[_vm._v(" clear ")])],1)],1)],2)],1):_c('VRow',{class:{
          menubar: !_vm.hasMenuBubble
        },style:({
          'margin-bottom': _vm.menuPosition === 'bottom' ? '-16px !important' : '0px',
        }),attrs:{"row":"","wrap":""}},[_vm._t("buttons",null,null,defaultProps),_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.enableLinks)?_c('VBtn',_vm._g(_vm._b({class:{
                menubar__button: !_vm.hasMenuBubble,
                menububble_button: _vm.hasMenuBubble,
                'is-active': defaultProps.isActive.link(),
                'white--text': _vm.hasMenuBubble && !defaultProps.isActive.link(),
                'info--text': defaultProps.isActive.link()
              },attrs:{"text":"","icon":"","small":"","color":defaultProps.isActive.link() ? 'info' : (_vm.hasMenuBubble ? 'white' : 'default')},on:{"click":function($event){_vm.showLinkMenu(defaultProps.commands.link, defaultProps.getMarkAttrs('link'))}}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("link")])],1):_vm._e()]}}],null,true)},[_vm._v(" Link ")])],2),_c('br')],1)]}}],null,false,1458533770)}):_vm._e(),(_vm.menuPosition === 'top')?_vm._t("editor",function(){return [_c('editor-content',{class:{
        'editor__content': true,
        'editor-error': _vm.error,
      },attrs:{"editor":_vm.editor}})]}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }