<template>
  <div
    style="height: 100%; width: 100%"
    :class="{
      'summary-mode': isSummaryMode
    }"
  >
    <CalendarView
      ref="calendarView"
      :show-date="showDate"
      :events="preparedEvents"
      :show-event-times="false"
      :title="title"
      month-name-format="short"
      class="theme-default holiday-us-traditional holiday-us-official"
      @click-date="onCreateEvent"
      @click-event="onShowDetails"
    >
      <VRow
        row
        wrap
        slot="header"
        slot-scope="t"
        class="cv-header"
        :header-props="t.headerProps"
        align-center
        @input="setShowDate"
      >
        <VCol
          shrink
          v-if="calendarMode==='multiple' && $vuetify.breakpoint.mdAndDown"
        >
          <div style="max-width: 72px">
            <VBtn
              text
              color="primary"
              icon
              :small="$vuetify.breakpoint.smAndDown"
              @click="$emit('toggleDrawer', !drawer)"
            >
              <VIcon :small="$vuetify.breakpoint.smAndDown">
                calendar_today
              </VIcon>
            </VBtn>
          </div>
        </VCol>
        <VCol grow>
          <VRow
            row
            justify-center
            align-center
          >
            <VBtn
              text
              icon
              :disabled="!t.headerProps.previousPeriod"
              class="previousPeriod"
              :small="$vuetify.breakpoint.smAndDown"
              @click="$emit('input', t.headerProps.previousPeriod)"
            >
              <VIcon>keyboard_arrow_left</VIcon>
            </VBtn>
            <div class="periodLabel text-sm-center justify-center align-center">
              <slot name="label">
                <small>{{ t.headerProps.periodLabel }}</small>
              </slot>
            </div>
            <VBtn
              text
              icon
              :disabled="!t.headerProps.nextPeriod"
              class="nextPeriod"
              :small="$vuetify.breakpoint.smAndDown"
              @click="$emit('input', t.headerProps.nextPeriod)"
            >
              <VIcon>keyboard_arrow_right</VIcon>
            </VBtn>
          </VRow>
        </VCol>
        <VCol shrink>
          <VTooltip top>
            <template #activator="{on}">
              <VBtn
                icon
                text
                label="Summary mode"
                :small="$vuetify.breakpoint.smAndDown"
                @click="isSummaryMode = !isSummaryMode"
                v-on="on"
              >
                <VIcon>{{ isSummaryMode ? 'unfold_more' : 'unfold_less' }}</VIcon>
              </VBtn>
            </template>
            <span>Summary mode</span>
          </VTooltip>
        </VCol>
        <VCol
          :shrink="$vuetify.breakpoint.mdAndUp"
          :grow="$vuetify.breakpoint.smAndUp"
        >
          <VRow
            row
            align-center
            justify-flex-end
          >
            <VTooltip top>
              <template #activator="{on}">
                <VBtn
                  text
                  small
                  style="border: none"
                  label="RSVPs"
                  :color="$user.new_rsvps_count ? 'primary' : 'default'"
                  @click="onToggleRSVPDialog"
                  v-on="on"
                >
                  <Badge
                    top
                    right
                    color="primary"
                    :value="$user.new_rsvps_count > 0"
                  >
                    <BetaBadge
                      enable-tooltip
                      :is-beta="isBetaFeature('rsvp')"
                    >
                      <span :color="$user.new_rsvps_count ? 'primary' : 'black'">Invitations</span>
                    </BetaBadge>
                  </Badge>
                </VBtn>
              </template>
              <span>
                <template v-if="$user.new_rsvps_count > 0">You have new RSVPs!</template>
                <template v-else>You have no new RSVPs at this time</template>
              </span>
            </VTooltip>
            <VSpacer />
            <VTooltip
              v-if="$isUserAdmin && canCreateEvent"
              top
            >
              <template #activator="{on}">
                <VBtn
                  text
                  icon
                  @click="onCreateEvent(Date.now())"
                  v-on="on"
                >
                  <VIcon>event</VIcon>
                </VBtn>
              </template>
              <span>Add Event</span>
            </VTooltip>
            <VTooltip
              v-if="$isUserAdmin && canCreate"
              top
            >
              <template #activator="{on}">
                <VBtn
                  text
                  icon
                  :small="$vuetify.breakpoint.smAndDown"
                  @click="handleCreate"
                  v-on="on"
                >
                  <img
                    style="max-width: 22px"
                    src="../../images/add-yellow@2x.png"
                  >
                </VBtn>
              </template>
              <span>Add Calendar</span>
            </VTooltip>
          </VRow>
        </VCol>
      </VRow>
      <template
        v-if="$refs.calendarView"
        slot="event"
        slot-scope="e"
      >
        <div
          :key="e.event.id"
          :draggable="$refs.calendarView.enableDragDrop"
          :class="e.event.classes"
          :title="e.event.title"
          class="cv-event"
          :style="`top:${isSummaryMode ? 'auto' : $refs.calendarView.getItemTop(e.event)}`"
          @dragstart="$refs.calendarView.onDragStart(e.event, $event)"
          @mouseenter="$refs.calendarView.onMouseEnter(e.event)"
          @mouseleave="$refs.calendarView.onMouseLeave(e.event)"
          @click.stop="$refs.calendarView.onClickItem(e.event, isSummaryMode)"
        >
          <Badge
            v-if="isSummaryMode"
            overlap
            top
            color="info"
          >
            <span
              class="day"
              :style="`top:${$refs.calendarView.getItemTop(e.event)};${e.event.originalEvent.style}`"
            >{{ e.event.originalEvent.dom }}</span>
            <span slot="badge">{{ e.event.title }}</span>
          </Badge>
          <span
            v-else
            :style="`${e.event.originalEvent.style}`"
          >{{ e.event.title }}</span>
        </div>
      </template>
    </CalendarView>
    <Confirm ref="confirmDelete" />
    <Dialog
      :persistent="true"
      max-width="640px"
      :is-dialog-open="dialogs.form"
      title="What type of calendar would you like to add?"
      :hide-overlay="hideFormOverlay"
      @toggle:dialog="onToggleFormDialog"
      @deleteItem="onDeleteItem"
    >
      <CalendarForm
        ref="addCalendar"
        :entity="entity"
        :entity-type="entityType"
        @toggle:dialog="onToggleFormDialog"
        @toggleLocalDialog="onToggleLocalFormDialog"
        @toggleGoogleDialog="onToggleGoogleFormDialog"
        @savedCalendar="(result) => $emit('savedCalendar', result)"
      />
    </Dialog>
    <Dialog
      :persistent="true"
      max-width="640px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.daysEvents"
      :title="`Events ${currentDate}`"
      @toggle:dialog="onToggleDaysEventsDialog"
    >
      <EventList
        :events="currentDaysEvents"
        :calendar="calendar"
        :entity="entity"
        :entity-type="entityType"
      />
    </Dialog>
    <Dialog
      :persistent="true"
      max-width="640px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.detail"
      :title="currentEvent.title"
      @toggle:dialog="onToggleEventDetailDialog"
    >
      <EventView :event="currentEvent" />
    </Dialog>
    <Dialog
      :persistent="true"
      max-width="640px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.rsvps"
      :title="$isUserAdmin ? featureName('RSVP').pluralize() : `Your ${featureName('RSVP').pluralize()}`"
      @toggle:dialog="onToggleRSVPDialog"
    >
      <VTabs
        v-if="$isUserAdmin"
        class="pt-0"
      >
        <VTab target="#rsvps">
          Your {{ featureName('RSVP').pluralize() }}
        </VTab>
        <VTab target="#manage-rsvps">
          Manage Events
        </VTab>
        <VTabItem class="mt-2">
          <UserRSVPList />
        </VTabItem>
        <VTabItem class="mt-2">
          <AdminRSVPList />
        </VTabItem>
      </VTabs>
      <UserRSVPList v-else />
    </Dialog>
    <FormDialog
      v-if="currentModel"
      max-width="640px"
      model-type="CalendarEntry"
      :button-text="`Save and Notify Users`"
      :current-model="currentModel"
      :is-dialog-open="dialogs.event"
      :title="`Save Event ${currentModel ? currentModel.title || '' : ''}`"
      @toggle:form:dialog="onToggleEventFormDialog"
      @deleteItem="onDeleteEvent"
    >
      <EventForm
        mode="dialog"
        :id="currentModel && currentModel.id ? {
          id: currentModel.id,
          calendarId: currentModel.calendar_id || currentModel.calendarId
        } : null"
        :entity-calendars="calendar ? [calendar] : []"
        :entity-calendar-id="calendar ? calendar.id : null"
        :entity="entity"
        :entity-type="entityType"
        :user-form="currentModel && !currentModel.id ? currentModel : {}"
      />
    </FormDialog>

    <FormDialog
      max-width="640px"
      title="Add Calendar"
      :persistent="true"
      :is-dialog-open="dialogs.localForm"
      :hide-overlay="true"
      @toggle:form:dialog="onToggleLocalFormDialog"
      @deleteItem="(params) => $emit('deleteItem', params)"
      model-type="Calendar"
    >
      <LocalForm
        ref="addCalendar"
        mode="dialog"
        :use-id-from-route="false"
        :entity="entity"
        :entity-type="entityType"
        @toggle:dialog="$emit('toggle:dialog')"
        @savedCalendar="(result) => $emit('savedCalendar', result)"
      />
    </FormDialog>
    <FormDialog
      model-type="Calendar"
      max-width="640px"
      title="Add Google Calendar"
      :persistent="true"
      :is-dialog-open="dialogs.google"
      :hide-overlay="true"
      @toggle:form:dialog="onToggleGoogleFormDialog"
      @deleteItem="(params) => $emit('deleteItem', params)"
    >
      <GoogleForm
        ref="addCalendar"
        mode="dialog"
        :use-id-from-route="false"
        :entity="entity"
        :entity-type="entityType"
        @savedCalendar="(result) => $emit('savedCalendar', result)"
      />
    </FormDialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CalendarView } from "vue-simple-calendar";
import CalendarForm from "./Save";
import moment from "moment";
import DeletesItems from "@/mixins/DeletesItems";
import EventForm from "./SaveEvent";
import LocalForm from "./SaveLocal";
import GoogleForm from "./SaveGoogle";
import UserRSVPList from "@/components/Elements/Data/UserRSVPList";
import AdminRSVPList from "@/components/Elements/Data/AdminRSVPList";
import EventList from "./EventList";
import EventView from "./EventView";

export default {
  name: "Calendar",
  components: {
    CalendarView,
    CalendarForm,
    LocalForm,
    GoogleForm,
    UserRSVPList,
    AdminRSVPList,
    EventList,
    EventView,
    EventForm,
  },
  mixins: [DeletesItems],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    summaryMode: {
      type: Boolean,
      default: true,
    },
    groupBy: {
      type: String,
      default: "group",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    canCreateEvent: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: [Date, String],
      default: () => new Date(),
    },
    events: {
      type: Array,
      default: () => [],
    },
    calendar: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    entity: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: "",
    },
    calendarMode: {
      type: String,
      default: "multiple",
    },
    hideFormOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      drawer: false,
      dialogs: {
        form: false,
        daysEvents: false,
        event: false,
        detail: false,
        google: false,
        localForm: false,
        rsvps: false,
      },
      currentDaysEvents: [],
      currentDate: "",
      isSummaryMode: false,
      currentModel: null,
      currentEvent: {},
    };
  },
  computed: {
    preparedEvents() {
      let events = this.events;
      if (this.isSummaryMode === true) {
        events = {};
        for (const index in this.events) {
          const event = this.events[index];
          if (event instanceof Object) {
            const summary =
              events[event[this.groupBy]] ||
              Object.assign({}, event, {
                title: 0,
                events: [],
                startDate: event.originalDate,
                endDate: event.originalDate,
              });
            summary.title++;
            summary.events.push(event);
            events[event[this.groupBy]] = summary;
          }
        }
        this.$log.debug("Grouped events", events);
        events = Object.values(events);
      } else {
        events = events
          .filter((event) => event instanceof Object)
          .map((event) => {
            event.startDate = event.originalStartDate;
            event.endDate = event.originalEndDate || event.startDate;
            return event;
          })
          .unique((event) => {
            return event.sequenceOwnerId;
          });
      }
      return events;
    },
  },
  watch: {
    "$route.hash": function (value) {
      if (value == "#rsvps" || value == "#manage-rsvps") {
        this.onToggleRSVPDialog();
      }
    },
  },
  created() {
    this.isSummaryMode = this.summaryMode;
    // if(this.calendar) {
    //   this.currrentModel = {
    //     calendar_id: this.calendar.id
    //   };
    // }
    if (this.$route.hash == "#rsvps" || this.$route.hash == "#manage-rsvps") {
      this.onToggleRSVPDialog();
    }
  },
  methods: {
    ...mapActions({
      doSaveCalendarEntry: "calendarEntry/save",
      doDeleteCalendarEntry: "calendarEntry/delete",
    }),
    handleCreate() {
      this.dialogs.form = true;
    },
    onToggleFormDialog() {
      this.dialogs.form = !this.dialogs.form;
    },
    onToggleEventFormDialog() {
      this.dialogs.event = !this.dialogs.event;
    },
    onToggleDaysEventsDialog() {
      this.dialogs.daysEvents = !this.dialogs.daysEvents;
    },
    onToggleEventDetailDialog() {
      this.dialogs.detail = !this.dialogs.detail;
    },
    onToggleRSVPDialog() {
      this.dialogs.rsvps = !this.dialogs.rsvps;
    },
    onToggleLocalFormDialog() {
      this.dialogs.localForm = !this.dialogs.localForm;
      this.dialogs.form = false;
    },
    onToggleGoogleFormDialog() {
      this.dialogs.google = !this.dialogs.google;
      this.dialogs.form = false;
    },
    setShowDate(d) {
      this.showDate = d;
    },
    onShowDetails(event, summaryMode) {
      const isSummaryMode =
        summaryMode === undefined ? this.isSummaryMode : summaryMode;
      if (isSummaryMode) {
        this.$log.debug(event);
        this.currentDaysEvents = event.originalEvent.events;
        this.currentDate = event.originalEvent.date;
        this.dialogs.daysEvents = true;
      } else {
        this.currentEvent = event.originalEvent;
        this.dialogs.detail = true;
      }
    },
    onCreateEvent(date) {
      if (
        !this.$isUserAdmin ||
        this.readonly ||
        (this.calendar && this.calendar.calendar_type !== "local")
      ) {
        return false;
      }
      this.currentModel = {
        start_date: moment(date).format("YYYY-MM-DD"),
      };
      this.dialogs.event = true;
    },
    onDeleteEvent(event) {
      const message = event.hasEntries
        ? `DANGER: This event is linked to other events. Are you sure you want to delete it? Ideally you can adjust the start and end dates for this specific event.`
        : "Are you sure?";
      return this.onDeleteItem(event, this.doDeleteCalendarEntry, message).then(
        (result) => {
          if (result) {
            const index = this.currentDaysEvents.findIndex(
              (current) => current.id == event.id
            );
            if (index > -1) {
              this.currentDaysEvents.splice(index, 1);
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.cv-header {
  opacity: 0.5;
  border-bottom: solid 1px #d8d8d8;
  margin-bottom: 10px;
  padding: 0 6px 6px;
  button {
    border: none !important;
  }
}

.cv-event {
  cursor: pointer;
}

.cv-header .periodLabel {
  text-transform: uppercase;
  color: #000;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
}

.cv-header-days {
  color: #a4a6a9;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  border: none;
  border-color: transparent;
  padding: 15px 0 4px 0;
}

.cv-day,
.cv-event,
.cv-header-day,
.cv-header-days,
.cv-week,
.cv-weeks {
  border-color: transparent !important;
  border-style: solid;
}

.cv-day {
  align-items: center;
  justify-content: center;
  cursor: crosshair;
  height: 100%;
}

.cv-day .cv-day-number {
  position: relative;
  color: #333636;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}

.cv-day.outsideOfMonth .cv-day-number {
  color: #a4a6a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}

.cv-day.today,
.cv-day:hover {
  background-color: #eee;
}

.calendar {
  border-radius: 6px;
  background-color: #ffffff;
}

.summary-mode .cv-week {
  align-items: center;
  overflow: hidden;
}

.summary-mode .cv-day {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.summary-mode .cv-event {
  background-color: transparent;
  color: transparent !important;
  display: flex;
  justify-content: center;
  z-index: 1;
  overflow: visible;
}

.summary-mode .cv-event span.day {
  display: flex;
  align-items: center;
  justify-content: center;
  top: auto !important;
  border-radius: 2rem;
  height: 2rem;
  width: 2rem;
  z-index: 0;
  cursor: pointer;
}

.summary-mode .cv-day .cv-day-number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  z-index: 2;
}

.calendar-actions .v-btn--icon.v-btn--small {
  width: 24px;
  height: 24px;
}

.calendar-actions .v-btn--icon.v-btn--small .v-icon {
  font-size: 18px;
}
</style>