<script>
export default {
  data() {
    return {
      dialogs: {
        members: false,
      },
      memberDialog: {
        type: "mentors",
        existing: [],
      },
      isGroup: false,
    };
  },
  methods: {
    onFindMemberIndex(members, member, callback) {
      return members.findIndex((m) => {
        if (callback instanceof Function) {
          this.$log.debug(
            "HasMembers.onFindMemberIndex: Using custom callback to check for member",
            m
          );
          return callback(m);
        } else {
          this.$log.debug(
            "HasMembers.onFindMemberIndex: Using id matching for member",
            m
          );
          return m.id === (member instanceof Object ? member.id : member);
        }
      });
    },
    onAddMember(members, member, callback) {
      members = members instanceof Array ? members : [];
      const index = this.onFindMemberIndex(members, member, callback);
      if (index === -1) {
        members.push(member);
        this.$log.debug(
          "HasMembers.onAddMember: Added member",
          member,
          members
        );
      }
      return members;
    },
    onRemoveMember(members, member, callback, removeImediately) {
      this.$log.debug(
        "HasMembers.onRemoveMember: Removing member",
        callback instanceof Function,
        member
      );
      const index = this.onFindMemberIndex(members, member, callback);
      let action = "Unable to find";
      if (index > -1) {
        const existing = members[index];
        // Delete immediately if we ask or if the entry doesn't already exist in the database
        if (removeImediately || !member.id) {
          members.splice(index, 1);
          action = "Deleted";
        } else {
          member.deleted = !member.deleted;
          members.splice(index, 1, member);
          action = member.deleted ? "Deleted" : "Restored";
        }
      }
      this.$log.debug(
        `HasMembers.onRemoveMember: ${action} member`,
        member,
        members
      );
      return members;
    },
    onToggleMember(members, member, callback) {
      if (this.isGroup) {
        if (members.indexOf(member) !== -1) {
          members = this.filterMembers(members, member);
        } else {
          members.push(member);
        }
        this.$log.debug(
          "HasMembers.onToggleMember: Toggling member",
          member.id,
          members
        );
        return members;
      } else {
        try {
          this.$log.debug(
            "HasMembers.onToggleMember: Calling custom callback on member",
            member.id,
            members
          );
          callback(member);
        } catch (e) {}
      }
    },
    filterMembers(members, member) {
      return members.filter((val) => {
        if (val instanceof Object) {
          return val.id !== (member instanceof Object ? member.id : member);
        } else {
          return val !== (member instanceof Object ? member.id : member);
        }
      });
    },
    findMember(members, member) {
      const index = members.findIndex((val) => {
        if (val instanceof Object) {
          return val.id === (member instanceof Object ? member.id : member);
        } else {
          return val === (member instanceof Object ? member.id : member);
        }
      });
      if (index !== -1) {
        members.splice(index, 1);
      }
      return members;
    },
    onToggleMemberDialog(type) {
      const realType = type || this.memberDialog.type;
      this.dialogs.members = !this.dialogs.members;
      this.memberDialog.type = realType;
      this.memberDialog.existing = (this.form || this.value)[realType];
    },
  },
};
</script>