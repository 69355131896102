<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow class="content-save">
      <VCol>
        <VCard>
          <VCardTitle
            class="px-2"
            style="height: 100% !important; border: none !important;"
          >
            <TextField
              v-model="form.title"
              label="Title"
              hide-details
              placeholder="Enter title..."
              :error="$v.form.title.$error"
              @input="$v.form.title.$touch()"
              @blur="$v.$touch()"
            />
          </VCardTitle>
          <VCardText class="px-3">
            <VRow>
              <VCol>
                <Editor
                  v-model="form.content"
                  auto-grow
                  filled
                  outlined
                  placeholder="Type content here..."
                  class="content-editor"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <span class="label">
                  {{ featureName("Programs") }}
                </span>
              </VCol>
              <VCol
                cols="12"
                class="py-0"
              >
                <ChipsList 
                  model-type="programs"
                  :normal-list="false"
                  :items="form.programs"
                  @addItems="onAddItems"
                  @removeItem="onRemoveItem"
                />
              </VCol>
            </VRow>
            <VRow>
              <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
              <VCol>
                <GoogleDriveUI
                  style="text-align: right"
                  button-class="px-4 dashed text-right"
                  :rounded="true"
                  :outlined="true"
                  :can-connect-on-no-token="true"
                  :files="form.files"
                  @addFile="onAddFile"
                  @removeFile="onRemoveFile"
                />
              </VCol>
              <VCol>
                <FileUpload
                  text="Attach Files"
                  style="text-align: right"
                  button-class="px-4 dashed text-right"
                  icon="cloud_upload"
                  :as-button="true"
                  :outlined="true"
                  :rounded="true"
                  :multiple="true"
                  :files="form.files"
                  @removeFile="onRemoveFile"
                  @formData="onAddFile"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <DynamicInputs
                  v-model="form.metadata"
                  title="Add Fields"
                  :card="false"
                  :input-name="featureName('Content')"
                  :enable-files="true"
                  :sections="form.metadata_sections"
                  @new:input="onNewMetadata"
                  @update:input="onUpdateMetadata"
                  @update:inputs="onUpdateMultipleMetadata"
                  @remove:input="onRemoveMetadata"
                  @remove:inputs="onRemoveMultipleMetadata"
                  @input:sections="onSetMetadataSections"
                  @update:sections="onUpdateMetadataSections"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <FormDialog
      max-width="500px"
      button-text="Add"
      :can-delete="false"
      :is-dialog-open="dialogs.form"
      :model-type="dialogs.modelType"
      :do-save-action="saveAttachedElement"
      :title="`Attach ${getTitle}`"
      @toggle:form:dialog="dialogs.form = !dialogs.form"
    >
      <AttachAssociation
        v-model="defaultForm[dialogs.modelType]"
        :title="getTitle"
        :item="JSON.parse(JSON.stringify(form))"
        :custom-options="prepareCustomOptions"
        :section="dialogs.modelType"
      />
    </FormDialog>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import DefaultAssociations from "@/mixins/DefaultAssociations.vue";
import GoogleDriveUI from "@/components/Elements/Forms/GoogleDriveUI";
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";

export default {
  name: "ContentSave",
  mixins: [FormMixin, MetadataMixin, DefaultAssociations],
  components: {
    DynamicInputs,
    GoogleDriveUI,
  },
  data() {
    return {
      modelType: "Content",
      hasFiles: true,
      dialogs: {
        form: false,
        modelType: "primary_contents",
      },
      options: {
        deliverables: [],
        supplements: [],
        countries: [],
        lessons: [],
        faqs: [],
      },
      defaultForm: {
        title: "",
        content: "",
        deliverables: [],
        supplements: [],
        programs: [],
        metadata: [],
        metadata_sections: [],
        faqs: [],
        lessons: [],
        files: [],
        visible_to: [],
        background_color: null,
        emoji: null,
      },
    }
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2),
      },
    },
  },
  computed: {
    getTitle() {
      let title = this.dialogs.modelType == "primary_contents" ? "Content" : this.dialogs.modelType;
      return this.featureName(title.capitalize());
    },
  },
  created() {
    if(this.$isUserMentor && !this.checkMentorPermission(this.modelType.toLowerCase())) {
      this.$router.push({ name: "program.index" });
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "content/getFormConfig",
      doSaveContent: "content/save",
      doGetContent: "content/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onAddItems(section) {
      this.$bus.$emit("isFormReady", true);
      this.$bus.$emit("doneFormLoading");
      this.defaultForm[section] = this.form[section];
      this.dialogs.modelType = section;
      this.dialogs.form = !this.dialogs.form;
    },
    onRemoveItem(id, section) {
      this.form[section] = this.form[section].filter(item => item.id != id);
    },
    saveAttachedElement() {
      this.form[this.dialogs.modelType] = this.defaultForm[this.dialogs.modelType];
      this.dialogs.form = !this.dialogs.form;
    },
    onSave() {
      const item = { ...this.form };
      item.supplements = this.prepareRelationIds(item.supplements);
      item.programs = this.prepareRelationIds(item.programs);
      item.deliverables = this.prepareRelationIds(item.deliverables);
      item.faqs = this.prepareRelationIds(item.faqs);
      item.lessons = this.prepareRelationIds(item.lessons);
      const data = this.prepareFormModel(
        { ...item },
        {
          dontSave: ["user"],
        }
      );
      return this.saveFormModel(this.doSaveContent, null, data).then((result) => {
        if(result) {
          this.$emit("updateModel", result);
        }
      });
    },
    onGet(id) {
      return this.doGetContent(id).then((result) => {
        return result;
      });
    },
  },
};
</script>
