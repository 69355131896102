import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Assessment Cards API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Assessment Cards",
	path: {
		getOne: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id;
			return `assessments/${assessment_id}/card`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const assessment_id = params.assessment_id;
			return `assessments/${assessment_id}/card`;
		},
		default: "assessments/card"
	}
});

export default api;
