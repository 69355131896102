<template>
  <AppLayout>
    <VContainer id="terms-page">
      <VRow
        row
        justify-center
      >
        <VCol
          md="10"
          lg="8"
          cols="12"
          align-self-center
        >
          <Terms />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import Terms from "@/components/App/Terms";

export default {
  name: "Reset",
  components: {
    Terms,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>
