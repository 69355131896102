var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VTooltip',{attrs:{"center":"","disabled":!_vm.active,"bottom":_vm.bottom,"top":_vm.top},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('VBtn',_vm._g({class:{
        'white--btn main-content-team-menu': true,
        [_vm.buttonClasses]: true
      },style:({
        border: _vm.realInvitationsCount > 0
          ? 'solid 2px '+_vm.$vuetify.theme.themes.light.primary
          : 'none'
      }),attrs:{"block":_vm.block,"large":_vm.$vuetify.breakpoint.mdAndUp,"small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}},on),[(_vm.$hasTeams && _vm.$team.photo_url)?_c('img',{style:({
          height: _vm.imageHeight
        }),attrs:{"src":_vm.$team.photo_url}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"px-2 layout column align-start"}):_vm._e(),_vm._v("  "),_c('VIcon',[_vm._v(_vm._s(_vm.isOpen ? 'expand_less' : 'expand_more'))]),(_vm.realInvitationsCount > 0)?_c('span',{staticClass:"d-inline-flex align-center justify-center",style:({
          backgroundColor: _vm.$vuetify.theme.themes.light.primary,
          position: 'absolute',
          color: '#fff',
          fontSize: _vm.large ? '12px' : '10px',
          fontWeight: '500',
          top: _vm.large ? '-24px' : '-8px',
          right: _vm.large ? '-16px' : '-10px',
          width: _vm.large ? '30px' : '20px',
          height: _vm.large ? '30px' : '20px',
          borderRadius: '5px',
          boxShadow: '0px 5px 14.3px 0.8px rgba(255, 43, 122, 0.3)'
        }),domProps:{"innerHTML":_vm._s(_vm.realInvitationsCount)}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("You have new invitations!")])])
}
var staticRenderFns = []

export { render, staticRenderFns }