import { Api as BaseApi } from "./deliverable";

/**
 * Pipeline API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Pipeline Stage",
	path: {
		getAll: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id || params.id;
			if (pipelineId) {
				return `pipelines/${pipelineId}/stages`;
			} else {
				return `pipelines/stages`;
			}
		},
		getOne: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipelines/${pipelineId}/stages`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipelines/${pipelineId}/stages`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			const pipelineId = params.pipelineId || params.pipeline_id;
			return `pipelines/${pipelineId}/stages`;
		},
		default: "pipelines",
	},
});

export default api;
