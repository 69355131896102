import api from "../../services/assessmentResult";
export default function (helper) {
	// Assessment Result types
	const TYPES = {
		SAVE_RESULT_CARD: "SAVE_RESULT_CARD",
		COMPLETE_RESULT: "COMPLETE_RESULT",
	};

	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("assessment_result");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions(
		{
			saveCard(context, id) {
				helper.log().info("[Store: Assessment Result]: Save Result Card", id);
				return helper
					.api()
					.saveCard(id)
					.then((response) => {
						helper
							.log()
							.info(`[Store: Assessment Result]: Saved Result Card`, response);
						const data = response.data.data;
						context.commit(TYPES.SAVE_RESULT_CARD, data);
						return data;
					})
					.catch((error) => {
						helper
							.log()
							.info(
								`[Store: Assessment Result]: Error Saving Result Card`,
								error
							);
						throw error;
					});
			},
			complete(context, id) {
				helper
					.log()
					.info("[Store: Assessment Result]: Complete Assessment", id);
				return helper
					.api()
					.complete(id)
					.then((response) => {
						helper
							.log()
							.info(
								`[Store: Assessment Result]: Completed Assessment`,
								response
							);
						const data = response.data.data;
						context.commit(TYPES.COMPLETE_RESULT, data);
						return data;
					})
					.catch((error) => {
						helper
							.log()
							.info(
								`[Store: Assessment Result]: Error Completing Assessments`,
								error
							);
						throw error;
					});
			},
		},
		"Assessment Result"
	);
	const mutations = helper.mutations(
		{
			[TYPES.SAVE_RESULT_CARD](state, data) {
				return data;
			},
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
