<script>
export default {
  data() {
    return {
      config: {
        stats: {}
      }
    };
  }
};
</script>