var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{class:{
    'members-search-container': true,
    group: _vm.isGroup,
    'px-0 fill-height column d-flex': true
  },style:({backgroundColor: _vm.backgroundColor})},[_c('VCardTitle',{staticClass:"py-0 px-0 d-flex mb-0 wrap sticky sticky-top",style:({
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      flexBasis: 'auto',
      backgroundColor: _vm.headerBackgroundColor,
      height: 'min-content !important'
    })},[_c('VCol',{staticClass:"pt-1",attrs:{"grow":"","wrap":"","cols":"12"}},[_c('TextField',{staticClass:"search-individuals v-input-sm no-details smaller--text borderless",attrs:{"clearable":"","outlined":"","persistent-hint":"","placeholder":"Type the name of a person ...","hint":"Press ENTER to search","prefix":"Search:","loading":_vm.activity.isLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.filter.s),callback:function ($$v) {_vm.$set(_vm.filter, "s", $$v)},expression:"filter.s"}},[_c('img',{staticStyle:{"cursor":"pointer","z-index":"1","margin-top":"6px"},attrs:{"slot":"append","width":"13","height":"13","src":require("@/images/search-icon@3x.png")},on:{"click":_vm.onSearch},slot:"append"}),_c('template',{slot:"append-outer"},[(_vm.hasFilterOptions && !_vm.hideFilters)?_c('Tooltip',{staticClass:"mt-0",attrs:{"small":"","small-icon":"","text-button":"","icon":"filter_list","title":"Filter users","disabled":_vm.activity.isLoading,"color":_vm.hasFilter ? 'info' : 'default',"action":() => _vm.filterDrawer = !_vm.filterDrawer}}):_vm._e()],1)],2)],1),(!_vm.hideFilters)?_c('VCol',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('CurrentFilter',{attrs:{"filter":_vm.filter,"filter-values":_vm.filterValues,"is-loading":_vm.activity.isLoading},on:{"removeFilter":_vm.onRemoveFilter,"removeStrictMode":_vm.onRemoveStrictMode,"resetFilter":_vm.onResetLocalFilter}})],1):_vm._e()],1),_c('VCardText',{staticClass:"row pa-0 ma-0 full-width",style:({
      flex: '1 1 auto',
      overflowY: 'hidden',
      overflowX: 'hidden',
      backgroundColor: _vm.resultsBackgroundColor
    })},[_c('VSkeletonLoader',{staticClass:"fluid",attrs:{"type":"list-item-avatar-two-line","loading":!_vm.hasFilteredUsers && _vm.activity.isLoading}},[_c('VList',{staticClass:"elevation-0 fluid rounded-0",style:({
          backgroundColor: _vm.listBackgroundColor,
          overflowY: 'auto',
        }),attrs:{"id":"infinite-users"}},[(_vm.hasFilteredUsers)?[_vm._l((_vm.data.data),function(user,index){return [_c('VListItem',{key:index,on:{"click":() => _vm.enableCheckboxes ? false : _vm.onToggleMember(user)}},[_c('VBadge',{attrs:{"avatar":"","bordered":"","overlap":"","bottom":"","left":"","offset-x":"30","offset-y":"36","icon":user.deleted ? 'close' : 'check',"color":user.deleted ? 'error' : 'success',"value":_vm.memberIds.indexOf(user.id) !== -1}},[_c('VListItemAvatar',{attrs:{"size":"50"}},[_c('img',{staticStyle:{"max-width":"50px","max-height":"50px"},attrs:{"src":user.photo_url}})])],1),_c('VListItemContent',[_c('VListItemTitle',{style:({
                    textDecoration: user.deleted ? 'line-through' : 'none',
                    color: user.deleted ? 'red' : 'inherit'
                  })},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('VListItemSubtitle',{staticClass:"py-1"},[_c('VChip',{class:{
                      'text-truncate': true,
                      'role--student': _vm.getRole(user) === 'student',
                      'role--mentor': _vm.getRole(user) === 'mentor',
                      'role--organization-admin': _vm.getRole(user) === 'organization-admin',
                      'role--missing': _vm.getRole(user) === 'missing' || _vm.getRole(user) === 'member'
                    },attrs:{"small":""}},[_vm._v(" "+_vm._s(user.role_name)+" ")])],1)],1),(_vm.enableCheckboxes)?_c('VListItemAction',[_c('VCheckbox',{staticStyle:{"height":"auto"},attrs:{"color":"primary","multiple":_vm.isGroup,"value":user.id,"disabled":_vm.isSaving},on:{"change":(value) => _vm.onHandleMemberChoice(user, value)},model:{value:(_vm.filteredMemberIds),callback:function ($$v) {_vm.filteredMemberIds=$$v},expression:"filteredMemberIds"}})],1):_vm._e()],1)]}),(_vm.isUserSearched && _vm.canGetMoreFilteredData)?_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"#infinite-users","distance":25,"identifier":new Date()},on:{"infinite":_vm.onTryGetMore}}):_vm._e()]:(!_vm.activity.isLoading)?_c('VListItem',[_vm._v(" "+_vm._s(_vm.isUserSearched ? 'No users found' : 'Search for users above')+" ")]):_vm._e()],2)],1),_c('VNavigationDrawer',{style:({
        overflowY: 'auto',
        overflowX: 'auto',
        flex: _vm.$vuetify.breakpoint.smAndDown ? (_vm.filterDrawer ? '1 1 100%' : '0 0 0%') : (_vm.filterDrawer ? '0 0 400px' : '0 0 0%'),
        width: _vm.$vuetify.breakpoint.smAndDown ? (_vm.filterDrawer ? '100%' : '0px') : (_vm.filterDrawer ? '400px' : '0px')
      }),attrs:{"absolute":"","temporary":"","right":""},model:{value:(_vm.filterDrawer),callback:function ($$v) {_vm.filterDrawer=$$v},expression:"filterDrawer"}},[_c('FilterOptions',{attrs:{"elevated":"","no-border":"","fixed":false,"filters":_vm.allFilters},on:{"filter":_vm.onSearch,"toggle":function($event){_vm.filterDrawer = !_vm.filterDrawer}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('VCardActions',{staticClass:"justify-center sticky sticky-bottom px-5",style:({
      backgroundColor: _vm.footerBackgroundColor
    })},[(_vm.isUserSearched && !_vm.isFirstLoad)?_c('VRow',{staticClass:"sticky sticky-bottom justify-space-between py-2"},[_c('VBtn',{attrs:{"loading":_vm.isSaving},on:{"click":_vm.onResetLocalFilter}},[_vm._v(" Cancel ")]),_c('VBtn',{attrs:{"color":"info"},on:{"click":_vm.onAddMembersFromSearch}},[_vm._v(" "+_vm._s(_vm.isGroup ? 'Done' : _vm.saveText)+" ")])],1):(!_vm.isUserSearched && !_vm.activity.isLoading)?[_c('VBtn',{attrs:{"color":"success","loading":_vm.isSaving,"disabled":!_vm.hasUsers},on:{"click":_vm.onSaveMembers}},[_vm._v(" "+_vm._s(_vm.isSaving ? _vm.savingText : _vm.saveText)+" ")])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }