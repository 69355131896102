import Vue from "vue";
import BaseApi from "./_base-api";

/**
 * Submitted Attendance API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getAll(params) {
		this.log().info(
			"[Services: Submitted Attendance]: Get Submitted Attendance",
			params
		);
		return this.api()
			.get(
				`${this.getUrl("getAll", null, "attendance_submitted")}/${params.id}`,
				params
			)
			.catch(error => {
				this.log().error(
					"[Services: Submitted Attendance: Get Submitted Attendance]:",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Submitted Attendance",
	path: {
		save: (app, _params) => {
			const params = _params || {};
			const attendance_id = params.attendance_id;
			return `attendance_submitted/${attendance_id}`;
		},
		default: "attendance_submitted"
	}
});

export default api;
