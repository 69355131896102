<template>
  <VCard
    min-height="150px"
    min-width="275px"
    :class="{
      'rounded-lg lesson-card': true,
      'hidden-card': isCardHidden,
    }"
    :color="cardBackgroundColor"
    :style="cardStyle"
    :href="processing ? null : getCardHref"
    :loading="loading"
    @click.prevent="onCardClick"
  >
    <template #progress>
      <VProgressLinear
        color="deep-purple"
        height="4"
        indeterminate
      />
    </template>
    <VOverlay
      absolute
      light
      :value="showCount"
    >
      <span style="color: #ffffff; z-index: 6; font-size: 24px; font-weight: 800;">+{{ count }}</span>
    </VOverlay>
    <VOverlay
      absolute
      light
      :value="processing"
    >
      <div class="text-center px-2 text-body-1 font-weight-bold"> 
        Your content is being duplicated.  Please check back soon.
      </div>
      <div
        class="ma-4"
        :style="{
          background: `url(${ProgressImage})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '36px',
        }"
      >
        <VRow class="fill-height">
          <VCol class="d-flex align-center px-8 py-0">
            <span style="font-size: 14px; color: white; font-weight: 700;">
              Working...
            </span>
            <VSpacer />
            <VTooltip
              top
              content-class="progress-tooltip"
            >
              <template #activator="{ on }">
                <VIcon 
                  v-on="on"
                  color="#FFFFFF"
                >
                  mdi-information-outline
                </VIcon>
              </template>
              <div style="font-size: 14px;">
                <span style="font-weight: 700;">Just a sec..</span>
                <br>
                <span style="font-weight: 400;">
                  Duplication can take a few minutes.
                  <br>
                  Please check back soon.
                </span>
              </div>
            </VTooltip>
          </VCol>
        </VRow>
      </div>
    </VOverlay>
    <VCardText
      v-if="isCardHidden && !showCount"
      class="d-flex align-center justify-center"
      style="position: absolute; top: 36%;"
    >
      <VBtn
        icon
        outlined
        rounded
        disabled
        height="40px"
        width="40px"
        style="color: #ffffff !important; z-index: 6;"
      >
        <VIcon
          color="#ffffff !important"
          style="z-index: 6;"
          size="20"
        >
          mdi-eye-off
        </VIcon>
      </VBtn>
    </VCardText>
    <VCardTitle
      class="pb-0 space-between"
      style="justify-content: space-between"
    >
      <VCol
        grow
        class="pt-1"
        style="max-width: calc(100% - 28px)"
      >
        <p 
          class="card-label item-title text-break"
          :style="{
            ...fontStyle,
            display: '-webkit-box',
          }"
        >
          {{ saved ? item.card.name || item.card.type : item.title }}
        </p>
        <p
          v-if="!saved"
          class="card-count"
          :style="fontStyle"
        >
          {{ item.cards_count || 0 }} Cards
          {{ item.questions_count || 0 }} Questions
        </p>
      </VCol>
      <CardMenu
        v-if="!$isUserStudent && !previewOnly && !showCount && !processing"
        item-type="lesson"
        :item="item"
        :icon-color="iconColor"
        :parent-model="parentModel"
        :disable-visibility="disableVisibility"
        @duplicated="(result) => $emit('duplicated', result)"
      >
        <template slot="status-action">
          <slot name="status-options" />
        </template>
        <template slot="additionalMenu">
          <slot
            name="menu-items"
            :preview-only="previewOnly"
            :is-back-to-default="isBackToDefault"
          />
        </template>
      </CardMenu>
    </VCardTitle>
    <VCardText class="py-0">
      <template v-if="incompleted">
        <VRow class="pt-1 pl-1">
          <VCol
            cols="4"
            class="py-0"
          >
            <h6
              style="line-height: 1.56;"
              :style="fontStyle"
            >
              PROGRESS
            </h6>
            <p
              class="card-label"
              :style="fontStyle"
            >
              {{
                item.result_for_user
                  ? 100 - item.result_for_user.progress
                  : 100
              }}% Left
            </p>
          </VCol>
          <!-- <VCol
            cols="8"
            class="pt-0"
          >
            <p
              class="card-label"
              :style="{ ...fontStyle, ...{ lineHeight: `1` } }"
            >
              Due
            </p>
            <p
              class="card-label"
              :style="fontStyle"
            >
              {{ realDueOn }}
            </p>
          </VCol> -->
        </VRow>
      </template>
      <template v-else-if="saved">
        <p class="card-label black--text">
          <VChip small>
            {{ item.card.type }}
          </VChip>
          <span style="cursor: pointer">
            {{ featureName("Lesson") }}:
            <strong>{{ item.assessment.title }}</strong>
          </span>
        </p>
      </template>
    </VCardText>
    <VCardActions
      class="pb-3"
      style="width: 100%;"
    >
      <VCol
        :class="{
          'pa-0 d-flex': true,
          'pl-1': !incompleted && !overdue && !all && !previewOnly,
        }"
      >
        <Linkable
          v-if="!processing"
          dense
          title="Copy Url"
          :button-class="{
            'pa-0 no-shadow': true,
          }"
          hover-class="transparent"
          :force-expandable="false"
          :color="cardBackgroundColor"
          :button-style="{
            ...actionButtonStyle,
            ...{
              width: '35px',
              height: '35px',
              borderRadius: '6px',
              textAlign: 'center',
            },
          }"
          :icon-props="{
            style: {
              color: iconColor,
            },
          }"
          :url="
            getFullyQualifiedUrlForItem(`present.view`, {
              params: {
                team: `${$team.slug}`,
                type: item.type,
                id: item.id,
              },
            })
          "
        />
        <VSpacer v-if="$isUserAdmin && $viewProgramAsAdmin && !previewOnly" />
        <template v-if="saved">
          <VBtn
            depressed
            min-width="35px"
            width="35px"
            height="35px"
            class="pa-0 ml-2 no-shadow"
            title="View"
            :color="colors.font.white"
            @click="$emit('viewAssessment', item)"
          >
            <VIcon
              color="#0000008a"
              dense
            >
              mdi-information-outline
            </VIcon>
          </VBtn>
        </template>
        <template v-else>
          <VTooltip
            v-if="!overdue && !incompleted && !all"
            right
            content-class="lesson-tooltip"
          >
            <template #activator="{on}">
              <VBtn
                v-on="on"
                depressed
                min-width="35px"
                width="35px"
                height="35px"
                class="pa-0 no-shadow mx-2"
                :color="isCardHidden ? 'rgba(47, 53, 63, 0.75)' : isVisible ? colors.border.default : colors.border.error"
              >
                <VIcon
                  dense
                  :color="isVisible && !isCardHidden ? colors.font.black : colors.font.white"
                >
                  mdi-information-outline
                </VIcon>
              </VBtn>
            </template>
            <VCard
              min-height="100px"
              width="250px"
              class="rounded-lg tooltip-card"
            >
              <VCardTitle>
                Details
              </VCardTitle>
              <VCardText>
                <VCol class="pl-0 pt-0">
                  <VIcon
                    dense
                    :color="colors.font.black"
                  >
                    mdi-account-multiple
                  </VIcon>
                  <span
                    class="tooltip-text pl-1"
                  >
                    {{ item.completed_results_count || 0 }}
                    {{ "Submission".pluralize(item.completed_results_count || 0) }}
                  </span>
                </VCol>
                <template
                  v-if="
                    item.key_points instanceof Array && item.key_points.length
                  "
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Key Take-a-ways:
                    </p>
                  </VCol>
                  <VCol class="pl-0 pt-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="point in item.key_points"
                      :key="point.id"
                    >
                      <template v-if="point.name">
                        <VIcon class="tooltip-checkbox">
                          mdi-checkbox-marked
                        </VIcon>
                        <span class="p-content">{{ point.name }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
                <template
                  v-if="item.do_now instanceof Array && item.do_now.length"
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Do Now:
                    </p>
                  </VCol>
                  <VCol class="pa-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="point in item.do_now"
                      :key="point.id"
                    >
                      <template v-if="point.name">
                        <VIcon class="tooltip-checkbox">
                          mdi-checkbox-marked
                        </VIcon>
                        <span class="p-content">{{ point.name }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
                <template
                  v-if="
                    item.prerequisites instanceof Array &&
                      (item.prerequisites || item.prerequisites_for_user).length
                  "
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Prerequisites:
                    </p>
                  </VCol>
                  <VCol class="pa-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="prerequisite in item.prerequisites ||
                        item.prerequisites_for_user"
                      :key="prerequisite.id"
                    >
                      <template v-if="prerequisite.title">
                        <VIcon
                          class="tooltip-checkbox mr-1"
                          :color="
                            prerequisite.result_for_user instanceof Object &&
                              prerequisite.result_for_user.completed_on != null
                              ? 'success'
                              : 'disabled'
                          "
                        >
                          {{
                            prerequisite.result_for_user instanceof Object &&
                              prerequisite.result_for_user.completed_on != null
                              ? "mdi-checkbox-marked"
                              : "mdi-checkbox-blank"
                          }}
                        </VIcon>
                        <span class="p-content">{{ prerequisite.title }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
              </VCardText>
            </VCard>
          </VTooltip>
          <VTooltip
            v-else
            content-class="lesson-tooltip"
            right
          >
            <template #activator="{on}">
              <VBtn
                v-on="on"
                depressed
                min-width="35px"
                width="35px"
                height="35px"
                class="pa-0 no-shadow mx-2"
                :style="actionButtonStyle"
                :color="colors.font.white"
              >
                <VIcon
                  color="#0000008a"
                  dense
                >
                  mdi-information-outline
                </VIcon>
              </VBtn>
            </template>
            <VCard
              min-height="100px"
              width="250px"
              class="rounded-lg tooltip-card"
            >
              <VCardTitle>
                Details
              </VCardTitle>
              <VCardText>
                <!-- <VCol class="pl-0 pt-0">
                  <p class="tooltip-text ma-0">
                    Due:
                    <span class="p-content">{{ realDueOn }}</span>
                  </p>
                </VCol> -->
                <template
                  v-if="
                    item.key_points instanceof Array && item.key_points.length
                  "
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Key Take-a-ways:
                    </p>
                  </VCol>
                  <VCol class="pa-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="point in item.key_points"
                      :key="point.id"
                    >
                      <template v-if="point.name">
                        <VIcon class="tooltip-checkbox">
                          mdi-checkbox-marked
                        </VIcon>
                        <span class="p-content">{{ point.name }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
                <template
                  v-if="item.do_now instanceof Array && item.do_now.length"
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Do Now:
                    </p>
                  </VCol>
                  <VCol class="pa-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="point in item.do_now"
                      :key="point.id"
                    >
                      <template v-if="point.name">
                        <VIcon class="tooltip-checkbox">
                          mdi-checkbox-marked
                        </VIcon>
                        <span class="p-content">{{ point.name }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
                <template
                  v-if="
                    item.prerequisites instanceof Array &&
                      (item.prerequisites || item.prerequisites_for_user).length
                  "
                >
                  <VCol class="pa-0">
                    <p class="tooltip-text ma-0">
                      Prerequisites:
                    </p>
                  </VCol>
                  <VCol class="pa-0">
                    <p
                      class="tooltip-text ma-0"
                      v-for="prerequisite in item.prerequisites ||
                        item.prerequisites_for_user"
                      :key="prerequisite.id"
                    >
                      <template v-if="prerequisite.title">
                        <VIcon
                          class="tooltip-checkbox mr-1"
                          :color="
                            prerequisite.result_for_user instanceof Object &&
                              prerequisite.result_for_user.completed_on != null
                              ? 'success'
                              : 'disabled'
                          "
                        >
                          {{
                            prerequisite.result_for_user instanceof Object &&
                              prerequisite.result_for_user.completed_on != null
                              ? "mdi-checkbox-marked"
                              : "mdi-checkbox-blank"
                          }}
                        </VIcon>
                        <span class="p-content">{{ prerequisite.title }}</span>
                      </template>
                    </p>
                  </VCol>
                </template>
              </VCardText>
            </VCard>
          </VTooltip>
        </template>
        <template v-if="!$isUserAdmin || !$viewProgramAsAdmin || previewOnly">
          <VSpacer />
          <VTooltip top>
            <template #activator="{on}">
              <VBtn
                v-on="on"
                depressed
                min-width="35px"
                height="35px"
                width="auto"
                class="pa-0 no-shadow"
                color="transparent"
                @click="
                  getUserCanTake(item)
                    ? navigateTo({
                      name: 'present.view',
                      params: {
                        team: `${$team.slug}`,
                        id: saved ? item.assessment_id : item.id,
                        type: 'lesson',
                        isBackToDefault,
                          ...(saved && { cardId: item.assessment_card_id }),
                      },
                    })
                    : onTogglePrerequisites()
                "
              >
                <VIcon :color="iconColor">
                  <template v-if="getUserCanTake(item)">
                    {{
                      isComplete
                        ? "mdi-replay"
                        : "mdi-arrow-right-bold-circle-outline"
                    }}
                  </template>
                  <template v-else>
                    warning
                  </template>
                </VIcon>
              </VBtn>
            </template>
            <span>{{ lessonToolTipForStudent }}</span>
          </VTooltip>
        </template>
      </VCol>
    </VCardActions>
    <Dialog
      title="Here are the prerequisites for this lesson"
      :is-dialog-open="dialogs.prerequisites"
      @toggle:dialog="onTogglePrerequisites"
    >
      <VList v-if="item.prerequisites_for_user_count > 0">
        <VListItem
          v-for="(prerequisite, index) in item.prerequisites_for_user"
          dense
          two-line
          class="my-2"
          :key="prerequisite.id"
        >
          <VListItemAction
            class="mr-0"
            style="min-width: auto;"
          >
            <VChip>{{ index + 1 }}</VChip>
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle>
              <span
                class="px-2"
                style="font-size: 14px;"
              >
                {{ prerequisite.title }}
              </span>
            </VListItemTitle>
            <VListItemSubtitle class="pl-2">
              <VChip small>
                {{ (prerequisite.result_for_user || {}).progress || 0 }}%
              </VChip>
              <VChip
                v-if="!isPrerequisiteComplete(prerequisite)"
                small
                class="ml-1"
              >
                Started: &nbsp;
                <strong>{{
                  prerequisite.result_for_user instanceof Object
                    ? $timezone.formatDateTime(
                      prerequisite.result_for_user.started_on
                    )
                    : "Not Started"
                }}</strong>
              </VChip>
              <VChip
                v-else-if="isPrerequisiteComplete(prerequisite)"
                small
                class="ml-1"
              >
                Completed: &nbsp;
                <strong>{{
                  prerequisite.result_for_user instanceof Object
                    ? $timezone.formatDateTime(
                      prerequisite.result_for_user.started_on
                    )
                    : "Not Complete"
                }}</strong>
              </VChip>
              <VChip
                small
                class="ml-1"
              >
                <strong>{{ prerequisite.questions_count || 0 }}</strong>&nbsp; Questions
              </VChip>
            </VListItemSubtitle>
          </VListItemContent>
          <VListItemAction
            class="mr-0"
            style="min-width: auto;"
          >
            <VTooltip
              v-if="!getUserCanTake(prerequisite)"
              top
            >
              <template #activator="{on}">
                <VIcon
                  v-on="on"
                  color="warning"
                >
                  warning
                </VIcon>
              </template>
              <span><strong>{{ prerequisite.title }}</strong> has prerequisites!
                Please close this dialog and view the prerequisite
                information.</span>
            </VTooltip>
            <VTooltip
              v-else
              top
            >
              <template #activator="{on}">
                <VBtn
                  v-on="on"
                  depressed
                  min-width="35px"
                  height="35px"
                  width="auto"
                  class="pa-0 no-shadow"
                  color="transparent"
                  :to="{
                    name: 'present.view',
                    params: {
                      team: `${$team.slug}`,
                      id: prerequisite.id,
                      type: 'lesson',
                      ...{ cardId: prerequisite.assessment_card_id },
                    },
                  }"
                >
                  <VIcon color="black">
                    <template v-if="isPrerequisiteComplete(prerequisite)">
                      mdi-replay
                    </template>
                    <template v-else>
                      mdi-arrow-right-bold-circle-outline
                    </template>
                  </VIcon>
                </VBtn>
              </template>
              <span>{{ getPrerequisiteTooltip(prerequisite) }}</span>
            </VTooltip>
          </VListItemAction>
        </VListItem>
      </VList>
      <VAlert v-else>
        No prerequisites!
      </VAlert>
    </Dialog>
  </VCard>
</template>
<script>
import ProgressImage from "@/images/progress-bar.gif";
import CardMenu from "@/components/Elements/Navigation/CardMenu";

export default {
  name: "LessonHomeCard",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    overdue: {
      type: Boolean,
      default: false,
    },
    incompleted: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    saved: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: function() {
        return {};
      },
    },
    visibleTo: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marginRight: {
      type: String,
      default: "0px",
    },
    dueOn: {
      type: String,
      default: null,
    },
    dueDay: {
      type: String,
      default: null,
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
    isBackToDefault: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      default: () => {},
    },
    customFilter: {
      type: Object,
      default: function() {
        return null;
      },
    },
    count: {
      type: Number,
      default: 0,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    disableVisibility: {
      type: Boolean,
      default: false,
    },
    parentModel: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardMenu,
  },
  data() {
    return {
      ProgressImage,
      dialogs: { prerequisites: false },
      toggleLoader: false,
      isCardHidden: false,
    };
  },
  watch: {
    "item.pivot.visible_to": {
      handler: function(value) {
        if(this.parentModel) {
          const visibility = JSON.parse(value);
          this.setCardHidden(visibility);
        }
      },
      deep: true,
    },
    "item.programs": {
      handler: function(value) {
        if(this.parentModel) {
          const program = value.find((program) => program.id === (this.parentModel || {}).id);
          const visibility = JSON.parse(program.pivot.visible_to);
          this.setCardHidden(visibility);
        }
      },
      deep: true,
    },
  },
  computed: {
    realDueOn() {
      return this.dueOn == "(not set)" || !this.dueOn || this.dueOn == ""
        ? "Any time"
        : this.dueOn;
    },
    userHasResult() {
      return (
        this.item instanceof Object &&
        this.item.result_for_user instanceof Object
      );
    },
    isLocked() {
      return !!this.item.is_locked;
    },
    isComplete() {
      return (
        this.item.is_active &&
        this.userHasResult &&
        (this.item.result_for_user || {}).completed_on !== null
      );
    },
    isInProgress() {
      return this.incompleted || (this.userHasResult && !this.isComplete);
    },
    isVisible() {
      if(this.parentModel) {
        return this.visibleTo.length > 0 && this.item.is_active;
      }
      // For Lessons dashboard page
      return this.item.is_active;
    },
    isVisibleOnlyToAdmins() {
      return this.isVisible && this.visibleTo.length == 0;
    },
    isVisibleButLocked() {
      return this.isVisible && this.item.is_locked;
    },
    isVisibleToStudents() {
      return this.isVisible && this.visibleTo.includes("student");
    },
    isVisibleToMentors() {
      return this.isVisible && this.visibleTo.includes("mentor");
    },
    cardStyle() {
      return {
        boxShadow: `-1.7px 4.7px 6.1px 0.4px ${this.cardBoxShadow} !important`,
        border: `${this.cardBorder} !important`,
        marginRight: this.marginRight,
        cursor: !this.$isUserStudent && !this.previewOnly && !this.processing ? "pointer" : "auto",
      };
    },
    fontStyle() {
      let color = this.colors.font.black;
      if(this.isCardHidden) {
        return { color };
      }
      if (
        this.item.is_active &&
        !(this.incompleted || this.all || this.overdue)
      ) {
        color = this.colors.font.white;
      } else if (!this.getUserCanTake(this.item)) {
        color = this.colors.font.black;
      } else if (this.incompleted || this.isComplete) {
        color = this.colors.font.white;
      }
      return {
        color,
      };
    },
    cardBackgroundColor() {
      if (this.incompleted || this.overdue || this.all) {
        if (!this.getUserCanTake(this.item)) {
          return this.colors.background.disabled;
        } else if (this.incompleted) {
          return this.colors.background.info;
        } else if (this.overdue) {
          return this.previewOnly || !this.$isUserAdmin || !this.$viewProgramAsAdmin
            ? this.colors.background.default
            : this.colors.background.warning;
        } else if (this.all) {
          return this.userHasResult
            ? this.colors.background.info
            : this.colors.background.warning;
        }
      } else {
        if(this.parentModel) {
          // For program details page
          if (!this.item.is_active) {
            return this.colors.background.default;
          } else if (this.isVisibleToStudents) {
            return this.isLocked ? this.colors.background.info : this.colors.background.success;
          } else if (this.isVisibleToMentors) {
            return !this.isLocked
              ? this.colors.background.warning
              : this.colors.background.info;
          } else if (this.isVisibleOnlyToAdmins) {
            return this.colors.background.error;
          } else if (!this.isVisibleToStudents) {
            return this.colors.background.error;
          } else {
            return this.colors.background.info;
          }
        } else {
          // For Lessons dashboard page
          if (this.item.is_active) {
            if(this.isLocked) {
              return this.colors.background.info;
            }
            return this.colors.background.success;
          } else {
            return this.colors.background.default;
          }
        }
      }
    },
    actionIconColor() {
      if (this.incompleted || this.all) {
        return this.colors.font.black;
      } else if (this.isVisibleToStudents) {
        return "success";
      } else if (this.item.is_locked) {
        return "error";
      } else if (!this.isVisibleToStudents) {
        return "warning";
      } else {
        return this.item.is_locked ? "error" : `info`;
      }
    },
    iconColor() {
      let color = this.colors.font.black;
      if(this.isCardHidden) {
        return this.colors.font.white;
      }
      if (this.$isUserStudent && !this.getUserCanTake(this.item)) {
        return this.colors.font.black;
      } else if (this.incompleted) {
        color = this.colors.font.white;
      } else if (this.all) {
        color = this.userHasResult
          ? this.colors.font.white
          : this.colors.font.black;
      } else if (
        this.item.is_active &&
        !(this.incompleted || this.all || this.overdue)
      ) {
        color = this.colors.font.white;
      }
      return color;
    },
    iconButtonBorderColor() {
      let color = this.colors.font.black;

      if (this.incompleted) {
        color = this.colors.font.white;
      } else if (this.all) {
        color = this.userHasResult
          ? this.colors.font.white
          : this.colors.font.black;
      } else if (
        this.item.is_active &&
        !(this.incompleted || this.all || this.overdue)
      ) {
        color = this.colors.font.white;
      }
      return color;
    },
    incompleteBackground() {
      if (this.active) {
        return this.colors.background.incomplete;
      } else if (this.incompleted) {
        return this.colos.background.default;
      } else {
        return this.colors.background.info;
      }
    },
    cardBorder() {
      if(this.isCardHidden) {
        return "none";
      }
      if ((!this.$isUserAdmin || !this.$viewProgramAsAdmin) && !this.getUserCanTake(this.item)) {
        return `2px solid ${this.colors.border.disabled}`;
      } else if (this.overdue) {
        return `2px solid ${this.colors.background.overdue}`;
      } else if (this.incompleted || this.all) {
        return "0.5px solid rgba(243, 205, 66, 0.35)";
      } else {
        return `2px solid ${!this.item.is_active && !this.item.is_locked ? this.colors.border.card : this.cardBackgroundColor}`;
      }
    },
    cardBoxShadow() {
      if (this.overdue) {
        return "rgba(247, 36, 110, 0.15)";
        // } else if (this.incompleted || this.all) {
        // return "rgba(243, 205, 66, 0.35)";
      } else {
        return this.colors.border.card;
      }
    },
    infoButtonBorder() {
      if (!this.visibleTo.length) {
        return this.colors.border.error;
      }
      if (this.active) {
        return this.colors.border.infoButton;
      } else {
        return this.colors.border.infoButtonDefault;
      }
    },
    lockButtonStyle() {
      let borderColor;

      if (this.incompleted || this.all) {
        borderColor = this.colors.font.black;
      } else if (this.isVisibleToStudents) {
        borderColor = this.colors.border.success;
      } else if (this.item.is_locked) {
        borderColor = this.colors.border.error;
      } else if (!this.isVisibleToStudents) {
        borderColor = this.colors.border.warning;
      } else {
        borderColor = this.item.is_locked
          ? this.colors.border.error
          : this.colors.border.info;
      }
      return {
        border: `solid 0.2px ${borderColor}`,
        borderColor: `${borderColor} !important`,
      };
    },
    actionButtonStyle() {
      let borderColor;

      if(this.isCardHidden) {
        borderColor = "rgba(47, 53, 63, 0.75)";
      } else {
        if (this.incompleted || this.overdue || this.all) {
          if (!this.getUserCanTake(this.item)) {
            borderColor = this.colors.background.disabled;
          } else if (this.incompleted) {
            borderColor = this.colors.border.info;
          } else if (this.all) {
            borderColor = this.colors.border.warning;
          }
        } else {
          if(this.parentModel) {
            // For program details page
            if (!this.item.is_active) {
              borderColor = this.colors.border.default;
            } else if (this.isVisibleToStudents) {
              borderColor = this.isLocked ? this.colors.border.info : this.colors.border.success;
            } else if (this.isVisibleToMentors) {
              borderColor = this.isLocked
                ? this.colors.border.info
                : this.colors.border.warning;
            } else if (this.isVisibleOnlyToAdmins) {
              borderColor = this.colors.border.error;
            } else if (!this.isVisibleToStudents) {
              borderColor = this.colors.border.error;
            } else {
              borderColor = this.colors.border.info;
            }
          } else {
            // For Lessons dashboard page
            if (this.item.is_active) {
              if(this.isLocked) {
                borderColor = this.colors.border.info;
              } else {
                borderColor = this.colors.border.success;
              }
            } else {
              borderColor = this.colors.border.default;
            }
          }
        }
      }
      return {
        border: `solid 0.2px ${borderColor}`,
        borderColor: `${borderColor} !important`,
      };
    },
    switchStyle() {
      return {
        border: "none",
        color: this.switchColor,
      };
    },
    switchColor() {
      let color;
      if (this.isVisibleToStudents) {
        color = "white";
      } else if (!this.item.is_active) {
        color = "grey";
      } else {
        color = "success";
      }
      return color;
    },
    lessonToolTipForStudent() {
      if (this.$isUserStudent && !this.getUserCanTake(this.item || {})) {
        return `You cannot take this ${this.featureName(
          "lesson",
          "singularize"
        )} yet. Click for more information`;
      }
      if (this.userHasResult || this.previewOnly) {
        if (this.isInProgress) {
          return "Continue";
        }
        return this.isComplete ? "Take Again" : "Take";
      }
      return "Take";
    },
    getCardHref() {
      if (!this.$isUserStudent && !this.previewOnly && !this.showCount) {
        return this.getHrefForItem("present.view", {
          team: this.$team.slug,
          type: this.item.type,
          id: this.item.id,
        });
      }
      return null;
    },
  },
  created() {
    if(this.parentModel) {
      let lessonVisibility = this.item;
      if(!this.item.hasOwnProperty("pivot")) {
        lessonVisibility = this.item.programs.find((program) => program.id === this.parentModel.id);
      }
      const visibility = JSON.parse(lessonVisibility.pivot.visible_to);
      this.setCardHidden(visibility);
    }
  },
  methods: {
    setCardHidden(visibility) {
      if(visibility instanceof Object) {
        let roles = Object.keys(visibility);
        this.isCardHidden = roles.length === 0 || (roles.length === 1 && roles[0] === "organization-admin");
      } else {
        this.isCardHidden = true;
      }
    },
    onCardClick() {
      if(!this.processing) {
        if(this.showCount) {
          this.$emit("showMore");
        } else if (!this.$isUserStudent && !this.previewOnly) {
          this.navigateTo({
            name: "present.view",
            params: {
              team: this.$team.slug,
              type: this.item.type,
              id: this.saved ? this.item.assessment_id : this.item.id,
              isBackToDefault: this.isBackToDefault,
            },
          })
        }
      }
    },
    onTogglePrerequisites() {
      this.dialogs.prerequisites = !this.dialogs.prerequisites;
    },
    getPrerequisiteColor(prerequisite) {
      if (this.isPrerequisiteComplete(prerequisite)) {
        return this.colors.background.success;
      } else if (this.isPrerequisiteInProgress(prerequisite)) {
        return this.colors.background.info;
      } else if (this.isPrerequisiteRequired(prerequisite)) {
        return this.colors.background.warning;
      } else {
        return this.color.background.default;
      }
    },
    getPrerequisiteTooltip(prerequisite) {
      if (this.isPrerequisiteInProgress(prerequisite)) {
        return "Continue";
      } else if (this.isPrerequisiteComplete(prerequisite)) {
        return "Take Again";
      }
      return this.getUserCanTake(prerequisite)
        ? "Take"
        : "You cannot take this lesson yet";
    },
    isPrerequisiteComplete(prerequisite) {
      return (
        this.$isUserStudent &&
        prerequisite instanceof Object &&
        prerequisite.result_for_user instanceof Object &&
        prerequisite.result_for_user.completed_on != null
      );
    },
    isPrerequisiteInProgress(prerequisite) {
      return (
        prerequisite instanceof Object &&
        prerequisite.result_for_user instanceof Object &&
        prerequisite.result_for_user.completed_on == null
      );
    },
    isPrerequisiteRequired(prerequisite) {
      return (
        this.$isUserStudent &&
        prerequisite instanceof Object &&
        this.getUserCanTake(prerequisite) == false
      );
    },
    getUserCanTake(item) {
      return (
        !this.$isUserStudent ||
        (this.$isUserStudent && (item || { user_can_take: true }).user_can_take)
      );
    },
  },
};
</script>
<style lang="scss">
.lesson-card {
  display: flex;
  flex-direction: column;
  .card-label {
    margin: 0 !important;
    font-weight: bold;
  }
  .card-count {
    font-size: 10px;
    margin-bottom: 0 !important;
    font-weight: bold;
    line-height: 20px;
  }
  .v-card__title {
    border-bottom: none !important;
  }
  .v-card__actions {
    bottom: 0;
    position: absolute;
  }
  .action-count {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  .no-shadow {
    box-shadow: none !important;
  }
  .item-title {
    line-height: normal;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.lesson-tooltip {
  background: transparent;
  opacity: 1 !important;

  .tooltip-text {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .tooltip-card {
    box-shadow: -1.7px 4.7px 6.1px 0.4px rgba(0, 0, 0, 0.03) !important;
    border: solid 0.5px #eaeaea !important;
  }
  .p-content {
    font-weight: normal;
    color: #606060;
  }
  .tooltip-checkbox {
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: initial !important;
  }
}
</style>
