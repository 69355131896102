<template>
  <VRow
    row
    column
    wrap
  >
    <VCol
      v-if="mode === 'standalone'"
      style="flex: 0 0 64px"
    >
      <ViewHeader :route-params="realPipeline.id">
        <template #title>
          {{ featureName('Submitted Applications') }}
          <template v-if="realPipeline && realPipeline.id">
            for {{ realPipeline.title || '(not set)' }}
          </template>
        </template>
        <template #extra-buttons>
          <Linkable
            v-if="!$isUserStudent"
            icon="visibility"
            :tooltip="`View ${featureName('Deliverable').singularize()}`"
            :copyable="false"
            :icon-props="{small: true}"
            :url="getFullyQualifiedUrlForItem(`pipeline.view`, {pipeline: realPipeline.id})"
            :to="getRouteForItem(`pipeline.view`, {pipeline: realPipeline.id})"
          />
        </template>
      </ViewHeader>
    </VCol>
    <VCol>
      <DataViewUI
        ref="dataView"
        :show-expand="false"
        :expandable-rows="false"
        :data="data"
        :headers="submittedHeaders"
        :is-loading="activity.isLoading"
        :no-data-text="submittedFor === 'all' ? 'No applications found.' : 'You currently have no current or past applications to other workspaces. When you do, you can view and manage them here.'"
        :filters="allFilters"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template #[`header.current_stage.title`]="{ header }">
          {{ header.text }}
          <VTooltip top>
            <template #activator="{ on, attrs }">
              <VIcon
                v-bind="attrs"
                v-on="on"
                size="21px"
              >
                info
              </VIcon>
            </template>
            <span>
              <strong>{{ featureName("Stage") }}: </strong>
              A stage to evaluate candidates, with each round narrowing down the pool of applicants.
            </span>
          </VTooltip>
        </template>
        <template #[`header.reviewers.name`]="{ header }">
          {{ header.text }}
          <VTooltip top>
            <template #activator="{ on, attrs }">
              <VIcon
                v-bind="attrs"
                v-on="on"
                size="21px"
              >
                info
              </VIcon>
            </template>
            <span>
              <strong>{{ featureName("Reviewers", "pluralize") }}: </strong>
              Individuals assigned to evaluate and review candidates submissions.
            </span>
          </VTooltip>
        </template>
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td v-if="submittedFor === 'me'">
            <VChip>
              <VAvatar>
                <VImg :src="props.item.pipeline.team.photo_url" />
              </VAvatar>
              {{ props.item.pipeline.team.name }}
            </VChip>
          </td>
          <template v-if="($isUserAdmin || $isUserMentor) && submittedFor === 'all'">
            <td>
              <VRow row>
                <!-- <UserChip :user="props.item.user" /> -->
                <UserModal
                  :title="props.item.title"
                  :new-messages-count="props.item.new_messages_for_user_count"
                  :members="(props.item.reviewers || []).map(u => u.id)"
                  :entity="props.item"
                  :thread="props.item.thread_for_user"
                  :user="props.item.user"
                  :enable-edit="submittedFor === 'all' && $isUserAdmin"
                  :enable-chat="teamSetting('pipeline.is_chat_enabled', true)"
                />
                <ChatButton
                  v-if="teamSetting('pipeline.is_chat_enabled', true)"
                  :title="props.item.title"
                  :new-messages-count="props.item.new_messages_for_user_count"
                  :members="(props.item.reviewers || []).map(u => u.id)"
                  :entity="props.item"
                  :thread="props.item.thread_for_user"
                  :user="props.item.user"
                  :enable-edit="submittedFor === 'all' && $isUserAdmin"
                  :disable-chat-btn="!(props.item.thread_for_user instanceof Object)"
                  :chat-button-color="props.item.thread_for_user instanceof Object ? 'white' : 'grey'"
                />
              </VRow>
            </td>
            <td v-if="!(pipeline && pipeline.hasOwnProperty('id'))">
              <Tooltip
                :as-button="false"
                :icon="props.item.pipeline.is_expired ? 'error' : null"
                :title="props.item.pipeline.is_expired ? 'This pipeline is expired!' : 'This pipeline is active'"
                :color="props.item.pipeline.is_expired ? 'warning' : ''"
                :text-color="props.item.pipeline.is_expired ? 'white' : ''"
                :button-text="(props.item.pipeline || {title: 'None'}).title"
              />
            </td>
          </template>
          <td v-else-if="submittedFor === 'me'">
            {{ props.item.pipeline.title }}
          </td>
          <td>
            <VMenu
              v-if="$isUserAdmin || $isUserMentor && !props.item.is_complete"
              offset-y
              :disabled="props.item.isSaving"
            >
              <template #activator="{on}">
                <VRow row>
                  <Tooltip
                    depressed
                    button-class="normal-text px-2"
                    right-icon="keyboard_arrow_down"
                    icon-right
                    :button-attributes="{
                      elevation: 3
                    }"
                    :activator-v-on="on"
                    :disabled="props.item.isSaving"
                    :loading="props.item.isSaving"
                    :text="false"
                    :icon="!(props.item.current_stage_for_user || {}).stage ? 'error' : ''"
                    :icon-color="getCurrentStageStatusIconColor(props.item.current_stage_for_user || {})"
                    :title="(props.item.current_stage_for_user || {status_message: `No ${featureName('stage', 'singularize').toLowerCase()} set`}).status_message"
                    :button-text="((props.item.current_stage_for_user || {}).stage || {title: 'None'}).title || `Missing ${featureName('Stage', 'singularize')} Title!`"
                  />
                </VRow>
              </template>
              <VList :id="`set-stage-${props.item.id}`">
                <VListItem
                  v-for="(pipelineStage, pipelineStageKey) in (props.item.pipeline.stages || [])"
                  :key="`pipeline-stage-${pipelineStageKey}`"
                  @click="onSetStage(props.index, pipelineStage.id)"
                >
                  {{ pipelineStage.title }}
                </VListItem>
              </VList>
            </VMenu>
            <Tooltip
              v-else
              :as-button="false"
              :icon="!(props.item.current_stage_for_user || {}).stage ? 'error' : ''"
              :icon-color="getCurrentStageStatusIconColor(props.item.current_stage_for_user || {})"
              :title="(props.item.current_stage_for_user || {status_message: `No ${featureName('stage', 'singularize').toLowerCase()} set`}).status_message"
              :button-text="((props.item.current_stage_for_user || {}).stage || {title: 'None'}).title || `Missing ${featureName('Stage', 'singularize')} Title!`"
            />
          </td>
          <td>
            <VMenu
              v-if="$isUserAdmin || $isUserMentor && !props.item.is_complete"
              offset-y
              transition="scale-transition"
              :disabled="props.item.isSaving"
            >
              <template #activator="{on}">
                <VRow row>
                  <Tooltip
                    depressed
                    button-class="normal-text px-2"
                    right-icon="keyboard_arrow_down"
                    icon-right
                    icon-left
                    :button-attributes="{
                      elevation: 3
                    }"
                    :activator-v-on="on"
                    :disabled="props.item.isSaving"
                    :loading="props.item.isSaving"
                    :text="false"
                    :color="getCurrentStageStatusColor(props.item.current_stage_for_user || {})"
                    :text-color="getCurrentStageStatusTextColor((props.item.current_stage_for_user || {}).stage || {is_expired: false})"
                    :icon-color="getCurrentStageStatusIconColor(props.item.current_stage_for_user || {})"
                    :icon="getCurrentStageStatusIcon(props.item.current_stage_for_user || {})"
                    :title="getCurrentStageMessage(props.item.current_stage_for_user || {status_message: 'Not Started'})"
                    :button-text="getCurrentStageText(props.item.current_stage_for_user || {status: 'Not Started'})"
                  />
                </VRow>
              </template>
              <VList :id="`set-status-${props.item.id}`">
                <VListItem @click="onSetStageStatus(props.index, 'in-progress')">
                  In Progress
                </VListItem>
                <VListItem @click="onSetStageStatus(props.index, 'submitted')">
                  Submitted
                </VListItem>
              </VList>
            </VMenu>
            <Tooltip
              v-else
              depressed
              button-class="normal-text px-2"
              :text="false"
              :action="() => onToggleEditSubmittedItemDialog(props.item)"
              :color="getCurrentStageStatusColor(props.item.current_stage_for_user || {})"
              :text-color="getCurrentStageStatusTextColor((props.item.current_stage_for_user || {}).stage || {is_expired: false})"
              :icon-color="getCurrentStageStatusIconColor(props.item.current_stage_for_user || {})"
              :icon="getCurrentStageStatusIcon(props.item.current_stage_for_user || {})"
              :title="getCurrentStageMessage(props.item.current_stage_for_user || {status_message: 'Not Started'})"
              :button-text="getCurrentStageText(props.item.current_stage_for_user || {status: 'Not Started'})"
            />
          </td>
          <td v-if="!$isUserStudent && submittedFor == 'all'">
            <VBtn
              v-if="props.item.user"
              v-model="props.item.user.is_approved"
              icon
              small
              :color="props.item.user.is_approved ? 'primary' : 'disabled'"
              @click.stop="onApprove(props.item.user)"
            >
              <VIcon>{{ props.item.user.is_approved ? 'check_box' : 'check_box_outline_blank' }}</VIcon>
            </VBtn>
            <span v-else> N/A </span>
          </td>
          <td>{{ getCurrentStageSubmittedOn(props.item.current_stage_for_user || {}) }}</td>
          <template v-if="$isUserAdmin && submittedFor === 'all'">
            <td>
              <Tooltip :title="getReviewersTitle(props.item)">
                <template #default="{ on }">
                  <VBtn
                    v-on="on"
                    depressed
                    class="normal-text px-1"
                    :disabled="props.item.is_complete"
                    :color="props.item.reviewers_count > 0 ? 'info' : 'grey lighten-2'"
                    :text-color="props.item.reviewers_count > 0 ? 'white' : 'grey darken-1'"
                    @click="onToggleReviewersDialog(props.index, props.item)"
                  >
                    <VAvatar
                      size="22"
                      :class="props.item.reviewers_count > 0 ? 'info' : 'grey lighten-2'"
                      :style="{
                        color: 'grey darken-2'
                      }"
                    >
                      {{ props.item.reviewers_count }}
                    </VAvatar>
                    &nbsp;{{ grammaticalFeatureName('Reviewers', props.item.reviewers_count) }} &nbsp;
                    <VIcon
                      class="mr-1"
                      color="primary"
                      text-color="white"
                    >
                      add_circle
                    </VIcon>
                  </VBtn>
                </template>
              </Tooltip>
            </td>
          </template>
          <td>{{ props.item.submitted_on ? $timezone.formatDateTime(props.item.submitted_on) : props.item.internal_status }}</td>
          <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
          <td class="actions">
            <Menu
              :disabled="props.item.isSaving || props.item.isLoading"
              :loading="props.item.isSaving || props.item.isLoading"
            >
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="canDeleteSubmitted(props.item)"
                @click="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <VCard flat>
            <VCardText>
              <VExpansionPanels focusable>
                <VExpansionPanel
                  v-for="(submittedStage,key) in (props.item.stages || [])"
                  :key="`stage-${key+1}`"
                  :class="{
                    'grey lighten-2 disabled': submittedStage.is_expired,
                    'px-2 py-4': true
                  }"
                  style="width: 100%"
                  lazy
                  @click.stop="() => {}"
                >
                  <VExpansionPanelHeader>
                    <h2 class="title">
                      {{
                        submittedStage.stage
                          ? submittedStage.stage.title
                          : `(Default) ${featureName('Stage', 'singularize')} ${key + 1}`
                      }}
                      <VChip v-show="submittedStage.status">
                        {{ (submittedStage.status || 'in-progress').titleize() }}
                      </VChip>
                    </h2>
                    <p
                      class="formatted"
                      v-if="submittedStage.description"
                      v-html="submittedStage.description"
                    />
                  </VExpansionPanelHeader>
                  <div class="px-2">
                    <DynamicInputsView
                      v-if="submittedStage.metadata instanceof Array && submittedStage.metadata.length"
                      :card="false"
                      :inputs="submittedStage.metadata"
                      :input-name="featureName('Metadata')"
                    />
                    <VAlert
                      v-else
                      type="info"
                      :value="true"
                    >
                      No entries set for this stage
                    </VAlert>
                  </div>
                </VExpansionPanel>
              </VExpansionPanels>
            </VCardText>
          </VCard>
        </template>
      </DataViewUI>
      <Confirm ref="confirmDelete" />
      <Dialog
        fullscreen
        persistent
        max-width="75%"
        :is-dialog-open="submittedDialogs.submitted"
        :title="`Update Submitted ${hasSubmittedModel ? getPipelineType(submittedModel) : ''}: ${hasSubmittedModel && submittedModel.pipeline ? submittedModel.pipeline.title : ''}`"
        :action="onSubmitPipeline"
        :hide-overlay="true"
        :close-immediately="false"
        @toggle:dialog="onToggleSubmittedDialog"
      >
        <SubmitForm
          v-if="!$isUserStudent && submittedDialogs.submitted && hasSubmittedModel"
          mode="dialog"
          ref="submitPipeline"
          :id="submittedModel.id"
          :pipeline="submittedModel.pipeline"
          :existing="submittedModel"
          :items="submitted.data"
          @isFormReady="onToggleItemLoading(submittedModel.id, false)"
        />
      </Dialog>
      <Dialog
        fullscreen
        max-width="75%"
        :is-dialog-open="dialogs.view"
        :title="`View Submitted ${currentModel ? getPipelineType(currentModel) : ''}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          ref="viewItem"
          mode="dialog"
          :id="currentModel ? {
            id: currentModel.id,
            pipeline_id: currentModel.pipeline_id
          } : null"
        />
      </Dialog>
      <Dialog
        fullscreen
        title="Submitted"
        :is-dialog-open="submittedDialogs.singleSubmitted"
        @toggle:dialog="onToggleSingleSubmittedDialog"
      >
        <ViewComponent
          mode="dialog"
          :existing="submittedModel"
          :can-get="false"
        />
      </Dialog>
      <StageSubmit
        v-if="hasSubmittedApplication && dialogs.applicationForm"
        is-inputs-required
        mode="dialog"
        ref="submitPipeline"
        :is-dialog-open="dialogs.applicationForm"
        :id="{
          id: (currentApplicationModel.current_stage_for_user || {}).id,
          pipeline_id: (submittedApplication || {}).pipeline.id,
          pipeline_stage_id: currentApplicationModel.current_stage_id
        }"
        :pipeline="(submittedApplication || {}).pipeline"
        :application="submittedApplication"
        :stage="currentApplicationStage"
        :stage-index="currentApplicationStageIndex"
        :deadline="currentStageDeadline"
        @toggle:dialog="() => dialogs.applicationForm = false"
        @startFormLoading="onUpdateCurrentModelLoading(true)"
        @doneFormLoading="onUpdateCurrentModelLoading(false)"
      />
      <MessagesDialog
        v-if="hasSubmittedApplication"
        max-width="640px"
        entity-type="pipeline-submitted"
        :is-dialog-open="dialogs.applicantChat"
        :title="submittedApplicationThreadTitle"
        :members="(submittedApplication.reviewers || []).map(u => u.id)"
        :entity="submittedApplication"
        :thread="submittedApplication.thread_for_user"
        :user="submittedApplication.user"
        :enable-edit="submittedFor === 'all' && $isUserAdmin"
        dialog-hide-overlay
        @toggle:dialog="onToggleApplicantChat"
        @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
      />
      <Dialog
        :is-dialog-open="dialogs.reviewers.active"
        :title="`Add ${featureName('Reviewers')}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleReviewersDialog"
      >
        <MembersForm
          ref="addReviewers"
          type="mentors"
          mode="multiple"
          :existing="(dialogs.reviewers.model || {}).reviewers || []"
          :is-group="true"
          :parent-is-saving="dialogs.reviewers.isSaving"
          @addMember="user => onAddReviewerToApplication(dialogs.reviewers.index, user)"
          @removeMember="user => onRemoveReviewerFromApplication(dialogs.reviewers.index, user)"
          @saveMembers="members => onSaveReviewersForApplication(dialogs.reviewers.index, true)"
        />
      </Dialog>
      <Dialog
        :is-dialog-open="dialogs.approve"
        title="Approve"
        persistent
        @toggle:dialog="dialogs.approve = !dialogs.approve"
      >
        <VRow
          v-if="currentModel"
          column
        >
          <VCol cols="12">
            <p class="px-6">
              Are you sure you want to {{ currentModel.is_approved ? "dis-approve" : "approve" }} this user?
            </p>
          </VCol>
          <VCol cols="12">
            <div class="d-flex align-center justify-space-between px-4">
              <VBtn
                class="mr-2"
                text
                :disabled="isApproving"
                @click="dialogs.approve = !dialogs.approve"
              >
                Cancel
              </VBtn>
              <VBtn
                :disabled="isApproving"
                :loading="isApproving"
                :color="currentModel.is_approved ? 'error' : 'success'"
                @click="onDoApprove"
              >
                {{ currentModel.is_approved ? "Dis-Approve" : "Approve" }}
              </VBtn>
            </div>
          </VCol>
        </VRow>
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import MessagesDialog from "@/components/Chat/MessagesDialog";
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasDueMixin from "@/mixins/HasDueOn";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import MetadataMixin from "@/mixins/Metadata";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./ViewSubmitted";
import HasMembersMixin from "@/mixins/HasMembers";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import StageSubmit from "@/components/Organization/Pipelines/StageSubmit";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "ApplicationsSubmitted",
  components: {
    DynamicInputsView,
    SubmitForm,
    ViewComponent,
    MessagesDialog,
    MembersForm,
    StageSubmit,
    ViewHeader,
  },
  props: {
    pipeline: {
      type: Object,
      default: () => null,
    },
    stage: {
      type: Object,
      default: () => null,
    },
    submittedFor: {
      type: String,
      default: "all",
    },
    canLoadFromUrl: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    SearchMixin,
    MetadataMixin,
    HasSubmittedDataMixin,
    HasApplicationPipelinesMixin,
    HasDueMixin,
    HasMembersMixin,
    SupportsPipelines,
  ],
  data() {
    return {
      isApproving: false,
      dialogs: {
        approve: false,
        reviewers: {
          active: false,
          model: {},
        },
      },
      modelType: "pipelineSubmitted",
    };
  },
  created() {
    //We should load a specific application
    if (this.$route.query.pipeline_id && this.$route.query.id) {
      this.doGetPipelineSubmitted({
        pipeline_id: this.$route.query.pipeline_id,
        id: this.$route.query.id,
      }).then((result) => {});
    }
  },
  mounted() {
    this.data.data = [];
  },
  computed: {
    submittedHeaders() {
      if (this.$isUserAdmin && this.submittedFor === "all") {
        return [
          {
            text: "User",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
            hidden: this.pipeline && this.pipeline.hasOwnProperty("id"),
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "current_stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.status",
          },
          {
            text: "Approved",
            align: "left",
            sortable: true,
            value: "user.is_approved",
          },
          {
            text: `${this.featureName("Stage")} Submitted On`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.submitted_on",
          },
          {
            text: this.featureName("Reviewers", "pluralize"),
            align: "left",
            sortable: true,
            value: "reviewers.name",
          },
          {
            text: `${this.featureName(
              "Application",
              "singularize"
            )} Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ].filter((i) => i.hidden !== true);
      } else if (this.$isUserMentor && this.submittedFor == "all") {
        return [
          {
            text: "User",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
            hidden: this.pipeline && this.pipeline.hasOwnProperty("id"),
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "current_stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.status",
          },
          {
            text: "Approved",
            align: "left",
            sortable: true,
            value: "user.is_approved",
          },
          {
            text: `${this.featureName("Stage")} Submitted On`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.submitted_on",
          },
          /* {
            text: `${this.featureName("Application", "singularize")} Status`,
            align: "left",
            sortable: true,
            value: "status",
          }, */
          {
            text: `${this.featureName(
              "Application",
              "singularize"
            )} Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ].filter((i) => i.hidden !== true);
      } else {
        return [
          {
            text: this.featureName("Team", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.team.name",
          },
          {
            text: this.featureName("Pipeline", "singularize"),
            align: "left",
            sortable: true,
            value: "pipeline.title",
            hidden: this.pipeline && this.pipeline.hasOwnProperty("id"),
          },
          {
            text: this.featureName("Stage"),
            align: "left",
            sortable: true,
            value: "current_stage_for_user.stage.title",
          },
          {
            text: `${this.featureName("Stage")} Status`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.status",
          },
          {
            text: `${this.featureName("Stage")} Submitted On`,
            align: "left",
            sortable: true,
            value: "current_stage_for_user.submitted_on",
          },
          {
            text: `${this.featureName(
              "Application",
              "singularize"
            )} Submitted On`,
            align: "left",
            sortable: true,
            value: "submitted_on",
          },
          {
            text: "Started On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
        ].filter((i) => i.hidden !== true);
      }
    },
  },
  methods: {
    ...mapActions({
      doGetSubmitted: "pipelineSubmitted/getAll",
      doDeletePipelineSubmitted: "pipelineSubmitted/delete",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      doSavePipelineStageSubmitted: "pipelineStageSubmitted/save",
      doGetIndexConfig: "pipelineSubmitted/getIndexConfig",
      doApproveUser: "team/approveUser",
    }),
    onDelete(submitted) {
      return this.doDeletePipelineSubmitted(submitted.id);
    },
    onGetAll(_params) {
      const content = this.pipeline || {};
      const pipelineId =
        this.$route.params.pipeline ||
        ((content.type || "").indexOf("pipeline") > -1
          ? content.id
          : content.pipeline_id);
      const pipelineStageId = this.$route.params.stage || (this.stage || {}).id;
      const params = {
        ...(_params || {}),
        ...{
          id: pipelineId,
          pipeline_stage_id: pipelineStageId,
          for: this.submittedFor,
          filter: {
            ...(_params.filter || {}),
            ...(this.customFilter && this.customFilter.hasOwnProperty("filter") ? this.customFilter.filter : {}),
          },
        },
      };
      return this.doGetSubmitted(params).then((result) => {
        this.$emit("updateStats", result);
        return result;
      });
    },
    onApprove(user) {
      this.dialogs.approve = true;
      this.currentModel = user;
    },
    onDoApprove() {
      this.isApproving = true;
      this.doApproveUser(this.currentModel.id)
        .then((result) => {
          const index = this.data.data.findIndex(
            (item) => item.user.id === this.currentModel.id
          );
          this.data.data[index].user.is_approved = result.is_approved;
          this.dialogs.approve = this.isApproving = false;
          this.$bus.$emit(
            "notificationMessage",
            result.is_approved ? "Approved user!" : "Dis-Approved user!"
          );
        })
        .catch((error) => {
          this.dialogs.approve = this.isApproving = false;
          this.$bus.$emit("notificationError", "Unable to approve user");
        });
    },
    refresh() {
      this.$refs.dataView.loadData(true);
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig({
        id: this.pipeline.id,
      });
    },
    onSubmitPipeline() {
      return this.$refs["submitPipeline"].onSave();
    },
    onAddReviewerToApplication(index, user) {
      const item = this.data.data[index];
      item.reviewers = item.reviewers || [];
      this.$set(
        this.data.data[index],
        "reviewers",
        this.onAddMember(item.reviewers, user)
      );
    },
    onRemoveReviewerFromApplication(index, user) {
      const item = this.data.data[index];
      item.reviewers = item.reviewers || [];
      this.$set(
        this.data.data[index],
        "reviewers",
        this.onRemoveMember(item.reviewers, user)
      );
    },
    onSaveReviewersForApplication(index, leaveDialogOpen) {
      const item = this.data.data[index];
      let params = {
        id: item.id,
        pipeline_id: item.pipeline_id,
        reviewers: item.reviewers.map((member) =>
          member instanceof Object
            ? {
                id: member.id,
                deleted: !!member.deleted,
              }
            : member
        ),
      };
      this.$set(this.dialogs.reviewers, "isSaving", true);
      this.doSavePipelineSubmitted(params).then((response) => {
        const result = response.data;
        const existing = this.data.data[index];
        this.$set(this.data.data[index], "reviewers", result.reviewers);
        this.$set(this.dialogs.reviewers.model, "reviewers", result.reviewers);
        this.$set(this.dialogs.reviewers, "isSaving", false);
        if (!leaveDialogOpen) {
          this.$set(this.dialogs.reviewers, "active", false);
        }
        this.$bus.$emit(
          "notificationMessage",
          result.message ||
            `Updated ${this.featureName("reviewers")
              .pluralize()
              .toLowerCase()} successfully!`
        );
      });
    },
    onToggleReviewersDialog(index, item) {
      this.dialogs.reviewers.active = !this.dialogs.reviewers.active;
      this.dialogs.reviewers.index = index;
      this.dialogs.reviewers.model = item;
    },
    onToggleEditSubmittedItemDialog(item) {
      if (this.$isUserStudent || this.submittedFor === "me") {
        this.onToggleApplicationForm(item);
      } else {
        this.onToggleSubmittedDialog(item, "pipelines");
      }
    },
    onUpdateCurrentModelLoading(isLoading) {
      const item = this.data.data.find(
        (item) => item.id == (this.currentApplicationModel || {}).id
      );
      if (item instanceof Object) {
        this.$set(item, "isLoading", !!isLoading);
      }
    },
    getReviewersTitle(item) {
      return item.reviewers_count
        ? `${item.reviewers.map((r) => r.name).join(", ")}`
        : `No ${this.featureName("Reviewers", "pluralize")}`;
    },
    canEditSubmitted(item) {
      return (
        (this.$isUserAdmin && this.submittedFor === "all") ||
        this.$isUserMentor ||
        (!item.pipeline.is_expired &&
          (this.teamSetting(
            "pipeline.is_application_editable_until_deadline",
            false
          ) ||
            !item.is_submitted))
      );
    },
    canDeleteSubmitted(item) {
      return this.$isUserAdmin && this.submittedFor === "all";
    },
    stageFor(item, stageId) {
      let id = stageId || item.current_stage_id;
      return item.stages.find((m) => m.pipeline_stage_id === id) || {};
    },
    currentStageFor(item) {
      return this.stageFor(item, item.current_stage_id);
    },
    stageDeadlineFor(item, stageId) {
      const stage = this.stageFor(item);
      const dueOn = stage.due_on || stage.pipeline.due_on;
      return dueOn ? this.$timezone.formatDateTime(dueOn) : "No deadline";
    },
    stageIndexFor(item, stageId) {
      let id = stageId || item.current_stage_id;
      return item.stages.findIndex((m) => m.pipeline_stage_id === id) || {};
    },
    onSetStageStatus(index, status) {
      let canSave = true;
      const stage = Object.select(
        { ...this.data.data[index].current_stage_for_user },
        [
          "id",
          "user_id",
          "is_submitted",
          "is_completed",
          "pipeline_id",
          "pipeline_stage_id",
        ]
      );
      switch (status) {
        case "in-progress":
          stage.is_submitted = false;
          stage.is_complete = false;
          break;
        case "completed":
          stage.is_submitted = true;
          stage.is_complete = true;
          break;

        case "submitted":
          stage.is_submitted = true;
          stage.is_complete = false;
          break;

        default:
          canSave = false;
          break;
      }
      if (canSave) {
        this.$set(this.data.data[index], "isSaving", true);
        this.$log.info(
          "Setting stage status to",
          status,
          "on",
          stage,
          canSave,
          (this.pipeline || {}).id
        );
        if (!stage.pipeline_id && this.pipeline instanceof Object) {
          stage.pipeline_id = this.pipeline.id;
        } else {
          stage.pipeline_id =
            stage.pipeline_id || this.data.data[index].pipeline_id;
        }
        this.doSavePipelineStageSubmitted({ data: stage }).then((result) => {
          this.data.data[index].current_stage_for_user = result.data || result;
          delete this.data.data[index].isSaving;
          this.$bus.$emit(
            "notificationMessage",
            `Updated ${this.featureName("stage").toLowerCase()} successfully!`
          );
        });
      }
    },
    onSetStage(index, stageId) {
      const submitted = Object.select(this.data.data[index], [
        "id",
        "user_id",
        "pipeline_id",
        "current_stage_id",
      ]);
      submitted.pipeline_stage_id = submitted.current_stage_id;
      submitted.current_stage_id = stageId;
      this.$set(this.data.data[index], "isSaving", true);
      this.doSavePipelineSubmitted({ data: submitted }).then((result) => {
        this.data.data[index].current_stage_for_user =
          result.current_stage_for_user;
        this.data.data[index].current_stage_id = result.current_stage_id;
        this.$delete(this.data.data[index], "isSaving");
        this.$bus.$emit(
          "notificationMessage",
          `Updated ${this.featureName("stage").toLowerCase()} successfully!`
        );
      });
    },
    getPipelineType(item) {
      return item.type == "pipeline" ? "Registration" : "Application";
    },
  },
};
</script>