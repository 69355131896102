import BaseApi from "./_base-api";

/**
 * Product Transactions API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {}

const api = new Api({
	type: "Product Transaction",
	path: {
		getAll: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id || params.id;
			if (productId) {
				return `products/${productId}/transactions`;
			} else {
				return `products/transactions`;
			}
		},
		getOne: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/transactions`;
		},
		save: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/transactions`;
		},
		delete: (app, _params) => {
			const params = _params || {};
			const productId = params.productId || params.product_id;
			return `products/${productId}/transactions`;
		},
		default: "products/transactions",
	},
});

export default api;
