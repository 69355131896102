<template>
  <VRow
    row
    wrap
  >
    <VCol
      v-if="altView"
      cols="12"
      class="alt-date-time-picker"
    >
      <VMenu
        v-model="menu.dateMenu"
        offset-y
        ref="dateMenu"
        transition="scale-transition"
        min-width="290px"
        :close-on-content-click="false"
        :return-value.sync="date"
      >
        <template #activator="{ on, attrs }">
          <TextField
            v-bind="attrs"
            v-on="on"
            readonly
            clearable
            label="Select Date"
            :value="formattedDate"
            @click:clear="onClear"
          >
            <template #append>
              <img
                v-bind="attrs"
                v-on="on"
                width="24px"
                src="../../../images/forms/calendar-icon@2x.png"
                style="z-index: 5; cursor: pointer;"
              >
            </template>
          </TextField>
        </template>
        <VDatePicker
          v-model="date"
          scrollable
          no-title
          @input="updateDate(date)"
        />
      </VMenu>
      <TextField
        v-model="time"
        ref="timeMenu"
        slot="activator"
        type="time"
        label="Enter Time"
        :disabled="!date"
        @input="updateTime(time)"
        @click:clear="updateTime('')"
      />
    </VCol>
    <template v-else>
      <VCol
        cols="12"
        sm="6"
      >
        <VMenu
          v-model="menu.dateMenu"
          offset-y
          ref="dateMenu"
          transition="scale-transition"
          min-width="290px"
          :close-on-content-click="false"
          :return-value.sync="date"
        >
          <template #activator="{ on, attrs }">
            <TextField
              v-bind="attrs"
              v-on="on"
              v-model="date"
              readonly
              clearable
              label="Select Date"
              prepend-icon="event"
              @click:clear="onClear"
            />
          </template>
          <VDatePicker
            v-model="date"
            scrollable
            no-title
            @input="updateDate(date)"
          />
        </VMenu>
      </VCol>
      <VCol
        cols="12"
        sm="6"
      >
        <TextField
          ref="timeMenu"
          slot="activator"
          v-model="time"
          type="time"
          label="Enter Time"
          prepend-icon="access_time"
          :disabled="!date"
          @input="updateTime(time)"
        />
      </VCol>
    </template>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "DateTimePicker",
  props: {
    value: {
      type: [String, Date],
      default: () => new Date(),
    },
    altView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: "",
      time: "",
      menu: {
        startDateMenu: false,
        dateMenu: false,
        startTimeMenu: false,
        timeMenu: false,
      },
    };
  },
  watch: {
    value: function (value) {
      this.setValue(value);
    },
  },
  created() {
    this.setValue(this.value);
  },
  computed: {
    formattedDate() {
      return this.date ? this.$timezone.formatDate(this.date) : "";
    },
  },
  methods: {
    updateTime(time) {
      this.time = time || "";
      this.update();
    },
    updateDate(date) {
      this.$refs.dateMenu.save(date);
      if (date && !this.time) {
        this.time = "00:00:00";
      }
      this.update();
    },
    update() {
      const dateTime = this.$timezone.formatDate(this.date, 'YYYY-MM-DD') + " " + (this.time || "");
      this.$emit("input", dateTime);
    },
    onClear() {
      this.date = "";
      this.time = "";
      this.$emit("input", "");
    },
    setValue(value) {
      if (value && value.length) {
        try {
          const dateTime = this.$timezone.parseDateTime(value);
          console.log("Datetime", dateTime, dateTime.toString(), dateTime.format('YYYY-MM-DD HH:mm:ss'));
          this.date = dateTime.format('YYYY-MM-DD');
          this.time = dateTime.format('HH:mm');
        } catch (e) {
          console.warn(e);
          let parts = value.split(" ");
          if (parts.length == 2) {
            this.date = parts[0];
            this.time = parts[1] || "00:00:00";
          }
        }
      } else {
        this.date = "";
        this.time = "";
      }
    },
  },
};
</script>
<style lang="scss">
.alt-date-time-picker {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: url("../../../images/forms/time-icon@2x.png");
    background-repeat: no-repeat;
    background-size: 24px;
    // background-position: bottom 0px right 0px;
  }
  input[type="time"] {
    padding: 0px;
  }
}
</style>