<template>
  <VRow>
    <VCol cols="12">
      <VTabs
        grow
        slider-color="primary"
        style="display: none;"
        :show-arrows="true"
      >
        <VTab
          v-for="(tab, index) in enabledTabs"
          :to="{
            hash: tab.url
          }"
          :key="index"
        >
          {{ tab.title }}&nbsp;
          <BetaBadge
            enable-tooltip
            :is-beta="tab.beta"
          />
        </VTab>
        <!-- <VTab>Integrations</VTab> -->
      </VTabs>
      <VTabsItems
        touchless
        :value="activeUrlTab"
      >
        <VTabItem
          v-for="(t,i) in enabledTabs"
          class="py-6"
          :key="`setting-${i}`"
        >
          <component
            class="mx-0"
            :is="t.component"
            :id="$team.id"
            :form-config="formConfig"
          />
        </VTabItem>
      </VTabsItems>
    </VCol>
  </VRow>
</template>
<script>
import Settings from "./Settings";
import Registration from "./Registration";
import Workspace from "./Workspace";
import Customization from "./Customization";
import AutomationWorkflows from "./AutomationWorkflows";
import Pipelines from "./Pipelines/index";
import SearchMixin from "@/mixins/Search";
import { mapActions } from "vuex";

export default {
  name: "Organization",
  mixins: [SearchMixin],
  components: {
    Settings,
    Registration,
    Workspace,
    Customization,
    AutomationWorkflows,
    Pipelines,
  },
  data() {
    return {
      tabsMatchMode: "custom",
      formConfig: {},
    };
  },
  computed: {
    tabs() {
      const env = this.$root.env;
      return [
        {
          title: "Settings",
          url: "settings",
          enabled: this.$isUserAdmin,
          component: "Settings",
        },
        /* {
          title: "Registration",
          url: "registration",
          enabled: this.$isUserAdmin,
          component: "Registration",
        }, */
        /* {
          title: "Rename Things",
          url: "rename-things",
          enabled: this.$isUserAdmin,
          component: "Workspace",
        }, */
        {
          title: this.featureName("Pipelines"),
          url: "pipelines",
          enabled:
            this.isEnabledFeature("PIPELINES") &&
            (this.$isUserAdmin || this.$isUserMentor),
          beta: this.isBetaFeature("PIPELINES"),
          component: "Pipelines",
        },
        {
          title: "Automation Workflows",
          url: "automations",
          enabled:
            this.$team.is_enterprise &&
            this.isEnabledFeature("AUTOMATIONS") &&
            this.$isUserAdmin,
          beta: this.isBetaFeature("AUTOMATIONS"),
          component: "AutomationWorkflows",
        },
        {
          title: "Customization",
          url: "customize",
          enabled:
            this.$team.can_customize &&
            this.isEnabledFeature("CUSTOMIZATION") &&
            this.$isUserAdmin,
          beta: this.isBetaFeature("CUSTOMIZATION"),
          component: "Customization",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "team/getFormConfig",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
  },
  created() {
    this.onGetFormConfig().then((config) => {
      this.formConfig = config;
    });
  },
};
</script>