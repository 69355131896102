<template>
  <VRow
    row
    align-center
    class="index-header"
    :column="$vuetify.breakpoint.smAndDown"
    :wrap="$vuetify.breakpoint.smAndDown"
  >
    <VCol
      grow
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        'order-sm1': true,
        'order-md1': true
      }"
    >
      <VRow
        row
        align-center
        justify-start
        :class="{
          'flex-nowrap': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <VCol pl-3>
          <h1
            :class="{
              'text-truncate text-no-wrap title' : true,
              'text-center': $vuetify.breakpoint.smAndDown
            }"
            :style="{
              textTransform: uppercaseTitle ? 'uppercase' : 'none',
              whiteSpace: $vuetify.breakpoint.smAndDown ? 'normal' : 'normal',
            }"
          >
            <VBtn
              icon
              fab
              @click="onGoBack"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            <slot name="title">
              View {{ featureName(title || modelType).capitalize() }}
              &nbsp;
            </slot>
            <BetaBadge
              enable-tooltip
              :is-beta="isBetaFeature((owner || $parent).modelType)"
            />
            <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, routeParams || ((owner || $parent).model || {}).id)" />
            <Linkable
              v-if="!$isUserStudent"
              icon="edit"
              tooltip="Click or tap to update"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.update`, routeParams || ((owner || $parent).model || {}).id)"
              :to="getRouteForItem(`${modelTypeRoute}.update`, routeParams || ((owner || $parent).model || {}).id)"
            />
            <slot name="extra-buttons" />
          </h1>
        </VCol>
        <VCol style="flex: 0 0 48px">
          <slot name="title-button" />
        </VCol>
      </VRow>
    </VCol>
    <VCol
      v-if="hasContent"
      align-center
      justify-start
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        md4: $vuetify.breakpoint.mdAndUp || hasContent,
        'order-sm3': true,
        'order-md2': true,
        'd-inline-flex': true
      }"
    >
      <slot name="content-center" />
    </VCol>
    <VCol
      :class="{
        xs12: $vuetify.breakpoint.smAndDown,
        'order-sm2': true,
        'order-md3': true
      }"
    >
      <VRow
        row
        align-center
        :justify-end="$vuetify.breakpoint.smAndUp"
        :class="{
          'flex-nowrap': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
        <VCol
          px-1
          class="d-inline-flex"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          :shrink="$vuetify.breakpoint.mdAndUp"
        >
          <slot name="actions-left" />
        </VCol>
        <VCol
          px-1
          class="d-inline-flex"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          :shrink="$vuetify.breakpoint.mdAndUp"
        >
          <slot name="actions-right" />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
import SupportsModelTypes from "@/mixins/SupportsModelTypes";
export default {
  props: {
    owner: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    type: {
      type: String,
      default: null,
    },
    typeAlt: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    contentClass: {
      type: [String, Array, Object],
      default: "xs12 md6",
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
    uppercaseTitle: {
      type: Boolean,
      default: false,
    },
    routeParams: {
      type: [Object, String, Number],
      default: null,
    },
    goBack: {
      type: Function,
      default: null,
    },
  },
  mixins: [SupportsModelTypes],
  data() {
    return {
      buttonHover: false,
      isBackToDefault: false,
    };
  },
  created() {
    if(this.$route.params && this.$route.params.isBackToDefault !== undefined) {
      this.isBackToDefault = this.$route.params.isBackToDefault;
    }
    this.modelType = this.type || (owner || $parent).modelType;
    this.modelTypeAlt = this.typeAlt || (owner || $parent).modelTypeAlt;
  },
  methods: {
    onGoBack() {
      if(this.isBackToDefault) {
        return this.$router.go(-1);
      }
      if (this.goBack instanceof Function) {
        return this.goBack();
      }
      return this.$router.length > 1
        ? this.$router.back()
        : this.navigateTo({ name: `${this.modelType}.index` });
    },
  },
};
</script>