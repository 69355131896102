<template>
  <VContainer
    grid-list-lg
    fluid
    id="save-applicant"
    :class="{
      'py-0': true,
      'is-loading': activity.isLoading || activity.isOptionsLoading
    }"
  >
    <VRow
      column
      v-if="fullStage"
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>Submit {{ featureName('Pipelines') }}</h1>
          <div>
            <VBtn
              large
              class="wethrive"
              :disabled="!isFormReady || isFormInputsDisabled"
              @click="onSave"
            >
              Save
              Changes
            </VBtn>
          </div>
        </VRow>
      </VCol>
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol
        style="flex: 1 1 auto"
        :class="{
          'py-0': true,
          'px-0': $vuetify.breakpoint.mdAndUp,
          'px-4': $vuetify.breakpoint.smAndDown
        }"
      >
        <VTabs
          lazy
          slider-color="primary"
          class="pb-0"
          :grow="$vuetify.breakpoint.smAndDown"
          :vertical="$vuetify.breakpoint.mdAndUp"
        >
          <VTab
            v-for="(tab, key) in tabs"
            :key="key"
            lazy
          >
            {{ tab.title }}
          </VTab>
          <VTabItem>
            <VCard>
              <VCardTitle class="pl-4 title">
                Submitted: {{ form.is_submitted ? $timezone.formatDateTime(form.submitted_on) : 'Not Submitted' }}
              </VCardTitle>
              <VCardText>
                <VRow class="px-3 mb-5">
                  <VCol class="pt-0">
                    <VSwitch
                      v-model="form.is_complete"
                      inset
                      class="mt-0"
                      label="Is this stage complete?"
                      hint="Marking an stage as complete removes it from the user's dashboard. They can review it later but cannot make any further changes once it is complete."
                      persistent-hint
                    />
                  </VCol>
                  <VCol class="pt-0">
                    <VSwitch
                      v-model="form.is_submitted"
                      inset
                      class="mt-0"
                      label="Is this stage submitted?"
                      hint="You may submit the stage on behalf of the user. This means that you would be recorded as the one who submitted it"
                      persistent-hint
                    />
                  </VCol>
                </VRow>
                <VRow class="mb-5">
                  <VCol class="pt-0">
                    <Autocomplete
                      v-model="form.user_id"
                      hide-no-data-msg
                      append-selection-text
                      item-text="name"
                      item-value="id"
                      append-inner-icon="arrow-down"
                      placeholder="Find user"
                      label="User"
                      :items="users"
                      :filter-options="{
                        type: 'user'
                      }"
                      :getter="doGetUsers"
                    >
                      <template #selection="slotProps">
                        <span class="v-select__selection--comma">
                          {{ slotProps.item.name }} ({{ slotProps.item.email }})
                        </span>
                      </template>
                      <template #item="slotProps">
                        {{ slotProps.item.name }} ({{ slotProps.item.email }})
                      </template>
                    </Autocomplete>
                  </VCol>
                  <VCol class="pt-0">
                    <Autocomplete
                      v-model="form.pipeline_stage_id"
                      persistent-hint
                      item-text="title"
                      item-value="id"
                      append-inner-icon="arrow-down"
                      placeholder="Select stage"
                      label="Current Stage"
                      hint="This is the stage/round that the user will have access to edit in the application process. Make sure to mark the previous stages as complete by toggling the switch for the appropriate stage."
                      :items="stages"
                    />
                  </VCol>
                </VRow>
                <VRow
                  class="mb-5"
                  v-if="hasPipelineStatuses"
                >
                  <VCol class="pt-0">
                    <Autocomplete
                      v-model="form.status"
                      item-text="title"
                      item-value="id"
                      append-inner-icon="arrow-down"
                      placeholder="Select status"
                      label="Status"
                      :items="formatStatusOptions(fullStage.statuses || {})"
                    />
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
          </VTabItem>
          <VTabItem class="px-3">
            <VCard>
              <VCardTitle>
                <VRow
                  row
                  justify-space-between
                  align-center
                >
                  <h4>{{ `Reviewers` }}</h4>
                  <VBtn
                    text
                    @click="onToggleMemberDialog('mentors')"
                  >
                    <VIcon class="wethrive">
                      add
                    </VIcon>
                    {{ `Add Reviewer` }}
                  </VBtn>
                </VRow>
              </VCardTitle>
              <VCardText>
                <MembersList
                  v-if="form.reviewers && form.reviewers.length"
                  :users="form.reviewers"
                  @removeMember="(member) => removeMember(member, 'reviewers')"
                />
                <p v-else>
                  No reviewers have been added
                </p>
              </VCardText>
            </VCard>
          </VTabItem>
          <VTabItem class="px-3">
            <p
              v-if="stage.description"
              v-html="stage.description"
            />
            <DynamicInputsSubmitStepper
              v-model="form.metadata"
              item-title-class="subheading bordered-y py-4"
              container-class="px-0 elevation-0"
              :can-submit="false"
              :current-section="section"
              :activity="activity"
              :metadata-sections="metadataSections || {}"
              :section-end="metadataSections.length"
              @input="onUpdateStageMetadata"
              @errors="onUpdateErrors"
              @update:section="onUpdateSection"
            />
          </VTabItem>
        </VTabs>
      </VCol>
      <VCol v-if="mode === 'standalone'">
        <VRow
          row
          justify-center
        >
          <VBtn
            large
            class="wethrive"
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSave"
          >
            Save
            Changes
          </VBtn>
        </VRow>
      </VCol>
      <Dialog
        :is-dialog-open="dialogs.members"
        :title="`Add ${memberDialog.type.titleize()}`"
        :hide-overlay="true"
        @toggle:dialog="onToggleMemberDialog"
      >
        <MembersForm
          ref="addMembers"
          mode="multiple"
          :type="memberDialog.type"
          :existing="memberDialog.existing"
          :is-group="true"
          :exclude-me="false"
          @addMember="user => addMember(user, 'reviewers')"
          @removeMember="removeMember"
          @saveMembers="dialogs.members = false"
        />
      </Dialog>
    </VRow>
  </VContainer>
</template>

<script>
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "@/mixins/SavesSubmittedData.vue";
import HasMembersMixin from "@/mixins/HasMembers";
import HasDynamicInputSectionsMixin from "@/mixins/HasDynamicInputSections";
import MembersForm from "@/components/Elements/Forms/MembersForm";
import MembersList from "@/components/Elements/Data/MembersList";
import DynamicInputsSubmitStepper from "@/components/Elements/Forms/DynamicInputsSubmitStepper";
import SupportsPipelines from "../../../mixins/SupportsPipelines";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between,
} from "vuelidate/lib/validators";
export default {
  name: "PipelinesSubmitStageForm",
  components: {
    MembersForm,
    MembersList,
    DynamicInputsSubmitStepper,
  },
  mixins: [
    FormMixin,
    MetadataMixin,
    SavesSubmittedData,
    HasMembersMixin,
    HasDynamicInputSectionsMixin,
    SupportsPipelines,
  ],
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modelType: "PipelineSubmittedStage",
      hasFiles: true,
      selectedEntityType: null,
      options: {
        pipeline: [],
        types: [],
      },
      defaultForm: {
        entity_type: "App\\Models\\Pipeline",
        pipeline_stage_id: null,
        metadata: [],
        reviewers: [],
      },
      fullStage: null,
      combinedMetadata: [],
    };
  },
  validations: {
    form: {
      user_id: {
        required,
      },
      metadata: {
        $each: {
          value: {},
        },
      },
    },
  },
  computed: {
    tabs() {
      return [
        {
          title: "Details",
        },
        {
          title: "Associations",
        },
        {
          title: "Responses",
        },
      ];
    },
    users() {
      return this.id ? [this.form.user] : this.options.users;
    },
    application() {
      return this.form.pipeline_submitted || {};
    },
    pipeline() {
      return this.fullStage.pipeline || {};
    },
    stages() {
      return this.options && this.options.stages instanceof Array
        ? this.options.stages.filter(
            (stage) => stage.pipeline_id === this.fullStage.pipeline_id
          )
        : [];
    },
    metadataSections() {
      return (
        (this.form.stage
          ? this.form.stage.metadata_sections
          : this.stage.metadata_sections) || []
      );
    },
    hasStages() {
      return this.stages && this.stages.length > 0;
    },
    formHasStages() {
      return (
        (this.form instanceof Object && this.form.stages instanceof Array
          ? this.form.stages
          : []
        ).length > 0
      );
    },
    otherStages() {
      return this.formHasStages
        ? this.form.stages.filter(
            (stage) => stage.pipeline_stage_id != this.form.pipeline_stage_id
          )
        : [];
    },
    hasPipelineStatuses() {
      return (
        this.fullStage.statuses instanceof Object &&
        this.fullStage.statuses.options instanceof Object &&
        this.fullStage.statuses.options.options instanceof Array
      );
    },
    hasPipelineStageStatuses() {
      return (
        this.fullStage.stage_statuses instanceof Object &&
        this.fullStage.stage_statuses.options instanceof Object &&
        this.fullStage.stage_statuses.options.options instanceof Array
      );
    },
  },
  created() {
    this.section = 0;
    if (this.existing instanceof Object) {
      this.form = this.existing;
    }
    this.doGetStage({
      id: this.stage.id,
      pipeline_id: this.stage.pipeline_id,
    }).then((result) => {
      this.fullStage = result;
      if (!this.id) {
        this.form.pipeline_stage_id = this.fullStage.id;
        this.form.metadata =
          this.form.metadata || this.fullStage.metadata || [];
      }
    });
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineSubmitted/getFormConfig",
      doSavePipelineStageSubmitted: "pipelineStageSubmitted/save",
      doGetPipelineStageSubmitted: "pipelineStageSubmitted/getOne",
      doGetStage: "pipelineStage/getOne",
      doGetUsers: "users/getAll",
    }),
    onGet(id) {
      return this.doGetPipelineStageSubmitted({
        id,
        pipeline_id: this.stage.pipeline_id,
        pipeline_stage_id: this.stage.id,
      });
    },
    onGetFormConfig() {
      return this.doGetFormConfig({
        id: this.stage.pipeline_id,
      });
    },
    onSave() {
      // this.form.id = this.form.id || this.fullStage.id;
      this.form[this.property] = this.form.entity_id;
      this.form.pipeline_id = this.stage.pipeline_id;
      this.form.pipeline_stage_id =
        this.form.pipeline_stage_id || this.stage.id;
      return this.saveFormModel(this.doSavePipelineStageSubmitted, null, null, {
        dontSave: ["user", "stage", "pipeline_submitted", "evaluation"],
      });
    },
    formatStatusOptions(owner) {
      const _options = Object.get(owner || {}, "options.options") || [];
      let options =
        typeof _options === "string" ? _options.split(",") : _options;
      return (options || []).map((option) => {
        return {
          id: option,
          title: option.titleize(),
        };
      });
    },
    addMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onAddMember(this.form[type], member);
      // this.saveMentors(true);
    },
    removeMember(member, userType) {
      const type = userType || this.memberDialog.type;
      this.form[type] = this.onRemoveMember(this.form[type], member);
      // this.saveMentors(true);
    },
    next() {
      if (this.section == 0) {
        this.section = 1;
      }
      this.section++;
      this.$log.debug("Advancing to next section", this.section);
      if (this.$v) {
        this.$v.$touch();
      }
      this.onBackgroundSave();
      this.scrollToElement();
    },
    prev() {
      if (this.section > this.offset) {
        this.section--;
        this.$log.debug("Advancing to previous section", this.section);
        if (this.$v) {
          this.$v.$touch();
        }
        this.onBackgroundSave();
        this.scrollToElement();
      }
    },
    onUpdateSection(value) {
      this.section = value;
      this.onBackgroundSave();
    },
    onUpdateErrors(value) {
      console.log("Updating errors", value);
      this.errors = value;
    },
    onUpdateStageMetadata(data) {
      if (data) {
        this.form.metadata = data;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  .v-expansion-panel-header {
    padding: 0px 12px;

    .v-label {
      top: 0px;
    }
  }
}

#save-applicant {
  .v-tabs-items {
    height: calc(100vh - 148px);
    overflow: hidden auto;
  }
}
</style>

