<template>
  <div class="attendance-page">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AttendancePage",
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>