<template>
  <VRow
    column
  >
    <VCol
      v-if="mode === 'standalone'"
      style="flex: 0 0 64px"
    >
      <VRow
        row
        justify-space-between
      >
        <h1>Submit {{ featureName('Pipelines') }}</h1>
        <div>
          <VBtn
            class="wethrive"
            large
            :disabled="!isFormReady || isFormInputsDisabled"
            @click="onSave"
          >
            Save
            Changes
          </VBtn>
        </div>
      </VRow>
    </VCol>
    <VCol v-if="hasMessagesOrErrors">
      <Alerts
        :messages="messages"
        :errors="errors"
      />
    </VCol>
    <VCol style="flex: 1 1 auto">
      <VDataIterator
        :items="items"
        :rows-per-page-items="[1]"
        :pagination.sync="submittedPagination"
        content-tag="VRow"
        row
        wrap
        @update:pagination="setCurrentIndex"
      >
        <VCol
          slot="item"
          slot-scope="props"
        >
          <DynamicInputsSubmit
            v-model="props.item.metadata"
            :card="false"
            :input-name="featureName('Pipeline')"
          />
        </VCol>
      </VDataIterator>
    </VCol>
    <VCol>
      <VRow
        row
        justify-center
      >
        <VBtn
          class="wethrive"
          @click="onSave"
        >
          Save
          Changes
        </VBtn>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import DynamicInputsSubmit from "@/components/Elements/Forms/DynamicInputsSubmit";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import SavesSubmittedData from "@/mixins/SavesSubmittedData.vue";
import { mapActions } from "vuex";
import {
  required,
  requiredIf,
  minLength,
  between
} from "vuelidate/lib/validators";
export default {
  name: "PipelinesSubmit",
  components: {
    DynamicInputsSubmit
  },
  mixins: [FormMixin, MetadataMixin, SavesSubmittedData],
  data() {
    return {
      options: {
        supplements: [],
        primary_contents: []
      },
      defaultForm: {
        program_id: null,
        supplement_id: null,
        primary_content_id: null
      }
    };
  },
  validations: {
    form: {
      program_id: {
        required: requiredIf(function(form) {
          return !form.primary_content_id && !form.supplement_id;
        })
      },
      primary_content_id: {
        required: requiredIf(function(form) {
          return !form.program_id && !form.supplement_id;
        })
      },
      supplement_id: {
        required: requiredIf(function(form) {
          return !form.primary_content_id && !form.program_id;
        })
      }
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "pipelineSubmitted/getFormConfig",
      doSavePipelineSubmitted: "pipelineSubmitted/save",
      doGetPipeline: "pipeline/getOne"
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      this.form.is_submitted = true;
      this.form.pipeline_id = this.current.id;
      return this.saveFormModel(this.doSavePipelineSubmitted);
    },
    onGet(id) {
      return this.doGetPipeline(id);
    }
  }
};
</script>

