<script>
export default {
  data() {
    return {
      modelType: null,
      modelTypeAlt: null,
    };
  },
  created() {
    if (!this.modelType) {
      this.modelType = this.$route.name.split(".").shift();
    }
    if (!this.modelTypeAlt) {
      this.modelTypeAlt = this.$route.name.split(".").shift();
    }
  },
  computed: {
    modelTypeTitle() {
      return this.modelType.titleize();
    },
    modelTypeRoute() {
      return this.modelTypeTitle.replace(" ", "-").parameterize();
    },
    modelTypeAltTitle() {
      return (this.modelTypeAlt || this.modelType).titleize();
    },
    modelTypeAltRoute() {
      return (this.modelTypeAltTitle || this.modelTypeTitle)
        .replace(" ", "-")
        .parameterize();
    },
  },
};
</script>