<template>
  <Dialog
    persistent
    title="You've been logged out. Please sign in to continue"
    class="login-form"
    :is-dialog-open="isDialogOpen"
    :dismissable="false"
    @toggle:dialog="$emit('toggle:dialog')"
  >
    <VRow
      row
      align-center
      justify-center
    >
      <VCol>
        <VCard>
          <VCardText class="py-0">
            <form
              class="form px-0 py-0"
              name="login"
              @submit.prevent="login"
            >
              <VLabel>Logged In As</VLabel>
              <p class="subtitle">
                {{ form.email }}
              </p>
              <PasswordInput
                v-model="form.password"
                :disabled="false"
                :editable="false"
                :error="$v.form.password.$error"
                @keyup.enter="login"
              />
              <VRow
                align-center
                justify-center
              >
                <VCol cols="12">
                  <VBtn
                    block
                    :class="submitClass"
                    :disabled="!isFormReady"
                    @click.prevent="login"
                  >
                    {{ buttonText }}
                  </VBtn>
                </VCol>
                <VCol cols="12">
                  <VBtn
                    block
                    text
                    small
                    style="color: black"
                    :to="{name: 'login'}"
                  >
                    Logout
                  </VBtn>
                </VCol>
              </VRow>
            </form>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </Dialog>
</template>

<script>
import { AUTH_REQUEST, AUTH_REMEMBER } from "@/store/actionTypes";
import { email, required, minLength, between } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import { mapActions } from "vuex";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "LoginModalForm",
  mixins: [FormMixin],
  components: { PasswordInput },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      error: false,
      submitClass: "wethrive",
      buttonText: "Login",
      form: {
        password: "",
        email: "",
        teamSlug: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required
      },
      email: {
        email,
        required
      }
    }
  },
  computed: {
    username: {
      get() {
        if (localStorage.email) {
          return localStorage.email;
        } else {
          return this.form.email;
        }
      },
      set(value) {
        this.form.email = value;
      }
    },
    rememberMe: {
      get() {
        return this.$store.state.auth.rememberMe;
      },
      set(value) {
        this.doRememberMe(
          {
            value,
            email: this.form.email
          },
          { root: true }
        );
      }
    }
  },
  mounted() {
    this.form.email = this.$user.email;
  },
  methods: {
    ...mapActions({
      doLogin: "auth/login",
      doLogout: "auth/logout",
      doRememberMe: "auth/rememberMe"
    }),
    login() {
      if(!this.isFormReady) {
        return false;
      }
      const { username, password = this.form.password } = this;
      this.isLoading = true;
      this.error = "";
      this.buttonText = "Logging you in...";
      this.doLogout().then(() => {
        this.doLogin({
          username: username.toLowerCase().trim(),
          password,
          rememberMe: this.rememberMe
        })
          .then(result => {
            this.isLoading = false;
            this.submitClass = "wethrive-alt smaller--text";
            this.buttonText = "Logged in!";
            this.$log.debug(
              "LoginModalForm.login: Logged in...waiting for user"
            );

            /**
             * Get the user and log them in
             * TODO: Need to add custom logic here to determine if the user is properly setup
             * Possibly need a flag on the user account or a check on the org info
             */
            // Need to set the current team for the user using API
            // this.$team = this.currentTeam ? this.currentTeam : this.$team;
            // const team = this.$team ? this.$team.slug : this.teamSlug;
            const ready = function() {
              this.$log.debug(
                "LoginModalForm.login: User is available",
                this.$team
              );
              this.isLoading = false;
              this.submitClass = "wethrive";
              this.buttonText = "Login";
              this.$emit("loggedIn");
            };

            setTimeout(() => {
              if (!this.$auth.ready()) {
                this.$auth.ready(ready);
              } else {
                ready.call(this);
              }
            }, 150);
          })
          .catch(error => {
            const response = error ? error.response : null;
            this.$log.debug("Error information", response, error);
            this.error = response
              ? error.response.data.message
              : "Error logging you in";
            this.isLoading = false;
            this.buttonText = "Login";
            this.doLogout();
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.login-form {
  margin: auto;

  #login-form-header h3 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  #circle {
    display: inline-block;
    background-color: #9bf2dc;
    border-radius: 50%;
    line-height: 5rem;
    height: 5rem;
    width: 5rem;
    text-align: center;
  }

  .form {
    background-color: $white-color;
    padding: 1rem;

    #form-header {
      border-bottom: 1px solid $border-color;
      margin: 0 -1rem 1rem -1rem;

      h3 {
        color: $content-title-color;
        font-size: 13px;
        font-weight: 900;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    label {
      font-size: 0.8rem;
    }

    span input {
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: none;
      border-bottom: 1px solid $border-color;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    button {
      border: 1px;
      box-shadow: none;
      margin-left: auto;
      margin-right: auto;
    }

    button.wethrive {
      border: 1px;
      box-shadow: none;
      width: 70%;
      margin-left: 15%;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      color: white;
    }

    #amnesia {
      text-align: right;
      font-size: 0.8rem;

      a {
        color: $font-light-color;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  #login-form-footer {
    margin-top: 20px;
    height: 72px;
    font-size: 0.8rem;

    #login a {
      color: $font-light-color;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }

    #badge {
      text-align: right;

      span {
        height: 32px;
        display: inline-block;
        line-height: 26px;
      }
    }
  }
}
</style>