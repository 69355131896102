<template>
  <Badge
    v-bind="{...$props, ...$attrs}"
    :class="{
      'beta-chip': true,
      'beta-chip-icon': asIcon
    }"
    :color="isBeta && !asIcon ? color : 'transparent'"
  >
    <template
      #badge
      v-if="isBeta"
    >
      <VTooltip
        v-if="enableTooltip"
        top
      >
        <template #activator="{on}">
          <VIcon
            v-if="asIcon"
            v-on="on"
            size="22"
            :color="color"
          >
            {{ icon }}
          </VIcon>
          <span
            v-on="on"
            v-else
            v-bind="labelOptions"
          >
            {{ label }}
          </span>
        </template>
        <span>
          <strong>NOTE</strong>: This is a beta feature. If you experience issues please contact support using the help icon above.
        </span>
      </VTooltip>
      <template v-else>
        <VIcon
          v-if="asIcon"
          size="22"
          :color="color"
        >
          {{ icon }}
        </VIcon>
        <template
          v-else
          v-bind="labelOptions"
        >
          {{ label }}
        </template>
      </template>
    </template>
    <slot />
  </Badge>
</template>

<script>
export default {
  name: "BetaBadge",
  props: {
    icon: {
      type: String,
      default: "new_releases",
    },
    label: {
      type: String,
      default: "new",
    },
    color: {
      type: String,
      default: "secondary",
    },
    textColor: {
      type: String,
      default: "white",
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    asIcon: {
      type: Boolean,
      default: false,
    },
    overlap: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    labelOptions: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
.beta-chip.v-badge {
  padding: 2px !important;
  &.beta-chip-icon {
    .v-badge__badge {
      font-size: 10px;
      line-height: 20px;
      border-radius: 10px;
    }
  }
  .v-badge__badge {
    padding: 0 4px;
    text-transform: lowercase;
    font-size: 10px;
    line-height: 20px;
    border-radius: 6px;
  }
}
</style>