<template>
  <VCard
    :class="{
      'members-search-container': true,
      group: isGroup,
      'px-0 fill-height column d-flex': true
    }"
    :style="{backgroundColor: backgroundColor}"
  >
    <VCardTitle
      class="py-0 px-0 d-flex mb-0 wrap sticky sticky-top"
      :style="{
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        flexBasis: 'auto',
        backgroundColor: headerBackgroundColor,
        height: 'min-content !important'
      }"
    >
      <VCol
        grow
        wrap
        cols="12"
        class="pt-1"
      >
        <TextField
          v-model="filter.s"
          clearable
          outlined
          persistent-hint
          placeholder="Type the name of a person ..."
          class="search-individuals v-input-sm no-details smaller--text borderless"
          hint="Press ENTER to search"
          prefix="Search:"
          :loading="activity.isLoading"
          @keyup.enter="onSearch"
        >
          <img
            slot="append"
            style="cursor: pointer; z-index: 1; margin-top: 6px"
            width="13"
            height="13"
            src="@/images/search-icon@3x.png"
            @click="onSearch"
          >
          <template slot="append-outer">
            <Tooltip
              v-if="hasFilterOptions && !hideFilters"
              small
              small-icon
              text-button
              class="mt-0"
              icon="filter_list"
              title="Filter users"
              :disabled="activity.isLoading"
              :color="hasFilter ? 'info' : 'default'"
              :action="() => filterDrawer = !filterDrawer"
            />
            <!-- <Tooltip
              small
              small-icon
              text-button
              class="mt-0"
              icon="view_list"
              :title="`Switch to ${useChipsLocal ? 'list' : 'chips'} view for selected users`"
              :disabled="activity.isLoading"
              :color="useChipsLocal ? 'primary' : 'default'"
              :action="() => useChipsLocal = !useChipsLocal"
            /> -->
          </template>
        </TextField>

        <!-- <VRow
          v-if="hasExistingUsers && useChips || useChipsLocal"
          class="px-4"
        >
          <VCol
            v-for="(user, index) in existing"
            shrink
            :key="`user-${index}`"
          >
            <VChip
              small
              close
              class="mr-1 mb-1"
              :style="{
                textDecoration: user.deleted ? 'line-through' : 'none',
                color: user.deleted ? 'red' : 'inherit'
              }"
              style="padding-left: 6px; padding-right: 6px"
              @click:close="onToggleMember(user)"
            >
              <VAvatar left>
                <VImg :src="user.photo_url" />
              </VAvatar>
              <small>{{ user.name }}</small>
            </VChip>
          </VCol>
        </VRow> -->
      </VCol>
      <VCol
        v-if="!hideFilters"
        cols="12"
        class="py-0"
      >
        <CurrentFilter
          :filter="filter"
          :filter-values="filterValues"
          :is-loading="activity.isLoading"
          @removeFilter="onRemoveFilter"
          @removeStrictMode="onRemoveStrictMode"
          @resetFilter="onResetLocalFilter"
        />
      </VCol>
    </VCardTitle>
    <VCardText
      class="row pa-0 ma-0 full-width"
      :style="{
        flex: '1 1 auto',
        overflowY: 'hidden',
        overflowX: 'hidden',
        backgroundColor: resultsBackgroundColor
      }"
    >
      <VSkeletonLoader
        class="fluid"
        type="list-item-avatar-two-line"
        :loading="!hasFilteredUsers && activity.isLoading"
      >
        <VList
          class="elevation-0 fluid rounded-0"
          id="infinite-users"
          :style="{
            backgroundColor: listBackgroundColor,
            overflowY: 'auto',
          }"
        >
          <template v-if="hasFilteredUsers">
            <template v-for="(user, index) in data.data">
              <VListItem
                :key="index"
                @click="() => enableCheckboxes ? false : onToggleMember(user)"
              >
                <VBadge
                  avatar
                  bordered
                  overlap
                  bottom
                  left
                  offset-x="30"
                  offset-y="36"
                  :icon="user.deleted ? 'close' : 'check'"
                  :color="user.deleted ? 'error' : 'success'"
                  :value="memberIds.indexOf(user.id) !== -1"
                >
                  <VListItemAvatar size="50">
                    <img
                      style="max-width: 50px; max-height: 50px"
                      :src="user.photo_url"
                    >
                  </VListItemAvatar>
                </VBadge>
                <VListItemContent>
                  <VListItemTitle
                    :style="{
                      textDecoration: user.deleted ? 'line-through' : 'none',
                      color: user.deleted ? 'red' : 'inherit'
                    }"
                  >
                    {{ user.name }}
                  </VListItemTitle>
                  <VListItemSubtitle class="py-1">
                    <VChip
                      small
                      :class="{
                        'text-truncate': true,
                        'role--student': getRole(user) === 'student',
                        'role--mentor': getRole(user) === 'mentor',
                        'role--organization-admin': getRole(user) === 'organization-admin',
                        'role--missing': getRole(user) === 'missing' || getRole(user) === 'member'
                      }"
                    >
                      {{ user.role_name }}
                    </VChip>
                  </VListItemSubtitle>
                </VListItemContent>
                <VListItemAction v-if="enableCheckboxes">
                  <VCheckbox
                    v-model="filteredMemberIds"
                    color="primary"
                    style="height: auto"
                    :multiple="isGroup"
                    :value="user.id"
                    :disabled="isSaving"
                    @change="(value) => onHandleMemberChoice(user, value)"
                  />
                </VListItemAction>
              </VListItem>
            </template>
            <infinite-loading
              v-if="isUserSearched && canGetMoreFilteredData"
              force-use-infinite-wrapper="#infinite-users"
              :distance="25"
              :identifier="new Date()"
              @infinite="onTryGetMore"
            />
          </template>
          <VListItem v-else-if="!activity.isLoading">
            {{ isUserSearched ? 'No users found' : 'Search for users above' }}
          </VListItem>
        </VList>
      </VSkeletonLoader>
      <VNavigationDrawer
        v-model="filterDrawer"
        absolute
        temporary
        right
        :style="{
          overflowY: 'auto',
          overflowX: 'auto',
          flex: $vuetify.breakpoint.smAndDown ? (filterDrawer ? '1 1 100%' : '0 0 0%') : (filterDrawer ? '0 0 400px' : '0 0 0%'),
          width: $vuetify.breakpoint.smAndDown ? (filterDrawer ? '100%' : '0px') : (filterDrawer ? '400px' : '0px')
        }"
      >
        <FilterOptions
          v-model="filter"
          elevated
          no-border
          :fixed="false"
          :filters="allFilters"
          @filter="onSearch"
          @toggle="filterDrawer = !filterDrawer"
        />
      </VNavigationDrawer>
    </VCardText>
    <VCardActions
      class="justify-center sticky sticky-bottom px-5"
      :style="{
        backgroundColor: footerBackgroundColor
      }"
    >
      <VRow
        v-if="isUserSearched && !isFirstLoad"
        class="sticky sticky-bottom justify-space-between py-2"
      >
        <VBtn
          :loading="isSaving"
          @click="onResetLocalFilter"
        >
          Cancel
        </VBtn>
        <VBtn
          color="info"
          @click="onAddMembersFromSearch"
        >
          {{ isGroup ? 'Done' : saveText }}
        </VBtn>
      </VRow>
      <template v-else-if="!isUserSearched && !activity.isLoading">
        <VBtn
          color="success"
          :loading="isSaving"
          :disabled="!hasUsers"
          @click="onSaveMembers"
        >
          {{ isSaving ? savingText : saveText }}
        </VBtn>
      </template>
    </VCardActions>
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import FilterOptions from "@/components/Elements/Data/FilterOptions";
import CurrentFilter from "@/components/Elements/Data/CurrentFilter";
import CanFilter from "@/mixins/CanFilter";
import RoleChip from "@/components/Elements/RoleChip";

export default {
  name: "MembersForm",
  mixins: [SearchMixin, CanFilter],
  components: { CurrentFilter, FilterOptions },
  props: {
    doInitialSearch: {
      type: Boolean,
      default: true,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    enableCheckboxes: {
      type: Boolean,
      default: false,
    },
    excludeExisting: {
      type: Boolean,
      default: false,
    },
    excludeMe: {
      type: Boolean,
      default: true,
    },
    autosave: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "single",
    },
    useChips: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "any",
    },
    existing: {
      type: Array,
      default: () => [],
    },
    parentIsSaving: {
      type: Boolean,
      default: false,
    },
    savingText: {
      type: String,
      default: "Saving...",
    },
    saveText: {
      type: String,
      default: "Save",
    },
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    headerBackgroundColor: {
      type: String,
      default: "#fff",
    },
    resultsBackgroundColor: {
      type: String,
      default: "#fff",
    },
    listBackgroundColor: {
      type: String,
      default: "#fff",
    },
    footerBackgroundColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      users: {
        data: [],
      },
      filter: {
        s: "",
      },
      localSearchData: {
        data: [],
      },
      modelType: "user",
      allDataProp: "users",
      useChipsLocal: true,
      memberIds: [],
      memberId: null,
      filteredMemberIds: [],
      isSaving: false,
      isUserSearched: false,
      isRemoveOrAddAction: false,
      getAllOnLoad: false,
    };
  },
  watch: {
    parentIsSaving: function (value) {
      this.isSaving = value;
    },
    existing: function (value) {
      this.initMembers(value);
    },
    // isSearching: function (value) {
    //   if (!value && this.filterValues.length === 0) {
    //     this.isUserSearched = value;
    //   }
    // },
  },
  computed: {
    isSearching() {
      return this.filter.s && this.filter.s.length > 3;
    },
    hasUsers() {
      return (
        (this.memberIds || []).length > 0 ||
        (this.localSearchData &&
          this.localSearchData.data &&
          this.localSearchData.data.length > 0)
      );
    },
    hasExistingUsers() {
      return this.existing instanceof Array && this.existing.length > 0;
    },
    hasFilteredUsers() {
      return (
        this.localSearchData &&
        this.localSearchData.data &&
        this.localSearchData.data.length > 0
      );
    },
    canGetMoreFilteredData() {
      return !this.isFirstLoad && this.pagination.current < this.data.last_page;
    },
  },
  created() {
    // if (this.excludeExisting) {
    //   this.filter.usersNotIn = this.memberIds;
    // }
    this.initMembers(this.existing);
    // this.getAllOnLoad = this.isUserSearched = this.doInitialSearch;
    // Avoided dual api calls i.e. from search mixin and from here
    this.isUserSearched = this.doInitialSearch;
    if (this.doInitialSearch) {
      // Next tick added to load the custom filters properly before search
      this.$nextTick(() => {
        this.$log.info("MembersForm doing initial search");
        this.onDoInitialSearch();
      });
    }
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "users/getIndexConfig",
      doGetUsers: "users/getAll",
    }),
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    getRole(user) {
      if (user.role instanceof Object) {
        return user.role.id;
      }
      if (user.team_user instanceof Object) {
        return user.team_user.role;
      }
      return "missing";
    },
    onGetAll(params) {
      this.$log.debug("MembersForm: Getting users");
      // Reset the filtered member ids
      // this.filteredMemberIds = this.memberIds;
      this.filteredMemberIds = this.memberIds;
      this.localSearchData.data = this.dataHasBeenPaginated
        ? this.localSearchData.data
        : [];
      return this.doGetUsers({
        ...(params || {}),
        ...{
          s: this.filter.s,
          filter: this.filter,
          type: this.type,
        },
      }).then((users) => {
        this.$log.debug("MembersForm: Got users", users);
        // Disable filtering for the current user
        if (this.excludeMe) {
          users.data = users.data.filter((user) => user.id !== this.$user.id);
        }
        users.data = users.data.sortBy(
          (u) => this.memberIds.indexOf(u.id) !== -1,
          true
        );
        this.localSearchData = this.onAfterGetMore(users);
        this.isFirstLoad = false;
        return users;
      });
    },
    onTryGetMore(params) {
      if (!this.isRemoveOrAddAction) {
        return this.onGetMore(params);
      }
    },
    onAfterGetMore(result) {
      result.data = this.localSearchData.data
        .concat(result.data)
        .filter((u) => u instanceof Object)
        .unique((user) => user.id)
        .sortBy((u) => this.memberIds.indexOf(u.id) !== -1, true);
      return result;
    },
    onDoInitialSearch() {
      this.$log.info("Doing initial search");
      this.doSearch(this.onGetAll).then((result) => {
        if (result) {
          result.data = [...this.existing, ...result.data]
            .filter((u) => u instanceof Object)
            .unique((user) => user.id)
            .sortBy((u) => this.memberIds.indexOf(u.id) !== -1, true);
          this.localSearchData = result;
        }
        this.isUserSearched = true;
        this.isFirstLoad = false;
      });
    },
    onSearch() {
      if (
        this.filter.s &&
        this.filter.s.length >= 3 &&
        !this.activity.isLoading
      ) {
        this.$log.debug("MembersForm doing user search");
        return this.doSearch(this.onGetAll).then((result) => {
          this.isUserSearched = true;
          return result;
        });
      }
      if (
        !this.filter.s ||
        (this.filter.s.length === 0 && this.doInitialSearch)
      ) {
        this.$log.debug("MembersForm doing initial search");
        return this.onDoInitialSearch();
      }
    },
    onAddMember(member) {
      // this.localSearchData.data.push(member);
      // this.filteredMemberIds.push(member.id);
      this.isRemoveOrAddAction = true;
      this.$emit("addMember", member);
    },
    onRemoveMember(member) {
      // this.localSearchData.data = this.localSearchData.data.filter(user => user.id !== member.id);
      // this.filteredMemberIds = this.filteredMemberIds.filter(id => id !== member.id);
      this.isRemoveOrAddAction = true;
      this.$emit("removeMember", member);
    },
    onToggleMember(member, value) {
      let event = undefined;
      this.isRemoveOrAddAction = true;
      if (this.mode === "single") {
        event = "toggleMember";
      } else {
        // Need to invert logic here since the local members would change before we get here
        if (this.memberIds.indexOf(member.id) === -1) {
          event = "addMember";
          // this.memberIds = this.memberIds.filter(id => id !== member.id);
        } else {
          event = "removeMember";
          // this.memberIds.push(member.id);
        }
      }
      this.$log.debug("Toggling member", member, event);
      this.$emit(event, member);
      if (this.autosave) {
        this.onSaveMembers();
      }
    },
    onSaveMembers() {
      const memberIds = this.isGroup ? this.memberIds : [this.memberId];
      this.$emit("saveMembers", memberIds);
    },
    syncFilteredMembers() {
      this.memberIds = this.memberIds.concat(this.filteredMemberIds).unique();
      this.$log.debug(
        "User ids before",
        this.localSearchData.data.map((u) => u.id)
      );
      this.localSearchData.data = this.localSearchData.data
        .filter((member) => this.memberIds.indexOf(member.id) !== -1)
        .concat(this.existing)
        .unique((user) => user.id);
      this.$log.debug(
        "User ids after",
        this.localSearchData.data.map((u) => u.id)
      );
    },
    refreshMembers() {
      this.filter.s = "";
      this.filteredMemberIds = [];
      this.isUserSearched = false;
      this.onDoInitialSearch();
    },
    onResetLocalFilter() {
      // this.onResetFilter();
      this.localSearchData.data = this.existing;
      this.isUserSearched = false;
    },
    onAddMembersFromSearch() {
      this.syncFilteredMembers();
      this.onResetLocalFilter();
    },
    findMember(id) {
      this.localSearchData.data.find((user) => (user.id = id));
    },
    onHandleMemberChoice(user, value) {
      this.$log.debug("Handling member", user, value);
      if (
        value === true ||
        (value instanceof Array &&
          value.indexOf(user instanceof Object ? user.id : user) > -1)
      ) {
        if (this.isGroup) {
          this.onAddMember(user);
        } else {
          this.onToggleMember(user);
        }
      } else {
        if (this.isGroup) {
          this.onRemoveMember(user);
        } else {
          this.onToggleMember(user);
        }
      }
      if (this.autosave) {
        this.onSaveMembers();
      }
    },
    initMembers(value) {
      if (value instanceof Array) {
        if (!this.isRemoveOrAddAction) {
          this.localSearchData.data = value;
        } else {
          this.isRemoveOrAddAction = false;
        }
        if (this.isGroup) {
          this.memberIds = value.map((member) =>
            parseInt(member instanceof Object ? member.id : member)
          );
        } else {
          this.memberId = value[0];
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
</style>
