<template>
  <Dialog
    max-width="640px"
    :is-dialog-open="true"
    :title="title"
    @toggle:dialog="$emit('toggle:dialog')"
  >
    <VList
      two-line
      v-if="hasItems"
    >
      <VListItem
        v-for="(item, key) in items"
        :key="key"
      >
        <slot
          name="item"
          :item="item"
        />
        <VListItemAction>
          <VBtn
            :icon="$vuetify.breakpoint.smAndDown"
            text
            @click="onToggleSubmitDialog(item)"
          >
            <VIcon>assignment</VIcon>
            <span v-if="$vuetify.breakpoint.mdAndUp">Submit</span>
          </VBtn>
        </VListItemAction>
      </VListItem>
    </VList>
    <VRow
      v-else-if="!loaded"
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
    <VAlert
      v-else
      :value="true"
      type="success"
    >
      Nothing due!
    </VAlert>
    <Dialog
      max-width="1024px"
      :is-dialog-open="submittedDialogs.submit"
      :title="`Submit ${type.titleize().singularize()} ${submittedModel instanceof Object ? ': '+submittedModel.title : ''}`"
      :hide-overlay="true"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @toggle:dialog="onToggleSubmitDialog"
    >
      <slot
        name="submit"
        :item="submittedModel"
        :uid="uid"
      />
    </Dialog>
  </Dialog>
</template>
<script>
import HasSubmittedData from "@/mixins/HasSubmittedData";
export default {
  mixins: [HasSubmittedData],
  props: {
    items: {
      type: Array,
      default: () => null
    },
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "deliverables"
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  watch: {
    items: function(value) {
      if (value !== null) {
        this.loaded = true;
      }
    }
  },
  computed: {
    hasItems() {
      return this.items instanceof Array && this.items.length > 0;
    }
  },
  created() {
    this.$bus.$on("toggle:dialog", data => {
      if (this.uid === data.uid) {
        this.$emit("toggle:dialog");
      }
    });
    // this.toggleIntercom();
  }
};
</script>