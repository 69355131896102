var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"fill-height":"","align-start":"","space-around":"","row":"","wrap":"","justify-start":_vm.card.name || _vm.description || _vm.card.layout == 'Left',"justify-end":!_vm.card.name && !_vm.description && _vm.card.layout == 'Right'}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('VSpacer'):_vm._e(),(_vm.card.name || _vm.description)?_c('VCol',{class:{
      'd-flex align-center': true,
      'order-first': _vm.card.images.length === 0 && _vm.card.layout == 'Right',
      'order-last': _vm.card.images.length === 0 && _vm.card.layout == 'Left',
    },style:({
      flex: _vm.$vuetify.breakpoint.smAndDown ? '0 1 100%' : '1 1 calc(100% - 580px)',
      paddingLeft: '28px',
      marginTop: '50px',
      marginBottom: '50px',
    }),attrs:{"cols":"12","order-sm":"3"}},[_c('VRow',{style:({
        maxHeight: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : 'calc(443px - 104px)',
        overflow: 'hidden auto',
      }),attrs:{"space-around":""}},[_c('VCol',[_c('CardTitle',{staticClass:"card-title",style:({
            fontSize: `${'24px'}!important`,
          }),attrs:{"align":!_vm.description || _vm.$vuetify.breakpoint.smAndDown ? 'center' : 'left'}},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],class:{
            formatted: true,
          },style:({
            lineHeight: 'normal',
            fontSize: '16px',
            opacity: 0.75,
            fontWeight: 300,
            textAlign: 'left'
          }),domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()],1)],1)],1):_vm._e(),_c('VCol',{class:{
      'layout row justify-center align-center mb-0 mt-0': true,
      'order-first': _vm.card.images.length === 0 && _vm.card.layout == 'Left',
      'order-last': _vm.card.images.length === 0 && _vm.card.layout == 'Right',
    },style:({
      flex: _vm.$vuetify.breakpoint.smAndDown ? '0 1 100%' : '0 1 580px',
    }),attrs:{"order-sm":"2","fill-height":_vm.$vuetify.breakpoint.mdAndUp,"xs":_vm.description !== null && !_vm.$vuetify.breakpoint.smAndDown ? 10 : 12}},[_c('VCol',{staticClass:"responsive-video",style:({
        width: '100%',
        maxWidth: _vm.$vuetify.breakpoint.mdAndUp ? '1920px' : '100%',
        maxHeight: !!_vm.description && !_vm.card.name || _vm.$vuetify.breakpoint.mdAndUp ? '100%' : (_vm.$vuetify.breakpoint.smAndDown ? '500px' : '375px')
      })},[(_vm.getIsSelfHostedMedia(_vm.card.video || _vm.card.value))?[_c('video',{ref:"video",staticStyle:{"position":"relative","display":"block","margin-left":"auto","margin-right":"auto","width":"inherit","height":"auto","min-height":"210px","border-radius":"4px"},attrs:{"autoplay":false,"controls":true},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.onCancelContextMenu($event)},"contextmenu":function($event){return _vm.onCancelContextMenu($event)}}},[_c('source',{attrs:{"id":_vm.mediaId,"type":_vm.mediaType,"src":_vm.url},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.onCancelContextMenu($event)},"contextmenu":function($event){return _vm.onCancelContextMenu($event)}}})])]:_c('iframe',{ref:"iframe",style:(_vm.style),attrs:{"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","src":_vm.url}})],2)],1),(_vm.description && _vm.$vuetify.breakpoint.smAndDown)?_c('VCol',{attrs:{"order-sm":"3","order-md":"3"}},[_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],class:{
        formatted: true,
        'text-center': _vm.$vuetify.breakpoint.smAndDown
      },domProps:{"innerHTML":_vm._s(_vm.description)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }