<template>
  <Dialog
    fill-height
    card-text-class="py-2"
    :dismissable="false"
    :max-width="maxWidth"
    :min-height="minHeight"
    :is-dialog-open="isDialogOpen"
    :hide-overlay="dialogHideOverlay"
    :style="{
      zIndex: 100000000,
      minHeight: minHeight
    }"
    @toggle:dialog="$emit('toggle:dialog')"
  >
    <Messages
      v-if="mode == 'replying'"
      dismissable
      :for-huddle="forHuddle"
      :old-thread="oldThread"
      :thread="thread"
      :pusher="pusher"
      :custom-filter="customFilter"
      :hide-filters="hideFilters"
      :thread-editable="threadEditable"
      @updateThread="onUpdateThread"
      @toggle:dialog="$emit('toggle:dialog')"
    />
    <StartChat
      v-else
      force-group
      :for-huddle="forHuddle"
      :dismissible="hasThread"
      :parent-is-saving="isSaving"
      :custom-filter="customFilter"
      :hide-filters="hideFilters"
      :members="members"
      @toggle="onToggleMode"
      @startThread="onNewThread"
      @toggleDrawer="onToggleDrawer"
      @closeEditor="$emit('toggle:dialog')"
    />
  </Dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CanFilter from "@/mixins/CanFilter";
import StartChat from "./StartChat";
import Messages from "./Messages";
export default {
  props: {
    user: {
      type: Object,
      default: () => null
    },
    members: {
      type: Array,
      default: () => []
    },
    entity: {
      type: Object,
      default: () => {}
    },
    entityType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: "640px"
    },
    minHeight: {
      type: String,
      default: "640px"
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    dialogHideOverlay: {
      type: Boolean,
      default: false
    },
    enableEdit: {
      type: Boolean,
      default: true
    },
    threadEditable: {
      type: Boolean,
      default: true,
    },
    forHuddle: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CanFilter],
  components: { 
    StartChat,
    Messages,
  },
  data() {
    return {
      drawer: false,
      isSaving: false,
      showThreads: false,
      oldThread: null,
      mode: "creating",
    }
  },
  computed: {
    ...mapGetters({
      thread: "chat/currentThread",
    }),
    pusher: function () {
      return this.$echo;
    },
    hasThread() {
      return this.thread instanceof Object;
    },
  },
  created() {
    this.doSetHuddleMode(this.forHuddle);
  },
  methods: {
    ...mapActions({
      doSetThread: "chat/setThread",
      doSetHuddleMode: "chat/setHuddleMode",
      doUpdateThread: "chat/updateThread",
      doSaveThread: "chat/saveThread",
      doDeleteThread: "chat/deleteThread",
    }),
    onToggleMode(_modeType) {
      if(_modeType) {
        this.mode = _modeType;
      } else {
        this.mode = this.mode === "creating" ? "replying" : "creating";
      }
    },
    onDeleteThread(thread) {
      this.$log.debug("Chat: Deleting thread", thread);
      return this.doDeleteThread(thread)
        .then((result) => {
          this.$log.debug("Chat: Deleted thread", thread);
          return result;
        })
        .catch((error) => {
          this.$log.debug("Chat: Error Deleting thread", error);
        });
    },
    onNewThread(thread) {
      const prepareThread = {
        entity_type: this.entityType,
        entity_id: this.entity.id,
        ...thread
      };
      this.$log.debug("Chat: Creating thread", prepareThread);
      this.doSaveThread(prepareThread)
        .then((thread) => {
          if (thread.status != "error") {
            this.$log.debug("Chat: Created thread", thread);
            this.onSetCurrentThread(thread);
            this.mode = "replying";
            this.$emit("newThread", thread);
          } else {
            this.$log.debug("Chat: Create thread error", thread);
            this.errors = thread;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    onSetCurrentThread(thread) {
      this.mode = "replying";
      if (!this.thread || (this.thread && thread.id != this.thread.id)) {
        this.$log.debug(
          "[Chat: Index]: Setting current thread...[" + thread.title + "]"
        );
        this.oldThread = { ...thread };
        this.doSetThread(thread).then((thread) => {
          window.localStorage.setItem("lastThread", JSON.stringify(thread));
          this.$emit("threadChanged");
        });
      } else {
        this.$log.debug(
          "[Chat: Index]: Not setting current thread...[" + thread.title + "]"
        );
      }
    },
    onToggleDrawer() {
      this.drawer = !this.drawer;
    },
    onUpdateThread(thread) {
      this.doUpdateThread(thread);
      this.$emit("updateThread", thread);
    },
  }
};
</script>