<template>
  <VRow
    fill-height
    align-center
    row
    wrap
  >
    <VCol
      cols="12"
      class="card-paragraph"
      :style="{
        marginBottom: '6px',
        paddingLeft: '16px'
      }"
    >
      <CardTitle
        :has-images="hasImages"
        align="left"
      >
        {{ card.name }}
      </CardTitle>
      <p
        class="subheading formatted"
        style="overflow-y: auto"
        v-html="card.value"
        v-linkified
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import PresentationCardMixin from "@/mixins/PresentationCard";

export default {
  name: "PresentationCardText",
  mixins: [PresentationCardMixin],
  props: {
    card: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped>
.card-paragraph {
  font-size: 16px;
  text-align: left;
  opacity: 0.75;
  line-height: normal;
  color: #000000;
}
</style>
