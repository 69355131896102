<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h1>Automation</h1>
        </template>
        <template #create-action>
          <ExpandableButton
            v-if="$isUserAdmin"
            large
            :action="() => onSetFormDialogModel({
              title: 'Create Automation'
            })"
            text="Add New"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol>
      <template v-if="automations && automations.data">
        <VList
          two-line
          v-if="automations.data.length"
        >
          <VListItem
            v-for="(automation, key) in automations.data"
            :key="key"
          >
            <VListItemAvatar>
              <VCheckbox
                style="height: inherit; min-height: inherit"
                v-model="automation.is_active"
                color="success"
                @change="toggleAutomation(automation)"
              />
            </VListItemAvatar>
            <VListItemContent>
              <VListItemTitle>{{ automation.title }}</VListItemTitle>
              <VListItemSubtitle>
                <template v-if="automation.description">
                  {{ automation.description }}
                </template>
                {{ getAutomationInterval(automation) }}
                <small>
                  <br>When
                  <strong>{{ Object.get(automation, 'trigger.event.description') }}</strong>
                  we should
                  <strong>{{ Object.get(automation, 'trigger.action.event.description') }}</strong>
                  <template v-if="Object.has(automation, 'trigger.notification.event.description')">
                    and finally
                    <strong>{{ Object.get(automation, 'trigger.notification.event.description') }}</strong>
                  </template>
                </small>
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <Menu :disabled="getIsDisabled(props.item)">
                <VListItem @click="setFormDialogModel(automation)">
                  <VListItemTitle>
                    <VIcon small>
                      edit
                    </VIcon>&nbsp;Edit
                  </VListItemTitle>
                </VListItem>
                <VListItem @click="onSetViewDialogModel(automation)">
                  <VListItemTitle>
                    <VIcon small>
                      visibility
                    </VIcon>&nbsp;View
                  </VListItemTitle>
                </VListItem>
                <VListItem @click="onDeleteItem(automation)">
                  <VListItemTitle color="error">
                    <VIcon
                      small
                      color="error"
                    >
                      delete
                    </VIcon>&nbsp;Delete
                  </VListItemTitle>
                </VListItem>
              </Menu>
            </VListItemAction>
          </VListItem>
        </VList>
        <VAlert
          v-else
          type="info"
          :value="true"
        >
          No automations found.
        </VAlert>
        <Confirm ref="confirmDelete" />
        <FormDialog
          v-if="currentModel"
          :current-model="currentModel"
          :is-dialog-open="dialogs.form"
          :title="currentModel ? currentModel.title : 'Create Automation'"
          @toggle:form:dialog="onToggleFormDialog"
          @deleteItem="onDeleteItem"
          :model-type="modelType"
          :fullscreen="true"
        >
          <Save
            ref="saveForm"
            mode="dialog"
            :id="currentModel ? currentModel.id : null"
          />
        </FormDialog>
        <Dialog
          max-width="640px"
          :is-dialog-open="dialogs.view"
          :title="`View ${featureName('Automation')}`"
          @toggle:dialog="onToggleViewDialog"
        >
          <ViewComponent
            :id="currentModel ? currentModel.id : null"
            ref="viewItem"
            mode="dialog"
          />
        </Dialog>
      </template>
      <VRow
        v-else
        row
        fluid
        justify-center
        class="mt-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Save from "./Save";
import SearchMixin from "@/mixins/Search";
import HasStatsMixin from "@/mixins/HasStats";
import ViewComponent from "./View";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";

export default {
  name: "AutomationWorkflows",
  mixins: [SearchMixin, HasStatsMixin],
  components: {
    Save,
    ViewComponent,
    IndexHeader,
  },
  data() {
    return {
      modelType: "Automation",
    };
  },
  computed: {
    ...mapGetters({
      automations: "automation/data",
    }),
  },
  methods: {
    ...mapActions({
      doGetAutomations: "automation/getAll",
      doGetAutomation: "automation/getOne",
      doDeleteAutomation: "automation/delete",
      doToggleAutomation: "automation/toggle",
    }),
    onDelete(automation) {
      return this.doDeleteAutomation(automation.id);
    },
    onGetAll(params) {
      return this.doGetAutomations(params);
    },
    onGet(id) {
      return this.doGetAutomation(id);
    },
    toggleAutomation(automation) {
      this.doToggleAutomation(automation);
    },
    getAutomationInterval(automation) {},
  },
};
</script>