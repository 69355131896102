<template>
  <VRow
    row
    wrap
  >
    <VCol cols="12">
      <VCheckbox
        v-model="immediately"
        label="Immediately?"
        @change="setImmediate"
      />
    </VCol>
    <template v-if="!immediately">
      <VCol class="pb-0">
        <TextField
          v-model="form.unit"
          sm="4"
          label="Enter unit"
          type="number"
          append-inner-icon="arrow-down"
          placeholder="Unit ..."
          @input="emitInput"
          @keypress="numericOnly"
        />
      </VCol>
      <VCol class="pb-0">
        <Autocomplete
          v-model="form.interval"
          sm="4"
          label="Select Interval"
          :items="durations"
          item-text="title"
          item-value="value"
          append-inner-icon="arrow-down"
          placeholder="Select interval ..."
          @change="emitInput"
        />
      </VCol>
    </template>
  </VRow>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    durations: {
      type: Array,
      default: () => [
        {
          title: "Hour",
          value: "hour"
        },
        {
          title: "Day",
          value: "day"
        },
        {
          title: "Week",
          value: "week"
        },
        {
          title: "Month",
          value: "month"
        },
        {
          title: "Year",
          value: "year"
        }
      ]
    }
  },
  data() {
    return {
      immediately: false,
      form: {},
      defaultForm: {
        unit: 1,
        interval: "hour"
      },
      previousForm: {}
    };
  },
  created() {
    this.form = this.cloneObject({ ...this.defaultForm, ...this.value });
  },
  methods: {
    emitInput() {
      this.$emit("input", this.form);
    },
    setImmediate(value) {
      if (value) {
        this.previousForm = this.form;
        this.form = { unit: 0, interval: "immediate" };
      } else {
        this.form = this.previousForm;
        this.previousForm = {};
      }
    }
  }
};
</script>