var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fill-height":"","fluid":"","type":"table-heading,card"}},[_c('VCard',{staticClass:"fill-height",attrs:{"id":"calendar-add-type-select"}},[_c('VCardText',{style:({
        height: _vm.$vuetify.breakpoint.mdAndUp ? 'auto' : 'calc(100vh - 162px)'
      })},[_c('VContainer',{attrs:{"grid-list-lg":"","fill-height":""}},[_c('VRow',{attrs:{"wrap":_vm.$vuetify.breakpoint.mdAndUp,"justify-center":"","align-center":"","fill-height":""}},[_c('VCol',{staticClass:"text-sm-center",style:({
              flex: 1,
              width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : 'auto'
            }),attrs:{"cols":"12","md":"6"}},[_c('VBtn',{attrs:{"depressed":"","block":""},on:{"click":_vm.onToggleGoogleDialog}},[_vm._v(" Google Calendar ")])],1),_c('VCol',{staticClass:"text-sm-center",style:({
              flex: 1,
              width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : 'auto'
            }),attrs:{"cols":"12","md":"6"}},[_c('VBtn',{attrs:{"depressed":"","block":""},on:{"click":_vm.onToggleFormDialog}},[_vm._v(" Local Calendar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }