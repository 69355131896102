<template>
  <div
    :class="{
      chips: true,
      'fill-height': fillHeight
    }"
  >
    <template v-if="items instanceof Array && items.length">
      <h3
        v-if="title.length"
        class="headline mb-2"
      >
        {{ title }}
      </h3>
      <template v-if="asButton">
        <VBtn
          v-for="(item, key) in items"
          :key="key"
          :outlined="outlined"
          :color="color"
          :text-color="textColor"
          :small="small"
          :selected="selected"
          :dark="dark"
          :light="light"
          :disabled="disabled"
          :rounded="round"
          :block="block"
          :text="flat || textButton"
          :class="{
            'text-truncate': true,
            'text-no-wrap': true,
            'text--left': textLeft,
            'text--right': textRight,
            'fill-height': fillHeight,
            'mt-0': true,
            'mb-1': true,
            'ml-0': true,
            'text--no-transform': true
          }"
          :href="urlResolver(item)"
          @click.prevent="e => clicked(item, e)"
        >
          <VIcon
            :small="small"
            :left="true"
            v-if="icon && iconLeft"
          >
            {{ icon }}
          </VIcon>
          {{ item[itemTitle] || item }}&nbsp;
          <VIcon
            :small="small"
            :right="true"
            v-if="icon && iconRight"
          >
            {{ icon }}
          </VIcon>
        </VBtn>
      </template>
      <template v-else>
        <VChip
          v-for="(item, key) in items"
          :key="key"
          :outlined="outlined"
          :color="color"
          :text-color="textColor"
          :small="small"
          :input-value="selected"
          :dark="dark"
          :light="light"
          :disabled="disabled"
          :label="label"
          :class="{
            'text-truncate': true,
            'text-no-wrap': true,
            'text--left': textLeft,
            'text--right': textRight,
            'fill-height': fillHeight,
            'mt-0': true,
            'mb-1': true,
            'ml-0': true,
            'mr-1': true
          }"
          :href="urlResolver(item)"
          @click.prevent="e => clicked(item, e)"
        >
          {{ item instanceof Object ? item[itemTitle] : item }}
          <VIcon
            v-if="openInNewWindow"
            class="ml-1"
          >
            launch
          </VIcon>
        </VChip>
      </template>
    </template>
    <template v-else>
      <slot name="empty">
        {{ emptyText }}
      </slot>
    </template>
  </div>
</template>

<script>
export default {
  name: "Chips",
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    itemTitle: {
      type: String,
      default: "title",
    },
    emptyText: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: Boolean,
      default: false,
    },
    textLeft: {
      type: Boolean,
      default: false,
    },
    textRight: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    textColor: {
      type: String,
      default: "auto",
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    urlResolver: {
      type: Function,
      default: () => "#",
    },
    action: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    clicked: function (item, event) {
      this.action(item);
      event.preventDefault();
    },
  },
};
</script>