<script>
import ExtendFrom from "vuetify/lib/components/VBadge";
export default {
extends: ExtendFrom,
  props: {
    offsetY: {
        type: null,
        default: '50%'
    },
    color: {
        type: String,
        default: 'white'
    },
    overlap: {
      type: Boolean,
      default: true
    },
    top: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    }
  }
};
</script>