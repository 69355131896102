<template>
  <VContainer
    fluid
    grid-list-lg
    fill-height
  >
    <VRow
      v-if="modelHasId"
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{ small: true }"
              :url="
                getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                  pipeline: model.id,
                })
              "
              :to="
                getRouteForItem(`${modelTypeRoute}.submitted`, {
                  pipeline: model.id,
                })
              "
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <VCardTitle style="height: auto;">
                <h1 class="display-2 text-break">
                  {{ model.title }}
                </h1>
              </VCardTitle>
              <VCardText>
                <h3 v-if="model.city">
                  {{ model.city.name }}
                </h3>
                <VList dense>
                  <VListItem
                    v-for="(value, property) in details"
                    :key="property"
                  >
                    <VListItemContent>
                      {{ property }}
                    </VListItemContent>
                    <VListItemContent class="align-end">
                      <strong>{{ value }}</strong>
                    </VListItemContent>
                  </VListItem>
                </VList>
                <p
                  class="formatted"
                  v-html="model.content"
                  v-linkified
                />
                <FilesList
                  :readonly="true"
                  :files="model.files"
                />
              </VCardText>
            </VCard>
            <DynamicInputsView
              :inputs="model.metadata"
              :card="false"
              :readonly="true"
              :metadata-sections="model.metadata_sections"
            />
          </VCol>
          <VCol
            cols="12"
            class="pa-2"
          >
            <AssociatedContent :model="model" />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import AssociatedContent from "@/components/Elements/Data/AssociatedContent";
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "DeliverableView",
  components: { DynamicInputsView, AssociatedContent, ViewHeader },
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Deliverable",
    };
  },
  computed: {
    details() {
      return {
        For: this.contentType,
        /* "Due On": this.model.due_on
          ? this.$timezone.formatDateTime(this.model.due_on)
          : "No Due Date", */
        "Is Active": this.model.is_active ? "Yes" : "No",
        "Multiple Submissions": this.model.allow_multiple_submissions
          ? "Yes"
          : "No",
      };
    },
    contentType() {
      let types = [];
      if (this.model.programs && this.model.programs.length) {
        types.push(this.featureName("Programs"));
      }
      if (this.model.supplements && this.model.supplements.length) {
        types.push(this.featureName("Supplements"));
      }
      if (this.model.primary_contents && this.model.primary_contents.length) {
        types.push(this.featureName("Content"));
      }
      return types.join(", ");
    },
  },
  methods: {
    ...mapActions({
      doGetDeliverable: "deliverable/getOne",
    }),
    onGet(id) {
      return this.doGetDeliverable(id).then((result) => {
        this.calendars.data = [result.calendars];
        return result;
      });
    },
  },
};
</script>
