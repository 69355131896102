import api from '../../services/report';
export default function (helper) {
	const TYPES = {
		PIN_STATS: "PIN_STATS"
	};

/**
 * Generated store module
 */
helper.setApi(api);
const types = helper.types('report');
const state = helper.state();
const getters = helper.getters();
const actions = helper.actions({
  pinStats(context, ids) {
    helper.log().info("[Store: Report]: Pin Stats", ids);
    return helper
      .api()
      .pinStats(ids)
      .then((response) => {
        helper
          .log()
          .info(`[Store: Report]: Pin Stats`, response);
        const data = response.data.data;
        context.commit(TYPES.PIN_STATS, data);
        return data;
      })
      .catch((error) => {
        helper
          .log()
          .info(`[Store: Report]: Error Pinning Stats`, error);
        throw error;
      });
  }
}, 'Report');
const mutations = helper.mutations(
  {
    [TYPES.PIN_STATS](state, data) {
      return data;
    },
  },
  types,
  state
);

return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}