<template>
  <div>
    <VRow
      v-if="hasToken && mode == 'inline'"
      row
      align-center
      justify-end
    >
      <VBtn
        :disabled="!pickerApiLoaded || disabled"
        v-model="filename"
        single-line
        :label="label"
        :required="required"
        :icon="asIconButton"
        :text="flatButton"
        :outlined="outline"
        :class="buttonClass"
        @click.native="handleButtonClick"
        :rounded="round"
        :style="buttonStyle"
      >
        <VIcon>{{ icon }}</VIcon>
        <slot>Google Drive</slot>
      </VBtn>
      &nbsp;
      {{ displayName }}
      <VSpacer />
      <slot name="append-outer" />
    </VRow>
    <VBtn
      v-else-if="hasToken"
      :disabled="!pickerApiLoaded || disabled"
      v-model="filename"
      single-line
      :label="label"
      :required="required"
      :icon="asIconButton"
      :text="flatButton"
      :outlined="outline"
      :class="buttonClass"
      @click.native="handleButtonClick"
      :rounded="round"
      :style="buttonStyle"
    >
      <VIcon>{{ icon }}</VIcon>
      <slot>Google Drive</slot>
    </VBtn>
    <GoogleAuth
      ref="googleAuth"
      v-else-if="!isLoading && canConnectOnNoToken"
      @connected="(account) => oauthToken = account && account.token ? account.token.token : oauthToken"
    >
      Connect Google
    </GoogleAuth>
    <small
      v-show="hint"
      v-html="hint"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import GoogleAuth from "@/components/Auth/Google";
import GoogleAuthMixin from "@/mixins/GoogleAuth";
export default {
  name: "GoogleDriveUI",
  mixins: [GoogleAuthMixin],
  components: {
    GoogleAuth
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    buttonClass: {
      type: [String, Object, Array],
      default: ""
    },
    buttonStyle: {
      type: String,
      default: "height: 44px"
    },
    asIconButton: {
      type: Boolean,
      default: false
    },
    createFormData: {
      type: Boolean,
      default: false
    },
    uploadOnSave: {
      type: Boolean,
      default: false
    },
    flatButton: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "attach_file"
    },
    hint: {
      type: String,
      default: null
    },
    value: {
      type: [Array, String],
      default: null
    },
    accept: {
      type: String,
      default: "*"
    },
    label: {
      type: String,
      default: "Please choose..."
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }, // not yet possible because of data
    text: {
      type: String,
      default: "Google Drive"
    },
    files: {
      type: Array,
      default: () => []
    },
    canConnectOnNoToken: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      picked: {},
      pickerApiLoaded: false,
      filename: "",
      displayName: "",
      localFiles: []
    };
  },
  methods: {
    ...mapActions({
      getConnectedAccount: "user/getConnectedAccount"
    }),
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      } else {
        return console.warn(authResult);
      }
    },
    handleButtonClick() {
      // window.gapi.auth2.authorize(
      //   this.config,
      //   this.handleAuthResult
      // );
      this.createPicker();
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        const origin = window.location.protocol + "//" + window.location.host;
        window.gapi.auth2.init({
          client_id: this.config.clientId
        });
        const picker = new google.picker.PickerBuilder()
          .addView(
            new google.picker.DocsView()
              .setIncludeFolders(true)
              .setOwnedByMe(true)
          )
          .setAppId(this.config.appId)
          .setOrigin(origin)
          .setOAuthToken(this.oauthToken)
          // .setDeveloperKey(this.config.developerKey)
          // .setEnableTeamDrives(true)
          .setCallback(this.showDetails)
          .build();
        picker.setVisible(true);
      }
    },
    showDetails(data) {
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        const doc = data.docs[0];
        this.displayName = doc.name;
        this.$emit("addFile", {
          type: doc.mimeType,
          name: doc.name,
          size: doc.sizeBytes,
          url: doc.embedUrl
        });
      }
    }
  },
  mounted() {
    this.loadGoogleApi().then(result => {
      // if (!window.gapi) return console.warn("Google API not loaded");
      window.gapi.load("picker", () => {
        this.pickerApiLoaded = true;
      });
    });
  }
};
</script>
<style>
.picker {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
}
</style>