<script>
export default {
  name: "HasPagination",
  props: {
    data: {
      type: Object,
      default: () => {
        data: [];
      },
    },
    rowsPerPage: {
      type: Array,
      default: () => null,
    },
    itemsAreLocal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
      defaultPagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
      firstPagination: true,
      defaultPerPage: 10,
      internalCurrentPage: 1,
      internalTotalPages: 1,
    };
  },
  computed: {
    dataTablePagination: {
      get: function () {
        return {
          ...this.defaultPagination,
          ...this.pagination,
          ...{
            descending: this.filter.order === "desc",
            sortByDesc: this.filter.order === "desc",
            page: this.pagination.page || this.pagination.currentPage || 1,
            current: this.pagination.page || this.pagination.currentPage || 1,
            rowsPerPage:
              this.pagination.per_page ||
              this.pagination.perPage ||
              this.pagination.rowsPerPage ||
              this.defaultPerPage,
            itemsPerPage:
              this.pagination.per_page ||
              this.pagination.perPage ||
              this.pagination.rowsPerPage ||
              this.defaultPerPage,
            sortBy: this.filter.sort,
            totalItems:
              this.totalItems ||
              this.pagination.count ||
              this.pagination.total_items ||
              this.pagination.totalItems ||
              0,
          },
        };
      },
      set: function (value) {
        // this.updatePagination(value);
        // this.pagination = {
        //   ...this.pagination,
        //   ...value
        // };
      },
    },
    rowsPerPageItems() {
      return !this.$root.env.isProduction
        ? [10, 2, 5, 25, 50, 100, 250, 500, 1000]
        : this.rowsPerPage || [10, 25, 50];
    },
    totalItems() {
      return (
        (this.data || {}).total || ((this.data || {}).data || []).length || 0
      );
    },
    totalPages() {
      if (this.itemsAreLocal) {
        return this.internalTotalPages;
      }
      return (
        (this.data || {}).last_page ||
        this.totalItems / (this.rowsPerPage || 1) ||
        1
      );
    },
    currentPage() {
      if (this.itemsAreLocal) {
        return this.internalCurrentPage;
      }
      return (
        (this.data || {}).page ||
        (this.data || {}).current_page ||
        this.internalCurrentPage
      );
    },
  },
  watch: {
    data: {
      handler: function (value) {
        this.$log.debug(this.$parent._name + ": Data updated", value);
        if (value instanceof Object) {
          this.pagination = {
            page: value.page || value.current_page || 1,
            current: value.page || value.current_page || 1,
            pages: value.pages || value.last_page || this.totalPages || 1,
            count: value.total || value.data.length || 0,
            totalItems: value.total || value.data.length || 0,
            perPage: value.per_page || this.defaultPerPage,
          };
          const total = value.total || 0;
          this.$log.debug("Total: ", this.pagination.count, total);
          if (this.pagination.count != total) {
            this.updatePagination(this.pagination);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    updatePage(page) {
      this.updatePagination({
        ...this.pagination,
        ...this.options,
        ...{
          page,
        },
      });
    },
    updatePagination(_pagination) {
      const pagination = _pagination || this.defaultPagination;
      const filter = {
        page: pagination.page,
        sort: pagination.sortBy || this.filter.sort,
        order: pagination.sortDesc || pagination.descending,
        perPage:
          pagination.itemsPerPage || pagination.perPage || this.defaultPerPage,
        totalItems: pagination.totalItems || this.totalItems,
      };
      this.$log.info("Page", pagination.page, this.pagination.page);
      if (
        pagination.page != this.pagination.page ||
        (!this.firstPagination && filter !== this.filter && !this.filter.s)
      ) {
        this.filter = {
          ...this.filter,
          ...filter,
        };
        this.$emit("paginate", this.filter);
      }
      this.firstPagination = false;
      this.pagination = {
        page:
          pagination.page ||
          pagination.current_page ||
          this.pagination.page ||
          1,
        current:
          pagination.page ||
          pagination.current_page ||
          this.pagination.current ||
          1,
        pages:
          pagination.pages ||
          pagination.last_page ||
          this.totalPages ||
          this.pagination.page ||
          1,
        count:
          pagination.total ||
          (pagination.data || []).length ||
          this.pagination.count ||
          0,
        totalItems:
          pagination.total ||
          (pagination.data || []).length ||
          this.pagination.totalItems ||
          0,
        perPage:
          pagination.per_page || this.pagination.perPage || this.defaultPerPage,
      };
      this.internalCurrentPage = this.pagination.page;
      this.internalTotalPages = this.pagination.pages;
      this.$emit("update:pagination", this.pagination);
    },
    onPaginateLocal(_pagination, prop) {
      this.$log.debug(this._name, prop, _pagination);
      const pagination = _pagination || this.defaultPagination;
      const property = prop || "data";
      const totalItems = pagination.totalItems || pagination.total;
      const rowsPerPage =
        pagination.perPage ||
        pagination.per_page ||
        pagination.rowsPerPage ||
        this.defaultPerPage;
      this[property].per_page = rowsPerPage;
      this[property].current_page = pagination.page || pagination.current || 1;
      this[property].last_page =
        pagination.last_page ||
        pagination.last ||
        Math.round(totalItems / (rowsPerPage || this.defaultPerPage));
      this.$log.debug(this._name, this[property]);
    },
    getLocalDataPaginated(_pagination, prop) {
      const property = prop || "data";
      this.$log.debug(this._name, prop, _pagination);
      const data = { ...{}, ...this[property] };
      const pagination = _pagination || this.defaultPagination;
      const rowsPerPage =
        pagination.rowsPerPage ||
        pagination.perPage ||
        pagination.per_page ||
        data.per_page ||
        data.rowsPerPage ||
        this.defaultPerPage;
      const currentPage =
        pagination.page ||
        pagination.current ||
        pagination.currentPage ||
        data.current_page ||
        data.page ||
        1;
      this.$log.debug(this._name, data, currentPage, rowsPerPage);
      if (data instanceof Object) {
        this.$log.debug(
          this._name,
          currentPage,
          rowsPerPage * (currentPage - 1),
          rowsPerPage + rowsPerPage * (currentPage - 1)
        );
        data.data = (data.data instanceof Array ? data.data : []).slice(
          rowsPerPage * (currentPage - 1),
          rowsPerPage + rowsPerPage * (currentPage - 1)
        );
        data.current_page = currentPage;
        const totalItems =
          pagination.totalItems || pagination.total || data.data.length;
        data.last_page =
          pagination.last_page ||
          pagination.last ||
          Math.round(totalItems / (rowsPerPage || this.defaultPerPage));
        data.per_page = rowsPerPage;
      }
      this.$log.debug(this._name, data);
      return data || { data: [] };
    },
  },
};
</script>