var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'mr-2': !_vm.altView,
  }},[(_vm.altView)?[_c('VCard',{staticClass:"d-flex align-center",attrs:{"height":"92px"}},[_c('VAvatar',{style:({
          border: _vm.item.title ? 'none' : '1px solid #555555 !important',
          opacity: _vm.item.title ? 1 : 0.7,
          margin: '0px 20px'
        }),attrs:{"size":"52px","color":_vm.getColor}},[_c('span',{class:{
            'white--text': _vm.getColor != '#ffffff',
            'black--text': _vm.getColor == '#ffffff',
          },staticStyle:{"font-size":"20px","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getAvatarContent)+" ")])]),_c('span',{staticStyle:{"font-weight":"500","color":"#0066FF","cursor":"pointer"},on:{"click":_vm.toggleEmojiPicker}},[_vm._v(" Change icon & background color > ")])],1)]:[(_vm.hasEmoji)?_c('VAvatar',{staticStyle:{"cursor":"pointer"},attrs:{"size":"40","color":_vm.getColor},on:{"click":_vm.toggleEmojiPicker}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.getAvatarContent)+" ")])]):_c('VBadge',{attrs:{"overlap":"","bordered":"","color":"#ebebeb"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('VIcon',{attrs:{"color":"#6c6c6c","size":"18px"}},[_vm._v(" mdi-plus ")])]},proxy:true}])},[_c('VAvatar',{staticStyle:{"cursor":"pointer"},attrs:{"size":"40","color":"#ebebeb"},on:{"click":_vm.toggleEmojiPicker}},[_c('VIcon',[_vm._v("mdi-emoticon-outline")])],1)],1)],_c('EmojiPicker',{ref:"emojiPicker",attrs:{"enable-color-picker":"","color":_vm.getColor},on:{"change":_vm.onChangeEmoji}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }