<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card"
    :loading="isFirstLoad && !isDashboard"
  >
    <VRow
      row
      wrap
      :class="{
        'mt-n3': isDashboard,
      }"
    >
      <VCol
        v-if="localPipelines.data.length === 0 && (!isDashboard || !$isUserAdmin)"
        cols="12"
      >
        <VAlert
          type="info"
          style="font-size: 16px;"
          :value="true"
        >
          No
          {{
            featureName(tabName)
              .pluralize()
              .toLowerCase()
          }}
          assigned!
        </VAlert>
      </VCol>
      <template v-else>
        <VCol
          v-if="!isTilesView"
          cols="12"
        >
          <PipelinesData
            :existing-data="localPipelines.data"
            @duplicated="(result) => localPipelines.data.unshift(result)"
            @deleted="
              (id) =>
                (localPipelines.data = localPipelines.data.filter(
                  (pipeline) => pipeline.id != id
                ))
            "
            @updated="
              (result) =>
                (localPipelines.data = localPipelines.data.map((pipeline) =>
                  pipeline.id === result.id ? result : pipeline
                ))
            "
          />
        </VCol>
        <DataViewUI
          v-else
          hide-actions
          hide-main-title
          hide-records-info
          default-view-mode="grid"
          background-color="transparent"
          :data="localPipelines"
          :md="$isMenuExpanded && isDashboard ? 12 : 6"
          :lg="!$isMenuExpanded && !isDashboard ? 4 : 6"
          :enable-infinite-loader="enableInfiniteLoader"
          @infiniteLoad="onInfiniteLoad"
        >
          <template
            slot="card"
            slot-scope="props"
          >
            <IndexCard
              disable-visibility
              height="240px"
              content-min-height="98px"
              card-shadow="0px 6px 16px rgba(216, 220, 240, 0.5)"
              :check-visibility="false"
              :enable-duplicate="false"
              :item="props.item"
              :item-type="tabName"
              :parent-model="model"
              :loading="props.item.loading || false"
              :hide-menu="!$isUserAdmin"
              :href="getHrefForItem(`${modelType}.${$isUserAdmin ? 'update' : 'view'}`, props.item.id)"
              :count="(model.pipelines_count - localPipelines.data.length) + 1"
              :show-count="checkShowCount(props.index)"
              @showMore="$emit('toggleTab', tabName.toLowerCase().pluralize())"
              @onClick="$bus.$emit('editSectionItem', tabName.toLowerCase(), props.item)"
              @enableItemLoading="(status) => $set(localPipelines.data[props.index], 'loading', status)"
            >
              <template #title-text>
                <VChip
                  text-color="white"
                  class="text-uppercase"
                  :color="props.item.type == 'pipeline' ? '#FF2A7A' : '#737ADF'"
                >
                  {{ pipelineType(props.item) }}
                  <VTooltip top>
                    <template #activator="{ on }">
                      <VIcon
                        v-on="on"
                        color="white"
                        class="pl-1"
                      >
                        mdi-information-outline
                      </VIcon>
                    </template>
                    <template v-if="props.item.type == 'pipeline'">
                      All registrants will join this {{ featureName("Program") }} upon submission
                    </template>
                    <template v-else>
                      Only selected applicants will join this {{ featureName("Program") }}
                    </template>
                  </VTooltip>
                </VChip>
              </template>
              <template #card-content>
                <VList>
                  <VListItem class="px-2 d-block">
                    <VListItemTitle>
                      <VRow>
                        <VCol
                          cols="10"
                          class="pa-0 text-truncate"
                        >
                          <span class="title font-weight-black">
                            {{ props.item.title }}
                          </span>
                          <VSpacer />
                        </VCol>
                        <VCol
                          cols="2"
                          class="pa-0 d-flex justify-end"
                        >
                          <Linkable :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, props.item.id)" />
                        </VCol>
                      </VRow>
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </template>
              <template #actions>
                <VRow class="mt-n5">
                  <VCol cols="12">
                    <VRow>
                      <VCol
                        cols="12"
                        class="py-2 d-flex"
                        style="color: #808080;"
                      >
                        Total {{ props.item.type == "registration" ? "Registrants" : "Applications" }}
                      </VCol>
                      <VCol
                        cols="12"
                        class="d-flex"
                      >
                        <VChip
                          color="#FDBF14"
                          text-color="white"
                          :class="{
                            'caption-1': $vuetify.breakpoint.mdAndDown,
                          }"
                          :style="{
                            'font-weight': 700,
                            'border-radius': '33px 6px 6px 33px',
                          }"
                        >
                          {{ `${props.item.applications_count} ${props.item.type == "registration" ? "Registrants" : "Applicants"}` }}
                        </VChip>
                        <VChip
                          color="rgba(96, 190, 57, 0.2)"
                          text-color="#60BE39"
                          style="font-weight: 700; border-radius: 6px 33px 33px 6px;"
                          :class="{
                            'ml-1': true,
                            'caption-1': $vuetify.breakpoint.mdAndDown,
                          }"
                        >
                          {{ `${props.item.completed_count} Submitted - ${props.item.outstanding_count} In Progress` }}
                        </VChip>
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              </template>
            </IndexCard>
          </template>
          <template #extra-card>
            <VCol
              v-if="isDashboard && $isUserAdmin"
              md="12"
              :lg="localPipelines.data.length ? 6 : 12"
            >
              <VCard
                v-if="localPipelines.data.length"
                color="transparent"
                height="240px"
              >
                <VCardText class="pa-0">
                  <VRow
                    class="ma-0"
                    style="border: 1px dashed #BFBFBF !important; border-radius: 16px;"
                  >
                    <VCol
                      cols="12"
                      class="text-center"
                    >
                      <VBtn
                        height="86px"
                        width="100%"
                        color="transparent"
                        class="no-shadow"
                        @click="$emit('toggleAttach')"
                      >
                        <VList color="transparent">
                          <VListItem
                            class="d-block"
                            style="min-height: auto;"
                          >
                            <VListItemTitle
                              class="title"
                              style="color: #808080;"
                            >
                              Use Existing
                            </VListItemTitle>
                            <VListItemSubtitle
                              class="subtitle-1"
                              style="line-height: 140%;"
                            >
                              Select from existing forms
                            </VListItemSubtitle>
                          </VListItem>
                        </VList>
                      </VBtn>
                    </VCol>
                  </VRow>
                  <VRow
                    class="ma-0 mt-4"
                    style="border: 1px dashed #BFBFBF !important; border-radius: 16px;"
                  >
                    <VCol
                      cols="12"
                      class="text-center"
                    >
                      <VBtn
                        height="86px"
                        width="100%"
                        color="transparent"
                        class="no-shadow"
                        @click="$emit('addSection')"
                      >
                        <VList color="transparent">
                          <VListItem
                            class="d-block"
                            style="min-height: auto;"
                          >
                            <VListItemTitle
                              class="title"
                              style="color: #808080;"
                            >
                              Create Blank
                            </VListItemTitle>
                            <VListItemSubtitle
                              class="subtitle-1"
                              style="line-height: 140%;"
                            >
                              Create new application
                            </VListItemSubtitle>
                          </VListItem>
                        </VList>
                      </VBtn>
                    </VCol>
                  </VRow>
                </VCardText>
              </VCard>
              <VRow v-else>
                <VCol cols="6">
                  <VBtn
                    height="110px"
                    width="100%"
                    class="no-shadow ml-2"
                    style="border: 1px dashed #BFBFBF; border-radius: 16px;"
                    @click="$emit('toggleAttach')"
                  >
                    <VList color="transparent">
                      <VListItem class="d-block">
                        <VListItemTitle
                          class="title"
                          style="color: #808080;"
                        >
                          Use Existing
                        </VListItemTitle>
                        <VListItemSubtitle
                          class="subtitle-1 py-1"
                          style="line-height: 140%;"
                        >
                          Select from existing forms
                        </VListItemSubtitle>
                      </VListItem>
                    </VList>
                  </VBtn>
                </VCol>
                <VCol cols="6">
                  <VBtn
                    height="110px"
                    width="100%"
                    class="no-shadow"
                    style="border: 1px dashed #BFBFBF; border-radius: 16px;"
                    @click="$emit('addSection')"
                  >
                    <VList color="transparent">
                      <VListItem class="d-block">
                        <VListItemTitle
                          class="title"
                          style="color: #808080;"
                        >
                          Create Blank
                        </VListItemTitle>
                        <VListItemSubtitle
                          class="subtitle-1 py-1"
                          style="line-height: 140%;"
                        >
                          Create new application
                        </VListItemSubtitle>
                      </VListItem>
                    </VList>
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
          </template>
        </DataViewUI>
      </template>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import HasDueMixin from "@/mixins/HasDueOn";
import PipelinesData from "@/components/Organization/Pipelines/Data";
import IndexCard from "../Card";
export default {
  name: "Pipelines",
  mixins: [ViewProgramSection, HasDueMixin],
  components: {
    IndexCard,
    PipelinesData,
  },
  data() {
    return {
      tabName: "Pipeline",
      modelType: "pipeline",
      localPipelines: {
        data: [],
      },
    };
  },
  watch: {
    activeTab: {
      handler: function(value) {
        if (value == 1) {
          this.doSearch(this.onGetAll);
        } else {
          this.localPipelines.data = this.model.pipelines;
        }
        this.enableInfiniteLoader = value > 0;
      },
      immediate: true,
    },
    model: {
      handler: function(value) {
        this.localPipelines.data = value.pipelines;
      },
      deep: true,
    },
    infiniteLoad: {
      handler: function(value) {
        if(!this.isDashboard) {
          this.localPipelines = value;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      doGetPipelines: "pipeline/getAll",
    }),
    onGetAll(params) {
      params.perPage = 12;
      params.filter = {
        program: [this.model.id],
        strict: true,
      };
      return this.doGetPipelines(params);
    },
    checkShowCount(key) {
      if(this.isDashboard) {
        return this.model.pipelines_count > this.limitForDashboard - 1 && key === this.localPipelines.data.length - 1;
      }
      return false;
    },
    pipelineType(item) {
      switch (item.type) {
        case "pipeline":
          return "registration";
        case "application-pipeline":
          return "application";
      
        default:
          return item.type.toLowerCase();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.metadata .v-list-item {
  min-height: 26px !important;
}
.status-chip {
  border-radius: 12px;
  position: absolute;
  bottom: 18px;
  right: 18px;
}
.metadata-section {
  height: 132px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 0.5px #ececf1;
}
.caption-1 {
  font-size: 10px;
}
</style>
