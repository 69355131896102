<template>
  <VContainer
    fill-height
    fluid
    style="width: 100%"
    class="px-0 py-0"
  >
    <Cards
      v-if="modelHasId"
      mode="dialog"
      :value="activeCard"
      :assessment="model"
      :return-to="returnTo"
      :is-preview="isPreview"
      @title="onUpdateTitle"
      @toggle:dialog="$emit('toggle:dialog')"
    />
    <VRow
      v-else
      row
      fluid
      fill-height
      align-center
      justify-center
      class="mt-4"
      style="height: 100%"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import Cards from "./Present/Cards";
import HasAssessmentsMixin from "@/mixins/HasAssessments";

export default {
  name: "LessonView",
  props: {
    returnTo: {
      type: Object,
      default: () => null
    },
    activeCard: {
      type: Number,
      default: null
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: { Cards },
  mixins: [ViewMixin, HasAssessmentsMixin],
  data() {
    return {
      modelType: "Assessment",
      assessmentType: "lesson",
    };
  },
  methods: {
    onGet(id) {
      return this.onGetAssessment(id);
    },
    onUpdateTitle(title) {
      this.title = `${this.model.title}: ${title}`;
      this.$emit("title", title);
    }
  }
};
</script>

