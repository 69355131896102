<script>
export default {
  data() {
    return {
      refNames: [],
      tabsMatchMode: "normal",
    };
  },
  computed: {
    realTabs() {
      return this.tabs || [];
    },
    activeUrlTab: {
      get() {
        const compare = (string) => {
          return (
            string === this.$route.hash || string === this.$route.hash.substr(1)
          );
        };
        const index =
          this.realTabs instanceof Array
            ? this.enabledTabs.findIndex((tab) => {
                return this.tabsMatchMode === "custom"
                  ? this.customTabsCompare(tab.url)
                  : compare(tab.url);
              })
            : 0;
        return index <= 0 ? 0 : index;
      },
      set(value) {
        this.$router.push({
          hash: value + "",
        });
      },
    },
    enabledTabs() {
      return this.realTabs.filter((tab) =>
        tab instanceof Object ? tab.enabled === true : true
      );
    },
    currentRefName() {
      return this.refNames[this.activeUrlTab];
    },
    tabRef() {
      return this.$refs[this.currentRefName] || { config: this.config };
    },
    tabRefProps() {
      return this.tabRef.props || {};
    },
    tabRefData() {
      return this.tabRef || {};
    },
    tabRefConfig() {
      return this.tabRefData.config || {};
    },
  },
  methods: {
    customTabsCompare(string) {
      const regex = new RegExp(`^${string}`);
      return (
        regex.test(this.$route.hash || "") ||
        regex.test((this.$route.hash || "").substr(1))
      );
    },
    getTabRef() {
      return this.$refs[this.currentRefName] || { config: this.config };
    },
    getTabRefData() {
      return this.getTabRef().data || {};
    },
    getTabRefConfig() {
      return this.getTabRefData().config || {};
    },
    getTabRefProps() {
      return this.getTabRef().props || {};
    },
  },
};
</script>