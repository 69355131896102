var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticClass:"menu-items pt-0 main-menu-items align-center justify-center fill-height",style:({
    height: !_vm.dashboard ? 'calc(100% - 56px)': 'auto'
  }),attrs:{"id":"main-menu"}},[_c('VCol',{class:{
      'pt-0': true,
      'pl-3': _vm.$vuetify.breakpoint.mdAndUp
    },attrs:{"sm":"12","md":_vm.dashboard ? 12 : 10,"lg":_vm.dashboard ? 12 : 8,"xl":_vm.dashboard ? 12 : 6}},[(!_vm.dashboard && false)?_c('VRow',{attrs:{"justify-center":"","align-center":""}},[_c('VCol',{attrs:{"shrink":""}},[_c('VBtn',{attrs:{"rounded":"","large":"","fab":""},on:{"click":function($event){return _vm.navigateTo({name: 'dashboard'})}}},[_c('VIcon',{attrs:{"color":"primary"}},[_vm._v(" home ")])],1)],1)],1):_vm._e(),_c('VRow',{staticClass:"mt-4",style:({
        maxHeight: _vm.dashboard ? 'auto' : 'calc(100vh - 140px)',
        overflow: 'hidden auto'
      }),attrs:{"align-center":"","justify-center":""}},[_vm._l((_vm.menuItems),function(item){return [_c('VCol',{key:item.title,class:{
            active: _vm.routeIsPartOfGroup(item.routeGroup),
            'menu-items-item': true,
            'pl-0': _vm.$vuetify.breakpoint.mdAndUp,
            'mb-4': true
          },style:({
            flex: '0 1 417px',
            width: '417px',
            maxWidth: '417px'
          }),attrs:{"sm":"6"},on:{"click":function($event){return _vm.onSelectMenuItem(item)},"mouseover":function($event){return _vm.onMouseoverMenuItem(item)},"mouseleave":function($event){return _vm.onMouseleaveMenuItem(item)}}},[_c('VBtn',{staticClass:"main-menu-button",style:({
              overflow: 'visible',
              border: item.style.border
            }),attrs:{"block":"","to":item.route || {name: item.routeName}}},[_c('VRow',{class:{
                'px-4': _vm.$vuetify.breakpoint.smAndDown
              },attrs:{"align-center":"","justify-start":""}},[_c('VCol',{attrs:{"shrink":""}},[_c('div',{staticClass:"d-flex align-center justify-center",style:({
                    width: '71px',
                    height: '71px',
                    backgroundColor: item.style.icon.backgroundColor,
                    borderRadius: '10px',
                    boxShadow: `0px 5px 14.3px 0.8px ${item.style.icon.shadowColor}`
                  })},[_c('img',{style:({
                      width: item.style.icon.width,
                      height: item.style.icon.height,
                      objectFit: 'contain'
                    }),attrs:{"src":item.icon}})])]),_c('VCol',{attrs:{"grow":""}},[_c('h3',{staticClass:"text-left"},[_c('BetaBadge',{attrs:{"enable-tooltip":"","is-beta":item.beta}},[_c('strong',[_vm._v(_vm._s(item.title))])])],1),_c('p',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(item.description || 'Here you can access high-level information for all of the programs you are in. Including key dates and deadlines.')}}),((item.badge || {}).active === true)?_c('span',{staticClass:"d-inline-flex align-center justify-center",style:({
                    backgroundColor: item.badge.backgroundColor || '#000',
                    position: 'absolute',
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: '500',
                    top: '-20px',
                    right: '-28px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '10px',
                    boxShadow: item.badge.boxShadow
                  }),domProps:{"innerHTML":_vm._s((item.badge || {}).value)}}):_vm._e()])],1)],1)],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }