import { Api as BaseApi } from "./assessment";

/**
 * Lesson API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	duplicateCard(params) {
		return this.api()
			.get(this.getUrl("lessons", `${params.lesson_id}/card/${params.card_id}/duplicate`))
			.catch(error => {
				this.log().error("[Services Error: Lesson: Duplicating card]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Lesson",
	path: "lessons",
});

export default api;
