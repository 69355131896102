import api from '../../services/product';
export default function (helper) {
  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('product');
  const state = helper.state();
  const getters = helper.getters();
  const actions = helper.actions(
    {
			/**
			 * Get Checkout Session
			 * @param {Object} context
			 * @param {any} params
			 * @returns {Promise}
			 */
       createCheckoutSession(context, params, attr) {
				helper
					.log()
					.info(
						`[Store: Product]: Create Checkout Session`,
						params
					);
				return new Promise((resolve, reject) => {
					return api
						.createCheckoutSession(params)
						.then((response) => {
							helper
								.log()
								.info(
									`[Store: Product]: Created Checkout Session`,
									response
								);
							const data = response.data.data;
							resolve(data);
						})
						.catch((error) => {
							helper
								.log()
								.info(
									`[Store: Product]: Error Creating Checkout Session`,
									error
								);
							reject(error);
						});
				});
			},
		},
    'Product'
  );
  const mutations = helper.mutations({}, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}