<template>
  <VCard id="save-card">
    <VCardTitle
      v-if="card.type"
      id="save-card-title"
      :class="{
        'mx-2 pl-5 pr-5': true,
        'red lighten-4': modelIsDirty
      }"
    >
      <VIcon
        size="20px"
        class="pa-1 rounded"
        style="background-color: #f8f8f8;"
      >
        {{ icon }}
      </VIcon>
      <span class="pl-3">
        {{ card.type }}
      </span>
      <VSpacer />
      <span
        :class="{
          'mx-2': true
        }"
      >
        <VProgressCircular
          v-if="isSavingCard && !isSavingCardDone"
          :color="savingCardMessageColor"
          indeterminate
          :size="10"
          :width="2"
        />
        <VIcon
          v-else-if="modelIsDirty"
          color="error"
          :size="12"
        >
          error
        </VIcon>
        <VIcon
          v-else
          color="success"
          :size="12"
        >
          check
        </VIcon>&nbsp;
        <small :class="`${savingCardMessageColor}--text`">{{ savingCardMessage }}</small>
        &nbsp;
      </span>
      <template v-if="isSavingCardError">
        <span class="error--text">{{ savingCardMessage }}</span>
      </template>
      <VBtn
        v-if="$isUserAdmin || $isUserMentor"
        depressed
        height="30px"
        color="info"
        id="preview-lesson"
        :disabled="!canAddSlide"
        @click="preview(index)"
      >
        <VIcon small>
          visibility
        </VIcon>
        <span
          class="pl-1"
          style="color: #fff; opacity: 0.75; font-size: 10px;"
        >
          PREVIEW
        </span>
      </VBtn>
    </VCardTitle>
    <VCardText
      fill-height
      id="save-card-text"
      class="py-0"
    >
      <template v-if="card.type != null">
        <VRow style="max-height: calc(100% - 87px)">
          <VCol
            v-if="images.length > 0"
            cols="6"
            class="pa-7"
            style="border-right: 1px solid #e3e3e3;"
          >
            <ImagesList
              :files="images"
              @removeFile="onRemoveFile"
            />
          </VCol>
          <VCol :cols="images.length > 0 ? 6 : 12">
            <component
              :is="getComponent()"
              :card="card"
              :index="index"
              :card-options="options"
              :assessment="assessment"
              :has-additional-images="images.length > 0"
              @blur="saveCard"
              @input="saveCard"
              @saveCardError="onToggleSavingCardError"
              @saveCardStart="onToggleSavingCard"
              @saveCardDirty="onToggleSavingCardDirty"
              @saveCardSuccess="onToggleSavingCard"
            />
          </VCol>
        </VRow>
        <VRow
          v-if="hasCurrentCard"
          :style="{
            'border-top': '1px solid #e3e3e3'
          }"
        >
          <CardOptions
            ref="cardOptions"
            :block="false"
            :assessment="assessment"
            :card="card"
            :index="index"
            class="pt-2 pb-0"
            @input="saveCard"
            @files="(files) => images = files"
            @saveCardDirty="$emit('saveCardDirty')"
          />
        </VRow>
      </template>
      <VAlert
        v-else
        class="mt-3"
        :type="!canAddSlide ? 'warning' : 'info'"
        :value="true"
      >
        <template v-if="!canAddSlide">
          Enter a title to save your {{ featureName('lesson').singularize() }}!
        </template>
        <template v-else>
          No card selected
        </template>
      </VAlert>
    </VCardText>
  </VCard>
</template>

<script>
import { mapActions } from "vuex";
import TextInput from "./TextInput";
import TextArea from "./TextArea";
import Question from "./Question";
import Video from "./Video";
import ImageInput from "./Image";
import Worksheet from "./Worksheet";
import Title from "./Title";
import Paragraph from "./Paragraph";
import Result from "./Result";
import CardOptions from "./Options";
import ImagesList from "@/components/Elements/Data/ImagesList";

export default {
  name: "SaveCard",
  components: {
    TextInput,
    TextArea,
    Question,
    Video,
    ImageInput,
    Worksheet,
    Title,
    Paragraph,
    Result,
    CardOptions,
    ImagesList,
  },
  props: {
    assessment: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [String, Number],
      default: null,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    modelIsDirty: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Lesson",
    },
    icon: {
      type: String,
      default: null,
    },
    canAddSlide: {
      type: Boolean,
      default: false,
    },
    hasCurrentCard: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      result: {},
      modelType: "SaveCard",
      isSavingCard: false,
      isSavingCardError: false,
      savingCardMessageColor: "light-gray",
      savingCardMessage: "All changes saved",
      isSavingCardDone: false,
      images: [],
    };
  },
  watch: {
    modelIsDirty: function (value) {
      if (value) {
        this.savingCardMessage = "Unsaved changes";
      } else {
        this.savingCardMessage = "All changes saved";
      }
    },
  },
  methods: {
    ...mapActions({
      doDelete: "assessmentSaved/delete",
      doSaveCard: "assessmentCard/save",
    }),
    saveForLater() {
      if (this.card.saved_for_user instanceof Object && this.card.id) {
        this.doDeleteSaved(this.card.id).then(
          (result) => (this.card.saved_for_user = null)
        );
      } else {
        this.doSaveForLater(this.card).then(
          (result) => (this.card.saved_for_user = result)
        );
      }
    },
    saveCard(_params) {
      const params = _params || {};
      this.$emit("input", params.id ? params : this.index, params);
      // if (this.assessment.id) {
      //   this.doSaveCard({
      //     ...{
      //       assessment_id: this.assessment.id
      //     },
      //     ...params
      //   }).then(result => {
      //     this.$emit("input", this.index, result);
      //   });
      // }
    },
    getComponent() {
      if (this.card.type === "Image") {
        return ImageInput;
      }
      if (this.card.type === "Text") {
        return TextInput;
      }
      return this.card.type;
    },
    onToggleSavingCard() {
      // Disable background save
      return true;
      this.isSavingCard = !this.isSavingCard;
      if (this.isSavingCard) {
        this.isSavingCardDone = false;
        this.savingCardMessage = "Saving...";
        this.isSavingCardError = false;
      } else {
        this.isSavingCard = this.isSavingCardDone = true;
        this.savingCardMessage = "Saved!";
        this.savingCardMessageColor = "success";
        setTimeout(() => {
          this.isSavingCard = false;
          this.savingCardMessage = "All changes saved";
          this.savingCardMessageColor = "gray";
        }, 2500);
      }
    },
    onToggleSavingCardError(message) {
      this.savingCardMessage = message;
      this.isSavingCardError = !this.isSavingCardError;
    },
    onToggleSavingCardDirty() {
      this.$emit("saveCardDirty");
      this.savingCardMessage = "Unsaved changes";
    },
    onRemoveFile(index, file) {
      this.$refs.cardOptions.onDeleteFile(index, file);
    },
    preview(priority) {
      this.$emit("setPreviewItem", priority);
      this.$emit("setViewDialogModel");
    },
  },
};
</script>

<style scoped lang="scss">
:root {
  #save-card-title {
    height: 51px;
  }
  #save-card-text {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 32px);

    .row:first-child {
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: row !important;
      height: 100%;
    }
  }
  #save-card {
    border-bottom-left-radius: 0px;
    height: 100%;
  }
  #preview-lesson {
    border: solid 0.5px #dfdfdf;
    box-shadow: none;
    padding: 5.5px 8.5px 5px 7px;
  }
}
</style>
