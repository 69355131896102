<template>
  <VRow
    fill-height
    id="lesson-cards"
    :style="{
      width: $vuetify.breakpoint.mdAndUp ? '100%' : 'auto'
    }"
  >
    <VCol
      cols="12"
      style="height: 10px"
    >
      <VProgressLinear
        color="success"
        class="mt-0"
        height="5"
        :value="progress"
      />
    </VCol>
    <VCol
      grow
      cols="12"
      :class="{
        'px-4': $vuetify.breakpoint.mdAndUp,
        'px-1': $vuetify.breakpoint.smAndDown
      }"
      :style="{
        height: $vuetify.breakpoint.smAndDown && mode != 'standalone' ? 'auto' : 'calc(100% - 10px)'
      }"
    >
      <VRow
        row
        justify-center
        fill-height
        :align-center="$vuetify.breakpoint.mdAndUp"
      >
        <VCol
          id="lesson-cards-carousel-container"
          align-self-center
          :style="{
            flex: $vuetify.breakpoint.mdAndUp ? '0 1 1100px' : '1 1 100%'
          }"
        >
          <VCarousel
            id="lesson-cards-carousel"
            :cycle="false"
            :value="currentIndex"
            :hide-delimiters="true"
            :show-arrows="false"
            :height="$vuetify.breakpoint.mdAndUp ? 476 : 'calc(100vh - 86px)'"
            :class="{
              'fill-height': true,

            }"
            @change="setCurrentIndex"
          >
            <template v-if="hasCards">
              <VCarouselItem
                v-for="(card,key) in cards"
                lazy
                class="fill-height"
                :value="key"
                :key="key"
                :id="`lesson-card-${key}`"
                :style="{
                  height: '100%',
                  maxWidth: $vuetify.breakpoint.mdAndUp ? '1100px' : 'calc(100vw - 24px)'
                }"
              >
                <Card
                  hide-complete-button
                  :card="card"
                  :index="key"
                  :is-current="currentIndex === key"
                  :is-first="key === 0"
                  :is-last="key === cards.length-1"
                  :is-preview="isPreview"
                  :assessment="assessment"
                  :mode="mode"
                  @next="next"
                  @previous="previous"
                  @toggle:dialog="$emit('toggle:dialog')"
                />
              </VCarouselItem>
            </template>
            <VCarouselItem
              v-else
              height="100%"
            >
              <VRow
                fill-height
                align-center
                justify-center
              >
                <VProgressCircular
                  v-if="showNoCardsMsg"
                  indeterminate
                  color="primary"
                />
                <VCol v-else>
                  <BlankCard />
                </VCol>
              </VRow>
            </VCarouselItem>
          </VCarousel>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import Card from "./Card";
import BlankCard from "./BlankCard";

export default {
  name: "PresentationCards",
  components: { 
    Card, 
    BlankCard,
  },
  props: {
    mode: {
      type: String,
      default: "standalone",
    },
    height: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: -1,
    },
    assessment: {
      type: Object,
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: -1,
      isFirstLoad: true,
      userChange: false,
      modelType: "PresentationCards",
    };
  },
  computed: {
    hasCards() {
      return this.cards.length > 0;
    },
    progress() {
      if (this.hasCards) {
        return ((this.currentIndex + 1) / this.cards.length) * 100;
      } else {
        return 0;
      }
    },
    cards() {
      if(this.tempCards.length) {
        return [
          ...this.tempCards,
          {
            name: "Congrats",
            type: "Final",
          },
        ];
      }
      return this.tempCards;
    },
    tempCards() {
      return this.assessment instanceof Object &&
        this.assessment.all_cards instanceof Array
        ? this.assessment.all_cards.sortBy("priority")
        : [];
    },
    localHeight() {
      if (this.height) {
        return this.height;
      }
      return this.mode === "standalone"
        ? "calc(100vh - 100px)"
        : "calc(100vh - 86px)";
    },
    currentCard() {
      return this.cards[this.currentIndex];
    },
    title() {
      if (this.assessment && this.assessment.title) {
        const index = this.currentIndex < 0 ? 0 : this.currentIndex;
        return `${this.assessment.title}: ${index + 1} / ${
          this.cards.length || 1
        }`;
      }
      return "";
    },
    showNoCardsMsg() {
      if (this.assessment && this.assessment.title) {
        return this.assessment.cards_count > 0 ? true : false;
      }
      return true;
    },
  },
  watch: {
    value: function (value) {
      this.currentIndex = value;
    },
    currentIndex: function (value) {
      this.$emit("title", this.title);
    },
    /**
     * Need this hack to be able to set the initial card when the presentation loads
     */
    "cards.length": function (value) {
      if (value > 0) {
        const index = this.assessment.result_for_user
          ? this.cards.findIndex(
              (card) =>
                card.id === this.assessment.result_for_user.current_card_id
            )
          : 0;
        this.setCurrentIndex(index > 0 ? index : 0, true);
      }
    },
  },
  created() {
    // Load any specific card by its priority
    if (this.value > 0) {
      this.setCurrentIndex(this.value, true);
    }
  },
  methods: {
    next() {
      console.log("next", this.currentIndex);
      if (this.currentIndex < this.cards.length) {
        /**
         * Compensating for -1 first value for current card
         */
        if (this.currentIndex < 0) {
          this.currentIndex = 0;
        }
        this.currentIndex++;
        this.userChange = true;
        this.$emit("currentIndex", this.currentIndex);
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.userChange = true;
        this.$emit("currentIndex", this.currentIndex);
      }
    },
    setCurrentIndex(index, force) {
      this.$log.debug("Setting card index", index, force);
      /**
       * Carousel won't accept a 0 value as initial value
       * It will instead default to the last card in the set
       * Setting -1 here to properly control initial card
       * */
      if (!this.userChange && !force) {
        index = -1;
      }
      this.currentIndex = index;
      this.$emit("currentIndex", this.currentIndex);
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/variables.scss";

#lesson-cards-carousel-container {
  .v-window.v-carousel {
    box-shadow: -5.1px 14.1px 40px 0 rgba(252, 178, 123, 0.15);
  }
}
.v-window {
  .v-window__container {
    height: 100%;
    .v-window-item {
      height: 100%;
      .v-carousel__item {
        height: 100%;
      }
    }
  }
}

#lesson-cards {
  background-color: #f6f6f6;
  background-image: url("../../../images/lessons/vector-left@3x.png"),
    url("../../../images/lessons/vector-right@3x.png");
  background-size: auto 100%, auto 100%;
  background-position: top left, top right;
  background-repeat: no-repeat;
}
</style>