var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{staticStyle:{"width":"100%"},attrs:{"wrap":"","fill-height":"","align-center":"","justify-start":""}},[(_vm.card.name)?_c('VCol',{attrs:{"shrink":"","px-4":"","cols":"12"}},[_c('CardTitle',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.card.name)+" ")])],1):_vm._e(),_c('VCol',{style:({
      maxHeight: _vm.card.name ? 'calc(100% - 74px)' : 'calc(100% - 52px)',
      overflowY: 'auto'
    }),attrs:{"grow":""}},[_c('VRow',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},_vm._l((_vm.allInputs),function(part,index){return _c('VCol',{key:index,class:{
          round: true,
          'blue lighten-5': part.type !== 'Text',
          'my-2 py-2': true,
          'mx-1 px-1': true,
          'title': true,
          'text-center': true,
          [part.size || 'auto']: true
        }},[(part.type === 'Text')?[_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],domProps:{"innerHTML":_vm._s(part.value)}})]:[_vm._v(" "+_vm._s(_vm.getVariableValue(part.value))+" ")]],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }