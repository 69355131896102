<template>
  <VMenu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    class="pr-1"
  >
    <template #activator="{ on }">
      <VChip
        pill
        :outlined="outline"
        :color="color"
        :text-color="textColor"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        :selected="selected"
        :dark="dark"
        :light="light"
        :disabled="disabled"
        v-on="on"
      >
        <slot name="activator-icon">
          <VIcon
            left
            v-if="icon !== null"
          />
        </slot>
        <slot>{{ text }}</slot>
        <slot name="activator-right" />
      </VChip>
    </template>
    <VCard width="auto">
      <VCardTitle dark>
        <template v-if="header">
          {{ header }}
        </template>
        <slot name="content-header" />
      </VCardTitle>
      <VCardText>
        <slot name="content" />
      </VCardText>
    </VCard>
  </VMenu>
</template>

<script>
export default {
  name: "ChipMenu",
  props: {
    text: {
      type: String,
      required: true,
      default: null
    },
    header: {
      type: String,
      default: null
    },
    userDetails: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: "default"
    },
    icon: {
      type: [Number, String],
      default: null
    },
    textColor: {
      type: String,
      default: "inherit"
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false
  })
};
</script>
<style lang="scss">
:root {
  .v-badge {
    .v-chip {
      height: 16px !important;
      .v-chip__content {
        height: 16px;
        padding: 0 6px;
        text-transform: lowercase;
      }
    }
  }
}
</style>