<template>
  <VBtn
    text:color="isConnected ? 'error' : 'primary'"
    :loading="isLoading"
    :small="small"
    @click="handleButtonClick"
  >
    {{ isConnected ? 'Disconnect' : 'Connect' }}
  </VBtn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      realConfig: {},
      client: null,
      isLoading: true,
      defaultScopes: [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/calendar.freebusy",
        "https://www.googleapis.com/auth/drive.file"
      ]
    };
  },
  computed: {
    isConnected() {
      return this.account instanceof Object && this.account.id >= 1;
    }
  },
  created() {
    this.realConfig = {
      client_id: this.config
        ? this.config.clientId || this.env.VUE_APP_GOOGLE_CLIENT_ID
        : this.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: this.config
        ? this.config.scope || this.defaultScopes.join(" ")
        : this.defaultScopes.join(" "),
      callback: (response) => {
        const params = {
          code: response.code,
          type: "google"
        };
        this.setAuthToken(params)
          .then(result => {
            this.isLoading = false;
            this.$emit("connected", result);
          })
          .catch(error => {
            this.isLoading = false;
            this.$emit("error", error.message || error);
          });
      },
      error_callback: (error) => {
        this.isLoading = false;
        console.log("Google Auth Error: ", error);
      },
    };
    this.loadUrl()
      .js("https://accounts.google.com/gsi/client")
      .then(() => {
        this.isLoading = false;
        if (!window.google) {
          return console.warn("Google API not loaded");
        }
        this.client = window.google.accounts.oauth2.initCodeClient(this.realConfig);
      });
  },
  methods: {
    ...mapActions({
      setAuthToken: "user/setAuthToken"
    }),
    async handleButtonClick() {
      this.isLoading = true;
      if (this.account && this.account.id) {
        this.$emit("remove:account", this.account);
        await this.waitFor(() => !this.account.id, 10000);
        this.isLoading = false;
      } else {
        this.client.requestCode();
      }
    }
  }
};
</script>