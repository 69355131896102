var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"row":"","wrap":""}},[(_vm.title)?_c('VCol',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"title mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('VCol',[_c('VExpansionPanels',{attrs:{"focusable":""}},_vm._l((_vm.stages),function(stage,key){return _c('VExpansionPanel',{key:key+1,class:{
          'px-0 pt-1 mb-3': true,
          'elevation-0': true,
          'primary': _vm.getIsCurrentStage(stage),
          'success': stage.is_complete,
          'info': !stage.is_complete && stage.is_submitted,
          'white--text': stage.is_complete || stage.is_submitted || _vm.getIsCurrentStage(stage),
          'v-expansion-panel__container--outline': true
        },staticStyle:{"width":"100%"},attrs:{"lazy":""},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('VExpansionPanelHeader',{staticClass:"py-1",class:{
            'white--text': stage.is_submitted || stage.is_complete
          },on:{"click":function($event){$event.preventDefault();}}},[_c('VRow',{key:key,attrs:{"row":"","wrap":"","align-center":""}},[_c('VCol',{attrs:{"grow":""}},[_c('h2',{staticClass:"truncate-text text-no-wrap",staticStyle:{"max-width":"75vw"}},[_vm._v(" "+_vm._s((stage.stage || {}).title || `${_vm.featureName('Stage')} ${_vm.realStageKey(stage)+1}`)+" ")]),_c('VSwitch',{attrs:{"inset":"","persistent-hint":"","dark":stage.is_submitted || stage.is_complete || _vm.getIsCurrentStage(stage),"label":`Is this ${_vm.featureName('Stage', 'singularize').toLowerCase()} complete?`,"hint":`Marking this ${_vm.featureName('Stage', 'singularize').toLowerCase()} complete allows the user to only view, not edit the ${_vm.featureName('Stage', 'singularize').toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(stage.is_complete),callback:function ($$v) {_vm.$set(stage, "is_complete", $$v)},expression:"stage.is_complete"}}),_c('VSwitch',{attrs:{"inset":"","persistent-hint":"","dark":stage.is_submitted || stage.is_complete || _vm.getIsCurrentStage(stage),"label":`Is this ${_vm.featureName('Stage', 'singularize').toLowerCase()} submitted?`,"hint":`Marking this ${_vm.featureName('Stage', 'singularize').toLowerCase()} as submitted`},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(stage.is_submitted),callback:function ($$v) {_vm.$set(stage, "is_submitted", $$v)},expression:"stage.is_submitted"}})],1),(_vm.hasPipelineStageStatuses)?_c('VCol',{attrs:{"align-items-end":""}},[_c('Autocomplete',{class:{
                  'mt-6 mr-2': true
                },attrs:{"filled":"","item-text":"title","item-value":"id","append-inner-icon":"arrow-down","dark":stage.is_submitted || stage.is_complete || _vm.getIsCurrentStage(stage),"items":_vm.statusOptions,"placeholder":`Select ${_vm.featureName('Stage', 'singularize').toLowerCase()} status`,"hint":`Use a custom ${_vm.featureName('Stage', 'singularize').toLowerCase()} status for your internal needs`,"label":`${_vm.featureName('Stage')} Status`},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)},"input":value => _vm.$emit('input:stage-proeprty', `${_vm.realStageKey(stage)}.status`, value)},model:{value:(stage.status),callback:function ($$v) {_vm.$set(stage, "status", $$v)},expression:"stage.status"}})],1):_vm._e()],1)],1),_c('VExpansionPanelContent',{staticClass:"px-0 pt-0"},[_c('VCard',{staticClass:"mb-2 mt-0 grey lighten-3"},[_c('VCardText',[(stage.description)?_c('p',{domProps:{"innerHTML":_vm._s(stage.description)}}):_vm._e(),_c('DynamicInputsSubmit',{attrs:{"enable-default-values":"","card":false,"input-name":`${_vm.featureName('Pipeline')} ${_vm.featureName('Stage', 'singularize')}`,"validator":_vm.$v[_vm.realStageKey(stage)]},on:{"update:input":(k, v) => _vm.$emit('input:metadata', _vm.realStageKey(stage), k, v)},model:{value:(stage.metadata),callback:function ($$v) {_vm.$set(stage, "metadata", $$v)},expression:"stage.metadata"}})],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }