<template>
  <Dialog
    :dismissable="false"
    :max-width="maxWidth"
    :min-height="minHeight"
    :is-dialog-open="isDialogOpen"
    :hide-overlay="dialogHideOverlay"
    @toggle:dialog="$emit('toggle:dialog')"
    :style="{
      zIndex: 100000000,
      minHeight: minHeight
    }"
    fill-height
    card-text-class="py-0"
  >
    <Messages
      mode="dialog"
      ensure-thread
      dismissable
      :title="title"
      :thread="realThread"
      :enable-edit="enableEdit"
      :entity="{
        title: title,
        entity_id: (entity || {}).id,
        entity_type: entityType,
        user_id: (user || $user).id,
        group: {
          members: this.members
        }
      }"
      @toggle:dialog="$emit('toggle:dialog')"
      @newThread="thread => $emit('newThread', thread)"
    />
  </Dialog>
</template>
<script>
import Messages from "@/components/Chat/Messages";
export default {
  props: {
    user: {
      type: Object,
      default: () => null
    },
    members: {
      type: Array,
      default: () => []
    },
    entity: {
      type: Object,
      default: () => {}
    },
    entityType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    thread: {
      type: Object,
      default: () => {}
    },
    maxWidth: {
      type: String,
      default: "640px"
    },
    minHeight: {
      type: String,
      default: "640px"
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    dialogHideOverlay: {
      type: Boolean,
      default: false
    },
    enableEdit: {
      type: Boolean,
      default: true
    }
  },
  components: { Messages },
  computed: {
    realThread() {
      return this.thread && this.thread.id
        ? this.thread
        : {
            thread_id: `${this.entityType}-${(this.entity || {}).id}`,
            group: {
              members: this.members
            }
          };
    }
  }
};
</script>