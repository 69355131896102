import api from '../../services/reporting';
import Vue from 'vue';
export default function (helper) {
  const TYPES = {
    LIST_CHART_TYPES: "LIST_CHART_TYPES",
    SET_REPORTING_FILTERS: "SET_REPORTING_FILTERS"
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('reporting');
  const state = helper.state({
    categories: {},
    filters: {}
  });
  const getters = helper.getters({
    getCategories: state => state.categories,
    allData: state => state.all,
    filters: state => state.filters
  });
  const actions = helper.actions({
    listChartTypes(context) {
      helper.log().info("[Store: Reporting]: List Chart Types");
      return helper
        .api()
        .listChartTypes()
        .then((response) => {
          helper
            .log()
            .info(`[Store: Reporting]: List Chart Types`, response);
          const data = response.data.data;
          context.commit(TYPES.LIST_CHART_TYPES, data);
          return data;
        })
        .catch((error) => {
          helper
            .log()
            .info(`[Store: Report]: Error List Chart Types`, error);
          throw error;
        });
    },
    /**
     *
     * @param context
     * @param params
     * @return {*}
     */
    getFilterOptions(context, params) {
      helper.log().info("[Store: Reporting]: List Options Values");
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .getFilterOptions(params)
          .then((response) => {
            Vue.$log.debug(
              `[Store: Reporting]: Get filter options`,
              response.data
            );
            resolve(response.data.data);
          })
          .catch((error) => {
            Vue.$log.debug(
              `[Store: Reporting]: Error getting all`,
              error
            );
            reject(error);
          });
      });
    },
    setFilters(context, filters) {
      context.commit(TYPES.SET_REPORTING_FILTERS, filters);
    },
  }, 'Reporting');
  const mutations = helper.mutations(
    {
      [TYPES.LIST_CHART_TYPES](state, data) {
        state.all = data;
        state.data = data;
        let categories = {};
        data.forEach((item) => {
          if (!categories.hasOwnProperty(item.category_name)) {
            categories[item.category_name] = [];
          }
          categories[item.category_name].push(item);
        });
        state.categories = categories;
      },
      [TYPES.SET_REPORTING_FILTERS](state, filters) {
        state.filters = filters;
      }
    },
    types,
    state
  );

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}