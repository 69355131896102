<template>
  <VSlideGroup
    v-if="hasMembers"
    class="member-container"
    multiple
  >
    <VSlideItem
      v-for="(member, key) in allMembers"
      :key="key"
    >
      <UserChip :user="member">
        <template #default="{on :menu}">
          <VTooltip
            top
            small
            :as-button="false"
            :title="member.name"
          >
            <template #activator="{on: tooltip}">
              <VAvatar
                v-if="member.photo_url"
                v-on="({...menu, ...tooltip})"
                size="28"
                class="member-member"
              >
                <img
                  :src="member.photo_url"
                  :alt="member.name ? member.name[0] : ''"
                >
              </VAvatar>
              <VAvatar
                v-else
                v-on="({...menu, ...tooltip})"
                size="28"
                color="primary"
                class="member-member"
              >
                <span>{{ member.name ? member.name[0] : '' }}</span>
              </VAvatar>
            </template>
            <span v-if="member.id === $user.id">You</span>
            <span v-else>{{ member.name ? member.name : '' }}</span>
          </VTooltip>
        </template>
      </UserChip>
    </VSlideItem>
  </VSlideGroup>
</template>

<script>
export default {
  name: "Members",
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    itemClass: function () {
      let itemClass = "dialog dialog--";
      if (this.currentThread) {
        let isCurrent = this.thread.id == this.currentThread.id;
        return itemClass + (isCurrent ? "not-read" : "read");
      } else {
        return itemClass + "read";
      }
    },
    hasMembers() {
      return this.members && this.members.length > 0;
    },
    allMembers() {
      return this.hasMembers ? this.members || [] : [];
    },
  },
  methods: {
    imageExists(url) {
      try {
        var http = new XMLHttpRequest();

        http.open("HEAD", url, false);
        http.send();

        return http.status != 404;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.member-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 16px;
    flex: 0 1 16px;
  }
  .v-avatar img::before {
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>