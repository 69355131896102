import Vue from "vue";
import BaseApi from "./_base-api";
import utils from "./utils";

/**
 * Calendar API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	getThreads(params) {
		this.log().info("Chat Api: Getting Threads", params);
		return this.api()
			.get("/api/chat/thread", {
				params: params
			})
			.catch(error => {
				this.log().error("[Services: Chat: GetThreads]:", error);
				throw this.utils.resolveError(error);
			});
	}
	getThread(id) {
		this.log().info("Chat Api: Getting Thread", id);
		return this.api()
			.get("/api/chat/thread" + (id ? "/" + id : ""))
			.catch(error => {
				this.log().error("[Services: Chat: getThread]:", error);
				throw this.utils.resolveError(error);
			});
	}
	saveThread(params) {
		let { id } = params;
		const data = this.utils.createFormData(params);
		this.log().info("Chat Api: Saving Thread", data);
		return this.api()
			.post("/api/chat/thread" + (id ? "/" + id : ""), data, params.options)
			.catch(error => {
				this.log().error("[Services: Chat: SaveThread]:", error);
				throw this.utils.resolveError(error);
			});
	}
	deleteThread(params) {
		let { id } = params;
		this.log().info("Chat Api: Deleting Thread", id);
		return this.api()
			.delete("/api/chat/thread" + (id ? "/" + id : ""))
			.catch(error => {
				this.log().error("[Services: Chat: DeleteThread]:", error);
				throw this.utils.resolveError(error);
			});
	}
	saveGroupThread(params) {
		let { id } = params;
		const data = this.utils.createFormData(params);
		this.log().info("Chat Api: Saving Group Thread", data);
		return this.api()
			.post("/api/chat/thread" + (id ? "/" + id : ""), data, params.options)
			.catch(error => {
				this.log().error("[Services: Chat: SaveGroupThread]]:", error);
				throw this.utils.resolveError(error);
			});
	}
	getMessages(params) {
		this.log().info("Chat Api: Getting Messages", params);
		return this.api()
			.get("/api/chat/message", {
				params: params
			})
			.catch(error => {
				this.log().error("[Services: Chat: GetMessages]:", error);
				throw this.utils.resolveError(error);
			});
	}
	getMessage(id) {
		this.log().info("Chat Api: Getting Message", id);
		return this.api()
			.get("/api/chat/message/" + (id ? "/" + id : ""))
			.catch(error => {
				this.log().error("[Services: Chat: GetMessage]:", error);
				throw this.utils.resolveError(error);
			});
	}
	saveMessage(params) {
		let { id } = params;
		const data = this.utils.createFormData(params);
		this.log().info("Chat Api: Saving Message", data);
		return this.api()
			.post("/api/chat/message" + (id ? "/" + id : ""), data, params.options)
			.catch(error => {
				this.log().error("[Services: Chat: SaveMessage]:", error);
				throw this.utils.resolveError(error);
			});
	}
	joinPublicThread(thread) {
		let { id } = thread;
		this.log().info("Chat Api: Joining Public Group", thread);
		return this.api()
			.post(`/api/chat/thread/${id}/join`)
			.catch(error => {
				this.log().error("[Services: Chat: Error Joining Public Group]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Chat",
	path: "thread"
});

export default api;
