<template>
  <VContainer
    fill-height
    :class="{
      'view-program ma-0': true,
      'd-flex align-start': model.hasOwnProperty('id'),
    }"
  >
    <VRow v-if="model.hasOwnProperty('id')">
      <VCol
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="auto"
        style="position: fixed; left: 0; display: none;"
      >
        <VTabs
          vertical
          hide-slider
          class="sections"
          style="margin-top: 90px;"
        >
          <VTab
            v-for="(section, index) in tabs"
            :key="index"
            :to="{
              hash: section.url,
            }"
          >
            <VTooltip
              right
              color="#ffffff"
              content-class="tab-tooltip"
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  v-on="on"
                  width="40px"
                  min-width="40px"
                  height="40px"
                  style="border-radius: 10px;"
                  :color="activeUrlTab == index ? '#ffc100' : 'white'"
                >
                  <img
                    v-if="section.isImage"
                    :style="{
                      width: '24px',
                      height: '24px',
                      objectFit: 'contain',
                    }"
                    :src="
                      activeUrlTab == index ? section.activeIcon : section.icon
                    "
                  >
                  <VIcon
                    v-else
                    :color="activeUrlTab == index ? 'white' : 'black'"
                  >
                    {{ section.icon }}
                  </VIcon>
                </VBtn>
              </template>
              <span
                class="black--text"
                style="font-size: 13px; font-weight: 500;"
              >
                {{ section.title }}
              </span>
            </VTooltip>
          </VTab>
        </VTabs>
      </VCol>
      <VCol cols="12">
        <VRow class="align-center py-2">
          <VCol
            cols="auto"
            class="px-0"
          >
            <VAvatar
              size="65"
              :color="
                model.background_color ? model.background_color : 'primary'
              "
            >
              <span
                v-if="model.emoji"
                style="font-size: 32px;"
              >
                {{ model.emoji }}
              </span>
              <b
                v-else
                class="white--text"
                style="font-size: 24px;"
              >
                {{ model.title.charAt(0).capitalize() }}
              </b>
            </VAvatar>
          </VCol>
          <VCol
            cols="auto"
            :style="{
              'max-width': $vuetify.breakpoint.smAndDown ? '64%' : '50%',
            }"
          >
            <VListItemTitle
              :style="{
                'line-height': '1.4',
                'font-weight': 900,
                'font-size': $vuetify.breakpoint.smAndDown ? '18px' : '32px',
              }"
            >
              {{ model.title }}
            </VListItemTitle>
            <VListItemSubtitle
              v-if="model.city_id && model.city"
              style="color: #808080; font-size: 16px;"
            >
              <VIcon size="14px">
                mdi-map-marker
              </VIcon>
              {{ model.city.name.trim() }}, {{ model.city.parent.name }}
            </VListItemSubtitle>
          </VCol>
          <VCol
            cols="auto"
            class="pb-4"
          >
            <template v-if="$isUserAdmin && $viewProgramAsAdmin">
              <VTooltip top>
                <template #activator="{ on }">
                  <VBtn
                    v-on="on"
                    height="40px"
                    width="40px"
                    min-width="40px"
                    color="#3c9cff61"
                    style="border-radius: 10px; box-shadow: none;"
                    @click="onSetFormDialogModel(model)"
                  >
                    <VIcon
                      color="blue"
                      size="30px"
                    >
                      mdi-square-edit-outline
                    </VIcon>
                  </VBtn>
                </template>
                Edit {{ featureName(modelType.capitalize(), 'singularize') }}
              </VTooltip>
            </template>
            <VMenu
              v-if="validSections.length"
              bottom
              left
              offset-y
              close-on-content-click
              nudge-right="12"
              nudge-bottom="4"
              @input="(status) => (isAddIcon = !status)"
            >
              <template #activator="{ on: onMenu, attrs }">
                <VTooltip top>
                  <template #activator="{ on: onTooltip }">
                    <VBtn
                      v-bind="attrs"
                      v-on="{
                        ...onMenu,
                        ...onTooltip,
                      }"
                      width="40px"
                      height="40px"
                      min-width="40px"
                      class="wethrive ml-4"
                      style="border-radius: 10px;"
                    >
                      <VIcon
                        color="white"
                        size="30px"
                      >
                        {{ isAddIcon ? "mdi-plus" : "mdi-close" }}
                      </VIcon>
                    </VBtn>
                  </template>
                  Add Content
                </VTooltip>
              </template>
              <VList
                style="background-color: #ffffff; border: 1px solid #efefef; border-radius: 10px;"
              >
                <VListItem
                  v-for="(item, index) in validSections"
                  :key="index"
                  class="pb-2"
                >
                  <VListItemAction style="display: contents;">
                    <b style="font-size: 16px; text-align: right; color: #252526;">{{
                      item.title
                    }}</b>
                    <VSpacer />
                    <VBtn
                      fab
                      height="35px"
                      width="35px"
                      class="wethrive ml-8"
                      @click="onShowCreateDialog(item.name.singularize())"
                    >
                      <VIcon size="26px">
                        mdi-plus
                      </VIcon>
                    </VBtn>
                  </VListItemAction>
                </VListItem>
              </VList>
            </VMenu>
            <VTooltip top>
              <template #activator="{ on, attrs }">
                <VHover>
                  <BetaBadge :is-beta="isBetaFeature('monetization')">
                    <VBtn
                      v-if="$isUserAdmin && $viewProgramAsAdmin && isEnabledFeature('monetization')"
                      v-bind="attrs"
                      v-on="on"
                      height="40px"
                      width="40px"
                      min-width="40px"
                      color="#0080003b"
                      style="border-radius: 10px; box-shadow: none;"
                      class="ml-4"
                      @click="onToggleFormDialog('monetize')"
                    >
                      <img
                        src="../../../images/monetize.png"
                        width="24"
                        height="24"
                      >
                    </VBtn>
                  </BetaBadge>
                </VHover>
              </template>
              Monetize
            </VTooltip>
          </VCol>
          <VSpacer />
          <VCol
            v-if="$isUserAdmin"
            cols="auto"
            class="pb-4 px-0"
          >
            <BetaBadge
              offset-y="30%"
              :is-beta="isBetaFeature('program_pipelines')"
            >
              <VMenu
                bottom
                right
                offset-y
                close-on-content-click
                nudge-bottom="4"
              >
                <template #activator="{ on, attrs }">
                  <VBtn
                    v-bind="attrs"
                    v-on="on"
                    plain
                    height="40px"
                    id="view-as-menu"
                    class="pr-4 pl-3"
                  >
                    <span
                      class="font-weight-medium"
                      style="color: #3C9CFF; font-size: 14px;"
                    >
                      {{ $store.state.program.viewAs == "organization-admin" ? "Viewing as Admin" : `Previewing as ${featureName($store.state.program.viewAs, "capitalize")}` }}
                    </span>
                    <VIcon
                      color="#3C9CFF"
                      class="ml-2"
                    >
                      mdi-eye
                    </VIcon>
                  </VBtn>
                </template>
                <VList style="border-radius: 10px;">
                  <VRadioGroup
                    hide-details
                    class="ma-0"
                    :value="$store.state.program.viewAs"
                  >
                    <VListItem
                      v-for="(role, index) in validRoles($team.roles)"
                      link
                      class="px-2"
                      :key="index"
                      @click="onChangePreviewAs(role)"
                    >
                      <VListItemTitle class="d-flex align-center justify-end">
                        <span
                          class="font-weight-medium"
                          style="font-size: 14px;"
                        >
                          View as {{ role.value == "organization-admin" ? "Admin" : role.title }}
                        </span>
                        <VRadio
                          color="success"
                          class="custom-radio ml-2"
                          :value="role.value"
                        />
                      </VListItemTitle>
                    </VListItem>
                  </VRadioGroup>
                </VList>
              </VMenu>
            </BetaBadge>
          </VCol>
        </VRow>
        <VRow v-if="model.content">
          <VCol class="px-0 py-2">
            <p
              v-linkified
              v-html="model.content"
              class="formatted content"
              :style="{
                'line-height': 1.4,
                color: '#808080',
                'font-size': '16px',
              }"
            />
          </VCol>
        </VRow>
        <VRow class="py-4">
          <VCol
            cols="12"
            class="d-flex px-0"
          >
            <span class="label-text">
              {{ activeUrlTab > 0 ? tabs[activeUrlTab].title : tabs[1].title }}
            </span>
            <VSpacer />
            <TextField
              v-model="searchString"
              single-line
              hide-details
              append-icon="refresh"
              label="Search..."
              class="search-field shrink mr-2"
              :disabled="isLoading"
              @keyup.enter="loadData"
              @click:append="() => loadData(true)"
            />
            <VSelect
              v-model="sortBy"
              dense
              clearable
              outlined
              hide-details
              single-line
              label="Sort"
              item-text="title"
              item-value="value"
              class="search-field shrink mx-2"
              :items="prepareSort"
              @change="loadData(true)"
            />
            <VBtn
              depressed
              width="120px"
              height="38px"
              class="px-3 mx-2"
              style="border-radius: 10px; box-shadow: none; border: solid 1px rgba(0, 0, 0, 0.05);"
              :disabled="isLoading"
              @click="filterDrawer = !filterDrawer"
            >
              <span
                style="color: #808080; font-weight: 500; font-size: 14px;"
              >
                Filter by
              </span>
              <VSpacer />
              <VIcon
                small
                :color="hasFilter ? 'info' : 'default'"
              >
                filter_list
              </VIcon>
            </VBtn>
            <VTabs
              v-model="viewType"
              fixed-tabs
              height="38px"
              background-color="transparent"
              class="form-tabs toggle-view ml-2"
            >
              <VTab>
                Tiles
              </VTab>
              <VTab>
                List
              </VTab>
            </VTabs>
          </VCol>
          <VCol
            v-if="filterValues.length > 0"
            cols="12"
            class="pt-4"
          >
            <CurrentFilter
              ref="currentFilter"
              :filter="filter"
              :filter-values="filterValues"
              :is-loading="isLoading"
              @removeFilter="removeFilter"
              @removeStrictMode="onRemoveStrictMode"
              @resetFilter="onResetLocalFilter"
              @filterData="loadData(true)"
            />
          </VCol>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <VCol
              cols="12"
              class="pt-4 d-flex align-center px-0"
            >
              <VAppBarNavIcon @click.stop="drawer = !drawer" />
              <span class="label-text">
                {{ activeUrlTab > 0 ? tabs[activeUrlTab].title : tabs[1].title }}</span>
            </VCol>
            <VCol
              v-if="activeUrlTab > 1 && (($isUserAdmin && $viewProgramAsAdmin) || hasUserMentorForProgram(model))"
              cols="12"
              class="d-flex justify-end"
            >
              <ItemActions
                :section="tabs[activeUrlTab]"
                :model="model"
                @addSection="addSection(tabs[activeUrlTab])"
                @reorder="onShowReorderDialog(tabs[activeUrlTab].name)"
                @toggleAttach="onShowAttachDialog(tabs[activeUrlTab].name)"
                @renameFeature="onShowRenameDialog(tabs[activeUrlTab].name)"
              />
            </VCol>
          </template>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            class="px-0"
          >
            <VTabsItems
              :value="activeUrlTab"
              style="background-color: transparent;"
            >
              <VTabItem
                v-for="(section, index) in tabs"
                :key="index"
              >
                <VRow
                  v-if="
                    activeUrlTab && section.name != 'community' &&
                      (($isUserAdmin && $viewProgramAsAdmin) || hasUserMentorForProgram(model)) &&
                      $vuetify.breakpoint.mdAndUp
                  "
                >
                  <VCol class="d-flex">
                    <VSpacer />
                    <ItemActions
                      :section="section"
                      :model="model"
                      :attach="section.name != 'faqs'"
                      @addSection="addSection(tabs[activeUrlTab])"
                      @reorder="onShowReorderDialog(section.name)"
                      @toggleAttach="onShowAttachDialog(section.name)"
                      @renameFeature="onShowRenameDialog(section.name)"
                    />
                  </VCol>
                </VRow>
                <VSkeletonLoader
                  grid-list-lg
                  fluid
                  type="card, divider, card"
                  class="py-2"
                  :loading="isLoading"
                >
                  <component
                    :is="section.name"
                    :model="model"
                    :sections="tabs"
                    :active-tab="activeUrlTab"
                    :is-dashboard="activeUrlTab === 0"
                    :default-community-tab="communityTab"
                    :is-tiles-view="viewType === 0"
                    :sort-by="sortBy"
                    @addSection="addSection"
                    @showCreateDialog="onShowCreateDialog"
                    @showViewDialog="onShowViewDialog"
                    @showReorderDialog="onShowReorderDialog"
                    @showAttachDialog="onShowAttachDialog"
                    @showRenameDialog="onShowRenameDialog"
                    @toggleTab="(tab) => (activeUrlTab = tab)"
                  />
                </VSkeletonLoader>
              </VTabItem>
            </VTabsItems>
          </VCol>
        </VRow>
        <FormDialog
          new-actions
          max-width="570px"
          button-text="Save Changes"
          :can-delete="false"
          :current-model="model"
          :is-dialog-open="dialogs.form"
          :model-type="modelType"
          :title="model ? model.title : ''"
          @deleteItem="deleteItem"
          @toggle:form:dialog="onToggleFormDialog"
        >
          <template #before-title="{ props }">
            <VSkeletonLoader
              type="avatar"
              class="mr-2"
              :loading="props.isFormLoading"
            >
              <EmojiAvatar
                :item="model"
                @change="onChangeEmojiAvatar"
              />
            </VSkeletonLoader>
          </template>
          <Save
            ref="saveForm"
            mode="dialog"
            :id="model ? model.id : null"
            @toggle:form:dialog="onToggleFormDialog"
            @updateModel="onUpdateModel"
          />
        </FormDialog>
        <FormDialog
          new-actions
          model-type="monetize"
          max-width="780px"
          button-text="Save Changes"
          :can-delete="false"
          :hide-actions="hideMonetizeActions"
          :current-model="model.product"
          :hide-delete-button="model.product === null"
          :is-dialog-open="dialogs.monetize"
          :title="`Save Payment Plan: ${model.title}`"
          @toggle:form:dialog="onToggleFormDialog('monetize')"
          @deleteItem="(params) => $refs.monetize.onDeleteItem(params)"
        >
          <MonetizeAdmin
            ref="monetize"
            mode="dialog"
            :current-model="model"
            :id="model.product
              ? model.product.id
              : null"
            :use-id-from-route="false"
            @productAdded="(programId, product) => $set(model, 'product', product)"
            @productDeleted="$set(model, 'product', null)"
            @toggleActions="(hideActions) => (hideMonetizeActions = hideActions)"
            @toggleDialog="onToggleFormDialog('monetize')"
          />
        </FormDialog>
        <Confirm ref="confirmDelete" />
        <FormDialogs
          ref="formDialogs"
          :model="model"
          :options="options"
          :is-loading="activity.isLoading"
          @addedSectionItem="onAddedSection"
          @updatedSectionItem="onUpdatedSection"
          @updatedAssociation="onUpdatedAssociation"
        />
        <ReorderDialog
          from-program
          :is-dialog-open="dialogs.reorder"
          :model-type="reorderModelType"
          :get-items="getReorderItems"
          :reorder-action="(d) => onSaveReorder('lessons', d)"
          @toggle:dialog="dialogs.reorder = false"
        />
        <VNavigationDrawer
          v-model="filterDrawer"
          temporary
          clipped
          right
          fixed
          hide-overlay
          width="640px"
        >
          <FilterOptions
            v-model="filter"
            :filters="modelMeta.filters"
            @filter="loadData(true)"
            @toggle="filterDrawer = !filterDrawer"
          />
        </VNavigationDrawer>
      </VCol>
    </VRow>
    <VSkeletonLoader
      v-else
      grid-list-lg
      fluid
      type="card, divider, card"
      class="py-2"
    />
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import ViewMixin from "@/mixins/View";
import FormMixin from "@/mixins/Form";
import FilterOptions from "@/components/Elements/Data/FilterOptions";
import CurrentFilter from "@/components/Elements/Data/CurrentFilter";
import EmojiAvatar from "@/components/Elements/Data/EmojiAvatar";
import ReorderDialog from "@/components/Elements/Dialogs/ReorderDialog";
import Community from "./Community/index";
import Dashboard from "./Dashboard";
import Lessons from "./Lessons";
import Contents from "./Content";
import Deliverables from "./Deliverables";
import Pipelines from "./Pipelines";
import Faqs from "./FAQ";
import Save from "../Save";
import FormDialogs from "./FormDialogs";
import ItemActions from "./Actions";
import MonetizeAdmin from "../Monetize/Admin";

export default {
  name: "ProgramView",
  mixins: [ViewMixin, SearchMixin, FormMixin],
  data() {
    return {
      modelType: "program",
      communityTab: 0,
      drawer: false,
      searchString: null,
      sortBy: null,
      isLoading: false,
      autoloadViewModel: false,
      hideMonetizeActions: false,
      limitForDashboard: 6,
      canGetModel: false,
      localData: null,
      viewType: 0,
      reorderModelType: null,
      isAddIcon: true,
      dialogs: {
        reorder: false,
      },
      options: {
        deliverables: [],
        primary_contents: [],
        lessons: [],
        faqs: [],
      },
    };
  },
  components: {
    Dashboard,
    Community,
    Lessons,
    Contents,
    Deliverables,
    Pipelines,
    Faqs,
    Save,
    FilterOptions,
    CurrentFilter,
    FormDialogs,
    ReorderDialog,
    ItemActions,
    EmojiAvatar,
    MonetizeAdmin,
  },
  computed: {
    tabs() {
      return [
        {
          name: "dashboard",
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          url: "",
          isImage: false,
          enabled: true,
        },
        {
          name: "pipelines",
          title: this.featureName("Pipelines"),
          routeName: "pipeline.index",
          createRoute: "pipeline.create",
          icon: require("../../../images/application-black-icon@3x.png"),
          activeIcon: require("../../../images/application-white-icon@3x.png"),
          url: "pipelines",
          isImage: true,
          enabled: this.$isUserAdmin && this.$viewProgramAsAdmin,
        },
        {
          name: "community",
          title: "Community",
          icon: require("../../../images/user-black-icon.png"),
          activeIcon: require("../../../images/user-white-icon.png"),
          url: "community",
          isImage: true,
          enabled: true,
        },
        {
          name: "lessons",
          title: this.featureName("Lessons"),
          routeName: "lesson.index",
          createRoute: "lesson.create",
          icon: require("../../../images/lessons-black-icon.png"),
          activeIcon: require("../../../images/lessons-white-icon.png"),
          url: "lessons",
          isImage: true,
          enabled: true,
        },
        {
          name: "contents",
          title: this.featureName("Content"),
          routeName: "content.index",
          createRoute: "content.create",
          icon: require("../../../images/content-black-icon.png"),
          activeIcon: require("../../../images/content-white-icon.png"),
          url: "contents",
          isImage: true,
          enabled: true,
        },
        {
          name: "deliverables",
          title: this.featureName("Deliverables"),
          routeName: "deliverable.index",
          createRoute: "deliverable.create",
          icon: require("../../../images/forms-black-icon.png"),
          activeIcon: require("../../../images/forms-white-icon.png"),
          url: "deliverables",
          isImage: true,
          enabled: true,
        },
        {
          name: "faqs",
          title: this.featureName("FAQs"),
          routeName: "faq.index",
          createRoute: "faq.create",
          icon: require("../../../images/faq-black-icon.png"),
          activeIcon: require("../../../images/faq-white-icon.png"),
          url: "faqs",
          isImage: true,
          enabled: true,
        },
      ].filter(tab => tab.enabled === true);
    },
    prepareSort() {
      const sort = [];
      Object.keys(this.modelMeta.sort).map((key) => {
        sort.push({ title: this.modelMeta.sort[key], value: key });
      });
      return sort;
    },
    validSections() {
      return this.tabs.filter((tab) => {
        if(["dashboard", "community"].indexOf(tab.name) === -1) {
          if(this.$isUserAdmin && this.$viewProgramAsAdmin) {
            return tab;
          } else if(this.checkMentorPermission(tab.name.singularize(), this.model)) {
            return tab;
          }
        }
      });
    },
    isBetaTab() {
      let tabDetails =  this.tabs[this.activeUrlTab || 1];
      return this.isBetaFeature(`program_` + tabDetails.name);
    },
  },
  beforeRouteLeave (to, from, next) {
    if(this.$isUserAdmin) {
      this.$store.commit("program/updateViewAs", "organization-admin");
    }
    next();
  },
  created() {
    // Events emmitted from card menu
    this.$bus.$on("editSectionItem", this.onEditSectionItem);
    this.$bus.$on("renameSectionItem", this.onRenameSectionItem);
    this.$bus.$on("removeSectionItem", this.onRemoveSectionItem);
    this.$bus.$on("deleteSectionItem", this.onDeleteSectionItem);
    this.$bus.$on("notificationForDuplications", this.onNotificationForDuplications);
    this.$nextTick(() => {
      // dashboard hash added to support the new left navigation menu
      if(!this.$route.hash) {
        this.$router.push({
          name: "program.view",
          hash: "#dashboard",
          params: {
            id: this.$route.params.id,
            team: this.$team.slug,
          }
        });
      }
    });
  },
  beforeDestroy(){
    this.$bus.$off("editSectionItem");
    this.$bus.$off("renameSectionItem");
    this.$bus.$off("removeSectionItem");
    this.$bus.$off("deleteSectionItem");
    this.$bus.$off("notificationForDuplications");
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "program/getFormConfig",
      doGetProgram: "program/getOne",
      doSaveProgram: "program/save",
      doRemoveAttachments: "program/removeAssociatedItems",
      doDeleteProgram: "program/delete",
      doSaveSortRelation: "program/sortRelation",
      doGetAllLessons: "lesson/getAll",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    loadData(force) {
      this.filter.s = this.searchString;
      if (
        (this.filter.s && this.filter.s.length >= 2) ||
        (force === true && !this.isLoading)
      ) {
        let params = {
          id: this.model.id,
          sort: this.sortBy,
          forPreview: 1,
          ...this.filter,
        };
        if(this.$viewProgramAsAdmin) {
          params.forPreview = 1;
        } else {
          params.filter.visible_to = [this.$store.state.program.viewAs];
        }
        this.$log.debug("Load data params", params);
        this.isLoading = true;
        this.doGetProgram(params).then((result) => {
          this.$log.debug("Load data result", result);
          result.data = this.updatePendingDuplications(result.data);
          this.onUpdateModel(result.data);
          this.isLoading = false;
        });
      }
    },
    onGet(id) {
      let params = {
        id,
        forPreview: 1,
      };
      return this.doGetProgram(params).then((result) => {
        this.config.filters = result.meta.filters;
        result.data = this.updatePendingDuplications(result.data);
        this.localData = result.data;
        return result;
      });
    },
    updatePendingDuplications(program) {
      const pendingItems = this.getGroupItemLocalStorage("pending-duplications");
      const validGroups = ["program-lesson", "program-deliverable", "program-content", "program-faq"];
      Object.keys(pendingItems).map((itemGroupKey) => {
        if(validGroups.includes(itemGroupKey)) {
          const programPendingItems = Object.keys(pendingItems[itemGroupKey]).filter((itemKey) => itemKey.split("-")[0] == this.model.id);
          programPendingItems.map((itemKey) => {
            const itemId = itemKey.split("-")[1];
            const itemModule = itemGroupKey.split("-")[1];
            const realModule = (itemModule == "content" ? "primary_content" : itemModule).pluralize();
            const realItemIndex = program[realModule].findIndex((tempItem) => tempItem.pivot.program_relations_id == itemId);
            if(realItemIndex > -1) {
              this.removeGroupItemLocalStorage("pending-duplications", itemGroupKey, itemKey);
            } else {
              program[realModule].unshift(pendingItems[itemGroupKey][itemKey]);
            }
          });
        }
      });
      return program;
    },
    onNotificationForDuplications(moduleName, itemDetails) {
      const itemGroupName = "program-" + moduleName.singularize();
      const realModuleName = moduleName == "content" ? "primary_contents" : moduleName;
      const itemIndex = this.model[realModuleName].findIndex(item => item.identifier === itemDetails.identifier);
      this.model[realModuleName][itemIndex].id = itemDetails.model_id;
      this.model[realModuleName][itemIndex].processingDuplicate = false;
      this.removeGroupItemLocalStorage("pending-duplications", itemGroupName, itemDetails.identifier);
    },
    onDelete(program) {
      return this.doDeleteProgram(program.id);
    },
    onUpdateModel(program) {
      this.model = program;
    },
    onResetLocalFilter() {
      this.onResetFilter();
      this.onUpdateModel(this.localData);
    },
    removeFilter(filter) {
      this.onRemoveFilter(filter);
      this.loadData(true);
    },
    onShowCreateDialog(modelType) {
      if(["deliverable", "pipeline"].includes(modelType)) {
        this.$router.push({
          name: `${modelType}.create`,
          params: {
            team: this.$team.slug,
            programs: [this.model],
            isBackToDefault: true,
            defaultFeaturePath: `/${this.$team.slug}/app/programs/${this.model.id}`,
          },
        });
      } else {
        this.$refs.formDialogs.toggleCreateDialog(modelType);
      }
    },
    onShowViewDialog(modelType, item) {
      this.$refs.formDialogs.toggleViewDialog(modelType, item);
    },
    onShowAttachDialog(modelType) {
      this.$refs.formDialogs.toggleAttachDialog(modelType, this.model);
    },
    onShowRenameDialog(modelType) {
      if(modelType === "contents") {
        modelType = "content";
      } else if(modelType === "faqs") {
        modelType = "faq";
      }
      this.$refs.formDialogs.toggleRenameDialog(modelType, this.$team, true);
    },
    onShowReorderDialog(modelType) {
      this.reorderModelType = modelType;
      this.dialogs.reorder = true;
    },
    getReorderItems() {
      let params = {
        sort: "priority_relation",
        forPrioritization: 1,
        sort: "priority_relation",
        filter: {
          programs: [this.model.id],
        },
      };
      return this.doGetAllLessons(params);
    },
    async onSaveReorder(relationType, data) {
      let params = {
        contentType: this.modelType,
        id: this.model.id,
        relation: relationType,
      };
      params.items = data.map((item, i) => {
        return {
          id: item.id,
          priority: i + 1,
        };
      });
      await this.doSaveSortRelation(params).then((result) => {
        this.model[relationType] = result;
        this.$bus.$emit(
          "notificationMessage",
          `Successfully ${relationType.capitalize()} reordered`
        );
      });
      return this.model[relationType];
    },
    onAddedSection(modelType, data) {
      let section = modelType.pluralize();
      let sectionName = modelType == "content" ? "primary_contents" : section;
      let hasModelItem = this.model[sectionName].find((item) => item.id === data.id);
      if(hasModelItem) {
        this.model[sectionName] = this.model[sectionName].map((item) => {
          return item.id === data.id ? data : item;
        });
      } else {
        this.model[sectionName].push(data);
      }
    },
    onUpdatedSection(modelType, data) {
      let section = modelType.pluralize();
      let sectionName = modelType == "content" ? "primary_contents" : section;
      this.model[sectionName] = this.model[sectionName].map((item) => {
        return item.id === data.id ? data : item;
      });
    },
    onUpdatedAssociation(modelType, data) {
      let section = modelType.pluralize();
      let sectionName = modelType == "content" ? "primary_contents" : section;
      this.model[sectionName + "_count"] = data[sectionName + "_count"];
      if(data[sectionName].length > this.limitForDashboard && this.activeUrlTab === 0) {
        this.model[sectionName] = data[sectionName].slice(0, this.limitForDashboard);
      } else {
        this.model[sectionName] = data[sectionName];
      }
    },
    onDeleteSectionItem(modelType, data) {
      let section = modelType.pluralize();
      let sectionName = modelType == "content" ? "primary_contents" : section;
      this.model[sectionName] = this.model[sectionName].filter(
        (item) => item.id != data.id
      );
    },
    onEditSectionItem(modelType, item) {
      if(["deliverable", "pipeline"].includes(modelType.toLowerCase())) {
        this.$router.push({
          name: `${modelType.toLowerCase()}.update`,
          params: {
            id: item.id,
            team: this.$team.slug,
            isBackToDefault: true,
            defaultFeaturePath: `/${this.$team.slug}/app/programs/${this.model.id}`,
          },
        });
      } else {
        this.$refs.formDialogs.toggleEditDialog(modelType, item);
      }
    },
    onRenameSectionItem(modelType, item) {
      this.$refs.formDialogs.toggleRenameDialog(modelType, item);
    },
    onRemoveSectionItem(modelType, data) {
      let section = modelType.pluralize();
      let sectionName = modelType == "content" ? "primaryContents" : section;
      let params = {
        program_id: this.model.id,
        relation_type: sectionName,
        relation_ids: [data.id],
      };
      this.doRemoveAttachments(params).then((result) => {
        // Refresh the items
        this.onUpdatedAssociation(modelType, result);
        this.$bus.$emit("notificationMessage", `${this.featureName(modelType).capitalize()} removed from this ${this.featureName("Program").singularize()} successfully!`);
      });
    },
    addSection(item) {
      const section = item.name.singularize();
      this.onShowCreateDialog(section);
    },
    deleteItem(params) {
      this.onDeleteItem(params).then((result) => {
        if (result) {
          this.$router.push({
            name: "program.index",
            params: { team: this.$team.slug },
          });
        }
      });
    },
    onBack() {
      if(this.activeUrlTab > 0) {
        this.activeUrlTab = "";
      } else {
        this.$router.push({
          name: 'program.index',
          params: { team: this.$team.slug },
        })
      }
    },
    onChangeEmojiAvatar(emoji, color) {
      this.$refs.saveForm.form.background_color = color;
      this.$refs.saveForm.form.emoji = emoji;
    },
    onChangePreviewAs(role) {
      this.$store.commit('program/updateViewAs', role.value);
      if(this.activeUrlTab === 0) {
        let params = {
          id: this.model.id,
        };
        if(this.$viewProgramAsAdmin) {
          params.forPreview = 1;
        } else {
          params.filter = {
            visible_to: [role.value],
          };
        }
        this.isLoading = true;
        this.doGetProgram(params).then((result) => {
          if(!this.$viewProgramAsAdmin) {
            ["lessons", "deliverables", "primary_contents", "faqs"].forEach(feature => {
              if(feature == "lessons") {
                let tempLessons = result.data.lessons.filter((lesson) => !lesson.is_locked && lesson.is_active && this.hasItemValidVisibility(lesson));
                if(this.$viewProgramAsMentor) {
                  result.data.lessons = tempLessons.slice(0, this.limitForDashboard);
                } else {
                  result.data.lessons = tempLessons;
                }
                result.data.lessons_count = tempLessons.length;
              } else {
                let tempData = result.data[feature].filter((item) => this.hasItemValidVisibility(item));
                result.data[feature] = tempData.slice(0, this.limitForDashboard);
                result.data[`${feature}_count`] = tempData.length;
              }
            });
          }
          this.onUpdateModel(result.data);
          this.isLoading = false;
        });
      }
    },
    isItemHidden(item) {
      let visibility = item.pivot.visible_to ? JSON.parse(item.pivot.visible_to) : [];
      if (Array.isArray(visibility) && visibility.length === 0) {
        return true;
      } else {
        let roles = Object.keys(visibility);
        return roles.length === 0 || (roles.length === 1 && roles[0] === "organization-admin");
      }
    },
    hasItemValidVisibility(item) {
      if(!this.isItemHidden(item)) {
        let visibility = Object.keys(JSON.parse(item.pivot.visible_to));
        return visibility.includes(this.$store.state.program.viewAs);
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.view-program {
  min-width: 100%;
  .v-card .v-card__title {
    height: auto;
    flex: 0 0 auto;
  }
  p {
    margin-bottom: 0px !important;
  }
  .search-field {
    .v-input__slot {
      min-height: 38px !important;
    }
    .v-label {
      color: #808080 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }
  .sections {
    .v-tab {
      padding: 0px;
      justify-content: left;
      min-width: auto;
      height: 55px !important;
    }
    .v-tabs-bar,
    .v-tabs-bar__content {
      background-color: transparent !important;
    }
  }
  .add-btn {
    min-width: 40px;
    border-radius: 16px !important;
    bottom: 25px;
    box-shadow: 0 3px 8px 0 rgba(47, 53, 63, 0.2) !important;
  }
  .label-text {
    font-size: 22px;
    font-weight: 900;
    color: #252526;
    word-break: normal;
  }
  .section-card-title {
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.35;
  }
  .toggle-view {
    .v-tab {
      min-width: auto !important;
    }
    .v-tabs-bar {
      padding: 0px !important;
    }
    .v-tabs-bar__content {
      margin-top: 0px !important;
      height: auto !important;
    }
  }
  .md-tab {
    .v-tab {
      padding: 0 10px !important;
    }
  }
  #view-as-menu {
    border-radius: 10px; 
    background-color: rgba(60, 156, 255, 0.20); 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    .v-btn__content {
      opacity: 1;
    }
  }
}
.tab-tooltip {
  box-shadow: 0 1.5px 3px 0 rgba(241, 156, 156, 0.12);
  border-radius: 10px;
  opacity: 1 !important;
}
.extra-padding {
  padding-left: 90px !important;
  padding-right: 65px !important;
}
</style>
