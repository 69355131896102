import BaseApi from "./_base-api";

/**
 * Product API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	createCheckoutSession(params) {
		this.log().info("[Services: Product]: Create Checkout Session", params);
		return this.api()
			.post(this.getUrl("checkout-session", `${params.metadata.product_id}/checkout-session`), params)
			.catch((error) => {
				this.log().error(
					"[Services: Product: Create Checkout Session]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Product",
	path: "products"
});

export default api;
