<template>
  <VCard
    light
    class="fill-height d-flex elevation-0 slightly-rounded"
    :style="{
      borderRadius: '5px !important'
    }"
  >
    <VCardTitle
      :style="{
        height: '64px',
        position: 'absolute',
        top: 0,
        left: '8px',
        right: '8px',
        borderBottom: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
      }"
    >
      <VRow
        row
        wrap
        align-center
      >
        <VSpacer />
        <VCol
          shrink
          pr-0
        >
          <VBtn
            text
            icon
            color="gray"
            @click="navigateTo({
              name: 'lesson.index'
            })"
          >
            <VIcon color="gray">
              close
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText
      :class="{
        'fill-height': true,
        'px-0': $vuetify.breakpoint.smAndDown,
        'pt-0': $vuetify.breakpoint.smAndDown,
        'flex xs12 align-self-center justify-self-center' : true
      }"
    >
      <VRow
        align-center
        justify-center
        row
        wrap
      >
        <VCol class="px-2 pa-0">
          <VRow
            fill-height
            align-center
            justify-center
            space-around
            class="pr-2"
          >
            <VCol
              cols="12"
              class="text-sm-center py-12"
            >
              <p
                class="display-1"
                :style="{
                  textTransform: 'uppercase',
                  fontWeight: 700
                }"
              >
                No slides/ cards available!
              </p>
            </VCol>
            <VCol class="text-sm-center">
              <VBtn
                class="px-12"
                color="primary"
                @click="navigateTo({
                  name: 'lesson.index'
                })"
              >
                Back
              </VBtn>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  name: "BlankCard"
}
</script>