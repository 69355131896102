<template>
  <VRow
    id="team-settings"
    fill-height
    row
    wrap
    justify-center
    :class="{
      'is-loading': activity.isLoading
    }"
  >
    <VCol cols="12">
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
      >
        <template #title>
          <h4>Workspace Settings</h4>
        </template>
        <template #create-action>
          <ExpandableButton
            large
            button-class="wethrive mr-4"
            text="Share"
            icon="share"
            :action="() => onToggleViewDialog('share')"
          />
          <ExpandableButton
            button-class="wethrive-alt"
            large
            :disabled="!isFormReady"
            :action="() => onSave()"
            text="Save Changes"
            icon="save"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      v-if="needsToComplete"
      cols="12"
    >
      <VAlert
        :value="true"
        type="warning"
      >
        Please complete the setup of your organization!
      </VAlert>
    </VCol>
    <VCol
      cols="12"
      v-if="hasMessagesOrErrors"
    >
      <Alerts
        :messages="messages"
        :errors="errors"
      />
    </VCol>
    <VCol
      cols="12"
      style="overflow:auto"
    >
      <VRow
        justify-space-between
        row
        wrap
      >
        <VCol
          align-center
          cols="12"
        >
          <VTabs
            lazy
            show-arrows
            slider-color="primary"
            class="d-none"
            :grow="$vuetify.breakpoint.smAndDown"
            :vertical="$vuetify.breakpoint.mdAndUp"
          >
            <VTab
              v-for="(tab, key) in enabledTabs"
              :key="key"
              :to="{
                hash: tab.url
              }"
              lazy
            >
              {{ tab.title }}
            </VTab>
          </VTabs>
          <VTabsItems :value="activeUrlTab">
            <VTabItem>
              <VCard class="mb-2">
                <VCardTitle>Logo</VCardTitle>
                <VCardText>
                  <VRow
                    row
                    align-center
                  >
                    <VCol shrink>
                      <VImg
                        v-if="form.photo_url"
                        contain
                        height="72"
                        :src="form.photo_url"
                      />
                    </VCol>
                    <VCol grow>
                      <VBtn
                        id="pick-avatar"
                        slot="badge"
                        text
                        color="default"
                      >
                        Change
                      </VBtn>
                      <AvatarCropper
                        ref="avatar"
                        trigger="#pick-avatar"
                        :cropper-options="{
                          aspectRatio: -1,
                          movable: true,
                          zoomable: true
                        }"
                        :upload-handler="saveAvatar"
                        :labels="{submit: 'Save', cancel: 'Cancel'}"
                      />
                    </VCol>
                  </VRow>
                </VCardText>
              </VCard>
              <VCard class="mt-2">
                <VCardTitle>Details</VCardTitle>
                <VCardText class="details-content">
                  <TextField
                    v-model="form.name"
                    name="name"
                    label="Workplace"
                    type="text"
                    :placeholder="form.name"
                    :error="$v.form.name.$error"
                    @input="$v.form.name.$touch()"
                    @blur="$v.$touch()"
                  />
                  <TextField
                    v-model="form.slug"
                    name="slug"
                    label="Team Url"
                    placeholder="name"
                    type="text"
                    class="url-input"
                    append-icon="file_copy"
                    @click:append="() => copyTextToClipboard(`${$root.env.VUE_APP_APP_URL}/${form.slug}`)"
                  >
                    <template #prepend-inner>
                      <small><b>{{ $root.env.VUE_APP_APP_URL }}/</b></small>
                    </template>
                  </TextField>
                  <TextField
                    v-model="form.website"
                    name="website"
                    label="Website"
                    type="url"
                    :placeholder="form.website"
                    :error="$v.form.website.$error"
                    @input="$v.form.website.$touch()"
                    @blur="$v.$touch()"
                  />
                  <TextField
                    v-model="form.email"
                    name="email"
                    label="Email"
                    type="email"
                    :placeholder="form.email"
                    :error="$v.form.email.$error"
                    @input="$v.form.email.$touch()"
                    @blur="$v.$touch()"
                  />
                </VCardText>
              </VCard>
            </VTabItem>
            <VTabItem>
              <VCard
                class="mb-2"
                v-if="form.profile"
              >
                <VCardTitle>Profile</VCardTitle>
                <VCardText>
                  <GeoSelectCity
                    v-if="isFormReady && hasFormConfigOption('countries') && options.countries.length"
                    :countries="options.countries"
                    :current-city="form.city"
                    :current-city-id="form.city_id"
                    @citySelected="(id) => form.city_id = id"
                    @removeCity="form.city = form.city_id = null"
                  />
                  <LongTextField
                    v-model="form.profile.tagline"
                    placeholder="Enter your company tagline"
                    label="Organization tag line"
                  />
                  <Editor
                    v-model="form.profile.bio"
                    placeholder="Tell us a little about the organization. This would be shown to users on the registration page and on the team profile page"
                    label="About the organization"
                  />
                </VCardText>
              </VCard>
            </VTabItem>
            <VTabItem>
              <VCard>
                <VCardTitle>
                  <span>Policies and agreements</span>
                  <VSpacer />
                  <Tooltip
                    icon-button
                    :block="false"
                    button-class="wethrive"
                    icon="add"
                    title="Add agreement or policy"
                    :action="addAgreement"
                  />
                </VCardTitle>
                <VCardText v-if="form.register_agreement_message">
                  <VExpansionPanels
                    multiple
                    focusable
                    class="elevation-0"
                  >
                    <VExpansionPanel
                      class="pb-2"
                      lazy
                    >
                      <VExpansionPanelHeader>
                        <span>Optional information</span>
                      </VExpansionPanelHeader>
                      <template #actions>
                        <VIcon color="info">
                          settings
                        </VIcon>
                      </template>
                      <VExpansionPanelContent class="px-4">
                        <Editor
                          v-model="form.register_agreement_message.value"
                          persistent-hint
                          label="Message"
                          hint="Enter a custom agreement message you would like applicants to see when agreeing to your agreements."
                          :placeholder="agreementsLabel"
                          @input="onUpdateMetadata(dialogs.agreements.index, dialogs.agreements.model, 'agreements')"
                        />
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>
                </VCardText>
                <VCardText>
                  <VAlert
                    :value="true"
                    type="info"
                  >
                    Your very own policies, terms and conditions or otherwise that you want every user to agree to upon entering the workspace.
                  </VAlert>
                </VCardText>
                <VCardText>
                  <VList>
                    <VListItem
                      v-for="(agreement, index) in (form.agreements || [])"
                      :key="`agreement-${index}`"
                    >
                      <VListItemContent>
                        <VListItemTitle>{{ agreement.name || 'No title set yet...' }}</VListItemTitle>
                      </VListItemContent>
                      <VListItemAction
                        class="d-inline-block align-center"
                        style="min-width: 48px"
                      >
                        <Menu v-if="!agreement.deleted">
                          <VListItem
                            v-if="$isUserAdmin"
                            @click="onToggleAgreementEdit(agreement, index)"
                          >
                            <VListItemTitle>
                              <VIcon small>
                                edit
                              </VIcon>&nbsp;Edit
                            </VListItemTitle>
                          </VListItem>
                          <VListItem @click="onToggleAgreementView(agreement, index)">
                            <VListItemTitle>
                              <VIcon small>
                                visibility
                              </VIcon>&nbsp;View
                            </VListItemTitle>
                          </VListItem>
                          <VListItem @click="onRemoveMetadata(agreement, 'agreements')">
                            <VListItemTitle color="error">
                              <VIcon
                                small
                                color="error"
                              >
                                delete
                              </VIcon>&nbsp;Delete
                            </VListItemTitle>
                          </VListItem>
                        </Menu>
                        <VBtn
                          v-else
                          fab
                          small
                          @click="onRemoveMetadata(agreement, 'agreements')"
                        >
                          <VIcon>{{ agreement.deleted ? 'refresh' : 'delete' }}</VIcon>
                        </VBtn>
                      </VListItemAction>
                    </VListItem>
                  </VList>
                </VCardText>
              </VCard>
            </VTabItem>
            <VTabItem>
              <VCard
                class="mb-2"
                v-if="form.profile"
              >
                <VCardTitle>Support Details</VCardTitle>
                <VCardText>
                  <TextField
                    v-model="form.profile.support_name"
                    placeholder="(Optional) Enter the support person or department"
                    label="Support name/department"
                  />
                  <VRow
                    row
                    wrap
                  >
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <TextField
                        slot="append"
                        v-model="form.profile.support_email"
                        type="email"
                        placeholder="(Optional) Enter the main support email"
                        label="Support email"
                      />
                    </VCol>
                    <VCol
                      cols="12"
                      md="6"
                    >
                      <TextField
                        v-model="form.profile.support_number"
                        type="tel"
                        mask="1 (###) - ### - ####"
                        placeholder="(Optional) Enter the main support number"
                        label="Support number"
                      />
                    </VCol>
                  </VRow>
                  <LongTextField
                    v-model="form.profile.support_address"
                    placeholder="(Optional) Enter your support address"
                    label="Support address"
                  />
                  <Editor
                    v-model="form.profile.support_details"
                    placeholder="(Optional) Describe your support practices and details"
                    label="Support instructions"
                  />
                </VCardText>
              </VCard>
            </VTabItem>
            <VTabItem>
              <Workspace
                ref="workspace"
                :is-standalone="false"
                @updated="(data) => updateForm(data, 'workspace')"
              />
            </VTabItem>
            <VTabItem>
              <h2 class="subtitle">
                Toggle certain features on or off
              </h2>
              <template v-for="(group, groupTitle) in toggleableFeatures">
                <VCard :key="`features-${groupTitle}`">
                  <VCardTitle>{{ getAppropriateGroupTitle(groupTitle) }}</VCardTitle>
                  <VCardText>
                    <VList two-line>
                      <VListItem
                        v-for="(feature, featureIndex) in group"
                        :key="`feature-${featureIndex}`"
                      >
                        <VListItemContent>
                          <VListItemTitle>{{ feature.title || feature.description }}</VListItemTitle>
                          <VListItemSubtitle v-if="feature.description && feature.description != feature.title">
                            {{ feature.description }}
                          </VListItemSubtitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VSwitch
                            v-model="form.settings[feature.group][featureIndex].value"
                            inset
                            color="success"
                          />
                        </VListItemAction>
                      </VListItem>
                      <VListItem v-if="['mentor', 'student'].includes(groupTitle.toLowerCase())">
                        <VListItemContent>
                          <VListItemTitle>
                            {{ getRegistrationTitle(groupTitle.toLowerCase()) }}
                          </VListItemTitle>
                          <VListItemSubtitle 
                            v-if="getRegistrationDescription(groupTitle.toLowerCase())
                              && getRegistrationDescription(groupTitle.toLowerCase()) != getRegistrationTitle(groupTitle.toLowerCase())"
                          >
                            {{ getRegistrationDescription(groupTitle.toLowerCase()) }}
                          </VListItemSubtitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VSwitch
                            v-if="form.settings.registration[groupTitle.toLowerCase() + '_on_register']"
                            v-model="form.settings.registration[groupTitle.toLowerCase() + '_on_register'].value"
                            inset
                            color="success"
                          />
                          <VSwitch
                            v-else
                            v-model="form.settings.registration[`approve_${groupTitle.toLowerCase()}_on_register`].value"
                            inset
                            color="success"
                          />
                        </VListItemAction>
                      </VListItem>
                    </VList>
                  </VCardText>
                </VCard>
              </template>
            </VTabItem>
            <!-- <VTabItem>
              <Registration
                ref="registration"
                :user-form="form"
                :is-standalone="false"
                :form-config="formConfig"
                @updated="(data) => updateForm(data, 'registration')"
              />
            </VTabItem> -->
            <VTabItem v-if="$team.is_enterprise">
              <Registration
                ref="registration"
                :active-tab="1"
                :user-form="form"
                :is-standalone="false"
                :form-config="formConfig"
                @updated="(data) => updateForm(data, 'registration')"
              />
            </VTabItem>
            <VTabItem>
              <Registration
                ref="registration"
                :active-tab="2"
                :user-form="form"
                :is-standalone="false"
                :form-config="formConfig"
                @updated="(data) => updateForm(data, 'registration')"
              />
            </VTabItem>
            <VTabItem>
              <Customization
                ref="customization"
                :is-standalone="false"
                @updated="(data) => updateForm(data, 'customization')"
              />
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
      <Dialog
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
        :is-dialog-open="dialogs.agreements.active"
        :title="`Edit agreement${dialogs.agreements.name ? ': '+dialogs.agreements.model.name : ''}`"
        @toggle:dialog="onToggleAgreementEdit"
        :action="onSaveAgreements"
        :close-immediately="false"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <TextField
          label="Enter a title"
          v-model="dialogs.agreements.model.name"
          @input="onUpdateMetadata(dialogs.agreements.index, dialogs.agreements.model, 'agreements')"
        />
        <Editor
          placeholder="Enter your policy or agreement here"
          v-model="dialogs.agreements.model.value"
          @input="onUpdateMetadata(dialogs.agreements.index, dialogs.agreements.model, 'agreements')"
        />
      </Dialog>
      <Dialog
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
        :is-dialog-open="dialogs.agreements.view"
        :title="`View${dialogs.agreements.model.name ? ': '+dialogs.agreements.model.name : ''}`"
        @toggle:dialog="onToggleAgreementView"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <VCard>
          <VCardText v-html="dialogs.agreements.model.value" />
        </VCard>
      </Dialog>
      <Dialog
        title="Sharable Links"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
        :is-dialog-open="dialogs.share"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @toggle:dialog="onToggleViewDialog('share')"
      >
        <ShareLinks hide-title />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import FormMixin from "@/mixins/Form";
import HasTabsMixin from "@/mixins/HasTabs";
import MetadataMixin from "@/mixins/Metadata";
import DialogViewMixin from "@/mixins/DialogView";
import AvatarCropper from "vue-avatar-cropper";
import { mapActions } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import Workspace from "./Workspace";
import Registration from "./Registration";
import ShareLinks from "./Share";
import Customization from "./Customization";

export default {
  name: "TeamSettings",
  mixins: [FormMixin, HasTabsMixin, MetadataMixin, DialogViewMixin],
  components: {
    AvatarCropper,
    IndexHeader,
    Workspace,
    Registration,
    ShareLinks,
    Customization,
  },
  data() {
    return {
      dialogs: {
        agreements: {
          active: false,
          model: {},
          index: null,
        },
        share: false,
      },
      modelType: "Settings",
      date: null,
      saveOnly: [
        "id",
        "name",
        "email",
        "website",
        "user_role_name",
        "owner_id",
        "student_role_name",
        "timezone",
        "time_format",
        "date_format",
        "profile",
        "city_id",
        "avatar",
        "agreements",
        "settings",
        "register_agreement_message",
      ],
      defaultForm: {
        name: "",
        email: "",
        website: "",
        user_role_name: "",
        owner: null,
        owner_id: null,
        city_id: null,
        timezone: "",
        time_format: "",
        date_format: "",
        profile: {
          bio: "",
          tagline: "",
        },
        agreements: [],
        register_agreement_message: {
          value: "",
          name: "Register Agreement Message",
        },
        settings: {
          mentor: {},
          student: {},
        },
      },
      fileProperties: ["avatar"],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
      },
      website: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    tabs() {
      return [
        {
          title: "General",
          url: "general",
          enabled: true,
        },
        {
          title: "Profile",
          url: "profile",
          enabled: true,
        },
        {
          title: "Policies",
          url: "policies",
          enabled: true,
        },
        {
          title: "Support Details",
          url: "support",
          enabled: true,
        },
        {
          title: this.featureName("Rename Things"),
          url: "rename-things",
          enabled: true,
        },
        {
          title: "Features",
          url: "features",
          enabled: true,
        },
        {
          title: "Profile Header",
          url: "profile-header",
          enabled: false,
        },
        {
          title: "Applicant Portal",
          url: "applicant-portal",
          enabled: this.$team.is_enterprise,
        },
        {
          title: "Landing Page",
          url: "landing-page",
          enabled: true,
        },
        {
          title: "Customization",
          url: "customization",
          enabled:
            this.$team.can_customize && this.isEnabledFeature("CUSTOMIZATION"),
          beta: this.isBetaFeature("CUSTOMIZATION"),
        },
      ];
    },
    needsToComplete() {
      return !this.form.user_role_name || !this.form.email || !this.form.slug;
    },
    agreementsLabel() {
      if (this.$team) {
        let teamName = this.$team.name;
        if (this.$team.name[this.$team.name.length - 1] === "s") {
          teamName = teamName + "'";
        } else {
          teamName = teamName + "'s";
        }
        return `Check here to agree to ${teamName} agreements`;
      }
      return "";
    },
    toggleableFeatures() {
      const toggleable = {};
      for (const group of ["mentor", "student", "platform"]) {
        if (this.$teamSettings.hasOwnProperty(group)) {
          toggleable[group.titleize()] =
            this.$teamSettings[group] || [].keyBy("relative_key");
        }
      }
      return toggleable;
    },
  },
  watch: {
    activeUrlTab: function(value) {
      // Landing Page Tab
      if(value === 8) {
        this.doGetFormConfig().then((result) => {
          this.formConfig.pipelines = result.pipelines;
        });
      }
    },
    "$route.hash": {
      handler: function(value) {
        if(!value) {
          this.$router.push({
            name: "settings",
            hash: "#general",
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.form = this.$team || this.form;
    this.form.owner = this.form.owner || this.$user.id;
    this.form.owner_id = this.form.owner_id || this.$user.id;
    this.loadFormModel(this.form.id);
  },
  methods: {
    ...mapActions({
      doSaveTeam: "team/save",
      doGet: "team/getOne",
      doGetFormConfig: "team/getFormConfig",
    }),
    onGet(id) {
      return this.doGet({
        id,
        _fields: "all",
        _relations: [
          "profile",
          "city",
          "agreements",
          "register_agreement_message",
          "registerLogic",
          "registerLogicMessage",
          "pipelines",
          "registerBanner",
          "applicationPipeline",
          "settings",
          "legacySettings.applicantPortalLearnMoreButton",
          "legacySettings.applicantPortalFaqButton",
          "legacySettings.applicantPortalExtraButtons",
        ],
      }).then((result) => {
        result.profile = result.profile || this.defaultForm.profile;
        return result;
      });
    },
    onSave() {
      this.form.slug = this.slugify(this.form.slug || this.form.name);
      this.form.website =
        this.form.website.indexOf("http") === -1
          ? `https://${this.form.website}`
          : this.form.website;
      return this.saveFormModel(this.doSaveTeam).then((team) => {
        this.form.register_logic = team.register_logic;
        this.form.settings = team.settings;
        this.$set(this.$team, "settings", team.settings);
      });
    },
    saveAvatar(cropper) {
      cropper
        .getCroppedCanvas(this.$refs.avatar.outputOptions)
        .toBlob((blob) => {
          return this.saveFormModel(this.doSaveTeam, null, {
            id: this.form.id,
            avatar: new File([blob], this.$refs.avatar.filename),
          }).then((result) => {
            this.$team.photo_url = this.form.photo_url =
              result instanceof Object ? result.photo_url : "";
            if (this.$user.teams instanceof Array) {
              let existingTeam = this.$user.teams.find(
                (team) => team.id === result.id
              );
              if (existingTeam instanceof Object) {
                existingTeam.photo_url = result.photo_url;
              }
            }
          });
        });
    },
    onSaveAgreements() {
      const data = {
        id: this.form.id,
        agreements: this.form.agreements,
      };
      return this.saveFormModel(this.doSaveTeam, null, data);
    },
    addAgreement() {
      this.onNewMetadata(
        {
          name: "",
          type: "Textarea",
          value: "",
          options: {
            description: "",
          },
        },
        null,
        "agreements"
      );
    },
    onToggleAgreementEdit(agreement, index) {
      this.dialogs.agreements.active = !this.dialogs.agreements.active;
      this.dialogs.agreements.model = agreement || {};
      this.dialogs.agreements.index = index;
    },
    onToggleAgreementView(agreement, index) {
      this.dialogs.agreements.view = !this.dialogs.agreements.view;
      this.dialogs.agreements.model = agreement || {};
      this.dialogs.agreements.index = index;
    },
    updateForm(result, refName) {
      let allSaveOnly = this.saveOnly.concat(
        Object.keys(this.$refs[refName].defaultForm)
      );
      this.saveOnly = allSaveOnly.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      this.form = {
        ...this.form,
        ...Object.select(result, Object.keys(this.$refs[refName].defaultForm)),
      };
    },
    getRegistrationTitle(role) {
      if(this.$teamSettings.registration[`${role}_on_register`]) {
        return this.$teamSettings.registration[`${role}_on_register`].title || this.$teamSettings.registration[`${role}_on_register`].description;
      }
      return this.$teamSettings.registration[`approve_${role}_on_register`].title || this.$teamSettings.registration[`approve_${role}_on_register`].description;
    },
    getRegistrationDescription(role) {
      return (this.$teamSettings.registration[`${role}_on_register`] || this.$teamSettings.registration[`approve_${role}_on_register`]).description;
    },
    getAppropriateGroupTitle(group) {
      return group == "Mentor" ? "Moderator" : group == "Student" ? "Member" : group;
    },
  },
};
</script>
