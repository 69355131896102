<template>
  <VRow column>
    <VCol style="flex: 1 1 auto">
      <VRow
        row
        column
        wrap
      >
        <VCol
          v-if="mode === 'standalone'"
          style="flex: 0 0 64px"
        >
          <ViewHeader :route-params="realPipeline.id">
            <template #title>
              {{ featureName("Pipeline") }}
              {{ featureName("Stages") }}
              <template v-if="realPipeline && realPipeline.id">
                for {{ realPipeline.title || "(not set)" }}
              </template>
            </template>
            <template #extra-buttons>
              <Linkable
                v-if="!$isUserStudent"
                icon="visibility"
                :tooltip="`View ${featureName('Pipeline').singularize()}`"
                :copyable="false"
                :icon-props="{ small: true }"
                :url="
                  getFullyQualifiedUrlForItem(`pipeline.view`, {
                    pipeline: realPipeline.id,
                  })
                "
                :to="getRouteForItem(`pipeline.view`, { pipeline: realPipeline.id })"
              />
            </template>
          </ViewHeader>
        </VCol>
        <VCol>
          <DataViewUI
            sort-by="priority"
            :data="data"
            :headers="headers"
            :is-loading="activity.isLoading"
            @paginate="onGetMore"
            @search="onSearch"
          >
            <template
              slot="items-cells"
              slot-scope="props"
            >
              <template>
                <td class="text-sm-center">
                  <VChip color="info">
                    {{ props.index + 1 }}
                  </VChip>
                </td>
                <td>
                  <VChip>
                    {{ props.item.title }}
                  </VChip>
                  <Linkable
                    :url="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, props.item.id)
                    "
                  />
                </td>
                <td>
                  <VSwitch
                    v-if="$isUserAdmin"
                    v-model="props.item.is_required_during_onboarding"
                    inset
                    :title="props.item.title"
                    :color="props.item.is_required_during_onboarding ? 'success' : 'gray'"
                    :loading="props.item.isSaving"
                    @change="
                      onToggleStageRequiredDuringOnboarding(props.index, props.item)
                    "
                  />
                  <VChip
                    v-else
                    :color="props.item.is_required_during_onboarding ? 'success' : 'gray'"
                  >
                    {{ props.item.is_required_during_onboarding ? "Yes" : "No" }}
                  </VChip>
                </td>
                <td
                  class="text-sm-center"
                  v-if="$isUserAdmin"
                >
                  <VChip
                    :text-color="props.item.applications_count ? 'white' : 'default'"
                    :color="props.item.applications_count ? 'black' : 'default'"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item)"
                  >
                    {{ props.item.applications_count || 0 }}
                    <VIcon
                      small
                      class="mx-2"
                    >
                      visibility
                    </VIcon>
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    :text-color="props.item.submitted_count ? 'white' : 'default'"
                    :color="props.item.submitted_count ? 'blue lighten-1' : 'default'"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item, 'submitted')"
                  >
                    {{ props.item.submitted_count || 0 }}
                    <VIcon
                      small
                      class="mx-2"
                    >
                      visibility
                    </VIcon>
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    text-color="white"
                    :color="props.item.completed_count ? 'success' : 'warning'"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item, 'complete')"
                  >
                    {{ props.item.completed_count || 0 }}
                    <VIcon
                      small
                      class="mx-2"
                    >
                      visibility
                    </VIcon>
                  </VChip>
                </td>
                <td class="text-sm-center">
                  <VChip
                    text-color="white"
                    :color="props.item.outstanding_count ? 'error' : 'success'"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item, 'incomplete')"
                  >
                    {{ props.item.outstanding_count || 0 }}
                    <VIcon
                      small
                      class="mx-2"
                    >
                      visibility
                    </VIcon>
                  </VChip>
                </td>
                <td>
                  {{
                    props.item.due_on
                      ? $timezone.formatDateTime(props.item.due_on)
                      : "No Date Set"
                  }}
                </td>
              </template>
              <td class="actions">
                <template v-if="$isUserAdmin">
                  <VBtn
                    icon
                    small
                    :disabled="props.index < 1"
                    :loading="props.item.isSaving"
                    @click="onMoveUp(props.index)"
                  >
                    <VIcon>arrow_upward</VIcon>
                  </VBtn>
                  <VBtn
                    icon
                    small
                    :disabled="props.index >= data.data.length - 1"
                    :loading="props.item.isSaving"
                    @click="onMoveDown(props.index)"
                  >
                    <VIcon>arrow_downward</VIcon>
                  </VBtn>
                </template>
                <Menu
                  :loading="getHasActivity(props.item)"
                  :disabled="getIsDisabled(props.item)"
                >
                  <VListItem
                    v-if="$isUserAdmin"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.update`, {
                        pipeline: props.item.pipeline_id,
                        id: props.item.id,
                      })
                    "
                    @click.prevent="onSetFormDialogModel(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        edit
                      </VIcon>&nbsp;Edit
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.view`, {
                        pipeline: props.item.pipeline_id,
                        id: props.item.id,
                      })
                    "
                    @click.prevent="onSetViewDialogModel(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        visibility
                      </VIcon>&nbsp;View
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-if="$isUserAdmin"
                    @click="onDeleteItem(props.item)"
                  >
                    <VListItemTitle color="error">
                      <VIcon
                        small
                        color="error"
                      >
                        delete
                      </VIcon>&nbsp;Delete
                    </VListItemTitle>
                  </VListItem>
                  <VSubheader>Applicants</VSubheader>
                  <VListItem
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmittedDialog(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        list_alt
                      </VIcon>&nbsp;View Applicants
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.submit`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleSubmitDialog(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        person_add
                      </VIcon>&nbsp;Add Applicant
                    </VListItemTitle>
                  </VListItem>
                  <VSubheader>Evaluations</VSubheader>
                  <VListItem
                    v-if="$isUserAdmin"
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.evaluation-form`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleEditStageEvaluationForm(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        {{ props.item.evaluation_form_count ? "edit" : "add" }}
                      </VIcon>&nbsp;
                      {{ props.item.evaluation_form_count ? "Edit" : "Add" }} Evaluation
                      Form
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.evaluate`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleEvaluationDialog(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        assignment
                      </VIcon>&nbsp;Evaluate Applicants
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    :href="
                      getFullyQualifiedUrlForItem(`${modelTypeRoute}.evaluated`, {
                        pipeline: props.item.pipeline_id,
                        stage: props.item.id,
                      })
                    "
                    @click.prevent="onToggleCompletedEvaluationsDialog(props.item)"
                  >
                    <VListItemTitle>
                      <VIcon small>
                        grading
                      </VIcon>&nbsp;View Completed Evaluations
                    </VListItemTitle>
                  </VListItem>
                </Menu>
              </td>
            </template>
          </DataViewUI>
          <FormDialog
            v-if="currentModel && dialogs.form"
            fullscreen
            is-dialog-open
            :current-model="currentModel"
            :title="currentModel ? currentModel.title : ''"
            :model-type="modelType"
            @deleteItem="onDeleteItem"
            @toggle:form:dialog="onToggleFormDialog"
          >
            <Save
              ref="saveForm"
              mode="dialog"
              :existing="currentModel"
              :id="{
                pipeline_id: currentModel.pipeline_id,
                id: currentModel.id,
              }"
            />
          </FormDialog>
          <Confirm ref="confirmDelete" />
          <Dialog
            v-if="dialogs.view"
            max-width="75%"
            is-dialog-open
            :title="`View ${featureName('Stage')}`"
            @toggle:dialog="onToggleViewDialog"
          >
            <ViewComponent
              :id="{
                pipeline_id: currentModel.pipeline_id,
                id: currentModel.id,
              }"
              ref="viewItem"
              mode="dialog"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.submitted"
            fullscreen
            is-dialog-open
            :title="`Submitted for Stage: ${submittedModel.title}`"
            @toggle:dialog="onToggleSubmittedDialog"
          >
            <Submitted
              mode="dialog"
              :stage="submittedModel"
              :can-load-from-url="false"
              :custom-filter="submittedFilter"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.singleSubmitted"
            fullscreen
            is-dialog-open
            :title="`Submitted for Stage: ${submittedModel.title}`"
            @toggle:dialog="onToggleSingleSubmittedDialog"
          >
            <ViewSubmittedComponent
              mode="dialog"
              :existing="submittedModel"
              :can-get="false"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.evaluation"
            is-dialog-open
            fullscreen
            :title="`Evaluate ${featureName('Stage').singularize()}${
              submittedModel ? ': ' + submittedModel.title : ''
            }`"
            @toggle:dialog="onToggleEvaluationDialog"
          >
            <Evaluated
              content-type="pipeline-stage"
              mode="dialog"
              :content="submittedModel"
              :can-load-from-url="false"
              @toggle:evaluation-form="onToggleEditStageEvaluationForm"
              @update:evaluation-form="onUpdateEvaluationForm"
              @toggle:evaluations="onToggleCompletedEvaluationsDialog"
            />
          </Dialog>
          <Dialog
            v-if="submittedDialogs.completedEvaluations"
            is-dialog-open
            fullscreen
            :title="`Submit Evaluations${
              submittedModel ? ': ' + submittedModel.evaluation_form.title : ''
            }`"
            @toggle:dialog="onToggleCompletedEvaluationsDialog"
          >
            <template v-if="(submittedModel || {}).id">
              <VAlert
                outlined
                class="mt-2"
                :type="!submittedModel.evaluation_form_count ? 'error' : 'info'"
                :value="true"
              >
                <template v-if="!submittedModel.evaluation_form">
                  <p>
                    This {{ featureName("pipeline").singularize() }} is missing an
                    evaluation form!
                  </p>
                  <VBtn
                    text
                    @click="() => onToggleEditStageEvaluationForm()"
                  >
                    Add Evaluation Form
                  </VBtn>
                </template>
                <template v-else-if="submittedModel.evaluation_form instanceof Object">
                  Using evaluation form: {{ submittedModel.evaluation_form.title }}.
                  <span>
                    <VBtn
                      text
                      @click="() => onToggleEditStageEvaluationForm()"
                    >
                      <VIcon small>edit</VIcon>&nbsp;Edit
                    </VBtn>
                  </span>
                </template>
              </VAlert>
              <SubmittedEvaluations
                v-if="submittedModel.evaluation_form instanceof Object"
                mode="dialog"
                :deliverable-id="(submittedModel.evaluation_form || {}).id"
                :custom-filter="submittedEvaluationFilter"
              />
            </template>
          </Dialog>
          <Dialog
            v-if="submittedDialogs.submit"
            fullscreen
            is-dialog-open
            :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
            :title="`Submit Stages${submittedModel ? ': ' + submittedModel.title : ''}`"
            :action="onSubmitStage"
            :close-immediately="false"
            @toggle:dialog="onToggleSubmitDialog"
          >
            <SubmitForm
              v-if="submitted"
              mode="dialog"
              property="program_id"
              entity-type="App\Models\Program"
              ref="submitStage"
              :items="submitted.data"
              :pipeline="submittedModel"
            />
          </Dialog>
          <FormDialog
            v-if="dialogs.addEvaluation"
            is-dialog-open
            persistent
            max-width="75%"
            title="Save Evaluation Form"
            model-type="PipelineEvaluationForm"
            style="z-index: 10000"
            @toggle:form:dialog="onToggleEditStageEvaluationForm"
          >
            <SaveEvaluationForm
              ref="saveEvaluationForm"
              mode="dialog"
              content-type="pipeline-stage"
              :id="submittedModel.id"
              :content="submittedModel"
              @update:form="onUpdateEvaluationForm"
            />
          </FormDialog>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStatsMixin from "@/mixins/HasStats";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasDueMixin from "@/mixins/HasDueOn";
import Save from "./SaveStage";
import Submitted from "./StageSubmitted";
import Evaluated from "./StagesEvaluated";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./ViewStage";
import ViewSubmittedComponent from "./ViewSubmitted";
import { default as SubmittedEvaluations } from "@/components/Deliverables/Submitted";
import { mapActions } from "vuex";
import SaveEvaluationForm from "./SaveEvaluationForm";
import SupportsPipelines from "@/mixins/SupportsPipelines";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "PipelineStagesData",
  components: {
    Save,
    Submitted,
    Evaluated,
    SubmitForm,
    ViewComponent,
    SaveEvaluationForm,
    SubmittedEvaluations,
    ViewSubmittedComponent,
    ViewHeader,
  },
  mixins: [
    SearchMixin,
    HasStatsMixin,
    HasSubmittedDataMixin,
    HasDueMixin,
    SupportsPipelines,
  ],
  props: {
    pipeline: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: null,
      dialogs: {
        addEvaluation: false,
      },
      submittedEvaluationFilter: null,
      modelType: "pipelineStage",
      statOptions: {
        inflect: {
          submitted: {
            plural: "Submitted",
          },
        },
      },
      config: {
        stats: {
          "-": "-",
        },
      },
    };
  },
  computed: {
    headers() {
      let headers;
      headers = [
        {
          text: "Stage Order",
          align: "center",
          sortable: true,
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Used for Onboarding?",
          align: "left",
          sortable: true,
          value: "is_required_during_onboarding",
        },
        {
          text: "# Applications",
          align: "center",
          sortable: true,
          value: "applications_count",
          style: "width: 32px",
          hidden: !this.$isUserAdmin,
        },

        {
          text: "# Submitted",
          align: "center",
          sortable: true,
          value: "submitted_count",
          style: "width: 32px",
        },
        {
          text: "# Completed",
          align: "center",
          sortable: true,
          value: "completed_count",
          width: "32px",
        },
        {
          text: "# Outstanding",
          align: "center",
          sortable: true,
          value: "outstanding_count",
          width: "32px",
        },
        {
          text: "Stage Closes On",
          align: "left",
          sortable: true,
          value: "due_on",
        },
      ].filter((i) => i.hidden !== true);
      return headers;
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "pipelineStage/getIndexConfig",
      doGetStages: "pipelineStage/getAll",
      doGetStage: "pipelineStage/getOne",
      doDeleteStage: "pipelineStage/delete",
      doSaveStage: "pipelineStage/save",
      doGetSubmitted: "pipelineSubmitted/getAll",
      doSaveStageSubmitted: "pipelineSubmitted/save",
    }),
    onDelete(stage) {
      return this.doDeleteStage({
        id: stage.id,
        pipeline_id: stage.pipeline_id,
      });
    },
    onGet(id) {
      return this.doGetStage(id);
    },
    onGetAll(params) {
      return this.doGetStages({
        ...(params || {}),
        ...{
          pipeline_id: this.realPipeline.id,
        },
      });
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    onMoveUp(index) {
      this.onSetStagePriority(index).then((result) => {
        this.$set(this.data.data[index], "priority", result.data.priority);
        this.$set(this.data.data[index - 1], "priority", result.data.priority + 1);
        this.$set(
          this.data,
          "data",
          this.data.data.sort((a, b) => a.priority - b.priority)
        );
      });
    },
    onMoveDown(index) {
      this.onSetStagePriority(index, true).then((result) => {
        this.$set(this.data.data[index], "priority", result.data.priority);
        this.$set(this.data.data[index + 1], "priority", result.data.priority - 1);
        this.$set(
          this.data,
          "data",
          this.data.data.sort((a, b) => a.priority - b.priority)
        );
      });
    },
    onSetStagePriority(index, increase) {
      const stage = this.data.data[index];
      this.data.data.splice(index, 1, {
        ...stage,
        ...{
          isSaving: true,
        },
      });
      const priority = index + 1;
      return this.doSaveStage({
        id: stage.id,
        pipeline_id: stage.pipeline_id,
        priority:
          (increase instanceof Number
            ? increase
            : increase
            ? priority + 1
            : priority - 1) || 1,
      }).then((result) => {
        const newIndex = this.data.data.findIndex((d) => d.id == result.data.id);
        this.data.data.splice(newIndex, 1, {
          ...result.data,
          ...{
            isSaving: false,
          },
        });
        return result;
      });
    },
    onToggleStageActive(stage) {
      return this.doSaveStage({
        id: stage.id,
        pipeline_id: stage.pipeline_id,
        is_active: !stage.is_active,
      });
    },
    getContentType(stage) {
      return stage.type ? stage.type.titleize() : "(not set)";
    },
    onSubmitStage() {
      return this.$refs["submitStage"].onSave();
    },
    onUpdateEvaluationForm(form, stageId) {
      const index = this.data.data.findIndex((p) => p.id == stageId);
      this.data.data.splice(index, {
        ...this.data.data[index],
        ...{
          evaluation_form: form,
          evaluation_form_count: 1,
        },
      });
      this.$set(this.submittedModel, `evaluation_form`, form);
      this.$set(this.submittedModel, `evaluation_form_count`, 1);
      this.$log.info("Updated evaluation form for ", stageId, index);
    },
    onToggleEditStageEvaluationForm(model) {
      this.dialogs.addEvaluation = !this.dialogs.addEvaluation;
      this.submittedModel = model || this.submittedModel;
    },
    onToggleCompletedEvaluationsDialog(model, filter) {
      if (!this.submittedDialogs.completedEvaluations) {
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
        this.submittedModel = null;
        this.submittedEvaluationFilter = filter;
        this.doGetStage({
          id: model.id,
          pipeline_id: this.realPipeline.id,
          _with: ["evaluationForm"],
          _fields: ["id", "evaluation_form", "evaluation_form_count"],
        }).then((stage) => {
          this.submittedModel = stage || model || this.submittedModel;
        });
      } else {
        this.submittedEvaluationFilter = null;
        this.submittedDialogs.completedEvaluations = !this.submittedDialogs
          .completedEvaluations;
      }
    },
    onToggleStageRequiredDuringOnboarding(index, item) {
      this.$set(this.data.data[index], "isSaving", true);
      return this.doSaveStage({
        id: item.id,
        pipeline_id: item.pipeline_id,
        is_required_during_onboarding: item.is_required_during_onboarding,
      }).then((result) => {
        this.data.data = this.data.data.map((d) => {
          d.is_required_during_onboarding = d.id == result.data.id;
          return d;
        });
        this.$set(this.data.data[index], "isSaving", false);
      });
    },
    onToggleSubmittedDialog(model, type) {
      this.submittedDialogs.submitted = !this.submittedDialogs.submitted;
      this.submittedModel = model || this.submittedModel;
      this.submittedFilter = {
        pipeline_id: this.submittedModel.pipeline_id,
        pipeline_stage_id: this.submittedModel.id,
      };
      if (type) {
        this.submittedFilter["stage_status"] = type;
      }
      // if (model) this.$set(model, "isLoading", true);
    },
  },
};
</script>
