import api from "../../services/deliverable";
export default function (helper, moduleApi) {
	// Assessment types
	const TYPES = {
		STORE_SAVE: `STORE_SAVE`,
	};
	const realApi = moduleApi || api;

	/**
	 * Generated store module
	 */
	helper.setApi(realApi);
	const types = helper.types("deliverable");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions(
		{
			/**
			 * Toggle the given data from the store
			 * @param {Object} context
			 * @param {any} params
			 * @returns {Promise}
			 */
			toggleLock(context, params, attr) {
				helper
					.log()
					.info(
						`[Store: ${realApi.type}]: Toggle Lock ${realApi.type}`,
						params
					);
				return new Promise((resolve, reject) => {
					return realApi
						.toggleLock(params)
						.then((response) => {
							helper
								.log()
								.info(
									`[Store: ${realApi.type}]: Toggled Lock ${realApi.type}`,
									response
								);
							const data = response.data.data;
							context.commit(TYPES.STORE_SAVE, {
								type: api.type,
								context,
								params,
								result: data,
							});
							resolve(data);
						})
						.catch((error) => {
							helper
								.log()
								.info(
									`[Store: ${realApi.type}]: Error Toggling Lock ${realApi.type}`,
									error
								);
							reject(error);
						});
				});
			},
		},
		"Deliverable"
	);
	const mutations = helper.mutations({}, types, state);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
