<template>
  <VRow
    v-if="$user.team_user instanceof Object && $user.team_user.is_approved === true"
    row
    dense
    class="mb-3 mx-0"
    :wrap="$vuetify.breakpoint.smAndDown"
    :style="{
      height: 'auto'
    }"
  >
    <VCol
      v-for="(item, value) in stats"
      xs="6"
      md="3"
      :key="value"
      :class="dynamicMarginClass(value)"
    >
      <VCard class="stat-card">
        <VRow nowrap>
          <VCol>
            <VCardText 
              :class="{
                'stat-card-count': true,
                'error': getHasIndicator('danger', value, stats[value]),
                'info': getHasIndicator('info', value, stats[value]),
                'warning': getHasIndicator('warning', value, stats[value]),
                'success': getHasIndicator('success', value, stats[value]),
              }"
            >
              {{ stats[value] }}
            </VCardText>
          </VCol>
          <VCol>
            <VCardText style="color: black">
              {{ value === '-' ? ' ' : formatTitle(value, stats[value]) }}
            </VCardText>
          </VCol>
        </VRow>
      </VCard>
    </VCol>
    <slot name="append-actions" />
  </VRow>
</template>

<script>
export default {
  name: "Stats",
  props: {
    height: {
      type: String,
      default: "112px",
    },
    stats: {
      type: [Object, Array],
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
        inflect: {
        }
        indicators: {
        }
      },
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getHasIndicator(type, key, value) {
      if (!(this.options.indicators instanceof Object)) {
        return false;
      }
      let indicator = this.options.indicators[key];
      if (!indicator && Object.entries(this.options.indicators).length > 0) {
        /**
         * We support regex checking
         * For example is there a devoitions_due key?
         * If there is we can send a regex to match: /_due$/
         * */
        indicator = Object.entries(this.options.indicators).find((obj) => {
          const [regex, item] = obj;
          try {
            return RegExp(regex).test(key);
          } catch (e) {
            return index === key;
          }
        });
        if (indicator instanceof Array) {
          indicator = indicator[1];
        }
      }
      if (indicator instanceof Function) {
        return indicator(value, type);
      }
      return indicator === type;
    },
    // https://github.com/gouch/to-title-case/blob/master/to-title-case.js
    formatTitle(string, value) {
      if (["staff"].indexOf(string.toLowerCase()) !== -1) {
        string = this.$team.user_role_name.pluralize();
        return string;
      }
      const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
      const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
      const wordSeparators = /([_ :–—-])/;
      const convertToSpace = /[_\_]/g;

      const inflectKey = string.toLowerCase().underscore();
      this.$log.debug("[Stats]:Checking for ", inflectKey);
      if (
        this.options.inflect &&
        this.options.inflect.hasOwnProperty(inflectKey)
      ) {
        const inflection = value === 1 ? "singular" : "plural";
        this.$log.debug(
          "[Stats]:Checking for ",
          inflectKey,
          "in",
          this.options,
          inflection
        );
        return (
          this.options.inflect[inflectKey][inflection] || string.titleize()
        );
      } else {
        let retVal = string
          .replace(convertToSpace, " ")
          .split(wordSeparators)
          .map(function (current, index, array) {
            if (
              /* Check for small words */
              current.search(smallWords) > -1 &&
              /* Skip first and last word */
              index !== 0 &&
              index !== array.length - 1 &&
              /* Ignore title end and subtitle start */
              array[index - 3] !== ":" &&
              array[index + 1] !== ":" &&
              /* Ignore small words that start a hyphenated phrase */
              (array[index + 1] !== "-" ||
                (array[index - 1] === "-" && array[index + 1] === "-"))
            ) {
              return current.toLowerCase();
            }

            /* Ignore intentional capitalization */
            if (current.substr(1).search(/[A-Z]|\../) > -1) {
              return current;
            }

            /* Ignore URLs */
            if (array[index + 1] === ":" && array[index + 2] !== "") {
              return current;
            }

            /* Capitalize the first letter */
            return current.replace(alphanumericPattern, function (match) {
              return match.toUpperCase();
            });
          })
          .join("");
        retVal = this.featureName(retVal);
        return retVal ? retVal.pluralize(parseInt(value)) : string;
      }
    },
    dynamicMarginClass(title) {
      if(this.fullWidth) {
        let statTitles = Object.keys(this.stats);
        let index = statTitles.indexOf(title);
        if(index === 0) {
          return "mr-auto";
        } else if(index === (statTitles.length - 1)) {
          return "ml-auto";
        } else {
          return "ma-auto";
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import "../../../styles/stats.scss";
</style>