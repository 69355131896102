import Vue from 'vue';

export default {
  resolveError(error, reject) {
    this.log().debug('Resolving', error.response);
    let data = error.response ? error.response.data : error;
    if (data.error) {
      // The request was made, but the server responded with a status code
      data = data.error.message || (data.error.errors ? data.error.errors.message : null) || data.error
    } else {
      // Something happened in setting up the request that triggered an Error
      // this.log().info('Error', error.message)
      data = data.data || data.message || data.exception || data
    }
    let result = error.response && error.response.data.errors ? Object.assign({}, {
      code: error.response.status
    }, error.response.data) : {
      code: error.response ? error.response.status : error.status,
      message: data
    }

    this.log().debug('Resolved', result);
    return result;
  },
  flattenObject(data, addToKey) {
    addToKey = addToKey || 'filter';
    let result = {};

    const flattenObjectLocal = function (object, parent) {
      if (object) {
        for (const k of Object.keys(object)) {
          let fullKey = k
          if (parent !== undefined) {
            fullKey = parent + '[' + fullKey + ']'
          }
          // fullKey = `[${addToKey}]${fullKey}`;
          if (object[k] instanceof File || object[k] instanceof Date) {
            result[fullKey] = object[k]
          } else if (typeof object[k] === 'object') {
            flattenObjectLocal(object[k], fullKey)
          } else {
            result[fullKey] = object[k]
          }
        }
      }
    }
    flattenObjectLocal(data, addToKey)
    return result
  },
  createFormData(data) {
    if (!data.hasFiles) {
      return data.data || data;
    } else {
      const result = {};
      const formData = new FormData();
      const objectToFormData = function (object, parent) {
        if (object) {
          if (object instanceof Array) {
            if (!object.length && parent) {
              result[parent] = object;
            } else {
              object.forEach((v, k) => {
                let fullKey = k;
                if (parent !== undefined) {
                  fullKey = parent + '[' + fullKey + ']';
                }
                if (
                  object[k] instanceof File ||
                  object[k] instanceof Date) {
                  result[fullKey] = object[k];
                } else if (object[k] instanceof Object) {
                  objectToFormData(object[k], fullKey);
                } else {
                  result[fullKey] = object[k];
                }
              });
            }
          } else {
            for (const k of Object.keys(object)) {
              let fullKey = k;
              if (parent !== undefined) {
                fullKey = parent + '[' + fullKey + ']';
              }
              if (
                object[k] instanceof File ||
                object[k] instanceof Date) {
                result[fullKey] = object[k];
              } else if (object[k] instanceof Object) {
                objectToFormData(object[k], fullKey);
              } else {
                result[fullKey] = object[k];
              }
            }
          }
        }
      }
      objectToFormData(data.data)
      this.log().info('[Utils]: FormData: ', result);
      for (const k of Object.keys(result)) {
        const value = result[k] === null || result[k] === undefined || result[k] === 'null' ? '' : result[k];
        formData.append(k, value);
      }

      for (var pair of formData.entries()) {
        this.log().info(pair[0] + ': ' + pair[1]);
      }
      return formData;
    }
  },
  objectValues(object) {
    return Object.keys(object).map(key => object[key])
  },
  getCookie(name) {
    let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (match) return match[1];
  },
  getTypeOptions(getter, type, mutationType, state, commit) {
    if (state.options) {
      return new Promise((resolve, reject) => {
        this.log().info(`Utils: Vuex: ${type}]: Getting options`, state.options)
        resolve(state.options);
      });
    } else {
      return getter({
        options: ['form', 'filter']
      }).then((response) => {
        let options = response.data.data.options;
        this.log().info(`[Utils: Vuex: ${type}]: Fetched options`, options)
        commit(type + '/' + mutationType, options, {
          root: true
        });
        return options;
      }).catch((error) => {
        this.log().info(`[Utils: Vuex: ${type}]: Error getting options`, error)
        reject(error)
      })
    }
  },
  /**
   * Update global statistics
   * @param {Object} state
   * @param {int} value
   * @param {string} type
   */
  updateStats(state, value, type) {
    const keys = {
      plural: type.pluralize().underscore().toLowerCase(),
      singular: type.singular().underscore().toLowerCase()
    };

    if (config.stats) {
      keys.forEach(key => {
        if (config.stats.hasOwnProperty(key)) {
          config.stats += value;
        }
      });
    }
  },
  addToStateData(state, item, stateIsTarget, push) {
    const addData = function (_state, _item) {
      if (_state instanceof Array) {
        const index = _state.findIndex(current => current instanceof Object && current.id === _item.id);
        if (index > -1) {
          _state.splice(index, 1, _item);
        } else if (push) {
          this.log().info(_state, _item);
          _state.push(_item);
        } else {
          _state.unshift(_item);
        }
      }
    };
    if (stateIsTarget === true) {
      addData(state, item);
    } else {
      addData(state.data.data, item);
      state.all.push(item);
      state.data.total = state.data.data.length;
    }
  },
  updateStateData(state, item, stateIsTarget, addToState) {
    const updateData = function (_state, _item) {
      let data;
      if (_state instanceof Array) {
        data = _state.find(current => current instanceof Object && current.id === _item.id);
      } else {
        data = _state;
      }
      if (data) {
        data = {
          ...data,
          ...item
        };
      } else if (addToState) {
        this.addToStateData(state, item, stateIsTarget);
      }
    };
    if (stateIsTarget === true) {
      updateData(state, item);
    } else {
      updateData(state.data.data, item);
    }
  },
  removeFromStateData(state, item, stateIsTarget) {
    const id = item instanceof Object ? item.id : item;
    const removeData = function (_state, _item) {
      const index = _state.findIndex(current => {
        if (current instanceof Object) {
          return current.id === _item;
        } else if (_item instanceof Function) {
          return _item(current);
        } else {
          return current === _item;
        }
      });
      if (index > -1) {
        _state.splice(index, 1);
      }
    }
    if (stateIsTarget === true) {
      removeData(state, id);
    } else {
      removeData(state.data.data, id);
      state.data.total = state.data.data.length;
    }

  },
  findItemInState(state, item, stateIsTarget) {
    const itemId = item instanceof Object ? item.id : item;
    if (stateIsTarget === true) {
      return state.findIndex(current => current instanceof Object && current.id == itemId);
    } else {
      return state.data.data.findIndex(current => current instanceof Object && current.id == itemId);
    }
  },
  getItemInState(state, item, stateIsTarget) {
    const itemId = item instanceof Object ? item.id : item;
    if (stateIsTarget === true) {
      return state.find(current => current instanceof Object && current.id == itemId);
    } else {
      return state.data.data.find(current => current instanceof Object && current.id == itemId);
    }
  }
}