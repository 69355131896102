<template>
  <VCard>
    <VCardTitle v-if="mode === 'standalone'">
      <h1>
        <VBtn 
          icon 
          large 
          @click="$router.go(-1)"
        >
          <VIcon>arrow_back</VIcon>
        </VBtn>Web and Mobile App Privacy Policy
      </h1>
    </VCardTitle>
    <VCardText style="white-space: pre-wrap">
      <p>Last modified: 12/18/19</p>
      <h2>Introduction</h2>
      <p>
        WeThrive Tech, Inc. (&ldquo;
        <strong>Company</strong>&rdquo; or &ldquo;
        <strong>We</strong>&rdquo;) respect your privacy and are committed to protecting it through our compliance with this policy.&nbsp;
        This policy describes the types of information we may collect or that you may provide when you purchase, download, install, register with, access, or use the WeThrive Tech&nbsp;(the &ldquo;
        <strong>App</strong>&rdquo;) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>On this App.</li>
        <li>In email, text, and other electronic messages between you and this App.</li>
        <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries); or&nbsp;</li>
        <li>any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the App.</li>
      </ul>
      <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time. Your continued use of this App after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
      <h2>Children Under the Age of 13</h2>
      <p>The App is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at daquan@wethrive.tech</p>
      <p>Information We Collect and How We Collect It</p>
      <p>We collect information from and about users of our App:</p>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>Automatically when you use the App.</li>
      </ul>

      <h2>Information You Provide to Us.</h2>
      <p>When you download, register with, or use this App, we may ask you to provide information:</p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline (&ldquo;
          <strong>personal information</strong>&rdquo;);
        </li>
        <li>About your internet connection, the equipment you use to access our App and usage details.</li>
      </ul>
      <p>
        This information includes
        <ins cite="mailto:Author">, but is not limited to</ins>:
      </p>
      <ul>
        <li>Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App, and subscribing to our service, and posting material, and requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with the App.</li>
        <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
        <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
        <li>Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.</li>
        <li>Your search queries on the App.</li>
      </ul>
      <p>
        You may provide information to be published or displayed (&ldquo;
        <strong>Posted</strong>&rdquo;) on public areas of websites you access through the App (collectively, &ldquo;
        <strong>User Contributions</strong>&rdquo;). Your User Contributions are Posted and transmitted to others at your own risk. &nbsp;Although you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
      </p>
      <h2>Automatic Information Collection and Tracking.</h2>
      <p>When you download, access, and use the App, it may use technology to automatically collect:</p>
      <ul>
        <li>
          <strong>Usage Details.</strong> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, logs, and other communication data and the resources that you access and use on or through the App.
        </li>
        <li>
          <strong>Device Information.</strong> We may collect information about your mobile device and internet connection, including the device&rsquo;s unique device identifier, IP address, operating system, browser type, mobile network information, and the device&rsquo;s telephone number.
        </li>
        <li>
          <strong>Stored Information and Files.</strong> The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.
        </li>
        <li>
          <strong>Location Information.</strong> This App does not collect real-time information about the location of your device.&nbsp;
        </li>
      </ul>
      <p>If you do not want us to collect this information do not download the App or delete it from your device.</p>
      <p>
        <strong>
          <u>Information Collection and Tracking Technologies</u>
        </strong>. The technologies we use for automatic information collection may include:
      </p>
      <ul>
        <li>
          <strong>Cookies (or mobile cookies).</strong> A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.
        </li>
        <li>
          <strong>Web Beacons.</strong> Pages of the App [and our emails] may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages [or opened an email] and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).
        </li>
      </ul>
      <h2>Third-Party Information Collection</h2>
      <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
      <ul>
        <li>Advertisers, ad networks, and ad servers.</li>
        <li>Analytics companies.</li>
        <li>Your mobile device manufacturer.</li>
        <li>Your mobile service provider.</li>
        <li>Customer support technology.</li>
      </ul>
      <p>These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
      <p>We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.&nbsp;</p>
      <h2>How We Use Your Information</h2>
      <p>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
      <ul>
        <li>Provide you with the App and its contents, and any other information, products or services that you request from us.</li>
        <li>Fulfill any other purpose for which you provide it.</li>
        <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
        <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
      </ul>
      <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
      <ul>
        <li>&nbsp;Estimate our audience size and usage patterns.</li>
        <li>Store information about your preferences, allowing us to customize our App according to your individual interests.</li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you use the App.</li>
      </ul>
      <p>We may use the information we collect to display advertisements to our advertisers&rsquo; target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
      <h2>Disclosure of Your Information</h2>
      <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</p>
      <p>In addition, we may disclose personal information that we collect or you provide:</p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
        <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of WeThrive&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by WeThrive about our App users is among the assets transferred.</li>
        <li>To third parties to market their products or services to you if you have consented to/not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
        <li>To fulfill the purpose for which you provide it.&nbsp;</li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>With your consent.</li>
        <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
        <li>To enforce our rights arising from any contracts entered into between you and us, including the App EULA, and for billing and collection.</li>
        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of WeThrive, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
      </ul>
      <h2>Choices About Our Collection, Use, and Disclosure of Your Information</h2>
      <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
      <ul>
        <li>
          <strong>Tracking Technologies.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.
        </li>
        <li>
          <strong>Promotional Offers from the Company.</strong> If you do not wish to have your email address/contact information used by the&nbsp;Company&nbsp;to promote our own or third parties&rsquo; products or services, you can opt-out by sending us an email stating your request to
          <a href="mailto:daquan@wethrive.tech">daquan@wethrive.tech</a>. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions.&nbsp;
        </li>
        <li>
          <strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers&rsquo; target-audience preferences,&nbsp;you&nbsp;can opt-out by sending us an email stating your request to
          <a href="mailto:daquan@wethrive.tech">daquan@wethrive.tech</a>.
        </li>
      </ul>
      <p>
        We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;
        <strong>NAI</strong>&rdquo;) on the NAI&rsquo;s website.
      </p>
      <h2>Accessing and Correcting Your Personal Information</h2>
      <p>You can review and change your personal information by logging into the App and visiting your account profile page.</p>
      <p>
        You may also send us an email at&nbsp;
        <a href="mailto:daquan@wethrive.tech">daquan@wethrive.tech</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate
      </p>
      <h2>Your California Privacy Rights</h2>
      <p>
        California Civil Code Section 1798.83 permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to
        <a href="mailto:daquan@wethrive.tech">daquan@wethrive.tech</a>.&nbsp;
      </p>
      <h2>Data Security</h2>
      <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.&nbsp;</p>
      <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
      <h2>Changes to Our Privacy Policy</h2>
      <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users&rsquo; personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and/or an in-App alert the first time you use the App after we make the change.</p>
      <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes.</p>
      <h2>Contact Information</h2>
      <p>
        To ask questions or comment about this privacy policy and our privacy practices, contact us at: WeThrive Tech, Inc., 1460 Broadway, New York, NY 10036, via email at
        <a href="mailto:daquan@wethrive.tech">daquan@wethrive.tech</a>.&nbsp;
      </p>
      <p>
        <br>
      </p>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  props: {
    mode: {
      type: String,
      default: "standalone"
    }
  }
};
</script>
<style>
ul {
  margin-bottom: 8px;
}
</style>