var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VContainer',{staticClass:"launch-page",style:(_vm.style),attrs:{"fluid":""}},[_c('VRow',{class:{
      'container': true,
      'pa-8': _vm.$vuetify.breakpoint.mdAndUp,
      'pa-0': _vm.$vuetify.breakpoint.smAndDown
    },style:({
      height: _vm.$vuetify.breakpoint.smAndDown ? '72px' : '150px'
    }),attrs:{"align-center":""}},[_c('VCol',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"12","shrink":_vm.$vuetify.breakpoint.mdAndUp}},[_c('img',{style:({
          maxHeight: _vm.$vuetify.breakpoint.smAndDown ? '36px' : '72px',
          minHeight: _vm.$vuetify.breakpoint.smAndDown ? '36px' : '54px'
        }),attrs:{"src":_vm.logo}})])],1),_c('VRow',{class:{
      'container': true,
      'pa-8': _vm.$vuetify.breakpoint.mdAndUp,
    },style:({
      height: _vm.$vuetify.breakpoint.smAndDown ? '300px' : 'calc(100vh - 150px)',
      marginBottom: _vm.$vuetify.breakpoint.smAndDown ? '0px' : '200px'
    })},[_c('VCol',{staticClass:"d-flex flex-column flex-wrap",class:{
        'pa-1': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"grow":_vm.$vuetify.breakpoint.smAndDown,"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 'auto'}},[_c('h1',{style:({
          marginTop: _vm.$vuetify.breakpoint.smAndDown ? '55px' : '15vh',
          marginBottom: '40px'
        })},[_vm._v(" Join your team "),_c('br'),_vm._v("on "),_c('b',[_vm._v("WeThrive")]),_vm._v(" "),_c('br'),_vm._v("Mobile ")]),(_vm.$vuetify.breakpoint.smAndDown)?[_c('p',{staticClass:"my-3 paragraph",style:({
            'font-weight': 500,
          })},[_vm._v(" Already have the WeThrive Mobile app? ")]),_c('VBtn',{staticClass:"white--text text-none launch-btn",staticStyle:{"border-radius":"16px"},attrs:{"depressed":"","color":"#000000","small":_vm.$vuetify.breakpoint.smAndDown,"x-large":_vm.$vuetify.breakpoint.mdAndUp,"href":_vm.linkForLaunchMobileApp}},[_vm._v(" Launch Mobile App ")])]:[_c('div',[_c('VBtn',{staticClass:"text-none download-btn",attrs:{"outlined":"","depressed":"","color":"#000000","href":_vm.downloadLinkForIOS,"x-large":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VIcon',{attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-apple ")]),_c('span',[_c('h4',[_vm._v("Download on the")]),_c('h2',{staticClass:"d-flex justify-start"},[_vm._v("App Store")])])],1),_c('VBtn',{staticClass:"text-none ml-4 download-btn",attrs:{"outlined":"","depressed":"","color":"#000000","href":_vm.downloadLinkForAndroid,"x-large":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VIcon',{attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-google-play ")]),_c('span',[_c('h6',{staticClass:"d-flex justify-start"},[_vm._v("ANDROID APP ON")]),_c('h2',[_vm._v("Google Play")])])],1)],1)]],2),_c('VCol',{class:{
        'd-flex flex-wrap': true,
        'pa-0': _vm.$vuetify.breakpoint.smAndDown,
        'justify-center': _vm.$vuetify.breakpoint.mdAndUp,
        'justify-end': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"shrink":_vm.$vuetify.breakpoint.smAndDown,"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 'auto'}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{style:(_vm.mobileImageStyle),attrs:{"id":"mobile-image","src":_vm.backgroundMobileImage}}),_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('VBtn',{staticClass:"white--text text-none py-6 mt-6 launch-btn",staticStyle:{"border-radius":"16px","max-width":"275px !important","padding":"10px 30px !important"},attrs:{"depressed":"","large":"","color":"#000000","small":_vm.$vuetify.breakpoint.smAndDown,"x-large":_vm.$vuetify.breakpoint.mdAndUp,"href":_vm.linkForLaunchMobileApp}},[_vm._v(" Launch Mobile App ")]):_vm._e()],1)])])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('VRow',{staticClass:"py-8"},[_c('VCol',{staticClass:"d-flex justify-center text-center py-6",attrs:{"cols":"12"}},[_c('span',{style:({
          'font-size': _vm.$vuetify.breakpoint.xs ? '22px' : '36px',
          'font-weight': 500,
        })},[_vm._v(" Need to download the app? ")])]),_c('VCol',{staticClass:"d-flex justify-center py-6",attrs:{"cols":"12"}},[_c('VBtn',{staticClass:"text-none download-btn",attrs:{"outlined":"","depressed":"","color":"#000000","href":_vm.downloadLinkForIOS,"x-large":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VIcon',{attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-apple ")]),_c('span',[_c('h4',[_vm._v("Download on the")]),_c('h2',{staticClass:"d-flex justify-start"},[_vm._v("App Store")])])],1),_c('VBtn',{staticClass:"text-none ml-4 download-btn",attrs:{"outlined":"","depressed":"","color":"#000000","href":_vm.downloadLinkForAndroid,"x-large":_vm.$vuetify.breakpoint.mdAndUp}},[_c('VIcon',{attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" mdi-google-play ")]),_c('span',[_c('h6',{staticClass:"d-flex justify-start"},[_vm._v("ANDROID APP ON")]),_c('h2',[_vm._v("Google Play")])])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }