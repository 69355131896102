<template>
  <VDialog
    v-if="files.length > 0"
    fullscreen
    dark
    :value="true"
    @toggle:dialog="$emit('toggle')"
  >
    <VRow
      id="preview-files"
      column
      justify-center
      align-center
      fill-height
      style="background-color: rgba(0, 0, 0, 0.75); margin: 0 12px 0 0; flex-wrap: nowrap; overflow: hidden"
    >
      <VCol
        sm1
        style="width: 100%; flex: 0 0 5vh"
      >
        <VRow
          dense
          color="transparent"
          class="elevation-0"
        >
          <VSpacer />
          <VCol shrink>
            <VBtn
              icon
              @click.native="$emit('toggle')"
            >
              <VIcon large>
                close
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        v-if="files.length > 0"
        d-flex
        align-content-center
        :style="{
          width: '100%',
          flex: '0 1 calc(100vh - 10vh)',
          overflow: 'auto'
        }"
      >
        <VCarousel
          v-model="currentFile"
          height="100%"
          :cycle="false"
          :show-arrows="files.length > 1"
          :hide-delimiters="true"
        >
          <VCarouselItem
            v-for="(file,i) in files"
            :key="i"
          >
            <VRow
              wrap
              justify-center
              align-center
              fill-height
            >
              <VCol
                align-center
                justify-center
                cols="12"
                class="d-flex"
              >
                <h4
                  class="xs12 text-center"
                  style="color: #fff;"
                >
                  {{ file.name }} <template v-if="file.size || file.readable_size">
                    | {{ file.size ? file.readable_size : '' }}
                  </template>
                </h4>
              </VCol>
              <VCol
                align-center
                justify-center
                cols="12"
                class="d-flex fill-height"
              >
                <iframe
                  v-if="getIsFromGoogle(file)"
                  frameborder="0"
                  :style="styles.frame"
                  :src="file.url+'?embedded=1'"
                />
                <template v-else-if="getIsEmbeddableVideoLink(file)">
                  <iframe
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="py-4"
                    :src="getVideoUrl(file)"
                    :style="{
                      width: '100%',
                      margin: 'auto',
                      maxWidth: 'calc(100vw - 32px)',
                      minHeight: $vuetify.breakpoint.mdAndUp ? '315px' : 'auto',
                      maxHeight: $vuetify.breakpoint.mdAndUp ? '100%' : '315px',
                    }"
                  />
                </template>
                <audio
                  v-else-if="getIsEmbeddableAudio(file)"
                  controls
                  :src="file.url"
                />
                <div
                  v-else-if="getIsEmbeddableVideo(file)"
                  class="responsive-video fluid"
                >
                  <template v-if="getIsSelfHostedMedia(file)">
                    <video
                      style="position: relative; display: block; margin-left: auto; margin-right: auto; width: inherit; height: auto; min-height: 210px; border-radius: 4px;"
                      :style="styles.video"
                      :autoplay="false"
                      :controls="true"
                      @click.left="onCancelContextMenu($event)"
                      @contextmenu="onCancelContextMenu($event)"
                    >
                      <source
                        :type="getMediaType(file)"
                        :src="getVideoUrl(file)"
                        @click.left="onCancelContextMenu($event)"
                        @contextmenu="onCancelContextMenu($event)"
                      >
                    </video>
                  </template>
                </div>
                <embed
                  v-else-if="getIsEmbeddable(file)"
                  :src="file.url"
                  :style="styles.frame"
                >
                <p
                  v-else-if="file.value"
                  v-html="file.value"
                  class="fill-height"
                  style="overflow-y: auto"
                />
                <img
                  v-else
                  :src="file.url"
                  style="max-width: 80%; max-height: calc(70vh)"
                >
              </VCol>
            </VRow>
          </VCarouselItem>
        </VCarousel>
      </VCol>
      <VCol
        sm1
        style="max-height: 100%; flex: 0 0 5vh"
        class="text-sm-center"
      >
        <VBtn
          v-if="currentFileIsDownloadable"
          color="primary"
          class="mr-2"
          @click.native="download(currentFile, true)"
        >
          <VIcon>get_app</VIcon>Download
        </VBtn>
        <VBtn
          text
          @click.native="$emit('toggle')"
        >
          <VIcon>close</VIcon>Close
        </VBtn>
      </VCol>
    </VRow>
  </VDialog>
</template>
<script>
import DownloadsFiles from "@/mixins/DownloadsFiles";
import { saveAs } from "file-saver";
export default {
  mixins: [DownloadsFiles],
  data() {
    return {
      currentFile: null,
      dialogs: {
        preview: false,
      },
    };
  },
  created() {
    this.currentFile = this.files.length ? this.files[0] : {};
  },
  computed: {
    styles() {
      return {
        frame: {
          width: this.$vuetify.breakpoint.smAndDown ? "100%" : "90vw",
          height: "80vh",
          minWidth: this.$vuetify.breakpoint.smAndDown ? "100%" : "50vw",
          maxWidth: this.$vuetify.breakpoint.smAndDown ? "100%" : "80%",
        },
        video: {},
      };
    },
    currentFileIsDownloadable() {
      if (this.$isUserAdmin) {
        return this.getIsDownloadable(this.files[this.currentFile]);
      }
      return (
        this.teamSetting("platform.enable_content_download", true) &&
        this.getIsDownloadable(this.files[this.currentFile])
      );
    },
  },
  methods: {
    getIsEmbeddableVideoLink(input) {
      return input instanceof Object && input.type === "Video_Link";
    },
    getMediaType(input) {
      let type = (input || {}).type || "video/mp4";
      if (type.indexOf("quicktime") > -1 || type.indexOf("mov") > -1) {
        return "";
      }
      return type;
    },
    getVideoUrl(input) {
      const value = input instanceof Object ? input.value || input.url : input;
      try {
        const url = new URL(value);
        let result;
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtu.be":
            const hostname =
              ["www.youtube.com", "youtube.com"].indexOf(url.hostname) === -1
                ? "www.youtube.com"
                : url.hostname;
            result = `//${hostname}/embed/${
              url.searchParams.get("v") || url.pathname.substr(1)
            }`;
            break;

          case "vimeo.com":
          case "www.vimeo.com":
            result = `//player.vimeo.com/video${url.pathname}`;
            break;

          default:
            result = value;
            break;
        }
        return result;
      } catch (e) {
        return value;
      }
    },
  },
};
</script>
<style>
#preview-files .v-carousel .v-window__container {
  display: flex;
  align-items: center;
}

#preview-files .v-carousel .v-window__container .v-window-item {
  width: 100%;
}
</style>