var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{style:({
    zIndex: 100000000,
    minHeight: _vm.minHeight
  }),attrs:{"dismissable":false,"max-width":_vm.maxWidth,"min-height":_vm.minHeight,"is-dialog-open":_vm.isDialogOpen,"hide-overlay":_vm.dialogHideOverlay,"fill-height":"","card-text-class":"py-0"},on:{"toggle:dialog":function($event){return _vm.$emit('toggle:dialog')}}},[_c('Messages',{attrs:{"mode":"dialog","ensure-thread":"","dismissable":"","title":_vm.title,"thread":_vm.realThread,"enable-edit":_vm.enableEdit,"entity":{
      title: _vm.title,
      entity_id: (_vm.entity || {}).id,
      entity_type: _vm.entityType,
      user_id: (_vm.user || _vm.$user).id,
      group: {
        members: this.members
      }
    }},on:{"toggle:dialog":function($event){return _vm.$emit('toggle:dialog')},"newThread":thread => _vm.$emit('newThread', thread)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }