<template>
  <div class="rsvp-to-event-form">
    <VRow
      v-if="calendarEntry && calendarEntry.id"
      row
      wrap
      :class="{
        'is-loading': activity.isLoading
      }"
    >
      <VCol v-if="hasMessagesOrErrors">
        <Alerts
          :messages="messages"
          :errors="errors"
        />
      </VCol>
      <VCol cols="12">
        <VCard>
          <VCardTitle v-if="mode !== 'dialog'">
            {{ calendarEntry.title || calendarEntry.calendar.title }}
          </VCardTitle>
          <VCardText v-if="calendarEntry.description || calendarEntry.calendar.description">
            <p v-html="calendarEntry.description || calendarEntry.calendar.description" />
          </VCardText>
          <VCardText class="pt-0">
            <p>
              {{ $timezone.formatDate(calendarEntry.date) }}: {{ $timezone.formatTime(calendarEntry.start_time || calendarEntry.startTime) }} to {{ $timezone.formatTime(calendarEntry.end_time || calendarEntry.endTime) }}
              <br>
            </p>
          </VCardText>
          <VCardText
            v-if="canRsvp"
            class="pt-0"
          >
            <template
              v-if="rsvpMode === 'shift'"
              style="min-height: 44px"
            >
              <VBtn
                v-show="canAccept(form)"
                depressed
                outlined
                color="success"
                :loading="form.isSaving"
                :disabled="form.isDeleting"
                @click="onAcceptRsvp(form)"
              >
                <VIcon>check_circle</VIcon>&nbsp;Accept
              </VBtn>
              <VBtn
                v-show="canReject(form)"
                depressed
                outlined
                color="error"
                :loading="form.isSaving"
                :disabled="form.isDeleting"
                @click="onRejectRsvp(form)"
              >
                <VIcon>cancel</VIcon>&nbsp;Decline
              </VBtn>
              <VChip
                color="success"
                text-color="white"
                v-if="form.is_accepted"
              >
                Accepted: {{ $timezone.formatDateTime(form.accepted_on) }}
              </VChip>
              <VChip
                color="warning"
                text-color="white"
                v-if="form.is_rejected"
              >
                Declined: {{ $timezone.formatDateTime(form.rejected_on) }}
              </VChip>
            </template>
            <VRadioGroup
              v-else
              v-model="form.status"
              :label="modeLabel"
            >
              <VRadio
                v-for="(mode, key) in options.rsvp_mode"
                :value="key"
                :key="`rsvp-mode-${key}`"
                :label="mode"
              />
            </VRadioGroup>
          </VCardText>
          <VCardText v-else>
            <VAlert
              :value="true"
              type="warning"
            >
              RSVP is unavailable
            </VAlert>
          </VCardText>
          <VCardText class="pt-0">
            <h2>
              Current RSVPs
              <VChip
                :color="rsvpCount > 0 ? 'info' : 'default'"
                :style="{
                  color: rsvpCount > 0 ? 'white' : 'black'
                }"
              >
                {{ rsvpCount }}
              </VChip>
            </h2>
            <RSVPList
              :rsvps="calendarEntry.rsvps"
              :mode="calendarEntry.rsvp_mode"
              readonly
            />
          </VCardText>
          <VCardActions
            v-if="mode !== 'dialog'"
            class="justify-center"
          >
            <VBtn
              large
              class="wethrive-alt"
              :loading="activity.isFormLoading"
              @click="onSave"
            >
              RSVP
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import { required, minLength, between } from "vuelidate/lib/validators";
import moment from "moment";
import Vue from "vue";
import RSVPList from "@/components/Elements/Data/RSVPList";
import CanRSVP from "@/mixins/CanRSVP";

export default {
  name: "RSVPToEvent",
  components: { RSVPList },
  props: {
    calendarEntry: {
      type: Object,
      default: () => {},
    },
    existing: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [FormMixin, MetadataMixin, CanRSVP],
  created() {
    // Turn calendarEntry rsvps into an observable object;
    this.$set(this.calendarEntry, "rsvps", this.calendarEntry.rsvps);
    const currentUserRsvp =
      this.calendarEntry.current_user_rsvp ||
      this.calendarEntry.currentUserRsvp;
    if (currentUserRsvp instanceof Object) {
      this.form = currentUserRsvp;
    }

    if (this.$route.params.status) {
      this.form.status = this.$route.params.status;
    }
    const unwatchCalendarEntry = this.$watch(
      "calendarEntry.id",
      function (value) {
        if (value) {
          this.form.calendar_entry = this.calendarEntry;
          this.form.calendar_entry_id = value;
          unwatchCalendarEntry();
        }
      }
    );
  },
  watch: {
    "calendarEntry.can_rsvp": function (value) {
      this.errors =
        value !== true
          ? [`RSVP for this ${this.eventType} is no longer available`]
          : this.errors;
    },
    "calendarEntry.current_user_rsvp": function (value) {
      if (value instanceof Object) {
        this.form = value;
      }
    },
    "calendarEntry.currentUserRsvp": function (value) {
      if (value instanceof Object) {
        this.form = value;
      }
    },
  },
  data() {
    return {
      modelType: "RSVP",
      options: {
        rsvp_mode: {
          yes: "Yes",
          maybe: "Maybe",
          no: "No",
        },
      },
      defaultForm: {
        status: "maybe",
      },
    };
  },
  computed: {
    rsvpCount() {
      return this.calendarEntry.rsvps_count || this.calendarEntry.rsvpsCount;
    },
    canRsvp() {
      return this.calendarEntry.can_rsvp || this.calendarEntry.canRsvp;
    },
    rsvpMode() {
      return this.calendarEntry.rsvp_mode || this.calendarEntry.rsvpMode;
    },
    modeLabel() {
      return this.rsvpMode === "standard"
        ? "Will you be attending?"
        : "Will you be taking this shift?";
    },
    eventType() {
      return this.rsvpMode === "shift" ? "shift" : "event";
    },
  },
  methods: {
    ...mapActions({
      doSaveRsvp: "calendarEntryRsvp/save",
    }),
    onSave() {
      this.form.user_id = this.form.user_id || this.$user.id;
      this.form.calendar_entry_id =
        this.form.calendar_entry_id || this.calendarEntry.id;
      const data = this.prepareFormModel(
        { ...this.form },
        {
          dontSave: ["calendarEntry", "calendar_entry"],
        }
      );
      return this.saveFormModel(this.doSaveRsvp, null, data).then((rsvp) => {
        this.form = rsvp;
        this.$set(this.calendarEntry, "current_user_rsvp", rsvp);
        Array.updateItem(this.calendarEntry.rsvps, rsvp, "user_id", true);
      });
    },
  },
};
</script>

<style scoped lang="scss">
input[type="file"] {
  display: none;
}
</style>

<style lang="scss">
@import "../../styles/variables.scss";

.v-btn.dashed {
  border: 1px solid #909295;
  border-style: dashed;
}

.v-btn.dashed {
  color: $font-light-color;
}
</style>