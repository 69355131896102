<template>
  <VCard>
    <VCardText class="pt-4">
      <Autocomplete
        v-model="model.programs"
        multiple
        class="textarea-field"
        :items="options.programs"
        :placeholder="`Attach to ${featureName('Program')}`"
      />
    </VCardText>
    <VCardActions v-if="mode === 'standalone'">
      <VBtn
        text
        @click.native="$emit('toggle:form:dialog')"
      >
        Cancel
      </VBtn>
      <VSpacer />
      <VBtn
        class="wethrive"
        @click="$emit('input', model)"
      >
        Save
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
export default {
  name: "LessonSettings",
  props: {
    existing: {
      type: Object,
      default: () => {}
    },
    assessmentType: {
      type: String,
      default: "Lesson"
    },
    options: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: "dialog"
    }
  },
  created() {
    this.model = this.existing;
  },
  data() {
    return {
      model: {}
    };
  }
};
</script>