<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <VCol cols="12">
        <TextField
          v-model="form.title"
          persistent-hint
          :placeholder="forFeature ? `` : 'Enter title...'"
          :label="forFeature ? `Name of ${model == 'faq' ? 'FAQs' : model.capitalize()}` : 'Title'"
          :error="$v.form.title.$error"
          @input="$v.form.title.$touch"
          @blur="$v.$touch()"
        />
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FormMixin from "@/mixins/Form";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "RenameCard",
  mixins: [FormMixin],
  props: {
    model: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    forFeature: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultForm: {
        title: "",
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2),
      },
    },
  },
  created() {
    this.modelType = this.model;
    if(!this.forFeature) {
      this.form = { ...this.item };
    }
  },
  methods: {
    ...mapActions({
      doGetContent: "content/getOne",
      doSaveContent: "content/save",
      doGetDeliverable: "deliverable/getOne",
      doSaveDeliverable: "deliverable/save",
      doGetLesson: "lesson/getOne",
      doSaveLesson: "lesson/save",
      doGetPipeline: "pipeline/getOne",
      doSavePipeline: "pipeline/save",
      doGetTeam: "team/getOne",
      doSaveTeam: "team/save",
    }),
    onSave() {
      if(this.forFeature) {
        this.form.feature_names[this.model] = this.form.title;
        return this.saveFormModel(this.doSaveTeam, null, null, null, false).then((team) => {
          this.$team = team;
          this.$emit("toggle:form:dialog");
          this.$bus.$emit(
          "notificationMessage",
          `Successfully ${this.model == 'faq' ? 'FAQs' : this.model.capitalize()} renamed`
        );
        });
      } else {
        let data = {
          id: this.form.id,
          title: this.form.title,
        };
        return this.saveFormModel(this[`doSave${this.model}`], null, data).then((result) => {
          this.$emit("updated", result);
        });
      }
    },
    onGet(id) {
      if(this.forFeature) {
        return this.doGetTeam({
          id: this.$team.id,
          _fields: "all",
          _relations: [
            "profile",
            "city",
            "agreements",
            "register_agreement_message",
            "registerLogic",
            "registerLogicMessage",
            "pipelines",
            "registerBanner",
            "applicationPipeline",
            "settings",
            "legacySettings.applicantPortalLearnMoreButton",
            "legacySettings.applicantPortalFaqButton",
            "legacySettings.applicantPortalExtraButtons",
          ],
        }).then((result) => {
          this.form.title = result.feature_names[this.model];
          return result;
        });
      } else {
        return this[`doGet${this.model}`](id);
      }
    },
  },
};
</script>

