<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="card-heading,table-heading,table-tbody"
    :loading="activity.isFirstLoad"
  >
    <VRow
      column
      wrap
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <ViewHeader :route-params="realDeliverable.id">
          <template #title>
            Submitted {{ featureName('Deliverables') }}
            <template v-if="realDeliverable && realDeliverable.id">
              for {{ realDeliverable.title || '(not set)' }}
            </template>
          </template>
          <template #extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="visibility"
              :tooltip="`View ${featureName('Deliverable').singularize()}`"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`deliverable.view`, {deliverable: realDeliverable.id})"
              :to="getRouteForItem(`deliverable.view`, {deliverable: realDeliverable.id})"
            />
          </template>
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <DataViewUI
          :show-expand="false"
          :expandable-rows="false"
          :data="data"
          :headers="getSubmittedHeaders()"
          :is-loading="activity.isLoading"
          :filters="allFilters"
          :custom-filter="filter"
          :disable-filter="disableFilter"
          @expand="onHandleExpand"
          @paginate="onGetMore"
          @search="onSearch"
        >
          <template
            slot="items-cells"
            slot-scope="props"
          >
            <template v-if="($isUserAdmin || $isUserMentor) && !forSingleUser">
              <td>
                <UserChip :user="props.item.user" />
                <Linkable :url="getFullyQualifiedUrlForItem(`deliverable-submitted.view`, {deliverable: props.item.deliverable_id, id: props.item.id})" />
              </td>
              <td>
                <UserChip :user="props.item.submitted_by" />
              </td>
              <td>
                {{ props.item.deliverable.title }}
                <Linkable :url="getFullyQualifiedUrlForItem(`deliverable.view`, props.item.deliverable_id)" />
              </td>
              <!-- <td v-if="props.item.entity">
            <i>{{ props.item.entity.title }}</i>
          </td>
          <td v-else-if="props.item.program">
            <strong>{{ featureName('Program') }}</strong>:
            <i>{{ props.item.program.title }}</i>
          </td>
          <td v-else-if="props.item.supplement">
            <strong>{{ featureName('Supplement') }}</strong>:
            <i>{{ props.item.supplement.title }}</i>
          </td>
          <td v-else-if="props.item.primary_content">
            <strong>{{ featureName('Content') }}</strong>:
            <i>{{ props.item.primary_content.title }}</i>
          </td>
          <td v-else>
            --
          </td> -->
              <td>
                <template v-if="hasSubmitted(props.item)">
                  {{ $timezone.formatDateTime(props.item.submitted_on) }}
                </template>
                <template v-else>
                  <VChip color="info">
                    In Progress
                  </VChip>
                </template>
              </td>
              <td
                v-for="(metadataHeader, metadataIndex) in metadataHeaders"
                :key="metadataIndex"
                :class="{
                  sticky: metadataHeader.value === 'metadata_rating'
                }"
              >
                <template v-if="metadataHeader.value === 'metadata_rating'">
                  <strong>{{ props.item.metadata_rating_sum || 0 }}</strong>
                </template>
                <template v-else>
                  <DynamicInput
                    compact
                    class="submitted-response"
                    :input="props.item.metadata && props.item.metadata.find(m => m.linked_metadata_id == metadataHeader.metadataId || m.name == metadataHeader.title)"
                  />
                </template>
              </td>
            </template>
            <template v-else>
              <td>
                {{ props.item.deliverable.title }}
                <Linkable :url="getFullyQualifiedUrlForItem(`deliverable.view`, props.item.deliverable_id)" />
              </td>
              <td v-if="props.item.entity">
                <i>{{ props.item.entity.title }}</i>
              </td>
              <td v-else-if="props.item.program">
                <strong>{{ featureName('Program') }}</strong>:
                <i>{{ props.item.program.title }}</i>
              </td>
              <td v-else-if="props.item.supplement">
                <strong>{{ featureName('Supplement') }}</strong>:
                <i>{{ props.item.supplement.title }}</i>
              </td>
              <td v-else-if="props.item.primary_content">
                <strong>{{ featureName('Content') }}</strong>:
                <i>{{ props.item.primary_content.title }}</i>
              </td>
              <td v-else>
                --
              </td>
              <td>
                <template v-if="hasSubmitted(props.item)">
                  {{ $timezone.formatDateTime(props.item.submitted_on) }}
                </template>
                <template v-else>
                  <VChip color="info">
                    In Progress
                  </VChip>
                </template>
              </td>
            </template>
            <td class="actions">
              <Menu :disabled="getIsDisabled(props.item)">
                <VListItem
                  :href="getFullyQualifiedUrlForItem(`deliverable-submitted.update`, {deliverable: props.item.deliverable_id, id: props.item.id})"
                  @click.prevent="onToggleSubmittedDialog(props.item, 'deliverables')"
                >
                  <VListItemTitle>
                    <VIcon small>
                      edit
                    </VIcon>&nbsp;Edit
                  </VListItemTitle>
                </VListItem>
                <VListItem
                  :href="getFullyQualifiedUrlForItem(`deliverable-submitted.view`, {deliverable: props.item.deliverable_id, id: props.item.id})"
                  @click.prevent="onSetViewDialogModel(props.item)"
                >
                  <VListItemTitle>
                    <VIcon small>
                      visibility
                    </VIcon>&nbsp;View
                  </VListItemTitle>
                </VListItem>
                <VListItem
                  v-if="$isUserAdmin"
                  @click="onDeleteItem(props.item)"
                >
                  <VListItemTitle color="error">
                    <VIcon
                      small
                      color="error"
                    >
                      delete
                    </VIcon>&nbsp;Delete
                  </VListItemTitle>
                </VListItem>
              </Menu>
            </td>
          </template>
          <template #expand="props">
            <VCard flat>
              <VCardText>
                <VRow
                  v-if="props.item.isLoading !== false"
                  row
                  fluid
                  justify-center
                  class="mt-4 mb-4"
                >
                  <VProgressCircular
                    indeterminate
                    color="primary"
                  />
                </VRow>
                <VAlert
                  v-else-if="!props.item.metadata || props.item.metadata instanceof Array && props.item.metadata.length == 0"
                  type="info"
                  :value="true"
                >
                  No metadata found
                </VAlert>
                <DynamicInputsView
                  v-else
                  :inputs="props.item.metadata"
                  :readonly="true"
                  :card="false"
                  :input-name="featureName('Report')"
                />
              </VCardText>
            </VCard>
          </template>
        </DataViewUI>
      </VCol>
    </VRow>
    <Confirm ref="confirmDelete" />
    <Dialog
      max-width="640px"
      card-text-class="overflow-hidden"
      :dismissable="false"
      :is-dialog-open="submittedDialogs.submitted"
      @toggle:dialog="onToggleSubmittedDialog"
    >
      <SubmitForm
        v-if="submittedDialogs.submitted && hasSubmittedModel"
        mode="dialog"
        property="program_id"
        entity-type="App\Models\Program"
        :id="submittedModel.id"
        :deliverable="submittedModel.deliverable"
        :existing="submittedModel"
        :items="submitted.data"
        :copyable-link="getFullyQualifiedUrlForItem(`deliverable-submitted.update`, { deliverable: submittedModel.deliverable_id, id: submittedModel.id })"
        @toggle:dialog="onToggleSubmittedDialog"
      />
    </Dialog>
    <Dialog
      max-width="640px"
      :is-dialog-open="dialogs.view"
      :title="`View ${featureName('Submitted Deliverable')}`"
      @toggle:dialog="onToggleViewDialog"
      :hide-overlay="true"
    >
      <ViewComponent
        :id="currentModel ? {
          id: currentModel.id,
          deliverable_id: currentModel.deliverable_id
        } : null"
        ref="viewItem"
        mode="dialog"
      />
    </Dialog>
  </VSkeletonLoader>
</template>
<script>
import DynamicInputsView from "@/components/Elements/Data/DynamicInputsView";
import DynamicInput from "@/components/Elements/Data/DynamicInput";
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import HasDueMixin from "@/mixins/HasDueOn";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import SubmitForm from "./SubmitForm";
import ViewComponent from "./ViewSubmitted";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "DeliverablesSubmitted",
  components: {
    DynamicInputsView,
    DynamicInput,
    SubmitForm,
    ViewComponent,
    ViewHeader,
  },
  mixins: [SearchMixin, HasSubmittedDataMixin, HasDueMixin],
  props: {
    forSingleUser: {
      type: Boolean,
      default: false,
    },
    useIdFromRoute: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deliverable: null,
      modelType: "deliverableSubmitted",
    };
  },
  computed: {
    realDeliverable() {
      return this.deliverable;
    },
    submittedHeaders() {
      const hidden = this.forSingleUser || this.$isUserStudent;
      return [
        {
          text: "For",
          align: "left",
          sortable: true,
          value: "user.name",
        },
        {
          text: "Submitted By",
          align: "left",
          sortable: true,
          value: "submittedby.name",
          hidden: hidden,
        },
        {
          text: this.featureName("Deliverable"),
          align: "left",
          sortable: true,
          value: "deliverable.title",
        },
        {
          text: "Submitted On",
          align: "left",
          sortable: true,
          value: "submitted_on",
        },
      ].filter((h) => !h.hidden);
    },
  },
  created() {
    let deliverableId =
      this.useIdFromRoute ? 
        (
          this.$route.params.deliverable ||
          this.$route.params.id ||
          this.$route.query.deliverable_id ||
          this.deliverableId
        ) : this.deliverableId;
    if (this.isFromExistingData) {
      deliverableId = this.deliverableId;
    }
    if (deliverableId) {
      this.filter.filter = { deliverable: [deliverableId] };
    }
    this.doGetDeliverable(deliverableId).then((deliverable) => {
      this.deliverable = deliverable;
      this.metadataHeaders = (deliverable.metadata || []).map((m) =>
        this.prepareSubmittedHeader(m)
      );
      this.setEnableSubmittedHeaders(true);
    });
  },
  methods: {
    ...mapActions({
      doGetDeliverable: "deliverable/getOne",
      doGetIndexConfig: "deliverableSubmitted/getIndexConfig",
      doGetSubmitted: "deliverableSubmitted/getAll",
      doDeleteDeliverableSubmitted: "deliverableSubmitted/delete",
      doGetDeliverableSubmitted: "deliverableSubmitted/getOne",
    }),
    onDelete(submitted) {
      return this.doDeleteDeliverableSubmitted(submitted.id);
    },
    onGetIndexConfig() {
      return this.doGetIndexConfig();
    },
    onGetAll(_params) {
      const params = _params || {};
      if (!this.forSingleUser) {
        let deliverableId =
          this.useIdFromRoute ? 
          (
            this.$route.params.deliverable ||
            this.$route.params.id ||
            this.$route.query.deliverable_id ||
            this.deliverableId
          ) : this.deliverableId;
        if(this.isFromExistingData) {
          deliverableId = this.deliverableId;
        }
        if (deliverableId) params.deliverable_id = deliverableId;
        const id = this.useIdFromRoute ?
          (this.$route.params.id || this.$route.query.deliverable_submitted_id)
          : null;
        if (id && !this.isFromExistingData) params.id = id;
      }
      params._with = ["metadata"];
      params.filter = {
        ...params.filter, 
        ...(this.customFilter || {})
      };
      return this.doGetSubmitted(params);
    },
    canDeleteSubmitted(item) {
      return this.$isUserAdmin && this.submittedFor === "all";
    },
    onHandleExpand(props) {
      if (
        props.isExpanded === true &&
        !(props.item.metadata instanceof Array)
      ) {
        const index = this.data.data.findIndex((s) => s.id == props.item.id);
        if (index > -1) {
          this.$log.debug(
            "[Submitted Deliverables]: Found submitted at index:",
            index
          );
          const item = this.data.data[index];
          this.doGetDeliverableSubmitted({
            id: item.id,
            deliverable_id: item.deliverable_id,
          }).then((submitted) => {
            submitted.isLoading = false;
            this.$set(this.data.data, index, submitted);
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
th {
  white-space: nowrap;
}
.submitted-response {
  p {
    margin-bottom: 0px;
  }
}
</style>