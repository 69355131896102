<script>
import get from "sugar/object/get";
import ActivityMixin from "@/mixins/Activity";
import HasFilesMixin from "@/mixins/HasFiles";
import { mapActions } from "vuex";
import SupportsModelTypes from "./SupportsModelTypes";

const moment = require("moment");

export default {
  mixins: [ActivityMixin, HasFilesMixin, SupportsModelTypes],
  props: {
    id: {
      type: [Number, String, Object],
      default: null,
    },
    useIdFromRoute: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "standalone",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    formConfig: {
      type: Object,
      default: () => {},
    },
    userForm: {
      type: Object,
      default: () => {},
    },
    formProps: {
      type: Object,
      default: null,
    },
  },
  data() {
    const dateIcons = {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-chevron-up",
      down: "fa fa-chevron-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-sun-o",
      clear: "fa fa-trash",
      close: "fa fa-remove",
    };
    return {
      options: {},
      errors: null,
      messages: null,
      currentMenuItem: null,
      form: {},
      defaultForm: {},
      saveOnly: null,
      dateIcons: dateIcons,
      hasFiles: false,
      formHasFiles: false,
      enableUploadProgress: false,
      fileProperties: ["files"],
      dates: {
        dueOn: {
          inline: true,
          sideBySide: true,
          minDate: moment(),
          useCurrent: false,
          icons: dateIcons,
        },
        timeOnly: {
          inline: true,
          sideBySide: true,
          minDate: moment(),
          useCurrent: false,
          icons: dateIcons,
          format: "HH:mm",
          pickDate: false,
        },
      },
      formStatus: {
        submitText: "Save",
        submitVariant: "info",
      },
      updateAfterSaveRoute: true, //Should the route be updated after saving? i.e.: If a new model is created should the rout be switched to the edit route? THis is useful for modifying related data
      canGetTypeOptions: true,
      canGetModel: true,
      canGetFormConfig: true,
      modelMeta: {},
      modelType: "Model",
      modelIsDirty: false,
      modelMergeStrategy: "merge",
      redirectOnSave: false,
      wasCreateMode: true,
      enableLeaveConfirmation: true,
      saveResult: null,
      skipFormValidation: false,
      validationMessages: {
        errors: {},
        hints: {},
        messages: {},
      },
      saveWaitTimeout: null,
      enableModelPrepare: true,
      saveOperations: [],
    };
  },
  // watch: {
  //   userForm: function(value) {
  //     if (value instanceof Object) {
  //       this.form = { ...this.form, ...this.userForm };
  //       console.log("User Form changed", this.form, this.userForm);
  //     }
  //   }
  // },
  computed: {
    formHasId() {
      return this.form.id ? true : false;
    },
    isFormInputsDisabled() {
      return this.activity.isFormLoading ? true : false;
    },
    formTitle() {
      return !this.currentModel
        ? "Create " + this.modelType
        : "Edit " + this.modelType;
    },
    isFormReady() {
      if (this.skipFormValidation) {
        return true;
      }
      const isValid = this.validateModel() && !this.activity.isFormLoading;
      this.emitReady(isValid);
      return isValid;
    },
    hasMessagesOrErrors() {
      return (
        (this.messages instanceof Array && this.messages.length > 0) ||
        (this.errors instanceof Array && this.errors.length > 0)
      );
    },
    hasErrors() {
      return this.errors instanceof Array && this.errors.length > 0;
    },
    hasMessages() {
      return this.messages instanceof Array && this.messages.length > 0;
    },
    formAction() {
      return this.id ? "Update" : "Save";
    },
  },
  watch: {
    formConfig: {
      deep: true,
      handler: function (value) {
        if (value instanceof Object) {
          this.options = { ...this.options, ...value };
        }
      },
    },
    $v: {
      handler: function (value) {
        this.$bus.$emit("toggleSave", value.$error || value.$invalid);
      },
      deep: true,
    },
  },
  mounted() {
    this.$log.debug("[FormMixin]: Created");
    this.loadFormModel();
    // Temp fix: Timeout added to fix the issue of save form/deliverable page not loading sometimes
    setTimeout(() => {
      this.loadFormConfig();
    }, 2000);
    // Make sure to remove any save form handlers
    this.$bus.$off("saveForm" + this.modelType.toLowerCase());
    this.$bus.$on("saveForm" + this.modelType.toLowerCase(), (params) => {
      this.$log.debug("[Form Mixin]: Caught save event:", this.name, params, {
        id: this.form.id,
        modelType: this.modelType,
      });
      if (
        (params.id === this.form.id || !params.id) &&
        params.modelType.toLowerCase() === this.modelType.toLowerCase()
      ) {
        if (!this.hasSaveOperation(params) || !params.id) {
          this.addSaveOperation(params);
          Promise.resolve(this.onSave()).then(result => {
            this.$log.debug("[Form Mixin]: Save event didn't exist", params);
            this.removeSaveOperation(params);
          });
        } else {
          this.$log.debug("[Form Mixin]: Save event already exists", params);
        }
      } else {
        this.$log.debug("[Form Mixin]: Not able to handle save event", params);
      }
    });
  },
  beforeDestroy() {
    // Make sure to remove the save form handler
    this.$bus.$off("saveForm" + this.modelType.toLowerCase());
    this.$log.debug("[Destroying]: ", this.name);
  },
  methods: {
    onReset() {
      this.$log.debug("[FormMixin]: Resetting form model");
      this.form = this.cloneObject({ ...JSON.parse(JSON.stringify(this.defaultForm)) });
    },
    onSetModelDirty() {
      this.modelIsDirty = true;
    },
    onSetModelClean() {
      this.modelIsDirty = false;
    },
    toggleMenuItem(item) {
      this.currentMenuItem = item.id;
    },
    parseErrors(errors) {
      errors =
        typeof errors === "string"
          ? [errors]
          : Object.values(errors).map((error) => {
              if (typeof error === "array") {
                return error[1];
              } else if (typeof error === "object") {
                return Object.values(error).length
                  ? Object.values(error).join("\n")
                  : error.message;
              } else {
                return error;
              }
            });
      return errors;
    },
    /**
     * Validate the model
     */
    validateModel() {
      if (this.skipFormValidation) {
        return true;
      }
      if (this.hasOwnProperty("$v") && this.$v instanceof Object) {
        this.$v.$touch();
        return (this.$v.$error || this.$v.$invalid) === false;
      } else {
        return true;
      }
      //return this.$v.values().filter(validation => validation.$error === false).length == 0;
    },
    validateModelAndSetErrors() {
      if (!this.validateModel()) {
        this.errors = this.getValidationErrors();
      } else {
        this.errors = null;
      }
    },
    /**
     * Get the validation errors
     */
    getValidationErrors() {
      return Object.entries(this.$v.form)
        .filter(
          (object) =>
            ["$invalid", "$dirty", "$error", "$pending", "$params"].indexOf(
              object[0]
            ) === -1
        )
        .map((entry) => {
          let key, validation;
          [key, validation] = entry;
          key = key.charAt(0).toUpperCase() + key.slice(1);

          if (validation.$invalid || validation.$error) {
            let message = Object.get(
              this.validationMessages.errors,
              key.toLowerCase()
            );
            this.$log.debug(
              "[Form Validation Errors]:",
              key.toLowerCase(),
              { ...this.validationMessages.errors },
              message
            );
            if (message) {
              return message instanceof Function
                ? message(validation.$params)
                : message;
            } else if (validation.$params.required) {
              return `${key} is required`;
            } else if (validation.$params.minLength && validation.$invalid) {
              return `${key} has a minimum length of ${validation.$params.minLength.min}`;
            } else if (validation.$params.maxLength && validation.$invalid) {
              return `${key} has a maximum length of ${validation.$params.maxLength.max}`;
            } else if (validation.$invalid) {
              return `This is not a valid value for ${key}`;
            } else if (validation.$error) {
              return `${key} has an error`;
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
        .filter((val) => val !== null);
    },
    addValidationMessages(extra) {
      const extend = extra || {};
      this.validationMessages = {
        ...this.validationMessages,
        ...extend,
      };
    },
    /**
     * Custom function that can be customized based on non standard routes
     */
    getAfterSaveRoute(result) {
      const newRoute = this.$route.name.replace("create", "update");
      if (
        this.redirectOnSave &&
        this.wasCreateMode &&
        newRoute !== this.$route.name
      ) {
        return {
          name: newRoute,
          params: {
            id: result.id,
          },
        };
      }
    },
    determineDestinationAfterSave(data) {
      const afterSaveRoute = this.getAfterSaveRoute(data);
      if (afterSaveRoute) {
        this.$router.push(afterSaveRoute);
      }
    },
    hasSaveOperation(data) {
      return this.getSaveOperationIndex(data)[0] > -1;
    },
    getSaveOperationIndex(data) {
      const hash = this.generateSaveOperationHash(data);
      return [this.saveOperations.indexOf(hash), hash];
    },
    addSaveOperation(data) {
      const index = this.getSaveOperationIndex(data);
      if (index[0] === -1) {
        this.saveOperations.push(index[1]);
      }
    },
    removeSaveOperation(data) {
      const index = this.getSaveOperationIndex(data);
      if (index[0] > -1) {
        this.saveOperations.splice(index[0], 1);
      }
    },
    generateSaveOperationHash(_data) {
      let data = { ..._data };
      if (typeof data === "object") {
        // Deep level nesting causes problems with stringifying data
        Object.keys(data).map((key) => {
          if (typeof data[key] === "object") {
            delete data[key];
          }
        });
      }
      const string =
        typeof data === "object" ? JSON.stringify(data) : data + "";
      let hash = 0,
        i,
        chr;
      if (string.length === 0) return hash;
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    /**
     * Helper for saving form models.
     * Sets activity states on the component
     */
    saveFormModel(callback, e, _data, userOptions, notify = true) {
      e && e.preventDefault();
      const hadFiles = this.hasFiles || this.formHasFiles;
      this.emitLoading();
      return this.$nextTick().then(() => {
        const data = _data || this.form;
        if (this.hasSaveOperation(data)) {
          return Promise.reject("Save in progress...");
        }
        if (this.validateModel()) {
          clearTimeout(this.saveWaitTimeout);
          this.$log.debug("[Form Mixin]: Validated");
          this.errors = this.messages = null;
          this.formStatus.submitText = this.spinnerHtml + " Saving...";
          this.wasCreateMode = !data.id;
          this.enableLeaveConfirmation = false;
          this.$log.debug("[Form Mixin]: Saving model", data);
          const options = {
            ...{ saveOnly: this.saveOnly },
            ...(userOptions || {}),
          };
          let preparedData = this.prepareFormModel(
            this.prepareFormModelFiles(data),
            options
          );

          const params = {
            id: data.id,
            data: preparedData,
            hasFiles: this.hasFiles || this.formHasFiles,
            options:
              this.hasFiles || this.formHasFiles
                ? {
                    headers: {
                      "Content-Type": "x-www-form-urlencoded",
                    },
                  }
                : {},
          };
          this.setFormSavingIndicators(true);
          this.addSaveOperation(data);
          if (params.hasFiles && this.enableUploadProgress) {
            // this.$bus.$emit('');
          }
          return callback(params)
            .then((response) => {
              let result = response.data || response;
              // Temporary fix before we switch to a global state/service model
              result = result.data
                ? result
                : {
                    data: result,
                  };
              this.saveResult = result.data;
              this.$log.debug("[Form Mixin]: Saved model", result, response);
              if (
                typeof result === "object" &&
                (result.data || {}).status === "error"
              ) {
                this.errors = result.data;
              }
              if (typeof result === "object" && result.data.id) {
                if (this.modelMergeStrategy == "merge") {
                  this.form = { ...this.form, ...(result.data || result) };
                } else {
                  this.form = result.data || result;
                }
              }
              this.form = this.cloneObject(this.form);
              this.emitLoadingDone();
              this.formStatus.submitText = "Saved";
              this.formStatus.submitVariant = "secondary";
              setTimeout(() => {
                this.formStatus.submitText = "Save";
                this.formStatus.submitVariant = "success";
              }, 2500);
              const resultMessage =
                typeof response === "object" && response.message != "ok"
                  ? response.message
                  : null;
              this.formEmitsEvents && notify &&
                this.$bus.$emit(
                  "notificationMessage",
                  resultMessage || `Saved ${this.modelType ? this.featureName(this.modelType, "singularize") : ""} successfully!`
                );
              this.resetForFiles(hadFiles);
              this.$emit("saved", result.data);
              if (this.updateAfterSaveRoute && this.redirectOnSave) {
                this.determineDestinationAfterSave(result.data);
              }
              return result.data;
            })
            .catch((error) => {
              this.$log.debug(
                "[Form Mixin]: Error saving model",
                error,
                error.response
              );
              // Only hide information if response if not a 404 since the data doesn't exist anyway
              switch (error.code) {
                case 500:
                  this.errors = this.parseErrors(
                    error.message || error.statusText
                  );
                  break;

                default:
                  this.errors = this.parseErrors(
                    error.response
                      ? error.response.data
                      : null || error.response
                      ? error.response.errors
                      : null || error.message
                  );
                  break;
              }
              this.resetForFiles(hadFiles);
              if ([404, 422, 500, 502, 503, 504].indexOf(error.code) === -1) {
                this.emitError(error);
              } else {
                throw error;
              }
            })
            .finally(() => {
              this.enableLeaveConfirmation = true;
              this.onSetModelClean();
              this.removeSaveOperation(data);
              this.setFormSavingIndicators(false, true);
              this.formStatus.submitText = "Save";
              this.emitLoadingDone();
            });
        } else {
          this.setFormSavingIndicators(false, true);
          this.removeSaveOperation(data);
          this.$log.debug("[Form Mixin]: Couldn't validate");
          this.resetForFiles(hadFiles);
          this.emitLoadingDone();
          return new Promise((resolve, reject) => {
            this.errors = this.getValidationErrors();
            reject(this.errors);
          });
        }
      });
    },
    /**
     * Helper for saving form models.
     * Sets activity states on the component
     */
    backgroundSaveFormModel(callback, e, data, userOptions, timeout) {
      return this.$nextTick().then(() => {
        e && e.preventDefault();
        const saver = () => {
          const hadFiles = this.hasFiles || this.formHasFiles;
          data = data || this.form;
          this.$log.debug("[Form Mixin]: Backgrounded  saving model", data);
          const options = {
            ...{ saveOnly: this.saveOnly },
            ...(userOptions || {}),
          };
          let preparedData = this.prepareFormModel(
            this.prepareFormModelFiles(data),
            options
          );

          const params = {
            id: data.id,
            data: preparedData,
            hasFiles: this.hasFiles || this.formHasFiles,
            options:
              this.hasFiles || this.formHasFiles
                ? {
                    headers: {
                      "Content-Type": "x-www-form-urlencoded",
                    },
                  }
                : {},
          };
          this.setFormBackgroundSavingIndicators(true);
          this.onSetModelDirty();
          return callback(params)
            .then((response) => {
              let result = response.data || response;
              // Temporary fix before we switch to a global state/service model
              result = result.data
                ? result
                : {
                    data: result,
                  };
              this.$log.debug(
                "[Form Mixin]: Saved backgrounded  model",
                result
              );

              if (
                typeof result === "object" &&
                (result.data || {}).status === "error"
              ) {
                this.errors = result.data;
              }

              this.resetForFiles(hadFiles);

              if (this.updateAfterSaveRoute && this.redirectOnSave) {
                this.determineDestinationAfterSave(result.data);
              }

              return result.data;
            })
            .catch((error) => {
              this.$log.debug(
                "[Form Mixin]: Error saving model",
                error,
                error.response
              );
              // Only hide information if response if not a 404 since the data doesn't exist anyway
              switch (error.code) {
                case 500:
                  this.errors = this.parseErrors(
                    error.message || error.statusText
                  );
                  break;

                default:
                  this.errors = this.parseErrors(
                    error.response
                      ? error.response.data
                      : null || error.response
                      ? error.response.errors
                      : null || error.message
                  );
                  break;
              }
              this.resetForFiles(hadFiles);
              if ([404, 422, 500].indexOf(error.code) === -1) {
                this.emitError(error);
              } else {
                throw error;
              }
            })
            .finally(() => {
              this.setFormBackgroundSavingIndicators(false);
              this.$bus.$emit("upload:dialog", false);
            });
          // }

          // else {
          //   this.setFormBackgroundSavingIndicators(false);
          //   this.$log.debug("[Form Mixin]: Couldn't validate");
          //   this.resetForFiles(hadFiles);
          //   return new Promise((resolve, reject) => {
          //     this.errors = this.getValidationErrors();
          //     resolve();
          //   });
          // }
        };

        clearTimeout(this.saveWaitTimeout);
        if (timeout !== true) {
          const realTimeout = parseInt(timeout) || 1000;
          this.saveWaitTimeout = setTimeout(
            () =>
              this.backgroundSaveFormModel(
                callback,
                e,
                data,
                userOptions,
                true
              ),
            realTimeout
          );
        }

        if (timeout === true) {
          return saver();
        }
        return Promise.resolve(false);
      });
    },
    toggleEmitFormEvents(value) {
      this.formEmitsEvents =
        value === false || value === true ? value : !this.formEmitsEvents;
      this.$log.info("Toggled form events to", this.formEmitsEvents);
    },
    /**
     * Prepare a form model
     */
    prepareFormModel(form, options) {
      let result = { ...form };
      const dontSave = options.dontSave || this.dontSave;
      if (dontSave instanceof Array) {
        console.log("Form Mixin: Preparing", form, "Deleting", dontSave);
        const deleter = (object, key) => {
          const parts = key.split(".");
          parts.forEach((part, index) => {
            if (parts[index + 1] === "*") {
              const subParts = parts.slice(index + 2);
              deleter(object[part], subParts.join("."));
            } else if (object instanceof Array) {
              object.forEach((v, k) => {
                deleter(v, key);
              });
            } else if (object instanceof Object) {
              if (object.hasOwnProperty(part)) {
                delete object[part];
              }
            }
          });
        };
        dontSave.forEach((key) => {
          deleter(result, key);
        });
      }

      const saveOnly = options.saveOnly || this.saveOnly;
      if (saveOnly && saveOnly instanceof Array && saveOnly.length > 0) {
        console.log("Form Mixin: Preparing", form, "Saving only", saveOnly);
        result = Object.select(form, saveOnly);
      }
      console.log("Form Mixin: Prepared", result);
      return result;
    },
    /**
     * Determine the form model to use for this component.
     * Model is loaded if there is an id on the route params
     */
    resolveFormModel(resolver, params, idKey) {
      this.$log.debug("[FormMixin]: Resolving form model");
      this.emitLoading();
      idKey = idKey || "id";
      params = params || {
        id: this.$route.params[idKey],
      };
      if (
        params &&
        ((params.params && params.params[idKey] != undefined) ||
          params[idKey] != undefined)
      ) {
        return resolver(params)
          .then((data) => {
            this.emitLoadingDone();
            if (data.items instanceof Array && data.items.length) {
              this.form = data.items[0];
            } else if (data.items.id) {
              this.form = data.items;
            }
            this.form = this.cloneObject(this.form);
            this.options = data.options.form || this.options;
            Promise.resolve(this.form);
          })
          .catch((error) => {
            this.errors = this.parseErrors(error);
          })
          .finally(() => this.emitLoadingDone());
      } else {
        return new Promise((resolve, reject) => {
          this.form = this.cloneObject(
            {
              ...(JSON.parse(JSON.stringify(this.defaultForm)) || {}),
            },
            true
          );
          this.emitLoadingDone();
          resolve(this.form);
        });
      }
    },
    getOptions(type) {},
    hasFormConfigOption(key) {
      return this.options instanceof Object && this.options.hasOwnProperty(key);
    },
    loadFormConfig() {
      if (this.canGetFormConfig && this.onGetFormConfig instanceof Function) {
        this.$log.debug(
          "[Form Mixin]: There is a form config getter. Getting form config",
          this.onGetFormConfig
        );
        this.$emit("startOptionsLoading");
        this.onGetFormConfig()
          .then((result) => {
            this.options = { ...this.options, ...result };
            return result;
          })
          .finally(() => this.$emit("doneOptionsLoading"));
      }

      if (this.formConfig instanceof Object) {
        this.options = { ...this.options, ...this.formConfig };
      }
    },
    loadFormModel(_id) {
      this.emitLoading();
      let id =
        _id || this.id || (this.useIdFromRoute ? this.$route.params.id : null);
      this.wasCreateMode = id === undefined;
      if (this.canGetModel && id && this.onGet instanceof Function) {
        this.$log.debug(
          "[Form Mixin]: There is a getter. Getting model",
          id,
          this.onGet
        );
        return this.onGet(id)
          .then((result) => {
            const model = result.data ? result.data : result;
            this.modelMeta = result.meta || {};
            this.$log.debug("[Form Mixin]: Got model", model);
            this.form = this.cloneObject(Object.assign({}, this.form, model));
          })
          .finally(() => {
            this._prepareModel();
          });
      } else {
        return this._prepareModel();
      }
    },
    _prepareModel() {
      this.$log.debug("[Form Mixin]: Preparing model", this.enableModelPrepare, this.form);
      if (!this.enableModelPrepare) return;
      this.formStatus.submitVariant = this.form.id ? "success" : "primary";
      if (this.$route.path.endsWith("/create")) {
        this.form = this.cloneObject(
          {
            ...JSON.parse(JSON.stringify(this.defaultForm)),
            ...(this.userForm || {}),
          },
          true
        );
      } else {
        if (this.modelMergeStrategy == "merge" && !this.form.id) {
          this.form = this.cloneObject(
            this.$route.params.id && this.$route.path.indexOf("create") !== -1
              ? {}
              : {
                  ...JSON.parse(JSON.stringify(this.defaultForm)),
                  ...(this.userForm || {}),
                  ...(this.form || {}),
                },
            true
          );
        }
        this.$log.debug("Cloned", this.form);
      }

      /**
       * Make sure default properties are set if they're null in the current form
       */
      for (const prop in this.defaultForm) {
        if (this.form.hasOwnProperty(prop) && !this.form[prop]) {
          this.form[prop] = this.defaultForm[prop];
        }
      }

      this.emitReady();
      this.emitLoadingDone();
    },
    resetForm() {
      this.$log.debug("[FormMixin]: Resetting form model");
      this.form = this.cloneObject({
        ...(JSON.parse(JSON.stringify(this.defaultForm)) || {}),
      });
      this.messages = this.errors = this.error = null;
      this.form.metadata = [];
      this.fileProperties.forEach((key) => {
        this.form[key] = [];
      }, true);
      this.wasCreateMode = true;
    },
    prepareRelationIds(items) {
      return items instanceof Array
        ? items
            .map((item) => (item instanceof Object ? item.id : item))
            .filter(Number)
        : [];
    },
    prepareRelationIdsWithVisibility(items) {
      if(items instanceof Array && items.length) {
        const data = items.map((item) => {
          if(item instanceof Object) {
            return {
              [item.id]: {
                visible_to: item.hasOwnProperty("pivot") ? JSON.parse(item.pivot.visible_to) : null, 
              }
            };
          }
          return {
            [item]: {
              visible_to: null,
            }
          };
        });
        return Object.assign({}, ...data );
      }
      return items;
    },
    setFormSavingIndicators(value, toggleBackground) {
      this.$set(this.form, "isSaving", !!value);
      if (this.hasFiles) {
        this.$bus.$emit("upload:dialog", !!value);
      }
      if (toggleBackground) {
        this.setFormBackgroundSavingIndicators(!!value);
      }
    },
    setFormBackgroundSavingIndicators(value) {
      this.$set(this.form, "isBackgroundSaving", !!value);
    },
    emitReady() {
      if (!this.formEmitsEvents) return;
      this.$bus.$emit("isFormReady", true);
      this.$emit("isFormReady", true);
    },
    emitNotReady() {
      if (!this.formEmitsEvents) return;
      this.$bus.$emit("isFormReady", false);
      this.$emit("isFormReady", false);
    },
    emitLoading() {
      this.$emit("startLoading");
      this.$emit("startFormLoading");
      this.$bus.$emit("startLoading");
      this.$bus.$emit("startFormLoading");
    },
    emitLoadingDone() {
      if (!this.formEmitsEvents) return;
      this.$emit("doneLoading");
      this.$emit("doneFormLoading");
      this.$bus.$emit("doneLoading");
      this.$bus.$emit("doneFormLoading");
      if (this.hasFiles) {
        this.$bus.$emit("upload:dialog", false);
      }
    },
    emitError(error) {
      this.$emit("loadFormError", error);
      this.$bus.$emit("loadFormError", error);
    },
    onAddFile(files, key) {
      const property = key || "files";
      this.form[property] = this.form[property] || [];
      this.form[property].push(files);
    },
    onRemoveFile(index, key) {
      const property = key || "files";
      if (this.form.hasOwnProperty(property)) {
        let file = [];
        if (this.form[property].length === 1) {
          file = this.form[property].splice(0, 1);
        } else {
          file = this.form[property].splice(index, 1);
        }
      }
    },
    prepareFormModelFiles(data) {
      for (var i in this.fileProperties) {
        const property = this.fileProperties[i];
        if (
          data.hasOwnProperty(property) &&
          (data[property].length || data[property] instanceof File)
        ) {
          this.hasFiles = this.formHasFiles = true;
          break;
        }
      }
      // if(this.hasFiles) {
      //     Object.keys(data).forEach(property => {
      //         if(this.fileProperties.indexOf(property) === -1) {
      //             formData.append(property, data[property] instanceof Object ? JSON.stringify(data[property]) : data[property]);
      //         } else {
      //             this.$log.debug("Checking files", data[property]);
      //             //now add all of the assigned files
      //             for (var i = 0; i < data[property]; i++) {
      //                 //add each file to the form data and iteratively name them
      //                 formData.append(property + i, data[property][i]);
      //             }
      //         }
      //     });
      // }
      return data;
    },
    resetForFiles(hadFiles) {
      this.hasFiles = this.formHasFiles = hadFiles;
    },
  },
};
</script>