<script>
import { isDate } from "util";
export default {
  props: {
    option: {
      type: Object,
      default: () => {
        option: {
        }
      }
    },
    value: {
      type: [Object, String, Number, Array],
      default: () => {}
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    trigger: {
      type: Object,
      default: () => {}
    },
    notification: {
      type: Object,
      default: () => {}
    },
    action: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showMessageInput: false,
      customMessage: "",
      realValue: null,
      currentValue: null,
      destination: null
    };
  },
  created() {
    if (
      this.option &&
      this.option.options &&
      (this.option.options.hasMessage ||
        this.option.options.hasMessageDestinations)
    ) {
      this.customMessage = this.value ? this.value.message : this.placeholder;
      this.destination = this.value && this.value.destination;
      if (this.destination instanceof Object) {
        this.destination.toOthers =
          this.destination.toOthers === true ||
          this.destination.toOthers === "true"
            ? true
            : false;
      }
      this.realValue = this.currentValue =
        (this.value && this.value.value) || this.value;
      this.showMessageInput =
        this.customMessage && this.customMessage.length >= 1;
    } else {
      this.realValue = this.currentValue = this.value;
    }
    if (this.realValue instanceof Array) {
      this.realValue = this.currentValue = this.realValue.map(value => {
        let result = value;
        if (!isNaN(parseFloat(value)) && isFinite(value)) {
          result = parseInt(value);
        }
        return result;
      });
    }
    this.syncInput();
  },
  computed: {
    optionValues() {
      return this.option.options.options instanceof Object
        ? Object.values(this.option.options.options)
        : this.option.options.options;
    },
    resolvedDestination: {
      set(value) {
        this.destination = value;
      },
      get() {
        return this.destination;
      }
    },
    variables() {
      return this.trigger instanceof Object && this.trigger.supportsVariables
        ? this.trigger.variables
        : {};
    }
  },
  methods: {
    setDestination(value) {
      this.destination = value;
      this.syncInput();
    },
    setMessage(message) {
      this.customMessage = message;
    },
    emitInput(value) {
      this.currentValue = value;
      if (
        this.option.options.hasMessage ||
        this.option.options.hasMessageDestinations
      ) {
        this.$emit("input", {
          value,
          message: this.customMessage,
          destination: this.destination
        });
      } else {
        this.$emit("input", value);
      }
    },
    syncInput() {
      if (
        this.option &&
        this.option.options &&
        (this.option.options.hasMessage ||
          this.option.options.hasMessageDestinations)
      ) {
        this.$emit("input", {
          value: this.currentValue,
          message: this.customMessage,
          destination: this.destination
        });
      } else {
        this.$emit("input", this.currentValue);
      }
    }
  }
};
</script>