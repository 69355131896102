<template>
  <VRow
    column
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      justify-content-center
      align-items-center
      cols="12"
      class="pb-0"
    >
      <TextField
        v-model="form.name"
        filled
        placeholder="Enter the question"
        class="textarea-field"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
      <LongTextField
        v-model="form.value"
        auto-grow
        filled
        class="textarea-field bg-textarea textarea-answer"
        height="100%"
        placeholder="Answer will go here"
        :disabled="true"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import SaveCardMixin from "@/mixins/SaveAssessmentCard";

export default {
  name: "SaveCardTextArea",
  mixins: [SaveCardMixin],
};
</script>

<style lang="scss">
.textarea-answer {
  height: 75%;
  .v-input__control {
    height: 100%;
  }
}
</style>
