<template>
  <VContainer
    fluid
    grid-list-lg
    fill-height
  >
    <VRow
      v-if="modelHasId"
      column
      fill-height
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>
            <VBtn
              icon
              fab
              @click="$router.go(-1)"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            View {{ featureName('Automation') }}
          </h1>
        </VRow>
      </VCol>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            cols="12"
            class="mr-2"
          >
            <VCard>
              <h1 class="display-2 text-break">
                {{ model.title }}
              </h1>
              <VCardText>
                <p
                  class="formatted"
                  v-html="model.description"
                />
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import MembersList from "@/components/Elements/Data/MembersList";

export default {
  name: "AutomationsView",
  components: {},
  mixins: [ViewMixin, HasCalendarEntries],
  data() {
    return {
      modelType: "Automation",
    };
  },
  methods: {
    ...mapActions({
      doGetAutomation: "automation/getOne",
    }),
    onGet(id) {
      return this.doGetAutomation(id).then((result) => {
        this.calendars.data = [result.calendars];
        return result;
      });
    },
  },
};
</script>

