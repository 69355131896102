<template>
  <VContainer
    fluid
    class="standard-applicant-portal px-0 py-0 fill-height"
    :style="containerStyle"
  >
    <VRow
      v-if="!activity.isLoading"
      fill-height
      :style="{
        maxHeight: $vuetify.breakpoint.mdAndUp ? '100vh' : 'auto'
      }"
    >
      <VCol
        xs="12"
        md="4"
        :class="{
          'bgImage px-8': true,
          'pr-8 pb-6': $vuetify.breakpoint.mdAndUp,
        }"
        :style="leftPanelStyle"
      >
        <VRow
          column
          fill-height
          justify-right
          justify-space-between
        >
          <VCol
            :style="{
              paddingLeft: $vuetify.breakpoint.mdAndUp ? '5vw' : '12px'
            }"
          >
            <VCard class="mb-4">
              <TeamMenuActivator
                block
                top
                large
                button-classes="dashboard-column"
                image-height="60px"
                @click="$bus.$emit('toggleTeamsDialog')"
              />
            </VCard>
            <VCard class="mb-4">
              <VBtn
                large
                block
                class="nav-item"
                @click="onToggleApplicationForm"
              >
                <VRow
                  align-center
                  justify-space-between
                >
                  <VCol
                    shrink
                    class="flex: 0 0 28px"
                  >
                    <div class="nav-item-image">
                      <VImg
                        contain
                        width="28px"
                        :src="require('../../images/edit-application-icon@2x.png')"
                      />
                    </div>
                  </VCol>
                  <VCol
                    grow
                    class="text-left"
                  >
                    <strong>
                      {{ canEditApplication ? 'Edit' : 'View' }} Application</strong>
                  </VCol>
                </VRow>
              </VBtn>
            </VCard>
            <VCard class="mb-4">
              <VBtn
                large
                block
                class="nav-item"
                @click="navigateTo('account')"
              >
                <VRow
                  align-center
                  justify-space-between
                >
                  <VCol
                    shrink
                    class="flex: 0 0 28px"
                  >
                    <div class="nav-item-image">
                      <VImg
                        contain
                        width="28px"
                        :src="require('../../images/updated-icon@2x.png')"
                      />
                    </div>
                  </VCol>
                  <VCol
                    grow
                    class="text-left"
                  >
                    <strong>Update <br>Applicant Profile</strong>
                  </VCol>
                </VRow>
              </VBtn>
            </VCard>
          </VCol>
          <VCol
            v-if="selectedPipeline.reviewers_count"
            shrink
            class="text-right"
          >
            <span
              style="cursor: pointer;"
              @click="createChatThread"
            >
              <strong>Contact Us</strong>
              <VProgressCircular
                v-if="contactUsLoader"
                indeterminate
                size="24"
                color="primary"
                class="ml-1"
                :loading="contactUsLoader"
              />
              <VIcon v-else>
                mdi-chevron-right
              </VIcon>
            </span>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        sm="12"
        md="8"
        :class="{
          'info-col bgImage px-8': true,
          'pl-10 pb-6': $vuetify.breakpoint.mdAndUp,
        }"
        :style="rightPanelStyle"
      >
        <VRow
          column
          fill-height
          justify-space-between
          align-left
          class="app_content_section"
        >
          <VCol shrink>
            <h1 :style="titleFontStyle">
              Welcome to {{ this.$team.name }}
            </h1>
            <Menu
              icon="keyboard_arrow_down"
              color="#000"
              :fab="false"
              :small="false"
              :button-attrs="{
                large: true,
                text: true,
                class: 'ml-0 pl-1'
              }"
            >
              <template slot="text">
                {{ (selectedPipeline || {}).title || "Select application" }}&nbsp;
              </template>
              <VListItem
                v-for="availablePipeline in availablePipelines"
                :key="`available-pipeline${availablePipeline.id}`"
                @click="onChangePipeline(availablePipeline)"
              >
                <VListItemContent>
                  <VListItemTitle>
                    {{ availablePipeline.title }} <VChip
                      v-if="!availablePipeline.current_user_submitted_count"
                      label
                      small
                      color="success"
                      style="cursor: pointer"
                    >
                      Apply Now!
                    </VChip>
                    <VChip
                      v-if="availablePipeline.id == pipelineDetails.id"
                      small
                      color="info"
                      style="cursor: pointer"
                    >
                      Current!
                    </VChip>
                  </VListItemTitle>
                </VListItemContent>
              </VListItem>
            </Menu>
            <VChip
              v-if="$user.submitted_application_count < availablePipelines.length"
              color="info"
              class="ml-2"
            >
              <VIcon class="shift--animation">
                {{ $vuetify.breakpoint.smAndDown ? 'expand_less' : 'chevron_left' }}
              </VIcon>
              &nbsp;New applications available!
            </VChip>
            <VDivider
              class="mt-3 primary"
              style="padding: 1px"
              width="100px"
            />
          </VCol>
          <VCol grow>
            <h2
              :class="{
                'mt-6': $vuetify.breakpoint.mdAndUp,
                'mt-2': $vuetify.breakpoint.smAndDown
              }"
              :style="primaryFontStyle"
            >
              Application
            </h2>

            <VList
              color="transparent"
              style="min-height: 40px;"
            >
              <VListItem class="info-item slightly-rounded mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    You created a(n) <b>{{ pipelineDetails.title }}</b> application.
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem class="info-item slightly-rounded mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    This application was last <span class="green--text">updated</span> on {{ $timezone.formatDateTime(pipelineDetails.updatedAt) }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem class="info-item slightly-rounded mb-3">
                <VListItemContent>
                  <VListItemSubtitle>
                    <template v-if="!currentApplicationStageForUser.is_submitted">
                      Your application has
                      <span class="red--text">NOT</span> been submitted yet. To submit your application click on <strong>Edit Application</strong>.
                    </template>
                    <template v-else>
                      You have submitted your application.
                    </template>
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                v-if="currentApplicationStageForUser.is_submitted"
                class="info-item mb-3"
              >
                <VListItemContent>
                  <VListItemSubtitle>
                    You <strong>{{ canEditApplication ? 'can' : 'can view but not' }}</strong> update your application.
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
            <h2
              class="applicant"
              :style="primaryFontStyle"
            >
              The Applicant
            </h2>
            <VRow no-gutters>
              <VCol>
                <VList color="transparent">
                  <VListItem class="info-item slightly-rounded">
                    <VListItemAvatar>
                      <VImg
                        :src="userAvatar"
                        alt="img"
                      />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemSubtitle>
                        {{ $user.name }}, <a
                          style="font-weight: bold"
                          :href="`mailto:${$user.email}`"
                        >{{ $user.email }}</a><template v-if="$user.city">
                          — {{ userLocation(true, ', ') }}
                        </template>
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                </VList>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            v-if="steps.length"
            shrink
          >
            <div class="d-flex space-between align-center">
              <h2
                class="applicant flex mb-0"
                :style="primaryFontStyle"
              >
                Timeline
              </h2>
              <VChip
                label
                medium
                style="border-radius: 10px !important; color: #fff; min-height: 38px"
                class="flex shrink px-4"
                :color="themeSetting('applicantPortal_primary_color')"
              >
                Next Notification: {{ $timezone.formatDate(nextNotification.value) }}
              </VChip>
            </div>
            <VStepper class="timeline">
              <VStepperHeader style="height: 48px">
                <template v-for="(step, stepIndex) in steps">
                  <Tooltip
                    icon-button
                    small-icon
                    icon="stop_circle"
                    class="v-stepper_step"
                    button-style="cursor: pointer; margin-top: 6px"
                    :color="$timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_primary_color') : '#ddd'"
                    :title="step.name"
                    :key="`step-${stepIndex}`"
                    :complete="$timezone.moment().isAfter(step.value)"
                  />
                  <VDivider
                    v-if="stepIndex < steps.length - 1"
                    :key="`step-divider-${stepIndex}`"
                    :style="{
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: $timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_primary_color') : '',
                      backgroundColor: $timezone.moment().isAfter(step.value) ? themeSetting('applicantPortal_primary_color') : ''
                    }"
                  />
                </template>
              </VStepperHeader>
            </VStepper>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      align-center
      fill-height
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
    <StageSubmit
      v-if="hasSubmittedApplication && dialogs.applicationForm"
      mode="dialog"
      ref="submitPipeline"
      :is-dialog-open="dialogs.applicationForm"
      :id="{
        id: currentApplicationStageForUser.id,
        pipeline_id: currentApplicationStage.pipeline_id,
        pipeline_stage_id: submittedApplication.current_stage_id
      }"
      :readonly="!canEditApplication"
      :pipeline="(submittedApplication || {}).pipeline"
      :application="submittedApplication"
      :existing="currentApplicationStageForUser"
      :stage="currentApplicationStage"
      :stage-index="currentApplicationStageIndex"
      :deadline="currentStageDeadline"
      is-inputs-required
      @toggle:dialog="() => dialogs.applicationForm = false"
      @input="submitted => $set(submittedApplication, 'current_stage_for_user', submitted)"
      @submitted="reloadPage"
    />
    <MessagesDialog
      v-if="hasSubmittedApplication"
      max-width="640px"
      entity-type="pipeline-submitted"
      :is-dialog-open="dialogs.applicantChat"
      :title="submittedApplicationThreadTitle"
      :members="(submittedApplication.reviewers || []).map(u => u.id)"
      :entity="submittedApplication"
      :thread="submittedApplication.thread_for_user"
      :user="submittedApplication.user"
      dialog-hide-overlay
      @toggle:dialog="onToggleApplicantChat"
      @newThread="thread => $set(submittedApplication, 'thread_for_user', thread)"
    />
    <StartChatDialog
      dialog-hide-overlay
      ref="startChatDialog"
      max-width="650px"
      min-height="550px"
      :thread-editable="false"
      :is-dialog-open="dialogs.startChat"
      @toggle:dialog="dialogs.startChat = !dialogs.startChat"
    />
  </VContainer>
</template>
<script>
import MessagesDialog from "@/components/Chat/MessagesDialog";
import HasApplicationPipelinesMixin from "@/mixins/HasApplicationPipelines";
import SupportsCustomizationMixin from "@/mixins/SupportsCustomization";
import StageSubmit from "@/components/Organization/Pipelines/StageSubmit";
import TeamMenuActivator from "@/components/Elements/Notifications/TeamMenuActivator";
import StartChatDialog from "@/components/Chat/StartChatDialog";
import { mapActions } from "vuex";
import moment from "moment";
import "moment-precise-range-plugin";

export default {
  name: "StandardPortal",
  components: {
    StageSubmit,
    MessagesDialog,
    TeamMenuActivator,
    StartChatDialog,
  },
  mixins: [HasApplicationPipelinesMixin, SupportsCustomizationMixin],
  props: {
    pipeline: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // steps: [
      //   {
      //     title: "September",
      //     date: "Sep 20, 2020",
      //     complete: true,
      //   },
      //   {
      //     title: "October",
      //     date: "Oct 20, 2020",
      //   },
      //   {
      //     title: "November",
      //     date: "Nov 20, 2020",
      //   },
      // ],
      contactUsLoader: false,
      dialogs: {
        startChat: false,
      },
    };
  },
  computed: {
    containerStyle() {
      return {
        maxHeight: this.$vuetify.breakpoint.mdAndUp ? "100vh" : "auto",
      };
    },
    titleFontStyle() {
      return {
        fontWeight: "600",
        fontSize: `calc(1 rem + 33%)`,
        marginBottom: "10px",
      };
    },
    primaryFontStyle() {
      return {
        fontWeight: "600",
        fontSize: "1 rem",
        marginBottom: "10px",
      };
    },
    leftPanelStyle() {
      return {
        paddingTop: "112px",
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "cover",
      };
    },
    rightPanelStyle() {
      return {
        paddingTop: this.$vuetify.breakpoint.mdAndUp ? "112px" : "24px",
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
      };
    },
  },
  created() {
    this.$emit("startLoading");
    const pipelineId =
      window.localStorage.getItem(
        `currentApplicationPipelineId${this.$team.id}`
      ) || "application";
    this.doGetApplication({
      id: "current",
      pipeline_id: pipelineId,
    })
      .then((application) => {
        this.currentApplicationModel = application;
        this.selectedPipeline = application.pipeline;
        this.onShowApplicationFormOnHash();
      })
      .catch((error) => {
        this.$log.debug("No applications");
      })
      .finally(() => {
        this.$emit("doneLoading");
      });
  },
  methods: {
    ...mapActions({
      doSaveThread: "chat/saveThread",
    }),
    createChatThread() {
      this.contactUsLoader = true;
      this.$log.debug("Applicant Contact Us Chat: Selected & Submitted Application", this.selectedPipeline, this.submittedApplication);
      const prepareThread = {
        title: "",
        user_id: this.$user.id,
        entity_type: "PipelineSubmitted",
        entity_id: this.submittedApplication.id,
        group: {
          title: "",
          members: this.selectedPipeline.reviewers.map((reviewer) => {
            return {
              user_id: reviewer.id,
              deleted: false,
            };
          }),
        },
      };
      this.$log.debug("Applicant Contact Us Chat: Creating thread", prepareThread);
      this.doSaveThread(prepareThread)
        .then((result) => {
          this.$log.debug("Applicant Contact Us : Created thread", result);
          this.$refs.startChatDialog.onSetCurrentThread(result);
          this.dialogs.startChat = !this.dialogs.startChat;
          this.contactUsLoader = false;
        })
        .catch((error) => {
          this.$log.debug("Applicant Contact Us : Create thread error", error);
        });
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss">
.standard-applicant-portal {
  a {
    color: currentColor !important;
  }
  .container {
    padding: 0px;
  }
  .bgImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .nav-item {
    &:hover {
      border: solid 2px #f3b90e;
    }
    .v-btn__content {
      font-size: 1.5rem !important;
    }
    height: 118px !important;
    border-radius: 10px;
    box-shadow: 0px 5px 9.5px 0.5px rgba(0, 0, 0, 0.05);

    .nav-item-image {
      padding: 16px 20px;
      border-radius: 10px;
      box-shadow: 0px 5px 9.5px 0.5px rgba(0, 0, 0, 0.05);
    }
  }
  .info-col {
    border-radius: 10px;
    box-shadow: 0px 15px 47.5px 2.5px rgba(0, 0, 0, 0.05);
    padding: 15px 15px 0 15px;
  }
  .app_content_section h2 {
    padding: 15px 0;
  }
  .info-item {
    border: none;
    width: fit-content;
    white-space: break-spaces;
    background-color: #f9f9fe;
  }
  .timeline {
    width: 100%;
    box-shadow: none;
    background: transparent !important;
  }
  .timeline .mdi-check::before {
    content: "" !important;
  }

  .v-stepper__step {
    padding: 16px;
    .v-stepper__step__step {
      width: 14px;
      height: 14px;
      max-width: 14px;
      min-width: 14px;
      margin-right: 0px;
    }
  }
  .theme--light.v-stepper
    .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    background: rgba(0, 0, 0, 0.15);
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    white-space: normal !important;
    color: black !important;
  }

  .item-avatar {
    width: 874px;
    height: 700px;
    border-radius: 10px;
    box-shadow: 0px 15px 47.5px 2.5px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
  }
  .green--text {
    color: #00c195 !important;
  }

  .red--text {
    color: #d96615 !important;
  }
}
</style>