<script>
import WebFont from "webfontloader";

export default {
  events: false,
  data: function () {
    return {
      loadedFonts: [],
    };
  },
  methods: {
    onLoadFont(font) {
      this.$log.debug("Trying to load font from", font);
      if (font) {
        const fontName = font instanceof Object ? font.family : font;
        if (this.loadedFonts.indexOf(fontName) === -1) {
          this.$log.debug("Loading font: ", fontName);
          let load = {
            google: {
              families: [fontName],
            },
          };
          if (font instanceof Object) {
            if (font.type == "custom") {
              load = {
                custom: {
                  families: [fontName],
                },
              };
            }
          }
          WebFont.load(load);
          this.loadedFonts.push(fontName);
        }
      }
    },
    getFontNameFrom(value) {
      this.$log.debug("Getting font name from", value);
      return value instanceof Object ? value.family : value;
    },
    getIsFontProperty(property) {
      // this.$log.debug(
      //   "Is Font?",
      //   property,
      //   /_font$|^font[a-zA-Z_]+name$/.test(property)
      // );
      return /_font$|^font[a-zA-Z_]+name$/.test(property);
    },
    loadFontsFrom(fonts) {
      this.$log.debug("Loading fonts from", fonts);
      fonts.map((value) => {
        if (value) {
          this.onLoadFont(value);
        }
      });
    },
  },
};
</script>