<template>
  <div class="pr-2">
    <slot
      name="button"
      :toggleDialog="toggleDialog"
    >
      <Badge
        overlap
        color="info"
        :value="parseInt(newMessagesCount) > 0"
      >
        <template #badge>
          <VIcon
            small
            color="white"
          >
            priority_high
          </VIcon>
        </template>
        <VBtn
          icon
          small
          :color="chatBtnColor"
          :class="{
            'slightly-rounded': true,
            'wethrive': true,
            'disabled': disableChatBtn
          }"
          @click="toggleDialog"
        >
          <VIcon small>
            chat
          </VIcon>
        </VBtn>
      </Badge>
    </slot>
    <MessagesDialog
      dialog-hide-overlay
      max-width="640px"
      min-height="550px"
      :entity-type="entityType"
      :is-dialog-open="dialog"
      :title="title"
      :members="members"
      :entity="entity"
      :thread="thread"
      :user="user"
      :enable-edit="enableEdit"
      @toggle:dialog="dialog = !dialog"
      @newThread="thread => $set(entity, 'thread_for_user', thread)"
    />
  </div>
</template>
<script>
import MessagesDialog from "@/components/Chat/MessagesDialog";

export default {
  name: "ChatButton",
  props: {
    title: {
      type: String,
      default: null,
    },
    newMessagesCount: {
      type: [Number, String],
      default: 0,
    },
    members: {
      type: Array,
      default: () => [],
    },
    entity: {
      type: Object,
      default: () => {},
    },
    entityType: {
      type: String,
      default: null,
    },
    thread: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => null,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    disableChatBtn: {
      type: Boolean,
      default: false,
    },
    chatBtnColor: {
      type: String,
      default: "white"
    }
  },
  components: {
    MessagesDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    }
  }
};
</script>