<template>
  <div 
    :class="{
      'mr-2': !altView,
    }"
  >
    <template v-if="altView">
      <VCard 
        height="92px" 
        class="d-flex align-center"
      >
        <VAvatar 
          size="52px"
          :style="{
            border: item.title ? 'none' : '1px solid #555555 !important',
            opacity: item.title ? 1 : 0.7,
            margin: '0px 20px'
          }"
          :color="getColor"
        >
          <span
            :class="{
              'white--text': getColor != '#ffffff',
              'black--text': getColor == '#ffffff',
            }"
            style="font-size: 20px; font-weight: 700;"
          >
            {{ getAvatarContent }}
          </span>
        </VAvatar>
        <span 
          style="font-weight: 500; color: #0066FF; cursor: pointer;"
          @click="toggleEmojiPicker"
        >
          Change icon & background color >
        </span>
      </VCard>
    </template>
    <template v-else>
      <VAvatar
        v-if="hasEmoji"
        size="40"
        style="cursor: pointer;"
        :color="getColor"
        @click="toggleEmojiPicker"
      >
        <span
          class="white--text"
          style="font-size: 18px;"
        >
          {{ getAvatarContent }}
        </span>
      </VAvatar>
      <VBadge
        v-else
        overlap
        bordered
        color="#ebebeb"
      >
        <template #badge>
          <VIcon
            color="#6c6c6c"
            size="18px"
          >
            mdi-plus
          </VIcon>
        </template>
        <VAvatar
          size="40"
          color="#ebebeb"
          style="cursor: pointer;"
          @click="toggleEmojiPicker"
        >
          <VIcon>mdi-emoticon-outline</VIcon>
        </VAvatar>
      </VBadge>
    </template>
    <EmojiPicker
      enable-color-picker
      ref="emojiPicker"
      :color="getColor"
      @change="onChangeEmoji"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    altView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: null,
      text: null,
      emoji: null,
    }
  },
  computed: {
    getColor() {
      if(this.color) {
        return this.color;
      } else if(this.item) {
        if(this.altView) {
          return this.item.background_color || (this.item.title ? "#FFC100" : "#ffffff");
        }
        return this.item.background_color || "#ebebeb";
      } else {
        if(this.altView) {
          return this.item.title ? "#FFC100" : "#ffffff";
        }
        return "#ebebeb";
      }
    },
    getAvatarContent() {
      if(this.emoji) {
        return this.emoji;
      } else if(this.item) {
        return this.item.emoji || this.item.title.charAt(0).capitalize();
      } else {
        return this.text;
      }
    },
    hasEmoji() {
      if(this.color || this.emoji) {
        return true;
      }
      if(this.item) {
        return this.item.emoji || this.item.background_color;
      }
      return false;
    }
  },
  methods: {
    toggleEmojiPicker() {
      this.$refs.emojiPicker.togglePicker();
    },
    onChangeEmoji(emoji, color) {
      this.emoji = emoji || this.item.emoji;
      this.color = color;
      this.$emit("change", this.emoji, this.color);
    },
    reset() {
      this.color = null;
      this.text = null;
      this.emoji = null;
      this.$refs.emojiPicker.backgroundColor = null;
    },
  },
}
</script>