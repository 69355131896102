<template>
  <VSkeletonLoader
    grid-list-lg
    fill-height
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
      >
        <template slot="title">
          {{ formAction }} {{ featureName("FAQs") }}
        </template>
      </SaveHeader>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Details</VCardTitle>
              <VCardText>
                <TextField
                  v-model="form.title"
                  label="Title"
                  placeholder="Enter title..."
                  :error="$v.form.title.$error"
                  @input="$v.form.title.$touch()"
                  @blur="$v.$touch()"
                />
                <Editor
                  v-model="form.content"
                  auto-grow
                  filled
                  label="Description"
                  placeholder="Type content here..."
                  outlined
                />
                <VRow>
                  <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
                  <VCol>
                    <GoogleDriveUI
                      style="text-align: right"
                      button-class="px-4 dashed text-right"
                      :rounded="true"
                      :outlined="true"
                      :can-connect-on-no-token="true"
                      :files="form.files"
                      @addFile="onAddFile"
                      @removeFile="onRemoveFile"
                    />
                  </VCol>
                  <VCol>
                    <FileUpload
                      text="Attach Files"
                      style="text-align: right"
                      button-class="px-4 dashed text-right"
                      icon="cloud_upload"
                      :as-button="true"
                      :outlined="true"
                      :rounded="true"
                      :multiple="true"
                      :files="form.files"
                      @removeFile="onRemoveFile"
                      @formData="onAddFile"
                    />
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
          </VCol>
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Associations</VCardTitle>
              <VCardText>
                <Autocomplete
                  v-model="form.programs"
                  multiple
                  :items="prepareCustomOptions.programs"
                  :placeholder="`Attach to ${featureName('Program')}`"
                />
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FileUpload from "@/components/Elements/Forms/FileUpload";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import { mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
import GoogleDriveUI from "@/components/Elements/Forms/GoogleDriveUI";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import DefaultAssociations from "@/mixins/DefaultAssociations.vue";

export default {
  name: "FaqsSave",
  components: {
    FileUpload,
    GoogleDriveUI,
    SaveHeader,
  },
  mixins: [FormMixin, MetadataMixin, DefaultAssociations],
  data() {
    return {
      modelType: "FAQ",
      hasFiles: true,
      options: {
        content: [],
        supplements: [],
        deliverables: [],
        programs: [],
        primary_contents: [],
      },
      defaultForm: {
        title: "",
        content: "",
        metadata: [],
        metadata_sections: [],
        deliverables: [],
        supplements: [],
        programs: [],
        primary_contents: [],
        files: [],
        visible_to: [],
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
    },
  },
  created() {
    if(this.$isUserMentor && !this.checkMentorPermission(this.modelType)) {
      this.$router.push({ name: "program.index" });
    }
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "faq/getFormConfig",
      doSaveFaq: "faq/save",
      doGetFaq: "faq/getOne",
    }),
    onNewMetadata(faq) {
      this.form.metadata.push(faq);
    },
    onRemoveMetadata(key) {
      this.form.metadata = this.form.metadata.filter((v, k) => k != key);
    },
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      this.form.supplements = this.prepareRelationIds(this.form.supplements);
      this.form.primary_contents = this.prepareRelationIds(
        this.form.primary_contents
      );
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.deliverables = this.prepareRelationIds(this.form.deliverables);
      this.form.reports = this.prepareRelationIds(this.form.reports);
      return this.saveFormModel(this.doSaveFaq);
    },
    onGet(id) {
      return this.doGetFaq(id);
    },
  },
};
</script>

