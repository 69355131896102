<template>
  <VRow class="mx-2 mt-2 additionalSave">
    <VCol
      v-if="activeTabIndex === 0"
      cols="12"
    >
      <VTabs
        fixed-tabs
        :class="{
          'form-tabs pipeline-types mb-n4': true,
          'pipeline-type-first': value.type == 'application-pipeline',
          'pipeline-type-last': value.type == 'pipeline',
        }"
        :value="applicationTypes.findIndex(type => type.value == value.type)"
        @change="onApplicationTypeChanged"
      >
        <VTab 
          v-for="(type, key) in applicationTypes"
          active-class="selected-type"
          class="rounded-lg font-weight-medium subtitle-2"
          :key="key"
        >
          {{ type.title }}
          <VTooltip top>
            <template #activator="{ on }">
              <VIcon
                v-on="on"
                class="pl-1"
              >
                mdi-information-outline
              </VIcon>
            </template>
            {{ type.tooltipText }}
          </VTooltip>
        </VTab>
      </VTabs>
    </VCol>
    <VCol cols="12">
      <TextField
        v-if="isEditTitleEnabled"
        v-model="value.title"
        autofocus
        hide-details
        placeholder="Enter title..."
        :error="validator.form.title.$error"
        @input="$emit('validated'); $emit('setModelDirty')"
        @blur="onTitleBlur"
      />
      <VRow
        v-else-if="value.title"
        class="align-center"
      >
        <VCol
          v-if="activeTabIndex > 0"
          cols="auto"
          class="pr-0"
        >
          <VAvatar
            size="40px"
            color="#FFC100"
          >
            <span class="white--text font-weight-black title">{{ value.title.charAt(0) }}</span>
          </VAvatar>
        </VCol>
        <VCol
          class="headline font-weight-black"
          style="line-height: 28px;"
          @click="activeTabIndex > 0 ? undefined : isEditTitle = !isEditTitle"
        >
          {{ value.title }}
        </VCol>
      </VRow>
      <VDivider
        v-if="value.title"
        class="mt-4"
      />
    </VCol>
    <VCol
      cols="12"
      class="pt-1"
    >
      <template v-if="activeTabIndex === 0">
        <VBtn
          color="white"
          height="40px"
          width="100%"
          style="border-radius: 10px; filter: drop-shadow(0px 6px 16px rgba(216, 220, 240, 0.5));"
          @click="settingsMenu = !settingsMenu"
        >
          <span
            class="subtitle-2 font-weight-medium"
            style="color: #252526;"
          > 
            Settings
          </span>
          <VSpacer />
          <VIcon
            right
            size="24px"
            color="#bfbfbf"
          >
            {{ settingsMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </VIcon>
        </VBtn>
        <VCard
          v-if="settingsMenu"
          class="mt-6"
          style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); border-radius: 16px;"
        >
          <VCardText class="pa-4">
            <VAutocomplete
              v-model="value.approved_user_role.value"
              dense
              outlined
              persistent-hint
              hint="This is the role that applicants will be assigned onced they have created an application"
              class="dropdown-hint"
              style="font-size: 16px;font-weight: 600;"
              :items="validRoles(options.user_roles)"
              :loading="activity.isOptionsLoading"
              @change="$emit('setModelDirty')"
            >
              <template #item="{item}">
                <VListItemContent>
                  <VListItemTitle style="font-size: 16px; font-weight: 600;">
                    {{ item.title }}
                  </VListItemTitle>
                </VListItemContent>
              </template>
            </VAutocomplete>
            <VList>
              <VListItem
                :class="{
                  'toggle-settings mb-4': true,
                  'toggle-settings__active': value.is_active,
                }"
              >
                <VListItemContent class="font-weight-medium subtitle-2">
                  Is this active?
                </VListItemContent>
                <VListItemAction>
                  <VSwitch
                    v-model="value.is_active"
                    inset
                    hide-details
                    :success="value.is_active"
                    @change="$emit('setModelDirty')"
                  />
                </VListItemAction>
              </VListItem>
            </VList>
            <Autocomplete
              v-model="value.programs"
              multiple
              append-selection-text
              label="Automatically added to"
              :searchable="false"
              :cache-items="false"
              :items="filteredPrograms"
              :placeholder="`Select ${featureName('Programs')}`"
              :loading="activity.isOptionsLoading"
              @change="$emit('setModelDirty')"
            >
              <template #prepend-item="{icon, toggle}">
                <VListItem
                  ripple
                  @click="toggle"
                >
                  <VListItemAction class="mr-4">
                    <VIcon :color="value.programs && value.programs.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </VIcon>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle style="font-size: 16px; font-weight: 600;">
                      Select All
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </template>
              <template #selection="{item}">
                <VChip
                  close
                  class="my-1"
                  @click:close="removeSelectedHub(item)"
                >
                  <VAvatar
                    left
                    size="24px"
                    :color="item.background_color || '#fdbf14'"
                  >
                    <span
                      class="white--text"
                      style="font-size: 12px; font-weight: 900;"
                    >
                      {{ item.emoji || item.title.charAt(0).capitalize() }}
                    </span>
                  </VAvatar>
                  <span
                    class="text-truncate"
                    style="font-size: 14px; font-weight: 600;"
                  >
                    {{ item.title }}
                  </span>
                </VChip>
              </template>
              <template #item="{item}">
                <VListItemAvatar size="24px">
                  <VAvatar
                    left
                    size="24px"
                    :color="item.background_color || '#fdbf14'"
                  >
                    <span
                      class="white--text"
                      style="font-size: 12px; font-weight: 900;"
                    >
                      {{ item.emoji || item.title.charAt(0).capitalize() }}
                    </span>
                  </VAvatar>
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle style="font-size: 16px; font-weight: 600;">
                    {{ item.title }}
                  </VListItemTitle>
                </VListItemContent>
              </template>
            </Autocomplete>
          </VCardText>
        </VCard>
        <VDivider class="my-4" />
      </template>
      <VRow>
        <VCol
          class="headline font-weight-black"
          :cols="activeTabIndex === 0 ? 8 : 9"
        >
          {{ `${featureName("Stage")} ${activeStageIndex + 1}: ${currentStage.title}` }}
        </VCol>
        <VCol
          class="d-flex align-center"
          :cols="activeTabIndex === 0 ? 4 : 3"
        >
          <VSpacer />
          <VMenu
            v-model="stagesMenu"
            offset-y
            content-class="no-shadow stages-menu"
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <VBtn
                v-on="on"
                v-bind="attrs"
                icon
                @click="stagesMenu = !stagesMenu"
              >
                <VIcon
                  size="24px"
                  color="#252526"
                >
                  {{ stagesMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </VIcon>
              </VBtn>
            </template>
            <VList class="rounds-list">
              <Draggable
                v-if="value.stages.length > 0"
                handle=".dynamic-stage-handle"
                :no-transition-on-drag="true"
                :animation="200"
                :value="value.stages"
                @change="(event) => {
                  $emit('stageMoved', event); 
                  $emit('setModelDirty');
                }"
              >
                <transition-group
                  name="dynamic-stages"
                  type="transition"
                >
                  <div
                    v-for="(stage, key) in value.stages"
                    :key="`dynamic-stage-${key}`"
                  >
                    <VListItem>
                      <VListItemContent class="pl-1">
                        <VRadioGroup
                          v-model="selectedStageindex"
                          hide-details
                          class="ma-0 pa-0"
                          @change="$emit('setModelDirty')"
                        >
                          <VRadio
                            color="success"
                            class="custom-radio"
                            :value="key"
                          >
                            <template #label>
                              <VListItemTitle class="text-truncate stage-title pl-2">
                                {{ `${featureName("Stage")} ${key + 1}: ${stage.title}` }}
                              </VListItemTitle>
                            </template>
                          </VRadio>
                        </VRadioGroup>
                      </VListItemContent>
                      <VListItemAction
                        v-if="activeTabIndex === 0 && value.stages.length > 1"
                        style="display: contents;"
                      >
                        <VBtn
                          v-if="isDeleteStageEnabled(stage)"
                          icon
                          class="ml-4"
                          @click="$emit('deleteStage', key)"
                        >
                          <VIcon color="error">
                            delete
                          </VIcon>
                        </VBtn>
                        <img
                          height="24px"
                          class="dynamic-stage-handle pl-2"
                          style="cursor: grabbing;"
                          src="../../../images/forms/drag-icon@2x.png"
                        >
                      </VListItemAction>
                    </VListItem>
                    <VDivider v-if="value.stages.length > 1 && key < value.stages.length - 1" />
                  </div>
                </transition-group>
              </Draggable>
            </VList>
            <VList
              v-if="activeTabIndex === 0"
              class="my-2 add-new-round"
              @click.native="$emit('addStage'); $emit('setModelDirty');"
            >
              <VListItem>
                <VListItemIcon class="pl-1 mx-0 my-3">
                  <VIcon color="#FDBF14">
                    mdi-plus-circle
                  </VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle
                    class="pl-4"
                    style="font-weight: 600; font-size: 16px; line-height: 24px; color: #FDBF14;"
                  >
                    Add New {{ featureName('Stage') }}
                  </VListItemTitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </VMenu>
          <template v-if="activeTabIndex === 0">
            <VSpacer />
            <VTooltip top>
              <template #activator="{ on }">
                <VIcon
                  v-on="on"
                  color="#bfbfbf"
                >
                  mdi-information-outline
                </VIcon>
              </template>
              <span>{{ featureName('Stage', 'pluralize') }} can be used when you have multiple phases of an Application process.</span>
              <br>
              <img
                src="../../../images/rounds-sample.png"
                class="py-2"
              >
            </VTooltip>
          </template>
        </VCol>
      </VRow>
      <template v-if="activeTabIndex === 0">
        <p class="pipeline-label pt-4">
          Deadline
        </p>
        <DateTimePicker
          v-model="value.stages[activeStageIndex].due_on"
          alt-view
          clearable
          @input="$emit('setModelDirty')"
        />
        <VList
          color="transparent"
          class="questions-list mx-n4 mt-n2"
        >
          <VListItem>
            <div
              class="d-flex"
              style="cursor: pointer;"
              @click="$emit('addNewPage'); $emit('setModelDirty')"
            >
              <VBtn
                color="#252526"
                height="40px"
                min-width="auto"
                class="px-2 mr-3"
                style="border-radius: 10px;"
              >
                <img
                  height="24px"
                  src="../../../images/forms/add-icon@2x.png"
                >
              </VBtn>
              <VListItemTitle>
                <span style="color: #252526; font-weight: 500;">
                  Add Page
                </span>
              </VListItemTitle>
            </div>
          </VListItem>
          <Draggable
            v-if="pages.length > 0"
            handle=".dynamic-page-handle"
            :no-transition-on-drag="true"
            :animation="200"
            :value="pages"
            @change="(event) => {
              $emit('pageMoved', event);
              $emit('setModelDirty');
            }"
          >
            <transition-group
              name="dynamic-pages"
              type="transition"
            >
              <VListItem
                v-for="(page, key) in pages"
                class="pt-2"
                :key="`dynamic-page-${key}`"
              >
                <VListItemTitle 
                  class="d-flex align-center"
                  :style="{
                    cursor: page !== defaultPageTitle ? 'pointer' : 'default',
                  }"
                  @click="$emit('updateDefaultPageTitle', page)"
                >
                  <VBtn
                    height="40px"
                    width="40px"
                    min-width="40px"
                    class="px-2 mr-3 no-shadow"
                    :color="page === defaultPageTitle ? 'success' : '#fff'"
                    :style="{
                      border: '1px solid #252526',
                      'border-radius': '10px',
                      cursor: page !== defaultPageTitle ? 'pointer' : 'default',
                    }"
                  >
                    <span
                      :style="{
                        'font-size': '16px', color: page === defaultPageTitle ? '#fff' : '#252526',
                      }"
                    >
                      {{ key + 1 }}
                    </span>
                  </VBtn>
                  <span
                    class="text-truncate"
                    :style="{
                      color: '#252526',
                      'font-weight': 500,
                      'max-width': $vuetify.breakpoint.lgAndUp ? '250px' : '130px',
                    }"
                  >
                    {{ page }}
                  </span>
                  <VSpacer />
                  <img
                    v-if="pages.length > 1"
                    height="24px"
                    class="dynamic-page-handle pl-2"
                    style="cursor: grabbing;"
                    src="../../../images/forms/drag-icon@2x.png"
                  >
                </VListItemTitle>
              </VListItem>
            </transition-group>
          </Draggable>
        </VList>
      </template>
      <VRow
        v-else
        class="pt-1"
      >
        <VCol>
          <VChip class="font-weight-medium my-2 subtitle-2">
            Total Applicants: {{ currentStage.applications_count || 0 }}
          </VChip>
          <VChip
            color="#60BE39"
            class="font-weight-medium my-2 pl-1 white--text subtitle-2"
          >
            <img
              src="../../../images/done-icon.png"
              height="24px"
              class="pr-2"
            >
            Submitted Applications: {{ currentStage.submitted_count || 0 }}
          </VChip>
          <VChip
            color="#FDBF14"
            class="font-weight-medium my-2 pl-1 white--text subtitle-2"
          >
            <img
              src="../../../images/in-progress-icon.png"
              height="24px"
              class="pr-2"
            >
            In Progress Applications: {{ currentStage.outstanding_count || 0 }}
          </VChip>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  name: "AdditionalSave",
  props: {
    value: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    validator: {
      type: Object,
      default: null,
    },
    currentStage: {
      type: Object,
      default: null,
    },
    pages: {
      type: Array,
      default: null,
    },
    hasMetadata: {
      type: Boolean,
      default: false,
    },
    defaultPageTitle: {
      type: String,
      default: null,
    },
    activeStageIndex: {
      type: Number,
      default: 0,
    },
    activeTabIndex: {
      type: Number,
      default: 0,
    },
    activity: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
  },
  data() {
    return {
      isEditTitle: false,
      settingsMenu: false,
      stagesMenu: false,
      applicationTypes: [
        {
          title: "Application",
          value: "application-pipeline",
          tooltipText: "Only selected applicants will join this Hub",
        },
        {
          title: "Registration",
          value: "pipeline",
          tooltipText: "All registrants will join this Hub upon submission",
        },
      ],
      selectedStageindex: this.activeStageIndex,
    }
  },
  watch: {
    activeStageIndex(index) {
      this.selectedStageindex = index;
    },
    selectedStageindex(index) {
      this.$emit("updateActiveStageIndex", index);
    },
  },
  computed: {
    isEditTitleEnabled() {
      return this.activeTabIndex < 2 && (!this.value.id || this.isEditTitle);
    },
    filteredPrograms() {
      if(this.value.approved_user_role.value == "organization-admin") {
        return this.options.programs;
      }
      return this.options.programs.filter((program) => program.visible_to instanceof Object && program.visible_to.hasOwnProperty(this.value.approved_user_role.value));
    },
  },
  methods: {
    onApplicationTypeChanged(tabIndex) {
      this.$set(this.value, 'type', this.applicationTypes[tabIndex].value);
      this.$emit("setModelDirty");
    },
    removeSelectedHub(item) {
      const index = this.value.programs.findIndex(item.id);
      if (index >= 0) this.value.programs.splice(index, 1);
    },
    onTitleBlur() {
      this.$emit('validated');
      if(!this.validator.form.title.$error) {
        this.isEditTitle = !this.isEditTitle; 
      }
    },
    isDeleteStageEnabled(stage) {
      if(this.value.hasOwnProperty("id")) {
        let savedStages = this.value.stages.filter((stage) => stage.hasOwnProperty("id"));
        if(savedStages.length > 1) {
          return true;
        }
        return stage.is_required_during_onboarding === false;
      }
      return this.value.hasOwnProperty("stages") && this.value.stages.length > 1;
    },
  },
}
</script>
<style lang="scss">
.additionalSave {
  .dropdown-hint {
    .v-text-field__details {
      padding: 8px 0px;
    }
    .v-messages__message {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #808080;
    }
  }
  .toggle-settings {
    border: 0.5px solid #eaeaea;
    border-radius: 9px;
    min-height: 40px!important;
    height: auto;
  }
  .toggle-settings__active {
    border: none;
    color: #60BE39 !important;
    background-color: rgba(96, 190, 57, 0.2);
  }
  .v-tabs-bar {
    padding: 0px !important;
  }
  .selected-type {
    color: white !important;
  }
}
.pipeline-label {
  font-size: 16px;
  font-weight: 700;
}
.stage-title {
  max-width: 200px; 
  font-weight: 600; 
  font-size: 16px;
  color: #252526;
}
.add-new-round {
  border-radius: 9px !important; 
  border: 1px solid #d3d3d347 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12) !important;
  
  .v-list-item {
    cursor: pointer; 
  }
}
.rounds-list {
  border-radius: 9px !important; 
  border: 1px solid #d3d3d347 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12) !important; 
}
</style>