var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{class:{
    'is-loading': _vm.activity.isLoading,
    'mt-n2': !_vm.isStandalone
  },attrs:{"fill-height":"","row":"","wrap":"","id":"customization"}},[(_vm.isStandalone)?_c('VCol',{attrs:{"cols":"12"}},[_c('IndexHeader',{attrs:{"mode":"standalone","can-create":_vm.$isUserAdmin},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',[_vm._v("Customize Your Workspace")])]},proxy:true},{key:"create-action",fn:function(){return [_c('ExpandableButton',{attrs:{"large":"","button-class":"wethrive-alt","text":"Save Changes","icon":"save","disabled":!_vm.isFormReady,"action":() => _vm.onSave()}})]},proxy:true}],null,false,1866632710)})],1):_vm._e(),(_vm.hasMessagesOrErrors)?_c('VCol',{attrs:{"cols":"12"}},[_c('Alerts',{attrs:{"messages":_vm.messages,"errors":_vm.errors}})],1):_vm._e(),_c('VCol',{staticStyle:{"overflow":"auto"},attrs:{"cols":"12"}},[_c('VRow',{attrs:{"justify-space-between":"","row":"","wrap":""}},[_c('VCol',{attrs:{"align-center":""}},_vm._l((_vm.groupedSettings),function(group,groupIndex){return _c('VCard',{key:`group-${groupIndex}`},[_c('VCardTitle',[_vm._v(_vm._s(groupIndex.titleize()))]),_c('VCardText',_vm._l((group),function(setting,index){return _c('VRow',{key:`setting-${groupIndex}-${index}`},[_c('VCol',{class:{
                  'pt-6': _vm.$vuetify.breakpoint.mdAndUp,
                  'text-right': _vm.$vuetify.breakpoint.mdAndUp
                },attrs:{"sm":"12","md":"5","lg":"3"}},[_vm._v(" "+_vm._s(setting.title)+" ")]),_c('VCol',[(setting.type === 'color')?_c('Swatches',{attrs:{"show-fallback":"","swatches":"text-advanced"},model:{value:(_vm.form.settings.theme[setting.relative_key].value),callback:function ($$v) {_vm.$set(_vm.form.settings.theme[setting.relative_key], "value", $$v)},expression:"form.settings.theme[setting.relative_key].value"}}):(setting.type === 'font')?[_c('Autocomplete',{attrs:{"return-object":"","item-text":"family","item-value":"family","prepend-icon":"font_download","label":"Select Font","search-input":_vm.fontFilter[setting.relative_key],"items":_vm.fonts,"loading":_vm.activity.isLoading},on:{"update:searchInput":function($event){return _vm.$set(_vm.fontFilter, setting.relative_key, $event)},"update:search-input":function($event){return _vm.$set(_vm.fontFilter, setting.relative_key, $event)},"change":_vm.onLoadFont},model:{value:(_vm.form.settings.theme[setting.relative_key].value),callback:function ($$v) {_vm.$set(_vm.form.settings.theme[setting.relative_key], "value", $$v)},expression:"form.settings.theme[setting.relative_key].value"}}),_c('p',{style:({
                      backgroundColor: '#000',
                      color: '#fff',
                      padding: '10px',
                      borderRadius: '6px',
                      fontFamily: _vm.getFontNameFrom(setting.value)
                    })},[_vm._v(" This is example text ")])]:(setting.type === 'image')?[_c('FileUpload',{on:{"files":files => _vm.form.settings.theme[setting.relative_key].value = files[0]}}),((_vm.form.settings.theme[setting.relative_key].value instanceof Object && _vm.form.settings.theme[setting.relative_key].value.url))?[_c('h3',[_vm._v("Current Image")]),_c('img',{staticClass:"my-3",staticStyle:{"max-width":"250px","cursor":"zoom-in"},attrs:{"id":`image-${setting.relative_key}`,"src":_vm.getImageUrl(_vm.form.settings.theme[setting.relative_key].value, `#image-${setting.relative_key}`)},on:{"click":function($event){$event.preventDefault();return _vm.doPreviewFiles(_vm.form.settings.theme[setting.relative_key].value)}}}),_c('br'),_c('VBtn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.form.settings.theme[setting.relative_key].value = {url: null}}}},[_vm._v(" Remove ")])]:_vm._e()]:_c('TextField',{attrs:{"placeholder":"Enter value ...","label":`Value of ${setting.relative_key.titleize()}`},model:{value:(_vm.form.settings.theme[setting.relative_key].value),callback:function ($$v) {_vm.$set(_vm.form.settings.theme[setting.relative_key], "value", $$v)},expression:"form.settings.theme[setting.relative_key].value"}})],2)],1)}),1)],1)}),1)],1),(_vm.dialogs.preview)?_c('Preview',{attrs:{"files":_vm.previewFiles},on:{"toggle":_vm.onTogglePreview}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }