var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$isUserApproved && _vm.hasSubmittedApplication)?_c('VContainer',{class:{
    'py-0 fill-height': true,
    'px-0': _vm.$vuetify.breakpoint.mdAndUp,
    'px-2': _vm.$vuetify.breakpoint.smAndDown,
  },attrs:{"fluid":""}},[(_vm.$team.is_enterprise)?_c('EnterprisePortal'):_c('StandardPortal')],1):_c('VContainer',{class:{
    'dashboard-panel': true,
    'px-0': _vm.$vuetify.breakpoint.mdAndUp,
    'px-2': _vm.$vuetify.breakpoint.smAndDown,
  },style:({
    maxWidth: '834px',
    height: 'auto'
  }),attrs:{"grid-list-lg":"","max-width":""}},[_c('VRow',[_c('VCol',[_c('h2',{staticClass:"headline"},[_vm._v(" Welcome Back, ")]),_c('h1',{staticClass:"display-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$user.first_name)+"! ")])])],1),_c('VRow',{attrs:{"align-center":"","justify-center":""}},[_c('VCol',{class:{
        'pl-0': _vm.$vuetify.breakpoint.mdAndUp,
        'pb-4 pt-0': true
      },attrs:{"cols":"12"}},[_c('PipelineApplication',{staticStyle:{"width":"100%"}})],1),_c('VCol',{class:{
        'pl-0': _vm.$vuetify.breakpoint.mdAndUp,
        'pb-1 pt-0': true
      },style:({
        flex: '0 1 417px',
        maxWidth: '417px'
      }),attrs:{"sm":"6","md":"4"}},[_c('TeamMenuActivator',{attrs:{"block":"","top":"","large":"","button-classes":"dashboard-column","image-height":"60px"},on:{"click":function($event){return _vm.$bus.$emit('toggleTeamsDialog')}}})],1),_c('VCol',{class:{
        'pl-0': _vm.$vuetify.breakpoint.mdAndUp,
        'mt-3': _vm.$vuetify.breakpoint.smAndDown,
        'pb-1 pt-0': true
      },style:({
        flex: '0 1 417px',
        maxWidth: '417px',
      }),attrs:{"sm":"6","md":"4"}},[_c('div',{staticClass:"dashboard-column px-3",style:({
          backgroundColor: _vm.$vuetify.theme.themes.light.primary,
          borderRadius: '10px'
        })},[_c('VRow',{staticClass:"fill-height px-3",attrs:{"align-center":"","justify-space-between":""}},[(_vm.activity.isLoading)?_c('VCol',[_c('VSkeletonLoader',{attrs:{"type":"avatar"}})],1):(_vm.config.threads.length > 0)?_vm._l((_vm.config.threads),function(thread,threadIndex){return _c('VCol',{key:threadIndex,staticClass:"px-1",attrs:{"shrink":""}},[_c('VBadge',{attrs:{"top":"","left":"","offset-x":"20","offset-y":"20","color":"#ff2b7a","content":thread.new_messages_for_user_count || 0,"value":thread.new_messages_for_user_count > 0}},[_c('VAvatar',{key:threadIndex,staticClass:"v-btn v-btn--rounded",style:({
                    objectFit: 'contain',
                    cursor: 'pointer'
                  }),attrs:{"width":"65px","height":"65px"},on:{"click":function($event){return _vm.navigateTo({
                    name: 'chat.index',
                    params: {
                      id: thread.thread_id
                    }
                  })}}},[((thread.to_user instanceof Object))?_c('img',{attrs:{"src":thread.to_user.photo_url}}):_c('span',[_vm._v(_vm._s(thread.title.substring(0, 2).toUpperCase()))])])],1)],1)}):_c('VCol',{attrs:{"shrink":""}},[_c('h1',{staticClass:"title white--text pa-2 text-no-wrap",attrs:{"color":"info"}},[_vm._v(" No messages ")])]),_c('VSpacer'),_c('VCol',{staticClass:"px-1",attrs:{"shrink":"","justify-self-end":""}},[_c('VBtn',{staticClass:"wethrive",staticStyle:{"height":"60px","width":"60px"},attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.navigateTo({
                name: 'chat.index'
              })}}},[_c('img',{attrs:{"width":"29","height":"26","src":_vm.chatIcon}})])],1)],2)],1)])],1),_c('MainMenu',{attrs:{"dashboard":""}}),(_vm.dialogs.attendanceDue)?_c('DueItems',{attrs:{"title":"Atttendance Due","items":_vm.dueData,"type":"Attendance"},on:{"toggle:dialog":function($event){return _vm.onToggleDue('Attendance', [])}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('VListItemContent',[_c('VListItemTitle',[_vm._v(_vm._s(item.entity ? item.entity.title : '(not set)')+": Held on: "+_vm._s(_vm.$timezone.formatDateTime(item.date)))]),_c('VListItemSubtitle',[_vm._v(_vm._s(item.entity ? item.entity.content : ''))])],1)]}},{key:"submit",fn:function({item}){return [_c('AttendanceSubmit',{attrs:{"mode":"dialog","user-form":{
          program_id: item.program_id
        }},on:{"submitted":_vm.removeSavedAttendance}})]}}],null,false,1121524485)}):_vm._e(),(_vm.dialogs.deliverablesDue)?_c('DueItems',{attrs:{"title":"Deliverables Due","items":_vm.dueData},on:{"toggle:dialog":function($event){return _vm.onToggleDue('Deliverables', [])}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('VListItemTitle',[_vm._v(_vm._s(item.title))])]}},{key:"submit",fn:function(deliverableParent){return [_c('DeliverablesSubmit',{attrs:{"mode":"dialog","property":"program_id","entity-type":"App\\Models\\Program","parent-uid":deliverableParent.uid,"deliverable":deliverableParent.item},on:{"submitted":_vm.removeSavedDeliverable}})]}}],null,false,2712020409)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }