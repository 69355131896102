<template>
  <VCard class="chat-threads overflow-hidden layout column mx-0">
    <VCard class="chat-threads-list overflow-hidden">
      <VCardText class="px-2 py-0">
        <VList
          v-if="hasThreads"
          v-infinite-scroll="getThreads"
          two-line
          :infinite-scroll-disabled="isLoading"
          infinite-scroll-distance="10"
          :style="{
            height: 'calc(100% - 58px)',
            overflowY: 'auto'
          }"
        >
          <VListItem
            v-for="(thread, index) in threads.data"
            :key="index"
          >
            <VListItemContent>
              <VListItemTitle v-html="getThreadTitle(thread)" />
              <VListItemSubtitle v-html="getMessage(thread)" />
              <VListItemSubtitle class="date">
                <small>Started {{ thread.date }}</small>
                <template v-if="getMessage(thread) && getMessage(thread) .length">
                  |
                  <small>
                    <b>Last message: {{ thread.last_message ? thread.last_message.date : thread.date }}</b>
                  </small>
                </template>
              </VListItemSubtitle>
              <template v-if="thread.group_id">
                <Threads :threads="threadThreads" />
              </template>
            </VListItemContent>
            <VListItemAction>
              <VCheckbox
                v-if="mode === 'multiple'"
                v-model="filteredThreadIds"
                multiple
                color="primary"
                style="height: auto"
                :value="thread.id"
                @change="(value) => handleThreadChoice(thread, value)"
              />
              <VRadio
                v-else
                v-model="filteredThreadIds"
                multiple
                color="primary"
                style="height: auto"
                :value="thread.id"
                @change="(value) => handleThreadChoice(thread, value)"
              />
            </VListItemAction>
          </VListItem>
        </VList>
        <VList
          v-else
          two-line
        >
          <VListItem :key="thread.title">
            <VListItemContent>
              <VListItemTitle>No Messages</VListItemTitle>
              <VListItemSubtitle>
                <VBtn
                  v-if="$route.name !== 'chat.index'"
                  class="wethrive"
                  large
                  @click="handleCreate"
                >
                  Add
                  One Here
                </VBtn>
                <p
                  v-else
                  class="text-center mt-6"
                >
                  Start one on the right
                </p>
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCardText>
    </VCard>
  </VCard>
  <!-- .b__messanger-dialogs -->
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import Threads from "@/components/Chat/Threads";

export default {
  extends: Threads,
  components: {},
  props: {
    currentThread: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: "single"
    },
    existing: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      s: "",
      filteredUsers: {
        data: []
      },
      threadIds: [],
      filteredThreadIds: [],
      isSaving: false
    };
  },
  created() {
    this.threadIds = this.existing.map(thread =>
      thread instanceof Object ? thread.id : thread
    );
    this.isSaving = false;
  },
  methods: {
    getThreadTitle(thread) {
      return thread.group_id
        ? thread.title
        : thread.to_id == this.$user.id
        ? thread.user
          ? thread.user.name
          : ""
        : thread.to
        ? thread.to.name
        : "";
    },
    getMessage(thread) {
      try {
        let message = thread.last_message;
        if (message && message.message) {
          const text =
            message.message.length > 140
              ? message.message
              : message.message.substr(0, 140) + "...";
          return text + " - " + message.user.name;
        }
      } catch (e) {
        this.$log.warn(e);
        return "";
      }
    },
    // onSearch() {
    //   if (this.s.length >= 3) {
    //     this.getUsers();
    //   }
    // },
    onToggleMultipleThread(thread) {
      if (this.filteredThreadIds.indexOf(thread.id) > -1) {
        this.onRemoveThread(thread);
      } else {
        this.onAddThread(thread);
      }
    },
    onAddThread(thread) {
      // this.users.data.push(thread);
      this.filteredThreadIds.push(thread.id);
      this.filteredThreadIds = this.filteredThreadIds.unique();
      this.$emit("addThread", thread);
    },
    onRemoveThread(thread) {
      // this.users.data = this.users.data.filter(user => user.id !== thread.id);
      this.filteredThreadIds = this.threadIds.filter(id => id !== thread.id);
      this.$emit("removeThread", thread);
    },
    onToggleThread(thread, value) {
      this.$log.debug(thread, value);
      this.filteredThreadIds =
        this.filteredThreadIds.indexOf(thread.id) !== -1 ? [] : [value];
    },
    onSaveThreads() {
      this.isSaving = true;
      this.threadIds = this.threadIds.concat(this.filteredThreadIds).unique();
      this.$emit("saveThreads", this.threadIds);
    },
    onResetFilter() {
      this.s = "";
      this.syncFilteredThreads();
      this.filteredThreadIds = [];
    },
    handleThreadChoice(user, value) {
      //   if (this.mode === "multiple") {
      //     this.onToggleMultipleThread(user);
      //   } else {
      //     this.onToggleThread(user);
      //   }
      this.onSaveThreads();
    }
  }
};
</script>