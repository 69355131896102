<template>
  <AppLayout>
    <OrganizationSettings
      slot="main-content"
      class="organization-settings-page"
    />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import OrganizationSettings from "@/components/Organization";

export default {
  name: "OrganizationSettingsProfile",
  components: {
    OrganizationSettings,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>
