<template>
  <VCard
    id="presentation-card"
    light
    :class="{
      'fill-height': true,
      'd-flex elevation-0 slightly-rounded': true
    }"
    :style="{
      borderRadius: '5px !important',
      marginTop: 0,
      maxWidth: $vuetify.breakpoint.mdAndUp ? '1100px' : '100%'
    }"
  >
    <VCardTitle
      v-if="mode === 'standalone' && card.type != 'Final'"
      :style="{
        height: '64px',
        position: 'absolute',
        top: 0,
        left: '8px',
        right: '8px',
        borderBottom: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
      }"
    >
      <VRow
        row
        wrap
        align-center
      >
        <VSpacer />
        <VCol
          v-if="env.isDev"
          shrink
          cols="2"
        >
          {{ card.type }}
        </VCol>
        <VCol
          shrink
          v-if="!dialogs.completeMessage"
        >
          <VBtn
            v-if="!isFirst"
            style="text-transform: none"
            text
            @click="saveForLater"
          >
            <span
              v-show="$vuetify.breakpoint.mdAndUp"
              class="mr-2"
            >
              Save for later
            </span>
            <VIcon
              size="19"
              :color="isSaved ? '#efc013': 'rgba(0, 0, 0, 0.55);'"
            >
              {{ isSaved ? 'star' : 'star_outline' }}
            </VIcon>
          </VBtn>
        </VCol>
        <VCol
          v-if="!isLast"
          shrink
          pr-0
        >
          <VBtn
            text
            icon
            @click="close"
            color="gray"
          >
            <VIcon color="gray">
              close
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText
      :class="{
        'fill-height': true,
        'px-0': $vuetify.breakpoint.smAndDown,
        'pt-0': $vuetify.breakpoint.smAndDown,
        'flex xs12 align-self-center justify-self-center' : true
      }"
      :style="{
        overflowY: $vuetify.breakpoint.smAndDown ? 'auto' : 'hidden',
        overflowX: 'hidden',
        paddingLeft: '14px',
        paddingRight: '14px',
        paddingTop: '12px',
        paddingBottom: '14px',
        marginTop: $vuetify.breakpoint.smAndDown ? '0px' : 'auto'
      }"
    >
      <VRow
        align-center
        justify-center
        row
        wrap
        :style="{
          width: '100%',
          margin: 'auto 0',
          height: ['Imgae', 'Video', 'Final'].includes(card.type) ? '100%' : 'calc(100% - 50px)'
        }"
      >
        <VCol
          v-if="hasImages && !dialogs.completeMessage"
          :class="{
            'pa-0': $vuetify.breakpoint.mdAndUp
          }"
          :style="{
            width: carousel.width,
            flex: $vuetify.breakpoint.smAndDown ? '1 1 100%' : ('0 0 ' + carousel.width)
          }"
        >
          <VCarousel
            hide-delimiters
            class="presentation-card-images"
            :show-arrows="images.length > 1"
            :id="`presentation-card-images-${card.id}`"
            :width="carousel.width"
            :height="carousel.height"
            :style="{
              maxHeight: carousel.height,
              overflowX: 'hidden',
              borderRadius: '6px',
              marginTop: $vuetify.breakpoint.smAndDown ? '64px' : 'inherit'
            }"
            :class="{
              'elevation-0': true,
              'px-0': $vuetify.breakpoint.mdAndUp,
              'px-0': $vuetify.breakpoint.smAndDown
            }"
          >
            <VCarouselItem
              v-for="(image, imageIndex) in images"
              :src="image.url"
              :key="imageIndex"
              :style="{
                borderRadius: '20px',
                cursor: 'zoom-in'
              }"
              @click="dialogs.preview = true"
            />
          </VCarousel>
          <Preview
            v-if="dialogs.preview"
            :files="images"
            @toggle="dialogs.preview = !dialogs.preview"
          />
        </VCol>
        <VCol
          class="presentation-card-content"
          :class="{
            'px-2': $vuetify.breakpoint.smAndDown,
            'pa-0': $vuetify.breakpoint.mdAndUp,
          }"
          :style="{
            overflowX: 'hidden',
            height: $vuetify.breakpoint.smAndDown ? 'auto' : '100%',
            flex: `1 0 calc(100% - ${carousel.width})`,
            overflowY: $vuetify.breakpoint.smAndDown ? 'auto' : 'hidden',
            paddingBottom: $vuetify.breakpoint.smAndDown ? '100px' : '64px',
            marginTop: !['Imgae', 'Video', 'Final'].includes(card.type) && mode === 'standalone' && $vuetify.breakpoint.mdAndUp ? '50px' : '0'

          }"
        >
          <component
            class="mx-0"
            :style="{
              width: '100%',
              overflowY: 'auto',
              maxHeight: ['Imgae', 'Video', 'Final'].includes(card.type) ? '100%' : 'calc(100% - 50px)',
            }"
            :is="getComponent(card.type)"
            :card="card"
            :mode="mode"
            :index="index"
            :is-current="isCurrent"
            :is-first="isFirst"
            :is-last="isLast"
            :assessment="assessment"
            :result="card.result_for_user"
            :is-completing="isCompleting"
            @next="navigate('next')"
            @input="data => stashResultCard(data, true)"
            @complete="onComplete"
          />
        </VCol>
      </VRow>
    </VCardText>
    <VCardActions
      v-if="enableActions"
      id="presentation-card-actions"
      class="flex xs12 justify-space-between align-items-center align-self-end px-2"
      :style="{
        height: '68px',
        right: '0',
        position: 'absolute',
        left: '0',
        bottom: '0',
        zIndex: 0
      }"
    >
      <VRow
        row
        wrap
        align-center
        v-if="!dialogs.completeMessage"
      >
        <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
        <VCol
          sm="2"
          md="auto"
          order-sm="2"
          order="1"
        >
          <small
            v-show="!canProgress"
            class="red--text"
          >This card is required!</small>
        </VCol>
        <VSpacer v-if="$vuetify.breakpoint.mdAndUp" />
        <VCol
          shrink
          sm="10"
          md="auto"
          order-sm="1"
          order="2"
        >
          <VBtn
            v-if="!isFirst"
            text
            color="rgba(152, 152, 152, 0.95)"
            style="background-color: rgba(255,255,255, 0.75)"
            @click="navigate('previous')"
          >
            Previous
            <!-- <VIcon>arrow_left</VIcon> -->
          </VBtn>
        </VCol>
        <VCol
          shrink
          sm="10"
          md="auto"
          order-sm="3"
          order="3"
        >
          <VBtn
            v-if="!isLast"
            depressed
            class="mx-2"
            color="success"
            :disabled="!canProgress"
            @click="navigate('next')"
          >
            Next
          </VBtn>
          <VBtn
            v-if="isLast && !dialogs.completeMessage && !isPreview"
            color="success"
            class="px-4"
            :loading="isCompleting"
            :disabled="isCompleting || !canProgress"
            @click="onComplete"
          >
            <VIcon
              small
              class="pr-2"
            >
              check
            </VIcon>
            <span>Complete Lesson</span>
          </VBtn>
        </VCol>
      </VRow>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapActions } from "vuex";
import TextInput from "./TextInput";
import TextArea from "./TextArea";
import Question from "./Question";
import Video from "./Video";
import Worksheet from "./Worksheet";
import Title from "./Title";
import Paragraph from "./Paragraph";
import ImageView from "./Image";
import Result from "./Result";
import Final from "./Final";
import utils from "@/store/utils";
import PresentationCardMixin from "@/mixins/PresentationCard";
import Preview from "@/components/Elements/Dialogs/Preview";

export default {
  name: "PresentationCard",
  mixins: [PresentationCardMixin],
  components: {
    TextInput,
    TextArea,
    Question,
    Video,
    ImageView,
    Worksheet,
    Title,
    Paragraph,
    Result,
    Preview,
    Final,
  },
  props: {
    assessment: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    returnTo: {
      type: Object,
      default: () => null,
    },
    index: {
      type: [String, Number],
      default: null,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Lesson",
    },
    mode: {
      type: String,
      default: "standalone",
    },
  },
  data() {
    return {
      dialogs: {
        preview: false,
        completeMessage: false,
      },
      isCompleting: false,
      modelType: "PresentationCard",
      currentResultCardForm: null,
      currentResultCardFormIsDirty: false,
      previousCard: {},
      isBackToDefault: false,
    };
  },
  mounted() {
    if (this.mode === "standalone") {
      if (!this.assessment.result_for_user) {
        this.doSaveResult({
          assessment_id: this.assessment.id,
          current_card_id: this.card.id,
        }).then((result) => {
          this.assessment.result_for_user = result.data || result;
          this.stashResultCard(result);
        });
      } else {
        const assessmentId =
          this.card.attached_assessment instanceof Object
            ? this.card.attached_assessment.id
            : this.assessment.id;
        this.stashResultCard(
          this.card.result_for_user || {
            assessment_id: assessmentId,
            assessment_card_id: this.card.id,
          }
        );
      }
    }
    if (
      this.$route.params &&
      this.$route.params.isBackToDefault !== undefined
    ) {
      this.isBackToDefault = this.$route.params.isBackToDefault;
    }
  },
  computed: {
    carousel() {
      return {
        height: "450px",
        width: this.$vuetify.breakpoint.mdAndUp ? "450px" : "100%",
      };
    },
    isSaved() {
      return (
        this.card.saved_for_user instanceof Object &&
        this.card.saved_for_user.id > 0
      );
    },
    hasImageOrVideo() {
      return (
        this.card.image instanceof Object ||
        this.card.video instanceof Object ||
        this.card.media instanceof Object
      );
    },
    cards() {
      if (this.assessment instanceof Object) {
        if (this.assessment.all_cards instanceof Array) {
          return this.assessment.all_cards;
        }
        if (this.assessment.cards instanceof Array) {
          return this.assessment.cards;
        }
      }
      return [];
    },
    canProgress() {
      const result = this.card.result_for_user || {};
      const form = this.currentResultCardForm || {};
      if (!this.card.is_required) {
        return true;
      }
      switch ((this.card.type || "").toLowerCase()) {
        case "question":
          return (result.value || form.value || []).length > 0;
          break;

        case "textarea":
        case "text":
          return (result.value || form.value || "").length > 0;
          break;

        case "file":
          return (
            (result.value || form.value || {}).url ||
            (form.value || result.value) instanceof File
          );
          break;
      }
    },
    enableActions() {
      return (
          !this.isFirst && !this.dialogs.completeMessage 
          || (this.isFirst && this.card.type !== 'Title') 
          && this.mode === 'standalone' 
          || (this.isFirst && this.card.type != 'title')
        ) && this.card.type != 'Final';
    },
  },
  watch: {
    index: function () {
      this.stashResultCard(this.card.result_for_user);
    },
  },
  methods: {
    ...mapActions({
      doSaveForLater: "assessmentSaved/save",
      doDeleteSaved: "assessmentSaved/delete",
      doSaveResultCard: "assessmentResult/saveCard",
      doSaveResult: "assessmentResult/save",
      doCompleteResult: "assessmentResult/complete",
    }),
    saveForLater() {
      if (this.card.saved_for_user instanceof Object && this.card.id) {
        this.card.saved_for_user = null;
        this.doDeleteSaved(this.card.id).then(
          (result) => (this.card.saved_for_user = null)
        );
      } else {
        this.card.saved_for_user = {
          id: this.card.id,
        };
        this.doSaveForLater({
          assessment_id: this.assessment.id,
          type: this.assessment.type,
          assessment_card_id: this.card.id,
        }).then((result) => {
          this.card.saved_for_user = result;
        });
      }
    },
    stashResultCard(_params, isDirty) {
      this.currentResultCardForm = _params;
      this.currentResultCardFormIsDirty = !!isDirty;
    },
    saveResultCard() {
      if (
        this.currentResultCardFormIsDirty &&
        this.currentResultCardForm instanceof Object &&
        this.assessment instanceof Object &&
        this.assessment.result_for_user instanceof Object
      ) {
        const params = this.currentResultCardForm;

        // Immediately update the result information. Useful for result cards
        if (this.cards instanceof Array) {
          const card = this.assessment.all_cards.find(
            (c) => c.id === params.assessment_card_id
          );
          if (card) {
            card.result_for_user = {
              ...(card.result_for_user || {}),
              ...params,
            };
          }
        }

        return this.doSaveResultCard({
          ...{
            assessment_id: this.assessment.id,
            assessment_result_id: this.assessment.result_for_user.id,
            assessment_card_id: this.card.id,
          },
          ...params,
        }).then((resultCard) => {
          if (this.cards instanceof Array) {
            const card = this.assessment.all_cards.find(
              (c) => c.id === resultCard.assessment_card_id
            );
            if (card) {
              card.result_for_user = {
                ...(card.result_for_user || {}),
                ...resultCard,
              };
            }
          }
          this.currentResultCardFormIsDirty = false;
          return resultCard;
        });
      }
    },
    navigate(direction) {
      this.saveResultCard();
      this.$emit(direction);
    },
    onComplete() {
      this.isCompleting = true;
      this.saveResultCard();
      this.doCompleteResult({
        assessment_id: this.assessment.id,
        assessment_result_id: this.assessment.result_for_user.id,
      })
        .then((result) => {
          this.card.result_for_user = result.data || result;
          this.dialogs.completeMessage = true;
          setTimeout(() => {
            this.dialogs.completeMessage = false;
            if(this.isBackToDefault) {
              this.$router.go(-1);
            } else {
              this.navigateTo({
                name: "lesson.index",
              });
            }
          }, 10000);
        })
        .catch(() => {
          this.$bus.$emit("notificationError", "Something went wrong! Please try again or contact customer support.");
          this.navigate("previous");
        })
        .finally(() => {
          this.isCompleting = false;
        });
    },
    getComponent(type) {
      switch (type) {
        case "Text":
          return "TextInput";
          break;
        case "Image":
          return "ImageView";
          break;
        default:
          return type;
          break;
      }
    },
    close() {
      if (this.isBackToDefault) {
        this.$router.go(-1);
      } else {
        if (this.mode === "dialog") {
          this.$emit("toggle:dialog");
        } else {
          this.navigateTo(
            this.returnTo || {
              name: `${this.type.parameterize().singularize()}.index`,
            }
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
.presentation-card-images {
  .v-carousel {
    .v-window__container {
      min-height: 450px;
    }
  }
  .v-image__image {
    background-size: contain;
  }
}
.presentation-card-content {
  > .layout {
    margin: auto 0;
  }

  .buttons {
    width: fit-content;
    margin-left: auto;
  }

  .arrows {
    width: fit-content;
    margin-left: auto;
  }
}
</style>
