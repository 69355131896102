<script>
export default {
  data() {
    return {
      errors: [],
      messages: [],
      formEmitsEvents: true,
      emitsEvents: true,
      activity: {
        isLoading: true,
        isFormLoading: false,
        isOptionsLoading: false,
        isReady: false,
        isFirstLoad: true,
      },
    };
  },
  computed: {
    isAuthReady: {
      set: function () {
        this.activity.isLoading = false;
        this.activity.isReady = true;
      },
      get: function () {
        let doneLoading =
          this.activity.isLoading === false && this.activity.isReady === true;
        if (this.$route.meta.auth != undefined) {
          return this.$auth.ready() && doneLoading;
        } else {
          return doneLoading === true;
        }
      },
    },
    hasMessagesOrErrors() {
      return (
        (this.messages && this.messages.length > 0) ||
        (this.errors && this.errors.length > 0)
      );
    },
  },
  created() {
    //Listen to loading events
    this.$on("startLoading", () => {
      this.activity.isLoading = true;
      this.activity.isReady = false;
    });
    this.$on("doneLoading", () => {
      this.activity.isLoading = false;
      this.activity.isReady = true;
      this.activity.isFirstLoad = false;
    });
    this.$on("loadError", () => {
      this.activity.isLoading = false;
      this.activity.isReady = true;
    });
    this.$on("startFormLoading", () => {
      this.activity.isFormLoading = true;
    });
    this.$on("doneFormLoading", () => {
      this.activity.isFormLoading = false;
      this.activity.isFirstLoad = false;
    });
    this.$on("loadFormError", () => {
      this.activity.isFormLoading = false;
    });
    this.$on("startOptionsLoading", () => {
      this.activity.isOptionsLoading = true;
    });
    this.$on("doneOptionsLoading", () => {
      this.activity.isOptionsLoading = false;
      this.activity.isFirstLoad = false;
    });
    this.$on("searchStart", () => {
      this.$log.debug("[Search]: Start");
      this.$emit("startLoading");
    });
    this.$on("searchComplete", (result) => {
      this.$log.debug("[Search]: Complete", result);
      // this.data = result;
      this.$emit("doneLoading");
    });
    this.$on("notificationError", (error) => {
      this.$log.debug("[Nofification Error]", error);
      this.errors = error instanceof Array ? error : [error];
    });
    this.$on("notificationMessage", (message) => {
      this.$log.debug("[Nofification Message]", message);
      this.messages = message instanceof Array ? message : [message];
    });
  },
  methods: {
    emitReady() {
      if (!this.formEmitsEvents) return;
      this.$bus.$emit("isFormReady", true);
      this.$emit("isFormReady", true);
    },
    emitNotReady() {
      if (!this.formEmitsEvents) return;
      this.$bus.$emit("isFormReady", false);
      this.$emit("isFormReady", false);
    },
    emitLoading() {
      this.$emit("startLoading");
      this.$emit("startFormLoading");
      this.$bus.$emit("startLoading");
      this.$bus.$emit("startFormLoading");
    },
    emitLoadingDone() {
      if (!this.formEmitsEvents) return;
      this.$emit("doneLoading");
      this.$emit("doneFormLoading");
      this.$bus.$emit("doneLoading");
      this.$bus.$emit("doneFormLoading");
    },
    emitError(error) {
      this.$emit("loadFormError", error);
      this.$bus.$emit("loadFormError", error);
    },
  },
};
</script>