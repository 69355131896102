import api from "../../services/deliverableSubmitted";
export default function (helper) {
	// Deliverable Submitted types
	const TYPES = {
		STORE_SAVE: `STORE_SAVE`,
		SET_DELIVERABLE_SUBMITTED_BY_ME: "SET_DELIVERABLE_SUBMITTED_BY_ME",
	};

	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types("deliverable_submitted");
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions(
		{
			/**
			 * Save the given data to the store
			 * @param {Object} context
			 * @param {Object} params
			 * @returns {Promise}
			 */
			save (context, params) {
				helper
					.log()
					.info(
						`[Store: Deliverable Submitted]: Save Deliverable Submitted`,
						params
					);
				return new Promise((resolve, reject) => {
					return helper
						.api()
						.save(params)
						.then((response) => {
							helper
								.log()
								.info(
									`[Store: Deliverable Submitted]: Saved Deliverable Submitted`,
									response
								);
							const data = response.data.data;
							context.commit(TYPES.STORE_SAVE, {
								type: "deliverable_submitted",
								context,
								params,
								result: data,
							});
							context.commit(TYPES.SET_DELIVERABLE_SUBMITTED_BY_ME, {
								type: "deliverable_submitted",
								context,
								params,
								result: data,
							});
							resolve(data);
						})
						.catch((error) => {
							helper
								.log()
								.info(
									`[Store: Deliverable Submitted]: Error Saving Deliverable Submitted`,
									error
								);
							reject(error);
						});
				});
			},
		},
		"Submitted Deliverables"
	);
	const mutations = helper.mutations(
		{
			[TYPES.SET_DELIVERABLE_SUBMITTED_BY_ME] (state, data) {
				const deliverable = (data.result || data).deliverable;
				deliverable.submitted_by_me = data.result || data;
				this.commit(
					"deliverable/STORE_UPDATE",
					{
						result: deliverable,
					},
					{
						root: true,
					}
				);
				return data;
			},
		},
		types,
		state
	);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations,
	};
}
