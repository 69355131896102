<template>
  <VCard id="people-import-card">
    <VCardTitle>
      <VRow
        row
        wrap
        align-center
      >
        <VCol
          cols="12"
          md="4"
        >
          Import
        </VCol>
        <VCol
          justify-self-end
          class="mt-n4"
        >
          <FileUpload
            ref="fileUpload"
            label="Select csv file"
            accept="text/csv"
            @files="handleFile"
          />
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText>
      <VAlert
        v-show="!hasFile"
        type="info"
        :value="true"
      >
        <div class="title">
          Upload a CSV file
        </div>
        <VAlert
          type="warning"
          class="mt-2"
          :value="true"
        >
          <h3>Format</h3>
          <p>Please make sure your file is a plain text CSV file and is not endcoded in UTF8 or any other encoding.</p>

          <p>To make sure your CSV has the right encoding. Open it in Notepad (Windows), TextEdit (Mac) and save it again as a plan csv file.</p>

          <h3>Headers</h3>
          <p>
            Do
            <strong>NOT</strong> modify the headers in the CSV file sample as this could result in an improperly read import.
          </p>
        </VAlert>
        <VBtn
          color="info lighten-1 elevation-0 block"
          @click="downloadSample"
        >
          Click here to download a sample file
        </VBtn>
      </VAlert>
    </VCardText>
    <VCardText>
      <Alerts
        v-if="hasMessagesOrErrors"
        :messages="messages"
        :errors="errors"
      />
      <VAlert
        v-if="isSavedDataQueued"
        prominent
        type="info"
      >
        <h3>
          Your request is running in the background. You'll receive a notification once it's done. If you don't see the export please check your Spam folders. If you still don't receive the export in 15 mins, please contact support.
        </h3>
        <p>{{ saved.data.message }}</p>
        <hr>
        <VSpacer />
        <VBtn
          color="success"
          class="mt-3"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="$v.invalid || activity.isFormLoading"
          @click="onReset"
        >
          Start New Import
        </VBtn>
      </VAlert>
      <VAlert
        v-else
        type="success"
        :value="hasSavedData && !hasErrors"
      >
        Successfully invitation sent to users!
      </VAlert>
      <VRow
        v-if="activity.isLoading"
        row
        fluid
        justify-center
        class="mt-4"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
      <template v-else-if="isReadyToImport">
        <DataViewUI
          items-are-local
          hide-item-actions
          id="prepared-users-data"
          :headers="prepared.headers"
          :data="prepared"
          :row-style="{
            height: '112px'
          }"
          @search="onSearch"
        >
          <template #items-cells="props">
            <td>{{ props.index + 1 }}</td>
            <td>
              <TextField
                filled
                v-model="prepared.data[props.index].name"
                label="Name"
                placeholder="Enter name..."
                :error-messages="$v.prepared.data.$each[props.index].name.$error && ['Missing or invalid name'] || []"
                :error="$v.prepared.data.$each[props.index].name.$error"
                @input="validateModelAndSetErrors"
              />
            </td>
            <td>
              <TextField
                filled
                v-model="prepared.data[props.index].email"
                label="Email"
                type="email"
                placeholder="Enter valid Email..."
                :error-messages="$v.prepared.data.$each[props.index].email.$error && ['Missing or invalid email'] || []"
                :error="$v.prepared.data.$each[props.index].email.$error"
                @input="validateModelAndSetErrors"
              />
            </td>
            <td>
              <Autocomplete
                filled
                v-model="prepared.data[props.index].team_user.role"
                item-text="name"
                item-value="id"
                append-inner-icon="arrow-down"
                placeholder="Select role..."
                :items="roles"
                :error-messages="$v.prepared.data.$each[props.index].team_user.role.$error && ['Missing or invalid role'] || []"
                :error="$v.prepared.data.$each[props.index].team_user.role.$error"
                @input="validateModelAndSetErrors"
              />
            </td>
          </template>
          <template #top>
            <VRow
              row
              py-2
              style="width: 100%"
              class="my-2"
              :justify-space-between="$vuetify.breakpoint.mdAndUp"
              :justify-center="$vuetify.breakpoint.smAndDown"
            >
              <VBtn
                class="mr-2"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onReset"
              >
                Reset
              </VBtn>
              <VBtn
                class="wethrive"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onSaveUsers"
              >
                Invite
              </VBtn>
            </VRow>
          </template>
          <template #footer>
            <VRow
              row
              py-2
              style="width: 100%"
              class="my-2"
              :justify-space-between="$vuetify.breakpoint.mdAndUp"
              :justify-center="$vuetify.breakpoint.smAndDown"
            >
              <VBtn
                class="mr-2"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onReset"
              >
                Reset
              </VBtn>
              <VBtn
                class="wethrive"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="$v.invalid || activity.isFormLoading"
                :loading="activity.isFormLoading"
                @click="onSaveUsers"
              >
                Invite
              </VBtn>
            </VRow>
          </template>
        </DataViewUI>
      </template>
      <DataViewUI
        v-else-if="hasSavedData"
        items-are-local
        hide-item-actions
        id="saved-users-data"
        :headers="saved.headers"
        :data="saved"
        :row-style="{
          height: '112px'
        }"
      >
        <template #items-cells="props">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ featureName(props.item.role_name || props.item.role) }}</td>
          <td>
            <VTooltip top>
              <template #activator="{on}">
                <VBtn
                  text
                  icon
                  v-on="on"
                >
                  <VIcon :color="props.item.message ? 'error': 'success'">
                    {{ props.item.message ? 'error' : 'done' }}
                  </VIcon>
                </VBtn>
              </template>
              <span v-html="props.item.message || 'Invitation sent successfully'" />
            </VTooltip>
          </td>
        </template>
        <template #top>
          <VRow
            row
            py-2
            style="width: 100%"
            class="my-2"
            :justify-end="$vuetify.breakpoint.mdAndUp"
            :justify-center="$vuetify.breakpoint.smAndDown"
          >
            <VBtn
              :block="$vuetify.breakpoint.smAndDown"
              :disabled="$v.invalid || activity.isFormLoading"
              :loading="activity.isFormLoading"
              @click="onReset"
            >
              Reset
            </VBtn>
          </VRow>
        </template>
        <template #footer>
          <VRow
            row
            py-2
            style="width: 100%"
            class="my-2"
            :justify-end="$vuetify.breakpoint.mdAndUp"
            :justify-center="$vuetify.breakpoint.smAndDown"
          >
            <VBtn
              :block="$vuetify.breakpoint.smAndDown"
              :disabled="$v.invalid || activity.isFormLoading"
              :loading="activity.isFormLoading"
              @click="onReset"
            >
              Reset
            </VBtn>
          </VRow>
        </template>
      </DataViewUI>
    </VCardText>
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import ImportsData from "@/mixins/ImportsData";

export default {
  props: {
    program: {
      type: Object,
      default: null,
    },
  },
  mixins: [ImportsData],
  components: {},
  data() {
    return {
      useIndexes: true,
      uniqueKey: "name",
      saved: {
        headers: [
          {
            text: "Name",
            align: "left",
            sortable: false,
            value: "name",
          },
          {
            text: "Email",
            align: "left",
            sortable: false,
            value: "email",
          },
          {
            text: "Role",
            align: "left",
            sortable: false,
            value: "role_name",
          },
          {
            text: "Result",
            align: "left",
            sortable: false,
            value: "result",
          },
        ],
        data: [],
      },
      error: "",
      message: "No data provided. Please upload a CSV file",
    };
  },
  computed: {
    isReadyToImport() {
      return !this.hasSavedData && this.hasPreparedData && this.hasOptions;
    },
    roles() {
      return this.$team.roles.filter((role) => Object.keys(this.program.visible_to).includes(role.value));
    },
  },
  validations: {
    prepared: {
      data: {
        $each: {
          name: {
            required,
            minLength: minLength(2),
          },
          email: {
            required,
            email,
            minLength: minLength(4),
          },
          team_user: {
            role: {
              required,
            },
          },
        },
      },
    },
  },
  created() {
    this.doGetFormConfig().then((result) => {
      this.options = result;
      this.hasOptions = true;
      this.$emit("doneLoading");
    });
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "users/getFormConfig",
      doSaveUsers: "users/import",
      doInviteUsers: "team/bulkInviteToProgram",
    }),
    onSaveUsers() {
      let data = {
        ...this.prepared.data,
        program_id: this.program.id,
      };
      return this.onImportData(() =>
        this.saveFormModel(this.doInviteUsers, null, data)
      );
    },
    handleFile(files) {
      const file = this.onHandleFileStart(files);
      const self = this;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          self.onHandleFileComplete(result, () => {
            const callback = function (result) {
              self.$log.debug(
                "[Import Users]: Completed importing file. Waiting for options...",
                result
              );
              if (result.errors && result.errors.length) {
                self.errors = result.errors.map((error) => error.message);
              } else {
                self.message = `Prepared&nbsp;<strong>${result.data.length}</strong>&nbspentries`;
                self.prepared.headers = result.meta.fields.map((header) => {
                  return {
                    text: header,
                    align: "left",
                    sortable: true,
                    value: header.toLowerCase(),
                    realKey:
                      header.toLowerCase() === "role"
                        ? "team_user"
                        : header.toLowerCase(),
                  };
                });
                if (
                  self.prepared.headers.findIndex((h) =>
                    ["program", "programs"].indexOf(h.text.toLowerCase())
                  ) == -1
                ) {
                  self.prepared.headers.push({
                    text: "Programs",
                    align: "left",
                    sortable: true,
                    value: "program.id",
                  });
                }
                self.prepared.data = result.data.map((item) => {
                  let normalized = {
                    index: {
                      text: "#",
                      align: "left",
                      sortable: false,
                      value: "index",
                    },
                  };
                  Object.keys(item).map((v, k) => {
                    normalized[v.toLowerCase()] = item[v];
                    if (["email"].indexOf(v.toLowerCase()) > -1) {
                      normalized.email = (item[v] || "").toLowerCase();
                    }
                  });
                  normalized.team_user = {
                    role: (normalized.role || "Student").toLowerCase(),
                  };
                  normalized.programs = [self.program.id];
                  return normalized;
                });
                self.prepared.total = self.prepared.data.length;
              }
              self.validateModelAndSetErrors;
              self.$emit("doneLoading");
            };
            if (!self.hasOptions) {
              const $unwatch = self.$watch("hasOptions", (newVal, oldVal) => {
                if (newVal || oldVal) {
                  callback(result);
                  $unwatch();
                }
              });
            } else {
              callback(result);
            }
          });
        },
      });
    },
    downloadSample() {
      var blob = new Blob(
        [
          Papa.unparse({
            fields: ["Name", "Email", "Role"],
            data: [
              ["Student", "student@company", "student"],
              ["Mentor", "mentor@company", "mentor"],
            ],
          }),
        ],
        {
          type: "text/csv;charset=utf-8",
        }
      );
      saveAs(blob, "import-users.csv");
    },
    onSearch(s) {
      return this.saved.data.filter((user) => {
        if (this.filter && this.filter.length) {
          const nameMatch = user.name.match(this.filter);
          const emailMatch = user.email.match(this.filter);
          return (
            (nameMatch && nameMatch.length > 0) ||
            (emailMatch && emailMatch.length > 0)
          );
        }
        return true;
      });
    },
    onReset() {
      this.$log.debug("[FormMixin]: Resetting form model");
      this.form = { ...this.defaultForm };
      this.hasFile = false;
      this.$refs.fileUpload.onReset();
      this.saved.data = [];
      this.prepared.data = { ...this.defaultPrepared };
    },
  },
};
</script>
<style lang="scss">
#people-import-card {
  th.column {
    min-width: 250px;
  }
}
</style>