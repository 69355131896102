<template>
  <VRow
    class="px-4"
    style="min-height: 315px;"
  >
    <VCol
      :cols="hasAdditionalImages ? 12 : 6"
      :class="{
        'py-3': true,
        'order-last': form.layout == 'Right',
      }"
    >
      <TextField
        v-show="!(url || '').startsWith('blob')"
        v-model="form.value.url"
        filled
        persistent-hint
        clearable
        placeholder="Url"
        label="Enter a url"
        class="textarea-field"
        :error-messages="form.value && form.value.url && !form.value.url.startsWith('https') && !form.value.url.startsWith('blob') ? 'Due to privacy concerns, we do not accept http:// links. Please use the https:// version of the link.' : ''"
        :disabled="form.isSaving"
        @blur="backgroundSaveCard"
        @input="backgroundSaveCard"
      >
        <template
          #append
          v-if="url"
        >
          <VBtn
            block
            text
            color="error"
            align-self-end
            style="margin-top: -8px"
            @click="clearMedia"
          >
            Remove
            <VIcon>delete</VIcon>
          </VBtn>
        </template>
      </TextField>
      <VAlert
        v-if="!url"
        color="primary"
        class="mt-3 py-3"
        style="border-radius: 5px; color: white;"
        :value="true"
      >
        Enter a url above
      </VAlert>
      <template v-if="!url">
        <FileUpload
          text="Or Upload A Video"
          button-class="px-4 dashed mb-3"
          icon="movie"
          accept="video/*"
          :as-button="true"
          :outlined="true"
          :rounded="true"
          :block="true"
          :multiple="false"
          :files="[form.value]"
          @removeFile="onRemoveFile"
          @formData="onAddFile"
        />
        <VAlert type="info">
          For the best compatibility please upload a video encoded in one of the following formats: <ul>
            <li>mp4</li>
            <li>webm</li>
            <li>mov</li>
          </ul>
          You may want to use a tool such as 
          <a
            href="https://handbrake.fr/"
            taget="_blank"
            style="color: #f5ba2b;"
          >
            Handbrake
            <VIcon small>
              launch
            </VIcon>
          </a> 
          to convert your videos accordingly. Please also ensure any linked videos are set to Unlisted or they may not display properly.
        </VAlert>
      </template>
      <template v-if="url">
        <VSkeletonLoader
          v-if="!getIsSelfHostedMedia(form.value) && !isIframeLoaded"
          type="image"
        />
        <template v-if="getIsSelfHostedMedia(form.value)">
          <VBtn
            v-show="(url || '').startsWith('blob')"
            block
            text
            color="error"
            class="mb-2"
            @click="clearMedia"
          >
            Remove
            <VIcon>delete</VIcon>
          </VBtn>
          <video
            style="position: relative; display: block; margin-left: auto; margin-right: auto; width: inherit; height: auto; min-height: 210px; border-radius: 4px;"
            :autoplay="false"
            :controls="true"
            @click.left="onCancelContextMenu($event)"
            @contextmenu="onCancelContextMenu($event)"
          >
            <source
              :id="mediaId"
              :type="mediaType"
              :src="url"
              @click.left="onCancelContextMenu($event)"
              @contextmenu="onCancelContextMenu($event)"
            >
          </video>
          <div class="d-flex-inline py-1">
            <VChip class="mt-1 mr-1">
              {{ mediaFileName }}
            </VChip>
            <VChip class="mt-1 mr-1">
              {{ mediaFileType }}
            </VChip>
            <VChip class="mt-1 mr-1">
              <strong>{{ mediaFileSize }}</strong>
            </VChip>
          </div>
        </template>
        <iframe
          v-else-if="!getIsSelfHostedMedia(form.value)"
          allowfullscreen
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          style="display: block; margin-left: auto; margin-right: auto; width: inherit; min-height: 210px; border-radius: 4px;"
          :src="url"
          @load="() => $nextTick(() => isIframeLoaded = true)"
        />
      </template>
    </VCol>
    <VCol
      class="py-3"
      :cols="hasAdditionalImages ? 12 : 6"
    >
      <VRow>
        <VCol
          cols="12"
          class="pb-0"
        >
          <VSelect 
            v-model="form.layout"
            dense
            outlined
            label="Select Layout"
            class="layout-options"
            :items="layoutOptions"
            :disabled="form.isSaving"
            @blur="backgroundSaveCard"
            @input="backgroundSaveCard"
          />
          <TextField
            v-model="form.name"
            placeholder="(Optional) Title"
            class="textarea-field"
            :disabled="form.isSaving"
            @blur="backgroundSaveCard"
            @input="backgroundSaveCard"
          />
        </VCol>
        <VCol
          justify-content-center
          align-items-center
          cols="12"
          class="py-0"
        >
          <Editor
            v-model="form.options.caption"
            placeholder="(Optional) Description"
            class="lesson-editor textarea-field bg-textarea"
            :disabled="form.isSaving"
            @blur="backgroundSaveCard"
            @input="backgroundSaveCard"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import SaveCardMixin from "@/mixins/SaveAssessmentCard";
import HasFilesMixin from "@/mixins/HasFiles";
import DownloadsFilesMixin from "@/mixins/DownloadsFiles";

export default {
  name: "SaveCardVideo",
  mixins: [SaveCardMixin, HasFilesMixin, DownloadsFilesMixin],
  data() {
    return {
      layoutOptions: ["Left", "Right"],
      defaultForm: {
        layout: "Left",
        name: "",
        value: {},
        media: {},
      },
      isIframeLoaded: false,
    };
  },
  computed: {
    isSelfHostedMedia() {
      return this.getIsSelfHostedMedia(this.form.value || {});
    },
    mediaId() {
      return "card-media" + this._uid;
    },
    mediaType() {
      let type = (this.form.media || {}).type || "video/mp4";
      if (type.indexOf("quicktime") > -1 || type.indexOf("mov") > -1) {
        return "";
      }
      return type;
    },
    mediaFileSize() {
      return (
        this.mediaFile.readable_size || this.getFileSize(this.mediaFile.size)
      );
    },
    mediaFileName() {
      return this.mediaFile.name;
    },
    mediaFileType() {
      return this.mediaFile.type;
    },
    mediaFile() {
      return this.form.media instanceof File
        ? this.form.media
        : this.form.value || {};
    },
    url() {
      const value = this.form.value.url;
      try {
        const url = new URL(value);
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtu.be":
            const hostname =
              ["www.youtube.com", "youtube.com"].indexOf(url.hostname) === -1
                ? "www.youtube.com"
                : url.hostname;
            return `//${hostname}/embed/${
              url.searchParams.get("v") || url.pathname.substr(1)
            }`;
            break;

          case "vimeo.com":
          case "www.vimeo.com":
            return `//player.vimeo.com/video${url.pathname}`;
            break;

          default:
            return value;
            break;
        }
      } catch (e) {
        return value;
      }
    },
  },
  methods: {
    onAddFile(file) {
      this.form.media = file;
      this.getMediaFromFileObject(file, "#" + this.mediaId, (src) => {
        this.form.value = {
          ...(this.form.value || {}),
          ...{
            url: src,
          },
        };
        this.backgroundSaveCard();
      });
    },
    clearMedia() {
      const name = this.form.value.name;
      this.form.value = {};
      this.form.media = null;
      this.backgroundSaveCard();
    },
  },
};
</script>

<style lang="scss">
.bg-textarea .v-input__slot {
  min-height: 210px;
}
</style>
